/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDxCompany = /* GraphQL */ `
  mutation CreateDxCompany(
    $input: CreateDxCompanyInput!
    $condition: ModelDxCompanyConditionInput
  ) {
    createDxCompany(input: $input, condition: $condition) {
      id
      fleettype
      name
      description
      department
      departments {
        items {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDxCompany = /* GraphQL */ `
  mutation UpdateDxCompany(
    $input: UpdateDxCompanyInput!
    $condition: ModelDxCompanyConditionInput
  ) {
    updateDxCompany(input: $input, condition: $condition) {
      id
      fleettype
      name
      description
      department
      departments {
        items {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDxCompany = /* GraphQL */ `
  mutation DeleteDxCompany(
    $input: DeleteDxCompanyInput!
    $condition: ModelDxCompanyConditionInput
  ) {
    deleteDxCompany(input: $input, condition: $condition) {
      id
      fleettype
      name
      description
      department
      departments {
        items {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDxDepartment = /* GraphQL */ `
  mutation CreateDxDepartment(
    $input: CreateDxDepartmentInput!
    $condition: ModelDxDepartmentConditionInput
  ) {
    createDxDepartment(input: $input, condition: $condition) {
      id
      fleettype
      name
      description
      department
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicles {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      member
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDepartmentsId
      __typename
    }
  }
`;
export const updateDxDepartment = /* GraphQL */ `
  mutation UpdateDxDepartment(
    $input: UpdateDxDepartmentInput!
    $condition: ModelDxDepartmentConditionInput
  ) {
    updateDxDepartment(input: $input, condition: $condition) {
      id
      fleettype
      name
      description
      department
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicles {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      member
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDepartmentsId
      __typename
    }
  }
`;
export const deleteDxDepartment = /* GraphQL */ `
  mutation DeleteDxDepartment(
    $input: DeleteDxDepartmentInput!
    $condition: ModelDxDepartmentConditionInput
  ) {
    deleteDxDepartment(input: $input, condition: $condition) {
      id
      fleettype
      name
      description
      department
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicles {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      member
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDepartmentsId
      __typename
    }
  }
`;
export const createDxGeneralConfig = /* GraphQL */ `
  mutation CreateDxGeneralConfig(
    $input: CreateDxGeneralConfigInput!
    $condition: ModelDxGeneralConfigConditionInput
  ) {
    createDxGeneralConfig(input: $input, condition: $condition) {
      id
      name
      parentname {
        id
        name
        parentname {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        fleettype
        documents
        images
        isactive
        date
        author
        editor
        jfield
        userinfo
        childs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxGeneralConfigChildsId
        owner
        __typename
      }
      fleettype
      documents
      images
      isactive
      date
      author
      editor
      jfield
      userinfo
      childs {
        items {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxGeneralConfigChildsId
      owner
      __typename
    }
  }
`;
export const updateDxGeneralConfig = /* GraphQL */ `
  mutation UpdateDxGeneralConfig(
    $input: UpdateDxGeneralConfigInput!
    $condition: ModelDxGeneralConfigConditionInput
  ) {
    updateDxGeneralConfig(input: $input, condition: $condition) {
      id
      name
      parentname {
        id
        name
        parentname {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        fleettype
        documents
        images
        isactive
        date
        author
        editor
        jfield
        userinfo
        childs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxGeneralConfigChildsId
        owner
        __typename
      }
      fleettype
      documents
      images
      isactive
      date
      author
      editor
      jfield
      userinfo
      childs {
        items {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxGeneralConfigChildsId
      owner
      __typename
    }
  }
`;
export const deleteDxGeneralConfig = /* GraphQL */ `
  mutation DeleteDxGeneralConfig(
    $input: DeleteDxGeneralConfigInput!
    $condition: ModelDxGeneralConfigConditionInput
  ) {
    deleteDxGeneralConfig(input: $input, condition: $condition) {
      id
      name
      parentname {
        id
        name
        parentname {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        fleettype
        documents
        images
        isactive
        date
        author
        editor
        jfield
        userinfo
        childs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxGeneralConfigChildsId
        owner
        __typename
      }
      fleettype
      documents
      images
      isactive
      date
      author
      editor
      jfield
      userinfo
      childs {
        items {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxGeneralConfigChildsId
      owner
      __typename
    }
  }
`;
export const createDxDriver = /* GraphQL */ `
  mutation CreateDxDriver(
    $input: CreateDxDriverInput!
    $condition: ModelDxDriverConditionInput
  ) {
    createDxDriver(input: $input, condition: $condition) {
      id
      fleettype
      name
      mobileno
      contactno
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedriver {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      emailid
      licenseno
      licensetype
      licensepicture
      startdate
      enddate
      jobrole
      firstname
      lastname
      uname
      isnew
      isarchived
      status
      departmentname
      businessunitname
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDriversId
      dxDepartmentDriversId
      owner
      __typename
    }
  }
`;
export const updateDxDriver = /* GraphQL */ `
  mutation UpdateDxDriver(
    $input: UpdateDxDriverInput!
    $condition: ModelDxDriverConditionInput
  ) {
    updateDxDriver(input: $input, condition: $condition) {
      id
      fleettype
      name
      mobileno
      contactno
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedriver {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      emailid
      licenseno
      licensetype
      licensepicture
      startdate
      enddate
      jobrole
      firstname
      lastname
      uname
      isnew
      isarchived
      status
      departmentname
      businessunitname
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDriversId
      dxDepartmentDriversId
      owner
      __typename
    }
  }
`;
export const deleteDxDriver = /* GraphQL */ `
  mutation DeleteDxDriver(
    $input: DeleteDxDriverInput!
    $condition: ModelDxDriverConditionInput
  ) {
    deleteDxDriver(input: $input, condition: $condition) {
      id
      fleettype
      name
      mobileno
      contactno
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedriver {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      emailid
      licenseno
      licensetype
      licensepicture
      startdate
      enddate
      jobrole
      firstname
      lastname
      uname
      isnew
      isarchived
      status
      departmentname
      businessunitname
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDriversId
      dxDepartmentDriversId
      owner
      __typename
    }
  }
`;
export const createDxVehicleActivity = /* GraphQL */ `
  mutation CreateDxVehicleActivity(
    $input: CreateDxVehicleActivityInput!
    $condition: ModelDxVehicleActivityConditionInput
  ) {
    createDxVehicleActivity(input: $input, condition: $condition) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      nextcontracts {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      logs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      recentevents {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      expanses
      nextdates
      events
      VehicleStatus
      ImagePath
      Documents
      expiring
      isarchived
      wfstatus
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverVehicleactivityId
      dxVehicleVehicleactivityId
      dxAppointmentsActivityId
      dxContractActivityId
      owner
      __typename
    }
  }
`;
export const updateDxVehicleActivity = /* GraphQL */ `
  mutation UpdateDxVehicleActivity(
    $input: UpdateDxVehicleActivityInput!
    $condition: ModelDxVehicleActivityConditionInput
  ) {
    updateDxVehicleActivity(input: $input, condition: $condition) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      nextcontracts {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      logs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      recentevents {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      expanses
      nextdates
      events
      VehicleStatus
      ImagePath
      Documents
      expiring
      isarchived
      wfstatus
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverVehicleactivityId
      dxVehicleVehicleactivityId
      dxAppointmentsActivityId
      dxContractActivityId
      owner
      __typename
    }
  }
`;
export const deleteDxVehicleActivity = /* GraphQL */ `
  mutation DeleteDxVehicleActivity(
    $input: DeleteDxVehicleActivityInput!
    $condition: ModelDxVehicleActivityConditionInput
  ) {
    deleteDxVehicleActivity(input: $input, condition: $condition) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      nextcontracts {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      logs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      recentevents {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      expanses
      nextdates
      events
      VehicleStatus
      ImagePath
      Documents
      expiring
      isarchived
      wfstatus
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverVehicleactivityId
      dxVehicleVehicleactivityId
      dxAppointmentsActivityId
      dxContractActivityId
      owner
      __typename
    }
  }
`;
export const createDxVehicle = /* GraphQL */ `
  mutation CreateDxVehicle(
    $input: CreateDxVehicleInput!
    $condition: ModelDxVehicleConditionInput
  ) {
    createDxVehicle(input: $input, condition: $condition) {
      id
      platecode
      plateno
      make
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      capacity
      departmentname
      status
      chasisno
      salikno
      salikpin
      fuelno
      vowner
      isarchived
      year
      model
      policyno
      startdate
      enddate
      company
      permittype
      allocationno
      drivername
      VehicleStatus
      ImagePath
      insuranceno
      registrationno
      scno
      scvendor
      cupdate
      jfield
      userinfo
      author
      editor
      fleettype
      allocationdriver
      expanses
      nextdates
      events
      Documents
      expiring
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDepartmentVehiclesId
      dxContractVehicleId
      owner
      __typename
    }
  }
`;
export const updateDxVehicle = /* GraphQL */ `
  mutation UpdateDxVehicle(
    $input: UpdateDxVehicleInput!
    $condition: ModelDxVehicleConditionInput
  ) {
    updateDxVehicle(input: $input, condition: $condition) {
      id
      platecode
      plateno
      make
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      capacity
      departmentname
      status
      chasisno
      salikno
      salikpin
      fuelno
      vowner
      isarchived
      year
      model
      policyno
      startdate
      enddate
      company
      permittype
      allocationno
      drivername
      VehicleStatus
      ImagePath
      insuranceno
      registrationno
      scno
      scvendor
      cupdate
      jfield
      userinfo
      author
      editor
      fleettype
      allocationdriver
      expanses
      nextdates
      events
      Documents
      expiring
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDepartmentVehiclesId
      dxContractVehicleId
      owner
      __typename
    }
  }
`;
export const deleteDxVehicle = /* GraphQL */ `
  mutation DeleteDxVehicle(
    $input: DeleteDxVehicleInput!
    $condition: ModelDxVehicleConditionInput
  ) {
    deleteDxVehicle(input: $input, condition: $condition) {
      id
      platecode
      plateno
      make
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      capacity
      departmentname
      status
      chasisno
      salikno
      salikpin
      fuelno
      vowner
      isarchived
      year
      model
      policyno
      startdate
      enddate
      company
      permittype
      allocationno
      drivername
      VehicleStatus
      ImagePath
      insuranceno
      registrationno
      scno
      scvendor
      cupdate
      jfield
      userinfo
      author
      editor
      fleettype
      allocationdriver
      expanses
      nextdates
      events
      Documents
      expiring
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDepartmentVehiclesId
      dxContractVehicleId
      owner
      __typename
    }
  }
`;
export const createDxAllocations = /* GraphQL */ `
  mutation CreateDxAllocations(
    $input: CreateDxAllocationsInput!
    $condition: ModelDxAllocationsConditionInput
  ) {
    createDxAllocations(input: $input, condition: $condition) {
      id
      vehicleid
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      activity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      date
      drivername
      enddate
      dfleettype
      fromdriverID
      fromdrivername
      fromKM
      fromdate
      fromlocation
      driverID
      handoverdrivername
      handoverKM
      handoverdate
      handoverlocation
      distances
      reason
      allocatestatus
      pictures
      documents
      wfstatus
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAllocationsId
      dxVehicleActivityAllocationsId
      dxVehicleAllocationsId
      dxAppointmentsAllocationsId
      owner
      __typename
    }
  }
`;
export const updateDxAllocations = /* GraphQL */ `
  mutation UpdateDxAllocations(
    $input: UpdateDxAllocationsInput!
    $condition: ModelDxAllocationsConditionInput
  ) {
    updateDxAllocations(input: $input, condition: $condition) {
      id
      vehicleid
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      activity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      date
      drivername
      enddate
      dfleettype
      fromdriverID
      fromdrivername
      fromKM
      fromdate
      fromlocation
      driverID
      handoverdrivername
      handoverKM
      handoverdate
      handoverlocation
      distances
      reason
      allocatestatus
      pictures
      documents
      wfstatus
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAllocationsId
      dxVehicleActivityAllocationsId
      dxVehicleAllocationsId
      dxAppointmentsAllocationsId
      owner
      __typename
    }
  }
`;
export const deleteDxAllocations = /* GraphQL */ `
  mutation DeleteDxAllocations(
    $input: DeleteDxAllocationsInput!
    $condition: ModelDxAllocationsConditionInput
  ) {
    deleteDxAllocations(input: $input, condition: $condition) {
      id
      vehicleid
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      activity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      date
      drivername
      enddate
      dfleettype
      fromdriverID
      fromdrivername
      fromKM
      fromdate
      fromlocation
      driverID
      handoverdrivername
      handoverKM
      handoverdate
      handoverlocation
      distances
      reason
      allocatestatus
      pictures
      documents
      wfstatus
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAllocationsId
      dxVehicleActivityAllocationsId
      dxVehicleAllocationsId
      dxAppointmentsAllocationsId
      owner
      __typename
    }
  }
`;
export const createDxPermissionsLevels = /* GraphQL */ `
  mutation CreateDxPermissionsLevels(
    $input: CreateDxPermissionsLevelsInput!
    $condition: ModelDxPermissionsLevelsConditionInput
  ) {
    createDxPermissionsLevels(input: $input, condition: $condition) {
      id
      name
      description
      mask
      groups
      field1
      field2
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxPermissionsLevels = /* GraphQL */ `
  mutation UpdateDxPermissionsLevels(
    $input: UpdateDxPermissionsLevelsInput!
    $condition: ModelDxPermissionsLevelsConditionInput
  ) {
    updateDxPermissionsLevels(input: $input, condition: $condition) {
      id
      name
      description
      mask
      groups
      field1
      field2
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxPermissionsLevels = /* GraphQL */ `
  mutation DeleteDxPermissionsLevels(
    $input: DeleteDxPermissionsLevelsInput!
    $condition: ModelDxPermissionsLevelsConditionInput
  ) {
    deleteDxPermissionsLevels(input: $input, condition: $condition) {
      id
      name
      description
      mask
      groups
      field1
      field2
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createDxRoles = /* GraphQL */ `
  mutation CreateDxRoles(
    $input: CreateDxRolesInput!
    $condition: ModelDxRolesConditionInput
  ) {
    createDxRoles(input: $input, condition: $condition) {
      id
      rolename
      description
      application
      permissionlevel
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      title
      create
      read
      update
      delete
      jfield
      fullcontrol
      moderator
      member
      reader
      choicetype
      fleettable
      fleetdocs
      vehicle
      vehicledocs
      driver
      driverdocs
      appointments
      author
      editor
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxRoles = /* GraphQL */ `
  mutation UpdateDxRoles(
    $input: UpdateDxRolesInput!
    $condition: ModelDxRolesConditionInput
  ) {
    updateDxRoles(input: $input, condition: $condition) {
      id
      rolename
      description
      application
      permissionlevel
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      title
      create
      read
      update
      delete
      jfield
      fullcontrol
      moderator
      member
      reader
      choicetype
      fleettable
      fleetdocs
      vehicle
      vehicledocs
      driver
      driverdocs
      appointments
      author
      editor
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxRoles = /* GraphQL */ `
  mutation DeleteDxRoles(
    $input: DeleteDxRolesInput!
    $condition: ModelDxRolesConditionInput
  ) {
    deleteDxRoles(input: $input, condition: $condition) {
      id
      rolename
      description
      application
      permissionlevel
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      title
      create
      read
      update
      delete
      jfield
      fullcontrol
      moderator
      member
      reader
      choicetype
      fleettable
      fleetdocs
      vehicle
      vehicledocs
      driver
      driverdocs
      appointments
      author
      editor
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createDxUserRoles = /* GraphQL */ `
  mutation CreateDxUserRoles(
    $input: CreateDxUserRolesInput!
    $condition: ModelDxUserRolesConditionInput
  ) {
    createDxUserRoles(input: $input, condition: $condition) {
      id
      username
      userrole
      usergroups
      userprofile {
        id
        userid
        provideruserid
        email
        display
        picture
        displayname
        sub
        accessgroups
        userroles {
          nextToken
          __typename
        }
        attributes
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      usergroup {
        id
        rolename
        description
        application
        permissionlevel
        userroles {
          nextToken
          __typename
        }
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxRolesUserrolesId
      dxUserProfileUserrolesId
      owner
      __typename
    }
  }
`;
export const updateDxUserRoles = /* GraphQL */ `
  mutation UpdateDxUserRoles(
    $input: UpdateDxUserRolesInput!
    $condition: ModelDxUserRolesConditionInput
  ) {
    updateDxUserRoles(input: $input, condition: $condition) {
      id
      username
      userrole
      usergroups
      userprofile {
        id
        userid
        provideruserid
        email
        display
        picture
        displayname
        sub
        accessgroups
        userroles {
          nextToken
          __typename
        }
        attributes
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      usergroup {
        id
        rolename
        description
        application
        permissionlevel
        userroles {
          nextToken
          __typename
        }
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxRolesUserrolesId
      dxUserProfileUserrolesId
      owner
      __typename
    }
  }
`;
export const deleteDxUserRoles = /* GraphQL */ `
  mutation DeleteDxUserRoles(
    $input: DeleteDxUserRolesInput!
    $condition: ModelDxUserRolesConditionInput
  ) {
    deleteDxUserRoles(input: $input, condition: $condition) {
      id
      username
      userrole
      usergroups
      userprofile {
        id
        userid
        provideruserid
        email
        display
        picture
        displayname
        sub
        accessgroups
        userroles {
          nextToken
          __typename
        }
        attributes
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      usergroup {
        id
        rolename
        description
        application
        permissionlevel
        userroles {
          nextToken
          __typename
        }
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxRolesUserrolesId
      dxUserProfileUserrolesId
      owner
      __typename
    }
  }
`;
export const createDxUserProfile = /* GraphQL */ `
  mutation CreateDxUserProfile(
    $input: CreateDxUserProfileInput!
    $condition: ModelDxUserProfileConditionInput
  ) {
    createDxUserProfile(input: $input, condition: $condition) {
      id
      userid
      provideruserid
      email
      display
      picture
      displayname
      sub
      accessgroups
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      attributes
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxUserProfile = /* GraphQL */ `
  mutation UpdateDxUserProfile(
    $input: UpdateDxUserProfileInput!
    $condition: ModelDxUserProfileConditionInput
  ) {
    updateDxUserProfile(input: $input, condition: $condition) {
      id
      userid
      provideruserid
      email
      display
      picture
      displayname
      sub
      accessgroups
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      attributes
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxUserProfile = /* GraphQL */ `
  mutation DeleteDxUserProfile(
    $input: DeleteDxUserProfileInput!
    $condition: ModelDxUserProfileConditionInput
  ) {
    deleteDxUserProfile(input: $input, condition: $condition) {
      id
      userid
      provideruserid
      email
      display
      picture
      displayname
      sub
      accessgroups
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      attributes
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createDxAppointments = /* GraphQL */ `
  mutation CreateDxAppointments(
    $input: CreateDxAppointmentsInput!
    $condition: ModelDxAppointmentsConditionInput
  ) {
    createDxAppointments(input: $input, condition: $condition) {
      id
      spares {
        items {
          id
          vehicleid
          driverid
          supplier
          invoiceno
          invoicedoc
          partname
          itemtype
          quantity
          description
          amount
          totalamount
          approvedby
          status
          appointmentid
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxAppointmentsSparesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      contract {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      eventtype
      Date
      place
      description
      drivername
      appointmentstatus
      wanttoallocatedriver
      closeallocatedriver
      issccovered
      pictures
      documents
      cost
      invoiceno
      mileage
      auditby
      userprofiles
      accidenthappenedat
      accidenttype
      wfstatus
      author
      editor
      cupdate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAppointmentsId
      dxVehicleAppointmentsId
      dxContractAppointmentsId
      dxMasterLogsAccidentsId
      owner
      __typename
    }
  }
`;
export const updateDxAppointments = /* GraphQL */ `
  mutation UpdateDxAppointments(
    $input: UpdateDxAppointmentsInput!
    $condition: ModelDxAppointmentsConditionInput
  ) {
    updateDxAppointments(input: $input, condition: $condition) {
      id
      spares {
        items {
          id
          vehicleid
          driverid
          supplier
          invoiceno
          invoicedoc
          partname
          itemtype
          quantity
          description
          amount
          totalamount
          approvedby
          status
          appointmentid
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxAppointmentsSparesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      contract {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      eventtype
      Date
      place
      description
      drivername
      appointmentstatus
      wanttoallocatedriver
      closeallocatedriver
      issccovered
      pictures
      documents
      cost
      invoiceno
      mileage
      auditby
      userprofiles
      accidenthappenedat
      accidenttype
      wfstatus
      author
      editor
      cupdate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAppointmentsId
      dxVehicleAppointmentsId
      dxContractAppointmentsId
      dxMasterLogsAccidentsId
      owner
      __typename
    }
  }
`;
export const deleteDxAppointments = /* GraphQL */ `
  mutation DeleteDxAppointments(
    $input: DeleteDxAppointmentsInput!
    $condition: ModelDxAppointmentsConditionInput
  ) {
    deleteDxAppointments(input: $input, condition: $condition) {
      id
      spares {
        items {
          id
          vehicleid
          driverid
          supplier
          invoiceno
          invoicedoc
          partname
          itemtype
          quantity
          description
          amount
          totalamount
          approvedby
          status
          appointmentid
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxAppointmentsSparesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      contract {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      eventtype
      Date
      place
      description
      drivername
      appointmentstatus
      wanttoallocatedriver
      closeallocatedriver
      issccovered
      pictures
      documents
      cost
      invoiceno
      mileage
      auditby
      userprofiles
      accidenthappenedat
      accidenttype
      wfstatus
      author
      editor
      cupdate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAppointmentsId
      dxVehicleAppointmentsId
      dxContractAppointmentsId
      dxMasterLogsAccidentsId
      owner
      __typename
    }
  }
`;
export const createDxVendors = /* GraphQL */ `
  mutation CreateDxVendors(
    $input: CreateDxVendorsInput!
    $condition: ModelDxVendorsConditionInput
  ) {
    createDxVendors(input: $input, condition: $condition) {
      id
      company
      vendorname
      contactno
      email
      vendortype
      officelocation
      garagelocation
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxVendors = /* GraphQL */ `
  mutation UpdateDxVendors(
    $input: UpdateDxVendorsInput!
    $condition: ModelDxVendorsConditionInput
  ) {
    updateDxVendors(input: $input, condition: $condition) {
      id
      company
      vendorname
      contactno
      email
      vendortype
      officelocation
      garagelocation
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxVendors = /* GraphQL */ `
  mutation DeleteDxVendors(
    $input: DeleteDxVendorsInput!
    $condition: ModelDxVendorsConditionInput
  ) {
    deleteDxVendors(input: $input, condition: $condition) {
      id
      company
      vendorname
      contactno
      email
      vendortype
      officelocation
      garagelocation
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createDxContract = /* GraphQL */ `
  mutation CreateDxContract(
    $input: CreateDxContractInput!
    $condition: ModelDxContractConditionInput
  ) {
    createDxContract(input: $input, condition: $condition) {
      id
      renewaltype
      vehicle {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      renewalno
      subtype
      issuedate
      expirydate
      vehicleid
      documents
      pictures
      documents2
      renewalstatus
      isappointmentrequire
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      wfstatus
      period
      scvendor
      author
      editor
      cupdate
      jfield
      userinfo
      amount
      tax
      totalamount
      policyno
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxContract = /* GraphQL */ `
  mutation UpdateDxContract(
    $input: UpdateDxContractInput!
    $condition: ModelDxContractConditionInput
  ) {
    updateDxContract(input: $input, condition: $condition) {
      id
      renewaltype
      vehicle {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      renewalno
      subtype
      issuedate
      expirydate
      vehicleid
      documents
      pictures
      documents2
      renewalstatus
      isappointmentrequire
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      wfstatus
      period
      scvendor
      author
      editor
      cupdate
      jfield
      userinfo
      amount
      tax
      totalamount
      policyno
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxContract = /* GraphQL */ `
  mutation DeleteDxContract(
    $input: DeleteDxContractInput!
    $condition: ModelDxContractConditionInput
  ) {
    deleteDxContract(input: $input, condition: $condition) {
      id
      renewaltype
      vehicle {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      renewalno
      subtype
      issuedate
      expirydate
      vehicleid
      documents
      pictures
      documents2
      renewalstatus
      isappointmentrequire
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      wfstatus
      period
      scvendor
      author
      editor
      cupdate
      jfield
      userinfo
      amount
      tax
      totalamount
      policyno
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createDxVehicleSpares = /* GraphQL */ `
  mutation CreateDxVehicleSpares(
    $input: CreateDxVehicleSparesInput!
    $condition: ModelDxVehicleSparesConditionInput
  ) {
    createDxVehicleSpares(input: $input, condition: $condition) {
      id
      vehicleid
      driverid
      supplier
      invoiceno
      invoicedoc
      partname
      itemtype
      quantity
      description
      amount
      totalamount
      approvedby
      status
      appointmentid
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxAppointmentsSparesId
      owner
      __typename
    }
  }
`;
export const updateDxVehicleSpares = /* GraphQL */ `
  mutation UpdateDxVehicleSpares(
    $input: UpdateDxVehicleSparesInput!
    $condition: ModelDxVehicleSparesConditionInput
  ) {
    updateDxVehicleSpares(input: $input, condition: $condition) {
      id
      vehicleid
      driverid
      supplier
      invoiceno
      invoicedoc
      partname
      itemtype
      quantity
      description
      amount
      totalamount
      approvedby
      status
      appointmentid
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxAppointmentsSparesId
      owner
      __typename
    }
  }
`;
export const deleteDxVehicleSpares = /* GraphQL */ `
  mutation DeleteDxVehicleSpares(
    $input: DeleteDxVehicleSparesInput!
    $condition: ModelDxVehicleSparesConditionInput
  ) {
    deleteDxVehicleSpares(input: $input, condition: $condition) {
      id
      vehicleid
      driverid
      supplier
      invoiceno
      invoicedoc
      partname
      itemtype
      quantity
      description
      amount
      totalamount
      approvedby
      status
      appointmentid
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxAppointmentsSparesId
      owner
      __typename
    }
  }
`;
export const createDxshifts = /* GraphQL */ `
  mutation CreateDxshifts(
    $input: CreateDxshiftsInput!
    $condition: ModelDxshiftsConditionInput
  ) {
    createDxshifts(input: $input, condition: $condition) {
      id
      shift
      drivershift {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      starttime
      endtime
      startdatetime
      enddatetime
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxshifts = /* GraphQL */ `
  mutation UpdateDxshifts(
    $input: UpdateDxshiftsInput!
    $condition: ModelDxshiftsConditionInput
  ) {
    updateDxshifts(input: $input, condition: $condition) {
      id
      shift
      drivershift {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      starttime
      endtime
      startdatetime
      enddatetime
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxshifts = /* GraphQL */ `
  mutation DeleteDxshifts(
    $input: DeleteDxshiftsInput!
    $condition: ModelDxshiftsConditionInput
  ) {
    deleteDxshifts(input: $input, condition: $condition) {
      id
      shift
      drivershift {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      starttime
      endtime
      startdatetime
      enddatetime
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createDxdriverShifts = /* GraphQL */ `
  mutation CreateDxdriverShifts(
    $input: CreateDxdriverShiftsInput!
    $condition: ModelDxdriverShiftsConditionInput
  ) {
    createDxdriverShifts(input: $input, condition: $condition) {
      id
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      date
      day
      startdatetime
      enddatetime
      startKm
      endkm
      totalduration
      durationafterW
      Mileage
      MileageafterWH
      location
      shift {
        id
        shift
        drivershift {
          nextToken
          __typename
        }
        starttime
        endtime
        startdatetime
        enddatetime
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      vehicleid
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      drivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedrivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsMileagedrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagereport {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      remarks
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverDrivershiftsId
      dxAllocationsDrivershiftsId
      dxshiftsDrivershiftId
      owner
      __typename
    }
  }
`;
export const updateDxdriverShifts = /* GraphQL */ `
  mutation UpdateDxdriverShifts(
    $input: UpdateDxdriverShiftsInput!
    $condition: ModelDxdriverShiftsConditionInput
  ) {
    updateDxdriverShifts(input: $input, condition: $condition) {
      id
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      date
      day
      startdatetime
      enddatetime
      startKm
      endkm
      totalduration
      durationafterW
      Mileage
      MileageafterWH
      location
      shift {
        id
        shift
        drivershift {
          nextToken
          __typename
        }
        starttime
        endtime
        startdatetime
        enddatetime
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      vehicleid
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      drivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedrivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsMileagedrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagereport {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      remarks
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverDrivershiftsId
      dxAllocationsDrivershiftsId
      dxshiftsDrivershiftId
      owner
      __typename
    }
  }
`;
export const deleteDxdriverShifts = /* GraphQL */ `
  mutation DeleteDxdriverShifts(
    $input: DeleteDxdriverShiftsInput!
    $condition: ModelDxdriverShiftsConditionInput
  ) {
    deleteDxdriverShifts(input: $input, condition: $condition) {
      id
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      date
      day
      startdatetime
      enddatetime
      startKm
      endkm
      totalduration
      durationafterW
      Mileage
      MileageafterWH
      location
      shift {
        id
        shift
        drivershift {
          nextToken
          __typename
        }
        starttime
        endtime
        startdatetime
        enddatetime
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      vehicleid
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      drivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedrivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsMileagedrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagereport {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      remarks
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverDrivershiftsId
      dxAllocationsDrivershiftsId
      dxshiftsDrivershiftId
      owner
      __typename
    }
  }
`;
export const createDxlogReimbursement = /* GraphQL */ `
  mutation CreateDxlogReimbursement(
    $input: CreateDxlogReimbursementInput!
    $condition: ModelDxlogReimbursementConditionInput
  ) {
    createDxlogReimbursement(input: $input, condition: $condition) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      vehicleid
      driverid
      date
      transactiontype
      invoiceno
      paidto
      Vendorname
      description
      remarks
      split
      amount
      expensetype
      isreimbursed
      approver
      reimbursedamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverLogreimbursementId
      dxVehicleLogreimbursementId
      dxMasterLogsLogreimbursementId
      owner
      __typename
    }
  }
`;
export const updateDxlogReimbursement = /* GraphQL */ `
  mutation UpdateDxlogReimbursement(
    $input: UpdateDxlogReimbursementInput!
    $condition: ModelDxlogReimbursementConditionInput
  ) {
    updateDxlogReimbursement(input: $input, condition: $condition) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      vehicleid
      driverid
      date
      transactiontype
      invoiceno
      paidto
      Vendorname
      description
      remarks
      split
      amount
      expensetype
      isreimbursed
      approver
      reimbursedamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverLogreimbursementId
      dxVehicleLogreimbursementId
      dxMasterLogsLogreimbursementId
      owner
      __typename
    }
  }
`;
export const deleteDxlogReimbursement = /* GraphQL */ `
  mutation DeleteDxlogReimbursement(
    $input: DeleteDxlogReimbursementInput!
    $condition: ModelDxlogReimbursementConditionInput
  ) {
    deleteDxlogReimbursement(input: $input, condition: $condition) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      vehicleid
      driverid
      date
      transactiontype
      invoiceno
      paidto
      Vendorname
      description
      remarks
      split
      amount
      expensetype
      isreimbursed
      approver
      reimbursedamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverLogreimbursementId
      dxVehicleLogreimbursementId
      dxMasterLogsLogreimbursementId
      owner
      __typename
    }
  }
`;
export const createDxMasterLogs = /* GraphQL */ `
  mutation CreateDxMasterLogs(
    $input: CreateDxMasterLogsInput!
    $condition: ModelDxMasterLogsConditionInput
  ) {
    createDxMasterLogs(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      Accidents
      Fine
      Salik
      Mileage
      Parking
      Vipfuel
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileages {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      fines {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          source
          finenumber
          blackpoints
          reason
          location
          type
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsFinesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      salik {
        items {
          id
          transactionid
          date
          transactiondate
          tollgate
          direction
          tagnumber
          vehicleid
          driverid
          drivername
          amount
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsSalikId
          owner
          __typename
        }
        nextToken
        __typename
      }
      parkings {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          author
          editor
          reimbursement
          company
          expirydate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsParkingsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vipfuel {
        items {
          id
          date
          transactiondate
          vehicleid
          rid
          unitprice
          volume
          total
          productname
          receiptno
          odometer
          idunitcode
          stationname
          stationcode
          fleetname
          groupname
          vehiclecode
          citycode
          costcenter
          vatrate
          vatamount
          actualamount
          driverid
          drivername
          cashdescription
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsVipfuelId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accidents {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      vehicleactivity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      author
      reimbursement
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMasterlogsId
      dxVehicleActivityLogsId
      dxVehicleMasterlogsId
      dxAllocationsMasterlogsId
      owner
      __typename
    }
  }
`;
export const updateDxMasterLogs = /* GraphQL */ `
  mutation UpdateDxMasterLogs(
    $input: UpdateDxMasterLogsInput!
    $condition: ModelDxMasterLogsConditionInput
  ) {
    updateDxMasterLogs(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      Accidents
      Fine
      Salik
      Mileage
      Parking
      Vipfuel
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileages {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      fines {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          source
          finenumber
          blackpoints
          reason
          location
          type
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsFinesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      salik {
        items {
          id
          transactionid
          date
          transactiondate
          tollgate
          direction
          tagnumber
          vehicleid
          driverid
          drivername
          amount
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsSalikId
          owner
          __typename
        }
        nextToken
        __typename
      }
      parkings {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          author
          editor
          reimbursement
          company
          expirydate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsParkingsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vipfuel {
        items {
          id
          date
          transactiondate
          vehicleid
          rid
          unitprice
          volume
          total
          productname
          receiptno
          odometer
          idunitcode
          stationname
          stationcode
          fleetname
          groupname
          vehiclecode
          citycode
          costcenter
          vatrate
          vatamount
          actualamount
          driverid
          drivername
          cashdescription
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsVipfuelId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accidents {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      vehicleactivity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      author
      reimbursement
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMasterlogsId
      dxVehicleActivityLogsId
      dxVehicleMasterlogsId
      dxAllocationsMasterlogsId
      owner
      __typename
    }
  }
`;
export const deleteDxMasterLogs = /* GraphQL */ `
  mutation DeleteDxMasterLogs(
    $input: DeleteDxMasterLogsInput!
    $condition: ModelDxMasterLogsConditionInput
  ) {
    deleteDxMasterLogs(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      Accidents
      Fine
      Salik
      Mileage
      Parking
      Vipfuel
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileages {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      fines {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          source
          finenumber
          blackpoints
          reason
          location
          type
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsFinesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      salik {
        items {
          id
          transactionid
          date
          transactiondate
          tollgate
          direction
          tagnumber
          vehicleid
          driverid
          drivername
          amount
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsSalikId
          owner
          __typename
        }
        nextToken
        __typename
      }
      parkings {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          author
          editor
          reimbursement
          company
          expirydate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsParkingsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vipfuel {
        items {
          id
          date
          transactiondate
          vehicleid
          rid
          unitprice
          volume
          total
          productname
          receiptno
          odometer
          idunitcode
          stationname
          stationcode
          fleetname
          groupname
          vehiclecode
          citycode
          costcenter
          vatrate
          vatamount
          actualamount
          driverid
          drivername
          cashdescription
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsVipfuelId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accidents {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      vehicleactivity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      author
      reimbursement
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMasterlogsId
      dxVehicleActivityLogsId
      dxVehicleMasterlogsId
      dxAllocationsMasterlogsId
      owner
      __typename
    }
  }
`;
export const createDxMileageReport = /* GraphQL */ `
  mutation CreateDxMileageReport(
    $input: CreateDxMileageReportInput!
    $condition: ModelDxMileageReportConditionInput
  ) {
    createDxMileageReport(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsMileagedrivershiftId
      owner
      __typename
    }
  }
`;
export const updateDxMileageReport = /* GraphQL */ `
  mutation UpdateDxMileageReport(
    $input: UpdateDxMileageReportInput!
    $condition: ModelDxMileageReportConditionInput
  ) {
    updateDxMileageReport(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsMileagedrivershiftId
      owner
      __typename
    }
  }
`;
export const deleteDxMileageReport = /* GraphQL */ `
  mutation DeleteDxMileageReport(
    $input: DeleteDxMileageReportInput!
    $condition: ModelDxMileageReportConditionInput
  ) {
    deleteDxMileageReport(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsMileagedrivershiftId
      owner
      __typename
    }
  }
`;
export const createDxMileage = /* GraphQL */ `
  mutation CreateDxMileage(
    $input: CreateDxMileageInput!
    $condition: ModelDxMileageConditionInput
  ) {
    createDxMileage(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      sortstartdatetime
      tablename
      enddate
      duration
      avgspeed
      maxspeed
      mileagedriver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMileagedriverId
      dxdriverShiftsMileagereportId
      owner
      __typename
    }
  }
`;
export const updateDxMileage = /* GraphQL */ `
  mutation UpdateDxMileage(
    $input: UpdateDxMileageInput!
    $condition: ModelDxMileageConditionInput
  ) {
    updateDxMileage(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      sortstartdatetime
      tablename
      enddate
      duration
      avgspeed
      maxspeed
      mileagedriver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMileagedriverId
      dxdriverShiftsMileagereportId
      owner
      __typename
    }
  }
`;
export const deleteDxMileage = /* GraphQL */ `
  mutation DeleteDxMileage(
    $input: DeleteDxMileageInput!
    $condition: ModelDxMileageConditionInput
  ) {
    deleteDxMileage(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      sortstartdatetime
      tablename
      enddate
      duration
      avgspeed
      maxspeed
      mileagedriver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMileagedriverId
      dxdriverShiftsMileagereportId
      owner
      __typename
    }
  }
`;
export const createDxlogMileage = /* GraphQL */ `
  mutation CreateDxlogMileage(
    $input: CreateDxlogMileageInput!
    $condition: ModelDxlogMileageConditionInput
  ) {
    createDxlogMileage(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsDrivershiftId
      dxMasterLogsMileagesId
      owner
      __typename
    }
  }
`;
export const updateDxlogMileage = /* GraphQL */ `
  mutation UpdateDxlogMileage(
    $input: UpdateDxlogMileageInput!
    $condition: ModelDxlogMileageConditionInput
  ) {
    updateDxlogMileage(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsDrivershiftId
      dxMasterLogsMileagesId
      owner
      __typename
    }
  }
`;
export const deleteDxlogMileage = /* GraphQL */ `
  mutation DeleteDxlogMileage(
    $input: DeleteDxlogMileageInput!
    $condition: ModelDxlogMileageConditionInput
  ) {
    deleteDxlogMileage(input: $input, condition: $condition) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsDrivershiftId
      dxMasterLogsMileagesId
      owner
      __typename
    }
  }
`;
export const createDxLogFines = /* GraphQL */ `
  mutation CreateDxLogFines(
    $input: CreateDxLogFinesInput!
    $condition: ModelDxLogFinesConditionInput
  ) {
    createDxLogFines(input: $input, condition: $condition) {
      id
      date
      transactiondate
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      source
      finenumber
      blackpoints
      reason
      location
      type
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsFinesId
      owner
      __typename
    }
  }
`;
export const updateDxLogFines = /* GraphQL */ `
  mutation UpdateDxLogFines(
    $input: UpdateDxLogFinesInput!
    $condition: ModelDxLogFinesConditionInput
  ) {
    updateDxLogFines(input: $input, condition: $condition) {
      id
      date
      transactiondate
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      source
      finenumber
      blackpoints
      reason
      location
      type
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsFinesId
      owner
      __typename
    }
  }
`;
export const deleteDxLogFines = /* GraphQL */ `
  mutation DeleteDxLogFines(
    $input: DeleteDxLogFinesInput!
    $condition: ModelDxLogFinesConditionInput
  ) {
    deleteDxLogFines(input: $input, condition: $condition) {
      id
      date
      transactiondate
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      source
      finenumber
      blackpoints
      reason
      location
      type
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsFinesId
      owner
      __typename
    }
  }
`;
export const createDxLogSalik = /* GraphQL */ `
  mutation CreateDxLogSalik(
    $input: CreateDxLogSalikInput!
    $condition: ModelDxLogSalikConditionInput
  ) {
    createDxLogSalik(input: $input, condition: $condition) {
      id
      transactionid
      date
      transactiondate
      tollgate
      direction
      tagnumber
      vehicleid
      driverid
      drivername
      amount
      reimbursement
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsSalikId
      owner
      __typename
    }
  }
`;
export const updateDxLogSalik = /* GraphQL */ `
  mutation UpdateDxLogSalik(
    $input: UpdateDxLogSalikInput!
    $condition: ModelDxLogSalikConditionInput
  ) {
    updateDxLogSalik(input: $input, condition: $condition) {
      id
      transactionid
      date
      transactiondate
      tollgate
      direction
      tagnumber
      vehicleid
      driverid
      drivername
      amount
      reimbursement
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsSalikId
      owner
      __typename
    }
  }
`;
export const deleteDxLogSalik = /* GraphQL */ `
  mutation DeleteDxLogSalik(
    $input: DeleteDxLogSalikInput!
    $condition: ModelDxLogSalikConditionInput
  ) {
    deleteDxLogSalik(input: $input, condition: $condition) {
      id
      transactionid
      date
      transactiondate
      tollgate
      direction
      tagnumber
      vehicleid
      driverid
      drivername
      amount
      reimbursement
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsSalikId
      owner
      __typename
    }
  }
`;
export const createDxLogParking = /* GraphQL */ `
  mutation CreateDxLogParking(
    $input: CreateDxLogParkingInput!
    $condition: ModelDxLogParkingConditionInput
  ) {
    createDxLogParking(input: $input, condition: $condition) {
      id
      date
      transactiondate
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      author
      editor
      reimbursement
      company
      expirydate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsParkingsId
      owner
      __typename
    }
  }
`;
export const updateDxLogParking = /* GraphQL */ `
  mutation UpdateDxLogParking(
    $input: UpdateDxLogParkingInput!
    $condition: ModelDxLogParkingConditionInput
  ) {
    updateDxLogParking(input: $input, condition: $condition) {
      id
      date
      transactiondate
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      author
      editor
      reimbursement
      company
      expirydate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsParkingsId
      owner
      __typename
    }
  }
`;
export const deleteDxLogParking = /* GraphQL */ `
  mutation DeleteDxLogParking(
    $input: DeleteDxLogParkingInput!
    $condition: ModelDxLogParkingConditionInput
  ) {
    deleteDxLogParking(input: $input, condition: $condition) {
      id
      date
      transactiondate
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      author
      editor
      reimbursement
      company
      expirydate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsParkingsId
      owner
      __typename
    }
  }
`;
export const createDxLogVipfuel = /* GraphQL */ `
  mutation CreateDxLogVipfuel(
    $input: CreateDxLogVipfuelInput!
    $condition: ModelDxLogVipfuelConditionInput
  ) {
    createDxLogVipfuel(input: $input, condition: $condition) {
      id
      date
      transactiondate
      vehicleid
      rid
      unitprice
      volume
      total
      productname
      receiptno
      odometer
      idunitcode
      stationname
      stationcode
      fleetname
      groupname
      vehiclecode
      citycode
      costcenter
      vatrate
      vatamount
      actualamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      driverid
      drivername
      cashdescription
      reimbursement
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsVipfuelId
      owner
      __typename
    }
  }
`;
export const updateDxLogVipfuel = /* GraphQL */ `
  mutation UpdateDxLogVipfuel(
    $input: UpdateDxLogVipfuelInput!
    $condition: ModelDxLogVipfuelConditionInput
  ) {
    updateDxLogVipfuel(input: $input, condition: $condition) {
      id
      date
      transactiondate
      vehicleid
      rid
      unitprice
      volume
      total
      productname
      receiptno
      odometer
      idunitcode
      stationname
      stationcode
      fleetname
      groupname
      vehiclecode
      citycode
      costcenter
      vatrate
      vatamount
      actualamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      driverid
      drivername
      cashdescription
      reimbursement
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsVipfuelId
      owner
      __typename
    }
  }
`;
export const deleteDxLogVipfuel = /* GraphQL */ `
  mutation DeleteDxLogVipfuel(
    $input: DeleteDxLogVipfuelInput!
    $condition: ModelDxLogVipfuelConditionInput
  ) {
    deleteDxLogVipfuel(input: $input, condition: $condition) {
      id
      date
      transactiondate
      vehicleid
      rid
      unitprice
      volume
      total
      productname
      receiptno
      odometer
      idunitcode
      stationname
      stationcode
      fleetname
      groupname
      vehiclecode
      citycode
      costcenter
      vatrate
      vatamount
      actualamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      driverid
      drivername
      cashdescription
      reimbursement
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsVipfuelId
      owner
      __typename
    }
  }
`;
export const createDxNavigation = /* GraphQL */ `
  mutation CreateDxNavigation(
    $input: CreateDxNavigationInput!
    $condition: ModelDxNavigationConditionInput
  ) {
    createDxNavigation(input: $input, condition: $condition) {
      id
      type
      item
      order
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxNavigation = /* GraphQL */ `
  mutation UpdateDxNavigation(
    $input: UpdateDxNavigationInput!
    $condition: ModelDxNavigationConditionInput
  ) {
    updateDxNavigation(input: $input, condition: $condition) {
      id
      type
      item
      order
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxNavigation = /* GraphQL */ `
  mutation DeleteDxNavigation(
    $input: DeleteDxNavigationInput!
    $condition: ModelDxNavigationConditionInput
  ) {
    deleteDxNavigation(input: $input, condition: $condition) {
      id
      type
      item
      order
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createDxWorkflow = /* GraphQL */ `
  mutation CreateDxWorkflow(
    $input: CreateDxWorkflowInput!
    $condition: ModelDxWorkflowConditionInput
  ) {
    createDxWorkflow(input: $input, condition: $condition) {
      id
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      approvalitems {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      title
      description
      startdate
      duedate
      enddate
      resource
      resourceid
      resourceurl
      currentlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      nextlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      wfstatus
      wfield1
      wfield2
      wfield3
      wfield4
      wfield5
      wjson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsWorkflowId
      dxApprovalConfigWorkflowId
      owner
      __typename
    }
  }
`;
export const updateDxWorkflow = /* GraphQL */ `
  mutation UpdateDxWorkflow(
    $input: UpdateDxWorkflowInput!
    $condition: ModelDxWorkflowConditionInput
  ) {
    updateDxWorkflow(input: $input, condition: $condition) {
      id
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      approvalitems {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      title
      description
      startdate
      duedate
      enddate
      resource
      resourceid
      resourceurl
      currentlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      nextlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      wfstatus
      wfield1
      wfield2
      wfield3
      wfield4
      wfield5
      wjson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsWorkflowId
      dxApprovalConfigWorkflowId
      owner
      __typename
    }
  }
`;
export const deleteDxWorkflow = /* GraphQL */ `
  mutation DeleteDxWorkflow(
    $input: DeleteDxWorkflowInput!
    $condition: ModelDxWorkflowConditionInput
  ) {
    deleteDxWorkflow(input: $input, condition: $condition) {
      id
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      approvalitems {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      title
      description
      startdate
      duedate
      enddate
      resource
      resourceid
      resourceurl
      currentlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      nextlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      wfstatus
      wfield1
      wfield2
      wfield3
      wfield4
      wfield5
      wjson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsWorkflowId
      dxApprovalConfigWorkflowId
      owner
      __typename
    }
  }
`;
export const createDxApprovals = /* GraphQL */ `
  mutation CreateDxApprovals(
    $input: CreateDxApprovalsInput!
    $condition: ModelDxApprovalsConditionInput
  ) {
    createDxApprovals(input: $input, condition: $condition) {
      id
      name
      title
      description
      startdate
      duedate
      enddate
      assignedto
      level
      status
      resource
      resourceid
      resourceurl
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      afield1
      afield2
      afield3
      afield4
      afield5
      userprofiles
      ajson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalitemsId
      dxApprovalConfigApprovalId
      owner
      __typename
    }
  }
`;
export const updateDxApprovals = /* GraphQL */ `
  mutation UpdateDxApprovals(
    $input: UpdateDxApprovalsInput!
    $condition: ModelDxApprovalsConditionInput
  ) {
    updateDxApprovals(input: $input, condition: $condition) {
      id
      name
      title
      description
      startdate
      duedate
      enddate
      assignedto
      level
      status
      resource
      resourceid
      resourceurl
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      afield1
      afield2
      afield3
      afield4
      afield5
      userprofiles
      ajson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalitemsId
      dxApprovalConfigApprovalId
      owner
      __typename
    }
  }
`;
export const deleteDxApprovals = /* GraphQL */ `
  mutation DeleteDxApprovals(
    $input: DeleteDxApprovalsInput!
    $condition: ModelDxApprovalsConditionInput
  ) {
    deleteDxApprovals(input: $input, condition: $condition) {
      id
      name
      title
      description
      startdate
      duedate
      enddate
      assignedto
      level
      status
      resource
      resourceid
      resourceurl
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      afield1
      afield2
      afield3
      afield4
      afield5
      userprofiles
      ajson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalitemsId
      dxApprovalConfigApprovalId
      owner
      __typename
    }
  }
`;
export const createDxApprovalsHistory = /* GraphQL */ `
  mutation CreateDxApprovalsHistory(
    $input: CreateDxApprovalsHistoryInput!
    $condition: ModelDxApprovalsHistoryConditionInput
  ) {
    createDxApprovalsHistory(input: $input, condition: $condition) {
      id
      changes
      status
      activity
      respondedby
      date
      approval {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        afield1
        afield2
        afield3
        afield4
        afield5
        userprofiles
        ajson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalhistoryId
      dxApprovalsApprovalhistoryId
      owner
      __typename
    }
  }
`;
export const updateDxApprovalsHistory = /* GraphQL */ `
  mutation UpdateDxApprovalsHistory(
    $input: UpdateDxApprovalsHistoryInput!
    $condition: ModelDxApprovalsHistoryConditionInput
  ) {
    updateDxApprovalsHistory(input: $input, condition: $condition) {
      id
      changes
      status
      activity
      respondedby
      date
      approval {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        afield1
        afield2
        afield3
        afield4
        afield5
        userprofiles
        ajson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalhistoryId
      dxApprovalsApprovalhistoryId
      owner
      __typename
    }
  }
`;
export const deleteDxApprovalsHistory = /* GraphQL */ `
  mutation DeleteDxApprovalsHistory(
    $input: DeleteDxApprovalsHistoryInput!
    $condition: ModelDxApprovalsHistoryConditionInput
  ) {
    deleteDxApprovalsHistory(input: $input, condition: $condition) {
      id
      changes
      status
      activity
      respondedby
      date
      approval {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        afield1
        afield2
        afield3
        afield4
        afield5
        userprofiles
        ajson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalhistoryId
      dxApprovalsApprovalhistoryId
      owner
      __typename
    }
  }
`;
export const createDxApprovalConfigSteps = /* GraphQL */ `
  mutation CreateDxApprovalConfigSteps(
    $input: CreateDxApprovalConfigStepsInput!
    $condition: ModelDxApprovalConfigStepsConditionInput
  ) {
    createDxApprovalConfigSteps(input: $input, condition: $condition) {
      id
      name
      title
      action
      approval {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acsjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsId
      owner
      __typename
    }
  }
`;
export const updateDxApprovalConfigSteps = /* GraphQL */ `
  mutation UpdateDxApprovalConfigSteps(
    $input: UpdateDxApprovalConfigStepsInput!
    $condition: ModelDxApprovalConfigStepsConditionInput
  ) {
    updateDxApprovalConfigSteps(input: $input, condition: $condition) {
      id
      name
      title
      action
      approval {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acsjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsId
      owner
      __typename
    }
  }
`;
export const deleteDxApprovalConfigSteps = /* GraphQL */ `
  mutation DeleteDxApprovalConfigSteps(
    $input: DeleteDxApprovalConfigStepsInput!
    $condition: ModelDxApprovalConfigStepsConditionInput
  ) {
    deleteDxApprovalConfigSteps(input: $input, condition: $condition) {
      id
      name
      title
      action
      approval {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acsjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsId
      owner
      __typename
    }
  }
`;
export const createDxApprovalConfig = /* GraphQL */ `
  mutation CreateDxApprovalConfig(
    $input: CreateDxApprovalConfigInput!
    $condition: ModelDxApprovalConfigConditionInput
  ) {
    createDxApprovalConfig(input: $input, condition: $condition) {
      id
      name
      title
      action
      approval {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxApprovalConfig = /* GraphQL */ `
  mutation UpdateDxApprovalConfig(
    $input: UpdateDxApprovalConfigInput!
    $condition: ModelDxApprovalConfigConditionInput
  ) {
    updateDxApprovalConfig(input: $input, condition: $condition) {
      id
      name
      title
      action
      approval {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxApprovalConfig = /* GraphQL */ `
  mutation DeleteDxApprovalConfig(
    $input: DeleteDxApprovalConfigInput!
    $condition: ModelDxApprovalConfigConditionInput
  ) {
    deleteDxApprovalConfig(input: $input, condition: $condition) {
      id
      name
      title
      action
      approval {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createDxDocument = /* GraphQL */ `
  mutation CreateDxDocument(
    $input: CreateDxDocumentInput!
    $condition: ModelDxDocumentConditionInput
  ) {
    createDxDocument(input: $input, condition: $condition) {
      id
      name
      resource
      resourceid
      presourceid
      presource
      field1
      field2
      field3
      s3object {
        bucket
        region
        key
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxDocument = /* GraphQL */ `
  mutation UpdateDxDocument(
    $input: UpdateDxDocumentInput!
    $condition: ModelDxDocumentConditionInput
  ) {
    updateDxDocument(input: $input, condition: $condition) {
      id
      name
      resource
      resourceid
      presourceid
      presource
      field1
      field2
      field3
      s3object {
        bucket
        region
        key
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxDocument = /* GraphQL */ `
  mutation DeleteDxDocument(
    $input: DeleteDxDocumentInput!
    $condition: ModelDxDocumentConditionInput
  ) {
    deleteDxDocument(input: $input, condition: $condition) {
      id
      name
      resource
      resourceid
      presourceid
      presource
      field1
      field2
      field3
      s3object {
        bucket
        region
        key
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createDxTableSettings = /* GraphQL */ `
  mutation CreateDxTableSettings(
    $input: CreateDxTableSettingsInput!
    $condition: ModelDxTableSettingsConditionInput
  ) {
    createDxTableSettings(input: $input, condition: $condition) {
      id
      application
      table
      column
      expression
      isactive
      field1
      field2
      field3
      field4
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateDxTableSettings = /* GraphQL */ `
  mutation UpdateDxTableSettings(
    $input: UpdateDxTableSettingsInput!
    $condition: ModelDxTableSettingsConditionInput
  ) {
    updateDxTableSettings(input: $input, condition: $condition) {
      id
      application
      table
      column
      expression
      isactive
      field1
      field2
      field3
      field4
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteDxTableSettings = /* GraphQL */ `
  mutation DeleteDxTableSettings(
    $input: DeleteDxTableSettingsInput!
    $condition: ModelDxTableSettingsConditionInput
  ) {
    deleteDxTableSettings(input: $input, condition: $condition) {
      id
      application
      table
      column
      expression
      isactive
      field1
      field2
      field3
      field4
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
