export const listdxcomps = `
query listdxcomps {
  listDxCompanies {
    items {
        id
        fleettype
        name
        description
        department
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departments {
            items {
              name
              id
            }
          }
      }
      nextToken
      startedAt
      
    
  }
}
`;

export const listdxdepts = `
query listdxdepts {
  listDxDepartments {
    items {
        id
        fleettype
        name
        description
        department
        owner
        member
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
      company {
        id
        name
      }
    }
    nextToken
    startedAt
  }
  }
`;
export const _listDxDrivers = /* GraphQL */ `
  query ListDxDrivers(
    $id: ID
    $filter: ModelDxDriverFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxDrivers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        jfield
        status
        userinfo
        firstname
        lastname
        isnew
        isarchived
        department {
          id
          name
        }
        company {
          id
          name
        }

        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentDriversId
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxVehicles = /* GraphQL */ `
  query ListDxVehicles(
    $id: ID
    $filter: ModelDxVehicleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxVehicles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fleettype
        platecode
        plateno
        make
        departmentid
        businessunitid
        capacity
        department {
          id
          name
        }
        vehicleactivity {
          items {
            id
          }
        }
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        year
        model
        policyno
        startdate
        enddate
        company
        isarchived
        permittype
        allocationno
        drivername
        allocationdriver
        expanses
        insuranceno
        registrationno
        scno
        scvendor
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxVehicleActivities = /* GraphQL */ `
  query ListDxVehicleActivities(
    $id: ID
    $filter: ModelDxVehicleActivityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $allocationfilter: ModeldxAllocationsFilterInput
  ) {
    listDxVehicleActivities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        isarchived
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        jfield
        recentevents {
          id
          wfstatus
          eventtype
          appointmentstatus
        }
        vehicle {
          id
          plateno
          jfield
          status
          capacity
          enddate
          make
          model
          ImagePath
          department {
            id
            name
          }
          masterlogs(limit: 10000) {
            items {
              id
              vehicleid
              Accidents
              date
              drivername
              Salik
              Parking
              Vipfuel
              Fine
              reimbursement
              Mileage
              jfield
              _version
              _deleted
            }
          }
        }
        driver {
          id
          name
          enddate
          jfield
        }
        allocations(limit: 5, filter: $allocationfilter, sortDirection: ASC) {
          items {
            id
            date
            handoverdrivername
            handoverKM
            handoverdate
            handoverlocation
            fromdriverID
            fromdrivername
            fromKM
            fromdate
            fromlocation
            allocatestatus
            jfield
            _version
            _deleted
          }
        }
        logs(limit: 100) {
          items {
            id
            Salik
            Vipfuel
            Fine
            Mileage
            Accidents
            date
            jfield
            _version
            _deleted
          }
          nextToken
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const _listDxVehicleActivities2 = /* GraphQL */ `
  query ListDxVehicleActivities(
    $id: ID
    $filter: ModelDxVehicleActivityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $allocationfilter: ModeldxAllocationsFilterInput
  ) {
    listDxVehicleActivities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        vehicle {
          id
          plateno
          masterlogs(limit: 100) {
            items {
              id
              vehicleid
              Accidents
              date
              drivername
              Salik
              Vipfuel
              Fine
              Mileage
            }
          }
        }
        driver {
          id
          name
          enddate
        }
        allocations(limit: 5, filter: $allocationfilter, sortDirection: ASC) {
          items {
            id
            date
            handoverdrivername
            handoverKM
            handoverdate
            handoverlocation
            fromdriverID
            fromdrivername
            fromKM
            fromdate
            fromlocation
            allocatestatus
            _version
            masterlogs(limit: 1000) {
              items {
                id
                Salik
                Vipfuel
              }
            }
          }
        }
        logs(limit: 100) {
          items {
            id
            Salik
            Vipfuel
            Fine
            Mileage
            Accidents
            date
            salik {
              items {
                id
                date
                tollgate
                amount
              }
              nextToken
            }
            vipfuel {
              items {
                id
                date
                total
                stationname
              }
            }
          }
          nextToken
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxApprovalConfigs = /* GraphQL */ `
  query ListDxApprovalConfigs(
    $filter: ModelDxApprovalConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDxApprovalConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        title
        action
        field1
        field2
        field3
        field4
        field5
        acjson
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        steps {
          items {
            id
            name
            title
          }
        }
        workflow {
          items {
            id
            name
            title
            description
            wfield1
          }
          nextToken
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const _listDxWorkflows = /* GraphQL */ `
  query ListDxWorkflows(
    $id: ID
    $filter: ModelDxWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxWorkflows(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        approvalconfig {
          id
          name
          field1
          field2
          steps {
            items {
              id
              name
              title
              acsjson
              field1
              field2
              field3
              dxApprovalConfigStepsId
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const _getDxWorkflow = /* GraphQL */ `
  query GetDxWorkflow($id: ID!) {
    getDxWorkflow(id: $id) {
      id
      approvalhistory {
        nextToken
      }
      approvalitems {
        items {
          id
          name
          title
          description
        }
      }
      approvalconfig {
        id
        name
        title
        action
        field1
        field2
        field3
        field4
        field5
        acjson
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        steps {
          items {
            id
            name
            title
            acsjson
            field1
            field2
            field3
            dxApprovalConfigStepsId
          }
        }
      }
      name
      title
      description
      startdate
      duedate
      enddate
      currentlevel {
        id
        name
        title
        action
        field1
        field2
        field3
        field4
        field5
        acsjson
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
      }
      nextlevel {
        id
        name
        title
        action
        field1
        field2
        field3
        field4
        field5
        acsjson
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
      }
      wfield1
      wfield2
      wfield3
      wfield4
      wfield5
      wjson
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsWorkflowId
      dxApprovalConfigWorkflowId
      owner
    }
  }
`;
export const _getDxApprovalConfig = /* GraphQL */ `
  query GetDxApprovalConfig($id: ID!) {
    getDxApprovalConfig(id: $id) {
      id
      name
      title
      action
      approval {
        items {
          id
          name
        }
        nextToken
      }
      steps {
        items {
          id
          name
          title
          acsjson
          field1
          field2
          field3
          dxApprovalConfigStepsId
        }
        nextToken
      }
      field1
      field2
      field3
      field4
      field5
      acjson
      workflow {
        items {
          id
          name
          title
          description
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;

export const _listDxApprovals = /* GraphQL */ `
  query ListDxApprovals(
    $id: ID
    $filter: ModelDxApprovalsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxApprovals(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        afield1
        afield2
        afield3
        afield4
        afield5
        ajson
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          approvalconfig {
            id
            name
            title
            steps {
              items {
                id
                name
                title
                acsjson
              }
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const _getDxApprovals = /* GraphQL */ `
  query GetDxApprovals($id: ID!) {
    getDxApprovals(id: $id) {
      id
      name
      title
      description
      startdate
      duedate
      enddate
      assignedto
      level
      status
      resource
      resourceid
      resourceurl
      approvalhistory {
        nextToken
      }
      approvalconfig {
        id
        name
        title
        action
        field1
        field2
        field3
        field4
        field5
        acjson
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      workflow {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        approvalconfig {
          id
          name
          title
          steps {
            items {
              id
              name
              title
              acsjson
            }
          }
        }
      }
      afield1
      afield2
      afield3
      afield4
      afield5
      ajson
      author
      editor
      createdAt
      updatedAt
      userinfo
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalitemsId
      dxApprovalConfigApprovalId
      owner
    }
  }
`;
export const _listDxContracts = /* GraphQL */ `
  query ListDxContracts(
    $id: ID
    $filter: ModelDxContractFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxContracts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        policyno
        issuedate
        expirydate
        vehicleid
        renewaltype
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        subtype

        appointments {
          items {
            id
            Date
            place
            description
            appointmentstatus
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxAllocations = /* GraphQL */ `
  query ListDxAllocations(
    $id: ID
    $filter: ModelDxAllocationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxAllocations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        date
        drivername
        driver {
          id
          name
        }
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxMasterLogs = /* GraphQL */ `
  query ListDxMasterLogs(
    $filter: ModelDxMasterLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDxMasterLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        driver {
          id
          name
        }
        reimbursement
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        dxVehicleActivityLogsId
        owner
      }
      nextToken
      startedAt
    }
  }
`;

export const _listDxApprovalConfigSteps = /* GraphQL */ `
  query ListDxApprovalConfigSteps(
    $id: ID
    $filter: ModelDxApprovalConfigStepsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxApprovalConfigSteps(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        title
        action
        field1
        field2
        field3
        field4
        field5
        acsjson
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxAppointments = /* GraphQL */ `
  query ListDxAppointments(
    $id: ID
    $filter: ModelDxAppointmentsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxAppointments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        eventtype
        Date
        place
        driver {
          id
          name
        }
        spares(limit: 10000) {
          items {
            id
            supplier
            invoiceno
            invoicedoc
            partname
            quantity
            itemtype
            description
            amount
            totalamount
            status
            jfield
            vehicleid
            _version
          }
        }
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        accidenthappenedat
        accidenttype
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
// $filter: ModelDxUserRolesFilterInput
export const _listDxUserRoles = /* GraphQL */ `
  query ListDxUserRoles(
    $id: ID
    $filter: ModelDxUserRolesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxUserRoles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        username
        userrole
        usergroups
        field1
        field2
        field3
        field4
        field5
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxRolesUserrolesId
        dxUserProfileUserrolesId
        owner
        userprofile {
          id
          userid
          provideruserid
          email
          display
          picture
          displayname
          sub
          accessgroups
          attributes
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        usergroup {
          id
          rolename
          description
          application
          permissionlevel
          title
          create
          read
          update
          delete
          jfield
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const _listDxRoles = /* GraphQL */ `
  query ListDxRoles(
    $id: ID
    $filter: ModelDxRolesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxRoles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        rolename
        description
        application
        permissionlevel
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        userroles {
          items {
            id
            username
          }

          nextToken
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const getDxApprovalConfig = /* GraphQL */ `
  query GetDxApprovalConfig($id: ID!) {
    getDxApprovalConfig(id: $id) {
      id
      name
      title
      action
      approval {
        nextToken
      }
      steps {
        items {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
        }
        nextToken
      }
      field1
      field2
      field3
      field4
      field5
      acjson
      workflow {
        nextToken
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const _listDxLogVipfuels = /* GraphQL */ `
  query ListDxLogVipfuels(
    $id: ID
    $filter: ModelDxLogVipfuelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxLogVipfuels(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        vehicleid
        rid
        unitprice
        volume
        total
        productname
        receiptno
        odometer
        idunitcode
        stationname
        stationcode
        fleetname
        groupname
        vehiclecode
        citycode
        costcenter
        vatrate
        vatamount
        actualamount
        driverid
        drivername
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsVipfuelId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxLogFines = /* GraphQL */ `
  query ListDxLogFines(
    $id: ID
    $filter: ModelDxLogFinesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxLogFines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        vehicleid
        driverid
        drivername
        amount
        innovationfee
        totalamount
        source
        finenumber
        blackpoints
        reason
        location
        type
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsFinesId
        log {
          driver {
            id
            name
          }
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxLogSaliks = /* GraphQL */ `
  query ListDxLogSaliks(
    $id: ID
    $filter: ModelDxLogSalikFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxLogSaliks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionid
        date
        transactiondate
        tollgate
        direction
        tagnumber
        vehicleid
        driverid
        drivername
        amount
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsSalikId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxlogMileages = /* GraphQL */ `
  query ListDxlogMileages(
    $id: ID
    $filter: ModelDxlogMileageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxlogMileages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        drivername
        date
        transactiondate
        startKM
        endKM
        inshifttime
        outshifttime
        totalshifttime
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        enddate
        duration
        avgspeed
        maxspeed
        log {
          id
          vehicleid
        }
        drivershift {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid

          driver {
            id
            name
          }
          dxAllocationsDrivershiftsId
          shift {
            id
            shift
            starttime
            endtime
          }
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxdriverShiftsDrivershiftId
        dxMasterLogsMileagesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const _listDxMileages = /* GraphQL */ `
  query ListDxMileages(
    $tablename: String
    $sortstartdatetimeDriverid: ModeldxMileagePrimaryCompositeKeyConditionInput
    $filter: ModelDxMileageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxMileages(
      tablename: $tablename
      sortstartdatetimeDriverid: $sortstartdatetimeDriverid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        drivername
        date
        type
        startedtime
        endedtime
        inshifttime
        outshifttime
        totalshifttime
        transactiondate
        startKM
        endKM
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        sortstartdatetime
        tablename
        enddate
        duration
        avgspeed
        maxspeed
        drivershift {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt

          driver {
            id
            name
          }
          shift {
            id
            shift
            starttime
            endtime
          }
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxdriverShiftsMileagereportId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const _listDxMileageReports = /* GraphQL */ `
  query ListDxMileageReports(
    $startdate: AWSDateTime
    $enddateDriveridStartdatetimeEnddatetime: ModeldxMileageReportPrimaryCompositeKeyConditionInput
    $filter: ModelDxMileageReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxMileageReports(
      startdate: $startdate
      enddateDriveridStartdatetimeEnddatetime: $enddateDriveridStartdatetimeEnddatetime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        drivername
        date
        transactiondate
        startKM
        endKM
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        enddate
        duration
        avgspeed
        maxspeed
        drivershift {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid

          driver {
            id
            name
          }
          dxAllocationsDrivershiftsId
          shift {
            id
            shift
            starttime
            endtime
          }
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxdriverShiftsMileagedrivershiftId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const _listDxLogParkings = /* GraphQL */ `
  query ListDxLogParkings(
    $id: ID
    $filter: ModelDxLogParkingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxLogParkings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        transactiondate
        vehicleid
        driverid
        drivername
        amount
        innovationfee
        totalamount
        author
        editor
        company
        expirydate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsParkingsId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxlogReimbursements = /* GraphQL */ `
  query ListDxlogReimbursements(
    $id: ID
    $filter: ModelDxlogReimbursementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxlogReimbursements(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        driverid
        vehicle {
          id
        }
        driver {
          id
          name
        }
        date
        transactiontype
        invoiceno
        paidto
        Vendorname
        description
        remarks
        split
        amount
        expensetype
        isreimbursed
        approver
        reimbursedamount
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverLogreimbursementId
        dxVehicleLogreimbursementId
        dxMasterLogsLogreimbursementId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxVehicleSpares = /* GraphQL */ `
  query ListDxVehicleSpares(
    $id: ID
    $filter: ModelDxVehicleSparesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxVehicleSpares(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        driverid
        supplier
        appointment {
          id
          eventtype
          Date
          place
          description
          invoiceno
          _version
        }
        invoiceno
        invoicedoc
        partname
        itemtype
        quantity
        description
        amount
        totalamount
        approvedby
        status
        appointmentid
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxAppointmentsSparesId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxNavigations = /* GraphQL */ `
  query ListDxNavigations(
    $filter: ModelDxNavigationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDxNavigations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        item
        order
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const _listDxdriverShifts = /* GraphQL */ `
  query ListDxdriverShifts(
    $id: ID
    $filter: ModelDxdriverShiftsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxdriverShifts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        driver {
          id
          name
          firstname
          lastname
          _version
          _deleted
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          _version
          _deleted
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate

          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          jfield
          userinfo
          _version
          _deleted
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
