import { API } from 'aws-amplify';

import { useLocation, useNavigate } from 'react-router-dom';
import { listDxDocuments } from '../../graphql/queries';
import { Storage } from 'aws-amplify';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { DataView } from 'primereact/dataview';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import excel from '../../assets/excel.jpg';
import Pdf from '../../assets/Pdf.jpg';
import ppt from '../../assets/ppt.jpg';
import word from '../../assets/word.jpg';
import './Vehicleform.css';
import rtf from '../../assets/rtf.jpg';
const ViewAllocationAttachment = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const [displayDocuments, setDisplayDocuments] = useState([]);
    const urlParams = new URLSearchParams(location.search);
    var type = urlParams.has('type') ? urlParams.get('type') : null;
    var allid = urlParams.has('id') ? urlParams.get('id') : null;
    var vid = urlParams.has('vid') ? urlParams.get('vid') : null;
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    useEffect(() => {
        getDxDocs();
    }, []);
    const getDxDocs = async () => {
        let docsfilter = {
            resourceid: {
                eq: allid
            },
            field1: {
                eq: type
            }
        };
        let GetResponse: any = await API.graphql({ query: listDxDocuments, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: docsfilter } });
        console.log(GetResponse.data.listDxDocuments.items);
        getFileLocation(GetResponse.data.listDxDocuments.items);
    };
    const Navigation = () => navigate(`/manage/vehicles/item/allocations?id=${vid}&mode=${formmode}`);
    const getFileLocation = async (e: any) => {
        console.log(e);

        let FinalData: any = [];

        for (let i = 0; i < e.length; i++) {
            let url = await Storage.get(e[i].name);
            console.log(url);
            console.log(e[i]);
            FinalData.push({
                name: e[i].name.split('/').pop(),
                documents: url,
                path: e[i].name,
                type: e[i].name.includes('allocations') ? 'Allocation' : e[i].name.includes('dealocations') ? 'DeAllocation' : ''
            });
            console.log(FinalData);
            //"deallocations/2023/F5678/picture/Ford (3) (1).jpeg"
        }
        let filterfinaaldata: any = [];
        let removeduplicates: any = [];
        filterfinaaldata = FinalData.filter((fil: any, index: any) => fil.name !== '');

        removeduplicates = filterfinaaldata.filter((item: any, index: any) => filterfinaaldata.indexOf(item) === index);

        setDisplayDocuments(removeduplicates);
    };
    const AttachmentBodyTemplate = (e: any) => {
        return (
            <a href={e.documents} target="_self" className="">
                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
            </a>
        );
    };
    const itemTemplate = (product: any) => {
        console.log(product);
        let extension = product.name.split('.').pop();
        console.log(extension);
        let imagesrc =
            extension == 'pdf' ? Pdf : extension == 'xlsx' ? excel : extension == 'rtf' ? rtf : extension == 'pptx' ? ppt : extension == 'docx' ? word : `https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/${product.path}`;
        console.log(imagesrc);
        return (
            // <div className="col-12">
            //     <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
            //         <img
            //             className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
            //             src={`https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/allocations/2023/Q71674/documents/DEN - Leave Policy- 30th April 2022.pdf`}
            //             alt={product.name}
            //         />
            //         <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            //             <div className="flex flex-column align-items-center sm:align-items-start gap-3">
            //                 <div className="text-m  text-900">{product.name}</div>
            //                 {/* <Rating value={product.rating} readOnly cancel={false}></Rating>
            //                   <div className="flex align-items-center gap-3">
            //                       <span className="flex align-items-center gap-2">
            //                           <i className="pi pi-tag"></i>
            //                           <span className="font-semibold">{product.category}</span>
            //                       </span>
            //                       <Tag value={product.inventoryStatus} severity={getSeverity(product)}></Tag>
            //                   </div> */}
            //             </div>
            //             <a href={product.documents} target="_blank" className="">
            //                 <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
            //             </a>
            //         </div>
            //     </div>
            // </div>
            <>
                <div className="col-12 sm:col-4 lg:col-4 xl:col-3 p-2">
                    <div className="p-4 border-1 surface-border surface-card border-round">
                        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                            <div className="flex align-items-center gap-2">
                                <i className="pi pi-tag"></i>
                                <span className="font-semibold">{product.type}</span>
                            </div>
                            <a href={product.documents} target="_self" className="">
                                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
                            </a>
                            {/* <Tag value={product.inventoryStatus}></Tag> */}
                        </div>
                        <div className="flex flex-column align-items-center gap-3 py-5">
                            {/* <img className="w-9 shadow-2 border-round" src={`https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/${product.path}`} alt={product.name} /> */}
                            <img className="w-9 border-round" src={imagesrc} style={{ height: '150px', objectFit: 'contain' }} />
                            <div className="text-l font-bold DocTitle">{product.name}</div>
                            {/* <Rating value={product.rating}></Rating> */}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {' '}
            <div className="grid">
                <div className="col-12 md:col-12">
                    <h4 className="container-header">
                        View {type} : {vid}
                    </h4>
                    <div className="card">
                        {/* <h5>{type?.toLocaleUpperCase()}</h5> */}
                        <Button className="mr-2 mb-2 p-button-warn" onClick={Navigation}>
                            <i className="pi pi-arrow-left" style={{ fontSize: '1rem' }}></i>
                        </Button>
                        {/* <DataTable
                            value={displayDocuments}
                            dataKey="id"
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} contracts"
                            emptyMessage="No Documents added."
                            responsiveLayout="scroll"
                        >
                           
                            <Column field="documents" header="Attachment" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        </DataTable> */}
                        <DataView value={displayDocuments} itemTemplate={itemTemplate} paginator rows={5} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default ViewAllocationAttachment;
