import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';

function Loader(props: any) {
    const [displayDialog, setdisplayDialog] = useState(false);
    const overlay = useRef<any>(null);
    return (
        <Dialog visible={props.displayDialog} style={{ width: '30vw', height: '15vw' }} modal onHide={() => setdisplayDialog(false)} closable={false}>
            <div className="flex justify-content-center">

                <div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 500 }}>Saving Please wait!</div>
                    <div>
                        {' '}
                        <ProgressSpinner />
                    </div>
                </div>

            </div>
        </Dialog>
    );
}
export default Loader;
