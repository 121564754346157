import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import './../Reports/Reports.css';
import dxService from '../../Services/dxService';
import { CSVLink } from 'react-csv';
import { convertdate, getdriveroptions, localetoisodatetime } from '../../utils/helper';
import { useEffect, useState, memo, useId } from 'react';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { useLocation } from 'react-router-dom';
import { getTypeColors } from '../../utils/color';

const NewDriverUsage = () => {
    const colors = getTypeColors('light');
    const [Reports, setReports] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [SelectedDate, setSelectedDate] = useState<any>([]);
    const [filterdriverid, setfilterdriverid] = useState<any>('');
    const [driveid, setdriveid] = useState<any>('');
    const [startDateFormat, setStartDateFormat] = useState<string>('');
    const [endDateFormat, setEndDateFormat] = useState<string>('');
    const [formatdata, setformatdata] = useState<any>([]);
    const [oldformatdata, setoldformdata] = useState<any>([]);
    const [totalfuel, settotalfuel] = useState<any>(0);
    const [totalsaik, settotalsalik] = useState<any>(0);
    const [totalfine, settotalfine] = useState<any>(0);
    const [totalReimbursement, setTotalReimbursement] = useState<any>(0);
    const [exportType, setExportType] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [salikexpandedRows, setSalikExpandedRows] = useState<any>([]);
    const [transformedData, setTransformedData] = useState<any>([]);
    const [selectView, setSelectView] = useState<any>('Summary');
    const [drivers, setDrivers] = useState<any>([]);
    const [selectedDriver, setSelectedDriver] = useState<any>('');
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var sdate: any = urlParams.has('sdate') ? urlParams.get('sdate') : '';
    var edate: any = urlParams.has('edate') ? urlParams.get('edate') : '';
    var view: any = urlParams.has('view') ? urlParams.get('view') : '';
    var driver: any = urlParams.has('driver') ? urlParams.get('driver') : '';
    //get the params value and set it to the state
    useEffect(() => {
        const getParamsDetails = () => {
            if (view !== '') {
                setSelectView(view);
            } else {
                setSelectView('Summary');
            }
            if (driver !== '') {
                setSelectedDriver(driver);
            }
            if (sdate !== '') {
                setTimeout(() => {
                    sdate = urlParams.has('sdate') ? urlParams.get('sdate') : '';
                    edate = urlParams.has('edate') ? urlParams.get('edate') : '';
                    let startDate = new Date(sdate);
                    let endDate = edate !== '' ? new Date(edate) : startDate;
                    setSelectedDate([startDate, endDate]);
                }, 1000);
            } else {
                let todate = new Date();
                let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
                let lastfirstdate = new Date(todate.getFullYear(), todate.getMonth() - 1, 1);
                setSelectedDate([lastfirstdate, lastenddate]);
            }
        };
        getParamsDetails();
    }, []);
    useEffect(() => {
        getVehicle();
    }, []);
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            await getData();
        };

        setTimeout(() => {
            fetchData();
        }, 1500);
        updateURL(selectView, SelectedDate[0], SelectedDate[1], selectedDriver);
    }, [SelectedDate, selectedDriver]);
    const getVehicle = () => {
        //get the driver dropdown options
        getdriveroptions().then((options) => {
            setDrivers(options);
        });
    };

    const getData = async () => {
        let tempData: any[] = [];
        let deptcolumns = `id fleettype name description _deleted
        vehicles{
            items{
                id
                _deleted
                masterlogs(filter: {
                date:{
                        ge: "${localetoisodatetime(SelectedDate.length > 0 ? SelectedDate[0] : SelectedDate)}",
                        le: "${localetoisodatetime(SelectedDate.length > 0 && SelectedDate[1] !== null ? SelectedDate[1] : SelectedDate[0])}"
                    },
                       
                }, sortDirection: DESC, limit:100000){
                    items{
                        id
                        Fine
                        Salik
                        Vipfuel
                        date
                        vehicleid
                        drivername
                        dxDriverMasterlogsId
                        reimbursement
                        driver{
                            id
                            name
                        }
                        _deleted
                    }
                    nextToken
                }
            }            
        }`;

        let b = await dxService.listitems('department', deptcolumns, {}, false);
        let a = b.filter((del: any) => del._deleted !== true);
        let filteredDepartments = [];
        if (selectedDriver) {
            filteredDepartments = a
                .map((department: { vehicles: { items: any[]; nextToken: any } }) => {
                    const filteredVehicles = department.vehicles.items
                        .map((vehicle: { masterlogs: { items: any[]; nextToken: any } }) => {
                            const filteredMasterLogs = vehicle.masterlogs.items.filter((log: { driver: { id: any } }) => log.driver && log.driver.id === selectedDriver);
                            return filteredMasterLogs.length > 0 ? { ...vehicle, masterlogs: { items: filteredMasterLogs, nextToken: vehicle.masterlogs.nextToken } } : null;
                        })
                        .filter(Boolean);
                    return filteredVehicles.length > 0 ? { ...department, vehicles: { items: filteredVehicles, nextToken: department.vehicles.nextToken } } : null;
                })
                .filter(Boolean);
        } else {
            filteredDepartments = a;
        }

        let totalfuel = 0;
        let totalsalik = 0;
        let totalfines = 0;
        let totalreimbursement = 0;
        let allvalues: { department: any; drivers: any; salik: number; fuel: number; fine: number; reimbursement: number }[] = [];
        setformatdata(filteredDepartments);

        //get the total amount of each logs
        filteredDepartments.forEach((dept: any) => {
            let dsalik = 0;
            let dfuel = 0;
            let dfine = 0;
            let dreimbursement = 0;
            let driverItems = dept.vehicles.items;

            driverItems
                .filter((del: any) => del._deleted !== true)
                .forEach((driver: any) => {
                    let masterlogs = driver.masterlogs.items;
                    masterlogs
                        .filter((del: any) => del._deleted !== true)
                        .forEach((masterlog: any) => {
                            dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                            dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                            dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
                            dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
                        });
                });
            totalfuel += dfuel;
            totalsalik += dsalik;
            totalfines += dfine;
            totalreimbursement += dreimbursement;
            allvalues.push({
                department: dept.name,
                drivers: driverItems.length,
                salik: dsalik,
                fuel: dfuel,
                fine: dfine,
                reimbursement: dreimbursement
            });
        });

        settotalfuel(totalfuel);
        settotalsalik(totalsalik);
        settotalfine(totalfines);
        setTotalReimbursement(totalreimbursement);
        // setformatdata(allvalues);
        setoldformdata(allvalues);

        //getting the data for showing all details
        filteredDepartments.forEach((department: any) => {
            department.vehicles.items.forEach((driver: any) => {
                // const fuelLogs = driver.masterlogs.items.filter((log: any) => log.Vipfuel !== null);
                // const salikLogs = driver.masterlogs.items.filter((log: any) => log.Salik !== null);
                // const fineLogs = driver.masterlogs.items.filter((log: any) => log.Fine !== null);
                // const totalFuelAmount = fuelLogs.reduce((total: any, log: any) => {
                //     const fuelValue = parseFloat(log.Vipfuel);
                //     return isNaN(fuelValue) ? total : total + fuelValue;
                // }, 0);
                // const totalSalikAmount = salikLogs.reduce((total: any, log: any) => {
                //     const salikValue = parseFloat(log.Salik);
                //     return isNaN(salikValue) ? total : total + salikValue;
                // }, 0);
                // const totalFineAmount = fineLogs.reduce((total: any, log: any) => {
                //     const fineValue = parseFloat(log.Fine);
                //     return isNaN(fineValue) ? total : total + fineValue;
                // }, 0);
                // const fuelUsageValue = (totalFuelAmount / totalfuel) * 100;
                // const fuelPercentage = totalfuel === 0 || isNaN(fuelUsageValue) ? '0%' : `${fuelUsageValue.toFixed(2)}%`;
                // const salikUsageValue = (totalSalikAmount / totalsalik) * 100;
                // const salikPercentage = totalsalik === 0 || isNaN(salikUsageValue) ? '0%' : `${salikUsageValue.toFixed(2)}%`;
                // const fineUsageValue = (totalFineAmount / totalfines) * 100;
                // const finePercentage = totalfines === 0 || isNaN(fineUsageValue) ? '0%' : `${fineUsageValue.toFixed(2)}%`;
                // tempData.push({
                //     department: department.name,
                //     driver: driver.masterlogs.items.length > 0 && driver.masterlogs.items[0].driver ? driver.masterlogs.items[0].driver.name : '-',
                //     totalFuelAmount: 'AED ' + totalFuelAmount.toFixed(2),
                //     fuelPercentage,
                //     totalSalikAmount: 'AED ' + totalSalikAmount.toFixed(2),
                //     salikPercentage,
                //     totalFineAmount: 'AED ' + totalFineAmount.toFixed(2),
                //     finePercentage
                // });
                if (driver.masterlogs.items.length > 0) {
                    const driverNames: any = [];

                    driver.masterlogs.items.forEach((item: any) => {
                        const driverName = item.driver ? item.driver.name : '';
                        if (!driverNames.includes(driverName)) {
                            driverNames.push(driverName);
                        }
                    });
                    for (const driverName of driverNames) {
                        let totalFuel = 0;
                        let totalSalik = 0;
                        let totalFine = 0;
                        let totalReimbursement = 0;
                        let vehicleid = '';

                        driver.masterlogs.items.forEach((item: any) => {
                            if (item.driver && item.driver.name === driverName) {
                                const fuel = item.Vipfuel ? parseFloat(item.Vipfuel) : 0;
                                const salik = item.Salik ? parseFloat(item.Salik) : 0;
                                const fine = item.Fine ? parseFloat(item.Fine) : 0;
                                const reimbursement = item.reimbursement ? parseFloat(item.reimbursement) : 0;
                                vehicleid = item.vehicleid;
                                totalFuel += fuel;
                                totalSalik += salik;
                                totalFine += fine;
                                totalReimbursement += reimbursement;
                            }
                        });

                        tempData.push({
                            department: department.name,
                            driver: driverName,
                            vehicleid,
                            totalFuelAmount: 'AED ' + totalFuel.toFixed(2),
                            fuelPercentage: totalFuel ? ((totalFuel / totalfuel) * 100).toFixed(2) + '%' : '0%',
                            totalSalikAmount: 'AED ' + totalSalik.toFixed(2),
                            salikPercentage: totalSalik ? ((totalSalik / totalsalik) * 100).toFixed(2) + '%' : '0%',
                            totalFineAmount: 'AED ' + totalFine.toFixed(2),
                            finePercentage: totalFine ? ((totalFine / totalfines) * 100).toFixed(2) + '%' : '0%',
                            totalReimbursementAmount: 'AED ' + totalReimbursement.toFixed(2),
                            reimbursementPercentage: totalReimbursement ? ((totalReimbursement / totalreimbursement) * 100).toFixed(2) + '%' : '0%'
                        });
                    }
                }
                //  else {
                //     tempData.push({
                //         department: department.name,
                //         driver: '-',
                //         vehicleid: '-',
                //         totalFuelAmount: 'AED ' + 0,
                //         fuelPercentage: '0%',
                //         totalSalikAmount: 'AED ' + 0,
                //         salikPercentage: '0%',
                //         totalFineAmount: 'AED ' + 0,
                //         finePercentage: '0%'
                //     });
                // }
            });
        });

        setTransformedData(tempData);
        setLoading(false);

        return filteredDepartments;
    };

    const header = [
        { label: 'Department', key: 'department' },
        { label: 'No. of Drivers', key: 'drivers' },
        { label: 'Total Amount', key: 'fueld' }
    ];
    useEffect(() => {
        //set the selecteddate in the state from the selected calendar
        const startDate = SelectedDate[0];
        const endDate = SelectedDate[1];
        const startDateFormat = startDate ? moment(startDate).format('DD MMM YY') : '';
        const endDateFormat = endDate ? moment(endDate).format('DD MMM YY') : '';

        setStartDateFormat(startDateFormat);
        setEndDateFormat(endDateFormat);
    }, [SelectedDate]);

    const ClearBtn = () => {
        setSelectedDate('');
        // dateformat = [];
        setStartDateFormat('');
        setEndDateFormat('');
        //   dateformat = 0;
        updateURL(selectView, '', '', selectedDriver);
    };

    if (SelectedDate !== null && SelectedDate.length > 0) {
        var ShowClrBtn: any = (
            <>
                <button
                    onClick={ClearBtn}
                    className={`clrbtn`}
                    // style={{ display: toggle, position: "relative", bottom: "5px" }}
                >
                    <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" style={{ color: '#db0000', fontWeight: 700 }}></i>
                    &nbsp;
                </button>
            </>
        );
    }

    //get total amount for each logs items
    const actionfloattemplate = (rowData: any, column: any) => {
        let totalfuel = 0;
        let totalsalik = 0;
        let totalfine = 0;
        let totalreimbursement = 0;

        let dsalik = 0;
        let dfuel = 0;
        let dfine = 0;
        let dreimbursement = 0;

        let driveritems = rowData.vehicles.items;

        driveritems &&
            driveritems.forEach((driver: any) => {
                let masterlogs = driver.masterlogs.items;
                masterlogs.forEach((masterlog: any) => {
                    dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                    dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                    dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
                    dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
                });
            });
        totalfuel += dfuel;
        totalsalik += dsalik;
        totalfine += dfine;
        totalreimbursement += dreimbursement;

        let num: any = column.field === 'salik' ? totalsalik : column.field === 'fuel' ? totalfuel : column.field === 'fine' ? totalfine : totalreimbursement;
        // Check if the input is a number and has more than two decimal places

        if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
            // Round off to two decimal places
            num = Number(Number(num).toFixed(2));
        }
        return (
            <div
                className={`p-button ${column.column.props.children}`}
                style={{
                    width: '-webkit-fill-available',
                    justifyContent: 'flex-end',
                    background: column.column.props.children,
                    color: '#000'
                }}
            >
                {num !== null ? 'AED ' + num : '-'}
            </div>
        );
    };

    //get the total number of children of the log items
    const actionsubfloattemplate = (rowData: any, column: any) => {
        let dsalik = 0;
        let dfuel = 0;
        let dfine = 0;
        let dreimbursement = 0;

        let masterlogs = rowData.masterlogs.items;
        masterlogs &&
            masterlogs.forEach((masterlog: any) => {
                dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
                dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
            });
        let num: any = column.field === 'salik' ? dsalik : column.field === 'fuel' ? dfuel : column.field === 'fine' ? dfine : dreimbursement;
        // Check if the input is a number and has more than two decimal places
        if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
            // Round off to two decimal places
            num = Number(Number(num).toFixed(2));
        }
        return <>{num !== null ? 'AED ' + num : '-'}</>;
    };

    // calculate the percentage of the logs items
    const usagetemplate = (rowData: any, column: any) => {
        let dsalik = 0;
        let dfuel = 0;
        let dfine = 0;
        let dreimbursement = 0;

        let driveritems = rowData.vehicles.items;

        driveritems &&
            driveritems.forEach((driver: any) => {
                let masterlogs = driver.masterlogs.items;
                masterlogs.forEach((masterlog: any) => {
                    dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                    dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                    dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
                    dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
                });
            });

        let num: any = column.field === 'salik' ? dsalik : column.field === 'fuel' ? dfuel : column.field === 'fine' ? dfine : dreimbursement;
        const usagevalue = column.field === 'fuel' ? (num / totalfuel) * 100 : column.field === 'salik' ? (num / totalsaik) * 100 : column.field === 'fine' ? (num / totalfine) * 100 : (num / totalReimbursement) * 100;
        const formattedValue = isNaN(usagevalue) ? '0%' : `${usagevalue.toFixed(2)}%`;
        // accumulate usage values
        // accumulate usage values

        // setTotalFuelUsage(fuelusage);
        // setTotalSalikUsage(salikusage);
        return (
            <div
                className={`p-button ${column.column.props.children}`}
                style={{
                    width: '-webkit-fill-available',
                    justifyContent: 'flex-end',
                    background: column.column.props.children,
                    color: '#000'
                }}
            >
                {formattedValue}
            </div>
        );
    };
    //calculate the percentage of the log items for expanded row
    const subusagetemplate = (rowData: any, column: any) => {
        let dsalik = 0;
        let dfuel = 0;
        let dfine = 0;
        let dreimbursement = 0;

        let masterlogs = rowData.masterlogs.items;
        masterlogs.forEach((masterlog: any) => {
            dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
            dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
            dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
            dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
        });

        let num: any = column.field === 'salik' ? dsalik : column.field === 'fuel' ? dfuel : column.field === 'fine' ? dfine : dreimbursement;
        const usagevalue = column.field === 'fuel' ? (num / totalfuel) * 100 : column.field === 'salik' ? (num / totalsaik) * 100 : column.field === 'fine' ? (num / totalfine) * 100 : (num / totalReimbursement) * 100;
        const formattedValue = isNaN(usagevalue) ? '0%' : `${usagevalue.toFixed(2)}%`;
        // accumulate usage values
        // accumulate usage values

        // setTotalFuelUsage(fuelusage);
        // setTotalSalikUsage(salikusage);
        return <>{formattedValue}</>;
    };

    const totalSalikUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.salik, 0);
    const totalFuelUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.fuel, 0);
    const totalFineUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.fine, 0);
    const totalReimbursementUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.reimbursement, 0);
    const totalDrivers = oldformatdata.reduce((acc: any, cur: any) => acc + cur.drivers, 0);

    const exportdata = (data: any, type: any) => {
        let items: any = [];
        data.map((exportitem: any) => {
            let saliknum: any = exportitem.salik;
            let fuelnum: any = exportitem.fuel;
            let finenum: any = exportitem.fine;
            let reimbusementnum: any = exportitem.reimbursement;
            // Check if the input is a number and has more than two decimal places
            if (saliknum !== null && !isNaN(saliknum) && /\.\d{3,}/.test(saliknum.toString())) {
                // Round off to two decimal places
                saliknum = Number(Number(saliknum).toFixed(2));
            }
            if (fuelnum !== null && !isNaN(fuelnum) && /\.\d{3,}/.test(fuelnum.toString())) {
                // Round off to two decimal places
                fuelnum = Number(Number(fuelnum).toFixed(2));
            }
            if (finenum !== null && !isNaN(finenum) && /\.\d{3,}/.test(finenum.toString())) {
                // Round off to two decimal places
                finenum = Number(Number(finenum).toFixed(2));
            }
            if (reimbusementnum !== null && !isNaN(reimbusementnum) && /\.\d{3,}/.test(reimbusementnum.toString())) {
                // Round off to two decimal places
                reimbusementnum = Number(Number(reimbusementnum).toFixed(2));
            }
            //% usage
            const salikusagevalue = (saliknum / totalsaik) * 100;
            const salikformattedValue = isNaN(salikusagevalue) ? '0' : `${salikusagevalue.toFixed(2)}%`;
            const fuelusagevalue = (fuelnum / totalfuel) * 100;
            const fuelformattedValue = isNaN(fuelusagevalue) ? '0' : `${fuelusagevalue.toFixed(2)}%`;
            const fineusagevalue = (finenum / totalfine) * 100;
            const fineformattedValue = isNaN(fineusagevalue) ? '0' : `${fineusagevalue.toFixed(2)}%`;
            const reimburseusagevalue = (reimbusementnum / totalReimbursement) * 100;
            const reimburseformattedValue = isNaN(reimburseusagevalue) ? '0' : `${reimburseusagevalue.toFixed(2)}%`;
            let a = {
                Department: exportitem.department,
                'Total No. of Drivers': exportitem.drivers,
                'Total Fuel Amount': 'AED ' + fuelnum,
                'Fuel % in usage': fuelformattedValue,
                'Total Salik Amount': 'AED ' + saliknum,
                'Salik % in usage': salikformattedValue,
                'Total Fine Amount': 'AED ' + finenum,
                'Fine % in usage': fineformattedValue,
                'Total Reinbursement Amount': 'AED ' + reimbusementnum,
                'Reimbursement % in usage': reimburseformattedValue
            };
            items.push(a);
        });
        // Add footer row
        let footer = {
            Department: 'Total',
            'Total No. of Drivers': totalDrivers,
            'Total Fuel Amount': totalfuel,
            'Fuel % in usage': totalFuelUsage === 0 ? '0%' : `${((100 * totalfuel) / totalFuelUsage).toFixed(2)}%`,
            'Total Salik Amount': totalsaik,
            'Salik % in usage': totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`,
            'Total Fine Amount': totalfine,
            'Fine % in usage': totalFineUsage === 0 ? '0%' : `${((100 * totalfine) / totalFineUsage).toFixed(2)}%`,
            'Total Reinbursement Amount': totalReimbursement,
            'Reimbursement % in usage': totalReimbursementUsage === 0 ? '0%' : `${((100 * totalReimbursement) / totalReimbursementUsage).toFixed(2)}%`
        };

        items.push(footer);
        return items;
    };
    const exportdetaileddata = (data: any, type: any) => {
        let items: any = [];
        data.forEach((item: any) => {
            // let driveritems = item.vehicles.items;
            // driveritems &&
            //     driveritems.forEach((driver: any) => {
            //         let dsalik = 0;
            //         let dfuel = 0;
            //         let dfine = 0;

            //         let masterlogs = driver.masterlogs.items;
            //         masterlogs.forEach((masterlog: any) => {
            //             dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
            //             dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
            //             dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
            //         });
            //         let saliknum: any = dsalik;
            //         const salikusagevalue = (saliknum / totalsaik) * 100;
            //         const salikformattedValue = isNaN(salikusagevalue) ? '0' : `${salikusagevalue.toFixed(2)}%`;
            //         let fuelnum: any = dfuel;
            //         const fuelusagevalue = (fuelnum / totalfuel) * 100;
            //         const fuelformattedValue = isNaN(fuelusagevalue) ? '0' : `${fuelusagevalue.toFixed(2)}%`;
            //         let finenum: any = dfine;
            //         const fineusagevalue = (finenum / totalfine) * 100;
            //         const fineformattedValue = isNaN(fineusagevalue) ? '0' : `${fineusagevalue.toFixed(2)}%`;
            let a = {
                Department: item.department,
                Driver: item.driver,
                Vehicle: item.vehicleid,
                'Total Fuel Amount': item.totalFuelAmount,
                'Fuel % in Usage': item.fuelPercentage,
                'Total Salik Amount': item.totalSalikAmount,
                'Salik % in Usage': item.salikPercentage,
                'Total Fine Amount': item.totalFineAmount,
                'Fine % in Usage': item.finePercentage,
                'Total Reimbursement Amount': item.totalReimbursementAmount,
                'Reimbursement % in Usage': item.reimbursementPercentage
            };
            items.push(a);
            // });
        });
        // Add footer row
        let footer = {
            Department: 'Total',
            Driver: '',
            Vehicle: '',
            'Total Fuel Amount': 'AED ' + totalfuel,
            'Fuel % in Usage': totalFuelUsage === 0 ? '0%' : `${((100 * totalfuel) / totalFuelUsage).toFixed(2)}%`,
            'Total Salik Amount': 'AED ' + totalsaik,
            'Salik % in Usage': totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`,
            'Total Fine Amount': 'AED ' + totalfine,
            'Fine % in Usage': totalFineUsage === 0 ? '0%' : `${((100 * totalfine) / totalFineUsage).toFixed(2)}%`,
            'Total Reimbursement Amount': 'AED ' + totalReimbursement,
            'Reimbursement % in Usage': totalReimbursementUsage === 0 ? '0%' : `${((100 * totalReimbursement) / totalReimbursementUsage).toFixed(2)}%`
        };

        items.push(footer);
        return items;
    };

    const csvLink = {
        filename:
            SelectedDate.length > 0 && startDateFormat && endDateFormat
                ? `Consumption_Drivers_usage_${selectView}_${startDateFormat}-${endDateFormat}` + (driveid !== '' ? `_${driveid}` : '') + (filterdriverid !== '' ? `_${filterdriverid}` : '')
                : 'Comsumption_Drivers_usage' + `_${selectView}` + (driveid !== '' ? `_${driveid}` : '') + (filterdriverid !== '' ? `_${filterdriverid}` : '') + '.csv',
        header: header,
        data: oldformatdata.length > 0 ? exportdata(oldformatdata, selectView) : Reports
    };
    const csvDetailedLink = {
        filename:
            SelectedDate.length > 0 && startDateFormat && endDateFormat
                ? `Consumption_Drivers_usage_${selectView}_${startDateFormat}-${endDateFormat}` + (driveid !== '' ? `_${driveid}` : '') + (filterdriverid !== '' ? `_${filterdriverid}` : '')
                : 'Comsumption_Drivers_usage' + `_${selectView}` + (driveid !== '' ? `_${driveid}` : '') + (filterdriverid !== '' ? `_${filterdriverid}` : '') + '.csv',
        header: header,
        // data: formatdata.length > 0 ? exportdetaileddata(formatdata, selectView) : Reports
        data: transformedData.length > 0 ? exportdetaileddata(transformedData, selectView) : Reports
    };

    const Commandbar = (
        <div className="p-toolbar p-component">
            <div className="p-toolbar-group-left">
                <Calendar
                    id="range"
                    className="dateFilter"
                    value={SelectedDate}
                    dateFormat="dd/m/yy"
                    onChange={(e: any) => {
                        updateURL(selectView, e.value[0], e.value[1], selectedDriver);
                        setSelectedDate(e.value);
                    }}
                    selectionMode="range"
                    readOnlyInput
                    placeholder="Select date"
                />

                {ShowClrBtn}
            </div>
            <Dropdown
                value={selectView}
                style={{ width: '200px' }}
                className="mr-2"
                placeholder="Select an option"
                options={[
                    { label: 'Grouped by Department', value: 'Summary' },
                    { label: 'Show All Details', value: 'Details' }
                ]}
                onChange={(e) => {
                    updateURL(e.value, SelectedDate[0], SelectedDate[1], selectedDriver);
                    setSelectView(e.value);
                }}
                optionLabel="label"
            />
            <Dropdown
                value={selectedDriver}
                // style={{ width: '200px' }}
                showClear
                placeholder="Driver"
                options={drivers}
                onChange={(e) => {
                    updateURL(selectView, SelectedDate[0], SelectedDate[1], e.value);
                    setSelectedDriver(e.value);
                }}
                optionLabel="label"
                filter
            />
        </div>
    );
    const actionCount = (rowData: any) => {
        return <>{rowData.vehicles.items.length}</>;
    };

    const actionRowClassName = (rowData: any) => {
        const colors = ['spd-success', 'spd-info', 'spd-warn', 'spd-error', 'spd-secondary', 'spd-primary', 'spd-light', 'spd-dark']; // array of different background colors
        const randomIndex = Math.floor(Math.random() * colors.length); // generate a random index
        return colors[randomIndex]; // return a random background color for each row
    };
    const rowExpansionTemplate = (data: any) => {
        return (
            <div className="orders-subtable">
                <DataTable
                    value={data.vehicles.items}
                    responsiveLayout="scroll"
                    className="p-datatable-responsive usagetable"
                    header={
                        <div className="p-toolbar p-component">
                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                <h5>Details for {data.name}</h5>
                            </div>
                            {/* <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                <CSVLink {...csvLink} onClick={() => setExportType(data.name)}>
                                    <span className="p-float-label exporttext text-white">Export {data.name}</span>
                                </CSVLink>
                            </div> */}
                        </div>
                    }
                    // rowClassName={actionRowClassName}
                >
                    {/* <Column
                        field="name"
                        className="text-left p-message p-message-warn"
                        header="Driver"
                        body={(e: any) => {
                            let dname = e.masterlogs.items.length > 0 && e.masterlogs.items[0].driver ? e.masterlogs.items[0].driver.name : '-';
                            return <>{dname}</>;
                        }}
                    /> */}
                    <Column
                        field="vehicle"
                        className="text-left p-message p-message-warn"
                        header="Vehicle"
                        body={(e: any) => {
                            let vehicle = e.masterlogs.items.length > 0 ? e.masterlogs.items[0].vehicleid : '-';
                            return <>{vehicle}</>;
                        }}
                    />
                    <Column
                        field="driver.name"
                        className="text-left p-message p-message-warn"
                        header="Driver"
                        body={(e: any) => {
                            let driver = e.masterlogs.items.length > 0 && e.masterlogs.items[0].driver ? e.masterlogs.items[0].driver.name : '-';
                            return <>{driver}</>;
                        }}
                    />
                    <Column field="fuel" className="text-right p-message p-message-warn" header="Total Fuel Amount" body={actionsubfloattemplate} />
                    <Column field="fuel" className="text-right p-message p-message-warn" header="Fuel % in Usage" body={subusagetemplate} />
                    <Column field="salik" className="text-right p-message p-message-warn" header="Total Salik Amount" body={actionsubfloattemplate} />
                    <Column field="salik" className="text-right p-message p-message-warn" header="Salik % in Usage" body={subusagetemplate} />
                    <Column field="fine" className="text-right p-message p-message-warn" header="Total Fine Amount" body={actionsubfloattemplate} />
                    <Column field="fine" className="text-right p-message p-message-warn" header="Fine % in Usage" body={subusagetemplate} />
                    <Column field="reimbursement" className="text-right p-message p-message-warn" header="Total Reimbusement Amount" body={actionsubfloattemplate} />
                    <Column field="reimbursement" className="text-right p-message p-message-warn" header="Reimbursement % in Usage" body={subusagetemplate} />
                </DataTable>
            </div>
        );
    };
    //set the values to the url
    const updateURL = (view: any, sdate: any, edate: any, driver: any) => {
        const baseUrl = window.location.href.split('?')[0];
        const queryParams = new URLSearchParams(window.location.search);

        if (sdate) {
            queryParams.set('sdate', convertdate(sdate));
        } else {
            queryParams.delete('sdate');
        }
        if (edate) {
            queryParams.set('edate', convertdate(edate));
        } else {
            queryParams.delete('edate');
        }
        if (view) {
            queryParams.set('view', view);
        } else {
            queryParams.delete('view');
        }
        if (driver) {
            queryParams.set('driver', driver);
        } else {
            queryParams.delete('driver');
        }

        const newUrl = baseUrl + '?' + queryParams.toString();
        window.history.replaceState({}, '', newUrl);
    };

    //memo is to load the table/component/data only when it is required
    const DataTableMemoized = memo(DataTable);

    return (
        <div className="grid ">
            <div className="col-12 md:col-12">
                {/* <h4 className="container-header">Driver Usage Report</h4> */}

                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card">
                            {selectView === 'Summary' && (
                                <DataTable
                                    value={formatdata}
                                    loading={loading}
                                    // header={Commandbar}
                                    header={
                                        <div className="p-toolbar p-component p-0">
                                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                                <h5>Summary/Grouped Report</h5>
                                            </div>
                                            <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                                {Commandbar}
                                                <CSVLink {...csvLink} onClick={() => setExportType('Grouped')}>
                                                    <span className="p-float-label exporttext text-white">Export</span>
                                                </CSVLink>
                                            </div>
                                        </div>
                                    }
                                    className="p-datatable-responsive"
                                    responsiveLayout="scroll"
                                    rows={300}
                                    selection={selectedProduct}
                                    onSelectionChange={(e) => setSelectedProduct(e.value)}
                                    rowExpansionTemplate={rowExpansionTemplate}
                                    expandedRows={expandedRows}
                                    onRowToggle={(e) => setExpandedRows(e.data)}
                                >
                                    <Column expander style={{ width: '3em' }} />
                                    <Column field="name" className="text-left" headerStyle={{ minWidth: '90px' }} sortable header="Department" footer="Total" />
                                    <Column header="Total no. of Drivers" headerStyle={{ minWidth: '110px' }} className="text-left" body={actionCount} />
                                    <Column field="fuel" className="text-right" headerStyle={{ minWidth: '110px' }} children={`${colors.Vipfuel}`} header="Total Fuel Amount" footer={'AED ' + totalfuel.toFixed(2)} body={actionfloattemplate} />
                                    <Column
                                        field="fuel"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        children={`${colors.Vipfuel}`}
                                        header="Fuel % in Usage"
                                        body={usagetemplate}
                                        footer={totalfuel === 0 ? '0%' : `${((100 * totalfuel) / totalfuel).toFixed(2)}%`}
                                    />
                                    <Column field="salik" className="text-right" headerStyle={{ minWidth: '110px' }} children={`${colors.Salik}`} header="Total Salik Amount" body={actionfloattemplate} footer={'AED ' + totalsaik} />
                                    <Column
                                        field="salik"
                                        className="text-right"
                                        children={`${colors.Salik}`}
                                        headerStyle={{ minWidth: '100px' }}
                                        header="Salik % in Usage"
                                        body={usagetemplate}
                                        footer={totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`}
                                    />
                                    <Column field="fine" className="text-right" headerStyle={{ minWidth: '110px' }} children={`${colors.Fine}`} header="Total Fine Amount" body={actionfloattemplate} footer={'AED ' + totalfine} />
                                    <Column
                                        field="fine"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        children={`${colors.Fine}`}
                                        header="Fine % in Usage"
                                        body={usagetemplate}
                                        footer={totalFineUsage === 0 ? '0%' : `${((100 * totalfine) / totalFineUsage).toFixed(2)}%`}
                                    />
                                    <Column
                                        field="reimbursement"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        children={`${colors.CashReimbursement}`}
                                        header="Total Reimbursement Amount"
                                        body={actionfloattemplate}
                                        footer={'AED ' + totalReimbursement}
                                    />
                                    <Column
                                        field="reimbursement"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        children={`${colors.CashReimbursement}`}
                                        header="Reimbursement % in Usage"
                                        body={usagetemplate}
                                        footer={totalReimbursementUsage === 0 ? '0%' : `${((100 * totalReimbursement) / totalReimbursementUsage).toFixed(2)}%`}
                                    />
                                </DataTable>
                            )}

                            {selectView === 'Details' && (
                                <DataTableMemoized
                                    value={transformedData}
                                    loading={loading}
                                    className="p-datatable-responsive"
                                    header={
                                        <div className="p-toolbar p-component p-0">
                                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                                <h5>All Details Report</h5>
                                            </div>
                                            <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                                {Commandbar}
                                                {/* <CSVLink {...csvLink} onClick={() => setExportType('report')}>
                                                <span className="p-float-label exporttext text-white">Export</span>
                                            </CSVLink> */}
                                                <CSVLink {...csvDetailedLink} onClick={() => setExportType('All_Details')}>
                                                    <span className="p-float-label exporttext text-white">Export Detailed Data</span>
                                                </CSVLink>
                                            </div>
                                        </div>
                                    }
                                    // rowGroupFooterTemplate={footerTemplate}
                                    responsiveLayout="scroll"
                                    scrollable
                                    scrollHeight="80vh"
                                >
                                    <Column field="department" className="text-left" header="Department" footer="Total" sortable />
                                    <Column field="driver" className="text-left" header="Driver" sortable />
                                    <Column field="vehicleid" className="text-left" header="Vehicle" sortable />
                                    <Column
                                        field="totalFuelAmount"
                                        className="text-right"
                                        headerStyle={{ minWidth: '110px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Vipfuel }}
                                        header="Total Fuel Amount"
                                        footer={'AED ' + totalfuel.toFixed(2)}
                                        sortable
                                    />
                                    <Column
                                        field="fuelPercentage"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Vipfuel }}
                                        header="Fuel % in usage"
                                        footer={totalfuel === 0 ? '0%' : `${((100 * totalfuel) / totalfuel).toFixed(2)}%`}
                                    />
                                    <Column
                                        field="totalSalikAmount"
                                        className="text-right"
                                        headerStyle={{ minWidth: '110px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Salik }}
                                        header="Total Salik Amount"
                                        footer={'AED ' + totalsaik}
                                        sortable
                                    />
                                    <Column
                                        field="salikPercentage"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Salik }}
                                        header="Salik % in usage"
                                        footer={totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`}
                                    />
                                    <Column
                                        field="totalFineAmount"
                                        className="text-right"
                                        headerStyle={{ minWidth: '110px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Fine }}
                                        header="Total Fine Amount"
                                        footer={'AED ' + totalfine}
                                        sortable
                                    />
                                    <Column
                                        field="finePercentage"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Fine }}
                                        header="Fine % in usage"
                                        footer={totalFineUsage === 0 ? '0%' : `${((100 * totalfine) / totalFineUsage).toFixed(2)}%`}
                                    />
                                    <Column
                                        field="totalReimbursementAmount"
                                        className="text-right"
                                        headerStyle={{ minWidth: '110px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.CashReimbursement }}
                                        header="Total Reimbursement Amount"
                                        footer={'AED ' + totalReimbursement}
                                        sortable
                                    />
                                    <Column
                                        field="reimbursementPercentage"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.CashReimbursement }}
                                        header="Reimbursement % in usage"
                                        footer={totalReimbursementUsage === 0 ? '0%' : `${((100 * totalReimbursement) / totalReimbursementUsage).toFixed(2)}%`}
                                    />
                                </DataTableMemoized>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewDriverUsage;
