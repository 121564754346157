/**
 * @param theme 'Specify dark or light'
 */
import AmplifyServices from "../Service/Service";
import { listDxTableSettings } from "../graphql/queries";

export const getTypeColors = (theme: string) => {
  // let TableData = await AmplifyServices.getTableDetails(listDxTableSettings, 'listDxTableSettings').then(async (res: any) => {});
  // console.log(TableData);

  const isLight = theme === "light";
  return {
    Vipfuel: isLight ? "rgba(250, 101, 7, 0.1803921569)" : "#d0b8ac",
    Salik: isLight ? "#e142471c" : "#ef7a85",
    Fine: isLight ? "#970e212e" : "#89c2d9",
    Parking: isLight ? "rgba(207, 207, 207, 0.75)" : "rgb(207, 207, 207)",
    Mileage: isLight ? "rgba(56, 176, 0, 0.1411764706)" : "rgb(178, 230, 154)",
    Accidents: isLight ? "rgb(255, 237, 207)" : "#f79d65",
    Repair: isLight ? "#ffb4aa" : "#b5e48c",
    Branding: isLight ? "#b7dfea" : "#86c1e6",
    Insurance: isLight ? "#ffd60a8c" : "#ffd60a8c",
    //ffd60a
    CashReimbursement: isLight ? "#b9e3d4" : "#96d4b3",
    CRSalik: isLight ? "#c7e5e8" : "#a4d6d9",
    CRFuel: isLight ? "#d2e9b9" : "#b4dbaf",
    CRParking: isLight ? "#d8e9d3" : "#c1dbc9",
    CRMaintenance: isLight ? "#e5e1c7" : "#d6c894",
    CRRenewals: isLight ? "#f2e7b6" : "#e6d87e",
    CROthers: isLight ? "#f8e5a5" : "#f5d65d",
    Default: isLight ? "#a88bdb" : "#8c67d8",
    ActiveVehicle: isLight ? "#66BB6A" : "#A5D6A7",
    GarageVehicle: isLight ? "#d32f2f" : "#d32f2f",
    InactiveVehicle: isLight ? "#fbc02d" : "#fbc02d",
  };
};
export const getTypeColor = async (theme: string) => {
  const getTableData = async (column: string) => {
    const res = await AmplifyServices.getTableDetails(
      listDxTableSettings,
      "listDxTableSettings"
    );
    const item = res.find((i: any) => i.column === column);
    return item ? item.field1 : "#fff3e2";
  };
  const TableDatas: any = await Promise.all([
    getTableData("Salik"),
    getTableData("Vipfuel"),
    getTableData("Fine"),
    getTableData("Parking"),
    getTableData("Mileage"),
    getTableData("Accidents"),
    getTableData("Repair"),
    getTableData("Branding"),
  ]);

  const isLight = theme === "light";

  return {
    Vipfuel: isLight ? "rgba(250, 101, 7, 0.1803921569)" : TableDatas[1],
    //  vipfuelColor !== undefined ? vipfuelColor.field1 : '#ececec',
    Salik: isLight ? "#e142471c" : TableDatas[0],
    Fine: isLight ? "#970e212e" : TableDatas[2],
    Parking: isLight ? "rgba(207, 207, 207, 0.75)" : TableDatas[3],
    Mileage: isLight ? "rgba(56, 176, 0, 0.1411764706)" : TableDatas[4],
    Accidents: isLight ? "rgb(255, 237, 207)" : TableDatas[5],
    Repair: isLight ? "#ffb4aa" : TableDatas[6],
    Branding: isLight ? "#b7dfea" : TableDatas[7],
    CashReimbursement: isLight ? "#b9e3d4" : "#96d4b3",
    CRSalik: isLight ? "#c7e5e8" : "#a4d6d9",
    CRFuel: isLight ? "#d2e9b9" : "#b4dbaf",
    CRParking: isLight ? "#d8e9d3" : "#c1dbc9",
    CRMaintenance: isLight ? "#e5e1c7" : "#d6c894",
    CRRenewals: isLight ? "#f2e7b6" : "#e6d87e",
    CROthers: isLight ? "#f8e5a5" : "#f5d65d",
    Default: isLight ? "#a88bdb" : "#8c67d8",
    ActiveVehicle: isLight ? "#66BB6A" : "#A5D6A7",
    GarageVehicle: isLight ? "#d32f2f" : "#d32f2f",
    InactiveVehicle: isLight ? "#fbc02d" : "#fbc02d",
  };
};
