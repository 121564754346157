import { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { Amplify, API, Storage } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
var Upload: any = [];
var UploadURl: any;
function UploadDocuments(props: any) {
    console.log(props.item);
    const toast = useRef<any>(null);
    const [totalSize, setTotalSize] = useState(0);
    const [Uploadfiles, setUploadfiles] = useState([]);
    const [isEnableUpload, setIsEnableUpload] = useState(false);
    const fileUploadRef = useRef<any>(null);

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    // console.log(formid);
    const onTemplateSelect = (e: any) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };
    useEffect(() => {
        // console.log(props.item.renewaltype);
        // console.log(isEnableUpload);
        props.EnableUpload !== '' && handleChangeDocumentUpload(Upload);
        setIsEnableUpload(props.EnableUpload);
        // console.log(props.EnableUpload);
    }, [props, props.EnableUpload, isEnableUpload]);
    const UploadDocuments = () => { };
    const onTemplateUpload = (e: any) => {
        let _totalSize = 0;

        e.files.forEach((file: any) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file: any, callback: any) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options: any) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                {/* <span>{formid}</span> */}
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
    const handleChangeDocumentUploadfunction = (files: any) => { };
    const handleChangeDocumentUpload = async (event: any) => {
        // Upload = files;
        console.log(event);
        if (event && event.files) {
            setUploadfiles(event);
            console.log('document upload', event);
            let Files: any = [];

            UploadURl = '';
            if (event.files !== undefined) {
                let path: any = props.item !== undefined ? props.item.renewaltype + '/' : '';
                return await Promise.all(
                    event.files.map(async (item: any) => {
                        await Storage.put(`${props.bucketname}/${formid}/${new Date().getFullYear()}/${path}` + item.name, item).then((res: any) => {
                            // console.log(res.key);
                            Files.push(res.key);
                            UploadURl = JSON.stringify({ File: Files });
                            props.setdocumentpath(UploadURl);

                            // console.log('uploaded');
                            toast.current.show({
                                severity: 'success',
                                summary: 'Files Uploaded',
                                // detail: detail,
                                life: 3000
                            });
                        });
                    })
                );
            }
        }


        // handleChangeDocumentUploadfunction(files);
    };
    const handleChangeDocumentUploadauto = async (event: any) => {
        // Upload = files;
        setUploadfiles(event);
        // console.log('document auto', event);
        console.log(props.item);
        let Files: any = [];

        UploadURl = '';
        if (event.files !== undefined) {
            let path: any = props.item !== undefined ? props.item.renewaltype + '/' : '';
            return await Promise.all(
                event.files.map(async (item: any) => {
                    await Storage.put(`${props.item.renewaltype}/${new Date().getFullYear()}/${props.item.vehicleid}/documents` + item.name, item).then((res: any) => {
                        // console.log(res.key);
                        Files.push(res.key);
                        UploadURl = JSON.stringify({ File: Files });
                        props.setdocumentpath(UploadURl);
                    });
                })
            );
        }
    };
    return (
        <>
            {' '}
            <div>
                <Toast ref={toast}></Toast>
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card">
                            <div className="p-fluid formgrid">
                                <div className="field col-12  md:col-12">
                                    <h5 className="">{props.header}</h5>
                                </div>

                                <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                                <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                                <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
                                {props.EnableautoUpload == true ? (
                                    <>
                                        {' '}
                                        <FileUpload
                                            ref={fileUploadRef}
                                            name="demo[]"
                                            // url="/api/upload"
                                            multiple
                                            accept="image/*"
                                            uploadHandler={(e) => handleChangeDocumentUploadauto(e)}
                                            maxFileSize={1000000}
                                            customUpload={true}
                                            auto={true}
                                            // onUpload={onTemplateUpload}
                                            // onSelect={onTemplateSelect}
                                            onError={onTemplateClear}
                                            onClear={onTemplateClear}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={itemTemplate}
                                            emptyTemplate={emptyTemplate}
                                            chooseOptions={chooseOptions}
                                            uploadOptions={uploadOptions}
                                            cancelOptions={cancelOptions}
                                        />
                                    </>
                                ) : (
                                    <FileUpload
                                        ref={fileUploadRef}
                                        name="demo[]"
                                        // url="/api/upload"
                                        multiple
                                        accept="image/*"
                                        uploadHandler={(e) => handleChangeDocumentUpload(e)}
                                        maxFileSize={1000000}
                                        customUpload={true}
                                        // auto={true}
                                        // onUpload={onTemplateUpload}
                                        onSelect={onTemplateSelect}
                                        onError={onTemplateClear}
                                        onClear={onTemplateClear}
                                        headerTemplate={headerTemplate}
                                        itemTemplate={itemTemplate}
                                        emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions}
                                        uploadOptions={uploadOptions}
                                        cancelOptions={cancelOptions}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UploadDocuments;
