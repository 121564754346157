import { useCallback, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import './approvals.css';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import AmplifyServices from '../../Service/Service'
import { TabMenu } from 'primereact/tabmenu';
import FormTemplate from '../../Components/FormTemplate';
import dxService from '../../Services/dxService';
import DataGridTemplate from '../../Components/DataGridTemplate';


var wizardItems: any;
const NewWFConfig = () => {
    const [listFormData, setlistFormData] = useState<any>({});
    const [subitems, setsubitems] = useState<any>([]);
    const toast = useRef<any>(null);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const [fcolumns, setfcolumns] = useState<any>({});
    const [activeIndex, setActiveIndex] = useState(0);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    var Param = location?.search.split('&')[0].split('?form=')[1];
    let acolumns: any = {};

    const navigate = useNavigate();
    const NavigateToHome = () => navigate('/manage/vehicles');

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
        formid = urlParams.has('id') ? urlParams.get('id') : null;
        const paths = location.pathname.split('/');
        const currentPath = paths[paths.length - 1];
        console.log(formid);
        if (formid != null && formid != "")
            getitems();

        // switch (currentPath) {
        //     case 'documents':
        //         setActiveIndex(1);
        //         break;
        //     case 'pictures':
        //         setActiveIndex(2);
        //         break;
        //     case 'logs':
        //         setActiveIndex(3);
        //         break;
        //     case 'Allocations':
        //         setActiveIndex(4);
        //         break;
        //     default:
        //         if (formid) getvehicledata(formid);
        //         break;
        // }
    }, [location]);


    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);

    // useEffect(()=>{
    //     getitems();
    // },[]);

    const getitems = async () => {
        console.log(formid);
        const response = await dxService.getitembyid('approvalconfig', formid, '*', true);

        let configitem = {
            name: response.name,
            title: response.name,
            field1: response.description,
            field2: response.firststep
        };
        let stepitems = response.steps.items;
        console.log(configitem);
        setlistFormData(configitem);
        console.log(stepitems);
        setsubitems(stepitems);
    }

    // name: String!
    // title: String
    // description: String
    // startdate: AWSDateTime
    // duedate: AWSDateTime
    // enddate: AWSDateTime
    // assignedto: String
    // level: String
    // status: String
    // resource: String
    // resourceid: String
    // resourceurl: String

    // ,
    //         {
    //             name: 'startdate',
    //             title: 'Start Date',
    //             value: '',
    //             fieldtype: 'datetime',
    //             required: false,
    //             disabled: false,
    //             hidden: false,
    //             data: []
    //         },
    //         {
    //             name: 'duedate',
    //             title: 'Due Date',
    //             value: '',
    //             fieldtype: 'datetime',
    //             required: false,
    //             disabled: false,
    //             hidden: false,
    //             data: []
    //         },
    //         {
    //             name: 'enddate',
    //             title: 'End Date',
    //             value: '',
    //             fieldtype: 'datetime',
    //             required: false,
    //             disabled: false,
    //             hidden: false,
    //             data: []
    //         },
    //         {
    //             name: 'assignedto',
    //             title: 'Assigned To',
    //             fieldtype: 'text',
    //             required: true,
    //             value: '',
    //             hidden: false,
    //             disabled: false,
    //             data:[]

    //         },
    //         {
    //             name: 'level',
    //             title: 'Level',
    //             fieldtype: 'text',
    //             required: true,
    //             value: '',
    //             hidden: false,
    //             disabled: false,
    //             data:[]

    //         },
    //         {
    //             name: 'status',
    //             title: 'Status',
    //             fieldtype: 'text',
    //             required: true,
    //             value: '',
    //             hidden: false,
    //             disabled: false,
    //             data:[]

    //         },
    //         {
    //             name: 'resource',
    //             title: 'Resource',
    //             fieldtype: 'text',
    //             required: true,
    //             value: '',
    //             hidden: false,
    //             disabled: false,
    //             data:[]

    //         },
    //         {
    //             name: 'resourceid',
    //             title: 'Resource Id',
    //             fieldtype: 'text',
    //             required: true,
    //             value: '',
    //             hidden: false,
    //             disabled: false,
    //             data:[]

    //         },
    //         {
    //             name: 'resourceurl',
    //             title: 'Resource Url',
    //             fieldtype: 'text',
    //             required: true,
    //             value: '',
    //             hidden: false,
    //             disabled: false,
    //             data:[]

    //         }

    const subchangedata = (props: any) => {
        //setupdatedata(props);
        console.log(props);
    };
    const subaddData = (props: any) => {
        console.log(props);
    };

    const changedata = (props: any) => {
        //setupdatedata(props);
        console.log(props);
    };

    const addData = async (props: any) => {
        console.log(props);
        let formsteps = props.form1;
        let formstepobjects = Object.keys(formsteps);

        let configitem = {
            name: props.name,
            title: props.name,
            field1: props.description,
            field2: props.firststep
        };



        let stepitems: any = [];
        // id: ID!
        // name: String!
        // title: String
        // action:AWSJSON
        // approval: dxApprovalConfig @belongsTo
        // field1: String
        // field2: String
        // field3: String
        // field4: String
        // field5: String
        // acsjson: AWSJSON
        // workflow: [dxWorkflow] @hasMany

        formstepobjects.forEach((ele: any) => {
            let s: any = formsteps[ele];
            console.log(s);
            let stepitem = {
                name: s.stepname,
                title: s.step,
                acsjson: JSON.stringify(s.conditions),
                field1: s.action,
                field2: s.nextstep,
                field3: s.person,
                //assignedto:s.person,
                dxApprovalConfigStepsId: null

            };
            stepitems.push(stepitem);
        })



        let addapprovalconfiguration = await AmplifyServices.Addnewapprovalconfig(configitem)
            .then(async (res: any) => {

                //dxApprovalConfigStepsId
                console.log(res);
                console.log(stepitems);
                stepitems.forEach(async (si: any) => {
                    si['dxApprovalConfigStepsId'] = res.id;
                    let stepitem = await AmplifyServices.Addnewapprovalconfigstep(si);
                    console.log(stepitem);
                });
                // setapprovaldata(res);


            })
            .catch((err: any) => console.log(err));
        console.log(addapprovalconfiguration);
        //setlistFormData(props);
        //console.log(listFormData);
        // if (formid == null) addnewvehicle(props);
        // else {
        //     editnewvehicle(props, formid);
        // }
    };

    const addstep = (props: any) => {
        console.log(props);

        // let ab ={
        //     name: 'form2',
        //     title: 'Step',
        //     fieldtype: 'form',
        //     required: false,
        //     value: '',
        //     hidden: false,
        //     disabled: false,
        //     class:'md:col-12',
        //     columns:subform,
        //     updatedata:[],
        //     mode:'new',
        //     onchange:{subchangedata},
        //     submitdata:{addData},
        //     showaction:true
        // };


        // console.log(fcolumns);
        // console.log(acolumns);
        // acolumns.columns.push(ab);
        // setfcolumns(acolumns);


        // console.log("Add step");
    }


    const subcolumns = [
        { field: 'id', headerName: 'index', width: 70 },
        { field: 'name', headerName: 'Step Name', width: 50 },
        { field: 'field2', headerName: 'Next Step', width: 50 },
        { field: 'title', headerName: 'Title', width: 90 },
        { field: 'field3', headerName: 'Assigned To', width: 90 },
        { field: 'field1', headerName: 'Action', width: 90 },
        // 
        { field: 'acsjson', headerName: 'Configuration', width: 90 }
    ];

    let subform = {
        columns: [
            {
                name: 'index',
                title: 'Index',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: true,
                disabled: false,
                data: [],
                class: 'md:col-1'

            },
            {
                name: 'stepname',
                title: 'Step Name',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-1'

            },
            {
                name: 'step',
                title: 'Title',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-2'

            },
            {
                name: 'person',
                title: 'Assigned To',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: '',
                class: 'md:col-2'

            },
            {
                name: 'action',
                title: 'Action',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-2'

            },
            {
                name: 'nextstep',
                title: 'Next Step',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-2'

            },
            {
                name: 'conditions',
                title: 'Conditions',
                fieldtype: 'textarea',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-2'

            }
        ]
    }
    let approvalColumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'name',
                title: 'Name',
                fieldtype: 'text',
                required: true,
                value: 'aaa',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-6'

            },
            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },

            {
                name: 'description',
                title: 'Description',
                fieldtype: 'textarea',
                required: false,
                value: 'test description',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-12'
            },

            {
                name: 'firststep',
                title: 'First Step',
                fieldtype: 'text',
                required: true,
                value: 'Step 1',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-6'

            },
            {
                title: 'Steps',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'form1',
                title: 'Add Step',
                fieldtype: 'form',
                required: false,
                value: '',
                hidden: false,
                disabled: false,
                class: 'md:col-12',
                columns: subform,
                updatedata: [],
                mode: 'new',
                onchange: { subchangedata },
                submitdata: { addData },
                showaction: false
            },
            // {
            //     name: 'btn1',
            //     title: 'Add Step',
            //     fieldtype: 'button',
            //     required: false,
            //     value: '',
            //     hidden: false,
            //     disabled: false,
            //     class:'md:col-3',
            //     action:{addstep}
            // }



        ]
    };
    let childcolumn = {
        name: 'form2',
        title: 'Step',
        fieldtype: 'form',
        required: false,
        value: '',
        hidden: false,
        disabled: false,
        class: 'md:col-12',
        columns: subform,
        updatedata: [],
        mode: 'new',
        onchange: { subchangedata },
        submitdata: { addData },
        showaction: false
    };




    let qs = '';
    let qsa = '';
    let qsaedit = '';
    let qsedit = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qsaedit = formid != null ? '?id=' + formid : '';
    qsaedit += formmode != null ? '&mode=' + 'edit' : '';
    qs = qsa != '' ? '?' + qsa : '';
    qsedit = qsaedit != '' ? '?' + qsaedit : '';
    console.log(qs);
    const wizardItemsedit = [
        { label: 'Basic Details', command: () => navigate('/manage/vehicles/item/basic' + qs) },
        {
            label: 'Logs',
            command: () => navigate('/manage/vehicles/item/logs' + qs)
        }
    ];

    const wizardItemsview = [
        { label: 'Basic Details', command: () => navigate('/manage/vehicles/item/basic' + qs) },
        {
            label: 'Logs',
            command: () => navigate('/manage/vehicles/item/logs' + qs)
        },

        {
            label: 'Edit',
            icon: 'pi pi-fw pi-pencil',
            command: () => navigate('/manage/vehicles/item/basic' + qsaedit)
        }
    ];
    if (formmode == 'view') {
        wizardItems = wizardItemsview;
    } else if (formmode == 'edit' || formmode == 'new') {
        wizardItems = wizardItemsedit;
    }
    console.log(listFormData);
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{formmode === 'edit' ? 'Edit Workflow Configuration : ' + formid : formmode === 'view' ? 'View Workflow Configuration: ' + formid : 'New Worklfow Configuration'}</h4>
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast} />
                            {/* <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} /> */}




                            <FormTemplate
                                id='newform'
                                columns={approvalColumns}
                                childform={subform}
                                data={listFormData}
                                showaction={true}
                                mode={formmode}
                                onChange={changedata}
                                ChangeBtn={'OpenDocument'}
                                layout="grid2"
                                title="New Workflow"
                                submitForm={addData}
                                showtopbutton={true}
                            />


                            <DataGridTemplate data={subitems} columns={subcolumns} />



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewWFConfig;