import { API } from 'aws-amplify';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AmplifyServices from '../../Service/Service';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createDxNavigation, updateDxNavigation } from '../../graphql/mutations';
import { listDxGeneralConfigs, listDxNavigations } from '../../graphql/queries';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import ListForm from '../../Components/ListForm';
import { AuthContext } from '../../AuthContext';

const NavForm = () => {
    const { dispatch } = useContext(AuthContext);
    const [updatedata, setupdatedata] = useState<any>([]);
    const [listFormData, setlistFormData] = useState<any>({});
    const [headerName, setHeaderName] = useState<any>([]);

    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<any>(null);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
        formid = urlParams.has('id') ? urlParams.get('id') : null;
        const paths = location.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'pictures':
                setActiveIndex(1);
                break;

            default:
                if (formid) getcongifsdata(formid);
                break;
        }
    }, [location]);
    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);

    const getcongifsdata = async (fid: any) => {
        let allItems: any[] = [];
        await AmplifyServices.getFilterbyIddetails(listDxNavigations, 'listDxNavigations', fid).then((a) => {
            if (a.length > 0) {
                let newView = {};
                Promise.all(
                    a.map((item: any) => {
                        newView = {
                            id: item.id,
                            type: item.type,
                            pname: JSON.parse(item.item).label,
                            picon: JSON.parse(item.item).icon,
                            cname: JSON.parse(item.item).items.label,
                            cicon: JSON.parse(item.item).items.icon,
                            to: JSON.parse(item.item).items.to,
                            badge: JSON.parse(item.item).items.badge,
                            badgeClassName: JSON.parse(item.item).items.badgeClassName,
                            order: item.order,
                            _deleted: item._deleted,
                            _version: item._version
                        };
                        allItems.push(newView);
                    })
                ).then(() => {
                    setlistFormData(allItems[0]);
                });
            }
        });
    };
    const changenavigationdata = (props: any) => {
        setupdatedata(props);
    };
    const getParentDropdownName = async () => {
        let filterItem = {
            fleettype: {
                eq: 'Navigation'
            }
        };
        let GetResponse: any = await API.graphql({
            query: listDxGeneralConfigs,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                filter: filterItem
            }
        });
        setHeaderName(GetResponse.data.listDxGeneralConfigs.items);
    };
    const headerNameOption: any = [];
    headerName
        .filter((del: any) => del._deleted !== true)
        .map((res: any) => {
            res['label'] = res.name;
            res['value'] = res.name;
            // console.log(res);
            headerNameOption.push(res);
        });

    useEffect(() => {
        getParentDropdownName();

        let cols = formColumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });
        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);

    const formColumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header'
            },

            {
                name: 'type',
                value: '',
                title: 'Nav Type',
                fieldtype: 'dropdown',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [
                    { label: 'Left', value: 'Left' },
                    { label: 'Top', value: 'Top' }
                ]
            },
            {
                name: 'order',
                value: '',
                title: 'Order By',
                fieldtype: 'number',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                title: 'Parent Info',
                name: 'parentinfo',
                fieldtype: 'header'
            },
            {
                name: 'pname',
                value: '',
                title: 'Parent Name',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: headerNameOption
            },
            {
                name: 'picon',
                value: '',
                title: 'Icon',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },

            {
                title: 'Sub Info',
                name: 'subinfo',
                fieldtype: 'header'
            },
            {
                name: 'cname',
                value: '',
                title: 'Sub item name',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'cicon',
                value: '',
                title: 'Sub icon',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'to',
                value: '',
                title: 'Path Name',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'badge',
                value: '',
                title: 'Badge',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'badgeClassName',
                value: '',
                title: 'Badge Class name',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            }
        ],
        getTable: listDxNavigations,
        createTable: createDxNavigation //Pass update table name
    };

    const submitForm = async (data: any) => {
        let items = {
            type: data.type,
            order: data.order,
            item: JSON.stringify({
                label: data.pname,
                icon: data.picon,
                items: {
                    label: data.cname,
                    icon: data.cicon,
                    to: data.to,
                    badge: data.badge,
                    badgeClassName: data.badgeClassName
                }
            })
        };

        // data['id'] = Math.random().toString(36).substring(2, 9);
        let os = await API.graphql({
            query: formColumns.createTable,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: items
            }
        });

        console.log(os);
        dispatch({ type: 'NAVIGATION', payload: 'Updated' + new Date() });
        showSuccess('', 'Added successfully!');
        navigate(`/admin/navigation/all`);
    };
    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';

    const wizardItems = [{ label: 'Basic Details', command: () => navigate('/admin/navigation/item/basic' + qs) }];
    const editnewnavigation = async (formdata: any, fid: string) => {
        let b: any = formdata;
        console.log(formdata);

        Object.keys(b).forEach((key) => {
            if (b[key] == null) {
                delete b[key];
            }
        });

        if (Object.keys(b).includes('_lastChangedAt')) delete b['_lastChangedAt'];
        if (Object.keys(b).includes('createdAt')) delete b['createdAt'];
        if (Object.keys(b).includes('updatedAt')) delete b['updatedAt'];
        // let dd = new Date(b['startdate']);
        //         b['startdate']= dd.toISOString();

        let items = {
            id: fid,
            type: b.type,
            order: b.order,
            item: JSON.stringify({
                label: b.pname,
                icon: b.picon,
                items: {
                    label: b.cname,
                    icon: b.cicon,
                    to: b.to,
                    badge: b.badge,
                    badgeClassName: b.badgeClassName
                }
            }),
            _version: b._version
        };

        AmplifyServices.EditConfigDetails(updateDxNavigation, 'updateDxNavigation', items)
            .then(async (res: any) => {
                console.log(res);
                dispatch({ type: 'NAVIGATION', payload: 'Updated' + new Date() });
                showSuccess(res.name, 'updated successfully!');
                navigate(`/admin/navigation/all`);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const showSuccess = (summary: string, detail: string) => {
        toast.current.show({
            severity: 'success',
            summary: summary,
            detail: detail,
            life: 3000
        });
    };
    const addConfig = (props: any) => {
        if (formid == null) submitForm(props);
        else {
            editnewnavigation(props, formid);
        }
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">
                    {formmode === 'edit' ? 'Edit Navigation : ' + listFormData.pname + ' - ' + listFormData.cname : formmode === 'view' ? 'View Navigation: ' + listFormData.pname + ' - ' + listFormData.cname : 'New Navigation'}
                </h4>

                <div className="card container-card">
                    <Toast ref={toast} />
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                    <Routes>
                        <Route
                            path={'/basic'}
                            element={
                                <>
                                    <ListForm
                                        bucketname="Generalnavigation"
                                        Fleettype={'GeneralnavigationPicture'}
                                        columns={formColumns}
                                        data={listFormData}
                                        showaction={true}
                                        mode={formmode}
                                        onChange={changenavigationdata}
                                        // setselectedmakeDropdown={setselectedmakeDropdown}
                                        // ChangeBtn={'OpenDocument'}
                                        layout="grid"
                                        title="New Navigation"
                                        submitForm={addConfig}
                                    />
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default NavForm;
