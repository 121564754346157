import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useState, useEffect } from "react";
import { localetoisodatetime } from "../../../utils/helper";
import ChartDataLabels from "chartjs-plugin-datalabels";
import dxService from "../../../Services/dxService";
import moment from "moment";
import html2canvas from "html2canvas";
import { getTypeColors } from "../../../utils/color";

var SalikArray: any = [];
var OriginalSalikArray: any = [];
var VipFuelArray: any = [];
var OriginalVipFuelArray: any = [];
var FineArray: any = [];
var OriginalFineArray: any = [];
var RepairArrray: any = [];
let chartMonthlyOptions: any;
let StoreRepair: any;
let storekey: any;
let StoreConsumptionFine: any;
let StoreSalikValue: any;
let StoreVipFuelValue: any;
const DashboardConsumptions = (props: any) => {
  const [showCRData, setShowCRData] = useState<any>(false);
  const [fetchData, setFetchData] = useState<any>(true);
  const [crSalik, setCRSalik] = useState<any>([0, 0, 0, 0, 0]);
  const [crFuel, setCRFuel] = useState<any>([0, 0, 0, 0, 0]);
  const [crFine, setCRFine] = useState<any>([0, 0, 0, 0, 0]);
  const [repairSpares, setrepairSpares] = useState<any>([]);
  const [ConsumptionbarData, setConsumptionBarData] = useState<any>();
  const [dprogress, setDownloadProgress] = useState<any>(false);
  const today = new Date(); // get today's date

  var targetDate = new Date(today.getTime());
  targetDate.setMonth(targetDate.getMonth() - 4);
  let lastenddate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let lastfirstdate = new Date(today.getFullYear(), today.getMonth() - 4, 1);
  const lastFiveMonthName = lastfirstdate.toLocaleString("default", {
    month: "long",
  });
  const ThisMonthName = lastenddate.toLocaleString("default", {
    month: "long",
  });
  /** passing data to the Consumption bar graph */
  const getChartData = async (colorMode: string) => {
    const color = await getTypeColors("dark");
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let currentMonth = new Date().getMonth();
    const previousFiveMonthNames = months.slice(
      currentMonth - 4,
      currentMonth + 1
    );
    //new way for fetching the last five month
    const currentMonths: any = new Date().getMonth();
    const pastMonthNames = [];
    for (let i = 0; i <= 4; i++) {
      const monthIndex = (currentMonths - i + 12) % 12;
      const monthName = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(new Date(0, monthIndex));
      pastMonthNames.push(monthName);
    }

    return {
      labels: pastMonthNames.reverse(),

      datasets: [
        {
          label: "Salik",
          data: SalikArray,
          borderColor: color.Salik,
          backgroundColor: color.Salik,
          borderWidth: 2,
          fill: true,
        },
        {
          label: "Vip Fuel",
          data: VipFuelArray,
          borderColor: color.Vipfuel,
          backgroundColor: color.Vipfuel,
          borderWidth: 2,
          fill: true,
        },
        {
          label: "Fine",
          data: FineArray,
          borderColor: color.Fine,
          backgroundColor: color.Fine,
          borderWidth: 2,
          fill: true,
        },
        {
          label: "Repair",
          data: RepairArrray,
          borderColor: color.Repair,
          backgroundColor: color.Repair,
          borderWidth: 2,
          fill: true,
        },
      ],
    };
  };
  /**properties for consumption bar graph */
  const getChartOptions = () => {
    const textColor =
      getComputedStyle(document.body).getPropertyValue("--text-color") ||
      "rgba(0, 0, 0, 0.87)";
    const gridLinesColor =
      getComputedStyle(document.body).getPropertyValue("--divider-color") ||
      "rgba(160, 167, 181, .3)";
    const fontFamily = getComputedStyle(document.body).getPropertyValue(
      "--font-family"
    );
    return {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          display: true,
          labels: {
            fontFamily,
            color: textColor,
          },
        },
        datalabels: {
          display: true,
          color: "black",
          align: "end", // Adjusted alignment to start
          anchor: "end", // Adjusted anchor to end
          // offset: -20,

          font: {
            size: "12",
            weight: "700",
          },
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              return `${context.dataset.label}: AED ${context.raw}`;
            },
          },
        },
      },
      scales: {
        y: {
          ticks: {
            fontFamily,
            color: textColor,
          },
          grid: {
            color: gridLinesColor,
          },
        },
        x: {
          categoryPercentage: 0.9,
          barPercentage: 0.8,
          ticks: {
            fontFamily,
            color: textColor,
          },
          grid: {
            color: gridLinesColor,
          },
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    };
  };
  /**fetchData() will fetch all the appointment and masterlog data for the last five month */
  useEffect(() => {
    const fetchData = async () => {
      setrepairSpares([]);
      chartMonthlyOptions = getChartOptions();

      setFetchData(true);
      const [appointmentData, consumptionData] = await Promise.all([
        getAppointmentData(),
        getConsumptionData(),
      ]);
      await MonthlyReports(consumptionData, appointmentData);
      setFetchData(false);
      setConsumptionBarData(await getChartData(props.trojan.colorMode));
    };

    fetchData();
  }, []);
  /** getConsumptionData() retrives the vipfuel, salik, fine data for previous five month from masterlog  */
  const getConsumptionData = async () => {
    let nextToken;
    let response = [];
    const ac = `Vipfuel Salik Fine date _deleted`;

    do {
      const variables: any = {
        and: [
          {
            date: { ge: localetoisodatetime(new Date(lastfirstdate)) },
          },
          {
            date: {
              le:
                moment(localetoisodatetime(lastenddate)).format("YYYY-MM-DD") +
                "T23:59:59.999Z",
            },
          },
        ],
      };
      const masterlogdata: any = await dxService.listitems(
        "masterlog",
        ac,
        variables,
        true,
        1000,
        nextToken
      );
      const items = masterlogdata.items.filter(
        (del: any) => del._deleted !== true
      );
      response.push(...items);

      nextToken = masterlogdata.nextToken;
    } while (nextToken);

    return response;
  };
  /** getAppointmentData() retrives the repair and service data for previous five month from appointment table  */
  const getAppointmentData = async () => {
    const response: any[] = [];
    let nextToken: any = null;
    const ac = `id Date cost eventtype appointmentstatus _deleted 
            spares{
                items{
                    id
                    vehicleid
                    amount
                    totalamount
                    jfield 
                }
            }`;

    do {
      let variables: any = {
        and: [
          {
            Date: { ge: localetoisodatetime(new Date(lastfirstdate)) },
          },
          {
            Date: {
              le:
                moment(localetoisodatetime(lastenddate)).format("YYYY-MM-DD") +
                "T23:59:59.999Z",
            },
          },
          {
            eventtype: { eq: "Repair/Replacement" },
          },
          {
            appointmentstatus: { eq: "Completed" },
          },
        ],
      };

      const masterlogdata = await dxService.listitems(
        "appointment",
        ac,
        variables,
        true,
        1000,
        nextToken
      );
      const { items, nextToken: newNextToken } = masterlogdata;

      response.push(...items);
      nextToken = newNextToken;
    } while (nextToken);

    return response;
  };
  /** MonthlyReports used to calculate the graph chart data*/
  const MonthlyReports = async (Report: any, repairSpares: any) => {
    const currentMonths = new Date().getMonth();
    const last6Months: any = [];

    const year = new Date().getFullYear();

    for (let i = 0; i <= 4; i++) {
      const monthIndex = (currentMonths - i + 12) % 12;
      const yearOffset = currentMonths - i < 0 ? -1 : 0; // Check if previous year is involved
      const monthName: any = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(new Date(year + yearOffset, monthIndex));

      last6Months.push(monthName);
    }

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const sortedMonthslast6Months = last6Months.sort((a: any, b: any) => {
      const [monthA, yearA] = a.split(" ");
      const [monthB, yearB] = b.split(" ");

      const monthIndexA = months.indexOf(monthA);
      const monthIndexB = months.indexOf(monthB);

      if (yearA === yearB) {
        return monthIndexA - monthIndexB;
      } else {
        return yearA.localeCompare(yearB);
      }
    });

    const formatDate = (date: any) => moment(date).format("MMMM YYYY");

    const filterDataByMonth = (data: any, field: string) =>
      data.filter(
        (item: any) =>
          item !== undefined &&
          item._deleted !== true &&
          item[field] !== null &&
          item[field] !== "" &&
          item[field] !== "0"
      );

    const mapDataWithCustomMonth = (data: any) =>
      data.map((item: any) => ({
        ...item,
        CustomMonth: formatDate(item.date),
      }));

    const mapRepairSparesData = (data: any) => {
      const repairSparesArray: any[] = [];
      data.forEach((custom: any) => {
        custom["CustomMonth"] = formatDate(custom.Date);
        if (custom.spares && custom.spares.items.length > 0) {
          custom.spares.items.forEach((newValue: any) => {
            newValue["CustomMonth"] = formatDate(custom.Date);
            repairSparesArray.push(newValue);
          });
        }
      });
      setrepairSpares((prevArray: any) => [...prevArray, ...repairSparesArray]);
      return repairSparesArray;
    };

    const salikarr = mapDataWithCustomMonth(filterDataByMonth(Report, "Salik"));
    const fuelarr = mapDataWithCustomMonth(
      filterDataByMonth(Report, "Vipfuel")
    );
    const Fines = mapDataWithCustomMonth(filterDataByMonth(Report, "Fine"));
    const Repair = mapRepairSparesData(
      repairSpares.filter(
        (rep: any) =>
          rep !== undefined &&
          rep._deleted !== true &&
          rep.appointmentstatus == "Completed" &&
          rep.spares &&
          rep.spares.items.length > 0
      )
    );

    getReimburseData(sortedMonthslast6Months);

    getSalikData(salikarr, sortedMonthslast6Months);

    getFuelData(fuelarr, sortedMonthslast6Months);

    getFineData(Fines, sortedMonthslast6Months);

    getRepairSapresData(Repair, sortedMonthslast6Months);
  };

  const getSalikData = async (salikarr: any, last6Months: any) => {
    setFetchData(true);

    SalikArray = [];
    OriginalSalikArray = [];
    var groupBySalik = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupBySalikMonthvalue: any = groupBySalik(salikarr, "CustomMonth");
    let reformatedata: any = [];
    let finaldata: any = [];

    for (const [key, value] of Object.entries(GroupBySalikMonthvalue)) {
      storekey = key;
      StoreSalikValue = value;
      var sum = 0;
      for (var i = 0; i < StoreSalikValue.length; i++) {
        sum += parseFloat(StoreSalikValue[i].Salik);
      }

      last6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: sum, key: storekey });
        }
      });
    }
    reformatedata.map((mon: any) => {
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    for (const [key, value] of Object.entries(groupByMontharray)) {
      StoreSalikValue = value;
      var sum = 0;
      for (var i = 0; i < StoreSalikValue.length; i++) {
        sum += StoreSalikValue[i].value;
      }
      SalikArray.push(sum);
      OriginalSalikArray.push(sum);
    }

    setFetchData(false);
  };
  const getFuelData = async (fuelarr: any, last6Months: any) => {
    setFetchData(true);

    VipFuelArray = [];
    OriginalVipFuelArray = [];
    var groupBySalik = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupBySalikMonthvalue: any = groupBySalik(fuelarr, "CustomMonth");
    let reformatedata: any = [];
    let finaldata: any = [];

    for (const [key, value] of Object.entries(GroupBySalikMonthvalue)) {
      storekey = key;
      StoreVipFuelValue = value;
      var sum = 0;
      for (var i = 0; i < StoreVipFuelValue.length; i++) {
        sum += parseFloat(StoreVipFuelValue[i].Vipfuel);
      }

      last6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: sum.toFixed(2), key: storekey });
        }
      });
    }
    reformatedata.map((mon: any) => {
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    for (const [key, value] of Object.entries(groupByMontharray)) {
      StoreVipFuelValue = value;
      var sum: number = 0;
      for (var i = 0; i < StoreVipFuelValue.length; i++) {
        sum += StoreVipFuelValue[i].value;
      }
      VipFuelArray.push(Number(sum));
      OriginalVipFuelArray.push(Number(sum));
    }

    setFetchData(false);
  };
  const getFineData = async (Fines: any, last6Months: any) => {
    setFetchData(true);

    FineArray = [];
    OriginalFineArray = [];
    var groupBySalik = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupBySalikMonthvalue: any = groupBySalik(Fines, "CustomMonth");
    let reformatedata: any = [];
    let finaldata: any = [];

    for (const [key, value] of Object.entries(GroupBySalikMonthvalue)) {
      storekey = key;
      StoreConsumptionFine = value;
      var sum = 0;
      for (var i = 0; i < StoreConsumptionFine.length; i++) {
        sum += parseFloat(StoreConsumptionFine[i].Fine);
      }

      last6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: sum.toFixed(2), key: storekey });
        }
      });
    }
    reformatedata.map((mon: any) => {
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    for (const [key, value] of Object.entries(groupByMontharray)) {
      StoreConsumptionFine = value;
      var sum: number = 0;
      for (var i = 0; i < StoreConsumptionFine.length; i++) {
        sum += StoreConsumptionFine[i].value;
      }
      FineArray.push(Number(sum));
      OriginalFineArray.push(Number(sum));
    }

    setFetchData(false);
  };
  const getRepairSapresData = async (repairSpares: any, last6Months: any) => {
    setFetchData(true);

    RepairArrray = [];
    let repairObj: any = {};
    let collectRepair: any = [];
    var groupByRepair = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupByRepairMonthvalue: any = groupByRepair(
      repairSpares,
      "CustomMonth"
    );
    let reformatedata: any = [];
    let finaldata: any = [];
    for (const [key, value] of Object.entries(GroupByRepairMonthvalue)) {
      storekey = key;
      StoreRepair = value;

      var sum = 0;
      // StoreRepair.map((data: any) => {
      //     for (let i = 0; i <= data.spares.items.length; i++) {
      //         const newValue = data.spares.items[i];

      //         if (newValue !== undefined) {
      //             const tax: any = newValue.jfield && JSON.parse(newValue.jfield).tax ? JSON.parse(newValue.jfield).tax : 0;
      //             const totalAmountWithTax: any = Number(newValue.totalamount) * (1 + Number(tax) / 100);
      //             sum += parseFloat(totalAmountWithTax);
      //             repairObj = storekey;
      //         }
      //     }
      // });
      for (var i = 0; i < StoreRepair.length; i++) {
        if (StoreRepair[i].cost !== null && StoreRepair[i].cost !== "NaN") {
          const tax: any =
            StoreRepair[i].jfield && JSON.parse(StoreRepair[i].jfield).tax
              ? JSON.parse(StoreRepair[i].jfield).tax
              : 0;
          const totalAmountWithTax: any =
            StoreRepair[i].totalamount !== "NaN"
              ? Number(StoreRepair[i].totalamount)
              : 0 * (1 + Number(tax) / 100);
          sum += parseFloat(totalAmountWithTax);
        }
      }
      last6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: sum.toFixed(2), key: storekey });
        }
      });
    }
    reformatedata.map((mon: any) => {
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    for (const [key, value] of Object.entries(groupByMontharray)) {
      StoreRepair = value;
      var sum: number = 0;
      for (var i = 0; i < StoreRepair.length; i++) {
        sum += StoreRepair[i].value;
      }

      RepairArrray.push(Number(sum));
    }

    setFetchData(false);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      if (showCRData === true && fetchData === false) {
        let salik = [];
        let fine = [];
        let fuel = [];
        for (let i = 0; i < crSalik.length; i++) {
          let data = crSalik[i] + SalikArray[i];
          salik.push(data);
        }
        for (let i = 0; i < crFine.length; i++) {
          let data = crFine[i] + FineArray[i];
          fine.push(data);
        }
        for (let i = 0; i < crFuel.length; i++) {
          let data = crFuel[i] + VipFuelArray[i];
          fuel.push(data);
        }
        SalikArray = salik;
        FineArray = fine;
        VipFuelArray = fuel;
      } else {
        SalikArray = OriginalSalikArray;
        FineArray = OriginalFineArray;
        VipFuelArray = OriginalVipFuelArray;
      }
      setConsumptionBarData(await getChartData(props.trojan.colorMode));
    };
    fetchAllData();
  }, [showCRData, fetchData]);

  const getReimburseData = async (comparisonLabels: any) => {
    setFetchData(true);
    const filter = {
      and: [
        { date: { ge: localetoisodatetime(new Date(lastfirstdate)) } },
        {
          date: {
            le:
              moment(localetoisodatetime(lastenddate)).format("YYYY-MM-DD") +
              "T23:59:59.999Z",
          },
        },
      ],
    };

    let response: any[] = [];
    let nextToken = null;
    const ac = `date expensetype amount reimbursedamount _deleted`;
    do {
      const { items, nextToken: newNextToken }: any = await dxService.listitems(
        "logreimbursement",
        ac,
        filter,
        true,
        1000,
        nextToken
      );
      response.push(...items);
      nextToken = newNextToken;
    } while (nextToken);

    let filteredResponse = response
      .filter((del: any) => del._deleted !== true)
      .sort((a: any, b: any) =>
        moment(b.date).format().localeCompare(moment(a.date).format())
      );

    const expenseTypes = ["Salik", "Fine", "Fuel"];
    const monthlyAmounts: Record<string, number[]> = {};

    for (const month of comparisonLabels) {
      const [monthName, year] = month.split(" ");

      for (const expenseType of expenseTypes) {
        if (!monthlyAmounts[expenseType]) {
          monthlyAmounts[expenseType] = [];
        }

        const totalAmount: number = filteredResponse
          .filter((item: any) => item.expensetype === expenseType)
          .reduce((total: number, item: any) => {
            const date = new Date(item.Date || item.date);
            if (
              date.toLocaleString("default", { month: "long" }) === monthName &&
              date.getUTCFullYear() === parseInt(year)
            ) {
              const fieldValue = Number(item.amount);
              return total + (isNaN(fieldValue) ? 0 : fieldValue);
            }
            return total;
          }, 0);

        monthlyAmounts[expenseType].push(totalAmount);
      }
    }

    const salikAmounts: number[] = monthlyAmounts["Salik"] || [];
    const fineAmounts: number[] = monthlyAmounts["Fine"] || [];
    const fuelAmounts: number[] = monthlyAmounts["Fuel"] || [];

    setFetchData(false);
    setCRSalik(salikAmounts);
    setCRFine(fineAmounts);
    setCRFuel(fuelAmounts);
    // Perform the addition if the toggle is enabled
  };
  const downloadChart = () => {
    setDownloadProgress(true);
    const chartElement: any = document.getElementById("chart-container");
    const scale = 2; // Increase the scale value for higher resolution

    html2canvas(chartElement, { scale }).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `DashBoard_Consumptions_${lastFiveMonthName}_to_${ThisMonthName}.png`;
      link.click();
      setDownloadProgress(false);
    });
  };
  return (
    <div className="col-12 lg:col-8">
      <div className="card height-100 widget-expenses">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h5 className="centerText">Consumptions</h5>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label htmlFor="inputSwitchId" className="mr-3">
              {showCRData ? "Hide" : "Show"} CR Data
            </label>
            <InputSwitch
              id="inputSwitchId"
              className="mr-5"
              checked={showCRData}
              onChange={() => setShowCRData(!showCRData)}
            />
            <Button
              style={{ height: "22px" }}
              disabled={dprogress}
              onClick={downloadChart}
            >
              {dprogress ? "Please wait..." : "Download"}
            </Button>
          </div>
        </div>

        <div>
          {fetchData === true ? (
            <ProgressSpinner
              aria-label="Loading"
              className=" flex justify-content-center"
            />
          ) : (
            <>
              <Chart
                id="chart-container"
                type="bar"
                plugins={[ChartDataLabels]}
                data={ConsumptionbarData}
                options={chartMonthlyOptions}
              ></Chart>
              <div className="consumption-footer">
                <span className="consumption-notify">*</span>Values are in AED
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardConsumptions;
