import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import AmplifyServices from '../../Service/Service';
import ListTable from '../../Components/ListTable';
import ListTemplate from '../../Components/ListTemplate';
import moment from 'moment';

const DriverAllocations = () => {
    const [drivername, setdrivername] = useState<any>([]);
    const [AllocatedDrivers, setAllocatedDrivers] = useState<any>([]);
    const [Tablefilter, setTableFilters] = useState<any>('');
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    const navigate = useNavigate();
    useEffect(() => {
        getDrivername();
        // getAllocationdetails();
    }, []);
    const getDrivername = async () => {
        AmplifyServices.getFilteredDriverdetails(formid).then((resp: any) => {
            resp = resp.filter((item: any) => item._deleted !== true);
            getAllocationdetails(resp[0].id);
            setdrivername(resp[0].name);
        });

        //   initFilters();
    };
    const getAllocationdetails = async (drivername: any) => {
        AmplifyServices.getAllocateDriver(drivername).then((resp: any) => {
            resp = resp.filter((item: any) => item._deleted !== true).sort((a: any, b: any) => moment(b.fromdate).format().localeCompare(moment(a.fromdate).format()));
            setAllocatedDrivers(resp);
        });

        initFilters();
    };
    const initFilters = () => {
        setTableFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            vehicleid: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            Mileage: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            Salik: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            Accidents: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            drivername: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
            // representative: { value: null, matchMode: FilterMatchMode.IN },
            // date: {
            //     operator: FilterOperator.AND,
            //     constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            // },
        });
    };
    const columns = [
        { field: 'vehicleid', header: 'Vehicle', headeralign: 'left', bodyalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'fromdrivername', header: 'Driver', headeralign: 'left', bodyalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'fromdrivername' },
        { field: 'handoverdrivername', header: 'Allocated To', headeralign: 'left', bodyalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'handoverdrivername' },
        { field: 'fromdate', header: 'From Date', headeralign: 'left', bodyalign: 'left', fieldtype: 'date', filterOption: true, filterFieldOption: 'fromdate' },
        { field: 'handoverdate', header: 'To Date', headeralign: 'left', bodyalign: 'left', fieldtype: 'date', filterOption: true, filterFieldOption: 'handoverdate' },
        { field: 'fromKM', header: 'Starting KM', headeralign: 'center', bodyalign: 'center', fieldtype: 'text', filterOption: true, filterFieldOption: 'fromKM' },
        { field: 'handoverKM', header: 'Ending KM', headeralign: 'center', bodyalign: 'center', fieldtype: 'text', filterOption: true, filterFieldOption: 'handoverKM' },
        { field: 'allocatestatus', header: 'Status', headeralign: 'left', bodyalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'allocatestatus' }
        // { field: 'drivername', header: 'drivername', fieldtype: 'text', filterOption: true, filterFieldOption: 'drivername' },
        // { field: 'date', header: 'date', fieldtype: 'date', showtime: false, filterOption: true, filterFieldOption: 'date' }
        // { field: 'status', header: 'Status', fieldtype: 'custom', filterOption: true, filterFieldOption: 'status', template: (r: any) => <>{contractStatusBodyTemplate(r)}</> }
    ];
    return (
        <>
            {' '}
            <div>
                <div className="grid">
                    <div className="col-12 md:col-12">
                        {/* <Button onClick={NavigateToHome} className="mr-2 mt-2 ml-3">
                            Add Logs
                        </Button> */}
                        <div className="card">
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <h5>Allocation</h5>
                                </div>
                                <div className="field col-12  md:col-12">
                                    <ListTemplate
                                        header="Manage Contracts"
                                        value={AllocatedDrivers}
                                        paginator={false}
                                        rows={100}
                                        dynamicColumns={columns}
                                        emptyMessage="No Allocation found."
                                        responsiveLayout="scroll"
                                        className="p-datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Service Contracts"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[100, 200, 300]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                        tablefilter={Tablefilter}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DriverAllocations;
