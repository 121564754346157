import {
  _getDxApprovalConfig,
  _getDxApprovals,
  _listDxAllocations,
  _listDxAppointments,
  _listDxApprovalConfigSteps,
  _listDxApprovals,
  _listDxContracts,
  _listDxDrivers,
  _listDxLogFines,
  _listDxLogSaliks,
  _listDxLogVipfuels,
  _listDxMasterLogs,
  _listDxUserRoles,
  _listDxVehicleActivities,
  _listDxVehicles,
  _listDxWorkflows,
  _listDxlogMileages,
  _listDxVehicleSpares,
  _listDxNavigations,
  _listDxLogParkings,
  _listDxlogReimbursements,
  _listDxdriverShifts,
  _listDxMileageReports,
  _listDxMileages,
} from "../dxgraphql/dxqueries";
import {
  createDxAllocations,
  createDxAppointments,
  createDxApprovalConfig,
  createDxApprovalConfigSteps,
  createDxApprovals,
  createDxContract,
  createDxDepartment,
  createDxDocument,
  createDxDriver,
  createDxGeneralConfig,
  createDxLogFines,
  createDxLogParking,
  createDxLogSalik,
  createDxLogVipfuel,
  createDxMasterLogs,
  createDxNavigation,
  createDxPermissionsLevels,
  createDxRoles,
  createDxUserProfile,
  createDxUserRoles,
  createDxVehicle,
  createDxVehicleActivity,
  createDxVehicleSpares,
  createDxVendors,
  createDxWorkflow,
  createDxlogMileage,
  createDxlogReimbursement,
  deleteDxAllocations,
  deleteDxAppointments,
  deleteDxApprovalConfig,
  deleteDxApprovalConfigSteps,
  deleteDxApprovals,
  deleteDxContract,
  deleteDxDepartment,
  deleteDxDocument,
  deleteDxDriver,
  deleteDxGeneralConfig,
  deleteDxLogFines,
  deleteDxLogParking,
  deleteDxLogSalik,
  deleteDxLogVipfuel,
  deleteDxMasterLogs,
  deleteDxNavigation,
  deleteDxPermissionsLevels,
  deleteDxRoles,
  deleteDxUserProfile,
  deleteDxUserRoles,
  deleteDxVehicle,
  deleteDxVehicleActivity,
  deleteDxVehicleSpares,
  deleteDxVendors,
  deleteDxWorkflow,
  deleteDxlogMileage,
  deleteDxlogReimbursement,
  updateDxAllocations,
  updateDxAppointments,
  updateDxApprovalConfig,
  updateDxApprovalConfigSteps,
  updateDxApprovals,
  updateDxContract,
  updateDxDepartment,
  updateDxDocument,
  updateDxDriver,
  updateDxGeneralConfig,
  updateDxLogFines,
  updateDxLogParking,
  updateDxLogSalik,
  updateDxLogVipfuel,
  updateDxMasterLogs,
  updateDxNavigation,
  updateDxPermissionsLevels,
  updateDxRoles,
  updateDxUserProfile,
  updateDxUserRoles,
  updateDxVehicle,
  updateDxVehicleActivity,
  updateDxVehicleSpares,
  updateDxVendors,
  updateDxWorkflow,
  updateDxlogMileage,
  updateDxlogReimbursement,
  createDxTableSettings,
  updateDxTableSettings,
  deleteDxTableSettings,
  updateDxshifts,
  deleteDxshifts,
  createDxshifts,
  deleteDxdriverShifts,
  updateDxdriverShifts,
  createDxdriverShifts,
  updateDxMileageReport,
  createDxMileageReport,
  deleteDxMileageReport,
  updateDxMileage,
  deleteDxMileage,
  createDxMileage,
} from "../graphql/mutations";
import {
  getDxAllocations,
  getDxAppointments,
  getDxApprovalConfig,
  getDxApprovalConfigSteps,
  getDxApprovals,
  getDxContract,
  getDxDepartment,
  getDxDocument,
  getDxDriver,
  getDxGeneralConfig,
  getDxLogFines,
  getDxlogMileage,
  getDxLogParking,
  getDxlogReimbursement,
  getDxLogSalik,
  getDxLogVipfuel,
  getDxMasterLogs,
  getDxNavigation,
  getDxPermissionsLevels,
  getDxRoles,
  getDxUserProfile,
  getDxUserRoles,
  getDxVehicle,
  getDxVehicleActivity,
  getDxVehicleSpares,
  getDxVendors,
  getDxWorkflow,
  listDxAllocations,
  listDxAppointments,
  listDxApprovalConfigs,
  listDxApprovalConfigSteps,
  listDxApprovals,
  listDxContracts,
  listDxDepartments,
  listDxDocuments,
  listDxDrivers,
  listDxGeneralConfigs,
  listDxLogFines,
  listDxlogMileages,
  listDxLogParkings,
  listDxlogReimbursements,
  listDxLogSaliks,
  listDxLogVipfuels,
  listDxMasterLogs,
  listDxNavigations,
  listDxPermissionsLevels,
  listDxRoles,
  listDxUserProfiles,
  listDxUserRoles,
  listDxVehicleActivities,
  listDxVehicles,
  listDxVehicleSpares,
  listDxVendors,
  listDxWorkflows,
  getDxTableSettings,
  listDxTableSettings,
  getDxshifts,
  listDxshifts,
  getDxdriverShifts,
  listDxdriverShifts,
  getDxMileageReport,
  listDxMileageReports,
  getDxMileage,
  listDxMileages,
} from "../graphql/queries";

export const getitem: any = {
  appointment: {
    name: "DxAppointments",
    listname: "DxAppointments",
    get: getDxAppointments,
    list: listDxAppointments,
    update: updateDxAppointments,
    delete: deleteDxAppointments,
    create: createDxAppointments,
    _list: _listDxAppointments,
  },
  shifts: {
    name: "Dxshifts",
    listname: "Dxshifts",
    get: getDxshifts,
    list: listDxshifts,
    update: updateDxshifts,
    delete: deleteDxshifts,
    create: createDxshifts,
    _list: null,
  },
  drivershifts: {
    name: "DxdriverShifts",
    listname: "DxdriverShifts",
    get: getDxdriverShifts,
    list: listDxdriverShifts,
    update: updateDxdriverShifts,
    delete: deleteDxdriverShifts,
    create: createDxdriverShifts,
    _list: _listDxdriverShifts,
  },
  tablesettings: {
    name: "DxTableSettings",
    listname: "DxTableSettings",
    get: getDxTableSettings,
    list: listDxTableSettings,
    update: updateDxTableSettings,
    delete: deleteDxTableSettings,
    create: createDxTableSettings,
    _list: null,
  },
  allocation: {
    name: "DxAllocations",
    listname: "DxAllocations",
    get: getDxAllocations,
    list: listDxAllocations,
    update: updateDxAllocations,
    delete: deleteDxAllocations,
    create: createDxAllocations,
    _list: _listDxAllocations,
  },
  renewal: {
    name: "DxContract",
    listname: "DxContracts",
    get: getDxContract,
    list: listDxContracts,
    update: updateDxContract,
    delete: deleteDxContract,
    create: createDxContract,
    _list: _listDxContracts,
  },
  vehicle: {
    name: "DxVehicle",
    listname: "DxVehicles",
    get: getDxVehicle,
    list: listDxVehicles,
    update: updateDxVehicle,
    delete: deleteDxVehicle,
    create: createDxVehicle,
    _list: _listDxVehicles,
  },
  driver: {
    name: "DxDriver",
    listname: "DxDrivers",
    get: getDxDriver,
    list: listDxDrivers,
    update: updateDxDriver,
    delete: deleteDxDriver,
    create: createDxDriver,
    _list: _listDxDrivers,
  },
  vehicleactivity: {
    name: "DxVehicleActivity",
    listname: "DxVehicleActivities",
    get: getDxVehicleActivity,
    list: listDxVehicleActivities,
    update: updateDxVehicleActivity,
    delete: deleteDxVehicleActivity,
    create: createDxVehicleActivity,
    _list: _listDxVehicleActivities,
  },
  vehiclespare: {
    name: "DxVehicleSpares",
    listname: "DxVehicleSpares",
    get: getDxVehicleSpares,
    list: listDxVehicleSpares,
    update: updateDxVehicleSpares,
    delete: deleteDxVehicleSpares,
    create: createDxVehicleSpares,
    _list: _listDxVehicleSpares,
  },
  generalconfiguration: {
    name: "DxGeneralConfig",
    listname: "DxGeneralConfigs",
    get: getDxGeneralConfig,
    list: listDxGeneralConfigs,
    update: updateDxGeneralConfig,
    delete: deleteDxGeneralConfig,
    create: createDxGeneralConfig,
    _list: null,
  },
  navigationconfiguration: {
    name: "DxNavigation",
    listname: "DxNavigations",
    get: getDxNavigation,
    list: listDxNavigations,
    update: updateDxNavigation,
    delete: deleteDxNavigation,
    create: createDxNavigation,
    _list: _listDxNavigations,
  },
  workflow: {
    name: "DxWorkflow",
    listname: "DxWorkflows",
    get: getDxWorkflow,
    list: listDxWorkflows,
    update: updateDxWorkflow,
    delete: deleteDxWorkflow,
    create: createDxWorkflow,
    _list: _listDxWorkflows,
  },
  document: {
    name: "DxDocument",
    listname: "DxDocuments",
    get: getDxDocument,
    list: listDxDocuments,
    update: updateDxDocument,
    delete: deleteDxDocument,
    create: createDxDocument,
    _list: null,
  },
  masterlog: {
    name: "DxMasterLogs",
    listname: "DxMasterLogs",
    get: getDxMasterLogs,
    list: listDxMasterLogs,
    update: updateDxMasterLogs,
    delete: deleteDxMasterLogs,
    create: createDxMasterLogs,
    _list: _listDxMasterLogs,
  },
  approval: {
    name: "DxApprovals",
    listname: "DxApprovals",
    get: getDxApprovals,
    list: listDxApprovals,
    update: updateDxApprovals,
    delete: deleteDxApprovals,
    create: createDxApprovals,
    _list: _listDxApprovals,
    _get: _getDxApprovals,
  },
  approvalconfig: {
    name: "DxApprovalConfig",
    listname: "DxApprovalConfigs",
    get: getDxApprovalConfig,
    list: listDxApprovalConfigs,
    update: updateDxApprovalConfig,
    delete: deleteDxApprovalConfig,
    create: createDxApprovalConfig,
    _list: null,
    _get: _getDxApprovalConfig,
  },
  approvalconfigsteps: {
    name: "DxApprovalConfigSteps",
    listname: "DxApprovalConfigSteps",
    get: getDxApprovalConfigSteps,
    list: listDxApprovalConfigSteps,
    update: updateDxApprovalConfigSteps,
    delete: deleteDxApprovalConfigSteps,
    create: createDxApprovalConfigSteps,
    _list: _listDxApprovalConfigSteps,
    _get: null,
  },
  userprofile: {
    name: "DxUserProfile",
    listname: "DxUserProfiles",
    get: getDxUserProfile,
    list: listDxUserProfiles,
    update: updateDxUserProfile,
    delete: deleteDxUserProfile,
    create: createDxUserProfile,
    _list: null,
  },
  permissionlevels: {
    name: "DxPermissionsLevels",
    listname: "DxPermissionsLevels",
    get: getDxPermissionsLevels,
    list: listDxPermissionsLevels,
    update: updateDxPermissionsLevels,
    delete: deleteDxPermissionsLevels,
    create: createDxPermissionsLevels,
    _list: null,
  },
  userrole: {
    name: "DxUserRoles",
    listname: "DxUserRoles",
    get: getDxUserRoles,
    list: listDxUserRoles,
    update: updateDxUserRoles,
    delete: deleteDxUserRoles,
    create: createDxUserRoles,
    _list: _listDxUserRoles,
  },
  roles: {
    name: "DxRoles",
    listname: "DxRoles",
    get: getDxRoles,
    list: listDxRoles,
    update: updateDxRoles,
    delete: deleteDxRoles,
    create: createDxRoles,
    _list: null,
  },
  vendor: {
    name: "DxVendors",
    listname: "DxVendors",
    get: getDxVendors,
    list: listDxVendors,
    update: updateDxVendors,
    delete: deleteDxVendors,
    create: createDxVendors,
    _list: null,
  },
  department: {
    name: "DxDepartment",
    listname: "DxDepartments",
    get: getDxDepartment,
    list: listDxDepartments,
    update: updateDxDepartment,
    delete: deleteDxDepartment,
    create: createDxDepartment,
    _list: null,
  },
  logvipfuels: {
    name: "DxLogVipfuel",
    listname: "DxLogVipfuels",
    get: getDxLogVipfuel,
    list: listDxLogVipfuels,
    update: updateDxLogVipfuel,
    delete: deleteDxLogVipfuel,
    create: createDxLogVipfuel,
    _list: _listDxLogVipfuels,
  },
  logfines: {
    name: "DxLogFines",
    listname: "DxLogFines",
    get: getDxLogFines,
    list: listDxLogFines,
    update: updateDxLogFines,
    delete: deleteDxLogFines,
    create: createDxLogFines,
    _list: _listDxLogFines,
  },
  logsalik: {
    name: "DxLogSalik",
    listname: "DxLogSaliks",
    get: getDxLogSalik,
    list: listDxLogSaliks,
    update: updateDxLogSalik,
    delete: deleteDxLogSalik,
    create: createDxLogSalik,
    _list: _listDxLogSaliks,
  },
  logmileage: {
    name: "DxlogMileage",
    listname: "DxlogMileages",
    get: getDxlogMileage,
    list: listDxlogMileages,
    update: updateDxlogMileage,
    delete: deleteDxlogMileage,
    create: createDxlogMileage,
    _list: _listDxlogMileages,
  },
  mileagereport: {
    name: "DxMileage",
    listname: "DxMileages",
    get: getDxMileage,
    list: listDxMileages,
    update: updateDxMileage,
    delete: deleteDxMileage,
    create: createDxMileage,
    _list: _listDxMileages,
  },
  logparking: {
    name: "DxLogParking",
    listname: "DxLogParkings",
    get: getDxLogParking,
    list: listDxLogParkings,
    update: updateDxLogParking,
    delete: deleteDxLogParking,
    create: createDxLogParking,
    _list: _listDxLogParkings,
  },
  logreimbursement: {
    name: "DxlogReimbursement",
    listname: "DxlogReimbursements",
    get: getDxlogReimbursement,
    list: listDxlogReimbursements,
    update: updateDxlogReimbursement,
    delete: deleteDxlogReimbursement,
    create: createDxlogReimbursement,
    _list: _listDxlogReimbursements,
  },
};
