import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import Documents from "./Documents";
import { Button as AwsButton } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { getNextQueryData, localetoisodatetime } from "../../utils/helper";
import dxService from "../../Services/dxService";
import moment from "moment";
const currentDate = new Date();

// Get the month and year of the previous month
const previousMonth = currentDate.getMonth() - 1;
const previousYear = currentDate.getFullYear();

// Create a new Date object for the previous month's start date
const previousMonthStartDate = new Date(previousYear, previousMonth, 1);

// Create a new Date object for the current month's start date
const currentMonthStartDate = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);

// Set the previous month's end date to the day before the current month's start date
const previousMonthEndDate = new Date(currentMonthStartDate.getTime() - 1);

// Format the start and end dates as desired
const previousMonthStartDateFormatted =
  previousMonthStartDate.toLocaleDateString();
const previousMonthEndDateFormatted = previousMonthEndDate.toLocaleDateString();
// console.log(previousMonthEndDateFormatted);
let Shiftitems: any = [];
let savedData: any = [];
let StoreDate: any = [];
let StoreDescription: any = [];
let updatedData: any = [];
function NewShiftImport() {
  const navigate = useNavigate();
  const [driver, setDriver] = useState<any>([]);
  const [sdate, setsdate] = useState(new Date(previousMonthStartDateFormatted));
  const [edate, setedate] = useState(new Date(previousMonthEndDateFormatted));
  const [charges, setCharges] = useState<any>([]);
  const [jsonData, setJsonData] = useState<any>([]);
  const [savingData, setSavingData] = useState(false);
  const [submit, setSubmited] = useState(false);
  const [Vehicle, setVehicle] = useState<any>([]);
  const [drivername, sedrivername] = useState<any>([]);
  const [drivershifts, setdrivershifts] = useState<any>([]);
  const validateallocations = async () => {
    // console.log(jsonData);
  };
  useEffect(() => {
    //This function fetch all the allocation based on the date range
    getAllocatedDriverDetails().then((res: any) => {
      setDriver(res);
    });
    //this func fetch all the vehicle
    getAllvehicles().then((res) => {
      setVehicle(res);
    });
    //this function fetch the driver shifts
    getDxdriverShifts();
    //this function fetch the drivers available in the erp
    getDrivers();
  }, [charges]);

  const getDrivers = async () => {
    let drivername = await dxService.listitems("driver", "*");
    sedrivername(drivername);
  };

  const getDxdriverShifts = async (nextToken?: any) => {
    let variables: any = {
      // id: { eq: "169046802759541" },
    };
    await getNextQueryData("", variables, "drivershifts", true).then(
      (ds: any) => {
        // console.log(ds);

        setdrivershifts(ds);
      }
    );
  };
  const getAllocatedDriverDetails = async (nextToken?: any) => {
    let variables: any = {};

    var sd = new Date(sdate);
    var ed = new Date(edate).setHours(23, 59, 59);

    let filter = {
      or: [
        {
          and: [
            { fromdate: { ge: localetoisodatetime(sd) } },
            { handoverdate: { attributeExists: true } },
            { handoverdate: { le: localetoisodatetime(ed) } },
          ],
        },
        {
          and: [
            { fromdate: { le: localetoisodatetime(sd) } },
            { handoverdate: { attributeExists: false } },
          ],
        },
        {
          and: [
            {
              fromdate: { le: localetoisodatetime(sd) },
            },
            { handoverdate: { ge: localetoisodatetime(ed) } },
          ],
        },
        {
          and: [
            {
              fromdate: { le: localetoisodatetime(sd) },
            },
            { handoverdate: { le: localetoisodatetime(ed) } },
            { handoverdate: { ge: localetoisodatetime(sd) } },
          ],
        },
        {
          and: [
            {
              fromdate: { ge: localetoisodatetime(sd) },
            },
            {
              fromdate: { le: localetoisodatetime(ed) },
            },
            { handoverdate: { attributeExists: false } },
          ],
        },
      ],
    };

    variables = filter;
    let GetResponse: any = await dxService.listitems(
      "allocation",
      "*",
      variables,
      true,
      1000,
      nextToken
    );
    let response = GetResponse.items;

    let nexttoken = GetResponse.nextToken;
    if (nexttoken) {
      // const nextResults = await getAllocatedDriverDetails(nextToken);
      // response = response.concat(nextResults);
      await getAllocatedDriverDetails(nexttoken).then((res: any) => {
        setDriver((olditems: any) => [...olditems, ...res]);
      });
    }
    // let GetResponse: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    // let res = GetResponse.data.listDxAllocations.items;
    // console.log(response);
    return response;
  };
  const getAllvehicles = async (nextToken?: any) => {
    let variables: any = {};
    let GetResponse: any = await dxService.listitems(
      "vehicle",
      "*",
      variables,
      true,
      100,
      nextToken
    );
    let response = GetResponse.items;

    let nexttoken = GetResponse.nextToken;
    if (nexttoken) {
      await getAllvehicles(nexttoken).then((res: any) => {
        setVehicle((olditems: any) => [...olditems, ...res]);
      });
    }

    // let GetResponse: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    // let res = GetResponse.data.listDxAllocations.items;
    return response;
  };
  const FinddriverId = async (id: any, type: any) => {
    let filter = {
      name: {
        contains: type == "shift" ? id.driver : id,
      },
    };
    let drivername = await dxService.listitems("driver", "*", filter);
    // console.log(approvalresponse);
    return drivername;
  };
  const Finddriverallocation = async (id: any, vid: any) => {
    let filter = {
      vehicleid: {
        eq: vid,
      },
    };
  };
  const checkNaughtyDriver = async () => {
    if (charges.name === "Shift") {
      if (jsonData.length > 0) {
        jsonData.map(async (item: any) => {
          let createitem = {
            id: item.id,
            shift: item.id,
            starttime: item.StartTime,
            endtime: item.EndTime,
          };
          // console.log(createitem);

          let master = await dxService.createitem("shifts", createitem, false);
          // console.log("Saved to master log", master);
        });
      }
    } else if (charges.name === "DriverShift") {
      setSubmited(true);
      let storeitem: any = [];
      //   console.log(jsonData, "save");
      let reformatjson: any = [];

      // Extract the header row to get the driver names
      let drivers = Object.keys(jsonData[0]).filter(
        (key) => key !== "IIII" && key !== "__EMPTY"
      );

      // Iterate over the json array
      for (let i = 1; i < jsonData.length; i++) {
        const date = jsonData[i]["IIII"];
        const day = jsonData[i]["__EMPTY"];

        // Iterate over the drivers
        drivers.forEach((driver) => {
          //   console.log(jsonData, "save");
          const shift: any = jsonData[i][driver];

          // Create a new object with the desired format
          reformatjson.push({ date, driver, shift, day });
        });
      }

      //   console.log(reformatjson);
      reformatjson.forEach(async (item: any) => {
        // console.log(item);

        await FinddriverId(item, "shift").then(async (success: any) => {
          // console.log(success);
          if (success.length > 0) {
            let createitem = {
              date: localetoisodatetime(new Date(item.date)),
              day: item.day,
              dxDriverDrivershiftsId: success[0].id,
              // driver: success[0].name,
              dxshiftsDrivershiftId: item.shift,
              // shift: item.shift,
            };
            storeitem.push(createitem);
            // console.log(storeitem);
            let drivershift = await dxService.createitem(
              "drivershifts",
              createitem,
              false
            );
            // console.log("Saved to master log", drivershift);
          }
        });
      });
    } else if (charges.name === "Mileage") {
      Shiftitems = [];
      StoreDate = [];
      if (jsonData) {
        const formattedJson = jsonData.map(async (item: any) => {
          // console.log(item);

          if (Object.keys(item).length > 7) {
            console.log(item);

            let keys = Object.keys(item);
            const StartdateString = item[keys[1]].split(" ")[1];

            const startparts =
              StartdateString !== undefined && StartdateString.split(".");
            const startformattedDateString = `${startparts[1]} ${startparts[0]} ${startparts[2]}`;
            const sdate = new Date(startformattedDateString);
            // console.log(sdate);
            const EnddateString = item[keys[1]].split(" ")[1];
            const endparts =
              EnddateString !== undefined && EnddateString.split(".");
            const endformattedDateString = `${endparts[1]} ${endparts[0]} ${endparts[2]}`;
            const edate = new Date(endformattedDateString);
            let storedname = item[keys[0]];
            let dname = storedname.split(" ");
            console.log(dname);

            var secondToLastItem = dname[1];
            console.log(secondToLastItem);
            Object.assign(item, {
              description: item[keys[0]],
              vehicleid: item[keys[0]].split(" ").pop(),
              startdatetime: item[keys[1]],
              startdate: new Date(sdate),
              enddate: new Date(edate),
              enddatetime: item[keys[2]],
              Start: item[keys[3]],
              dname: secondToLastItem,
              // Startaddress: item[keys[4]],
              End: item[keys[4]],
              // Endaddress: item[keys[5]],
              duration: item[keys[5]],
              distance: item[keys[6]],
              avgspeed: item[keys[7]],
              maxspeed: item[keys[8]],
            });
            let nameRegex = /^[a-zA-Z]+$/;
            // console.log(nameRegex, secondToLastItem);
            if (nameRegex.test(secondToLastItem)) {
              //   console.log(nameRegex, dname);

              let driname = drivername.filter((d: any) =>
                d.name.toLowerCase().includes(secondToLastItem.toLowerCase())
              );
              console.log(driname);
              item["driverid"] = driname[0].id;
              item["driver"] = driname[0].name;
            } else {
              // console.log(driver);
              let formatdate = moment(sdate).format("DD-MMM-YYYY");
              //   console.log(formatdate);
              let dname = driver.filter(
                (d: any) =>
                  d.vehicleid == item[keys[0]].split(" ").pop() &&
                  (d.fromdate !== null && d.handoverdate !== null
                    ? moment(formatdate).isBetween(
                        new Date(d.fromdate),
                        new Date(d.handoverdate)
                      )
                    : new Date(formatdate) > new Date(d.fromdate))
              );
              // console.log(dname);
              item["driverid"] = dname[0].driver.id;
              item["driver"] = dname[0].driver.name;
            }
            StoreDate.push(item);
            // StoreDescription.push({
            //   des: item.description,
            //   date: item.startdate,
            // });
            // console.log(item);
          }
        });
      }
      console.log(StoreDate);

      let commonShifts: any = [];
      commonShifts = StoreDate.map(async (item: any) => {
        let storedname = item.description;
        let dname = storedname.split(" ");
        const secondToLastItem = dname[1];
        // console.log(dname, secondToLastItem);
        // function findCommonItem(item: any) {
        drivershifts.find((bItem: any) => {
          //   console.log(bItem);
          let formatShiftitems = moment(new Date(bItem.date)).format(
            "MM-DD-YY"
          );
          let formatitem = moment(new Date(item.startdate)).format("MM-DD-YY");
          // console.log(
          //   formatitem === formatShiftitems,
          //   bItem.driver.name,
          //   secondToLastItem
          // );
          if (
            formatitem === formatShiftitems &&
            bItem.driver.name
              .toLowerCase()
              .includes(secondToLastItem.toLowerCase())
          ) {
            // console.log(bItem);
            // console.log(item, bItem);
            let mstart = `${item.startdatetime.split(" ")[0]}.000Z`;
            let mend = `${item.enddatetime.split(" ")[0]}.000Z`;
            const inputTime = item.startdatetime;
            const [timePart, datePart] = inputTime.split(" ");
            const [hours, minutes, seconds] = timePart.split(":");
            const [day, month, year] = datePart.split(".");

            // Create a new Date object in UTC using the provided year, month (zero-based), day, hours, minutes, and seconds
            const utcDate = new Date(
              Date.UTC(year, month - 1, day, hours, minutes, seconds)
            );

            // Get the ISO 8601 formatted date string in UTC (with .000Z milliseconds)
            const isoDateString = utcDate.toISOString();

            // console.log(isoDateString);

            // console.log(
            //   bItem.shift,
            //   bItem.shift.starttime !== null && bItem.shift.starttime
            // );
            if (bItem.shift == null) {
              console.log(bItem);
            }
            let shstart =
              bItem.shift && bItem.shift.starttime !== null
                ? bItem.shift.starttime
                : bItem.shift.id;
            let shend =
              bItem.shift.endtime !== null
                ? bItem.shift.endtime
                : bItem.shift.id;
            // console.log(item, bItem);
            const extractTime = (timeString: any) => {
              const [hours, minutes, seconds] = timeString
                .slice(0, -1)
                .split(":")
                .map(Number);
              return new Date(1970, 0, 1, hours, minutes, seconds).getTime();
            };

            const aTime = extractTime(mstart);
            let bTime = extractTime(
              mend == "00:00:00.000Z" ? "24:00:00.000Z" : mend
            );

            const cTime = extractTime(
              shstart == "00:00:00.000Z" ? "24:00:00.000Z" : shstart
            );
            const dTime = extractTime(
              shend == "00:00:00.000Z" ? "24:00:00.000Z" : shend
            );

            const formatTimeDifference = (milliseconds: any) => {
              const pad = (num: any) => String(num).padStart(2, "0");
              const hours = Math.floor(milliseconds / (60 * 60 * 1000));
              const minutes = Math.floor(
                (milliseconds % (60 * 60 * 1000)) / (60 * 1000)
              );
              const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
              const millisecondsPart = milliseconds % 1000;

              return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
            };
            const isATimeInRange = aTime >= cTime && aTime <= dTime;
            const isBTimeInRange = bTime >= cTime && bTime <= dTime;

            const differenceAC = Math.abs(aTime - cTime);
            const differenceBD = Math.abs(bTime - dTime);

            const formattedDifferenceAC = formatTimeDifference(differenceAC);
            const formattedDifferenceBD = formatTimeDifference(differenceBD);

            if (isATimeInRange && isBTimeInRange) {
              //   console.log("a and b are contained within c and d.");
              const differenceAC = Math.abs(aTime - bTime);
              const formattedDifferenceAC = formatTimeDifference(differenceAC);
              // console.log(shstart, shend, mstart, mend);

              // console.log(
              //   "a and b are contained within c and d:",
              //   formattedDifferenceAC
              // );
              item["inshifttime"] = formattedDifferenceAC;
              item["totalshifttime"] = "in";
            } else {
              const isATimeInRange = aTime >= cTime && aTime <= dTime;
              const isBTimeInRange = bTime >= cTime && bTime <= dTime;
              if (isATimeInRange == true && isBTimeInRange == false) {
                const differenceAC = Math.abs(aTime - dTime);
                const differenceBC = Math.abs(bTime - dTime);

                const formattedDifferenceAC =
                  formatTimeDifference(differenceAC);
                const formattedDifferenceBC =
                  formatTimeDifference(differenceBC);

                // console.log(
                //   `Difference between a and c: ${formattedDifferenceAC}`
                // );
                // console.log(
                //   `Difference between b and c: ${formattedDifferenceBC}`
                // );
                item["outshifttime"] = formattedDifferenceBC;
                item["inshifttime"] = formattedDifferenceAC;
                item["totalshifttime"] = "inOut";
              } else if (isATimeInRange == false && isBTimeInRange == true) {
                //finding the inshift time and outshift time

                const differenceAC = Math.abs(aTime - cTime);
                const differenceBC = Math.abs(bTime - cTime);

                const formattedDifferenceAC =
                  formatTimeDifference(differenceAC);
                const formattedDifferenceBC =
                  formatTimeDifference(differenceBC);

                // console.log(
                //   `Difference between a and c: ${formattedDifferenceAC}`
                // );
                // console.log(
                //   `Difference between b and c: ${formattedDifferenceBC}`
                // );
                item["outshifttime"] = formattedDifferenceAC;
                item["inshifttime"] = formattedDifferenceBC;
                item["totalshifttime"] = "outIn";
              } else {
                // const differenceAC = Math.abs(aTime - cTime);
                const differenceBC = Math.abs(aTime - bTime);

                // const formattedDifferenceAC =
                //   formatTimeDifference(differenceAC);
                const formattedDifferenceBC =
                  formatTimeDifference(differenceBC);
                // console.log(shstart, shend, mstart, mend);
                // console.log(
                //   `Difference between a and c: ${formattedDifferenceAC}`
                // );
                // console.log(
                //   `Difference between b and c: ${formattedDifferenceBC}`
                // );
                item["outshifttime"] = formattedDifferenceBC;
                item["totalshifttime"] = "out";
              }
              //   console.log(`Is a in between c and d? ${isATimeInRange}`);
              //   console.log(`Is b in between c and d? ${isBTimeInRange}`);
            }
            // console.log(item, bItem);
            item["drivershiftid"] = bItem.id;

            // console.log(mstart, mend, shstart, shend);
            item["sortstartdatetime"] = isoDateString;

            savedData.push(item);

            return item;
          }
        });
        // }

        // commonShifts = await StoreDate.filter(findCommonItem);
      });
      console.log(savedData);
      savedData.map((item: any) => {
        let createmileageitem = {
          vehicleid: item.vehicleid,
          description: item.description,
          startKM: item.Start,
          endKM: item.End,
          // startAddress: item.Startaddress,
          // endAddress: item.Endaddress,
          drivername: item.driver,
          driverid: item.driverid,
          // dxdriverShiftsDrivershiftId: item.drivershiftid,
          sortstartdatetime: item.sortstartdatetime,
          dxDriverMileagedriverId: item.driverid,
          dxdriverShiftsMileagereportId: item.drivershiftid,
          startdate: localetoisodatetime(item.startdate),
          enddate: localetoisodatetime(item.enddate),
          startdatetime: item.startdatetime.split(" ")[0],
          enddatetime: item.enddatetime.split(" ")[0],
          id: Math.random().toString(36).substring(2, 11),
          duration: item.duration,
          avgspeed: item.avgspeed,
          maxspeed: item.maxspeed,
          distance: item.distance,
          tablename: "Mileage",
          totalshifttime: item.totalshifttime,
          inshifttime:
            item.inshifttime &&
            item.inshifttime.replaceAll(")", "").replaceAll("(", ""),
          outshifttime:
            item.outshifttime &&
            item.outshifttime.replaceAll(")", "").replaceAll("(", ""),
        };
        // savedData.push(createmileageitem);

        // let os = dxService.createitem("mileagereport", createmileageitem);
      });
    }
  };
  return (
    <>
      <div className="col-12 md:col-12">
        <h4>Import Shift</h4>
        <div className="card p-fluid">
          <div className="field">
            <div className="col-12 sm:col-8 md:col-7 grid">
              <div className="col-12 md:col-2 mt-2">
                <label htmlFor="">Select</label>
              </div>
              <div className="col-12 md:col-10">
                <Dropdown
                  options={[
                    { label: "Shifts", name: "Shifts" },
                    { label: "DriverShift", name: "DriverShift" },
                    { label: "Mileage", name: "Mileage" },
                  ]}
                  value={charges}
                  onChange={(e) => {
                    savedData = [];
                    updatedData = [];
                    setCharges(e.target.value);
                  }}
                  optionLabel="label"
                  placeholder="Select an option"
                  filter
                />
              </div>
            </div>
            <div className="col-12 sm:col-8 md:col-7 grid">
              <div className="col-12 md:col-2 mt-2">
                <label htmlFor="">Start Date</label>
              </div>
              <div className="col-12 md:col-4">
                <Calendar
                  value={sdate}
                  onChange={(e: any) => {
                    setsdate(e.value);
                  }}
                ></Calendar>
              </div>
              <div className="col-12 md:col-2 mt-2">
                <label htmlFor="">End Date</label>
              </div>
              <div className="col-12 md:col-4">
                <Calendar
                  value={edate}
                  onChange={(e: any) => {
                    setedate(e.value);
                  }}
                ></Calendar>
              </div>
              {/* <div className="col-12 md:col-4">
                                <Button onClick={(e: any) => validateallocations()} label="Validate Allocations" />
                            </div> */}
            </div>
          </div>
          {charges.name !== undefined && (
            <div className="field">
              <div className="col-12 sm:col-8 md:col-7 grid">
                <div className="col-12 md:col-2 mt-2">
                  <label htmlFor="">Upload File</label>
                </div>
                <div className="col-12 md:col-10">
                  <Documents setJsonData={setJsonData} />
                  {/* <FileUpload accept=".xlsx, .xls*" auto={true} customUpload={true} uploadHandler={handleFileUpload} /> */}
                </div>
              </div>
            </div>
          )}
          <div className="field col-5 grid md:col-3">
            <AwsButton
              className=" mr-2"
              disabled={submit === true ? true : false}
              onClick={async () => {
                checkNaughtyDriver();
                // validatedriver();
              }}
            >
              {savingData === false ? "Submit" : "Saving"}
            </AwsButton>
            <AwsButton
              disabled={savingData ? true : false}
              style={{
                background: "#d32f2f",
                color: "#fff",
                border: "none",
              }}
              onClick={async () => {
                navigate("/");
              }}
            >
              Cancel
            </AwsButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewShiftImport;
