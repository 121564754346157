import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import React, { useRef, useState } from 'react';
import AddUser from '../AddUser';
import RemoveUser from '../RemoveUser';
import styles from './Navigation.module.scss';

function Navigation(props: any) {
    const addMenu = useRef<any>(null);
    const removeMenu = useRef<any>(null);
    const [hideAddUser, setHideAddUser] = useState(false);
    const [hideRemoveUser, setHideRemoveUser] = useState(false);

    const onHide = (e: any) => {
        if (e == 'AddUser') {
            setHideAddUser(false);
        } else {
            setHideRemoveUser(false);
        }
    };

    const onDropDownSelect = (e: any) => {
        if (e.value == 'AddUser') {
            setHideAddUser(true);
        } else if (e.value == 'RemoveUser') {
            setHideRemoveUser(true);
        } else {
            if (props.removeUsers.length > 0) {
                console.log('Hello');
                window.location.href = `mailto:${props.removeUsers}`;
            } else {
                alert('Select user');
            }
        }
    };
    const newOptions = [
        {
            label: 'Add user to a group',
            icon: 'pi pi-fw pi-user-plus',
            command: () => {
                setHideAddUser(true);
            }
        }
    ];
    const actionOptions = [
        {
            label: 'Send Message',
            icon: 'pi pi-fw pi-envelope',
            command: () => {
                if (props.removeUsers.length > 0) {
                    console.log('Hello');
                    window.location.href = `mailto:${props.removeUsers}`;
                } else {
                    alert('Select user');
                }
            }
        },
        {
            label: 'Remove user from this group',
            icon: 'pi pi-fw pi-trash',
            command: () => {
                if (props.removeUsers.length > 0) {
                    setHideRemoveUser(true);
                } else {
                    alert('Select User to remove');
                }
            }
        }
    ];
    return (
        <>
            <nav className={styles.navigation}>
                {/* <Dropdown options={newOptions} placeholder="New" onChange={onDropDownSelect} /> */}
                <Menu ref={addMenu} model={newOptions} popup />
                <Button type="button" label="New" icon="pi pi-angle-down" onClick={(e) => addMenu.current.toggle(e)} style={{ width: 'auto' }} className="mr-2" />
                <Dialog visible={hideAddUser} onHide={() => onHide('AddUser')} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw' }}>
                    <AddUser group={props.group} hide={setHideAddUser} />
                </Dialog>

                {/* <Dropdown options={actionOptions} placeholder="Actions" onChange={onDropDownSelect} /> */}
                <Menu ref={removeMenu} model={actionOptions} popup />
                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => removeMenu.current.toggle(e)} style={{ width: 'auto' }} />
                <Dialog visible={hideRemoveUser} onHide={() => onHide('RemoveUser')} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw' }}>
                    <RemoveUser group={props.group} hide={setHideRemoveUser} removeUser={props.removeUsers} selectedCheckBox={props.selectedCheckBox} />
                </Dialog>
            </nav>
        </>
    );
}

export default Navigation;
