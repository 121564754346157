import React, { useEffect, useState, useRef } from 'react';
import dxService from '../../../../Services/dxService';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getTypeColor, getTypeColors } from '../../../../utils/color';
const chartOptions = {
    responsive: true,
    cutoutPercentage: 50,
    legend: {
        position: 'bottom'
    },
    plugins: {
        legend: {
            display: true
        },
        datalabels: {
            display: true,
            color: '#000',
            align: 'end', // Adjusted alignment to start
            anchor: 'end', // Adjusted anchor to end
            clamp: true,
            font: {
                weight: '700'
            }
        },
        tooltip: {
            callbacks: {
                label: (context: any) => {
                    return `${context.dataset.label}: AED ${context.raw}`;
                }
            }
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            }
        },
        y: {
            beginAtZero: true
        }
    }
};

export const DriverDashboardConsumptions = (props: any) => {
    const colors = getTypeColors('dark');
    const currentDate = new Date();
    const [consumption, setConsumption] = useState<any>([]);
    const [colorcode, setcolorcode] = useState<any>([]);
    const chartref = useRef<any>(null);
    useEffect(() => {
        getTypeColor('dark').then((i: any) => {
            setcolorcode((prevArray: any) => [...prevArray, i]);
        });
        getConsumptionData().then((res) => {
            setConsumption(res);
            props.getdata(res);
        });
    }, [props.name]);

    const getConsumptionData = async () => {
        let nextToken = null;
        let response = [];

        do {
            const variables: any = {
                drivername: {
                    eq: props && props.name
                }
            };

            const masterlogdata: any = await dxService.listitems('masterlog', '*', variables, true, 1000, nextToken);
            const items = masterlogdata.items.filter((del: any) => del._deleted !== true);
            response.push(...items);

            nextToken = masterlogdata.nextToken;
        } while (nextToken);

        return response;
    };
    // const dateBodyTemplate = (rowData: any) => {
    //     //return formatDate(rowData.date);
    //     return <>{convertdate(isotolocaledatetime(rowData.date))}</>;
    // };
    // const dateFilterTemplate = (options: any) => {
    //     return <Calendar value={options.value} onChange={(e: any) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    // };
    //formatting the data to dispaly in a chart
    // Step 1: Extract relevant fields and convert date to proper date object
    const formattedData = consumption.map((obj: { Vipfuel: any; Salik: any; Fine: any; Parking: any; date: string | number | Date }) => ({
        Vipfuel: obj.Vipfuel,
        Salik: obj.Salik,
        Fine: obj.Fine,
        Parking: obj.Parking,
        date: new Date(obj.date)
    }));

    // Step 2: Get the last 5 months
    const currentMonth = currentDate.getMonth();
    const lastFiveMonths: number[] = [];
    for (let i = 4; i >= 0; i--) {
        const month = (currentMonth - i + 12) % 12; // Wrap around for previous year
        lastFiveMonths.push(month);
    }

    // Step 3: Create an object to store the total amounts for each month and field
    const totalsByMonth: any = {};

    // Step 4: Calculate total amount for each month and field
    lastFiveMonths.forEach((month) => {
        const monthlyData = formattedData.filter((obj: { date: { getMonth: () => number } }) => obj.date.getMonth() === month);
        const totalAmounts: any = {};

        monthlyData.forEach((obj: { [x: string]: any }) => {
            for (const field in obj) {
                if (field !== 'date') {
                    totalAmounts[field] = (totalAmounts[field] || 0) + Number(obj[field]);
                }
            }
        });

        totalsByMonth[month] = totalAmounts;
    });
    // Helper function to generate random color
    const getRandomColor = (type: any) => {
        // console.log(colorcode);

        let machu = type === 'Vipfuel' ? colorcode[0].Vipfuel : type === 'Salik' ? colorcode[0].Salik : type === 'Fine' ? colorcode[0].Fine : type === 'Parking' ? colorcode[0].Parking : '';
        const color = machu;

        return color;
    };
    // Step 6: Convert the resulting object into the desired format for primereact chart
    const labels = lastFiveMonths.map((month) => new Date(currentDate.getFullYear(), month).toLocaleString('default', { month: 'long' }));

    const fieldNames = formattedData.length > 0 ? Object.keys(formattedData[0]).filter((field) => field !== 'date') : [];

    const datasets = fieldNames.map((field: any) => ({
        label: field,
        data: lastFiveMonths.map((month) => (totalsByMonth[month][field] || 0).toFixed(2)),
        backgroundColor: getRandomColor(field),
        borderColor: getRandomColor(field),
        borderWidth: 1
    }));

    const chartData = {
        labels,
        datasets
    };

    return (
        <div>
            {/* <DataTable value={consumption} className="p-datatable-responsive" responsiveLayout="scroll" paginator rows={300} scrollable scrollHeight="60vh">
                <Column
                    field="date"
                    sortable
                    //  body={actionTemplateDate}
                    header="DATE"
                    dataType="date"
                    style={{ minWidth: '10rem' }}
                    body={dateBodyTemplate}
                    filter
                    filterElement={dateFilterTemplate}
                />
                <Column field="vehicleid" sortable header="VEHICLE" />
                <Column
                    field="Mileage"
                    header="KM TRAVELLED"
                    body={(rowData) => {
                        return rowData.Mileage ? rowData.Mileage : '-';
                    }}
                />
                <Column field="Parking" header="PARKING" />
                <Column field="Accidents" header="ACCIDENTS" />
                <Column
                    field="reimbursement"
                    sortable
                    body={(data) => {
                        return <>{data.reimbursement ? data.reimbursement : '-'}</>;
                    }}
                    header="REIMBURSEMENT (AED)"
                />
                <Column field="Fine" sortable header="FINE (AED)" />
                <Column field="Salik" sortable header="SALIK (AED)" />
                <Column field="Vipfuel" sortable header="VIP FUEL (AED)" />
            </DataTable> */}
            <h5>Consumptions</h5>
            <div className="col-12 md:col-12 lg:col-12">
                <Chart
                    id="chart-container"
                    ref={chartref}
                    type="bar"
                    data={chartData}
                    options={chartOptions}
                    plugins={[ChartDataLabels]}
                    //  height="400px"
                />
            </div>
        </div>
    );
};
