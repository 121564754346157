import React from 'react';
import AppBreadcrumb from './AppBreadcrumb';
import Navigation from './Navigation';
import AppMenu from './Menu';
const HomePage = (props: { user: any; horizontal: any; activeTopbarItem: any; onMenuButtonClick: any; onTopbarItemClick: any; onRightMenuButtonClick: any; onMobileTopbarButtonClick: any; mobileTopbarActive: any; searchActive: any; onSearch: any }) => {
    // console.log(props.horizontal);

    return (
        <>
            <Navigation
            user = {props.user}
                horizontal={props.horizontal}
                activeTopbarItem={props.activeTopbarItem}
                onMenuButtonClick={props.onMenuButtonClick}
                onTopbarItemClick={props.onTopbarItemClick}
                onRightMenuButtonClick={props.onRightMenuButtonClick}
                onMobileTopbarButtonClick={props.onMobileTopbarButtonClick}
                mobileTopbarActive={props.mobileTopbarActive}
                searchActive={props.searchActive}
                onSearch={props.onSearch}
            />
            <AppMenu />
            {/* <AppBreadcrumb/> */}
        </>
    );
};

export default HomePage;
