import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import dxService from '../../../Services/dxService';
import { localetoisodatetime } from '../../../utils/helper';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';

var finalexpenseWeekArray: any = [];
var finalexpenseMonthArray: any = [];
var last14Expense: any = [];
var last7Expense: any = [];
var thismonthExpense: any = [];
var lastmonthExpense: any = [];
const DashboardExpenses = () => {
    const [expensetoggle, setexpensetoggle] = useState<any>(false);
    const [ExpenseArray, setExpenseArray] = useState<any>([]);
    const today = new Date(); // get today's date
    const lastTwoWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 14);
    const lasttwoWeekEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 8);
    // Calculate the start and end dates of the last week
    const lastWeekEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 1);
    const lastWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
    // Calculate the start and end dates of the last month before
    let lastTwoMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    let lastTwoMonthEndDate = new Date(today.getFullYear(), today.getMonth() - 1, 0);
    today.setMonth(today.getMonth() - 1);
    today.setDate(1);

    // Calculate the start and end dates of the month  last
    var lastMonthStartDate = today.toDateString();
    today.setMonth(today.getMonth() + 1);
    today.setDate(0);
    var lastMonthEndDate = today.toDateString();
    useEffect(() => {
        const fetchData = async () => {
            let Exp14days, Exp7days;
            if (expensetoggle) {
                [Exp14days, Exp7days] = await Promise.all([get14daysExpenseLogData(), get7daysExpenseLogData()]);
            } else {
                [Exp14days, Exp7days] = await Promise.all([lastmonthgetExpensesData(), thismonthgetExpensesData()]);
            }

            await getExpenses(Exp14days, Exp7days);
        };

        fetchData();
    }, [expensetoggle]);

    const lastmonthgetExpensesData = async (nextToken?: any) => {
        const filterlastmonth = {
            and: [{ date: { ge: localetoisodatetime(new Date(lastTwoMonthStartDate)) } }, { date: { le: moment(localetoisodatetime(lastTwoMonthEndDate.toISOString())).format('YYYY-MM-DD') + 'T23:59:59.999Z' } }]
        };

        let items = [];
        let responseNextToken = nextToken;
        const ac = `date Parking Salik Vipfuel Fine`;
        do {
            const { items: fetchedItems, nextToken: fetchedNextToken } = await dxService.listitems('masterlog', ac, filterlastmonth, true, 1000, responseNextToken);

            items.push(...fetchedItems);
            responseNextToken = fetchedNextToken;
        } while (responseNextToken);

        return items;
    };

    const thismonthgetExpensesData = async (nextToken?: any) => {
        const filterthismonth = {
            and: [{ date: { ge: localetoisodatetime(new Date(lastMonthStartDate)) } }, { date: { le: moment(localetoisodatetime(lastMonthEndDate)).format('YYYY-MM-DD') + 'T23:59:59.999Z' } }]
        };

        let items = [];
        let responseNextToken = nextToken;
        const ac = `date Parking Salik Vipfuel Fine`;
        do {
            const { items: fetchedItems, nextToken: fetchedNextToken } = await dxService.listitems('masterlog', ac, filterthismonth, true, 1000, responseNextToken);

            items.push(...fetchedItems);
            responseNextToken = fetchedNextToken;
        } while (responseNextToken);

        return items;
    };

    const get14daysExpenseLogData = async (nextToken?: any) => {
        const variables = {
            and: [{ date: { ge: localetoisodatetime(lastTwoWeekStartDate) } }, { date: { le: moment(localetoisodatetime(lasttwoWeekEndDate.toISOString())).format('YYYY-MM-DD') + 'T23:59:59.999Z' } }]
        };

        let items = [];
        let responseNextToken = nextToken;
        const ac = `date Parking Salik Vipfuel Fine`;
        do {
            const { items: fetchedItems, nextToken: fetchedNextToken } = await dxService.listitems('masterlog', ac, variables, true, 1000, responseNextToken);

            items.push(...fetchedItems);
            responseNextToken = fetchedNextToken;
        } while (responseNextToken);

        return items;
    };

    const get7daysExpenseLogData = async (nextToken?: any) => {
        const variables = {
            and: [{ date: { ge: localetoisodatetime(lastWeekStartDate) } }, { date: { le: moment(localetoisodatetime(lastWeekEndDate.toISOString())).format('YYYY-MM-DD') + 'T23:59:59.999Z' } }]
        };

        let items = [];
        let responseNextToken = nextToken;
        const ac = `date Parking Salik Vipfuel Fine`;
        do {
            const { items: fetchedItems, nextToken: fetchedNextToken } = await dxService.listitems('masterlog', ac, variables, true, 1000, responseNextToken);

            items.push(...fetchedItems);
            responseNextToken = fetchedNextToken;
        } while (responseNextToken);

        return items;
    };

    const getExpenses = async (lastarr1?: any, thisarr2?: any) => {
        finalexpenseWeekArray = [];
        finalexpenseMonthArray = [];
        last14Expense = [];
        thismonthExpense = [];
        lastmonthExpense = [];
        last7Expense = [];

        let thismonthexpObj: any = {};
        let lastmonthexpObj: any = {};
        let expObj14: any = {};
        let expObj7: any = {};
        let finalExpObj: any = {};

        if (!expensetoggle) {
            const parkingsum14 = sumValues(lastarr1, 'Parking');
            const saliksum14 = sumValues(lastarr1, 'Salik');
            const fuelsum14 = sumValues(lastarr1, 'Vipfuel');
            const fine14 = sumValues(lastarr1, 'Fine');

            lastmonthexpObj = {
                Parking: Math.round(parkingsum14),
                Salik: Math.round(saliksum14),
                Vipfuel: Math.round(fuelsum14),
                Fine: Math.round(fine14)
            };
            lastmonthExpense.push(lastmonthexpObj);

            const parkingsum7 = sumValues(thisarr2, 'Parking');
            const saliksum7 = sumValues(thisarr2, 'Salik');
            const fuelsum7 = sumValues(thisarr2, 'Vipfuel');
            const fine7 = sumValues(thisarr2, 'Fine');

            thismonthexpObj = {
                Parking: Math.round(parkingsum7),
                Salik: Math.round(saliksum7),
                Vipfuel: Math.round(fuelsum7),
                Fine: Math.round(fine7)
            };
            thismonthExpense.push(thismonthexpObj);

            await expenseCalculation(lastmonthExpense, finalexpenseMonthArray, lastmonthExpense, thismonthExpense).then((item: any) => {
                rendermontharray();
            });
        }

        if (expensetoggle) {
            const parkingsum14 = sumValues(lastarr1, 'Parking');
            const saliksum14 = sumValues(lastarr1, 'Salik');
            const fuelsum14 = sumValues(lastarr1, 'Vipfuel');
            const fine14 = sumValues(lastarr1, 'Fine');

            expObj14 = {
                Parking: Math.round(parkingsum14),
                Salik: Math.round(saliksum14),
                Vipfuel: Math.round(fuelsum14),
                Fine: Math.round(fine14)
            };
            last14Expense.push(expObj14);
            const parkingsum7 = sumValues(thisarr2, 'Parking');
            const saliksum7 = sumValues(thisarr2, 'Salik');
            const fuelsum7 = sumValues(thisarr2, 'Vipfuel');
            const fine7 = sumValues(thisarr2, 'Fine');

            expObj7 = {
                Parking: Math.round(parkingsum7),
                Salik: Math.round(saliksum7),
                Vipfuel: Math.round(fuelsum7),
                Fine: Math.round(fine7)
            };
            last7Expense.push(expObj7);

            await expenseCalculation(last14Expense, finalexpenseWeekArray, last14Expense, last7Expense).then((item: any) => {
                rendermontharray();
            });
        }
    };

    const sumValues = (arr: any[], key: string) => {
        return arr.reduce((sum, item) => {
            if (item[key] !== null && item[key] !== '') {
                return sum + parseFloat(item[key]);
            }
            return sum;
        }, 0);
    };

    const rendermontharray = async () => {
        if (!expensetoggle) {
            setExpenseArray(finalexpenseMonthArray);
        } else {
            setExpenseArray(finalexpenseWeekArray);
        }
    };
    const expenseCalculation = async (key: any, finalarray: any, arr1: any, arr2: any) => {
        const keys = Object.keys(key[0]); // get the keys from the first object

        for (const keyToCompare of keys) {
            let percent: any;
            let value1: any;
            let value2: any;
            let difference = 0;

            for (let index = 0; index < arr1.length; index++) {
                const obj = arr1[index];
                const obj2 = arr2[index];

                value1 = obj[keyToCompare];
                value2 = obj2[keyToCompare];

                const diff = value1 - value2;
                const sum = value1 + value2;
                percent = sum !== 0 ? Math.round((diff / sum) * 100) : 0;

                difference += diff;
            }

            const absDifference = Math.abs(difference);
            const status = difference < 0 ? 'highexpense' : 'lowexpense';

            finalarray.push({
                type: keyToCompare,
                amount: absDifference,
                status,
                percentage: Math.abs(percent),
                total: `${value1}/${value2}`
            });
        }
    };

    var expenseUi: any = (
        <>
            {ExpenseArray.map((exp: any, index: any) => {
                let icons = exp.type == 'Salik' ? 'ms-Icon--Toll' : exp.type == 'Parking' ? 'ms-Icon--Car' : exp.type == 'Vipfuel' ? 'ms-Icon--Filter' : exp.type == 'Fine' ? 'ms-Icon--Money' : '';
                let iconColor = exp.status == 'highexpense' ? 'pi pi-arrow-up expensesLossIcon ' : exp.status == 'lowexpense' ? 'pi pi-arrow-down expensesGainIcon' : exp.status == 'noexpense' ? ' pi pi-minus' : '';
                let PercentageColor = exp.status == 'highexpense' ? 'expensesLossIcon' : exp.status == 'lowexpense' ? 'expensesGainIcon' : '';

                return (
                    <div className={`flex justify-content-between align-items-center`} key={index} style={{ borderBottom: '1px solid', borderBottomColor: ' #E4E4E4' }}>
                        <div className="flex flex-column">
                            <i className={`ms-Icon ${icons}`} aria-hidden="true"></i>

                            <span className="value mb-2 mt-2">
                                AED {exp.amount}
                                <i className={iconColor} aria-hidden="true"></i>
                                <span className={PercentageColor}> {exp.percentage}%</span>
                            </span>
                            <span className="subtext">
                                {exp.type} total: AED {exp.total}
                            </span>
                        </div>
                    </div>
                );
            })}
        </>
    );
    return (
        <div className="col-12 md:col-12  lg:col-4">
            <div className="height-100 card">
                <div className="widget-expenses">
                    <div className="card-header ExpenseTitle">
                        <div style={{ display: 'flex' }}>
                            <h5 style={{ paddingRight: '4px' }}>Expenses</h5>
                            <span>{expensetoggle ? '(Last two week expenses)' : !expensetoggle ? '(Last two month expenses)' : ''}</span>
                        </div>

                        <div className=" flex flex-wrap justify-content-center gap-2">
                            <Button style={{ height: '22px' }} type="button" label="Week" onClick={(e) => setexpensetoggle(true)}></Button>
                            <Button style={{ height: '22px' }} type="button" label="Month" onClick={(e) => setexpensetoggle(false)} className="p-button-warning"></Button>
                        </div>
                    </div>
                    <div className="card-subheader  pb-3">
                        {expensetoggle
                            ? `${moment(lastTwoWeekStartDate).format('MMMM DD')} - ${moment(lasttwoWeekEndDate).format('MMMM DD')}/${moment(lastWeekStartDate).format('MMMM DD')} - ${moment(lastWeekEndDate).format('MMMM DD')}`
                            : !expensetoggle
                            ? `${moment(lastTwoMonthStartDate).format('MMMM DD')} - ${moment(lastTwoMonthEndDate).format('MMMM DD')}/${moment(lastMonthStartDate).format('MMMM DD')} - ${moment(lastMonthEndDate).format('MMMM DD')}`
                            : ''}
                    </div>
                    {(!expensetoggle && finalexpenseMonthArray.length == 0) || (expensetoggle && finalexpenseWeekArray).length == 0 ? <ProgressSpinner aria-label="Loading" className=" flex justify-content-center" /> : expenseUi}
                </div>
            </div>
        </div>
    );
};

export default DashboardExpenses;
