import { API } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { updateDxVehicle, updateDxAllocations, updateDxVehicleActivity, createDxDocument } from '../../graphql/mutations';
import { listDxVehicles, listDxAllocations, listDxVehicleActivities } from '../../graphql/queries';

import { _listDxVehicleActivities } from '../../dxgraphql/dxqueries';
import { Toast } from 'primereact/toast';
import { Storage } from 'aws-amplify';
import FormTemplate from '../../Components/FormTemplate';
import awsExports from '../../aws-exports';
import dxService from '../../Services/dxService';
import Loader from '../../Components/Loader';
import { getNextQueryData, getallocateditemfromvehicleactivity, getcurrentallocationfromvehicleactivity, getdriveroptions, isotolocaledatetime, localetoisodatetime } from '../../utils/helper';
var id: any;
var checkfromdriver: any = [];

const AllocateDriverPopUp = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [updatedata, setupdatedata] = useState<any>([]);
    const [VehicleData, setVehicleData] = useState<any>([]);
    const [vactivityitem, setvactivityitem] = useState<any>(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [DrivernameOptions, setDrivernameOptions] = useState<any>([]);
    const [allocateditem, setVallocateditem] = useState<any>([]);
    const [currentallocationitem, setVcurrentallocationitem] = useState<any>([]);
    const [displayDialog, setdisplayDialog] = useState(false);
    const urlParams = new URLSearchParams(location.search);
    const toast = useRef<any>(null);
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    var type = urlParams.has('type') ? urlParams.get('type') : null;
    var aid = urlParams.has('aid') ? urlParams.get('aid') : null;
    //#region Onload methods
    //calls method during the component load
    useEffect(() => {
        getdata();
        getDriverdetailsData();
    }, [props.displayModal]);

    useEffect(() => {
        // console.log(vactivityitem);
        setlistFormData(vactivityitem);
        if (vactivityitem != null && vactivityitem.allocations) {
            let _allocations = vactivityitem.allocations;

            setVcurrentallocationitem(getcurrentallocationfromvehicleactivity(_allocations));
            // setVallocateditem(getallocateditemfromvehicleactivity(_allocations));
            // console.log(vactivityitem);

            //checkfromdriver=> checking whether the driver is allocated to the vehicle or not
            checkfromdriver = vactivityitem;
            // console.log(checkfromdriver);
        }
        const GetPreviousAllocation = async () => {
            let filter = {
                vehicleid: { eq: formid },
                allocatestatus: { eq: 'Allocated' }
            };
            await getNextQueryData('', filter, 'allocation', true).then((res: any) => {
                setVallocateditem(res);
            });
        };
        GetPreviousAllocation();
    }, [vactivityitem]);

    useEffect(() => {
        if (allocateditem != null && allocateditem.length > 0) {
            if (allocateditem && allocateditem.length > 0) {
                setlistFormData({
                    FromDriverDate: isotolocaledatetime(allocateditem[0].fromdate),
                    dxDriverVehicleactivityId: allocateditem[0].fromdriverID,
                    FromKmMileage: allocateditem[0].fromKM,
                    FromLocation: allocateditem[0].fromlocation,
                    id: allocateditem[0].vehicleid
                    //dxAllocationsActivityId: GetResponse[0].dxAllocationsActivityId
                });
            }
        }
    }, [allocateditem]);
    //#endregion

    //getting the vehicle details from the vehicle table based on vehicle id
    const getdata = async () => {
        let filter = {
            id: {
                eq: formid
            }
        };
        let GetResponse: any = formid && (await dxService.listitems('vehicleactivity', '*', filter, true));
        setvactivityitem(GetResponse[0]);
        setVehicleData(GetResponse);
    };
    //# drivername dropdown
    //getting the driver names from the driver table to list it down in the dropdown
    const getDriverdetailsData = async () => {
        await dxService.listitems('driver', '*').then(async (res: any) => {
            setDrivernameOptions(res);
        });
    };

    const DriverNameOption: any = [];
    const HandOverOption: any = [];
    const drivernames = DrivernameOptions.filter((del: any) => del._deleted !== true && del.status !== 'InActive' && del.name !== null);

    console.log(drivernames);

    drivernames.forEach((ele: any) => {
        let a: any = {};
        a['label'] = ele.name;
        a['value'] = ele.id;

        DriverNameOption.push(a);

        let b: any = {};
        b['label'] = ele.name;
        b['value'] = ele.id + ',' + ele.name;
        HandOverOption.push(b);
    });

    //#endregion of drivername dropdown
    const addDocToDB = async (files: any) => {
        // console.log('adding document', files);
        let createDocument = await dxService.createitem('document', files, false);
    };

    //CreateNewAllocation function calls if there is no driver allocated to the vehicle
    const CreateNewAllocation = async (data: any) => {
        let os: any = {};
        setdisplayDialog(true);
        var UploadDocuments: any = [];
        let doumentObj: any = {};
        var Isdocument = Array.isArray(data.documents);
        var Ispicture = Array.isArray(data.pictures);

        let hdriver = data.HandoverDriverDropdown.length > 0 ? data.HandoverDriverDropdown.split(',') : [];
        // console.log(hdriver);
        if (data.documents && data.documents.length >= 0) {
            for (let i = 0; i < data.documents.length; i++) {
                //getting the document upload names and creating the own object
                doumentObj = new Object({ file: data.documents[i], type: 'documents', path: `allocations/${new Date().getFullYear()}/${data.id}/documents/` });

                //pushing an object in an UploadDocuments array
                UploadDocuments.push(doumentObj);
            }
        }
        if (data.pictures && data.pictures.length > 0) {
            for (let i = 0; i < data.pictures.length; i++) {
                //getting the document upload names and creating the own object
                doumentObj = new Object({ file: data.pictures[i], type: 'picture', path: `allocations/${new Date().getFullYear()}/${data.id}/picture/` });

                //pushing an object in an UploadDocuments array
                UploadDocuments.push(doumentObj);
            }
        }
        let createallocation = {
            vehicleid: data.id,
            fromdriverID: hdriver[0],
            fromdrivername: hdriver[1],
            fromKM: data.HandoverKmMileage,
            fromdate: localetoisodatetime(new Date(data.HandoverDriverDate)),
            fromlocation: data.HandoverLocation,
            dxVehicleActivityAllocationsId: data.id,
            dxDriverAllocationsId: hdriver[0],
            allocatestatus: 'Allocated',
            pictures: Ispicture,
            documents: Isdocument
        };
        console.log(createallocation);
        let allocation = await dxService.createitem('allocation', createallocation, false).then(async (res: any) => {
            UploadDocuments.map(async (item: any) => {
                await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                    const files = {
                        name: res.key,

                        s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                        resource: 'Allocation',
                        resourceid: id,
                        presourceid: formid,
                        presource: 'Vehicle',
                        field1: res.key.includes(`${formid}/picture`) ? 'pictures' : res.key.includes(`${formid}/documents`) ? 'documents' : ''
                    };
                    addDocToDB(files);
                });
            });

            let updatevehicleactivity = {
                id: data.id,
                //dxAllocationsActivityId: id,
                dxDriverVehicleactivityId: hdriver[0], //handover driver id
                _version: checkfromdriver._version
            };
            os = await dxService.updateitem('vehicleactivity', updatevehicleactivity);
            let loadworkspacedata = await dxService.listitems('vehicleactivity', '*').then((res: any) => {
                toast.current.show({
                    severity: 'success',
                    summary: 'Driver allocated Successfully',
                    life: 3000
                });
                setTimeout(() => {
                    navigate('/general/workspace');
                }, 1000);
            });
        });
    };
    //ChangeOldAllocation function calls to deallocate the old allocation
    const ChangeOldAllocation = async (data: any) => {
        console.log(data);
        // console.log(listFormData);
        // console.log(allocateditem);
        // console.log(currentallocationitem);
        setdisplayDialog(true);
        let hdriver = data.HandoverDriverDropdown.length > 0 ? data.HandoverDriverDropdown.split(',') : [];

        let updateallocation = {
            id: allocateditem[0].id,
            handoverdate: localetoisodatetime(new Date(data.HandoverDriverDate)),
            handoverKM: data.HandoverKmMileage,
            handoverlocation: data.HandoverLocation,
            allocatestatus: 'DeAllocated',
            reason: data.reason,
            handoverdrivername: hdriver.length > 1 ? hdriver[1] : hdriver,
            _version: allocateditem[0]._version
        };
        let os: any = {};
        os = await dxService.updateitem('allocation', updateallocation);
        return os;
    };
    //Deallocation function calls when we deallocate the driver to a vehicle
    const DeAllocation = async (data: any) => {
        console.log(data);
        setdisplayDialog(true);
        var UploadDocuments: any = [];
        let os: any = {};
        let doumentObj: any = {};
        var Isdocument = Array.isArray(data.documents);
        var Ispicture = Array.isArray(data.pictures);
        let filter = {
            id: {
                eq: `${data.dxAllocationsActivityId}`
            }
        };
        //let getAllocatedItem = await dxService.listitems('allocation', '*', filter);
        let getAllocatedItem = allocateditem;
        if (getAllocatedItem.length > 0) {
            if (data.documents && data.documents.length >= 0) {
                for (let i = 0; i < data.documents.length; i++) {
                    //getting the document upload names and creating the own object
                    doumentObj = new Object({ file: data.documents[i], type: 'documents', path: `dealocations/${new Date().getFullYear()}/${data.id}/documents/` });

                    //pushing an object in an UploadDocuments array
                    UploadDocuments.push(doumentObj);
                }
            }
            if (data.pictures && data.pictures.length > 0) {
                for (let i = 0; i < data.pictures.length; i++) {
                    //getting the document upload names and creating the own object
                    doumentObj = new Object({ file: data.pictures[i], type: 'picture', path: `dealocations/${new Date().getFullYear()}/${data.id}/picture/` });

                    //pushing an object in an UploadDocuments array
                    UploadDocuments.push(doumentObj);
                }
            }
            let Allocateddata = getAllocatedItem[0];
            let updateallocation = {
                id: Allocateddata.id,
                handoverdate: localetoisodatetime(data.HandoverDriverDate),
                handoverKM: data.FromKmMileage,
                handoverlocation: data.FromLocation,
                // handoverdrivername: data.HandoverDriverDropdown,
                allocatestatus: 'DeAllocated',
                pictures: Ispicture,
                documents: Isdocument,
                reason: data.reason,
                _version: Allocateddata._version
            };
            os = await dxService.updateitem('allocation', updateallocation);
            UploadDocuments.map(async (item: any) => {
                await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                    // item['fullpath'] = res.key;
                    const files = {
                        name: res.key,
                        // dxAllocationsPicturesId: id,
                        s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                        resource: 'DeAllocation',
                        resourceid: Allocateddata.id,
                        presourceid: formid,
                        presource: 'Vehicle',
                        field1: res.key.includes(`${formid}/picture`) ? 'pictures' : res.key.includes(`${formid}/documents`) ? 'documents' : ''
                    };
                    addDocToDB(files);
                });
            });
            let updatevehicleactivity = {
                id: data.id,
                //dxAllocationsActivityId: null,
                dxDriverVehicleactivityId: null,
                _version: checkfromdriver._version
            };
            await dxService.updateitem('vehicleactivity', updatevehicleactivity);
            let loadworkspacedata = await dxService.listitems('vehicleactivity', '*').then((res: any) => {
                toast.current.show({
                    severity: 'success',
                    summary: 'Driver Deallocated Successfully',
                    life: 3000
                });
                setTimeout(() => {
                    navigate('/general/workspace');
                }, 1000);
            });
        }
    };
    const EditAllocation = async (data: any) => {
        console.log(data);
    };
    //this function calls while clicking on the save button
    const onSaveAllocateDriver = async (data: any) => {
        console.log(data);

        if (type == 'allocate') {
            //if it is a allocation these functions will get call
            //if (vactivityitem.dxDriverVehicleactivityId !== null && allocateditem.length === 0) {
            if (checkfromdriver.dxDriverVehicleactivityId == null || allocateditem.length === 0) {
                //if (checkfromdriver.dxDriverVehicleactivityId == null) {
                CreateNewAllocation(data);
            } else if (allocateditem.length > 0) {
                ChangeOldAllocation(data).then((res: any) => {
                    CreateNewAllocation(data);
                });
            } else {
                CreateNewAllocation(data);
            }
        } else if (type == 'deallocate') {
            //if it is a deallocation these functions will get call
            DeAllocation(data);
        } else if (type == 'edit') {
            EditAllocation(data);
        }
    };

    const Allocationcolumns = {
        columns: [
            {
                name: 'id',
                value: '',
                title: 'Vehicle Id',
                fieldtype: 'text',
                required: false,
                disabled: true,
                hidden: false,
                data: []
            },
            {
                title: 'Current info',
                name: 'basicinfo',
                fieldtype: 'header',
                hidden: checkfromdriver.dxDriverVehicleactivityId == null ? true : false
            },
            {
                name: 'dxDriverVehicleactivityId',
                value: '',
                title: 'Current Driver',
                fieldtype: 'dropdown',
                required: false,
                // disabled: checkfromdriver.dxDriverVehicleactivityId == null ? false : true,
                disabled: true,
                hidden: checkfromdriver.dxDriverVehicleactivityId == null ? true : false,
                data: DriverNameOption
            },
            {
                name: 'FromDriverDate',
                value: '',
                title: 'Current Date',
                fieldtype: 'datetime',
                required: false,
                disabled: true,
                hidden: checkfromdriver.dxDriverVehicleactivityId == null ? true : false,
                showcurrentdate: true,
                data: []
            },
            {
                name: 'FromKmMileage',
                value: '',
                title: 'Current KM Mileage',
                fieldtype: 'number',
                required: false,
                disabled: true,
                hidden: checkfromdriver.dxDriverVehicleactivityId == null ? true : false,
                data: []
            },
            {
                name: 'FromLocation',
                value: '',
                title: 'Current Location',
                fieldtype: 'text',
                required: false,
                disabled: true,
                hidden: checkfromdriver.dxDriverVehicleactivityId == null ? true : false,
                data: []
            },

            {
                title: 'Handover info',
                name: 'basicinfo',
                fieldtype: 'header'
            },
            {
                name: 'HandoverDriverDropdown',

                value: '',
                title: 'Handover Driver',
                fieldtype: 'dropdown',
                // required: checkfromdriver.dxDriverVehicleactivityId == null ? true : false,
                required: type == 'deallocate' ? false : true,
                disabled: false,

                hidden: type == 'deallocate' ? true : false,
                data: HandOverOption
            },

            {
                name: 'HandoverDriverDate',
                value: '',
                title: 'Handover Date',
                fieldtype: 'datetime',
                // required: checkfromdriver.dxDriverVehicleactivityId == null ? true : false,
                required: true,
                disabled:
                    // checkfromdriver.dxDriverVehicleactivityId == null ? true :
                    false,
                // showcurrentdate: false,
                hidden: false,
                data: []
            },

            {
                name: 'HandoverKmMileage',
                value: '',
                title: 'Handover KM Mileage',
                fieldtype: 'number',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },

            {
                name: 'HandoverLocation',
                value: '',
                title: 'Handover Location',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'reason',
                value: '',
                title: 'Reason',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: checkfromdriver.dxDriverVehicleactivityId == null ? true : false,
                data: []
            },
            {
                title: 'Uploads',
                name: 'basicinfo',
                fieldtype: 'header'
            },
            {
                name: 'documents',
                value: '',
                title: 'Upload documents',
                fieldtype: 'fileupload',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'pictures',

                value: '',
                title: 'Upload pictures',
                fieldtype: 'fileupload',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'VehicleStatus',
                value: '',
                title: 'VehicleStatus',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'expanses',
                value: '',
                title: 'expanses',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },

            {
                name: 'allocationdriver',
                value: '',
                title: 'allocationdriver',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'capacity',
                value: '',

                title: 'capacity',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'events',
                value: '',
                title: 'events',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'expiring',
                value: '',
                title: 'expiring',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'make',
                value: '',
                title: 'make',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'model',
                value: '',
                title: 'model',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'platecode',
                value: '',
                title: 'platecode',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'plateno',
                value: 'plateno',
                title: 'plateno',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'policyno',
                value: '',
                title: 'policyno',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'status',
                value: '',
                title: 'status',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'year',
                value: '',
                title: 'year',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'departmentname',
                value: '',
                title: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'dxVehicleVehicleactivityId',
                value: '',
                title: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'dxAllocationsActivityId',
                value: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'dxAppointmentsActivityId',
                value: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'dxContractActivityId',
                value: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            }
        ],
        actions: [
            {
                title: 'Submit'
            },
            {
                title: 'Cancel'
            }
        ],
        // fleettype: 'vehicle#activity',
        getTable: listDxVehicleActivities
    };
    const changevehicledata = (props: any) => {
        setupdatedata(props);
        console.log(props);
    };
    return (
        <div>
            {' '}
            <>
                <Toast ref={toast} />
                <div className="col-12 md:col-12">
                    <h3>{type == 'deallocate' ? 'Deallocate Driver' : 'Allocate Driver'}</h3>
                    <FormTemplate
                        id="newallocation"
                        showaction={true}
                        data={listFormData}
                        onChange={changevehicledata}
                        getTableName={'listDxVehicleActivities'}
                        columns={Allocationcolumns}
                        layout="grid2"
                        title="Allocate Driver"
                        submitForm={onSaveAllocateDriver}
                    />
                    <Loader displayDialog={displayDialog} />
                </div>
            </>
        </div>
    );
};

export default AllocateDriverPopUp;
