import { Storage } from 'aws-amplify';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';

import { listDxContracts } from '../../graphql/queries';
import AmplifyServices from '../../Service/Service';

let vehicleId: any;
const SContractviewform = () => {
    const location = window.location.href;
    const urlParams = new URLSearchParams(location.split('?')[1]);
    const [displayDocuments, setDisplayDocuments] = useState([]);
    useEffect(() => {
        vehicleId = urlParams.get('id');
        // vehicleId !== '' && getDisplayDetails();
        getDocumentDetails();
    }, [window.location.href]);
    const getDocumentDetails = async () => {
        setDisplayDocuments([]);

        // await AmplifyServices.getFilteredTableDetails(listDxContracts, 'listDxContracts', vehicleId).then((res: any) => {

        await AmplifyServices.getViewfilterContractId(listDxContracts, 'listDxContracts', vehicleId).then((res: any) => {
            let contracts = res.filter((vehicle: any) => vehicle._deleted !== true);
            console.log(contracts);

            getFileLocation(contracts);
        });
    };
    const getDisplayDetails = async () => {
        let AllDocuments: any = displayDocuments && displayDocuments.filter((res: any) => res.vehicleid === vehicleId)[0];

        if (AllDocuments !== undefined) {
            AllDocuments = JSON.parse(AllDocuments['expiring']);

            // console.log(Object.keys(AllDocuments));
            let names = Object.keys(AllDocuments);
            let EditDocuments: any = [];
            for (let i = 0; i < names.length; i++) {
                AllDocuments[names[i]].documentType = AllDocuments[names[i]].documents.includes('Registration')
                    ? 'Registration / Milkia'
                    : AllDocuments[names[i]].documents.includes('ServiceContract')
                    ? 'Service Contracts'
                    : AllDocuments[names[i]].documents.includes('Insurance')
                    ? 'Milkia Insurance Returns'
                    : AllDocuments[names[i]].documents.includes('RTADocuments')
                    ? 'RTA Test documents'
                    : AllDocuments[names[i]].documents.includes('Warranty')
                    ? 'Warranty'
                    : 'Others';
                AllDocuments[names[i]].name = AllDocuments[names[i]].documents;

                AllDocuments[names[i]].Year = new Date(AllDocuments[names[i]].expiryDate).toDateString();
                EditDocuments.push(AllDocuments[names[i]]);

                setDisplayDocuments(EditDocuments);
            }
        }
    };
    const getFileLocation = (e: any) => {
        let FinalData: any = [];
        e.map(async (item: any) => {
            console.log(item);
            let vid = item.vehicleid.includes(',') ? item.vehicleid.replace(',', ';') : item.vehicleid;
            let file = item.documents && item.documents;
            console.log(file);
            let splitfile = file.split(',');
            console.log(splitfile);
            for (let i = 0; i < splitfile.length; i++) {
                let url = await Storage.get(splitfile[i]);
                console.log(`${item.vehicleid}/documents`);
                console.log(splitfile[i].includes(`${vid}/documents`));

                FinalData.push({
                    name: splitfile[i].split('/').pop(),
                    documents: url,
                    renewaltype: item.renewaltype,
                    expirydate: item.expirydate,
                    type: splitfile[i].includes(`${vid}/documents`) ? 'Documents' : splitfile[i].includes(`${vid}/picture`) ? 'Pictures' : splitfile[i].includes(`${vid}/receipts`) ? 'Receipts' : ''
                });
                console.log(FinalData);
            }
            let filterfinaaldata: any = [];
            let removeduplicates: any = [];
            filterfinaaldata = FinalData.filter((fil: any, index: any) => fil.name !== '');

            removeduplicates = filterfinaaldata.filter((item: any, index: any) => filterfinaaldata.indexOf(item) === index);

            setDisplayDocuments(removeduplicates);
        });
    };
    const AttachmentBodyTemplate = (e: any) => {
        return (
            <a href={e.documents} target="_blank" className="">
                {e.name}
                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
            </a>
        );
    };
    const expiryBodyTemplate = (e: any) => {
        return <span className="">{new Date(e.expirydate).toDateString()}</span>;
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <div className="card">
                    <h5>Documents</h5>
                    <DataTable
                        value={displayDocuments}
                        dataKey="id"
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} contracts"
                        emptyMessage="No Documents added."
                        responsiveLayout="scroll"
                    >
                        {/* <Column field="expirydate" header="Expires On" body={expiryBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        <Column field="renewaltype" header="Contract Type" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="type" header="Document Type" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="documents" header="Attachment" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
export default SContractviewform;
