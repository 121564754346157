import React, { useState, useEffect, useRef, Children, useContext } from 'react';
import { Amplify, API, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsconfig from '../../aws-exports';
import { Toast } from 'primereact/toast';
import { AuthContext } from '../../AuthContext';
import { CognitoIdentityProviderClient, AdminAddUserToGroupCommand, AdminRemoveUserFromGroupCommand, ListGroupsCommand, AdminListGroupsForUserCommand, AdminUpdateUserAttributesCommand } from '@aws-sdk/client-cognito-identity-provider';
import UserRoles from './UserRoles';
import AmplifyServices from '../../Service/Service';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../dxgraphql/dxqueries';
import { getDxApprovalConfig, getDxWorkflow, listDxApprovals } from '../../graphql/queries';
import { updateDxApprovalConfig, updateDxApprovals, updateDxWorkflow } from '../../graphql/mutations';
import FormTemplate from '../../Components/FormTemplate';
import { Button } from 'primereact/button';


const TestPage = () => {
    const { dispatch } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [workflowitem, setworkflowitem] = useState<any>({});
    const [stepitem, setstepitem] = useState<any>({});
    const [appitem, setappitem] = useState<any>({});
    const [newdata, setnewdata] = useState<any>({});
    const toast = useRef(null);
    const [uname, setUname] = useState('');
    const [name, setname] = useState('');


    useEffect(() => {
        getdata();

       
       
    }, []);

    useEffect(()=>{
        let a = {
            name:"Approval for Appointment",
            description:"test description 1"
        }
        setlistFormData(a);
    },[appitem]);
    const formcolumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'title',
                title: 'Title',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-6'

            },
            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },

            {
                name: 'description',
                title: 'Description',
                fieldtype: 'textarea',
                required: false,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-12'
            }, {
                name: 'comments',
                title: 'Comments',
                fieldtype: 'textarea',
                required: false,
                value: 'test description',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-12'
            }
        ],
        actions: [
            {
                title: 'Approve'
            },
            {
                title: 'Reject'
            }
        ]
    }

    

    const changedata = (props: any) => {
        console.log(props);
        setnewdata(props);
        
    };

    const addData = (props: any) => {
        console.log(props);

    };

    const addnewData = (props: any) => {
        console.log(newdata);

        // Update Approval Item
        //Create Approval Item
        // Update Workflow Item
        // Update Application Item
        

    };



    

    const getdata = async () => {

        //Get All Approval Configuration Items
        let GetResponse: any = await API.graphql({ query: _listDxApprovalConfigs, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        console.log("***** Approval Configurations - DxApprovalConfigs *****")
        console.log(GetResponse);

        //Get Single Approval Configuration Item
        let getitem: any = await API.graphql({
            query: _getDxApprovalConfig, authMode: 'AMAZON_COGNITO_USER_POOLS',

            variables: { id: 'f21d2a3d-dede-4e63-b841-0bf2d64eeb9c' }
        })
        console.log("***** Single Approval Configuration - DxWorkflow Item *****");
        console.log(getitem);

        //Get Single Workflow Item [ Item attaches the Approval Configuration and Application]
        let getworkflowitem: any = await API.graphql({
            query: _getDxWorkflow, authMode: 'AMAZON_COGNITO_USER_POOLS',

            variables: { id: '268e858b-62ae-4c15-a02a-480ad61a7f56' }
        });
        console.log("***** Single Workflow Item - DxWorkflow  *****");
        console.log(getworkflowitem);
        setworkflowitem(getworkflowitem);

       
        //Total Steps
        console.log(getworkflowitem.data.getDxWorkflow.approvalconfig.steps.items)
        let configsteps = getworkflowitem.data.getDxWorkflow.approvalconfig.steps.items;
        const sortsteps = [...configsteps].sort((a, b) => parseInt(a.name) - parseInt(b.name));
        console.log("***** All the steps for the Workflow Item - DxWorkflow > ApporvalConfig > ApprovalConfigSteps *****");
        console.log(sortsteps);
        let currentlevelindex = getworkflowitem.data.getDxWorkflow.wfield1;

        console.log("***** Current approval step - DxWorkflow > ApporvalConfig > ApprovalConfigSteps  *****");
        console.log("Current Level");
        console.log(sortsteps[currentlevelindex]);
        let currentlevelitem = sortsteps[currentlevelindex];
        setstepitem(currentlevelitem);
        let actions = JSON.parse(JSON.parse(currentlevelitem.acsjson));

        console.log("***** Action item for the workflow step DxWorkflow > ApporvalConfig > ApprovalConfigSteps => Action Field *****");
        console.log(actions);

        console.log("***** Approval Items  *****");
        const variables: any = {
            filter: {
                dxWorkflowApprovalitemsId: {
                eq: '268e858b-62ae-4c15-a02a-480ad61a7f56'
              }
            }
          };
        let getapprovalitems: any = await API.graphql({
            query: listDxApprovals, authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables:variables
        });
        let approvalitems = getapprovalitems.data.listDxApprovals.items;
        console.log(approvalitems);


        console.log("***** Get Application Item  *****");
        let applicationitem = approvalitems[0];
        setappitem(applicationitem);
        let resourcedetails = JSON.parse(applicationitem.resource);
        console.log(resourcedetails);
       

        //Update Approval step item
        //  let updateapprovalitem: any = await API.graphql({
        //     query: updateDxApprovals, authMode: 'AMAZON_COGNITO_USER_POOLS',
        //     variables: { input:{id: '4232b618-d4ef-4a31-92c4-323193d7d7b5', dxWorkflowApprovalitemsId:'268e858b-62ae-4c15-a02a-480ad61a7f56'} }
        // })
        // console.log(updateapprovalitem);



        //Update Worflow item with the Approval Configuration
        // let updateworkflowitem: any = await API.graphql({
        //     query: updateDxWorkflow, authMode: 'AMAZON_COGNITO_USER_POOLS',
        //     variables: { input:{id: '268e858b-62ae-4c15-a02a-480ad61a7f56', dxApprovalConfigWorkflowId:'4232b618-d4ef-4a31-92c4-323193d7d7b5'} }
        // })
        // console.log(updateworkflowitem);

        //"f21d2a3d-dede-4e63-b841-0bf2d64eeb9c"
        // await AmplifyServices.getapprovals()
        // .then(async (res: any) => {
        // })
        // .catch((err:any) => console.log(err));
    }


    // async getallSContractdetails() {
    //     let GetResponse: any = await API.graphql({ query: listDxContracts, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    //     console.log(GetResponse);
    //     return GetResponse.data.listDxContracts.items;
    // }

    return (
        <div className="datatable-rowgroup-demo">

            <Toast ref={toast}></Toast>


            <hr />
            <div className="card">

            </div>

            <div className="grid">
                <div className="col-6 md:col-6">
                    <div className="card">
                        <h5>Procurement</h5>
                        <p>This is your Procurement page template to start building beautiful applications.</p>
                    </div>
                </div>

                <div className="col-12 md:col-6">
                        <div className="card container-card">
                            <Toast ref={toast} />


                            <FormTemplate
                                columns={formcolumns}
                                data={listFormData}
                                showaction={false}
                                mode="new"
                                onChange={changedata}
                                ChangeBtn={'OpenDocument'}
                                layout="grid2"
                                title="New Approval"
                                submitForm={addData}
                            />
                            {JSON.stringify(stepitem)}
                            {stepitem.field1}
                           

                            {stepitem && stepitem.field1 && stepitem.field1 === 'Approval'? (
                                <div className="spd-card-footer">
                                <Button onClick={addnewData} className="mr-2">Approve
                                    
                                </Button>
                                <Button onClick={addnewData} className="p-button-danger">
                                Reject
                                </Button>
                            </div>
                            
                        ) : (
                            <div></div>
                        )}
                            


                        </div>
                    </div>
            </div>
        </div>
    );
};
export default TestPage;

