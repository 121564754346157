import DataGridTemplate from "../../../Components/DataGridTemplate";

const PermissionLevels = () => {

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'description', headerName: 'Description', width: 90 },
        { field: 'mask', headerName: 'Permission Mask', width: 90 },
        { field: 'groups', headerName: 'Groups', width: 90 },
    ];
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Permission Levels</h4>
                <div className={`datatable-editing-demo `}>
                    <div className="datatable-rowgroup-demo">
                         <div className="card PermissionTable">
                            <DataGridTemplate data={[]} columns={columns} application='permissionlevels' />

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default PermissionLevels;