import React, { useCallback, useEffect, useState } from 'react';

import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { API, graphqlOperation } from 'aws-amplify';
import { createDxDriver, createDxAllocations, createDxAppointments, deleteDxAppointments, updateDxVehicle, updateDxAppointments, updateDxVehicleActivity } from '../../graphql/mutations';
import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { DriverAddForm, CompanyForm, DepartmentForm } from './formColumns'; //columns
import ListnewForm from '../../Components/ListnewForm';
import AmplifyServices from '../../Service/Service';
import { listDxVehicles, listDxVendors, listDxAppointments, listDxVehicleActivities } from '../../graphql/queries';
import Sparesnewform from '../Spares/Newform';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { _listDxVehicleActivities } from '../../dxgraphql/dxqueries';

var id1: any;
var id: any;

function AppointmentNewForm() {
    const [DrivernameOptions, setDrivernameOptions] = useState<any>([]);
    const [feetVehicle, setfeetVehicle] = useState<any>([]);
    const [VendorPlaceDetails, setVendorPlaceDetails] = useState<any>([]);
    const [ToggleAllocate, setToggleAllocate] = useState<any>(false);
    const [AllocationId, setAllocationId] = useState<any>('');
    const [AllocationDate, setAllocationDate] = useState<any>('');
    const [AllocationDriver, setAllocationDriver] = useState<any>('');
    const [showSparesForm, setShowSparesForm] = useState<any>(false);

    const navigate = useNavigate();

    useEffect(() => {
        getDriverdetailsData();
        getVehicleTabledetailsData();
    }, []);

    const getDriverdetailsData = async () => {
        let data = await AmplifyServices.getDriverDetails().then((res: any) => {
            setDrivernameOptions(res);
        });
        // let data = await AmplifyServices.getDriverDetails().then((res)=>{
        //     return res;
        // });
    };
    const getVehicleTabledetailsData = async () => {
        let vehicle = await AmplifyServices.getTableDetails(_listDxVehicleActivities, 'listDxVehicleActivities').then((res: any) => {
            // let Fleetvehicle = res.filter((vehicle: any) => vehicle.fleettype.includes('vehicle#activity'));
            setfeetVehicle(res);
        });

        let vendorplace = await AmplifyServices.getTableDetails(listDxVendors, 'listDxVendors').then((res: any) => {
            setVendorPlaceDetails(res);
        });
        // let data = await AmplifyServices.getDriverDetails().then((res)=>{
        //     return res;
        // });
    };
    const DriverNameOption: any = [];
    const drivernames = DrivernameOptions.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.id;
        DriverNameOption.push(res);
    });
    const FleetVehicleIdOption: any = [];
    const vehicleId = feetVehicle.map((res: any) => {
        res['label'] = res.id;
        res['value'] = res.id;
        FleetVehicleIdOption.push(res);
    });
    const vendorGarageLocation: any = [];
    const garagelocation = VendorPlaceDetails.map((res: any) => {
        res['label'] = res.garagelocation;
        res['value'] = res.garagelocation;

        vendorGarageLocation.push(res);
    });
    const FleetType = [
        {
            label: 'Service',
            value: 'Service'
        },
        { label: 'Repair/Replacement', value: 'Repair/Replacement' },
        { label: 'Internal Inspection', value: 'InternalInspection' }
    ];
    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                name: 'Appointmentvehicleid', // pass the exact column name of db
                value: 'Vehicle ID',
                fieldtype: 'dropdown',
                required: true,
                disabled: showSparesForm == true ? true : false,
                hidden: false,
                data: FleetVehicleIdOption
            },
            {
                name: 'eventtype', // pass the exact column name of db
                value: 'Event Type', //for display
                fieldtype: 'dropdown',
                required: true,
                disabled: showSparesForm == true ? true : false,
                hidden: false,
                data: FleetType
            },
            {
                name: 'place', // pass the exact column name of db
                value: 'Place', //for display
                fieldtype: 'dropdown',
                required: true,
                disabled: showSparesForm == true ? true : false,
                hidden: false,
                data: vendorGarageLocation
            },
            {
                name: 'AppointmentDate', // pass the exact column name of db
                value: 'Date', //for display
                fieldtype: 'datetime',
                required: true,
                disabled: showSparesForm == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'description', // pass the exact column name of db
                value: 'Description', //for display
                fieldtype: 'text',
                required: false,
                disabled: showSparesForm == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'Appointmentdrivername', // pass the exact column name of db
                value: 'Driver Name',
                fieldtype: 'dropdown',
                required: true,
                disabled: showSparesForm == true ? true : false,
                hidden: false,
                data: DriverNameOption
            },
            {
                name: 'appointmentstatus', // pass the exact column name of db
                value: 'Appointment Status',
                fieldtype: 'dropdown',
                required: false,
                disabled: showSparesForm == true ? true : false,
                hidden: false,
                data: [
                    { label: 'Scheduled', value: 'Scheduled' },
                    { label: 'Completed', value: 'Completed' }
                ]
            },
            {
                name: 'toggleAllocate', // pass the exact column name of db
                value: 'Do you want to allocate driver for transport?',
                fieldtype: 'switch',
                required: false,
                disabled: showSparesForm == true ? true : false,
                hidden: false,
                data: [
                    // { label: 'Yes', value: true },
                    // { label: 'No', value: false }
                ]
            }
        ],
        fleettype: '',
        getTable: listDxAppointments,
        createTable: createDxAppointments, //Pass create table name
        updateTable: updateDxAppointments, //Pass update table name
        deleteTableItem: deleteDxAppointments //Pass update table name
    };

    const submitForm = async (data: any) => {
        data['id'] = Math.random().toString(36).substring(2, 9);

        let os = await API.graphql(
            //change createDxCompanySettings
            graphqlOperation(createDxAppointments, {
                input: data
            })
        );
    };
    const createAppointment = async (isallocation: boolean, va: any, data: any) => {
        id = Math.random().toString(36).substring(2, 9);
        let varvehicleactivitynew = {
            id: id,
            vehicleid: data.Appointmentvehicleid,
            eventtype: data.eventtype,
            place: data.place,
            Date: data.AppointmentDate,
            description: data.description,
            drivername: data.Appointmentdrivername,
            appointmentstatus: data.appointmentstatus,
            wanttoallocatedriver: data.toggleAllocate
        };
        let os = await API.graphql({
            query: createDxAppointments,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: varvehicleactivitynew
            }
        });
        var nowdate = moment(new Date()).format('MM-d-yyy');
        var appointmentdate = moment(data.AppointmentDate).format('MM-d-yyy');
        let vdetails: any = {
            id: data.Appointmentvehicleid,
            // fleettype: 'vehicle#activity',
            VehicleStatus: nowdate == appointmentdate ? 'Garage' : 'Active',
            _version: va._version,
            dxAppointmentsActivityId: id
        };
        if (isallocation) {
            // vdetails['allocationdriver'] = JSON.stringify({
            //     driver: data.Appointmentdrivername,
            //     date: data.AppointmentDate,
            //     allocationid: id1
            // });

            vdetails['dxDriverVehicleactivityId'] = data.Appointmentdrivername;
            vdetails['dxAllocationsActivityId'] = id1;
        }
        if (data.eventtype == 'Service') {
            if (va != null) {
                let vaevents: any = { ...va['events'] };
                if (va.events != null) {
                    vaevents = JSON.parse(va.events);
                } else {
                    vaevents['Service'] = {
                        date: '',
                        id: '',
                        allocationid: ''
                    };
                }
                vaevents['Service'] = {};
                vaevents['Service']['date'] = new Date(data.AppointmentDate);
                vaevents['Service']['id'] = id;
                vaevents['Service']['appointmentstatus'] = 'Scheduled';
                if (isallocation) {
                    vaevents['Service']['allocationid'] = id1;
                }
                vdetails['events'] = JSON.stringify(vaevents);
            }
        } else if (data.eventtype == 'Repair/Replacement') {
            // var repaircost = JSON.parse(va.events).Repair.cost;
            var repaircost = va.events == null ? 0 : JSON.parse(va.events).Repair == null ? 0 : JSON.parse(va.events).Repair.cost == null ? 0 : JSON.parse(va.events).Repair.cost;
            if (va != null) {
                let vaevents: any = { ...va['events'] };
                if (va.events != null) {
                    vaevents = JSON.parse(va.events);
                } else {
                    vaevents['Repair'] = {
                        date: '',
                        id: '',
                        allocationid: ''
                    };
                }
                vaevents['Repair'] = {};
                vaevents['Repair']['cost'] = repaircost;
                vaevents['Repair']['date'] = data.AppointmentDate;
                vaevents['Repair']['id'] = id;
                vaevents['Repair']['appointmentstatus'] = 'Scheduled';
                if (isallocation) {
                    vaevents['Repair']['allocationid'] = id1;
                }
                vdetails['events'] = JSON.stringify(vaevents);
            }
        } else if (data.eventtype == 'Internal Inspection') {
            if (va != null) {
                let vaevents: any = { ...va['events'] };
                if (va.events != null) {
                    vaevents = JSON.parse(va.events);
                } else {
                    vaevents['InternalInspection'] = {
                        date: '',
                        id: '',
                        allocationid: ''
                    };
                }
                vaevents['InternalInspection'] = {};
                vaevents['InternalInspection']['date'] = new Date(data.AppointmentDate);
                vaevents['InternalInspection']['id'] = id;
                vaevents['InternalInspection']['appointmentstatus'] = 'Scheduled';
                if (isallocation) {
                    vaevents['InternalInspection']['allocationid'] = id1;
                }
                vdetails['events'] = JSON.stringify(vaevents);
            }
        }
        let o = await API.graphql({
            query: updateDxVehicleActivity,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vdetails
            }
        });

        if (data.eventtype == 'Internal Inspection') {
            setShowSparesForm(false);
            navigate('/general/workspace');
        } else {
            setShowSparesForm(true);
        }
    };
    const OnAppointmentSave = async (data: any) => {
        var filter = {
            id: {
                eq: `${data.Appointmentvehicleid}` // filter priority = 1
            }
        };
        var GetResponse: any = await API.graphql({
            query: listDxVehicleActivities,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: { filter: filter }
        });
        // let vaevents = JSON.parse(Vehicleactivity.events);
        createAppointment(data.toggleAllocate, GetResponse.data.listDxVehicleActivities.items[0], data);
        // var dates = new Date(DateTime).toISOString();
        // var isodate = moment.utc('2019-11-03T05:00:00.000Z').format('MM/DD/YYYY');
        // var date = new Date('2023-01-11T08:45:53.539Z');
        id1 = Math.random().toString(36).substring(2, 8);
        if (data.toggleAllocate == true) {
            // createAppointment();
            let vehicle = {
                id: id1,
                vehicleid: data.Appointmentvehicleid,
                // drivername: data.Appointmentdrivername,
                dxDriverAllocationsId: data.Appointmentdrivername,
                allocatestatus: 'Allocated',
                fromdrivername: data.AppointmentDate,
                fromdate: data.AppointmentDate
            };
            let AddVehicleDetailsInsurence = await API.graphql(
                graphqlOperation(createDxAllocations, {
                    input: vehicle
                })
            );
        }
    };
    const dateTemplate = (data: any, col: string) => {
        return data.date == '' ? '-' : moment(data[col]).format('D-MM-YYYY');
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <ListnewForm
                    columns={formColumns}
                    layout="grid"
                    title="Appointment Form"
                    setToggleAllocate={setToggleAllocate}
                    AllocationDriver={setAllocationDriver}
                    AllocationDate={setAllocationDate}
                    AllocationId={setAllocationId}
                    submitForm={OnAppointmentSave}
                />
            </div>
            <div className="col-12 md:col-6">
                {ToggleAllocate && (
                    <div className="card">
                        <h5>Scheduled Allocation</h5>
                        <DataTable value={[{ vehicleid: AllocationId, date: AllocationDate, drivername: AllocationDriver }]} className="datatable-responsive" emptyMessage="No Allocation Schedules." responsiveLayout="scroll">
                            <Column field="vehicleid" header="VehicleId" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column
                                field="date"
                                header="Date"
                                body={(rowData: any) => {
                                    return dateTemplate(rowData, 'date');
                                }}
                                headerStyle={{ width: '14%', minWidth: '10rem' }}
                            ></Column>
                            <Column field="drivername" header="drivername" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            {/* <Column field="documentType" header="Document Type" body={DocumentTypeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>  <Column field="File" header="Attachment" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        </DataTable>
                    </div>
                )}

                {showSparesForm == true && <Sparesnewform />}
            </div>
        </div>
    );
}

export default AppointmentNewForm;
