import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import './Vehicleform.css';
import AmplifyServices from '../../Service/Service';
import { useParams, useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { createDxContract, createDxVehicle, updateDxVehicle } from '../../graphql/mutations';
import { listDxGeneralConfigs, listDxVehicles } from '../../graphql/queries';
import { Storage } from 'aws-amplify';
import moment from 'moment';
import VehicleEditForm from './DocumentEditform';
import ListnewForm from '../../Components/ListnewForm';
import { Steps } from 'primereact/steps';
import SContractnewform from '../ServiceContracts/Newform';
var data: any = [];
var Documents: any = [];
var DocumentsObject: any = {};
var listFormData: any = {};
const VehicleForm = () => {
    const [calendarValue, setCalendarValue] = useState<any>(null);
    const [startDate, setstartDate] = useState<any>('');
    const [Make, setMake] = useState<any>('');
    const [Model, setModel] = useState<any>('');
    const [VehicleYear, setVehicleYear] = useState<any>('');
    const [PlateCode, setPlateCode] = useState<any>('');
    const [PlateNumber, setPlateNumber] = useState<any>('');
    const [Department, setDepartment] = useState<any>('');
    const [Status, setStatus] = useState<any>('');
    const [Capacity, setCapacity] = useState<any>('');
    const [VehicleImage, setVehicleImage] = useState<any>('');
    const [EndDate, setEndDate] = useState<any>(null);
    const [Year, setYear] = useState<any>(null);
    const [File, setFile] = useState<any>('');
    const [dropdownItem, setDropdownItem] = useState<any>('');
    const [ShowDocuments, setShowDocuments] = useState(false);
    const [EditData, setEditData] = useState<any>({});

    const [listFormData, setlistFormData] = useState<any>({});
    const [Data, setData] = useState<any>([]);
    const [AddDocuments, setAddDocuments] = useState<any>([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [fileUrl, setFileUrl] = useState('');
    const [filename, setfilename] = useState('');
    const [files, setfiles] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [VehicleMakename, setVehicleMakename] = useState<any>([]);
    const [VehicleModelname, setVehicleModelname] = useState<any>([]);
    const [selectedmakeDropdown, setselectedmakeDropdown] = useState<any>(null);
    const toast = useRef<any>(null);
    const locations = useLocation();
    const location = window.location.href;
    const urlParams = new URLSearchParams(location);
    var editId = urlParams.has('id') ? urlParams.get('vid') : '';
    // var editId = location?.search.split('&')[1];
    var Param = locations?.search.split('&')[0].split('?form=')[1];

    const dropdownItems = [
        { name: 'Registration / Milkia', code: 'Registration' },
        { name: 'Service Contracts', code: 'ServiceContract' },
        { name: 'Milkia Insurance Returns', code: 'Insurance' },
        { name: 'RTA Test documents', code: 'RTADocuments' },
        { name: 'Warranty documents', code: 'Warranty' }
    ];
    const navigate = useNavigate();
    const NavigateToHome = () => navigate('/manage/vehicles');
    const checkActiveIndex = useCallback(() => {
        const paths = locations.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'seat':
                setActiveIndex(1);
                break;
            case 'payment':
                setActiveIndex(2);
                break;
            case 'confirmation':
                setActiveIndex(3);
                break;
            default:
                break;
        }
    }, [location]);

    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);
    useEffect(() => {
        if (Param == 'edit') {
            setShowDocuments(true);
            const getdata = async () => {
                let filter = {
                    and: [{ fleettype: { eq: 'vehicle' } }, { id: { eq: editId } }]
                };

                let GetResponse: any = await API.graphql({ query: listDxVehicles, variables: { filter: filter } });

                setEditData(GetResponse.data.listDxVehicles.items[0]);
                // console.log(GetResponse.data.listDxVehicles.items[0]);
                setEditData((state: any) => {
                    // console.log(state); // "React is awesome!"
                    // setMake(state.make);
                    // setModel(state.model);
                    // setVehicleYear(state.year);
                    // setPlateCode(state.platecode);
                    // setPlateNumber(state.plateno);
                    // setDepartment(state.departmentname);
                    // setStatus(state.status);
                    // setCapacity(state.capacity)
                    return state;
                });
                // console.log(EditData);
            };
            getdata().catch((err) => {
                console.log(err);
            });
            // console.log(EditData);
            Storage.list(`vehicle/${editId}/`) // for listing ALL files without prefix, pass '' instead
                .then((result: any) => {
                    let EditDocuments: any = [];
                    result.results.map(async (item: any) => {
                        // item.push(EditDocuments)
                        const signedURL = await Storage.get(item.key);

                        item.downLoadUrl = signedURL;

                        if (item.key.includes('Registration#')) {
                            item.documentType = 'Registration / Milkia';
                            item.name = item.key.split('#')[2];
                            item.Year = item.key.split('#')[1];
                            EditDocuments.push(item);
                        } else if (item.key.includes('ServiceContract#')) {
                            item.documentType = 'Service Contracts';
                            item.name = item.key.split('#')[2];
                            item.Year = item.key.split('#')[1];
                            EditDocuments.push(item);
                        } else if (item.key.includes('Insurance#')) {
                            item.documentType = 'Milkia Insurance Returns';
                            item.name = item.key.split('#')[2];
                            item.Year = item.key.split('#')[1];
                            EditDocuments.push(item);
                        } else if (item.key.includes('RTADocuments#')) {
                            item.documentType = 'RTA Test documents';
                            item.name = item.key.split('#')[2];
                            item.Year = item.key.split('#')[1];
                            EditDocuments.push(item);
                        } else if (item.key.includes('Waranty#')) {
                            item.documentType = 'Waranty documents';
                            item.name = item.key.split('#')[2];
                            item.Year = item.key.split('#')[1];
                            EditDocuments.push(item);
                        }

                        setAddDocuments(EditDocuments);
                    });
                })

                .catch((err) => console.log(err));
        }
        getMakeModel();
    }, []);
    const getMakeModel = async () => {
        await AmplifyServices.getTableDetails(listDxGeneralConfigs, 'listDxGeneralConfigs').then(async (res: any) => {
            let Make = res.filter((dept: any) => dept.fleettype.includes('Make'));
            let Model = res.filter((dept: any) => dept.fleettype.includes('Model'));
            setVehicleMakename(Make);
            setVehicleModelname(Model);
        });
    };
    const AddVehicleData = async () => {
        var UploadedFiles: any = [];
        // console.log(Documents);
        let allDocs: any = {};
        if (Documents.length == 0) {
            AmplifyServices.AddVehicleDetails(
                'vehicle',
                listFormData.PlateCode,
                listFormData.PlateNumber,
                listFormData.make,
                listFormData.model,
                listFormData.VehicleYear,
                listFormData.Department,
                listFormData.Status,
                listFormData.Capacity,
                listFormData.ImagePath
            )
                .then(async (res: any) => {
                    NavigateToHome();
                })
                .catch((err) => {
                    console.log(err);
                });
            let vehicleactivity = {
                id: `${listFormData.PlateCode + listFormData.PlateNumber}`,
                fleettype: 'vehicle#activity',
                VehicleStatus: listFormData.Status
            };
            let o = await API.graphql(
                graphqlOperation(createDxVehicle, {
                    input: vehicleactivity
                })
            );
            console.log(o);
        } else {
        }
        console.log(Documents);

        Documents.map(async (docfile: any, key: any) => {
            // console.log(docfile);
            var StoreDocuments = await Storage.put('RenewalDocuments/' + listFormData.PlateCode + listFormData.PlateNumber + '/' + new Date().getFullYear() + '/' + docfile.Filename, docfile.Filepath);
            // var StoreDocuments = await Storage.put('vehicle/' + listFormData.PlateCode + listFormData.PlateNumber + '/' + docfile.Filename, docfile.Filepath);

            // console.log('saved',res);
            UploadedFiles.push(StoreDocuments);

            //Adding it to the ContractTable
            let contractData: any = {};
            if (Documents.length > 0) {
                contractData = {
                    id: `${listFormData.PlateCode + listFormData.PlateNumber}`,
                    policyno: listFormData.policyno,
                    issuedate: docfile.issueDate,
                    expirydate: docfile.expiryDate,
                    renewaltype: docfile.documentType,
                    documents: StoreDocuments.key,
                    renewalstatus: 'Completed',
                    _version: 1
                };
                let contract: any = await API.graphql(
                    graphqlOperation(createDxContract, {
                        input: contractData
                    })
                );
                console.log(contract);
                // add items to vehicle activity fleettype

                let vehicleactivity: any = {
                    id: `${listFormData.PlateCode + listFormData.PlateNumber}`,

                    VehicleStatus: listFormData.Status,
                    platecode: listFormData.PlateCode,
                    plateno: listFormData.PlateNumber,
                    make: listFormData.make,
                    model: listFormData.model,
                    year: listFormData.VehicleYear,
                    ImagePath: listFormData.ImagePath,
                    departmentname: listFormData.Department,
                    capacity: listFormData.Capacity,
                    status: listFormData.Status
                };
                if (Documents.length > 1) {
                    allDocs = {
                        ...allDocs,
                        [docfile.documentType]: {
                            issueDate: docfile.issueDate,
                            expiryDate: docfile.expiryDate,
                            documents: StoreDocuments.key,
                            status: 'Completed'
                        }
                    };

                    if (Documents.length == key + 1) {
                        vehicleactivity['expiring'] = JSON.stringify(allDocs);
                        let fleets = ['vehicle', 'vehicle#activity'];
                        for (let i = 0; i < fleets.length; i++) {
                            vehicleactivity['fleettype'] = fleets[i];
                            let o = await API.graphql(
                                graphqlOperation(createDxVehicle, {
                                    input: vehicleactivity
                                })
                            );
                            await AmplifyServices.getvehicledetails('vehicle');
                            setData([]);
                            data = [];
                            Documents = [];
                            NavigateToHome();
                            console.log(0);
                        }
                        // console.log(vehicleactivity);
                    }
                } else {
                    vehicleactivity['expiring'] = JSON.stringify({
                        [docfile.documentType]: {
                            issueDate: docfile.issueDate,
                            expiryDate: docfile.expiryDate,
                            documents: StoreDocuments.key,
                            status: 'Completed'
                        }
                    });
                    let fleets = ['vehicle', 'vehicle#activity'];
                    for (let i = 0; i < fleets.length; i++) {
                        vehicleactivity['fleettype'] = fleets[i];
                        let o = await API.graphql(
                            graphqlOperation(createDxVehicle, {
                                input: vehicleactivity
                            })
                        );
                        console.log(0);
                        await AmplifyServices.getvehicledetails('vehicle');
                        setData([]);
                        data = [];
                        Documents = [];
                        NavigateToHome();
                    }
                }
                console.log(vehicleactivity);
            } else {
            }

            // add items to vehicle fleettype

            let FormatStartDate = moment(startDate).format('yyyy');
            UploadedFiles.filter((doc: any) => doc.key.includes('Registration#')).length > 0 &&
                AmplifyServices.AddVehicleRegistrationDetails('vehicle#Registration#2022', PlateCode, PlateNumber, Make, Model, VehicleYear, UploadedFiles).then((res: any) => {});
            UploadedFiles.filter((doc: any) => doc.key.includes('Insurance#')).length > 0 && AmplifyServices.AddVehicleInsurenceDetails('vehicle#Insurance#2022', PlateCode, PlateNumber, Make, Model, VehicleYear, UploadedFiles).then((res: any) => {});

            UploadedFiles.filter((doc: any) => doc.key.includes('ServiceContract#')).length > 0 &&
                AmplifyServices.AddVehicleServiceContractDetails('vehicle#ServiceContract#2022', PlateCode, PlateNumber, Make, Model, VehicleYear, UploadedFiles).then((res: any) => {});
            UploadedFiles.filter((doc: any) => doc.key.includes('Waranty#')).length > 0 && AmplifyServices.AddVehicleWarrentyDetails('vehicle#Warrenty#2022', PlateCode, PlateNumber, Make, Model, VehicleYear, UploadedFiles).then((res: any) => {});
            UploadedFiles.filter((doc: any) => doc.key.includes('RTADocuments#')).length > 0 && AmplifyServices.AddVehicleRTADetails('vehicle#RTA#2022', PlateCode, PlateNumber, Make, Model, VehicleYear, UploadedFiles).then((res: any) => {});

            // } else {
            //     console.log('uploading');
            // }
        });
        // setData([])
        // Documents=[]
        // await AmplifyServices.getvehicledetails('vehicle');
        // NavigateToHome();
        setMake('');
        setModel('');
        setVehicleYear('');
        setPlateCode('');
        setPlateNumber('');
    };

    const openDocument = (props: any) => {
        setlistFormData(props);
        setShowDocuments(true);

        DocumentsObject.filename = filename;
        DocumentsObject.filepath = files;

        // Documents.push({ Filename: 'Vehiclepicture#' + filename, Filepath: files });
    };

    const save = () => {
        // setState(prevState => ({
        //     ...prevState,
        //     fName: 'your updated value here'
        //  }));
        var DocumentType = dropdownItem.name;
        var DocumentTypeName = dropdownItem.code;
        var FileType = File;
        var year = Year;
        var startdate = startDate;
        var enddate = EndDate;

        // console.log(DocumentType,FileType);

        if (DocumentType !== undefined && FileType !== '') {
            var uniqueIds: any = [];
            DocumentsObject.filename = File.name;
            DocumentsObject.filepath = File;

            let PrefixImage = '';

            let FormatStartDate = startDate != '' ? moment(startDate).format('yyyy') : '-';
            // moment(startDate).format('yyyy')
            if (DocumentType == 'Registration / Milkia') {
                PrefixImage = `Registration#${FormatStartDate}#`;
            } else if (DocumentType == 'Service Contracts') {
                PrefixImage = `ServiceContract#${FormatStartDate}#`;
            } else if (DocumentType == 'Milkia Insurance Returns') {
                PrefixImage = `Insurance#${FormatStartDate}#`;
            } else if (DocumentType == 'RTA Test documents') {
                PrefixImage = `RTADocuments#${FormatStartDate}#`;
            } else if (DocumentType == 'Waranty documents') {
                PrefixImage = `Waranty#${FormatStartDate}#`;
            }

            Documents.push({ Filename: PrefixImage + File.name, Filepath: File, documentType: DocumentTypeName, issueDate: startDate, expiryDate: enddate });

            var filterdocumenttype = data.filter((data: any) => data.documentType.includes(DocumentType));

            if (filterdocumenttype.length > 0) {
                filterdocumenttype[0].File = FileType;
                let allobj = Object.assign({}, ...filterdocumenttype);
                data.push(allobj);

                const obj = { documentType: DocumentType, File: FileType, year: FormatStartDate, startdate: startdate, enddate: enddate };

                var alldata: any = data.push(obj);
                setData(data);
            } else {
                const obj = { documentType: DocumentType, File: FileType, year: FormatStartDate, startdate: startdate, enddate: enddate };
                var alldata: any = data.push(obj);

                setData(data);
            }

            setDropdownItem('');

            const file: any = document.querySelector('.file');
            file.value = '';
            setFile('');
            setYear('');
            setstartDate('');
            setEndDate('');
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Please fill the Document Type and Attachment',
                life: 3000
            });
        }
    };
    const fileType = (e: any) => {
        // console.log(e.target.files[0].name);
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Products</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DocumentTypeBodyTemplate = (rowData: any) => {
        // console.log(rowData.documentType);
        // console.log(rowData);

        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.documentType}
            </>
        );
    };
    const AttachmentBodyTemplate = (rowData: any) => {
        // var attachment=Param=='add'?(<>
        // <span className="p-column-title">Code</span>
        // {rowData.File.name !== undefined ? rowData.File.name : ''}
        // </>):Param=='edit'?(<>
        //     <span className="p-column-title">Code</span>
        //     <a href={rowData.downLoadUrl}>{rowData.name}</a>

        // </>):''
        var attachment =
            rowData.name !== undefined ? (
                <>
                    <span className="p-column-title">Code</span>
                    <a href={rowData.downLoadUrl}>{rowData.name}</a>
                </>
            ) : (
                <>
                    <span className="p-column-title">Code</span>
                    {rowData.File.name}
                </>
            );
        return <>{attachment}</>;
    };
    var FormBtn =
        Param == 'add' ? (
            <>
                {' '}
                <Button
                    label="Submit"
                    className="mr-2 mb-2"
                    onClick={(e) => {
                        AddVehicleData();
                    }}
                />
            </>
        ) : Param == 'edit' ? (
            <>
                {' '}
                <Button
                    label="Save"
                    className="mr-2 mb-2"
                    onClick={(e) => {
                        // EditVehicleData();
                    }}
                />
            </>
        ) : (
            ''
        );

    const handleChange = (e: any) => {
        const file = e.target.files[0];
        setFileUrl(URL.createObjectURL(file));
        setfilename(file.name);
        setfiles(file);
    };
    const Savefile = () => {
        // console.log(filename, fileUrl, files);
    };
    const vehicleMake: any = [];
    const Makename = VehicleMakename.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        // console.log(res);
        vehicleMake.push(res);
    });

    const vehicleModel: any = [];
    const Modelname = VehicleModelname.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        // console.log(res);
        vehicleModel.push(res);
    });
    const ManagevehicleColumns = {
        columns: [
            {
                name: 'make',
                value: 'Make',
                fieldtype: 'dropdown',
                required: true,

                hidden: false,

                disabled: ShowDocuments == true ? true : false,
                data: vehicleMake
            },
            {
                name: 'model',
                value: 'Model',
                fieldtype: 'dropdown',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: selectedmakeDropdown !== null ? vehicleModel.filter((res: any) => res.label.toLowerCase().includes(selectedmakeDropdown.toLowerCase())) : []
            },
            {
                name: 'PlateCode',
                value: 'PlateCode',
                fieldtype: 'text',
                required: true,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'PlateNumber',
                value: 'PlateNumber',
                fieldtype: 'text',
                required: true,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },

            {
                name: 'VehicleYear',
                value: 'year',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'Department',
                value: 'Department',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'Status',
                value: 'Status',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: [
                    { label: 'Active', value: 'Active' },
                    { label: 'Inactive', value: 'Inactive' }
                ]
            },
            {
                name: 'Capacity',
                value: 'Capacity',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'ChassisNo',
                value: 'Chassis No',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'LicensePlate',
                value: 'License Plate',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'RegistrationDate',
                value: 'Registration Date',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'ExpiryDate',
                value: 'Expiry Date',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'SalikNumber',
                value: 'Salik Number',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'SalikPIN',
                value: 'Salik PIN',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'FuelCardNumber',
                value: 'Fuel Card Number',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'Owner',
                value: 'Owner',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'SCExpiryDate',
                value: 'Service Contract Expiry Date',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'Warantyexpirydate',
                value: 'Waranty expiry date',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            { name: 'ImagePath', value: 'Picture of Vehicle', fieldtype: 'fileupload', required: false, disabled: ShowDocuments == true ? true : false, hidden: false, data: [] }
        ],
        fleettype: 'vehicle'
    };
    const wizardItems = [
        { label: 'Basic Details', command: () => navigate('/manage/vehicles/submitVehicleform?form=add') },
        { label: 'Documents', command: () => navigate('/manage/servicecontracts/add') },
        { label: 'Payment', command: () => navigate('/uikit/menu/payment') },
        {
            label: 'Confirmation',
            command: () => navigate('/uikit/menu/confirmation')
        }
    ];
    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <div className="card card-w-title">
                    <h5>Steps</h5>
                    <Steps model={wizardItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
                    <Routes>
                        <Route
                            path={'/'}
                            element={
                                <ListnewForm
                                    bucketname="vehicle"
                                    Fleettype={'vehiclePicture'}
                                    columns={ManagevehicleColumns}
                                    setselectedmakeDropdown={setselectedmakeDropdown}
                                    ChangeBtn={'OpenDocument'}
                                    layout="grid"
                                    title="New vehicle"
                                    submitForm={openDocument}
                                />
                            }
                        />
                        {/* <Route path={'/confirmation'} element={<ConfirmationDemo />} />
                    <Route path={'/payment'} element={<PaymentDemo />} /> */}
                        <Route path={'/manage/servicecontracts/add'} element={<SContractnewform />} />
                    </Routes>
                </div>
            </div>
            {ShowDocuments ? (
                <div className="col-12 md:col-6">
                    {/* {Param=='edit'?<VehicleEditForm data={AddDocuments} />:''} */}
                    <div className="card">
                        <h5>Documents</h5>

                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="name1">Document Type </label>
                                <Dropdown id="state" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} options={dropdownItems} optionLabel="name" placeholder="Select One"></Dropdown>
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">Start Date</label>
                                <Calendar showIcon showButtonBar value={startDate} onChange={(e) => setstartDate(e.value)}></Calendar>
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">End Date</label>
                                <Calendar showIcon showButtonBar value={EndDate} onChange={(e) => setEndDate(e.value)}></Calendar>
                            </div>
                            <div className="field col-9 md:col-10">
                                <label htmlFor="email1">Attachment</label>
                                {/* <FileUpload mode="basic"   name="demo[]" url="./upload.php" accept="image/*" maxFileSize={1000000} onUpload={(e)=>onUpload(e)} onSelect={(e)=>fileType(e)} /> */}
                                {/*<div className="container">
      <div className="button-wrap">
        <label className="button"  htmlFor="upload" >Upload File</label>
        <input type='file' className="file"  id="fileUpload"   onChange={(e:any)=>setFile(e.target.files[0].name)} />
      </div>
    </div> */}
                                {/* <form action="/form/sumbit" method="get">
      <label className="label">
      <input type='file' className="file"  id="upload"   onChange={(e:any)=>setFile(e.target.files[0].name)} />
        <span>Select a file</span>
      </label>
    </form> */}
                                <form>
                                    {/* <label htmlFor="fileUpload">Upload file</label> */}
                                    <input type="file" className="file" id="fileUpload" onChange={(e: any) => setFile(e.target.files[0])} />
                                </form>
                            </div>
                            <div className="field col-2 md:col-2" style={{ width: 'auto' }}>
                                <label htmlFor="email1"></label>
                                <label htmlFor="email1"></label>
                                <Button className="mr-2 mb-2" onClick={save}>
                                    <i className="pi pi-plus" style={{ position: 'relative', left: '13px' }}></i>
                                </Button>
                            </div>
                            <div className="field col-12">
                                <div className="card">
                                    <div className="p-fluid formgrid grid">
                                        <div className="field col-12 md:col-12">
                                            <DataTable
                                                value={Data}
                                                selection={selectedProducts}
                                                onSelectionChange={(e) => setSelectedProducts(e.value)}
                                                dataKey="id"
                                                rowsPerPageOptions={[5, 10, 25]}
                                                className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                                globalFilter={globalFilter}
                                                emptyMessage="No Documents added."
                                                responsiveLayout="scroll"
                                            >
                                                <Column field="year" header="Year" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                                <Column field="documentType" header="Document Type" body={DocumentTypeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                                <Column field="File" header="Attachment" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field col-12 md:col-5"></div>
                            <div className="field col-12 md:col-4"></div>
                            <div className="field col-12 md:col-3">{FormBtn}</div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
    //#region Unsed functions
    <div className="p-fluid formgrid grid">
        <div className="field col-12 md:col-6">
            <label htmlFor="name1">Make</label>

            <Dropdown
                value={Make}
                onChange={(e) => setMake(e.target.value)}
                // options={FilteredDriverName}
                // disabled={formtype == 'edit' ? true : false}
                // options={Vehicle}
                optionDisabled={(option: any) => option.isdisabled} // disable an option
                optionLabel="label"
            />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="email1">Model</label>
            <InputText type="text" value={Model} onChange={(e) => setModel(e.target.value)} />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="email1">Year</label>
            <InputText type="text" value={VehicleYear} onChange={(e) => setVehicleYear(e.target.value)} />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Plate Code</label>
            <InputText type="text" value={PlateCode} onChange={(e) => setPlateCode(e.target.value)} />
        </div>
        <div className="field col-12 md:col-12">
            <label htmlFor="email1">Pictures of Vehicle</label>
            {/* <FileUpload mode="basic" name="demo[]" url="./upload.php" accept="image/*" maxFileSize={1000000} onUpload={onUpload} /> */}
            {/* <input multiple type="file" onChange={handleChange} /> */}
            <FileUpload
                name="demo[]"
                // url="./upload.php"
                uploadHandler={handleChange}
                multiple
                accept="image/*"
                maxFileSize={1000000}
            />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Chassis No</label>
            <InputText type="text" />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">License Plate</label>
            <InputText type="text" />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Registration Date</label>
            <Calendar showIcon showButtonBar value={calendarValue} onChange={(e) => setCalendarValue(e.value)}></Calendar>
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Expiry Date</label>
            <Calendar showIcon showButtonBar value={calendarValue} onChange={(e) => setCalendarValue(e.value)}></Calendar>
        </div>

        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Plate Number</label>
            <InputText type="text" value={PlateNumber} onChange={(e) => setPlateNumber(e.target.value)} />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Salik Number</label>
            <InputText type="text" />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Department</label>
            <InputText type="text" value={Department} onChange={(e) => setDepartment(e.target.value)} />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Status</label>
            <InputText type="text" value={Status} onChange={(e) => setStatus(e.target.value)} />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Salik PIN</label>
            <InputText type="text" />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Fuel Card Number</label>
            <InputText type="text" />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Passenger Capacity</label>
            <InputText type="text" value={Capacity} onChange={(e) => setCapacity(e.target.value)} />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Owner</label>
            <InputText type="text" />
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Service Contract Expiry Date</label>
            <Calendar showIcon showButtonBar value={calendarValue} onChange={(e) => setCalendarValue(e.value)}></Calendar>
        </div>
        <div className="field col-12 md:col-6">
            <label htmlFor="age1">Waranty expiry date</label>
            <Calendar showIcon showButtonBar value={calendarValue} onChange={(e) => setCalendarValue(e.value)}></Calendar>
        </div>
        <div className="field col-12 md:col-4"></div>
        <div className="field col-12 md:col-4">
            <Button label="Documents" className="mr-2 mb-2" onClick={openDocument} />
            {/* <Button label="Submit" className="mr-2 mb-2"  onClick={Param=='add'?AddVehicleData:Param=='edit'?EditVehicleData:''} /> */}
        </div>
        <div className="field col-12 md:col-4"></div>
    </div>;

    //#endregion
};
export default VehicleForm;
