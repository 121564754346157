import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
let invoiceUrl: string;
//Amplify.configure(awsconfig);
var pagination: any = '';

const ListTemplate = (props: any) => {
    const [selectedProducts, setSelectedProducts] = useState<any>([]);

    useEffect(() => {}, []);

    const formatDate = (value: any, col: any) => {
        let date = value[col.field];
        if (!date) {
            return '';
        }
        const time = date.toLocaleTimeString('en-US', { hour12: true });
        const formattedTime = moment(time, 'HH:mm:ss').format('HH:mm');

        const formattedDate =
            col.showtime && value[col.field] !== null
                ? moment(
                      date.toLocaleDateString('en-US', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                      }),
                      'MM/DD/YYYY'
                  ).format('DD-MMM-YYYY') +
                  ' ' +
                  formattedTime
                : value[col.field] !== null
                ? moment(
                      date.toLocaleDateString('en-US', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                      }),
                      'MM/DD/YYYY'
                  ).format('DD-MMM-YYYY')
                : '';
        return formattedDate;
    };
    const showdata = (rowData: any, col: any) => {
        switch (col.fieldtype) {
            case 'image':
                return col.template(rowData);
            case 'description':
                return (
                    <span className="truncate" style={{ width: col.width ? col.width : 'inherit' }}>
                        {rowData[col.field]}
                    </span>
                );
            case 'custom':
                return col.template(rowData);
                break;
            case 'user':
                return <span>{rowData[col.field] ? rowData[col.field][col.showfield] : ''}</span>;
            case 'lookup':
                return <span>{rowData[col.field] ? rowData[col.field].name : ''}</span>;
            case 'lookupcount':
                return <span>{rowData[col.field] && rowData[col.field].items ? rowData[col.field].items.length : ''}</span>;
            case 'lookupcollection':
                let a: any = [];
                if (rowData[col.field] && rowData[col.field].items)
                    rowData[col.field].items.forEach((e: any) => {
                        a.push(e.title);
                    });

                return <span>{a.join(';')}</span>;
            case 'date':
                return col.showtime && rowData[col.field] !== null ? moment(rowData[col.field]).format('DD-MMM-YYYY hh:mm') : rowData[col.field] !== null ? moment(rowData[col.field]).format('DD-MMM-YYYY') : '';
                break;
            case 'datefilter':
                return formatDate(rowData, col);
                break;
            case 'textstr':
                return <span>{rowData.vehicleid.includes('[', ']') ? rowData.vehicleid.replace('[', '').replace(']', '') : rowData.vehicleid}</span>;
                break;
            case 'status':
                return <span className={`customer-badge status-${rowData.status.replaceAll(' ', '')}`}>{rowData.status}</span>;
                break;
            case 'boolean':
                return <span>{rowData.isactive ? rowData.isactive.toString() : rowData[col.field] ? rowData[col.field].toString() : 'false'}</span>;
                break;
            case 'navigatedriver':
                return (
                    <a href={`#/manage/drivers/item/basic?mode=view&id=${rowData.id}&fleettype=driver`} className={`customer-badge status-${rowData.id}`}>
                        {rowData.name}
                    </a>
                );
                break;
            case 'navigatedocument':
                //manage/documents/item/basic?mode=edit&
                return (
                    <a href={`#/manage/renewals/item/basic?mode=view&id=${rowData.id}&type=${rowData.renewaltype}`} className={`customer-badge status-${rowData.vehicleid}`}>
                        {rowData.vehicleid.includes('[', ']') ? rowData.vehicleid.replace('[', '').replace(']', '') : rowData.vehicleid}
                    </a>
                );
                break;
            case 'navigateservice':
                return (
                    <a href={`${col.path}${rowData.id}`} className={`customer-badge status-${rowData.id}`}>
                        {rowData.vehicleid.includes('[', ']') ? rowData.vehicleid.replace('[', '').replace(']', '') : rowData.vehicleid}
                    </a>
                );
                break;
            case 'href':
                //#/manage/vehicles/item/basic?mode=view&id=
                const enddate = new Date(rowData['enddate']);
                if (!enddate) return '';
                const next15daysDate = new Date();
                next15daysDate.setDate(next15daysDate.getDate() + 15);
                let vid = rowData.vehicleid ? `&vid=${rowData.vehicleid}` : '';
                return (
                    <a href={`${col.path}${rowData.id}${vid}`} className={`customer-badge status-${rowData.id} ${col.highlight && enddate < next15daysDate ? '' : ''}`}>
                        {col.showfield ? rowData[col.showfield] : rowData.id}
                    </a>
                );
                break;
            case 'link':
                //#/manage/vehicles/item/basic?mode=view&id=
                return (
                    <a href={`${col.path}${rowData[col.field]}`} className={`customer-badge status-${rowData.id}`}>
                        {col.showfield ? rowData[col.showfield] : rowData[col.field]}
                    </a>
                );
                break;
            case 'partnames':
                let names = [];

                if (rowData.partname !== null && rowData.partname !== '') {
                    names = rowData.partname !== null && JSON.parse(rowData.partname);
                    let partnames: any = [];
                    for (let i = 0; i < names.length; i++) {
                        let option = {
                            value: names[i],
                            label: names[i]
                        };
                        partnames.push(option);
                    }
                    return <Dropdown options={partnames} value={partnames[0].label} defaultValue={partnames[0].label} />;
                }

                break;
            default:
                return <span>{rowData[col.field]}</span>;
        }
    };

    const header = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                <Button
                    icon="pi pi-plus"
                    label="New"
                    // onClick={expandAll}
                    className="p-button-success mr-2 mb-2"
                />
                <Button
                    icon="pi pi-minus"
                    label="Actions"
                    // onClick={collapseAll}
                    className="p-button-danger mb-2"
                    disabled={!selectedProducts || !selectedProducts.length}
                />
            </div>
            <div>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <input
                        // value={FilterData}
                        type="text"
                        id="search"
                        // onChange={SearchOnchange}
                        placeholder="Search ..."
                        className="homeNews__search"
                    />
                    {/* <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} placeholder="Search..." /> */}
                    {/* <form>
                        <div
                            style={{
                                position: 'relative',
                                marginTop: '3px',
                                marginBottom: '1em'
                            }}
                        > */}

                    {/* {FilterData.length > 0 ? (
                            <button
                                type="button"
                                className='contentBox__searchClearBtn'
                                onClick={clearbtn}
                            >
                                X
                            </button>
                        ) : (
                            ""
                        )} */}
                    {/* </div>
                    </form> */}
                </span>
            </div>
        </div>
    );
    const OnSelectedChange = (e: any) => {
        setSelectedProducts(e.value);
        props.selectedItems(e.value);
    };
    const dateFilterTemplate = (options: any) => {
        return <Calendar value={options.value} onChange={(e: any) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    return (
        <div className="grid table-demo">
            <div className="col-12">
                {/* <div className="card"> */}
                {/* <h5>{props.header ? props.header : 'Manage'}</h5> */}

                <DataTable
                    value={props.value && props.value.filter((item: any) => item._deleted === undefined || item._deleted === null || item._deleted === false)}
                    rows={props.rows}
                    paginator={props.paginator != undefined && props.paginator ? true : false}
                    emptyMessage={props.emptyMessage != undefined && props.emptyMessage ? props.emptyMessage : ''}
                    responsiveLayout={props.responsiveLayout != undefined && props.responsiveLayout ? props.responsiveLayout : ''}
                    className={props.className != undefined && props.className ? props.className : ''}
                    header={props.Commandbar != undefined && props.Commandbar}
                    paginatorTemplate={props.paginatorTemplate != undefined && props.paginatorTemplate ? props.paginatorTemplate : ''}
                    currentPageReportTemplate={props.currentPageReportTemplate != undefined && props.currentPageReportTemplate ? props.currentPageReportTemplate : ''}
                    rowsPerPageOptions={props.rowsPerPageOptions != undefined && props.rowsPerPageOptions ? props.rowsPerPageOptions : ''}
                    onSelectionChange={(e) => OnSelectedChange(e)}
                    // selection={Data.selection != undefined && Data.selection ? Data.selection : ''}
                    loading={props.loading}
                    selection={selectedProducts}
                    // onSelectionChange={e => setSelectedProducts(e.value)}
                    filterDisplay="menu"
                    dataKey="id"
                    filters={props.tablefilter}
                    scrollHeight={props.scrollHeight ? props.scrollHeight : 'auto'}

                    // filters={props.tablefilter}
                >
                    {props.multiselect && <Column key={'-1'} selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>}

                    {props.dynamicColumns.map((col: any, i: number) => {
                        return (
                            <Column
                                key={col.field + '_' + i.toString()}
                                // selectionMode={col.fieldtype != undefined && col.fieldtype ?"multiple"  : ''}
                                field={col.field !== undefined && col.field}
                                //   filter={false}
                                sortable={col?.sortable}
                                sortField={col?.sortField}
                                filterMenuStyle={{ minWidth: col.width ? col.width : 'inherit' }}
                                style={{ minWidth: col.width ? col.width : 'inherit' }}
                                filterField={col.fieldtype == 'lookup' ? col.filterFieldOption + '.name' : col.filterFieldOption}
                                filter={col.filterOption == true ? true : false}
                                body={(rowData: any) => {
                                    return showdata(rowData, col);
                                }}
                                filterElement={col.dataType && dateFilterTemplate}
                                dataType={col.dataType && col.dataType}
                                header={col.header}
                                headerStyle={col.headeralign && { textAlign: col.headeralign }}
                                bodyStyle={col.bodyalign && { textAlign: col.bodyalign }}
                                bodyClassName={(rowData: any) => {
                                    if (rowData.enddate === null) return '';
                                    const enddate = new Date(rowData['enddate']);
                                    if (!enddate && !col?.highlight) return '';
                                    const today = new Date();
                                    const next30days = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);
                                    if (enddate < today) {
                                        return 'highlight-red'; // add class for red highlight
                                    } else if (enddate < next30days) {
                                        return 'highlight-orange'; // add class for orange highlight
                                    } else {
                                        return ''; // no highlight class
                                    }
                                }}
                            />
                        );
                    })}
                </DataTable>
                {/* {selectedProducts.length > 0 && selectedProducts != null ? <Vehicles selectedProducts={selectedProducts} className="ListTable" OnSelectedChange={OnSelectedChange} /> : ''}
                {selectedProducts.length > 0 && selectedProducts != null ? <Task selectedProducts={selectedProducts} className="ListTable" OnSelectedChange={OnSelectedChange} /> : ''}
                {selectedProducts.length > 0 && selectedProducts != null ? <ManageAppointments selectedProducts={selectedProducts} className="ListTable" OnSelectedChange={OnSelectedChange} /> : ''} */}
            </div>
        </div>
        // </div>
    );
};

export default ListTemplate;
