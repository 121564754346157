import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./WorkSpace.scss";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { OverlayPanel } from "primereact/overlaypanel";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Badge } from "primereact/badge";
import { API, Storage } from "aws-amplify";
import {
  listDxVehicles,
  listDxAppointments,
  listDxContracts,
} from "../../graphql/queries";
import { useLocation } from "react-router";
import AmplifyServices from "../../Service/Service";
import AllocateDriverPopUp from "./WSAllocation";
import {
  convertdate,
  getNextQueryData,
  getcurrentallocationfromvehicleactivity,
  isotolocaledatetime,
  localetoisodateendtime,
  localetoisodatetime,
} from "../../utils/helper";
import { Tooltip } from "primereact/tooltip";
import dxService from "../../Services/dxService";
import { Link } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { Calendar } from "primereact/calendar";
let menuname: any = "";
// var id: any;
var currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() - 1);
currentDate.setDate(1);

// Calculate the start and end dates of the month  last
var lastMonthStartDate: any = currentDate.toDateString();
currentDate.setMonth(currentDate.getMonth() + 1);
currentDate.setDate(0);
var lastMonthEndDate: any = currentDate.toDateString();
var newDate = new Date();
// Set the date to the first day of the month
const ThisMonthstartDate: any = new Date(
  newDate.getFullYear(),
  newDate.getMonth(),
  1
);

// Set the date to the last day of the month
const ThisMonthendDate: any = new Date(
  newDate.getFullYear(),
  newDate.getMonth() + 1,
  0
);

let AllDriver: any = [];
const WorkSpace = () => {
  const { dispatch } = useContext(AuthContext);
  //#region Reference Variables
  const toast = useRef<any>();
  const expSoonMenu = useRef<any>(null);
  const newrenewal = useRef<any>(null);
  const expSoonScheduleMenu = useRef<any>(null);

  const opdatatable = useRef<any>(null);
  const VechileidAllocation = useRef<any>(null);
  const serviceRef = useRef<any>(null);
  const repairrreplaceRef = useRef<any>(null);
  const inspectionRef = useRef<any>(null);
  const InsuranceRef = useRef<any>(null);
  const MulkiaRef = useRef<any>(null);
  const accidentRef = useRef<any>(null);
  const DriverAllocation = useRef<any>(null);
  const SericesAppointment = useRef<any>(null);
  const RepairAppointment = useRef<any>(null);
  const InternalInspectionAppointment = useRef<any>(null);
  const AccidentToggle = useRef<any>(null);
  const RefInsuranceCost = useRef<any>(null);
  const RefMulkiaCost = useRef<any>(null);
  const FineToggle = useRef<any>(null);
  const SalikToggle = useRef<any>(null);
  const VipfuelToggle = useRef<any>(null);
  const MileageToggle = useRef<any>(null);
  const ParkingToggle = useRef<any>(null);
  const fineRef = useRef<any>(null);
  const vipfuelRef = useRef<any>(null);
  const mileageRef = useRef<any>(null);
  const salikRef = useRef<any>(null);
  const ParkingRef = useRef<any>(null);
  //#endregion

  //#region State Variables
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [DropdownItem, setDropdownItem] = useState<any>(null);
  const [sales, setSales] = useState<any>([]);
  const [Database, setDatabase] = useState<any>([]);
  const [FilterData, setFilterData] = useState<any>("");
  const [filtervehicleid, setfiltervehicleid] = useState<any>("");
  const [VehicleDriver, setVehicleDriver] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [AppointmentData, setAppointmentData] = useState<any>([]);
  const [AllocateDriverToggle, setAllocareDriverToggle] = useState<any>(false);
  const [DeAllocateDriverToggle, setDeAllocareDriverToggle] =
    useState<any>(false);
  const [ServiceAppointmentFormToggle, setServiceAppointmentFormToggle] =
    useState<any>(false);
  const [RepairAppointmentFormToggle, setRepairAppointmentFormToggle] =
    useState<any>(false);
  const [InternalAppointmentFormToggle, setInternalAppointmentFormToggle] =
    useState<any>(false);
  const [InsuranceFormToggle, setInsuranceFormToggle] = useState<any>(false);
  const [MulkiaFormToggle, setMulkiaFormToggle] = useState<any>(false);
  const [
    ServiceAppointmentFormEditToggle,
    setServiceAppointmentFormEditToggle,
  ] = useState<any>(false);
  const [RepairAppointmentFormEditToggle, setRepairAppointmentFormEditToggle] =
    useState<any>(false);
  const [
    InternalAppointmentFormEditToggle,
    setInternalAppointmentFormEditToggle,
  ] = useState<any>(false);
  const [selectedrow, setselectedrow] = useState<any>();
  const [sparamsvalue, setsparamsvalue] = useState<any>("");

  //allocation driver setstate

  const [pastvehicledrivers, getpastvehicledrivers] = useState<any>([]);
  const [Vehiclestatuskey, setVehiclestatuskey] = useState<any>(null);
  const [expiringfilterkey, setexpiringfilterkey] = useState<any>(null);
  const [lastdaysfilterkey, setlastdaysfilterkey] = useState<any>(null);
  const [filterReports, setfilterReports] = useState<any>([]);
  // const [filterLogs, setFilterLogs] = useState<any>([]);
  const [expiringName, setExpiringName] = useState<any>("");
  const [recentAppointments, setRecentAppointments] = useState<any>([]);
  const [recentRenewals, setrecentRenewals] = useState<any>([]);
  const [recentLogs, setLogs] = useState<any>([]);
  const [recentLogsLoadingmsg, setLogsLoadingmsg] = useState<any>("");
  const [logVid, setLogVid] = useState<any>("");
  const [repair, setrepair] = useState<any>("");
  const navigate = useNavigate();
  const location = useLocation();
  const [customDate, setCustomDate] = useState<any>([]);
  //#endregion

  //#region Variables
  const sortOptions = [
    { label: "Show All", value: "-" },
    { label: "Active", value: "active" },
    { label: "In Active", value: "in active" },
    { label: "Garage", value: "garage" },
  ];
  const Options_expiringfilter = [
    { label: "Show All", value: "-" },
    { label: "Insurance", value: "insurance" },
    { label: "Branding", value: "branding" },
    { label: "Registration", value: "registration" },
    { label: "Service Contract", value: "servicecontract" },
    { label: "Parking", value: "parking" },
  ];
  let Options_lastdaysfilter = [
    { label: "Show All", value: "-" },
    { label: "last 7 days", value: "7" },
    { label: "last 30 days", value: "30" },
    { label: "last Month", value: "lastmonth" },
    { label: "This Month", value: "thismonth" },
    { label: "Custom", value: "custom" },
  ];

  //#endregion

  //#region React Hooks
  useEffect(() => {
    getData();
    // setSalesdata(salesinfo);
  }, [location.key]);

  useEffect(() => {
    ServiceAppointmentFormToggle &&
      navigate(
        `/manage/appointments/item/basic?mode=new&vid=${AppointmentData.id}&type=Service&parent=workspace`
      );
    RepairAppointmentFormToggle &&
      navigate(
        `/manage/appointments/item/basic?mode=new&vid=${AppointmentData.id}&type=Repair/Replacement&parent=workspace`
      );
    InternalAppointmentFormToggle &&
      navigate(
        `/manage/appointments/item/basic?mode=new&vid=${AppointmentData.id}&type=Branding Expense&parent=workspace`
      );
    InsuranceFormToggle &&
      navigate(
        `/manage/documents/item/basic?mode=new&id=${AppointmentData.id}&exp=Insurance`
      );
    MulkiaFormToggle &&
      navigate(
        `/manage/documents/item/basic?mode=new&id=${AppointmentData.id}&exp=Registration`
      );
    ServiceAppointmentFormEditToggle &&
      navigate(
        `/manage/appointments/item/basic?mode=close&vid=${
          AppointmentData.id
        }&type=Service&id=${
          JSON.parse(AppointmentData.events).Service.id
        }&parent=workspace`
      );
    RepairAppointmentFormEditToggle &&
      navigate(
        `/manage/appointments/item/basic?mode=close&vid=${
          AppointmentData.id
        }&type=Repair/Replacement&id=${
          JSON.parse(AppointmentData.events).Repair.id
        }&parent=workspace`
      );
    InternalAppointmentFormEditToggle &&
      navigate(
        `/manage/appointments/item/basic?mode=close&vid=${
          AppointmentData.id
        }&type=Branding Expense&id=${
          JSON.parse(AppointmentData.events).InternalInspection.id
        }&parent=workspace`
      );
  }, [
    ServiceAppointmentFormEditToggle,
    RepairAppointmentFormEditToggle,
    InternalAppointmentFormEditToggle,
    ServiceAppointmentFormToggle,
    RepairAppointmentFormToggle,
    InternalAppointmentFormToggle,
    InsuranceFormToggle,
    MulkiaFormToggle,
  ]);

  //#endregion

  //#region Render Templates

  const actionVehicleIdTemplate = (rowData: any) => {
    let params = `?id=${AppointmentData.id}`;
    return (
      <>
        <Button
          style={{ minWidth: "80px" }}
          onContextMenu={(e) => {
            handleClick("vehicleid", e, menuname);
            CreateAppointment(rowData);
            setselectedrow(rowData);
            VechileidAllocation.current.toggle(e);
            // menu.current.toggle(e);
          }}
          //  onClick={handleClick} onContextMenu={handleClick}
          label={`${rowData.id}`}
          // className={`customer-badge status-${rowData.VehicleStatus}`}
          className="workspace vehicleid workspacehover p-button-info"
          onClick={(e) => {
            handleClick("vehicleid", e, rowData);
            setselectedrow(rowData);
          }}
          // onClick={(event) => menu.current.toggle(event)}
        />
        <Menu
          ref={VechileidAllocation}
          popup
          id="VehicleAllocation"
          model={[
            {
              label: "Edit Vehicle",
              icon: "pi pi-user-edit",
              command(e) {
                setDeAllocareDriverToggle(true);
                navigate(
                  `/manage/vehicles/item/basic?mode=edit&id=${selectedrow.vehicle.id}`
                );
                setDisplayModal(true);
              },
            },
            {
              label: "View Documents",
              icon: "pi pi-file",
              command(e) {
                navigate(
                  `/manage/vehicles/item/documents?mode=view&id=${selectedrow.vehicle.id}`
                );
              },
            },
            {
              label: "View Pictures",
              icon: "pi pi-images",
              command(e) {
                navigate(
                  `/manage/vehicles/item/pictures?mode=view&id=${selectedrow.vehicle.id}`
                );
              },
            },

            {
              label: "View Vehicle",
              icon: "pi pi-book",
              command(e) {
                navigate(
                  `/manage/reports/item/?mode=view&id=${selectedrow.vehicle.id}`
                );
              },
            },
          ]}
        ></Menu>
      </>
    );
  };
  const actionVehicleStatusTemplate = (rowData: any) => {
    const today = moment().startOf("day");
    const events = rowData.events;
    const parsedEvents = events ? JSON.parse(events) : null;
    let hasActiveEvent = false;
    let inActive = false;

    if (parsedEvents) {
      for (const eventKey in parsedEvents) {
        const event = parsedEvents[eventKey];

        if (event.appointmentstatus === "Scheduled") {
          if (rowData.dxDriverVehicleactivityId === null) {
            inActive = true;
          }

          inActive = false;
          hasActiveEvent = true;
          break;
        } else {
          if (rowData.dxDriverVehicleactivityId === null) {
            inActive = true;
          }
        }
      }
    } else if (rowData.dxDriverVehicleactivityId !== null) {
      inActive = false;
    } else {
      inActive = true;
    }
    //  else if (rowData.VehicleStatus == 'active' && parsedEvents == null) {
    //     inActive = false;
    // }

    let VehicleStatusBtn =
      hasActiveEvent === false && inActive === false
        ? "p-button-outlined p-button-success"
        : hasActiveEvent == true && inActive === false
        ? "p-button-danger"
        : "p-button-outlined p-button-warning";
    return (
      <>
        {/* <a href="#" onClick={() => { func1(); func2();}}>Test Link</a> */}
        {rowData.VehicleStatus !== null && (
          <>
            <Button
              style={{ minWidth: "65px" }}
              onContextMenu={(e) => {
                handleClick("displayBasic", e, menuname);
              }}
              //  onClick={handleClick} onContextMenu={handleClick}
              label={
                hasActiveEvent == true && inActive === false
                  ? "Garage"
                  : hasActiveEvent === false && inActive === false
                  ? "Active"
                  : "InActive"
              }
              className={`workspace ${VehicleStatusBtn}`}
              title={
                hasActiveEvent == true && inActive === false
                  ? ""
                  : hasActiveEvent === false && inActive === false
                  ? ""
                  : "Available for allocation"
              }
            />
          </>
        )}
      </>
    );
  };

  const drivernameTemplate = (rowData: any) => {
    let navigateurl: any =
      rowData.driver !== null
        ? `#/manage/drivers/item/basic?mode=view&fleettype=driver&id=${rowData.driver.id}`
        : "";
    let fd = isotolocaledatetime(rowData.fromdate);
    let td = new Date();
    if ((fd != null && fd >= td) || rowData.handoverdate == null) {
      return (
        <a href={navigateurl}>
          <b> {rowData.driver !== null ? rowData.driver.name : ""}</b>
        </a>
      );
    } else if (
      rowData.handoverdate !== null &&
      moment(rowData.fromdate).format("MM-D-YYYY") <=
        moment().format("MM-D-YYYY") &&
      moment(rowData.handoverdate).format("MM-D-YYYY") >=
        moment().format("MM-D-YYYY")
    ) {
      return (
        <>
          {" "}
          <a href={navigateurl}>
            {rowData.driver !== null ? rowData.driver.name : ""}
          </a>
        </>
      );
    } else {
      return (
        <>
          {" "}
          <a href={navigateurl}>
            {rowData.driver !== null ? rowData.driver.name : ""}
          </a>
        </>
      );
    }
  };
  const handoverdrivernameTemplate = (rowData: any) => {
    let navigateurl: any =
      rowData.driver !== null
        ? `#/manage/drivers/item/basic?mode=view&fleettype=driver&id=${rowData.driver.id}`
        : "";
    let fd = isotolocaledatetime(rowData.fromdate);
    let td = new Date();
    if ((fd != null && fd >= td) || rowData.handoverdate == null) {
      return (
        <a href={navigateurl}>
          <b>
            {rowData.handoverdrivername !== null
              ? rowData.handoverdrivername
              : "-"}
          </b>
        </a>
      );
    } else if (
      rowData.handoverdate !== null &&
      moment(rowData.fromdate).format("MM-D-YYYY") <=
        moment().format("MM-D-YYYY") &&
      moment(rowData.handoverdate).format("MM-D-YYYY") >=
        moment().format("MM-D-YYYY")
    ) {
      return (
        <>
          <a href={navigateurl}>
            {rowData.handoverdrivername !== null
              ? rowData.handoverdrivername
              : "-"}
          </a>
        </>
      );
    } else {
      return (
        <>
          {" "}
          <a href={navigateurl}>
            {" "}
            {rowData.handoverdrivername !== null
              ? rowData.handoverdrivername
              : "-"}
          </a>
        </>
      );
    }
  };
  const dateTemplate = (data: any, col: string) => {
    let fd = isotolocaledatetime(data.fromdate);
    let td = new Date();
    if ((fd != null && fd >= td) || data.handoverdate == null) {
      return (
        <a
          href={`#/manage/appointments/item/basic?id=${data.id}&mode=view&vid=${data.vehicleid}&type=`}
        >
          <b>{data[col] !== null ? convertdate(data[col]) : "-"}</b>
        </a>
      );
    } else {
      return <>{data[col] !== null ? convertdate(data[col]) : "-"}</>;
    }
  };
  const amountTemplate = (data: any, col: any, tofixneeded: Boolean) => {
    if (tofixneeded) {
      return <>{data[col] !== null ? parseInt(data[col]).toFixed(2) : "-"}</>;
    } else {
      return <>{data[col] !== null ? data[col] : "-"}</>;
    }
  };
  const actionDriverTemplate = (rowData: any, index: any) => {
    let next30DaysDate = moment().add(30, "days").toISOString();
    var todaydate = moment().toISOString();
    let todayallocation = getcurrentallocationfromvehicleactivity(
      rowData.allocations
    );

    // if (rowData.allocations.items) {
    //     var currentdate = new Date();
    //     let allocateitems = rowData.allocations.items;
    //     let a = allocateitems.filter((ele: any) => {
    //         if (ele.handoverdat == null)
    //             return new Date(ele.fromdate) <= currentdate
    //         else
    //             return new Date(ele.fromdate) <= currentdate && currentdate <= new Date(ele.handoverdate)
    //     });
    //     todayallocation =a;

    // }

    let btnLabel =
      todayallocation != null && todayallocation.length > 0
        ? rowData?.driver?.name.split(" ")[0]
        : rowData.driver !== null
        ? rowData.driver.name.split(" ")[0]
        : "";
    let btnbadgelabel;
    let btnbadgeclass = "";
    if (rowData.driver !== null) {
      let endDate = new Date(rowData?.driver?.enddate).toISOString();
      const today = new Date();
      const endDates = new Date(rowData?.driver?.enddate);

      const timeDiff = endDates.getTime() - today.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      const value = daysDiff >= 0 ? daysDiff : Math.abs(daysDiff);

      if (endDate > todaydate && endDate <= next30DaysDate) {
        btnbadgelabel = (
          <span
            className="p-badge p-badge-warning"
            title={new Date(rowData?.driver?.enddate).toDateString()}
            style={{
              padding: "5px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "1.5rem",
              minHeight: "1.5rem",
            }}
          >
            {value}
          </span>
        );

        btnbadgeclass = "p-badge-warning";
      } else if (endDate < todaydate) {
        btnbadgelabel = (
          <span
            className="p-badge p-badge-danger"
            title={new Date(rowData?.driver?.enddate).toDateString()}
            style={{
              padding: "5px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "1.5rem",
              minHeight: "1.5rem",
            }}
          >
            {value}
          </span>
        );

        btnbadgeclass = "p-badge-danger";
      } else {
      }
    }

    // var allocatedate = rowData.allocationdriver !== null && JSON.parse(rowData.allocationdriver).date;

    // if (allocatedate != undefined) {
    //     boxshadow = moment(allocatedate).format('MMM Do YY') == todaydate ? 'rgb(25 255 0 / 58%) 0px 0px 0px 3px' : '';
    // }

    // var boxshadow=allocatedate==todaydate?'rgb(25 255 0 / 58%) 0px 0px 0px 3px':''

    return (
      <div
        className="stylename"
        //  style={{width: '97px',display: 'inline-block'}}
      >
        <Menu
          ref={DriverAllocation}
          popup
          id="DriverAllocation"
          model={[
            {
              label: "Allocate Driver",
              icon: "pi pi-fw pi-plus",
              command(e) {
                setAllocareDriverToggle(true);
                navigate(
                  `/workspace/allocatedriver?type=allocate&id=${selectedrow.id}`
                );
                setDisplayModal(true);
                // setdropDownOption(e.item.label);
                // OnMenuChange(e,rowData,index,'open')
                //  menuname=e.item.label
              },
            },
            {
              label: "Deallocate Driver",
              icon: "pi pi-fw pi-pencil",
              command(e) {
                // setdropDownOption(e.item.label);
                // menuname = e.item.label
                // OnMenuChange(e, rowData, index, 'open')
                //setAllocareDriverToggle(false);
                setDeAllocareDriverToggle(true);
                navigate(
                  `/workspace/deallocatedriver?type=deallocate&id=${selectedrow.id}`
                );
                setDisplayModal(true);
              },
            },
            {
              label: "Edit Driver",
              icon: "pi pi-user-edit",
              disabled: selectedrow && selectedrow.driver ? false : true,
              command(e) {
                // setdropDownOption(e.item.label);
                // menuname = e.item.label
                // OnMenuChange(e, rowData, index, 'open')
                //setAllocareDriverToggle(false);
                setDeAllocareDriverToggle(true);
                navigate(
                  `/manage/drivers/item/basic?mode=edit&id=${selectedrow.driver.id}`
                );
                setDisplayModal(true);
              },
            },

            {
              label: "Log Mileage",
              icon: "pi pi-fw pi-plus",
              command(e) {
                // setdropDownOption(e.item.label);
                // menuname = e.item.label
                // OnMenuChange(e, rowData, index, 'open')
                //setAllocareDriverToggle(false);
                // setDeAllocareDriverToggle(true);
                //localhost:3000/#/manage/mileage/item/basic?mode=new
                navigate(
                  `/manage/mileage/item/basic?mode=new&id=${selectedrow.id}&driver=${selectedrow.driver.id}`
                );
                //navigate(`/workspace/Mileagelog?&id=${selectedrow.id}&fleettype=vehicle#activity`);
                // setDisplayModal(true);
              },
            },
            {
              label: "Renew License",
              icon: "pi pi-fw pi-refresh",
              disabled:
                selectedrow &&
                selectedrow.driver &&
                selectedrow.driver.enddate < next30DaysDate
                  ? false
                  : true,
              command(e) {
                // setdropDownOption(e.item.label);
                // menuname = e.item.label
                // OnMenuChange(e, rowData, index, 'open')
                //setAllocareDriverToggle(false);
                // setDeAllocareDriverToggle(true);
                //localhost:3000/#/manage/mileage/item/basic?mode=new

                navigate(
                  `/manage/drivers/item/basic?mode=edit&id=${selectedrow.driver.id}&parent=workspace`
                );
                //navigate(`/workspace/Mileagelog?&id=${selectedrow.id}&fleettype=vehicle#activity`);
                // setDisplayModal(true);
              },
            },
            {
              label: "View Documents",
              icon: "pi pi-book",
              disabled: selectedrow && selectedrow.driver ? false : true,

              command(e) {
                // navigate(`/manage/logs/item/basic?mode=view&vid=${selectedrow.id}&driver=${selectedrow.driver.name}`);
                navigate(
                  `/manage/drivers/item/pictures?mode=view&id=${selectedrow.driver.id}`
                );
              },
            },
            {
              label: "View Driver",
              icon: "pi pi-user",
              disabled: selectedrow && selectedrow.driver ? false : true,
              command(e) {
                setDeAllocareDriverToggle(true);
                navigate(`/dashboard/driver?id=${selectedrow.driver.id}`);
                setDisplayModal(true);
              },
            },
            {
              label: "View Logs",
              icon: "pi pi-list",
              disabled: selectedrow && selectedrow.driver ? false : true,

              command(e) {
                // navigate(`/manage/logs/item/basic?mode=view&vid=${selectedrow.id}&driver=${selectedrow.driver.name}`);
                navigate(
                  `/analysereports/consumptions?driver=${selectedrow.driver.name}&vid=${selectedrow.id}`
                );
              },
            },
          ]}
        ></Menu>
        <Button
          // label={btnLabel}
          // {`${rowData.allocationdriver}`}
          title={`${btnLabel}`}
          onContextMenu={(e) => {
            setselectedrow(rowData);
            handleClick("displayBasic", e, menuname);
            DriverAllocation.current.toggle(e);
            OndriverChange(rowData, index);
          }}
          className={`workspace p-button-info workspacehover ${btnbadgeclass}`}
          onClick={(e) => {
            setselectedrow(rowData);
            handleClick("Driver", e, rowData);
            toggleopdatatable(e);
          }}
          style={{
            flex: 1,
            minWidth: "105px",
            width: "-webkit-fill-available",
            height: "36px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            justifyContent: "space-between",

            // boxShadow: boxshadow
          }}
        >
          {rowData.driverimage && rowData.driver ? (
            <img
              src={rowData.driverimage}
              className="mr-1"
              alt={rowData.driver.name}
              style={{
                height: "20px",
                width: "20px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : !rowData.driverimage && !rowData.driver ? (
            <></>
          ) : (
            <div className="pi pi-user"></div>
          )}
          {btnLabel}
          {btnbadgelabel}
        </Button>

        <OverlayPanel
          ref={opdatatable}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div>
              <h5>Recent Allocations</h5>
            </div>
            <div>
              <a
                href={"#/manage/Allocations/all?vid=" + rowData.vehicle.id}
                style={{ color: "#1884b3", borderBottom: "1px solid #1884b3" }}
              >
                View all
              </a>
            </div>
          </div>

          <DataTable value={pastvehicledrivers} rows={5}>
            <Column
              field="drivername"
              header="From Driver"
              body={drivernameTemplate}
            ></Column>
            <Column
              field="handoverdrivername"
              header="Handover Driver"
              body={handoverdrivernameTemplate}
            ></Column>
            <Column
              field="date"
              header="Allocated Date"
              body={(rowData: any) => {
                //return dateTemplate(rowData, 'fromdate');
                let dt = isotolocaledatetime(rowData.fromdate);
                return dt ? (
                  <a
                    href={
                      "#/manage/Allocations/items?type=view&vid=" +
                      rowData.vehicleid +
                      "&id=" +
                      rowData.id
                    }
                  >
                    {convertdate(dt)}{" "}
                  </a>
                ) : (
                  "-"
                );
              }}
            ></Column>
            <Column
              field="enddate"
              header="End Date"
              body={(rowData: any) => {
                //return dateTemplate(rowData, 'handoverdate');
                let dt = isotolocaledatetime(rowData.handoverdate);
                return dt ? convertdate(dt) : "-";
              }}
            ></Column>
          </DataTable>
        </OverlayPanel>
        {/* <Dropdown value={rowData.value} options={cities} optionLabel="label" optionValue="value"
            onChange={(e) => rowData.editorCallback(e.value)}
            className='editabledropdown'
         panelStyle={{width:"150px"}}
            // placeholder="Select a Status"
            // itemTemplate={(option) => {


            //     return <span className={`product-badge status-${option.value.toLowerCase()}`}>{option.value}</span>
            // }}
        /> */}

        {/* <Dropdown value={dropDown[index.rowIndex].Driver}   
  onContextMenu={(e)=>{handleClick('Expiring',e);menu.current.toggle(e)}} 
   options={cities} onChange={(e)=>onCityChange(e,rowData,index)}
    optionLabel="name" 
    optionValue="name"
    /> */}
        {/* <Dropdown value={rowData.Driver} options={cities} optionLabel="label" optionValue="value"
                    // onChange={(e) => rowData.editorCallback(e.value)} 
                    // placeholder="Select a Status"
                    itemTemplate={(option) => {


                        return <span className={`product-badge status-${option.value.toLowerCase()}`}>{option.value}</span>
                    }}
                /> */}
      </div>
    );
  };

  const toggleopdatatable = (event: any) => {
    opdatatable.current.toggle(event);
  };
  const serviceRefToggle = (event: any) => {
    serviceRef.current.toggle(event);
  };

  const actionExpiringsoonTemplate = (rowData: any) => {
    var ExpiringSoon: any = [];

    var label: any = "";
    var insurencedatetabel: any = "";
    var registrationtabel: any = "";
    var Warrentytabel: any = "";
    // let insurencedate = rowData.Expiringsoon
    let insurance =
      rowData.expiring !== null &&
      JSON.parse(rowData.expiring).Insurance !== undefined &&
      JSON.parse(rowData.expiring).Insurance;
    let insurencedate = insurance && insurance.expiryDate;
    let insurancestatus = insurance && insurance.status;
    //registration
    let _registration =
      rowData.expiring !== null &&
      JSON.parse(rowData.expiring).Registration !== undefined &&
      JSON.parse(rowData.expiring).Registration;
    let registration = _registration && _registration.expiryDate;
    let registrationstatus = _registration ? _registration.status : "";
    //waranty
    let waranty =
      rowData.expiring !== null &&
      JSON.parse(rowData.expiring).Warranty !== undefined &&
      JSON.parse(rowData.expiring).Warranty;

    let Warrenty = waranty && waranty.expiryDate;
    let warrentystatus = waranty && waranty.status;
    //service contract
    let servicecontract =
      rowData.expiring !== null &&
      JSON.parse(rowData.expiring).ServiceContract !== undefined &&
      JSON.parse(rowData.expiring).ServiceContract;
    let ServiceContract = servicecontract && servicecontract.expiryDate;
    let servicecontractstatus = servicecontract && servicecontract.status;
    //branding
    let _branding =
      rowData.expiring !== null &&
      JSON.parse(rowData.expiring).Branding !== undefined &&
      JSON.parse(rowData.expiring).Branding;
    let Branding = _branding && _branding.expiryDate;
    let brandingstatus = _branding && _branding.status;

    let _parking =
      rowData.expiring !== null &&
      JSON.parse(rowData.expiring).Parking !== undefined &&
      JSON.parse(rowData.expiring).Parking;
    let Parking = _parking && _parking.expiryDate;
    let parkingstatus = _parking && _parking.status;

    var now = new Date();
    var nextWeek = new Date(now);
    var next15days = new Date(now);
    nextWeek.setDate(nextWeek.getDate() + 7);
    next15days.setDate(next15days.getDate() + 14);

    var insurencedateFormat = insurencedate
      ? moment(insurencedate).format()
      : "";
    let formatdate = nextWeek ? moment(nextWeek).format() : "";

    if (
      (moment(insurencedate).format() >= moment().format() &&
        moment(insurencedate).format() <= moment(next15days).format()) ||
      insurancestatus != "Completed" ||
      (moment(registration).format() >= moment().format() &&
        moment(registration).format() <= moment(next15days).format()) ||
      registrationstatus != "Completed" ||
      (moment(Warrenty).format() >= moment().format() &&
        moment(Warrenty).format() <= moment(nextWeek).format()) ||
      warrentystatus != "Completed"
    ) {
      insurencedatetabel = insurencedate;
      registrationtabel = registration;
      Warrentytabel = Warrenty;
    }

    var insuranceDate =
      insurencedate !== undefined &&
      insurencedate !== false &&
      ((moment(insurencedate).format() >= moment().format() &&
        moment(insurencedate).format() <= moment(next15days).format()) ||
        insurancestatus != "Completed") ? (
        <>
          <>
            <div className="insuranceExpiring avatar-badge">
              <span
                title="Insurance"
                className="p-overlay-badge mr-2 HoverInfo"
                style={{
                  color: "black",
                  border: "1px solid black",
                  background: "none",
                  padding: "5px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              >
                I
                {insurancestatus === "In Progress" ? (
                  <div>
                    <Tooltip target=".custom-target-icon" />
                    <span
                      className="custom-target-icon  p-text-secondary p-overlay-badge"
                      data-pr-tooltip={`${
                        JSON.parse(rowData.expiring).Insurance !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Insurance.expiryDate
                        ).format("DD MMMM YYYY")
                      }`}
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{ fontSize: "2rem", cursor: "pointer" }}
                    >
                      {/* <Tooltip target=".custom-target-icon" /> */}
                      <Badge
                        // data-tooltip="text"
                        // tooltip={`${JSON.parse(rowData.expiring).Registration !== undefined && moment(JSON.parse(rowData.expiring).Registration.expiryDate).format('DD MMMM')}`}
                        // tooltip="date"
                        value="S"
                        severity="success"
                      ></Badge>
                    </span>
                  </div>
                ) : (
                  <>
                    <Tooltip target=".custom-target-icon" />
                    <span
                      className="custom-target-icon  p-text-secondary p-overlay-badge"
                      data-pr-tooltip={`${
                        JSON.parse(rowData.expiring).Insurance !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Insurance.expiryDate
                        ).format("DD MMMM YYYY")
                      }`}
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{ fontSize: "2rem", cursor: "pointer" }}
                    >
                      <Badge
                        severity="warning"
                        value={
                          JSON.parse(rowData.expiring).Insurance !==
                            undefined &&
                          moment(
                            JSON.parse(rowData.expiring).Insurance.expiryDate
                          ).diff(moment().startOf("day"), "days") >= 0 &&
                          `${moment(
                            JSON.parse(rowData.expiring).Insurance.expiryDate
                          ).diff(moment().startOf("day"), "days")}`
                        }
                      ></Badge>
                    </span>
                  </>
                  // <Badge
                  //     title={`${JSON.parse(rowData.expiring).Insurance !== undefined && moment(JSON.parse(rowData.expiring).Insurance.expiryDate).format('DD MMMM')}`}
                  //     value={JSON.parse(rowData.expiring).Insurance !== undefined && moment(JSON.parse(rowData.expiring).Insurance.expiryDate).format('DD')}
                  //     severity="warning"
                  // ></Badge>
                )}
              </span>
            </div>
          </>
        </>
      ) : insurencedate !== undefined &&
        insurencedate !== false &&
        moment(insurencedate).format() < moment().format() ? (
        <>
          <>
            <div className="insuranceExpiring avatar-badge">
              <span
                title="Insurance"
                className="p-overlay-badge mr-2 HoverInfo"
                style={{
                  color: "black",
                  border: "1px solid black",
                  background: "none",
                  padding: "5px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              >
                I
                {insurancestatus === "In Progress" ? (
                  <>
                    <Tooltip target=".custom-target-icon" />
                    <span
                      className="custom-target-icon  p-text-secondary p-overlay-badge"
                      data-pr-tooltip={`${
                        JSON.parse(rowData.expiring).Insurance !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Insurance.expiryDate
                        ).format("DD MMMM YYYY")
                      }`}
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{ fontSize: "2rem", cursor: "pointer" }}
                    >
                      <Badge
                        //  title={`${JSON.parse(rowData.expiring).Insurance !== undefined && moment(JSON.parse(rowData.expiring).Insurance.expiryDate).format('DD MMMM')}`}
                        value="S"
                        severity="success"
                      ></Badge>
                    </span>
                  </>
                ) : (
                  <>
                    <Tooltip target=".custom-target-icon" />
                    <span
                      className="custom-target-icon  p-text-secondary p-overlay-badge"
                      data-pr-tooltip={`${
                        JSON.parse(rowData.expiring).Insurance !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Insurance.expiryDate
                        ).format("DD MMMM YYYY")
                      }`}
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{ fontSize: "2rem", cursor: "pointer" }}
                    >
                      <Badge
                        severity="danger"
                        value={
                          JSON.parse(rowData.expiring).Insurance !==
                            undefined &&
                          moment(
                            JSON.parse(rowData.expiring).Insurance.expiryDate
                          ).diff(moment().startOf("day"), "days") <= 0
                            ? moment(moment().startOf("day")).diff(
                                moment(
                                  JSON.parse(rowData.expiring).Insurance
                                    .expiryDate
                                ),
                                "days"
                              ) === 0
                              ? "0"
                              : `${Math.abs(
                                  moment(moment().startOf("day")).diff(
                                    moment(
                                      JSON.parse(rowData.expiring).Insurance
                                        .expiryDate
                                    ),
                                    "days"
                                  )
                                )}`
                            : `${moment(
                                JSON.parse(rowData.expiring).Insurance
                                  .expiryDate
                              ).diff(moment().startOf("day"), "days")}`
                        }
                      ></Badge>
                    </span>
                  </>
                  // <Badge
                  //     title={`${JSON.parse(rowData.expiring).Insurance !== undefined && moment(JSON.parse(rowData.expiring).Insurance.expiryDate).format('DD MMMM')}`}
                  //     value={JSON.parse(rowData.expiring).Insurance !== undefined && moment(JSON.parse(rowData.expiring).Insurance.expiryDate).format('DD')}
                  //     severity="danger"
                  // ></Badge>
                )}
              </span>
            </div>
          </>
        </>
      ) : (
        ""
      );
    var RegistrationDate =
      registration !== undefined &&
      registration !== false &&
      moment(registration).format() >= moment().format() &&
      moment(registration).format() <= moment(next15days).format() ? (
        <>
          <div className="registrionExpiring avatar-badge">
            <span
              title="Registration"
              className="p-overlay-badge mr-2"
              style={{
                color: "black",
                border: "1px solid black",
                background: "none",
                padding: "5px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              R
              {registrationstatus === "In Progress" ? (
                <div>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Registration !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Registration.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    {/* <Tooltip target=".custom-target-icon" /> */}
                    <Badge
                      // data-tooltip="text"
                      // tooltip={`${JSON.parse(rowData.expiring).Registration !== undefined && moment(JSON.parse(rowData.expiring).Registration.expiryDate).format('DD MMMM')}`}
                      // tooltip="date"
                      value="S"
                      severity="success"
                    ></Badge>
                  </span>
                </div>
              ) : (
                <>
                  {/* <div data-tooltip="text isefwgfewge"> vegeggege </div> */}
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Registration !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Registration.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      severity="warning"
                      value={
                        JSON.parse(rowData.expiring).Registration !==
                          undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Registration.expiryDate
                        ).diff(moment().startOf("day"), "days") >= 0 &&
                        `${moment(
                          JSON.parse(rowData.expiring).Registration.expiryDate
                        ).diff(moment().startOf("day"), "days")}`
                      }
                    ></Badge>
                  </span>
                </>
              )}
            </span>
          </div>
        </>
      ) : registration !== undefined &&
        moment(registration).format() < moment().format() ? (
        <>
          <div className="registrionExpiring avatar-badge">
            <span
              title="Registration"
              className="p-overlay-badge mr-2"
              style={{
                color: "black",
                border: "1px solid black",
                background: "none",
                padding: "5px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              R
              {registrationstatus === "In Progress" ? (
                <div data-tooltip="textergerhgergerhe">
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Registration !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Registration.expiryDate
                      ).format("DD MMMM YYYY")
                      //  moment(JSON.parse(rowData.expiring).Registration.expiryDate).format('DD MMMM YYYY')
                    }
                                             `}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    {/* <Tooltip target=".custom-target-icon" /> */}
                    <Badge
                      // data-tooltip="text"
                      // tooltip={`${JSON.parse(rowData.expiring).Registration !== undefined && moment(JSON.parse(rowData.expiring).Registration.expiryDate).format('DD MMMM')}`}
                      // tooltip="date"
                      value="S"
                      severity="success"
                    ></Badge>
                  </span>
                </div>
              ) : (
                <>
                  {/* <div data-tooltip="text isefwgfewge"> vegeggege </div> */}
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Registration !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Registration.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      severity="danger"
                      value={
                        JSON.parse(rowData.expiring).Registration !==
                          undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Registration.expiryDate
                        ).diff(moment().startOf("day"), "days") <= 0
                          ? moment(moment().startOf("day")).diff(
                              moment(
                                JSON.parse(rowData.expiring).Registration
                                  .expiryDate
                              ),
                              "days"
                            ) === 0
                            ? "0"
                            : `${Math.abs(
                                moment(moment().startOf("day")).diff(
                                  moment(
                                    JSON.parse(rowData.expiring).Registration
                                      .expiryDate
                                  ),
                                  "days"
                                )
                              )}`
                          : `${moment(
                              JSON.parse(rowData.expiring).Registration
                                .expiryDate
                            ).diff(moment().startOf("day"), "days")}`
                      }
                    ></Badge>
                  </span>
                </>
              )}
            </span>
          </div>
        </>
      ) : (
        ""
      );

    var branding =
      Branding !== undefined &&
      Branding !== false &&
      moment(Branding).format() >= moment().format() &&
      moment(Branding).format() <= moment(nextWeek).format() ? (
        <>
          <div className="expiringTRA avatar-badge">
            <span
              title="Branding"
              className="p-overlay-badge mr-2"
              style={{
                color: "black",
                border: "1px solid black",
                background: "none",
                padding: "5px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              B
              {brandingstatus === "In Progress" ? (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Branding !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Branding.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      // title={`${JSON.parse(rowData.expiring).Branding !== undefined && moment(JSON.parse(rowData.expiring).Branding.expiryDate).format('DD MMMM')}`}
                      value="S"
                      severity="success"
                    ></Badge>
                  </span>
                </>
              ) : (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Branding !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Branding.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      severity="warning"
                      value={
                        JSON.parse(rowData.expiring).Branding !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Branding.expiryDate
                        ).diff(moment().startOf("day"), "days") >= 0 &&
                        `${moment(
                          JSON.parse(rowData.expiring).Branding.expiryDate
                        ).diff(moment().startOf("day"), "days")}`
                      }
                    ></Badge>
                  </span>
                </>
              )}
            </span>
          </div>
        </>
      ) : Branding !== undefined &&
        Branding !== false &&
        moment(Branding).format() < moment().format() ? (
        <>
          <div className="expiringTRA avatar-badge">
            <span
              title="Branding"
              className="p-overlay-badge mr-2"
              style={{
                color: "black",
                border: "1px solid black",
                background: "none",
                padding: "5px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              B
              {brandingstatus === "In Progress" ? (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Branding !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Branding.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      // title={`${JSON.parse(rowData.expiring).Branding !== undefined && moment(JSON.parse(rowData.expiring).Branding.expiryDate).format('DD MMMM')}`}
                      value="S"
                      severity="success"
                    ></Badge>
                  </span>
                </>
              ) : (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Branding !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Branding.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      severity="danger"
                      value={
                        JSON.parse(rowData.expiring).Branding !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Branding.expiryDate
                        ).diff(moment().startOf("day"), "days") <= 0
                          ? moment(moment().startOf("day")).diff(
                              moment(
                                JSON.parse(rowData.expiring).Branding.expiryDate
                              ),
                              "days"
                            ) === 0
                            ? "0"
                            : `${Math.abs(
                                moment(moment().startOf("day")).diff(
                                  moment(
                                    JSON.parse(rowData.expiring).Branding
                                      .expiryDate
                                  ),
                                  "days"
                                )
                              )}`
                          : `${moment(
                              JSON.parse(rowData.expiring).Branding.expiryDate
                            ).diff(moment().startOf("day"), "days")}`
                      }
                    ></Badge>
                  </span>
                </>
              )}
            </span>
          </div>
        </>
      ) : (
        ""
      );
    var ServiceContractDate =
      ServiceContract !== undefined &&
      ServiceContract !== false &&
      moment(ServiceContract).format() >= moment().format() &&
      moment(ServiceContract).format() <= moment(next15days).format() ? (
        <>
          <div className="expiringServiceContract avatar-badge">
            <span
              title="ServiceContract"
              aria-label="S"
              className="p-overlay-badge mr-2"
              style={{
                color: "black",
                border: "1px solid black",
                background: "none",
                padding: "5px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              S
              {servicecontractstatus === "In Progress" ? (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).ServiceContract !==
                        undefined &&
                      moment(
                        JSON.parse(rowData.expiring).ServiceContract.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      title={`${
                        JSON.parse(rowData.expiring).ServiceContract !==
                          undefined &&
                        moment(
                          JSON.parse(rowData.expiring).ServiceContract
                            .expiryDate
                        ).format("DD MMMM")
                      }`}
                      value="S"
                      severity="success"
                    ></Badge>
                  </span>
                </>
              ) : (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).ServiceContract !==
                        undefined &&
                      moment(
                        JSON.parse(rowData.expiring).ServiceContract.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      severity="warning"
                      value={
                        JSON.parse(rowData.expiring).ServiceContract !==
                          undefined &&
                        moment(
                          JSON.parse(rowData.expiring).ServiceContract
                            .expiryDate
                        ).diff(moment().startOf("day"), "days") >= 0 &&
                        `${moment(
                          JSON.parse(rowData.expiring).ServiceContract
                            .expiryDate
                        ).diff(moment().startOf("day"), "days")}`
                      }
                    ></Badge>
                  </span>
                </>
              )}
            </span>
          </div>
        </>
      ) : ServiceContract !== undefined &&
        ServiceContract !== false &&
        moment(ServiceContract).format() < moment().format() ? (
        <>
          <div className="expiringServiceContract avatar-badge">
            <span
              title="ServiceContract"
              aria-label="S"
              className="p-overlay-badge mr-2"
              style={{
                color: "black",
                border: "1px solid black",
                background: "none",
                padding: "5px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              S
              {servicecontractstatus === "In Progress" ? (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).ServiceContract !==
                        undefined &&
                      moment(
                        JSON.parse(rowData.expiring).ServiceContract.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      title={`${
                        JSON.parse(rowData.expiring).ServiceContract !==
                          undefined &&
                        moment(
                          JSON.parse(rowData.expiring).ServiceContract
                            .expiryDate
                        ).format("DD MMMM")
                      }`}
                      value="S"
                      severity="success"
                    ></Badge>
                  </span>
                </>
              ) : (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).ServiceContract !==
                        undefined &&
                      moment(
                        JSON.parse(rowData.expiring).ServiceContract.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      severity="danger"
                      value={
                        JSON.parse(rowData.expiring).ServiceContract !==
                          undefined &&
                        moment(
                          JSON.parse(rowData.expiring).ServiceContract
                            .expiryDate
                        ).diff(moment().startOf("day"), "days") <= 0
                          ? moment(moment().startOf("day")).diff(
                              moment(
                                JSON.parse(rowData.expiring).ServiceContract
                                  .expiryDate
                              ),
                              "days"
                            ) === 0
                            ? "0"
                            : `${Math.abs(
                                moment(moment().startOf("day")).diff(
                                  moment(
                                    JSON.parse(rowData.expiring).ServiceContract
                                      .expiryDate
                                  ),
                                  "days"
                                )
                              )}`
                          : `${moment(
                              JSON.parse(rowData.expiring).ServiceContract
                                .expiryDate
                            ).diff(moment().startOf("day"), "days")}`
                      }
                    ></Badge>
                  </span>
                </>
              )}
            </span>
          </div>
        </>
      ) : (
        ""
      );
    var SeasonalParking =
      Parking !== undefined &&
      Parking !== false &&
      moment(Parking).format() >= moment().format() &&
      moment(Parking).format() <= moment(nextWeek).format() ? (
        <>
          <div className="expiringServiceContract avatar-badge">
            <span
              title="Parking"
              aria-label="S"
              className="p-overlay-badge mr-2"
              style={{
                color: "black",
                border: "1px solid black",
                background: "none",
                padding: "5px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              P
              {parkingstatus === "In Progress" ? (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Parking !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Parking.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      title={`${
                        JSON.parse(rowData.expiring).Parking !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Parking.expiryDate
                        ).format("DD MMMM")
                      }`}
                      value="S"
                      severity="success"
                    ></Badge>
                  </span>
                </>
              ) : (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Parking !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Parking.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      severity="warning"
                      value={
                        JSON.parse(rowData.expiring).Parking !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Parking.expiryDate
                        ).diff(moment().startOf("day"), "days") >= 0 &&
                        `${moment(
                          JSON.parse(rowData.expiring).Parking.expiryDate
                        ).diff(moment().startOf("day"), "days")}`
                      }
                    ></Badge>
                  </span>
                </>
              )}
            </span>
          </div>
        </>
      ) : Parking !== undefined &&
        Parking !== false &&
        moment(Parking).format() < moment().format() ? (
        <>
          <div className="expiringServiceContract avatar-badge">
            <span
              title="Parking"
              aria-label="S"
              className="p-overlay-badge mr-2"
              style={{
                color: "black",
                border: "1px solid black",
                background: "none",
                padding: "5px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              P
              {parkingstatus === "In Progress" ? (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Parking !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Parking.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      title={`${
                        JSON.parse(rowData.expiring).Parking !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Parking.expiryDate
                        ).format("DD MMMM")
                      }`}
                      value="S"
                      severity="success"
                    ></Badge>
                  </span>
                </>
              ) : (
                <>
                  <Tooltip target=".custom-target-icon" />
                  <span
                    className="custom-target-icon  p-text-secondary p-overlay-badge"
                    data-pr-tooltip={`${
                      JSON.parse(rowData.expiring).Parking !== undefined &&
                      moment(
                        JSON.parse(rowData.expiring).Parking.expiryDate
                      ).format("DD MMMM YYYY")
                    }`}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  >
                    <Badge
                      severity="danger"
                      value={
                        JSON.parse(rowData.expiring).Parking !== undefined &&
                        moment(
                          JSON.parse(rowData.expiring).Parking.expiryDate
                        ).diff(moment().startOf("day"), "days") <= 0
                          ? moment(moment().startOf("day")).diff(
                              moment(
                                JSON.parse(rowData.expiring).Parking.expiryDate
                              ),
                              "days"
                            ) === 0
                            ? "0"
                            : `${Math.abs(
                                moment(moment().startOf("day")).diff(
                                  moment(
                                    JSON.parse(rowData.expiring).Parking
                                      .expiryDate
                                  ),
                                  "days"
                                )
                              )}`
                          : `${moment(
                              JSON.parse(rowData.expiring).Parking.expiryDate
                            ).diff(moment().startOf("day"), "days")}`
                      }
                    ></Badge>
                  </span>
                </>
              )}
            </span>
          </div>
        </>
      ) : (
        ""
      );
    let sparams = "";
    if (ServiceContract != undefined) {
      moment(ServiceContract).format() >= moment().format() &&
        moment(ServiceContract).format() <= moment(next15days).format() &&
        ExpiringSoon.push(ServiceContract);
      sparams =
        servicecontract.id && `?id=${servicecontract.id}&exp=${expiringName}`;
    }
    if (insurencedate != undefined) {
      ((moment(insurencedate).format() >= moment().format() &&
        moment(insurencedate).format() <= moment(next15days).format()) ||
        insurancestatus != "Completed") &&
        ExpiringSoon.push(insurencedate);
      sparams = insurance.id && `?id=${insurance.id}&exp=${expiringName}`;
    }
    if (registration != undefined) {
      moment(registration).format() >= moment().format() &&
        moment(registration).format() <= moment(next15days).format() &&
        ExpiringSoon.push(registration);
      sparams =
        _registration.id && `?id=${_registration.id}&exp=${expiringName}`;
    }
    if (Warrenty != undefined) {
      moment(Warrenty).format() >= moment().format() &&
        moment(Warrenty).format() <= moment(next15days).format() &&
        ExpiringSoon.push(Warrenty);
      sparams = waranty.id && `?id=${waranty.id}&exp=${expiringName}`;
    }
    if (Branding != undefined) {
      moment(Branding).format() >= moment().format() &&
        moment(Branding).format() <= moment(nextWeek).format() &&
        ExpiringSoon.push(Branding);
      sparams = _branding.id && `?id=${_branding.id}&exp=${expiringName}`;
    }

    let params = `?id=${AppointmentData.id}&exp=${expiringName}`;
    //     insuranceDate,
    //     RegistrationDate,

    //     branding,
    //     ServiceContractDate,
    //     SeasonalParking
    // );
    return (
      <>
        <>
          <Button
            onContextMenu={(e: any) => {
              setExpiringName(e.target.title);
              handleClick("Expiring", e, menuname);
              if (
                (e.target.title == "Insurance" &&
                  insurancestatus === "In Progress") ||
                (e.target.title == "Registration" &&
                  registrationstatus === "In Progress") ||
                (e.target.title == "ServiceContract" &&
                  servicecontractstatus === "In Progress") ||
                (e.target.title == "Branding" &&
                  brandingstatus === "In Progress") ||
                (e.target.title == "Warranty" &&
                  warrentystatus === "In Progress") ||
                (e.target.title == "Parking" && parkingstatus === "In Progress")
              ) {
                let sparamsvalue = "";
                switch (e.target.title) {
                  case "Insurance":
                    sparamsvalue =
                      insurance.id &&
                      `?mode=Close&id=${insurance.id}&vid=${rowData.id}&type=${e.target.title}`;
                    setsparamsvalue(sparamsvalue);
                    break;
                  case "Registration":
                    sparamsvalue =
                      _registration.id &&
                      `?mode=Close&id=${_registration.id}&vid=${rowData.id}&type=${e.target.title}`;
                    setsparamsvalue(sparamsvalue);
                    break;
                  case "ServiceContract":
                    sparamsvalue =
                      servicecontract.id &&
                      `?mode=Close&id=${servicecontract.id}&vid=${rowData.id}&type=${e.target.title}`;
                    setsparamsvalue(sparamsvalue);
                    break;
                  case "Branding":
                    sparamsvalue =
                      _branding.id &&
                      `?mode=Close&id=${_branding.id}&vid=${rowData.id}&type=${e.target.title}`;
                    setsparamsvalue(sparamsvalue);
                    break;
                  case "Warranty":
                    sparamsvalue =
                      waranty.id &&
                      `?mode=Close&id=${waranty.id}&vid=${rowData.id}&type=${e.target.title}`;
                    setsparamsvalue(sparamsvalue);
                    break;
                  case "Parking":
                    sparamsvalue =
                      _parking.id &&
                      `?mode=Close&id=${_parking.id}&vid=${rowData.id}&type=${e.target.title}`;
                    setsparamsvalue(sparamsvalue);
                    break;
                  //_parking
                }

                expSoonScheduleMenu.current.toggle(e);
              }
              // else if (!(insuranceDate || RegistrationDate || branding || ServiceContractDate || SeasonalParking)) {
              //     newrenewal.current.toggle(e);
              // }
              else if (
                insuranceDate ||
                RegistrationDate ||
                branding ||
                ServiceContractDate ||
                SeasonalParking
              ) {
                expSoonMenu.current.toggle(e);
              }
              CreateAppointment(rowData);
            }}
            style={{
              width: "100%",
              alignItems: "center",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              color: "black",
            }}
            className={`workspace expiringSoon`}
            onClick={(e) => {
              handleClick("Expiring", e, menuname);
            }}
          >
            {insuranceDate}
            {RegistrationDate}
            {/* {WarrentyDate} */}
            {branding}
            {ServiceContractDate}
            {SeasonalParking}
          </Button>
        </>

        <Menu
          ref={expSoonMenu}
          popup
          model={[
            {
              label: "Renew",
              icon: "pi pi-fw pi-pencil",
              command(e) {
                navigate("/general/workspace/renew" + params);
              },
            },
          ]}
        ></Menu>
        <Menu
          ref={newrenewal}
          popup
          model={[
            {
              label: "Renew",
              icon: "pi pi-fw pi-pencil",
              command(e) {
                navigate(
                  `/manage/documents/item/basic?mode=new&vid=${rowData.id}`
                );
                // setAllocareDriverToggle(false);
                // setActionFormToggle(true);
                // setDisplayModal(true);
              },
            },
          ]}
        ></Menu>
        <Menu
          ref={expSoonScheduleMenu}
          popup
          model={[
            {
              label: "Close",
              icon: "pi pi-fw pi-pencil",
              command(e: any) {
                navigate("/manage/renewals/item/basic" + sparamsvalue);
                // setAllocareDriverToggle(false);
                // setActionFormToggle(true);
                // setDisplayModal(true);
              },
            },
          ]}
        ></Menu>

        {/* <Button label={`${rowData.Expiringsoon}`} className="workspacep-button-raised p-button-text p-button-plain" onClick={() => { onOpenVehicleStatus('Expiring') }}  /> */}
      </>
    );
  };

  const actionServicesTemplate = (rowData: any) => {
    var btnLabel = "-";
    var btnbadgelabel = "";
    var btnallocationid = "";
    let btnbadgeclass = "badge-size p-badge-warning";
    let menuvehiclestatus = rowData.VehicleStatus == "Active" ? true : false;
    if (rowData.events != null) {
      let eventsjson = JSON.parse(rowData.events);
      let eo = Object.keys(eventsjson);
      if (eo.indexOf("Service") > -1) {
        let eso = Object.keys(eventsjson["Service"]);
        btnLabel =
          eso.indexOf("date") > -1
            ? moment(eventsjson["Service"]["date"]).format("DD-MM-YY")
            : "-";
        btnallocationid =
          eso.indexOf("id") > -1 ? eventsjson["Service"]["id"] : "";

        if (btnallocationid != "") {
          btnbadgelabel = "S";
          btnbadgeclass = "badge-size p-badge-success";
        } else {
          btnbadgelabel = "N";
        }
      }
    }

    return (
      <>
        <Button
          onContextMenu={(e) => {
            setselectedrow(rowData);

            handleClick("Services", e, menuname);

            SericesAppointment.current.toggle(e);

            CreateAppointment(rowData);
            // setServiceAppointmentFormToggle(true)
          }}
          style={{
            // width: '97px',
            minWidth: "95px",
            width: "-webkit-fill-available",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
          label={`${btnLabel}`}
          className="workspace bgcolors workspacehover p-button-info"
          onClick={(e: any) => {
            handleClick("Service", e, rowData);
            serviceRefToggle(e);
          }}
        >
          {btnbadgelabel != "" ? (
            <Badge className={btnbadgeclass} value={btnbadgelabel}></Badge>
          ) : (
            ""
          )}
          {/* {btnallocationid != "" ? <Badge className='badge-size' value={btnbadgelabel}></Badge> : ''} */}
        </Button>
        <Menu
          ref={SericesAppointment}
          id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Schedule Service",
              icon: "pi pi-fw pi-plus",
              disabled:
                selectedrow == null
                  ? false
                  : selectedrow.events == null
                  ? false
                  : selectedrow.events !== null &&
                    JSON.parse(selectedrow.events).Service !== undefined &&
                    JSON.parse(selectedrow.events).Service.appointmentstatus ==
                      "Scheduled"
                  ? true
                  : false,

              command(e) {
                setServiceAppointmentFormToggle(true);
              },
            },
            {
              label: "Close Service",
              icon: "pi pi-fw pi-pencil",
              // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).Service.appointmentstatus == 'Active' ? true : false,
              disabled:
                selectedrow == null
                  ? false
                  : selectedrow.events == null
                  ? true
                  : selectedrow.events !== null &&
                    JSON.parse(selectedrow.events).Service == undefined
                  ? true
                  : selectedrow.events !== null &&
                    JSON.parse(selectedrow.events).Service !== undefined &&
                    JSON.parse(selectedrow.events).Service.appointmentstatus ==
                      "Active"
                  ? // ? true
                    // : JSON.parse(selectedrow.events).Service.appointmentstatus == 'Active'
                    true
                  : false,
              command(e) {
                setServiceAppointmentFormEditToggle(true);
              },
            },
          ]}
        ></Menu>
        <OverlayPanel
          ref={serviceRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <h5>Recent Services</h5>
          <DataTable value={recentAppointments} rows={5}>
            <Column
              field="Date"
              header="Date"
              body={(rowData: any) => {
                return dateTemplate(rowData, "Date");
              }}
            ></Column>
            <Column field="appointmentstatus" header="Status"></Column>
            <Column field="place" header="Place"></Column>
            <Column
              field="cost"
              header="Amount"
              body={(rowData: any) => {
                return (
                  <span style={{ display: "block", width: "100px" }}>
                    AED {rowData.cost}
                  </span>
                );
              }}
            ></Column>
          </DataTable>
        </OverlayPanel>
        {/* {<RecentActivity ref={clickedEvent} data={recentAppointments} />} */}
      </>
    );
  };
  const actionRepairsReplacementsTemplate = (rowData: any) => {
    // var btnLabel = rowData.expanses !== null ? JSON.parse(rowData.expanses).repair : '-';
    var btnLabel = "-";
    var btnbadgelabel = "";
    var btnallocationid = "";
    let approvalneeded = "";
    let btnbadgeclass = "badge-size p-badge-warning";
    let menuvehiclestatus = rowData.VehicleStatus == "Active" ? true : false;
    approvalneeded =
      rowData["recentevents"] &&
      rowData.eventtype == "Branding Expense" &&
      rowData["recentevents"]["appointmentstatus"]
        ? rowData["recentevents"]["appointmentstatus"]
        : "";
    if (rowData.events != null) {
      let eventsjson = JSON.parse(rowData.events);

      let eo = Object.keys(eventsjson);

      if (eo.indexOf("Repair") > -1) {
        let eso = Object.keys(eventsjson["Repair"]);

        // let repairCost = eventsjson['Repair'] && eventsjson['Repair']['cost'] ? Number(eventsjson['Repair']['cost']).toFixed(2) : '-';
        let repairCost =
          rowData.repaircost && rowData.repaircost !== 0
            ? rowData.repaircost
            : "-";
        // btnLabel = eso.indexOf('date') > -1 ? 'AED' + ' ' + eventsjson['Repair']['cost'] : '-';

        btnLabel =
          eso.indexOf("date") > -1
            ? repairCost !== "-"
              ? parseFloat(repairCost).toFixed(2)
              : "-"
            : "-";

        btnallocationid =
          eso.indexOf("id") > -1 ? eventsjson["Repair"]["id"] : "";
        if (btnallocationid != "") {
          btnbadgelabel = "";
          btnbadgeclass = "badge-size p-badge-success";
        } else {
          btnbadgelabel = "";
        }
      }
    }
    return (
      <>
        <Button
          title={approvalneeded === "Pending" ? "Approval Needed" : ""}
          onContextMenu={(e) => {
            setselectedrow(rowData);

            handleClick("RepairsReplacements", e, menuname);
            RepairAppointment.current.toggle(e);
            CreateAppointment(rowData);
          }}
          style={{
            minWidth: "97px",
            width: "-webkit-fill-available",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
          className={`workspace bgcolors workspacehover p-button-info`}
          onClick={(e) => {
            handleClick("RepairsReplacements", e, rowData);
            repairrreplaceRef.current.toggle(e);
          }}
        >
          {approvalneeded === "Pending" ? (
            <span className="pi pi-check-circle mr-2"></span>
          ) : (
            ""
          )}
          {btnLabel}
          {btnbadgelabel != "" ? (
            <Badge className={btnbadgeclass} value={btnbadgelabel}></Badge>
          ) : (
            ""
          )}
        </Button>
        <Menu
          ref={RepairAppointment}
          id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Add Repair/Replacement",
              icon: "pi pi-fw pi-plus",
              // disabled:
              //     selectedrow == null
              //         ? false
              //         : selectedrow.events == null
              //         ? false
              //         : selectedrow.events !== null && JSON.parse(selectedrow.events).Repair !== undefined && JSON.parse(selectedrow.events).Repair.appointmentstatus == 'Scheduled'
              //         ? true
              //         : false,
              // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).Repair.appointmentstatus == 'Scheduled' ? true : false,
              command(e) {
                setRepairAppointmentFormToggle(true);
                // navigate(`/general/workspace/createAppointmentform?form=add&${rowData.id}&Repair/Replacement&vehicle%23activity`)
              },
            },
          ]}
        ></Menu>
        <OverlayPanel
          ref={repairrreplaceRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div>
              <h5>
                Recent Repair/Replacements :{" "}
                {repair != "" && repair !== null ? repair : ""}
              </h5>
            </div>
            <div>
              <a
                href={`#/general/appointments/all?vid=${repair}&type=Repair/Replacement`}
                style={{ color: "#1884b3", borderBottom: "1px solid #1884b3" }}
              >
                View all
              </a>
            </div>
          </div>

          <DataTable value={recentAppointments} rows={5}>
            <Column
              field="Date"
              header="Date"
              body={(rowData: any) => {
                return dateTemplate(rowData, "Date");
              }}
            ></Column>
            <Column field="appointmentstatus" header="Status"></Column>
            <Column field="place" header="Place"></Column>
            <Column field="cost" header="Amount"></Column>
          </DataTable>
        </OverlayPanel>
      </>
    );
  };
  const actionInsuranceTemplate = (rowData: any) => {
    let inscost =
      rowData.InsuranceCost !== undefined &&
      parseFloat(rowData.InsuranceCost) > 0
        ? parseFloat(rowData.InsuranceCost).toFixed(2)
        : "-";
    return (
      <>
        <Button
          onContextMenu={(e) => {
            setselectedrow(rowData);
            handleClick("InsuranceCost", e, menuname);
            RefInsuranceCost.current.toggle(e);
            CreateAppointment(rowData);
          }}
          style={{
            minWidth: "96px",
            width: "-webkit-fill-available",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
          label={inscost}
          // label={`${rowData.nextdates !== null ? moment(JSON.parse(rowData.nextdates).InsuranceCost).format('MM-DD-YY') : ''}`}
          className="workspace  bgcolors workspacehover p-button-info"
          onClick={(e) => {
            handleClick("InsuranceCost", e, rowData);
            InsuranceRef.current.toggle(e);
          }}
        >
          {/* {btnLabel} */}
          {/* {btnbadgelabel != '' ? <Badge className={btnbadgeclass} value={btnbadgelabel}></Badge> : ''} */}
        </Button>
        <Menu
          ref={RefInsuranceCost}
          id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Add Insurance Expense",
              icon: "pi pi-fw pi-plus",
              command(e) {
                var menuname = e.item.label;
                setInsuranceFormToggle(true);
              },
            },
          ]}
        ></Menu>
        <OverlayPanel
          ref={InsuranceRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <h5>Recent Insurance Expense</h5>
          <DataTable value={recentRenewals} rows={5}>
            <Column field="vehicleid" header="Vehicle ID"></Column>
            <Column
              field="Date"
              header="Renewal No"
              body={(rowData: any) => {
                return amountTemplate(rowData, "renewalno", false);
              }}
            ></Column>

            <Column
              field="totalamount"
              header="Amount"
              body={(rowData: any) => {
                return amountTemplate(rowData, "totalamount", true);
              }}
            ></Column>
            <Column
              field="Date"
              header="Date"
              body={(rowData: any) => {
                return dateTemplate(rowData, "createdAt");
              }}
            ></Column>
          </DataTable>
        </OverlayPanel>
      </>
    );
  };
  const actionRegistrationTemplate = (rowData: any) => {
    let inscost =
      rowData.mulkiacost !== undefined && parseFloat(rowData.mulkiacost) > 0
        ? parseFloat(rowData.mulkiacost).toFixed(2)
        : "-";
    return (
      <>
        <Button
          onContextMenu={(e) => {
            setselectedrow(rowData);
            handleClick("MulkiaCost", e, menuname);
            RefMulkiaCost.current.toggle(e);
            CreateAppointment(rowData);
          }}
          style={{
            minWidth: "96px",
            width: "-webkit-fill-available",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
          label={inscost}
          // label={`${rowData.nextdates !== null ? moment(JSON.parse(rowData.nextdates).InsuranceCost).format('MM-DD-YY') : ''}`}
          className="workspace  bgcolors workspacehover p-button-info"
          onClick={(e) => {
            handleClick("MulkiaCost", e, rowData);
            MulkiaRef.current.toggle(e);
          }}
        >
          {/* {btnLabel} */}
          {/* {btnbadgelabel != '' ? <Badge className={btnbadgeclass} value={btnbadgelabel}></Badge> : ''} */}
        </Button>

        <Menu
          ref={RefMulkiaCost}
          id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Add Registration Expense",
              icon: "pi pi-fw pi-plus",
              command(e) {
                var menuname = e.item.label;
                setMulkiaFormToggle(true);
              },
            },
          ]}
        ></Menu>
        <OverlayPanel
          ref={MulkiaRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <h5>Recent Registration Expense</h5>
          <DataTable value={recentRenewals} rows={5}>
            <Column field="vehicleid" header="Vehicle ID"></Column>
            <Column
              field="Date"
              header="Renewal No"
              body={(rowData: any) => {
                return amountTemplate(rowData, "renewalno", false);
              }}
            ></Column>

            <Column
              field="totalamount"
              header="Amount"
              body={(rowData: any) => {
                return amountTemplate(rowData, "totalamount", true);
              }}
            ></Column>
            <Column
              field="Date"
              header="Date"
              body={(rowData: any) => {
                return dateTemplate(rowData, "createdAt");
              }}
            ></Column>
          </DataTable>
        </OverlayPanel>
      </>
    );
  };

  const actionBrandingTemplate = (rowData: any) => {
    var btnLabel = "-";
    var btnbadgelabel = "";
    var btnallocationid = "";

    let btnbadgeclass = "badge-size p-badge-warning";
    let menuvehiclestatus = rowData.VehicleStatus == "Active" ? true : false;

    if (rowData.events != null) {
      let eventsjson = JSON.parse(rowData.events);

      let eo = Object.keys(eventsjson);

      if (eo.indexOf("BrandingExpense") > -1) {
        let eso = Object.keys(eventsjson["BrandingExpense"]);

        // let repairCost = eventsjson['Repair'] && eventsjson['Repair']['cost'] ? Number(eventsjson['Repair']['cost']).toFixed(2) : '-';
        let brandingCost =
          rowData.brandingCost && rowData.brandingCost !== 0
            ? rowData.brandingCost
            : "-";
        // btnLabel = eso.indexOf('date') > -1 ? 'AED' + ' ' + eventsjson['Repair']['cost'] : '-';

        btnLabel =
          eso.indexOf("date") > -1
            ? brandingCost !== "-"
              ? parseFloat(brandingCost).toFixed(2)
              : "-"
            : "-";

        btnallocationid =
          eso.indexOf("id") > -1 ? eventsjson["BrandingExpense"]["id"] : "";
        if (btnallocationid != "") {
          btnbadgelabel = "";
          btnbadgeclass = "badge-size p-badge-success";
        } else {
          btnbadgelabel = "";
        }
      }
    }
    return (
      <>
        <Button
          onContextMenu={(e) => {
            setselectedrow(rowData);
            handleClick("InternalInspection", e, menuname);
            InternalInspectionAppointment.current.toggle(e);
            CreateAppointment(rowData);
          }}
          style={{
            minWidth: "96px",
            width: "-webkit-fill-available",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
          label={`${btnLabel}`}
          // label={`${rowData.nextdates !== null ? moment(JSON.parse(rowData.nextdates).InternalInspection).format('MM-DD-YY') : ''}`}
          className="workspace  bgcolors workspacehover p-button-info"
          onClick={(e) => {
            handleClick("InternalInspection", e, rowData);
            inspectionRef.current.toggle(e);
          }}
        >
          {/* {btnLabel} */}
          {/* {btnbadgelabel != '' ? <Badge className={btnbadgeclass} value={btnbadgelabel}></Badge> : ''} */}
        </Button>
        <Menu
          ref={InternalInspectionAppointment}
          id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Add Branding Expense",
              icon: "pi pi-fw pi-plus",
              command(e) {
                var menuname = e.item.label;
                setInternalAppointmentFormToggle(true);
              },
            },
          ]}
        ></Menu>
        <OverlayPanel
          ref={inspectionRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <h5>Recent Branding Expense</h5>
          <DataTable value={recentAppointments} rows={5}>
            <Column field="vehicleid" header="Vehicle ID"></Column>
            <Column
              field="Date"
              header="Date"
              body={(rowData: any) => {
                return dateTemplate(rowData, "Date");
              }}
            ></Column>
            <Column field="place" header="Place"></Column>
            <Column field="cost" header="Amount"></Column>
          </DataTable>
        </OverlayPanel>
      </>
    );
  };

  const actionAccidentsTemplate = (rowData: any) => {
    const accidentsByVehicle: any = [];
    var btnLabel = "-";
    var btnbadgelabel = "";
    var btnallocationid = "";
    let btnbadgeclass = "badge-size p-badge-warning";
    let menuvehiclestatus = rowData.VehicleStatus == "Active" ? true : false;

    if (rowData.events != null) {
      let eventsjson = JSON.parse(rowData.events);

      let eo = Object.keys(eventsjson);
      btnLabel =
        rowData.Accidents !== undefined &&
        rowData.Accidents !== null &&
        rowData.Accidents !== "" &&
        rowData.Accidents !== 0
          ? rowData.Accidents
          : "-";
      if (eo.indexOf("Accident Repair") > -1) {
        let eso = Object.keys(eventsjson["Accident Repair"]);

        // btnLabel = rowData.Accidents !== undefined && rowData.Accidents !== null && rowData.Accidents !== '' && rowData.Accidents !== 0 ? rowData.Accidents : '-';
        btnallocationid =
          eso.indexOf("id") > -1 ? eventsjson["Accident Repair"]["id"] : "";
        if (btnallocationid != "") {
          btnLabel = moment(eventsjson["Accident Repair"].date).format(
            "DD-MM-YY"
          );
          btnbadgelabel = "s";
          btnbadgeclass = "badge-size p-badge-success";
        } else {
          btnbadgelabel = "N";
        }
      }
    }
    return (
      <>
        <Button
          onContextMenu={(e) => {
            setselectedrow(rowData);
            handleClick("Accident", e, menuname);
            AccidentToggle.current.toggle(e);
            CreateAppointment(rowData);
          }}
          style={{
            minWidth: "85px",
            width: "-webkit-fill-available",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
          label={`${btnLabel}`}
          // label={`${rowData.nextdates !== null ? moment(JSON.parse(rowData.nextdates).InternalInspection).format('MM-DD-YY') : ''}`}
          className="workspace  bgcolors workspacehover p-button-info"
          onClick={(e) => {
            handleClick("Accident", e, rowData);
            accidentRef.current.toggle(e);
          }}
        >
          {btnbadgelabel != "" ? (
            <Badge className={btnbadgeclass} value={btnbadgelabel}></Badge>
          ) : (
            ""
          )}
        </Button>
        <Menu
          ref={AccidentToggle}
          id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Accident",
              // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).InternalInspection.appointmentstatus == 'Scheduled' ? true : false,

              icon: "pi pi-fw pi-plus",
              command(e) {
                var menuname = e.item.label;
                navigate(
                  `/manage/appointments/item/basic?mode=new&vid=${AppointmentData.id}&type=Accident&parent=workspace`
                );
              },
            },
            {
              label: "Schedule Accident Repair",

              disabled:
                selectedrow == null
                  ? false
                  : selectedrow.events == null
                  ? false
                  : selectedrow.events !== null &&
                    JSON.parse(selectedrow.events)["Accident Repair"] !==
                      undefined &&
                    JSON.parse(selectedrow.events)["Accident Repair"]
                      .appointmentstatus === "Scheduled"
                  ? true
                  : false,
              icon: "pi pi-fw pi-pencil",
              command(e) {
                navigate(
                  `/manage/appointments/item/basic?mode=new&vid=${AppointmentData.id}&type=Accident Repair&parent=workspace`
                );
              },
            },
            {
              label: "Close Accident Repair",

              // disabled:
              disabled:
                selectedrow == null
                  ? true
                  : selectedrow.events == null
                  ? true
                  : JSON.parse(selectedrow.events)["Accident Repair"] ===
                    undefined
                  ? true
                  : JSON.parse(selectedrow.events)["Accident Repair"] !==
                      undefined &&
                    JSON.parse(selectedrow.events)["Accident Repair"]
                      .appointmentstatus === "Active"
                  ? true
                  : false,

              icon: "pi pi-fw pi-pencil",
              command(e) {
                navigate(
                  `/manage/appointments/item/basic?mode=close&vid=${
                    AppointmentData.id
                  }&type=Accident Repair&id=${
                    JSON.parse(AppointmentData.events)["Accident Repair"].id
                  }&parent=workspace`
                );
              },
            },
          ]}
        ></Menu>
        <OverlayPanel
          ref={accidentRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <h5>
            Recent Accidents
            {/* {lastdaysfilterkey ? '('+lastdaysfilterkey +' Days)':''}:  {logVid}  */}
          </h5>
          <DataTable value={recentAppointments} rows={5}>
            <Column
              field="Date"
              header="Date"
              body={(rowData: any) => {
                return dateTemplate(rowData, "Date");
              }}
            ></Column>
            <Column field="accidenthappenedat" header="Where"></Column>
            <Column
              field="accidenttype"
              header="Type"
              body={(rowData) => {
                return (
                  <Link
                    to={`/manage/appointments/item/documents?id=${rowData.id}&mode=view&vid=${rowData.vehicleid}`}
                  >
                    {rowData.accidenttype}
                  </Link>
                );
              }}
            ></Column>
          </DataTable>
        </OverlayPanel>
      </>
    );
  };

  const actionParkingTemplate = (rowData: any) => {
    return (
      <>
        <Button
          onContextMenu={(e) => {
            handleClick("Parking", e, menuname);

            setselectedrow(rowData);
            ParkingToggle.current.toggle(e);
          }}
          className="workspace parking workspacehover p-button-info"
          onClick={(e) => {
            handleClick("Parking", e, rowData);
            setLogs([]);
            setselectedrow(rowData);
            ParkingRef.current.toggle(e);
          }}
          style={{
            minWidth: "87px",
            width: "-webkit-fill-available",
            background: "#970e21",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          {`${
            rowData.Parking == null
              ? ""
              : rowData.Parking == ""
              ? ""
              : rowData.Parking
          }`}
        </Button>
        <Menu
          ref={ParkingToggle}
          // id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Add Seasonal Parking",
              // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).InternalInspection.appointmentstatus == 'Scheduled' ? true : false,
              disabled: false,
              icon: "pi pi-fw pi-plus",
              command(e) {
                navigate(
                  `/manage/parking/item/basic?mode=new&id=${selectedrow.id}&driver=${selectedrow.driver.id}`
                );
              },
            },
            // {
            //     label: 'Add Cash Reimbursement',
            //     // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).InternalInspection.appointmentstatus == 'Scheduled' ? true : false,
            //     disabled: false,
            //     icon: 'pi pi-fw pi-money-bill',
            //     command(e) {
            //         navigate(`/manage/parking/item/basic?mode=new&type=cash&id=${selectedrow.id}&driver=${selectedrow.driver.id}`);
            //     }
            // }
          ]}
        ></Menu>
        <OverlayPanel
          ref={ParkingRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div>
              <h5>
                {" "}
                Recent Parking Logs{" "}
                {lastdaysfilterkey
                  ? "(" + lastdaysfilterkey + " Days)"
                  : ""}: {logVid}
              </h5>
            </div>
            <div>
              <a
                href={"#/manage/logs/all?vid=" + logVid}
                style={{ color: "#1884b3", borderBottom: "1px solid #1884b3" }}
              >
                View all
              </a>
            </div>
          </div>

          <DataTable
            value={
              recentLogs &&
              recentLogs
                .sort(
                  (a: any, b: any) =>
                    Number(new Date(b.date)) - Number(new Date(a.date))
                )
                .slice(0, 100)
            }
            rows={5}
            className="logs-overlay-height"
            emptyMessage={recentLogsLoadingmsg}
          >
            <Column
              field="date"
              header="Transaction Date"
              body={(rowData: any) => {
                return LogsdateTemplate(rowData, "date");
              }}
            ></Column>
            <Column
              field="amount"
              header="Total Amount"
              body={(rowData: any) => {
                return logsAmountTemplate(rowData, "amount");
              }}
            ></Column>

            <Column field="drivername" header="Driver"></Column>
          </DataTable>
          <span style={{ fontWeight: "bold" }}>
            Total:{" "}
            {recentLogs &&
              recentLogs
                .reduce((sum: any, item: any) => sum + Number(item.amount), 0)
                .toFixed(2)}
          </span>
        </OverlayPanel>
      </>
    );
  };
  const actionFinesTemplate = (rowData: any) => {
    return (
      <>
        <Button
          onContextMenu={(e) => {
            handleClick("Fines", e, menuname);

            setselectedrow(rowData);
            FineToggle.current.toggle(e);
          }}
          className="workspace fines workspacehover p-button-info"
          onClick={(e) => {
            handleClick("Fines", e, rowData);
            setLogs([]);
            setselectedrow(rowData);
            fineRef.current.toggle(e);
          }}
          style={{
            minWidth: "87px",
            width: "-webkit-fill-available",
            background: "#970e21",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          {`${
            rowData.Fine == null ? "" : rowData.Fine == "" ? "" : rowData.Fine
          }`}
        </Button>
        <Menu
          ref={FineToggle}
          // id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Add Fines",
              // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).InternalInspection.appointmentstatus == 'Scheduled' ? true : false,
              disabled: false,
              icon: "pi pi-fw pi-plus",
              command(e) {
                navigate(
                  `/manage/fines/item/basic?mode=new&id=${selectedrow.id}&driver=${selectedrow.driver.id}`
                );
              },
            },
          ]}
        ></Menu>
        <OverlayPanel
          ref={fineRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div>
              <h5>
                {" "}
                Recent Fine Logs{" "}
                {lastdaysfilterkey
                  ? "(" + lastdaysfilterkey + " Days)"
                  : ""}: {logVid}
              </h5>
            </div>
            <div>
              <a
                href={"#/manage/logs/all?vid=" + logVid}
                style={{ color: "#1884b3", borderBottom: "1px solid #1884b3" }}
              >
                View alll
              </a>
            </div>
          </div>

          <DataTable
            value={
              recentLogs &&
              recentLogs
                .sort(
                  (a: any, b: any) =>
                    Number(new Date(b.date)) - Number(new Date(a.date))
                )
                .slice(0, 100)
            }
            rows={5}
            className="logs-overlay-height"
            emptyMessage={recentLogsLoadingmsg}
          >
            <Column
              field="date"
              header="Transaction Date"
              body={(rowData: any) => {
                return LogsdateTemplate(rowData, "date");
              }}
            ></Column>
            <Column
              field="totalamount"
              header="Total Amount"
              body={(rowData: any) => {
                return logsAmountTemplate(rowData, "totalamount");
              }}
            ></Column>

            <Column field="source" header="Source"></Column>
          </DataTable>
          <span style={{ fontWeight: "bold" }}>
            Total:{" "}
            {recentLogs &&
              recentLogs
                .reduce(
                  (sum: any, item: any) => sum + Number(item.totalamount),
                  0
                )
                .toFixed(2)}
          </span>
        </OverlayPanel>
      </>
    );
  };
  const actionSalikTemplate = (rowData: any) => {
    return (
      <>
        <Button
          onContextMenu={(e) => {
            setselectedrow(rowData);
            handleClick("Salik", e, menuname);
            SalikToggle.current.toggle(e);
          }}
          label={`${
            rowData.Salik == null
              ? ""
              : rowData.Salik == ""
              ? ""
              : rowData.Salik
          }`}
          className="workspace salik workspacehover p-button-info"
          onClick={(e) => {
            handleClick("Salik", e, rowData);
            setselectedrow(rowData);
            setLogs([]);
            salikRef.current.toggle(e);
          }}
          style={{
            minWidth: "87px",
            width: "-webkit-fill-available",
            background: "#e14247",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        />
        <Menu
          ref={SalikToggle}
          // id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Add Salik",
              // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).InternalInspection.appointmentstatus == 'Scheduled' ? true : false,
              disabled: false,
              icon: "pi pi-fw pi-plus",
              command(e) {
                navigate(
                  `/manage/salik/item/basic?mode=new&id=${selectedrow.id}&driver=${selectedrow.driver.id}`
                );
              },
            },
            // {
            //     label: 'Add Cash Reimbursement',
            //     // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).InternalInspection.appointmentstatus == 'Scheduled' ? true : false,
            //     disabled: false,
            //     icon: 'pi pi-fw pi-money-bill',
            //     command(e) {
            //         navigate(`/manage/salik/item/basic?mode=new&type=cash&id=${selectedrow.id}&driver=${selectedrow.driver.id}`);
            //     }
            // }
          ]}
        ></Menu>
        <OverlayPanel
          ref={salikRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div>
              <h5>
                Recent Salik Logs{" "}
                {lastdaysfilterkey ? "(" + lastdaysfilterkey + " Days)" : ""}:{" "}
                {logVid}
              </h5>
            </div>
            <div>
              <a
                href={"#/manage/logs/salik?vid=" + logVid}
                style={{ color: "#1884b3", borderBottom: "1px solid #1884b3" }}
              >
                View all
              </a>
            </div>
          </div>

          <DataTable
            value={
              recentLogs &&
              recentLogs
                .sort(
                  (a: any, b: any) =>
                    Number(new Date(b.date)) - Number(new Date(a.date))
                )
                .slice(0, 100)
            }
            rows={5}
            className="logs-overlay-height"
            emptyMessage={recentLogsLoadingmsg}
          >
            <Column
              field="date"
              header="Transaction Date"
              body={(rowData: any) => {
                return LogsdateTemplate(rowData, "date");
              }}
            ></Column>
            <Column
              field="amount"
              header="Amount"
              body={(rowData: any) => {
                return logsAmountTemplate(rowData, "amount");
              }}
            ></Column>
            <Column field="tollgate" header="Toll Gate"></Column>
          </DataTable>
          <span style={{ fontWeight: "bold" }}>
            Total:{" "}
            {recentLogs &&
              recentLogs
                .reduce((sum: any, item: any) => sum + Number(item.amount), 0)
                .toFixed(2)}
          </span>
        </OverlayPanel>
      </>
    );
  };

  const actionMileageTemplate = (rowData: any) => {
    return (
      <>
        <Button
          onContextMenu={(e) => {
            setselectedrow(rowData);
            handleClick("Mileage", e, menuname);
            MileageToggle.current.toggle(e);
          }}
          label={`${
            rowData.Mileage == null
              ? ""
              : rowData.Mileage == null
              ? ""
              : rowData.Mileage
          }`}
          // label={`${rowData.Mileage !== null ? JSON.parse(rowData.Mileage).mileage : ''}`}
          className="workspace mileage workspacehover p-button-info"
          onClick={(e) => {
            setselectedrow(rowData);
            handleClick("Mileage", e, rowData);
            setLogs([]);
            mileageRef.current.toggle(e);
          }}
          style={{
            minWidth: "87px",
            width: "-webkit-fill-available",
            background: "#38b000",
          }}
        />
        <Menu
          ref={MileageToggle}
          // id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Add Mileage",
              // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).InternalInspection.appointmentstatus == 'Scheduled' ? true : false,
              disabled: false,
              icon: "pi pi-fw pi-plus",
              command(e) {
                navigate(
                  `/manage/mileage/item/basic?mode=new&id=${selectedrow.id}&driver=${selectedrow.driver.id}`
                );
              },
            },
          ]}
        ></Menu>
        <OverlayPanel
          ref={mileageRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <h5>
            Recent Mileage Logs{" "}
            {lastdaysfilterkey ? "(" + lastdaysfilterkey + " Days)" : ""}:{" "}
            {logVid}{" "}
          </h5>
          <DataTable
            value={recentLogs.slice(0, 100)}
            rows={5}
            className="logs-overlay-height"
            emptyMessage={recentLogsLoadingmsg}
          >
            <Column
              field="date"
              header="Transaction Date"
              body={(rowData: any) => {
                return LogsdateTemplate(rowData, "date");
              }}
            ></Column>
            <Column field="startKM" header="Start KM"></Column>
            <Column field="endKM" header="End KM"></Column>
            <Column field="distance" header="Distance"></Column>
          </DataTable>
        </OverlayPanel>
      </>
    );
  };

  const actionVIPFuelTemplate = (rowData: any) => {
    return (
      <>
        <Button
          onContextMenu={(e) => {
            setselectedrow(rowData);
            handleClick("Vipfuel", e, menuname);
            VipfuelToggle.current.toggle(e);
          }}
          label={`${
            rowData.Vipfuel == null
              ? ""
              : rowData.Vipfuel == ""
              ? ""
              : rowData.Vipfuel
          }`}
          className="workspace vipfuel workspacehover p-button-info"
          onClick={(e) => {
            handleClick("Vipfuel", e, rowData);
            setselectedrow(rowData);
            setLogs([]);
            vipfuelRef.current.toggle(e);
          }}
          style={{
            minWidth: "87px",
            width: "-webkit-fill-available",
            background: "#fa6507",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        />
        <Menu
          ref={VipfuelToggle}
          // id={selectedrow != null && selectedrow.id}
          popup
          model={[
            {
              label: "Add Vipfuel",
              // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).InternalInspection.appointmentstatus == 'Scheduled' ? true : false,
              disabled: false,
              icon: "pi pi-fw pi-plus",
              command(e) {
                navigate(
                  `/manage/vipfuel/item/basic?mode=new&id=${selectedrow.id}&driver=${selectedrow.driver.id}`
                );
              },
            },
            // {
            //     label: 'Add Cash Reimbursement',
            //     // disabled: selectedrow != null && selectedrow.events != null && JSON.parse(selectedrow.events).InternalInspection.appointmentstatus == 'Scheduled' ? true : false,
            //     disabled: false,
            //     icon: 'pi pi-fw pi-money-bill',
            //     command(e) {
            //         navigate(`/manage/vipfuel/item/basic?mode=new&type=cash&id=${selectedrow.id}&driver=${selectedrow.driver.id}`);
            //     }
            // }
          ]}
        ></Menu>
        <OverlayPanel
          ref={vipfuelRef}
          showCloseIcon
          id="overlay_panel"
          style={{ width: "450px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div>
              <h5>
                Recent VIP Fuel Logs{" "}
                {lastdaysfilterkey ? "(" + lastdaysfilterkey + " Days)" : ""}:{" "}
                {logVid}
              </h5>
            </div>
            <div>
              <a
                href={"#/manage/logs/vipfuel?vid=" + logVid}
                style={{ color: "#1884b3", borderBottom: "1px solid #1884b3" }}
              >
                View all
              </a>
            </div>
          </div>

          <DataTable
            value={
              recentLogs &&
              recentLogs
                .sort(
                  (a: any, b: any) =>
                    Number(new Date(b.date)) - Number(new Date(a.date))
                )
                .slice(0, 100)
            }
            rows={5}
            className="logs-overlay-height"
            emptyMessage={recentLogsLoadingmsg}
          >
            <Column
              field="date"
              header="Transaction Date"
              body={(rowData: any) => {
                return LogsdateTemplate(rowData, "date");
              }}
            ></Column>
            <Column
              field="total"
              header="Amount"
              body={(rowData: any) => {
                return logsAmountTemplate(rowData, "total");
              }}
            ></Column>
            <Column field="stationname" header="Station Name"></Column>
          </DataTable>
          <span style={{ fontWeight: "bold" }}>
            Total:{" "}
            {recentLogs &&
              recentLogs
                .reduce((sum: any, item: any) => sum + Number(item.total), 0)
                .toFixed(2)}
          </span>
        </OverlayPanel>
      </>
    );
  };
  //#endregion

  //#region DB fetch services

  /**
   * Fetch all driver information from DB
   */

  /**
   * Fetch Vehicle activity details from Vehicle DB for Workspace DataTable
   */

  const getData = async () => {
    const today = new Date();
    const res = await AmplifyServices.getvehicleactivitydetails();

    const itemsWithDriverImages = await Promise.all(
      res
        .filter(
          (res: any) =>
            res.vehicle && res.vehicle.status && res.vehicle.status === "Active"
        )
        .map(async (item: any) => {
          const driverpic =
            (item.driver &&
              item.driver.jfield &&
              JSON.parse(item.driver.jfield)?.driverpicture) ||
            "";
          const driverimage = driverpic ? await Storage.get(driverpic) : "";
          item.driverimage = driverimage;
          return item;
        })
    );
    // console.log(
    //   itemsWithDriverImages[6],
    //   JSON.parse(itemsWithDriverImages[6].expiring)
    // );

    setfilterReports(itemsWithDriverImages);

    const processItemWithLogs = async (item: any) => {
      const appointmentCost = await getAppointmentCost(item.id);
      const Brandingcost = await getBrandingExpCost(item.id);
      const InsuranceCost = await getRenewalCost(item.id, "Insurance");
      const RegistrationCost = await getRenewalCost(item.id, "Registration");
      //   console.log(InsuranceCost);

      // Calculate repair cost
      const repairCost = appointmentCost.reduce(
        (
          acc: any,
          serviceItem: { cost: any; eventtype: any; appointmentstatus: any }
        ) => {
          if (
            serviceItem.eventtype !== "Branding Expense" &&
            serviceItem.appointmentstatus === "Completed"
          ) {
            return acc + Number(serviceItem.cost);
          }
          return acc;
        },
        0
      );
      item.repaircost = repairCost;
      // Calculate Insurance cost
      const renewInsuranceCost = InsuranceCost.reduce(
        (acc: any, serviceItem: { totalamount: any; vehicleid: any }) => {
          if (serviceItem.totalamount) {
            return acc + Number(serviceItem.totalamount);
          }
          return acc;
        },
        0
      );
      const renewRegistrationCost = RegistrationCost.reduce(
        (acc: any, serviceItem: { totalamount: any; vehicleid: any }) => {
          if (serviceItem.totalamount) {
            return acc + Number(serviceItem.totalamount);
          }
          return acc;
        },
        0
      );
      //   console.log(renewInsuranceCost);

      item.InsuranceCost = renewInsuranceCost;

      item.mulkiacost = renewRegistrationCost;
      // Calculate branding cost
      const brandingCost = Brandingcost.reduce(
        (
          acc: any,
          serviceItem: { cost: any; eventtype: any; appointmentstatus: any }
        ) => {
          if (
            serviceItem.eventtype === "Branding Expense" &&
            serviceItem.appointmentstatus === "Completed"
          ) {
            return acc + Number(serviceItem.cost);
          }
          return acc;
        },
        0
      );
      item.brandingCost = brandingCost;

      let logs = item.vehicle.masterlogs.items;

      if (
        lastdaysfilterkey !== null &&
        lastdaysfilterkey !== "-" &&
        lastdaysfilterkey !== "custom"
      ) {
        if (lastdaysfilterkey === "lastmonth") {
          logs = logs.filter((log: any) => {
            const itemStartDate: any = new Date(log.date);
            return (
              itemStartDate >= new Date(lastMonthStartDate) &&
              itemStartDate <= new Date(lastMonthEndDate)
            );
          });
        } else {
          const numDaysAgo = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - lastdaysfilterkey
          );
          logs = logs.filter((log: any) => {
            const itemDate = new Date(log.date);
            return itemDate >= numDaysAgo && itemDate <= today;
          });
        }
      }

      if (lastdaysfilterkey === "custom") {
        let sdate = new Date(customDate[0]);
        let edate = new Date(customDate[1]);
        logs = logs.filter((log: any) => {
          const itemDate = new Date(log.date);
          return moment(itemDate).isBetween(moment(sdate), moment(edate));
        });
      }

      let accidents = 0;
      let fine = 0;
      let salik = 0;
      let mileage = 0;
      let vipfuel = 0;
      let parking = 0;
      logs
        .filter(
          (del: any) => del._deleted !== true && del.reimbursement !== "Yes"
        )
        .forEach((log: any) => {
          if (log.Accidents) {
            accidents++;
            item.Accidents = accidents;
          }
          if (log.Parking) {
            parking += parseFloat(log.Parking);
            item.Parking = parking.toFixed(2);
          }
          if (log.Fine) {
            fine += parseFloat(log.Fine);
            item.Fine = fine.toFixed(2);
          }
          if (log.Salik) {
            salik += parseFloat(log.Salik);
            item.Salik = salik.toFixed(2);
          }
          if (log.Mileage) {
            mileage += parseFloat(log.Mileage);
            item.Mileage = mileage.toFixed(2);
          }
          if (log.Vipfuel) {
            vipfuel += parseFloat(log.Vipfuel);
            item.Vipfuel = vipfuel.toFixed(2);
          }
        });

      return item;
    };

    const itemsWithLogs = await Promise.all(
      itemsWithDriverImages.map(processItemWithLogs)
    );
    console.log(itemsWithLogs);

    setDatabase(itemsWithLogs);
    setfilterReports(itemsWithLogs);
    setLoading(false);
  };
  const getRenewalCost = async (id: any, type?: any) => {
    const ac = `id renewaltype totalamount _version issuedate expirydate vehicleid _deleted`;
    let filter = {
      vehicleid: {
        eq: id,
      },
      renewaltype: {
        eq: type,
      },
      totalamount: { attributeExists: true },
    };
    let nextres = await getNextQueryData("", filter, "renewal", false, ac);
    return nextres;
  };
  const getBrandingExpCost = async (id: any, nexttoken?: any) => {
    const ac = `cost eventtype Date _version appointmentstatus vehicleid _deleted`;
    let filter = {
      vehicleid: {
        eq: id,
      },
      eventtype: {
        eq: "Branding Expense",
      },
    };
    let nextres = await getNextQueryData("", filter, "appointment", true, ac);
    return nextres;
  };
  const getAppointmentCost = async (id: any, nexttoken?: any) => {
    const ac = `cost eventtype Date _version appointmentstatus vehicleid _deleted`;
    let filter = {
      vehicleid: {
        eq: id,
      },
      or: [
        {
          eventtype: {
            eq: "Service",
          },
        },

        {
          eventtype: {
            eq: "Repair/Replacement",
          },
        },
      ],
    };

    let response = await dxService.listitems(
      "appointment",
      ac,
      filter,
      true,
      1000,
      nexttoken
    );
    let items = response.items;
    let nextToken = items.nextToken;
    if (nextToken) {
      const nextresponse = await getAppointmentCost(id, nextToken);
      items = items.concat(nextresponse);
    }
    items = items.filter((item: any) => item._deleted !== true);
    return items;
  };

  /**
   * Fetch all allocated driver information
   * @param vid - Vehicle ID
   * @param limit - Limit for displaying items in a table
   * @returns - Items retrived from DB
   */
  const getpreviousdata = async (vid: any, limit?: number) => {
    // let filter = {
    //     or: [{ vehicleid: { eq: vid } }]
    // };
    // let query: any = { filter: filter };
    // if (limit !== undefined) query['limit'] = limit;

    // const res = await AmplifyServices.getallocateddrivers(query);
    // return res;

    // const res = await dxService.getitembyid('vehicleactivity',vid);

    // if(res && res.allocations && res.allocations.items.length > 0)
    // return res.allocations.items;
    // else
    // return [];

    let filter = {
      or: [{ vehicleid: { eq: vid } }],
    };
    let limitvalue = limit != undefined ? limit : 1000;

    //const res = await AmplifyServices.getallocateddrivers(query);
    const res = await dxService.listitems(
      "allocation",
      "*",
      filter,
      true,
      limitvalue,
      null
    );

    return res.items;
  };
  const getPreviousVehicleActivity = async (
    vid: any,
    type: any,
    nextToken?: any
  ) => {
    let filter = {
      vehicleid: {
        eq: vid,
      },
      eventtype: {
        eq: type,
      },
      // ...(lastdaysfilterkey !== null && lastdaysfilterkey !== '-' && lastdaysfilterkey !== 'custom'
      //     ? {
      //           and: [
      //               {
      //                   Date: {
      //                       //ISO format
      //                       ge:
      //                           lastdaysfilterkey === 'lastmonth'
      //                               ? moment(lastMonthStartDate).format('YYYY-MM-DDT00:00:00.000[Z]')
      //                               : lastdaysfilterkey === 'thismonth'
      //                               ? moment(ThisMonthstartDate).format('YYYY-MM-DDT00:00:00.000[Z]')
      //                               : moment().subtract(lastdaysfilterkey, 'days').format('YYYY-MM-DDT00:00:00.000[Z]')
      //                   }
      //               },
      //               {
      //                   Date: {
      //                       le:
      //                           lastdaysfilterkey === 'lastmonth'
      //                               ? moment(lastMonthEndDate).format('YYYY-MM-DDT24:59:59.999[Z]')
      //                               : lastdaysfilterkey === 'thismonth'
      //                               ? moment(ThisMonthendDate).format('YYYY-MM-DDT24:59:59.999[Z]')
      //                               : new Date().toISOString()
      //                   }
      //               }
      //           ]
      //       }
      //     : {}),
      // ...(lastdaysfilterkey === 'custom'
      //     ? {
      //           and: [
      //               {
      //                   Date: {
      //                       ge: localetoisodatetime(customDate[0])
      //                   }
      //               },
      //               {
      //                   Date: {
      //                       le: customDate[1] ? localetoisodateendtime(customDate[1]) : localetoisodateendtime(customDate[0])
      //                   }
      //               }
      //           ]
      //       }
      //     : {})
    };

    let variables: any = { filter: filter, limit: 1000 };
    if (nextToken) {
      variables.nextToken = nextToken;
    }

    let GetResponse: any = await API.graphql({
      query: listDxAppointments,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: variables,
    });
    let res = GetResponse.data.listDxAppointments.items;

    if (GetResponse.data.listDxAppointments.nextToken) {
      const nextResults = await getPreviousVehicleActivity(
        vid,
        type,
        GetResponse.data.listDxAppointments.nextToken
      );
      res = res.concat(nextResults);
    }

    return res;
  };
  const getPreviousRenewals = async (vid: any, type: any, nextToken?: any) => {
    let filter = {
      vehicleid: {
        eq: vid,
      },
      renewaltype: {
        eq: type,
      },
      totalamount: { attributeExists: true },
      // ...(lastdaysfilterkey !== null && lastdaysfilterkey !== '-' && lastdaysfilterkey !== 'custom'
      //     ? {
      //           and: [
      //               {
      //                   Date: {
      //                       //ISO format
      //                       ge:
      //                           lastdaysfilterkey === 'lastmonth'
      //                               ? moment(lastMonthStartDate).format('YYYY-MM-DDT00:00:00.000[Z]')
      //                               : lastdaysfilterkey === 'thismonth'
      //                               ? moment(ThisMonthstartDate).format('YYYY-MM-DDT00:00:00.000[Z]')
      //                               : moment().subtract(lastdaysfilterkey, 'days').format('YYYY-MM-DDT00:00:00.000[Z]')
      //                   }
      //               },
      //               {
      //                   Date: {
      //                       le:
      //                           lastdaysfilterkey === 'lastmonth'
      //                               ? moment(lastMonthEndDate).format('YYYY-MM-DDT24:59:59.999[Z]')
      //                               : lastdaysfilterkey === 'thismonth'
      //                               ? moment(ThisMonthendDate).format('YYYY-MM-DDT24:59:59.999[Z]')
      //                               : new Date().toISOString()
      //                   }
      //               }
      //           ]
      //       }
      //     : {}),
      // ...(lastdaysfilterkey === 'custom'
      //     ? {
      //           and: [
      //               {
      //                   Date: {
      //                       ge: localetoisodatetime(customDate[0])
      //                   }
      //               },
      //               {
      //                   Date: {
      //                       le: customDate[1] ? localetoisodateendtime(customDate[1]) : localetoisodateendtime(customDate[0])
      //                   }
      //               }
      //           ]
      //       }
      //     : {})
    };

    let variables: any = { filter: filter, limit: 1000 };
    if (nextToken) {
      variables.nextToken = nextToken;
    }

    let GetResponse: any = await API.graphql({
      query: listDxContracts,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: variables,
    });
    let res = GetResponse.data.listDxContracts.items;

    if (GetResponse.data.listDxContracts.nextToken) {
      const nextResults = await getPreviousRenewals(
        vid,
        type,
        GetResponse.data.listDxContracts.nextToken
      );
      res = res.concat(nextResults);
    }

    return res;
  };
  const LogsdateTemplate = (data: any, col: string) => {
    return (
      <span>
        {data[col] !== null ? convertdate(isotolocaledatetime(data[col])) : "-"}
      </span>
    );
    //return <>{data[col] !== null ? isotolocaledatetime(data[col]) : '-'}</>;
  };
  const logsAmountTemplate = (data: any, col: string) => {
    return <span>{data[col] !== null ? "AED" + " " + data[col] : "-"}</span>;
  };

  const getExpenselogs = async (
    servicename: any,
    vehicleid: any,
    drivername: any,
    query: any,
    qfilter: any
  ) => {
    if (
      lastdaysfilterkey !== null &&
      lastdaysfilterkey !== "-" &&
      lastdaysfilterkey !== "custom"
    ) {
      var tempd = new Date();
      var pd = new Date(tempd.toDateString());
      pd.setDate(pd.getDate() - lastdaysfilterkey);
      var td = new Date(tempd.toDateString());

      let dfilter = {
        and: [
          {
            date: {
              ge:
                lastdaysfilterkey === "lastmonth"
                  ? moment(lastMonthStartDate).format(
                      "YYYY-MM-DDT00:00:00.000[Z]"
                    )
                  : lastdaysfilterkey === "thismonth"
                  ? moment(ThisMonthstartDate).format(
                      "YYYY-MM-DDT00:00:00.000[Z]"
                    )
                  : localetoisodatetime(pd),
            },
          },
          {
            date: {
              le:
                lastdaysfilterkey === "lastmonth"
                  ? moment(lastMonthEndDate).format(
                      "YYYY-MM-DDT24:59:59.999[Z]"
                    )
                  : lastdaysfilterkey === "thismonth"
                  ? moment(ThisMonthendDate).format(
                      "YYYY-MM-DDT24:59:59.999[Z]"
                    )
                  : localetoisodatetime(td),
            },
          },
        ],
      };
      qfilter["logfilter"] = dfilter;
    }
    if (lastdaysfilterkey === "custom") {
      let dfilter = {
        and: [
          {
            date: {
              ge: localetoisodatetime(customDate[0]),
            },
          },
          {
            date: {
              le: customDate[1]
                ? localetoisodateendtime(customDate[1])
                : localetoisodateendtime(customDate[0]),
            },
          },
        ],
      };
      qfilter["logfilter"] = dfilter;
    }
    let res = await dxService.queryitems(query, qfilter);

    let masterlogitems = res.logs.items;

    let logitems: any[] = [];
    masterlogitems.map((item: any) => {
      item[servicename].items.map((sitem: any) => {
        logitems.push(sitem);
      });
    });

    return logitems;
  };

  const getPreviousLogs = async (
    servicename: any,
    vehicleid: any,
    drivername: any,
    nextToken?: any
  ) => {
    let dfilter = {
      and: [
        {
          date: {
            ge: moment().subtract(lastdaysfilterkey, "days").toISOString(),
          },
        },
        {
          date: {
            le: new Date().toISOString(),
          },
        },
      ],
    };

    if (drivername === undefined) {
      setLogsLoadingmsg("No Results Found");
    } else {
      setLogsLoadingmsg("Fetching Please wait");
      let variables = {
        vehicleid: {
          eq: vehicleid, // filter priority = 1
        },
        drivername: {
          eq: drivername,
        },
        ...(lastdaysfilterkey !== null &&
        lastdaysfilterkey !== "-" &&
        lastdaysfilterkey !== "custom"
          ? {
              and: [
                {
                  date: {
                    //ISO format
                    ge:
                      lastdaysfilterkey === "lastmonth"
                        ? moment(lastMonthStartDate).format(
                            "YYYY-MM-DDT00:00:00.000[Z]"
                          )
                        : lastdaysfilterkey === "thismonth"
                        ? moment(ThisMonthstartDate).format(
                            "YYYY-MM-DDT00:00:00.000[Z]"
                          )
                        : moment()
                            .subtract(lastdaysfilterkey, "days")
                            .format("YYYY-MM-DDT00:00:00.000[Z]"),
                  },
                },
                {
                  date: {
                    le:
                      lastdaysfilterkey === "lastmonth"
                        ? moment(lastMonthEndDate).format(
                            "YYYY-MM-DDT24:59:59.999[Z]"
                          )
                        : lastdaysfilterkey === "thismonth"
                        ? moment(ThisMonthendDate).format(
                            "YYYY-MM-DDT24:59:59.999[Z]"
                          )
                        : new Date().toISOString(),
                  },
                },
              ],
            }
          : {}),
        ...(lastdaysfilterkey === "custom"
          ? {
              and: [
                {
                  date: {
                    ge: localetoisodatetime(customDate[0]),
                  },
                },
                {
                  date: {
                    le: customDate[1]
                      ? localetoisodateendtime(customDate[1])
                      : localetoisodateendtime(customDate[0]),
                  },
                },
              ],
            }
          : {}),
      };

      let GetResponse: any = await dxService.listitems(
        servicename,
        "*",
        variables,
        true,
        100,
        nextToken
      );
      let response = GetResponse.items;

      //push the items to the setitems
      setLogs((prevItems: any) => [...prevItems, ...response]);
      let nexttoken = GetResponse.nextToken;
      if (nexttoken) {
        response = [
          ...response,
          ...(await getPreviousLogs(
            servicename,
            vehicleid,
            drivername,
            nexttoken
          )),
        ];
      }
      if (!nexttoken && response.length === 0) {
        setLogsLoadingmsg("No Results Found");
      }
      if (response.length > 0) {
        setLogsLoadingmsg("");
      }
      // window.onscroll = async function (ev) {
      //     if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {

      //     }
      // };
      return response;
    }
  };
  //#endregion

  //#region Other functions

  // filterByReference(Database, DriverNameOption);

  const onMenuDialogHide = () => {
    setselectedrow(null);
    setAllocareDriverToggle(false);
    setDeAllocareDriverToggle(false);
    setDisplayModal(false);
    // getvehicleactivities();
  };

  const OndriverChange = (rowData: any, index: any) => {
    setVehicleDriver(rowData);
    let a = rowData.driver;
    setDropdownItem(a);
  };
  const CreateAppointment = (rowData: any) => {
    let AppointmentData = [];
    AppointmentData.push(rowData);
    // setVehicleDriver(rowData);
    // setDropdownItem(rowData.allocationdriver);
    setAppointmentData(rowData);
    // ServiceAppointmentFormToggle&&<><AppointmentForm  data={AppointmentData} msg='appointment' /></>
  };

  const handleClick = async (name: any, e: any, menuname: any) => {
    document.oncontextmenu = function () {
      return false;
    };

    //Hide the menu in a right click
    var ActionBoxHide = document.getElementsByClassName("p-menu-overlay")[0];
    if (ActionBoxHide !== undefined) {
      var ActionBoxHid = (ActionBoxHide.className = "HidePopupMenu");
    }

    //executes on left click event
    if (e.type === "click") {
      let nextdisplaybasic = !displayBasic;
      // let nextdisplaybasicclose = displayBasic;
      setDisplayBasic(!nextdisplaybasic);
      if (name === "displayBasic") {
        {
        }
      } else if (name === "Driver") {
        // getpreviousdata().then((resp)=>{
        // })
        getpastvehicledrivers(null);
        getpreviousdata(menuname.id).then((res) => {
          if (res.length > 0) {
            const sortedAsc = res.sort(
              (objA: any, objB: any) =>
                Number(new Date(objB.fromdate)) -
                Number(new Date(objA.fromdate))
            );

            //let limiteditems = res.slice(0, 5)
            getpastvehicledrivers(sortedAsc.slice(0, 10));
            //   renderoverlaypanel(res);

            //toggleopdatatable(e);
            // dynamicColumn = res.map((item: any) => {
            //     return (
            //         <li key={item.id}>
            //             <div className="bestseller-item flex align-items-center p-3 mb-2">
            //                 <span style={{ margin: 'auto', textAlign: 'center', width: '73%' }}>{item.drivername}</span>
            //                 <span className="item-button"></span>
            //             </div>
            //         </li>
            //     )
            // });
            // let setVariable: any = renderDialog(nextdisplaybasic, name, dynamicColumn);
            // return setOpenModel(setVariable);
          } else {
            getpastvehicledrivers(null);
            return null;
          }
        });

        // {
        //     return(
        //       <>
        //        <div className="bestseller-item flex align-items-center p-3 mb-2">
        //        <span style={{ margin: 'auto', textAlign: 'center', width: '73%' }}>Test 1</span>
        //              <span className="item-button"></span>
        //        </div>
        //       </>
        //     )
        // }
        //getpastvehicledrivers
        // {
        //     dynamicColumn = salesData.map((item: any) => {

        //         return (
        //             <>
        //                 <div className="bestseller-item flex align-items-center p-3 mb-2">
        //                     <span style={{ margin: 'auto', textAlign: 'center', width: '73%' }}>{item.Driver}</span>
        //                     <span className="item-button"></span>
        //                 </div>
        //             </>
        //         );
        //     });
        // }
      } else if (name === "Expiring") {
      } else if (name === "Service") {
        getPreviousVehicleActivity(menuname.id, "Service").then((res: any) => {
          const sortedObjects = res
            .filter((del: any) => del._deleted !== true)
            .sort(
              (objA: any, objB: any) =>
                Number(new Date(objB.Date)) - Number(new Date(objA.Date))
            );
          let sortedItem = sortedObjects.slice(0, 5);

          setRecentAppointments(sortedItem);
        });
      } else if (name === "RepairsReplacements") {
        setrepair(menuname.id);
        getPreviousVehicleActivity(menuname.id, "Repair/Replacement").then(
          (res: any) => {
            const sortedObjects = res
              .filter((del: any) => del._deleted !== true)
              .sort(
                (objA: any, objB: any) =>
                  Number(new Date(objB.Date)) - Number(new Date(objA.Date))
              );

            let sortedItem = sortedObjects.slice(0, 5);

            setRecentAppointments(sortedItem);
          }
        );
      } else if (name === "InternalInspection") {
        getPreviousVehicleActivity(menuname.id, "Branding Expense").then(
          (res: any) => {
            const sortedObjects = res
              .filter((del: any) => del._deleted !== true)
              .sort(
                (objA: any, objB: any) =>
                  Number(new Date(objB.Date)) - Number(new Date(objA.Date))
              );
            let sortedItem = sortedObjects.slice(0, 5);

            setRecentAppointments(sortedItem);
          }
        );
      } else if (name === "InsuranceCost") {
        getPreviousRenewals(menuname.id, "Insurance").then((res: any) => {
          const sortedObjects = res
            .filter((del: any) => del._deleted !== true)
            .sort(
              (objA: any, objB: any) =>
                Number(new Date(objB.Date)) - Number(new Date(objA.Date))
            );
          let sortedItem = sortedObjects.slice(0, 5);
          console.log(sortedItem);

          setrecentRenewals(sortedItem);
        });
        //MulkiaCost
      } else if (name === "MulkiaCost") {
        getPreviousRenewals(menuname.id, "Registration").then((res: any) => {
          const sortedObjects = res
            .filter((del: any) => del._deleted !== true)
            .sort(
              (objA: any, objB: any) =>
                Number(new Date(objB.Date)) - Number(new Date(objA.Date))
            );
          let sortedItem = sortedObjects.slice(0, 5);
          console.log(sortedItem);

          setrecentRenewals(sortedItem);
        });
        //MulkiaCost
      } else if (name === "Accident") {
        getPreviousVehicleActivity(menuname.id, "Accident").then((res: any) => {
          const sortedObjects = res
            .filter((del: any) => del._deleted !== true)
            .sort(
              (objA: any, objB: any) =>
                Number(new Date(objB.Date)) - Number(new Date(objA.Date))
            )
            .slice(0, 5);
          let sortedItem = sortedObjects;

          setRecentAppointments(sortedItem);
        });
      } else if (name === "Fines") {
        setLogVid(menuname.id);
        // getPreviousLogs('logfines', menuname.id, menuname?.driver?.name).then(res => {
        //     setLogs(res)
        // })

        let qfilter = {
          id: menuname.id,
        };
        let q = `
                query getvehicleactivitylogs($id: ID!, $logfilter: ModeldxMasterLogsFilterInput) {
                    getDxVehicleActivity(id: $id){
                            id
                            logs(
                                limit: 100
                                filter: $logfilter
                                ) {
                                items {
                                    id
                                    Fine
                                    date
                                    fines {
                                        items{
                                            id
                                            date
                                            amount
                                            innovationfee
                                            totalamount
                                            source
                                        }
                                        nextToken
                                    }
                                }
                                nextToken
                            }
                    }
                }
            `;

        setLogVid(menuname.id);
        getExpenselogs(
          "fines",
          menuname.id,
          menuname?.driver?.name,
          q,
          qfilter
        ).then((res) => {
          setLogs(res);
        });
      } else if (name === "Salik") {
        let qfilter = {
          id: menuname.id,
        };
        let q = `
                query getvehicleactivitylogs($id: ID!, $logfilter: ModeldxMasterLogsFilterInput) {
                    getDxVehicleActivity(id: $id){
                            id
                            logs(
                                limit: 100
                                filter: $logfilter
                                ) {
                                items {
                                    id
                                    Salik
                                    date
                                    salik {
                                        items{
                                            id
                                            date
                                            tollgate
                                            amount
                                        }
                                        nextToken
                                    }
                                }
                                nextToken
                            }
                    }
                }
            `;

        setLogVid(menuname.id);
        getExpenselogs(
          "salik",
          menuname.id,
          menuname?.driver?.name,
          q,
          qfilter
        ).then((res) => {
          setLogs(res);
        });
        // getPreviousLogs('logsalik', menuname.id, menuname?.driver?.name).then(res => {
        //     setLogs(res)
        // })
      } else if (name === "Mileage") {
        setLogVid(menuname.id);
        getPreviousLogs("logmileage", menuname.id, menuname?.driver?.name).then(
          (res) => {
            setLogs(res);
          }
        );
      } else if (name === "Vipfuel") {
        setLogVid(menuname.id);
        // getPreviousLogs('logvipfuels', menuname.id, menuname?.driver?.name).then(res => {
        //     setLogs(res)
        // })

        let qfilter = {
          id: menuname.id,
        };
        let q = `
                query getvehicleactivitylogs($id: ID!, $logfilter: ModeldxMasterLogsFilterInput) {
                    getDxVehicleActivity(id: $id){
                            id
                            logs(
                                limit: 100
                                filter: $logfilter
                                ) {
                                items {
                                    id
                                    Vipfuel
                                    date
                                    vipfuel{
                                        items{
                                            id
                                            date
                                            total
                                            stationname
                                        }
                                    }
                                }
                                nextToken
                            }
                    }
                }
            `;

        setLogVid(menuname.id);
        getExpenselogs(
          "vipfuel",
          menuname.id,
          menuname?.driver?.name,
          q,
          qfilter
        ).then((res) => {
          setLogs(res);
        });
      } else if (name === "Parking") {
        let qfilter = {
          id: menuname.id,
        };
        let q = `
                query getvehicleactivitylogs($id: ID!, $logfilter: ModeldxMasterLogsFilterInput) {
                    getDxVehicleActivity(id: $id){
                            id
                            logs(
                                limit: 100
                                filter: $logfilter
                                ) {
                                items {
                                    id
                                    Parking
                                    date
                                    parkings{
                                        items{
                                            id
                                            date
                                            amount
                                            drivername
                                        }
                                    }
                                }
                                nextToken
                            }
                    }
                }
            `;

        setLogVid(menuname.id);
        getExpenselogs(
          "parkings",
          menuname.id,
          menuname?.driver?.name,
          q,
          qfilter
        ).then((res) => {
          setLogs(res);
        });
      } else if (name === "vehicleid") {
        navigate(
          `/manage/vehicles/item/basic?mode=view&id=${e.target.innerText}`
        );
      }
    } else if (e.type === "contextmenu") {
    }
  };

  // const onHide = () => {
  //     setDisplayBasic(false);
  //     setOpenModel('');
  // };

  // let VehicleStatus = "Hover Information Last 5 statuses of the vehicle"

  //For grouping the colum headers
  let headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Vehicle Detail" colSpan={1} />
        <Column header="Allocate" colSpan={2} />
        <Column header="Renewals" colSpan={3} />
        <Column header="Create a event/appointment" colSpan={4} />
        {/* <Column header="Alert" colSpan={1} /> */}
        <Column header="View" colSpan={6} />
        {/* <Column  colSpan={1} /> */}
      </Row>
      <Row>
        <Column header="Vehicle" field="id" sortable colSpan={1} />
        <Column header="Status" colSpan={1} />
        {/* <Column header="Image" colSpan={1} /> */}
        <Column header="Driver" colSpan={1} />
        <Column header="Expiring soon" colSpan={1} />
        <Column header="Insurance (AED)" colSpan={1} />
        <Column header="Registration (AED)" colSpan={1} />
        <Column header="Services" colSpan={1} />
        <Column
          header="Repairs/ Replacements (AED)"
          colSpan={1}
          headerClassName="headerwidth"
          headerStyle={{ width: "8%" }}
        />
        <Column
          header="Branding Expense (AED)"
          colSpan={1}
          headerStyle={{ width: "8%" }}
          headerClassName="headerwidth"
        />
        <Column header="Accidents" colSpan={1} />
        <Column header="Fines (AED)" colSpan={1} />
        <Column header="Parking (AED)" colSpan={1} />
        <Column header="Salik (AED)" colSpan={1} />
        <Column header="Mileage" colSpan={1} />
        <Column header="VIP Fuel (AED)" colSpan={1} />
        {/* <Column  colSpan={1} /> */}
      </Row>
    </ColumnGroup>
  );

  const onRowEditComplete1 = (e: any) => {
    // let objectLenght = Object.keys(e).length;
    if (e.data.Driver !== e.newData.Driver) {
      let _products2 = [...sales];
      let { newData, index } = e;

      _products2[index] = newData;

      setSales(_products2);
      toast.current.show({
        severity: "success",
        summary: "Updated Successfully",
        life: 2000,
      });
    }
  };

  // const clearbtn = () => {
  //     setFilterData('');
  // };
  const SearchOnchange = (e: any) => {
    setFilterData(e.target.value);
    //onsearchdata();
    // setFilterData(e.target.value);

    // var fr: any = Database.filter((searchfil: any) => {
    //     if (FilterData === '') {
    //         return searchfil;
    //     } else if (searchfil.allocationdriver.toLowerCase().includes(FilterData.toLowerCase())) {
    //         return FilterData;
    //     }
    // });
    // setfilterReports(fr);
  };

  const SearchOnvehiclechange = (e: any) => {
    setfiltervehicleid(e.target.value);
  };
  const onSortChange = (event: any) => {
    const value = event.value;
    setVehiclestatuskey(value);
    //onsearchdata();
    // const value = event.value;
    // setVehiclestatuskey(value);
    // let fr = Database.filter((searchfil:any)=>{
    //     if(searchfil.VehicleStatus != null)
    //     return searchfil.VehicleStatus.toLowerCase() == value;
    //     else{
    //         return null;
    //     }
    // })
    // setfilterReports(fr);
  };

  const onChange_expiringfilter = (event: any) => {
    const value = event.value;
    setexpiringfilterkey(value);
  };

  const onChange_lastdaysfilter = (event: any) => {
    const value = event.value;
    if (value !== "custom") setLoading(true);
    setlastdaysfilterkey(value);
    setVehiclestatuskey(null);
    setexpiringfilterkey(null);
    setFilterData("");
    setfiltervehicleid("");
    setCustomDate([]);
  };
  const onChange_Customlastdaysfilter = (event: any) => {
    const containsNull = event.value.includes(null);

    if (!containsNull) setLoading(true);
    setCustomDate(event.value);
  };
  useEffect(() => {
    let daysFilter = lastdaysfilterkey;
    if (daysFilter !== null) getData();
  }, [lastdaysfilterkey, customDate]);

  useEffect(() => {
    let vs = Vehiclestatuskey;
    let txtd = FilterData;
    let txtvid = filtervehicleid;
    let expiryFilter = expiringfilterkey;

    let searchkeys: any = {};
    if (vs !== undefined && vs !== null && vs !== "-")
      searchkeys["events"] = { value: vs, condition: "vehiclestatus" };
    if (txtd !== "" && txtd !== undefined)
      searchkeys["driver"] = {
        value: txtd,
        condition: "includes",
        nestedKey: "name",
      };
    if (txtvid !== "" && txtvid !== undefined)
      searchkeys["id"] = { value: txtvid, condition: "includes" };
    if (
      expiryFilter !== undefined &&
      expiryFilter !== null &&
      expiryFilter !== "-"
    ) {
      searchkeys["expiring"] = {
        value: expiryFilter,
        condition: "expiring",
      };
    }

    let fr = Database.filter((item: any) => {
      return Object.keys(searchkeys).every((a: any) => {
        if (searchkeys[a].nestedKey) {
          const nestedValue = item[a] && item[a][searchkeys[a].nestedKey];
          if (searchkeys[a].condition === "eq") {
            return (
              nestedValue !== null &&
              searchkeys[a].value !== null &&
              nestedValue.toLowerCase() === searchkeys[a].value.toLowerCase()
            );
          }

          if (searchkeys[a].condition === "includes") {
            return (
              nestedValue !== null &&
              searchkeys[a].value !== null &&
              nestedValue
                .toLowerCase()
                .includes(searchkeys[a].value.toLowerCase())
            );
          }
        } else {
          if (searchkeys[a].condition === "eq") {
            return (
              item[a] !== null &&
              searchkeys[a].value !== null &&
              item[a] &&
              searchkeys[a] &&
              item[a].toLowerCase() === searchkeys[a].value.toLowerCase()
            );
          }
          if (searchkeys[a].condition === "vehiclestatus") {
            const today = moment().startOf("day");
            const events = item.events;
            const parsedEvents: any = events ? JSON.parse(events) : false;
            const vstatus = item.dxDriverVehicleactivityId;

            let filterUnSceduledevent: any;
            Object.keys(parsedEvents).every((eventKey) => {
              const event = parsedEvents[eventKey];
              if (
                !moment(event.date).isSame(today, "day") &&
                event.appointmentstatus === "Scheduled" &&
                vstatus !== null
              ) {
                filterUnSceduledevent = parsedEvents;
                return false;
              }
            });

            if (searchkeys[a].value === "garage") {
              return (
                parsedEvents &&
                Object.keys(parsedEvents).some((eventKey) => {
                  const event = parsedEvents[eventKey];
                  return event.appointmentstatus === "Scheduled";
                })
              );
            } else if (searchkeys[a].value === "active") {
              let result = false;

              // Check if parsedEvents is null and vstatus is not null
              if (!parsedEvents && vstatus) {
                result = true;
              } else {
                const eventKeys = Object.keys(parsedEvents);

                for (let i = 0; i < eventKeys.length; i++) {
                  const event = parsedEvents[eventKeys[i]];
                  const isScheduled = event.appointmentstatus === "Scheduled";
                  const isActive = event.appointmentstatus === "Active";

                  if (moment(event.date).isSame(today, "day") && isScheduled) {
                    result = false; // Event is scheduled for today, so set result as false

                    break;
                  } else if (isActive && vstatus !== null) {
                    result = true; // Event is active and vstatus is not null, so set result as true
                  } else if ((isScheduled || isActive) && vstatus !== null) {
                    result = true; // Event is either scheduled or active, and vstatus is not null, so set result as true
                  }
                }
              }

              return result;
            }

            if (searchkeys[a].value === "in active") {
              if (!parsedEvents && item.dxDriverVehicleactivityId == null) {
                return true;
              }
              if (parsedEvents && item.dxDriverVehicleactivityId == null) {
                return true;
              }
            }
          }

          if (searchkeys[a].condition === "includes") {
            return (
              item[a] !== null &&
              searchkeys[a].value !== null &&
              item[a] &&
              searchkeys[a] &&
              item[a].toLowerCase().includes(searchkeys[a].value.toLowerCase())
            );
          }
          if (searchkeys[a].condition === "expiring") {
            let expiringValue = item["expiring"]
              ? JSON.parse(item["expiring"])
              : {};
            let daysRange = 7;
            if (
              [
                "insurance",
                "registration",
                "warranty",
                "servicecontract",
              ].includes(searchkeys[a].value.toLowerCase())
            ) {
              daysRange = 14;
            }

            return Object.keys(expiringValue).some((b: any) => {
              //15 insurance, registration, warranty, service contract
              //7 branding, parking

              let expiryDate = new Date(expiringValue[b].expiryDate);

              let today = new Date();
              let nextWeek = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() + daysRange
              );
              // let isExpired = expiryDate <= today;

              // return b.toLowerCase() === searchkeys[a].value.toLowerCase() && (isExpired || (expiryDate >= today && expiryDate <= nextWeek));
              let pastyears = new Date(
                today.getFullYear() - 10,
                today.getMonth(),
                today.getDate()
              );
              return (
                b.toLowerCase() === searchkeys[a].value.toLowerCase() &&
                pastyears <= expiryDate &&
                expiryDate <= nextWeek
              );
            });
          }
        }

        return false;
      });
    });

    setfilterReports(fr);
  }, [Vehiclestatuskey, expiringfilterkey, FilterData, filtervehicleid]);

  //#endregion

  //#region Render Method
  return (
    <div>
      <h5>Fleet Management - Workspace</h5>
      <div className="card Workspace dropdown">
        <Toast ref={toast} />
        <div className="container-space">
          <div className="grid grid-nogutter ">
            <div className="col-4 p-fluid" style={{ textAlign: "left" }}>
              <div className="formgrid grid">
                <div className="field col">
                  <span className="p-float-label">
                    <Dropdown
                      id="ddsearchstatus"
                      value={Vehiclestatuskey}
                      options={sortOptions}
                      optionLabel="label"
                      placeholder="Filter by Vehicle Status"
                      onChange={onSortChange}
                    />
                    <label htmlFor="ddsearchstatus">Vehicle Status</label>
                  </span>
                </div>
                <div className="field col">
                  <span className="p-float-label">
                    <Dropdown
                      id="ddsearchexpiringitems"
                      value={expiringfilterkey}
                      options={Options_expiringfilter}
                      optionLabel="label"
                      placeholder="Filter by Expiring"
                      onChange={onChange_expiringfilter}
                    />
                    <label htmlFor="ddsearchexpiringitems">Expiring</label>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-2 p-fluid"></div>
            <div className="col-6 p-fluid" style={{ textAlign: "left" }}>
              <div className="formgrid grid">
                <div className="field col">
                  <span className="p-float-label">
                    <input
                      value={filtervehicleid}
                      type="text"
                      id="txtsearchvehicleid"
                      onChange={SearchOnvehiclechange}
                      placeholder="Search Vehicle id..."
                      className="p-inputtext p-component homeNews__search"
                    />
                    <label htmlFor="txtsearchvehicleid">Vehicle Id</label>
                  </span>
                </div>
                <div className="field col">
                  <span className="p-float-label">
                    <input
                      value={FilterData}
                      type="text"
                      id="txtsearchdriver"
                      onChange={SearchOnchange}
                      placeholder="Search Driver..."
                      className="p-inputtext p-component homeNews__search"
                    />
                    <label htmlFor="txtsearchdriver">Driver</label>
                  </span>
                </div>

                <div className="field col">
                  <span className="p-float-label">
                    <Dropdown
                      id="ddlastdays"
                      value={lastdaysfilterkey}
                      options={Options_lastdaysfilter}
                      optionLabel="label"
                      placeholder="Filter by days"
                      onChange={onChange_lastdaysfilter}
                    />
                    <label htmlFor="ddlastdays">Filter by days</label>
                  </span>
                </div>
                {lastdaysfilterkey === "custom" && (
                  <div className="field col">
                    <span className="p-float-label">
                      <Calendar
                        id="range"
                        className="dateFilter mr-2"
                        value={customDate}
                        dateFormat="dd/m/yy"
                        // onChange={(e: any) => {
                        //     setCustomDate(e.value);
                        // }}
                        onChange={onChange_Customlastdaysfilter}
                        selectionMode="range"
                        readOnlyInput
                        placeholder="Select date"
                      />
                      <label htmlFor="range">Custom filter</label>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container-space">
          <div className="grid grid-nogutter">
            <div className="col-12">
              <DataTable
                value={filterReports
                  .sort(function (a: any, b: any) {
                    return moment(b.updatedAt)
                      .format()
                      .localeCompare(moment(a.updatedAt).format());
                  })
                  .filter(
                    (item: any) =>
                      item._deleted === null ||
                      item._deleted === false ||
                      item.isarchived !== true
                  )}
                dataKey="id"
                onRowEditComplete={onRowEditComplete1}
                editMode="row"
                loading={loading}
                headerColumnGroup={headerGroup}
                responsiveLayout="scroll"
                className="p-datatable-gridlines"
              >
                <Column
                  field="id"
                  header="Code"
                  sortable
                  body={actionVehicleIdTemplate}
                />

                <Column
                  className="text-center"
                  field="VehicleStatus"
                  header="Code"
                  sortable
                  body={actionVehicleStatusTemplate}
                />
                {/* <Column className="text-center" field="Image" body={driverimageBodyTemplate} /> */}
                <Column
                  className="text-center"
                  field="Driver"
                  body={actionDriverTemplate}
                />

                <Column
                  className="text-center"
                  field="Expiringsoon"
                  body={actionExpiringsoonTemplate}
                />
                <Column
                  className="text-center"
                  field="Insurance Cost"
                  body={actionInsuranceTemplate}
                />
                <Column
                  className="text-center"
                  field="Registration Cost"
                  body={actionRegistrationTemplate}
                />
                <Column
                  className="text-center"
                  field="Services"
                  body={actionServicesTemplate}
                />
                <Column
                  className="text-center"
                  field="RepairsReplacements"
                  body={actionRepairsReplacementsTemplate}
                />
                <Column
                  className="text-center"
                  field="InternalInspection"
                  body={actionBrandingTemplate}
                />
                <Column
                  className="text-center"
                  field="Accidents"
                  body={actionAccidentsTemplate}
                />
                <Column
                  className="text-center"
                  field="Fines"
                  body={actionFinesTemplate}
                />
                <Column
                  className="text-center"
                  field="Parking"
                  body={actionParkingTemplate}
                />
                <Column
                  className="text-center"
                  field="Salik"
                  body={actionSalikTemplate}
                />
                <Column
                  className="text-center"
                  field="Mileage"
                  body={actionMileageTemplate}
                />
                <Column
                  className="text-center"
                  field="VIPFuel"
                  header="VIP Fuel"
                  body={actionVIPFuelTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>

        {AllocateDriverToggle ? (
          <AllocateDriverPopUp
            displayModal={true}
            data={selectedrow}
            deallocate={false}
            onclose={onMenuDialogHide}
            Origin={"workspace"}
            //  AllocateDriverDate={AllocateDriverDate}

            //  OnDriverChangeDropdown={OnDriverChangeDropdown}
            DropdownItem={DropdownItem}
            VehicleData={VehicleDriver}
            getData={getData}
            navigateurl={"/workspace/allocatedriver/allocate"}
          />
        ) : DeAllocateDriverToggle ? (
          <AllocateDriverPopUp
            deallocate={true}
            data={selectedrow}
            onclose={onMenuDialogHide}
            Origin={"workspace"}
            displayModal={true}
            //  AllocateDriverDate={AllocateDriverDate}

            //  OnDriverChangeDropdown={OnDriverChangeDropdown}
            DropdownItem={DropdownItem}
            VehicleData={VehicleDriver}
            getData={getData}
          />
        ) : (
          ""
        )}
        <div></div>
      </div>
    </div>
  );
  //#endregion
};
export default WorkSpace;
