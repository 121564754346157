import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import ListForm from '../../Components/ListForm';
import { API, graphqlOperation } from 'aws-amplify';
import UploadDocuments from '../../Components/UploadDocuments';
import { Button } from 'primereact/button';
import ListTable from '../../Components/ListTable';
import FormTemplate from '../../Components/FormTemplate';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import AmplifyServices from '../../Service/Service';
import { createDxVehicle, createDxAppointments, createDxContract, createDxVehicleActivity, deleteDxVehicle, deleteDxVehicleActivity, updateDxVehicle, updateDxContract, updateDxVehicleActivity } from '../../graphql/mutations';
import { listDxContracts, listDxVehicles, listDxVehicleActivities } from '../../graphql/queries';
import SContractviewform from '../ServiceContracts/Viewform';
import { Amplify, Storage } from 'aws-amplify';
var storeVehicleData: any = [];
var UploadDocument: any = [];
var UploadPictures: any;
var Uploadrecipts: any;
var vehicleactivityitems: any;
var listcontarctitems: any;
function CloseRenewalform() {
    let VehicleIdOption: any = [];
    const navigate = useNavigate();
    const [appointmentNeeded, setAppointmentNeeded] = useState(false);
    const [document, setDocument] = useState([]);
    const [documentpath, setdocumentpath] = useState<any>([]);
    const [vehicleID, setVehicleID] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [contract, setcontract] = useState<any>([]);
    const [vehicleactivityitem, setvehicleactivityitem] = useState({});
    const [updatedata, setupdatedata] = useState<any>([]);
    const [ScData, setScData] = useState<any>([]);
    const [Appointmentdata, setAppointmentdata] = useState<any>([]);
    const toast = useRef<any>(null);
    const [VendornameOptions, setVendornameOptions] = useState<any>([]);
    const [listFormData, setlistFormData] = useState<any>({});
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    var type = urlParams.has('type') ? urlParams.get('type') : null;
    console.log('close');
    const checkActiveIndex = useCallback(() => {
        formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
        formid = urlParams.has('id') ? urlParams.get('id') : null;

        const paths = location.pathname.split('/');
        const currentPath = paths[paths.length - 1];
        console.log(currentPath, formid);

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'allocations':
                setActiveIndex(2);
                break;

            default:
                if (formid) getvehicledata(formid);
                break;
        }
    }, [location]);
    useEffect(() => {
        checkActiveIndex();
        getcurrentvehicle();
    }, [checkActiveIndex]);
    const getcurrentvehicle = async () => {
        // AmplifyServices.getvehicleitem(formid, 'vehicle').then((resp: any) => {
        //     setvehicleitem(resp);
        // });
        await AmplifyServices.fetchSingleContract(formid)
            .then((a) => {
                console.log(a);
                setcontract(a[0]);
                //  listcontarctitems = a[0];
                //  console.log(listcontarctitems);
            })
            .catch((err) => {
                console.log(err);
            });
        //  console.log(listcontarctitems);
        AmplifyServices.filteruniqueContract(formid, type).then((resp: any) => {
            console.log(resp);
            if (resp.items && resp.items.length > 0) setvehicleactivityitem(resp.items[0]);
        });
    };
    console.log(listFormData);
    const subchangedata = (props: any) => {
        console.log(props);
    };

    const addsubData = async (props: any) => {
        console.log(props);
    };
    const getvehicledata = async (fid: any) => {
        console.log(fid);
        console.log(type);

        await AmplifyServices.filterScOnly(fid, type)
            .then((a) => {
                console.log(a);

                if (a.length > 0) {
                    setlistFormData(a[0]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        // await AmplifyServices.getOnlySContractdetails('ServiceContract').then((a) => {
        //     console.log(a);
        //     if (a.length > 0) {
        //         setlistFormData(a[0]);
        //     }
        // });
    };

    useEffect(() => {
        let cols = formColumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });
        console.log(cols);
        console.log(listFormData);
        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);
    useEffect(() => {
        console.log(vehicleID);
        // vehicleID !== '' && navigate('basic?mode=new&id=' + vehicleID + '&fleettype=vehicle#activity');
        getDocumentDetails();
        getData();
        getvendordata();
    }, [window.location.href]);

    const getData = async () => {
        setVehicles([]);
        // setDocument([]);

        await AmplifyServices.getTableDetails(listDxVehicleActivities, 'listDxVehicleActivities').then((res: any) => {
            let Fleetvehicle = res.filter((vehicle: any) => vehicle._deleted !== true);

            Fleetvehicle.map((res: any) => {
                res['label'] = res.id;
                res['value'] = res.id;
                console.log(res);
                VehicleIdOption.push(res);
            });
            setVehicles(VehicleIdOption);
        });
        // await AmplifyServices.getContractdetails(formid)
        //     .then(async (res: any) => {
        //         console.log(res);
        //         res = res.filter((item: any) => item._deleted == null || item._deleted == false);
        //         setScData(res);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        if (formid) {
            await AmplifyServices.filterScOnly(formid, type).then(async (a) => {
                console.log(a[0].vehicleid.includes('[', ']') ? a[0].vehicleid.replace('[', '').replace(']', '') : a[0].vehicleid);
                await AmplifyServices.getContractdetails(a[0].vehicleid.includes('[', ']') ? a[0].vehicleid.replace('[', '').replace(']', '') : a[0].vehicleid)
                    .then(async (res: any) => {
                        console.log(res);
                        // res['vehicleid'] = res.vehicleid.includes('[', ']') ? res.vehicleid.replace('[', '').replace(']', '').split(' ').join('').split(',') : res.vehicleid.split(',');
                        res = res.filter((item: any) => item._deleted == null || item._deleted == false);
                        console.log(res);
                        setScData(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
            await AmplifyServices.getAppointment(formid)
                .then(async (res: any) => {
                    console.log(res);
                    // res['vehicleid'] = res.vehicleid.includes('[', ']') ? res.vehicleid.replace('[', '').replace(']', '').split(' ').join('').split(',') : res.vehicleid.split(',');
                    res = res.filter((item: any) => item._deleted == null || item._deleted == false);
                    console.log(res);
                    setAppointmentdata(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const getDocumentDetails = async () => {
        await AmplifyServices.getFilteredTableDetails(listDxContracts, 'listDxContracts', formid).then((res: any) => {
            let contracts = res.filter((vehicle: any) => vehicle._deleted !== true);
            setDocument(contracts);
        });
    };

    // useEffect(() => {
    //     vehicleID !== '' && navigate('?id=' + vehicleID);
    // }, [vehicleID]);
    // const locations = window.location.href;
    // const urlParams1 = new URLSearchParams(locations.split('?')[1]);

    // var VehicleID = urlParams1.get('id');
    // var expType = urlParams1.get('exp');
    const getvendordata = async () => {
        let vendordata = await AmplifyServices.getVendorDetails();
        console.log(vendordata);
        let vendors: any[] = [];
        vendordata.forEach((v: any) => {
            let a = {
                label: v.vendorname,
                value: v.id,
                item: v
            };
            vendors.push(a);
        });
        setVendornameOptions(vendors);
    };
    let sparecolumns = {
        columns: [
            {
                name: 'place',
                title: 'Place',
                value: '',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: VendornameOptions,
                class: 'md:col-6'
            },
            {
                name: 'startdate',
                title: 'Date  & Time',
                value: '',
                fieldtype: 'datetime',
                required: false,
                disabled: false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'appointmentstatus', // pass the exact column name of db
                title: 'Appointment Status',
                value: '',
                class: 'md:col-6',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: [
                    { label: 'Scheduled', value: 'Scheduled' },
                    { label: 'Completed', value: 'Completed' }
                ]
            },
            {
                name: 'description', // pass the exact column name of db
                title: 'Description',
                value: '', //for display
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                class: 'md:col-6',
                data: []
            }
        ]
    };
    const formColumns = {
        columns: [
            //Edit Form Columns
            // {
            //     title: 'Basic Info',
            //     name: 'basicinfo',
            //     fieldtype: 'header'
            // },

            {
                name: 'vehicleid', // pass the exact column name of db
                value: '',
                title: 'Vehicle',
                fieldtype: 'dropdown',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: vehicles
            },
            {
                name: 'renewaltype', // pass the exact column name of db
                value: '',
                title: 'Renewal Type',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [
                    { label: 'Insurance', value: 'Insurance' },
                    { label: 'Registration', value: 'Registration' },
                    { label: 'Service Contract', value: 'ServiceContract' },
                    { label: 'Branding', value: 'Branding' },
                    { label: 'Warranty', value: 'Warranty' }
                ]
            },
            {
                name: 'policyno', // pass the exact column name of db
                value: '', //for display
                title: 'Policy No',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            {
                name: 'issuedate', // pass the exact column name of db
                value: '', //for display
                title: 'Issue date',
                fieldtype: 'datetime',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            {
                name: 'expirydate', // pass the exact column name of db
                value: '',
                title: 'Expiry date',
                fieldtype: 'datetime',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },

            // {
            //     name: 'documents', // pass the exact column name of db
            //     value: '',
            //     title: 'Document',
            //     fieldtype: 'fileupload',
            //     required: false,
            //     disabled: appointmentNeeded ? true : false,
            //     hidden: appointmentNeeded ? true : false,
            //     data: []
            // },
            {
                name: 'renewalstatus', // pass the exact column name of db
                value: '',
                title: 'Renewal Status',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [
                    { label: 'In Progress', value: 'In Progress' },
                    { label: 'Completed', value: 'Completed' }
                ]
            },
            {
                name: 'documents', // pass the exact column name of db
                value: '',
                title: 'Upload documents',
                fieldtype: 'fileupload',
                required: false,
                disabled: formmode == 'new' ? true : false,
                hidden: formmode == 'new' ? true : formmode == 'view' ? true : false,
                class: 'md:col-12',
                data: []
            },
            {
                name: 'picture', // pass the exact column name of db
                value: '',
                title: 'Upload Pictures',
                fieldtype: 'fileupload',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: formmode == 'new' ? true : formmode == 'view' ? true : false,
                class: 'md:col-12',
                data: []
            },
            {
                name: 'receipts', // pass the exact column name of db
                value: '',
                title: 'Upload recepits',
                fieldtype: 'fileupload',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: formmode == 'new' ? true : formmode == 'view' ? true : false,
                class: 'md:col-12',
                data: []
            },
            // {
            //     name: 'isAppointmentNeeded', // pass the exact column name of db
            //     value: '',
            //     title: 'is Appointment Needed?',
            //     fieldtype: 'switch',
            //     required: false,
            //     disabled: false,
            //     hidden: false,
            //     data: []
            // },
            //hidden fields
            {
                name: 'capacity', // pass the exact column name of db
                value: '',
                title: 'capacity',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'year', // pass the exact column name of db
                value: '',
                title: 'Year',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'departmentname', // pass the exact column name of db
                value: '',
                title: 'Department Name',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'make', // pass the exact column name of db
                value: '',
                title: 'Make',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'permittype', // pass the exact column name of db
                value: '',
                title: 'Permit Type',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'model', // pass the exact column name of db
                value: '',
                title: 'Model',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'platecode', // pass the exact column name of db
                value: '',
                title: 'Platecode',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'plateno', // pass the exact column name of db
                value: '',
                title: 'Plate No',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'status', // pass the exact column name of db
                value: '',
                title: 'Status',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'expiring', // pass the exact column name of db
                value: '',
                title: 'Expiring',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'allocationdriver', // pass the exact column name of db
                value: '',
                title: 'allocationdriver',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'events', // pass the exact column name of db
                value: '',
                title: 'events',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'VehicleStatus', // pass the exact column name of db
                value: '',
                title: 'Vehicle Status',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'form1',
                title: 'Schedule Appointment',
                fieldtype: 'form',
                required: false,
                value: '',
                hidden: formmode == 'edit' ? true : formmode == 'view' ? true : false,
                disabled: false,
                class: 'md:col-12',
                columns: sparecolumns,
                updatedata: [],
                mode: 'new',
                onchange: { subchangedata },
                submitdata: { addsubData },
                showaction: false
                // conditionshow: { name: 'eventtype', value: 'replacement' }
            }
        ],
        // fleettype: 'vehicle',
        getTable: listDxVehicleActivities,
        createTable: createDxVehicleActivity, //Pass create table name
        updateTable: updateDxVehicleActivity, //Pass update table name
        deleteTableItem: deleteDxVehicleActivity //Pass update table name
    };

    const submitForm = async (data: any) => {
        console.log(data);

        let a = formColumns.columns.filter((e: any) => {
            return e.fieldtype == 'date' || e.fieldtype == 'datetime';
        });

        let filtervehicleactivity = {
            id: {
                eq: data.id
            }
        };
        let vehicleactivity: any = await API.graphql({ query: listDxVehicleActivities, variables: { filter: filtervehicleactivity } });
        let vehicleactivitydata = vehicleactivity.data.listDxVehicleActivities.items[0];

        let filtervehicle = {
            id: {
                eq: data.id
            }
        };
        let vehicle: any = await API.graphql({ query: listDxVehicleActivities, variables: { filter: filtervehicle } });
        let vehicledata = vehicle.data.listDxVehicleActivities.items[0];

        console.log(vehicle.data.listDxVehicleActivities.items[0]._version);
        let b: any = data;
        a.map((_a: any) => {
            console.log(b[_a.name]);
            b[_a.name] = b[_a.name] ? new Date(b[_a.name]).toISOString() : null;
        });
        b['id'] = Math.random().toString(36).substring(2, 9);
        b['vehicleid'] = formid;
        //   b['documents'] = documentpath;
        Object.keys(b).forEach((key) => {
            if (b[key] == null || b[key] == '') {
                delete b[key];
            }
        });
        console.log(b);
        // let newcontract: any = await API.graphql(
        //     graphqlOperation(createDxContract, {
        //         input: b
        //     })
        // );

        // console.log(newcontract);
        console.log(vehicledata, vehicleactivitydata);
        let d: any = vehicledata;
        let e: any = vehicleactivitydata;
        console.log(d);
        let expiringvalue = d && d['expiring'] ? JSON.parse(d['expiring']) : {};
        let rt: any = {};
        rt[b['renewaltype']] = {
            issueDate: b['issuedate'],
            expiryDate: b['expirydate'],
            // documents: b['documents'],
            status: b['renewalstatus']
        };
        console.log(rt);
        console.log(expiringvalue);

        //Update Vehicle Activity Item

        e['expiring'] = expiringvalue;
        e['expiring'][b['renewaltype']] = '';
        e['expiring'][b['renewaltype']] = rt[b['renewaltype']];
        e['expiring'] = JSON.stringify(e['expiring']);

        if (Object.keys(e).includes('_lastChangedAt')) delete e['_lastChangedAt'];
        if (Object.keys(e).includes('createdAt')) delete e['createdAt'];
        if (Object.keys(e).includes('updatedAt')) delete e['updatedAt'];

        Object.keys(e).forEach((key) => {
            if (e[key] == null || e[key] == '') {
                delete e[key];
            }
        });
        console.log(e);
        // let os1: any = await API.graphql(
        //     graphqlOperation(formColumns.updateTable, {
        //         input: e
        //     })
        // );
        // console.log(os1);
        toast.current.show({
            severity: 'success',
            summary: 'Renewal Created',
            // detail: detail,
            life: 3000
        });
    };

    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';
    console.log(qs);
    const wizardItems = [
        { label: 'Basic Details', command: () => navigate('/manage/documents/item/basic' + qs) }
        // { label: 'Documents', command: () => navigate('/manage/servicecontracts/item/documents' + qs) }
    ];
    ///manage/servicecontracts/
    const changevehicledata = (props: any) => {
        setupdatedata(props);
        console.log(props);
    };

    const callchangedata = () => {
        console.log(updatedata);
        if (formid == null) addnewcontract(updatedata);
        else {
            editnewvehicle(updatedata, formid);
        }
        // setEnableUpload(true);
        // submitForm(updatedata);
        // addnewcontract(updatedata);
    };
    const addnewcontract = async (data: any) => {
        console.log(data);
        var contractid = Math.random().toString(36).substring(2, 9);
        let a = formColumns.columns.filter((e: any) => {
            return e.fieldtype == 'date' || e.fieldtype == 'datetime';
        });
        if (data.form1 && data.form1.length > 0) {
            let app: any = {
                id: contractid,
                Date: data.form1[0].startdate,
                appointmentstatus: data.form1[0].appointmentstatus,
                description: data.form1[0].description,
                eventtype: data.renewaltype,
                place: data.form1[0].place,
                vehicleid: data.vehicleid
            };
            let os = await API.graphql({
                query: createDxAppointments,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: app
                }
            });

            console.log(os);
        }

        let b: any = { expirydate: data.expirydate, issuedate: data.issuedate, policyno: data.policyno, renewalstatus: data.renewalstatus, renewaltype: data.renewaltype, vehicleid: data.vehicleid };

        console.log(a);
        a.map((_a: any) => {
            console.log(b[_a.name]);
            b[_a.name] = b[_a.name] ? new Date(b[_a.name]).toISOString() : null;
        });
        b['id'] = contractid;

        b['documents'] = documentpath;
        Object.keys(b).forEach((key) => {
            if (b[key] == null || b[key] == '') {
                delete b[key];
            }
        });
        console.log(b);
        let newcontract: any = await API.graphql({
            query: createDxContract,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: b
            }
        });
        console.log(newcontract);

        AmplifyServices.getvehicleitem(data.vehicleid).then(async (resp: any) => {
            console.log(resp);
            vehicleactivityitems = resp.items[0];
            console.log(vehicleactivityitems);
            let e: any = vehicleactivityitems;
            // console.log(d);
            let expiringvalue = e && e['expiring'] ? JSON.parse(e['expiring']) : {};
            let rt: any = {};
            rt[b['renewaltype']] = {
                issueDate: b['issuedate'],
                expiryDate: b['expirydate'],
                documents: b['documents'],
                status: b['renewalstatus']
            };
            console.log(rt);

            //Update Vehicle Activity Item

            e['dxContractActivityId'] = contractid;
            e['expiring'] = expiringvalue;
            e['expiring'][b['renewaltype']] = '';
            e['expiring'][b['renewaltype']] = rt[b['renewaltype']];
            e['expiring'] = JSON.stringify(e['expiring']);

            if (Object.keys(e).includes('_lastChangedAt')) delete e['_lastChangedAt'];
            if (Object.keys(e).includes('createdAt')) delete e['createdAt'];
            if (Object.keys(e).includes('updatedAt')) delete e['updatedAt'];

            Object.keys(e).forEach((key) => {
                if (e[key] == null || e[key] == '') {
                    delete e[key];
                }
            });
            delete e['items'];
            console.log(e);
            let os1: any = await API.graphql({
                query: formColumns.updateTable,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: e
                }
            });
            console.log(os1);
            toast.current.show({
                severity: 'success',
                summary: data.renewaltype + 'added sucessfully',
                // detail: detail,
                life: 3000
            });
        });

        await AmplifyServices.getContractdetails(data.vehicleid)
            .then(async (res: any) => {
                console.log(res);
                res = res.filter((item: any) => item._deleted == null || item._deleted == false);
                setScData(res);
            })
            .catch((err) => {
                console.log(err);
            });
        navigate('/general/document');
    };
    const editnewvehicle = async (data: any, fid: string) => {
        console.log(data, fid);
        let documents: any = [];
        let pictures: any = [];
        let recipts: any = [];
        let doumentObj: any = {};
        //Array.isArray([1, 3, 5])
        let splititem = Array.isArray(data.documents);
        let splitpicture = Array.isArray(data.pictures);
        let splitrec = Array.isArray(data.receipts);
        console.log(splititem);
        if (splititem && data.documents.length > 0) {
            for (let i = 0; i < data.documents.length; i++) {
                // console.log(data.documents.length);
                doumentObj = new Object({ file: data.documents[i], type: 'documents', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.vehicleid}/documents/` });
                // data.documents[i]['path'] = `${data.renewaltype}/${new Date().getFullYear()}/${data.id}/documents/`;
                console.log(data.documents[i]);
                console.log(doumentObj);
                UploadDocument.push(doumentObj);
            }
        }
        if (splitpicture && data.picture.length > 0) {
            for (let i = 0; i < data.picture.length; i++) {
                // console.log(data.documents.length);
                doumentObj = new Object({ file: data.picture[i], type: 'picture', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.vehicleid}/picture/` });
                console.log(data.picture[i]);
                UploadDocument.push(doumentObj);
            }
        }

        if (splitrec && data.receipts.length > 0) {
            for (let i = 0; i < data.receipts.length; i++) {
                doumentObj = new Object({ file: data.receipts[i], type: 'receipts', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.vehicleid}/receipts/` });
                // console.log(data.documents.length);
                console.log(data.receipts[i]);
                UploadDocument.push(doumentObj);
            }
        }

        console.log(UploadDocument, documents);
        console.log(UploadPictures, pictures);
        console.log(Uploadrecipts, recipts);
        await Promise.all(
            UploadDocument.map(async (item: any) => {
                await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                    console.log(res.key);
                    item['fullpath'] = res.key;
                });
            })
        ).then(async (res1: any) => {
            console.log(UploadDocument);
            let Files: any = [];
            let docs: any = '';
            let picture: any = [];
            let pics: any = '';
            let receipts: any = [];
            let rec: any = '';
            UploadDocument.map((map: any) => {
                docs = map.type == 'documents' ? Files.push(map.fullpath) : '';
                pics = map.type == 'picture' ? picture.push(map.fullpath) : '';
                rec = map.type == 'receipts' ? receipts.push(map.fullpath) : '';
            });
            console.log(docs, Files, pics, picture, rec, receipts);

            if (data.form1 && Object.keys(data.form1).length > 0) {
                console.log('if');
                let app: any = {
                    id: data.id,
                    Date: data.form1[0].startdate,
                    appointmentstatus: data.form1[0].appointmentstatus,
                    description: data.form1[0].description,
                    eventtype: data.renewaltype,
                    place: data.form1[0].place,
                    vehicleid: data.vehicleid
                };
                console.log(app);
                let os = await API.graphql({
                    query: createDxAppointments,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {
                        input: app
                    }
                });

                console.log(os);
            }
            console.log(contract);

            let b: any = {
                _version: data._version,
                id: data.id,
                expirydate: data.expirydate,
                issuedate: data.issuedate,
                policyno: data.policyno,
                renewalstatus: data.renewalstatus,
                renewaltype: data.renewaltype,
                vehicleid: data.vehicleid.toString(),
                documents: contract.documents !== null ? contract.documents + ',' + Files.toString() + ',' + picture.toString() + ',' + receipts.toString() : Files.toString() + ',' + picture.toString() + ',' + receipts.toString(),
                pictures: picture.toString(),
                documents2: receipts.toString()
            };

            // let b: any = data;
            //  b['id'] = Math.random().toString(36).substring(2, 9);
            // b['vehicleid'] = data.vehicleid;
            // b['documents'] = documentpath;
            Object.keys(b).forEach((key) => {
                if (b[key] == null || b[key] == '') {
                    delete b[key];
                }
            });
            if (Object.keys(b).includes('_lastChangedAt')) delete b['_lastChangedAt'];
            if (Object.keys(b).includes('createdAt')) delete b['createdAt'];
            if (Object.keys(b).includes('updatedAt')) delete b['updatedAt'];
            console.log(b);

            let newcontract: any = await API.graphql({
                query: updateDxContract,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: b
                }
            });
            console.log(newcontract);
            console.log(vehicleactivityitem);
            await AmplifyServices.getContractdetails(data.vehicleid)
                .then(async (res: any) => {
                    console.log(res);
                    res = res.filter((item: any) => item._deleted == null || item._deleted == false);
                    setScData(res);
                })
                .catch((err) => {
                    console.log(err);
                });

            console.log('insideloop');
            let filtervehicleactivity = {
                id: {
                    eq: data.vehicleid
                }
            };
            let vehicleactivity: any = await API.graphql({ query: listDxVehicleActivities, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filtervehicleactivity } });
            let vehicleactivitydata = vehicleactivity.data.listDxVehicleActivities.items[0];

            console.log(vehicleactivitydata);

            let e: any = vehicleactivitydata;
            let expiringvalue = e && e['expiring'] ? JSON.parse(e['expiring']) : {};
            let rt: any = {};
            rt[b['renewaltype']] = {
                issueDate: b['issuedate'],
                expiryDate: b['expirydate'],
                documents: b['documents'],
                status: b['renewalstatus']
            };
            console.log(rt);
            console.log(expiringvalue);

            e['expiring'] = expiringvalue;
            e['expiring'][b['renewaltype']] = '';
            e['expiring'][b['renewaltype']] = rt[b['renewaltype']];
            e['expiring'] = JSON.stringify(e['expiring']);

            if (Object.keys(e).includes('_lastChangedAt')) delete e['_lastChangedAt'];
            if (Object.keys(e).includes('createdAt')) delete e['createdAt'];
            if (Object.keys(e).includes('updatedAt')) delete e['updatedAt'];

            Object.keys(e).forEach((key) => {
                if (e[key] == null || e[key] == '') {
                    delete e[key];
                }
            });
            console.log(e);

            let os1: any = await API.graphql({
                query: formColumns.updateTable,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: e
                }
            });
            console.log(os1);
            navigate('/general/document');
        });
    };
    const addVehicle = (props: any) => {
        console.log(props);
        // callchangedata(props, formid);
    };
    const columns = [
        { field: 'vehicleid', header: 'Vehicle ID', fieldtype: 'text', filterOption: false, filterFieldOption: 'vehicleid' },
        { field: 'renewaltype', header: 'Renewal Type', fieldtype: 'text', filterOption: false, filterFieldOption: 'renewalstatus' },
        { field: 'renewalstatus', header: 'Renewal Status', fieldtype: 'text', filterOption: false, filterFieldOption: 'renewalstatus' },
        { field: 'issuedate', header: 'Start Date', fieldtype: 'date', filterOption: false, filterFieldOption: 'issuedate' },
        { field: 'expirydate', header: 'Expire Date', fieldtype: 'date', filterOption: false, filterFieldOption: 'expirydate' }
        // { field: 'status', header: 'Status', fieldtype: 'custom', filterOption: true, filterFieldOption: 'status', template: (r: any) => <>{contractStatusBodyTemplate(r)}</> }
    ];
    const appointmentcolumns = [
        { field: 'vehicleid', header: 'Vehicle ID', fieldtype: 'text', filterOption: false, filterFieldOption: 'vehicleid' },
        { field: 'place', header: 'Place', fieldtype: 'text', filterOption: false, filterFieldOption: 'renewalstatus' },
        { field: 'description', header: 'Description', fieldtype: 'text', filterOption: false, filterFieldOption: 'renewalstatus' },
        { field: 'Date', header: 'Date', fieldtype: 'date', filterOption: false, filterFieldOption: 'issuedate' },
        { field: 'appointmentstatus', header: 'Status', fieldtype: 'text', filterOption: false, filterFieldOption: 'expirydate' }
        // { field: 'status', header: 'Status', fieldtype: 'custom', filterOption: true, filterFieldOption: 'status', template: (r: any) => <>{contractStatusBodyTemplate(r)}</> }
    ];
    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <div className="card container-card">
                        <Toast ref={toast} />
                        <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                        <Routes>
                            <Route
                                path={'/basic'}
                                element={
                                    <>
                                        <FormTemplate
                                            columns={formColumns}
                                            // Fleettype={formColumns.fleettype}
                                            layout="grid"
                                            getTableName="listDxVehicleActivities"
                                            // title={`New Contract ${vehicleID !== '' ? ' - ' : ''}`}
                                            data={listFormData}
                                            // submitForm={submitForm}
                                            onChange={changevehicledata}
                                            isAppointmentNeeded={setAppointmentNeeded}
                                            bucketname="RenewalDocuments"
                                            formName="serviceContract"
                                            getVehicleID={setVehicleID}
                                            showaction={false}
                                            submitForm={addVehicle}
                                        />
                                        {/* {formmode != 'edit' && <UploadDocuments item={updatedata} header="Upload Documents" setdocumentpath={setdocumentpath} bucketname={'RenewalDocuments'} EnableautoUpload={true} submitForm={submitForm} />} */}

                                        <div className="spd-card-footer">
                                            <Button disabled={formmode == 'view' ? true : false} onClick={callchangedata} className="mr-2">
                                                Submit
                                            </Button>
                                            <Button disabled={formmode == 'view' ? true : false} onClick={() => window.history.back()} className="p-button-danger">
                                                Cancel
                                            </Button>
                                        </div>
                                        <div></div>
                                    </>
                                }
                            />
                        </Routes>
                    </div>
                </div>

                <div className="col-12 md:col-6">
                    {formmode != 'new' && (
                        <div className="col-12 md:col-12">
                            <SContractviewform />
                        </div>
                    )}

                    <div className="col-12 md:col-12">
                        <div className="card">
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <h5>Scheduled Appointment</h5>
                                </div>
                                <div className="field col-12  md:col-12">
                                    <ListTable
                                        header="Manage Contracts"
                                        value={Appointmentdata}
                                        paginator={true}
                                        rows={2}
                                        dynamicColumns={appointmentcolumns}
                                        emptyMessage="No Documents found."
                                        responsiveLayout="scroll"
                                        className="p-datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Service Contracts"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[5, 10, 25]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-12">
                        <div className="card">
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <h5>View Previous Renewals</h5>
                                </div>
                                <div className="field col-12  md:col-12">
                                    <ListTable
                                        header="Manage Renewals"
                                        value={ScData}
                                        paginator={true}
                                        rows={5}
                                        dynamicColumns={columns}
                                        emptyMessage="No Documents found."
                                        responsiveLayout="scroll"
                                        className="p-datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Service Contracts"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[5, 10, 25]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CloseRenewalform;
