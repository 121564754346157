import { API, graphqlOperation } from "aws-amplify";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { transform } from "typescript";
import { OnCreateDxAppointmentsSubscription } from "../../API";
import { GraphQLSubscription } from '@aws-amplify/api';
import { onCreateDxAppointments } from "../../graphql/subscriptions";
import FormTemplate from "../../Components/FormTemplate";
import ViewTemplate from "../../Components/ViewTemplate";


import AmplifyServices from "../../Service/Service";

const AttachWorkflow = () => {
    const toast = useRef<any>(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [workflowdata, setworkflowdata] = useState<any>([]);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;

    useEffect(() => {
        getdata();
    }, []);

    const getdata = async () => {
        await AmplifyServices.getApprovalConfigs()
            .then(async (res: any) => {
                console.log(res);
                let a: { label: any; value: any; }[] = [];
                res.forEach((r: any) => {
                    let b = {
                        label: r.title,
                        value: r.id
                    };
                    a.push(b);
                })
                setworkflowdata(a);
                // setapprovaldata(res);
            })
            .catch((err: any) => console.log(err));
    };

    const applicationdata = [
        {
            value: 'servicecontract',
            label: 'Service Contract'
        },
        {
            value: 'replacement',
            label: 'Replacement'
        },
        {
            value: 'other',
            label: 'Other'
        }
    ]

    const formcolumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'name',
                title: 'Name',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-6'

            },
            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },
            {
                name: 'workflow',
                title: 'Workflow',
                value: '',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                //data: selectedmakeDropdown !== null ? vehicleModel.filter((res: any) => res.label.toLowerCase().includes(selectedmakeDropdown.toLowerCase())) : []
                data: workflowdata,
                class: 'md:col-6'
            },
            {
                name: 'application',
                title: 'Application',
                value: '',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                //data: selectedmakeDropdown !== null ? vehicleModel.filter((res: any) => res.label.toLowerCase().includes(selectedmakeDropdown.toLowerCase())) : []
                data: applicationdata,
                class: 'md:col-6'
            },
            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },

            {
                name: 'description',
                title: 'Description',
                fieldtype: 'textarea',
                required: false,
                value: 'test description',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-12'
            }
        ],
        actions: [
            {
                title: 'Submit'
            },
            {
                title: 'Cancel'
            }
        ]
    }

    const viewdata = [
        {
            platenumber: 'a'
        },
        {
            platenumber: 'b'
        },
        {
            platenumber: 'c'
        }
    ];

    const viewcolumns = {
        columns: [
            {
                name: 'platenumber',
                title: 'Plate Number',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'model',
                title: 'MOdel Name',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'requestor',
                title: 'Requestor Name',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'priority',
                title: 'Priority',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'startdate',
                title: 'Pending for',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'approvaltype',
                title: 'Approval Type',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'description',
                title: 'Description',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'price',
                title: 'Price',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
        ]
    }

    useEffect(() => {

    }, [])

    const changedata = (props: any) => {

        console.log(props);
    };

    const addData = async (props: any) => {
        console.log(props);

        //     approvalconfig: dxApprovalConfig @belongsTo #workflow
        // name: String! #application
        // title: String #title
        // description: String #description

        let a = {
            name: props.application,
            title: props.name,
            description: props.description,
            dxApprovalConfigWorkflowId: props.workflow
        };

        let addapprovalconfiguration = await AmplifyServices.AddnewWorkflow(a)
            .then(async (res: any) => {
                console.log(res);
            })
            .catch((err: any) => console.log(err));

            // async AddnewWorkflow(data: any) {
            //     console.log(data);
            //     //   const updatedTodo = await API.graphql({ query:updateDxVehicle, variables: {input: todoDetails}});
            //     let resp: any = await API.graphql({
            //         query: createDxWorkflow,
            //         authMode: 'AMAZON_COGNITO_USER_POOLS',
            //         variables: {
            //             input: data
            //         }
            //     });
            //     return resp.data.createDxWorkflow;
            // }z

            const subs = API.graphql<GraphQLSubscription<OnCreateDxAppointmentsSubscription>>({
                query: onCreateDxAppointments,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            }).subscribe({
                next: async ({ provider, value }) => {
                    console.log({ provider, value });

                    let a = {
                        name: 'replacement',
                        title: 'sample attach',
                        description:JSON.stringify(value),
                        dxApprovalConfigWorkflowId: null
                    };
            
                    let addapprovalconfiguration = await AmplifyServices.AddnewWorkflow(a)
                        .then(async (res: any) => {
                            console.log(res);
                        })
                        .catch((err: any) => console.log(err));
                
                },
                error: (error) => console.warn(error)
            })

            

            // const sub = await API.graphql(
            //     graphqlOperation(onCreateDxAppointments)
            //   ).subscribe({
            //     next: ({ provider, value }) => console.log({ provider, value }),
            //     error: (error) => console.warn(error)
            //   });


    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Add Workflow</h4>




                <div className="grid">

                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast} />


                            <FormTemplate
                                columns={formcolumns}
                                data={listFormData}
                                showaction={true}
                                mode={formmode}
                                onChange={changedata}
                                ChangeBtn={'OpenDocument'}
                                layout="grid2"
                                title="New Approval"
                                submitForm={addData}
                            />


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AttachWorkflow;