import { API } from 'aws-amplify';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createDxDocument, createDxVehicleSpares, updateDxAllocations, updateDxAppointments, updateDxVehicleActivity } from '../../graphql/mutations';
import { listDxAllocations } from '../../graphql/queries';
import { Storage } from 'aws-amplify';
import { Toast } from 'primereact/toast';
import FormTemplate from '../../Components/FormTemplate';
import { TabMenu } from 'primereact/tabmenu';
import Appointmentviewform from './Viewform';
import ListTable from '../../Components/ListTable';
import dxService from '../../Services/dxService';
import awsExports from '../../aws-exports';
import { getdriveroptions, getEventtypeoptions, getuserinfoprofile, getVehicleoptions, getvendoroptions, isotolocaledatetime, localetoisodateendtime, localetoisodatetime } from '../../utils/helper';
import AppointmentExpense from './AppointmentExpense';
import { AuthContext } from '../../AuthContext';
import './Appointments.css';

var Appointmenttabledata: any = [];
var Vehicletabledata: any = [];
let sparesitem: any = [];

let selectedVendorPlaceName: string = '';
let totalCostAmount: number = 0;

const NewAppointment = () => {
    const { dispatch } = useContext(AuthContext);

    const [errorMessage, setErrorMessage] = useState('');
    const [DateTime, setDateTime] = useState<any>('');
    const [vehicleid, setvehicleid] = useState<any>('');
    const [currentitem, setcurrentitem] = useState<any>({});
    const [Vehicleactivity, setVehicleactivity] = useState<any>({});
    const [vehicleIDOptions, setVehicleIDOptions] = useState<any>([]);
    const [DrivernameOptions, setDrivernameOptions] = useState<any>([]);
    const [VendornameOptions, setVendornameOptions] = useState<any>([]);
    const [serviceTypeOptions, setServiceTypeOptions] = useState<any>([]);
    const [accidentTypeOptions, setAccidentTypeOptions] = useState<any>([]);
    const [sparesNameOptions, setSparesNameOptions] = useState<any>([]);
    const [locationNameOptions, setLocationNameOptions] = useState<any>([]);
    const [jField, setjField] = useState<any>([]);
    const [checked, setChecked] = useState(false);
    const [selectedVendorPlace, setSelectedVendorPlace] = useState<any>('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [expenseData, setExpenseData] = useState([]);
    const [conditioncost, setconditioncost] = useState(500);
    const [EmailContent, setEmailContent] = useState<any>([]);
    const [type, setType] = useState<any>('');
    const [vid, setVID] = useState<any>('');
    const [selectedDate, setSelectedDate] = useState<any>('');
    const navigate = useNavigate();
    const toast = useRef<any>(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [approvalitems, setapprovalitems] = useState<any>([]);
    const location1 = useLocation();
    const urlParams = new URLSearchParams(location1.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : '';
    var VehicleID = urlParams.has('vid') ? urlParams.get('vid') : '';
    var EventType = urlParams.has('type') ? urlParams.get('type')?.toString() : '';
    var formID = urlParams.has('id') ? urlParams.get('id') : '';
    var parent = urlParams.has('parent') ? urlParams.get('parent') : '';

    const approvalcolumns = [
        //{ field: 'id', header: 'ID', fieldtype: 'text', filterOption: false, filterFieldOption: 'id' },
        { field: 'title', header: 'Title', fieldtype: 'text', filterOption: false, filterFieldOption: 'title' },
        { field: 'status', header: 'Status', fieldtype: 'text', filterOption: false, filterFieldOption: 'status' },
        { field: 'id', header: 'Action', fieldtype: 'linkbutton', path: '#/manage/approvals/approve?id=', filterOption: false, filterFieldOption: 'id', text: 'Act Now' }
    ];

    const subchangedata = (props: any) => {};
    const addsubData = async (props: any) => {};

    //#region  Callback variables and methods
    const checkActiveIndex = useCallback(() => {
        formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
        formID = urlParams.has('id') ? urlParams.get('id') : null;
        const paths = location1.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'expense':
                setActiveIndex(2);
                break;

            case 'approvals':
                setActiveIndex(3);
                getApprovals();
                break;
        }
    }, [location1]);
    //#endregion

    //#region OnLoad methods
    // Calls method during the component load
    useEffect(() => {
        //set id and service type default value
        setlistFormData({
            eventtype: EventType === 'Branding Expense' ? 'Branding Expense' : EventType,
            vehicleid: VehicleID
        });
        getVehicleoptions().then((resp) => setVehicleIDOptions(resp));
        getEventtypeoptions(['Event', 'Accident', 'Parts', 'Location']).then((resp) => {
            setServiceTypeOptions(resp['Event']);
            setAccidentTypeOptions(resp['Accident']);
            setSparesNameOptions(resp['Parts']);
            setLocationNameOptions(resp['Location']);
        });
        getdriveroptions().then((resp) => {
            setDrivernameOptions(resp);
        });
        getvendoroptions().then((resp: any) => {
            let garagetype = resp.filter((item: any) => item.vtype.toLowerCase() === 'garage');
            setVendornameOptions(garagetype);
        });
        setTimeout(() => {
            creatediv();
        }, 3000);
    }, []);
    //create div for displaying total amount and approval form
    const creatediv = () => {
        // totalCostAmount = 0;
        const expBtn: any = document.getElementById('addexpensebtn');
        if (expBtn) {
            expBtn.style.display = 'flex';
            expBtn.style.alignItems = 'center';
            const newDiv = document.createElement('div');
            newDiv.className = 'm-0 p-message p-message-success mb-2 mr-2';
            newDiv.id = 'totalamountid';
            newDiv.style.display = 'none';
            expBtn.appendChild(newDiv);
            const approverdiv = document.createElement('div');
            approverdiv.className = 'm-0 p-message p-message-error mb-2';
            approverdiv.id = 'approverdiv';
            approverdiv.style.display = 'none';
            expBtn.appendChild(approverdiv);
        }
    };
    //#endregion

    //#region State change mthods

    useEffect(() => {
        if (type !== '') {
            let appitem: any = {};
            appitem['eventtype'] = type;
            getassociatedworkflows(appitem).then((resp) => {
                let wf = resp && resp.length > 0 ? resp[0] : null;
                if (wf) {
                    let firstworkflowstepname = wf.wfield1;
                    //Get All Steps
                    let steps = getallsteps(wf);
                    let firstwfstep = steps.filter((s: any) => s.name === firstworkflowstepname);
                    let currentwfstep = null;
                    if (currentitem != null) currentwfstep = steps.filter((s: any) => s.name === currentitem.wfstatus);
                    else currentwfstep = firstwfstep;

                    let approvalfilters = steps.filter((s: any) => {
                        return s.field1 === 'Approval';
                    });
                    // let wfstep;
                    // if (currentitem && currentitem.wfstatus && currentitem.wfstatus !== '') {
                    //     wfstep = getwfstep2(currentitem, steps);
                    // } else {
                    //     wfstep = getwfstep(wf, steps);
                    // }
                    // if (wfstep != null) {
                    //     //let wfstep = getwfstep(wf, steps);
                    //     //let wfstep = getwfstep2(appointmentitem, steps);

                    //     //Get Step Action type
                    //     let stepactiontype = wfstep.field1;

                    //     //Create Approval

                    // }
                }
            });
        }
    }, [type != '']);
    //Runs during the tab chage
    //Calls method during the change in checkActiveIndex property / Tab change in the form
    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);

    //calculate the total amount of expenses if the page is in edit mode
    useEffect(() => {
        let totalTax = 0;

        const calculateTotals = () => {
            if (expenseData.length > 0) {
                const totalSpan: any = document.getElementById('totalamountid');
                const approverdiv: any = document.getElementById('approverdiv');
                if (totalSpan !== null) {
                    expenseData.forEach((item: any) => {
                        const taxRate = item.tax ? item.tax : 0; // Extract tax rate from jfield property
                        const amount = item.totalamount ? Number(item.totalamount) : 0; // Extract amount
                        const tax: any = amount * (taxRate / 100); // Calculate tax as a percentage of amount
                        totalTax += tax; // Add tax to total tax
                    });
                    const totalAmount = expenseData.reduce((acc: any, item: any) => acc + Number(item.totalamount), 0); // Sum up the total amount
                    const totalAmountWithTax = totalAmount + totalTax;
                    totalCostAmount = totalAmountWithTax;
                    if (totalSpan && totalAmountWithTax !== 0) {
                        totalSpan.style.display = 'block';
                        // approverdiv.style.display = 'block';
                        totalSpan.textContent = 'Total: AED ' + totalAmountWithTax;
                        // approverdiv.textContent = 'Approval: AED ' + totalAmountWithTax;
                    }
                } else {
                    setTimeout(calculateTotals, 500); // Try again in 500 milliseconds
                }
            }
        };

        formmode === 'edit' && calculateTotals();
    }, [expenseData]);

    useEffect(() => {
        getdata();
    }, [vid]);

    //Get driver name If the Vehicle is allocated,
    useEffect(() => {
        let filter = {
            vehicleid: {
                eq: vehicleid
            },
            fromdate: {
                le: selectedDate ? localetoisodatetime(selectedDate) : null
            },
            or: [
                {
                    handoverdate: {
                        ge: selectedDate ? localetoisodateendtime(selectedDate) : null
                    }
                },
                {
                    handoverdate: {
                        ne: ''
                    }
                }
            ]
        };

        if (selectedDate && vehicleid) {
            dxService.listitems('allocation', '*', filter, true, 100000).then((res) => {
                res.items = res.items.sort((a: { fromdate: any }, b: { fromdate: string | number | Date }) => {
                    const dateA = new Date(a.fromdate);
                    const dateB = new Date(b.fromdate);
                    return dateB.getTime() - dateA.getTime();
                });

                if (res.items.length > 0) {
                    const filteredItems = res.items.filter((item: { handoverdate: string | number | Date; fromdate: string | number | Date }) => {
                        if (item.handoverdate) {
                            const fromDate = new Date(item.fromdate).toDateString();
                            const handoverDate = new Date(item.handoverdate).toDateString();
                            const selectedDateObj = new Date(selectedDate).toDateString();

                            return selectedDateObj === fromDate && selectedDateObj === handoverDate;
                        } else {
                            return false;
                        }
                    });
                    // Filtered items are available
                    const selectedItem = filteredItems.length > 0 ? filteredItems[0] : res.items[0];

                    setlistFormData({
                        ...listFormData,
                        driver: selectedItem.driver.id,
                        vehicleid: vehicleid,
                        Date: selectedDate,
                        eventtype: type
                    });
                } else {
                    // No filtered items available
                    setlistFormData({
                        ...listFormData,
                        vehicleid: vehicleid,
                        Date: selectedDate,
                        driver: null,
                        eventtype: type
                    });
                }
            });
        }
    }, [selectedDate, vehicleid]);

    //Calls methods during the change in 'checked' property
    useEffect(() => {
        if (checked === true) {
            getAllocationtabledetails();
            // ScheduledDriver();
        }

        if (formID !== '' && formID !== null) {
            getAppointment();
        }
        //getDriverdetailsAndVendordetailsData();
    }, [checked]);
    // get the vehicle activities
    const getdata = async () => {
        let data = await dxService.getitembyid('vehicleactivity', VehicleID !== '' ? VehicleID : vid, '*');

        if (data) {
            Vehicletabledata = data;
        }
        data ? setVehicleactivity(data) : setVehicleactivity(null);
    };

    //Get the allocated detail of the selected vehicle
    const getAllocationtabledetails = async () => {
        let filter = {
            vehicleid: {
                eq: `${VehicleID !== '' ? VehicleID : vid}` // filter priority = 1
            },
            allocatestatus: {
                eq: 'Allocated'
            }
        };

        let o: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });

        if (o.data.listDxAllocations.items.length > 0) {
            let sortdata = o.data.listDxAllocations.items.sort(function (a: any, b: any) {
                return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
            })[0];

            if ([sortdata].length > 0) {
                let vehicle = {
                    id: sortdata.id,
                    vehicleid: VehicleID !== '' ? VehicleID : vid,
                    // drivername:drivername,
                    allocatestatus: 'DeAllocated',
                    // date:allocateddate
                    _version: sortdata._version
                };
                // await API.graphql({
                //     query: updateDxAllocations,
                //     authMode: 'AMAZON_COGNITO_USER_POOLS',
                //     variables: {
                //         input: vehicle
                //     }
                // });

                await dxService.updateitem('allocation', vehicle);
            }
        }
    };
    //#endregion

    //#region Query database

    //get approvals
    const getApprovals = async () => {
        let filter = {
            resourceid: {
                eq: formID
            }
        };
        let approvalresponse = await dxService.listitems('approval', '*', filter);
        setapprovalitems(approvalresponse);
    };

    //get current appointment information
    const getAppointment = async (nextToken?: any) => {
        let ac = `id auditby userprofiles vehicleid spares eventtype Date place dxDriverAppointmentsId invoiceno mileage _version description cost  appointmentstatus`;

        let appointmentitem: any = formID && (await dxService.getitembyid('appointment', formID, '*'));

        Appointmenttabledata = appointmentitem;
        let variable = {
            appointmentid: {
                eq: appointmentitem.id
            }
        };
        let sparesdata = await dxService.listitems('vehiclespare', '*', variable, true, 1000, nextToken);

        sparesitem = [
            ...sparesitem,
            ...sparesdata.items
                .filter((del: any) => del._deleted !== true)
                .map((spare: any) => ({
                    id: spare.id,
                    vehicleid: spare.vehicleid,
                    driverid: spare.driverid,
                    supplier: spare.supplier,
                    invoiceno: spare.invoiceno,
                    partname: spare.partname ? JSON.parse(spare.partname)[0] : '',
                    quantity: spare.quantity,
                    description: spare.description,
                    amount: spare.amount,
                    totalamount: `${parseFloat(spare.quantity) * parseFloat(spare.amount)}`,
                    dxAppointmentsSparesId: spare.dxAppointmentsSparesId,
                    status: spare.status,
                    approvedby: spare.approvedby,
                    tax: spare.jfield ? JSON.parse(spare.jfield).tax : null,
                    unit: spare.jfield ? JSON.parse(spare.jfield).unit : null,
                    _version: spare._version
                }))
        ];

        const nexttoken = sparesdata.nextToken;
        if (nexttoken) {
            await getAppointment(nexttoken);
        }
        let uniqueArray = sparesitem.reduce((acc: any, obj: any) => (acc.find((item: any) => item.id === obj.id) ? acc : [...acc, obj]), []);
        setExpenseData(uniqueArray);

        if (Appointmenttabledata !== null) setcurrentitem(appointmentitem);

        // getVehicletabledata();

        if (appointmentitem != null) {
            setcurrentitem(appointmentitem);
            let appitem = appointmentitem;
            appitem.jfield && setjField(JSON.parse(appitem.jfield));

            setlistFormData({
                id: appitem.id,
                auditby: JSON.parse(appitem?.userprofiles)?.email,
                // auditby: appitem.auditby,
                vehicleid: appitem.vehicleid,
                dxVehicleAppointmentsId: appitem.vehicleid,
                eventtype: appitem.eventtype,
                description: appitem.description,
                place: appitem.place,
                driver: appitem.dxDriverAppointmentsId,
                invoiceno: appitem.invoiceno,
                mileage: appitem.mileage,
                Date: isotolocaledatetime(appitem.Date), //new Date(appitem.Date).toISOString(),
                cost: appitem.cost,
                documents: appitem.documents,
                createdAt: isotolocaledatetime(appitem.createdAt),
                updatedAt: isotolocaledatetime(appitem.updatedAt),
                pictures: appitem.pictures,
                accidenthappenedat: appitem.accidenthappenedat,
                accidenttype: appitem.accidenttype,
                otherlocation: appitem.jfield && JSON.parse(appitem.jfield).otherlocation,
                _version: appitem._version,
                forms1: { ...sparesitem }
            });

            setChecked(appitem.wanttoallocatedriver);
            setDateTime(isotolocaledatetime(appitem.Date));
        }
    };

    //#endregion

    //#region Form columns
    // form columns for appointments
    let sparecolumns = {
        columns: [
            {
                name: 'supplier',
                title: 'Vendor',
                fieldtype: 'dropdown',
                required: false,
                value: '',
                hidden: true,
                disabled: false,
                data: VendornameOptions,
                class: 'md:col-3'
            },
            {
                name: 'partname',
                title: 'Category',
                fieldtype: 'dropdown',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: sparesNameOptions,
                class: 'md:col-3'
            },
            {
                name: 'description',
                title: 'Description',
                fieldtype: 'text',
                required: false,
                value: '',
                hidden: false,
                disabled: false,
                data: sparesNameOptions,
                class: 'md:col-6'
            },
            {
                name: 'quantity',
                title: 'Quantity',
                fieldtype: 'number',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-3'
            },
            {
                name: 'unit',
                title: 'Unit',
                fieldtype: 'dropdown',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [
                    {
                        label: 'Pcs',
                        value: 'Pcs'
                    },
                    {
                        label: 'Gal',
                        value: 'Gal'
                    },
                    {
                        label: 'Ltr',
                        value: 'Ltr'
                    }
                ],
                class: 'md:col-3'
            },
            {
                name: 'amount',
                title: 'Amount per unit',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-3'
            },
            {
                name: 'totalamount',
                title: 'Total Amount',
                fieldtype: 'text',
                required: false,
                value: totalCostAmount,
                hidden: true,
                disabled: false,
                data: [],
                class: 'md:col-2'
            },
            {
                name: 'jobwarranty',
                title: 'Job Warranty',
                fieldtype: 'text',
                required: false,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-3'
            },
            {
                name: 'tax',
                title: 'Tax %',
                fieldtype: 'number',
                required: true,
                value: '',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-1'
            },

            {
                name: 'remove',
                title: 'Remove',
                fieldtype: 'clear',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode !== 'view' ? false : true,
                disabled: false,
                showaction: false,
                class: 'md:col-2'
            }
        ]
    };

    const formcolumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'vehicleid',
                title: 'Vehicle',
                fieldtype: 'dropdown',
                required: true,
                value: listFormData.VehicleID ? listFormData.VehicleID : '',
                hidden: false,
                disabled: formmode === 'close' ? true : false,
                //disabled: false,
                data: vehicleIDOptions,
                class: 'md:col-4'
            },

            {
                name: 'eventtype',
                title: 'Event Type',
                value: '',
                fieldtype: 'dropdown',
                required: VehicleID === '' && formID === '' ? true : false,
                disabled: VehicleID === '' && formID === '' ? false : true,
                hidden: false,
                //data: selectedmakeDropdown !== null ? vehicleModel.filter((res: any) => res.label.toLowerCase().includes(selectedmakeDropdown.toLowerCase())) : []
                data: serviceTypeOptions,
                class: 'md:col-4'
            },
            {
                name: 'Date',
                title: formmode === 'new' && EventType !== 'Accident' && type !== 'Accident' ? 'Schedule Date' : formmode === 'new' && type === 'Accident' ? 'Accident Date' : 'Scheduled Date',
                value: '',
                fieldtype: 'datetime',
                required: true,
                disabled: false,
                hidden: false,
                data: [],
                class: 'md:col-4',
                maxdate: listFormData.eventtype === 'Repair/Replacement' || type === 'Repair/Replacement' ? new Date() : null
            },
            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },

            {
                name: 'description',
                title: 'Description',
                fieldtype: 'textarea',
                required: false,
                value: '',
                hidden: false,
                disabled: formmode === 'close' ? true : false,
                data: [],
                class: 'md:col-12'
            },
            {
                name: 'place',
                title: listFormData.eventtype === 'Branding Expense' || type === 'Branding Expense' ? 'Garage' : 'Garage',
                value: '',
                fieldtype: 'dropdown',
                required: listFormData.eventtype === 'Accident' || type === 'Accident' || type === 'Branding Expense' ? false : true,
                disabled: false,
                hidden: listFormData.eventtype === 'Accident' || type === 'Accident' ? true : false,
                data: listFormData.eventtype === 'Branding Expense' || type === 'Branding Expense' ? locationNameOptions : VendornameOptions,
                class: 'md:col-4'
            },
            {
                name: 'otherlocation',
                title: 'Location Name',
                value: '',
                fieldtype: 'text',
                required: selectedVendorPlace === 'Other' ? true : false,
                disabled: false,
                hidden: selectedVendorPlace === 'Other' ? false : true,
                data: [],
                class: 'md:col-4'
            },

            {
                name: 'driver',
                title: 'Driver',
                value: '',
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: DrivernameOptions,
                class: 'md:col-4'
            },
            {
                name: 'accidenthappenedat',
                title: 'Accident Happened At?',
                value: '',
                fieldtype: 'text',
                required: listFormData.eventtype === 'Accident' || type === 'Accident' || type === 'Accident' ? true : false,
                disabled: false,
                hidden: listFormData.eventtype === 'Accident' || type === 'Accident' || type === 'Accident' ? false : true,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'accidenttype',
                title: 'Accident Type?',
                value: '',
                fieldtype: 'dropdown',
                required: listFormData.eventtype === 'Accident' || type === 'Accident' ? true : false,
                disabled: false,
                hidden: listFormData.eventtype === 'Accident' || type === 'Accident' ? false : true,
                data: accidentTypeOptions,
                class: 'md:col-4'
            },
            {
                name: 'auditby',
                title: 'Audit By',
                value: '',
                fieldtype: 'user',
                // required: listFormData.eventtype === 'Branding Expense' || type === 'Branding Expense' ? true : false,
                required: false,
                disabled: false,
                hidden: listFormData.eventtype === 'Branding Expense' || type === 'Branding Expense' ? false : true,
                data: '',
                class: 'md:col-4'
            },
            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-3',
                hidden: listFormData.eventtype === 'Branding Expense' || listFormData.eventtype === 'Accident' || type === 'Branding Expense' ? true : false
            },
            //show below columns only on the close form and during repair/replacement selection
            {
                name: 'invoiceno',
                title: 'Invoice No',
                fieldtype: 'text',
                required: false,
                value: '',
                hidden:
                    (formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' && listFormData.eventtype === 'Accident Repair' && type !== 'Repair/Replacement' && type === 'Accident Repair') ||
                    (formmode === 'new' && listFormData.eventtype === 'Service') ||
                    // listFormData.eventtype === 'Branding Expense' ||
                    listFormData.eventtype === 'Accident' ||
                    (formmode === 'new' && type === 'Service') ||
                    (formmode === 'new' && type === 'Accident Repair') ||
                    // type === 'Branding Expense' ||
                    type === 'Accident'
                        ? true
                        : false,
                disabled: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'mileage',
                title: 'Current Mileage',
                value: '',
                fieldtype: 'text',
                required:
                    (formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' && listFormData.eventtype !== 'Accident Repair' && type !== 'Repair/Replacement' && type !== 'Branding Expense' && type !== 'Accident Repair') ||
                    (formmode === 'new' && listFormData.eventtype === 'Service') ||
                    listFormData.eventtype === 'Branding Expense' ||
                    listFormData.eventtype === 'Accident' ||
                    (formmode === 'new' && type === 'Service') ||
                    type === 'Branding Expense' ||
                    type === 'Accident'
                        ? false
                        : true,
                disabled: false,
                hidden:
                    (formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' && listFormData.eventtype !== 'Accident Repair' && type !== 'Repair/Replacement' && type !== 'Accident Repair' && type !== 'Branding Expense') ||
                    (formmode === 'new' && listFormData.eventtype === 'Service') ||
                    listFormData.eventtype === 'Branding Expense' ||
                    listFormData.eventtype === 'Accident' ||
                    (formmode === 'new' && type === 'Service') ||
                    type === 'Branding Expense' ||
                    type === 'Accident'
                        ? true
                        : false,
                data: [],
                class: 'md:col-4'
            },

            {
                name: 'cost',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                title: 'Additional Cost',
                fieldtype: 'text',
                required: false,
                value: totalCostAmount,
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },
            // {
            //     title: 'Created and Modified Info',
            //     name: 'createdinfo',
            //     fieldtype: 'header',
            //     class: 'md:col-12'
            // },

            {
                name: 'documents',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                title:
                    listFormData.eventtype === 'Branding Expense' || type === 'Branding Expense'
                        ? 'Upload Branding Expense checklist'
                        : listFormData.eventtype === 'Accident' || type === 'Accident'
                        ? 'Upload Police Report'
                        : 'Upload Service Documents',
                fieldtype: 'fileupload',
                required: false,
                value: '',
                hidden:
                    (formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' && listFormData.eventtype !== 'Accident' && listFormData.eventtype === 'Accident Repair' && type !== 'Repair/Replacement' && type === 'Accident Repair') ||
                    (formmode === 'new' && listFormData.eventtype === 'Service') ||
                    (formmode === 'new' && type === 'Service') ||
                    (formmode === 'new' && type === 'Accident Repair') ||
                    formmode === 'view'
                        ? true
                        : false,
                disabled: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'pictures',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                title: 'Upload Vehicle Pictures',
                fieldtype: 'imageupload',
                required: false,
                value: '',
                hidden:
                    (formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' && listFormData.eventtype !== 'Accident' && listFormData.eventtype === 'Accident Repair' && type !== 'Repair/Replacement' && type === 'Accident Repair') ||
                    (formmode === 'new' && listFormData.eventtype === 'Service') ||
                    (formmode === 'new' && type === 'Service') ||
                    (formmode === 'new' && type === 'Accident Repair') ||
                    formmode === 'view'
                        ? true
                        : false,
                disabled: false,
                data: [],
                class: 'md:col-4'
            },

            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },
            {
                name: 'createdAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                title: 'Created At',
                fieldtype: 'datetime',
                required: false,
                value: totalCostAmount,
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode == 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'updatedAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                title: 'Updated At',
                fieldtype: 'datetime',
                required: false,
                value: totalCostAmount,
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode === 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            },
            // {
            //     name: 'form2',
            //     title: 'Add Expense',
            //     fieldtype: 'expense',
            //     hidden:
            //         (formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' && listFormData.eventtype === 'Service' && type !== 'Repair/Replacement' && type === 'Accident Repair') ||
            //         listFormData.eventtype === 'Accident' ||
            //         (formmode === 'new' && type === 'Service') ||
            //         type === 'Accident Repair' ||
            //         formmode === 'view'
            //             ? true
            //             : false,
            //     required: false,
            //     disabled: false,
            //     columns: sparecolumns,
            //     class: 'md:col-12'
            // },

            {
                name: 'form1',
                title: 'Add Expense',
                fieldtype: 'form',
                required: false,
                value: '',
                hidden:
                    (formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' && listFormData.eventtype === 'Service' && type !== 'Repair/Replacement' && type === 'Accident Repair') ||
                    listFormData.eventtype === 'Accident' ||
                    (formmode === 'new' && type === 'Service') ||
                    type === 'Accident Repair' ||
                    formmode === 'view'
                        ? true
                        : false,
                // hidden: formmode != 'view' ? false : true,
                disabled: false,
                class: 'md:col-12',
                columns: sparecolumns,
                updatedata: expenseData,
                mode: 'new',
                onchange: { subchangedata },
                submitdata: { addsubData },
                showaction: false,
                conditionshow: {
                    name: 'eventtype',
                    value: 'Repair/Replacement | replacement | Service | Accident Repair | Branding Expense'
                }
            }
        ],
        actions: [
            {
                title: formmode === 'new' ? 'Submit' : formmode === 'edit' ? 'Save' : 'Close',
                disabled: formmode === 'view' ? true : false
            },
            {
                title: 'Cancel'
            }
        ]
    };
    //#endregion

    //#region changed data Method
    //Methods calls when ver changes happened in the form
    const changedata = (props: any) => {
        if (props) {
            if (props.vehicleid) {
                setvehicleid(props.vehicleid);
            }
            if (props.eventtype) {
                setType(props.eventtype);
            }
            if (props.vehicleid) {
                setVID(props.vehicleid);
            }
            if (props.place) {
                selectedVendorPlaceName = props.place;
                setSelectedVendorPlace(props.place);
            }
            if (props.Date) {
                setSelectedDate(props.Date);
            } else {
            }

            if (props.form1) {
                let thisappointmenttotal = 0;
                for (let key in props.form1) {
                    const item = props.form1[key];

                    if (item && item.amount && item.quantity) {
                        const amount = parseFloat(item.amount);
                        const quantity = parseFloat(item.quantity);
                        if (!isNaN(amount) && !isNaN(quantity)) {
                            const taxRate = item.tax ? parseFloat(item.tax) : 0;
                            const tax = amount * (taxRate / 100);
                            const totalValueWithTax = (amount + tax) * quantity;
                            thisappointmenttotal += totalValueWithTax;
                        }
                    }
                    // setTempTotal(thisappointmenttotal);
                }
                totalCostAmount = thisappointmenttotal;

                const totalSpan: any = document.getElementById('totalamountid');
                if (totalSpan === null) {
                    creatediv();
                } else {
                    if (totalSpan && thisappointmenttotal !== 0 && totalCostAmount !== 0) {
                        totalSpan.style.display = 'block';
                        totalSpan.textContent = 'Total: AED ' + thisappointmenttotal.toFixed(2);
                    }
                }
            }
        }

        // if (!Number.isNaN(totalCostAmount)) {
        //     setTotalAmount(totalCostAmount);
        // } else {
        // }
    };
    //#endregion

    //#region Save Data Method
    //Method calls when ever submit button is clicked
    const addData1 = async (props: any) => {
        let a = localetoisodatetime(props.Date);
    };

    const addData = async (props: any) => {
        setEmailContent(props);
        let etype = '';

        switch (props.eventtype) {
            case 'Repair/Replacement':
                etype = 'Repair/Replacement';

                break;
            case 'Service':
                etype = 'Service';
                break;
            case 'Branding Expense':
                etype = 'Branding Expense';
                break;
            case 'Accident':
                etype = 'Accident';
                break;
            case 'Branding Expense':
                etype = 'Branding Expense';
                break;
            case 'Accident Repair':
                etype = 'Accident Repair';
                break;
            default:
                etype = 'Repair/Replacement';
        }

        let repairstatus = etype === 'Repair/Replacement' && totalCostAmount > 500 ? 'Pending' : etype !== 'Repair/Replacement' && etype !== 'Branding Expense' && etype !== 'Accident' ? 'Scheduled' : 'Completed';
        //totalCostAmount > 500 ? 'Pending' : 'Completed'

        //columns to save in appointment table db
        let _appointmentNew = {
            //addservicedocumenturl
            //addpictureurl
            vehicleid: props.vehicleid,
            dxVehicleAppointmentsId: props.vehicleid,
            eventtype: etype,
            description: props.description,
            place: props.place,
            invoiceno: props.invoiceno,
            mileage: props.mileage,
            Date: localetoisodatetime(props.Date),
            auditby: props.auditby && props.auditby.profiles ? props.auditby.profiles.userid : null,
            userprofiles: props.auditby && props.auditby.profiles ? JSON.stringify(props.auditby.profiles) : null,
            accidenthappenedat: props.accidenthappenedat ? props.accidenthappenedat : null,
            accidenttype: props.accidenttype ? props.accidenttype : null,
            dxDriverAppointmentsId: props.driver,
            cost: `${totalCostAmount}`,
            jfield: JSON.stringify({ otherlocation: props.otherlocation }),
            //appointmentstatus: etype === 'Repair/Replacement'  ? 'Completed' : 'Scheduled'
            appointmentstatus: repairstatus
        };

        let _appointmentClose: any = {
            //addservicedocumenturl
            //addpictureurl
            id: formID,
            vehicleid: props.vehicleid,
            dxVehicleAppointmentsId: props.vehicleid,
            eventtype: etype,
            description: props.description,
            place: props.place,
            invoiceno: props.invoiceno,
            mileage: props.mileage,
            Date: localetoisodatetime(props.Date),
            // auditby: props.auditby.profiles ? props.auditby.profiles.userid : null,
            // userprofiles: props.auditby.profiles ? JSON.stringify(props.auditby.profiles) : null,
            accidenthappenedat: props.accidenthappenedat ? props.accidenthappenedat : null,
            accidenttype: props.accidenttype ? props.accidenttype : null,
            dxDriverAppointmentsId: props.driver,
            cost: `${totalCostAmount}`,
            appointmentstatus: totalCostAmount > 500 && (etype === 'Service' || etype === 'Accident Repair') ? 'Pending' : 'Completed',
            closeallocatedriver: true,
            jfield: JSON.stringify({ otherlocation: props.otherlocation }),
            _version: props._version
        };
        jField.otherlocation = props.otherlocation;
        let _appointmentEdit: any = {
            //addservicedocumenturl
            //addpictureurl
            id: formID,
            vehicleid: props.vehicleid,
            dxVehicleAppointmentsId: props.vehicleid,
            eventtype: etype,
            description: props.description,
            place: props.place,
            invoiceno: props.invoiceno,
            mileage: props.mileage,
            Date: localetoisodatetime(props.Date),
            // auditby: props.auditby.profiles ? props.auditby.profiles.userid : null,
            // userprofiles: props.auditby.profiles ? JSON.stringify(props.auditby.profiles) : null,
            accidenthappenedat: props.accidenthappenedat ? props.accidenthappenedat : null,
            accidenttype: props.accidenttype ? props.accidenttype : null,
            dxDriverAppointmentsId: props.driver,
            cost: `${totalCostAmount}`,
            appointmentstatus: repairstatus,
            closeallocatedriver: true,
            jfield: JSON.stringify(jField),
            _version: props._version
        };
        if (typeof props.auditby === 'object') {
            _appointmentClose['auditby'] = props.auditby && props.auditby.profiles ? props.auditby.profiles.userid : null;
            _appointmentClose['userprofiles'] = props.auditby && props.auditby.profiles ? JSON.stringify(props.auditby.profiles) : null;
        }

        // Upload documents

        // Add Spares

        let formsteps = props.form1;

        let stepitems: any = [];
        if (formsteps !== undefined) {
            let formstepobjects = Object.keys(formsteps);
            formstepobjects.forEach((ele: any) => {
                let s: any = formsteps[ele];
                //columns to save in spares table db
                if (props.eventtype === 'Service' || props.eventtype === 'Repair/Replacement' || props.eventtype === 'Branding Expense' || props.eventtype === 'Accident' || props.eventtype === 'Accident Repair') {
                    let _spareNew = {
                        vehicleid: props.vehicleid,
                        driverid: props.driver,
                        supplier: selectedVendorPlaceName,
                        invoiceno: props.invoiceno,
                        description: s.description,
                        partname: JSON.stringify([s.partname]),
                        quantity: s.quantity,
                        amount: s.amount,
                        totalamount: `${parseFloat(s.quantity) * parseFloat(s.amount)}`,
                        appointmentid: '',
                        dxAppointmentsSparesId: null,
                        status: props.eventtype === 'Repair/Replacement' || props.eventtype === 'Branding Expense' ? 'Completed' : 'Pending',
                        approvedby: '',
                        jfield: JSON.stringify({ tax: s.tax, unit: s.unit, warranty: s.jobwarranty })
                    };
                    let _spareClose = {
                        id: Math.random().toString(36).substring(2, 9),
                        vehicleid: props.vehicleid,
                        driverid: props.driver,
                        supplier: selectedVendorPlaceName,
                        invoiceno: props.invoiceno,
                        partname: JSON.stringify([s.partname]),
                        quantity: s.quantity,
                        description: s.description,
                        amount: s.amount,
                        totalamount: `${parseFloat(s.quantity) * parseFloat(s.amount)}`,
                        appointmentid: '',
                        dxAppointmentsSparesId: null,
                        status: 'Completed',
                        approvedby: '',
                        jfield: JSON.stringify({ tax: s.tax, unit: s.unit, warranty: s.jobwarranty }),
                        _version: s._version
                    };
                    let _spareEdit = {
                        id: s.id,
                        vehicleid: props.vehicleid,
                        driverid: props.driver,
                        supplier: selectedVendorPlaceName,
                        invoiceno: props.invoiceno,
                        description: s.description,
                        partname: JSON.stringify([s.partname]),
                        quantity: s.quantity,
                        amount: s.amount,
                        totalamount: `${parseFloat(s.quantity) * parseFloat(s.amount)}`,
                        appointmentid: '',
                        dxAppointmentsSparesId: null,
                        status: props.eventtype === 'Repair/Replacement' ? 'Completed' : 'Pending',
                        approvedby: '',
                        jfield: JSON.stringify({ tax: s.tax, unit: s.unit, warranty: s.jobwarranty }),
                        _version: s._version
                    };
                    stepitems.push(formmode === 'close' ? _spareClose : formmode === 'edit' ? _spareEdit : _spareNew);
                }
            });
        }

        let os: any = {};

        if (formmode === 'close') {
            await dxService
                .updateitem('appointment', _appointmentClose)
                .then((res) => {
                    os = res;
                })
                .catch((err) => {
                    console.error(err);
                    setErrorMessage('There was a problem while saving, please refresh and try again');
                });
        } else if (formmode === 'edit') {
            await dxService
                .updateitem('appointment', _appointmentEdit)
                .then((res) => {
                    os = res;
                })
                .catch((err) => {
                    console.error(err);
                    setErrorMessage('There was a problem while saving, please refresh and try again');
                });
        } else {
            await dxService
                .createitem('appointment', _appointmentNew)
                .then((res) => {
                    os = res;
                })
                .catch((err) => {
                    console.error(err);
                    setErrorMessage('There was a problem while saving, please refresh and try again');
                });
        }

        //creating new items for accident in master logs
        let masterData = {
            vehicleid: props.vehicleid,
            drivername: props.driver,
            date: localetoisodatetime(props.Date),
            Accidents: props.accidenttype ? props.accidenttype : null,
            dxDriverMasterlogsId: props.driver,
            dxVehicleMasterlogsId: props.vehicleid
        };
        if (EventType === 'Accident' || type === 'Accident') {
            let master = await dxService.createitem('masterlog', masterData, false);
            let uappointment = {
                id: os.id,
                dxMasterLogsAccidentsId: master.id,
                _version: os._version
            };
            //updating the masterlog id
            await dxService.updateitem('appointment', uappointment).then((res) => {});
        }

        let appid = os.id;
        if (os) {
            await updateVehicleactivity(os)
                .then(async () => {
                    //#region method to run of document is selected
                    //ADD DOCUMENTS

                    var Uploadedfiles: any = [];
                    var ToastMsg: any = [];
                    Uploadedfiles = props.documents ? props.documents : [];

                    if (Array.isArray(props.documents) && Uploadedfiles.length > 0) {
                        let documentNames = '';
                        for (const item of Uploadedfiles) {
                            await Storage.put('appointments/' + new Date().getFullYear() + '/' + appid + '/documents/' + item.name, item).then((res: any) => {
                                ToastMsg.push(res);
                                documentNames += res.key + ';';
                                const files = {
                                    name: res.key,
                                    // dxAllocationsPicturesId: id,
                                    s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                                    resource: _appointmentClose.eventtype,
                                    resourceid: appid,
                                    presourceid: props.vehicleid,
                                    presource: 'Appointment',
                                    field1: 'documents',
                                    field2: props.invoiceno
                                };
                                addDocToDB(files);
                            });

                            // let appupdate: any = await API.graphql({
                            //     query: updateDxAppointments,
                            //     authMode: 'AMAZON_COGNITO_USER_POOLS',
                            //     variables: {
                            //         input: appointmentdocuments
                            //     }
                            // });
                        }
                        const appointmentdocuments = {
                            id: appid,
                            documents: JSON.stringify({
                                Documents: documentNames
                            })
                        };
                        let appupdate: any = await dxService.updateitem('appointment', appointmentdocuments);
                    }
                    //Upload Pictures
                    //var StoreDocuments = await Storage.put('appointments/' + 'Pictures/' + `${appid}/` + item.name, item).then((res: any) => {

                    var Uploadedpics: any = [];
                    var ToastpicMsg: any = [];
                    Uploadedpics = props.pictures ? props.pictures : [];
                    if (Array.isArray(props.pictures) && Uploadedpics.length > 0) {
                        let pictureNames = '';
                        for (const item of Uploadedpics) {
                            await Storage.put('appointments/' + new Date().getFullYear() + '/' + appid + '/Pictures/' + item.name, item).then((res: any) => {
                                // UploadDocument.current.show({
                                //     severity: 'info',
                                //     summary: 'Success',
                                //     detail: 'Document Uploaded',
                                //     life: 2000
                                // });
                                pictureNames += res.key + ';';
                                ToastpicMsg.push(res);
                                const files = {
                                    name: res.key,
                                    // dxAllocationsPicturesId: id,
                                    s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                                    resource: _appointmentClose.eventtype,
                                    resourceid: appid,
                                    presourceid: props.vehicleid,
                                    presource: 'Appointment',
                                    field1: 'pictures',
                                    field2: props.invoiceno
                                };
                                addDocToDB(files);
                            });

                            // let apppicupdate: any = await API.graphql({
                            //     query: updateDxAppointments,
                            //     authMode: 'AMAZON_COGNITO_USER_POOLS',
                            //     variables: {
                            //         input: appointmentpics
                            //     }
                            // });
                        }
                        const appointmentpics = {
                            id: appid,
                            pictures: JSON.stringify({
                                Pictures: pictureNames
                            })
                        };
                        let apppicupdate: any = await dxService.updateitem('appointment', appointmentpics);
                    }
                    //#endregion

                    //Save the spares items on spares table
                    if (stepitems.length > 0) {
                        const missingObjects = expenseData && expenseData.filter((item: any) => !stepitems.some((obj: any) => obj.id === item.id));

                        //delete the removed item from the spares table
                        missingObjects &&
                            missingObjects.forEach(async (item: any) => {
                                let deletespares = await dxService.deleteitem('vehiclespare', item.id, item._version);
                            });
                        const promises = stepitems
                            .filter((id: any) => id.id !== undefined)
                            .map(async (si: any) => {
                                si['dxAppointmentsSparesId'] = os.id;
                                si['appointmentid'] = os.id;

                                try {
                                    const stepitem = formmode === 'edit' ? await dxService.updateitem('vehiclespare', si) : await dxService.createitem('vehiclespare', si);
                                    return stepitem;
                                } catch (error) {
                                    console.error(error);
                                    throw error;
                                }
                            });
                        const promiseupdatenewitem = stepitems
                            .filter((id: any) => id.id === undefined)
                            .map(async (si: any) => {
                                si['dxAppointmentsSparesId'] = os.id;
                                si['appointmentid'] = os.id;

                                try {
                                    const stepitem = await dxService.createitem('vehiclespare', si);
                                    return stepitem;
                                } catch (error) {
                                    console.error(error);
                                    throw error;
                                }
                            });

                        try {
                            const results = await Promise.all(promises);
                            formmode === 'edit' && stepitems.filter((id: any) => id.id === undefined).length > 0 && (await Promise.all(promiseupdatenewitem));
                            if (totalCostAmount > 500 && etype !== 'Branding Expense') {
                                let newapp = await createappointmentapproval(os);

                                if (newapp === null) navigate(parent === 'workspace' ? `/general/workspace` : '/general/appointments/all');
                            } else {
                                navigate(parent === 'workspace' ? `/general/workspace` : '/general/appointments/all');
                            }
                        } catch (error) {
                            console.error(error);
                        }

                        // Promise.all(
                        //     stepitems.forEach(async (si: any) => {
                        //         si['dxAppointmentsSparesId'] = os.id;
                        //         si['appointmentid'] = os.id;
                        //         let stepitem: any = await dxService.createitem('vehiclespare', si);

                        //         // let stepitem: any = await API.graphql({
                        //         //     // query: formmode === 'close' ? updateDxVehicleSpares : createDxVehicleSpares,
                        //         //     query: createDxVehicleSpares,
                        //         //     authMode: 'AMAZON_COGNITO_USER_POOLS',
                        //         //     variables: {
                        //         //         input: si
                        //         //     }
                        //         // });
                        //         return stepitem;
                        //     })
                        // ).then(async () => {
                        //     if (totalCostAmount > 500) {
                        //         let newapp = await createappointmentapproval(os);
                        //         if (newapp === null) navigate(parent === 'workspace' ? `/general/workspace` : '/general/appointments/all');
                        //     } else {
                        //         navigate(parent === 'workspace' ? `/general/workspace` : '/general/appointments/all');
                        //     }
                        // });
                    } else {
                        navigate(parent === 'workspace' ? `/general/workspace` : '/general/appointments/all');
                    }
                })
                .then(() => {
                    navigate(parent === 'workspace' ? `/general/workspace` : '/general/appointments/all');
                });
        }
    };
    //Method to call when user uploads files
    const addDocToDB = async (files: any) => {
        // let appointmentDocs: any = await API.graphql({
        //     query: createDxDocument,
        //     authMode: 'AMAZON_COGNITO_USER_POOLS',
        //     variables: {
        //         input: files
        //     }
        // });
        let appointmentDocs: any = await dxService.createitem('document', files);
    };
    //Update Vehicle activity item
    const updateVehicleactivity = async (appoinmentitem: any) => {
        let va: any = Vehicleactivity;

        if (Vehicleactivity) {
            var nowdate = isotolocaledatetime(new Date().toDateString());
            var appointmentdate = isotolocaledatetime(new Date(DateTime).toDateString());
            let vdetails: any = {
                id: Vehicleactivity.id,

                VehicleStatus: nowdate === appointmentdate && EventType !== 'Repair/Replacement' ? 'Garage' : 'Active',
                _version: Vehicleactivity._version,
                dxAppointmentsActivityId: appoinmentitem.id
            };
            //check the eventtype and update based on the eventtype
            if (appoinmentitem.eventtype === 'Service') {
                let repaircost = va.events == null ? 0 : JSON.parse(va.events).Repair == null ? 0 : JSON.parse(va.events).Repair.cost == null ? 0 : JSON.parse(va.events).Repair.cost;

                repaircost = repaircost + parseInt(appoinmentitem.cost);
                if (va != null) {
                    let vaevents: any = { ...va['events'] };
                    if (Vehicleactivity.events != null) {
                        vaevents = JSON.parse(Vehicleactivity.events);
                    } else {
                        vaevents['Service'] = {
                            date: '',
                            id: '',
                            allocationid: ''
                        };
                    }

                    vaevents['Service'] = {};
                    vaevents['Service']['date'] = appoinmentitem.Date;
                    vaevents['Service']['id'] = appoinmentitem.id;
                    vaevents['Service']['appointmentstatus'] = formmode === 'new' ? 'Scheduled' : 'Active';
                    // if (isallocation) {
                    //     vaevents['Service']['allocationid'] = id1;
                    // }
                    if (formmode === 'close') {
                        delete vaevents['Service']['id'];
                        let d = new Date(appoinmentitem.Date);

                        let numberOfDaysToAdd = 90;
                        let result = d.setDate(d.getDate() + numberOfDaysToAdd);

                        vaevents['Service']['date'] = result;

                        if (parseInt(appoinmentitem.cost) < 500) {
                            if (!vaevents['Repair']) {
                                vaevents['Repair'] = {};
                            }
                            vaevents['Repair']['cost'] = repaircost;
                            vaevents['Repair']['date'] = appoinmentitem.Date;
                            vaevents['Repair']['id'] = appoinmentitem.id;
                            vaevents['Repair']['appointmentstatus'] = 'Active';
                        }
                        vdetails['events'] = JSON.stringify(vaevents);
                    } else {
                        vdetails['events'] = JSON.stringify(vaevents);
                    }
                }
            } else if (appoinmentitem.eventtype === 'Repair/Replacement') {
                // var repaircost1 = JSON.parse(va.events).Repair.cost !== null ? JSON.parse(va.events).Repair.cost : 0;

                let oldrepaircost = 0;
                let repaircost = va.events == null ? 0 : JSON.parse(va.events).Repair == null ? 0 : JSON.parse(va.events).Repair.cost == null ? 0 : JSON.parse(va.events).Repair.cost;
                oldrepaircost = repaircost;
                repaircost = repaircost + parseInt(appoinmentitem.cost);

                if (va !== null) {
                    let vaevents: any = { ...va['events'] };
                    if (Vehicleactivity.events != null) {
                        vaevents = JSON.parse(Vehicleactivity.events);
                    } else {
                        vaevents['Repair'] = {
                            date: '',
                            id: '',
                            allocationid: ''
                        };
                    }

                    vaevents['Repair'] = {};
                    if (parseInt(appoinmentitem.cost) < 500) {
                        vaevents['Repair']['cost'] = repaircost;
                    } else {
                        vaevents['Repair']['cost'] = oldrepaircost;
                    }
                    //vaevents['Repair']['cost'] = repaircost;
                    vaevents['Repair']['date'] = appoinmentitem.Date;
                    vaevents['Repair']['id'] = appoinmentitem.id;
                    // vaevents['Repair']['appointmentstatus'] = formmode === 'new' ? 'Scheduled' : 'Active';
                    vaevents['Repair']['appointmentstatus'] = 'Active';
                    // if (isallocation) {
                    //     vaevents['Repair']['allocationid'] = id1;
                    // }
                    if (formmode === 'close') {
                        delete vaevents['Repair']['id'];
                        // vaevents['Repair']['date'] = new Date(appoinmentitem.Date);
                    }
                    vdetails['events'] = JSON.stringify(vaevents);
                }
            } else if (appoinmentitem.eventtype === 'Branding Expense' || appoinmentitem.eventtype === 'Branding Expense') {
                // if (va != null) {
                //     let vaevents: any = { ...va['events'] };
                //     if (Vehicleactivity.events != null) {
                //         vaevents = JSON.parse(Vehicleactivity.events);
                //     } else {
                //         vaevents['BrandingExpense'] = {
                //             date: '',
                //             id: '',
                //             allocationid: ''
                //         };
                //     }

                //     vaevents['BrandingExpense'] = {};
                //     vaevents['BrandingExpense']['date'] = appoinmentitem.Date;
                //     vaevents['BrandingExpense']['id'] = appoinmentitem.id;
                //     // vaevents['BrandingExpense']['appointmentstatus'] = formmode === 'new' ? 'Scheduled' : 'Active';
                //     vaevents['BrandingExpense']['appointmentstatus'] = formmode === 'new' ? 'Scheduled' : 'Active';
                //     //vaevents['BrandingExpense']['appointmentstatus'] = 'Active';
                //     // if (isallocation) {
                //     //     vaevents['BrandingExpense']['allocationid'] = id1;
                //     // }
                //     if (formmode === 'close') {
                //         delete vaevents['BrandingExpense']['id'];
                //         let d = new Date(appoinmentitem.Date);
                //         let numberOfDaysToAdd = 90;
                //         let result = d.setDate(d.getDate() + numberOfDaysToAdd);
                //         vaevents['BrandingExpense']['date'] = result;
                //     }

                //     // delete vaevents['BrandingExpense']['id'];
                //     vdetails['events'] = JSON.stringify(vaevents);
                // }
                let oldbrandingcost = 0;
                let brandingcost = va.events == null ? 0 : JSON.parse(va.events).BrandingExpense == null ? 0 : JSON.parse(va.events).BrandingExpense.cost == null ? 0 : JSON.parse(va.events).BrandingExpense.cost;
                oldbrandingcost = brandingcost;
                brandingcost = brandingcost + parseInt(appoinmentitem.cost);

                if (va !== null) {
                    let vaevents: any = { ...va['events'] };
                    if (Vehicleactivity.events != null) {
                        vaevents = JSON.parse(Vehicleactivity.events);
                    } else {
                        vaevents['BrandingExpense'] = {
                            date: '',
                            id: '',
                            allocationid: ''
                        };
                    }

                    vaevents['BrandingExpense'] = {};
                    // if (parseInt(appoinmentitem.cost) < 500) {
                    vaevents['BrandingExpense']['cost'] = brandingcost;
                    // } else {
                    //     vaevents['BrandingExpense']['cost'] = oldbrandingcost;
                    // }
                    //vaevents['Repair']['cost'] = repaircost;
                    vaevents['BrandingExpense']['date'] = appoinmentitem.Date;
                    vaevents['BrandingExpense']['id'] = appoinmentitem.id;
                    // vaevents['Repair']['appointmentstatus'] = formmode === 'new' ? 'Scheduled' : 'Active';
                    vaevents['BrandingExpense']['appointmentstatus'] = 'Active';
                    // if (isallocation) {
                    //     vaevents['Repair']['allocationid'] = id1;
                    // }
                    if (formmode === 'close') {
                        delete vaevents['BrandingExpense']['id'];
                        // vaevents['Repair']['date'] = new Date(appoinmentitem.Date);
                    }
                    vdetails['events'] = JSON.stringify(vaevents);
                }
            } else if (appoinmentitem.eventtype === 'Accident') {
                if (va !== null) {
                    let vaevents: any = { ...va['events'] };
                    if (Vehicleactivity.events !== null) {
                        vaevents = JSON.parse(Vehicleactivity.events);
                    } else {
                        vaevents['Accident'] = {
                            date: '',
                            id: '',
                            allocationid: ''
                        };
                    }

                    vaevents['Accident'] = {};
                    vaevents['Accident']['date'] = appoinmentitem.Date;
                    vaevents['Accident']['id'] = appoinmentitem.id;
                    vaevents['Accident']['appointmentstatus'] = 'Active';
                    // if (isallocation) {
                    //     vaevents['Accident']['allocationid'] = id1;
                    // }
                    formmode === 'close' && delete vaevents['Accident']['id'];
                    vdetails['events'] = JSON.stringify(vaevents);
                }
            } else if (appoinmentitem.eventtype === 'Accident Repair') {
                let repaircost = va.events == null ? 0 : JSON.parse(va.events).Repair == null ? 0 : JSON.parse(va.events).Repair.cost == null ? 0 : JSON.parse(va.events).Repair.cost;

                repaircost = repaircost + parseInt(appoinmentitem.cost);

                if (va !== null) {
                    let vaevents: any = { ...va['events'] };
                    if (Vehicleactivity.events !== null) {
                        vaevents = JSON.parse(Vehicleactivity.events);
                    } else {
                        vaevents['Accident Repair'] = {
                            date: '',
                            id: '',
                            allocationid: ''
                        };
                    }

                    vaevents['Accident Repair'] = {};
                    vaevents['Accident Repair']['date'] = appoinmentitem.Date;
                    vaevents['Accident Repair']['id'] = appoinmentitem.id;
                    vaevents['Accident Repair']['appointmentstatus'] = formmode === 'new' ? 'Scheduled' : 'Active';
                    if (parseInt(appoinmentitem.cost) < 500) vaevents['Repair']['cost'] = repaircost;
                    // if (isallocation) {
                    //     vaevents['Accident Repair']['allocationid'] = id1;
                    // }
                    formmode === 'close' && delete vaevents['Accident Repair']['id'];
                    vdetails['events'] = JSON.stringify(vaevents);
                }
            }

            // let o = await API.graphql({
            //     query: updateDxVehicleActivity,
            //     authMode: 'AMAZON_COGNITO_USER_POOLS',
            //     variables: {
            //         input: vdetails
            //     }
            // });
            let o = await dxService.updateitem('vehicleactivity', vdetails);

            return o;
        }
    };
    //#endregion

    //#region Appointment Approval
    const createappointmentapproval = async (appointmentitem: any) => {
        let wfs = await getassociatedworkflows(appointmentitem);
        let wf = wfs && wfs.length > 0 ? wfs[0] : null;
        if (wf) {
            //Get All Steps
            let steps = getallsteps(wf);

            //Get Current Step
            let wfstep;
            if (appointmentitem.wfstatus && appointmentitem.wfstatus !== '') {
                wfstep = getwfstep2(appointmentitem, steps);
            } else {
                wfstep = getwfstep(wf, steps);
            }
            if (wfstep != null) {
                //let wfstep = getwfstep(wf, steps);
                //let wfstep = getwfstep2(appointmentitem, steps);

                //Get Step Action type
                let stepactiontype = wfstep.field1;

                //Create Approval

                if (stepactiontype === 'Approval') {
                    let newapprovalitem = await createnewapprovalitem(wf, appointmentitem, appointmentitem, wfstep).then((newapproval: any) => {
                        console.log(newapproval);
                        console.log(appointmentitem, appointmentitem);
                        console.log(JSON.parse(appointmentitem.userinfo).owner);

                        fetch(awsExports.aws_approval_email_api, {
                            mode: 'no-cors',
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                toName: 'suba@sharepointdesigns.com',
                                fromName: 'suba@sharepointdesigns.com',
                                message: 'Alert Msg from approval',
                                id: `${appointmentitem.id}`,
                                vehicleid: `${appointmentitem.vehicleid}`,
                                Appointmenttype: `${appointmentitem.eventtype}`,
                                RequestDate: `${moment(appointmentitem.Date).format('DD MMM YYYY')}`,
                                Place: `${appointmentitem.place}`,
                                Status: `Pending`,
                                Expensecost: `AED ${appointmentitem.cost}`,
                                Mileage: `${appointmentitem.mileage}`,
                                Requestedby: `${JSON.parse(appointmentitem.userinfo).owner.displayname}`,
                                navid: `${newapproval.id}`,
                                base64Data: '',
                                date: moment(new Date()).format('MMM DD YYYY'),
                                fileName: 'TEST_FILE_NAME'
                            })
                        });
                    });
                    return newapprovalitem;
                } else if (stepactiontype === 'Update') {
                    let newappitem = await runapprovalupdate(wfstep, appointmentitem);
                    return null;
                }
            }
        }
        return null;
    };

    const runapprovalupdate = async (wfstep: any, appointmentitem: any) => {
        //Get Actions
        let updatejson = JSON.parse(JSON.parse(wfstep.acsjson));

        let _conditionjson = updatejson.condition;
        let _cfield = _conditionjson.name;
        let _cvalue = _conditionjson.value;
        let _cvaluetype = _conditionjson.valuetype;
        let _ccondition = _conditionjson.condition;
        let a = _cvaluetype === 'number' ? parseInt(_cvalue) : _cvalue;
        let b = _cvaluetype === 'number' ? parseInt(appointmentitem[_cfield]) : appointmentitem[_cfield];

        switch (_ccondition) {
            case 'geq':
                if (b >= a) {
                    let trueupdates = _conditionjson.true;
                    let _resupdatevales = trueupdates.resourceupdates;
                    let rdata: any = {
                        id: appointmentitem.id,
                        _version: appointmentitem._version
                        //duedate: ''
                    };
                    _resupdatevales.forEach((ele: any) => {
                        rdata[ele.name] = ele.value;
                    });
                    //                 let _updateresitem = await updateResoueceItem("appointments", rdata);
                    let _updateresitem = await dxService.updateitem('appointment', rdata);
                    setcurrentitem(_updateresitem);

                    return createappointmentapproval(_updateresitem);
                } else {
                    let falseupdates = _conditionjson.false;
                    let _resupdatevales = falseupdates.resourceupdates;
                    let rdata: any = {
                        id: appointmentitem.id,
                        _version: appointmentitem._version
                        //duedate: ''
                    };
                    _resupdatevales.forEach((ele: any) => {
                        rdata[ele.name] = ele.value;
                    });
                    let _updateresitem = await dxService.updateitem('appointment', rdata);
                    setcurrentitem(_updateresitem);
                    return createappointmentapproval(_updateresitem);
                }
                break;
            case 'eq':
                if (b === a) {
                }
        }
        return null;
    };

    const getassociatedworkflows = async (appointment: any) => {
        let a = appointment.eventtype;
        let b = a === 'Repair/Replacement' || 'replacement' || 'Service' || 'Accident Repair' ? 'replacement' : a;
        const variables: any = {
            name: {
                eq: b
            }
        };
        let getallworkflows = await dxService.listitems('workflow', '', variables, true);
        return getallworkflows;
    };

    const getallsteps = (wf: any) => {
        //IDENTIFY STEPS
        let steps = wf.approvalconfig.steps.items;
        return steps;
    };

    const getwfstep2 = (wf: any, steps: any) => {
        let _wfcurrentlevel = wf.wfstatus;

        //Get First Step
        let d = steps.filter((ele: any) => {
            return ele.name === _wfcurrentlevel;
        });

        if (d.length > 0) return d[0];

        return null;
    };

    const getwfstep = (wf: any, steps: any) => {
        let _wfcurrentlevel = wf.wfield1 ? wf.wfield1 : wf.approvalconfig.field2 ? wf.approvalconfig.field2 : '';

        //Get First Step
        let d = steps.filter((ele: any) => {
            return ele.name === _wfcurrentlevel;
        });

        if (d.length > 0) return d[0];

        return null;
    };

    const createnewapprovalitem = async (wf: any, resource: any, resourceitem: any, step: any) => {
        let adata = {
            dxWorkflowApprovalitemsId: wf.id,
            resource: JSON.stringify(resource),
            resourceid: resourceitem.id,
            resourceurl: 'dxAppointments',
            name: step.name,
            title: step.title,
            description: step.field4,
            level: step.name,
            status: 'Not Started',
            assignedto: step.field3,
            userinfo: ''
            //duedate: ''
        };

        let userf = await getuserinfoprofile(step.field3);
        if (userf != null) {
            adata.assignedto = userf.userid;
            adata.userinfo = JSON.stringify({
                assignedto: userf
            });
        }

        let addapprovalitem = await dxService.createitem('approval', adata);

        // let addapprovalitem = await API.graphql({
        //     query: createDxApprovals,
        //     authMode: 'AMAZON_COGNITO_USER_POOLS',
        //     variables: {
        //         input: adata
        //     }
        // });

        return addapprovalitem;
    };

    //#endregion

    let qs = '';
    let qsa = '';
    qsa = formID !== null ? 'id=' + formID : '';
    qsa += formmode !== null ? '&mode=' + formmode : '';
    qsa += VehicleID !== null ? '&vid=' + VehicleID : '';
    qsa += EventType !== null ? '&type=' + EventType : '';
    qs = qsa !== '' ? '?' + qsa : '';
    const wizardItems = [
        {
            label: 'Basic Details',
            command: () => navigate('/manage/appointments/item/basic' + qs)
        },
        // { label: 'Documents', disabled: formID != "" ? false : true ,command: () => navigate('/manage/appointments/item/documents' + qs) }
        { label: 'Documents', command: () => navigate('/manage/appointments/item/documents' + qs) },
        { label: 'Expense', command: () => navigate('/manage/appointments/item/expense' + qs) },
        { label: 'Approvals', command: () => navigate('/manage/appointments/item/approvals' + qs) }
    ];

    return (
        <div className="grid appoinment">
            <div className="col-12 md:col-12">
                <h4 className="container-header">
                    {formmode === 'new'
                        ? `New ${EventType} Appointment : ${vehicleid}`
                        : formmode === 'edit'
                        ? `Edit ${EventType} Appointment : ${vehicleid} ${currentitem.appointmentstatus}`
                        : formmode === 'close'
                        ? `Close ${EventType} Appointment : ${vehicleid} ${currentitem.appointmentstatus}`
                        : `View ${EventType} Appointment : ${vehicleid} ${currentitem.appointmentstatus}`}
                </h4>

                <div className="grid">
                    <div className={`col-12 col-md-12}`}>
                        <div className="card container-card">
                            <Toast ref={toast} />
                            <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                            <div style={{ color: 'red', fontStyle: 'italic' }}>{errorMessage}</div>
                            <Routes>
                                <Route
                                    path={'/basic'}
                                    element={
                                        <div className="grid">
                                            <div
                                                className={
                                                    //'col-12 md:col-8'
                                                    formmode === 'new' ? 'col-12 md:col-12' : 'col-12 md:col-8'
                                                }
                                            >
                                                <FormTemplate
                                                    id="newform"
                                                    columns={formcolumns}
                                                    data={listFormData}
                                                    showaction={true}
                                                    mode={formmode}
                                                    onChange={changedata}
                                                    ChangeBtn={'OpenDocument'}
                                                    layout="grid2"
                                                    title="New Approval"
                                                    submitForm={addData}
                                                    showtopbutton={true}
                                                />
                                            </div>
                                            {formmode !== 'new' && (
                                                <div className="col-12 md:col-4">
                                                    <Appointmentviewform />

                                                    <AppointmentExpense />
                                                </div>
                                            )}
                                        </div>
                                    }
                                />

                                <Route
                                    path={'/documents'}
                                    element={
                                        <>
                                            <Appointmentviewform />
                                        </>
                                    }
                                />
                                <Route
                                    path={'/expense'}
                                    element={
                                        <>
                                            <AppointmentExpense />
                                        </>
                                    }
                                />
                                <Route
                                    path={'/approvals'}
                                    element={
                                        <ListTable
                                            header="Manage Contracts"
                                            value={approvalitems}
                                            paginator={false}
                                            rows={10}
                                            dynamicColumns={approvalcolumns}
                                            emptyMessage="No approvals found."
                                            responsiveLayout="scroll"
                                            className="p-datatable-responsive"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Service Contracts"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            rowsPerPageOptions={[5, 10, 25]}
                                            //   selection={selectedProducts}
                                            multiselect={false}
                                        />
                                    }
                                />
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewAppointment;
