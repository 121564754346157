import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import AmplifyServices from '../../Service/Service';
//import AmplifyServices from '../Service/Service'
import ListTable from '../../Components/ListTable';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { Menu } from 'primereact/menu';
import { deleteDxAllocations, deleteDxAppointments } from '../../graphql/mutations';
import { Dialog } from 'primereact/dialog';
import { listDxAppointments } from '../../graphql/queries';
import { API } from 'aws-amplify';
import { InputText } from 'primereact/inputtext';
import { _listDxAppointments } from '../../dxgraphql/dxqueries';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import dxService from '../../Services/dxService';
import { convertdate, isotolocaledatetime } from '../../utils/helper';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
// var selectedItems:any
let params: any;
const ManageAllocations = (props: any) => {
    // selectedItems=props.selectedProducts
    const location = useLocation();
    const menu = useRef<any>(null);
    const [data, setData] = useState<any>([]);
    const [sdata, setsData] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);
    // setSelectedItems(props.selectedItems);
    const [Tablefilter, setTableFilters] = useState<any>('');
    const [globalFilter, setGlobalFilter] = useState<any>('');
    const urlParams = new URLSearchParams(location.search);
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    var vid = urlParams.has('vid') ? urlParams.get('vid') : null;
    useEffect(() => {
        getData(vid);
    }, []);
    const getData = async (_vehicleid: any) => {
        // setData(formatDatefield(GetResponse.data.listDxAppointments.items));
        let filter = {};
        if (_vehicleid != null) {
            filter = {
                or: [{ vehicleid: { eq: _vehicleid } }]
            };
        }
        let allocationresponse = await dxService.listitems('allocation', '*', filter, false, 1000, null);
        //setData(formatDatefield(allocationresponse));
        setData(allocationresponse.items);

        if (allocationresponse != null && allocationresponse.items.length > 0) {
            var sorteddata = allocationresponse.items
                .sort(function (a: any, b: any) {
                    return moment(b.fromdate).format().localeCompare(moment(a.fromdate).format());
                })
                .filter((del: any) => del._deleted !== true)
                .filter((name: any) => {
                    if (
                        (name.vehicleid !== null && name.vehicleid.toLowerCase().includes(globalFilter.toLowerCase())) ||
                        (name.fromdrivername !== null && name.fromdrivername.toLowerCase().includes(globalFilter.toLowerCase())) ||
                        (name.fromKM !== null && name.fromKM.toLowerCase().includes(globalFilter.toLowerCase())) ||
                        (name.handoverdrivername !== null && name.handoverdrivername.toLowerCase().includes(globalFilter.toLowerCase())) ||
                        (name.handoverKM !== null && name.handoverKM.toLowerCase().includes(globalFilter.toLowerCase()))
                    ) {
                        return name;
                    }
                });
            setsData(sorteddata);
        }
    };
    const formatDatefield = (data: any) => {
        return [...(data || [])].map((d) => {
            d.fromdate = isotolocaledatetime(d.fromdate);
            d.handoverdate = isotolocaledatetime(d.handoverdate);
            return d;
        });
    };
    const statusBodyTemplate = (e: any) => {
        if (e.appointmentstatus !== null) {
            let status = e.appointmentstatus;
            return <>{status && <span className={`customer-badge status-${status.replaceAll(' ', '')}`}>{status}</span>}</>;
        }
    };
    const fromDateBodyTemplate = (e: any) => {
        if (e.fromdate !== null) {
            let fromdate = convertdate(isotolocaledatetime(e.fromdate));

            return <>{fromdate && new Date(fromdate).toDateString()}</>;
        }
    };
    const HandoverDateBodyTemplate = (e: any) => {
        if (e.handoverdate !== null) {
            let handoverdate = isotolocaledatetime(e.handoverdate);

            return <>{handoverdate && new Date(handoverdate).toDateString()}</>;
        }
    };
    const redirectDriver = (e: any, type: any) => {
        let id = type === 'fromdriver' ? `id=${e.fromdriverID}` : `name=${e.handoverdrivername}`;
        let value = type === 'fromdriver' ? e.fromdrivername : e.handoverdrivername ? e.handoverdrivername : e.handoverdrivername;
        return <Link to={`/dashboard/driver?${id}`}>{value}</Link>;
    };
    const columns = [
        // { fieldtype:'multiselect'},

        { field: 'vehicleid', header: 'Vehicle', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'fromdrivername', header: 'From Driver', textalign: 'left', fieldtype: 'custom', filterOption: true, filterFieldOption: 'fromdrivername', template: (r: any) => <>{redirectDriver(r, 'fromdriver')} </> },
        { field: 'fromdate', header: 'From Date', fieldtype: 'custom', textalign: 'left', dataType: 'date', filterOption: true, filterFieldOption: 'fromdate', template: (r: any) => <>{fromDateBodyTemplate(r)} </> },
        { field: 'fromKM', header: 'From Km', fieldtype: 'text', textalign: 'center', filterOption: true, filterFieldOption: 'fromKM' },
        {
            field: 'handoverdrivername',
            header: 'Handover Driver',
            textalign: 'left',
            dataType: 'text',
            fieldtype: 'custom',
            showtime: false,
            filterOption: true,
            filterFieldOption: 'handoverdrivername',
            template: (r: any) => <>{redirectDriver(r, 'handoverdriver')} </>
        },
        { field: 'handoverdate', header: 'Handover Date', textalign: 'left', fieldtype: 'custom', dataType: 'date', filterOption: true, filterFieldOption: 'handoverdate', template: (r: any) => <>{HandoverDateBodyTemplate(r)}</> },
        { field: 'handoverKM', header: 'Handover Km', textalign: 'center', fieldtype: 'text', filterOption: true, filterFieldOption: 'handoverKM' },
        { field: 'distances', header: 'Mileage', fieldtype: 'number', textalign: 'left', sortOption: true, filterOption: false, headeralign: 'left', bodyalign: 'left' },
        { field: 'updatedAt', header: 'Updated At', fieldtype: 'date', textalign: 'left', filterOption: false, headeralign: 'left', bodyalign: 'left', filterFieldOption: 'updatedAt' }
    ];

    let sortedData =
        data != null && data.length > 0
            ? data
                  .sort(function (a: any, b: any) {
                      return moment(b.fromdate).format().localeCompare(moment(a.fromdate).format());
                  })
                  .filter((del: any) => del._deleted !== true)
                  .filter((name: any) => {
                      if (
                          (name.vehicleid !== null && name.vehicleid.toLowerCase().includes(globalFilter.toLowerCase())) ||
                          (name.fromdrivername !== null && name.fromdrivername.toLowerCase().includes(globalFilter.toLowerCase())) ||
                          (name.fromKM !== null && name.fromKM.toLowerCase().includes(globalFilter.toLowerCase())) ||
                          (name.handoverdrivername !== null && name.handoverdrivername.toLowerCase().includes(globalFilter.toLowerCase())) ||
                          (name.handoverKM !== null && name.handoverKM.toLowerCase().includes(globalFilter.toLowerCase()))
                      ) {
                          return name;
                      }
                  })
            : [];

    window.onclick = function (event: any) {
        if (!event.target.matches('.dropbtn')) {
            var dropdowns = document.getElementsByClassName('dropdown-content');
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    };
    const navigate = useNavigate();
    const deleteItem = async () => {
        AmplifyServices.DeleteItems(selectedItems, deleteDxAllocations).then((res) => {
            getData(null);
            hideDeleteItemsDialog();
            setSelectedItems([]);
            selectedItems.length = 0;
        });
    };
    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };

    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };

    const clearbtn = () => {
        setGlobalFilter('');
    };

    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="table-header-container">
                {/* <Button icon="pi pi-plus" label="Schedule Appointment" onClick={() => navigate('/manage/appointments/item/basic?mode=new&parent=appointments')} className="p-button-primary mr-2  newbtnhover" /> */}
                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />

                <Menu
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedItems.length == 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                params = selectedItems.length > 0 && `?mode=close&vid=${selectedItems[0].vehicleid}&type=${selectedItems[0].eventtype}&id=${selectedItems[0].id}&parent=appointments`;
                                // navigate('/manage/appointments/item/basic' + params);
                                navigate(`/manage/Allocations/items?type=edit&vid=${selectedItems[0].vehicleid}&id=${selectedItems[0].id}`);
                            }
                        },
                        {
                            disabled: selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                // deleteItem();
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            {selectedItems && selectedItems.length > 0 && <span>{selectedItems.length + ' of ' + sdata.length + ' selected'}</span>}

            <div>
                <InputText type="text" id={globalFilter} value={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
                {globalFilter.length > 0 ? (
                    <button type="button" className="contentBox__searchClearBtn" onClick={clearbtn}>
                        X
                    </button>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text companyBtn" onClick={() => deleteItem()} />
        </>
    );

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Allocations</h4>
                <div className={`datatable-editing-demo ${props.className}`}>
                    <ListTable
                        header="Manage Allocations"
                        value={sortedData}
                        paginator={true}
                        rows={100}
                        dynamicColumns={columns}
                        emptyMessage="No allocations found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Allocations"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[100, 200, 300]}
                        tablefilter={Tablefilter}
                        multiselect={true}
                        //   selectedItems={selectedProducts}
                        Commandbar={Commandbar}
                        selectedItems={setSelectedItems}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}
                    />
                    <Dialog visible={deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedItems && <span>Are you sure you want to delete the selected items?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ManageAllocations;
