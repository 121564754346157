import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ListnewForm from '../../Components/ListnewForm';
import { createDxlogMileage, createDxMasterLogs, updateDxVehicle, updateDxMasterLogs, updateDxlogMileage } from '../../graphql/mutations';
import { listDxVehicles, listDxMasterLogs, listDxlogMileages } from '../../graphql/queries';
var mileageid: any;
var masterid: any;
const MileageForm = () => {
    const [fetchVehicleId, setfetchvehicleId] = useState<any>([]);
    const navigate = useNavigate();
    const location = window.location.href;
    const urlParams = new URLSearchParams(location);
    console.log(location, urlParams);
    var type = urlParams.has('fleettype') ? urlParams.get('fleettype')?.toString() : '';
    var id = urlParams.has('id') ? urlParams.get('id') : '';
    useEffect(() => {
        getVehicleDetails();
    }, []);
    const getVehicleDetails = async () => {
        let filter = {
            id: {
                eq: `${id}` // filter priority = 1
            },
            fleettype: {
                eq: type
            }
        };
        var GetResponse: any = await API.graphql({
            query: listDxVehicles,
            variables: { filter: filter }
        });
        console.log(GetResponse.data.listDxVehicles.items[0]);
        setfetchvehicleId(GetResponse.data.listDxVehicles.items[0]);
    };
    // console.log(type, id);
    const logMileage = {
        columns: [
            //Edit Form Columns
            {
                name: 'id', // pass the exact column name of db
                value: 'id',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'id', // pass the exact column name of db
                value: 'Vehicle ID',
                fieldtype: 'text',
                required: true,
                disabled: true,
                hidden: false,
                data: []
            },
            {
                name: 'drivername', // pass the exact column name of db
                value: 'Driver',
                fieldtype: 'text',
                required: true,
                disabled: true,
                hidden: false,
                data: []
            },
            {
                name: 'FromDriverDate', // pass the exact column name of db
                value: 'Date',
                fieldtype: 'date',
                required: false,
                disabled: true,
                hidden: false,
                showcurrentdate: true,
                data: []
            },
            {
                name: 'startKM', // pass the exact column name of db
                value: 'Start KM',
                fieldtype: 'number',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'endKM', // pass the exact column name of db
                value: 'End KM',
                fieldtype: 'number',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'distance', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'VehicleStatus', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'allocationdriver', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'capacity', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'events', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'expanses', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'expiring', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'make', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'model', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'platecode', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'plateno', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'policyno', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'status', // pass the exact column name of db
                value: 'Distance',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'year', // pass the exact column name of db
                value: 'year',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'departmentname', // pass the exact column name of db
                value: 'departmentname',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            }
        ],
        fleettype: 'vehicle#activity',
        getTable: listDxVehicles
    };
    const submitForm = async (data: any) => {
        data['date'] = moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00.000Z';
        var todaydate = moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00.000Z';
        console.log(data);
        let filter = {
            vehicleid: {
                eq: `${data.id}`
            },
            drivername: {
                eq: data.drivername
            },
            date: {
                eq: todaydate
            }
        };
        var GetMileageResponse: any = await API.graphql({
            query: listDxlogMileages,
            variables: { filter: filter }
        });
        var GetMasterLogesponse: any = await API.graphql({
            query: listDxMasterLogs,
            variables: { filter: filter }
        });

        var logMileagelength = GetMileageResponse.data.listLogMileages.items.length;
        var MasterLoglength = GetMasterLogesponse.data.listMasterLogs.items.length;
        let LogMileageVersion = GetMileageResponse.data.listLogMileages.items[0];
        let masterlogVersion = GetMasterLogesponse.data.listMasterLogs.items[0];
        console.log(GetMileageResponse.data.listLogMileages.items, logMileagelength);
        console.log(GetMasterLogesponse.data.listMasterLogs.items);
        let distance: any = parseFloat(data.endKM) - parseFloat(data.startKM);
        mileageid = Math.random().toString(36).substring(2, 8);
        masterid = Math.random().toString(36).substring(2, 9);
        if (logMileagelength == 0) {
            let Mileagelog = {
                id: mileageid,
                vehicleid: data.id,
                drivername: data.drivername,
                date: data.date,
                startKM: data.startKM,
                endKM: data.endKM,
                distance: distance
            };
            let Mileage = await API.graphql(
                graphqlOperation(createDxlogMileage, {
                    input: Mileagelog
                })
            );
            console.log(Mileage);
            console.log(Mileagelog);
        } else {
            let UpdateMileageLog = {
                id: LogMileageVersion.id,
                startKM: data.startKM,
                vehicleid: data.id,
                endKM: data.endKM,
                distance: distance,
                _version: LogMileageVersion._version
            };
            let os = await API.graphql(
                graphqlOperation(updateDxlogMileage, {
                    input: UpdateMileageLog
                })
            );
            console.log(os);
        }

        if (MasterLoglength == 0) {
            let Masterlog = {
                id: masterid,
                vehicleid: data.id,
                drivername: data.drivername,
                date: data.date,

                Mileage: distance
            };
            let master = await API.graphql(
                graphqlOperation(createDxMasterLogs, {
                    input: Masterlog
                })
            );
            console.log(master);
        } else {
            let UpdateMasterlog = {
                id: masterlogVersion.id,
                Mileage: masterlogVersion.Mileage !== null && masterlogVersion.Mileage !== undefined ? parseFloat(masterlogVersion.Mileage) + distance : distance,
                vehicleid: data.id,

                _version: masterlogVersion._version
            };
            let os = await API.graphql(
                graphqlOperation(updateDxMasterLogs, {
                    input: UpdateMasterlog
                })
            );
            console.log(os);
        }

        console.log(fetchVehicleId);

        var MileageKm = fetchVehicleId.expanses !== null && JSON.parse(fetchVehicleId.expanses).Mileage !== null ? parseFloat(JSON.parse(fetchVehicleId.expanses).Mileage) + parseFloat(distance) : parseFloat(distance);

        console.log(MileageKm);

        let vehicleMileage: any = {
            id: fetchVehicleId.id,
            _version: `${fetchVehicleId._version}`,
            fleettype: fetchVehicleId.fleettype
        };

        let vaevents: any = { ...fetchVehicleId['expanses'] };
        vaevents['Mileage'] = MileageKm;
        vaevents['EndMileage'] = data.endKM;
        vehicleMileage['expanses'] = JSON.stringify(vaevents);

        let UpdateVehicleMileage = await API.graphql(
            graphqlOperation(updateDxVehicle, {
                input: vehicleMileage
            })
        );
        console.log(UpdateVehicleMileage);
        navigate('/general/workspace');
        //   if (fetchVehicleId !== null) {
        //       let vaevents: any = { ...fetchVehicleId['expanses'] };
        //       if (fetchVehicleId.events != null) {
        //           vaevents = JSON.parse(fetchVehicleId.events);
        //       } else {
        //           vaevents['Mileage'] = {
        //               TotalMileage: '',
        //               EndMileage: ''
        //           };
        //       }
        //       vaevents['Mileage'] = {};
        //       vaevents['Mileage']['TotalMileage'] = MileageKm;
        //       vaevents['Mileage']['EndMileage'] = data.endKM;
        //       vehicleMileage['expanses'] = JSON.stringify(vaevents);

        //       let UpdateVehicleMileage = await API.graphql(
        //           graphqlOperation(updateDxVehicle, {
        //               input: vehicleMileage
        //           })
        //       );
        //       console.log(UpdateVehicleMileage);
        //   }
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <ListnewForm
                    //    getVehicleID={setVehicleId}
                    //    fetchVehicleDetails={fetchVehicleDetails}
                    Fleettype={logMileage.fleettype}
                    columns={logMileage}
                    getTableName={'listDxVehicles'}
                    layout="grid"
                    title="Mileage Form"
                    submitForm={submitForm}
                />
            </div>
        </div>
    );
};

export default MileageForm;
