import React, { useEffect, useState } from 'react';

import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { API, graphqlOperation } from 'aws-amplify';
import { createDxAppointments, createDxDriver, deleteDxAppointments, updateDxAppointments } from '../../graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { DriverAddForm, CompanyForm, DepartmentForm } from './formColumns'; //columns
import ListnewForm from '../../Components/ListnewForm';
import AmplifyServices from '../../Service/Service';
import { listDxAppointments, listDxVehicles, listDxVendors } from '../../graphql/queries';

function AppointmentEditForm() {
    const [DrivernameOptions, setDrivernameOptions] = useState<any>([]);
    const [feetVehicle, setfeetVehicle] = useState<any>([]);
    const [VendorPlaceDetails, setVendorPlaceDetails] = useState<any>([]);
    const navigate = useNavigate();
    useEffect(() => {
        getDriverdetailsData();
        getVehicleTabledetailsData();
    }, []);
    const getDriverdetailsData = async () => {
        let data = await AmplifyServices.getDriverDetails().then((res: any) => {
            setDrivernameOptions(res);
        });
        // let data = await AmplifyServices.getDriverDetails().then((res)=>{
        //     return res;
        // });
    };
    const getVehicleTabledetailsData = async () => {
        let vehicle = await AmplifyServices.getTableDetails(listDxVehicles, 'listDxVehicles').then((res: any) => {
            let Fleetvehicle = res.filter((vehicle: any) => vehicle.fleettype.includes('vehicle#activity'));
            setfeetVehicle(Fleetvehicle);
        });

        let vendorplace = await AmplifyServices.getTableDetails(listDxVendors, 'listDxVendors').then((res: any) => {
            setVendorPlaceDetails(res);
        });
        // let data = await AmplifyServices.getDriverDetails().then((res)=>{
        //     return res;
        // });
    };
    const DriverNameOption: any = [];
    const drivernames = DrivernameOptions.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        DriverNameOption.push(res);
    });
    const FleetVehicleIdOption: any = [];
    const vehicleId = feetVehicle.map((res: any) => {
        res['label'] = res.id;
        res['value'] = res.id;
        FleetVehicleIdOption.push(res);
    });
    const vendorGarageLocation: any = [];
    const garagelocation = VendorPlaceDetails.map((res: any) => {
        res['label'] = res.garagelocation;
        res['value'] = res.garagelocation;

        vendorGarageLocation.push(res);
    });
    const FleetType = [
        {
            label: 'Service',
            value: 'Service'
        },
        { label: 'Repair/Replacement', value: 'Repair/Replacement' },
        { label: 'Internal Inspection', value: 'InternalInspection' }
    ];
    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                name: 'vehicleid', // pass the exact column name of db
                value: 'Vehicle ID',
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: FleetVehicleIdOption
            },
            {
                name: 'eventtype', // pass the exact column name of db
                value: 'Event Type', //for display
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: FleetType
            },
            {
                name: 'place', // pass the exact column name of db
                value: 'Place', //for display
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: vendorGarageLocation
            },
            {
                name: 'Date', // pass the exact column name of db
                value: 'Date', //for display
                fieldtype: 'datetime',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'description', // pass the exact column name of db
                value: 'Description', //for display
                fieldtype: 'text',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'drivername', // pass the exact column name of db
                value: 'Driver Name',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: DriverNameOption
            },
            {
                name: 'appointmentstatus', // pass the exact column name of db
                value: 'Appointment Status',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: [
                    { label: 'Scheduled', value: 'Scheduled' },
                    { label: 'Completed', value: 'Completed' }
                ]
            },
            {
                name: 'wanttoallocatedriver', // pass the exact column name of db
                value: 'Want To Allocate Driver',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: true,
                data: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                ]
            }
        ],
        fleettype: '',
        getTable: listDxAppointments,
        createTable: createDxAppointments, //Pass create table name
        updateTable: updateDxAppointments, //Pass update table name
        deleteTableItem: deleteDxAppointments //Pass update table name
    };

    const submitForm = async (data: any) => {
        let os = await API.graphql(
            //change createDxCompanySettings
            graphqlOperation(formColumns.updateTable, {
                input: data
            })
        );
        let navigateUrl = '/general/appointments';
        navigate(navigateUrl);
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <ListnewForm columns={formColumns} layout="horizontal" getTableName="listDxAppointments" title="Edit Appointment Form" submitForm={submitForm} />
            </div>
        </div>
    );
}

export default AppointmentEditForm;
