/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDxCompany = /* GraphQL */ `
  query GetDxCompany($id: ID!) {
    getDxCompany(id: $id) {
      id
      fleettype
      name
      description
      department
      departments {
        items {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDxCompanies = /* GraphQL */ `
  query ListDxCompanies(
    $id: ID
    $filter: ModelDxCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxCompanies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxCompanies = /* GraphQL */ `
  query SyncDxCompanies(
    $filter: ModelDxCompanyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxDepartment = /* GraphQL */ `
  query GetDxDepartment($id: ID!) {
    getDxDepartment(id: $id) {
      id
      fleettype
      name
      description
      department
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicles {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      member
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDepartmentsId
      __typename
    }
  }
`;
export const listDxDepartments = /* GraphQL */ `
  query ListDxDepartments(
    $id: ID
    $filter: ModelDxDepartmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxDepartments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxDepartments = /* GraphQL */ `
  query SyncDxDepartments(
    $filter: ModelDxDepartmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxDepartments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxGeneralConfig = /* GraphQL */ `
  query GetDxGeneralConfig($id: ID!) {
    getDxGeneralConfig(id: $id) {
      id
      name
      parentname {
        id
        name
        parentname {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        fleettype
        documents
        images
        isactive
        date
        author
        editor
        jfield
        userinfo
        childs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxGeneralConfigChildsId
        owner
        __typename
      }
      fleettype
      documents
      images
      isactive
      date
      author
      editor
      jfield
      userinfo
      childs {
        items {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxGeneralConfigChildsId
      owner
      __typename
    }
  }
`;
export const listDxGeneralConfigs = /* GraphQL */ `
  query ListDxGeneralConfigs(
    $id: ID
    $filter: ModelDxGeneralConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxGeneralConfigs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        parentname {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        fleettype
        documents
        images
        isactive
        date
        author
        editor
        jfield
        userinfo
        childs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxGeneralConfigChildsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxGeneralConfigs = /* GraphQL */ `
  query SyncDxGeneralConfigs(
    $filter: ModelDxGeneralConfigFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxGeneralConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        parentname {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        fleettype
        documents
        images
        isactive
        date
        author
        editor
        jfield
        userinfo
        childs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxGeneralConfigChildsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxDriver = /* GraphQL */ `
  query GetDxDriver($id: ID!) {
    getDxDriver(id: $id) {
      id
      fleettype
      name
      mobileno
      contactno
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedriver {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      emailid
      licenseno
      licensetype
      licensepicture
      startdate
      enddate
      jobrole
      firstname
      lastname
      uname
      isnew
      isarchived
      status
      departmentname
      businessunitname
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDriversId
      dxDepartmentDriversId
      owner
      __typename
    }
  }
`;
export const listDxDrivers = /* GraphQL */ `
  query ListDxDrivers(
    $id: ID
    $filter: ModelDxDriverFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxDrivers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxDrivers = /* GraphQL */ `
  query SyncDxDrivers(
    $filter: ModelDxDriverFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxDrivers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxVehicleActivity = /* GraphQL */ `
  query GetDxVehicleActivity($id: ID!) {
    getDxVehicleActivity(id: $id) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      nextcontracts {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      logs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      recentevents {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      expanses
      nextdates
      events
      VehicleStatus
      ImagePath
      Documents
      expiring
      isarchived
      wfstatus
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverVehicleactivityId
      dxVehicleVehicleactivityId
      dxAppointmentsActivityId
      dxContractActivityId
      owner
      __typename
    }
  }
`;
export const listDxVehicleActivities = /* GraphQL */ `
  query ListDxVehicleActivities(
    $id: ID
    $filter: ModelDxVehicleActivityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxVehicleActivities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxVehicleActivities = /* GraphQL */ `
  query SyncDxVehicleActivities(
    $filter: ModelDxVehicleActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxVehicleActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxVehicle = /* GraphQL */ `
  query GetDxVehicle($id: ID!) {
    getDxVehicle(id: $id) {
      id
      platecode
      plateno
      make
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      capacity
      departmentname
      status
      chasisno
      salikno
      salikpin
      fuelno
      vowner
      isarchived
      year
      model
      policyno
      startdate
      enddate
      company
      permittype
      allocationno
      drivername
      VehicleStatus
      ImagePath
      insuranceno
      registrationno
      scno
      scvendor
      cupdate
      jfield
      userinfo
      author
      editor
      fleettype
      allocationdriver
      expanses
      nextdates
      events
      Documents
      expiring
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDepartmentVehiclesId
      dxContractVehicleId
      owner
      __typename
    }
  }
`;
export const listDxVehicles = /* GraphQL */ `
  query ListDxVehicles(
    $id: ID
    $filter: ModelDxVehicleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxVehicles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxVehicles = /* GraphQL */ `
  query SyncDxVehicles(
    $filter: ModelDxVehicleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxVehicles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxAllocations = /* GraphQL */ `
  query GetDxAllocations($id: ID!) {
    getDxAllocations(id: $id) {
      id
      vehicleid
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      activity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      date
      drivername
      enddate
      dfleettype
      fromdriverID
      fromdrivername
      fromKM
      fromdate
      fromlocation
      driverID
      handoverdrivername
      handoverKM
      handoverdate
      handoverlocation
      distances
      reason
      allocatestatus
      pictures
      documents
      wfstatus
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAllocationsId
      dxVehicleActivityAllocationsId
      dxVehicleAllocationsId
      dxAppointmentsAllocationsId
      owner
      __typename
    }
  }
`;
export const listDxAllocations = /* GraphQL */ `
  query ListDxAllocations(
    $id: ID
    $filter: ModelDxAllocationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxAllocations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxAllocations = /* GraphQL */ `
  query SyncDxAllocations(
    $filter: ModelDxAllocationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxAllocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxPermissionsLevels = /* GraphQL */ `
  query GetDxPermissionsLevels($id: ID!) {
    getDxPermissionsLevels(id: $id) {
      id
      name
      description
      mask
      groups
      field1
      field2
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxPermissionsLevels = /* GraphQL */ `
  query ListDxPermissionsLevels(
    $id: ID
    $filter: ModelDxPermissionsLevelsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxPermissionsLevels(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        mask
        groups
        field1
        field2
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxPermissionsLevels = /* GraphQL */ `
  query SyncDxPermissionsLevels(
    $filter: ModelDxPermissionsLevelsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxPermissionsLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        mask
        groups
        field1
        field2
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxRoles = /* GraphQL */ `
  query GetDxRoles($id: ID!) {
    getDxRoles(id: $id) {
      id
      rolename
      description
      application
      permissionlevel
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      title
      create
      read
      update
      delete
      jfield
      fullcontrol
      moderator
      member
      reader
      choicetype
      fleettable
      fleetdocs
      vehicle
      vehicledocs
      driver
      driverdocs
      appointments
      author
      editor
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxRoles = /* GraphQL */ `
  query ListDxRoles(
    $id: ID
    $filter: ModelDxRolesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxRoles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        rolename
        description
        application
        permissionlevel
        userroles {
          nextToken
          __typename
        }
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxRoles = /* GraphQL */ `
  query SyncDxRoles(
    $filter: ModelDxRolesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rolename
        description
        application
        permissionlevel
        userroles {
          nextToken
          __typename
        }
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxUserRoles = /* GraphQL */ `
  query GetDxUserRoles($id: ID!) {
    getDxUserRoles(id: $id) {
      id
      username
      userrole
      usergroups
      userprofile {
        id
        userid
        provideruserid
        email
        display
        picture
        displayname
        sub
        accessgroups
        userroles {
          nextToken
          __typename
        }
        attributes
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      usergroup {
        id
        rolename
        description
        application
        permissionlevel
        userroles {
          nextToken
          __typename
        }
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxRolesUserrolesId
      dxUserProfileUserrolesId
      owner
      __typename
    }
  }
`;
export const listDxUserRoles = /* GraphQL */ `
  query ListDxUserRoles(
    $id: ID
    $filter: ModelDxUserRolesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxUserRoles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        username
        userrole
        usergroups
        userprofile {
          id
          userid
          provideruserid
          email
          display
          picture
          displayname
          sub
          accessgroups
          attributes
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        usergroup {
          id
          rolename
          description
          application
          permissionlevel
          title
          create
          read
          update
          delete
          jfield
          fullcontrol
          moderator
          member
          reader
          choicetype
          fleettable
          fleetdocs
          vehicle
          vehicledocs
          driver
          driverdocs
          appointments
          author
          editor
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxRolesUserrolesId
        dxUserProfileUserrolesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxUserRoles = /* GraphQL */ `
  query SyncDxUserRoles(
    $filter: ModelDxUserRolesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxUserRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        userrole
        usergroups
        userprofile {
          id
          userid
          provideruserid
          email
          display
          picture
          displayname
          sub
          accessgroups
          attributes
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        usergroup {
          id
          rolename
          description
          application
          permissionlevel
          title
          create
          read
          update
          delete
          jfield
          fullcontrol
          moderator
          member
          reader
          choicetype
          fleettable
          fleetdocs
          vehicle
          vehicledocs
          driver
          driverdocs
          appointments
          author
          editor
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxRolesUserrolesId
        dxUserProfileUserrolesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxUserProfile = /* GraphQL */ `
  query GetDxUserProfile($id: ID!) {
    getDxUserProfile(id: $id) {
      id
      userid
      provideruserid
      email
      display
      picture
      displayname
      sub
      accessgroups
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      attributes
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxUserProfiles = /* GraphQL */ `
  query ListDxUserProfiles(
    $id: ID
    $filter: ModelDxUserProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxUserProfiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userid
        provideruserid
        email
        display
        picture
        displayname
        sub
        accessgroups
        userroles {
          nextToken
          __typename
        }
        attributes
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxUserProfiles = /* GraphQL */ `
  query SyncDxUserProfiles(
    $filter: ModelDxUserProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxUserProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userid
        provideruserid
        email
        display
        picture
        displayname
        sub
        accessgroups
        userroles {
          nextToken
          __typename
        }
        attributes
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxAppointments = /* GraphQL */ `
  query GetDxAppointments($id: ID!) {
    getDxAppointments(id: $id) {
      id
      spares {
        items {
          id
          vehicleid
          driverid
          supplier
          invoiceno
          invoicedoc
          partname
          itemtype
          quantity
          description
          amount
          totalamount
          approvedby
          status
          appointmentid
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxAppointmentsSparesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      contract {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      eventtype
      Date
      place
      description
      drivername
      appointmentstatus
      wanttoallocatedriver
      closeallocatedriver
      issccovered
      pictures
      documents
      cost
      invoiceno
      mileage
      auditby
      userprofiles
      accidenthappenedat
      accidenttype
      wfstatus
      author
      editor
      cupdate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAppointmentsId
      dxVehicleAppointmentsId
      dxContractAppointmentsId
      dxMasterLogsAccidentsId
      owner
      __typename
    }
  }
`;
export const listDxAppointments = /* GraphQL */ `
  query ListDxAppointments(
    $id: ID
    $filter: ModelDxAppointmentsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxAppointments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxAppointments = /* GraphQL */ `
  query SyncDxAppointments(
    $filter: ModelDxAppointmentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxVendors = /* GraphQL */ `
  query GetDxVendors($id: ID!) {
    getDxVendors(id: $id) {
      id
      company
      vendorname
      contactno
      email
      vendortype
      officelocation
      garagelocation
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxVendors = /* GraphQL */ `
  query ListDxVendors(
    $id: ID
    $filter: ModelDxVendorsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxVendors(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        company
        vendorname
        contactno
        email
        vendortype
        officelocation
        garagelocation
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxVendors = /* GraphQL */ `
  query SyncDxVendors(
    $filter: ModelDxVendorsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxVendors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        company
        vendorname
        contactno
        email
        vendortype
        officelocation
        garagelocation
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxContract = /* GraphQL */ `
  query GetDxContract($id: ID!) {
    getDxContract(id: $id) {
      id
      renewaltype
      vehicle {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      renewalno
      subtype
      issuedate
      expirydate
      vehicleid
      documents
      pictures
      documents2
      renewalstatus
      isappointmentrequire
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      wfstatus
      period
      scvendor
      author
      editor
      cupdate
      jfield
      userinfo
      amount
      tax
      totalamount
      policyno
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxContracts = /* GraphQL */ `
  query ListDxContracts(
    $id: ID
    $filter: ModelDxContractFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxContracts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxContracts = /* GraphQL */ `
  query SyncDxContracts(
    $filter: ModelDxContractFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxContracts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxVehicleSpares = /* GraphQL */ `
  query GetDxVehicleSpares($id: ID!) {
    getDxVehicleSpares(id: $id) {
      id
      vehicleid
      driverid
      supplier
      invoiceno
      invoicedoc
      partname
      itemtype
      quantity
      description
      amount
      totalamount
      approvedby
      status
      appointmentid
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxAppointmentsSparesId
      owner
      __typename
    }
  }
`;
export const listDxVehicleSpares = /* GraphQL */ `
  query ListDxVehicleSpares(
    $id: ID
    $filter: ModelDxVehicleSparesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxVehicleSpares(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        driverid
        supplier
        invoiceno
        invoicedoc
        partname
        itemtype
        quantity
        description
        amount
        totalamount
        approvedby
        status
        appointmentid
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxAppointmentsSparesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxVehicleSpares = /* GraphQL */ `
  query SyncDxVehicleSpares(
    $filter: ModelDxVehicleSparesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxVehicleSpares(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vehicleid
        driverid
        supplier
        invoiceno
        invoicedoc
        partname
        itemtype
        quantity
        description
        amount
        totalamount
        approvedby
        status
        appointmentid
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxAppointmentsSparesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxshifts = /* GraphQL */ `
  query GetDxshifts($id: ID!) {
    getDxshifts(id: $id) {
      id
      shift
      drivershift {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      starttime
      endtime
      startdatetime
      enddatetime
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxshifts = /* GraphQL */ `
  query ListDxshifts(
    $id: ID
    $filter: ModelDxshiftsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxshifts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        shift
        drivershift {
          nextToken
          __typename
        }
        starttime
        endtime
        startdatetime
        enddatetime
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxshifts = /* GraphQL */ `
  query SyncDxshifts(
    $filter: ModelDxshiftsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxshifts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shift
        drivershift {
          nextToken
          __typename
        }
        starttime
        endtime
        startdatetime
        enddatetime
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxdriverShifts = /* GraphQL */ `
  query GetDxdriverShifts($id: ID!) {
    getDxdriverShifts(id: $id) {
      id
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      date
      day
      startdatetime
      enddatetime
      startKm
      endkm
      totalduration
      durationafterW
      Mileage
      MileageafterWH
      location
      shift {
        id
        shift
        drivershift {
          nextToken
          __typename
        }
        starttime
        endtime
        startdatetime
        enddatetime
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      vehicleid
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      drivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedrivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsMileagedrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagereport {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      remarks
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverDrivershiftsId
      dxAllocationsDrivershiftsId
      dxshiftsDrivershiftId
      owner
      __typename
    }
  }
`;
export const listDxdriverShifts = /* GraphQL */ `
  query ListDxdriverShifts(
    $id: ID
    $filter: ModelDxdriverShiftsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxdriverShifts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxdriverShifts = /* GraphQL */ `
  query SyncDxdriverShifts(
    $filter: ModelDxdriverShiftsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxdriverShifts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxlogReimbursement = /* GraphQL */ `
  query GetDxlogReimbursement($id: ID!) {
    getDxlogReimbursement(id: $id) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      vehicleid
      driverid
      date
      transactiontype
      invoiceno
      paidto
      Vendorname
      description
      remarks
      split
      amount
      expensetype
      isreimbursed
      approver
      reimbursedamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverLogreimbursementId
      dxVehicleLogreimbursementId
      dxMasterLogsLogreimbursementId
      owner
      __typename
    }
  }
`;
export const listDxlogReimbursements = /* GraphQL */ `
  query ListDxlogReimbursements(
    $id: ID
    $filter: ModelDxlogReimbursementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxlogReimbursements(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        vehicleid
        driverid
        date
        transactiontype
        invoiceno
        paidto
        Vendorname
        description
        remarks
        split
        amount
        expensetype
        isreimbursed
        approver
        reimbursedamount
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverLogreimbursementId
        dxVehicleLogreimbursementId
        dxMasterLogsLogreimbursementId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxlogReimbursements = /* GraphQL */ `
  query SyncDxlogReimbursements(
    $filter: ModelDxlogReimbursementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxlogReimbursements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        vehicleid
        driverid
        date
        transactiontype
        invoiceno
        paidto
        Vendorname
        description
        remarks
        split
        amount
        expensetype
        isreimbursed
        approver
        reimbursedamount
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverLogreimbursementId
        dxVehicleLogreimbursementId
        dxMasterLogsLogreimbursementId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxMasterLogs = /* GraphQL */ `
  query GetDxMasterLogs($id: ID!) {
    getDxMasterLogs(id: $id) {
      id
      vehicleid
      drivername
      date
      Accidents
      Fine
      Salik
      Mileage
      Parking
      Vipfuel
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileages {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      fines {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          source
          finenumber
          blackpoints
          reason
          location
          type
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsFinesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      salik {
        items {
          id
          transactionid
          date
          transactiondate
          tollgate
          direction
          tagnumber
          vehicleid
          driverid
          drivername
          amount
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsSalikId
          owner
          __typename
        }
        nextToken
        __typename
      }
      parkings {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          author
          editor
          reimbursement
          company
          expirydate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsParkingsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vipfuel {
        items {
          id
          date
          transactiondate
          vehicleid
          rid
          unitprice
          volume
          total
          productname
          receiptno
          odometer
          idunitcode
          stationname
          stationcode
          fleetname
          groupname
          vehiclecode
          citycode
          costcenter
          vatrate
          vatamount
          actualamount
          driverid
          drivername
          cashdescription
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsVipfuelId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accidents {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      vehicleactivity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      author
      reimbursement
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMasterlogsId
      dxVehicleActivityLogsId
      dxVehicleMasterlogsId
      dxAllocationsMasterlogsId
      owner
      __typename
    }
  }
`;
export const listDxMasterLogs = /* GraphQL */ `
  query ListDxMasterLogs(
    $id: ID
    $filter: ModelDxMasterLogsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxMasterLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxMasterLogs = /* GraphQL */ `
  query SyncDxMasterLogs(
    $filter: ModelDxMasterLogsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxMasterLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxMileageReport = /* GraphQL */ `
  query GetDxMileageReport(
    $startdate: AWSDateTime!
    $enddate: AWSDateTime!
    $driverid: String!
    $startdatetime: String!
    $enddatetime: String!
  ) {
    getDxMileageReport(
      startdate: $startdate
      enddate: $enddate
      driverid: $driverid
      startdatetime: $startdatetime
      enddatetime: $enddatetime
    ) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsMileagedrivershiftId
      owner
      __typename
    }
  }
`;
export const listDxMileageReports = /* GraphQL */ `
  query ListDxMileageReports(
    $startdate: AWSDateTime
    $enddateDriveridStartdatetimeEnddatetime: ModeldxMileageReportPrimaryCompositeKeyConditionInput
    $filter: ModelDxMileageReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxMileageReports(
      startdate: $startdate
      enddateDriveridStartdatetimeEnddatetime: $enddateDriveridStartdatetimeEnddatetime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        drivername
        date
        type
        startedtime
        endedtime
        inshifttime
        outshifttime
        totalshifttime
        transactiondate
        startKM
        endKM
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        enddate
        duration
        avgspeed
        maxspeed
        drivershift {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxdriverShiftsMileagedrivershiftId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxMileageReports = /* GraphQL */ `
  query SyncDxMileageReports(
    $filter: ModelDxMileageReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxMileageReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vehicleid
        drivername
        date
        type
        startedtime
        endedtime
        inshifttime
        outshifttime
        totalshifttime
        transactiondate
        startKM
        endKM
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        enddate
        duration
        avgspeed
        maxspeed
        drivershift {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxdriverShiftsMileagedrivershiftId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxMileage = /* GraphQL */ `
  query GetDxMileage(
    $tablename: String!
    $sortstartdatetime: AWSDateTime!
    $driverid: String!
  ) {
    getDxMileage(
      tablename: $tablename
      sortstartdatetime: $sortstartdatetime
      driverid: $driverid
    ) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      sortstartdatetime
      tablename
      enddate
      duration
      avgspeed
      maxspeed
      mileagedriver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMileagedriverId
      dxdriverShiftsMileagereportId
      owner
      __typename
    }
  }
`;
export const listDxMileages = /* GraphQL */ `
  query ListDxMileages(
    $tablename: String
    $sortstartdatetimeDriverid: ModeldxMileagePrimaryCompositeKeyConditionInput
    $filter: ModelDxMileageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxMileages(
      tablename: $tablename
      sortstartdatetimeDriverid: $sortstartdatetimeDriverid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        drivername
        date
        type
        startedtime
        endedtime
        inshifttime
        outshifttime
        totalshifttime
        transactiondate
        startKM
        endKM
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        sortstartdatetime
        tablename
        enddate
        duration
        avgspeed
        maxspeed
        mileagedriver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        drivershift {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMileagedriverId
        dxdriverShiftsMileagereportId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxMileages = /* GraphQL */ `
  query SyncDxMileages(
    $filter: ModelDxMileageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxMileages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vehicleid
        drivername
        date
        type
        startedtime
        endedtime
        inshifttime
        outshifttime
        totalshifttime
        transactiondate
        startKM
        endKM
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        sortstartdatetime
        tablename
        enddate
        duration
        avgspeed
        maxspeed
        mileagedriver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        drivershift {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMileagedriverId
        dxdriverShiftsMileagereportId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxlogMileage = /* GraphQL */ `
  query GetDxlogMileage($id: ID!) {
    getDxlogMileage(id: $id) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsDrivershiftId
      dxMasterLogsMileagesId
      owner
      __typename
    }
  }
`;
export const listDxlogMileages = /* GraphQL */ `
  query ListDxlogMileages(
    $id: ID
    $filter: ModelDxlogMileageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxlogMileages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vehicleid
        drivername
        date
        type
        startedtime
        endedtime
        inshifttime
        outshifttime
        totalshifttime
        transactiondate
        startKM
        endKM
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        enddate
        duration
        avgspeed
        maxspeed
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        drivershift {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxdriverShiftsDrivershiftId
        dxMasterLogsMileagesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxlogMileages = /* GraphQL */ `
  query SyncDxlogMileages(
    $filter: ModelDxlogMileageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxlogMileages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vehicleid
        drivername
        date
        type
        startedtime
        endedtime
        inshifttime
        outshifttime
        totalshifttime
        transactiondate
        startKM
        endKM
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        enddate
        duration
        avgspeed
        maxspeed
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        drivershift {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxdriverShiftsDrivershiftId
        dxMasterLogsMileagesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxLogFines = /* GraphQL */ `
  query GetDxLogFines($id: ID!) {
    getDxLogFines(id: $id) {
      id
      date
      transactiondate
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      source
      finenumber
      blackpoints
      reason
      location
      type
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsFinesId
      owner
      __typename
    }
  }
`;
export const listDxLogFines = /* GraphQL */ `
  query ListDxLogFines(
    $id: ID
    $filter: ModelDxLogFinesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxLogFines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        transactiondate
        vehicleid
        driverid
        drivername
        amount
        innovationfee
        totalamount
        source
        finenumber
        blackpoints
        reason
        location
        type
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsFinesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxLogFines = /* GraphQL */ `
  query SyncDxLogFines(
    $filter: ModelDxLogFinesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxLogFines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        transactiondate
        vehicleid
        driverid
        drivername
        amount
        innovationfee
        totalamount
        source
        finenumber
        blackpoints
        reason
        location
        type
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsFinesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxLogSalik = /* GraphQL */ `
  query GetDxLogSalik($id: ID!) {
    getDxLogSalik(id: $id) {
      id
      transactionid
      date
      transactiondate
      tollgate
      direction
      tagnumber
      vehicleid
      driverid
      drivername
      amount
      reimbursement
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsSalikId
      owner
      __typename
    }
  }
`;
export const listDxLogSaliks = /* GraphQL */ `
  query ListDxLogSaliks(
    $id: ID
    $filter: ModelDxLogSalikFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxLogSaliks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionid
        date
        transactiondate
        tollgate
        direction
        tagnumber
        vehicleid
        driverid
        drivername
        amount
        reimbursement
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsSalikId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxLogSaliks = /* GraphQL */ `
  query SyncDxLogSaliks(
    $filter: ModelDxLogSalikFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxLogSaliks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        transactionid
        date
        transactiondate
        tollgate
        direction
        tagnumber
        vehicleid
        driverid
        drivername
        amount
        reimbursement
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsSalikId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxLogParking = /* GraphQL */ `
  query GetDxLogParking($id: ID!) {
    getDxLogParking(id: $id) {
      id
      date
      transactiondate
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      author
      editor
      reimbursement
      company
      expirydate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsParkingsId
      owner
      __typename
    }
  }
`;
export const listDxLogParkings = /* GraphQL */ `
  query ListDxLogParkings(
    $id: ID
    $filter: ModelDxLogParkingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxLogParkings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        transactiondate
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        driverid
        drivername
        amount
        innovationfee
        totalamount
        author
        editor
        reimbursement
        company
        expirydate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsParkingsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxLogParkings = /* GraphQL */ `
  query SyncDxLogParkings(
    $filter: ModelDxLogParkingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxLogParkings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        transactiondate
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        driverid
        drivername
        amount
        innovationfee
        totalamount
        author
        editor
        reimbursement
        company
        expirydate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsParkingsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxLogVipfuel = /* GraphQL */ `
  query GetDxLogVipfuel($id: ID!) {
    getDxLogVipfuel(id: $id) {
      id
      date
      transactiondate
      vehicleid
      rid
      unitprice
      volume
      total
      productname
      receiptno
      odometer
      idunitcode
      stationname
      stationcode
      fleetname
      groupname
      vehiclecode
      citycode
      costcenter
      vatrate
      vatamount
      actualamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      driverid
      drivername
      cashdescription
      reimbursement
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsVipfuelId
      owner
      __typename
    }
  }
`;
export const listDxLogVipfuels = /* GraphQL */ `
  query ListDxLogVipfuels(
    $id: ID
    $filter: ModelDxLogVipfuelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxLogVipfuels(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        transactiondate
        vehicleid
        rid
        unitprice
        volume
        total
        productname
        receiptno
        odometer
        idunitcode
        stationname
        stationcode
        fleetname
        groupname
        vehiclecode
        citycode
        costcenter
        vatrate
        vatamount
        actualamount
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        driverid
        drivername
        cashdescription
        reimbursement
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsVipfuelId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxLogVipfuels = /* GraphQL */ `
  query SyncDxLogVipfuels(
    $filter: ModelDxLogVipfuelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxLogVipfuels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        transactiondate
        vehicleid
        rid
        unitprice
        volume
        total
        productname
        receiptno
        odometer
        idunitcode
        stationname
        stationcode
        fleetname
        groupname
        vehiclecode
        citycode
        costcenter
        vatrate
        vatamount
        actualamount
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        driverid
        drivername
        cashdescription
        reimbursement
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsVipfuelId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxNavigation = /* GraphQL */ `
  query GetDxNavigation($id: ID!) {
    getDxNavigation(id: $id) {
      id
      type
      item
      order
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxNavigations = /* GraphQL */ `
  query ListDxNavigations(
    $filter: ModelDxNavigationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDxNavigations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        item
        order
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxNavigations = /* GraphQL */ `
  query SyncDxNavigations(
    $filter: ModelDxNavigationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxNavigations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        item
        order
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxWorkflow = /* GraphQL */ `
  query GetDxWorkflow($id: ID!) {
    getDxWorkflow(id: $id) {
      id
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      approvalitems {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      title
      description
      startdate
      duedate
      enddate
      resource
      resourceid
      resourceurl
      currentlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      nextlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      wfstatus
      wfield1
      wfield2
      wfield3
      wfield4
      wfield5
      wjson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsWorkflowId
      dxApprovalConfigWorkflowId
      owner
      __typename
    }
  }
`;
export const listDxWorkflows = /* GraphQL */ `
  query ListDxWorkflows(
    $id: ID
    $filter: ModelDxWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxWorkflows(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxWorkflows = /* GraphQL */ `
  query SyncDxWorkflows(
    $filter: ModelDxWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxWorkflows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxApprovals = /* GraphQL */ `
  query GetDxApprovals($id: ID!) {
    getDxApprovals(id: $id) {
      id
      name
      title
      description
      startdate
      duedate
      enddate
      assignedto
      level
      status
      resource
      resourceid
      resourceurl
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      afield1
      afield2
      afield3
      afield4
      afield5
      userprofiles
      ajson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalitemsId
      dxApprovalConfigApprovalId
      owner
      __typename
    }
  }
`;
export const listDxApprovals = /* GraphQL */ `
  query ListDxApprovals(
    $id: ID
    $filter: ModelDxApprovalsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxApprovals(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        afield1
        afield2
        afield3
        afield4
        afield5
        userprofiles
        ajson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxApprovals = /* GraphQL */ `
  query SyncDxApprovals(
    $filter: ModelDxApprovalsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxApprovals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        afield1
        afield2
        afield3
        afield4
        afield5
        userprofiles
        ajson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxApprovalsHistory = /* GraphQL */ `
  query GetDxApprovalsHistory($id: ID!) {
    getDxApprovalsHistory(id: $id) {
      id
      changes
      status
      activity
      respondedby
      date
      approval {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        afield1
        afield2
        afield3
        afield4
        afield5
        userprofiles
        ajson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalhistoryId
      dxApprovalsApprovalhistoryId
      owner
      __typename
    }
  }
`;
export const listDxApprovalsHistories = /* GraphQL */ `
  query ListDxApprovalsHistories(
    $id: ID
    $filter: ModelDxApprovalsHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxApprovalsHistories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        changes
        status
        activity
        respondedby
        date
        approval {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalhistoryId
        dxApprovalsApprovalhistoryId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxApprovalsHistories = /* GraphQL */ `
  query SyncDxApprovalsHistories(
    $filter: ModelDxApprovalsHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxApprovalsHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        changes
        status
        activity
        respondedby
        date
        approval {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalhistoryId
        dxApprovalsApprovalhistoryId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxApprovalConfigSteps = /* GraphQL */ `
  query GetDxApprovalConfigSteps($id: ID!) {
    getDxApprovalConfigSteps(id: $id) {
      id
      name
      title
      action
      approval {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acsjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsId
      owner
      __typename
    }
  }
`;
export const listDxApprovalConfigSteps = /* GraphQL */ `
  query ListDxApprovalConfigSteps(
    $id: ID
    $filter: ModelDxApprovalConfigStepsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxApprovalConfigSteps(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxApprovalConfigSteps = /* GraphQL */ `
  query SyncDxApprovalConfigSteps(
    $filter: ModelDxApprovalConfigStepsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxApprovalConfigSteps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxApprovalConfig = /* GraphQL */ `
  query GetDxApprovalConfig($id: ID!) {
    getDxApprovalConfig(id: $id) {
      id
      name
      title
      action
      approval {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxApprovalConfigs = /* GraphQL */ `
  query ListDxApprovalConfigs(
    $id: ID
    $filter: ModelDxApprovalConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxApprovalConfigs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxApprovalConfigs = /* GraphQL */ `
  query SyncDxApprovalConfigs(
    $filter: ModelDxApprovalConfigFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxApprovalConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxDocument = /* GraphQL */ `
  query GetDxDocument($id: ID!) {
    getDxDocument(id: $id) {
      id
      name
      resource
      resourceid
      presourceid
      presource
      field1
      field2
      field3
      s3object {
        bucket
        region
        key
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxDocuments = /* GraphQL */ `
  query ListDxDocuments(
    $id: ID
    $filter: ModelDxDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDxDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        resource
        resourceid
        presourceid
        presource
        field1
        field2
        field3
        s3object {
          bucket
          region
          key
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxDocuments = /* GraphQL */ `
  query SyncDxDocuments(
    $filter: ModelDxDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        resource
        resourceid
        presourceid
        presource
        field1
        field2
        field3
        s3object {
          bucket
          region
          key
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDxTableSettings = /* GraphQL */ `
  query GetDxTableSettings($id: ID!) {
    getDxTableSettings(id: $id) {
      id
      application
      table
      column
      expression
      isactive
      field1
      field2
      field3
      field4
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listDxTableSettings = /* GraphQL */ `
  query ListDxTableSettings(
    $filter: ModelDxTableSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDxTableSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        application
        table
        column
        expression
        isactive
        field1
        field2
        field3
        field4
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDxTableSettings = /* GraphQL */ `
  query SyncDxTableSettings(
    $filter: ModelDxTableSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDxTableSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        application
        table
        column
        expression
        isactive
        field1
        field2
        field3
        field4
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dxAllocationsByFromdateAndVehicleid = /* GraphQL */ `
  query DxAllocationsByFromdateAndVehicleid(
    $fromdate: AWSDateTime!
    $vehicleid: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldxAllocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dxAllocationsByFromdateAndVehicleid(
      fromdate: $fromdate
      vehicleid: $vehicleid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dxlogReimbursementsByVehicleidAndDate = /* GraphQL */ `
  query DxlogReimbursementsByVehicleidAndDate(
    $vehicleid: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldxlogReimbursementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dxlogReimbursementsByVehicleidAndDate(
      vehicleid: $vehicleid
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        vehicleid
        driverid
        date
        transactiontype
        invoiceno
        paidto
        Vendorname
        description
        remarks
        split
        amount
        expensetype
        isreimbursed
        approver
        reimbursedamount
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverLogreimbursementId
        dxVehicleLogreimbursementId
        dxMasterLogsLogreimbursementId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dxlogReimbursementsByDriveridAndDate = /* GraphQL */ `
  query DxlogReimbursementsByDriveridAndDate(
    $driverid: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldxlogReimbursementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dxlogReimbursementsByDriveridAndDate(
      driverid: $driverid
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        vehicleid
        driverid
        date
        transactiontype
        invoiceno
        paidto
        Vendorname
        description
        remarks
        split
        amount
        expensetype
        isreimbursed
        approver
        reimbursedamount
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverLogreimbursementId
        dxVehicleLogreimbursementId
        dxMasterLogsLogreimbursementId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dxLogSaliksByDateAndVehicleidAndDrivername = /* GraphQL */ `
  query DxLogSaliksByDateAndVehicleidAndDrivername(
    $date: AWSDateTime!
    $vehicleidDrivername: ModeldxLogSalikBydatebyvehiclebydriverCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldxLogSalikFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dxLogSaliksByDateAndVehicleidAndDrivername(
      date: $date
      vehicleidDrivername: $vehicleidDrivername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionid
        date
        transactiondate
        tollgate
        direction
        tagnumber
        vehicleid
        driverid
        drivername
        amount
        reimbursement
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxMasterLogsSalikId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
