import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import AmplifyServices from '../../Service/Service';
import ListTable from '../../Components/ListTable';
import { Menu } from 'primereact/menu';
import { useNavigate } from 'react-router-dom';
import { listDxVendors } from '../../graphql/queries';
import { Dialog } from 'primereact/dialog';
import { deleteDxVendors } from '../../graphql/mutations';
import moment from 'moment';
let params: any;
const ManageVendors = () => {
    const navigate = useNavigate();
    const menu = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [items, setItems] = useState<any>(null);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<any>('');
    const [selectedItems, setSelectedItems] = useState<any>([]);

    params = selectedItems.length > 0 && `?mode=edit&id=${selectedItems[0].id}`;
    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        console.log('useefect');
        await AmplifyServices.getTableDetails(listDxVendors, 'listDxVendors').then(async (res: any) => {
            console.log(res, 'res');
            setItems(res);
            setLoading(false);
        });
    };

    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };

    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };

    const columns = [
        // { fieldtype: 'multiselect' },
        { field: 'company', header: 'Company', textalign: 'left', fieldtype: 'navigateVendor', filterOption: true, filterFieldOption: 'company', width: '100px' },
        { field: 'vendorname', header: 'Vendor Name', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'vendorname', width: '150px' },
        // // {field: 'imageData', header: 'Image', fieldtype:'image'},
        { field: 'contactno', header: 'Contact No', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'contactno', width: '130px' },
        { field: 'email', header: 'Email', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'email', width: '130px' },
        { field: 'vendortype', header: 'Vendor Type', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'vendortype', width: '130px' },
        { field: 'officelocation', header: 'Office Location', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'officelocation', width: '140px' },
        { field: 'garagelocation', header: 'Garage Location', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'garagelocation', width: '140px' },
        { field: 'updatedAt', header: 'Updated At', fieldtype: 'date', textalign: 'left', filterOption: false, headeralign: 'left', bodyalign: 'left', filterFieldOption: 'updatedAt', width: '90px' }
    ];

    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text companyBtn" onClick={() => deleteItem()} />
        </>
    );
    const deleteItem = async () => {
        AmplifyServices.DeleteItems(selectedItems, deleteDxVendors).then((res) => {
            getData().then((res) => {
                hideDeleteItemsDialog();
                setSelectedItems([]);
                selectedItems.length = 0;
            });
        });
    };
    const clearbtn = () => {
        setGlobalFilter('');
    };
    let filteredSearch =
        items &&
        items
            .sort(function (a: any, b: any) {
                return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
            })
            .filter((del: any) => del._deleted !== true)
            .filter((name: any) => {
                if (
                    (name.company !== null && name.company.toLowerCase().includes(globalFilter.toLowerCase().toLowerCase())) ||
                    (name.contactno !== null && name.contactno.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase().toLowerCase())) ||
                    (name.email !== null && name.email.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.garagelocation !== null && name.garagelocation.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.officelocation !== null && name.officelocation.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.vendorname !== null && name.vendorname.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.vendortype !== null && name.vendortype.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase()))
                ) {
                    return name;
                }
            });
    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New" onClick={() => navigate('/manage/vendors/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />

                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />

                <Menu
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedItems.length === 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                navigate('/manage/vendors/item/basic' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            {selectedItems && selectedItems.length > 0 && <span>{selectedItems.length + ' of ' + filteredSearch.length + ' selected'}</span>}

            <div>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <form>
                        <div
                            style={{
                                position: 'relative',
                                marginTop: '3px',
                                marginBottom: '1em'
                            }}
                        >
                            <InputText type="text" id={globalFilter} value={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
                            {globalFilter.length > 0 ? (
                                <button type="button" className="contentBox__searchClearBtn" onClick={clearbtn}>
                                    X
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </form>
                </span>
            </div>
        </div>
    );

    return (
        <div className="grid crud">
            <div className="col-12">
                <h4 className="container-header">Manage Vendors</h4>

                <div className="card">
                    <ListTable
                        header="Manage Vendors"
                        value={filteredSearch}
                        paginator={true}
                        rows={100}
                        dynamicColumns={columns}
                        emptyMessage="No Vendors found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vendors"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[100, 200, 300]}
                        //   selection={selectedProducts}
                        multiselect={true}
                        loading={loading}
                        Commandbar={Commandbar}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}
                        selectedItems={setSelectedItems}
                    />
                    <Dialog visible={deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedItems && <span>Are you sure you want to delete the selected items?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ManageVendors;
