import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AmplifyServices from '../../Service/Service';
import { createDxDepartment, deleteDxDepartment, updateDxDepartment } from '../../graphql/mutations';
import { listDxCompanies, listDxDepartments } from '../../graphql/queries';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import FormTemplate from '../../Components/FormTemplate';
import dxService from '../../Services/dxService';
const DepartmentNewform = () => {
    const toast = useRef<any>(null);
    const [DepartmentOptions, setDepartmentOptions] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [listFormData, setlistFormData] = useState<any>({});
    const location1 = useLocation();
    const urlParams1 = new URLSearchParams(location1.search);
    var formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
    var formid = urlParams1.has('id') ? urlParams1.get('id') : null;

    const navigate = useNavigate();

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
        formid = urlParams1.has('id') ? urlParams1.get('id') : null;
        const paths = location1.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'allocations':
                setActiveIndex(2);
                break;
            // case 'logs':
            //     setActiveIndex(3);
            //     break;
            // case 'Allocations':
            //     setActiveIndex(4);
            //     break;
            default:
                if (formid) getDeptdata(formid);
                break;
        }
    }, [location1]);
    const getDeptdata = async (fid: any) => {
        await AmplifyServices.getFilterbyIddetails(listDxDepartments, 'listDxDepartments', fid).then((a) => {
            if (a.length > 0) {
                setlistFormData(a[0]);
            }
        });
    };
    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);
    useEffect(() => {
        getCompanyOptions();
    }, []);

    const getCompanyOptions = async () => {
        await AmplifyServices.getTableDetails(listDxCompanies, 'listDxCompanies').then(async (res: any) => {
            setDepartmentOptions(res);
        });
    };

    const BusinessUnitOption: any = [];
    const names = DepartmentOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.id;

        BusinessUnitOption.push(res);
    });

    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                name: 'name', // pass the exact column name of db
                title: 'Name',
                value: '',
                fieldtype: 'text',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'description', // pass the exact column name of db
                title: 'Description', //for display
                value: '', //for display
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'dxCompanyDepartmentsId', // pass the exact column name of db
                title: 'Business Unit', //for display
                value: '', //for display
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: BusinessUnitOption
            },
            {
                name: 'owner', // pass the exact column name of db
                title: 'Owner',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'member', // pass the exact column name of db
                title: 'Member',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            }
        ],
        actions: [
            {
                title: 'Submit'
            },
            {
                title: 'Cancel'
            }
        ]
        // fleettype: "Department",
        // getTable: listDxDepartments,
        // createTable: createDxDepartment, //Pass create table name
        // updateTable: updateDxDepartment, //Pass update table name
        // deleteTableItem: deleteDxDepartment, //Pass update table name
    };
    const addData = async (props: any) => {
        // data['id'] = Math.random().toString(36).substring(2, 9);

        let data = {
            description: props.description,
            dxCompanyDepartmentsId: props.dxCompanyDepartmentsId,
            member: props.member,
            name: props.name
        };

        await dxService.createitem('department', data).then((res) => {
            showSuccess('', 'Added Successfully');
            navigate('/admin/departments/all');
        });
    };
    const changedata = (props: any) => {
        console.log(props);
    };
    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';
    const wizardItems = [
        {
            label: 'Basic Details',
            command: () => navigate('/admin/departments/item/basic' + qs)
        }
    ];
    const editnewnavigation = async (formdata: any, fid: string) => {
        let b: any = formdata;
        let data = {
            id: formdata.id,
            description: formdata.description,
            dxCompanyDepartmentsId: formdata.dxCompanyDepartmentsId,
            member: formdata.member,
            name: formdata.name,
            _version: formdata._version
        };
        // return;
        await dxService.updateitem('department', data).then((res) => {
            showSuccess(res.name, 'updated successfully!');
            navigate(`/admin/departments/all`);
        });
    };
    const showSuccess = (summary: string, detail: string) => {
        toast.current.show({
            severity: 'success',
            summary: summary,
            detail: detail,
            life: 3000
        });
    };
    const addConfig = (props: any) => {
        if (formid == null) addData(props);
        else {
            editnewnavigation(props, formid);
        }
    };
    //   const submitForm = async (data: any) => {
    //     // console.log(props.columns);
    //     data["id"] = Math.random().toString(36).substring(2, 9);
    //     data["fleettype"] = formColumns.fleettype;

    //     let os = await API.graphql({
    //       query: formColumns.createTable,
    //       authMode: "AMAZON_COGNITO_USER_POOLS",
    //       variables: {
    //         input: data,
    //       },
    //     });

    //     let navigateUrl = "/admin/departments";
    //     navigate(navigateUrl);
    //   };

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{formmode === 'edit' ? 'Edit Department : ' + listFormData.name : formmode === 'view' ? 'View Department: ' + listFormData.name : 'New Department'}</h4>

                <div className="card container-card">
                    <Toast ref={toast} />
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                    <Routes>
                        <Route
                            path={'/basic'}
                            element={
                                <>
                                    <FormTemplate
                                        id="newdepartment"
                                        columns={formColumns}
                                        data={listFormData}
                                        showaction={true}
                                        onChange={changedata}
                                        mode={formmode}
                                        ChangeBtn={'OpenDocument'}
                                        layout="grid2"
                                        title="New Approval"
                                        submitForm={addConfig}
                                    />
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default DepartmentNewform;
