import { API, Storage } from 'aws-amplify';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { listDxVehicleSpares } from '../../graphql/queries';
import AmplifyServices from '../../Service/Service';
import { useLocation } from 'react-router-dom';
import dxService from '../../Services/dxService';

let sparesID: any;
const Sparesviewform = () => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const [displayDocuments, setDisplayDocuments] = useState([]);
    useEffect(() => {
        sparesID = urlParams.has('id') ? urlParams.get('id') : '';
        // sparesID !== '' && getDisplayDetails();
        getDocumentDetails();
    }, []);

    const getDocumentDetails = async (nextToken?: any) => {
        setDisplayDocuments([]);
        let variables = {
            id: {
                eq: sparesID
            }
        };
        const response: any = await dxService.listitems('vehiclespare', '*', variables, false, 1000, nextToken);

        let data = response.items;
        const nextTokenResponse = response.nextToken;

        if (nextTokenResponse) {
            const nextResults = await getDocumentDetails(nextTokenResponse);
            data = data.concat(nextResults);
        }
        data = data.filter((item: any) => item._deleted !== true);

        getFileLocation(data);
        // setDisplayDocuments(GetResponse.data.listDxVehicleSpares.items);
    };
    const getFileLocation = (e: any) => {
        console.log(e);

        let FinalData: any = [];
        e.map(async (item: any) => {
            let invoice = item.invoicedoc !== null && JSON.parse(item.invoicedoc).File;
            for (let i = 0; i < invoice.length; i++) {
                let url = await Storage.get(invoice[i]);
                FinalData.push({
                    name: invoice[i].split('/').pop(),
                    invoicedoc: url,
                    invoiceno: item.invoiceno
                });
            }

            setDisplayDocuments(FinalData);
        });
    };
    const AttachmentBodyTemplate = (e: any) => {
        return (
            <a href={e.invoicedoc} target="_blank" className="">
                {e.name}
                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
            </a>
        );
    };
    const invoiceNoBodyTemplate = (e: any) => {
        return <span className="">{e.invoiceno}</span>;
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <div className="card">
                    <h5>Documents</h5>
                    <DataTable
                        value={displayDocuments}
                        dataKey="id"
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} contracts"
                        emptyMessage="No Documents added."
                        responsiveLayout="scroll"
                    >
                        <Column field="invoiceno" header="Invoice No" body={invoiceNoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="invoicedoc" header="Document" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
export default Sparesviewform;
