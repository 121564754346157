import React, { useState, useEffect, useRef, Children, useContext } from 'react';
import { Amplify, API, Auth, Hub, Storage } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsconfig from '../../aws-exports';
import { Toast } from 'primereact/toast';
import { AuthContext } from '../../AuthContext';
import { CognitoIdentityProviderClient, AdminAddUserToGroupCommand, AdminRemoveUserFromGroupCommand, ListGroupsCommand, AdminListGroupsForUserCommand, AdminUpdateUserAttributesCommand } from '@aws-sdk/client-cognito-identity-provider';
import AmplifyServices from '../../Service/Service';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../dxgraphql/dxqueries';
import { getDxApprovalConfig, getDxWorkflow, listDxApprovals } from '../../graphql/queries';
import { createDxContract, updateDxApprovalConfig, updateDxApprovals, updateDxWorkflow } from '../../graphql/mutations';
import FormTemplate from '../../Components/FormTemplate';
import { Button } from 'primereact/button';
import awsExports from '../../aws-exports';


const UploadPage = () => {
    const { dispatch } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [workflowitem, setworkflowitem] = useState<any>({});
    const [stepitem, setstepitem] = useState<any>({});
    const [appitem, setappitem] = useState<any>({});
    const [newdata, setnewdata] = useState<any>({});
    const toast = useRef(null);
    const [uname, setUname] = useState('');
    const [name, setname] = useState('');


    useEffect(() => {
        getdata();



    }, []);

    useEffect(() => {

    }, [appitem]);



    const changedata = (props: any) => {
        console.log(props);


    };

    const addData = (props: any) => {
        console.log(props);

    };





    const getdata = async () => {


    }

    const adddoctodb = async (file:any) =>{
        
        try{
            let newcontract: any = await API.graphql({
                query: createDxContract,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: file
                }
            });
            console.log(newcontract);
        }catch(ex){
            console.log(ex);
        }
    }

    

    const onchange = async(e:any) =>{
        const file = e.target.files[0];
        console.log(file);

       

        Storage.put(file.name, file,{
            contentType: 'image/jpeg'
        }).then(async (result)=>{
            console.log(URL.createObjectURL(file));
            console.log(result);
            const image = {
                policyno: file.name,
                document:[
                    {
                        bucket: awsExports.aws_user_files_s3_bucket,
                        region: awsExports.aws_user_files_s3_bucket_region,
                        key: 'public/'+file.name,
                        name: file.name
                    }
                ]
            };
            console.log(image);
            let a = await adddoctodb(image);

           


        })
    }


    return (

        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Header Template</h4>

                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}

                            <div className="UploadImage">
                                <div>
                                    <p>Please select an image to upload</p>
                                    <input type="file" onChange={(e)=> onchange(e)}/>
                                </div>
                            </div>
                            
                            {/* END Inner COMPONENT HTML */}

                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}




            </div>
        </div>

    );
};
export default UploadPage;

