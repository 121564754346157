import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AmplifyServices from "../../Service/Service";
import ListTable from "../../Components/ListTable";
import { deleteDxDriver } from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import { listDxDrivers } from "../../graphql/queries";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { _listDxDrivers } from "../../dxgraphql/dxqueries";
import ListTemplate from "../../Components/ListTemplate";
import dxService from "../../Services/dxService";
import { API } from "aws-amplify";
import moment from "moment";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { Storage } from "aws-amplify";
import { Avatar } from "primereact/avatar";
import { CSVLink } from "react-csv";
var AllDriver: any = [];
const ManageDrivers = () => {
  const navigate = useNavigate();
  const menu = useRef<any>(null);
  const toast = useRef<any>(null);
  const [driveritems, setdriveritems] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>("");
  const [tableFilters, setTableFilters] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);
  //inifinite scroll

  const [page, setPage] = useState(100);
  useEffect(() => {
    getData().then(async (res) => {
      getallDriverData(res);
    });
  }, []);

  const getData = async (nextToken?: any) => {
    let variables: any = {};
    let GetResponse: any = await dxService.listitems(
      "driver",
      "*",
      variables,
      true,
      100,
      nextToken
    );
    let response = GetResponse.items;
    let nexttoken = GetResponse.nextToken;
    window.onscroll = async function (ev) {
      if (
        window.innerHeight + window.scrollY + 10 >=
          document.body.scrollHeight &&
        nexttoken
      ) {
        await getData(nexttoken).then((res) => {
          // console.log(res);
          // getallDriverData(res);
          setdriveritems((olditems: any) => [...olditems, ...res]);
          setLoading(false);
        });
      }
    };
    return response;
  };
  const getallDriverData = async (alldata: any) => {
    // console.log(alldata);
    AllDriver = [];
    let newItems = alldata
      .sort(function (a: any, b: any) {
        return moment(b.updatedAt)
          .format()
          .localeCompare(moment(a.updatedAt).format());
      })
      .filter(
        (item: any) =>
          item !== undefined &&
          item._deleted !== true &&
          item.name !== null &&
          item.isarchived !== true
      )
      .map(async (item: any) => {
        // console.log(item);
        if (item.jfield !== null && JSON.parse(item.jfield).driverpicture) {
          // console.log(item);
          let driverpic = JSON.parse(item.jfield).driverpicture;
          let driverimage = await Storage.get(driverpic);
          // console.log(driverimage);
          item["driverimage"] = driverimage;
        } else {
          item["driverimage"] = null;
        }
        AllDriver.push(item);
        setdriveritems(formatDatefield(AllDriver));
        setLoading(false);
        // setdriveritems((oldArray: any) => [...oldArray, item]);
        // getallDriverData((oldArray: any) => [...oldArray, item]);
      });
    // console.log(AllDriver);
    // console.log(newItems);
  };
  const formatDatefield = (data: any) => {
    return [...(data || [])].map((d) => {
      d.startdate = new Date(d.startdate);
      d.enddate = new Date(d.enddate);
      return d;
    });
  };
  const VehicleimageBodyTemplate = (rowData: any) => {
    return (
      rowData.driverimage !== null && (
        <div className="formstemplate">
          {/* <span className="p-column-title">Image</span> */}
          {/* <img src={`https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/images/logo/${imgext}`} className="product-image" width="32" /> */}
          {/* <img src={rowData.driverimage} className="product-image" width="32" /> */}
          <Avatar
            image={rowData.driverimage}
            size="large"
            className="mr-4"
            shape="circle"
          ></Avatar>
        </div>
      )
    );
  };
  const driverStatusBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.status == "InActive"
          ? "Inactive"
          : rowData.status == "Active"
          ? "Active"
          : ""}
      </>
    );
  };
  const columns = [
    {
      field: "id",
      showfield: "name",
      headeralign: "left",
      bodyalign: "left",
      header: "Name",
      fieldtype: "href",
      path: "#/dashboard/driver?id=",
      filterOption: true,
      filterFieldOption: "name",
      sortable: true,
      sortField: "name",
    },
    {
      field: "driverimage",
      header: "Image",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "image",
      filterOption: false,
      template: (r: any) => <>{VehicleimageBodyTemplate(r)}</>,
    },
    //{ field: 'mobileno', header: 'Mobile No', fieldtype: 'text', filterOption: true, filterFieldOption: 'mobileno' },
    {
      field: "contactno",
      header: "Contact No",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "contactno",
    },
    // // {field: 'imageData', header: 'Image', fieldtype:'image'},
    {
      field: "department",
      headeralign: "left",
      bodyalign: "left",
      header: "Department",
      fieldtype: "lookup",
      filterOption: true,
      filterFieldOption: "department.name",
      sortable: true,
      sortField: "department.name",
    },
    {
      field: "company",
      headeralign: "left",
      bodyalign: "left",
      header: "Business Unit",
      fieldtype: "lookup",
      filterOption: true,
      filterFieldOption: "company.name",
      sortable: true,
      sortField: "company.name",
    },
    {
      field: "licenseno",
      headeralign: "left",
      bodyalign: "left",
      header: "License No",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "licenseno",
    },
    {
      field: "startdate",
      headeralign: "left",
      bodyalign: "left",
      header: "Start Date",
      showtime: false,
      fieldtype: "datefilter",
      sortable: true,
      sortField: "startdate",
    },
    {
      field: "enddate",
      headeralign: "left",
      bodyalign: "left",
      showtime: false,
      header: "End Date",
      fieldtype: "datefilter",
      sortable: true,
      highlight: true,
      sortField: "enddate",
    },
    {
      field: "status",
      header: "Status",
      fieldtype: "custom",
      textalign: "left",
      filterOption: false,
      headeralign: "left",
      bodyalign: "left",
      filterFieldOption: "status",
      template: (r: any) => <>{driverStatusBodyTemplate(r)}</>,
    },
  ];

  const deleteDrivers = async () => {
    // console.log("delete");
    // Promise.all(
    selectedItems.forEach(async (item: any) => {
      let updatedriver = {
        id: item.id,
        _version: item._version,
        status: "InActive",
      };
      // let deleteDriver = await dxService.deleteitem('driver', item.id, item._version);
      let updatedriverdata = await dxService.updateitem("driver", updatedriver);
      setSelectedItems([]);
      selectedItems.length = 0;
      getData().then((res: any) => {
        getallDriverData(res);
        toast.current.show({
          severity: "success",
          summary: "Sucessfully made driver as Inactive",
          // detail: detail,
          life: 3000,
        });
      });
    });

    // );
    // AmplifyServices.DeleteDrivers(selectedItems, deleteDxDriver).then((res) => {
    //     getData().then((alldata) => {
    //         setSelectedItems([]);
    //         selectedItems.length = 0;
    //         toast.current.show({
    //             severity: 'success',
    //             summary: 'Driver deleted sucessfully',
    //             // detail: detail,
    //             life: 3000
    //         });
    //     });
    // });
  };
  const ArchiveDrivers = async () => {
    // console.log("delete");
    // Promise.all(
    selectedItems.forEach(async (item: any) => {
      let updatedriver = {
        id: item.id,
        _version: item._version,
        isarchived: true,
      };
      // let deleteDriver = await dxService.deleteitem('driver', item.id, item._version);
      let updatedriverdata = await dxService.updateitem("driver", updatedriver);
      setSelectedItems([]);
      selectedItems.length = 0;
      getData().then((res: any) => {
        getallDriverData(res);
        toast.current.show({
          severity: "success",
          summary: "Sucessfully Archived driver",
          // detail: detail,
          life: 3000,
        });
      });
    });

    // );
    // AmplifyServices.DeleteDrivers(selectedItems, deleteDxDriver).then((res) => {
    //     getData().then((alldata) => {
    //         setSelectedItems([]);
    //         selectedItems.length = 0;
    //         toast.current.show({
    //             severity: 'success',
    //             summary: 'Driver deleted sucessfully',
    //             // detail: detail,
    //             life: 3000
    //         });
    //     });
    // });
  };
  let params =
    selectedItems.length > 0 &&
    `?mode=edit&id=${selectedItems[0].id}&fleettype=${selectedItems[0].fleettype}`;
  const clearbtn = () => {
    setGlobalFilter("");
  };
  // console.log(driveritems);

  let filteredSearch =
    driveritems &&
    driveritems
      .filter((del: any) => del._deleted !== true)
      .filter((name: any) => {
        // console.log(name, globalFilter);
        if (
          (name.name !== null &&
            name.name.toLowerCase().includes(globalFilter.toLowerCase())) ||
          (name.department !== null &&
            name.department.name
              .toLowerCase()
              .includes(globalFilter.toLowerCase())) ||
          (name.company !== null &&
            name.company.name
              .toLowerCase()
              .includes(globalFilter.toLowerCase())) ||
          (name.licenseno !== null &&
            name.licenseno
              .toLowerCase()
              .includes(globalFilter.toLowerCase())) ||
          (name.mobileno !== null &&
            name.mobileno.toLowerCase().includes(globalFilter.toLowerCase()))
        ) {
          return name;
        }
      })
      .sort((a: any, b: any) => {
        const dateA = Number(new Date(a.enddate));
        const dateB = Number(new Date(b.enddate));
        return dateA - dateB;
      })
      .filter(
        (item: any, index: any, self: any) =>
          self.findIndex((i: any) => i.id === item.id) === index
      );
  const exportdata = (data: any) => {
    // console.log(data);
    let items: any = [];
    data.map((expitem: any) => {
      let a = {
        Name: expitem.name,
        "Contact no": expitem.contactno,
        Company: expitem.company ? expitem.company.name : "",
        Department: expitem.department ? expitem.department.name : "",
        Enddate: expitem.enddate,
        Email: expitem.emailid,
        "License no": expitem.licenseno,
        "License type": expitem.licensetype,
        Startdate: expitem.startdate,
        Status: expitem.status,
        "Job role": expitem.jobrole,
      };
      items.push(a);
    });
    return items;
  };
  const csvLink = {
    filename: `Driverdata`,

    data: filteredSearch.length > 0 ? exportdata(filteredSearch) : [],
  };
  const Commandbar = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="table-header-container">
        <Button
          icon="pi pi-plus"
          label="New"
          onClick={() => navigate("/manage/drivers/item/basic?mode=new")}
          className="p-button-primary mr-2  newbtnhover"
        />

        <Button
          type="button"
          label="Actions"
          icon="pi pi-angle-down"
          onClick={(e) => menu.current.toggle(e)}
        />

        <Menu
          ref={menu}
          popup
          model={[
            {
              disabled: selectedItems.length == 1 ? false : true,
              label: "Edit",
              icon: "pi pi-fw pi-pencil",
              command: () => {
                //  navigate(`/manage/vehicles/item/basic?mode=edit&id=${SelectedItems != undefined && EditNavigation[0].id}&fleettype=vehicle`);
                navigate("/manage/drivers/item/basic" + params);
              },
            },
            {
              disabled: selectedItems.length > 0 ? false : true,
              label: "Inactive",
              icon: "pi pi-fw pi-times",
              command: () => {
                deleteDrivers();
              },
            },
            {
              disabled: selectedItems.length > 0 ? false : true,
              label: "Archive Driver",
              icon: "pi pi-fw pi-arrow-right",
              command: () => {
                ArchiveDrivers();
              },
            },
          ]}
        ></Menu>
      </div>
      <span>
        {selectedItems &&
          selectedItems.length + " of " + filteredSearch.length + " selected"}
      </span>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        {" "}
        <div className="field col">
          <InputText
            type="text"
            id={globalFilter}
            value={globalFilter}
            placeholder="Search ..."
            className="homeNews__search"
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          {globalFilter.length > 0 ? (
            <button
              type="button"
              className="contentBox__searchClearBtn"
              onClick={clearbtn}
            >
              X
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="field ">
          <CSVLink {...csvLink}>
            <span className="p-float-label export_renewals text-white">
              Export
            </span>
          </CSVLink>
        </div>
      </div>
    </div>
  );

  return (
    <div className="grid managedrivers">
      <div className="col-12 md:col-12">
        <h4 className="container-header">Manage Drivers</h4>
        <div className="datatable-editing-demo">
          {/* <NewForm disableAllField={false} parent="Workspace" columns={AddForm} fleettype="drivers" title={formTitle} onSubmit={onSubmit} /> */}
          <Toast ref={toast} />
          <ListTemplate
            header="Manage Drivers"
            value={filteredSearch}
            paginator={false}
            rows={100}
            filters={tableFilters}
            dynamicColumns={columns}
            emptyMessage="No drivers found."
            responsiveLayout="scroll"
            className="datatable-responsive"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Drivers"
            // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            // rowsPerPageOptions={[5, 10, 25]}
            //   selection={selectedProducts}
            multiselect={true}
            loading={loading}
            Commandbar={Commandbar}
            //   onSelectionChange={e => setSelectedProducts(e.value)}Wd
            selectedItems={setSelectedItems}
            //inifinite scroll
          />
        </div>
      </div>
    </div>
  );
};

export default ManageDrivers;
