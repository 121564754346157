import { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './WorkSpace.scss';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import { Toast } from 'primereact/toast';

import { listDxVehicles, listDxMasterLogs, listDxAppointments } from '../../graphql/queries';
import { useLocation } from 'react-router';
import AmplifyServices from '../../Service/Service';
import AllocateDriverPopUp from './WSAllocation';
import { convertdate, getNextQueryData, getcurrentallocationfromvehicleactivity, getdriveroptions, getdriveroptionsName, isotolocaledatetime, localetoisodatetime } from '../../utils/helper';
import { Tooltip } from 'primereact/tooltip';
import dxService from '../../Services/dxService';
import { AuthContext } from '../../AuthContext';
import { Calendar } from 'primereact/calendar';
let collectNewdriver: any = [];
let menuname: any = '';
// var id: any;
let AllDriver: any = [];
const MonthlyAllocation = () => {
    const { dispatch } = useContext(AuthContext);
    //#region Reference Variables
    const toast = useRef<any>();

    const opdatatable = useRef<any>(null);
    const VechileidAllocation = useRef<any>(null);

    //#endregion

    //#region State Variables

    const [DriverDropdownItem, setDriverDropdownItem] = useState<any>(null);
    const [sales, setSales] = useState<any>([]);

    const [loading, setLoading] = useState<boolean>(true);
    const [AppointmentData, setAppointmentData] = useState<any>([]);

    const [selectedrow, setselectedrow] = useState<any>();

    //allocation driver setstate

    const [filterReports, setfilterReports] = useState<any>([]);
    // const [filterLogs, setFilterLogs] = useState<any>([]);

    const navigate = useNavigate();
    const location = useLocation();
    //#endregion
    const [SelectedDate, setSelectedDate] = useState<any>([]);
    const [startDateFormat, setStartDateFormat] = useState<string>('');
    const [endDateFormat, setEndDateFormat] = useState<string>('');
    const [LastmonthEndDate, setLastmonthEndDate] = useState<string>('');
    const [selectedDriver, setSelectedDriver] = useState<any>({});
    //#region Variables

    //#endregion

    //#region React Hooks
    useEffect(() => {
        let todate = new Date();
        let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
        let lastfirstdate = new Date(todate.getFullYear(), todate.getMonth() - 1, 1);

        let previouslastenddate: any = new Date(todate.getFullYear(), todate.getMonth(), 0);
        console.log(previouslastenddate);
        setLastmonthEndDate(previouslastenddate);
        setSelectedDate([lastfirstdate, lastenddate]);
        getData();
        getDriverNames();
    }, [location.key]);
    const getDriverNames = async () => {
        getdriveroptionsName().then((resp: any) => {
            setDriverDropdownItem(resp);
        });
    };
    //#region Render Templates
    console.log(selectedDriver);

    const actionVehicleIdTemplate = (rowData: any) => {
        let params = `?id=${AppointmentData.id}`;
        return (
            <>
                <Button
                    style={{ width: '80px' }}
                    onContextMenu={(e) => {
                        setselectedrow(rowData);
                        VechileidAllocation.current.toggle(e);
                        // menu.current.toggle(e);
                    }}
                    //  onClick={handleClick} onContextMenu={handleClick}
                    label={`${rowData.id}`}
                    // className={`customer-badge status-${rowData.VehicleStatus}`}
                    className="workspace vehicleid workspacehover p-button-info"
                    onClick={(e) => {
                        setselectedrow(rowData);
                    }}
                    // onClick={(event) => menu.current.toggle(event)}
                />
            </>
        );
    };

    const actionDriverTemplate = (rowData: any, index: any) => {
        let next30DaysDate = moment().add(30, 'days').toISOString();
        var todaydate = moment().toISOString();
        let todayallocation = getcurrentallocationfromvehicleactivity(rowData.allocations);

        let btnLabel = todayallocation != null && todayallocation.length > 0 ? rowData?.driver?.name : rowData.driver !== null ? rowData.driver.name : '';
        let btnbadgelabel;
        let btnbadgeclass = '';
        if (rowData.driver !== null) {
            let endDate = new Date(rowData?.driver?.enddate).toISOString();
            const today = new Date();
            const endDates = new Date(rowData?.driver?.enddate);

            const timeDiff = endDates.getTime() - today.getTime();
            const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
            const value = daysDiff >= 0 ? daysDiff : Math.abs(daysDiff);

            if (endDate > todaydate && endDate <= next30DaysDate) {
                btnbadgelabel = (
                    <span
                        className="p-badge p-badge-warning"
                        title={new Date(rowData?.driver?.enddate).toDateString()}
                        style={{ padding: '5px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '1.5rem', minHeight: '1.5rem' }}
                    >
                        {value}
                    </span>
                );

                btnbadgeclass = 'p-badge-warning';
            } else if (endDate < todaydate) {
                btnbadgelabel = (
                    <span
                        className="p-badge p-badge-danger"
                        title={new Date(rowData?.driver?.enddate).toDateString()}
                        style={{ padding: '5px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '1.5rem', minHeight: '1.5rem' }}
                    >
                        {value}
                    </span>
                );

                btnbadgeclass = 'p-badge-danger';
            } else {
            }
        }
        // console.log(selectedDriver[rowData.id]);

        return (
            <div
                className="dropdownstylename"
                //  style={{width: '97px',display: 'inline-block'}}
            >
                <Dropdown
                    name={btnLabel}
                    value={selectedDriver[rowData.id] ? selectedDriver[rowData.id] : btnLabel}
                    options={DriverDropdownItem}
                    onChange={(e) => OndriverChange(e, btnLabel, rowData)}
                    // onChange={(e) => setSelectedDriver(e.value)}
                    // onChange={(e) => handleUpdate(e)}
                    optionLabel="label"
                    placeholder="Select an option"
                    filter
                    className="drivernameoption"
                    optionDisabled={(option: any) => option.isdisabled}
                />
            </div>
        );
    };

    const OndriverChange = (e: any, btnlabel: any, rowData: any) => {
        console.log(e, btnlabel, rowData);
        let newDriver: any = (rowData['drivername'] = e.value);
        setSelectedDriver((prevdriver: any) => ({ ...prevdriver, [rowData.id]: e.value }));
        collectNewdriver.push(rowData);
        console.log(filterReports, collectNewdriver);
    };
    const OnSaveAllocation = () => {
        // setSelectedDriver(event.value);
        console.log(filterReports, collectNewdriver);
        console.log(startDateFormat, endDateFormat);
        console.log(SelectedDate);
        filterReports.map(async (item: any) => {
            if (item.driver !== null && item.drivername) {
                console.log(item.id, 'old allocation');
                let filter = { vehicleid: { eq: item.id }, allocatestatus: { eq: 'Allocated' } };
                await getNextQueryData('', filter, 'allocation', true)
                    .then(async (alloc: any) => {
                        console.log(alloc);

                        let updateallocation = {
                            id: alloc[0].id,
                            handoverdate: localetoisodatetime(new Date(LastmonthEndDate)),

                            allocatestatus: 'DeAllocated',

                            handoverdrivername: item.drivername,
                            _version: alloc[0]._version
                        };
                        console.log(updateallocation);

                        let os: any = {};
                        os = await dxService.updateitem('allocation', updateallocation);
                        return os;
                    })
                    .then(async (success) => {
                        let filter = { name: { contains: item.drivername } };
                        await CreateNewAllocation(item, filter);
                    });
            } else if (item.driver == null && item.drivername) {
                console.log(item.id, 'new allocation');
                let filter = { name: { contains: item.drivername } };
                await CreateNewAllocation(item, filter);
            } else if (item.driver !== null && !item.drivername) {
                console.log(item.id, 'no change');
                let filter = { vehicleid: { eq: item.id }, allocatestatus: { eq: 'Allocated' } };
                await getNextQueryData('', filter, 'allocation', true)
                    .then(async (alloc: any) => {
                        console.log(alloc);
                        if (alloc.length > 0) {
                            let updateallocation = {
                                id: alloc[0].id,
                                handoverdate: localetoisodatetime(new Date(LastmonthEndDate)),

                                allocatestatus: 'DeAllocated',

                                handoverdrivername: item.driver.name,
                                _version: alloc[0]._version
                            };
                            console.log(updateallocation);
                            let os: any = {};
                            os = await dxService.updateitem('allocation', updateallocation);
                            return os;
                        }
                    })
                    .then(async (success) => {
                        let filter = { name: { contains: item.driver.name } };
                        await CreateNewAllocation(item, filter);
                    });
            } else if (item.driver == null && !item.drivername) {
                console.log(item.id, 'empty');
            }
        });
    };
    const CreateNewAllocation = async (item: any, filter: any) => {
        console.log(item, filter);

        await getNextQueryData('', filter, 'driver', true).then(async (driverid: any) => {
            console.log(driverid);
            let createallocation = {
                vehicleid: item.id,
                fromdriverID: driverid.length > 0 ? driverid[0].id : '',
                fromdrivername: driverid.length > 0 ? driverid[0].name : '',
                // fromKM: data.HandoverKmMileage,
                fromdate: localetoisodatetime(new Date(SelectedDate[0])),
                // fromlocation: data.HandoverLocation,
                dxVehicleActivityAllocationsId: item.id,
                dxDriverAllocationsId: driverid.length > 0 ? driverid[0].id : '',
                allocatestatus: 'Allocated'
            };
            console.log(createallocation);
            let allocation = await dxService.createitem('allocation', createallocation, false).then(async (res: any) => {
                let updatevehicleactivity = {
                    id: item.id,
                    //dxAllocationsActivityId: id,
                    dxDriverVehicleactivityId: driverid[0].id, //handover driver id
                    _version: item._version
                };
                console.log(updatevehicleactivity);
                let os = await dxService.updateitem('vehicleactivity', updatevehicleactivity);
            });
        });
    };
    const getData = async () => {
        const today = new Date();
        const res = await AmplifyServices.getvehicleactivitydetails();

        const itemsWithDriverImages = await Promise.all(
            res.filter((res: any) => res.vehicle && res.vehicle.status && res.vehicle.status === 'Active')
            // .map(async (item: any) => {
            //     console.log(item);

            //     // const driverpic = (item.driver && item.driver.jfield && JSON.parse(item.driver.jfield)?.driverpicture) || '';
            //     // const driverimage = driverpic ? await Storage.get(driverpic) : '';
            //     // item.driverimage = driverimage;
            //     // return item;
            // })
        );
        console.log(itemsWithDriverImages);

        setfilterReports(itemsWithDriverImages);

        setLoading(false);
    };
    //For grouping the colum headers
    let headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Vehicle Detail" colSpan={1} />
                <Column header="Allocate" colSpan={1} />
            </Row>
        </ColumnGroup>
    );

    const ClearBtn = () => {
        setSelectedDate('');
        // dateformat = [];
        setStartDateFormat('');
        setEndDateFormat('');
        //   dateformat = 0;
    };
    if (SelectedDate !== null && SelectedDate.length > 0) {
        var ShowClrBtn: any = (
            <>
                <button
                    onClick={ClearBtn}
                    className={`clrbtn`}
                    // style={{ display: toggle, position: "relative", bottom: "5px" }}
                >
                    <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" style={{ color: '#db0000', fontWeight: 700 }}></i>
                    &nbsp;
                </button>
            </>
        );
    }

    //#endregion

    //#region Render Method
    return (
        <div>
            <h5>Fleet Management - Workspace</h5>
            <div className="card Workspace dropdown">
                <Toast ref={toast} />
                <div className="container-space">
                    <div className="p-toolbar-group-start p-toolbar-group-left">
                        <Calendar
                            id="range"
                            className="dateFilter"
                            value={SelectedDate}
                            dateFormat="dd/m/yy"
                            onChange={(e) => {
                                setSelectedDate(e.value);
                            }}
                            selectionMode="range"
                            readOnlyInput
                            placeholder="Select date"
                        />

                        {ShowClrBtn}
                    </div>
                </div>
                <div className="container-space">
                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <DataTable
                                value={filterReports
                                    .sort(function (a: any, b: any) {
                                        return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
                                    })
                                    .filter((item: any) => item._deleted === null || item._deleted === false)}
                                dataKey="id"
                                editMode="row"
                                loading={loading}
                                headerColumnGroup={headerGroup}
                                responsiveLayout="scroll"
                                className="p-datatable-gridlines"
                            >
                                <Column field="id" header="Code" sortable body={actionVehicleIdTemplate} />

                                <Column className="text-center" field="Driver" body={actionDriverTemplate} />
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div className="spd-card-footer ">
                    {' '}
                    <Button className="p-button p-component p-button mr-2 w-10rem" onClick={OnSaveAllocation}>
                        Save
                    </Button>
                    <Button className="p-button p-component p-button-danger mr-2 w-10rem" onClick={OnSaveAllocation}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
    //#endregion
};
export default MonthlyAllocation;
