import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import AmplifyServices from '../../Service/Service';
import ListTable from '../../Components/ListTable';
import { Menu } from 'primereact/menu';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { deleteDxContract, updateDxVehicle } from '../../graphql/mutations';
import { Dropdown } from 'primereact/dropdown';
import { API, graphqlOperation } from 'aws-amplify';
import { listDxContracts } from '../../graphql/queries';
import moment from 'moment';
let params: any;
const ManageServiceContract = () => {
    const navigate = useNavigate();
    const menu = useRef<any>(null);
    const [Vehiclestatuskey, setVehiclestatuskey] = useState<any>(null);
    const [VehcileViewfilter, setVehcileViewfilter] = useState<any>(null);
    const [filtervehicleid, setfiltervehicleid] = useState<any>('');
    const [selectedValue, setSelectedValue] = useState<any>({});
    const [items, setItems] = useState<any>(null);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<any>('');
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredData, setfilteredData] = useState<any>([]);
    const [selectedServiceContract, setSelectedServiceContract] = useState<any>('');
    params = selectedItems.length > 0 && `&id=${selectedItems[0].id}&vid=${selectedItems[0].vehicleid}&type=${selectedItems[0].renewaltype}`;
    const sortOptions = [
        { label: 'Show All', value: '-' },
        { label: 'Active', value: 'Active' },
        { label: 'Completed', value: 'Completed' },
        { label: 'In Progress', value: 'In Progress' }
    ];
    const sortViewOptions = [
        { label: 'Show All Renewals', value: '-' },
        { label: 'Upcoming Renewals', value: 'Upcoming Renewals' },
        { label: 'Expired Renewals', value: 'Expired Renewals' }
    ];
    useEffect(() => {
        getData();
    }, []);

    const getData = async (nextToken?: any) => {
        // let variables: any = {};
        let variables: any = {
            filter: {
                renewaltype: {
                    eq: 'ServiceContract' // filter priority = 1
                }
            }
        };

        if (nextToken) {
            variables.nextToken = nextToken;
        }

        let GetResponse: any = await API.graphql({
            query: listDxContracts,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: variables
        });

        let res = GetResponse.data.listDxContracts.items;
        if (GetResponse.data.listDxContracts.nextToken) {
            // const nextResults = await getData(GetResponse.data.listDxContracts.nextToken);

            const nextResults: any = await getData(GetResponse.data.listDxContracts.nextToken);

            res = res.concat(nextResults);
        }

        let finalres: any = res
            .filter((item: any) => item !== undefined && item._deleted !== true)
            .sort(function (a: any, b: any) {
                return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
            });
        setItems(finalres);
        setfilteredData(formatDatefield(finalres));
        setLoading(false);
        setItems(formatDatefield(finalres));

        return finalres;
    };
    useEffect(() => {
        if (items !== null && items.length > 0) {
            let vs = Vehiclestatuskey;

            let txtvid = filtervehicleid;
            let view = VehcileViewfilter;

            let searchkeys: any = {};
            if (txtvid != '' && txtvid != undefined) searchkeys['vehicleid'] = { value: txtvid, condition: 'includes' };
            if (vs != undefined && vs != null && vs != '-') searchkeys['renewalstatus'] = { value: vs, condition: 'eq' };
            if (view !== null && view == 'Upcoming Renewals') {
                searchkeys['expirydate'] = { value: view, condition: 'Upcoming' };
            } else if (view !== null && view == 'Expired Renewals') {
                searchkeys['expirydate'] = { value: view, condition: 'Expired' };
            }

            // if (view != undefined && view != null && view != '-') {
            // }
            // if (view != undefined && view != null && view != '-') {

            // }
            // if (Ct != undefined && Ct != null && Ct != '-') searchkeys['renewaltype'] = { value: Ct, condition: 'eq' };

            let fr = items
                .sort(function (a: any, b: any) {
                    return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
                })
                .filter((item: any) => {
                    return Object.keys(searchkeys).every((a: any) => {
                        if (searchkeys[a].condition == 'eq') return item[a] !== null && searchkeys[a].value !== null && item[a].toLowerCase() == searchkeys[a].value.toLowerCase();
                        if (searchkeys[a].condition == 'includes') {
                            return item[a] !== null && searchkeys[a].value !== null && item[a].toLowerCase().includes(searchkeys[a].value.toLowerCase());
                        }
                        if (searchkeys[a].condition == 'Upcoming') {
                            let expdate = item[a];
                            let today = new Date();

                            const next15days = new Date(today.getTime() + 16 * 24 * 60 * 60 * 1000);
                            const next7days = new Date(today.getTime() + 8 * 24 * 60 * 60 * 1000);
                            //searchkeys[a].value.toLowerCase()
                            if (item.renewaltype == 'Registration' && moment(item.expirydate).isBetween(new Date(), next15days) && item.renewalstatus !== 'Completed') {
                                return true;
                            }
                            if (item.renewaltype == 'Insurance' && moment(item.expirydate).isBetween(new Date(), next15days) && item.renewalstatus !== 'Completed') {
                                return true;
                            }
                            if (item.renewaltype == 'ServiceContract' && moment(item.expirydate).isBetween(new Date(), next15days) && item.renewalstatus !== 'Completed') {
                                return true;
                            }
                            if (item.renewaltype == 'Warranty' && moment(item.expirydate).isBetween(new Date(), next15days) && item.renewalstatus !== 'Completed') {
                                return true;
                            }
                            if (item.renewaltype == 'Parking' && moment(item.expirydate).isBetween(new Date(), next7days) && item.renewalstatus !== 'Completed') {
                                return true;
                            }
                            if (item.renewaltype == 'Branding' && moment(item.expirydate).isBetween(new Date(), next7days) && item.renewalstatus !== 'Completed') {
                                return true;
                            }
                            // return expdate >= today && expdate <= next15days;
                        }
                        if (searchkeys[a].condition == 'Expired') {
                            let expdate = item[a];
                            let today = new Date();
                            //searchkeys[a].value.toLowerCase()

                            return expdate < today && moment(expdate).format('MMM-DD-YYYY') !== moment(new Date(1970, 0, 1)).format('MMM-DD-YYYY') && item.renewalstatus !== 'Completed';
                        }
                        return false;
                    });
                });

            setfilteredData(fr);
            setLoading(false);
        }
    }, [filtervehicleid, Vehiclestatuskey, VehcileViewfilter]);
    const formatDatefield = (data: any) => {
        return [...(data || [])].map((d) => {
            d.issuedate = new Date(d.issuedate);
            d.expirydate = new Date(d.expirydate);
            return d;
        });
    };
    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };

    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };
    const handleChange = (e: any) => {
        setSelectedValue({ ...selectedValue, [e.target.name]: e.target.value });
        setSelectedServiceContract(e.target.value);
    };

    const issueDateBodyTemplate = (e: any) => {
        if (e.issuedate !== null) {
            let issueDate = e.issuedate;

            return <>{issueDate && new Date(issueDate).toDateString()}</>;
        }
    };
    const expiryDateBodyTemplate = (e: any) => {
        if (e.expirydate !== null) {
            let expiry = e.expirydate;
            return <>{expiry && new Date(expiry).toDateString()}</>;
        }
    };
    const contractStatusBodyTemplate = (e: any) => {
        if (e.renewalstatus !== null) {
            let status = e.renewalstatus;
            return <>{status && <span className={`customer-badge status-${status.replaceAll(' ', '')}`}>{status}</span>}</>;
        }
    };
    const columns = [
        // { fieldtype: 'multiselect' },
        { field: 'vehicleid', header: 'Vehicle', textalign: 'left', fieldtype: 'navigatedocument', filterOption: true, filterFieldOption: 'vehicleid' },
        // { field: 'model', header: 'Model', fieldtype: 'text', filterOption: true, filterFieldOption: 'model' },
        {
            field: 'renewaltype',
            header: 'Renewal Type',
            textalign: 'left',
            fieldtype: 'text',
            filterOption: true
            //  template: (r: any) => <>{serviceTypeBodyTemplate(r)} </>
        },
        {
            field: 'issuedate',
            header: 'Issue Date',
            fieldtype: 'datefilter',
            textalign: 'left',
            dataType: 'date',
            filterOption: true,
            filterFieldOption: 'issuedate',
            template: (r: any) => <>{issueDateBodyTemplate(r)} </>
        },
        {
            field: 'expirydate',
            header: 'Expiry Date',
            fieldtype: 'hightlightdatefilter',
            textalign: 'left',
            filterOption: true,
            dataType: 'date',
            filterFieldOption: 'expirydate',
            template: (r: any) => <>{expiryDateBodyTemplate(r)}</>
        },
        {
            field: 'renewalstatus',
            header: 'Status',
            fieldtype: 'custom',
            textalign: 'left',
            filterOption: true,
            filterFieldOption: 'status',
            template: (r: any) => <>{contractStatusBodyTemplate(r)}</>
        },
        { field: 'updatedAt', header: 'Updated At', fieldtype: 'date', textalign: 'left', filterOption: false, headeralign: 'left', bodyalign: 'left', filterFieldOption: 'updatedAt' }
    ];

    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text companyBtn" onClick={() => deleteItem()} />
        </>
    );

    const deleteItem = async () => {
        Promise.all(
            selectedItems.map(async (item: any) => {
                let deleteData = {
                    id: item.id,

                    _version: item._version
                };
                let DeleteContract = await API.graphql(
                    graphqlOperation(deleteDxContract, {
                        input: deleteData
                    })
                );
                // let deleteContract = JSON.parse(item.expiring);
                // let contractIssueDate = JSON.parse(item.expiring);
                // await AmplifyServices.getFilteredTableDetails(listDxContracts, 'listDxContracts', item.id).then(async (res: any) => {
                //     let contracts = res.filter((vehicle: any) => vehicle._deleted !== true && vehicle.issuedate === contractIssueDate[selectedValue[item.id]]['issueDate']);
                //     if (contracts.length > 0) {
                //         const objData = Object.assign({}, ...contracts);
                //         let deleteData = {
                //             id: objData.id,
                //             issuedate: objData.issuedate,
                //             _version: objData._version
                //         };
                //         let DeleteContract = await API.graphql(
                //             graphqlOperation(deleteDxContract, {
                //                 input: deleteData
                //             })
                //         );
                //     }
                // });

                // // delete from vehicle expiring column
                // delete deleteContract[selectedValue[item.id]];
                // let deleteItem = {
                //     id: item.id,
                //     fleettype: item.fleettype,
                //     _version: item._version,
                //     expiring: JSON.stringify(deleteContract)
                // };
                // let DeleteDetails = await API.graphql(
                //     graphqlOperation(updateDxVehicle, {
                //         input: deleteItem
                //     })
                // );

                getData();
                hideDeleteItemsDialog();
                setSelectedItems([]);
                selectedItems.length = 0;
            })
        );
        // AmplifyServices.DeleteItems(selectedItems, deleteDxVehicle).then((res) => {
        //     getData();
        //     hideDeleteItemsDialog();
        //     setSelectedItems([]);
        //     selectedItems.length = 0;
        // });
    };
    const clearbtn = () => {
        setGlobalFilter('');
    };
    const SearchOnvehiclechange = (e: any) => {
        setfiltervehicleid(e.target.value);
    };
    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New" onClick={() => navigate('/manage/documents/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />

                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />

                <Menu
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedItems.length === 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                navigate('/manage/renewals/item/basic?mode=edit' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length > 0 ? false : true,
                            label: `Delete ${selectedServiceContract}`,
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            {selectedItems && selectedItems.length > 0 && <span>{selectedItems.length + ' of ' + filteredData.length + ' selected'}</span>}

            {/* <div>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <form>
                        <div
                            style={{
                                position: 'relative',
                                marginTop: '3px',
                                marginBottom: '1em'
                            }}
                        >
                            <InputText type="text" value={globalFilter} id={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
                            {globalFilter.length > 0 ? (
                                <button type="button" className="contentBox__searchClearBtn" onClick={clearbtn}>
                                    X
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </form>
                </span>
            </div> */}
            <div className="grid grid-nogutter ">
                <div className="col-12 p-fluid" style={{ textAlign: 'left' }}>
                    <div className="formgrid grid" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="ddsearchstatus" value={VehcileViewfilter} options={sortViewOptions} optionLabel="label" placeholder="Filter by view" onChange={(e) => setVehcileViewfilter(e.value)} />
                                <label htmlFor="ddsearchstatus">Renewal Views</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="ddsearchstatus" value={Vehiclestatuskey} options={sortOptions} optionLabel="label" placeholder="Filter by Document Status" onChange={(e) => setVehiclestatuskey(e.value)} />
                                <label htmlFor="ddsearchstatus">Renewal Status</label>
                            </span>
                        </div>
                        {/* <div className="field col">
                                    <span className="p-float-label">
                                        <Dropdown id="ddsearchstatus" value={filterServiceContract} options={ContractType} optionLabel="label" placeholder="Filter by Document Status" onChange={onSCChange} />
                                        <label htmlFor="ddsearchstatus">Document Status</label>
                                    </span>
                                </div> */}
                        <div className="field col">
                            <span className="p-float-label">
                                <input value={filtervehicleid} type="text" id="txtsearchvehicleid" onChange={SearchOnvehiclechange} placeholder="Search Vehicle id..." className="p-inputtext p-component homeNews__search" />
                                <label htmlFor="txtsearchvehicleid">Vehicle Id</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // let filteredSearch =
    //     items &&
    //     items
    //         .sort(function (a: any, b: any) {
    //             return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
    //         })
    //         .filter((del: any) => del._deleted !== true)
    //         .filter((name: any) => {
    //             if (
    //                 (name.vehicleid !== null && name.vehicleid.toLowerCase().includes(globalFilter.toLowerCase())) ||
    //                 // ||
    //                 // (name.model !== null && name.model.toLowerCase().includes(globalFilter.toLowerCase())) ||
    //                 // (name.plateno !== null && name.plateno.toLowerCase().includes(globalFilter.toLowerCase())) ||
    //                 (name.renewalstatus !== null && name.renewalstatus.toLowerCase().includes(globalFilter.toLowerCase()))
    //             ) {
    //                 return name;
    //             }
    //         });
    return (
        <div className="grid crud">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Contract</h4>
                <div className="card service-table">
                    <ListTable
                        header="Manage Contracts"
                        value={filteredData}
                        paginator={true}
                        rows={100}
                        dynamicColumns={columns}
                        emptyMessage="No Service Contracts found."
                        responsiveLayout="scroll"
                        className="p-datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Service Contracts"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[100, 200, 300]}
                        //   selection={selectedProducts}
                        multiselect={true}
                        loading={loading}
                        Commandbar={Commandbar}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}
                        selectedItems={setSelectedItems}
                    />
                    <Dialog visible={deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedItems && <span>Are you sure you want to delete the selected items?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ManageServiceContract;
