import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import './Vehicleform.css';
import AmplifyServices from '../../Service/Service';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import { listDxGeneralConfigs, listDxDepartments } from '../../graphql/queries';
import { Storage } from 'aws-amplify';
import moment from 'moment';

import { TabMenu } from 'primereact/tabmenu';
import AllDocuments from './Documents';
import Logs from './Logs';
import Allocation from './Allocation';
import AllPictures from './Pictures';
import VehicleRenewals from './Renewals';
import FormTemplate from '../../Components/FormTemplate';
import dxService from '../../Services/dxService';
import { getNextQueryData, getvendoroptions, isotolocaledatetime, removeDuplicates } from '../../utils/helper';
import { AuthContext } from '../../AuthContext';
import awsExports from '../../aws-exports';
import Loader from '../../Components/Loader';

var data: any = [];
var Documents: any = [];
var DocumentsObject: any = {};
var listFormData: any = {};
var wizardItems: any;
let UploadVechilePicture: any = [];
const NewVehicle = () => {
    const { dispatch } = useContext(AuthContext);
    const [startDate, setstartDate] = useState<any>('');

    const [EndDate, setEndDate] = useState<any>(null);
    const [Year, setYear] = useState<any>(null);
    const [File, setFile] = useState<any>('');
    const [dropdownItem, setDropdownItem] = useState<any>('');
    const [ShowDocuments, setShowDocuments] = useState(false);
    const [listFormData, setlistFormData] = useState<any>({});
    const [Data, setData] = useState<any>([]);
    const [updatedata, setupdatedata] = useState<any>([]);
    const [displayDialog, setdisplayDialog] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [VendornameOptions, setVendornameOptions] = useState<any>([]);
    const [filename, setfilename] = useState('');
    const [files, setfiles] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [DepartmentOptions, setDepartmentOptions] = useState<any>([]);
    const [VehicleMakename, setVehicleMakename] = useState<any>([]);
    const [VehicleModelname, setVehicleModelname] = useState<any>([]);
    const [selectedmakeDropdown, setselectedmakeDropdown] = useState<any>(null);

    const [oldjField, setoldjField] = useState<any>([]);

    const toast = useRef<any>(null);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;

    var editId = urlParams.has('id') ? urlParams.get('vid') : '';
    // var editId = location?.search.split('&')[1];
    var Param = location?.search.split('&')[0].split('?form=')[1];

    const dropdownItems = [
        { name: 'Registration / Milkia', code: 'Registration' },
        { name: 'Service Contracts', code: 'ServiceContract' },
        { name: 'Milkia Insurance Returns', code: 'Insurance' },
        { name: 'RTA Test documents', code: 'RTADocuments' },
        { name: 'Warranty documents', code: 'Warranty' }
    ];
    const navigate = useNavigate();
    const NavigateToHome = () => navigate('/manage/vehicles');

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
        formid = urlParams.has('id') ? urlParams.get('id') : null;
        const paths = location.pathname.split('/');
        const currentPath = paths[paths.length - 1];
        // console.log(formid);

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'pictures':
                setActiveIndex(2);
                break;
            case 'logs':
                setActiveIndex(3);
                break;
            case 'Allocations':
                setActiveIndex(4);
                break;
            default:
                if (formid) getvehicledata(formid);
                break;
        }
    }, [location]);

    useEffect(() => {
        getvendoroptions().then((resp: any) => {
            setVendornameOptions(resp);
        });
        checkActiveIndex();
    }, [checkActiveIndex]);
    // console.log(listFormData);

    useEffect(() => {
        let cols = ManagevehicleColumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });
        // console.log(cols);
        // console.log(listFormData);
        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);

    useEffect(() => {
        getMakeModel();
        getData();
    }, []);
    const getData = async () => {
        await AmplifyServices.getTableDetails(listDxDepartments, 'listDxDepartments').then(async (res: any) => {
            setDepartmentOptions(res);
        });
    };
    const getvehicledata = async (fid: any) => {
        let ac = `id make model VehicleStatus createdAt fleettype departmentid departmentname drivername dxContractVehicleId dxDepartmentVehiclesId enddate events expanses expiring permittype
platecode plateno policyno salikno ImagePath capacity fuelno updatedAt salikpin startdate vowner chasisno status year insuranceno registrationno scno scvendor jfield _version`;

        let fetchvehicle = await dxService.getitembyid('vehicle', fid, ac);
        // console.log(fetchvehicle);
        setoldjField(fetchvehicle);
        let vehiclephoto = '';
        if (fetchvehicle.jfield && JSON.parse(fetchvehicle.jfield).vehiclepicture) {
            vehiclephoto = await Storage.get(JSON.parse(fetchvehicle.jfield).vehiclepicture);
            // console.log(vehiclephoto);

            // console.log(vehiclephoto.split('?')[0]);
        }
        setlistFormData({
            make: fetchvehicle.make,
            model: fetchvehicle.model,
            year: fetchvehicle.year,
            id: fetchvehicle.id,
            capacity: fetchvehicle.capacity,
            chasisno: fetchvehicle.chasisno,
            dxDepartmentVehiclesId: fetchvehicle.dxDepartmentVehiclesId,
            platecode: fetchvehicle.platecode,
            plateno: fetchvehicle.plateno,
            departmentname: fetchvehicle.departmentname,
            salikno: fetchvehicle.salikno,
            salikpin: fetchvehicle.salikpin,
            scno: fetchvehicle.scno,
            scvendor: fetchvehicle.scvendor,
            vowner: fetchvehicle.vowner,
            ImagePath: fetchvehicle.ImagePath,
            fuelno: fetchvehicle.fuelno,
            status: fetchvehicle.status !== null && fetchvehicle.status == 'Active' ? true : false,
            fleettype: fetchvehicle.fleettype !== null && fetchvehicle.fleettype == 'Rented' ? true : false,
            insuranceno: fetchvehicle.insuranceno,
            registrationno: fetchvehicle.registrationno,
            createdAt: isotolocaledatetime(fetchvehicle.createdAt),
            updatedAt: isotolocaledatetime(fetchvehicle.updatedAt),
            // vehicleimage: vehiclephoto ? vehiclephoto.split('?')[0] : '',
            vehicleimage: vehiclephoto,
            startdate: isotolocaledatetime(fetchvehicle.startdate),
            enddate: isotolocaledatetime(fetchvehicle.enddate),
            _version: fetchvehicle._version
        });
    };
    // console.log(listFormData);

    const getMakeModel = async () => {
        await AmplifyServices.getTableDetails(listDxGeneralConfigs, 'listDxGeneralConfigs').then(async (res: any) => {
            let Make = res.filter((dept: any) => dept.fleettype.includes('Make'));
            let Model = res.filter((dept: any) => dept.fleettype.includes('Model'));
            // console.log(res, Model);

            setVehicleMakename(Make);
            setVehicleModelname(Model);
        });
    };
    const addDocToDB = async (files: any) => {
        // console.log('adding document', files);
        let createDocument = await dxService.createitem('document', files, false);
    };

    const editnewvehicle = async (formdata: any, fid: string) => {
        // console.log(formdata);
        console.log(formdata, fid);
        // console.log(ManagevehicleColumns);
        setdisplayDialog(true);
        let vehicleImageObj: any = {};
        let b: any = formdata;
        let filterva = {
            id: {
                eq: b.id
            }
        };
        await getNextQueryData('', filterva, 'vehicleactivity').then(async (resp: any) => {
            // console.log(resp);
            let updateva = {
                id: resp[0].id,
                VehicleStatus: b.status == true ? 'Active' : 'InActive',
                _version: resp[0]._version
            };
            await dxService.updateitem('vehicleactivity', updateva);
        });
        let a = ManagevehicleColumns.columns.filter((e: any) => {
            return e.fieldtype == 'date' || e.fieldtype == 'datetime';
        });
        // console.log(a);
        a.map((_a: any) => {
            // console.log(b[_a.name]);
            b[_a.name] = b[_a.name] ? new Date(b[_a.name]).toISOString() : null;
        });
        if (b.vehicleimage && b.vehicleimage.file && b.vehicleimage.file.length > 0) {
            for (let i = 0; i < b.vehicleimage.file.length; i++) {
                // console.log(b.documents);

                //getting the document upload names and creating the own object
                vehicleImageObj = new Object({ file: b.vehicleimage.file[i], type: 'picture', path: `vehicle/${b.make}/${new Date().getFullYear()}/${b.id}/images/` });
                //pushing an object in an UploadVechilePicture array
                UploadVechilePicture.push(vehicleImageObj);
            }
        }

        let updatevehicle: any = {
            id: formdata.id,
            platecode: formdata.platecode,
            plateno: formdata.plateno,
            make: formdata.make,
            capacity: formdata.capacity,
            status: formdata.status == true ? 'Active' : 'InActive',
            fleettype: formdata.fleettype == true ? 'Rented' : '',
            // status: formdata.status,
            chasisno: formdata.chasisno,
            salikno: formdata.salikno,
            salikpin: formdata.salikpin,
            fuelno: formdata.fuelno,
            vowner: formdata.vowner,
            year: formdata.year,
            model: formdata.model,
            policyno: formdata.policyno,
            startdate: formdata.startdate,
            enddate: formdata.enddate,
            registrationno: formdata.registrationno,
            dxDepartmentVehiclesId: formdata.dxDepartmentVehiclesId,
            insuranceno: formdata.insuranceno,
            scno: formdata.scno,
            scvendor: formdata.scvendor,
            _version: formdata._version
        };

        const uniqueDriverPictures = removeDuplicates(UploadVechilePicture, 'file');
        // console.log(oldjField);

        if (uniqueDriverPictures.length > 0) {
            let jsondatajfield = oldjField.jfield ? JSON.parse(oldjField.jfield) : {};
            await Promise.all(
                uniqueDriverPictures.map(async (item: any) => {
                    console.log(item);
                    console.log(item.path, item.file.name, item.file);
                    await Storage.put(item.path + item.file.name, item.file).then(async (res: any) => {
                        item['fullpath'] = res.key;
                        jsondatajfield.vehiclepicture = res.key;
                        updatevehicle.jfield = JSON.stringify(jsondatajfield);
                        const files = {
                            name: res.key,
                            // dxAllocationsPicturesId: id,
                            s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                            resource: b.model,
                            resourceid: `plateno/${updatevehicle.plateno}`,
                            presourceid: updatevehicle.id,
                            presource: 'vehicle',
                            field1: `pictures`
                        };
                        addDocToDB(files);
                        updatevehicle['ImagePath'] = res.key;
                    });
                })
            ).then(() => {
                vehicleImageObj = {};
                UploadVechilePicture = [];
            });
        }
        await dxService
            .updateitem('vehicle', updatevehicle)
            .then((res: any) => {
                // console.log(res);

                showSuccess('Vehicle: ' + res.id, 'Vehicle updated successfully!');
                dispatch({ type: 'NAVIGATION', payload: 'Updated' + new Date() });
                navigate(`/manage/vehicles/all`);
            })
            .catch((err) => {
                console.log(err);
            });
        // console.log(updatevehicle);
    };
    const addnewvehicle = async (formdata: any) => {
        // console.log('edit data');
        console.log(formdata);
        // console.log(ManagevehicleColumns);
        setdisplayDialog(true);
        let vehicleImageObj: any = {};
        let a = ManagevehicleColumns.columns.filter((e: any) => {
            return e.fieldtype == 'date' || e.fieldtype == 'datetime';
        });
        let b: any = formdata;
        b['status'] = b.status == true ? 'Active' : 'InActive';
        b['fleettype'] = b.fleettype == true ? 'Rented' : '';
        b['id'] = b['platecode'] + b['plateno'];
        if (b.vehicleimage && b.vehicleimage.file && b.vehicleimage.file.length > 0) {
            for (let i = 0; i < b.vehicleimage.file.length; i++) {
                // console.log(b.documents);
                //getting the document upload names and creating the own object
                vehicleImageObj = new Object({ file: b.vehicleimage.file[i], type: 'picture', path: `vehicle/${b.make}/${new Date().getFullYear()}/${b.id}/images/` });
                //pushing an object in an UploadVechilePicture array
                UploadVechilePicture.push(vehicleImageObj);
            }
        }

        // console.log(a);
        a.map((_a: any) => {
            // console.log(b[_a.name]);
            b[_a.name] = b[_a.name] ? new Date(b[_a.name]).toISOString() : null;
        });

        Object.keys(b).forEach((key) => {
            if (b[key] == null || b[key] == '') {
                delete b[key];
            }
        });

        const uniqueDriverPictures = removeDuplicates(UploadVechilePicture, 'file');
        // console.log(oldjField);

        if (uniqueDriverPictures.length > 0) {
            let jsondatajfield = oldjField.jfield ? JSON.parse(oldjField.jfield) : {};
            await Promise.all(
                uniqueDriverPictures.map(async (item: any) => {
                    console.log(item);

                    await Storage.put(item.path + item.file.name, item.file).then(async (res: any) => {
                        item['fullpath'] = res.key;
                        jsondatajfield.vehiclepicture = res.key;
                        b.jfield = JSON.stringify(jsondatajfield);
                        const files = {
                            name: res.key,
                            // dxAllocationsPicturesId: id,
                            s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                            resource: b.model,
                            resourceid: `plateno/${b.plateno}`,
                            presourceid: b.id,
                            presource: 'vehicle',
                            field1: `pictures`
                        };
                        addDocToDB(files);
                        b['ImagePath'] = res.key;
                    });
                })
            ).then(() => {
                vehicleImageObj = {};
                UploadVechilePicture = [];
            });
        }

        // console.log(b);
        delete b.vehicleimage;
        let vehicle = await dxService.createitem('vehicle', b, false).then(async (res: any) => {
            let vehicleactivity = {
                id: res.id,
                // fleettype: 'vehicle#activity',

                VehicleStatus: res.status,
                dxVehicleVehicleactivityId: res.id
            };
            let o = await dxService.createitem('vehicleactivity', vehicleactivity, false);
            // console.log(o);
            dispatch({ type: 'NAVIGATION', payload: 'Updated' + new Date() });
            showSuccess('Vehicle: ' + res.id, 'Vehicle added Successfully!');
            navigate(`/manage/vehicles/all`);
            // navigate(`/manage/vehicles/item/basic?mode=edit&id=${formdata.id}&fleettype=vehicle`);
        });
    };

    const addVehicle = (props: any) => {
        // console.log(props);
        //setlistFormData(props);
        //console.log(listFormData);
        if (formid == null) addnewvehicle(props);
        else {
            editnewvehicle(props, formid);
        }
    };

    const showSuccess = (summary: string, detail: string) => {
        toast.current.show({
            severity: 'success',
            summary: summary,
            detail: detail,
            life: 3000
        });
    };

    const callchangedata = () => {
        // console.log(updatedata);
    };

    const changevehicledata = (props: any) => {
        setupdatedata(props);
        setselectedmakeDropdown(updatedata.make);
        // console.log(props);
    };
    const openDocument = (props: any) => {
        setlistFormData(props);
        setShowDocuments(true);

        DocumentsObject.filename = filename;
        DocumentsObject.filepath = files;

        // Documents.push({ Filename: 'Vehiclepicture#' + filename, Filepath: files });
    };

    const save = () => {
        // setState(prevState => ({
        //     ...prevState,
        //     fName: 'your updated value here'
        //  }));
        var DocumentType = dropdownItem.name;
        var DocumentTypeName = dropdownItem.code;
        var FileType = File;
        var year = Year;
        var startdate = startDate;
        var enddate = EndDate;

        // console.log(DocumentType,FileType);

        if (DocumentType !== undefined && FileType !== '') {
            var uniqueIds: any = [];
            DocumentsObject.filename = File.name;
            DocumentsObject.filepath = File;

            let PrefixImage = '';

            let FormatStartDate = startDate != '' ? moment(startDate).format('yyyy') : '-';
            // moment(startDate).format('yyyy')
            if (DocumentType == 'Registration / Milkia') {
                PrefixImage = `Registration#${FormatStartDate}#`;
            } else if (DocumentType == 'Service Contracts') {
                PrefixImage = `ServiceContract#${FormatStartDate}#`;
            } else if (DocumentType == 'Milkia Insurance Returns') {
                PrefixImage = `Insurance#${FormatStartDate}#`;
            } else if (DocumentType == 'RTA Test documents') {
                PrefixImage = `RTADocuments#${FormatStartDate}#`;
            } else if (DocumentType == 'Waranty documents') {
                PrefixImage = `Waranty#${FormatStartDate}#`;
            }

            Documents.push({ Filename: PrefixImage + File.name, Filepath: File, documentType: DocumentTypeName, issueDate: startDate, expiryDate: enddate });

            var filterdocumenttype = data.filter((data: any) => data.documentType.includes(DocumentType));

            if (filterdocumenttype.length > 0) {
                filterdocumenttype[0].File = FileType;
                let allobj = Object.assign({}, ...filterdocumenttype);
                data.push(allobj);

                const obj = { documentType: DocumentType, File: FileType, year: FormatStartDate, startdate: startdate, enddate: enddate };

                var alldata: any = data.push(obj);
                setData(data);
            } else {
                const obj = { documentType: DocumentType, File: FileType, year: FormatStartDate, startdate: startdate, enddate: enddate };
                var alldata: any = data.push(obj);

                setData(data);
            }

            setDropdownItem('');

            const file: any = document.querySelector('.file');
            file.value = '';
            setFile('');
            setYear('');
            setstartDate('');
            setEndDate('');
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Please fill the Document Type and Attachment',
                life: 3000
            });
        }
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Products</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DocumentTypeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.documentType}
            </>
        );
    };
    const AttachmentBodyTemplate = (rowData: any) => {
        var attachment =
            rowData.name !== undefined ? (
                <>
                    <span className="p-column-title">Code</span>
                    <a href={rowData.downLoadUrl}>{rowData.name}</a>
                </>
            ) : (
                <>
                    <span className="p-column-title">Code</span>
                    {rowData.File.name}
                </>
            );
        return <>{attachment}</>;
    };

    const vehicleMake: any = [];
    const Makename = VehicleMakename.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        // console.log(res);
        vehicleMake.push(res);
    });
    // console.log(VehicleModelname);

    const vehicleModel: any = [];
    const Modelname = VehicleModelname.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        // console.log(res);
        vehicleModel.push(res);
    });
    const DepartmentNameOption: any = [];
    const names = DepartmentOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.id;
        // console.log(res);
        DepartmentNameOption.push(res);
    });
    // console.log(vehicleModel, selectedmakeDropdown);
    // console.log(updatedata.make);

    // console.log(formmode != 'new' ? vehicleModel : updatedata.make != null ? vehicleModel.filter((res: any) => res.label.toLowerCase().includes(updatedata.make.toLowerCase())) : []);

    let ManagevehicleColumns = {
        columns: [
            {
                name: 'vehicleimage',
                value: '',
                title: 'Vehicle Image',
                fieldtype: 'upload',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header'
            },

            {
                title: '',
                name: '',
                fieldtype: 'break'
            },
            {
                name: 'make',
                title: 'Make',
                fieldtype: 'dropdown',
                required: true,
                value: '',
                hidden: false,

                disabled: ShowDocuments == true ? true : false,
                data: vehicleMake
            },
            {
                name: 'model',
                title: 'Model',
                value: '',
                fieldtype: 'dropdown',
                required: true,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                //data: selectedmakeDropdown !== null ? vehicleModel.filter((res: any) => res.label.toLowerCase().includes(selectedmakeDropdown.toLowerCase())) : []
                // data: updatedata.make != null ? vehicleModel.filter((res: any) => res.label.toLowerCase().includes(updatedata.make.toLowerCase())) : []
                data: vehicleModel
            },
            {
                name: 'year',
                title: 'Vehicle Year',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'platecode',
                title: 'PlateCode',
                value: '',
                fieldtype: 'text',
                required: true,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'plateno',
                title: 'PlateNumber',
                value: '',
                fieldtype: 'text',
                required: true,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'id',
                title: 'License Plate',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: true,
                hidden: false,
                data: []
            },
            {
                name: 'capacity',
                title: 'Capacity',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },

            // {
            //     name: 'status',
            //     title: 'Status',
            //     value: 'Active',
            //     fieldtype: 'text',
            //     required: false,
            //     disabled: ShowDocuments == true ? true : true,
            //     hidden: false,
            //     data: [
            //         { label: 'Active', value: 'Active' },
            //         { label: 'Inactive', value: 'Inactive' }
            //     ]
            // },
            {
                name: 'dxDepartmentVehiclesId',
                value: '',
                title: 'Department',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: DepartmentNameOption
            },
            {
                name: 'status',
                title: 'isActive',
                value: '',
                fieldtype: 'switch',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'fleettype',
                title: 'isRented?',
                value: '',
                fieldtype: 'switch',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                title: 'Card Details',
                name: 'relateditems',
                fieldtype: 'header'
            },
            {
                name: 'chasisno',
                title: 'Chassis No',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'salikno',
                title: 'Salik Number',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'salikpin',
                title: 'Salik PIN',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },

            {
                name: 'fuelno',
                title: 'Fuel Card Number',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'insuranceno',
                title: 'Insurance Number',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'registrationno',
                title: 'Registration Number',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'scno',
                title: 'Service Contract Number',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'scvendor',
                title: 'SC Garage',
                value: '',
                fieldtype: 'dropdown',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: VendornameOptions
            },
            {
                title: 'Others',
                name: 'others',
                fieldtype: 'header'
            },
            {
                name: 'startdate',
                title: 'Registration Date',
                value: '',
                fieldtype: 'datetime',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: formmode == 'new' ? true : listFormData.startdate == null ? true : false,
                data: []
            },
            {
                name: 'enddate',
                title: 'Expiry Date',
                value: '',
                fieldtype: 'datetime',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: formmode == 'new' ? true : listFormData.startdate == null ? true : false,
                data: []
            },
            {
                name: 'vowner',
                title: 'Vehicle Owner',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: ShowDocuments == true ? true : false,
                hidden: false,
                data: []
            },

            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },
            {
                name: 'createdAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                labeltitle: 'Created At',
                fieldtype: 'label',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode == 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'updatedAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                labeltitle: 'Updated At',
                fieldtype: 'label',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode === 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            }
        ],
        actions: [
            {
                title: formmode != 'edit' ? 'Submit' : 'Save',
                disabled: formmode == 'view' ? true : false
            },
            {
                title: 'Cancel'
            }
        ],
        fleettype: 'vehicle'
    };

    let qs = '';
    let qsa = '';
    let qsaedit = '';
    let qsedit = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qsaedit = formid != null ? '?id=' + formid : '';
    qsaedit += formmode != null ? '&mode=' + 'edit' : '';
    qs = qsa != '' ? '?' + qsa : '';
    qsedit = qsaedit != '' ? '?' + qsaedit : '';

    const wizardItemsedit = [
        { label: 'Basic Details', command: () => navigate('/manage/vehicles/item/basic' + qs) },
        { label: 'Documents', command: () => navigate('/manage/vehicles/item/documents' + qs) },
        { label: 'Pictures', command: () => navigate('/manage/vehicles/item/pictures' + qs) },
        {
            label: 'Logs',
            command: () => navigate('/manage/vehicles/item/logs' + qs)
        },
        {
            label: 'Allocations',
            command: () => navigate('/manage/vehicles/item/allocations' + qs)
        },
        {
            label: 'Renewals',
            command: () => navigate('/manage/vehicles/item/renew' + qs)
        }
    ];
    const wizardItemsview = [
        { label: 'Basic Details', command: () => navigate('/manage/vehicles/item/basic' + qs) },
        { label: 'Documents', command: () => navigate('/manage/vehicles/item/documents' + qs) },
        { label: 'Pictures', command: () => navigate('/manage/vehicles/item/pictures' + qs) },
        {
            label: 'Logs',
            command: () => navigate('/manage/vehicles/item/logs' + qs)
        },
        {
            label: 'Allocations',
            command: () => navigate('/manage/vehicles/item/allocations' + qs)
        },

        {
            label: 'Renewals',
            command: () => navigate('/manage/vehicles/item/renew' + qs)
        },
        {
            label: 'Edit',
            icon: 'pi pi-fw pi-pencil',
            command: () => navigate('/manage/vehicles/item/basic' + qsaedit)
        }
    ];
    if (formmode == 'view') {
        wizardItems = wizardItemsview;
    } else if (formmode == 'edit' || formmode == 'new') {
        wizardItems = wizardItemsedit;
    }
    // const Changeparam = () => {
    //     search_params.set('id', '101');

    //     // change the search property of the main url
    //     url.search = search_params.toString();

    //     // the new url string
    //     var new_url = url.toString();

    //     // output : http://demourl.com/path?id=101&topic=main
    //     console.log(new_url);
    //     const setparams = urlParams.set('mode', 'edit');
    // };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{formmode === 'edit' ? 'Edit Vehicle : ' + formid : formmode === 'view' ? 'View Vehicle: ' + formid : 'New Vehicle'}</h4>

                {/* <div className="card card-w-title spd-pemptytopbottom">
                
                   
                    
                </div> */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast} />
                            <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                            {/* {formmode == 'view' ? (
                                <>
                                    {' '}
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info  mr-2" onClick={Changeparam} />
                                    </div>
                                </>
                            ) : (
                                ''
                            )} */}

                            <Routes>
                                <Route
                                    path={'/basic'}
                                    element={
                                        <>
                                            <FormTemplate
                                                bucketname="vehicle"
                                                Fleettype={'vehiclePicture'}
                                                columns={ManagevehicleColumns}
                                                data={listFormData}
                                                showaction={true}
                                                mode={formmode}
                                                onChange={changevehicledata}
                                                setselectedmakeDropdown={setselectedmakeDropdown}
                                                ChangeBtn={'OpenDocument'}
                                                layout="grid2"
                                                title="New vehicle"
                                                submitForm={addVehicle}
                                            />
                                            <Loader displayDialog={displayDialog} />
                                            {/* 
                                            <div className='grid'>
                                                <div className='col-12'>
                                                    <div className='spd-card-footer'>
                                                        <Button onClick={callchangedata} className="mr-2">
                                                            Submit
                                                        </Button>
                                                        <Button onClick={() => window.history.back()} className="p-button-danger">
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        */}
                                        </>
                                    }
                                />

                                <Route path={'/documents'} element={<AllDocuments mode={formmode} type={'pictures'} />} />
                                <Route path={'/pictures'} element={<AllPictures mode={formmode} type={'pictures'} />} />
                                <Route path={'/logs'} element={<Logs mode={formmode} />} />
                                <Route path={'/allocations'} element={<Allocation mode={formmode} />} />
                                <Route path={'/renew'} element={<VehicleRenewals mode={formmode} />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
            {ShowDocuments ? (
                <div className="col-12 md:col-6">
                    {/* {Param=='edit'?<VehicleEditForm data={AddDocuments} />:''} */}
                    <div className="card">
                        <h5>Documents</h5>

                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="name1">Document Type </label>
                                <Dropdown id="state" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} options={dropdownItems} optionLabel="name" placeholder="Select One"></Dropdown>
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">Start Date</label>
                                <Calendar showIcon showButtonBar value={startDate} onChange={(e) => setstartDate(e.value)}></Calendar>
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="name1">End Date</label>
                                <Calendar showIcon showButtonBar value={EndDate} onChange={(e) => setEndDate(e.value)}></Calendar>
                            </div>
                            <div className="field col-9 md:col-10">
                                <label htmlFor="email1">Attachment</label>

                                <form>
                                    {/* <label htmlFor="fileUpload">Upload file</label> */}
                                    <input type="file" className="file" id="fileUpload" onChange={(e: any) => setFile(e.target.files[0])} />
                                </form>
                            </div>
                            <div className="field col-2 md:col-2" style={{ width: 'auto' }}>
                                <label htmlFor="email1"></label>
                                <label htmlFor="email1"></label>
                                <Button className="mr-2 mb-2" onClick={save}>
                                    <i className="pi pi-plus" style={{ position: 'relative', left: '13px' }}></i>
                                </Button>
                            </div>
                            <div className="field col-12">
                                <div className="card">
                                    <div className="p-fluid formgrid grid">
                                        <div className="field col-12 md:col-12">
                                            <DataTable
                                                value={Data}
                                                selection={selectedProducts}
                                                onSelectionChange={(e) => setSelectedProducts(e.value)}
                                                dataKey="id"
                                                rowsPerPageOptions={[5, 10, 25]}
                                                className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                                globalFilter={globalFilter}
                                                emptyMessage="No Documents added."
                                                responsiveLayout="scroll"
                                            >
                                                <Column field="year" header="Year" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                                <Column field="documentType" header="Document Type" body={DocumentTypeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                                <Column field="File" header="Attachment" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field col-12 md:col-5"></div>
                            <div className="field col-12 md:col-4"></div>
                            <div className="field col-12 md:col-3">
                                {
                                    //FormBtn
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
export default NewVehicle;
