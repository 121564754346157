import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import { useEffect, useState } from "react";
import dxService from "../Services/dxService";

const DataGridTemplate = (props: any) => {
    const [data, setData] = useState<any>([]);
    const [columns, setColumns] = useState(props.columns);

    useEffect(() => {
        
        if(props.data){
            setData(props.data);
        }
    }, [props]);

    useEffect(() => {
        const newColumns = [
            ...columns,
            {
                field: "actions",
                headerName: "Actions",
                width: 130,
                renderCell: (params: any) => (
                    <>
                     <Button key={'btnu' + params.row.id} onClick={() => handlerowUpdate(params.row.id, params.row)} icon="pi pi-check" 
                     className="p-button-rounded p-button-success p-button-text  mr-2 mb-2" />
                     <Button icon="pi pi-times" 
                     className="p-button-rounded p-button-danger p-button-text mr-2 mb-2"  key={'btnd' + params.row.id} onClick={() => handleDelete(params.row.id)} />
                       
                    </>
                ),
            },
        ];
        setColumns(newColumns);
       
        getitems();
        console.log(props);
    }, []);

    const getitems = async () => {
        if (props.application) {
            console.log(props.application);
            let items = await dxService.listitems(props.application, '*');
            console.log(items);
            setData(items);
        }
       
    }

    const handleAdd = () => {
        const newData = { id: "new_" + (data.length + 1), name: "", description: "0", mask: "", groups: "" };
        setData([...data, newData]);
        console.log(data);
    };

    const addnewitem = async (value: any) => {
        let newitem = await dxService.createitem(props.application, value);
        return newitem;
    }

    const updateolditem = async (value: any) => {
        let newitem = await dxService.updateitem(props.application, value);
        return newitem;
    }
    const handlerowUpdate = (id: any, row: any) => {



        if (id.includes('new_')) {
            row['id'] = id.replace("new_", "");

            addnewitem(row).then((resp) => {
                setData([...data, resp]);
            });

        } else {


            setData((prevData: any) => {


                const newData = prevData.map(async (item: any) => {
                    if (item.id === id) {

                        if (Object.keys(row).includes('_lastChangedAt')) delete row['_lastChangedAt'];
                        if (Object.keys(row).includes('createdAt')) delete row['createdAt'];
                        if (Object.keys(row).includes('updatedAt')) delete row['updatedAt'];
                        if (Object.keys(row).includes('owner')) delete row['owner'];
                        if (Object.keys(row).includes('_deleted')) delete row['_deleted'];

                        let b = await updateolditem(row);

                        return b;
                    } else {

                        // let a = await addnewitem(row);
                        // return a;
                        console.log(row);
                        return row;

                    }
                });
                return newData

            });
        }




        // setData((prevData: any) => {
        //     const newData = prevData.map((item: any) => {
        //         if (item.id === id) {
        //             return row;
        //         } else {
        //             return item;
        //         }
        //     });
        //     return newData

        // })
    }

    const handleUpdate = (id: any, field: any, value: any) => {
        // const newData = data.map((item: any) => {
        //     if (item.id === id) {
        //         return { ...item, [field]: value };
        //     } else {
        //         return item;
        //     }
        // });
        // setData(newData);
        console.log(field);
        console.log(value);


        setData((prevData: any) => {
            const newData = prevData.map((item: any) => {
                if (item.id === id) {
                    return { ...item, [field]: value };
                } else {
                    return item;
                }
            });
            return newData

        })
    };

    const handleDelete = (id: any) => {
        // console.log(data);
        // const newData = data.filter((item: any) => item.id !== id);
        // setData(newData);

        setData((prevData: any) => {
            const newData = prevData.filter((item: any) => item.id !== id);
            console.log(newData);
            return newData;
        });
    };

    return (
        <React.Fragment>
            <div>
                <Button icon="pi pi-plus" className="p-button-rounded mr-2 mb-2" 
                 onClick={handleAdd}></Button>
                <table>
                    <thead>
                        <tr>
                            {columns.map((column: any) => (
                                <th key={column.field} style={{ width: column.width }}>
                                    {column.headerName}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item: any) => (
                            item.id &&

                            <tr id={'tr-' + item.id} key={item.id}>
                                {columns.map((column: any) => (
                                    <td key={`${item.id}-${column.field}`}>
                                        {column.field === "actions" ? (
                                            column.renderCell({ row: item })
                                        ) : (
                                            <InputText
                                           
                                                id={`txt-${item.id}-${column.field}`}
                                                value={item[column.field]}
                                                onChange={(e) => handleUpdate(item.id, column.field, e.target.value)}
                                            />
                                        )}
                                    </td>
                                ))}
                            </tr>

                        ))}

                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default DataGridTemplate;
