import React, { useState, useEffect, useRef, Children, useContext } from 'react';

import { Toast } from 'primereact/toast';
import { AuthContext } from '../../AuthContext';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../dxgraphql/dxqueries';



const Comingsoon = () => {
    const { dispatch } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [workflowitem, setworkflowitem] = useState<any>({});
    const [stepitem, setstepitem] = useState<any>({});
    const [appitem, setappitem] = useState<any>({});
    const [newdata, setnewdata] = useState<any>({});
    const toast = useRef(null);
    const [uname, setUname] = useState('');
    const [name, setname] = useState('');


    useEffect(() => {
        getdata();



    }, []);

    useEffect(() => {

    }, [appitem]);



    const changedata = (props: any) => {
        console.log(props);


    };

    const addData = (props: any) => {
        console.log(props);

    };





    const getdata = async () => {


    }


    return (

        <div className="grid">
            <div className="col-12 md:col-12">
                {/* <h4 className="container-header">Header Template</h4> */}

                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}
                            <h1 className="text-blue-600">Coming Soon</h1>
                            {/* END Inner COMPONENT HTML */}

                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}




            </div>
        </div>

    );
};
export default Comingsoon;

