import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useEffect, useRef, useState } from 'react';
import AmplifyServices from '../../Service/Service';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createDxGeneralConfig, updateDxGeneralConfig } from '../../graphql/mutations';
import { listDxGeneralConfigs, listDxTableSettings } from '../../graphql/queries';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import ListForm from '../../Components/ListForm';
import Documents from './Documents';
import dxService from '../../Services/dxService';
import FormTemplate from '../../Components/FormTemplate';
import { Amplify, Storage } from 'aws-amplify';
const Configsform = () => {
    let fleetOptions = [
        {
            label: 'Make',
            value: 'Make'
        },
        {
            label: 'Model',
            value: 'Model'
        },
        {
            label: 'Log',
            value: 'Log'
        },
        {
            label: 'Location',
            value: 'Location'
        },

        {
            label: 'Event',
            value: 'Event'
        },
        {
            label: 'Renewal Type',
            value: 'Renewal Type'
        },
        {
            label: 'Navigation',
            value: 'Navigation'
        },
        {
            label: 'Parts',
            value: 'Parts'
        },
        {
            label: 'Accident',
            value: 'Accident'
        },
        {
            label: 'Vendors',
            value: 'Vendors'
        },
        {
            label: 'Licence Type',
            value: 'licencetype'
        }
    ];
    let tableOptions = [
        {
            label: 'Color',
            value: 'Color'
        }
    ];
    const [documentpath, setdocumentpath] = useState<any>([]);
    const [updatedata, setupdatedata] = useState<any>([]);
    const [listFormData, setlistFormData] = useState<any>({});
    const [gets3, sets3] = useState<any>([]);
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<any>(null);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode: any = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid: any = urlParams.has('id') ? urlParams.get('id') : null;

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
        formid = urlParams.has('id') ? urlParams.get('id') : null;
        const paths = location.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'pictures':
                setActiveIndex(1);
                break;

            default:
                if (formmode == 'tableedit') gettabledata(formid);
                else if (formid) getcongifsdata(formid);

                break;
        }
    }, [location]);
    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);
    console.log(activeIndex);
    const gettabledata = async (fid: any) => {
        console.log(fid);
        await AmplifyServices.getFilterbyIddetails(listDxTableSettings, 'listDxTableSettings', fid).then(async (a) => {
            console.log(a);

            setlistFormData({
                column: a[0].column,
                field1: a[0].field1,
                table: a[0].table,
                id: a[0].id,
                _version: a[0]._version
            });
        });
    };
    const getcongifsdata = async (fid: any) => {
        let logo = '';
        await AmplifyServices.getFilterbyIddetails(listDxGeneralConfigs, 'listDxGeneralConfigs', fid).then(async (a) => {
            if (a.length > 0) {
                // console.log(a[0]);
                logo = await Storage.get(a[0].images);
                // console.log(logo);

                setlistFormData({
                    fleettype: a[0].fleettype,
                    name: a[0].name,
                    isactive: a[0].isactive,
                    images: a[0].images !== null ? logo : a[0].images,
                    id: a[0].id,
                    jfield: a[0].jfield,
                    _version: a[0]._version,
                    date: a[0].date,
                    documents: a[0].documents
                });
            }
        });
        // let getlogos = await Storage.list('images/logo/');
        // console.log(getlogos);
        // sets3(getlogos.results);
    };
    const changeconfigsdata = (props: any) => {
        // console.log(props);

        setupdatedata(props);
    };
    useEffect(() => {
        let cols = formColumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });
        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);

    const formColumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header'
            },
            {
                name: 'name',
                value: '',
                title: 'Name',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'fleettype',
                value: '',
                title: 'Type',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: fleetOptions
            },

            // { name: 'date', value: '', title: 'Date', fieldtype: 'datetime', required: false, disabled: false, hidden: false, data: [] },
            {
                name: 'isactive',
                value: true,
                title: 'Active?',
                fieldtype: 'switch',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'images',
                value: '',
                title: 'Upload logo',
                fieldtype: 'upload',
                required: false,
                disabled: false,
                hidden: updatedata.fleettype == 'Make' || listFormData.fleettype == 'Make' ? false : true,
                data: []
            }
        ],
        getTable: listDxGeneralConfigs,
        createTable: createDxGeneralConfig //Pass update table name
    };
    const tablecolumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header'
            },
            {
                name: 'column',
                value: '',
                title: 'Name',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'field1',
                value: '',
                title: 'Value',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                name: 'table',
                value: '',
                title: 'Type',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: tableOptions
            }
        ],
        getTable: listDxGeneralConfigs,
        createTable: createDxGeneralConfig //Pass update table name
    };
    const submitForm = async (data: any) => {
        let getlogos = await Storage.list('images/logo/');

        let gets3 = getlogos.results;
        console.log(data, gets3);
        var Uploadedfiles: any = [];
        let vehicleImageObj: any = {};

        // console.log(Uploadedfiles);
        if (data.images && data.images !== null) {
            if (data.images.file) {
                for (let i = 0; i < data.images.file.length; i++) {
                    vehicleImageObj = new Object({ file: data.images.file[i], type: 'picture', path: 'images/logo/' });
                    Uploadedfiles.push(vehicleImageObj);
                }
            }
            if (data.images) {
                let filters3: any = gets3.filter((item: any) => item.key.toLowerCase().includes(data.name.toLowerCase()));
                // console.log(filters3);
                if (filters3.length > 0) {
                    await Storage.remove(filters3[0].key).then(async (res) => {
                        // console.log('removed completed');
                        addconfig(Uploadedfiles, data);
                    });
                } else {
                    addconfig(Uploadedfiles, data);
                }
            }
        } else {
            let newitems = {
                name: data.name,
                fleettype: data.fleettype,
                // date: formdata.date,
                jfield: JSON.stringify({
                    name: data.name,
                    value: data.name.replace(/\s/g, '')
                }),
                isactive: data.isactive
            };
            await dxService.createitem('generalconfiguration', newitems).then(() => {
                navigate(`/admin/configs/all`);
            });
        }
    };
    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';

    const wizardItems = [
        { label: 'Table Details', command: () => navigate('/admin/configs/item/tablesettings' + qs) },
        { label: 'Basic Details', command: () => navigate('/admin/configs/item/basic' + qs) },
        { label: 'Pictures', command: () => navigate('/admin/configs/item/pictures' + qs) }
    ];
    const editnewconfigs = async (formdata: any, fid: string) => {
        let getlogos = await Storage.list('images/logo/');

        let gets3 = getlogos.results;
        console.log(formdata, gets3);
        var Uploadedfiles: any = [];
        let vehicleImageObj: any = {};

        // console.log(Uploadedfiles);
        if (formdata.images && formdata.images !== null) {
            if (formdata.images.file) {
                for (let i = 0; i < formdata.images.file.length; i++) {
                    vehicleImageObj = new Object({ file: formdata.images.file[i], type: 'picture', path: 'images/logo/' });
                    Uploadedfiles.push(vehicleImageObj);
                }
            }
            console.log(Uploadedfiles);
            if (formdata.images) {
                let filters3: any = gets3.filter((item: any) => item.key.toLowerCase().includes(formdata.name.toLowerCase()));
                // console.log(filters3);
                if (filters3.length > 0) {
                    await Storage.remove(filters3[0].key).then(async (res) => {
                        console.log('removed completed');
                        editConfig(Uploadedfiles, formdata);
                    });
                } else {
                    console.log('removed completed1');
                    editConfig(Uploadedfiles, formdata);
                }
            }
        } else {
            let b: any = formdata;

            Object.keys(b).forEach((key) => {
                if (b[key] == null) {
                    delete b[key];
                }
            });
            let updatedItems = {
                id: formdata.id,
                name: formdata.name,
                fleettype: formdata.fleettype,

                jfield: JSON.stringify({
                    name: formdata.name,
                    value: formdata.name.replace(/\s/g, '')
                }),
                isactive: formdata.isactive,
                _version: formdata._version
            };
            // console.log(updatedItems);

            await dxService
                .updateitem('generalconfiguration', updatedItems)
                .then((res) => {
                    // console.log(res);

                    navigate(`/admin/configs/all`);
                })
                .catch((err) => {
                    // console.log(err);
                });
        }
    };
    const addconfig = async (Uploadedfiles: any, data: any) => {
        Uploadedfiles.map(async (item: any) => {
            // console.log(item);
            await Storage.put(item.path + data.name.toLowerCase() + '.' + item.file.name.split('.')[1], item.file)
                .then(async (suc: any) => {
                    // console.log('uploaded completed 1');

                    let newitems = {
                        name: data.name,
                        fleettype: data.fleettype,
                        images: suc.key,
                        jfield: JSON.stringify({
                            name: data.name,
                            value: data.name.replace(/\s/g, '')
                        }),
                        isactive: data.isactive
                    };
                    await dxService.createitem('generalconfiguration', newitems).then(() => {
                        navigate(`/admin/configs/all`);
                    });
                })
                .catch((err: any) => {
                    console.error(err);
                });
        });
    };
    const editConfig = async (Uploadedfiles: any, formdata: any) => {
        if (Uploadedfiles.length == 0) {
            let updatedItems = {
                id: formdata.id,
                name: formdata.name,
                fleettype: formdata.fleettype,
                //    images: suc.key,
                jfield: JSON.stringify({
                    name: formdata.name,
                    value: formdata.name.replace(/\s/g, '')
                }),
                isactive: formdata.isactive,
                _version: formdata._version
            };
            await dxService
                .updateitem('generalconfiguration', updatedItems)
                .then((res) => {
                    // console.log(res);

                    navigate(`/admin/configs/all`);
                })
                .catch((err) => {
                    // console.log(err);
                });
        } else {
            Uploadedfiles.map(async (item: any) => {
                // console.log(item);
                await Storage.put(item.path + formdata.name.toLowerCase() + '.' + item.file.name.split('.')[1], item.file)
                    .then(async (suc: any) => {
                        // console.log('uploaded completed 1');

                        let updatedItems = {
                            id: formdata.id,
                            name: formdata.name,
                            fleettype: formdata.fleettype,
                            images: suc.key,
                            jfield: JSON.stringify({
                                name: formdata.name,
                                value: formdata.name.replace(/\s/g, '')
                            }),
                            isactive: formdata.isactive,
                            _version: formdata._version
                        };
                        await dxService
                            .updateitem('generalconfiguration', updatedItems)
                            .then((res) => {
                                // console.log(res);

                                navigate(`/admin/configs/all`);
                            })
                            .catch((err) => {
                                // console.log(err);
                            });
                    })
                    .catch((err: any) => {
                        console.error(err);
                    });
            });
        }
    };
    const addConfig = (props: any) => {
        if (formid == null) submitForm(props);
        else {
            editnewconfigs(props, formid);
        }
    };
    const edittableSettings = async (props: any, formid: any) => {
        console.log('edit', props);
        let updateitems = { id: props.id, table: props.table, column: props.column, field1: props.field1, _version: props._version };
        await dxService.updateitem('tablesettings', updateitems).then(() => {
            navigate(`/admin/configs/all?view=tableconfigs`);
        });
    };
    const submitTableSettingForm = async (props: any) => {
        console.log(props);
        let newitems = {
            table: props.table,
            column: props.column,
            field1: props.field1
        };
        await dxService.createitem('tablesettings', newitems).then(() => {
            navigate(`/admin/configs/all?view=tableconfigs`);
        });
    };
    const addtableSetting = (props: any) => {
        if (formid == null) submitTableSettingForm(props);
        else {
            edittableSettings(props, formid);
        }
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <div className="card container-card">
                    <Toast ref={toast} />
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                    {formmode == 'tableedit' ? (
                        <Routes>
                            <Route
                                path={'/tablesettings'}
                                element={
                                    <>
                                        <FormTemplate
                                            bucketname="GeneralConfigs"
                                            Fleettype={'GeneralConfigsPicture'}
                                            columns={tablecolumns}
                                            data={listFormData}
                                            showaction={true}
                                            mode={formmode}
                                            onChange={changeconfigsdata}
                                            // setselectedmakeDropdown={setselectedmakeDropdown}
                                            // ChangeBtn={'OpenDocument'}
                                            layout="grid"
                                            title="New Configs"
                                            submitForm={addtableSetting}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path={'/basic'}
                                element={
                                    <>
                                        <FormTemplate
                                            bucketname="GeneralConfigs"
                                            Fleettype={'GeneralConfigsPicture'}
                                            columns={formColumns}
                                            data={listFormData}
                                            showaction={true}
                                            mode={formmode}
                                            onChange={changeconfigsdata}
                                            // setselectedmakeDropdown={setselectedmakeDropdown}
                                            // ChangeBtn={'OpenDocument'}
                                            layout="grid"
                                            title="New Configs"
                                            submitForm={addConfig}
                                        />
                                    </>
                                }
                            />

                            <Route
                                path={'/pictures'}
                                element={
                                    <>
                                        <Documents formmode={formmode} formid={formid} />
                                    </>
                                }
                            />
                        </Routes>
                    ) : (
                        <Routes>
                            <Route
                                path={'/basic'}
                                element={
                                    <>
                                        <FormTemplate
                                            bucketname="GeneralConfigs"
                                            Fleettype={'GeneralConfigsPicture'}
                                            columns={formColumns}
                                            data={listFormData}
                                            showaction={true}
                                            mode={formmode}
                                            onChange={changeconfigsdata}
                                            // setselectedmakeDropdown={setselectedmakeDropdown}
                                            // ChangeBtn={'OpenDocument'}
                                            layout="grid"
                                            title="New Configs"
                                            submitForm={addConfig}
                                        />
                                    </>
                                }
                            />

                            <Route
                                path={'/pictures'}
                                element={
                                    <>
                                        <Documents formmode={formmode} formid={formid} />
                                    </>
                                }
                            />
                            <Route
                                path={'/tablesettings'}
                                element={
                                    <>
                                        <FormTemplate
                                            bucketname="GeneralConfigs"
                                            Fleettype={'GeneralConfigsPicture'}
                                            columns={tablecolumns}
                                            data={listFormData}
                                            showaction={true}
                                            mode={formmode}
                                            onChange={changeconfigsdata}
                                            // setselectedmakeDropdown={setselectedmakeDropdown}
                                            // ChangeBtn={'OpenDocument'}
                                            layout="grid"
                                            title="New Configs"
                                            submitForm={addtableSetting}
                                        />
                                    </>
                                }
                            />
                        </Routes>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Configsform;
