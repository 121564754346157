import { Button as AwsButton } from '@aws-amplify/ui-react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GetUser from '../../Components/GetUser';
import dxService from '../../Services/dxService';

function AddExpense(props: any) {
    console.log(props);

    const [formData, setFormData] = useState<any>([]);
    const [oldData, setOldData] = useState<any>([]);

    useEffect(() => {
        if (props.id) getSpares(props.id);
    }, [props]);
    const getSpares = async (id: any) => {
        let filter = {
            appointmentid: {
                eq: id
            }
        };
        await dxService.listitems('vehiclespare', '*', filter).then((res) => {
            console.log(res);
            setOldData(res);
            setFormData(res);
        });
    };
    const handleAddRow = () => {
        setFormData([...formData, {}]);
    };

    const handleDeleteRow = (index: number) => {
        const newFormData = [...formData];
        newFormData.splice(index, 1);
        setFormData(newFormData);
    };
    console.log(formData);

    const handleChange = async (event?: any, type?: any, fieldName?: any) => {
        if (type !== 'fileupload' && type !== 'upload' && type !== 'imageupload') {
            setFormData({
                ...formData,
                [type === 'fileupload' || type === 'imageupload' || type == 'form' ? fieldName : type === 'number' ? event.originalEvent.target.name : type === 'user' ? fieldName : event.target.name]:
                    type == 'form'
                        ? event.target.value
                        : type === 'fileupload'
                        ? event.files[0].objectURL
                        : type === 'imageupload'
                        ? event.files[0].objectURL
                        : type === 'number'
                        ? event.originalEvent.target.value
                        : type === 'multiselect'
                        ? event.target.value
                        : type === 'user'
                        ? event
                        : event.target.value
            });

            props.onChange(event, type, fieldName);
        }

        if (type === 'upload') {
            setFormData({
                ...formData,
                [fieldName]: {
                    url: [event.files[0].objectURL],
                    file: [event.files[0]],
                    change: 1
                }
            });
            props.onChange(event, type, fieldName);
        }

        //props.changevehicledata(data);
    };
    const dynamicFields = (field: any, type: any, fieldName: any, options: any, disabled: boolean, hidden: boolean, required: boolean, showcurrentdate: boolean) => {
        let ddata: any = oldData;
        let fieldvalue = field.value;

        var dvalue = ddata && ddata[fieldName] ? ddata[fieldName] : fieldvalue ? fieldvalue : '';
        //console.log(dvalue, 'dvalue');
        if (type === 'text') {
            return (
                <InputText
                    name={fieldName}
                    value={dvalue}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'textarea') {
            return (
                <InputTextarea
                    name={fieldName}
                    value={dvalue}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    autoResize={true}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'number') {
            return (
                <InputNumber
                    name={fieldName}
                    value={dvalue || null}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    useGrouping={false}
                    maxLength={12}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'datetime') {
            let dateValue: any = dvalue != '' ? new Date(dvalue) : '';

            return (
                <Calendar
                    name={fieldName}
                    value={dateValue}
                    stepMinute={15}
                    id="buttonbar"
                    showTime
                    showButtonBar
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    required={required}
                    showIcon
                    hourFormat="12"
                    maxDate={field.maxdate ? field.maxdate : ''}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'date') {
            let ShowDate = showcurrentdate == true ? new Date() : new Date().toISOString();

            let dateValue: any = props.getTableName !== undefined && dvalue !== null && dvalue !== undefined ? new Date(dvalue) : props.getTableName !== undefined && dvalue === undefined && showcurrentdate == true ? ShowDate : dvalue;

            return (
                <Calendar
                    name={fieldName}
                    value={dateValue}
                    id="buttonbar"
                    showButtonBar
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    required={required}
                    showIcon
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'dropdown') {
            return (
                <Dropdown
                    name={fieldName}
                    value={dvalue}
                    options={options}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    optionLabel="label"
                    placeholder="Select an option"
                    filter
                    optionDisabled={(option: any) => option.isdisabled}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'multiselect') {
            return (
                <MultiSelect
                    name={fieldName}
                    value={dvalue}
                    options={options}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    optionLabel="label"
                    placeholder="Select an option"
                    filter
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'user') {
            return (
                <GetUser
                    value={dvalue}
                    onChange={(e: any) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else {
            return (
                <InputText
                    name={fieldName}
                    value={dvalue}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        }

        //}
    };
    return (
        <form>
            <AwsButton type="button" onClick={handleAddRow}>
                Add Expense
            </AwsButton>
            <table>
                <thead>
                    {formData.length > 0 && (
                        <tr>
                            {props.columns.columns.columns.map((column: any, index: number) => (
                                <th key={index}>{column.title}</th>
                            ))}
                            {formData.length > 1 && <th></th>}
                        </tr>
                    )}
                </thead>
                <tbody>
                    {formData.map((row: any, rowIndex: number) => (
                        <tr key={rowIndex}>
                            {oldData.length > 0
                                ? oldData.map((column: any, columnIndex: number) => {
                                      console.log(column);

                                      return <td key={columnIndex}>{dynamicFields(column, column.fieldtype, column.name, column.data, column.disabled, column.hidden, column.required, column.showcurrentdate)}</td>;
                                  })
                                : props.columns.columns.columns.map((column: any, columnIndex: number) => (
                                      <td key={columnIndex}>
                                          return <td key={columnIndex}>{dynamicFields(column, column.fieldtype, column.name, column.data, column.disabled, column.hidden, column.required, column.showcurrentdate)}</td>;
                                      </td>
                                  ))}
                            {formData.length > 0 && (
                                <td>
                                    <button type="button" onClick={() => handleDeleteRow(rowIndex)}>
                                        Delete
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </form>
    );
}

export default AddExpense;
