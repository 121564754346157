import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { useEffect, useState, useRef, useContext } from 'react';
import dxService from '../Services/dxService';

const FormTemplateComponent = (props: any) => {
    const [subcolumns, setsubcolumns] = useState<any>(props.columns.columns);
    const [data, setData] = useState<any>({});
    const [ddoptions, setddoptions] = useState<any>({});
    let layoutclass = 'md:col-12';
    if (props.layout)
        switch (props.layout.toLowerCase()) {
            case 'vertical':
                layoutclass = 'md:col-12';
                break;
            case 'horizontal':
                layoutclass = 'grid md:col-12';
                break;
            case 'grid':
                layoutclass = 'dropdown md:col-6';
                break;
            case 'grid2':
                layoutclass = 'dropdown md:col-4';
                break;
            default:
                layoutclass = 'md:col-12';
        }

    const getoptions = async (list: any, columns: any, filter: any): Promise<any[]> => {
        if (list === 'generalconfiguration') {
            let query = '';
            let qfilter = filter;

            let q = `
                query ListDxGeneralConfigs(
                    $id: ID
                    $filter: ModelDxGeneralConfigFilterInput
                    $limit: Int
                    $nextToken: String
                    $sortDirection: ModelSortDirection
                  ) {
                    listDxGeneralConfigs(
                      id: $id
                      filter: $filter
                      limit: $limit
                      nextToken: $nextToken
                      sortDirection: $sortDirection
                    ) {
                      items {
                        id
                        name
                        fleettype
                      }
                    }
                }
            `;

            let res = await dxService.queryitems(q, qfilter);
            console.log(res);
            return res;
        }
        return [];
        // let options = [];
        // for (let i = 0; i < subcolumns.length; i++) {
        //     options.push({
        //         label: subcolumns[i].name,
        //         value: subcolumns[i].name
        //     });
        // }
        // return options;
    }

    const getdropdownoptions = async (field: any) => {
        let options: { label: any; value: any; }[] = [];

        if (field.list === "generalconfiguration") {
            let query = '';
            let qfilter = JSON.parse(field.query);
            let q = `
                query ListDxGeneralConfigs(
                    $id: ID
                    $filter: ModelDxGeneralConfigFilterInput
                    $limit: Int
                    $nextToken: String
                    $sortDirection: ModelSortDirection
                  ) {
                    listDxGeneralConfigs(
                      id: $id
                      filter: $filter
                      limit: $limit
                      nextToken: $nextToken
                      sortDirection: $sortDirection
                    ) {
                      items {
                        id
                        name
                        fleettype
                      }
                    }
                }`;
            console.log(field);
            console.log(qfilter);
            let res = await dxService.queryitems(q, qfilter);
            console.log(res);

          

            res.items.forEach((ele: any) => {
                options.push({
                    label: ele.name,
                    value: ele.id
                });
            });
            console.log(options);
            let aoptions = ddoptions;
            aoptions[field.name] = options
            console.log(aoptions);
            setddoptions(aoptions);
        }


       // let a: any = await getoptions(field.list, 'id,name,fieldtype', field.filter);


    }

    const dynamicFields = (field: any, type: any, fieldName: any, options: any, disabled: boolean, hidden: boolean, required: boolean, showcurrentdate: boolean, disabledDates: any) => {

        let ddata: any = data;
        let fieldvalue = field.value;

        var dvalue = ddata && ddata[fieldName] ? ddata[fieldName] : fieldvalue ? fieldvalue : '';

        if (type === 'text') {
            return (
                <InputText
                    name={fieldName}
                    defaultValue='kk'
                    // value={dvalue}
                    // onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'dropdown') {
            getdropdownoptions(field);

            return (
                <Dropdown
                    name={fieldName}
                    list=''
                    defaultValue={''}
                    options={ddoptions && ddoptions[field.name]}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    optionLabel="label"
                    placeholder="Select an option"
                    filter
                    optionDisabled={(option: any) => option.isdisabled}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        }

    };

    const handleChange = async (event?: any, type?: any, fieldName?: any) => {
    }

    return (
        <div className="p-fluid formgrid grid" id={props.id}>
            {subcolumns
                ? subcolumns
                    .filter((hidden: any) => hidden.hidden === false || hidden.hidden === undefined)
                    .map((val: any, key: number) => {
                        return (
                            <React.Fragment key={key.toString()}>

                                <div className={`field col-12 ${val.class && val.class != '' ? val.class : layoutclass}`}>
                                    <label htmlFor={val.value ? val.value : ''} className={layoutclass.includes('grid') ? 'col-12 mb-2 md:col-2 md:mb-0' : ''}>
                                        {val.title}
                                        {val.required ? '*' : ''}
                                    </label>
                                    <div className={layoutclass.includes('grid') ? 'col-12 md:col-10' : ''}>
                                        {dynamicFields(val, val.fieldtype, val.name, val.data, val.disabled, val.hidden, val.required, val.showcurrentdate, val.disabledDates)}
                                    </div>
                                </div>


                            </React.Fragment>
                        );
                    })
                : ''}
        </div>
    );

};
export default FormTemplateComponent;
