import { API } from 'aws-amplify';
import { createDxAllocations, createDxApprovalConfig, createDxApprovalConfigSteps, createDxVehicle, createDxWorkflow, deleteDxVehicle, deleteDxVehicleActivity, updateDxAllocations, updateDxDriver, updateDxVehicle } from '../graphql/mutations';
import { listDxCompanies, listDxDrivers, listDxVehicles, listDxAppointments, listDxVehicleActivities, listDxAllocations, listDxApprovals, listDxVendors, listDxContracts } from '../graphql/queries';
import { _listDxVehicleActivities, _listDxVehicles, _listDxApprovalConfigs, _listDxContracts, _listDxAllocations, _listDxMasterLogs } from '../dxgraphql/dxqueries';
import dxService from '../Services/dxService';

//Amplify.configure(awsconfig);
class AmplifyServices {
    async getallSContractdetails() {
        let GetResponse: any = await API.graphql({ query: listDxContracts, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        return GetResponse.data.listDxContracts.items;
    }
    async getOnlySContractdetails(renewaltype: any) {
        let filter = {
            renewaltype: {
                eq: `${renewaltype}` // filter priority = 1
            }
        };

        let GetResponse: any = await API.graphql({ query: listDxContracts, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxContracts.items;
    }
    async getAllocateDriver(id: any) {
        let filter = {
            fromdriverID: {
                eq: `${id}` // filter priority = 1
            }
        };

        let GetResponse: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxAllocations.items;
    }
    async EditDriverDetails(data: any) {
        //   const updatedTodo = await API.graphql({ query:updateDxVehicle, variables: {input: todoDetails}});
        let EditDriverDetails: any = await API.graphql({
            query: updateDxDriver,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });
        return EditDriverDetails.data.updateDxDriver;
    }
    async fetchSingleContract(fid: any) {
        let filter = {
            id: {
                eq: fid
            }
        };
        let GetResponse: any = await API.graphql({ query: _listDxContracts, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxContracts.items;
    }
    async filterallContract(fid: any, type: any) {
        let filter = {
            vehicleid: {
                eq: fid
            },
            renewaltype: {
                eq: type // filter priority = 1
            }
        };
        let GetResponse: any = await API.graphql({ query: listDxContracts, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxContracts.items;
    }
    async filterScOnly(fid: any, type: any) {
        let filter = {
            id: {
                eq: fid
            },
            renewaltype: {
                eq: type // filter priority = 1
            }
        };
        let GetResponse: any = await API.graphql({ query: listDxContracts, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxContracts.items;
    }
    async getFilteredDriverdetails(id: any) {
        let filter = {
            id: {
                eq: `${id}` // filter priority = 1
            }
        };

        let GetResponse: any = await API.graphql({ query: listDxDrivers, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxDrivers.items;
    }
    async getAllocationdetails(id: any) {
        let filter = {
            vehicleid: {
                eq: `${id}` // filter priority = 1
            }
        };

        let GetResponse: any = await API.graphql({ query: _listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxAllocations.items;
    }
    async getMasterLogdetails(id: any) {
        let filter = {
            vehicleid: {
                eq: `${id}` // filter priority = 1
            }
        };

        let GetResponse: any = await API.graphql({ query: _listDxMasterLogs, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxMasterLogs.items;
    }

    async getContractdetails(id: any) {
        let filter = {
            vehicleid: {
                eq: `${id}` // filter priority = 1
            }
        };

        let GetResponse: any = await API.graphql({ query: listDxContracts, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxContracts.items;
    }
    async filteruniqueContract(id: any, type: any) {
        let filter = {
            vehicleid: {
                eq: `${id}` // filter priority = 1
            },
            renewaltype: {
                eq: type
            }
        };

        let GetResponse: any = await API.graphql({ query: listDxContracts, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxContracts.items;
    }
    async getfilteredvehicle(id: any) {
        let filter = {
            id: {
                eq: id
            }
        };

        let GetResponse: any = await API.graphql({ query: listDxVehicles, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxVehicles.items;
    }
    async getvehicledetails(fleetType: any) {
        // let filter = {
        //     fleettype: {
        //         eq: `${fleetType}` // filter priority = 1
        //     }
        // };

        let GetResponse: any = await API.graphql({ query: _listDxVehicles, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: {} });
        return GetResponse.data.listDxVehicles.items;
    }

    async getapprovals() {
        let GetResponse: any = await API.graphql({ query: listDxApprovals, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: {} });
        return GetResponse.data.listDxApprovals.items;
    }

    async getvehicleactivitydetails() {
        // let filter = {
        //     fleettype: {
        //         eq: `${fleetType}` // filter priority = 1
        //     }
        // };

        //{"filter":{"or":[{"vehicleid":{"eq":"P61854"}}]}}
        let filter = {
            allocationfilter: {
                and: [
                    {
                        fromdate: {
                            le: new Date().toISOString()
                        }
                    },
                    {
                        or: [
                            {
                                handoverdate: {
                                    ge: new Date().toISOString()
                                }
                            },
                            {
                                handoverdate: {
                                    attributeExists: false
                                }
                            }
                        ]
                    }
                ]
            }
        };

        // let GetResponse: any = await dxService.listitems('vehicleactivity','*',filter,true);
        // GetResponse.items.filter((res: any) => res._delete !== true);
        // return GetResponse.items;
        let GetResponse: any = await API.graphql({ query: _listDxVehicleActivities, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: filter });
        GetResponse.data.listDxVehicleActivities.items.filter((res: any) => res._delete !== true);
        return GetResponse.data.listDxVehicleActivities.items;
    }

    async getvehicleitem(vehicleid: any) {
        let filter = {
            id: {
                eq: vehicleid
            }
        };
        let GetResponse: any = await API.graphql({ query: listDxVehicleActivities, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxVehicleActivities;
    }
    async getBusinessDepartment(fleetType: any) {
        let filter = {
            fleettype: {
                eq: `${fleetType}` // filter priority = 1
            }
        };
        let GetResponse: any = await API.graphql({ query: listDxCompanies, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxCompanies.items;
    }
    async getAppointment(id: any) {
        let filter = {
            id: {
                eq: `${id}` // filter priority = 1
            }
        };
        let GetResponse: any = await API.graphql({ query: listDxAppointments, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        return GetResponse.data.listDxAppointments.items;
    }
    async getAppointmentdetails() {
        let GetResponse: any = await API.graphql({ query: listDxAppointments, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        return GetResponse.data.listDxAppointments.items;
    }

    async getallocateddrivers(query: any) {
        let GetResponse: any = await API.graphql({ query: _listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: query });
        return GetResponse.data.listDxAllocations.items;
    }
    async getVendorDetails() {
        let GetResponse: any = await API.graphql({ query: listDxVendors, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        return GetResponse.data.listDxVendors.items;
    }
    async getDriverDetails() {
        let GetResponse: any = await API.graphql({ query: listDxDrivers, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        return GetResponse.data.listDxDrivers.items;
    }

    async AddAllocationdetails(
        vehicleid: any,
        HandoverKmMileage: any,
        Reason: any,
        HandoverLocation: any,
        FromLocation: any,
        FromDriverDropdown: any,
        HandoverDriverDropdown: any,
        FromDriverDate: any,
        HandoverDriverDate: any,
        FromKmMileage: any,
        id: any,
        Status: any
    ) {
        let vehicle = {
            id: id,
            vehicleid: vehicleid,
            fromdrivername: FromDriverDropdown,
            handoverdrivername: HandoverDriverDropdown,
            fromKM: FromKmMileage,
            handoverKM: HandoverKmMileage,
            fromlocation: FromLocation,
            handoverlocation: HandoverLocation,
            // distances: allocateddate,
            reason: Reason,
            fromdate: FromDriverDate,
            handoverdate: HandoverDriverDate,
            allocatestatus: Status
        };

        let AddVehicleDetailsInsurence = await API.graphql({
            query: createDxAllocations,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetailsInsurence;
    }
    async AddFirstAllocation(
        vehicleid: any,
        HandoverKmMileage: any,
        HandoverLocation: any,
        HandoverDriverDate: any,

        handoverdriverid: any,
        handoverdrivername: any,
        FromDriverDate: any,
        id: any,
        Status: any,
        Isdocument: any,
        Ispicture: any
        // documents: any
    ) {
        let vehicle = {
            id: id,
            vehicleid: vehicleid,
            dxDriverAllocationsId: handoverdriverid,
            handoverdrivername: handoverdrivername,

            handoverKM: HandoverKmMileage,

            handoverlocation: HandoverLocation,

            fromdate: FromDriverDate,
            handoverdate: HandoverDriverDate,
            allocatestatus: Status,
            pictures: Ispicture,
            documents: Isdocument
        };

        let AddVehicleDetailsInsurence = await API.graphql({
            query: createDxAllocations,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetailsInsurence;
    }
    async AddHandoverAllocation(
        vehicleid: any,

        Reason: any,

        FromLocation: any,
        FromDriverDropdown: any,

        FromDriverDate: any,

        FromKmMileage: any,
        id: any,
        Status: any,
        Isdocument: any,
        Ispicture: any
        // documents: any
    ) {
        let vehicle = {
            id: id,
            vehicleid: vehicleid,
            dxDriverAllocationsId: FromDriverDropdown,
            fromdrivername: FromDriverDropdown,

            fromKM: FromKmMileage,

            fromlocation: FromLocation,

            // distances: allocateddate,
            reason: Reason,
            fromdate: FromDriverDate,

            allocatestatus: Status,
            pictures: Ispicture,
            documents: Isdocument
        };

        let AddVehicleDetailsInsurence = await API.graphql({
            query: createDxAllocations,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetailsInsurence;
    }
    async EditAllocationdetails(
        versionid: any,
        id: any,
        vehicleid: any,
        allocationstatus: any,
        HandoverDriverDate: any,
        HandoverKmMileage: any,
        Reason: any,
        HandoverLocation: any,
        FromLocation: any,
        FromDriverDropdown: any,
        FromDriverDate: any,
        FromKmMileage: any
    ) {
        let vehicle = {
            id: id,
            vehicleid: vehicleid,
            // drivername:drivername,
            fromdrivername: FromDriverDropdown,
            fromKM: FromKmMileage,
            handoverKM: HandoverKmMileage,
            fromlocation: FromLocation,
            handoverlocation: HandoverLocation,
            reason: Reason,
            fromdate: FromDriverDate,
            handoverdate: HandoverDriverDate,
            _version: versionid,
            allocatestatus: allocationstatus
        };

        let AddVehicleDetailsInsurence = await API.graphql({
            query: updateDxAllocations,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetailsInsurence;
    }

    async AddnewVehicleDetails(data: any) {
        //   const updatedTodo = await API.graphql({ query:updateDxVehicle, variables: {input: todoDetails}});
        let resp: any = await API.graphql({
            query: createDxVehicle,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });
        return resp.data.createDxVehicle;
    }

    async AddVehicleDetails(fleetType: any, PlateCode: any, PlateNumber: any, Make: any, Model: any, VehicleYear: any, Department: any, Status: any, Capacity: any, Imagepath: any) {
        let vehicle = {
            id: `${PlateCode + PlateNumber}`,
            fleettype: fleetType,
            platecode: PlateCode,
            plateno: PlateNumber,
            make: Make,
            model: Model,
            year: VehicleYear,
            ImagePath: Imagepath,
            departmentname: Department,
            capacity: Capacity,
            status: Status
        };

        let AddVehicleDetails = await API.graphql({
            query: createDxVehicle,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetails;
    }
    async AddVehicleInsurenceDetails(fleetType: any, PlateCode: any, PlateNumber: any, Make: any, Model: any, VehicleYear: any, res: any) {
        let vehicle = {
            id: `${PlateCode + PlateNumber}`,
            fleettype: fleetType,
            platecode: PlateCode,
            plateno: PlateNumber,
            Documents: JSON.stringify({
                Insurance: await res
                    .filter((doc: any) => doc.key.includes('Insurance#'))
                    .map((item: any) => item.key)
                    .toString()
            })
            // businessunitid: "1",
            // capacity: "1 + 4 Passenger",
            // chasisno: "MDHBN7AD9NG152877",
            // isnew:true,
            // version:1
        };

        let AddVehicleDetailsInsurence = await API.graphql({
            query: createDxVehicle,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetailsInsurence;
    }
    async AddVehicleRegistrationDetails(fleetType: any, PlateCode: any, PlateNumber: any, Make: any, Model: any, VehicleYear: any, res: any) {
        let vehicle = {
            id: `${PlateCode + PlateNumber}`,
            fleettype: fleetType,
            platecode: PlateCode,
            plateno: PlateNumber,
            Documents: JSON.stringify({
                Registration: await res
                    .filter((doc: any) => doc.key.includes('Registration#'))
                    .map((item: any) => item.key)
                    .toString()
            })
            // businessunitid: "1",
            // capacity: "1 + 4 Passenger",
            // chasisno: "MDHBN7AD9NG152877",
            // isnew:true,
            // version:1
        };

        let AddVehicleDetailsInsurence = await API.graphql({
            query: createDxVehicle,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetailsInsurence;
    }
    async AddVehicleServiceContractDetails(fleetType: any, PlateCode: any, PlateNumber: any, Make: any, Model: any, VehicleYear: any, res: any) {
        let vehicle = {
            id: `${PlateCode + PlateNumber}`,
            fleettype: fleetType,
            platecode: PlateCode,
            plateno: PlateNumber,
            Documents: JSON.stringify({
                ServiceContracts: await res
                    .filter((doc: any) => doc.key.includes('ServiceContract#'))
                    .map((item: any) => item.key)
                    .toString()
            })
            // businessunitid: "1",
            // capacity: "1 + 4 Passenger",
            // chasisno: "MDHBN7AD9NG152877",
            // isnew:true,
            // version:1
        };

        let AddVehicleDetailsInsurence = await API.graphql({
            query: createDxVehicle,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetailsInsurence;
    }
    async AddVehicleWarrentyDetails(fleetType: any, PlateCode: any, PlateNumber: any, Make: any, Model: any, VehicleYear: any, res: any) {
        let vehicle = {
            id: `${PlateCode + PlateNumber}`,
            fleettype: fleetType,
            platecode: PlateCode,
            plateno: PlateNumber,
            Documents: JSON.stringify({
                Waranty: await res
                    .filter((doc: any) => doc.key.includes('Waranty#'))
                    .map((item: any) => item.key)
                    .toString()
            })
            // businessunitid: "1",
            // capacity: "1 + 4 Passenger",
            // chasisno: "MDHBN7AD9NG152877",
            // isnew:true,
            // version:1
        };

        let AddVehicleDetailsInsurence = await API.graphql({
            query: createDxVehicle,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetailsInsurence;
    }
    async AddVehicleRTADetails(fleetType: any, PlateCode: any, PlateNumber: any, Make: any, Model: any, VehicleYear: any, res: any) {
        let vehicle = {
            id: `${PlateCode + PlateNumber}`,
            fleettype: fleetType,
            platecode: PlateCode,
            plateno: PlateNumber,
            Documents: JSON.stringify({
                RTA: await res
                    .filter((doc: any) => doc.key.includes('RTADocuments#'))
                    .map((item: any) => item.key)
                    .toString()
            })
            // businessunitid: "1",
            // capacity: "1 + 4 Passenger",
            // chasisno: "MDHBN7AD9NG152877",
            // isnew:true,
            // version:1
        };

        let AddVehicleDetailsInsurence = await API.graphql({
            query: createDxVehicle,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vehicle
            }
        });

        return AddVehicleDetailsInsurence;
    }
    async EditVehicleDetails(data: any) {
        //   const updatedTodo = await API.graphql({ query:updateDxVehicle, variables: {input: todoDetails}});
        let EditVehicleDetails: any = await API.graphql({
            query: updateDxVehicle,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });
        return EditVehicleDetails.data.updateDxVehicle;
    }

    async DeleteVehicleDetails(fleetTypevehicle: any, selectedProducts: any, vehicleactivityVersion: any) {
        //fleetType:any,PlateCode:any,PlateNumber:any
        //delete the vehicle item in datatable
        selectedProducts.map(async (item: any) => {
            let varvehicleDelete = {
                id: item.id,
                _version: item._version
            };
            let DeleteVehicleDetails = await API.graphql({
                query: deleteDxVehicle,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: varvehicleDelete
                }
            });

            return DeleteVehicleDetails;
        });
        //delete the vehicle activity item in datatable
        selectedProducts.map(async (item: any) => {
            let varvehicleDelete = {
                id: item.id,
                _version: vehicleactivityVersion
            };
            let DeleteVehicleDetails = await API.graphql({
                query: deleteDxVehicleActivity,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: varvehicleDelete
                }
            });

            return DeleteVehicleDetails;
        });
    }

    async getNavDetails(tableName: any, responseName: string, type: any) {
        let filterItem = {
            type: {
                eq: type
            }
        };
        let GetResponse: any = await API.graphql({
            query: tableName,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                filter: filterItem
            }
        });
        return GetResponse.data[responseName].items;
    }

    async EditWorkspaceDriver(version: any, fleetType: any, platecode: any, drivername: any, allocatedriverdate: any, allocationid: any) {
        let WorkspaceDriver = {
            id: platecode,
            _version: `${version}`,
            fleettype: fleetType,
            allocationdriver: JSON.stringify({
                driver: drivername,
                date: allocatedriverdate,
                allocationid: allocationid
            })
        };
        //   const updatedTodo = await API.graphql({ query:updateDxVehicle, variables: {input: todoDetails}});
        let EditWorkspaceDetails = await API.graphql({
            query: updateDxVehicle,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: WorkspaceDriver
            }
        });

        return EditWorkspaceDetails;
    }
    async getVehicleStorage() {
        //     let VehicleImage=await Storage.get('renault.png').then((data:any)=>{
        //   data
        //     })
    }
    async DeleteDrivers(selectedItem: any, tableName: any) {
        return await Promise.all(
            selectedItem.map(async (item: any) => {
                let deleteItem = {
                    id: item.id,
                    _version: item._version
                };

                let DeleteDetails = await API.graphql({
                    query: tableName,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {
                        input: deleteItem
                    }
                });
                return DeleteDetails;
            })
        );
    }
    //Reuseable Fucntions
    async getTableDetails(tableName: any, responseName: string) {
        let GetResponse: any = await API.graphql({
            query: tableName,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                limit: 100000
            }
        });
        return GetResponse.data[responseName].items;
    }
    async getViewfilterContractId(tableName: any, responseName: string, id: any) {
        let filterItem = {
            id: {
                eq: id
            }
        };
        let GetResponse: any = await API.graphql({
            query: tableName,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                filter: filterItem
            }
        });

        return GetResponse.data[responseName].items;
    }
    async getFilteredTableDetails(tableName: any, responseName: string, id: any) {
        let filterItem = {
            vehicleid: {
                eq: id
            }
        };
        let GetResponse: any = await API.graphql({
            query: tableName,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                filter: filterItem
            }
        });
        return GetResponse.data[responseName].items;
    }
    async getFilterbyIddetails(tableName: any, responseName: string, id: any) {
        let filterItem = {
            id: {
                eq: id
            }
        };
        let GetResponse: any = await API.graphql({
            query: tableName,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                filter: filterItem
            }
        });
        return GetResponse.data[responseName].items;
    }
    async EditConfigDetails(tableName: any, responseName: string, data: any) {
        //   const updatedTodo = await API.graphql({ query:updateDxVehicle, variables: {input: todoDetails}});
        let EditDetails: any = await API.graphql({
            query: tableName,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });
        return EditDetails.data[responseName];
    }
    async DeleteItems(selectedItem: any, tableName: any) {
        await Promise.all(
            selectedItem.map(async (item: any) => {
                let deleteItem = {
                    id: item.id,
                    _version: item._version
                };
                let DeleteDetails = await API.graphql({
                    query: tableName,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {
                        input: deleteItem
                    }
                });
                return DeleteDetails;
            })
        );
    }
    async DeleteConfigItems(selectedItem: any, tableName: any) {
        await Promise.all(
            selectedItem.map(async (item: any) => {
                let deleteItem = {
                    id: item.id,
                    name: item.name,
                    fleettype: item.fleettype,
                    _version: item._version
                };
                let DeleteDetails = await API.graphql({
                    query: tableName,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {
                        input: deleteItem
                    }
                });
                return DeleteDetails;
            })
        );
    }

    async Addnewapprovalconfig(data: any) {
        //   const updatedTodo = await API.graphql({ query:updateDxVehicle, variables: {input: todoDetails}});
        let resp: any = await API.graphql({
            query: createDxApprovalConfig,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });
        return resp.data.createDxApprovalConfig;
    }

    async Addnewapprovalconfigstep(data: any) {
        //   const updatedTodo = await API.graphql({ query:updateDxVehicle, variables: {input: todoDetails}});
        let resp: any = await API.graphql({
            query: createDxApprovalConfigSteps,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });
        return resp.data.createDxApprovalConfigSteps;
    }

    async AddnewWorkflow(data: any) {
        //   const updatedTodo = await API.graphql({ query:updateDxVehicle, variables: {input: todoDetails}});
        let resp: any = await API.graphql({
            query: createDxWorkflow,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });
        return resp.data.createDxWorkflow;
    }

    async getApprovalConfigs() {
        let GetResponse: any = await API.graphql({ query: _listDxApprovalConfigs, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        return GetResponse.data.listDxApprovalConfigs.items;
    }
}
export default new AmplifyServices();
