import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import AmplifyServices from '../../Service/Service';
import ListTable from '../../Components/ListTable';
import { Storage } from 'aws-amplify';
import moment from 'moment';
import { getNextQueryData } from '../../utils/helper';
const Allocation = (props: any) => {
    const [Allocation, setAllocation] = useState<any>([]);
    const [Tablefilter, setTableFilters] = useState<any>('');
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    const navigate = useNavigate();

    const NavigateToHome = () => navigate(`/workspace/Mileagelog?&id=${formid}&fleettype=vehicle#activity`);
    useEffect(() => {
        console.log(formid);
        getcurrentvehicle();
    }, []);
    const getcurrentvehicle = async () => {
        let filter = {
            vehicleid: {
                eq: formid // filter priority = 1
            }
        };
        await getNextQueryData('', filter, 'allocation', true).then((item: any) => {
            console.log(item);
            item = item
                .sort(function (a: any, b: any) {
                    return moment(b.fromdate).format().localeCompare(moment(a.fromdate).format());
                })
                .filter((item: any) => item._deleted == null || item._deleted == false);
            // getFileLocation(item);
            setAllocation(formatDatefield(item));
        });
        AmplifyServices.getAllocationdetails(formid).then((resp: any) => {
            console.log(resp);
            // resp = resp
            //     .sort(function (a: any, b: any) {
            //         return moment(b.fromdate).format().localeCompare(moment(a.fromdate).format());
            //     })
            //     .filter((item: any) => item._deleted == null || item._deleted == false);
            // // getFileLocation(item);
            // setAllocation(formatDatefield(resp));
        });
    };
    const formatDatefield = (data: any) => {
        return [...(data || [])].map((d) => {
            d.fromdate = new Date(d.fromdate);
            d.handoverdate = new Date(d.handoverdate);
            return d;
        });
    };
    const getFileLocation = (e: any) => {
        let FinalData: any = [];
        e.map(async (item: any) => {
            console.log(item);
            let vid = item.vehicleid.includes(',') ? item.vehicleid.replace(',', ';') : item.vehicleid;
            let file = item.pictures && item.documents;
            console.log(file);
            let splitfile = file.split(',');
            console.log(splitfile);
            for (let i = 0; i < splitfile.length; i++) {
                let url = await Storage.get(splitfile[i]);
                console.log(`${item.vehicleid}/documents`);
                console.log(splitfile[i].includes(`${vid}/documents`));

                FinalData.push({
                    name: splitfile[i].split('/').pop(),
                    documents: url,
                    renewaltype: item.renewaltype,
                    expirydate: item.expirydate,
                    type: splitfile[i].includes(`${vid}/documents`) ? 'Documents' : splitfile[i].includes(`${vid}/picture`) ? 'Pictures' : splitfile[i].includes(`${vid}/receipts`) ? 'Receipts' : ''
                });
                console.log(FinalData);
            }
            let filterfinaaldata: any = [];
            let removeduplicates: any = [];
            filterfinaaldata = FinalData.filter((fil: any, index: any) => fil.name !== '');

            removeduplicates = filterfinaaldata.filter((item: any, index: any) => filterfinaaldata.indexOf(item) === index);

            setAllocation(removeduplicates);
        });
    };
    console.log(Allocation);

    const columns = [
        { field: 'vehicleid', textalign: 'left', header: 'Vehicle', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'driver', textalign: 'left', header: 'Driver', fieldtype: 'lookup', filterOption: true, filterFieldOption: 'driver' },
        { field: 'fromdate', textalign: 'left', header: 'From Date', dataType: 'date', fieldtype: 'datefilter', showtime: false, filterOption: true, filterFieldOption: 'fromdate' },
        { field: 'handoverdate', textalign: 'left', header: 'To Date', dataType: 'date', fieldtype: 'datefilter', showtime: false, filterOption: true, filterFieldOption: 'handoverdate' },
        { field: 'allocatestatus', textalign: 'left', header: 'Status', fieldtype: 'text', filterOption: true, filterFieldOption: 'allocatestatus' },
        { field: 'pictures', textalign: 'left', header: 'Documents', fieldtype: 'custom', filterOption: false, filterFieldOption: 'Accidents', template: (r: any) => <>{documentBodyTemplate(r)}</> },
        { field: 'pictures', textalign: 'left', header: 'Pictures', fieldtype: 'custom', filterOption: false, filterFieldOption: 'Accidents', template: (r: any) => <>{PictureBodyTemplate(r)}</> }
        // { field: 'drivername', header: 'drivername', fieldtype: 'text', filterOption: true, filterFieldOption: 'drivername' },
        // { field: 'date', header: 'date', fieldtype: 'date', showtime: false, filterOption: true, filterFieldOption: 'date' }
        // { field: 'status', header: 'Status', fieldtype: 'custom', filterOption: true, filterFieldOption: 'status', template: (r: any) => <>{contractStatusBodyTemplate(r)}</> }
    ];

    const documentBodyTemplate = (rowData: any) => {
        console.log(rowData.documents);
        return (
            <>
                {' '}
                {rowData.documents !== null && rowData.documents == true && (
                    <>
                        <a href={`#/manage/vehicles/allocationsAttachment?type=documents&id=${rowData.id}&vid=${rowData.vehicleid}&mode=${formmode}`}>view documents</a>
                    </>
                )}
            </>
        );
    };
    const PictureBodyTemplate = (rowData: any) => {
        console.log(rowData.pictures);

        return (
            <>
                {rowData.pictures !== null && rowData.pictures == true && (
                    <>
                        <a href={`#/manage/vehicles/allocationsAttachment?type=pictures&id=${rowData.id}&vid=${rowData.vehicleid}&mode=${formmode}`}>view pictures</a>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <div>
                <div className="grid">
                    <div className="col-12 md:col-12">
                        {/* <Button onClick={NavigateToHome} className="mr-2 mt-2 ml-3">
                            Add Logs
                        </Button> */}
                        <div className="card">
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <h5>Allocation</h5>
                                </div>
                                <div className="field col-12  md:col-12">
                                    <ListTable
                                        header="Manage Contracts"
                                        value={Allocation}
                                        paginator={true}
                                        rows={10}
                                        dynamicColumns={columns}
                                        emptyMessage="No Allocation found."
                                        responsiveLayout="scroll"
                                        className="p-datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Allocations"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[5, 10, 25]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                        tablefilter={Tablefilter}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Allocation;
