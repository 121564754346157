import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import Documents from "./Documents";
import { Button as AwsButton } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { localetoisodatetime } from "../../utils/helper";
import dxService from "../../Services/dxService";
import moment from "moment";
const currentDate = new Date();

// Get the month and year of the previous month
const previousMonth = currentDate.getMonth() - 1;
const previousYear = currentDate.getFullYear();

// Create a new Date object for the previous month's start date
const previousMonthStartDate = new Date(previousYear, previousMonth, 1);

// Create a new Date object for the current month's start date
const currentMonthStartDate = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);

// Set the previous month's end date to the day before the current month's start date
const previousMonthEndDate = new Date(currentMonthStartDate.getTime() - 1);

// Format the start and end dates as desired
const previousMonthStartDateFormatted =
  previousMonthStartDate.toLocaleDateString();
const previousMonthEndDateFormatted = previousMonthEndDate.toLocaleDateString();
console.log(previousMonthEndDateFormatted);
let Shiftitems: any = [];
let savedData: any = [];
let StoreDate: any = [];
let StoreDescription: any = [];
let updatedData: any = [];
function ShiftImport() {
  const navigate = useNavigate();
  const [driver, setDriver] = useState<any>([]);
  const [sdate, setsdate] = useState(new Date(previousMonthStartDateFormatted));
  const [edate, setedate] = useState(new Date(previousMonthEndDateFormatted));
  const [charges, setCharges] = useState<any>([]);
  const [jsonData, setJsonData] = useState<any>([]);
  const [savingData, setSavingData] = useState(false);
  const [Vehicle, setVehicle] = useState<any>([]);
  const [drivershifts, setdrivershifts] = useState<any>([]);
  const validateallocations = async () => {
    console.log(jsonData);
  };
  useEffect(() => {
    getAllocatedDriverDetails().then((res: any) => {
      setDriver(res);
    });
    getAllvehicles().then((res) => {
      setVehicle(res);
    });
    getDxdriverShifts().then((res) => {
      console.log(res);
      setdrivershifts(res);
    });
    // setDriver(data);
  }, [charges]);
  const getDxdriverShifts = async (nextToken?: any) => {
    let variables: any = {};
    let GetResponse: any = await dxService.listitems(
      "drivershifts",
      "*",
      variables,
      true,
      100,
      nextToken
    );
    let response = GetResponse.items;

    let nexttoken = GetResponse.nextToken;
    if (nexttoken) {
      await getDxdriverShifts(nexttoken).then((res: any) => {
        setdrivershifts((olditems: any) => [...olditems, ...res]);
      });
    }

    return response;
  };
  const getAllocatedDriverDetails = async (nextToken?: any) => {
    let variables: any = {};

    var sd = new Date(sdate);
    var ed = new Date(edate).setHours(23, 59, 59);

    let filter = {
      or: [
        {
          and: [
            { fromdate: { ge: localetoisodatetime(sd) } },
            { handoverdate: { attributeExists: true } },
            { handoverdate: { le: localetoisodatetime(ed) } },
          ],
        },
        {
          and: [
            { fromdate: { le: localetoisodatetime(sd) } },
            { handoverdate: { attributeExists: false } },
          ],
        },
        {
          and: [
            {
              fromdate: { le: localetoisodatetime(sd) },
            },
            { handoverdate: { ge: localetoisodatetime(ed) } },
          ],
        },
        {
          and: [
            {
              fromdate: { le: localetoisodatetime(sd) },
            },
            { handoverdate: { le: localetoisodatetime(ed) } },
            { handoverdate: { ge: localetoisodatetime(sd) } },
          ],
        },
        {
          and: [
            {
              fromdate: { ge: localetoisodatetime(sd) },
            },
            {
              fromdate: { le: localetoisodatetime(ed) },
            },
            { handoverdate: { attributeExists: false } },
          ],
        },
      ],
    };

    variables = filter;
    let GetResponse: any = await dxService.listitems(
      "allocation",
      "*",
      variables,
      true,
      1000,
      nextToken
    );
    let response = GetResponse.items;

    let nexttoken = GetResponse.nextToken;
    if (nexttoken) {
      // const nextResults = await getAllocatedDriverDetails(nextToken);
      // response = response.concat(nextResults);
      await getAllocatedDriverDetails(nexttoken).then((res: any) => {
        setDriver((olditems: any) => [...olditems, ...res]);
      });
    }
    // let GetResponse: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    // let res = GetResponse.data.listDxAllocations.items;
    // console.log(response);
    return response;
  };
  const getAllvehicles = async (nextToken?: any) => {
    let variables: any = {};
    let GetResponse: any = await dxService.listitems(
      "vehicle",
      "*",
      variables,
      true,
      100,
      nextToken
    );
    let response = GetResponse.items;

    let nexttoken = GetResponse.nextToken;
    if (nexttoken) {
      await getAllvehicles(nexttoken).then((res: any) => {
        setVehicle((olditems: any) => [...olditems, ...res]);
      });
    }

    // let GetResponse: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    // let res = GetResponse.data.listDxAllocations.items;
    return response;
  };
  const FinddriverId = async (id: any, type: any) => {
    let filter = {
      name: {
        contains: type == "shift" ? id.driver : id,
      },
    };
    let drivername = await dxService.listitems("driver", "*", filter);
    // console.log(approvalresponse);
    return drivername;
  };
  const Finddriverallocation = async (id: any, vid: any) => {
    let filter = {
      vehicleid: {
        eq: vid,
      },
    };
  };
  const checkNaughtyDriver = () => {
    if (charges.name === "Shift") {
      if (jsonData.length > 0) {
        jsonData.map(async (item: any) => {
          let createitem = {
            id: item.id,
            shift: item.id,
            starttime: item.StartTime,
            endtime: item.EndTime,
          };
          // console.log(createitem);

          let master = await dxService.createitem("shifts", createitem, false);
          // console.log("Saved to master log", master);
        });
      }
    } else if (charges.name === "DriverShift") {
      let storeitem: any = [];
      console.log(jsonData, "save");
      let reformatjson: any = [];

      // Extract the header row to get the driver names
      let drivers = Object.keys(jsonData[0]).filter(
        (key) => key !== "IIII" && key !== "__EMPTY"
      );

      // Iterate over the json array
      for (let i = 1; i < jsonData.length; i++) {
        const date = jsonData[i]["IIII"];
        const day = jsonData[i]["__EMPTY"];

        // Iterate over the drivers
        drivers.forEach((driver) => {
          const shift: any = jsonData[i][driver];

          // Create a new object with the desired format
          reformatjson.push({ date, driver, shift, day });
        });
      }

      console.log(reformatjson);
      reformatjson.forEach(async (item: any) => {
        // console.log(item);

        await FinddriverId(item, "shift").then(async (success: any) => {
          // console.log(success);
          if (success.length > 0) {
            let createitem = {
              date: localetoisodatetime(new Date(item.date)),
              day: item.day,
              dxDriverDrivershiftsId: success[0].id,
              // driver: success[0].name,
              dxshiftsDrivershiftId: item.shift,
            };
            storeitem.push(createitem);
            console.log(storeitem);
            let drivershift = await dxService.createitem(
              "drivershifts",
              createitem,
              false
            );
            // console.log("Saved to master log", drivershift);
          }
        });
      });
    } else if (charges.name === "Mileage") {
      Shiftitems = [];
      StoreDate = [];
      if (jsonData) {
        const formattedJson = jsonData.map(async (item: any) => {
          // console.log(item);

          if (Object.keys(item).length > 10) {
            let keys = Object.keys(item);
            const StartdateString = item[keys[1]].split(" ")[1];

            const startparts =
              StartdateString !== undefined && StartdateString.split(".");
            const startformattedDateString = `${startparts[1]} ${startparts[0]} ${startparts[2]}`;
            const sdate = new Date(startformattedDateString);
            // console.log(sdate);
            const EnddateString = item[keys[1]].split(" ")[1];
            const endparts =
              EnddateString !== undefined && EnddateString.split(".");
            const endformattedDateString = `${endparts[1]} ${endparts[0]} ${endparts[2]}`;
            const edate = new Date(endformattedDateString);
            Object.assign(item, {
              description: item[keys[0]],
              vehicleid: item[keys[0]].split(" ").pop(),
              startdatetime: item[keys[1]],
              startdate: new Date(sdate),
              enddate: new Date(edate),
              enddatetime: item[keys[2]],
              Start: item[keys[3]],

              Startaddress: item[keys[4]],
              End: item[keys[5]],
              Endaddress: item[keys[6]],
              duration: item[keys[7]],
              distance: item[keys[8]],
              avgspeed: item[keys[9]],
              maxspeed: item[keys[10]],
            });

            StoreDescription.push({
              des: item.description,
              date: item.startdate,
            });
            // console.log(item);

            StoreDate.push(item);
          }
        });
      }
      // }
      // }
      // console.log(StoreDescription);
      // console.log(StoreDate);
      const groupedData = StoreDate.reduce((acc: any, obj: any) => {
        const key = obj.description + obj.startdate;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

      const results = Object.values(groupedData);
      console.log(results);
      results.map(async (item: any) => {
        let drivershiftObj: any = {};
        // console.log(item);
        // Find the earliest start datetime and latest end datetime
        const groStartDatetime = item.reduce((minStart: any, item: any) => {
          const startDatetime = new Date(item.startdatetime);
          return startDatetime < minStart ? startDatetime : minStart;
        }, new Date("9999-12-31"));
        const groStartDatetimeitem = item[0].startdatetime.split(" ")[0];
        const groEndDatetimeitem =
          item[item.length - 1].enddatetime.split(" ")[0];
        console.log(groStartDatetimeitem, groEndDatetimeitem);

        const groEndDatetime = item.reduce((maxEnd: any, item: any) => {
          const endDatetime = new Date(item.enddatetime);
          return endDatetime > maxEnd ? endDatetime : maxEnd;
        }, new Date("1970-01-01"));
        let cumulativeDuration = 0;

        item.forEach((obj: any) => {
          const durationParts = obj.duration.split(":");
          const hours = parseInt(durationParts[0]);
          const minutes = parseInt(durationParts[1]);
          const seconds = parseInt(durationParts[2]);

          const totalSeconds = hours * 3600 + minutes * 60 + seconds;
          cumulativeDuration += totalSeconds;
        });

        const hours = Math.floor(cumulativeDuration / 3600);
        const minutes = Math.floor((cumulativeDuration % 3600) / 60);
        const seconds = cumulativeDuration % 60;

        const formattedDuration = `${hours}:${minutes}:${seconds}`;
        // console.log(formattedDuration);
        let cumulativeDistance = 0;

        item.forEach((obj: any) => {
          const distanceStr = obj.distance.split(" ")[0];
          const distanceNum = parseFloat(distanceStr);
          cumulativeDistance += distanceNum;
        });
        drivershiftObj = {
          des: item[0].description,
          // driverid: driverid,
          // drivername: drivername,
          vehicleid: item[0].vehicleid,
          starttime: groStartDatetimeitem,
          endtime: groEndDatetimeitem,
          totalduration: formattedDuration,
          totaldistance: cumulativeDistance,
          date: moment(item[0].startdate).format("DD-MMM-YYYY"),
        };
        console.log(drivershiftObj);
        let storedname = item[0].description;
        let dname = storedname.split(" ")[1];
        // console.log(storedname.split(" ")[1]);

        let nameRegex = /^[a-zA-Z]+$/;
        if (nameRegex.test(dname)) {
          let filter = {
            name: {
              contains: dname,
            },
          };
          let drivername = await dxService.listitems("driver", "*", filter);
          // console.log(drivername);

          drivershiftObj["driverid"] = drivername[0].id;
          drivershiftObj["driver"] = drivername[0].name;
          // console.log(drivershiftObj);
        } else {
          // console.log(driver);
          let formatdate = moment(item[0].startdate).format("DD-MMM-YYYY");
          let dname = await driver.filter(
            (d: any) =>
              d.vehicleid == item[0].vehicleid &&
              (d.fromdate !== null && d.handoverdate !== null
                ? moment(formatdate).isBetween(
                    new Date(d.fromdate),
                    new Date(d.handoverdate)
                  )
                : new Date(formatdate) > new Date(d.fromdate))
          );
          // console.log(dname);
          drivershiftObj["driverid"] = dname[0].driver.id;
          drivershiftObj["driver"] = dname[0].driver.name;
        }

        Shiftitems.push(drivershiftObj);
        // console.log(Shiftitems);

        function findCommonItem(item: any) {
          return Shiftitems.find((bItem: any) => {
            let formatShiftitems = moment(new Date(bItem.date)).format(
              "MM-DD-YY"
            );
            let formatitem = moment(new Date(item.date)).format("MM-DD-YY");
            // console.log(bItem, bItem.des, bItem.driver);

            if (
              formatitem == formatShiftitems &&
              item.driver.id == bItem.driverid
            ) {
              // console.log(item, bItem);
              let updateitem = {
                startKm: bItem.starttime,
                endkm: bItem.endtime,
                totalduration: bItem.totalduration,
                Mileage: bItem.totaldistance,
                vehicleid: bItem.vehicleid,
                id: item.id,
                _version: item._version,
              };
              let os = dxService.updateitem("drivershifts", updateitem);
              // return item;
            }
          });
        }
        const commonShifts = await drivershifts.filter(findCommonItem);

        // console.log(commonShifts);
      });
      // console.log(Shiftitems, drivershifts);

      // Filter the common items from array a

      // Shiftitems.find((bItem: any) => {
      //   console.log(bItem.date);
      // });
    }
  };
  return (
    <>
      <div className="col-12 md:col-12">
        <h4>Import Shift</h4>
        <div className="card p-fluid">
          <div className="field">
            <div className="col-12 sm:col-8 md:col-7 grid">
              <div className="col-12 md:col-2 mt-2">
                <label htmlFor="">Select</label>
              </div>
              <div className="col-12 md:col-10">
                <Dropdown
                  options={[
                    { label: "Shift", name: "Shift" },
                    { label: "DriverShift", name: "DriverShift" },
                    { label: "Mileage", name: "Mileage" },
                  ]}
                  value={charges}
                  onChange={(e) => {
                    savedData = [];
                    updatedData = [];
                    setCharges(e.target.value);
                  }}
                  optionLabel="label"
                  placeholder="Select an option"
                  filter
                />
              </div>
            </div>
            <div className="col-12 sm:col-8 md:col-7 grid">
              <div className="col-12 md:col-2 mt-2">
                <label htmlFor="">Start Date</label>
              </div>
              <div className="col-12 md:col-4">
                <Calendar
                  value={sdate}
                  onChange={(e: any) => {
                    setsdate(e.value);
                  }}
                ></Calendar>
              </div>
              <div className="col-12 md:col-2 mt-2">
                <label htmlFor="">End Date</label>
              </div>
              <div className="col-12 md:col-4">
                <Calendar
                  value={edate}
                  onChange={(e: any) => {
                    setedate(e.value);
                  }}
                ></Calendar>
              </div>
              {/* <div className="col-12 md:col-4">
                                <Button onClick={(e: any) => validateallocations()} label="Validate Allocations" />
                            </div> */}
            </div>
          </div>
          {charges.name !== undefined && (
            <div className="field">
              <div className="col-12 sm:col-8 md:col-7 grid">
                <div className="col-12 md:col-2 mt-2">
                  <label htmlFor="">Upload File</label>
                </div>
                <div className="col-12 md:col-10">
                  <Documents setJsonData={setJsonData} />
                  {/* <FileUpload accept=".xlsx, .xls*" auto={true} customUpload={true} uploadHandler={handleFileUpload} /> */}
                </div>
              </div>
            </div>
          )}
          <div className="field col-5 grid md:col-3">
            <AwsButton
              className=" mr-2"
              disabled={
                charges.name === undefined || jsonData.length === 0
                  ? true
                  : false
              }
              onClick={async () => {
                checkNaughtyDriver();
                // validatedriver();
              }}
            >
              {savingData === false ? "Submit" : "Saving"}
            </AwsButton>
            <AwsButton
              disabled={savingData ? true : false}
              style={{
                background: "#d32f2f",
                color: "#fff",
                border: "none",
              }}
              onClick={async () => {
                navigate("/");
              }}
            >
              Cancel
            </AwsButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShiftImport;
