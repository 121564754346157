import React, { useEffect, useState } from 'react';
import AmplifyServices from '../../Service/Service';
import UploadDocuments from '../../Components/UploadDocuments';
import Configsviewform from './Viewform';
import { updateDxGeneralConfig } from '../../graphql/mutations';
import { listDxGeneralConfigs } from '../../graphql/queries';
const Documents = (props: any) => {
    console.log(props.formid);

    const [documentpath, setdocumentpath] = useState<any>([]);
    const [configsData, setconfigsData] = useState<any>([]);
    useEffect(() => {
        getconfigsData();
        console.log(documentpath);
    }, [documentpath]);
    const getconfigsData = async () => {
        if (props.formid !== undefined || props.formid !== null || props.formid !== ' ') {
            AmplifyServices.getFilterbyIddetails(listDxGeneralConfigs, 'listDxGeneralConfigs', props.formid)
                .then(async (res: any) => {
                    console.log(res);

                    // res = res.filter((item: any) => item._deleted === null || item._deleted === false);
                    setconfigsData(res[0]);

                    UpdateConfigImage();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const UpdateConfigImage = async () => {
        let b: any = configsData;
        b['images'] = documentpath;
        Object.keys(b).forEach((key) => {
            if (b[key] == null || b[key] == '') {
                delete b[key];
            }
        });
        if (Object.keys(b).includes('_lastChangedAt')) delete b['_lastChangedAt'];
        if (Object.keys(b).includes('createdAt')) delete b['createdAt'];
        if (Object.keys(b).includes('updatedAt')) delete b['updatedAt'];

        AmplifyServices.EditConfigDetails(updateDxGeneralConfig, 'updateDxGeneralConfig', b)
            .then(async (res: any) => {
                console.log(res, 'updated');
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            {props.formmode == 'view' ? (
                <>
                    <Configsviewform />
                </>
            ) : (
                <>
                    <UploadDocuments setdocumentpath={setdocumentpath} EnableautoUpload={false} bucketname="generalConfig" />
                    <Configsviewform />
                </>
            )}
        </>
    );
};
export default Documents;
