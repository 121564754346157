import { API, Storage } from 'aws-amplify';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { listDxGeneralConfigs } from '../../graphql/queries';

const Configsviewform = () => {
    const [displayDocuments, setDisplayDocuments] = useState([]);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    useEffect(() => {
        getDocumentDetails();
    }, []);

    const getDocumentDetails = async () => {
        console.log(formid);
        setDisplayDocuments([]);
        let filterItem = {
            id: {
                eq: formid
            }
        };
        let GetResponse: any = await API.graphql({
            query: listDxGeneralConfigs,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                filter: filterItem
            }
        });
        console.log(GetResponse.data.listDxGeneralConfigs.items);

        getFileLocation(GetResponse.data.listDxGeneralConfigs.items);
        // setDisplayDocuments(GetResponse.data.listDxVehicleSpares.items);
    };
    const getFileLocation = (e: any) => {
        let FinalData: any = [];
        e.map(async (item: any) => {
            let images = item.images !== null && JSON.parse(item.images).File;
            for (let i = 0; i < images.length; i++) {
                let url = await Storage.get(images[i]);
                FinalData.push({
                    name: images[i].split('/').pop(),
                    fleettype: item.fleettype,
                    images: url
                });
            }

            setDisplayDocuments(FinalData);
        });
    };
    const AttachmentBodyTemplate = (e: any) => {
        return (
            <a href={e.images} target="_blank" className="">
                {e.name}
                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
            </a>
        );
    };
    const nameBodyTemplate = (e: any) => {
        return <span className="">{e.name}</span>;
    };
    const typeBodyTemplate = (e: any) => {
        return <span className="">{e.fleettype}</span>;
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <div className="card">
                    <h5>Documents</h5>
                    <DataTable
                        value={displayDocuments}
                        dataKey="id"
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                        emptyMessage="No Documents added."
                        responsiveLayout="scroll"
                    >
                        <Column field="name" header="Name" body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="fleettype" header="Type" body={typeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="licensepicture" header="Document" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
export default Configsviewform;
