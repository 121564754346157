import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import AmplifyServices from '../../Service/Service';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { listDxDepartments } from '../../graphql/queries';
import ListTable from '../../Components/ListTable';
import { deleteDxDepartment } from '../../graphql/mutations';
import { InputText } from 'primereact/inputtext';
import { listdxdepts } from '../../dxgraphql/dxqueries';

let params: any;
const DepartmentView = () => {
    let emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };
    const menu = useRef<any>(null);
    const [products, setProducts] = useState<any>(null);
    const [deleteProductDialog, setDeleteProductDialog] = useState<boolean>(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState<boolean>(false);
    const [product, setProduct] = useState<any>(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const [globalFilter, setGlobalFilter] = useState<any>('');

    const toast = useRef<any>(null);

    params = selectedProducts.length > 0 && `?mode=edit&id=${selectedProducts[0].id}&fleettype=${selectedProducts[0].fleettype}`;

    useEffect(() => {
        getData();
    }, [deleteProductDialog, deleteProductsDialog]);
    const getData = async () => {
        await AmplifyServices.getTableDetails(listdxdepts, 'listDxDepartments').then(async (res: any) => {
            setProducts(res);
        });
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setSelectedProducts([]);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };
    const navigate = useNavigate();

    const editProduct = (product: any) => {
        navigate('/admin/departments/item/basic?mode=edit&id=' + product.id);
        // setProductDialog(true);
    };

    const confirmDeleteProduct = (product: any) => {
        setSelectedProducts([product]);
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };
    const actionBodyTemplateDepartment = (rowData: any) => {
        return <span>{rowData['company'] ? rowData['company'].name : ''}</span>;
    };

    const columns = [
        // { fieldtype: 'multiselect' },
        {
            field: 'name',
            header: 'Name',
            fieldtype: 'text',
            filterOption: true,
            filterFieldOption: 'name'
        },
        {
            field: 'description',
            header: 'Description',
            fieldtype: 'text',
            filterOption: true,
            filterFieldOption: 'description'
        },
        {
            field: 'company',
            header: 'Business Unit',
            fieldtype: 'custom',
            filterOption: true,
            filterFieldOption: 'company',
            template: (r: any) => <>{actionBodyTemplateDepartment(r)}</>
        },
        {
            field: 'owner',
            header: 'Owner',
            fieldtype: 'text',
            filterOption: true,
            filterFieldOption: 'owner'
        },
        {
            field: 'member',
            header: 'Member',
            fieldtype: 'text',
            filterOption: true,
            filterFieldOption: 'member'
        },
        {
            field: 'fleettype',
            header: 'Actions',
            fieldtype: 'custom',
            filterOption: false,
            template: (r: any) => <>{actionBodyTemplate(r)}</>
        }
    ];

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-danger " onClick={() => deleteItem()} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-danger " onClick={() => deleteItem()} />
        </>
    );
    const deleteItem = async () => {
        await AmplifyServices.DeleteItems(selectedProducts, deleteDxDepartment).then((res) => {
            getData();
            hideDeleteProductDialog();
            hideDeleteProductsDialog();
            setSelectedProducts([]);
            selectedProducts.length = 0;
        });
    };

    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New" onClick={() => navigate('/admin/departments/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />

                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />

                <Menu
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedProducts.length == 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                navigate('/admin/departments/item/basic' + params);
                            }
                        },
                        {
                            disabled: selectedProducts.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            <div>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <form>
                        <div
                            style={{
                                position: 'relative',
                                marginTop: '3px',
                                marginBottom: '1em'
                            }}
                        >
                            <InputText type="text" id={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
                        </div>
                    </form>
                </span>
            </div>
        </div>
    );
    let filteredSearch =
        products &&
        products
            .filter((del: any) => del._deleted !== true)
            .filter((name: any) => {
                console.log(name);

                if (
                    (name.name !== null && name.name.toLowerCase().includes(globalFilter.toLowerCase().toLowerCase())) ||
                    (name.description !== null && name.description.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase().toLowerCase())) ||
                    (name.owner !== null && name.owner.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.member !== null && name.member.toLowerCase().includes(globalFilter.toLowerCase()))
                ) {
                    return name;
                }
            });

    return (
        <div className="grid crud">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <ListTable
                        header="Departments"
                        value={filteredSearch}
                        paginator={true}
                        rows={100}
                        dynamicColumns={columns}
                        emptyMessage="No department found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Department"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[100, 200, 300]}
                        //   selection={selectedProducts}
                        multiselect={true}
                        //   selectedItems={selectedProducts}
                        Commandbar={Commandbar}
                        // Commandbar={leftToolbarTemplate}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}
                        selectedItems={setSelectedProducts}
                    />

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && (
                                <span>
                                    Are you sure you want to delete <b>{product.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default DepartmentView;
