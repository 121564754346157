import React from 'react';

const AdditionalConsumptions = (props: any) => {
    const currentDate = new Date();
    const totalAccidents =
        props.data &&
        props.data.reduce((total: number, obj: { Accidents: string }) => {
            if (obj.Accidents && obj.Accidents.trim() !== '') {
                return total + 1;
            }
            return total;
        }, 0);
    const currentMonthAccidents =
        props.data &&
        props.data.reduce((total: number, obj: { date: string | number | Date; Accidents: string }) => {
            const objDate = new Date(obj.date);
            if (objDate.getMonth() === currentDate.getMonth() && objDate.getFullYear() === currentDate.getFullYear() && obj.Accidents && obj.Accidents.trim() !== '') {
                return total + 1;
            }
            return total;
        }, 0);
    const totalMileage = props.data && props.data.reduce((total: any, obj: { Mileage: any }) => total + Number(obj.Mileage), 0);
    const currentMonthMileage =
        props.data &&
        props.data.reduce((total: any, obj: { date: string | number | Date; Mileage: any }) => {
            const objDate = new Date(obj.date);
            if (objDate.getMonth() === currentDate.getMonth() && objDate.getFullYear() === currentDate.getFullYear()) {
                return total + obj.Mileage;
            }
            return total;
        }, 0);
    const totalReimbursement = props.data && props.data.reduce((total: any, obj: { reimbursement: any }) => total + Number(obj.reimbursement), 0);
    const currentMonthReimbursement =
        props.data &&
        props.data.reduce((total: any, obj: { date: string | number | Date; reimbursement: any }) => {
            const objDate = new Date(obj.date);
            if (objDate.getMonth() === currentDate.getMonth() && objDate.getFullYear() === currentDate.getFullYear()) {
                return total + obj.reimbursement;
            }
            return total;
        }, 0);
    return (
        <div className="grid dashboard">
            <div className="col-12 md:col-6 lg:col-4" style={{ flex: '1 1' }}>
                <div className="card overview-box flex flex-column pt-2" style={{ height: "100%" }}>
                    <div className="flex align-items-center">
                        <h5>Mileage</h5>
                    </div>
                    <div className="flex justify-content-between mt-3 flex-wrap" style={{ height: '100%' }}>
                        <div className="flex flex-column justify-content-between" style={{ minWidth: '100px' }}>
                            <span className="mb-1 fs-xlarge" title="Current Month">
                                {currentMonthMileage.toFixed(2)} KM
                            </span>
                            <span className="overview-status p-2 cyan-bgcolor fs-large" style={{ display: 'flex', placeContent: 'center', placeItems: 'center', fontSize: 'calc(12px + (50 - 40) * ((100vw - 300px) / (1600 - 300)))' }} title="Total KM">
                                Total: {totalMileage.toFixed(2)} KM
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4" style={{ flex: '1 1' }}>
                <div className="card overview-box flex flex-column pt-2" style={{ height: "100%" }}>
                    <div className="flex align-items-center">
                        <h5>Acidents</h5>
                    </div>
                    <div className="flex justify-content-between mt-3 flex-wrap" style={{ height: '100%' }}>
                        <div className="flex flex-column justify-content-between" style={{ minWidth: '100px' }}>
                            <div>
                                <span className="mb-1 fs-xlarge" title="Current Month">
                                    {currentMonthAccidents}
                                </span>
                            </div>
                            <span className="overview-status p-2 cyan-bgcolor fs-large" style={{ display: 'flex', placeContent: 'center', placeItems: 'center', fontSize: 'calc(12px + (50 - 40) * ((100vw - 300px) / (1600 - 300)))' }} title="Total Accidents">
                                Total: {totalAccidents}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4" style={{ flex: '1 1' }}>
                <div className="card overview-box flex flex-column pt-2" style={{ height: "100%" }}>
                    <div className="flex align-items-center">
                        <h5>Reimbursement</h5>
                    </div>
                    <div className="flex justify-content-between mt-3 flex-wrap" style={{ height: '100%' }}>
                        <div className="flex flex-column justify-content-between" style={{ minWidth: '100px' }}>
                            <div>
                                <span className="mb-1 fs-xlarge" title="Current Month">
                                    {currentMonthReimbursement.toFixed(2)}
                                </span>
                            </div>
                            <span className="overview-status p-2 cyan-bgcolor fs-large" style={{ display: 'flex', placeContent: 'center', placeItems: 'center', fontSize: 'calc(12px + (50 - 40) * ((100vw - 300px) / (1600 - 300)))' }} title="Total Reimbursed Amount">
                                AED: {totalReimbursement.toFixed(2)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdditionalConsumptions;
