import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createDxContract, createDxVehicle, createDxAppointments, createDxVehicleActivity, deleteDxVehicle, deleteDxVehicleActivity, updateDxVehicle, updateDxVehicleActivity, createDxDocument } from '../../graphql/mutations';
import { listDxContracts, listDxVehicles, listDxVehicleActivities } from '../../graphql/queries';

import { Amplify, Storage } from 'aws-amplify';
import { Messages } from 'primereact/messages';
import moment from 'moment';
import './Renewals.css';
import awsExports from '../../aws-exports';
import dxService from '../../Services/dxService';
import { getGenralconfigs, getvendorCompanyoptions, getvendorGarageoptions, getvendoroptions, isotolocaledatetime, localetoisodatetime, getVehicleactivityoptions, getNextQueryData } from '../../utils/helper';
import Loader from '../../Components/Loader';
import RenewFormTemplate from '../../Components/RenewFormTemplate';

var UploadDocuments: any = [];
var storeVehicleActivityData: any = [];
var storeVehicleData: any = [];
var StoreScVid: any = [];
var vehicleactivityitems: any;
var vehicleitems: any;
let CollectScVid: any = [];
let vehicleId: any = '';
let renewaltype: any = '';
function RenewalForm() {
    const [updatedata, setupdatedata] = useState<any>([]);
    const [ExpiringSC, setExpiringSC] = useState<any>([]);
    const [listFormData, setlistFormData] = useState<any>({});

    const [GarageVendorOptions, setGarageVendorOptions] = useState<any>([]);
    const [CompanyVendorOptions, setGarageCompanyOptions] = useState<any>([]);
    const navigate = useNavigate();
    const [appointmentNeeded, setAppointmentNeeded] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [contract, setcontract] = useState<any>([]);
    const [renewaltypeoption, setRenewaltypeoptions] = useState<any>([]);
    const [displayDialog, setdisplayDialog] = useState(false);

    const msgs = useRef<any>(null);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var VehicleID = urlParams.has('id') ? urlParams.get('id') : null;
    var expType = urlParams.has('exp') ? urlParams.get('exp') : null;
    var Type = urlParams.has('type') ? urlParams.get('type') : null;

    useEffect(() => {
        let scid: any = expType == 'ServiceContract' ? [VehicleID] : VehicleID;
        setlistFormData({ id: scid, renewaltype: expType, renewalstatus: 'In Progress', isappointmentrequire: false });
        getDropdownOptions();

        getScExpiringData();
        getRenewalType();
    }, []);
    useEffect(() => {
        if (VehicleID) getRenewalData();
        if (formmode == 'new') getRenewalData();
    }, [vehicleId, renewaltype]);

    /**
    getRenewalData() is function which filter the previous contract with a Active status in renewal table
    the result is used for updating the Active item status as Completed status.
 */
    const getRenewalData = async (nextToken?: any) => {
        // let variables: any = {};
        let variables: any = {
            filter: {
                vehicleid: {
                    eq: vehicleId !== null || vehicleId !== undefined ? vehicleId.toString() : VehicleID
                },
                renewaltype: {
                    eq: renewaltype !== null || renewaltype !== undefined ? renewaltype : expType
                },
                renewalstatus: { eq: 'Active' }
                // or: [{ renewalstatus: { eq: 'InProgress' } }, { renewalstatus: { eq: 'Active' } }]
            }
        };
        if (nextToken) {
            variables.nextToken = nextToken;
        }

        let GetResponse: any = await API.graphql({
            query: listDxContracts,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: variables
        });

        let res = GetResponse.data.listDxContracts.items;
        if (GetResponse.data.listDxContracts.nextToken) {
            // const nextResults = await getRenewalData(GetResponse.data.listDxContracts.nextToken);

            const nextResults: any = await getRenewalData(GetResponse.data.listDxContracts.nextToken);

            res = res.concat(nextResults);
        }
        console.log(res);

        if (res.length > 0) {
            // setlistFormData(res[0]);
            setcontract(res);
        }
        return res;
    };
    /**
     *getDropdownOptions() is used to fetch all the vehicle id, vendor name and display inside the dropdown
     *getVehicleactivityoptions() fetch the vehicleid alone from vehicleactivity table
     *getvendorCompanyoptions() fetch the vendor type as Company from vendor table
     *getvendorGarageoptions() fetch the vendor type as Garage from the vendor table to display in appointment form dropdown
     */
    const getDropdownOptions = async () => {
        setVehicles([]);
        //getting the vehicle id details to show on the Vehicle dropdown
        let VActivity: any = await getVehicleactivityoptions();
        setVehicles(VActivity);
        //getting the company  vendor details  to show on the place dropdown
        let Companyvendordata = await getvendorCompanyoptions();

        setGarageCompanyOptions(Companyvendordata);
        //getting the garage vendor details to show on the place dropdown
        let Garagevendordata = await getvendorGarageoptions();

        setGarageVendorOptions(Garagevendordata);
    };
    /**
     *while renewing the Service Contract 'getScExpiringData()' this function will list all the 
     vehicleid whose Service Contract also going to expire with a warning msg. 
     */
    const getScExpiringData = async () => {
        StoreScVid = [];
        var now = new Date();
        var nextWeek = new Date(now);
        nextWeek.setDate(nextWeek.getDate() + 15);
        let filterva = {};
        await getNextQueryData('', filterva, 'vehicleactivity', true).then((items: any) => {
            items.filter((res: any) => {
                if (res.expiring !== null) {
                    let parsesc = JSON.parse(res.expiring).ServiceContract && JSON.parse(res.expiring).ServiceContract;
                    if (parsesc !== undefined && parsesc.expiryDate !== undefined && parsesc !== false) {
                        if ((moment(parsesc.expiryDate).format() >= moment().format() && moment(parsesc.expiryDate).format() <= moment(nextWeek).format()) || parsesc.status != 'Completed') {
                            if (VehicleID !== res.id) {
                                StoreScVid.push(res.id);
                            }

                            StoreScVid.length > 0 &&
                                formmode !== 'new' &&
                                expType == 'ServiceContract' &&
                                msgs.current.show([{ sticky: true, severity: 'warn', summary: StoreScVid.toString() + ' ', detail: ' ' + 'is going to expire soon', closable: true }]);
                            setExpiringSC(res);
                        }
                    }
                }
            });
        });
    };
    /**
     * getRenewalType() will list all the Renewal type (i.e.Insurence,Registration,Warenty) from a generalconfig
     * table to display inside the renewal type dropdown
     */
    const getRenewalType = () => {
        getGenralconfigs('Renewal Type').then((res) => {
            setRenewaltypeoptions(res);
        });
    };
    //sparecolumns is a sub form for creating appointment
    let sparecolumns = {
        columns: [
            {
                title: 'Add Appointment',
                name: 'basicinfo',
                fieldtype: 'header'
            },
            {
                name: 'place',
                title: 'Place',
                value: '',
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: GarageVendorOptions,
                class: 'md:col-4'
            },
            {
                name: 'startdate',
                title: 'Date  & Time',
                value: '',
                fieldtype: 'datetime',
                required: true,
                disabled: false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'appointmentstatus', // pass the exact column name of db
                title: 'Appointment Status',
                value: '',
                class: 'md:col-4',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: true,
                data: [
                    { label: 'Scheduled', value: 'Scheduled' },
                    { label: 'Completed', value: 'Completed' }
                ]
            },
            {
                name: 'description', // pass the exact column name of db
                title: 'Description',
                value: '', //for display
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                class: 'md:col-4',
                data: []
            }
        ]
    };
    //formColumns is a form template for creating the renewal
    const formColumns = {
        columns: [
            {
                name: 'renewaltype', // pass the exact column name of db
                value: '',
                title: 'Renewal Type',
                fieldtype: 'dropdown',
                required: true,
                disabled: VehicleID !== null && VehicleID != '' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: renewaltypeoption
            },
            {
                name: 'id', // pass the exact column name of db
                value: updatedata.renewaltype == 'ServiceContract' || expType == 'ServiceContract' ? [] : '',
                title: 'Vehicle',
                fieldtype: updatedata.renewaltype == 'ServiceContract' || expType == 'ServiceContract' ? 'multiselect' : 'dropdown',
                required: true,
                disabled: VehicleID !== null && VehicleID !== '' && expType == 'ServiceContract' ? false : VehicleID == null ? false : true,
                hidden: appointmentNeeded ? true : false,
                data: vehicles
            },
            {
                name: 'renewalstatus', // pass the exact column name of db
                value: '',
                title: 'Renewal Status',
                fieldtype: 'dropdown',
                required: true,
                disabled: true,
                hidden: true,
                data: [
                    { label: 'In Progress', value: 'In Progress' },
                    { label: 'Completed', value: 'Completed' }
                ]
            },
            { name: 'break2', title: '', fieldtype: 'break', class: 'md:col-12' },

            {
                name: 'isappointmentrequire', // pass the exact column name of db
                value: '', //for display
                title: 'Is appointment needed?',
                fieldtype: 'switch',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: VehicleID !== null && expType == 'ServiceContract' ? true : VehicleID !== null && expType == 'Parking' ? true : VehicleID !== null && expType == 'Warranty' ? true : false,
                conditionshow: { name: 'renewaltype', value: 'Insurance,Registration,Branding,', type: String },
                data: []
            },

            { name: 'break2', title: '', fieldtype: 'break', class: 'md:col-12' },

            {
                name: 'form1',
                title: 'Schedule Appointment',
                fieldtype: 'form',
                required: false,
                value: '',
                disabled: false,
                class: 'md:col-12',
                columns: sparecolumns,
                updatedata: [],
                mode: 'new',
                showaction: false,
                rows: 1,
                conditionshow: { name: 'isappointmentrequire', value: true, type: Boolean }
            },

            {
                name: 'renewalno', // pass the exact column name of db
                value: '',
                title:
                    VehicleID !== null && expType == 'ServiceContract'
                        ? 'Contract No'
                        : VehicleID !== null && expType == 'Parking'
                        ? 'Seasonal Parking ID'
                        : VehicleID !== null && expType == 'Warranty'
                        ? 'Warranty No'
                        : updatedata.renewaltype == 'ServiceContract'
                        ? 'Contract No'
                        : updatedata.renewaltype == 'Warranty'
                        ? 'Warranty No'
                        : updatedata.renewaltype == 'Parking'
                        ? 'Seasonal Parking ID'
                        : 'Certificate No',
                fieldtype: 'text',
                required: updatedata.isappointmentrequire == true ? false : false,
                disabled: appointmentNeeded ? true : false,
                hidden: false,
                data: [],
                conditionshow: { name: 'isappointmentrequire', value: false, type: Boolean }
            },
            {
                name: 'permittype', // pass the exact column name of db
                value: '',
                title: 'Permit Type',
                fieldtype: 'text',
                disabled: Type !== null && Type == 'ServiceContract' ? true : false,
                hidden: VehicleID !== null && expType == 'ServiceContract' ? true : false,
                data: [],
                conditionshow: { name: 'isappointmentrequire', value: false, type: Boolean }
            },
            {
                name: 'scvendor', // pass the exact column name of db
                value: '', //for display
                title: 'Company',
                fieldtype: 'dropdown',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: false,
                data: CompanyVendorOptions,
                conditionshow: { name: 'isappointmentrequire', value: false, type: Boolean }
            },
            {
                name: 'period', // pass the exact column name of db
                value: '', //for display
                title: 'Period',
                fieldtype: 'number',
                // required: updatedata.renewaltype == 'ServiceContract' || expType == 'ServiceContract' ? true : false,
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: true,
                data: [],
                conditionshow: { name: 'renewaltype', value: 'ServiceContract' }
            },
            {
                name: 'credits', // pass the exact column name of db
                value: '', //for display
                title: 'No of Credits',
                fieldtype: 'number',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: false,
                data: [],
                conditionshow: { name: 'renewaltype', value: 'ServiceContract' }
            },
            {
                name: 'issuedate', // pass the exact column name of db
                value: '', //for display
                title: updatedata.renewaltype == 'Registration' ? 'Registration date' : 'Issue date',
                fieldtype: 'newdate',
                required: updatedata.isappointmentrequire == true || (VehicleID !== null && expType == 'ServiceContract') || updatedata.renewaltype == 'ServiceContract' ? false : true,
                disabled: appointmentNeeded ? true : false,
                hidden: false,
                data: [],
                conditionshow: { name: 'isappointmentrequire', value: false }
            },
            {
                name: 'expirydate', // pass the exact column name of db
                value: '',
                title: 'Expiry date',
                fieldtype: 'newdate',
                required: updatedata.isappointmentrequire == true || (VehicleID !== null && expType == 'ServiceContract') || updatedata.renewaltype == 'ServiceContract' ? false : true,
                disabled: appointmentNeeded ? true : false,
                hidden: false,
                data: [],
                conditionshow: { name: 'isappointmentrequire', value: false, type: Boolean }
            },
            {
                name: 'totalamount', // pass the exact column name of db
                value: '',
                title: 'Amount(AED)',
                fieldtype: 'text',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: false,
                data: [],
                conditionshow: { name: 'isappointmentrequire', value: false, type: Boolean }
            },
            { name: 'break2', title: '', fieldtype: 'break', class: 'md:col-12' },
            {
                name: 'documents', // pass the exact column name of db
                value: '',
                title: 'Upload documents',
                fieldtype: 'fileupload',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: false,
                class: 'md:col-4',
                data: [],
                conditionshow: { name: 'isappointmentrequire', value: false }
            },
            {
                name: 'picture', // pass the exact column name of db
                value: '',
                title: 'Upload Pictures',
                fieldtype: 'imageupload',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: false,
                class: 'md:col-4',
                data: [],
                conditionshow: { name: 'isappointmentrequire', value: false }
            },
            {
                name: 'receipts', // pass the exact column name of db
                value: '',
                title: 'Upload receipts',
                fieldtype: 'fileupload',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: false,
                class: 'md:col-4',
                data: [],
                conditionshow: { name: 'isappointmentrequire', value: false, type: Boolean }
            }
        ],
        actions: [
            {
                title: 'Submit'
            },
            {
                title: 'Cancel'
            }
        ]
    };
    //changevehicledata function is to get the updated date in a onchange
    const changevehicledata = (props: any) => {
        let ScObj: any;

        if (props.id !== null && props.renewaltype == 'ServiceContract') {
            if (Array.isArray(props.id)) {
                setupdatedata(props);
            } else {
                CollectScVid = [];
                CollectScVid.push(props.id);

                if (CollectScVid.length == 1) {
                    ScObj = props;
                    ScObj['id'] = CollectScVid;
                    setupdatedata(ScObj);
                } else if (CollectScVid.length > 1) {
                    // CollectScVid = [];
                    ScObj = props;

                    // ScObj['id'] = [];

                    setupdatedata(ScObj);
                }

                // setupdatedata(ScObj);
                // setupdatedata(props);
            }
        } else {
            setupdatedata(props);
        }
        if (formmode == 'new' && props.renewaltype == 'ServiceContract') {
            if (Object.keys(props).length !== 0 && props.renewaltype !== null && props.renewaltype !== '') {
                // const currentUrl = window.location.href;
                // const newUrl = `${currentUrl}&type=${props.renewaltype}`;
                // window.history.pushState(null, '', newUrl);
                props.renewaltype !== '' && navigate('/manage/documents/item/basic?mode=new&type=' + props.renewaltype);
            }
        }
        if (props.id) {
            vehicleId = props.id;
        }
        if (props.renewaltype) {
            renewaltype = props.renewaltype;
        }
    };
    //callchangedata runs on click of a submit button
    const callchangedata = (data: any) => {
        if (updatedata.renewaltype == 'ServiceContract') {
            ScRenewal(updatedata);
        } else {
            Renewal(updatedata);
        }
    };
    /**
     * addDocToDB() creates a Image S3 url and image related data in document table
     */
    const addDocToDB = async (files: any) => {
        let createDocument = await dxService.createitem('document', files, false);
    };
    /**
     * ScRenewal() function renews the Service contract renewal type
     */

    const ScRenewal = async (data: any) => {
        storeVehicleActivityData = [];
        storeVehicleData = [];
        UploadDocuments = [];
        setdisplayDialog(true);
        /** 
         *  first check if the sc has any documents,pictures,receipts, if exist
     then store the necessary details of the documents,pictures,receipts in an array.
         */
        let doumentObj: any = {};
        let vid = data.id.toString().includes(',') ? data.id.toString().replace(',', ';') : data.id.toString();

        if (data.documents && data.documents.length > 0) {
            for (let i = 0; i < data.documents.length; i++) {
                //getting the document upload names and creating the own object
                doumentObj = new Object({ file: data.documents[i], type: 'documents', path: `${data.renewaltype}/${new Date().getFullYear()}/${vid}/documents/` });

                //pushing an object in an UploadDocuments array
                UploadDocuments.push(doumentObj);
            }
        }
        if (data.picture && data.picture.length > 0) {
            for (let i = 0; i < data.picture.length; i++) {
                //getting the document upload names and creating the own object
                doumentObj = new Object({ file: data.picture[i], type: 'picture', path: `${data.renewaltype}/${new Date().getFullYear()}/${vid}/picture/` });
                //pushing an object in an UploadDocuments array
                UploadDocuments.push(doumentObj);
            }
        }

        if (data.receipts && data.receipts.length > 0) {
            for (let i = 0; i < data.receipts.length; i++) {
                //getting the document upload names and creating the own object
                doumentObj = new Object({ file: data.receipts[i], type: 'receipts', path: `${data.renewaltype}/${new Date().getFullYear()}/${vid}/receipts/` });

                //pushing an object in an UploadDocuments array
                UploadDocuments.push(doumentObj);
            }
        }
        /***  If any previous sc exist then update that contract status as completed */
        if (contract.length > 0) {
            let updatepreviouscontract = {
                vehicleid: contract[0].vehicleid,
                id: contract[0].id,
                renewalstatus: 'Completed',
                _version: contract[0]._version
            };
            let UpdateprevContractitem: any = await dxService.updateitem('renewal', updatepreviouscontract);
        }
        /***  Create a new contract with all necessary fields */
        //creating the new contract
        let b: any = {
            expirydate: localetoisodatetime(data.expirydate),
            issuedate: localetoisodatetime(data.issuedate),
            renewalno: data.renewalno,
            renewalstatus: data.isappointmentrequire ? data.isappointmentrequire != true : 'Active',
            renewaltype: data.renewaltype,
            vehicleid: data.id.toString(),
            jfield: JSON.stringify({ credits: data.credits }),
            period: data.period,
            scvendor: data.scvendor,
            subtype: data.permittype
        };

        Object.keys(b).forEach((key) => {
            if (b[key] == null || b[key] == '') {
                delete b[key];
            }
        });

        let createcontract = await dxService.createitem('renewal', b, false).then(async (b: any) => {
            await Promise.all(
                /** * In the success of the creating new contract upload all the documents in a S3 bucketand 
     also create a details of the documents in a Document table */
                UploadDocuments.map(async (item: any) => {
                    await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                        item['fullpath'] = res.key;
                        const files = {
                            name: res.key,
                            // dxAllocationsPicturesId: id,
                            s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                            resource: 'Renewals',
                            resourceid: b.id,
                            presourceid: vid,
                            presource: 'Vehicle',
                            field1: res.key.includes(`${vid}/picture`) ? 'pictures' : res.key.includes(`${vid}/documents`) ? 'documents' : res.key.includes(`${vid}/receipts`) ? 'receipts' : ''
                        };
                        addDocToDB(files);
                    });
                })
            ).then(async (res1: any) => {
                let Files: any = [];
                let docs: any = '';
                let picture: any = [];
                let pics: any = '';
                let receipts: any = [];
                let rec: any = '';
                UploadDocuments.map((map: any) => {
                    docs = map.type == 'documents' ? Files.push(map.fullpath) : '';
                    pics = map.type == 'picture' ? picture.push(map.fullpath) : '';
                    rec = map.type == 'receipts' ? receipts.push(map.fullpath) : '';
                });
                /** Vehicleid will come in a form of array (i.e. [D54343]) so loop inside the vehicle
                 array and filter the vehicle from the vehicle table to update the scno and scvendor 
                 */
                const storemultiarray: any = data.id;

                for (let i = 0; i < storemultiarray.length; i++) {
                    let filtervehicleactivity = {
                        id: {
                            eq: storemultiarray[i]
                        }
                    };

                    await getNextQueryData('', filtervehicleactivity, 'vehicle', true).then((vres: any) => {
                        if (vres.length > 0) {
                            let vehicledata: any = vres[0];

                            storeVehicleData.push(vehicledata);
                        }
                    });
                }
                for (let i = 0; i < storeVehicleData.length; i++) {
                    let e: any = storeVehicleData[i];
                    let updatevehicle = {
                        scno: data.renewalno,
                        scvendor: data.scvendor,
                        _version: e._version,
                        id: e.id
                    };

                    let uv: any = await dxService.updateitem('vehicle', updatevehicle);
                }
                /** again loop inside the vehicle array and filter the vehicle from the vehicleactivity
                  table to update the expiring and dxContractActivityId 
                 */
                for (let i = 0; i < storemultiarray.length; i++) {
                    let filtervehicleactivity = {
                        id: {
                            eq: storemultiarray[i]
                        }
                    };
                    await getNextQueryData('', filtervehicleactivity, 'vehicleactivity', true).then((vres) => {
                        if (vres.length > 0) {
                            let vehicleactivitydata = vres[0];

                            storeVehicleActivityData.push(vehicleactivitydata);
                        }
                    });
                }
                for (let i = 0; i < storeVehicleActivityData.length; i++) {
                    let e: any = storeVehicleActivityData[i];
                    let expiringvalue = e && e['expiring'] ? JSON.parse(e['expiring']) : {};
                    let rt: any = {};
                    rt[b['renewaltype']] = {
                        issueDate: localetoisodatetime(b['issuedate']),
                        expiryDate: localetoisodatetime(b['expirydate']),
                        // documents: b['documents'] + ',' + b['pictures'] + ',' + b['documents2'],
                        status: data.isappointmentrequire ? data.isappointmentrequire != true : 'Completed',
                        id: b['id']
                    };

                    e['dxContractActivityId'] = b.id;
                    e['expiring'] = expiringvalue;
                    e['expiring'][b['renewaltype']] = '';
                    e['expiring'][b['renewaltype']] = rt[b['renewaltype']];
                    e['expiring'] = JSON.stringify(e['expiring']);
                    if (Object.keys(e).includes('_lastChangedAt')) delete e['_lastChangedAt'];
                    if (Object.keys(e).includes('createdAt')) delete e['createdAt'];
                    if (Object.keys(e).includes('updatedAt')) delete e['updatedAt'];
                    if (Object.keys(e).includes('owner')) delete e['owner'];

                    Object.keys(e).forEach((key) => {
                        if (e[key] == null || e[key] == '') {
                            delete e[key];
                        }
                    });

                    let Updatevehicleactivity = {
                        dxContractActivityId: e.dxContractActivityId,
                        expiring: e.expiring,
                        _version: e._version,
                        id: e.id
                    };
                    let uva: any = await dxService.updateitem('vehicleactivity', Updatevehicleactivity);
                }
                UploadDocuments = [];
                /**
  Refresh the changes in the workspace
 */
                let filterva = {};
                await getNextQueryData('', filterva, 'vehicleactivity', true).then((v) => {
                    setTimeout(() => {
                        location.pathname.includes('workspace') ? navigate('/general/workspace') : navigate('/general/document');
                        // window.history.back();
                    }, 1000);
                });
            });
        });
        vehicleId = '';
        renewaltype = '';
    };
    /**
     * Renewal() function renews the all renewal type (i.e.Registration,Insurance,Branding,Parking) except Service contract
     */

    //addnewcontract is to create a new appointment if needed or to create a new contract
    const Renewal = async (data: any) => {
        UploadDocuments = [];

        setdisplayDialog(true);
        if (data.isappointmentrequire == true) {
            //checking if appointment required

            if (data.form1) {
                //create a new contract
                let contractdetails = {
                    vehicleid: data.id,
                    renewaltype: data.renewaltype,
                    renewalstatus: 'In Progress'
                };
                let renewal = await dxService.createitem('renewal', contractdetails, false).then(async (contract: any) => {
                    //create a new appointment
                    let app: any = {
                        Date: localetoisodatetime(data.form1[0].startdate),
                        // appointmentstatus: data.form1[0].appointmentstatus,
                        description: data.form1[0].description,
                        // eventtype: data.renewaltype,
                        eventtype: 'Renewals',
                        appointmentstatus: 'Scheduled',
                        place: data.form1[0].place,
                        vehicleid: data.id,
                        dxContractAppointmentsId: contract.id
                    };
                    let appointment = await dxService.createitem('appointment', app, false).then(async (appid: any) => {
                        //get the vehicle details from listdxvehicleactivity using the id
                        // AmplifyServices.getvehicleitem(data.id).then(async (resp: any) => {
                        let filterva = {
                            id: {
                                eq: data.id
                            }
                        };
                        await getNextQueryData('', filterva, 'vehicleactivity', true).then(async (resp: any) => {
                            vehicleactivityitems = resp[0];
                            let e: any = vehicleactivityitems;

                            //update the vehicle activity table
                            let expiringvalue = e && e['expiring'] ? JSON.parse(e['expiring']) : {};
                            let rt: any = {};
                            if (expiringvalue !== undefined) {
                                rt[data['renewaltype']] = {
                                    issueDate: expiringvalue[data['renewaltype']] ? localetoisodatetime(expiringvalue[data['renewaltype']]['issueDate']) : '',
                                    expiryDate: expiringvalue[data['renewaltype']] ? localetoisodatetime(expiringvalue[data['renewaltype']]['expiryDate']) : '',
                                    documents: expiringvalue[data['renewaltype']] ? expiringvalue[data['renewaltype']]['documents'] : '',
                                    id: contract.id,
                                    status: 'In Progress',
                                    appid: appid.id,
                                    isappointmentneeded: data.isappointmentrequire
                                };
                                e['expiring'] = expiringvalue;
                                e['expiring'][data['renewaltype']] = '';
                                e['expiring'][data['renewaltype']] = rt[data['renewaltype']];
                                e['expiring'] = JSON.stringify(e['expiring']);
                                rt['_version'] = e._version;
                                rt['id'] = e.id;
                            } else {
                                rt[data['renewaltype']] = {
                                    status: 'In Progress',
                                    appid: appid.id,
                                    isappointmentneeded: data.isappointmentrequire
                                };
                                e['expiring'] = expiringvalue;
                                e['expiring'][data['renewaltype']] = '';
                                e['expiring'][data['renewaltype']] = rt[data['renewaltype']];
                                e['expiring'] = JSON.stringify(e['expiring']);
                                rt['_version'] = e._version;
                                rt['id'] = e.id;
                            }

                            if (Object.keys(e).includes('_lastChangedAt')) delete e['_lastChangedAt'];
                            if (Object.keys(e).includes('createdAt')) delete e['createdAt'];
                            if (Object.keys(e).includes('updatedAt')) delete e['updatedAt'];
                            if (Object.keys(e).includes('owner')) delete e['owner'];
                            Object.keys(e).forEach((key) => {
                                if (e[key] == null || e[key] == '') {
                                    delete e[key];
                                }
                            });
                            let updatevehicleactivity = { expiring: e.expiring, id: e.id, _version: e._version };

                            let os1: any = await API.graphql({
                                query: updateDxVehicleActivity,
                                authMode: 'AMAZON_COGNITO_USER_POOLS',
                                variables: {
                                    input: updatevehicleactivity
                                }
                            });
                        });

                        setTimeout(() => {
                            window.history.back();
                            // navigate('/general/workspace');
                        });
                    });
                });
            }
        } else {
            //if there is no appointment needed execute the below code
            let documents: any = [];
            /** 
         * check if the form has any documents,pictures,receipts, if exist
     then store the necessary details of the documents,pictures,receipts in an array.
         */
            let doumentObj: any = {};
            if (data.documents && data.documents.length > 0) {
                for (let i = 0; i < data.documents.length; i++) {
                    //getting the document upload names and creating the own object
                    doumentObj = new Object({ file: data.documents[i], type: 'documents', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.id}/documents/` });

                    //pushing an object in an UploadDocuments array
                    UploadDocuments.push(doumentObj);
                }
            }
            if (data.picture && data.picture.length > 0) {
                for (let i = 0; i < data.picture.length; i++) {
                    //getting the document upload names and creating the own object
                    doumentObj = new Object({ file: data.picture[i], type: 'picture', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.id}/picture/` });

                    //pushing an object in an UploadDocuments array
                    UploadDocuments.push(doumentObj);
                }
            }

            if (data.receipts && data.receipts.length > 0) {
                for (let i = 0; i < data.receipts.length; i++) {
                    //getting the document upload names and creating the own object
                    doumentObj = new Object({ file: data.receipts[i], type: 'receipts', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.id}/receipts/` });

                    //pushing an object in an UploadDocuments array
                    UploadDocuments.push(doumentObj);
                }
            }
            /***  If any previous sc exist then update that contract status as completed */
            if (contract.length > 0) {
                let updatepreviouscontract = {
                    vehicleid: contract[0].vehicleid,
                    id: contract[0].id,
                    renewalstatus: 'Completed',
                    _version: contract[0]._version
                };
                let UpdateprevContractitem: any = await dxService.updateitem('renewal', updatepreviouscontract);
            }
            /***  Create a new contract with all necessary fields */
            let b: any = {
                expirydate: localetoisodatetime(data.expirydate),
                issuedate: localetoisodatetime(data.issuedate),
                renewalno: data.renewalno,
                renewalstatus: data.isappointmentrequire ? data.isappointmentrequire != true : 'Active',
                renewaltype: data.renewaltype,
                vehicleid: data.id,
                scvendor: data.scvendor,
                subtype: data.permittype,
                totalamount: data.totalamount
                //   documents: Files.toString() + ',' + picture.toString() + ',' + receipts.toString(),
                //   pictures: picture.toString(),
                //   documents2: receipts.toString()
            };

            //   b['id'] = contractid;

            let renewal = await dxService.createitem('renewal', b, false).then(async (b: any) => {
                await Promise.all(
                    /** * In the success of the creating new contract upload all the documents in a S3 bucketand 
     also create a details of the documents in a Document table */
                    UploadDocuments.map(async (item: any) => {
                        await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                            item['fullpath'] = res.key;
                            const files = {
                                name: res.key,
                                // dxAllocationsPicturesId: id,
                                s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                                resource: 'Renewals',
                                resourceid: b.id,
                                presourceid: data.id,
                                presource: 'Vehicle',
                                field1: res.key.includes(`${data.id}/picture`) ? 'pictures' : res.key.includes(`${data.id}/documents`) ? 'documents' : res.key.includes(`${data.id}/receipts`) ? 'receipts' : ''
                            };
                            addDocToDB(files);
                        });
                    })
                ).then(async (res1: any) => {
                    let os: any = {};
                    let Files: any = [];
                    let docs: any = '';
                    let picture: any = [];
                    let pics: any = '';
                    let receipts: any = [];
                    let rec: any = '';
                    UploadDocuments.map((map: any) => {
                        docs = map.type == 'documents' ? Files.push(map.fullpath) : '';
                        pics = map.type == 'picture' ? picture.push(map.fullpath) : '';
                        rec = map.type == 'receipts' ? receipts.push(map.fullpath) : '';
                    });

                    /**  filter the vehicle from the vehicle
                  table to update the data
                 */
                    let filterv = {
                        id: {
                            eq: data.id
                        }
                    };
                    await getNextQueryData('', filterv, 'vehicle', true).then(async (veh) => {
                        vehicleitems = veh[0];

                        if (data.renewaltype !== null && data.renewaltype == 'Registration') {
                            let Updatevehicle = { id: data.id, registrationno: data.renewalno, startdate: localetoisodatetime(data.issuedate), enddate: localetoisodatetime(data.expirydate), _version: vehicleitems._version };
                            //updateDxVehicle
                            os = await dxService.updateitem('vehicle', Updatevehicle);
                        } else if (data.renewaltype !== null && data.renewaltype == 'Insurance') {
                            let Updatevehicle = { id: data.id, insuranceno: data.renewalno, _version: vehicleitems._version };
                            //updateDxVehicle
                            os = await dxService.updateitem('vehicle', Updatevehicle);
                        }
                    });
                    /** filter the vehicle from the vehicleactivity
                  table to update the expiring and dxContractActivityId 
                 */
                    let filterva = {
                        id: {
                            eq: data.id
                        }
                    };
                    await getNextQueryData('', filterva, 'vehicleactivity', true).then(async (resp: any) => {
                        vehicleactivityitems = resp[0];

                        let e: any = vehicleactivityitems;
                        //updating the vehicle activity details
                        let expiringvalue = e && e['expiring'] ? JSON.parse(e['expiring']) : {};
                        let rt: any = {};
                        rt[b['renewaltype']] = {
                            issueDate: localetoisodatetime(b['issuedate']),
                            expiryDate: localetoisodatetime(b['expirydate']),
                            documents: b['documents'] + ',' + b['pictures'] + ',' + b['documents2'],
                            status: data.isappointmentrequire ? data.isappointmentrequire != true : 'Completed',
                            id: b['id']
                        };

                        e['dxContractActivityId'] = b.id;
                        e['expiring'] = expiringvalue;
                        e['expiring'][b['renewaltype']] = '';
                        e['expiring'][b['renewaltype']] = rt[b['renewaltype']];
                        e['expiring'] = JSON.stringify(e['expiring']);
                        let Updatevehicleactivity = {
                            dxContractActivityId: e.dxContractActivityId,
                            expiring: e.expiring,
                            _version: e._version,
                            id: e.id
                        };
                        await dxService.updateitem('vehicleactivity', Updatevehicleactivity);
                    });
                    UploadDocuments = [];

                    setTimeout(() => {
                        window.history.back();
                    }, 1000);
                });
            });
        }
        vehicleId = '';
        renewaltype = '';
    };

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-12">
                    <h4 className="container-header">{formmode === 'new' ? `New Renewal:` : VehicleID !== null ? 'New Renewal' : ''}</h4>
                    <div className="card container-card Renewal">
                        <Messages ref={msgs} />
                        <RenewFormTemplate
                            columns={formColumns}
                            // Fleettype={formColumns.fleettype}
                            layout="grid2"
                            getTableName="listDxVehicleActivities"
                            // title={`New Contract ${vehicleID !== '' ? ' - ' : ''}`}
                            data={listFormData}
                            // submitForm={submitForm}
                            onChange={changevehicledata}
                            isAppointmentNeeded={setAppointmentNeeded}
                            bucketname="RenewalDocuments"
                            formName="serviceContract"
                            showaction={true}
                            showtopbutton={false}
                            submitForm={callchangedata}
                            Templatename={'RenewTemplate'}
                        />
                        <Loader displayDialog={displayDialog} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default RenewalForm;
