import { API } from 'aws-amplify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AmplifyServices from '../../../Service/Service';
import { createDxLogSalik, createDxMasterLogs, updateDxMasterLogs } from '../../../graphql/mutations';
import { listDxAllocations, listDxLogSaliks, listDxVehicles } from '../../../graphql/queries';
import { GraphQLSubscription } from '@aws-amplify/api';
import FormTemplate from '../../../Components/FormTemplate';
import { OnCreateDxLogSalikSubscription } from '../../../API';
import { onCreateDxLogSalik } from '../../../graphql/subscriptions';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import moment from 'moment';
import dxService from '../../../Services/dxService';
import { getNextQueryData, localetoisodateendtime, localetoisodatetime } from '../../../utils/helper';
let driversNameOptions: any = [];
const SalikForm = () => {
    const toast = useRef<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [vehicleIDOptions, setVehicleIDOptions] = useState<any>([]);
    const [vehicleID, setVehicleID] = useState<any>('');
    const [salikDate, setSalikDate] = useState<any>('');
    const [driverName, setDriverName] = useState<any>('');
    const [driverID, setDriverID] = useState<any>('');
    const [allocationID, setallocationID] = useState<any>('');
    const [listFormData, setlistFormData] = useState<any>({});
    const [errorMsg, seterrorMsg] = useState<any>(null);
    const location1 = useLocation();
    const urlParams1 = new URLSearchParams(location1.search);
    var formmode: any = urlParams1.has('mode') ? urlParams1.get('mode') : null;
    var formid: any = urlParams1.has('id') ? urlParams1.get('id') : null;
    var driver: any = urlParams1.has('driver') ? urlParams1.get('driver') : null;
    var type: any = urlParams1.has('type') ? urlParams1.get('type') : null;
    const navigate = useNavigate();

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
        formid = urlParams1.has('id') ? urlParams1.get('id') : null;
        const paths = location1.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'allocations':
                setActiveIndex(2);
                break;

            default:
                if (formid) getvendordata(formid);
                break;
        }
    }, [location1]);

    useEffect(() => {
        if (formid !== null) {
            // fetchUserIdUserName().then((driver: any) => {

            setlistFormData({
                vehicleid: formid
            });
            // });
        }
    }, []);
    const fetchUserIdUserName = async (nextToken?: any) => {
        let variables: any = {
            id: {
                eq: formid
            }
        };
        let Finedata = await dxService.listitems('vehicleactivity', '*', variables, true, 1000, nextToken);
        let response = Finedata.items;

        let nexttoken = Finedata.nextToken;
        if (nexttoken) {
            response = [...response, ...(await fetchUserIdUserName(nexttoken))];

            // response = response.concat(nextResults);
        }

        return response;
    };
    useEffect(() => {
        checkActiveIndex();
        getVehicleID();
        getdriverID();
    }, [checkActiveIndex]);
    const getvendordata = async (fid: any) => {
        await AmplifyServices.getFilterbyIddetails(listDxLogSaliks, 'listDxLogSaliks', fid).then((a) => {
            if (a.length > 0) {
                setlistFormData(a[0]);
            }
        });
    };

    const getVehicleID = async () => {
        let GetResponse: any = await API.graphql({ query: listDxVehicles, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        setVehicleIDOptions(GetResponse.data.listDxVehicles.items);
        // getDriverDetailsByDateandVID();
    };
    const getdriverID = async () => {
        driversNameOptions = [];
        let drivers = await dxService.listitems('driver');
        drivers.map((res: any) => {
            res['label'] = res.name;
            res['value'] = res.id;
            driversNameOptions.push(res);
        });
    };
    useEffect(() => {
        salikDate !== null && vehicleID !== '' && getDriverDetailsByDateandVID();
    }, [salikDate, vehicleID]);

    //get driver id and driver name based on vehicle and date selection
    const getDriverDetailsByDateandVID = async () => {
        let filter = {
            vehicleid: {
                eq: vehicleID
            },

            fromdate: {
                le: salikDate ? localetoisodatetime(salikDate) : null
            },

            or: [
                {
                    handoverdate: {
                        ge: salikDate ? localetoisodateendtime(salikDate) : null
                    }
                },

                {
                    handoverdate: {
                        ne: ''
                    }
                }
            ]
        };

        dxService.listitems('allocation', '*', filter, true, 100000).then((res) => {
            res.items = res.items.sort((a: { fromdate: any }, b: { fromdate: string | number | Date }) => {
                const dateA = new Date(a.fromdate);

                const dateB = new Date(b.fromdate);

                return dateB.getTime() - dateA.getTime();
            });

            if (res.items.length > 0) {
                const filteredItems = res.items.filter((item: { handoverdate: string | number | Date; fromdate: string | number | Date }) => {
                    if (item.handoverdate) {
                        const fromDate = new Date(item.fromdate).toDateString();

                        const handoverDate = new Date(item.handoverdate).toDateString();

                        const selectedDateObj = new Date(salikDate).toDateString();

                        return selectedDateObj === fromDate && selectedDateObj === handoverDate;
                    } else {
                        return false;
                    }
                }); // Filtered items are available

                const selectedItem = filteredItems.length > 0 ? filteredItems[0] : res.items[0];

                setlistFormData({
                    ...listFormData,

                    drivername: selectedItem.driver.id,

                    date: salikDate
                });
                setallocationID(selectedItem.id);
                setDriverName(selectedItem.fromdrivername !== null ? selectedItem.fromdrivername : selectedItem.handoverdrivername !== null ? selectedItem.handoverdrivername : '');

                setDriverID(selectedItem.dxDriverAllocationsId !== null ? selectedItem.dxDriverAllocationsId : selectedItem.driverID !== null ? selectedItem.driverID : '');
            } else {
            }
        });
    };
    const VehicleOptions: any = [];
    const names = vehicleIDOptions
        .filter((del: any) => del._deleted !== true)
        .map((res: any) => {
            res['label'] = res.id;
            res['value'] = res.id;
            VehicleOptions.push(res);
        });
    useEffect(() => {
        let cols = formColumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });
        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);
    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },

            {
                name: 'vehicleid', // pass the exact column name of db
                title: 'Vehicle ID',
                fieldtype: 'dropdown',
                value: '',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: VehicleOptions,
                class: 'md:col-6'
            },
            {
                name: 'date', // pass the exact column name of db
                title: 'Trip Date',
                fieldtype: 'date',
                value: '',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'driverid', // pass the exact column name of db
                title: 'Driver ID',
                fieldtype: 'text',
                value: driverID,
                required: false,
                disabled: true,
                hidden: true,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'drivername', // pass the exact column name of db
                title: 'Driver Name',
                fieldtype: 'dropdown',
                value: driverName,
                required: true,
                disabled: false,
                hidden: false,
                data: driversNameOptions,
                class: 'md:col-6'
            },
            {
                name: 'transactiondate', // pass the exact column name of db
                title: 'Transaction Date',
                fieldtype: 'date',
                value: '',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'transactionid', // pass the exact column name of db
                title: 'Transaction ID', //for display
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },

            {
                name: 'amount', // pass the exact column name of db
                title: 'Amount', //for display
                value: '',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },

            {
                name: 'tollgate', // pass the exact column name of db
                title: 'Toll Gate',
                value: '',
                fieldtype: 'text',
                required: type == 'cash' ? false : true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'direction', // pass the exact column name of db
                title: 'Direction',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'tagnumber', // pass the exact column name of db
                title: 'Tag Number',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            }
        ],
        actions: [
            {
                title: 'Submit',
                disabled: errorMsg !== null ? true : false
            },
            {
                title: 'Cancel'
            }
        ]
    };
    const getReimbursemasterlogdata = async (props?: any, nextToken?: any) => {
        // let variables: any = {};
        let convertdate: any = localetoisodatetime(props.date);
        let splitdate: any = convertdate.split('T')[0];
        let variables: any = {
            // and: [{ date: { ge: localetoisodatetime(lastTwoWeekStartDate) } },

            //      { date: { le: moment(localetoisodatetime(lasttwoWeekEndDate.toISOString())).format('YYYY-MM-DD') + 'T23:59:59.999Z' } }
            //     ]
            vehicleid: { eq: props.vehicleid },
            date: { contains: splitdate },
            dxDriverMasterlogsId: { eq: props.driverid },
            reimbursement: { eq: 'Yes' }
        };
        let get14daysExp = await dxService.listitems('masterlog', '*', variables, true, 6000, nextToken);
        let response = get14daysExp.items;

        let nexttoken = get14daysExp.nextToken;
        if (nexttoken) {
            response = [...response, ...(await getReimbursemasterlogdata(props, nexttoken))];
        }
        return response;
    };
    const getmasterlogdata = async (props?: any, nextToken?: any) => {
        // let variables: any = {};
        console.log(props);

        let convertdate: any = localetoisodatetime(props.date);
        let splitdate: any = convertdate.split('T')[0];
        let variables: any = {
            // and: [{ date: { ge: localetoisodatetime(lastTwoWeekStartDate) } },

            //      { date: { le: moment(localetoisodatetime(lasttwoWeekEndDate.toISOString())).format('YYYY-MM-DD') + 'T23:59:59.999Z' } }
            //     ]
            vehicleid: { eq: props.vehicleid },
            date: { contains: splitdate },
            dxDriverMasterlogsId: { eq: props.driverid },
            reimbursement: { ne: 'Yes' }
        };
        let get14daysExp = await dxService.listitems('masterlog', '*', variables, true, 6000, nextToken);
        let response = get14daysExp.items;

        let nexttoken = get14daysExp.nextToken;
        if (nexttoken) {
            response = [...response, ...(await getmasterlogdata(props, nexttoken))];
        }
        return response;
    };
    const addData = async (props: any) => {
        let data = props;

        console.log(data);
        let filter = {
            id: { eq: data.drivername }
        };
        await getNextQueryData('', filter, 'driver', true).then(async (driver: any) => {
            // data['id'] = Math.random().toString(36).substring(2, 9);
            data['driverid'] = data.drivername !== undefined ? data.drivername : driverID;
            data['drivername'] = driver[0].name !== undefined ? driver[0].name : driverName;
            const subs = API.graphql<GraphQLSubscription<OnCreateDxLogSalikSubscription>>({
                query: onCreateDxLogSalik,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            }).subscribe({
                next: async ({ provider, value }) => {},
                error: (error) => console.warn(error)
            });
            if (type == 'cash') {
                await getReimbursemasterlogdata(props).then(async (res: any) => {
                    let sumSalik = 0;
                    if (res.length > 0) {
                        if (allocationID !== '') {
                            res.map((item: any) => {
                                sumSalik += item.Salik !== '' && item.Salik !== null && item.Salik !== 'NaN' ? parseFloat(item.Salik) : 0;
                                // sumSalik += parseFloat(item.Parking);
                            });
                            console.log(sumSalik);
                            let updatemasterData = {
                                vehicleid: data.vehicleid,
                                drivername: data.drivername !== undefined ? data.drivername : driverName,
                                date: localetoisodatetime(data.date),
                                // Fine: '',
                                Salik: sumSalik + parseFloat(data.amount),
                                // Vipfuel: '',
                                dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : '',
                                dxVehicleActivityLogsId: data.vehicleid,
                                dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                                dxVehicleMasterlogsId: data.vehicleid,
                                _version: res[0]._version,
                                reimbursement: 'Yes',
                                id: res[0].id
                            };
                            let os = await dxService.updateitem('masterlog', updatemasterData).then((success: any) => {
                                // showSuccess('', 'Added Successfully');
                                if (subs) subs.unsubscribe();
                                // navigate('/manage/logs/all');
                                // window.history.back();
                            });
                        } else {
                            seterrorMsg('There is no Allocation available for this combination');
                            showError();
                        }
                    } else {
                        if (allocationID !== '') {
                            let masterData: any = {
                                vehicleid: data.vehicleid,
                                drivername: data.drivername !== undefined ? data.drivername : driverName,
                                date: localetoisodatetime(data.date),
                                // Fine: '',
                                Salik: data.amount,
                                // Vipfuel: '',
                                reimbursement: 'Yes',
                                dxVehicleActivityLogsId: data.vehicleid,
                                dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : '',
                                dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                                dxVehicleMasterlogsId: data.vehicleid
                            };
                            let cuskey = `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}#reimbursement`;
                            masterData['id'] = cuskey;
                            let master = await dxService.createitem('masterlog', masterData, false).then((success) => {
                                // showSuccess('', 'Added Successfully');
                                if (subs) subs.unsubscribe();
                                // navigate('/manage/logs/all');
                                // window.history.back();
                            });
                        } else {
                            seterrorMsg('There is no Allocation available for this combination');
                            showError();
                        }
                    }
                });
            } else {
                await getmasterlogdata(props).then(async (res: any) => {
                    console.log(res);

                    let sumSalik = 0;
                    if (res.length > 0) {
                        if (allocationID !== '') {
                            res.map((item: any) => {
                                sumSalik += item.Salik !== '' && item.Salik !== null && item.Salik !== 'NaN' ? parseFloat(item.Salik) : 0;
                                // sumSalik += parseFloat(item.Parking);
                            });
                            console.log(sumSalik);
                            let updatemasterData = {
                                vehicleid: data.vehicleid,
                                drivername: data.drivername !== undefined ? data.drivername : driverName,
                                date: localetoisodatetime(data.date),
                                // Fine: '',
                                Salik: sumSalik + parseFloat(data.amount),
                                // Vipfuel: '',
                                dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : '',
                                dxVehicleActivityLogsId: data.vehicleid,
                                dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                                dxVehicleMasterlogsId: data.vehicleid,
                                _version: res[0]._version,
                                id: res[0].id
                            };
                            let os = await dxService.updateitem('masterlog', updatemasterData).then((success: any) => {
                                // showSuccess('', 'Added Successfully');
                                if (subs) subs.unsubscribe();
                                // navigate('/manage/logs/all');
                                // window.history.back();
                            });
                        } else {
                            seterrorMsg('There is no Allocation available for this combination');
                            showError();
                        }

                        //   showSuccess('', 'Added Successfully');
                        //   if (subs) subs.unsubscribe();
                        //   // navigate('/manage/logs/all');
                        //   setTimeout(() => {
                        //     window.history.back();
                        //   }, 1500);
                    } else {
                        if (allocationID !== '') {
                            let masterData: any = {
                                vehicleid: data.vehicleid,
                                drivername: data.drivername !== undefined ? data.drivername : driverName,
                                date: localetoisodatetime(data.date),
                                // Fine: '',
                                Salik: data.amount,
                                // Vipfuel: '',
                                dxVehicleActivityLogsId: data.vehicleid,
                                dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : '',
                                dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                                dxVehicleMasterlogsId: data.vehicleid
                            };
                            let cuskey = `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}`;
                            masterData['id'] = cuskey;
                            let master = await dxService.createitem('masterlog', masterData, false).then((success) => {
                                // showSuccess('', 'Added Successfully');
                                if (subs) subs.unsubscribe();
                                // navigate('/manage/logs/all');
                                // window.history.back();
                            });
                        } else {
                            seterrorMsg('There is no Allocation available for this combination');
                            showError();
                        }
                    }
                });
            }

            let salikdata: any = {
                id: data.id,
                amount: data.amount,
                date: localetoisodatetime(data.date),
                driverid: data.driverid,
                drivername: data.drivername,
                tagnumber: data.tagnumber,
                // dxMasterLogsSalikId: `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}`,
                tollgate: data.tollgate,
                transactiondate: localetoisodatetime(data.transactiondate),
                transactionid: data.transactionid,
                vehicleid: data.vehicleid,
                direction: data.direction,
                jfield: JSON.stringify({
                    allocationid: allocationID
                })
            };
            if (allocationID !== '') {
                if (type == 'cash') {
                    salikdata['reimbursement'] = 'Yes';
                    salikdata['dxMasterLogsSalikId'] = `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}#reimbursement`;
                } else {
                    salikdata['dxMasterLogsSalikId'] = `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}`;
                }
                let parkingitem = await dxService.createitem('logsalik', salikdata, false).then(async (success) => {
                    await showSuccess('', 'Added Successfully');
                    if (subs) subs.unsubscribe();
                    setTimeout(() => {
                        window.history.back();
                    }, 1000);
                });
            }
        });
    };
    const changedata = (props: any) => {
        props['vehicleid'] !== undefined && setVehicleID(props.vehicleid);
        props['date'] !== undefined && setSalikDate(props.date);
    };
    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';

    const wizardItems = [
        { label: 'New Salik', command: () => navigate('/manage/salik/item/basic' + qs) },
        { label: 'New Mileage', command: () => navigate('/manage/mileage/item/basic' + qs) },
        { label: 'New Fines', command: () => navigate('/manage/fines/item/basic' + qs) },
        { label: 'New VIP Fuel', command: () => navigate('/manage/vipfuel/item/basic' + qs) },
        { label: 'New Parking', command: () => navigate('/manage/parking/item/basic' + qs) }
    ];
    const editnewnavigation = async (formdata: any, fid: string) => {
        //
    };
    const showSuccess = (summary: string, detail: string) => {
        toast.current.show({
            severity: 'success',
            summary: summary,
            detail: detail,
            life: 3000
        });
    };
    const addConfig = (props: any) => {
        // if (formid == null)
        addData(props);
        // else {
        // editnewnavigation(props, formid);
        // }
    };
    const logtabItems = [
        {
            label: 'Salik',
            command: () => navigate('/manage/logs/salik')
        },

        // { label: 'Documents', disabled: formID != "" ? false : true ,command: () => navigate('/manage/appointments/item/documents' + qs) }
        { label: 'Vip Fuel', command: () => navigate('/manage/logs/vipfuel') },
        { label: 'Fines', command: () => navigate('/manage/logs/fines') },
        { label: 'Mileage', command: () => navigate('/manage/logs/mileage') },
        { label: 'Parking', command: () => navigate('/manage/logs/parking') },
        {
            label: 'All',
            command: () => navigate('/manage/logs/all')
        }
    ];
    const showError = () => {
        toast.current.show({
            severity: 'error',
            summary: 'There is no Allocation available for this combination',
            life: 3000
        });
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{formmode === 'edit' ? 'Edit Salik : ' + formid : formmode === 'view' ? 'View Salik: ' + formid : type == 'cash' ? 'Salik Reimbursement Form' : 'New Salik'}</h4>
                <TabMenu className="spd-pemptytopbottom" model={logtabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                <div className="card container-card">
                    <Toast ref={toast} />
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                    <Routes>
                        <Route
                            path={'/basic'}
                            element={
                                <>
                                    <FormTemplate columns={formColumns} data={listFormData} showaction={true} onChange={changedata} mode={formmode} ChangeBtn={'OpenDocument'} layout="grid2" title="New Approval" submitForm={addConfig} />
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};
export default SalikForm;
