import { useContext, useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { CSSTransition } from 'react-transition-group';
import { RTLContext } from '../../App';
import { TabMenu } from 'primereact/tabmenu';
import { useNavigate } from 'react-router-dom';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth, Storage } from 'aws-amplify';
import { useAuthContext } from '../../useAuthContext';
import { AuthContext } from '../../AuthContext';
import { getcurrentuserDetails } from '../../utils/helper';
import dxService from '../../Services/dxService';

const Navigation = (props: any) => {
    //get loggin user
    const { profileStatus, navStatus } = useContext(AuthContext);
    const userAuth = useAuthContext();
    const { dispatch } = useContext(AuthContext);

    const [activeIndex, setActiveIndex] = useState<any>(null);
    const [navName, setnavName] = useState('');
    const [event, setevent] = useState<any>(-1);
    const [dbMenu, setDBMenu] = useState<any>([]);
    const [userDetails, setUserDetails] = useState(userAuth);
    const [profileImage, setProfileImage] = useState('');
    const isRTL = useContext(RTLContext);
    const navigate = useNavigate();
    const topbarRef3 = useRef<any>(null);
    const topbarRef4 = useRef(null);

    useEffect(() => {
        getnavigationItems();
    }, [navStatus]);
    useEffect(() => {
        getcurrentuserDetails().then(async (user) => {
            dispatch({ type: 'LOGIN', payload: user });
            let image = await Storage.get(userAuth.user.attributes.profile);
            setProfileImage(image);
            setUserDetails(userAuth);
        });
    }, [profileStatus]);

    const getnavigationItems = async () => {
        let allItems: any[] = [];
        let filter = {
            type: { eq: 'Top' }
        }

        await dxService.listitems('navigationconfiguration', "*", filter).then((res) => {
            let newView = {};
            Promise.all(
                res
                    .filter((del: any) => del._deleted !== true)
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((item: any) => {
                        newView = {
                            label: JSON.parse(item.item).items.label,
                            icon: JSON.parse(item.item).items.icon,
                            command: () => navigate(JSON.parse(item.item).items.to)
                        };
                        allItems.push(newView);
                    })
            ).then(() => {
                //group the item to its own parent
                setDBMenu(allItems);
            });

        })

    };

    //setActiveIndex(e.index)

    const navigation = (e: any) => {
        let windowUrl = window.location.href;
        let Navname = e.value.label;
        setnavName(Navname);
        setevent(e.index);
        // if(windowUrl.toLowerCase().includes(Navname.toLowerCase())){

        //     let div:any = document.getElementsByClassName('p-tabmenuitem');

        //     //querySelectorAll('.slide.active')
        //     div.forEach((a:any)=> a.add('p-highlight'))
        // }
    };
    useEffect(() => {
        var windowUrl = window.location.href;
        var divelement = [];
        var div: any = document.getElementsByClassName('p-tabmenuitem');
        divelement.push(div);
        if (windowUrl.toLowerCase().includes(navName.toLowerCase())) {
            setActiveIndex(event);
            divelement.filter((i: any) => i.innerText == navName);
        } else {
            setActiveIndex(-1);
            for (let i = 0; i < div.length; i++) {
                return div[i].classList.remove('p-highlight');
            }
        }
    }, [window.location.href]);
    const SettingsClick = (input: any) => {
        switch (input) {
            case 'CompanySettings':
                navigate('/admin/businessunit/all');

                break;
            case 'DepartmentSettings':
                navigate('/admin/departments/all');

                break;
            case 'PermissionLevel':
                navigate('/PermissionLevel');
                break;
            case 'SettingsSettings':
                navigate('/admin/settings');
                break;
            case 'Users':
                navigate('/admin/users');
        }
    };


    return (
        <div className="layout-topbar shadow-4">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => navigate('/')}>
                    <img id="app-logo" src="assets/layout/images/logo-light.svg" alt="ultima-layout" style={{ height: '3.25rem' }} />
                </button>
                {userDetails.user ? (
                    <>
                        <button type="button" className="layout-menu-button shadow-6 p-link" onClick={props.onMenuButtonClick}>
                            <i className="pi pi-chevron-right"></i>
                        </button>
                        <button type="button" className="layout-topbar-mobile-button p-link">
                            <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}></i>
                        </button>
                    </>
                ) : (
                    ''
                )}
            </div>
            {userDetails.user ? (
                <div
                    className={classNames('layout-topbar-right', {
                        'layout-topbar-mobile-active': props.mobileTopbarActive
                    })}
                >
                    <div className="layout-topbar-actions-left">
                        {/* <MegaMenu model={model} className="layout-megamenu" /> */}
                        <TabMenu model={dbMenu} activeIndex={activeIndex} onTabChange={(e) => navigation(e)} />
                        {/* <Routes>
                    <Link to="/"></Link>   */}
                        {/* <Route path="/fleet" element={<Fleet />} />
                        <Route path="/warehousing" element={<Warehousing />} />
                        <Route path="/fitout" element={<Fitout />} />
                        <Route path="/procurement" element={<Procurement />} />
                        <Route path="/finance" element={<Finance />} /> */}
                        {/* </Routes> */}
                    </div>
                    <div className="layout-topbar-actions-right">
                        <ul className="layout-topbar-items">
                            {/* <li className="layout-topbar-item layout-search-item">
                                <button className="layout-topbar-action rounded-circle p-link" onClick={() => props.onSearch(true)}>
                                    <i className="pi pi-search fs-large"></i>
                                </button>
                                <CSSTransition nodeRef={topbarRef1} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.searchActive} unmountOnExit>
                                    <div ref={topbarRef1} className="layout-search-panel p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-search"></i>
                                        </span>
                                        <InputText type="text" placeholder="Search" onKeyDown={onInputKeydown} />
                                        <span className="p-inputgroup-addon">
                                            <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-text p-button-plain" onClick={() => props.onSearch(false)}></Button>
                                        </span>
                                    </div>
                                </CSSTransition>
                            </li>

                            <li className="layout-topbar-item notifications">
                                <button
                                    className="layout-topbar-action rounded-circle p-link"
                                    onClick={(event) =>
                                        props.onTopbarItemClick({
                                            originalEvent: event,
                                            item: 'notifications'
                                        })
                                    }
                                >
                                    <span className="p-overlay-badge">
                                        <i className="pi pi-bell fs-large"></i>
                                        <span className="p-badge p-badge-info p-badge-dot"></span>
                                    </span>
                                </button>

                                <CSSTransition nodeRef={topbarRef2} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'notifications'} unmountOnExit>
                                    <ul ref={topbarRef2} className="layout-topbar-action-panel shadow-6 fadeInDown">
                                        <li className="mb-3">
                                            <span className="px-3 fs-small">
                                                You have <b>4</b> new notifications
                                            </span>
                                        </li>
                                        <li className="layout-topbar-action-item">
                                            <div className="flex flex-row align-items-center">
                                                <img src="assets/demo/images/avatar/avatar-1.png" alt="" />
                                                <div
                                                    className={classNames('flex flex-column', {
                                                        'ml-3': !isRTL,
                                                        'mr-3': isRTL
                                                    })}
                                                    style={{ flexGrow: '1' }}
                                                >
                                                    <div className="flex align-items-center justify-content-between mb-1">
                                                        <span className="fs-small font-bold">Jerome Bell</span>
                                                        <small>42 mins ago</small>
                                                    </div>
                                                    <span className="fs-small">How to write content about your photographs?</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="layout-topbar-action-item">
                                            <div className="flex flex-row align-items-center">
                                                <img src="assets/demo/images/avatar/avatar-2.png" alt="" />
                                                <div
                                                    className={classNames('flex flex-column', {
                                                        'ml-3': !isRTL,
                                                        'mr-3': isRTL
                                                    })}
                                                    style={{ flexGrow: '1' }}
                                                >
                                                    <div className="flex align-items-center justify-content-between mb-1">
                                                        <span className="fs-small font-bold">Cameron Williamson</span>
                                                        <small>48 mins ago</small>
                                                    </div>
                                                    <span className="fs-small">Start a blog to reach your creative peak.</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="layout-topbar-action-item">
                                            <div className="flex flex-row align-items-center">
                                                <img src="assets/demo/images/avatar/avatar-3.png" alt="" />
                                                <div
                                                    className={classNames('flex flex-column', {
                                                        'ml-3': !isRTL,
                                                        'mr-3': isRTL
                                                    })}
                                                    style={{ flexGrow: '1' }}
                                                >
                                                    <div className="flex align-items-center justify-content-between mb-1">
                                                        <span className="fs-small font-bold">Anna Miles</span>
                                                        <small>1 day ago</small>
                                                    </div>
                                                    <span className="fs-small">Caring is the new marketing</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="layout-topbar-action-item">
                                            <div className="flex flex-row align-items-center">
                                                <img src="assets/demo/images/avatar/avatar-4.png" alt="" />
                                                <div
                                                    className={classNames('flex flex-column', {
                                                        'ml-3': !isRTL,
                                                        'mr-3': isRTL
                                                    })}
                                                    style={{ flexGrow: '1' }}
                                                >
                                                    <div className="flex align-items-center justify-content-between mb-1">
                                                        <span className="fs-small font-bold">Arlene Mccoy</span>
                                                        <small>4 day ago</small>
                                                    </div>
                                                    <span className="fs-small">Starting your traveling blog with Vasco.</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </CSSTransition>
                            </li> */}
                            <li className="layout-topbar-item app">
                                <button
                                    className="layout-topbar-action rounded-circle p-link"
                                    onClick={(event) => {
                                        props.onTopbarItemClick({
                                            originalEvent: event,
                                            item: 'apps'
                                        });
                                    }}
                                >
                                    <i className="pi pi-cog fs-large"></i>
                                </button>

                                <CSSTransition nodeRef={topbarRef3} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'apps'} unmountOnExit>
                                    <div ref={topbarRef3} className="layout-topbar-action-panel shadow-6">
                                        <div className="grid grid-nogutter navicons">
                                            <div className="layout-topbar-action-item col-4">
                                                <button
                                                    className="flex align-items-center flex-column text-color p-link"
                                                    onClick={(event) => {
                                                        SettingsClick('CompanySettings');
                                                        props.onTopbarItemClick({
                                                            originalEvent: event,
                                                            item: 'item'
                                                        });
                                                    }}
                                                >
                                                    <i className="ms-Icon ms-Icon--BusinessCenterLogo action indigo-bgcolor white-color"></i>
                                                    <span>Business Units</span>
                                                </button>
                                            </div>
                                            <div className="layout-topbar-action-item col-4">
                                                <button
                                                    className="flex align-items-center flex-column text-color p-link"
                                                    onClick={(event) => {
                                                        SettingsClick('DepartmentSettings');
                                                        props.onTopbarItemClick({
                                                            originalEvent: event,
                                                            item: 'item'
                                                        });
                                                    }}
                                                >
                                                    <i className="ms-Icon ms-Icon--Org action orange-bgcolor white-color"></i>
                                                    <span>Departments</span>
                                                </button>
                                            </div>
                                            <div className="layout-topbar-action-item col-4">
                                                <button
                                                    className="flex align-items-center flex-column text-color p-link"
                                                    onClick={(event) => {
                                                        SettingsClick('PermissionLevel');
                                                        props.onTopbarItemClick({
                                                            originalEvent: event,
                                                            item: 'item'
                                                        });
                                                    }}
                                                >
                                                    <i className="ms-Icon ms-Icon--Signin action teal-bgcolor white-color"></i>
                                                    <span>Roles</span>
                                                </button>
                                            </div>
                                            <div className="layout-topbar-action-item col-4">
                                                <button
                                                    className="flex align-items-center flex-column text-color p-link"
                                                    onClick={(event) => {
                                                        SettingsClick('SettingsSettings');
                                                        props.onTopbarItemClick({
                                                            originalEvent: event,
                                                            item: 'item'
                                                        });
                                                    }}
                                                >
                                                    <i className="pi pi-cog action pink-bgcolor white-color"></i>
                                                    <span>Settings</span>
                                                </button>
                                            </div>
                                            <div className="layout-topbar-action-item col-4">
                                                <button
                                                    className="flex align-items-center flex-column text-color p-link"
                                                    onClick={(event) => {
                                                        SettingsClick('Users');
                                                        props.onTopbarItemClick({
                                                            originalEvent: event,
                                                            item: 'item'
                                                        });
                                                    }}
                                                >
                                                    <i className="ms-Icon ms-Icon--Family action bluegrey-bgcolor white-color"></i>
                                                    <span>Users</span>
                                                </button>
                                            </div>
                                            <div className="layout-topbar-action-item col-4">
                                                <button className="flex align-items-center justify-content-center flex-column text-color p-link">
                                                    <i className="pi pi-sitemap action cyan-bgcolor white-color"></i>
                                                    <span>Sitemap</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>
                            </li>
                            <li className="layout-topbar-item">
                                <button
                                    className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link"
                                    onClick={(event) =>
                                        props.onTopbarItemClick({
                                            originalEvent: event,
                                            item: 'profile'
                                        })
                                    }
                                >
                                    <img src={profileImage ? profileImage : 'assets/demo/images/avatar/amyelsner.png'} alt="avatar" style={{ width: '32px', height: '32px', borderRadius: '50%' }} />
                                    {/* <img src="assets/demo/images/avatar/amyelsner.png" alt="avatar" style={{ width: '32px', height: '32px' }} /> */}
                                </button>

                                <CSSTransition nodeRef={topbarRef4} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                                    <ul ref={topbarRef4} className="layout-topbar-action-panel shadow-6">
                                        {userDetails.user ? (
                                            <li className="mb-3">
                                                {userDetails.user ? (
                                                    <div className="flex align-items-center justify-content-between mb-1">
                                                        <div className="px-3  font-bold">{userDetails.user.attributes.preferred_username}</div>
                                                        <div className="px-3"> {userDetails.user.attributes.email}</div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                        <li className="layout-topbar-action-item">
                                            <button className="flex flex-row align-items-center p-link">
                                                <i
                                                    className={classNames('pi pi-cog', {
                                                        'mr-2': !isRTL,
                                                        'ml-2': isRTL
                                                    })}
                                                ></i>
                                                <span
                                                    onClick={() => {
                                                        navigate('profile?id=' + userDetails.user.username);
                                                        props.onTopbarItemClick({
                                                            originalEvent: event,
                                                            item: 'item'
                                                        });
                                                    }}
                                                >
                                                    My Profile
                                                </span>
                                            </button>
                                        </li>
                                        <li className="layout-topbar-action-item">
                                            <button className="flex flex-row align-items-center p-link">
                                                <i
                                                    className={classNames('pi pi-cog', {
                                                        'mr-2': !isRTL,
                                                        'ml-2': isRTL
                                                    })}
                                                ></i>
                                                <span>Settings</span>
                                            </button>
                                        </li>
                                        <li className="layout-topbar-action-item">
                                            <button className="flex flex-row align-items-center p-link">
                                                <i
                                                    className={classNames('pi pi-file', {
                                                        'mr-2': !isRTL,
                                                        'ml-2': isRTL
                                                    })}
                                                ></i>
                                                <span>Terms of Usage</span>
                                            </button>
                                        </li>
                                        <li className="layout-topbar-action-item ">
                                            <button className="flex flex-row align-items-center p-link">
                                                <i
                                                    className={classNames('pi pi-compass', {
                                                        'mr-2': !isRTL,
                                                        'ml-2': isRTL
                                                    })}
                                                ></i>
                                                <span>Support</span>
                                            </button>
                                        </li>
                                        <li className="layout-topbar-action-item">
                                            <button className="flex flex-row align-items-center p-link">
                                                <i
                                                    className={classNames('pi pi-power-off', {
                                                        'mr-2': !isRTL,
                                                        'ml-2': isRTL
                                                    })}
                                                ></i>

                                                {userDetails.user ? (
                                                    <span
                                                        onClick={() => {
                                                            Auth.signOut();
                                                            userDetails.dispatch({ type: 'LOGOUT', payload: null });
                                                        }}
                                                    >
                                                        Log Out
                                                    </span>
                                                ) : (
                                                    <span onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}>Log In</span>
                                                )}
                                            </button>
                                        </li>
                                    </ul>
                                </CSSTransition>
                            </li>
                            {/* <li className="layout-topbar-item">
                    <button type="button" className="layout-topbar-action rounded-circle p-link" onClick={props.onRightMenuButtonClick}>
                        <i className="pi fs-large pi-arrow-left"></i>
                    </button>
                </li> */}
                        </ul>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default Navigation;
