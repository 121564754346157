import  { useState, useEffect, useRef} from 'react';
import { Toast } from 'primereact/toast';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../dxgraphql/dxqueries';
import Webparts from '../../Components/Webparts';
const PageForm = () => {
    const [appitem, setappitem] = useState<any>({});
    const toast = useRef(null);
    useEffect(() => {
        getdata();
    }, []);

    useEffect(() => {
    }, [appitem]);

    const changedata = (props: any) => {
        console.log(props);
    };

    const addData = (props: any) => {
        console.log(props);
    };

    const getdata = async () => {
    }

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Header Template</h4>
                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}
                            <Webparts/>
                       
                            {/* END Inner COMPONENT HTML */}
                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}
            </div>
        </div>

    );
};
export default PageForm;

