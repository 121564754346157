import React, { useState, useEffect, useRef, Children, useContext } from 'react';
import { Amplify, API, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsconfig from '../../aws-exports';
import { Toast } from 'primereact/toast';
import { AuthContext } from '../../AuthContext';
import { CognitoIdentityProviderClient, AdminAddUserToGroupCommand, AdminRemoveUserFromGroupCommand, ListGroupsCommand, AdminListGroupsForUserCommand, AdminUpdateUserAttributesCommand } from '@aws-sdk/client-cognito-identity-provider';
import AmplifyServices from '../../Service/Service';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../dxgraphql/dxqueries';
import { getDxApprovalConfig, getDxWorkflow, listDxApprovals } from '../../graphql/queries';
import { updateDxApprovalConfig, updateDxApprovals, updateDxWorkflow } from '../../graphql/mutations';
import FormTemplate from '../../Components/FormTemplate';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useNavigate } from 'react-router-dom';

const SettingsPage = () => {
    const { dispatch } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [workflowitem, setworkflowitem] = useState<any>({});
    const [stepitem, setstepitem] = useState<any>({});

    const [newdata, setnewdata] = useState<any>({});

    const [uname, setUname] = useState('');
    const [name, setname] = useState('');

    const [appitem, setappitem] = useState<any>({});
    const toast = useRef(null);
    const menu = useRef<any>(null);
    const menu2 = useRef<any>(null);
    const menu3 = useRef<any>(null);
    const navigate = useNavigate();

    const items = [
        {
            label: 'Workflows',
            items: [
                {
                    label: 'Manage Workflows',
                    icon: 'pi pi-refresh',
                    command: () => {
                        navigate('/manage/workflows');
                        // toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                    }
                },
                {
                    label: 'Create Workflow',
                    icon: 'pi pi-times',
                    command: () => {
                        navigate('/manage/workflows/add');
                        // toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                    }
                },
                {
                    label: 'Associate Approvals',
                    icon: 'pi pi-refresh',
                    command: () => {
                        navigate('/manage/workflows/associate');
                        // toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                    }
                }
            ]
        },
        {
            label: 'Approvals',
            items: [
                {
                    label: 'Manage Approvals',
                    icon: 'pi pi-times',
                    command: () => {
                        navigate('/manage/approvals/all');
                        // toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                    }
                }
                // {
                //     label: 'Associate Approvals',
                //     icon: 'pi pi-external-link',
                //     url: 'https://reactjs.org/'
                // },
                // {
                //     label: 'Manage Approvals',
                //     icon: 'pi pi-upload',
                //     command:(e:any) => {
                //         //router.push('/manage/wfconfig');
                //     }
                // }
            ]
        }
    ];

    const generalitems = [
        {
            label: 'General',
            items: [
                {
                    label: 'Manage Configurations',
                    icon: 'pi pi-refresh',
                    command: () => {
                        navigate('/admin/configs/all?view=generalconfigs');
                    }
                }
            ]
        },
        {
            label: 'Navigations',
            items: [
                {
                    label: 'Manage Navigation',
                    icon: 'pi pi-times',
                    command: () => {
                        navigate('/admin/navigation/all');
                    }
                }
            ]
        }
    ];

    const useritems = [
        {
            label: 'Group and Permissions',
            items: [
                {
                    label: 'Manage Permissions Levels',
                    icon: 'pi pi-refresh',
                    command: () => {
                        navigate('/admin/permissionlevels');
                    }
                },
                {
                    label: 'Create Role with Permission',
                    icon: 'pi pi-times',
                    command: () => {
                        navigate('/admin/permissions');
                    }
                },
                {
                    label: 'Add Group Permission',
                    icon: 'pi pi-times',
                    command: () => {
                        navigate('/admin/userrole');
                    }
                }
            ]
        },
        {
            label: 'Groups and Members',
            items: [
                {
                    label: 'Manage Groups',
                    icon: 'pi pi-times',
                    command: () => {
                        navigate('/admin/erpgroups');
                    }
                },
                {
                    label: 'Manage All Users',
                    icon: 'pi pi-times',
                    command: () => {
                        navigate('/admin/users');
                    }
                }
            ]
        }
    ];

    useEffect(() => {
        getdata();
    }, []);

    useEffect(() => {}, [appitem]);

    const changedata = (props: any) => {
        console.log(props);
    };

    const addData = (props: any) => {
        console.log(props);
    };

    const getdata = async () => {};

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Settings</h4>

                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}
                            <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                <div className="surface-ground">
                                    <div className="grid">
                                        <div className="col-12 lg:col-4 p-3">
                                            <div className="p-3 border-round shadow-2 flex align-items-center surface-card">
                                                <div
                                                    className=" inline-flex align-items-center justify-content-center mr-3"
                                                    // style="width: 48px; height: 48px; border-radius: 10px;"
                                                >
                                                    <i className="pi pi-check  text-3xl"></i>
                                                </div>
                                                <div>
                                                    <span className="text-900 text-xl font-medium mb-2">Approvals</span>
                                                    <p className="mt-1 mb-0 text-600 font-medium text-sm">Click here to manage approval configurations</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <Button icon="pi pi-ellipsis-v" onClick={(e) => menu.current.toggle(e)} />

                                                    <Menu model={items} popup ref={menu} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* General Configuration */}
                                        <div className="col-12 lg:col-4 p-3">
                                            <div className="p-3 border-round shadow-2 flex align-items-center surface-card">
                                                <div
                                                    className=" inline-flex align-items-center justify-content-center mr-3"
                                                    // style="width: 48px; height: 48px; border-radius: 10px;"
                                                >
                                                    <i className="pi pi-check  text-3xl"></i>
                                                </div>
                                                <div>
                                                    <span className="text-900 text-xl font-medium mb-2">Configurations</span>
                                                    <p className="mt-1 mb-0 text-600 font-medium text-sm">Click here to manage configurations</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <Button icon="pi pi-ellipsis-v" onClick={(e) => menu2.current.toggle(e)} />

                                                    <Menu model={generalitems} popup ref={menu2} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* User Management */}
                                        <div className="col-12 lg:col-4 p-3">
                                            <div className="p-3 border-round shadow-2 flex align-items-center surface-card">
                                                <div
                                                    className=" inline-flex align-items-center justify-content-center mr-3"
                                                    // style="width: 48px; height: 48px; border-radius: 10px;"
                                                >
                                                    <i className="pi pi-check  text-3xl"></i>
                                                </div>
                                                <div>
                                                    <span className="text-900 text-xl font-medium mb-2">User Management</span>
                                                    <p className="mt-1 mb-0 text-600 font-medium text-sm">Click here to manage users and permissions</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <Button icon="pi pi-ellipsis-v" onClick={(e) => menu3.current.toggle(e)} />

                                                    <Menu model={useritems} popup ref={menu3} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* END Inner COMPONENT HTML */}
                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}
            </div>
        </div>
    );
};
export default SettingsPage;
