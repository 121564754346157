import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import './Vehicleform.css';
import AmplifyServices from '../../Service/Service';
const VehicleDocumentEditForm = (Data: any, txt: any) => {
    const DocumentTypeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.documentType}
            </>
        );
    };
    const AttachmentBodyTemplate = (rowData: any) => {
        var attachment =
            rowData.name !== undefined ? (
                <>
                    <span className="p-column-title">Code</span>
                    <a href={rowData.downLoadUrl}>
                        {rowData.name}
                        <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
                    </a>
                </>
            ) : (
                <>
                    <span className="p-column-title">Code</span>
                    {rowData.File.name}
                </>
            );
        return <>{attachment}</>;
    };
    return (
        <div className="field col-12">
            <div className="card">
                <h5>Uploaded Documents</h5>
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-12">
                        <DataTable
                            value={Data.data}
                            dataKey="id"
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No Documents added."
                            responsiveLayout="scroll"
                        >
                            <Column field="Year" header="Year" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="documentType" header="Document Type" body={DocumentTypeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="File" header="Attachment" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VehicleDocumentEditForm;
