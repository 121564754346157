import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import './../Reports/Reports.css';
import dxService from '../../Services/dxService';
import { CSVLink } from 'react-csv';
import { convertdate, getVehicleoptions, isotolocaledatetime, localetoisodatetime } from '../../utils/helper';
import { useEffect, useState, memo } from 'react';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { useLocation } from 'react-router-dom';
import { getTypeColors } from '../../utils/color';

const VehicleUsage = () => {
    const [Reports, setReports] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [SelectedDate, setSelectedDate] = useState<any>([]);
    const [filtervehicleid, setfiltervehicleid] = useState<any>('');
    const [vehicleid, setvehicleid] = useState<any>('');
    const [startDateFormat, setStartDateFormat] = useState<string>('');
    const [endDateFormat, setEndDateFormat] = useState<string>('');
    const [formatdata, setformatdata] = useState<any>([]);
    const [oldformatdata, setoldformdata] = useState<any>([]);
    const [totalfuel, settotalfuel] = useState<any>(0);
    const [totalsaik, settotalsalik] = useState<any>(0);
    const [totalfine, settotalfine] = useState<any>(0);
    const [totalReimbursement, setTotalReimbursement] = useState<any>(0);
    const [exportType, setExportType] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [transformedData, setTransformedData] = useState<any>([]);
    const [selectView, setSelectView] = useState<any>('');
    const [vehicles, setVehicles] = useState<any>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<any>('');
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var sdate: any = urlParams.has('sdate') ? urlParams.get('sdate') : '';
    var edate: any = urlParams.has('edate') ? urlParams.get('edate') : '';
    var view: any = urlParams.has('view') ? urlParams.get('view') : '';
    var vid: any = urlParams.has('vid') ? urlParams.get('vid') : '';
    const colors = getTypeColors('light');
    // Get the params value and set it to the state
    useEffect(() => {
        const getParamsDetails = () => {
            // Check if 'view' parameter is provided and set the state accordingly
            if (view !== '') {
                setSelectView(view);
            } else {
                setSelectView('Summary');
            }

            // Check if 'vid' parameter is provided and set the selected vehicle state
            if (vid !== '') {
                setSelectedVehicle(vid);
            }

            // Check if 'sdate' parameter is provided and set the selected date range state
            if (sdate !== '') {
                setTimeout(() => {
                    // Retrieve 'sdate' and 'edate' from URL params, or set them as empty strings
                    sdate = urlParams.has('sdate') ? urlParams.get('sdate') : '';
                    edate = urlParams.has('edate') ? urlParams.get('edate') : '';

                    // Convert the retrieved dates to Date objects
                    let startDate = new Date(sdate);
                    let endDate = edate !== '' ? new Date(edate) : startDate;

                    // Set the selected date range state
                    setSelectedDate([startDate, endDate]);
                }, 1000);
            } else {
                // If 'sdate' is not provided, set the default date range
                let todate = new Date();
                let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
                let lastfirstdate = new Date(todate.getFullYear(), todate.getMonth() - 1, 1);
                setSelectedDate([lastfirstdate, lastenddate]);
            }
        };

        // Call the function to retrieve and set the parameter details
        getParamsDetails();
    }, []);

    useEffect(() => {
        getVehicle();
    }, []);
    const getVehicle = () => {
        //get vehicle dropdown options
        getVehicleoptions().then((options) => {
            setVehicles(options);
        });
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            await getData();
        };
        setTimeout(() => {
            fetchData();
        }, 1500);
        updateURL(selectView, SelectedDate[0], SelectedDate[1], selectedVehicle);
    }, [SelectedDate, selectedVehicle]);

    //get the data from the department and fetch the data from the master logs from its linked data
    const getData = async () => {
        // let a1 = isotolocaledatetime(SelectedDate);
        // let filter1 ="";
        // if(a1 != null){
        //     let a2 = new Date(a1);
        //     filter1 = moment(a2).format("yyyy-MM-");
        // }

        let tempData: any[] = [];
        let deptcolumns = `id fleettype name description _deleted   
                            vehicles${
                                selectedVehicle
                                    ? `(filter: {
                                            id: {
                                                eq: "${selectedVehicle}"
                                            }
                                        })`
                                    : ''
                            }{
                                items{
                                    id
                                    _deleted
                                    masterlogs(filter: {
                                        date: {
                                            ge: "${localetoisodatetime(SelectedDate.length > 0 ? SelectedDate[0] : SelectedDate)}",
                                            le: "${localetoisodatetime(SelectedDate.length > 0 && SelectedDate[1] !== null ? SelectedDate[1] : SelectedDate[0])}"
                                        }         
                                    }, sortDirection: DESC, limit: 100000){
                                        items{
                                            id
                                            Fine
                                            Salik
                                            Vipfuel
                                            reimbursement
                                            date
                                            vehicleid
                                            _deleted
                                        }
                                        nextToken
                                    }
                                }            
                            }`;

        let b = await dxService.listitems('department', deptcolumns, {}, false);
        let c = b.filter((del: any) => del._deleted !== true);
        let a: any = [];
        if (selectedVehicle) {
            a = c.filter((department: any) => department.vehicles.items.length > 0);
        } else {
            a = c;
        }
        let totalfuel = 0;
        let totalsalik = 0;
        let totalfines = 0;
        let totalreimbursement = 0;
        let allvalues: { department: any; vehicles: any; salik: number; fuel: number; fine: number; reimbursement: number }[] = [];
        setformatdata(a);

        a.forEach((dept: any) => {
            let dsalik = 0;
            let dfuel = 0;
            let dfine = 0;
            let dreimbursement = 0;
            let vehicleItems = dept.vehicles.items;

            //get the total amount and their values
            vehicleItems
                .filter((del: any) => del._deleted !== true)
                .forEach((vehicle: any) => {
                    let masterlogs = vehicle.masterlogs.items;
                    masterlogs
                        .filter((del: any) => del._deleted !== true)
                        .forEach((masterlog: any) => {
                            dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                            dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                            dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
                            dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
                        });
                });
            totalfuel += dfuel;
            totalsalik += dsalik;
            totalfines += dfine;
            totalreimbursement += dreimbursement;
            allvalues.push({
                department: dept.name,
                vehicles: vehicleItems.length,
                salik: dsalik,
                fuel: dfuel,
                fine: dfine,
                reimbursement: dreimbursement
            });
        });

        settotalfuel(totalfuel);
        settotalsalik(totalsalik);
        settotalfine(totalfines);
        setTotalReimbursement(totalreimbursement);
        // setformatdata(allvalues);
        setoldformdata(allvalues);

        //getting the data for showing all details
        a.forEach((department: any) => {
            department.vehicles.items.forEach((vehicle: any) => {
                const fuelLogs = vehicle.masterlogs.items.filter((log: any) => log.Vipfuel !== null);
                const salikLogs = vehicle.masterlogs.items.filter((log: any) => log.Salik !== null);
                const finelogs = vehicle.masterlogs.items.filter((log: any) => log.Fine !== null);
                const reimburselogs = vehicle.masterlogs.items.filter((log: any) => log.reimbursement !== null);
                const totalFuelAmount = fuelLogs.reduce((total: any, log: any) => {
                    const fuelValue = parseFloat(log.Vipfuel);
                    return isNaN(fuelValue) ? total : total + fuelValue;
                }, 0);
                const totalSalikAmount = salikLogs.reduce((total: any, log: any) => {
                    const salikValue = parseFloat(log.Salik);
                    return isNaN(salikValue) ? total : total + salikValue;
                }, 0);
                const totalFineAmount = finelogs.reduce((total: any, log: any) => {
                    const fineValue = parseFloat(log.Fine);
                    return isNaN(fineValue) ? total : total + fineValue;
                }, 0);
                const totalReimburseAmount = reimburselogs.reduce((total: any, log: any) => {
                    const reimburseValue = parseFloat(log.reimbursement);
                    return isNaN(reimburseValue) ? total : total + reimburseValue;
                }, 0);
                const fuelUsageValue = (totalFuelAmount / totalfuel) * 100;
                const fuelPercentage = totalfuel === 0 || isNaN(fuelUsageValue) ? '0%' : `${fuelUsageValue.toFixed(2)}%`;
                const salikUsageValue = (totalSalikAmount / totalsaik) * 100;
                const salikPercentage = totalsaik === 0 || isNaN(salikUsageValue) ? '0%' : `${salikUsageValue.toFixed(2)}%`;
                const fineUsageValue = (totalFineAmount / totalfine) * 100;
                const finePercentage = totalsaik === 0 || isNaN(fineUsageValue) ? '0%' : `${fineUsageValue.toFixed(2)}%`;
                const reimburseUsageValue = (totalReimburseAmount / totalreimbursement) * 100;
                const reimbursePercentage = totalreimbursement === 0 || isNaN(reimburseUsageValue) ? '0%' : `${reimburseUsageValue.toFixed(2)}%`;

                tempData.push({
                    department: department.name,
                    vehicle: vehicle.id,
                    totalFuelAmount: 'AED ' + totalFuelAmount.toFixed(2),
                    fuelPercentage,
                    totalSalikAmount: 'AED ' + totalSalikAmount.toFixed(2),
                    salikPercentage,
                    totalFineAmount: 'AED ' + totalFineAmount.toFixed(2),
                    finePercentage,
                    totalReimburseAmount: 'AED ' + totalReimburseAmount.toFixed(2),
                    reimbursePercentage
                });
            });
        });

        setTransformedData(tempData);
        setLoading(false);

        return a;
    };

    const header = [
        { label: 'Department', key: 'department' },
        { label: 'No. of Vehicles', key: 'vehicles' },
        { label: 'Total Amount', key: 'fueld' }
    ];

    //set the date based on the date selection from the calendar
    useEffect(() => {
        const startDate = SelectedDate[0];
        const endDate = SelectedDate[1];
        const startDateFormat = startDate ? moment(startDate).format('DD MMM YY') : '';
        const endDateFormat = endDate ? moment(endDate).format('DD MMM YY') : '';

        setStartDateFormat(startDateFormat);
        setEndDateFormat(endDateFormat);
    }, [SelectedDate]);

    const ClearBtn = () => {
        setSelectedDate('');
        // dateformat = [];
        setStartDateFormat('');
        setEndDateFormat('');
        //   dateformat = 0;
        updateURL(selectView, '', '', selectedVehicle);
    };

    if (SelectedDate !== null && SelectedDate.length > 0) {
        var ShowClrBtn: any = (
            <>
                <button
                    onClick={ClearBtn}
                    className={`clrbtn`}
                    // style={{ display: toggle, position: "relative", bottom: "5px" }}
                >
                    <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" style={{ color: '#db0000', fontWeight: 700 }}></i>
                    &nbsp;
                </button>
            </>
        );
    }
    //get the total amount for the specific log and set the value
    const actionfloattemplate = (rowData: any, column: any) => {
        let totalfuel = 0;
        let totalsalik = 0;
        let totalfine = 0;
        let totalreimbursement = 0;

        let dsalik = 0;
        let dfuel = 0;
        let dfine = 0;
        let dreimbursement = 0;

        let vehicleitems = rowData.vehicles.items;

        vehicleitems &&
            vehicleitems.forEach((vehicle: any) => {
                let masterlogs = vehicle.masterlogs.items;
                masterlogs.forEach((masterlog: any) => {
                    dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                    dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                    dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
                    dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
                });
            });
        totalfuel += dfuel;
        totalsalik += dsalik;
        totalfine += dfine;
        totalreimbursement += dreimbursement;

        let num: any = column.field === 'salik' ? totalsalik : column.field === 'fuel' ? totalfuel : column.field === 'fine' ? totalfine : totalreimbursement;
        // Check if the input is a number and has more than two decimal places

        if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
            // Round off to two decimal places
            num = Number(Number(num).toFixed(2));
        }
        return (
            <div
                className={`p-button ${column.column.props.children}`}
                style={{
                    width: '-webkit-fill-available',
                    justifyContent: 'flex-end',
                    background: column.column.props.children,
                    color: '#000'
                }}
            >
                {num !== null ? 'AED ' + num : '-'}
            </div>
        );
    };
    //get the total amount for the specific log and set the value (expanded row)
    const actionsubfloattemplate = (rowData: any, column: any) => {
        let dsalik = 0;
        let dfuel = 0;
        let dfine = 0;
        let dreimbursement = 0;

        let masterlogs = rowData.masterlogs.items;
        masterlogs &&
            masterlogs.forEach((masterlog: any) => {
                dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
                dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
            });
        let num: any = column.field === 'salik' ? dsalik : column.field === 'fuel' ? dfuel : column.field === 'fine' ? dfine : dreimbursement;
        // Check if the input is a number and has more than two decimal places
        if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
            // Round off to two decimal places
            num = Number(Number(num).toFixed(2));
        }
        return <>{num !== null ? 'AED ' + num : '-'}</>;
    };

    //calculate the percentage for the specific log
    const usagetemplate = (rowData: any, column: any) => {
        let dsalik = 0;
        let dfuel = 0;
        let dfine = 0;
        let dreimbursement = 0;
        let vehicleitems = rowData.vehicles.items;

        vehicleitems &&
            vehicleitems.forEach((vehicle: any) => {
                let masterlogs = vehicle.masterlogs.items;
                masterlogs.forEach((masterlog: any) => {
                    dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                    dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                    dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
                    dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
                });
            });

        let num: any = column.field === 'salik' ? dsalik : column.field === 'fuel' ? dfuel : column.field === 'fine' ? dfine : dreimbursement;
        const usagevalue = column.field === 'fuel' ? (num / totalfuel) * 100 : column.field === 'salik' ? (num / totalsaik) * 100 : column.field === 'fine' ? (num / totalfine) * 100 : (num / totalReimbursement) * 100;
        const formattedValue = isNaN(usagevalue) ? '0' : `${usagevalue.toFixed(2)}%`;
        // accumulate usage values
        // accumulate usage values

        // setTotalFuelUsage(fuelusage);
        // setTotalSalikUsage(salikusage);
        return (
            <div
                className={`p-button ${column.column.props.children}`}
                style={{
                    width: '-webkit-fill-available',
                    justifyContent: 'flex-end',
                    background: column.column.props.children,
                    color: '#000'
                }}
            >
                {formattedValue}
            </div>
        );
    };
    // calculate the percentage for the log
    const subusagetemplate = (rowData: any, column: any) => {
        let dsalik = 0;
        let dfuel = 0;
        let dfine = 0;
        let dreimbursement = 0;

        let masterlogs = rowData.masterlogs.items;
        masterlogs.forEach((masterlog: any) => {
            dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
            dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
            dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
            dreimbursement += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
        });

        let num: any = column.field === 'salik' ? dsalik : column.field === 'fuel' ? dfuel : column.field === 'fine' ? dfine : dreimbursement;
        const usagevalue = column.field === 'fuel' ? (num / totalfuel) * 100 : column.field === 'salik' ? (num / totalsaik) * 100 : column.field === 'fine' ? (num / totalfine) * 100 : (num / totalReimbursement) * 100;

        const formattedValue = isNaN(usagevalue) ? '0' : `${usagevalue.toFixed(2)}%`;
        // accumulate usage values
        // accumulate usage values

        // setTotalFuelUsage(fuelusage);
        // setTotalSalikUsage(salikusage);
        return <>{formattedValue}</>;
    };

    const totalSalikUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.salik, 0);
    const totalFuelUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.fuel, 0);
    const totalFineUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.fine, 0);
    const totalReimbursementUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.reimbursement, 0);
    const totalVehicles = oldformatdata.reduce((acc: any, cur: any) => acc + cur.vehicles, 0);

    const exportdata = (data: any, type: any) => {
        let items: any = [];
        data.map((exportitem: any) => {
            let saliknum: any = exportitem.salik;
            let fuelnum: any = exportitem.fuel;
            let finenum: any = exportitem.fine;
            let reimbusementnum: any = exportitem.reimbursement;

            // Check if the input is a number and has more than two decimal places
            if (saliknum !== null && !isNaN(saliknum) && /\.\d{3,}/.test(saliknum.toString())) {
                // Round off to two decimal places
                saliknum = Number(Number(saliknum).toFixed(2));
            }
            if (fuelnum !== null && !isNaN(fuelnum) && /\.\d{3,}/.test(fuelnum.toString())) {
                // Round off to two decimal places
                fuelnum = Number(Number(fuelnum).toFixed(2));
            }
            if (finenum !== null && !isNaN(finenum) && /\.\d{3,}/.test(finenum.toString())) {
                // Round off to two decimal places
                finenum = Number(Number(finenum).toFixed(2));
            }
            if (reimbusementnum !== null && !isNaN(reimbusementnum) && /\.\d{3,}/.test(reimbusementnum.toString())) {
                // Round off to two decimal places
                reimbusementnum = Number(Number(reimbusementnum).toFixed(2));
            }
            //% usage
            const salikusagevalue = (saliknum / totalsaik) * 100;
            const salikformattedValue = isNaN(salikusagevalue) ? '0' : `${salikusagevalue.toFixed(2)}%`;
            const fuelusagevalue = (fuelnum / totalfuel) * 100;
            const fuelformattedValue = isNaN(fuelusagevalue) ? '0' : `${fuelusagevalue.toFixed(2)}%`;
            const fineusagevalue = (finenum / totalfine) * 100;
            const fineformattedValue = isNaN(fineusagevalue) ? '0' : `${fineusagevalue.toFixed(2)}%`;
            const reimburseusagevalue = (reimbusementnum / totalReimbursement) * 100;
            const reimburseformattedValue = isNaN(reimburseusagevalue) ? '0' : `${reimburseusagevalue.toFixed(2)}%`;
            let a = {
                Department: exportitem.department,
                'Total No. of vehicles': exportitem.vehicles,
                'Total Fuel Amount': 'AED ' + fuelnum,
                'Fuel % in usage': fuelformattedValue,
                'Total Salik Amount': 'AED ' + saliknum,
                'Salik % in usage': salikformattedValue,
                'Total Fine Amount': 'AED ' + finenum,
                'Fine % in usage': fineformattedValue,
                'Total Reimburse Amount': 'AED ' + reimbusementnum,
                'Reimbursement % in usage': reimburseformattedValue
            };
            items.push(a);
        });
        // Add footer row
        let footer = {
            Department: 'Total',
            'Total No. of vehicles': totalVehicles,
            'Total Fuel Amount': totalfuel,
            'Fuel % in usage': totalFuelUsage === 0 ? '0%' : `${((100 * totalfuel) / totalFuelUsage).toFixed(2)}%`,
            'Total Salik Amount': totalsaik,
            'Salik % in usage': totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`,
            'Total Fine Amount': totalfine,
            'Fine % in usage': totalFineUsage === 0 ? '0%' : `${((100 * totalfine) / totalFineUsage).toFixed(2)}%`,
            'Total Reimburse Amount': totalReimbursement,
            'Reimbursement % in usage': totalReimbursementUsage === 0 ? '0%' : `${((100 * totalReimbursement) / totalReimbursementUsage).toFixed(2)}%`
        };

        items.push(footer);
        return items;
    };
    const exportdetaileddata = (data: any, type: any) => {
        let items: any = [];
        data.forEach((item: any) => {
            let vehicleitems = item.vehicles.items;
            vehicleitems &&
                vehicleitems.forEach((vehicle: any) => {
                    let dsalik = 0;
                    let dfuel = 0;
                    let dfine = 0;
                    let dreimburse = 0;

                    let masterlogs = vehicle.masterlogs.items;
                    masterlogs.forEach((masterlog: any) => {
                        dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                        dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                        dfine += masterlog.Fine ? parseFloat(masterlog.Fine) : 0;
                        dreimburse += masterlog.reimbursement ? parseFloat(masterlog.reimbursement) : 0;
                    });
                    let saliknum: any = dsalik;
                    const salikusagevalue = (saliknum / totalsaik) * 100;
                    const salikformattedValue = isNaN(salikusagevalue) ? '0' : `${salikusagevalue.toFixed(2)}%`;
                    let fuelnum: any = dfuel;
                    const fuelusagevalue = (fuelnum / totalfuel) * 100;
                    const fuelformattedValue = isNaN(fuelusagevalue) ? '0' : `${fuelusagevalue.toFixed(2)}%`;
                    let finenum: any = dfine;
                    const fineusagevalue = (finenum / totalfine) * 100;
                    const fineformattedValue = isNaN(fineusagevalue) ? '0' : `${fineusagevalue.toFixed(2)}%`;
                    let reimbursenum: any = dreimburse;
                    const reimburseusagevalue = (reimbursenum / totalReimbursement) * 100;
                    const reimburseformattedValue = isNaN(reimburseusagevalue) ? '0' : `${reimburseusagevalue.toFixed(2)}%`;
                    let a = {
                        Department: item.name,
                        Vehicle: vehicle.id,
                        'Total Fuel Amount': dfuel.toFixed(2),
                        'Fuel % in Usage': fuelformattedValue,
                        'Total Salik Amount': dsalik.toFixed(2),
                        'Salik % in Usage': salikformattedValue,
                        'Total Fine Amount': dfine.toFixed(2),
                        'Fine % in Usage': fineformattedValue,
                        'Total Reimburse Amount': dreimburse.toFixed(2),
                        'Reimburse % in Usage': reimburseformattedValue
                    };
                    items.push(a);
                });
        });
        // Add footer row
        let footer = {
            Department: 'Total',
            Vehicle: '',
            'Total Fuel Amount': totalfuel,
            'Fuel % in Usage': totalFuelUsage === 0 ? '0%' : `${((100 * totalfuel) / totalFuelUsage).toFixed(2)}%`,
            'Total Salik Amount': totalsaik,
            'Salik % in Usage': totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`,
            'Total Fine Amount': totalfine,
            'Fine % in Usage': totalFineUsage === 0 ? '0%' : `${((100 * totalfine) / totalFineUsage).toFixed(2)}%`,
            'Total Reimburse Amount': 'AED ' + totalReimbursement,
            'Reimburse % in Usage': totalReimbursementUsage === 0 ? '0%' : `${((100 * totalReimbursement) / totalReimbursementUsage).toFixed(2)}%`
        };

        items.push(footer);
        return items;
    };

    const csvLink = {
        filename:
            SelectedDate.length > 0 && startDateFormat && endDateFormat
                ? `Consumption_Vehicle_usage_${selectView}_${startDateFormat}-${endDateFormat}` + (vehicleid !== '' ? `_${vehicleid}` : '') + (filtervehicleid !== '' ? `_${filtervehicleid}` : '')
                : 'Comsumption_vehicle_usage' + `_${selectView}` + (vehicleid !== '' ? `_${vehicleid}` : '') + (filtervehicleid !== '' ? `_${filtervehicleid}` : '') + '.csv',
        header: header,
        data: oldformatdata.length > 0 ? exportdata(oldformatdata, selectView) : Reports
    };

    const csvDetailedLink = {
        filename:
            SelectedDate.length > 0 && startDateFormat && endDateFormat
                ? `Consumption_vehicle_usage_${selectView}_${startDateFormat}-${endDateFormat}` + (vehicleid !== '' ? `_${vehicleid}` : '') + (filtervehicleid !== '' ? `_${filtervehicleid}` : '')
                : 'Comsumption_vehicle_usage' + `_${selectView}` + (vehicleid !== '' ? `_${vehicleid}` : '') + (filtervehicleid !== '' ? `_${filtervehicleid}` : '') + '.csv',
        header: header,
        data: formatdata.length > 0 ? exportdetaileddata(formatdata, selectView) : Reports
    };

    const Commandbar = (
        <div className="p-toolbar p-component spd-removebg">
            <div className="p-toolbar-group-start p-toolbar-group-left">
                <Calendar
                    id="range"
                    className="dateFilter"
                    value={SelectedDate}
                    dateFormat="dd/m/yy"
                    onChange={(e: any) => {
                        updateURL(selectView, e.value[0], e.value[1], selectedVehicle);
                        setSelectedDate(e.value);
                    }}
                    selectionMode="range"
                    readOnlyInput
                    placeholder="Select date"
                />

                {ShowClrBtn}
            </div>
            <Dropdown
                value={selectView}
                style={{ width: '200px' }}
                placeholder="Select an option"
                className="mr-2"
                options={[
                    { label: 'Grouped by Department', value: 'Summary' },
                    { label: 'Show All Details', value: 'Details' }
                ]}
                onChange={(e) => {
                    updateURL(e.value, SelectedDate[0], SelectedDate[1], selectedVehicle);
                    setSelectView(e.value);
                }}
                optionLabel="label"
            />
            <Dropdown
                value={selectedVehicle}
                // style={{ width: '200px' }}
                placeholder="Vehicle"
                options={vehicles}
                onChange={(e) => {
                    updateURL(selectView, SelectedDate[0], SelectedDate[1], e.value);
                    setSelectedVehicle(e.value);
                }}
                optionLabel="label"
                filter
                showClear
            />
        </div>
    );
    const actionVehicleCount = (rowData: any) => {
        return <>{rowData.vehicles.items.length}</>;
    };
    const rowExpansionTemplate = (data: any) => {
        return (
            <div className="orders-subtable">
                <DataTable
                    value={data.vehicles.items}
                    responsiveLayout="scroll"
                    className="p-datatable-responsive usagetable"
                    header={
                        <div className="p-toolbar p-component">
                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                <h5>Details for {data.name}</h5>
                            </div>
                            {/* <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                <CSVLink {...csvLink} onClick={() => setExportType(data.name)}>
                                    <span className="p-float-label exporttext text-white">Export {data.name}</span>
                                </CSVLink>
                            </div> */}
                        </div>
                    }
                >
                    <Column field="id" className="text-left p-message p-message-warn" header="Vehicle" />
                    <Column field="fuel" className="text-right p-message p-message-warn" header="Total Fuel Amount" body={actionsubfloattemplate} />
                    <Column field="fuel" className="text-right p-message p-message-warn" header="Fuel % in Usage" body={subusagetemplate} />
                    <Column field="salik" className="text-right p-message p-message-warn" header="Total Salik Amount" body={actionsubfloattemplate} />
                    <Column field="salik" className="text-right p-message p-message-warn" header="Salik % in Usage" body={subusagetemplate} />
                    <Column field="fine" className="text-right p-message p-message-warn" header="Total Fine Amount" body={actionsubfloattemplate} />
                    <Column field="fine" className="text-right p-message p-message-warn" header="Fine % in Usage" body={subusagetemplate} />
                    <Column field="reimbursement" className="text-right p-message p-message-warn" header="Total Reimburse Amount" body={actionsubfloattemplate} />
                    <Column field="reimbursement" className="text-right p-message p-message-warn" header="Reimburse % in Usage" body={subusagetemplate} />
                </DataTable>
            </div>
        );
    };
    //set the values to the url
    const updateURL = (view: any, sdate: any, edate: any, vid: any) => {
        const baseUrl = window.location.href.split('?')[0];
        const queryParams = new URLSearchParams(window.location.search);

        if (sdate) {
            queryParams.set('sdate', convertdate(sdate));
        } else {
            queryParams.delete('sdate');
        }
        if (edate) {
            queryParams.set('edate', convertdate(edate));
        } else {
            queryParams.delete('edate');
        }
        if (view) {
            queryParams.set('view', view);
        } else {
            queryParams.delete('view');
        }
        if (vid) {
            queryParams.set('vid', vid);
        } else {
            queryParams.delete('vid');
        }

        const newUrl = baseUrl + '?' + queryParams.toString();
        window.history.replaceState({}, '', newUrl);
    };

    // const salikrowExpansionTemplate = (data: any) => {
    //     return (
    //         <div className="orders-subtable">
    //             <DataTable
    //                 value={data.vehicles.items}
    //                 responsiveLayout="scroll"
    //                 className="p-datatable-responsive usagetable"
    //                 header={
    //                     <div className="p-toolbar p-component">
    //                         <div className="p-toolbar-group-start p-toolbar-group-left">
    //                             <h5>Details for {data.name}</h5>
    //                         </div>
    //                         {/* <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
    //                             <CSVLink {...csvLink} onClick={() => setExportType(data.name)}>
    //                                 <span className="p-float-label exporttext text-white">Export</span>
    //                             </CSVLink>
    //                         </div> */}
    //                     </div>
    //                 }
    //             >
    //                 <Column field="id" className="text-left p-message p-message-success" header="Vehicle" />
    //                 <Column field="salik" className="text-right p-message p-message-success" header="Total Salik Amount" body={actionsubfloattemplate} />
    //                 <Column field="salik" className="text-right p-message p-message-success" header="% in Usage" body={subusagetemplate} />
    //             </DataTable>
    //         </div>
    //     );
    // };
    //memo is to load the table/component/data only when it is required
    const DataTableMemoized = memo(DataTable);
    return (
        <div className="grid ">
            <div className="col-12 md:col-12">
                {/* <h4 className="container-header">Vehicle Usage Report</h4> */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            {selectView === 'Summary' && (
                                <DataTable
                                    value={formatdata}
                                    loading={loading}
                                    // header={Commandbar}
                                    header={
                                        <div className="p-toolbar p-component p-0">
                                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                                <h5>Summary/Grouped Report</h5>
                                            </div>

                                            <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                                {Commandbar}
                                                <CSVLink {...csvLink} onClick={() => setExportType('Grouped')}>
                                                    <span className="p-float-label exporttext text-white">Export</span>
                                                </CSVLink>
                                            </div>
                                        </div>
                                    }
                                    className="p-datatable-responsive"
                                    responsiveLayout="scroll"
                                    rows={300}
                                    selection={selectedProduct}
                                    onSelectionChange={(e) => setSelectedProduct(e.value)}
                                    rowExpansionTemplate={rowExpansionTemplate}
                                    expandedRows={expandedRows}
                                    onRowToggle={(e) => setExpandedRows(e.data)}
                                >
                                    <Column expander style={{ width: '3em' }} />
                                    <Column field="name" className="text-left" headerStyle={{ minWidth: '90px' }} sortable header="Department" footer="Total" />
                                    <Column header="Total no. of Vehicles" className="text-center" headerStyle={{ minWidth: '110px' }} body={actionVehicleCount} />
                                    <Column field="fuel" className="text-right" headerStyle={{ minWidth: '110px' }} children={`${colors.Vipfuel}`} header="Total Fuel Amount" footer={'AED ' + totalfuel.toFixed(2)} body={actionfloattemplate} />
                                    <Column
                                        field="fuel"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        children={`${colors.Vipfuel}`}
                                        header="Fuel % in Usage"
                                        body={usagetemplate}
                                        footer={totalfuel === 0 ? '0%' : `${((100 * totalfuel) / totalfuel).toFixed(2)}%`}
                                    />
                                    <Column field="salik" className="text-right" headerStyle={{ minWidth: '110px' }} children={`${colors.Salik}`} header="Total Salik Amount" body={actionfloattemplate} footer={'AED ' + totalsaik} />
                                    <Column
                                        field="salik"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        children={`${colors.Salik}`}
                                        header="Salik % in Usage"
                                        body={usagetemplate}
                                        footer={totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`}
                                    />
                                    <Column field="fine" className="text-right" headerStyle={{ minWidth: '110px' }} children={`${colors.Fine}`} header="Total Fine Amount" body={actionfloattemplate} footer={'AED ' + totalfine} />
                                    <Column
                                        field="fine"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        children={`${colors.Fine}`}
                                        header="Fine % in Usage"
                                        body={usagetemplate}
                                        footer={totalFineUsage === 0 ? '0%' : `${((100 * totalfine) / totalFineUsage).toFixed(2)}%`}
                                    />
                                    <Column
                                        field="reimbursement"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        children={`${colors.CashReimbursement}`}
                                        header="Total Reimburse Amount"
                                        body={actionfloattemplate}
                                        footer={'AED ' + totalReimbursement}
                                    />
                                    <Column
                                        field="reimbursement"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        children={`${colors.CashReimbursement}`}
                                        header="Reimburse % in Usage"
                                        body={usagetemplate}
                                        footer={totalReimbursementUsage === 0 ? '0%' : `${((100 * totalReimbursement) / totalReimbursementUsage).toFixed(2)}%`}
                                    />
                                </DataTable>
                            )}

                            {selectView === 'Details' && (
                                <DataTableMemoized
                                    value={transformedData}
                                    loading={loading}
                                    className="p-datatable-responsive"
                                    header={
                                        <div className="p-toolbar p-component p-0">
                                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                                <h5>All Details Report</h5>
                                            </div>
                                            <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                                {/* <CSVLink {...csvLink} onClick={() => setExportType('report')}>
                                                <span className="p-float-label exporttext text-white">Export</span>
                                            </CSVLink> */}
                                                {Commandbar}
                                                <CSVLink {...csvDetailedLink} onClick={() => setExportType('All_Details')}>
                                                    <span className="p-float-label exporttext text-white">Export Detailed Data</span>
                                                </CSVLink>
                                            </div>
                                        </div>
                                    }
                                    // rowGroupFooterTemplate={footerTemplate}
                                    responsiveLayout="scroll"
                                    scrollable
                                    scrollHeight="80vh"
                                >
                                    <Column field="department" className="text-left" header="Department" footer="Total" sortable />
                                    <Column field="vehicle" className="text-center" header="Vehicle" sortable />
                                    <Column
                                        field="totalFuelAmount"
                                        className="text-right"
                                        headerStyle={{ minWidth: '110px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Vipfuel }}
                                        header="Total Fuel Amount"
                                        footer={'AED ' + totalfuel.toFixed(2)}
                                        sortable
                                    />
                                    <Column
                                        field="fuelPercentage"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Vipfuel }}
                                        header="Fuel % in usage"
                                        footer={totalfuel === 0 ? '0%' : `${((100 * totalfuel) / totalfuel).toFixed(2)}%`}
                                    />
                                    <Column
                                        field="totalSalikAmount"
                                        className="text-right"
                                        headerStyle={{ minWidth: '110px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Salik }}
                                        header="Total Salik Amount"
                                        footer={'AED ' + totalsaik}
                                        sortable
                                    />
                                    <Column
                                        field="salikPercentage"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Salik }}
                                        header="Salik % in usage"
                                        footer={totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`}
                                    />
                                    <Column
                                        field="totalFineAmount"
                                        className="text-right"
                                        headerStyle={{ minWidth: '110px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Fine }}
                                        header="Total Fine Amount"
                                        footer={'AED ' + totalfine}
                                        sortable
                                    />
                                    <Column
                                        field="finePercentage"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.Fine }}
                                        header="Fine % in usage"
                                        footer={totalFineUsage === 0 ? '0%' : `${((100 * totalfine) / totalFineUsage).toFixed(2)}%`}
                                    />
                                    <Column
                                        field="totalReimburseAmount"
                                        className="text-right"
                                        headerStyle={{ minWidth: '110px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.CashReimbursement }}
                                        header="Total Reimburse Amount"
                                        footer={'AED ' + totalReimbursement}
                                        sortable
                                    />
                                    <Column
                                        field="reimbursePercentage"
                                        className="text-right"
                                        headerStyle={{ minWidth: '100px' }}
                                        bodyClassName="p-button m-2"
                                        headerClassName="mr-2"
                                        style={{ justifyContent: 'flex-end', color: '#000' }}
                                        bodyStyle={{ backgroundColor: colors.CashReimbursement }}
                                        header="Reimburse % in usage"
                                        footer={totalReimbursementUsage === 0 ? '0%' : `${((100 * totalReimbursement) / totalReimbursementUsage).toFixed(2)}%`}
                                    />

                                    {/* <Column field="name" className="text-left" header="Department" footer="Total" />
                                <Column field="vehicle" className="text-left" header="Vehicle" footer="" />
                                <Column field="fuel" className="text-right" header="Total Fuel Amount" footer={'AED ' + totalfuel.toFixed(2)} body={actionfloattemplate} />
                                <Column field="fuel" className="text-right" header="Fuel % in Usage" body={usagetemplate} footer={totalfuel === 0 ? '0%' : `${((100 * totalfuel) / totalfuel).toFixed(2)}%`} />
                                <Column field="salik" className="text-right" header="Total Salik Amount" body={actionfloattemplate} footer={'AED ' + totalsaik} />
                                <Column field="salik" className="text-right" header="Salik % in Usage" body={usagetemplate} footer={totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`} /> */}
                                </DataTableMemoized>
                            )}
                        </div>
                    </div>
                    {/* <div className="col-12 md:col-6">
                        <div className="card">
                            <DataTable
                                value={formatdata}
                                loading={loading}
                                // header={Commandbar}

                                header={
                                    <div className="p-toolbar p-component">
                                        <div className="p-toolbar-group-start p-toolbar-group-left">
                                            <h5>Salik</h5>
                                        </div>
                                        <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                            <CSVLink {...csvLink} onClick={() => setExportType('salik')}>
                                                <span className="p-float-label exporttext text-white">Export</span>
                                            </CSVLink>
                                        </div>
                                    </div>
                                }
                                className="p-datatable-responsive"
                                responsiveLayout="scroll"
                                rows={300}
                                selection={selectedProduct}
                                onSelectionChange={(e) => setSelectedProduct(e.value)}
                                rowExpansionTemplate={salikrowExpansionTemplate}
                                expandedRows={salikexpandedRows}
                                onRowToggle={(e) => setSalikExpandedRows(e.data)}
                            >
                                <Column expander style={{ width: '3em' }} />
                                <Column field="name" className="text-left" sortable header="Department" footer="Total" />
                                <Column header="Total no. of Vehicles" body={actionVehicleCount} />
                                <Column field="salik" className="text-right" header="Total Salik Amount" body={actionfloattemplate} footer={'AED ' + totalsaik} />
                                <Column field="salik" className="text-right" header="% in Usage" body={usagetemplate} footer={totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`} />
                            </DataTable>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default VehicleUsage;
