import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createDxVendors, deleteDxVendors, updateDxVendors } from '../../graphql/mutations';
import { listDxVendors } from '../../graphql/queries';
import ListnewForm from '../../Components/ListnewForm';

const Vendoreditform = () => {
    const [VendorsOptions, setVendorsOptions] = useState<any>([]);
    const location1 = useLocation();
    const urlParams1 = new URLSearchParams(location1.search);
    var formid = urlParams1.has('id') ? urlParams1.get('id') : null;
    const navigate = useNavigate();
    useEffect(() => {
        // getData();
    }, []);

    // const getData = async () => {
    //     await AmplifyServices.getTableDetails(listDxFleets, 'listDxFleets').then(async (res: any) => {
    //         let Department = res.filter((dept: any) => dept.fleettype.includes(formColumns.fleettype));
    //         setVendorsOptions(Department);
    //     });
    // };

    const DepartmentNameOption: any = [];
    const names = VendorsOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        // console.log(res);
        DepartmentNameOption.push(res);
    });

    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                name: 'company', // pass the exact column name of db
                value: 'Company',
                fieldtype: 'text',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'contactno', // pass the exact column name of db
                value: 'Mobile No.', //for display
                fieldtype: 'number',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'email', // pass the exact column name of db
                value: 'Email', //for display
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'garagelocation', // pass the exact column name of db
                value: 'Garage Location',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'officelocation', // pass the exact column name of db
                value: 'Office Location',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'vendorname', // pass the exact column name of db
                value: 'Vendor Name',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'vendortype', // pass the exact column name of db
                value: 'Vendor Type',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: [
                    { label: 'Manufacturers', value: 'Manufacturers' },
                    { label: 'Wholesalers', value: 'Wholesalers' },
                    { label: 'Retailers', value: 'Retailers' },
                    { label: 'Service and maintenance providers', value: 'Service and maintenance providers' },
                    { label: 'Independent vendors', value: 'Independent vendors' }
                ]
            }
        ],
        getTable: listDxVendors,
        createTable: createDxVendors, //Pass create table name
        updateTable: updateDxVendors, //Pass update table name
        deleteTableItem: deleteDxVendors //Pass update table name
    };

    const submitForm = async (data: any) => {
        if (Object.keys(data).includes('_lastChangedAt')) delete data['_lastChangedAt'];
        let os = await API.graphql(
            graphqlOperation(formColumns.updateTable, {
                input: data
            })
        );
        let navigateUrl = '/manage/vendors/all';
        navigate(navigateUrl);
    };

    return (
        <>
            <h4 className="container-header">Edit Vendor : {formid}</h4>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <ListnewForm columns={formColumns} layout="horizontal" getTableName="listDxVendors" title="" submitForm={submitForm} />
                </div>
            </div>
        </>
    );
};
export default Vendoreditform;
