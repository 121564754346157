import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { RTLContext } from "../../App";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "primereact/chart";
import "./DashBoard.css";
import dxService from "../../Services/dxService";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { listDxContracts } from "../../graphql/queries";
import { getNextQueryData } from "../../utils/helper";
import DashboardExpenses from "./Expenses/Expenses";
import DashboardConsumptions from "./Consumption/DashboardConsumption";
import { getTypeColors } from "../../utils/color";
const colors = getTypeColors("dark");
let doughnutOption: object;
let barChartOptions: object;
var AccidentArray: any = [];
var IncidentFineArray: any = [];
var VehicleStatusArray: any = [];
let storevalue: any;
let StoreAccident: any;
let StoreFine: any;
let storekey: any;
const today = new Date(); // get today's date
const next15days = new Date(today.getTime() + 16 * 24 * 60 * 60 * 1000);
const next7days = new Date(today.getTime() + 8 * 24 * 60 * 60 * 1000);
var targetDate = new Date(today.getTime());
targetDate.setMonth(targetDate.getMonth() - 4);
/**passing data to the Incidents bar graph */
const getBarData = async (colorMode: string) => {
  const colors = await getTypeColors("dark");

  const currentMonths: any = new Date().getMonth();
  const pastMonthNames = [];
  for (let i = 0; i <= 5; i++) {
    const monthIndex = (currentMonths - i + 12) % 12;
    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(new Date(0, monthIndex));
    pastMonthNames.push(monthName);
  }
  return {
    labels: pastMonthNames.reverse(),
    datasets: [
      {
        label: "Fines",
        backgroundColor: colors.Fine,
        borderColor: colors.Fine,
        data: IncidentFineArray,
      },
      {
        label: "Accidents",
        backgroundColor: colors.Accidents,
        borderColor: colors.Accidents,
        data: AccidentArray,
      },
    ],
  };
};
/**passing data to the Vehicles doughnut chart */
const getDoughnutChartData = async (colorMode: string) => {
  const colors = await getTypeColors("dark");
  const borderColor =
    getComputedStyle(document.body).getPropertyValue("--divider-color") ||
    "rgba(160, 167, 181, .3)";

  return {
    datasets: [
      {
        // data: [],
        data: VehicleStatusArray,
        backgroundColor: [
          colors.ActiveVehicle,
          colors.GarageVehicle,
          colors.InactiveVehicle,
        ],
        borderColor,
        label: "Dataset",
      },
    ],
    labels: ["Active", "At Garage", "In Active"],
  };
};
/**properties  for bar graph */
const getBarChartOptions = () => {
  const textColor =
    getComputedStyle(document.body).getPropertyValue("--text-color") ||
    "rgba(0, 0, 0, 0.87)";
  const gridLinesColor =
    getComputedStyle(document.body).getPropertyValue("--divider-color") ||
    "rgba(160, 167, 181, .3)";
  const fontFamily = getComputedStyle(document.body).getPropertyValue(
    "--font-family"
  );

  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          fontFamily,
          color: textColor,
        },
      },
      datalabels: {
        display: true,
        color: "black",
        anchor: "end",
        clamp: true,
        align: "end",
        // offset: -20,

        font: {
          size: "12",
          weight: "700",
        },
      },
    },

    scales: {
      x: {
        ticks: {
          fontFamily,
          color: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
      y: {
        ticks: {
          fontFamily,
          color: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
    },
  };
};
/**properties for doughnut chart */
const getDoughnutChartOptions = () => {
  const textColor =
    getComputedStyle(document.body).getPropertyValue("--text-color") ||
    "rgba(0, 0, 0, 0.87)";
  const fontFamily = getComputedStyle(document.body).getPropertyValue(
    "--font-family"
  );
  return {
    plugins: {
      legend: {
        display: true,
        labels: {
          fontFamily,
          color: textColor,
        },
      },
      datalabels: {
        display: true,
        color: "black",
        // anchor: 'start',
        clamp: true,
        // align: 'start',
        // // offset: 20,

        font: {
          size: "13",
        },
      },
    },
  };
};

const DashBoard = (props: any) => {
  const [products, setProducts] = useState<any>(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [barData, setBarData] = useState<any>(getBarData(props.colorMode));

  const [doughnutdata, setdoughnutData] = useState<any>(
    getDoughnutChartData(props.colorMode)
  );
  const [exprenewals, setexprenewals] = useState<any>([]);
  const [uprenewals, setuprenewals] = useState<any>([]);
  const [Fine, setfine] = useState<any>([]);
  const [Accidents, setAccidents] = useState<any>([]);
  const [brandingcount, setBrandingcount] = useState<any>([]);
  const [parkingcount, setParkingcount] = useState<any>([]);
  const isRTL = useContext(RTLContext);
  const navigate = useNavigate();

  const updateCharts = () => {
    getvehicleStatus().then(async (res: any) => {
      setdoughnutData(await getDoughnutChartData(props.colorMode));
    });

    doughnutOption = getDoughnutChartOptions();
    barChartOptions = getBarChartOptions();
  };

  useEffect(() => {
    getFineLogsData().then((fine) => {
      getAccidentLogsData().then((accident) => {
        getIncidentsitems(fine, accident).then(async (res: any) => {
          setBarData(await getBarData(props.colorMode));
        });
      });
    });
  }, []);
  /**
   * getExpiredrenewals() fetch the expired items from the renewal table
   */
  const getExpiredrenewals = async (nextToken?: any) => {
    let variables: any = {
      filter: {
        and: [
          { expirydate: { le: new Date() } },
          {
            expirydate: { ne: new Date(1970, 0, 1) },
            renewalstatus: { ne: "Completed" },
          },
        ],
      },
    };
    if (nextToken) {
      variables.nextToken = nextToken;
    }

    let GetResponse: any = await API.graphql({
      query: listDxContracts,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: variables,
    });

    let res = GetResponse.data.listDxContracts.items;
    if (GetResponse.data.listDxContracts.nextToken) {
      const nextResults: any = await getExpiredrenewals(
        GetResponse.data.listDxContracts.nextToken
      );

      res = res.concat(nextResults);
    }
    res = res.filter(
      (item: any) => item._deleted !== true && item.renewaltype !== "Warranty"
    );
    if (res.length > 0) {
      setexprenewals(res);
    }
    return res;
  };
  /**
   * getUpcomingrenewals() fetch the upcomming items from the renewal table
   */
  const getUpcomingrenewals = async (nextToken?: any) => {
    let collectUpcommingarray: any = [];
    let variables: any = {
      filter: {
        and: [
          { expirydate: { gt: new Date() } },
          { expirydate: { le: next15days } },
        ],
      },
    };
    if (nextToken) {
      variables.nextToken = nextToken;
    }

    let GetResponse: any = await API.graphql({
      query: listDxContracts,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: variables,
    });

    let res = GetResponse.data.listDxContracts.items;
    if (GetResponse.data.listDxContracts.nextToken) {
      const nextResults: any = await getUpcomingrenewals(
        GetResponse.data.listDxContracts.nextToken
      );

      res = res.concat(nextResults);
    }
    res = res.filter((item: any) => {
      if (item._deleted !== true) {
        if (
          item.renewaltype == "Registration" &&
          moment(item.expirydate).isBetween(new Date(), next15days) &&
          item.renewalstatus !== "Completed"
        ) {
          collectUpcommingarray.push(item);
          return true;
        }
        if (
          item.renewaltype == "Insurance" &&
          moment(item.expirydate).isBetween(new Date(), next15days) &&
          item.renewalstatus !== "Completed"
        ) {
          collectUpcommingarray.push(item);
          return true;
        }
        if (
          item.renewaltype == "ServiceContract" &&
          moment(item.expirydate).isBetween(new Date(), next15days) &&
          item.renewalstatus !== "Completed"
        ) {
          collectUpcommingarray.push(item);
          return true;
        }
        if (
          item.renewaltype == "Parking" &&
          moment(item.expirydate).isBetween(new Date(), next7days) &&
          item.renewalstatus !== "Completed"
        ) {
          collectUpcommingarray.push(item);
          return true;
        }
        if (
          item.renewaltype == "Branding" &&
          moment(item.expirydate).isBetween(new Date(), next7days) &&
          item.renewalstatus !== "Completed"
        ) {
          collectUpcommingarray.push(item);
          return true;
        }
      }
    });

    collectUpcommingarray = res;
    if (collectUpcommingarray.length > 0) {
      setuprenewals(collectUpcommingarray);
    }
    return res;
  };
  /**
   * BrandingandParkingrenewals() fetch the branding and parking items with Active status from the renewal table
   */
  const BrandingandParkingrenewals = async () => {
    let brandingfilter: any = {
      renewaltype: {
        eq: "Branding",
      },
      renewalstatus: {
        eq: "Active",
      },
    };
    let fetchbranding = await getNextQueryData(
      "",
      brandingfilter,
      "renewal"
    ).then((item: any) => {
      setBrandingcount(item);
    });
    let parkingfilter: any = {
      renewaltype: {
        eq: "Parking",
      },
      renewalstatus: {
        eq: "Active",
      },
    };
    let fetchparking = await getNextQueryData(
      "",
      parkingfilter,
      "renewal"
    ).then((item: any) => {
      setParkingcount(item);
    });
  };

  const getapprovalitems = async () => {
    let filter = {
      status: {
        eq: "Not Started",
      },
    };
    let a: [] = await dxService.listitems("approval", "*", filter);
    let b: any = [];
    a.forEach((ele: any) => {
      let res = JSON.parse(ele.resource);
      let resvehicle = res.vehicle;
      let users = JSON.parse(res.userinfo);

      let c: any = {
        id: ele.id,
        Platenumber: resvehicle.id,
        ModelName: resvehicle.model,
        name: res.eventtype,
        description: res.description,
        Approval: "bamboo-watch.jpg",
        price: res.cost,
        appid: res.id,
        //"RequestorName": res.owner,
        RequestorName: users["owner"].displayname,
        quantity: 0,
        Priority: "Urgent",
        Pendingfor: moment(res.createdAt).format("DD-MMM-YYYY"),
      };
      b.push(c);
    });
    setProducts(b);
  };
  //fetch the item which contains fine from db
  const getFineLogsData = async (nextToken?: any) => {
    let variables: any = {
      Fine: { attributeExists: true },
      // and: [{ date: { ge: localetoisodatetime(new Date(lastMonthStartDate)) } }, { date: { le: localetoisodatetime(new Date(lastMonthEndDate)) } }]
    };
    const ac = `Fine date vehicleid  _deleted`;
    let Finedata = await dxService.listitems(
      "masterlog",
      ac,
      variables,
      true,
      1000,
      nextToken
    );
    let response = Finedata.items;
    setfine((prevItems: any) => [...prevItems, ...response]);
    let nexttoken = Finedata.nextToken;
    if (nexttoken) {
      response = [...response, ...(await getFineLogsData(nexttoken))];

      // response = response.concat(nextResults);
    }
    response = response.filter((item: any) => item._deleted !== true);
    return response;
  };

  //fetch the item which contains accidents from db
  const getAccidentLogsData = async (nextToken?: any) => {
    let variables: any = {
      Accidents: { attributeExists: true },
    };
    const ac = `Accidents date vehicleid  _deleted`;
    let Accidentdata = await dxService.listitems(
      "masterlog",
      ac,
      variables,
      true,
      1000,
      nextToken
    );
    let response = Accidentdata.items;
    setAccidents((prevItems: any) => [...prevItems, ...response]);
    let nexttoken = Accidentdata.nextToken;
    if (nexttoken) {
      response = [...response, ...(await getAccidentLogsData(nexttoken))];

      // response = response.concat(nextResults);
    }
    response = response.filter((item: any) => item._deleted !== true);
    return response;
  };
  const getIncidentsitems = async (Finearray: any, Accidentarray: any) => {
    IncidentFineArray = [];
    AccidentArray = [];
    const currentMonths = new Date().getMonth();
    const last6Months: any = [];

    const year = new Date().getFullYear();

    for (let i = 0; i <= 5; i++) {
      const monthIndex = (currentMonths - i + 12) % 12;
      const yearOffset = currentMonths - i < 0 ? -1 : 0; // Check if previous year is involved
      const monthName: any = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(new Date(year + yearOffset, monthIndex));

      last6Months.push(monthName);
    }
    // last6Months.reverse();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const sortedMonthslast6Months = last6Months.sort((a: any, b: any) => {
      const [monthA, yearA] = a.split(" ");
      const [monthB, yearB] = b.split(" ");

      const monthIndexA = months.indexOf(monthA);
      const monthIndexB = months.indexOf(monthB);

      if (yearA === yearB) {
        return monthIndexA - monthIndexB;
      } else {
        return yearA.localeCompare(yearB);
      }
    });

    // group AccidentArray
    // let months = ['January', 'February', 'March', 'April', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let currentMonth: any = new Date().getMonth();
    let last6Month = months
      .slice(currentMonth - 6)
      .concat(months.slice(0, currentMonth));

    var accident = Accidentarray.filter((acc: any) => acc.Accidents !== null);
    accident.map((custom: any) => {
      custom["CustomMonth"] = moment(custom.date).format("MMMM YYYY");
    });
    var Fines = Finearray.filter(
      (acc: any) => acc !== undefined && acc.Fine !== null && acc.Fine !== ""
    );
    Fines.map((custom: any) => {
      custom["CustomMonth"] = moment(custom.date).format("MMMM YYYY");
    });

    var groupByAccident = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupByAccidentMonthvalue: any = groupByAccident(
      accident,
      "CustomMonth"
    );
    let reformatedata: any = [];
    let finaldata: any = [];

    for (const [key, value] of Object.entries(GroupByAccidentMonthvalue)) {
      storevalue = value;
      storekey = key;
      sortedMonthslast6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: storevalue.length, key: storekey });
        }
      });
    }
    reformatedata.map((mon: any) => {
      sortedMonthslast6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    let accidentarr: any = 0;
    for (const [key, value] of Object.entries(groupByMontharray)) {
      StoreAccident = value;

      var sum = 0;
      for (var i = 0; i < StoreAccident.length; i++) {
        sum += StoreAccident[i].value;
      }
      AccidentArray.push(sum);
    }

    //group Fines Array

    var groupByFine = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByFineMonthvalue: any = groupByFine(Fines, "CustomMonth");

    let reformateFinedata: any = [];
    let finalFinedata: any = [];
    for (const [key, value] of Object.entries(groupByFineMonthvalue)) {
      storevalue = value;
      storekey = key;
      sortedMonthslast6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformateFinedata.push({ value: storevalue.length, key: storekey });
        }
      });
    }

    reformateFinedata.map((mon: any) => {
      sortedMonthslast6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finalFinedata.push({ value: mon.value, key: mon.key });
        } else {
          finalFinedata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByFineMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByFineMontharray: any = groupByFineMonth(finalFinedata, "key");

    for (const [key, value] of Object.entries(groupByFineMontharray)) {
      StoreFine = value;
      var Finesum = 0;
      for (var i = 0; i < StoreFine.length; i++) {
        Finesum += StoreFine[i].value;
      }
      IncidentFineArray.push(Finesum);
    }
  };
  const getvehicleStatus = async () => {
    VehicleStatusArray = [];
    let garagestatus: any = [];
    let activestatus: any = [];
    let Inactivestatus: any = [];
    let filteractivevehicle: any = [];
    let Garage: any = await getNextQueryData(
      "",
      {},
      "vehicleactivity",
      true
    ).then((item: any) => {
      filteractivevehicle = item.filter(
        (res: any) => res.vehicle !== null && res.vehicle.status === "Active"
      );
    });
    // dxService.listitems('vehicleactivity', '*');
    let arr: any = [];
    let gstatus = filteractivevehicle.map((item: any) => {
      let result = false;
      const today = moment().startOf("day");
      const events = item.events;
      const parsedEvents = events ? JSON.parse(events) : false;
      const vstatus = item.dxDriverVehicleactivityId;
      //to get the garage vehicle data
      if (parsedEvents) {
        for (const eventKey in parsedEvents) {
          const event = parsedEvents[eventKey];

          if (event.appointmentstatus === "Scheduled") {
            garagestatus.push(item);
            if (item.dxDriverVehicleactivityId !== null) {
              arr.push(item);
            }
          }
        }
      }
      //to get the inactive vehicle data
      if (!vstatus) {
        Inactivestatus.push(item);
      }
    });
    //to get the active vehicle length
    let totalvehicle = filteractivevehicle.filter(
      (res: any) => res._deleted !== true
    );
    let vcount = totalvehicle.length;
    let IGcount = Inactivestatus.length + garagestatus.length;
    let difference = vcount - IGcount;

    VehicleStatusArray.push(difference);
    VehicleStatusArray.push(garagestatus.length);
    VehicleStatusArray.push(Inactivestatus.length);
  };
  useEffect(() => {
    updateCharts();

    getapprovalitems();
    getExpiredrenewals();
    getUpcomingrenewals();
    BrandingandParkingrenewals();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (props.isNewThemeLoaded) {
      updateCharts();
      props.onNewThemeChange(false);
    }
  }, [props.isNewThemeLoaded, props.onNewThemeChange]);

  const ApprovalTemplate = (data: any, column: any) => {
    // var src = 'assets/demo/images/product/' + rowData.image;
    // return <img src={src} alt={rowData.brand} width="50px" className="shadow-4" />;
    return (
      <>
        <a
          href={`#/manage/appointments/item/basic?mode=view&id=${data.appid}&vid=${data.Platenumber}`}
          target="_self"
        >
          {data.name}
        </a>
      </>
    );
  };

  const actionTemplateApprove = (rowData: any, column: any) => {
    return (
      <>
        <Button
          label="Act Now"
          className="p-button-success "
          onClick={(e) =>
            navigate("/manage/approvals/approve?id=" + rowData.id)
          }
        />
      </>
    );
  };
  const priceBodyTemplate = (data: any) => {
    const totalPrice: any = parseFloat(data.price);

    return (
      <>
        <span className="p-column-title">{`AED ${totalPrice.toFixed(2)}`}</span>
        <span className="font-bold">{`AED ${totalPrice.toFixed(2)}`}</span>
        {/* {formatCurrency(data.price)} */}
      </>
    );
  };

  const bodyTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{data.description}</span>
        {data.description}
      </>
    );
  };
  const PlatenumberBodyTemplate = (data: any, props: any) => {
    return (
      <>
        <a
          href={`#/manage/vehicles/item/basic?mode=view&id=${data.Platenumber}`}
          className={`customer-badge status-${data.Platenumber}`}
          target="_self"
        >
          {data.Platenumber}
        </a>
      </>
    );
  };
  const ModelNameBodyTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{data.ModelName}</span>
        {data.ModelName}
      </>
    );
  };
  const RequestorNameBodyTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{data.RequestorName}</span>
        {data.RequestorName}
      </>
    );
  };
  const PendingforBodyTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{data.Pendingfor}</span>
        {data.Pendingfor}
      </>
    );
  };

  return (
    <div>
      <div className="grid dashboard">
        <div className="col-12 md:col-12 lg:col-12">
          <div className="card height-100">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div>
                <h5>Approvals</h5>
              </div>
              <div>
                <a
                  href="#/manage/approvals/all"
                  style={{
                    color: "#1884b3",
                    borderBottom: "1px solid #1884b3",
                  }}
                >
                  View all
                </a>
              </div>
            </div>

            <div className="approvaltable">
              <DataTable
                value={products}
                className="p-datatable-gridlines"
                responsiveLayout="scroll"
                rows={10}
                selection={selectedProduct}
                onSelectionChange={(e) => setSelectedProduct(e.value)}
              >
                <Column
                  field="Platenumber"
                  body={PlatenumberBodyTemplate}
                  header="Vehicle"
                  headerClassName="headerWidth"
                  headerStyle={{ minWidth: "6rem" }}
                />
                <Column
                  field="ModelName "
                  body={ModelNameBodyTemplate}
                  header="Model Name"
                  headerStyle={{ minWidth: "6rem" }}
                />
                <Column
                  field="name"
                  body={ApprovalTemplate}
                  header="Approval Type"
                  headerStyle={{ minWidth: "6rem" }}
                />
                <Column
                  field="Pendingfor"
                  body={PendingforBodyTemplate}
                  header="Pending from"
                  headerStyle={{ minWidth: "6rem" }}
                />
                <Column
                  field="description"
                  body={bodyTemplate}
                  header="Description"
                  headerStyle={{ minWidth: "6rem" }}
                />
                <Column
                  field="RequestorName "
                  body={RequestorNameBodyTemplate}
                  header="Requestor Name"
                  headerStyle={{ minWidth: "6rem" }}
                />
                {/* <Column field="Priority" body={PriorityBodyTemplate} header="Priority" headerStyle={{ minWidth: '6rem' }} /> */}

                <Column
                  field="price"
                  body={priceBodyTemplate}
                  header="Price"
                  headerStyle={{ minWidth: "6rem" }}
                />
                <Column
                  body={actionTemplateApprove}
                  style={{ width: "8rem" }}
                />
                {/* <Column body={actionTemplateReject} style={{ width: '4rem' }} /> */}
              </DataTable>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-12 lg:col-12" style={{ display: "flex" }}>
          <div className="col-12 md:col-12  lg:col-4">
            <div className=" height-100 card">
              {/* <div className="card" style={{height:"100%"}}> */}
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="pb-2"
              >
                <div>
                  <h5>Renewals</h5>
                </div>
                <div>
                  <a
                    href="/#/general/document?type=upcoming"
                    style={{
                      color: "#1884b3",
                      borderBottom: "1px solid #1884b3",
                    }}
                  >
                    View all
                  </a>
                </div>
              </div>
              <div className="grid">
                <div className="col-6 md:col-6 lg:col-6">
                  <div
                    className=" overview-box flex flex-column pt-2 pb-5"
                    style={{
                      //  height: '125px' ,
                      paddingLeft: "1rem",
                      border: "1px solid #eee",
                    }}
                  >
                    <div className="flex align-items-center muted-text">
                      {/* <i className="pi pi-shopping-cart"></i> */}
                      <h6
                        className={classNames("mt-4 pb-4")}
                        style={{ fontSize: "15px" }}
                      >
                        Upcoming Renewals
                      </h6>
                      <div
                        className={classNames({
                          "ml-auto": !isRTL,
                          "mr-auto": isRTL,
                        })}
                      ></div>
                    </div>
                    <div className="flex justify-content-between mt-0 flex-wrap">
                      <div
                        className="flex flex-column"
                        // style={{ width: '80px' }}
                      >
                        {/* <span className="mb-1 fs-xlarge">7</span> */}
                        <Button
                          type="button"
                          className="p-button-rounded p-button-text  overview-status p-1 teal-bgcolor fs-xlarge"
                          onClick={() =>
                            navigate("/general/document?type=upcoming")
                          }
                        >
                          {uprenewals ? uprenewals.length.toString() : ""}
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 md:col-6 lg:col-6">
                  <div
                    className="overview-box flex flex-column pt-2 pb-5"
                    style={{
                      // height: '125px',
                      paddingLeft: "1rem",
                      border: "1px solid #eee",
                    }}
                  >
                    <div className="flex align-items-center muted-text">
                      {/* <i className="pi pi-shopping-cart"></i> */}
                      <h6
                        className={classNames("mt-4 pb-4")}
                        style={{ fontSize: "15px" }}
                      >
                        Expired Renewals
                      </h6>
                      <div
                        className={classNames({
                          "ml-auto": !isRTL,
                          "mr-auto": isRTL,
                        })}
                      ></div>
                    </div>
                    <div className="flex justify-content-between mt-0 flex-wrap">
                      <div
                        className="flex flex-column"
                        //  style={{ width: '80px' }}
                      >
                        <Button
                          type="button"
                          className="p-button-rounded p-button-text  overview-status p-1 teal-bgcolor fs-xlarge"
                          onClick={() =>
                            navigate("/general/document?type=expired")
                          }
                        >
                          {exprenewals ? exprenewals.length.toString() : ""}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 md:col-6 lg:col-6">
                  <div
                    className=" overview-box flex flex-column pt-2 pb-5"
                    style={{
                      // height: '125px',
                      paddingLeft: "1rem",
                      border: "1px solid #eee",
                    }}
                  >
                    <div className="flex align-items-center muted-text">
                      {/* <i className="pi pi-shopping-cart"></i> */}
                      <h6
                        className={classNames("mt-4  pb-4")}
                        style={{ fontSize: "15px" }}
                      >
                        Branding
                      </h6>
                      <div
                        className={classNames({
                          "ml-auto": !isRTL,
                          "mr-auto": isRTL,
                        })}
                      ></div>
                    </div>
                    <div className="flex justify-content-between mt-0 flex-wrap">
                      <div
                        className="flex flex-column"
                        //  style={{ width: '80px' }}
                      >
                        {/* <span className="mb-1 fs-xlarge">2</span> */}
                        {/* <span className="overview-status p-1 teal-bgcolor fs-xlarge">{brandingcount ? brandingcount.length.toString() : ''}</span> */}
                        <Button
                          type="button"
                          className="p-button-rounded p-button-text  overview-status p-1 teal-bgcolor fs-xlarge"
                          onClick={() =>
                            navigate("/general/document?Rtype=Branding")
                          }
                        >
                          {brandingcount ? brandingcount.length.toString() : ""}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 md:col-6 lg:col-6">
                  <div
                    className=" overview-box flex flex-column pt-2 pb-5"
                    style={{
                      paddingLeft: "1rem",
                      border: "1px solid #eee",
                    }}
                  >
                    <div className="flex align-items-center muted-text">
                      <h6
                        className={classNames("mt-4  pb-4")}
                        style={{ fontSize: "15px" }}
                      >
                        Parking
                      </h6>
                      <div
                        className={classNames({
                          "ml-auto": !isRTL,
                          "mr-auto": isRTL,
                        })}
                      ></div>
                    </div>
                    <div className="flex justify-content-between mt-0 flex-wrap">
                      <div className="flex flex-column">
                        <Button
                          type="button"
                          className="p-button-rounded p-button-text  overview-status p-1 teal-bgcolor fs-xlarge"
                          onClick={() =>
                            navigate("/general/document?Rtype=Parking")
                          }
                        >
                          {parkingcount ? parkingcount.length.toString() : ""}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>

          <div className="col-12 md:col-12  lg:col-4">
            <div className=" height-100 card">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h5 className="centerText">Vehicles</h5>
                </div>
                <div>
                  <a
                    href="#/general/workspace"
                    style={{
                      color: "#1884b3",
                      borderBottom: "1px solid #1884b3",
                    }}
                  >
                    View all
                  </a>
                </div>
              </div>
              {VehicleStatusArray.length > 0 ? (
                <div className="flex justify-content-center chartWidth">
                  <Chart
                    style={{ position: "relative", width: "50%" }}
                    plugins={[ChartDataLabels]}
                    type="doughnut"
                    data={doughnutdata}
                    options={doughnutOption}
                  ></Chart>
                </div>
              ) : (
                <div className="external ">
                  <div className="middle ">
                    <div className="internal "> No Data available</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <DashboardExpenses />
        </div>
      </div>
      <div className="grid">
        <div className="col-12 lg:col-4">
          <div className="card height-100">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h5 className="centerText">Incidents</h5>
              </div>
              <div>
                <a
                  href="#/general/workspace"
                  style={{
                    color: "#1884b3",
                    borderBottom: "1px solid #1884b3",
                  }}
                >
                  View all
                </a>
              </div>
            </div>

            <Chart
              type="bar"
              data={barData}
              plugins={[ChartDataLabels]}
              options={barChartOptions}
            ></Chart>
          </div>
        </div>
        <DashboardConsumptions trojan={props} />
      </div>
    </div>
  );
};

export default DashBoard;
