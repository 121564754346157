import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createDxDocument, createDxVehicleSpares, deleteDxVehicleSpares, updateDxVehicleSpares } from '../../graphql/mutations';
import { listDxDrivers, listDxGeneralConfigs, listDxVehicles, listDxVehicleSpares, listDxVendors } from '../../graphql/queries';
import AmplifyServices from '../../Service/Service';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import awsExports from '../../aws-exports';
import dxService from '../../Services/dxService';
import { getdriveroptions, getGenralconfigs, getSparePartoptions, getVehicleoptions, getvendoroptions, isotolocaledatetime, localetoisodatetime } from '../../utils/helper';
import FormTemplate from '../../Components/FormTemplate';
import { _listDxVehicleSpares } from '../../dxgraphql/dxqueries';

const Sparesnewform = (props: any) => {
    const toast = useRef<any>(null);
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [listFormData, setlistFormData] = useState<any>({});

    const [vehicleNoOptions, setVehicleNoOptions] = useState<any>([]);
    const [sparesOption, setSparesOption] = useState<any>([]);
    const [driverIdOptions, setDriverIdOptions] = useState<any>([]);
    const [supplierOptions, setSupplierOptions] = useState<any>([]);
    const [Vehicleactivity, setVehicleactivity] = useState<any>([]);
    const location1 = useLocation();
    const urlParams1 = new URLSearchParams(location1.search);
    var formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
    var formid = urlParams1.has('id') ? urlParams1.get('id') : null;
    var parent = urlParams1.has('parent') ? urlParams1.get('parent') : null;

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
        formid = urlParams1.has('id') ? urlParams1.get('id') : null;
        const paths = location1.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'pictures':
                setActiveIndex(1);
                break;

            default:
                if (formid) getcongifsdata(formid);
                break;
        }
    }, [location1]);
    useEffect(() => {
        checkActiveIndex();
        // getData();
    }, [checkActiveIndex]);
    const getcongifsdata = async (fid: any) => {
        let a = await dxService.getitembyid('vehiclespare', fid, '*');
        if (a) {
            if (a.partname) {
                //parsing the JSON
                a.partname = JSON.parse(a.partname)[0];
            } else {
                a = a;
            }
            getdata(a);

            setlistFormData(a);
        }
    };
    useEffect(() => {}, [formid]);

    const getdata = async (a: any) => {
        let data = await dxService.getitembyid('vehicleactivity', a.vehicleid, '*').then((res) => {
            setVehicleactivity(res);
        });
    };
    useEffect(() => {
        getOptions();
    }, []);
    //get spare parts name
    const getOptions = async () => {
        getGenralconfigs('Parts').then((res) => {
            setSparesOption(res);
        });
        getVehicleoptions().then((res) => {
            setVehicleNoOptions(res);
        });
        getdriveroptions().then((res) => {
            setDriverIdOptions(res);
        });
        getvendoroptions().then((res) => {
            setSupplierOptions(res);
        });
    };

    const formColumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            //Edit Form Columns
            {
                name: 'vehicleid', // pass the exact column name of db
                title: 'Vehicle',
                value: '',
                fieldtype: 'dropdown',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: vehicleNoOptions,
                class: 'md:col-4'
            },
            {
                name: 'driverid', // pass the exact column name of db
                title: 'Driver Name', //for display
                value: '',
                fieldtype: 'dropdown',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: driverIdOptions,
                class: 'md:col-4'
            },
            {
                name: 'supplier', // pass the exact column name of db
                title: 'Supplier', //for display
                value: '',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: supplierOptions, //Fetch from other table
                class: 'md:col-4'
            },

            {
                name: 'partname', // pass the exact column name of db
                title: 'Part Name',
                value: '',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: sparesOption,
                class: 'md:col-4'
            },
            {
                name: 'quantity', // pass the exact column name of db
                title: 'Quantity',
                value: '',
                fieldtype: 'number',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'description', // pass the exact column name of db
                title: 'Description',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },

            {
                name: 'amount', // pass the exact column name of db
                title: 'Amount',
                value: '',
                fieldtype: 'number',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'totalamount', // pass the exact column name of db
                title: 'Total Amount',
                value: '',
                fieldtype: 'number',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: formmode == 'view' ? false : true,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'invoiceno', // pass the exact column name of db
                title: 'Invoice No',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'status', // pass the exact column name of db
                title: 'Status',
                value: '',
                fieldtype: 'dropdown',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [
                    { label: 'In Progress', value: 'In Progress' },
                    { label: 'Completed', value: 'Completed' }
                ],
                class: 'md:col-4'
            },
            {
                name: 'invoicedoc', // pass the exact column name of db
                title: 'Invoice Doc',
                value: '',
                fieldtype: 'fileupload',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: formmode == 'view' ? true : false,
                data: [],
                class: 'md:col-4'
            },
            { name: 'break2', title: '', fieldtype: 'break', class: 'md:col-12' },
            {
                name: 'createdAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                labeltitle: 'Created At',
                fieldtype: 'label',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode == 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'updatedAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                labeltitle: 'Updated At',
                fieldtype: 'label',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode === 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            }
        ],

        actions: [
            {
                title: 'Submit'
            },
            {
                title: 'Cancel'
            }
        ]
    };

    const addData = async (data: any) => {
        let url = window.location.href;
        let navigateUrl;

        let newSparesdata = {
            description: data.description,
            driverid: data.driverid,
            invoiceno: data.invoiceno,
            partname: JSON.stringify([data.partname]),
            quantity: data.quantity,
            amount: data.amount,
            totalamount: parseFloat(data.amount) * parseFloat(data.quantity),
            status: data.status,
            supplier: data.supplier,
            vehicleid: data.vehicleid
        };

        let saveData = await dxService.createitem('vehiclespare', newSparesdata);

        let appid = saveData.id;
        var sparesdocuments: any;
        var Uploadedfiles: any = [];
        Uploadedfiles = data.invoicedoc ? data.invoicedoc : [];
        if (Uploadedfiles.length > 0) {
            Uploadedfiles.map(async (item: any) => {
                await Storage.put('SparesDocuments/' + new Date().getFullYear() + '/' + appid + '/documents/' + item.name, item).then(async (res: any) => {
                    const files = {
                        name: res.key,
                        // dxAllocationsPicturesId: id,
                        s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                        resource: data.supplier,
                        resourceid: appid,
                        presourceid: data.vehicleid,
                        presource: 'VehicleSpares',
                        field1: 'documents',
                        field2: data.invoiceno
                    };

                    await addDocToDB(files);
                    sparesdocuments = {
                        id: appid,
                        invoicedoc: JSON.stringify({
                            File: [res.key]
                        })
                    };
                });

                await dxService.updateitem('vehiclespare', sparesdocuments).then((res) => {
                    if (url.includes('general/appointments')) {
                        navigateUrl = '/general/appointments/all';
                    } else if (url.includes('general/workspace')) {
                        navigateUrl = '/general/workspace';
                    } else {
                        navigateUrl = '/manage/spares/all';
                    }
                    navigate(navigateUrl);
                });
            });
        } else {
            if (url.includes('general/appointments')) {
                navigateUrl = '/general/appointments/all';
            } else if (url.includes('general/workspace')) {
                navigateUrl = '/general/workspace';
            } else {
                navigateUrl = '/manage/spares/all';
            }
            navigate(navigateUrl);
        }
    };
    //Method to call when user uploads files
    const addDocToDB = async (files: any) => {
        let appointmentDocs: any = await API.graphql({
            query: createDxDocument,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: files
            }
        });
    };
    const changedata = (props: any) => {
        if (props && props.amount && props.quantity) {
            const amount = parseFloat(props.amount);
            const quantity = parseFloat(props.quantity);
            let totalCostAmount = amount * quantity;

            setTotalAmount(totalCostAmount);
        }
    };
    const addConfig = (props: any) => {
        if (formid == null) addData(props);
        else {
            editnewSpares(props, formid);
        }
    };
    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? 'mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';

    const wizardItems = [{ label: 'Basic Details', command: () => navigate('/manage/spares/item/basic' + qs) }];

    const editnewSpares = async (formdata: any, fid: string) => {
        let editSparesdata = {
            id: formdata.id,
            description: formdata.description,
            driverid: formdata.driverid,
            invoiceno: formdata.invoiceno,
            partname: JSON.stringify([formdata.partname]),
            quantity: formdata.quantity,
            amount: formdata.amount,
            totalamount: parseFloat(formdata.amount) * parseFloat(formdata.quantity),
            status: formdata.status,
            supplier: formdata.supplier,
            vehicleid: formdata.vehicleid,
            approvedby: formdata.owner,
            _version: formdata._version
        };
        var Uploadedfiles: any = [];
        Uploadedfiles = formdata.invoicedoc ? formdata.invoicedoc : [];
        dxService
            .updateitem('vehiclespare', editSparesdata, false)
            .then(async (res) => {
                //new amount

                let filter = {
                    id: {
                        eq: listFormData.appointment.id
                    }
                };
                await dxService.listitems('appointment', '*', filter, true, 100000).then(async (appres) => {
                    //old amount
                    let oldAppointmentCost = appres.items[0].cost ? Number(appres.items[0].cost) - Number(listFormData.totalamount) : 0;
                    let newAppointmentCost = oldAppointmentCost + Number(res.totalamount);

                    let updatedAppointment = {
                        id: appres.items[0].id,
                        cost: newAppointmentCost,
                        _version: appres.items[0]._version
                    };

                    await dxService.updateitem('appointment', updatedAppointment);
                });
                await updateVehicleactivity(res);

                if (formdata.invoicedoc && typeof formdata.invoicedoc === 'object') {
                    let sparesdocuments: any;
                    Uploadedfiles.map(async (item: any) => {
                        await Storage.put('SparesDocuments/' + new Date().getFullYear() + '/' + formdata.id + '/documents/' + item.name, item).then(async (res: any) => {
                            const files = {
                                name: res.key,
                                // dxAllocationsPicturesId: id,
                                s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                                resource: formdata.supplier,
                                resourceid: formdata.id,
                                presourceid: formdata.vehicleid,
                                presource: 'VehicleSpares',
                                field1: 'documents',
                                field2: formdata.invoiceno
                            };

                            await addDocToDB(files);
                            sparesdocuments = {
                                id: formdata.id,
                                invoicedoc: JSON.stringify({
                                    File: [res.key]
                                })
                            };
                        });

                        await dxService.updateitem('vehiclespare', sparesdocuments).then((res) => {
                            parent === 'appointment' ? navigate(`/general/appointments/all`) : navigate(`/manage/spares/all`);
                        });
                    });
                } else {
                    parent === 'appointment' ? navigate(`/general/appointments/all`) : navigate(`/manage/spares/all`);
                }
                // showSuccess(res.name, 'updated successfully!');
            })
            .catch((err) => {
                console.error(err);
            });
        // AmplifyServices.EditConfigDetails(updateDxVehicleSpares, 'updateDxVehicleSpares', b).then(async (res: any) => {

        // });
    };

    const updateVehicleactivity = async (appoinmentitem: any) => {
        let va: any = Vehicleactivity;

        if (Vehicleactivity) {
            var nowdate = isotolocaledatetime(new Date().toDateString());
            let vdetails: any = {
                id: Vehicleactivity.id,
                _version: Vehicleactivity._version,
                dxAppointmentsActivityId: appoinmentitem.id
            };
            //check the eventtype and update based on the eventtype
            if (listFormData.appointment.eventtype === 'Service') {
                let repaircost = va.events == null ? 0 : JSON.parse(va.events).Repair == null ? 0 : JSON.parse(va.events).Repair.cost == null ? 0 : JSON.parse(va.events).Repair.cost;
                repaircost = repaircost - listFormData.totalamount;
                repaircost = repaircost + parseInt(appoinmentitem.totalamount);

                if (va != null) {
                    let vaevents: any = { ...va['events'] };

                    if (Vehicleactivity.events != null) {
                        vaevents = JSON.parse(Vehicleactivity.events);
                    } else {
                        vaevents['Service'] = {
                            date: '',
                            id: '',
                            allocationid: ''
                        };
                    }

                    if (parseInt(appoinmentitem.totalamount) < 500) {
                        vaevents['Repair']['cost'] = repaircost;
                    }

                    vdetails['events'] = JSON.stringify(vaevents);
                }
            } else if (listFormData.appointment.eventtype === 'Repair/Replacement') {
                let vaevents: any = {};
                let repaircost = 0;
                let oldrepaircost = 0;
                if (va !== null) {
                    vaevents = JSON.parse(va.events);
                    repaircost = vaevents['Repair']?.cost || 0;
                    oldrepaircost = repaircost;
                }
                repaircost = repaircost - parseInt(listFormData.totalamount);
                repaircost = repaircost + parseInt(appoinmentitem.totalamount);

                if (parseInt(appoinmentitem.totalamount) < 500) {
                    vaevents['Repair'] = vaevents['Repair'] || {};
                    vaevents['Repair']['cost'] = repaircost;
                } else {
                    repaircost = oldrepaircost;
                }

                if (formmode === 'close') {
                    delete vaevents['Repair']['id'];
                    // vaevents['Repair']['date'] = new Date(appoinmentitem.Date);
                }

                vdetails['events'] = JSON.stringify(vaevents);
            } else if (listFormData.appointment.eventtype === 'Inspection' || listFormData.appointment.eventtype === 'Internal Inspection') {
                if (va != null) {
                    let vaevents: any = { ...va['events'] };
                    if (Vehicleactivity.events != null) {
                        vaevents = JSON.parse(Vehicleactivity.events);
                    } else {
                        vaevents['InternalInspection'] = {
                            date: '',
                            id: '',
                            allocationid: ''
                        };
                    }
                    vdetails['events'] = JSON.stringify(vaevents);
                }
            } else if (listFormData.appointment.eventtype === 'Accident') {
                if (va !== null) {
                    let vaevents: any = { ...va['events'] };
                    if (Vehicleactivity.events !== null) {
                        vaevents = JSON.parse(Vehicleactivity.events);
                    } else {
                        vaevents['Accident'] = {
                            date: '',
                            id: '',
                            allocationid: ''
                        };
                    }
                    vdetails['events'] = JSON.stringify(vaevents);
                }
            } else if (listFormData.appointment.eventtype === 'Accident Repair') {
                let vaevents: any = {};
                let repaircost = 0;
                let oldrepaircost = 0;
                if (va !== null) {
                    vaevents = JSON.parse(va.events);
                    repaircost = vaevents['Repair']?.cost || 0;
                    oldrepaircost = repaircost;
                }

                repaircost = repaircost - parseInt(listFormData.totalamount);
                repaircost = repaircost + parseInt(appoinmentitem.totalamount);

                if (parseInt(appoinmentitem.totalamount) < 500) {
                    vaevents['Repair'] = vaevents['Repair'] || {};
                    vaevents['Repair']['cost'] = repaircost;
                } else {
                    repaircost = oldrepaircost;
                }

                vdetails['events'] = JSON.stringify(vaevents);
            }

            // let o = await API.graphql({
            //     query: updateDxVehicleActivity,
            //     authMode: 'AMAZON_COGNITO_USER_POOLS',
            //     variables: {
            //         input: vdetails
            //     }
            // });
            let o = await dxService.updateitem('vehicleactivity', vdetails);

            return o;
        }
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{formmode === 'edit' ? 'Edit Spare : ' + listFormData.partname : formmode === 'view' ? 'View Spare: ' + listFormData.partname : 'New Spares'}</h4>

                <div className="card container-card">
                    <Toast ref={toast} />
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                    <Routes>
                        <Route
                            path={'/basic'}
                            element={
                                <>
                                    <FormTemplate columns={formColumns} data={listFormData} showaction={true} onChange={changedata} mode={formmode} ChangeBtn={'OpenDocument'} layout="grid2" title="New Spares" submitForm={addConfig} />
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};
export default Sparesnewform;
