import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AmplifyServices from '../../Service/Service';
import { createDxDriver, deleteDxDriver, updateDxDriver } from '../../graphql/mutations';
import { listDxCompanies, listDxDepartments, listDxDrivers } from '../../graphql/queries';
import ListnewForm from '../../Components/ListnewForm';
import Driverviewform from './DocumentViewform';
import { listdxdepts } from '../../dxgraphql/dxqueries';

const Drivereditform = () => {
    const [DepartmentOptions, setDepartmentOptions] = useState<any>([]);
    const [BusinessOptions, setBusinessOptions] = useState<any>([]);
    const navigate = useNavigate();
    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        await AmplifyServices.getTableDetails(listDxCompanies, 'listDxCompanies').then(async (res: any) => {
            setBusinessOptions(res);
        });
        await AmplifyServices.getTableDetails(listDxDepartments, 'listDxDepartments').then(async (res: any) => {
            setDepartmentOptions(res);
        });
    };
    const DepartmentNameOption: any = [];
    const names = DepartmentOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.id;
        // console.log(res);
        DepartmentNameOption.push(res);
    });
    const BusinessNameOption: any = [];
    const Dnames = BusinessOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.id;
        // console.log(res);
        BusinessNameOption.push(res);
    });
    const driverformcolumns = {
        columns: [
            {
                name: 'name',
                value: 'Name',
                fieldtype: 'text',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            }
            // { name: 'mobileno', value: 'Mobile No', fieldtype: 'number', required: false, disabled: false, hidden: false, data: [] },
            // { name: 'contactno', value: 'Contact No', fieldtype: 'number', required: true, disabled: false, hidden: false, data: [] },
            // { name: 'emailid', value: 'Email ID', fieldtype: 'text', required: true, disabled: false, hidden: false, data: [] },

            // {
            //     name: 'businessunitname',
            //     value: 'Business Unit',
            //     fieldtype: 'dropdown',
            //     required: true,
            //     disabled: false,
            //     hidden: false,
            //     data: BusinessNameOption
            // },
            // {
            //     name: 'dxDepartmentDriversId',
            //     value: 'Department',
            //     fieldtype: 'dropdown',
            //     required: true,
            //     disabled: false,
            //     hidden: false,
            //     data: DepartmentNameOption
            // },
            // { name: 'licenseno', value: 'License No', fieldtype: 'text', required: true, disabled: false, hidden: false, data: [] },
            // { name: 'licensetype', value: 'License Type', fieldtype: 'text', required: true, disabled: false, hidden: false, data: [] },
            // { name: 'jobrole', value: 'Job Role', fieldtype: 'text', required: false, disabled: false, hidden: false, data: [] },
            // { name: 'startdate', value: 'Start Date', fieldtype: 'datetime', required: true, disabled: false, hidden: false, data: [] },
            // { name: 'enddate', value: 'End Date', fieldtype: 'datetime', required: true, disabled: false, hidden: false, data: [] },
            // {
            //     name: 'isnew',
            //     value: 'New Driver?',
            //     fieldtype: 'dropdown',
            //     required: true,
            //     disabled: false,
            //     hidden: false,
            //     data: [
            //         { label: 'Yes', value: true },
            //         { label: 'No', value: false }
            //     ]
            // },
            // { name: 'licensepicture', value: "Driver's Lincese", fieldtype: 'fileupload', required: false, disabled: false, hidden: false, data: [] }
        ],
        fleettype: 'driver',
        getTable: listDxDrivers,
        updateTable: updateDxDriver //Pass update table name
    };

    const submitForm = async (data: any) => {
        // console.log(props.columns);
        data['id'] = data.id;
        data['fleettype'] = data.fleettype;
        if (Object.keys(data).includes('_lastChangedAt')) delete data['_lastChangedAt'];
        if (Object.keys(data).includes('owner')) delete data['owner'];
        let os = await API.graphql({
            query: driverformcolumns.updateTable,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });
        let navigateUrl = '/manage/drivers';
        navigate(navigateUrl);
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <ListnewForm columns={driverformcolumns} ChangeBtn={'SaveForm'} layout="grid" getTableName="listDxDrivers" title="Edit Driver - " submitForm={submitForm} />
            </div>
            <div className="col-12 md:col-6">{/* <Driverviewform /> */}</div>
        </div>
    );
};

export default Drivereditform;
