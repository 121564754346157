import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';

import { Button } from 'primereact/button';

import moment from 'moment';

import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';

const ListTable = (props: any) => {
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const navigate = useNavigate();
    useEffect(() => {}, []);

    const formatDate = (value: any, col: any) => {
        let date = value[col.field];

        const time = date !== '' && date !== null && date.toLocaleTimeString('en-US', { hour12: true });
        const formattedTime = time !== '' && moment(time, 'HH:mm:ss').format('HH:mm');

        let formatunuseddate = moment(date).format('DD-MMM-YYYY');
        const formattedDate =
            date !== '' && col.showtime && value[col.field] !== null
                ? moment(
                      date.toLocaleDateString('en-US', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                      }),
                      'MM/DD/YYYY'
                  ).format('DD-MMM-YYYY') +
                  ' ' +
                  formattedTime
                : date !== '' && formatunuseddate !== '01-Jan-1970' && value[col.field] !== null
                ? moment(
                      date.toLocaleDateString('en-US', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                      }),
                      'MM/DD/YYYY'
                  ).format('DD-MMM-YYYY')
                : '';
        // console.log(formattedDate);
        return formattedDate;
    };
    const hightlightformatDate = (value: any, col: any) => {
        let date = value[col.field];

        const time = date !== '' && date !== null && date.toLocaleTimeString('en-US', { hour12: true });
        const formattedTime = time !== '' && moment(time, 'HH:mm:ss').format('HH:mm');

        let formatunuseddate = moment(date).format('DD-MMM-YYYY');
        const formattedDate =
            date !== '' && col.showtime && value[col.field] !== null
                ? moment(
                      date.toLocaleDateString('en-US', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                      }),
                      'MM/DD/YYYY'
                  ).format('DD-MMM-YYYY') +
                  ' ' +
                  formattedTime
                : date !== '' && formatunuseddate !== '01-Jan-1970' && value[col.field] !== null
                ? moment(
                      date.toLocaleDateString('en-US', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                      }),
                      'MM/DD/YYYY'
                  ).format('DD-MMM-YYYY')
                : '';
        // console.log(formattedDate);
        if (formattedDate === null || moment(formattedDate).format('MMM-DD-YY') == 'Jan-01-70') return '';

        const expirydate = new Date(formattedDate);
        if (!expirydate) return '';
        const today = new Date();
        const notificationDays = value['renewaltype'] === 'Parking' || value['renewaltype'] === 'Branding' ? 7 : 15;
        // console.log(notificationDays);

        if (value['renewalstatus'] !== 'Completed' && expirydate.getTime() - today.getTime() <= notificationDays * 24 * 60 * 60 * 1000) {
            // Add highlight class for notification
            if (expirydate < today && moment(expirydate).format('MM-DD-YYYY') !== moment(today).format('MM-DD-YYYY')) {
                let expiry = formattedDate;
                return <span className="customer-badge status-highlight-red">{expiry && new Date(expiry).toDateString()}</span>;
            } else {
                let expiry = formattedDate;
                return <span className="customer-badge status-highlight-orange">{expiry && new Date(expiry).toDateString()}</span>;
            }
        } else {
            // No highlight class

            let expiry = formattedDate;
            return <div>{expiry && new Date(expiry).toDateString()}</div>;
        }
        // return formattedDate;
    };

    const showdata = (rowData: any, col: any) => {
        switch (col.fieldtype) {
            case 'image':
                return col.template(rowData);
            case 'description':
                return (
                    <span className="truncate" style={{ width: col.width ? col.width : 'inherit' }}>
                        {rowData[col.field]}
                    </span>
                );
            case 'custom':
                return col.template(rowData);
                break;
            case 'user':
                return <span>{rowData[col.field] ? rowData[col.field].displayname : ''}</span>;
            case 'lookup':
                return <span>{rowData[col.field] ? rowData[col.field].name : rowData.drivername}</span>;
            case 'lookupcount':
                return <span>{rowData[col.field] && rowData[col.field].items ? rowData[col.field].items.length : ''}</span>;
            case 'link':
                return (
                    <a href={`${col.path}${rowData[col.field]}`} className={`customer-badge status-${rowData.id}`}>
                        {col.showfield ? rowData[col.showfield] : rowData[col.field]}
                    </a>
                );
                break;

            case 'linkbutton':
                return <Button label={col.text} className="p-button-success " onClick={(e) => navigate('/manage/approvals/approve?id=' + rowData.id)} />;
                break;
            case 'lookupcollection':
                let a: any = [];
                if (rowData[col.field] && rowData[col.field].items)
                    rowData[col.field].items.forEach((e: any) => {
                        a.push(e.title);
                    });

                return <span>{a.join(';')}</span>;
            case 'date':
                return col.showtime && rowData[col.field] !== null ? moment(rowData[col.field]).format('DD-MMM-YYYY hh:mm') : rowData[col.field] !== null ? moment(rowData[col.field]).format('DD-MMM-YYYY') : '';
                break;
            case 'datefilter':
                return formatDate(rowData, col);
                break;
            case 'hightlightdatefilter':
                return hightlightformatDate(rowData, col);
                break;
            case 'textstr':
                return <span>{rowData.vehicleid.includes('[', ']') ? rowData.vehicleid.replace('[', '').replace(']', '') : rowData.vehicleid}</span>;
                break;
            case 'status':
                return <span className={`customer-badge status-${rowData.status.replaceAll(' ', '')}`}>{rowData.status}</span>;
                break;
            case 'boolean':
                return <span>{rowData.isactive.toString()}</span>;
                break;
            case 'navigatedriver':
                return (
                    <a href={`#/manage/drivers/item/basic?mode=view&id=${rowData.id}&fleettype=driver`} className={`customer-badge status-${rowData.id}`}>
                        {rowData.name}
                    </a>
                );
                break;
            case 'navigateVendor':
                return (
                    <a href={`#/manage/vendors/item/basic?mode=view&id=${rowData.id}`} className={`customer-badge status-${rowData.id}`}>
                        {rowData.company}
                    </a>
                );
                break;
            case 'navigateSpares':
                return (
                    <a href={`#/manage/spares/item/basic?mode=view&id=${rowData.id}`} className={`customer-badge status-${rowData.id}`}>
                        {rowData.vehicleid}
                    </a>
                );
                break;
            case 'navigatedocument':
                //manage/documents/item/basic?mode=edit&
                return (
                    <a href={`#/manage/renewals/item/basic?mode=view&id=${rowData.id}&vid=${rowData.vehicleid}&type=${rowData.renewaltype}`} className={`customer-badge status-${rowData.vehicleid}`}>
                        {rowData.vehicleid.includes('[', ']') ? rowData.vehicleid.replace('[', '').replace(']', '') : rowData.vehicleid}
                        {/* {rowData.vehicleid} */}
                    </a>
                );
                break;
            case 'navigateservice':
                return (
                    <a href={`${col.path}${rowData.id}`} className={`customer-badge status-${rowData.id}`}>
                        {rowData.vehicleid.includes('[', ']') ? rowData.vehicleid.replace('[', '').replace(']', '') : rowData.vehicleid}
                    </a>
                );
                break;
            case 'href':
                //#/manage/vehicles/item/basic?mode=view&id=
                return (
                    <a href={`${col.path}${rowData.id}`} className={`customer-badge status-${rowData.id}`}>
                        {rowData[col.field]}
                    </a>
                );
                break;
            case 'partnames':
                let names = [];

                if (rowData.partname !== null && rowData.partname !== '') {
                    names = rowData.partname !== null && JSON.parse(rowData.partname);
                    let partnames: any = [];
                    for (let i = 0; i < names.length; i++) {
                        let option = {
                            value: names[i],
                            label: names[i]
                        };
                        partnames.push(option);
                    }
                    return <>{partnames[0].value}</>;
                }

                break;
            default:
                return <span>{rowData[col.field]}</span>;
        }
    };

    const OnSelectedChange = (e: any) => {
        setSelectedProducts(e.value);
        props.selectedItems(e.value);
    };
    const dateFilterTemplate = (options: any) => {
        return <Calendar value={options.value} onChange={(e: any) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };
    return (
        <div className="grid table-demo">
            <div className="col-12">
                {/* <div className="card"> */}
                {/* <h5>{props.header ? props.header : 'Manage'}</h5> */}

                <DataTable
                    value={
                        props.value &&
                        props.value
                            .filter((item: any) => item._deleted == undefined || item._deleted === null || item._deleted === false)
                            .sort(function (a: any, b: any) {
                                return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
                            })
                    }
                    rows={props.rows}
                    paginator={props.paginator != undefined && props.paginator ? true : false}
                    emptyMessage={props.emptyMessage != undefined && props.emptyMessage ? props.emptyMessage : ''}
                    responsiveLayout={props.responsiveLayout != undefined && props.responsiveLayout ? props.responsiveLayout : ''}
                    className={props.className != undefined && props.className ? props.className : ''}
                    header={props.Commandbar != undefined && props.Commandbar}
                    paginatorTemplate={props.paginatorTemplate != undefined && props.paginatorTemplate ? props.paginatorTemplate : ''}
                    currentPageReportTemplate={props.currentPageReportTemplate != undefined && props.currentPageReportTemplate ? props.currentPageReportTemplate : ''}
                    rowsPerPageOptions={props.rowsPerPageOptions != undefined && props.rowsPerPageOptions ? props.rowsPerPageOptions : ''}
                    onSelectionChange={(e) => OnSelectedChange(e)}
                    // selection={Data.selection != undefined && Data.selection ? Data.selection : ''}
                    selection={selectedProducts}
                    // onSelectionChange={e => setSelectedProducts(e.value)}
                    loading={props.loading}
                    filterDisplay="menu"
                    filters={props.tablefilter}
                    scrollable={props.scrollable ? props.scrollable : false}
                    scrollHeight={props.scrollHeight ? props.scrollHeight : 'auto'}
                >
                    {props.multiselect && <Column key={'-1'} selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>}

                    {props.dynamicColumns.map((col: any, i: number) => {
                        return (
                            <Column
                                key={col.field + '_' + i.toString()}
                                // selectionMode={col.fieldtype != undefined && col.fieldtype ?"multiple"  : ''}
                                field={col.field !== undefined && col.field}
                                //   filter={false}
                                sortable={col?.sortable}
                                filterMenuStyle={{ minWidth: col.width ? col.width : 'inherit' }}
                                style={{ minWidth: col.width ? col.width : 'inherit' }}
                                filterField={col.fieldtype == 'lookup' ? col.filterFieldOption + '.name' : col.filterFieldOption}
                                filter={col.filterOption == true ? true : false}
                                body={(rowData: any) => {
                                    return showdata(rowData, col);
                                }}
                                filterElement={col.dataType && dateFilterTemplate}
                                dataType={col.dataType && col.dataType}
                                header={col.header}
                                headerStyle={{ width: props.width ? props.width : 'inherit', textAlign: col.headeralign ? col.headeralign : 'left' }}
                                // bodyClassName={(rowData: any) => {
                                //     // console.log(rowData['renewalstatus'] !== 'Completed');

                                // }}
                                bodyStyle={col.textalign && { textAlign: col.textalign }}
                            />
                        );
                    })}
                </DataTable>
                {/* {selectedProducts.length > 0 && selectedProducts != null ? <Vehicles selectedProducts={selectedProducts} className="ListTable" OnSelectedChange={OnSelectedChange} /> : ''}
                {selectedProducts.length > 0 && selectedProducts != null ? <Task selectedProducts={selectedProducts} className="ListTable" OnSelectedChange={OnSelectedChange} /> : ''}
                {selectedProducts.length > 0 && selectedProducts != null ? <ManageAppointments selectedProducts={selectedProducts} className="ListTable" OnSelectedChange={OnSelectedChange} /> : ''} */}
            </div>
        </div>
        // </div>
    );
};

export default ListTable;
