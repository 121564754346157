import React, { useState, useEffect, useRef, Children, useContext } from 'react';
import { Amplify, API, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Toast } from 'primereact/toast';
import { AuthContext } from '../../../AuthContext';
import { CognitoIdentityProviderClient, AdminAddUserToGroupCommand, AdminRemoveUserFromGroupCommand, ListGroupsCommand, AdminListGroupsForUserCommand, AdminUpdateUserAttributesCommand } from '@aws-sdk/client-cognito-identity-provider';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../../dxgraphql/dxqueries';
import AWS from 'aws-sdk';
import awsExports from '../../../aws-exports';
import ListTemplate from '../../../Components/ListTemplate';



const ManageAWSUsers = () => {
    const { dispatch } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [workflowitem, setworkflowitem] = useState<any>({});
    const [stepitem, setstepitem] = useState<any>({});
    const [appitem, setappitem] = useState<any>({});
    const [newdata, setnewdata] = useState<any>({});
    const toast = useRef(null);
    const [uname, setUname] = useState('');
    const [name, setname] = useState('');
    const [useritems, setuseritems] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [tableFilters, setTableFilters] = useState<any>('');

    useEffect(() => {
        getdata();



    }, []);

    useEffect(() => {


    }, [appitem]);

    const columns = [
        { field: 'username', showfield: 'username', header: 'User name', fieldtype: 'link', path: '#/profile?id=', filterOption: true, filterFieldOption: 'name' },
        { field: 'preferred_username', header: 'Display name', fieldtype: 'text', filterOption: true, filterFieldOption: 'mobileno' },
        { field: 'email', header: 'Email', fieldtype: 'text', filterOption: true, filterFieldOption: 'mobileno' },
        { field: 'UserStatus', header: 'Status', fieldtype: 'text' },
        { field: 'Enabled', header: 'Active', fieldtype: 'boolean' },
        // { field: 'mobileno', header: 'Mobile No', fieldtype: 'text', filterOption: true, filterFieldOption: 'mobileno' },
        // // // {field: 'imageData', header: 'Image', fieldtype:'image'},
        // { field: 'department', header: 'Department', fieldtype: 'lookup', filterOption: true, filterFieldOption: 'department.name' },
        // { field: 'company', header: 'Business Unit', fieldtype: 'lookup', filterOption: true, filterFieldOption: 'businessunitname' },
        // { field: 'licenseno', header: 'License No', fieldtype: 'text', filterOption: true, filterFieldOption: 'licenseno' },
        // { field: 'startdate', header: 'Start Date', fieldtype: 'date', filterOption: true, filterFieldOption: 'startdate' },
        // { field: 'enddate', header: 'End Date', fieldtype: 'date', filterOption: true, filterFieldOption: 'enddate' }
    ];



    const changedata = (props: any) => {
        console.log(props);
    };

    const addData = (props: any) => {
        console.log(props);
    };

    const getdata = async () => {
        let a:any = await allusers(awsExports.aws_user_pools_id);
        console.log(a);
        let alluser:any = [];
        if(a && a.Users){
            a.Users.map((u:any)=>{
                let user:any = {
                    username:  u.Username,
                    UserStatus: u.UserStatus,
                    Enabled: u.Enabled
                }
                console.log(u);
                u.Attributes && u.Attributes.map((ua:any)=>{
                    user[ua.Name] = ua.Value
                });
                alluser.push(user);

            });
            console.log(alluser);
        setuseritems(alluser);
        }

    }

    const allusers = async (userPoolId: any) => {
        return Auth
            .currentCredentials()
            .then(user => {

                let AWSconfig = new AWS.Config({
                    apiVersion: '2016-04-18',
                    credentials: user,    //  The whole user object goes in the config.credentials field!  Key issue.
                    region: awsExports.aws_cognito_region
                });

                const params = {
                    UserPoolId: userPoolId,
                };

                const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);
                
                cognitoIdp.listUsers(params, function (err, userData) {
                    if (err) {
                        console.log('Error in getCognitoUsers: ', err);
                    } else {
                    return userData;
                    }
                  });
                return cognitoIdp.listUsers(params).promise();
            });

    }

    const listUsersByEmail = async (userPoolId: any, email: any) => {

        Auth
            .currentCredentials()
            .then(user => {

                let AWSconfig = new AWS.Config({
                    apiVersion: '2016-04-18',
                    credentials: user,    //  The whole user object goes in the config.credentials field!  Key issue.
                    region: awsExports.aws_cognito_region
                });

                const params = {
                    UserPoolId: userPoolId,
                    Filter: `email = "${email}"`,
                };

                const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);
                
                cognitoIdp.listUsers(params, function (err, userData) {
                    if (err) {
                        console.log('Error in getCognitoUsers: ', err);
                    } else {
                    console.log(userData);
                    }
                  });
                return cognitoIdp.listUsers(params).promise();


            });

    }



    return (

        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Cognito Users</h4>

                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}

                            <ListTemplate
                        header="Manage Users"
                        value={useritems}
                        paginator={true}
                        rows={10}
                        filters={tableFilters}
                        dynamicColumns={columns}
                        emptyMessage="No drivers found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Drivers"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 25]}
                        //   selection={selectedProducts}
                        multiselect={true}
                        //   selectedItems={selectedProducts}
                       // Commandbar={Commandbar}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}Wd
                        selectedItems={setSelectedItems}
                    />

                            {/* <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                <div className="surface-ground">
                                    <div className="grid">
                                        <div className="col-12 lg:col-4 p-3">

                                        </div>

                                    </div></div></div> */}


                            {/* END Inner COMPONENT HTML */}

                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}




            </div>
        </div>

    );
};
export default ManageAWSUsers;

