import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import AmplifyServices from '../../Service/Service';
import ListTable from '../../Components/ListTable';
import { deleteDxNavigation } from '../../graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { listDxNavigations } from '../../graphql/queries';
import dxService from '../../Services/dxService';

const ManageNavigation = () => {
    const navigate = useNavigate();
    const menu = useRef<any>(null);
    const [navitems, setNavitems] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [globalFilter, setGlobalFilter] = useState<any>('');

    useEffect(() => {
        let allItems: any = [];
        getData().then((res) => {
            Promise.all(
                res.map(async (item: any) => {
                    const parsedItem = JSON.parse(item.item);
                    const newView = {
                        id: item.id,
                        type: item.type,
                        name: parsedItem.label,
                        items: parsedItem.items.label,
                        to: parsedItem.items.to,
                        order: item.order,
                        _deleted: item._deleted,
                        _version: item._version
                    };
                    allItems.push(newView);
                })
            ).then(() => {
                setNavitems(allItems);
            });
        });
    }, []);
    const getData = async (nextToken?: any) => {
        let allItems: any[] = [];
        const response = await dxService.listitems('navigationconfiguration', '*', {}, true, 100, nextToken);
        let items = response.items;

        Promise.all(
            items.map(async (item: any) => {
                const parsedItem = JSON.parse(item.item);
                const newView = {
                    id: item.id,
                    type: item.type,
                    name: parsedItem.label,
                    items: parsedItem.items.label,
                    to: parsedItem.items.to,
                    order: item.order,
                    _deleted: item._deleted,
                    _version: item._version
                };
                allItems.push(newView);
            })
        ).then(() => {
            setNavitems((prevItems: any) => [...prevItems, ...allItems]);
        });

        let nexttoken = response.nextToken;

        if (nexttoken) {
            items = [...items, ...(await getData(nexttoken))];
        }

        return items;
    };

    const columns = [
        // { fieldtype: 'multiselect' },
        { field: 'type', header: 'Type', fieldtype: 'text', filterOption: true, filterFieldOption: 'name' },
        { field: 'name', header: 'Parent', fieldtype: 'text', filterOption: true, filterFieldOption: 'name' },
        { field: 'items', header: 'Sub Items', fieldtype: 'text', filterOption: true, filterFieldOption: 'items' },
        { field: 'to', header: 'Path', fieldtype: 'text', filterOption: true, filterFieldOption: 'to' },
        { field: 'order', header: 'Order By', fieldtype: 'text', filterOption: true, filterFieldOption: 'order' }
    ];

    const deleteItems = async () => {
        await AmplifyServices.DeleteItems(selectedItems, deleteDxNavigation).then((res) => {
            getData();
            setSelectedItems([]);
            selectedItems.length = 0;
        });
    };

    let params = selectedItems.length > 0 && `?mode=edit&id=${selectedItems[0].id}`;

    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New" onClick={() => navigate('/admin/navigation/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />

                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />

                <Menu
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedItems.length == 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                //  navigate(`/manage/vehicles/item/basic?mode=edit&id=${SelectedItems != undefined && EditNavigation[0].id}&fleettype=vehicle`);
                                navigate('/admin/navigation/item/basic' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                deleteItems();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            <div>
                <InputText type="text" id={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
            </div>
        </div>
    );

    let filteredSearch = navitems && navitems.filter((del: any) => del._deleted !== true).sort((a: any, b: any) => a.order - b.order);
    // .filter((name: any) => {
    //     if (name.name.toLowerCase().includes(globalFilter.toLowerCase().toLowerCase()) || name.to.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase()) || name.items.toLowerCase().includes(globalFilter.toLowerCase())) {
    //         return name;
    //     }
    // });
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Navigations</h4>
                <div className="datatable-editing-demo">
                    {/* <NewForm disableAllField={false} parent="Workspace" columns={AddForm} fleettype="drivers" title={formTitle} onSubmit={onSubmit} /> */}

                    <ListTable
                        header="Manage Drivers"
                        value={filteredSearch}
                        paginator={true}
                        rows={100}
                        dynamicColumns={columns}
                        emptyMessage="No items found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[100, 200, 300]}
                        //   selection={selectedProducts}
                        multiselect={true}
                        //   selectedItems={selectedProducts}
                        Commandbar={Commandbar}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}Wd
                        selectedItems={setSelectedItems}
                    />
                </div>
            </div>
        </div>
    );
};

export default ManageNavigation;
