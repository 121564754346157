import { API, Storage } from 'aws-amplify';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import dxService from '../../Services/dxService';
import moment from 'moment';
import ListTemplate from '../../Components/ListTemplate';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Link } from 'react-router-dom';

let driverID: any;
const Logsviewform = () => {
    // const location = window.location.href;
    // const urlParams = new URLSearchParams(location.split('?')[1]);
    const [items, setItems] = useState<any>([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [SelectedItems, setSelectedItems] = useState<any>([]);
    const location = useLocation();
    const actionmenu = useRef<any>(null);
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var vid = urlParams.has('vid') ? urlParams.get('vid') : null;
    var drivername = urlParams.has('driver') ? urlParams.get('driver') : null;
    useEffect(() => {
        getLogDetails().then((res) => {
            setItems(res);
        });
    }, []);

    const getLogDetails = async (nextToken?: any) => {
        let variables = {
            vehicleid: {
                eq: vid // filter priority = 1
            },
            drivername: {
                eq: drivername
            }
        };
        let GetResponse: any = await dxService.listitems('masterlog', '*', variables, true, 100, nextToken);
        let response = GetResponse.items;

        //push the items to the setitems
        setItems((prevItems: any) => [...prevItems, ...response]);

        let nexttoken = GetResponse.nextToken;

        // window.onscroll = async function (ev) {
        //     if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {
        if (nexttoken) {
            response = [...response, ...(await getLogDetails(nexttoken))];
        }
        //     }
        // };

        return response;
    };
    const columns = [
        // { fieldtype:'multiselect'},
        { field: 'vehicleid', header: 'Vehicle', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'date', header: 'Date', fieldtype: 'custom', sortable: true, sortField: 'id', template: (r: any) => <>{DateBodyTemplate(r)}</>, dataType: 'date' },
        { field: 'Fine', header: 'Fines', fieldtype: 'text', filterOption: true, filterFieldOption: 'Fine' },
        { field: 'Salik', header: 'Salik', fieldtype: 'text', filterOption: true, filterFieldOption: 'Salik' },
        { field: 'Mileage', header: 'Mileage', fieldtype: 'text', filterOption: true, filterFieldOption: 'Mileage' },
        { field: 'Vipfuel', header: 'VIP Fuel', fieldtype: 'text', filterOption: true, filterFieldOption: 'Vipfuel' },
    ];
    const DateBodyTemplate = (e: any) => {
        return <span className="">{moment(e.date).format('DD-MM-YYYY')}</span>;
    };
    const deleteItems = async () => {
        await dxService.deleteitem('masterlog', SelectedItems[0].id, SelectedItems[0]._version).then((res) => {
            getLogDetails().then((res) => {
                setItems(res);
                let filter = {
                    dxMasterLogsSalikId: {
                        eq: SelectedItems[0].id
                    }
                };
                dxService.listitems('logsalik', '*', filter, true, 100000).then(async (res) => {
                    await dxService.deleteitem('', SelectedItems[0].id, SelectedItems[0]._version).then((res) => { });
                });
            });
            setSelectedItems([]);
            SelectedItems.length = 0;
        });
    };


    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                {/* <Button icon="pi pi-plus" label="New" className="p-button-primary mr-2 mb-2 newbtnhover" /> */}

                {/* {SelectedItems.length > 0 ? (
                    <Button
                        icon="pi pi-trash"
                        label="Delete"
                        onClick={() => {
                            deleteItems();
                        }}
                        className="p-button-danger mr-2  newbtnhover"
                    />
                ) : (
                    ''
                )} */}
            </div>
            {SelectedItems.length}/{items.length}
        </div>
    );
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4>
                    View {drivername} (<Link to={`/manage/vehicles/item/basic?mode=view&id=${vid}`}>{vid}</Link>) Logs
                </h4>
                <div className="card">
                    <ListTemplate
                        header="Manage Logs"
                        value={items}
                        paginator={false}
                        rows={100}
                        dynamicColumns={columns}
                        emptyMessage="No Items found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[100, 200, 300]}
                        //   selection={selectedProducts}
                        multiselect={true}
                        SelectedItems={selectedProducts}
                        Commandbar={Commandbar}
                        selectedItems={setSelectedItems}
                    //   onSelectionChange={e => setSelectedProducts(e.value)}
                    />
                </div>
            </div>
        </div>
    );
};
export default Logsviewform;
