import { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useNavigate } from "react-router-dom";
import "./Vehicles.css";
import { Amplify, API } from "aws-amplify";
import awsconfig from "../../aws-exports.js";
import AmplifyServices from "../../Service/Service";
import moment from "moment";
import ListTable from "../../Components/ListTable";
import { Menu } from "primereact/menu";
import { listDxVehicleActivities, listDxVehicles } from "../../graphql/queries";
import dxService from "../../Services/dxService";
import ListTemplate from "../../Components/ListTemplate";
import { _listDxVehicles } from "../../dxgraphql/dxqueries";
import awsExports from "../../aws-exports.js";
import { Storage } from "aws-amplify";
import { required } from "yargs";
import { getNextQueryData } from "../../utils/helper";
import { CSVLink } from "react-csv";
//Amplify.configure(awsconfig);
var EditNavigation: any = "";
var imgext: any = "";
var alldata: any = [];
const Vehicles = (props: any) => {
  // SelectedItems=props.selectedProducts
  let emptyProduct = {
    id: "",
    make: "",
    image: "",
    model: "",
    platecode: "",
    plateno: "",
  };
  const [deleteProductDialog, setDeleteProductDialog] =
    useState<boolean>(false);
  const [deleteProductsDialog, setDeleteProductsDialog] =
    useState<boolean>(false);
  const [product, setProduct] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const [VehicleData, setVehicleData] = useState<any>([]);
  const [S3Images, setS3Images] = useState<any>([]);
  const [VehicleDataDelete, setVehicleDataDelete] = useState<any>([]);
  const [FilterData, setFilterData] = useState<any>("");
  const [SelectedItems, setSelectedItems] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const toast = useRef<any>(null);
  const actionmenu = useRef<any>(null);
  const dt = useRef<any>(null);

  useEffect(() => {}, [SelectedItems]);

  useEffect(() => {
    setSelectedProducts(props.selectedProducts);
    setSelectedItems(props.selectedProducts ? props.selectedProducts : []);
    initFilters().then(async (suc: any) => {
      await getData().then(async (res) => {
        await res.map(async (item: any) => {
          if (item.ImagePath !== null) {
            let vehicleimage = await Storage.get(item.ImagePath);
            item["vehiclelogo"] = vehicleimage;
          } else {
            item["vehiclelogo"] = "";
          }

          await suc.filter(async (makeimg: any) => {
            if (makeimg.key.toLowerCase().includes(item.make.toLowerCase())) {
              await fetchImgUrl(makeimg.key).then(async (S3Img: any) => {
                await (item["makelogo"] = S3Img);

                setVehicleData((oldArray: any) => [...oldArray, item]);
                setLoading(false);
              });
            } else {
              setVehicleData((oldArray: any) => [...oldArray, item]);
              setLoading(false);
            }
          });
        });
      });
    });
  }, []);
  const getData = async (nextToken?: any) => {
    let variables: any = {};
    let GetResponse: any = await dxService.listitems(
      "vehicle",
      "*",
      variables,
      true,
      300,
      nextToken
    );

    let response = GetResponse.items;
    let nexttoken = GetResponse.nextToken;
    window.onscroll = async function (ev) {
      if (
        window.innerHeight + window.scrollY + 10 >=
          document.body.scrollHeight &&
        nexttoken
      ) {
        await getData(nexttoken).then((res) => {
          setVehicleData((olditems: any) => [...olditems, ...res]);
        });
      }
    };
    return response;
  };
  const SearchOnchange = (e: any) => {
    setFilterData(e.target.value);
  };
  const clearbtn = () => {
    setFilterData("");
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };
  const navigate = useNavigate();
  //const newNavigateClick = () => navigate('/manage/vehicles/submitVehicleform?form=add');
  const newNavigateClick = () =>
    navigate("/manage/vehicles/item/basic?mode=new");
  //const editNavigateClick = (rowData: any) => navigate(`/manage/vehicles/submitVehicleform?form=edit&${rowData.id}`);
  //http://localhost:3000/#/manage/vehicles/submitVehicleform/edit?form=edit&id=S2226&fleettype=vehicle
  EditNavigation = SelectedItems != undefined && SelectedItems.slice(-1);

  const deleteProduct = async () => {
    var DeleteData = [];
    DeleteData.push(VehicleDataDelete);

    let _products = VehicleData.filter(
      (val: any) => val.id !== VehicleDataDelete.id
    );
    // setVehicleData(o);
    setVehicleData(_products);
    setDeleteProductDialog(false);

    setVehicleDataDelete(emptyProduct);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = async () => {
    SelectedItems.forEach(async (item: any) => {
      let filterva = {
        id: {
          eq: item.id,
        },
      };
      await getNextQueryData("", filterva, "vehicleactivity").then(
        async (res: any) => {
          let deleteDriver = await dxService.deleteitem(
            "vehicleactivity",
            res[0].id,
            res[0]._version
          );
        }
      );

      let deleteDriver = await dxService.deleteitem(
        "vehicle",
        item.id,
        item._version
      );
      setDeleteProductsDialog(false);
      setSelectedProducts(null);
      setSelectedItems([]);
      SelectedItems.length = 0;
      getData().then((res: any) => {
        setVehicleData(res);
        toast.current.show({
          severity: "success",
          summary: "Vehicle deleted sucessfully",
          // detail: detail,
          life: 3000,
        });
      });
    });
  };
  const ArchiveSelectedvehicle = async () => {
    SelectedItems.forEach(async (item: any) => {
      let filterva = {
        id: {
          eq: item.id,
        },
      };
      await getNextQueryData("", filterva, "vehicleactivity").then(
        async (res: any) => {
          let updateva = {
            id: res[0].id,
            _version: res[0]._version,
            isarchived: true,
          };
          let updatedvadata = await dxService.updateitem(
            "vehicleactivity",
            updateva
          );
          // let deleteDriver = await dxService.deleteitem(
          //   "vehicleactivity",
          //   res[0].id,
          //   res[0]._version
          // );
        }
      );
      let updateveh = {
        id: item.id,
        _version: item._version,
        isarchived: true,
      };
      let updatevehdata = await dxService.updateitem("vehicle", updateveh);
      // let deleteDriver = await dxService.deleteitem(
      //   "vehicle",
      //   item.id,
      //   item._version
      // );

      setSelectedProducts(null);
      setSelectedItems([]);
      SelectedItems.length = 0;
      getData().then((res: any) => {
        setVehicleData(res);
        toast.current.show({
          severity: "success",
          summary: "Vehicle Archived sucessfully",
          // detail: detail,
          life: 3000,
        });
      });
    });
  };
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </>
  );
  const deleteProductsDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </>
  );
  var SortVehicleData: any = VehicleData.filter(
    (item: any) => item._deleted !== true && item.isarchived !== true
  )
    .filter((searchfil: any) => {
      if (FilterData === "") {
        return searchfil;
      } else if (
        searchfil.make !== null &&
        searchfil.make.toLowerCase().includes(FilterData.toLowerCase())
      ) {
        return FilterData;
      } else if (
        searchfil.model !== null &&
        searchfil.model.toLowerCase().includes(FilterData.toLowerCase())
      ) {
        return FilterData;
      } else if (
        searchfil.id !== null &&
        searchfil.id.toLowerCase().includes(FilterData.toLowerCase())
      ) {
        return FilterData;
      } else if (
        searchfil.department !== null &&
        searchfil.department.name
          .toLowerCase()
          .includes(FilterData.toLowerCase())
      ) {
        return FilterData;
      } else if (
        searchfil.capacity !== null &&
        searchfil.capacity.toLowerCase().includes(FilterData.toLowerCase())
      ) {
        return FilterData;
      } else if (
        searchfil.status !== null &&
        searchfil.status.toLowerCase().includes(FilterData.toLowerCase())
      ) {
        return FilterData;
      }
    })
    .sort((a: any, b: any) => {
      const dateA = a.enddate
        ? new Date(a.enddate).getTime()
        : Number.MAX_SAFE_INTEGER;
      const dateB = b.enddate
        ? new Date(b.enddate).getTime()
        : Number.MAX_SAFE_INTEGER;
      return dateA - dateB || +(a.enddate === null) - +(b.enddate === null);
    })
    .filter(
      (item: any, index: any, self: any) =>
        self.findIndex((i: any) => i.id === item.id) === index
    );
  const fetchImgUrl = async (make: any) => {
    // let url = await Storage.get(`images/logo/${make.toLowerCase()}.jpeg`);
    let url = await Storage.get(`${make}`);
    return url;
  };
  const imageBodyTemplate = (rowData: any) => {
    var makename = rowData.make;
    var imgext =
      rowData.make !== null ? makename.toLowerCase() + ".jpeg" : "NoLogo.jpeg";
    const Logo: any = rowData.makelogo;

    return (
      rowData.makelogo !== undefined && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <span className="p-column-title">Image</span> */}
          {/* <img src={`https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/images/logo/${imgext}`} className="product-image" width="32" /> */}
          <img
            src={Logo}
            className="product-image mr-1"
            width="30"
            style={{ borderRadius: "50%", height: "30px", objectFit: "cover" }}
          />
          <span>{rowData.make}</span>
        </div>
      )
    );
    //https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/images/logo/${imgext}`
  };
  const actionExpiryTemplate = (e: any) => {
    return e.enddate ? moment(e.enddate).format("DD-MMM-YYYY") : "-";
  };
  const VehicleimageBodyTemplate = (rowData: any) => {
    return (
      rowData.ImagePath !== null && (
        <>
          <span className="p-column-title">Image</span>
          {/* <img src={`https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/images/logo/${imgext}`} className="product-image" width="32" /> */}
          <img
            src={rowData.vehiclelogo}
            className="product-image"
            width="30px"
            style={{ borderRadius: "50%", height: "30px", objectFit: "cover" }}
          />
        </>
      )
    );
  };
  const driverStatusBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.status == "InActive"
          ? "Inactive"
          : rowData.status == "Active"
          ? "Active"
          : ""}
      </>
    );
  };

  const columns = [
    // { fieldtype:'multiselect'},
    {
      field: "id",
      header: "Plate No",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "href",
      filterOption: true,
      filterFieldOption: "id",
      path: "#/manage/reports/item/?mode=view&id=",
      sortable: true,
      sortField: "id",
    },

    {
      field: "ImagePath",
      header: "Image",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "image",
      filterOption: false,
      template: (r: any) => <>{VehicleimageBodyTemplate(r)}</>,
    },

    {
      field: "department",
      header: "Department",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "lookup",
      filterOption: true,
      filterFieldOption: "department.name",
    },
    {
      field: "capacity",
      header: "Capacity",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "capacity",
    },
    {
      field: "enddate",
      header: "Expiry Date",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "date",
      sortable: true,
      highlight: true,
    },

    {
      field: "status",
      header: "Status",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "status",
      template: (r: any) => <>{driverStatusBodyTemplate(r)}</>,
    },
    {
      field: "make",
      header: "Make",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "make",
      template: (r: any) => <>{imageBodyTemplate(r)}</>,
    },
    {
      field: "model",
      header: "Model",
      headeralign: "left",
      bodyalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "model",
    },

    // { field: 'fleettype', header: 'Actions', fieldtype: 'custom', filterOption: false, template: (r: any) => <>{actionBodyTemplate2(r)}</> }
    // {field: 'quantity', header: 'Quantity'}
  ];

  const initFilters = () => {
    let s3ButketImages = Storage.list("images/logo") // for listing ALL files without prefix, pass '' instead
      .then(({ results }) => {
        //   results.filter((img: any) => {
        //     //   if (img.key.includes(rowData.make)) {
        //     //   }
        //   });

        setS3Images(results);
        return results;
      })
      .catch((err) => console.error(err));
    return s3ButketImages;
  };
  const exportdata = (data: any) => {
    // console.log(data);
    let items: any = [];
    data.map((expitem: any) => {
      let a = {
        "Vehicle no": expitem.id,
        Capacity: expitem.capacity,
        "Chasis no": expitem.chasisno,
        "Department Name": expitem.department ? expitem.department.name : "",
        Enddate: expitem.enddate,
        Fuelno: expitem.fuelno,
        "Insurance no": expitem.insuranceno,
        Make: expitem.make,
        Model: expitem.model,
        "Plate code": expitem.platecode,
        "Plate no": expitem.plateno,
        "Registration no": expitem.registrationno,
        "Salik no": expitem.salikno,
        "Salik pin": expitem.salikpin,
        Scno: expitem.scno,
        Scvendor: expitem.scvendor,
        Startdate: expitem.startdate,
        Status: expitem.status,
        "Vehicle Owner": expitem.vowner,
        Year: expitem.year,
      };
      items.push(a);
    });
    return items;
  };

  const csvLink = {
    filename: `Vehicledata`,
    // header: header,
    data: SortVehicleData.length > 0 ? exportdata(SortVehicleData) : [],
  };

  const Commandbar = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="table-header-container">
        <Button
          icon="pi pi-plus"
          label="New"
          onClick={newNavigateClick}
          className="p-button-primary mr-2 mb-2 newbtnhover"
        />

        <Button
          type="button"
          label="Actions"
          icon="pi pi-angle-down"
          className={"mb-2"}
          onClick={(e) => actionmenu.current.toggle(e)}
        />
        <Menu
          ref={actionmenu}
          popup
          model={[
            {
              disabled:
                SelectedItems != undefined && SelectedItems.length == 1
                  ? false
                  : true,
              label: "Edit",
              icon: "pi pi-fw pi-pencil",
              command: () => {
                navigate(
                  `/manage/vehicles/item/basic?mode=edit&id=${
                    SelectedItems != undefined && EditNavigation[0].id
                  }`
                );
              },
            },
            {
              disabled:
                SelectedItems != undefined && SelectedItems.length > 0
                  ? false
                  : true,
              label: "Delete",
              icon: "pi pi-fw pi-trash",
              command: () => {
                confirmDeleteSelected();
              },
            },
            {
              disabled:
                SelectedItems != undefined && SelectedItems.length > 0
                  ? false
                  : true,
              label: "Archive",
              icon: "pi pi-fw pi-arrow-right",
              command: () => {
                ArchiveSelectedvehicle();
              },
            },
          ]}
        ></Menu>
      </div>
      <span>
        {SelectedItems &&
          SelectedItems.length + " of " + SortVehicleData.length + " selected"}
      </span>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <div className="field col">
          <input
            value={FilterData}
            type="text"
            id="search"
            onChange={SearchOnchange}
            placeholder="Search ..."
            className="p-inputtext p-component homeNews__search"
          />
          {FilterData.length > 0 ? (
            <button
              type="button"
              className="contentBox__searchClearBtn"
              onClick={clearbtn}
            >
              X
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="field ">
          <CSVLink {...csvLink}>
            <span className="p-float-label export_renewals text-white">
              Export
            </span>
          </CSVLink>
        </div>
      </div>
    </div>
  );
  // console.log(SortVehicleData);

  return (
    <div className="grid">
      <div className="col-12 md:col-12">
        <h4 className="container-header">Manage Vehicles</h4>

        <Toast ref={toast} />

        {/* <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar> */}
        <ListTemplate
          header="Manage Vehicles"
          value={SortVehicleData}
          paginator={false}
          // rows={100}
          dynamicColumns={columns}
          emptyMessage="No Vehicles found."
          responsiveLayout="scroll"
          className="datatable-responsive"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vehicles"
          // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          // rowsPerPageOptions={[5, 10, 25]}
          //   selection={selectedProducts}
          multiselect={true}
          SelectedItems={selectedProducts}
          Commandbar={Commandbar}
          loading={loading}
          selectedItems={setSelectedItems}
          //   onSelectionChange={e => setSelectedProducts(e.value)}
        />

        <Dialog
          visible={deleteProductDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductDialogFooter}
          onHide={hideDeleteProductDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {VehicleDataDelete && (
              <span>
                Are you sure you want to delete{" "}
                <b>{`${
                  VehicleDataDelete.platecode + VehicleDataDelete.plateno
                }`}</b>
                ?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>
                Are you sure you want to delete the selected products?
              </span>
            )}
          </div>
        </Dialog>
      </div>
    </div>
  );
};
export default Vehicles;
