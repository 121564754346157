import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import ListTable from "../../Components/ListTable";
import { Menu } from "primereact/menu";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { deleteDxContract } from "../../graphql/mutations";
import { Dropdown } from "primereact/dropdown";
import { API, graphqlOperation } from "aws-amplify";
import { listDxContracts } from "../../graphql/queries";
import moment from "moment";
import { Toast } from "primereact/toast";
import { CSVLink } from "react-csv";
import { convertdate, getNextQueryData } from "../../utils/helper";
import dxService from "../../Services/dxService";
let params: any;

const ManageDocuments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  var type = urlParams.has("type") ? urlParams.get("type") : "-";
  let url =
    type !== null && type == "upcoming"
      ? "Upcoming Renewals"
      : type == "expired"
      ? "Expired Renewals"
      : null;
  var Rtype = urlParams.has("Rtype") ? urlParams.get("Rtype") : null;
  let Rtypeurl =
    Rtype !== null && Rtype == "Branding"
      ? "Branding"
      : Rtype == "Parking"
      ? "Parking"
      : null;
  const menu = useRef<any>(null);
  var status = urlParams.has("status") ? urlParams.get("status") : "-";
  const [selectedValue, setSelectedValue] = useState<any>({});
  const [items, setItems] = useState<any>(null);
  const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);
  const [Vehiclestatuskey, setVehiclestatuskey] = useState<any>(null);
  const [VehcileViewfilter, setVehcileViewfilter] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [filteredData, setfilteredData] = useState<any>([]);
  const [filtervehicleid, setfiltervehicleid] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);
  const toast = useRef<any>(null);
  const [renewalfilter, setrenewalfilter] = useState<any>(null);
  params =
    selectedItems.length > 0 &&
    `&id=${selectedItems[0].id}&vid=${selectedItems[0].vehicleid}&type=${selectedItems[0].renewaltype}`;
  const sortOptions = [
    { label: "Show All", value: "-" },
    { label: "Active", value: "Active" },
    { label: "Completed", value: "Completed" },
    { label: "In Progress", value: "In Progress" },
  ];
  const sortViewOptions = [
    { label: "Show All Renewals", value: "-" },
    { label: "Upcoming Renewals", value: "Upcoming Renewals" },
    { label: "Expired Renewals", value: "Expired Renewals" },
  ];
  const ContractType = [
    { label: "Show All", value: "-" },
    { label: "Insurance", value: "Insurance" },
    { label: "Registration", value: "Registration" },
    { label: "Branding", value: "Branding" },
    { label: "ServiceContract", value: "ServiceContract" },
    { label: "Parking", value: "Parking" },
    { label: "Warranty", value: "Warranty" },
  ];

  useEffect(() => {
    getData();
  }, []);
  /**
   * getData() gets all the renewals form the Db
   */
  const getData = async (nextToken?: any) => {
    let variables: any = {};
    const ac = `expirydate id issuedate renewalno renewalstatus renewaltype scvendor updatedAt vehicleid _deleted _version`;
    await getNextQueryData("", variables, "renewal", true, ac).then(
      (res: any) => {
        let finalres: any = res
          .filter((item: any) => item !== undefined && item._deleted !== true)
          .sort(function (a: any, b: any) {
            return moment(b.createdAt)
              .format()
              .localeCompare(moment(a.createdAt).format());
          });
        setItems(finalres);
        setfilteredData(formatDatefield(finalres));
        setLoading(false);
        setVehcileViewfilter(url);
        setrenewalfilter(Rtypeurl);
        setVehiclestatuskey(status);
      }
    );
  };
  /**
   * formatDatefield() formats the issuedate column and expirydate column
   */
  const formatDatefield = (data: any) => {
    return [...(data || [])].map((d) => {
      d.issuedate = new Date(d.issuedate);
      d.expirydate = new Date(d.expirydate);
      return d;
    });
  };
  const hideDeleteItemsDialog = () => {
    setDeleteItemsDialog(false);
  };

  const confirmDeleteSelected = () => {
    setDeleteItemsDialog(true);
  };
  /** issueDateBodyTemplate() custom template to format the issuedate column*/
  const issueDateBodyTemplate = (e: any) => {
    if (e.issuedate !== null) {
      let issueDate = e.issuedate;

      return <>{issueDate && new Date(issueDate).toDateString()}</>;
    }
  };
  /** expiryDateBodyTemplate() custom template to format the expirydate column and highlight
     based on the expirydate and already expired items
    */
  const expiryDateBodyTemplate = (e: any) => {
    // console.log('in');

    if (
      e.expirydate === null ||
      moment(e.expirydate).format("MMM-DD-YY") == "Jan-01-70"
    )
      return "";

    const expirydate = new Date(e["expirydate"]);
    if (!expirydate) return "";
    const today = new Date();
    const notificationDays =
      e["renewaltype"] === "Parking" || e["renewaltype"] === "Branding"
        ? 7
        : 15;
    if (
      e["renewalstatus"] !== "Completed" &&
      expirydate.getTime() - today.getTime() <=
        notificationDays * 24 * 60 * 60 * 1000
    ) {
      // Add highlight class for notification
      // console.log(expirydate, today);
      if (expirydate < today) {
        let expiry = e.expirydate;
        return (
          <div className="highlight-red">
            {expiry && new Date(expiry).toDateString()}
          </div>
        );
      } else {
        let expiry = e.expirydate;
        return (
          <div className="highlight-orange">
            {expiry && new Date(expiry).toDateString()}
          </div>
        );
      }
    } else {
      // No highlight class
      let expiry = e.expirydate;
      return <div>{expiry && new Date(expiry).toDateString()}</div>;
    }
  };
  /** contractStatusBodyTemplate() custom template to format the status column*/
  const contractStatusBodyTemplate = (e: any) => {
    if (e.renewalstatus !== null) {
      let status = e.renewalstatus;
      return (
        <>
          {status && (
            <span
              className={`customer-badge status-${status.replaceAll(" ", "")}`}
            >
              {status}
            </span>
          )}
        </>
      );
    }
  };
  /**columns- required columns to display in Manage Renewal table */
  const columns = [
    // { fieldtype: 'multiselect' },
    {
      field: "vehicleid",
      header: "Vehicle",
      textalign: "left",
      fieldtype: "navigatedocument",
      filterOption: true,
      filterFieldOption: "vehicleid",
      sortable: true,
      sortField: "vehicleid",
    },
    // { field: 'model', header: 'Model', fieldtype: 'text', filterOption: true, filterFieldOption: 'model' },
    {
      field: "renewaltype",
      header: "Renewal Type",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "renewaltype",
      sortable: true,
      sortField: "renewaltype",
      textalign: "left",
      //  template: (r: any) => <>{serviceTypeBodyTemplate(r)} </>
    },
    {
      field: "renewalno",
      header: "Renewal No",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "renewalno",
      sortable: true,
      sortField: "renewalno",
      textalign: "left",
      //  template: (r: any) => <>{serviceTypeBodyTemplate(r)} </>
    },
    {
      field: "issuedate",
      header: "Issue Date",
      fieldtype: "datefilter",
      dataType: "date",
      filterOption: true,
      showtime: false,
      filterFieldOption: "issuedate",
      template: (r: any) => <>{issueDateBodyTemplate(r)} </>,
      sortable: true,
      sortField: "issuedate",
      textalign: "left",
    },
    {
      field: "expirydate",
      header: "Expiry Date",
      fieldtype: "hightlightdatefilter",
      dataType: "date",
      showtime: false,
      filterOption: true,
      filterFieldOption: "expirydate",
      template: (r: any) => <>{expiryDateBodyTemplate(r)}</>,
      sortable: true,
      sortField: "expirydate",

      highlight: true,
      textalign: "left",
    },
    {
      field: "renewalstatus",
      header: "Renewal Status",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "renewalstatus",

      template: (r: any) => <>{contractStatusBodyTemplate(r)}</>,
      textalign: "left",
    },
    {
      field: "updatedAt",
      header: "Updated At",
      fieldtype: "date",
      textalign: "left",
      filterOption: false,
      filterFieldOption: "updatedAt",
    },
  ];

  /**deleteItemsDialogFooter- footer for the delete popup box */
  const deleteItemsDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text companyBtn"
        onClick={hideDeleteItemsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text companyBtn"
        onClick={() => deleteItem()}
      />
    </>
  );
  /**deleteItem()- deletes the renewal item */
  const deleteItem = async () => {
    Promise.all(
      selectedItems.map(async (item: any) => {
        let deleteData = {
          id: item.id,

          _version: item._version,
        };
        await API.graphql(
          graphqlOperation(deleteDxContract, {
            input: deleteData,
          })
        );
        let filtervehicleactivity = {
          id: {
            eq: item.vehicleid,
          },
        };

        await getNextQueryData(
          "",
          filtervehicleactivity,
          "vehicleactivity",
          true
        ).then(async (vres) => {
          try {
            const parsedExpiring = JSON.parse(vres[0].expiring);
            if (parsedExpiring[item.renewaltype] !== undefined) {
              // console.log('Property exists, deleting...');
              delete parsedExpiring[item.renewaltype];
              // OR use delete parsedExpiring[item.renewaltype];
              // console.log('Deleted:', parsedExpiring);
              // If you want to update the 'expiring' property in the original object:
              let newexpiring = JSON.stringify(parsedExpiring);
              let vehicleactivity = {
                id: item.vehicleid,
                expiring: newexpiring,
                _version: vres[0]._version,
              };
              let os = await dxService.updateitem(
                "vehicleactivity",
                vehicleactivity
              );
            } else {
              console.log("Property does not exist.");
            }
          } catch (error) {
            console.error("Error parsing or deleting:", error);
          }
        });
        getData();
        hideDeleteItemsDialog();
        setSelectedItems([]);
        selectedItems.length = 0;
        toast.current.show({
          severity: "success",
          summary: "Renewal deleted sucessfully",
          // detail: detail,
          life: 3000,
        });
      })
    );
  };

  const onSortChange = (event: any) => {
    const value = event.value;
    setVehiclestatuskey(value);
  };

  const SearchOnvehiclechange = (e: any) => {
    setfiltervehicleid(e.target.value);
  };
  /**exportdata()- export the data to excel */
  const exportdata = (data: any) => {
    let items: any = [];
    data.map((exportitem: any) => {
      let a = {
        Vehicle: exportitem.vehicleid,
        "Renewal Type": exportitem.renewaltype,
        "Renewal No": exportitem.renewalno,
        "Issue Date":
          exportitem.issuedate &&
          (exportitem.issuedate === null ||
            moment(exportitem.issuedate).format("MMM-DD-YY") == "Jan-01-70")
            ? ""
            : moment(exportitem.issuedate).format("DD-MMM-YYYY"),
        "Expiry Date":
          exportitem.expirydate &&
          (exportitem.expirydate === null ||
            moment(exportitem.expirydate).format("MMM-DD-YY") == "Jan-01-70")
            ? ""
            : new Date(exportitem.expirydate).toDateString(),
        "Renewal Status": exportitem.renewalstatus,
        "Updated At":
          exportitem.updatedAt &&
          moment(exportitem.updatedAt).format("DD-MMM-YYYY"),
      };
      items.push(a);
    });

    return items;
  };

  useEffect(() => {
    if (items !== null && items.length > 0) {
      let vs = Vehiclestatuskey;

      let txtvid = filtervehicleid;
      let view = VehcileViewfilter;
      let renewtype = renewalfilter;
      let searchkeys: any = {};
      if (txtvid !== "" && txtvid !== undefined)
        searchkeys["vehicleid"] = { value: txtvid, condition: "includes" };
      if (status !== null) {
        if (vs !== undefined && vs !== null && vs !== "-")
          searchkeys["renewalstatus"] = { value: vs, condition: "active" };
      }
      if (vs !== undefined && vs !== null && vs !== "-")
        searchkeys["renewalstatus"] = { value: vs, condition: "eq" };

      if (view !== null && view === "Upcoming Renewals") {
        searchkeys["expirydate"] = { value: view, condition: "Upcoming" };
      } else if (view !== null && view === "Expired Renewals") {
        searchkeys["expirydate"] = { value: view, condition: "Expired" };
      }
      if (renewtype != undefined && renewtype != null && renewtype !== "-")
        searchkeys["renewaltype"] = { value: renewtype, condition: "eq" };

      let fr = items
        .sort(function (a: any, b: any) {
          return moment(b.createdAt)
            .format()
            .localeCompare(moment(a.createdAt).format());
        })
        .filter((item: any) => {
          return Object.keys(searchkeys).every((a: any) => {
            if (Rtype !== null) {
              if (searchkeys[a].condition === "eq")
                return (
                  item[a] !== null &&
                  searchkeys[a].value !== null &&
                  item[a].toLowerCase() === searchkeys[a].value.toLowerCase() &&
                  item.renewalstatus === "Active"
                );
            } else {
              if (searchkeys[a].condition === "eq")
                return (
                  item[a] !== null &&
                  searchkeys[a].value !== null &&
                  item[a].toLowerCase() === searchkeys[a].value.toLowerCase()
                );
            }
            if (searchkeys[a].condition === "active")
              return (
                item[a] !== null &&
                searchkeys[a].value !== null &&
                item[a].toLowerCase() === searchkeys[a].value.toLowerCase() &&
                item.renewalstatus === "Active"
              );
            if (searchkeys[a].condition === "status")
              return (
                item[a] !== null &&
                searchkeys[a].value !== null &&
                item[a].toLowerCase() === searchkeys[a].value.toLowerCase()
              );
            if (searchkeys[a].condition == "includes") {
              return (
                item[a] !== null &&
                searchkeys[a].value !== null &&
                item[a]
                  .toLowerCase()
                  .includes(searchkeys[a].value.toLowerCase())
              );
            }
            if (searchkeys[a].condition == "Upcoming") {
              let expdate = item[a];

              let today = new Date();

              const next15days = new Date(
                today.getTime() + 16 * 24 * 60 * 60 * 1000
              );
              const next7days = new Date(
                today.getTime() + 8 * 24 * 60 * 60 * 1000
              );

              if (
                item.renewaltype == "Registration" &&
                moment(item.expirydate).isBetween(new Date(), next15days) &&
                item.renewalstatus !== "Completed"
              ) {
                return true;
              }
              if (
                item.renewaltype == "Insurance" &&
                moment(item.expirydate).isBetween(new Date(), next15days) &&
                item.renewalstatus !== "Completed"
              ) {
                return true;
              }
              if (
                item.renewaltype == "ServiceContract" &&
                moment(item.expirydate).isBetween(new Date(), next15days) &&
                item.renewalstatus !== "Completed"
              ) {
                return true;
              }
              if (
                item.renewaltype == "Warranty" &&
                moment(item.expirydate).isBetween(new Date(), next15days) &&
                item.renewalstatus !== "Completed"
              ) {
                return false;
              }
              if (
                item.renewaltype == "Parking" &&
                moment(item.expirydate).isBetween(new Date(), next7days) &&
                item.renewalstatus !== "Completed"
              ) {
                return true;
              }
              if (
                item.renewaltype == "Branding" &&
                moment(item.expirydate).isBetween(new Date(), next7days) &&
                item.renewalstatus !== "Completed"
              ) {
                return true;
              }
            }
            if (searchkeys[a].condition == "Expired") {
              let expdate = item[a];

              let today = new Date();

              return (
                expdate < today &&
                moment(expdate).format("MMM-DD-YYYY") !==
                  moment(new Date(1970, 0, 1)).format("MMM-DD-YYYY") &&
                item.renewalstatus !== "Completed" &&
                item.renewaltype !== "Warranty"
              );
            }
            return false;
          });
        });

      setfilteredData(fr);
      setLoading(false);
    }
  }, [filtervehicleid, Vehiclestatuskey, renewalfilter, VehcileViewfilter]);
  const header = [
    { label: "Vehicle", key: "Vehicle" },
    { label: "Renewal Type", key: "Renewal Type" },
    { label: "Renewal No", key: "Renewal No" },
    { label: "Issue Date", key: "Issue Date" },
    { label: "Expiry Date", key: "Expiry Date" },
    { label: "Renewal Status", key: "Renewal Status" },
    { label: "Updated At", key: "Updated At" },
  ];
  const csvLink = {
    filename: `Renewals${renewalfilter !== null ? "_" + renewalfilter : ""}${
      VehcileViewfilter !== null ? "_" + VehcileViewfilter : ""
    }_${Vehiclestatuskey}`,
    header: header,
    data: filteredData.length > 0 ? exportdata(filteredData) : [],
  };

  const Commandbar = (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="table-header-container">
          <Button
            icon="pi pi-plus"
            style={{ height: "38px" }}
            label="New"
            onClick={() =>
              navigate("/manage/documents/item/basic?mode=new&type=")
            }
            className="p-button-primary mr-2  newbtnhover"
          />

          <Button
            type="button"
            style={{ height: "38px" }}
            label="Actions"
            icon="pi pi-angle-down"
            onClick={(e) => menu.current.toggle(e)}
          />

          <Menu
            ref={menu}
            popup
            model={[
              {
                // disabled: selectedItems.length === 1 ? false : true,
                disabled:
                  selectedItems.length > 0 && selectedItems.length === 1
                    ? false
                    : true,
                label: "Edit",
                icon: "pi pi-fw pi-pencil",
                command: () => {
                  navigate("/manage/renewals/item/basic?mode=edit" + params);
                },
              },
              {
                // disabled: selectedItems.length === 1 ? false : true,
                disabled:
                  selectedItems.length > 0 && selectedItems.length === 1
                    ? false
                    : true,
                label:
                  selectedItems.length > 0 &&
                  selectedItems[0].renewalstatus == "In Progress"
                    ? "Close Appointment"
                    : "Renew",
                icon: "pi pi-fw pi-pencil",
                command: () => {
                  selectedItems[0].renewalstatus == "In Progress"
                    ? navigate(
                        `/manage/renewals/item/basic?mode=Close&id=${selectedItems[0].id}&vid=${selectedItems[0].vehicleid}&type=${selectedItems[0].renewaltype}`
                      )
                    : navigate(
                        `/general/workspace/renew?&id=${selectedItems[0].vehicleid}&exp=${selectedItems[0].renewaltype}`
                      );
                },
              },
              {
                disabled: selectedItems.length > 0 ? false : true,
                label: `Delete`,
                icon: "pi pi-fw pi-trash",
                command: () => {
                  confirmDeleteSelected();
                },
              },
            ]}
          ></Menu>
        </div>

        {/* <div> */}
        {/* <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <form>
                        <div
                            style={{
                                position: 'relative',
                                marginTop: '3px',
                                marginBottom: '1em'
                            }}
                        >
                            <InputText type="text" id={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
                        </div>
                    </form>
                </span> */}
        {/* <div className="container-space"> */}
        <div className="grid grid-nogutter ">
          <div className="col-12 p-fluid" style={{ textAlign: "left" }}>
            <div
              className="formgrid grid"
              style={{ display: "flex", flexWrap: "nowrap" }}
            >
              <div className="field col">
                <span className="p-float-label">
                  <Dropdown
                    id="ddsearchstatus"
                    value={renewalfilter}
                    options={ContractType}
                    optionLabel="label"
                    placeholder="Filter by renewal type"
                    onChange={(e) => setrenewalfilter(e.value)}
                  />
                  <label htmlFor="ddsearchstatus">Renewal Type</label>
                </span>
              </div>
              <div className="field col">
                <span className="p-float-label">
                  <Dropdown
                    id="ddsearchstatus"
                    value={VehcileViewfilter}
                    options={sortViewOptions}
                    optionLabel="label"
                    placeholder="Filter by view"
                    onChange={(e) => setVehcileViewfilter(e.value)}
                  />
                  <label htmlFor="ddsearchstatus">Renewal Views</label>
                </span>
              </div>
              <div className="field col">
                <span className="p-float-label">
                  <Dropdown
                    id="ddsearchstatus"
                    value={Vehiclestatuskey}
                    options={sortOptions}
                    optionLabel="label"
                    placeholder="Filter by Renewal Status"
                    onChange={onSortChange}
                  />
                  <label htmlFor="ddsearchstatus">Renewal Status</label>
                </span>
              </div>
              {/* <div className="field col">
                                    <span className="p-float-label">
                                        <Dropdown id="ddsearchstatus" value={filterServiceContract} options={ContractType} optionLabel="label" placeholder="Filter by Renewal Status" onChange={onSCChange} />
                                        <label htmlFor="ddsearchstatus">Renewal Status</label>
                                    </span>
                                </div> */}
              <div className="field col">
                <span className="p-float-label">
                  <input
                    value={filtervehicleid}
                    type="text"
                    id="txtsearchvehicleid"
                    onChange={SearchOnvehiclechange}
                    placeholder="Search Vehicle..."
                    className="p-inputtext p-component homeNews__search pb-0"
                  />
                  <label htmlFor="txtsearchvehicleid">Vehicle</label>
                </span>
              </div>
              <div className="field ">
                <CSVLink {...csvLink}>
                  <span className="p-float-label export_renewals text-white">
                    Export
                  </span>
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
      {selectedItems && selectedItems.length > 0 && (
        <span>
          {selectedItems.length + " of " + filteredData.length + " selected"}
        </span>
      )}
    </>
  );

  return (
    <div className="grid crud">
      <div className="col-12">
        <h4 className="container-header">Manage Renewals</h4>
        <div className="datatable-editing-demo service-table">
          <Toast ref={toast} />
          <ListTable
            header="Manage Contracts"
            value={filteredData}
            paginator={true}
            rows={100}
            dynamicColumns={columns}
            emptyMessage="No result found."
            responsiveLayout="scroll"
            className="p-datatable-responsive"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[100, 200, 300]}
            //   selection={selectedProducts}
            multiselect={true}
            loading={loading}
            Commandbar={Commandbar}
            //   onSelectionChange={e => setSelectedProducts(e.value)}
            selectedItems={setSelectedItems}
          />
          <Dialog
            visible={deleteItemsDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteItemsDialogFooter}
            onHide={hideDeleteItemsDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {selectedItems && (
                <span>Are you sure you want to delete the selected items?</span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default ManageDocuments;
