import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import './MaintenanceReport.css';
import moment from 'moment';
import dxService from '../../Services/dxService';
import { convertdate } from '../../utils/helper';
import { CSVLink } from 'react-csv';
import { Dropdown } from 'primereact/dropdown';
import { _listDxVehicleSpares } from '../../dxgraphql/dxqueries';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface ConvertedDataItem {
    vehicleid: string;
    details: any[];
    amount: number;
    totalamount: any;
    quantity: number;
    tax: any;
}
const MaintenanceReport = () => {
    const [Reports, setReports] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [SelectedDate, setSelectedDate] = useState<any>([]);
    const [filteredData, setfilteredData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [expandVehicle, setExpandVehicle] = useState<any>([]);
    const [selectView, setSelectView] = useState<any>('');
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var sdate: any = urlParams.has('sdate') ? urlParams.get('sdate') : '';
    var edate: any = urlParams.has('edate') ? urlParams.get('edate') : '';
    var view: any = urlParams.has('view') ? urlParams.get('view') : '';
    //get the params value and set it to the state
    useEffect(() => {
        const getParamsDetails = () => {
            if (view !== '') {
                setSelectView(view);
            } else {
                setSelectView('Summary');
            }
            if (sdate !== '') {
                sdate = urlParams.has('sdate') ? urlParams.get('sdate') : '';
                edate = urlParams.has('edate') ? urlParams.get('edate') : '';
                let startDate = new Date(sdate);
                let endDate = edate ? new Date(edate) : startDate;
                setSelectedDate([startDate, endDate]);
            } else {
                let todate = new Date();
                let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
                let lastfirstdate = new Date(todate.getFullYear(), todate.getMonth() - 1, 1);
                setSelectedDate([lastfirstdate, lastenddate]);
            }
        };
        getParamsDetails();
    }, []);
    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        let variables: any = {};
        let nextToken: any = null;

        do {
            const logs = await dxService.listitems('vehiclespare', '*', variables, true, 1000, nextToken);
            const response = logs.items;
            nextToken = logs.nextToken;

            // Render the retrieved data here
            setReports((prevReports: any) => [...prevReports, ...formatDatefield(response)]);
            setfilteredData((prevFilteredData: any) => [...prevFilteredData, ...formatDatefield(response)]);
        } while (nextToken);

        // All data has been retrieved, setLoading to false
        setLoading(false);
    };

    const formatDatefield = (data: any) => {
        return [...(data || [])].map((d) => {
            if (d.appoinment !== null && d.appoinment !== undefined) {
                d.appointment.Date = new Date(d.appointment.Date);
                return d;
            } else {
                return d;
            }
        });
    };
    const ClearBtn = () => {
        setSelectedDate('');
    };
    if (SelectedDate !== null && SelectedDate.length > 0) {
        var ShowClrBtn: any = (
            <>
                <button
                    onClick={ClearBtn}
                    className={`clrbtn`}
                    // style={{ display: toggle, position: "relative", bottom: "5px" }}
                >
                    <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" style={{ color: '#db0000', fontWeight: 700 }}></i>
                    {/* &nbsp; Clear filter */}
                </button>
            </>
        );
    }
    {
    }
    const filterReports =
        Reports.length > 0
            ? Reports.filter((item: any) => {
                  if (SelectedDate.length == 0) {
                      return item;
                  } else if (
                      item.appointment !== null &&
                      item.appointment !== undefined &&
                      item.appointment.Date !== null &&
                      moment(item.appointment.Date).isBetween(moment(SelectedDate[0]).subtract(1, 'day').format('D-MMM-YY'), moment(SelectedDate[1]).add(1, 'day').format('D-MMM-YY'))
                  ) {
                      return item;
                  }
              })
            : [];
    const groupedData =
        filterReports &&
        filterReports.reduce((acc: { [x: string]: any }, obj: { vehicleid: any; amount: number; totalamount: number; quantity: number; jfield: any }) => {
            const { vehicleid, amount, totalamount, quantity } = obj;
            const tax = obj.jfield && JSON.parse(obj.jfield).tax ? JSON.parse(obj.jfield).tax : 0;

            if (!acc[vehicleid]) {
                acc[vehicleid] = {
                    details: [],
                    amount: 0,
                    totalamount: 0,
                    quantity: 0
                    // tax: 0
                };
            }
            acc[vehicleid].details.push(obj);
            if (!isNaN(Number(amount))) {
                acc[vehicleid].amount += Number(amount);
            }

            if (!isNaN(Number(totalamount))) {
                acc[vehicleid].totalamount += Number(totalamount);
            }

            if (!isNaN(Number(quantity))) {
                acc[vehicleid].quantity += Number(quantity);
            }
            // acc[vehicleid].tax += Number(tax);
            return acc;
        }, {});

    const convertedData: ConvertedDataItem[] = Object.keys(groupedData).map((vehicleid) => ({
        vehicleid,
        details: groupedData[vehicleid].details,
        amount: groupedData[vehicleid].amount.toFixed(2),
        totalamount: groupedData[vehicleid].totalamount.toFixed(2),
        quantity: groupedData[vehicleid].quantity,
        tax: groupedData[vehicleid].tax
    }));
    const totalVehicleCount = convertedData.reduce((acc, curr) => acc + curr.details.length, 0);
    const absoluteTotalQuantity = convertedData.reduce((acc, curr) => acc + Number(curr.quantity), 0);
    const absoluteAmount = convertedData.reduce((acc, curr) => acc + Number(curr.amount), 0);
    const absoluteTotalAmount = convertedData.reduce((acc, curr) => acc + Number(curr.totalamount), 0);
    let absoluteTotalAmountwithTax: any[] = [];
    if (filterReports && filterReports != null) {
        absoluteTotalAmountwithTax = filterReports.reduce((acc: number, curr: { jfield: string; totalamount: any }) => {
            const tax = curr.jfield && JSON.parse(curr.jfield).tax ? JSON.parse(curr.jfield).tax : 0;
            const totalAmountWithTax = Number(curr.totalamount) * (1 + Number(tax) / 100);
            return acc + Number(totalAmountWithTax.toFixed(2));
        }, 0);
    }

    // const colorfilter:any=Reports.length>0&&filterReports.filter((fil:any)=>{
    //     //  colorfilters=fil.Total>'AED 300'?'red':'blue'
    //     if(fil.Total> `AED 300`){
    //          return color='red'

    //     }else {

    //         return color='blue'
    //     }
    // })

    const actionTemplateTotal = (rowData: any, column: any) => {
        const tax = rowData.jfield && JSON.parse(rowData.jfield).tax ? JSON.parse(rowData.jfield).tax : 0;
        const totalamount = Number(rowData.totalamount);
        const taxAsNumber = Number(tax);
        const totalTax = totalamount * (taxAsNumber / 100);
        const totalAmountWithTax = totalTax + totalamount;
        let Classname = rowData.totalamount > 300 ? 'TotalColorcondition' : 'TotalColor';
        return (
            <>
                <a className={Classname} target="black">
                    AED {totalAmountWithTax.toFixed(2)}
                </a>
            </>
        );
    };

    const actionGaragename = (rowData: any, column: any) => {
        // let Classname = rowData.totalamount > 300 ? 'TotalColorcondition' : 'TotalColor';
        return (
            <>
                <a target="black">{rowData.appointment ? rowData.appointment.place : '-'}</a>
            </>
        );
    };
    const actionInvno = (rowData: any, column: any) => {
        // let Classname = rowData.totalamount > 300 ? 'TotalColorcondition' : 'TotalColor';
        return (
            <>
                <a target="black">{rowData.appointment ? rowData.appointment.invoiceno : '-'}</a>
            </>
        );
    };
    const actioncategory = (rowData: any, column: any) => {
        return (
            <>
                <a target="black">{rowData.partname ? JSON.parse(rowData.partname).toString() : '-'}</a>
            </>
        );
    };

    const dateBodyTemplate = (rowData: any) => {
        let value = rowData.invoiceno ? (
            <Link to={`/manage/appointments/item/documents?id=${rowData.appointmentid}&mode=view&vid=${rowData.vehicleid}`}>{rowData.appointment ? convertdate(rowData.appointment.Date) : '-'}</Link>
        ) : (
            <>{rowData.appointment ? convertdate(rowData.appointment.Date) : '-'}</>
        );

        return value;
    };
    const dateFilterTemplate = (options: any) => {
        return <Calendar value={options.value} onChange={(e: any) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };
    const exportdata = (data: any, type: any) => {
        let items: any = [];
        data.map((exportitem: any) => {
            let a = {
                'PLATE NUMBER': exportitem.vehicleid,
                'Total Repairs': exportitem.details.length,
                QTY: 'AED ' + exportitem.quantity,
                Amount: exportitem.amount,
                'Total Amount': 'AED ' + exportitem.totalamount
            };
            items.push(a);
        });
        // Add footer row
        let footer = {
            'PLATE NUMBER': 'Total',
            'Total Repairs': totalVehicleCount,
            QTY: absoluteTotalQuantity,
            Amount: absoluteAmount,
            'Total Amount': absoluteTotalAmount
        };

        items.push(footer);
        return items;
    };
    const exportdetaileddata = (data: any, type: any) => {
        let items: any = [];
        data.map((exportitem: any) => {
            const tax = exportitem.jfield && JSON.parse(exportitem.jfield).tax ? JSON.parse(exportitem.jfield).tax : 0;

            let a = {
                Date: exportitem.appointment ? convertdate(exportitem.appointment.Date) : '',
                'PLATE NUMBER': exportitem.vehicleid,
                'GARAGE NAME': exportitem.appointment ? exportitem.appointment.place : '',
                'Invoice No': exportitem.appointment ? exportitem.appointment.invoiceno : '',
                Category: exportitem.partname ? JSON.parse(exportitem.partname).toString() : '',
                QTY: 'AED ' + exportitem.quantity,
                Amount: exportitem.amount,
                'Total Amount': 'AED ' + exportitem.totalamount,
                TAX: tax + '%'
            };
            items.push(a);
        });
        // Add footer row
        let footer = {
            Date: 'Total',
            'PLATE NUMBER': '',
            'GARAGE NAME': '',
            'Invoice No': '',
            Category: '',
            QTY: absoluteTotalQuantity.toFixed(2),
            Amount: absoluteAmount.toFixed(2),
            'Total Amount': absoluteTotalAmountwithTax,
            TAX: ''
        };

        items.push(footer);
        return items;
    };
    const csvLink = {
        filename: SelectedDate && SelectedDate.length > 0 ? `Maintenance_Report_${selectView}_${convertdate(SelectedDate[0])}-${convertdate(SelectedDate[1])}` : 'Maintenence_Report' + '.csv',
        header: [],
        data: selectView === 'Summary' ? (convertedData.length > 0 ? exportdata(convertedData, selectView) : Reports) : filterReports.length > 0 ? exportdetaileddata(filterReports, selectView) : Reports
    };

    const vehicleRowExpansionTemplate = (data: any) => {
        return (
            <div className="orders-subtable">
                <DataTable
                    value={data.details}
                    responsiveLayout="scroll"
                    className="p-datatable-responsive usagetable"
                    header={
                        <div className="p-toolbar p-component">
                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                <h5>Details for {data.vehicleid}</h5>
                            </div>
                            <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                {/* <CSVLink {...csvLink}>
                                    <span className="p-float-label exporttext text-white">Export Summary</span>
                                </CSVLink> */}
                            </div>
                        </div>
                    }
                    // rowClassName={actionRowClassName}
                >
                    <Column field="appointment.Date" className="text-left p-message p-message-warn" sortable header="DATE" dataType="date" filter body={dateBodyTemplate} filterElement={dateFilterTemplate} />
                    {/* <Column field="vehicleid" sortable filter header="PLATE NUMBER" /> */}

                    <Column field="Garagename" className="text-left p-message p-message-warn" body={actionGaragename} filter header="GARAGE NAME" />
                    <Column field="InvNo" className="text-left p-message p-message-warn" body={actionInvno} header="INV. No." />
                    <Column field="Category" className="text-left p-message p-message-warn" body={actioncategory} filter header="CATEGORY" />
                    {/* <Column field="JobDescription" body={actionDescription} filter header="JOB DESCRIPTION" /> */}
                    <Column field="quantity" className="text-left p-message p-message-warn" filter header="QTY" body={(data) => (data.quantity ? data.quantity : '-')} />
                    <Column field="amount" className="text-left p-message p-message-warn" header="AMOUNT" sortable body={(data) => (data.amount ? data.amount : '-')} />
                    <Column field="totalamount" className="text-left p-message p-message-warn" body={actionTemplateTotal} header="TOTAL" sortable />
                    <Column
                        field=""
                        className="text-left p-message p-message-warn"
                        body={(data) => {
                            const tax = data.jfield && JSON.parse(data.jfield).tax ? JSON.parse(data.jfield).tax : 0;

                            return <>{((data.totalamount * tax) / 100).toFixed(2)}%</>;
                        }}
                        header="% Usage"
                    />
                    <Column
                        field="tax"
                        header="Tax"
                        className="text-left p-message p-message-warn"
                        body={(rowdata) => {
                            const tax = rowdata.jfield && JSON.parse(rowdata.jfield).tax ? JSON.parse(rowdata.jfield).tax : 0;
                            return <>{tax}%</>;
                        }}
                    />
                </DataTable>
            </div>
        );
    };
    //set the values to the url
    const updateURL = (view: any, sdate: any, edate: any) => {
        const baseUrl = window.location.href.split('?')[0];
        const queryParams = new URLSearchParams(window.location.search);

        if (sdate) {
            queryParams.set('sdate', convertdate(sdate));
        } else {
            queryParams.delete('sdate');
        }
        if (edate) {
            queryParams.set('edate', convertdate(edate));
        } else {
            queryParams.delete('edate');
        }
        if (view) {
            queryParams.set('view', view);
        } else {
            queryParams.delete('view');
        }

        const newUrl = baseUrl + '?' + queryParams.toString();
        window.history.replaceState({}, '', newUrl);
    };

    return (
        <div className="grid">
            <div className="col-12">
                <h4>Maintenance Report</h4>
                <div className="card">
                    <div className="MaintenanceReportTable">
                        {selectView === 'Summary' && (
                            <DataTable
                                value={convertedData}
                                loading={loading}
                                // header={Commandbar}
                                header={
                                    <div className="p-toolbar p-component p-0">
                                        <div className="p-toolbar-group-start p-toolbar-group-left">
                                            <label htmlFor="range"></label>
                                            <Calendar
                                                id="range"
                                                className="dateFilter"
                                                value={SelectedDate}
                                                dateFormat="dd/m/yy"
                                                onChange={(e: any) => {
                                                    updateURL(selectView, e.value[0], e.value[1]);
                                                    setSelectedDate(e.value);
                                                }}
                                                selectionMode="range"
                                                readOnlyInput
                                                placeholder="Select date"
                                            />

                                            {ShowClrBtn}
                                        </div>

                                        <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                            <Dropdown
                                                value={selectView}
                                                style={{ width: '200px' }}
                                                placeholder="Select an option"
                                                options={[
                                                    { label: 'Grouped by Vehicle', value: 'Summary' },
                                                    { label: 'Show All Details', value: 'Details' }
                                                ]}
                                                onChange={(e) => {
                                                    updateURL(e.value, SelectedDate[0], SelectedDate[1]);
                                                    setSelectView(e.value);
                                                }}
                                                optionLabel="label"
                                                className="mr-2"
                                            />
                                            <CSVLink {...csvLink}>
                                                <span className="p-float-label exporttext text-white" style={{ padding: '11px' }}>
                                                    Export Summary
                                                </span>
                                            </CSVLink>
                                        </div>
                                    </div>
                                }
                                className="p-datatable-responsive"
                                responsiveLayout="scroll"
                                rows={300}
                                selection={selectedProduct}
                                onSelectionChange={(e) => setSelectedProduct(e.value)}
                                rowExpansionTemplate={vehicleRowExpansionTemplate}
                                expandedRows={expandVehicle}
                                onRowToggle={(e) => setExpandVehicle(e.data)}
                            >
                                <Column expander style={{ width: '10px' }} />
                                <Column field="vehicleid" sortable filter header="PLATE NUMBER" footer="Total" footerStyle={{ fontWeight: '700', textAlign: 'center' }} />
                                <Column field="vehicle" sortable filter header="Total Repairs" body={(data) => data.details.length} footer={totalVehicleCount} footerStyle={{ fontWeight: '700', textAlign: 'center' }} />

                                <Column field="quantity" className="text-right" filter header="QTY" sortable footer={absoluteTotalQuantity.toFixed(2)} footerStyle={{ fontWeight: '700', textAlign: 'center' }} />
                                <Column field="amount" className="text-right" header="AMOUNT" sortable footer={'AED ' + absoluteAmount.toFixed(2)} footerStyle={{ fontWeight: '700', textAlign: 'center' }} />
                                <Column field="totalamount" className="text-right" body={actionTemplateTotal} header="TOTAL" sortable footer={'AED ' + absoluteTotalAmount.toFixed(2)} footerStyle={{ fontWeight: '700', textAlign: 'center' }} />
                                {/* <Column field="tax" header="Tax" /> */}
                            </DataTable>
                        )}
                        {selectView === 'Details' && (
                            <DataTable
                                value={
                                    filterReports.length > 0 &&
                                    filterReports.sort((a: any, b: any) => {
                                        const dateA = Number(new Date(a.appointment && a.appointment !== null && a.appointment.Date));
                                        const dateB = Number(new Date(b.appointment && b.appointment !== null && b.appointment.Date));
                                        return dateB - dateA;
                                    })
                                }
                                header={
                                    <div className="p-toolbar p-component p-0">
                                        <div className="p-toolbar-group-start p-toolbar-group-left">
                                            <label htmlFor="range"></label>
                                            <Calendar
                                                id="range"
                                                className="dateFilter"
                                                value={SelectedDate}
                                                dateFormat="dd/m/yy"
                                                onChange={(e: any) => {
                                                    updateURL(selectView, e.value[0], e.value[1]);
                                                    setSelectedDate(e.value);
                                                }}
                                                selectionMode="range"
                                                readOnlyInput
                                                placeholder="Select date"
                                            />

                                            {ShowClrBtn}
                                        </div>

                                        <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                            <Dropdown
                                                value={selectView}
                                                style={{ width: '200px' }}
                                                placeholder="Select an option"
                                                options={[
                                                    { label: 'Grouped by Vehicle', value: 'Summary' },
                                                    { label: 'Show All Details', value: 'Details' }
                                                ]}
                                                onChange={(e) => {
                                                    updateURL(e.value, SelectedDate[0], SelectedDate[1]);
                                                    setSelectView(e.value);
                                                }}
                                                optionLabel="label"
                                                className="mr-2"
                                            />
                                            <CSVLink {...csvLink}>
                                                <span className="p-float-label exporttext text-white" style={{ padding: '11px' }}>
                                                    Export Detailed Report
                                                </span>
                                            </CSVLink>
                                        </div>
                                    </div>
                                }
                                loading={loading}
                                // className="p-datatable-gridlines"
                                className="p-datatable"
                                responsiveLayout="scroll"
                                paginator
                                rows={300}
                                selection={selectedProduct}
                                onSelectionChange={(e) => setSelectedProduct(e.value)}
                            >
                                <Column field="appointment.Date" sortable header="DATE" dataType="date" filter body={dateBodyTemplate} filterElement={dateFilterTemplate} footer="Total" />
                                <Column field="vehicleid" sortable filter header="PLATE NUMBER" />

                                <Column field="Garagename" body={actionGaragename} filter header="GARAGE NAME" />
                                <Column field="InvNo" body={actionInvno} header="INV. No." />
                                <Column field="Category" body={actioncategory} filter header="CATEGORY" />
                                <Column field="quantity" className="text-right" filter header="QTY" footer={absoluteTotalQuantity} />
                                <Column field="amount" className="text-right" header="AMOUNT" footer={absoluteAmount.toFixed()} />
                                <Column field="totalamount" className="text-right" body={actionTemplateTotal} header="TOTAL" footer={'AED ' + absoluteTotalAmountwithTax} />
                                <Column
                                    field="tax"
                                    header="TAX"
                                    body={(data) => {
                                        const tax = data.jfield && JSON.parse(data.jfield).tax ? JSON.parse(data.jfield).tax : 0;
                                        return <>{tax}%</>;
                                    }}
                                />
                            </DataTable>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceReport;
