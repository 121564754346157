import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
// import { CustomerService } from '../service/CustomerService';
import './PermissionLevel.css';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Icon } from '@aws-amplify/ui-react';
import dxService from '../../Services/dxService';
import { useLocation } from 'react-router-dom';
const PermissionLevel = () => {
  const [roleitem, setroleitem] = useState<any>(null);
  const [name,setname] = useState('');
  const [description,setdescription] = useState('');
  const [Data, setData] = useState([]);
  const [rolevalue, setroleValue] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);
  const toast = useRef(null);
  const [city, setCity] = useState([]);
  const categories = [{ name: 'Accounting', key: 'A' }, { name: 'Marketing', key: 'M' }, { name: 'Production', key: 'P' }, { name: 'Research', key: 'R' }];
  const [checkedCreate, setCheckedCreate] = useState<any>();
  const [checkedEdit, setCheckedEdit] = useState(false);
  const [checkedview, setCheckedview] = useState(false);
  const [checkedDelete, setCheckedDelete] = useState(false);
  const [SendEmail, setSendEmail] = useState(false);
  const [MakeCall, setMakeCall] = useState(false);
  const [ViewMask, setViewMask] = useState(false);
  const [SendApproval, setSendApproval] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(categories.slice(1, 3));
  const [selectedCategory, setSelectedCategory] = useState(categories[1]);
  const location1 = useLocation();
  const urlParams = new URLSearchParams(location1.search);
  var formmode = urlParams.has('mode') ? urlParams.get('mode') : '';
  var formID:string | null = urlParams.has('id') ? urlParams.get('id') : '';
  /*
  "rolename":"",
  "description":"",
  "jfield":{
    "vehicle":"1111",  //application:permissionmask
  }
  */
  /**
   "data":[
     {
       "id":"",
       "application":"",
       "name":"",
       "title":"",
       "description":"",
       "create":false,
       "read":false,
       "update":false,
       "delete":false
     }
   ]
   */
  const PermissionData: any = {
    "data": [
      {
        "id": 1,
        "application": "Company",
        "name": "Business Units",
        "title": "Manage Business Units",
        "description": "Can manage the company details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }


      },
      {
        "id": 2,
        "application": "Department",
        "name": "Departments",
        "title": "Manage Departments",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 3,
        "application": "GeneralConfig",
        "name": "Application Settings",
        "title": "Manage Application Settings",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 4,
        "name": "Driver",
        "application": "Driver",
        "title": "Manage Driver",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 5,
        "application": "Vehicle",
        "title": "Manage Vehicle",
        "name": "Vehicle",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 6,
        "application": "VehicleActivity",
        "title": "Manage Vehicle Activities",
        "name": "Vehicle Activities",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 7,
        "application": "Allocations",
        "title": "Manage Allocations",
        "name": "Vehicle Allocations",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 8,
        "application": "Appointments",
        "title": "Manage Appointments",
        "name": "appointments",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 9,
        "application": "Vendors",
        "title": "Manage Vendors",
        "name": "Vendors",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 10,
        "application": "Contract",
        "title": "Manage Contracts",
        "name": "Contracts",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 11,
        "application": "MasterLogs",
        "title": "Manage Logs",
        "name": "Logs",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 12,
        "application": "Navigation",
        "title": "Manage Navigation",
        "name": "Navigation",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }
      },
      {
        "id": 13,
        "application": "Document",
        "title": "Manage Documents",
        "name": "Documents",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }
      },
      {
        "id": 14,
        "application": "Approvals",
        "title": "Manage Approvals",
        "name": "Approvals",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }
      },{
        "id": 15,
        "application": "Roles",
        "title": "Manage Roles",
        "name": "Roles",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
      {
        "id": 16,
        "application": "UserRoles",
        "title": "Manage Members",
        "name": "Members",
        "description": "Can manage the department details",
        "permissions": {
          "create": false,
          "update": false,
          "read": false,
          "delete": false
        }

      },
    ]
  }
  useEffect(() => {
    if(formID && formID != "" ){
      getrolepermissions(formID);
    }else{
      setData(PermissionData.data)
    }
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getrolepermissions = async(id:string) =>{

    let _roleitem = await dxService.getitembyid('roles',id);
    setname(_roleitem.rolename);
    setdescription(_roleitem.description);
    setroleitem(_roleitem)
    let permissionsvalue = JSON.parse(_roleitem.jfield);

    let pdata = PermissionData.data;
    pdata.map((element:any) => {

        let pp = permissionsvalue[element.application].split("");
        let c = pp.length > 0 ? Boolean(parseInt(pp[0])) :  false;
        let r = pp.length > 1 ? Boolean(parseInt(pp[1])) :  false;
        let u = pp.length > 2 ? Boolean(parseInt(pp[2])) :  false;
        let d = pp.length > 3 ? Boolean(parseInt(pp[3])) :  false;

        element.permissions = {
          "create" : c,
          "read":r,
          "update":u,
          "delete":d
        }

      
    });

    setData(pdata);


  }

  const onCreateChange = (e: any, rowData: any) => {
    console.log(rowData);
    setCheckedCreate(e.checked)
    // if(rowData.name==name){

    // }

  }
  const CreateBodyTemplate = (rowData: any) => {
   // console.log(rowData);

    return (

      <div className='Permission'>
        <div className="field-checkbox">
          <Checkbox inputId={`create${rowData.id}`}
            checked={rowData.permissions.create}
            onChange={e => changecheckedvalue(e, rowData, 'create')}
          />
        </div>
      </div>

    );
  }
  const ViewBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div className='Permission'>
          <div className="field-checkbox">
            <Checkbox inputId={`view${rowData.id}`}
              checked={rowData.permissions.read}
              onChange={e => changecheckedvalue(e, rowData, 'read')}
            />
            {/* <input
              type="checkbox"
              id={`custom-checkbox-${rowData.id}`}
              name={rowData.name}
              value={rowData.name}
              onChange={() => onCreateChange(rowData)}
            /> */}
            {/* <Checkbox inputId="city2" name="city" value="Los Angeles" onChange={onCityChange} checked={cities.indexOf('Los Angeles') !== -1} /> */}
          </div>
        </div>

      </React.Fragment>
    );
  }
  const EditBodyTemplate = (rowData: any) => {
    return (
      <div className='Permission'>
        <div className="field-checkbox">
          <Checkbox inputId={`edit${rowData.id}`}
            checked={rowData.permissions.update}
            onChange={e => changecheckedvalue(e, rowData, 'update')}
          />
          {/* <input
            type="checkbox"
            id={`custom-checkbox-${rowData.id}`}
            name={rowData.name}
            value={rowData.name}
            onChange={() => onCreateChange(rowData)}
          /> */}
        </div>
      </div>
    );
  }
  const DeleteBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>

        <div className='Permission'>
          <div className="field-checkbox">
            <Checkbox inputId={`delete${rowData.id}`}
              checked={rowData.permissions.delete}
              onChange={e => changecheckedvalue(e, rowData, 'delete')} />
            {/* <input
              type="checkbox"
              id={`custom-checkbox-${rowData.id}`}
              name={rowData.delete}
              value={rowData.delete}
              onChange={() => onCreateChange(rowData)} 
            />*/}
          </div>
        </div>
      </React.Fragment>
    );
  }

  const changecheckedvalue = (e: any, rowData: any, operation: string) => {
    console.log(rowData);
    console.log(e);
    const nextdata: any = Data.map((c: any, i) => {
      if (c.id === rowData.id) {
        // Increment the clicked counter
       c['permissions'][operation] = e.target.checked
        return c;
      } else {
        // The rest haven't changed
        return c;
      }
    });
    console.log(nextdata);
    setData(nextdata);
    //setCheckedDelete(e.checked)
  }
  
  const onCityChange = (e: any) => {
    // let selectedCities: any = [...cities];

    // if (e.checked)
    //   selectedCities.push(e.value);
    // else
    //   selectedCities.splice(selectedCities.indexOf(e.value), 1);

    // setCities(selectedCities);
  }
 
  const SendEmailBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>

        <div className='Permission'>
          <div className="field-checkbox">
            {/* <Checkbox inputId={`city${rowData.id}`} checked={SendEmail} onChange={e => setSendEmail(e.checked)} /> */}
            {/* <input
              type="checkbox"
              id={`custom-checkbox-${rowData.id}`}
              name={rowData.name}
              value={rowData.name}
              onChange={() => onCreateChange(rowData)}
            /> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
  const MakeCallBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>

        <div className='Permission'>
          <div className="field-checkbox">
            {/* <Checkbox inputId={`city${rowData.id}`} checked={MakeCall} onChange={e => setMakeCall(e.checked)} /> */}
            {/* <input
              type="checkbox"
              id={`custom-checkbox-${rowData.id}`}
              name={rowData.name}
              value={rowData.name}
              onChange={() => onCreateChange(rowData)}
            /> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
  const ViewmaskBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>

        <div className='Permission'>
          <div className="field-checkbox">
            {/* <Checkbox inputId={`city${rowData.id}`} checked={ViewMask} onChange={e => setViewMask(e.checked)} /> */}
            {/* <input
              type="checkbox"
              id={`custom-checkbox-${rowData.id}`}
              name={rowData.name}
              value={rowData.name}
              onChange={() => onCreateChange(rowData)}
            /> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
  const SendAprrovalBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>

        <div className='Permission'>
          <div className="field-checkbox">
            {/* <Checkbox inputId={`city${rowData.id}`} checked={SendApproval} onChange={e => setSendApproval(e.checked)} /> */}
            {/* <input
              type="checkbox"
              id={`custom-checkbox-${rowData.id}`}
              name={rowData.name}
              value={rowData.name}
              onChange={() => onCreateChange(rowData)}
            /> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
  const statusBodyTemplate = (rowData: any) => {
    return <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>;
  }

  const representativeBodyTemplate = (rowData: any) => {
    let icons = rowData.name == 'Driver' ? 'pi-car' : rowData.name == 'Documents' ? 'pi-book' : rowData.name == 'pages' ? 'pi-file' : rowData.name == 'Vehicle' ? 'pi-truck' : ''
    return (
      <React.Fragment>
        <span className={`customer-badge status-${rowData.name}`}> <i className={`pi ${icons} fs-large PiIcons`}></i>  {rowData.name}</span>
      </React.Fragment>

    )
  }
  const NameBodyTemplate = (rowData: any) => {
    return (<React.Fragment>
      <span className={`${rowData.title}`}>{rowData.title}</span>
      <div >{rowData.description}</div>
    </React.Fragment>);
  }

  const savepermissions = async () => {
    // "rolename":"",
    // "description":"",
    // "jfield":{
    //   "vehicle":"1111",  //application:permissionmask
    // }
    console.log(Data);
    let permission:any = {};
    Data.forEach((ele:any)=>{
      let appname = ele.application;
      let mask = Number(ele.permissions.create)+""+Number(ele.permissions.read)+""+Number(ele.permissions.update)+""+Number(ele.permissions.delete);
      permission[appname] = mask;
    });
    let newobj:any = {
      "rolename":name,
      "description":description,
      "jfield":JSON.stringify(permission)
    }
    console.log(newobj);
    if(formID != null && formID != ""){
      newobj['id']= formID;
      newobj['_version']= roleitem['_version'];
      let edititem = await dxService.updateitem("roles",newobj);
      console.log(edititem);

    }else{
      let newitem = await dxService.createitem("roles",newobj);
      console.log(newitem);
    }
     
     
  }

  return (
    <div className="grid">
      <div className="col-12 md:col-12">
        <h4 className="container-header">Manage Group Pemrissions</h4>
        <div className={`datatable-editing-demo `}>
          <div className="datatable-rowgroup-demo">


            <div className="card PermissionTable">

              <div className={`field col-12 grid`}>
                <label htmlFor='' className='col-12 mb-2 md:col-1 md:mb-0'>Role Name
                </label>
                <div className='col-12 md:col-9' >

                  <InputText className='md:col-6' value={name} onChange={(e) => setname(e.target.value)} />
                </div>
              </div>
              <div className={`field col-12 grid`}>
                <label htmlFor='' className='col-12 mb-2 md:col-1 md:mb-0'>Description</label>
                <div className='col-12 md:col-9' >

                  <InputText className='md:col-6' value={description} onChange={(e) => setdescription(e.target.value)} />
                </div>
                <div className='col-12 md:col-2 text-right'>
                  <Button onClick={savepermissions} className="mr-2">Save

                  </Button>
                  <Button onClick={() => window.history.back()} className="p-button-danger">
                    Cancel
                  </Button>
                </div>
              </div>

              <DataTable value={Data} rowGroupMode="rowspan" groupRowsBy="name"
                sortMode="single" sortField="id" sortOrder={1} responsiveLayout="scroll" className='p-datatable-gridlines'>
                {/* <Column header="#" headerStyle={{ width: '3em' }} body={(data, options) => options.rowIndex + 1}></Column> */}
                <Column field="title" header="Information" body={representativeBodyTemplate}></Column>
                <Column field="name" header="Permission level" body={NameBodyTemplate}></Column>
                <Column field="permissions.create" header="Create" body={CreateBodyTemplate} headerClassName='HeaderWidth'></Column>
                <Column field="permissions.update" header="Edit" body={EditBodyTemplate} headerClassName='HeaderWidth'></Column>
                <Column field="permissions.read" header="View" body={ViewBodyTemplate} headerClassName='HeaderWidth'></Column>
                <Column field="permissions.delete" header="Delete" body={DeleteBodyTemplate} headerClassName='HeaderWidth'></Column>
                {/* <Column field="date" header="Send Email" body={SendEmailBodyTemplate}headerClassName='HeaderWidth'></Column>
                    <Column field="date" header="Make phone call" body={MakeCallBodyTemplate}headerClassName='HeaderWidth'></Column>
                    <Column field="date" header="View Masked" body={ViewmaskBodyTemplate}headerClassName='HeaderWidth'></Column>
                    <Column field="date" header="Send Approval" body={SendAprrovalBodyTemplate} headerClassName='HeaderWidth'></Column> */}
              </DataTable>
              <div className='mt-4 text-right'>
                <Button onClick={savepermissions} className="mr-2">Save</Button>
                <Button onClick={() => window.history.back()} className="p-button-danger">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PermissionLevel;