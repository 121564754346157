import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { Button as AwsButton } from "@aws-amplify/ui-react";
import Documents from "./Documents";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import dxService from "../../Services/dxService";
import { Calendar } from "primereact/calendar";
import {
  FormatInvalidDate,
  convertdate,
  isotolocaledatetime,
  localetoisodatetime,
  removenumberseprators,
} from "../../utils/helper";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// Get the current date
const currentDate = new Date();

// Get the month and year of the previous month
const previousMonth = currentDate.getMonth() - 1;
const previousYear = currentDate.getFullYear();

// Create a new Date object for the previous month's start date
const previousMonthStartDate = new Date(previousYear, previousMonth, 1);

// Create a new Date object for the current month's start date
const currentMonthStartDate = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);

// Set the previous month's end date to the day before the current month's start date
const previousMonthEndDate = new Date(currentMonthStartDate.getTime() - 1);

// Format the start and end dates as desired
const previousMonthStartDateFormatted =
  previousMonthStartDate.toLocaleDateString();
const previousMonthEndDateFormatted = previousMonthEndDate.toLocaleDateString();

// console.log("Previous Month Start Date:", previousMonthStartDateFormatted);
// console.log("Previous Month End Date:", previousMonthEndDateFormatted);

let errMessages: any = [];
let savedData: any = [];
let updatedData: any = [];
let importfailed: any = [];
let invaliddateerr: any = [];
let uniquefilteredFinedata: any = [];
function ImportLogs() {
  const navigate = useNavigate();
  const toast = useRef<any>(null);
  const [driver, setDriver] = useState<any>([]);
  const [driverName, setdriverName] = useState<any>([]);
  const [jsonData, setJsonData] = useState<any>([]);
  const [savingData, setSavingData] = useState(false);
  const [charges, setCharges] = useState<any>([]);
  const [sdate, setsdate] = useState(new Date(previousMonthStartDateFormatted));
  const [edate, setedate] = useState(new Date(previousMonthEndDateFormatted));
  const [totalAdded, setTotalAdded] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>([]);
  const [invaliddate, setInvalidDate] = useState<any>([]);
  const [saveData, setsaveData] = useState<any>([]);
  const [Vehicle, setVehicle] = useState<any>([]);
  const [emsg, setemsg] = useState([]);

  useEffect(() => {
    setsaveData([]);
    getAllocatedDriverDetails().then((res: any) => {
      setDriver(res);
    });
    getAllvehicles().then((res) => {
      setVehicle(res);
    });
    getAllDrivers().then((res) => {
      setdriverName(res);
    });
    // setDriver(data);
  }, [charges]);

  const validateallocations = async () => {
    getAllocatedDriverDetails().then((res: any) => {
      setDriver(res);

      if (charges.name === "Salik") {
        jsonData.map((js: any) => {
          // console.log(js);

          let tripdate = localetoisodatetime(
            new Date(`${js["Trip Date"]} ${js["Trip Time"]}`)
          );
          let jsvehicle = js["Plate"];
          let jsfilter = res.filter((r1: any) => {
            let fd = isotolocaledatetime(r1.fromdate);
            let ed = isotolocaledatetime(r1.handoverDate);
            let td = isotolocaledatetime(tripdate);
            //     fromdate: {ge: localetoisodatetime(sd)}},
            //     {handoverdate:{attributeExists:true}},
            //    {handoverdate:{le: localetoisodatetime(ed)}}
            if (fd != null && td != null && ed != null) {
              if (r1.vehicleid.includes(jsvehicle) && fd <= td && ed >= td) {
                js["allocation"] = true;
                js["aavailable"] = "available";
                return true;
              } else if (
                r1.vehicleid.includes(jsvehicle) &&
                fd >= td &&
                ed <= td
              ) {
                js["allocation"] = true;
                js["aavailable"] = "available";
                return true;
              } else {
                js["allocation"] = false;
                js["aavailable"] = "not available";
                return false;
              }
            } else if (fd != null && td != null && ed == null) {
              if (r1.vehicleid.includes(jsvehicle) && fd <= td) {
                js["allocation"] = true;
                js["aavailable"] = "available";
                return true;
              }
            } else {
              js["allocation"] = false;
              js["aavailable"] = "not available";
              return false;
            }
          });
        });
      } else if (charges.name === "VIP Fuel") {
        //SaleEnd
        jsonData.map((js: any) => {
          let tripdate: any;
          //   console.log(new Date(js.SaleEnd));
          const dateString = js.SaleEnd;
          const date: any = new Date(dateString);

          // Check if the date is valid
          if (!isNaN(date)) {
            tripdate = localetoisodatetime(new Date(js.SaleEnd));
            // console.log(tripdate);
          } else {
            let saleendDate = js.SaleEnd;
            const SplitdateString = saleendDate.split(" ");
            // console.log(SplitdateString);
            const sDate = moment(SplitdateString[0]).format("DD MMM YYYY");
            const eTime =
              SplitdateString[1].replace(/\./g, ":") + " " + SplitdateString[2];
            // console.log(sDate, eTime);
            const date = new Date(`${sDate} ${eTime}`);
            //   const iso8601String = date.toUTCString();

            // console.log(date);
            tripdate = localetoisodatetime(new Date(`${sDate} ${eTime}`));
            // console.log(tripdate);
          }

          let jsvehicle = js["VehiclePlateNumber"];
          let jsfilter = res.filter((r1: any) => {
            let fd = isotolocaledatetime(r1.fromdate);
            let ed = isotolocaledatetime(r1.handoverDate);
            let td = isotolocaledatetime(tripdate);
            //     fromdate: {ge: localetoisodatetime(sd)}},
            //     {handoverdate:{attributeExists:true}},
            //    {handoverdate:{le: localetoisodatetime(ed)}}
            if (fd != null && td != null && ed != null) {
              if (r1.vehicleid.includes(jsvehicle) && fd <= td && ed >= td) {
                js["allocation"] = true;
                js["aavailable"] = "available";
                return true;
              } else if (
                r1.vehicleid.includes(jsvehicle) &&
                fd >= td &&
                ed <= td
              ) {
                js["allocation"] = true;
                js["aavailable"] = "available";
                return true;
              } else {
                js["allocation"] = false;
                js["aavailable"] = "not available";
                return false;
              }
            } else if (fd != null && td != null && ed == null) {
              if (r1.vehicleid.includes(jsvehicle) && fd <= td) {
                js["allocation"] = true;
                js["aavailable"] = "available";
                return true;
              }
            } else {
              js["allocation"] = false;
              js["aavailable"] = "not available";
              return false;
            }
          });
        });
      }
      // else if (charges.name === "Mileage") {
      //   jsonData
      //     .filter((item: any) => {
      //       let keys = Object.keys(item);
      //       Object.assign(item, {
      //         description: item[keys[0]],
      //         vehicleid: item[keys[0]].split(" ").pop(),
      //         startdatetime: item[keys[1]],
      //         enddatetime: item[keys[2]],
      //         Start: item[keys[3]],

      //         Startaddress: item[keys[4]],
      //         End: item[keys[5]],
      //         Endaddress: item[keys[6]],
      //         duration: item[keys[7]],
      //         distance: item[keys[8]],
      //         avgspeed: item[keys[9]],
      //         maxspeed: item[keys[10]],
      //       });
      //       if (item.startdatetime !== undefined) {
      //         const length = Object.keys(item).length;
      //         return true;
      //       }
      //     })
      //     .map((js: any) => {
      //       let tripdate = localetoisodatetime(new Date(`${js.startdatetime}`));
      //       let jsvehicle = js["vehicleid"];
      //       let jsfilter = res.filter((r1: any) => {
      //         let fd = isotolocaledatetime(r1.fromdate);
      //         let ed = isotolocaledatetime(r1.handoverDate);
      //         let td = isotolocaledatetime(tripdate);
      //         //     fromdate: {ge: localetoisodatetime(sd)}},
      //         //     {handoverdate:{attributeExists:true}},
      //         //    {handoverdate:{le: localetoisodatetime(ed)}}
      //         if (fd != null && td != null && ed != null) {
      //           if (r1.vehicleid.includes(jsvehicle) && fd <= td && ed >= td) {
      //             js["allocation"] = true;
      //             js["aavailable"] = "available";
      //             return true;
      //           } else if (
      //             r1.vehicleid.includes(jsvehicle) &&
      //             fd >= td &&
      //             ed <= td
      //           ) {
      //             js["allocation"] = true;
      //             js["aavailable"] = "available";
      //             return true;
      //           } else {
      //             js["allocation"] = false;
      //             js["aavailable"] = "not available";
      //             return false;
      //           }
      //         } else if (fd != null && td != null && ed == null) {
      //           if (r1.vehicleid.includes(jsvehicle) && fd <= td) {
      //             js["allocation"] = true;
      //             js["aavailable"] = "available";
      //             return true;
      //           }
      //         } else {
      //           js["allocation"] = false;
      //           js["aavailable"] = "not available";
      //           return false;
      //         }
      //       });
      //     });
      // }
    });
  };
  const getAllocatedDriverDetails = async (nextToken?: any) => {
    let variables: any = {};

    var sd = new Date(sdate);
    var ed = new Date(edate).setHours(23, 59, 59);

    let filter = {
      or: [
        {
          and: [
            { fromdate: { ge: localetoisodatetime(sd) } },
            { handoverdate: { attributeExists: true } },
            { handoverdate: { le: localetoisodatetime(ed) } },
          ],
        },
        {
          and: [
            { fromdate: { le: localetoisodatetime(sd) } },
            { handoverdate: { attributeExists: false } },
          ],
        },
        {
          and: [
            {
              fromdate: { le: localetoisodatetime(sd) },
            },
            { handoverdate: { ge: localetoisodatetime(ed) } },
          ],
        },
        {
          and: [
            {
              fromdate: { le: localetoisodatetime(sd) },
            },
            { handoverdate: { le: localetoisodatetime(ed) } },
            { handoverdate: { ge: localetoisodatetime(sd) } },
          ],
        },
        {
          and: [
            {
              fromdate: { ge: localetoisodatetime(sd) },
            },
            {
              fromdate: { le: localetoisodatetime(ed) },
            },
            { handoverdate: { attributeExists: false } },
          ],
        },
      ],
    };

    variables = filter;
    let GetResponse: any = await dxService.listitems(
      "allocation",
      "*",
      variables,
      true,
      1000,
      nextToken
    );
    let response = GetResponse.items;

    let nexttoken = GetResponse.nextToken;
    if (nexttoken) {
      // const nextResults = await getAllocatedDriverDetails(nextToken);
      // response = response.concat(nextResults);
      await getAllocatedDriverDetails(nexttoken).then((res: any) => {
        setDriver((olditems: any) => [...olditems, ...res]);
      });
    }
    // let GetResponse: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    // let res = GetResponse.data.listDxAllocations.items;
    // console.log(response);
    return response;
  };
  // console.log(driver);

  //get vehicles from db
  const getAllvehicles = async (nextToken?: any) => {
    let variables: any = {};
    let GetResponse: any = await dxService.listitems(
      "vehicle",
      "*",
      variables,
      true,
      100,
      nextToken
    );
    let response = GetResponse.items;

    let nexttoken = GetResponse.nextToken;
    if (nexttoken) {
      await getAllvehicles(nexttoken).then((res: any) => {
        setVehicle((olditems: any) => [...olditems, ...res]);
      });
    }
    // let GetResponse: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    // let res = GetResponse.data.listDxAllocations.items;
    return response;
  };
  const getAllDrivers = async (nextToken?: any) => {
    let variables: any = {};
    let GetResponse: any = await dxService.listitems(
      "driver",
      "*",
      variables,
      true,
      100,
      nextToken
    );
    let response = GetResponse.items;

    let nexttoken = GetResponse.nextToken;
    if (nexttoken) {
      await getAllDrivers(nexttoken).then((res: any) => {
        setdriverName((olditems: any) => [...olditems, ...res]);
      });
    }
    // let GetResponse: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    // let res = GetResponse.data.listDxAllocations.items;
    return response;
  };
  useEffect(() => {
    const unmatchedVehicles = jsonData.filter((item1: any) => {
      const item1Value =
        item1.VehiclePlateNumber !== undefined
          ? item1.VehiclePlateNumber.replace(/-|DXB|SHJ|AUX/g, "")
          : item1.Plate !== undefined
          ? item1.Plate
          : item1["VEHICLE NO."];

      const isMatched = Vehicle.some((item2: any) =>
        item2.id.includes(item1Value)
      );
      return !isMatched;
    });

    const unallocatedDrivers =
      charges.label !== "Cash Reimbursement"
        ? jsonData.filter((item1: any) => {
            const item1Value =
              charges.label === "VIP Fuel"
                ? item1.VehiclePlateNumber.replace(/-|DXB|SHJ|AUX/g, "")
                : charges.label === "Salik"
                ? item1.Plate
                : charges.label === "Fines"
                ? item1["VEHICLE NO."]
                : "";

            const isAllocated = driver.some((item2: any) =>
              item2.vehicleid.includes(item1Value)
            );
            return !isAllocated;
          })
        : [];

    setemsg(unallocatedDrivers);
  }, [jsonData, Vehicle]);

  function sort_by_key(array: any[], key: string, keytype: string) {
    return array.sort(function (a, b) {
      if (keytype != "date") {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      } else {
        var x: any = new Date(a[key]);
        var y: any = new Date(b[key]);
        return x < y ? -1 : x > y ? 1 : 0;
      }
    });
  }

  const validatedriver = () => {
    if (jsonData) {
      if (charges.name === "Salik") {
        let sorteddata = sort_by_key(jsonData, "Trip Date", "date");
        let fdate = new Date(sorteddata[0]["Trip Date"]);
        let ldate = new Date(sorteddata[sorteddata.length - 1]["Trip Date"]);
        jsonData.find((jsonItem: any) => {
          const jsonDate = new Date(
            `${jsonItem["Trip Date"]} ${jsonItem["Trip Time"]}`
          );
        });
      }
    }
  };
  const dateFormats = [
    "M/D/YYYY h:mm:ss A",
    "M/D/YYYY HH:mm",
    "M-D-YYYY HH:mm",
    "M-D-YYYY H:mm",
  ];

  const checkNaughtyDriver = () => {
    errMessages = [];
    importfailed = [];
    let items: any = [];
    let vipfuelarr: any = [];
    setErrorMessage([]);
    // console.log(driver);

    const commonItems = driver.filter(
      (date: any) => date.fromdate !== null
      //  && date.handoverdate !== null
    );

    function isValidDate(dateString: any) {
      // Create a new Date object from the input string
      const date: any = new Date(dateString);

      // Check if the date is valid
      // The date is considered valid if it's not 'Invalid Date' and it's not NaN
      return !isNaN(date) && date.toString() !== "Invalid Date";
    }

    if (jsonData) {
      if (charges.name === "Fines") {
        let Fineimporteddata: any = [];
        let checkFineValidDate: any = jsonData.filter((res: any) => {
          return moment(res["FINE RECORDED"], [
            "D-MMM-YY",
            "M/D/YYYY h:mm:ss A",
            "M/D/YYYY HH:mm",
            "M-D-YYYY HH:mm",
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
            "YYYY-MM-DD HH:mm:ss",
            "MM/DD/YYYY",
            "MM-DD-YYYY",
            "DD/MM/YYYY",
            "DD-MM-YYYY",
          ]).isBetween(
            moment(sdate, "DD-MM-YYYY"),
            moment(edate, "DD-MM-YYYY").endOf("day"),
            null,
            "[]"
          );
        });
        for (let i = 0; i < checkFineValidDate.length; i++) {
          const jsonItem: any = checkFineValidDate[i];
          const matchedDriverItem = commonItems.filter((driverItem: any) => {
            const jsonDate = new Date(jsonItem["FINE RECORDED"]);
            const fromDate = new Date(driverItem.fromdate);
            const handoverDate = driverItem.handoverdate
              ? new Date(driverItem.handoverdate)
              : new Date();
            if (
              jsonItem["VEHICLE NO."] === driverItem.vehicleid &&
              jsonDate >= fromDate &&
              jsonDate <= handoverDate
            ) {
              jsonItem["fromdrivername"] = driverItem.fromdrivername;
              jsonItem["handoverdrivername"] = driverItem.handoverdrivername;
              jsonItem["vehicleid"] = driverItem.vehicleid;
              jsonItem["fromdriverID"] = driverItem.fromdriverID;
              jsonItem["driverID"] = driverItem.driverID;
              jsonItem["id"] = driverItem.id;
              Fineimporteddata.push(jsonItem);
              return Fineimporteddata;
            }
          });
          const removeSalikDuplicatesById = (arr: any) => {
            const uniqueIds = new Set();
            return arr.filter((obj: any) => {
              if (!uniqueIds.has(obj["FINE NO."])) {
                uniqueIds.add(obj["FINE NO."]);
                return true;
              }
              return false;
            });
          };
          const uniqueFinedata = removeSalikDuplicatesById(Fineimporteddata);
          // console.log(uniqueFinedata);
          // items = [];
          uniqueFinedata.map((driverItem: any) => {
            const jsonDate = new Date(driverItem["FINE RECORDED"]);
            let dn =
              driverItem.fromdrivername !== null
                ? driverItem.fromdrivername
                : driverItem.handoverdrivername;
            let aa = moment(jsonDate).format("YYYY-MM-DD");
            let key =
              driverItem.vehicleid +
              "#" +
              moment(`${aa} 00:00:00`).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") +
              "#" +
              dn;
            //str = str.toLowerCase().replace(/[ *_#]/g, '');
            const finesData = {
              id: jsonItem["FINE NO."],
              date: moment(`${aa} 00:00:00`).format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              ),
              transactiondate: moment(`${aa} 00:00:00`).format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              ),
              vehicleid:
                jsonItem["VEHICLE NO."] !== undefined
                  ? jsonItem["VEHICLE NO."]
                  : "",
              driverid:
                driverItem.fromdriverID !== null
                  ? driverItem.fromdriverID
                  : driverItem.driverID,
              jfield: JSON.stringify({
                allocationid: driverItem.id,
              }),
              drivername: jsonItem.DRIVER !== undefined ? jsonItem.DRIVER : "",
              amount:
                jsonItem.AMOUNT !== undefined
                  ? removenumberseprators(jsonItem.AMOUNT.trim())
                  : "",
              innovationfee:
                jsonItem["INNOVATION FEE"] !== undefined
                  ? removenumberseprators(jsonItem["INNOVATION FEE"])
                  : "",
              totalamount:
                jsonItem.TOTAL !== undefined
                  ? removenumberseprators(jsonItem.TOTAL.trim())
                  : "",
              source: jsonItem.SOURCE !== undefined ? jsonItem.SOURCE : "",
              finenumber:
                jsonItem["FINE NO."] !== undefined ? jsonItem["FINE NO."] : "",
              blackpoints:
                jsonItem["BLACK POINTS"] !== undefined
                  ? jsonItem["BLACK POINTS"]
                  : "",
              reason:
                jsonItem["REASON"] !== undefined ? jsonItem["REASON"] : "",
              location: jsonItem["LOCATION"],
              type: charges.name,
              dxMasterLogsFinesId: key,
            };
            // console.log(finesData);

            items.push(finesData);
          });
          // console.log(items);
          const removeduplidateInvalidDate = (arr: any) => {
            const uniqueIds = new Set();
            return arr.filter((obj: any) => {
              const key = obj["id"];
              if (!uniqueIds.has(key)) {
                uniqueIds.add(key);
                return true;
              }
              return false;
            });
          };
          uniquefilteredFinedata = removeduplidateInvalidDate(items);
          // console.log(uniquefilteredFinedata);

          function findUnImportedSalik(arr1: any, arr2: any) {
            // Compare arr1 with arr2 and return objects that are unique to arr1
            const uniqueObjectsInArr1 = arr1.filter((obj1: any) => {
              // Check if obj1 exists in arr2 based on a specific property (e.g., "Transaction ID")
              return !arr2.some((obj2: any) => obj2["FINE NO."] === obj1["id"]);
            });
            return uniqueObjectsInArr1;
          }
          // console.log(items, uniqueFinedata);

          const oddObjects = findUnImportedSalik(
            checkFineValidDate,
            uniqueFinedata
          );
          // console.log(oddObjects);
          setInvalidDate(invaliddateerr);
          setErrorMessage(oddObjects);
        }
      }
      if (charges.name === "Salik") {
        //salikimporteddata array will hold the all items which has allocation
        let salikimporteddata: any = [];
        //checkSalikValidDate will filter array of items which has a valid date
        let checkSalikValidDate: any = jsonData.filter((res: any) => {
          const tripDate = moment(res["Trip Date"], [
            "DD-MMM-YY",
            "DD MMM YYYY",
            "DD MMM YY",
            "M/D/YYYY h:mm:ss A",
            "M/D/YYYY HH:mm",
            "M-D-YYYY HH:mm",
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
            "YYYY-MM-DD HH:mm:ss",
            "MM/DD/YYYY",
            "MM-DD-YYYY",
            "DD/MM/YYYY",
            "DD-MM-YYYY",
          ]);
          const startDate = moment(sdate).startOf("day");
          const endDate = moment(edate).startOf("day");
          const tripdate: any = new Date(res["Trip Date"]);
          // console.log(new Date(res["Trip Date"]));

          // console.log(moment(tripDate).format("DD-MMM-YY"));
          if (
            tripdate.toString() !== "Invalid Date" &&
            tripDate.isValid() &&
            tripDate.isBetween(startDate, endDate, null, "[]")
          ) {
            return (
              tripdate.toString() !== "Invalid Date" &&
              tripDate.isValid() &&
              tripDate.isBetween(startDate, endDate, null, "[]")
            );
          } else {
            invaliddateerr.push(res);
            // console.log(res);
          }
        });
        // console.log(checkSalikValidDate);
        //for loop is to loop inside the array which has a valid date
        for (let i = 0; i < checkSalikValidDate.length; i++) {
          const jsonItem: any = checkSalikValidDate[i];
          const jsonDate = new Date(
            `${jsonItem["Trip Date"]} ${jsonItem["Trip Time"]}`
          );
          const matchedDriverItem = commonItems.filter((driverItem: any) => {
            let fd = new Date(
              driverItem.fromdate.replace("T", " ").replace("Z", "")
            );
            let hd = driverItem.handoverdate
              ? new Date(
                  driverItem.handoverdate.replace("T", " ").replace("Z", "")
                )
              : new Date();
            const fromDate = fd;
            const handoverDate = hd;
            //if condition will check the array of items which has a allocation
            if (
              driverItem.vehicleid.includes(jsonItem.Plate) &&
              jsonDate >= fromDate &&
              jsonDate <= handoverDate
            ) {
              //if allocation exist map some of the allocation data to the excel item
              jsonItem["fromdrivername"] = driverItem.fromdrivername;
              jsonItem["handoverdrivername"] = driverItem.handoverdrivername;
              jsonItem["vehicleid"] = driverItem.vehicleid;
              jsonItem["fromdriverID"] = driverItem.fromdriverID;
              jsonItem["driverID"] = driverItem.driverID;
              jsonItem["id"] = driverItem.id;
              // push all the valid items in salikimporteddata array
              salikimporteddata.push(jsonItem);
              return salikimporteddata;
            }
          });
        }
        // console.log(salikimporteddata);
        //removeSalikDuplicatesById used to remove the duplication of  salikimporteddata
        const removeSalikDuplicatesById = (arr: any) => {
          const uniqueIds = new Set();
          return arr.filter((obj: any) => {
            if (!uniqueIds.has(obj["Transaction ID"])) {
              uniqueIds.add(obj["Transaction ID"]);
              return true;
            }
            return false;
          });
        };
        const uniqueSalikdata = removeSalikDuplicatesById(salikimporteddata);
        // console.log(uniqueSalikdata);
        uniqueSalikdata.map((driverItem: any) => {
          let dn =
            driverItem.fromdrivername !== null
              ? driverItem.fromdrivername
              : driverItem.handoverdrivername;

          let key =
            driverItem.vehicleid +
            "#" +
            moment(`${driverItem["Transaction Post Date"]} 00:00:00`).format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            ) +
            "#" +
            dn;
          const salikData = {
            id: driverItem["Transaction ID"],
            transactionid: driverItem["Transaction ID"],
            date: moment(
              `${driverItem["Trip Date"]} ${driverItem["Trip Time"]}`
            ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            // transactiondate: new Date(`${driverItem['Transaction Post Date']}`).toISOString(),
            transactiondate: moment(
              `${driverItem["Transaction Post Date"]} 00:00:00`
            ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            tollgate: driverItem["Toll Gate"],
            direction: driverItem["Direction"],
            tagnumber: driverItem["Tag Number"],
            vehicleid: driverItem.vehicleid,
            driverid:
              driverItem.fromdriverID !== null
                ? driverItem.fromdriverID
                : driverItem.driverID,
            drivername: dn,
            jfield: JSON.stringify({
              allocationid: driverItem.id,
            }),
            amount: driverItem["Amount(AED)"],
            dxMasterLogsSalikId: key,
          };
          items.push(salikData);
          // console.log(salikData);
        });
        //to filter the unimported item findUnImportedSalik func is used
        function findUnImportedSalik(arr1: any, arr2: any) {
          // Compare arr1 with arr2 and return objects that are unique to arr1
          const uniqueObjectsInArr1 = arr1.filter((obj1: any) => {
            // Check if obj1 exists in arr2 based on a specific property (e.g., "Transaction ID")
            return !arr2.some(
              (obj2: any) => obj2["Transaction ID"] === obj1["Transaction ID"]
            );
          });
          return uniqueObjectsInArr1;
        }
        const oddObjects = findUnImportedSalik(
          checkSalikValidDate,
          uniqueSalikdata
        );
        // console.log(oddObjects);
        const removeduplidateInvalidDate = (arr: any) => {
          const uniqueIds = new Set();
          return arr.filter((obj: any) => {
            const key = obj["Transaction ID"];
            if (!uniqueIds.has(key)) {
              uniqueIds.add(key);
              return true;
            }
            return false;
          });
        };
        const uniqueInvalidDate = removeduplidateInvalidDate(invaliddateerr);

        setInvalidDate(uniqueInvalidDate);

        setErrorMessage(oddObjects);
      }
      if (charges.name === "VIP Fuel") {
        //salikimporteddata array will hold the all items which has allocation
        let vipfuelimporteddata: any = [];
        //checkSalikValidDate will filter array of items which has a valid date
        let checkVipfuelValidDate: any = jsonData.filter((res: any) => {
          // if (!isNaN(date)) {
          // console.log(res.SaleEnd);

          const saleEndDate = moment(
            res.SaleEnd,
            [
              "M/D/YYYY h:mm:ss A",
              "m/d/yyyy h:mm:ss A",
              "m/d/yyyy hh:mm:ss A",
              "m/dd/yyyy hh:mm:ss A",
              "m-d-yyyy h:mm:ss A",
              "m-d-yyyy hh:mm:ss A",
              "M-D-YYYY h.mm.ss A",

              "dd-mm-yyyy h.mm.ss A",
              "dd/MM/yyyy h.mm.ss A",
              "dd-MM-yyyy h.mm.ss A",
              "M/D/YYYY H:mm:ss A",
              "M/D/YYYY HH:mm:ss A",
              "M/D/YYYY HH:mm",
              "MM-DD-YYYY HH:mm:ss A",
              "MM-DD-YYYY HH:mm",
              "M-D-YYYY hh:mm:ss A",
            ],
            true
          );
          const sDate = moment(sdate).startOf("day");
          const eDate = moment(edate).endOf("day");
          const dateString = res.SaleEnd;
          const SaleEnd: any = new Date(dateString);
          // console.log(saleEndDate, sdate, eDate);

          // console.log(
          //   SaleEnd.toString(),
          //   saleEndDate.isValid(),
          //   saleEndDate.isBetween(sDate, eDate, null, "[]")
          // );
          if (
            SaleEnd.toString() !== "Invalid Date" &&
            saleEndDate.isValid() &&
            saleEndDate.isBetween(sDate, eDate, null, "[]")
          ) {
            return (
              SaleEnd.toString() !== "Invalid Date" &&
              saleEndDate.isValid() &&
              saleEndDate.isBetween(sDate, eDate, null, "[]")
            );
          } else {
            invaliddateerr.push(res);
          }
          // }
        });
        // console.log(checkVipfuelValidDate);
        //for loop is to loop inside the array which has a valid date
        for (let i = 0; i < checkVipfuelValidDate.length; i++) {
          const jsonItem: any = checkVipfuelValidDate[i];
          let jsonDate: any;
          const dateString = jsonItem.SaleEnd;
          const date: any = new Date(dateString);

          if (!isNaN(date)) {
            jsonDate = new Date(jsonItem["SaleEnd"]);
          } else {
            let saleend = FormatInvalidDate(jsonItem.SaleEnd);
            jsonDate = new Date(saleend);
          }
          const matchedDriverItem = commonItems.filter((driverItem: any) => {
            const fromDate = new Date(driverItem.fromdate);
            const handoverDate = driverItem.handoverdate
              ? new Date(driverItem.handoverdate)
              : new Date();

            jsonItem["fromDate"] = fromDate;
            jsonItem["handoverDate"] = handoverDate;
            //if condition will check the array of items which has a allocation
            if (
              jsonItem["VehiclePlateNumber"].replace(/-|DXB|SHJ|AUX/g, "") ===
                driverItem.vehicleid &&
              jsonDate >= fromDate &&
              jsonDate <= handoverDate
            ) {
              //if allocation exist map some of the allocation data to the excel item
              jsonItem["fromdrivername"] = driverItem.fromdrivername;
              jsonItem["handoverdrivername"] = driverItem.handoverdrivername;
              jsonItem["vehicleid"] = driverItem.vehicleid;
              jsonItem["fromdriverID"] = driverItem.fromdriverID;
              jsonItem["driverID"] = driverItem.driverID;
              jsonItem["id"] = driverItem.id;
              // push all the valid items in salikimporteddata array
              vipfuelimporteddata.push(jsonItem);
              return vipfuelimporteddata;
            }
          });
        }
        // console.log(vipfuelimporteddata);
        //removeSalikDuplicatesById used to remove the duplication of  salikimporteddata
        const removeVipFuelDuplicatesById = (arr: any) => {
          const uniqueIds = new Set();
          return arr.filter((obj: any) => {
            const key = `${obj.SaleEnd}-${obj.RID}-${obj.ReceiptNr}`;
            if (!uniqueIds.has(key)) {
              uniqueIds.add(key);
              return true;
            }
            return false;
          });
        };
        const uniqueVipfueldata =
          removeVipFuelDuplicatesById(vipfuelimporteddata);
        // console.log(uniqueVipfueldata);
        uniqueVipfueldata.map((driverItem: any) => {
          let jsonDate;
          const dateString = driverItem.SaleEnd;
          const date: any = new Date(dateString);

          if (!isNaN(date)) {
            jsonDate = new Date(driverItem["SaleEnd"]);
          } else {
            let saleend = FormatInvalidDate(driverItem.SaleEnd);
            jsonDate = new Date(saleend);
          }
          let dn =
            driverItem.fromdrivername !== null
              ? driverItem.fromdrivername
              : driverItem.handoverdrivername;
          let aa = moment(jsonDate).format("YYYY-MM-DD");
          let key =
            driverItem.vehicleid +
            "#" +
            moment(`${aa} 00:00:00`).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") +
            "#" +
            dn;

          let vipfuelData: any = {
            date: moment(`${aa} 00:00:00`).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            transactiondate: moment(`${aa} 00:00:00`).format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            ),
            vehicleid:
              driverItem["VehiclePlateNumber"] !== undefined
                ? driverItem["VehiclePlateNumber"].replace(/-|DXB|SHJ|AUX/g, "")
                : "",
            rid: driverItem["RID"] !== undefined ? driverItem["RID"] : "",
            unitprice:
              driverItem["UnitPrice"] !== undefined
                ? driverItem["UnitPrice"]
                : "",
            volume:
              driverItem["Volume"] !== undefined ? driverItem["Volume"] : "",
            total: driverItem["total"] !== undefined ? driverItem["total"] : "",
            productname:
              driverItem["ProductName"] !== undefined
                ? driverItem["ProductName"]
                : "",
            receiptno:
              driverItem["ReceiptNr"] !== undefined
                ? driverItem["ReceiptNr"]
                : "",
            odometer:
              driverItem["Odometer"] !== undefined
                ? driverItem["Odometer"]
                : "",
            idunitcode:
              driverItem["IDUnitCode"] !== undefined
                ? driverItem["IDUnitCode"]
                : "",
            stationname:
              driverItem["StationName"] !== undefined
                ? driverItem["StationName"]
                : "",
            stationcode:
              driverItem["StationCode"] !== undefined
                ? driverItem["StationCode"]
                : "",
            fleetname:
              driverItem["FleetName"] !== undefined
                ? driverItem["FleetName"]
                : "",
            groupname:
              driverItem["GroupName"] !== undefined
                ? driverItem["GroupName"]
                : "",
            vehiclecode:
              driverItem["VehicleCode"] !== undefined
                ? driverItem["VehicleCode"]
                : "",
            citycode:
              driverItem["CityCode"] !== undefined
                ? driverItem["CityCode"]
                : "",
            costcenter:
              driverItem["CostCenter"] !== undefined
                ? driverItem["CostCenter"]
                : "",
            vatrate:
              driverItem["VatRate"] !== undefined ? driverItem["VatRate"] : "",
            vatamount:
              driverItem["VATAMOUNT"] !== undefined
                ? driverItem["VATAMOUNT"]
                : "",
            actualamount:
              driverItem["ACTUALAMOUNT"] !== undefined
                ? driverItem["ACTUALAMOUNT"]
                : "",
            driverid:
              driverItem.fromdriverID !== null
                ? driverItem.fromdriverID
                : driverItem.driverID,
            drivername:
              driverItem.fromdrivername !== null
                ? driverItem.fromdrivername
                : driverItem.handoverdrivername,
            jfield: JSON.stringify({
              allocationid: driverItem.id,
            }),
            dxMasterLogsVipfuelId: key,
          };

          if (
            driverItem["ReceiptNr"] !== undefined &&
            driverItem["ReceiptNr"] !== null
          ) {
            vipfuelData[
              "id"
            ] = `${driverItem["ReceiptNr"]}#${driverItem["StationCode"]}`;
          }

          items.push(vipfuelData);
        });
        function findUnImportedVipfuel(arr1: any, arr2: any) {
          // Compare arr1 with arr2 and return objects that are unique to arr1
          const uniqueObjectsInArr1 = arr1.filter((obj1: any) => {
            // Check if obj1 exists in arr2 based on a specific property (e.g., "Transaction ID")
            return !arr2.some(
              (obj2: any) => obj2["ReceiptNr"] === obj1["ReceiptNr"]
            );
          });
          return uniqueObjectsInArr1;
        }
        const oddObjects = findUnImportedVipfuel(
          checkVipfuelValidDate,
          uniqueVipfueldata
        );
        // console.log(oddObjects);
        const removeduplidateInvalidDate = (arr: any) => {
          const uniqueIds = new Set();
          return arr.filter((obj: any) => {
            const key = `${obj.SaleEnd}-${obj.RID}-${obj.ReceiptNr}`;
            if (!uniqueIds.has(key)) {
              uniqueIds.add(key);
              return true;
            }
            return false;
          });
        };
        const uniqueInvalidDate = removeduplidateInvalidDate(invaliddateerr);
        // console.log(uniqueVipfueldata);
        setInvalidDate(uniqueInvalidDate);
        setErrorMessage(oddObjects);
      }
      if (charges.name === "Cash Reimbursement") {
        let cashreimburseData: any = [];
        // console.log(jsonData);

        let checkCashReimburseData: any = jsonData.filter((item: any) => {
          const date = moment(item.date, [
            "D-MMM-YY",
            "M/D/YYYY",
            "D/M/YYYY",

            "M/D/YYYY",
            "M-D-YYYY",
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
            "YYYY-MM-DD",
            "MM/DD/YYYY",
            "MM-DD-YYYY",
            "DD/MM/YYYY",
            "DD-MM-YYYY",
          ]);
          const sDate = moment(sdate).startOf("day");
          const eDate = moment(edate).endOf("day");
          const cdate: any = new Date(item.date);
          // console.log(
          //   cdate,
          //   cdate.toString(),
          //   // date,
          //   cdate.toString() !== "Invalid Date",
          //   date.isValid(),
          //   // sdate,
          //   // eDate,
          //   date.isBetween(sDate, eDate, null, "[]")
          // );

          if (
            cdate.toString() !== "Invalid Date" &&
            date.isValid() &&
            date.isBetween(sDate, eDate, null, "[]")
          ) {
            return (
              cdate.toString() !== "Invalid Date" &&
              date.isValid() &&
              date.isBetween(sDate, eDate, null, "[]")
            );
          } else {
            invaliddateerr.push(item);
            // setInvalidDate((prevItems: any) => [...prevItems, item]);
          }
        });
        // console.log(checkCashReimburseData);
        for (let i = 0; i < checkCashReimburseData.length; i++) {
          const jsonItem: any = checkCashReimburseData[i];
          const jsonDate = new Date(jsonItem["date"]);

          const dateString = jsonItem["date"];
          const dateParts = dateString.split("/");
          // console.log(dateParts);
          const date = new Date(
            `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`
          );
          // console.log(date);
          const formattedDate = date.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          });

          const matchingDriver = driver.find(
            (driver: any) => driver.dxDriverAllocationsId === jsonItem.driverid
          );

          const dname = driverName.find((d: any) =>
            d.name.toLowerCase().includes(jsonItem.paidto.toLowerCase())
          );
          // console.log(dname);

          if (dname !== undefined) {
            let dn =
              jsonItem.paidto == "NoDriver" ||
              jsonItem.paidto == "No driver" ||
              jsonItem.paidto == "Nodriver" ||
              jsonItem.paidto == "nodriver" ||
              jsonItem.paidto == "No Driver"
                ? "NoDriver"
                : matchingDriver && matchingDriver.driver
                ? matchingDriver.driver.name
                : dname.name;
            let aa = moment(formattedDate).format("YYYY-MM-DD");
            //   console.log(aa);

            let key =
              matchingDriver && matchingDriver.vehicleid
                ? matchingDriver.vehicleid
                : "" +
                  "#" +
                  moment(`${aa} 00:00:00`).format(
                    "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                  ) +
                  "#" +
                  dn;
            // update log fields from masterlog in expense logs
            // update logreimbursement in masterlog
            let id: any = Math.random().toString(36).substring(2, 15);
            let reimbursementData = {
              //id: driverItem.vehicleid + '#' + moment(`${aa}`).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') + '#' + dn,
              date: moment(`${aa} 00:00:00`).format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              ),
              //  moment(`${aa} 00:00:00`).format(
              //   "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              // ),
              Vendorname: id,
              transactiondate: moment(`${aa} 00:00:00`).format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              ),
              paidto:
                matchingDriver && matchingDriver.driver
                  ? matchingDriver.driver.name
                  : jsonItem.paidto,
              description: jsonItem.description,
              amount: jsonItem.amount.replaceAll(" ", "").toString(),
              reimbursedamount: jsonItem.amount.replaceAll(" ", ""),
              expensetype: jsonItem.expensetype,
              invoiceno: jsonItem.No ? jsonItem.No : "",
              split: jsonItem.Split ? jsonItem.Split : "",
              remarks: jsonItem.Name ? jsonItem.Name : "",
              vehicleid:
                jsonItem.vehicleid == "NoVehicle" ||
                jsonItem.paidto == "No vehicle" ||
                jsonItem.paidto == "Novehicle" ||
                jsonItem.paidto == "novehicle" ||
                jsonItem.paidto == "No Vehicle"
                  ? "NoVehicle"
                  : matchingDriver && matchingDriver.vehicleid
                  ? matchingDriver.vehicleid
                  : jsonItem.vehicleid,
              dxVehicleLogreimbursementId:
                jsonItem.vehicleid == "NoVehicle" ||
                jsonItem.paidto == "No vehicle" ||
                jsonItem.paidto == "Novehicle" ||
                jsonItem.paidto == "novehicle" ||
                jsonItem.paidto == "No Vehicle"
                  ? "NoVehicle"
                  : matchingDriver && matchingDriver.vehicleid
                  ? matchingDriver.vehicleid
                  : jsonItem.vehicleid,
              driverid:
                jsonItem.paidto == "NoDriver" ||
                jsonItem.paidto == "No driver" ||
                jsonItem.paidto == "Nodriver" ||
                jsonItem.paidto == "nodriver" ||
                jsonItem.paidto == "No Driver"
                  ? 168914607130889
                  : matchingDriver && matchingDriver.driver
                  ? matchingDriver.driver.id
                  : dname.name,
              dxDriverLogreimbursementId:
                jsonItem.paidto == "NoDriver" ||
                jsonItem.paidto == "No driver" ||
                jsonItem.paidto == "Nodriver" ||
                jsonItem.paidto == "nodriver" ||
                jsonItem.paidto == "No Driver"
                  ? "NoDriver"
                  : matchingDriver && matchingDriver.driver
                  ? matchingDriver.driver.id
                  : dname.name,
              drivername:
                jsonItem.paidto == "NoDriver" ||
                jsonItem.paidto == "No driver" ||
                jsonItem.paidto == "Nodriver" ||
                jsonItem.paidto == "nodriver" ||
                jsonItem.paidto == "No Driver"
                  ? "NoDriver"
                  : matchingDriver && matchingDriver.driver
                  ? matchingDriver.driver.name
                  : dname.name,
              isreimbursed: jsonItem.isreimbursed
                ? jsonItem.isreimbursed
                : "Yes",
              jfield: JSON.stringify({
                allocationid:
                  matchingDriver && matchingDriver.id
                    ? matchingDriver.id
                    : dname.name,
              }),
              dxMasterLogsLogreimbursementId:
                matchingDriver && matchingDriver.vehicleid
                  ? matchingDriver.vehicleid
                  : jsonItem.vehicleid +
                    "#" +
                    moment(`${aa} 00:00:00`).format(
                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                    ) +
                    "#" +
                    dn +
                    "#" +
                    id,
            };
            // console.log(reimbursementData);

            items.push(reimbursementData);
          } else {
            importfailed.push(jsonItem);
          }
        }
        let uniqueKeys = new Map();
        // console.log(importfailed);

        // Filter the JSON array to remove duplicates
        let filteredJSON = importfailed.filter((item: any) => {
          const key = `${item.date}-${item.description}-${item.paidto}`;
          if (!uniqueKeys.has(key)) {
            uniqueKeys.set(key, true);
            return true;
          }
          return false;
        });

        // console.log(filteredJSON);
        setErrorMessage(filteredJSON);
        let filterinvaliddate = invaliddateerr.filter((item: any) => {
          const key = `${item.date}-${item.description}-${item.paidto}`;
          if (!uniqueKeys.has(key)) {
            uniqueKeys.set(key, true);
            return true;
          }
          return false;
        });

        // console.log(filterinvaliddate);
        setInvalidDate(filterinvaliddate);
      }
    }
    // console.log(items);

    if (charges.name == "Fines") {
      updateData1(uniquefilteredFinedata);
    } else {
      updateData1(items);
    }
  };

  useEffect(() => {
    if (errorMessage.length == 0 && invaliddate.length == 0) {
      setCharges([]);
    }
  }, [errorMessage, invaliddate]);
  const updateDatas = async (data: any) => {
    console.log(data);
  };
  const updateData1 = async (data: any) => {
    console.log(data);
    const removeSalikDuplicatesById = (arr: any) => {
      const uniqueIds = new Set();
      return arr.filter((obj: any) => {
        if (!uniqueIds.has(obj.id)) {
          uniqueIds.add(obj.id);
          return true;
        }
        return false;
      });
    };

    const uniqueSalikdata = removeSalikDuplicatesById(data);
    const removeVipfuelDuplicatesById = (arr: any) => {
      const uniqueIds = new Set();
      return arr.filter((obj: any) => {
        if (!uniqueIds.has(obj.receiptno)) {
          uniqueIds.add(obj.receiptno);
          return true;
        }
        return false;
      });
    };

    const uniquevipFueldata = removeVipfuelDuplicatesById(data);
    // console.log(uniqueSalikdata);
    let countItem = 0;
    setSavingData(true);
    if (data.length === 0) {
      setSavingData(false);
      setJsonData([]);
    }
    let masterdata =
      charges.name === "Salik"
        ? uniqueSalikdata
        : charges.name === "VIP Fuel"
        ? uniquevipFueldata
        : data;
    let masterobj: any = {};
    // console.log(masterdata);

    masterdata.forEach((obj: any) => {
      // console.log(obj);
      let key: any;
      if (
        charges.name === "Cash Reimbursement" &&
        obj.vehicleid == "NoVehicle"
      ) {
        key =
          obj.vehicleid +
          "#" +
          localetoisodatetime(
            moment(obj.date.split("T")[0]).format("YYYY-MM-DD")
          ) +
          "#" +
          obj.drivername +
          "#" +
          obj.Vendorname;
      } else {
        key =
          obj.vehicleid +
          "#" +
          localetoisodatetime(
            moment(obj.date.split("T")[0]).format("YYYY-MM-DD")
          ) +
          "#" +
          obj.drivername;
      }

      // if (obj.transactiondate !== obj.date) {
      //     key = obj.vehicleid + '#' + obj.date + '#' + obj.drivername;
      // }
      if (!masterobj[key]) {
        masterobj[key] = [];
      }
      masterobj[key].push(obj);
    });

    let tempmasteritems = Object.keys(masterobj);
    // const masterlogfilterItem = {
    //     and: {
    //         date: {
    //             le: data[0].date,
    //             ge: data[data.length - 1].date
    //         }
    //     }
    // };
    const masterlogfilterItem = {
      and: {
        date: {
          le: edate,
          ge: sdate,
        },
      },
    };
    let details = [];
    let nextToken: any = null;
    do {
      const { items, nextToken: newNextToken } = await dxService.listitems(
        "masterlog",
        "*",
        masterlogfilterItem,
        true,
        10000,
        nextToken
      );
      details.push(...items);
      nextToken = newNextToken;
    } while (nextToken);
    var objectWithGroupByName: any = {};
    for (var key in details) {
      var name = details[key].id;
      if (!objectWithGroupByName[name]) {
        objectWithGroupByName[name] = [];
      }
      objectWithGroupByName[name].push(details[key]);
    }

    // for (const mi of tempmasteritems) {
    tempmasteritems.map(async (mi) => {
      let ddate = masterobj[mi][0].transactiondate;
      if (masterobj[mi][0].transactiondate != masterobj[mi][0].date) {
        ddate = masterobj[mi][0].date;
      }
      const masterData = {
        id: mi,
        vehicleid: masterobj[mi][0].vehicleid,
        drivername: masterobj[mi][0].drivername,
        dxVehicleMasterlogsId: masterobj[mi][0].vehicleid,
        dxVehicleActivityLogsId: masterobj[mi][0].vehicleid,
        dxDriverMasterlogsId: masterobj[mi][0].driverid,
        dxAllocationsMasterlogsId: JSON.parse(masterobj[mi][0].jfield)[
          "allocationid"
        ],
        // date: ddate
        date: localetoisodatetime(
          moment(masterobj[mi][0].date.split("T")[0]).format("YYYY-MM-DD")
        ),
        // Fine: null,
        // Salik: null,
        // Vipfuel: null

        // id:vehicleid+"#"+transactiondate+"#"+drivername,
        // date: charges.name === 'Fines' ? date : charges.name === 'Salik' ? transactiondate : date,
        // Fine: charges.name === 'Fines' ? item.amount : '',
        // Salik: charges.name === 'Salik' ? item.amount : '',
        // Vipfuel: charges.name === 'VIP Fuel' ? item.total : ''
      };
      try {
        const tableName = [
          "masterlog",
          charges.name === "Fines"
            ? "logfines"
            : charges.name === "Salik"
            ? "logsalik"
            : charges.name === "VIP Fuel"
            ? "logvipfuels"
            : "",
        ];

        let findmasterlog = await dxService.getitembyid(
          "masterlog",
          masterData.id
        );
        // console.log(findmasterlog);

        if (findmasterlog != null) {
        } else {
          let a = await dxService.createitem("masterlog", masterData);
          savedData.push(a);
          findmasterlog = a;
        }
        let uv = {};
        if (charges.name === "Fines") {
          let subitems = masterobj[mi];
          let totalamount = 0;
          for (const si of subitems) {
            si["dxMasterLogsFinesId"] =
              si.vehicleid + "#" + si.transactiondate + "#" + si.drivername;
            try {
              let getitem = await dxService.getitembyid("logfines", si.id);
              // console.log(getitem, si);

              // if (getitem !== null) {
              //   let c = await dxService.updateitem("logfines", si);
              //   totalamount += c && c.amount ? parseFloat(c.totalamount) : 0;
              // } else {
              //   let b = await dxService.createitem("logfines", si);
              //   totalamount += b && b.amount ? parseFloat(b.totalamount) : 0;
              // }

              if (getitem !== null) {
                if (getitem.amount != si.amount) {
                  let c = await dxService.updateitem("logfines", si);
                  totalamount += c && c.amount ? parseFloat(c.totalamount) : 0;
                } else {
                  // console.log("si", si);
                  totalamount +=
                    si && si.amount ? parseFloat(si.totalamount) : 0;
                  // console.log(totalamount);
                }
              } else {
                let b = await dxService.createitem("logfines", si);
                totalamount +=
                  b && b.totalamount ? parseFloat(b.totalamount) : 0;
                // console.log(totalamount);
              }
            } catch (e) {}
          }
          uv = {
            id: findmasterlog.id,
            Fine: totalamount,
            _version: findmasterlog._version,
          };
        }
        if (charges.name === "Salik") {
          let subitems = masterobj[mi];
          let totalamount = 0;
          for (const si of subitems) {
            // console.log(si);

            si["dxMasterLogsSalikId"] =
              si.vehicleid +
              "#" +
              localetoisodatetime(
                moment(si.date.split("T")[0]).format("YYYY-MM-DD")
              ) +
              "#" +
              si.drivername;
            try {
              let getitem = await dxService.getitembyid("logsalik", si.id);

              if (getitem !== null) {
                if (getitem.amount != si.amount) {
                  let c = await dxService.updateitem("logsalik", si);
                  // console.log(c);
                  totalamount += c && c.amount ? parseFloat(c.amount) : 0;
                  // console.log(totalamount);
                } else {
                  // console.log("si", si);
                  totalamount += si && si.amount ? parseFloat(si.amount) : 0;
                  // console.log(totalamount);
                }
              } else {
                let b = await dxService.createitem("logsalik", si);
                // console.log(b);
                totalamount += b && b.amount ? parseFloat(b.amount) : 0;
                // console.log(totalamount);
              }
            } catch (e) {}
          }
          uv = {
            id: findmasterlog.id,
            Salik: totalamount,
            _version: findmasterlog._version,
          };
          // console.log(uv);
        }

        if (charges.name === "VIP Fuel") {
          let subitems = masterobj[mi];
          //   console.log(subitems);
          let totalamount = 0;
          for (const si of subitems) {
            // console.log(si);

            si["dxMasterLogsVipfuelId"] =
              si.vehicleid +
              "#" +
              localetoisodatetime(
                moment(si.date.split("T")[0]).format("YYYY-MM-DD")
              ) +
              "#" +
              si.drivername;
            try {
              let getitem = await dxService.getitembyid("logvipfuels", si.id);
              //   console.log("update", getitem);
              if (getitem !== null) {
                if (getitem.total !== si.total) {
                  let c = await dxService.updateitem("logvipfuels", si);
                  //   console.log(c);

                  totalamount += c && c.total ? parseFloat(c.total) : 0;
                  //   console.log(totalamount);
                } else {
                  //   console.log("si", si);
                  totalamount += si && si.total ? parseFloat(si.total) : 0;
                }
              } else {
                // console.log("create", si);
                let b = await dxService.createitem("logvipfuels", si);

                totalamount += b && b.total ? parseFloat(b.total) : 0;
              }
            } catch (e) {}
          }

          uv = {
            id: findmasterlog.id,
            Vipfuel: totalamount,
            _version: findmasterlog._version,
          };
          // countItem++;
          // setTotalAdded(countItem);
        }
        if (charges.name === "Cash Reimbursement") {
          let subitems = masterobj[mi];

          let totalamount = 0;
          for (const si of subitems) {
            // console.log(si);

            if (si.vehicleid == "NoVehicle") {
              si["dxMasterLogsLogreimbursementId"] =
                si.vehicleid +
                "#" +
                si.date +
                "#" +
                si.drivername +
                "#" +
                si.Vendorname;
            } else {
              si["dxMasterLogsLogreimbursementId"] =
                si.vehicleid +
                "#" +
                si.date +
                "#" +
                si.drivername +
                "#" +
                si.Vendorname;
            }

            delete si.transactiondate;
            delete si.drivername;
            try {
              let getitem = await dxService.getitembyid(
                "logreimbursement",
                si.id
              );

              if (getitem !== null) {
                if (getitem.reimbursedamount !== si.reimbursedamount) {
                  let c = await dxService.updateitem("logreimbursement", si);

                  totalamount +=
                    c && c.reimbursedamount
                      ? parseFloat(c.reimbursedamount.replace(/,/g, ""))
                      : 0;
                } else {
                  totalamount +=
                    si && si.reimbursedamount
                      ? parseFloat(si.reimbursedamount.replace(/,/g, ""))
                      : 0;
                }
              } else {
                // console.log(si);

                let b = await dxService.createitem("logreimbursement", si);

                totalamount +=
                  b && b.reimbursedamount
                    ? parseFloat(b.reimbursedamount.replace(/,/g, ""))
                    : 0;
              }
            } catch (e) {
              console.error(e);
            }
          }
          uv = {
            id: findmasterlog.id,
            reimbursement: totalamount,
            _version: findmasterlog._version,
          };
        }
        // console.log(uv);

        let c = await dxService.updateitem("masterlog", uv);

        updatedData.push(c);
        console.log(updateData);

        setSavingData(false);
        setJsonData([]);
        // console.log(errorMessage);
        // setCharges([]);
      } catch (e) {
        console.error("Error updating");
        console.error(e);
      }
    });
  };

  const updateData = async (data: any) => {
    let countItem = 0;
    setSavingData(true);

    try {
      const savedData = await Promise.all(
        data.map(async (item: any, key: number) => {
          const { vehicleid, date, drivername, transactiondate } = item;
          const filterItem = {
            vehicleid: { eq: vehicleid },
            date: { eq: date },
            drivername: { eq: drivername },
          };
          const tableName = [
            "masterlog",
            charges.name === "Fines"
              ? "logfines"
              : charges.name === "Salik"
              ? "logsalik"
              : charges.name === "VIP Fuel"
              ? "logvipfuels"
              : "",
          ];

          for (const table of tableName) {
            try {
              const GetResponse = await dxService.listitems(
                table,
                "*",
                filterItem,
                true,
                100000
              );

              if (GetResponse.items.length === 0) {
                let mdate = new Date(
                  new Date(date).setHours(0, 0, 0, 0)
                ).toDateString();
                const masterData = {
                  vehicleid,
                  drivername,
                  id: vehicleid + "#" + transactiondate + "#" + drivername,
                  date:
                    charges.name === "Fines"
                      ? date
                      : charges.name === "Salik"
                      ? transactiondate
                      : date,
                  Fine: charges.name === "Fines" ? item.amount : "",
                  Salik: charges.name === "Salik" ? item.amount : "",
                  Vipfuel: charges.name === "VIP Fuel" ? item.total : "",
                };
                countItem++;
                const savedData = await dxService.createitem(
                  table,
                  table === "masterlog" ? masterData : item
                );
                setTotalAdded(countItem);
                return savedData;
              } else {
                setTotalAdded(0);
              }
            } catch (err) {
              console.error(err);
            }
          }
        })
      );

      toast.current.show({
        severity: "success",
        summary: countItem + " items added successfully",
        life: 9000,
      });
      setSavingData(false);
      setJsonData([]);
      // setCharges([]);
    } catch (err) {
      console.error(err);
    }
  };
  // console.log(errorMessage);
  // console.log(invaliddate, invaliddate.length > 0);

  return (
    <div className="col-12 md:col-12">
      <Toast ref={toast}></Toast>
      <h4>Import</h4>
      <div className="card p-fluid">
        <div className="field">
          <div className="col-12 sm:col-8 md:col-7 grid">
            <div className="col-12 md:col-2 mt-2">
              <label htmlFor="">Select</label>
            </div>
            <div className="col-12 md:col-10">
              <Dropdown
                options={[
                  { label: "Cash Reimbursement", name: "Cash Reimbursement" },
                  { label: "Fines", name: "Fines" },
                  { label: "Salik", name: "Salik" },
                  { label: "VIP Fuel", name: "VIP Fuel" },
                ]}
                value={charges}
                onChange={(e) => {
                  savedData = [];
                  updatedData = [];
                  setCharges(e.target.value);
                }}
                optionLabel="label"
                placeholder="Select an option"
                filter
              />
            </div>
          </div>
          <div className="col-12 sm:col-8 md:col-7 grid">
            <div className="col-12 md:col-2 mt-2">
              <label htmlFor="">Start Date</label>
            </div>
            <div className="col-12 md:col-4">
              <Calendar
                value={sdate}
                onChange={(e: any) => {
                  setsdate(e.value);
                }}
              ></Calendar>
            </div>
            <div className="col-12 md:col-2 mt-2">
              <label htmlFor="">End Date</label>
            </div>
            <div className="col-12 md:col-4">
              <Calendar
                value={edate}
                onChange={(e: any) => {
                  setedate(e.value);
                }}
              ></Calendar>
            </div>
          </div>
        </div>

        {charges.name !== undefined && (
          <div className="field">
            <div className="col-12 sm:col-8 md:col-7 grid">
              <div className="col-12 md:col-2 mt-2">
                <label htmlFor="">Upload File</label>
              </div>
              <div className="col-12 md:col-10">
                <Documents setJsonData={setJsonData} />
                {/* <FileUpload accept=".xlsx, .xls*" auto={true} customUpload={true} uploadHandler={handleFileUpload} /> */}
              </div>
            </div>
          </div>
        )}
        {/* {charges.name !== "Cash Reimbursement" ? (
          <div className="field grid col-12 md:col-2">
            <Button
              onClick={(e: any) => validateallocations()}
              label="Validate Allocations"
            />
          </div>
        ) : (
          ""
        )} */}

        <div className="field col-5 grid md:col-3">
          <AwsButton
            className=" mr-2"
            disabled={
              charges.name === undefined || jsonData.length === 0 ? true : false
            }
            onClick={async () => {
              checkNaughtyDriver();
              // validatedriver();
            }}
          >
            {savingData === false ? "Submit" : "Saving"}
          </AwsButton>
          <AwsButton
            disabled={savingData ? true : false}
            style={{
              background: "#d32f2f",
              color: "#fff",
              border: "none",
            }}
            onClick={async () => {
              navigate("/");
            }}
          >
            Cancel
          </AwsButton>
        </div>

        {/* <div className="grid col-6 md:col-6">
          {driver.length}
      

          {driver.length > 0 ? (
            <DataTable key={"dt1"} value={driver} responsiveLayout="scroll">
              <Column field="vehicleid" header="Vehicle"></Column>
              <Column field="fromdate" header="From date"></Column>
              <Column field="handoverdate" header="Handover date"></Column>
            </DataTable>
          ) : (
            ""
          )}
        </div> */}
        <div className="grid col-6 md:col-6">
          {"Total items from excel: " + jsonData.length.toString()}
          {/* {"Unimported Items: " +parseIntjsonData.length-errorMessage.length+ */}
          {/* // jsonData.filter((d: any) => { */}
          {/* //   //   console.log(jsonData);

            //   return d.allocation === true;
            // }).length
            } */}
        </div>
        <div className="grid col-6 md:col-6">
          {" "}
          <div
            className={`col-12 ${
              savedData.length === 0 ? "md:col-12" : "md:col-6"
            }`}
            style={{ color: "#b00020" }}
          >
            {invaliddate.length !== 0 && (
              <>
                {charges.name == "Cash Reimbursement" ? (
                  <>
                    {" "}
                    {invaliddate.length > 0 ? (
                      <>
                        {`${invaliddate.length} items failed to import`}{" "}
                        (Invalid date format or Date is not between startdate
                        and enddate)
                        <table
                          border={1.5}
                          cellPadding={0}
                          cellSpacing={0}
                          width="100%"
                        >
                          {" "}
                          <thead>
                            <tr>
                              <th>Date</th>

                              <th>description</th>
                              <th>Plate</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invaliddate.map((tem: any, key: number) => {
                              return (
                                <tr key={key}>
                                  <td>{tem["date"]}</td>
                                  <td>{tem["description"]}</td>
                                  <td>{tem["vehicleid"]}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : charges.name == "VIP Fuel" ? (
                  <>
                    {" "}
                    {invaliddate.length > 0 ? (
                      <>
                        {`${invaliddate.length} items failed to import`}{" "}
                        (Invalid date format or Date is not between startdate
                        and enddate)
                        <table
                          border={1.5}
                          cellPadding={0}
                          cellSpacing={0}
                          width="100%"
                        >
                          {" "}
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Plate</th>
                              <th>Amount</th>
                              <th>ReceiptNr</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invaliddate.map((tem: any, key: number) => {
                              return (
                                <tr key={key}>
                                  <td>{tem["SaleEnd"]}</td>
                                  <td>{tem["VehiclePlateNumber"]}</td>
                                  <td>{tem["total"]}</td>
                                  <td>{tem["ReceiptNr"]}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : charges.name == "Salik" ? (
                  <>
                    {" "}
                    {invaliddate.length > 0 ? (
                      <>
                        {`${invaliddate.length} items failed to import`}{" "}
                        (Invalid date format or Date is not between startdate
                        and enddate)
                        <table
                          border={1.5}
                          cellPadding={0}
                          cellSpacing={0}
                          width="100%"
                        >
                          {" "}
                          <thead>
                            <tr>
                              <th>Plate</th>
                              <th>Transaction Id</th>
                              <th>Date</th>
                              <th>Amount(AED)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invaliddate.map((tem: any, key: number) => {
                              return (
                                <tr key={key}>
                                  <td>{tem["Plate"]}</td>
                                  <td>{tem["Transaction ID"]}</td>
                                  <td>{tem["Trip Date"]}</td>
                                  <td>{tem["Amount(AED)"]}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          <div className="grid col-12 md:col-12">
            <div
              className={`col-12 ${
                savedData.length === 0 ? "md:col-12" : "md:col-6"
              }`}
              style={{ color: "#b00020" }}
            >
              {errorMessage.length !== 0 && (
                <>
                  {charges.name == "Salik" ? (
                    <>
                      {`${errorMessage.length} items failed to import   (Please check the date and allocation for these Plate no)`}

                      <table
                        border={1.5}
                        cellPadding={0}
                        cellSpacing={0}
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>Plate</th>
                            <th>Transaction Id</th>
                            <th>Date</th>
                            <th>Amount(AED)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {errorMessage.map((tem: any, key: number) => {
                            return (
                              <tr key={key}>
                                <td>{tem["Plate"]}</td>
                                <td>{tem["Transaction ID"]}</td>
                                <td>{tem["Trip Date"]}</td>
                                <td>{tem["Amount(AED)"]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : charges.name == "Cash Reimbursement" ? (
                    <>
                      {`${errorMessage.length} items failed to import`}
                      (! Please check the driver name)
                      <table
                        border={1.5}
                        cellPadding={0}
                        cellSpacing={0}
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>Plate</th>
                            <th>Date</th>
                            <th>Paidto</th>
                            <th>description</th>
                            <th>Amount(AED)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {errorMessage.map((tem: any, key: number) => {
                            return (
                              <tr key={key}>
                                <td>{tem["vehicleid"]}</td>
                                <td>{tem["date"]}</td>
                                <td>{tem["paidto"]}</td>
                                <td>{tem["description"]}</td>
                                <td>{tem["amount"]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : charges.name == "VIP Fuel" ? (
                    <>
                      {`${errorMessage.length} items failed to import   (! Please check the allocation)`}

                      <table
                        border={1.5}
                        cellPadding={0}
                        cellSpacing={0}
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Plate</th>
                            <th>Amount</th>
                            <th>ReceiptNr</th>
                          </tr>
                        </thead>
                        <tbody>
                          {errorMessage.map((tem: any, key: number) => {
                            return (
                              <tr key={key}>
                                <td>{tem["SaleEnd"]}</td>
                                <td>{tem["VehiclePlateNumber"]}</td>
                                <td>{tem["total"]}</td>
                                <td>{tem["ReceiptNr"]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
          {jsonData.length > 0 && charges.name === "Salik" ? (
            <DataTable
              key={"dt2"}
              value={jsonData}
              responsiveLayout="scroll"
              className="p-datatable-gridlines"
            >
              <Column field="Transaction ID" header="Transaction ID"></Column>
              <Column field="Plate" header="Plate"></Column>
              <Column field="allocation" header="allocation"></Column>
              <Column field="aavailable" header="Available Status"></Column>
            </DataTable>
          ) : jsonData.length > 0 && charges.name === "VIP Fuel" ? (
            <DataTable
              key={"dt2"}
              value={jsonData}
              className="p-datatable-gridlines"
              responsiveLayout="scroll"
            >
              <Column field="ReceiptNr" header="ReceiptNr"></Column>
              <Column field="VehiclePlateNumber" header="Vehicle Id"></Column>
              <Column field="allocation" header="allocation"></Column>
              <Column field="aavailable" header="Available Status"></Column>
            </DataTable>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ImportLogs;
