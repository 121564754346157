import { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useNavigate } from 'react-router-dom';
import './approvals.css';
import { Amplify, API } from 'aws-amplify';
import awsconfig from '../../aws-exports.js';
import AmplifyServices from '../../Service/Service';
import moment from 'moment';
import ListTable from '../../Components/ListTable';
import { Menu } from 'primereact/menu';
import dxService from '../../Services/dxService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ListTemplate from '../../Components/ListTemplate';


var EditNavigation: any = '';

const ManageApprovals = (props: any) => {
    const toast = useRef<any>(null);
    const actionmenu = useRef<any>(null);
    const [approvaldata, setapprovaldata] = useState<any>([]);
    const [selecteditem, setselecteditem] = useState<any>(null);
    const [Tablefilter, setTableFilters] = useState<any>('');
    const [selectedItems, setselectedItems] = useState<any>([]);
    const [approvalitems, setapprovalitems] = useState<any>([]);
    const [FilterData, setFilterData] = useState<any>('');
    const [deleteItemDialog, setdeleteItemDialog] = useState<boolean>(false);
    const navigate = useNavigate();

    const newNavigateClick = () => navigate('/manage/approvals/item/basic?mode=new');
    EditNavigation = selectedItems != undefined && selectedItems.slice(-1);

    const approvalcolumns = [
      
        { field: 'id', showfield:'title', header: 'Title', fieldtype: 'link', path:'#/manage/approvals/approve?id=', filterOption: false, filterFieldOption: 'id' },
        { field: 'vehicleid', header: 'Vehicle Id', fieldtype: 'text', filterOption: false },
        { field: 'eventtype', header: 'Type', fieldtype: 'text', filterOption: false },
        { field: 'description', header: 'Description', fieldtype: 'text', filterOption: false },
        { field: 'cost', header: 'Cost', fieldtype: 'text', filterOption: false, filterFieldOption: 'id' },
        { field: 'owner', showfield:'displayname', header: 'Requested by', fieldtype: 'user', filterOption: false, filterFieldOption: 'id' },
        { field: 'status', header: 'Status', fieldtype: 'text', filterOption: false, filterFieldOption: 'status' },
        { field: 'id', header: 'ID', fieldtype: 'text', filterOption: false,filterFieldOption: 'id' },
         // { field: 'title', header: 'Title', fieldtype: 'text', filterOption: false, filterFieldOption: 'title' },
        

    ];



    useEffect(() => {
        console.log(selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        getdata();

    }, []);


    const getdata = async () => {
        let allapprovals = await dxService.listitems('approval', '*', {}, true);
        let b: any = [];
        allapprovals.forEach((ele: any) => {
            let res = JSON.parse(ele.resource);
            let resvehicle = res.vehicle;
            // console.log(res);
            let c: any = {
                id: ele.id,
                vehicleid: resvehicle.id,
                model: resvehicle.model,
                eventtype: res.eventtype,
                description: res.description,
                title: ele.title,
                cost: res.cost,
                status: ele.status,
                _deleted: ele._deleted,
                owner: res.userinfo ? JSON.parse(res.userinfo).owner: '',
                pendingfor: moment(res.createdAt).format('DD-MMM-YYYY')
                // quantity: 0,
                //priority: 'Urgent',
                //Approval: 'bamboo-watch.jpg',
                //"RequestorName": res.owner,
                
            };
            b.push(c);
        });
        console.log(b);
        console.log(allapprovals);
        setapprovalitems(b);

    };
    const confirmDeleteSelected = () => {
        // console.log(selectedProducts);

        setdeleteItemDialog(true);
    };
    const SearchOnchange = (e: any) => {
        setFilterData(e.target.value);
    };
    const clearbtn = () => {
        setFilterData('');
    };
    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New" onClick={newNavigateClick} className="p-button-primary mr-2 mb-2 newbtnhover" />

                <Button type="button" label="Actions" icon="pi pi-angle-down" className={'mb-2'} onClick={(e) => actionmenu.current.toggle(e)} />
                <Menu
                    ref={actionmenu}
                    popup
                    model={[
                        {
                            disabled: selectedItems != undefined && selectedItems.length == 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                navigate(`/manage/vehicles/item/basic?mode=edit&id=${selectedItems != undefined && EditNavigation[0].id}&fleettype=vehicle`);
                            }
                        },
                        {
                            disabled: selectedItems != undefined && selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            <div>

                <input value={FilterData} type="text" id="search" onChange={SearchOnchange} placeholder="Search ..." className="p-inputtext p-component homeNews__search" />
                {FilterData.length > 0 ? (
                    <button type="button" className="contentBox__searchClearBtn" onClick={clearbtn}>
                        X
                    </button>
                ) : (
                    ''
                )}
            </div>
        </div>
    );


    return (

        <div className="grid">
        <div className="col-12 md:col-12">
            <h4 className="container-header">Manage Approvals</h4>
            <div className={`datatable-editing-demo ${props.className}`}>
                <Toast ref={toast} />

                <ListTemplate
                    header="Manage Approvals"
                    value={approvalitems}
                    paginator={false}
                    rows={10}
                    dynamicColumns={approvalcolumns}
                    emptyMessage="No approvals found."
                    responsiveLayout="scroll"
                    className="p-datatable-responsive"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Service Contracts"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[5, 10, 25]}
                    //   selection={selectedProducts}
                    multiselect={false}
                />
                </div>
            </div>
        </div>
    );
};
export default ManageApprovals;
