import { Auth } from 'aws-amplify';
import moment from 'moment';
import dxService from '../Services/dxService';
export const helper = () => {};
export const logger = (message: any) => {
    console.log(message);
};

//Calendar Control to DB
export const localetoisodatetime = (d: any) => {
    if (d) {
        try {
            let selectedDate = new Date(d);
            const utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), selectedDate.getHours(), selectedDate.getMinutes(), selectedDate.getSeconds()));

            // convert the UTC date to an ISO string for storage in the database
            const isoString = utcDate.toISOString();

            return isoString;
        } catch (e) {
            console.error('Could not convert', d);
            return null;
        }
    }
    return null;
};
export const localetoisodateendtime = (d: any) => {
    if (d) {
        try {
            let selectedDate = new Date(d);
            selectedDate.setHours(23, 59, 59, 999);
            const utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), selectedDate.getHours(), selectedDate.getMinutes(), selectedDate.getSeconds()));

            // convert the UTC date to an ISO string for storage in the database
            const isoString = utcDate.toISOString();

            return isoString;
        } catch (e) {
            console.error('Could not convert', d);
            return null;
        }
    }
    return null;
};

//DB to Calendar Control / Display
export const isotolocaledatetime = (d: any) => {
    if (d) {
        let isoString = new Date(d.slice(0, -1));
        return isoString;
    }
    return null;
};

export const removenumberseprators = (value: any) => {
    try {
        return value.trim().replace(/\,/g, '');
    } catch (ex) {
        return '';
    }
};

export const convertdate = (d: any) => {
    return moment(d).format('DD-MMM-yyy');
};

//get vehicle id
export const getVehicleoptions = async (): Promise<any[]> => {
    let vehicleIDOptions: any[] = [];
    let columns = `id`;
    let response = await dxService.listitems('vehicle', columns);
    let filteredData = response.filter((item: any) => item._deleted !== true);
    filteredData.map((res: any) => {
        res['label'] = res.id;
        res['value'] = res.id;
        return vehicleIDOptions.push(res);
    });
    return vehicleIDOptions;
};
//get vehicle id
export const getVehicleactivityoptions = async (): Promise<any[]> => {
    let vehicleactivityIDOptions: any[] = [];
    let columns = `id`;
    let response = await dxService.listitems('vehicleactivity', columns);
    let filteredData = response.filter((item: any) => item._deleted !== true);
    filteredData.map((res: any) => {
        res['label'] = res.id;
        res['value'] = res.id;
        return vehicleactivityIDOptions.push(res);
    });
    return vehicleactivityIDOptions;
};
//get the event type from db
export const getEventtypeoptions = async (filtertypes: any[]) => {
    let fquery: any[] = [];
    let retvalue: any = {};
    filtertypes.forEach((ele) => {
        let a = {
            fleettype: {
                eq: ele
            }
        };
        retvalue[ele] = [];
        fquery.push(a);
    });
    let filterItem = fquery;
    let columns = `id name fleettype`;
    for (let i = 0; i < filterItem.length; i++) {
        const element = filterItem[i];
        let response = await dxService.listitems('generalconfiguration', columns, element);
        let filteredData = response.filter((item: any) => item._deleted !== true);

        filteredData.map((res: any) => {
            res['label'] = res.name;
            res['value'] = res.name;
            return retvalue[res.fleettype].push(res);
        });
    }

    return retvalue;
};
// get spareparts
export const getSparePartoptions = async (): Promise<any[]> => {
    let sparesNameOptions: any[] = [];
    let filter = {
        fleettype: {
            eq: 'Parts'
        },
        isactive: {
            eq: true
        }
    };
    let columns = `id name`;
    let response = await dxService.listitems('generalconfiguration', columns, filter);
    let filteredData = response.filter((item: any) => item._deleted !== true);
    filteredData.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        return sparesNameOptions.push(res);
    });
    return sparesNameOptions;
};
// get drivers information
export const getdriveroptions = async () => {
    let response = await dxService.listitems('driver');
    let filteredData = response.filter((item: any) => item._deleted !== true && item.name !== null);
    let drivers: any[] = [];
    filteredData.forEach((d: any) => {
        let a = {
            label: d.name,
            value: d.id,
            item: d
        };
        drivers.push(a);
    });
    return drivers;
};

export const getdriveroptionsName = async () => {
    let response = await dxService.listitems('driver');
    let filteredData = response.filter((item: any) => item._deleted !== true && item.name !== null);
    let drivers: any[] = [];
    filteredData.forEach((d: any) => {
        let a = {
            label: d.name,
            value: d.name,
            item: d
        };
        drivers.push(a);
    });
    return drivers;
};

// get configs information
export const getGenralconfigs = async (type: string) => {
    let filter = {
        fleettype: {
            eq: type
        },
        isactive: {
            eq: true
        }
    };

    let response = await dxService.listitems('generalconfiguration', '*', filter);
    let filteredData: [] = response.filter((item: any) => item._deleted !== true);
    let configs: any[] = [];
    filteredData.forEach((d: any) => {
        let a = {
            label: d.name,
            value: d.name.replace(/\s/g, ''),
            jsonfield: d.jfield ? d.jfield : null,
            item: d
        };
        configs.push(a);
    });
    return configs;
};
//get vendor details
export const getvendoroptions = async () => {
    let vendordata = await dxService.listitems('vendor');
    let filteredData = vendordata.filter((item: any) => item._deleted !== true);
    let vendors: any[] = [];
    filteredData.forEach((v: any) => {
        let a = {
            label: v.vendorname,
            value: v.vendorname,
            item: v,
            vtype: v.vendortype
        };
        vendors.push(a);
    });
    return vendors;
};
//get vendor details with filter type as Company
export const getvendorCompanyoptions = async () => {
    let vendordata = await dxService.listitems('vendor');
    let filteredData = vendordata.filter((item: any) => item._deleted !== true && item.vendortype !== null && item.vendortype === 'Company');
    let vendors: any[] = [];
    filteredData.forEach((v: any) => {
        let a = {
            label: v.vendorname,
            value: v.vendorname,
            item: v
        };
        vendors.push(a);
    });
    return vendors;
};
//get vendor details with filter type as Garage
export const getvendorGarageoptions = async () => {
    let vendordata = await dxService.listitems('vendor');
    let filteredData = vendordata.filter((item: any) => item._deleted !== true && item.vendortype !== null && item.vendortype === 'Garage');
    let garagevendors: any[] = [];
    filteredData.forEach((v: any) => {
        let a = {
            label: v.vendorname,
            value: v.vendorname,
            item: v
        };
        garagevendors.push(a);
    });
    return garagevendors;
};
export const getcurrentallocationfromvehicleactivity = (allocations: any) => {
    if (allocations.items) {
        var currentdate = new Date();
        let allocateitems = allocations.items;
        let a = allocateitems.filter((ele: any) => {
            if (ele.handoverdate == null) return new Date(ele.fromdate) <= currentdate;
            else return new Date(ele.fromdate) <= currentdate && currentdate <= new Date(ele.handoverdate);
        });

        // console.log(a);
        return a;
    } else {
        return null;
    }
};

export const getallocateditemfromvehicleactivity = (allocations: any) => {
    if (allocations.items) {
        let allocateitems = allocations.items;
        let a = allocateitems.filter((ele: any) => {
            return ele.allocatestatus === 'Allocated';
        });
        console.log(a);
        return a;
    } else {
        return null;
    }
};

export const getuserinfo = (item: any, field: string, userfield: string, prop?: string) => {
    console.log(item);
    console.log(field);
    console.log(userfield);
    try {
        if (item[field]) {
            let a = JSON.parse(item[field]);
            return prop ? a[userfield][prop] : a[userfield];
        } else {
            return '';
        }
    } catch (err) {
        return '';
    }
};

export const getuserinfoprofile = async (usrid: string) => {
    let user = await dxService.getitembyid('userprofile', usrid);
    let newitemvalue = {
        userid: user.userid,
        email: user.email,
        displayname: user.displayname ? user.displayname : user.userid
    };
    return newitemvalue;
};
export const getcurrenruserprofile = async () => {
    return Auth.currentAuthenticatedUser().then(async (currentUser): Promise<any> => {
        let newitemvalue = {
            userid: currentUser.username,
            email: currentUser.attributes.email ? currentUser.attributes.email : '',
            displayname: currentUser.attributes.preferred_username ? currentUser.attributes.preferred_username : currentUser.username,
            picture: currentUser.attributes.picture ? currentUser.attributes.picture : currentUser.attributes.picture,
            profile: currentUser.attributes.profile ? currentUser.attributes.profile : currentUser.attributes.profile
        };
        return newitemvalue;
    });
};

export const getcurrentuserDetails = async () => {
    return Auth.currentAuthenticatedUser();
};

/**
 * Returns the array of items from db based on specified application
 * @param nextToken 'returns the nexttoken id'
 * @param idfilter custom filters
 * @param listname 'application name'
 * @param lookup 'Check whether to use custom data model declaration'
 * @param columnlimit 'returns the only necessary column from db'
 */
export const getNextQueryData = async (nextToken?: any, idfilter?: any, listname?: any, lookup?: any, columnlimit?: any) => {
    // let variables: any = {};
    // console.log(nextToken, idfilter, listname);
    let morecolumns = columnlimit ? columnlimit : '*';
    let variables: any = idfilter;
    if (nextToken) {
        variables.nextToken = nextToken;
    }
    let Islookup = lookup ? lookup : false;
    let Documents = await dxService.listitems(`${listname}`, morecolumns, variables, Islookup, 1000, nextToken);
    //   let response = Documents.items;

    let res = Documents.items;
    let nexttoken = Documents.nextToken;
    if (nexttoken) {
        const nextResults = await getNextQueryData(nextToken, idfilter, listname, lookup);
        res = res.concat(nextResults);
    }
    res = res.filter((item: any) => item._deleted !== true);
    // console.log(res);

    return res;
};
export const FormatInvalidDate = (SaleEnd: any) => {
    let saleend = SaleEnd;
    const splitdateString = saleend.split(' ');
    // console.log(splitdateString);
    const sDateformat = moment(splitdateString[0]).format('DD MMM YYYY');
    const eTime = splitdateString[1].replace(/\./g, ':') + ' ' + splitdateString[2];
    //   console.log(sDateformat, eTime);
    const date = moment(new Date(`${sDateformat} ${eTime}`)).format('M/D/YYYY h:mm:ss A');
    return date;
};
/**
 * Returns the unique set of arrays
 * @param arr 'List array of items'
 * @param key column/attrib name
 */
export const removeDuplicates = (arr: any, key: any) => {
    return arr.reduce((acc: any, obj: any) => {
        const found = acc.some((item: any) => item[key] === obj[key]);
        if (!found) {
            acc.push(obj);
        }
        return acc;
    }, []);
};
