import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import './../Reports/Reports.css';
import dxService from '../../Services/dxService';
import { CSVLink } from 'react-csv';
import { localetoisodatetime } from '../../utils/helper';
import { useEffect, useState } from 'react';
import moment from 'moment';

const DriverUsage = () => {
    const [Reports, setReports] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [SelectedDate, setSelectedDate] = useState<any>([]);
    const [filterdriverid, setfilterdriverid] = useState<any>('');
    const [drivername, setDrivername] = useState<any>('');
    const [startDateFormat, setStartDateFormat] = useState<string>('');
    const [endDateFormat, setEndDateFormat] = useState<string>('');
    const [formatdata, setformatdata] = useState<any>([]);
    const [oldformatdata, setoldformdata] = useState<any>([]);
    const [totalfuel, settotalfuel] = useState<any>(0);
    const [totalsaik, settotalsalik] = useState<any>(0);
    const [exportType, setExportType] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [fuelexpandedRows, setFuelExpandedRows] = useState<any>([]);
    const [salikexpandedRows, setSalikExpandedRows] = useState<any>([]);

    useEffect(() => {
        let todate = new Date();
        let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
        let lastfirstdate = new Date(todate.getFullYear(), todate.getMonth() - 1, 1);
        setSelectedDate([lastfirstdate, lastenddate]);
    }, []);

    useEffect(() => {
        // if (drivername.length == 0) {
        getData();
        // }
    }, []);

    const getData = async (nextToken?: any) => {
        let ac = `id vehicleid drivername date Accidents Fine Salik Mileage Parking Vipfuel driver vehicle`;
        let variables: any = {};
        let deptcolumns = `id fleettype name description 
        drivers{
            items{
                name
                id
                masterlogs(filter: {
                date:{
                        ge: "${localetoisodatetime(SelectedDate.length > 0 ? SelectedDate[0] : new Date(new Date().getFullYear(), new Date().getMonth(), 0))}",
                        le: "${localetoisodatetime(SelectedDate.length > 0 ? SelectedDate[1] : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1))}"
                    }         
                }){
                    items{
                        id
                        Fine
                        Salik
                        Vipfuel
                        date
                        vehicleid
                    }
                }
            }            
        }`;
        let a = await dxService.listitems('department', deptcolumns, {}, false);
        console.log(a);
        let totalfuel = 0;
        let totalsalik = 0;
        let allvalues: { department: any; drivers: any; salik: number; fuel: number }[] = [];
        setformatdata(a);

        a.forEach((dept: any) => {
            let dsalik = 0;
            let dfuel = 0;
            //console.log(dept.name,dept.drivers.items.length);
            let driverItems = dept.drivers.items;

            driverItems.forEach((driver: any) => {
                //console.log(driver.id,driver.masterlogs.items.length);
                let masterlogs = driver.masterlogs.items;
                masterlogs.forEach((masterlog: any) => {
                    dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                    dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                });
            });
            totalfuel += dfuel;
            totalsalik += dsalik;
            allvalues.push({
                department: dept.name,
                drivers: driverItems.length,
                salik: dsalik,
                fuel: dfuel
            });
        });

        // console.log(totalfuel);
        // console.log(totalsalik);
        settotalfuel(totalfuel);
        settotalsalik(totalsalik);
        // setformatdata(allvalues);
        setoldformdata(allvalues);
        setLoading(false);

        return a;
    };

    const header = [
        { label: 'Department', key: 'department' },
        { label: 'No. of Drivers', key: 'drivers' },
        { label: 'Total Amount', key: 'fueld' }
    ];
    useEffect(() => {
        getData();
        const startDate = SelectedDate[0];
        const endDate = SelectedDate[1];
        const startDateFormat = startDate ? moment(startDate).format('DD MMM YY') : '';
        const endDateFormat = endDate ? moment(endDate).format('DD MMM YY') : '';

        setStartDateFormat(startDateFormat);
        setEndDateFormat(endDateFormat);
    }, [SelectedDate]);

    const ClearBtn = () => {
        setSelectedDate('');
        // dateformat = [];
        setStartDateFormat('');
        setEndDateFormat('');
        //   dateformat = 0;
    };
    if (SelectedDate !== null && SelectedDate.length > 0) {
        var ShowClrBtn: any = (
            <>
                <button
                    onClick={ClearBtn}
                    className={`clrbtn`}
                    // style={{ display: toggle, position: "relative", bottom: "5px" }}
                >
                    <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" style={{ color: '#db0000', fontWeight: 700 }}></i>
                    &nbsp;
                </button>
            </>
        );
    }

    const actionfloattemplate = (rowData: any, column: any) => {
        let totalfuel = 0;
        let totalsalik = 0;

        let dsalik = 0;
        let dfuel = 0;

        //console.log(dept.name,dept.drivers.items.length);
        let driveritems = rowData.drivers.items;

        driveritems &&
            driveritems.forEach((driver: any) => {
                //console.log(driver.id,driver.masterlogs.items.length);
                let masterlogs = driver.masterlogs.items;
                masterlogs.forEach((masterlog: any) => {
                    dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                    dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                });
            });
        totalfuel += dfuel;
        totalsalik += dsalik;

        let num: any = column.field === 'salik' ? totalsalik : totalfuel;
        // Check if the input is a number and has more than two decimal places

        if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
            // Round off to two decimal places
            num = Number(Number(num).toFixed(2));
        }
        return <>{num !== null ? 'AED ' + num : '-'}</>;
    };
    const actionsubfloattemplate = (rowData: any, column: any) => {
        let dsalik = 0;
        let dfuel = 0;

        let masterlogs = rowData.masterlogs.items;
        masterlogs &&
            masterlogs.forEach((masterlog: any) => {
                dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
            });
        let num: any = column.field === 'salik' ? dsalik : dfuel;
        // Check if the input is a number and has more than two decimal places
        if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
            // Round off to two decimal places
            num = Number(Number(num).toFixed(2));
        }
        return <>{num !== null ? 'AED ' + num : '-'}</>;
    };

    const usagetemplate = (rowData: any, column: any) => {
        let dsalik = 0;
        let dfuel = 0;

        //console.log(dept.name,dept.drivers.items.length);
        let driveritems = rowData.drivers.items;

        driveritems &&
            driveritems.forEach((driver: any) => {
                //console.log(driver.id,driver.masterlogs.items.length);
                let masterlogs = driver.masterlogs.items;
                masterlogs.forEach((masterlog: any) => {
                    dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
                    dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
                });
            });

        let num: any = column.field === 'salik' ? dsalik : dfuel;
        const usagevalue = column.field === 'fuel' ? (num / totalfuel) * 100 : (num / totalsaik) * 100;
        const formattedValue = isNaN(usagevalue) ? '0' : `${usagevalue.toFixed(2)}%`;
        // accumulate usage values
        // accumulate usage values

        // setTotalFuelUsage(fuelusage);
        // setTotalSalikUsage(salikusage);
        return <>{formattedValue}</>;
    };
    const subusagetemplate = (rowData: any, column: any) => {
        let dsalik = 0;
        let dfuel = 0;

        let masterlogs = rowData.masterlogs.items;
        masterlogs.forEach((masterlog: any) => {
            dsalik += masterlog.Salik ? parseFloat(masterlog.Salik) : 0;
            dfuel += masterlog.Vipfuel ? parseFloat(masterlog.Vipfuel) : 0;
        });

        let num: any = column.field === 'salik' ? dsalik : dfuel;
        const usagevalue = column.field === 'fuel' ? (num / totalfuel) * 100 : (num / totalsaik) * 100;
        const formattedValue = isNaN(usagevalue) ? '0' : `${usagevalue.toFixed(2)}%`;
        // accumulate usage values
        // accumulate usage values

        // setTotalFuelUsage(fuelusage);
        // setTotalSalikUsage(salikusage);
        return <>{formattedValue}</>;
    };
    const actionsubvehicleid = (rowData: any, column: any) => {
        let masterlogs = rowData.masterlogs.items;
        return <>{masterlogs.length > 0 ? masterlogs[0].vehicleid : '-'}</>;
    };

    const totalSalikUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.salik, 0);
    const totalFuelUsage = oldformatdata.reduce((acc: any, cur: any) => acc + cur.fuel, 0);
    const totalDrivers = oldformatdata.reduce((acc: any, cur: any) => acc + cur.drivers, 0);

    const exportdata = (data: any, type: any) => {
        let items: any = [];
        let TotalAmountType = type === 'fuel' ? 'Total Fuel Amount' : 'Total Salik Amount';
        let TotalAmount = type === 'fuel' ? totalfuel : totalsaik;
        let totalperusage = type === 'salik' ? (totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`) : totalFuelUsage === 0 ? '0%' : `${((100 * totalfuel) / totalFuelUsage).toFixed(2)}%`;
        data.map((exportitem: any) => {
            let num: any = exportitem[type];
            // Check if the input is a number and has more than two decimal places
            if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
                // Round off to two decimal places
                num = Number(Number(num).toFixed(2));
            }
            //% usage
            const usagevalue = type === 'fuel' ? (num / totalfuel) * 100 : (num / totalsaik) * 100;
            const formattedValue = isNaN(usagevalue) ? '0' : `${usagevalue.toFixed(2)}%`;
            let a = {
                Department: exportitem.department,
                'Total No. of drivers': exportitem.drivers,
                [TotalAmountType]: num,
                '% in usage': formattedValue
            };
            items.push(a);
        });
        // Add footer row
        let footer = {
            Department: 'Total',
            'Total No. of drivers': totalDrivers,
            [TotalAmountType]: 'AED ' + TotalAmount,
            '% in usage': totalperusage
        };

        items.push(footer);
        return items;
    };

    const csvLink = {
        filename:
            SelectedDate.length > 0 && startDateFormat && endDateFormat
                ? `Consumption_${exportType}_${startDateFormat}-${endDateFormat}` + (drivername !== '' ? `_${drivername}` : '') + (filterdriverid !== '' ? `_${filterdriverid}` : '')
                : 'Comsumption' + `_${exportType}` + (drivername !== '' ? `_${drivername}` : '') + (filterdriverid !== '' ? `_${filterdriverid}` : '') + '.csv',
        header: header,
        data: oldformatdata.length > 0 ? exportdata(oldformatdata, exportType) : Reports
    };
    const Commandbar = (
        <div className="p-toolbar p-component spd-removebg">
            <div className="p-toolbar-group-start p-toolbar-group-left">
                <label htmlFor="range">Select Date Range: </label>
                <Calendar
                    id="range"
                    className="dateFilter"
                    value={SelectedDate}
                    dateFormat="dd/m/yy"
                    onChange={(e) => {
                        setSelectedDate(e.value);
                    }}
                    selectionMode="range"
                    readOnlyInput
                    placeholder="Select date"
                />

                {ShowClrBtn}
            </div>
        </div>
    );
    const actionDriverCount = (rowData: any) => {
        return <>{rowData.drivers.items.length}</>;
    };

    const fuelrowExpansionTemplate = (data: any) => {
        return (
            <div className="orders-subtable">
                <DataTable
                    value={data.drivers.items}
                    responsiveLayout="scroll"
                    className="p-datatable-responsive usagetable"
                    header={
                        <div className="p-toolbar p-component">
                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                <h5>Details for {data.name}</h5>
                            </div>
                            {/* <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                <CSVLink {...csvLink} onClick={() => setExportType(data.name)}>
                                    <span className="p-float-label exporttext text-white">Export {data.name}</span>
                                </CSVLink>
                            </div> */}
                        </div>
                    }
                >
                    <Column field="name" className="text-left p-message p-message-success" header="Driver" />
                    <Column field="vehicle" className="text-center p-message p-message-success" header="Vehicle" body={actionsubvehicleid} />
                    <Column field="fuel" className="text-right p-message p-message-success" header="Total Fuel Amount" body={actionsubfloattemplate} />
                    <Column field="fuel" className="text-right p-message p-message-success" header="% in Usage" body={subusagetemplate} />
                </DataTable>
            </div>
        );
    };

    const salikrowExpansionTemplate = (data: any) => {
        return (
            <div className="orders-subtable">
                <DataTable
                    value={data.drivers.items}
                    responsiveLayout="scroll"
                    className="p-datatable-responsive usagetable"
                    header={
                        <div className="p-toolbar p-component">
                            <div className="p-toolbar-group-start p-toolbar-group-left"></div>
                            {/* <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                <CSVLink {...csvLink} onClick={() => setExportType(data.name)}>
                                    <span className="p-float-label exporttext text-white">Export</span>
                                </CSVLink>
                            </div> */}
                        </div>
                    }
                >
                    <Column field="name" className="text-left p-message p-message-success" header="Driver" />
                    <Column field="vehicle" className="text-center p-message p-message-success" header="Vehicle" body={actionsubvehicleid} />
                    <Column field="salik" className="text-right p-message p-message-success" header="Total Salik Amount" body={actionsubfloattemplate} />
                    <Column field="salik" className="text-right p-message p-message-success" header="% in Usage" body={subusagetemplate} />
                </DataTable>
            </div>
        );
    };

    return (
        <div className="grid ">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Driver Usage Report</h4>

                {Commandbar}
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card">
                            <DataTable
                                value={formatdata}
                                loading={loading}
                                // header={Commandbar}
                                header={
                                    <div className="p-toolbar p-component">
                                        <div className="p-toolbar-group-start p-toolbar-group-left">
                                            <h5>Fuel</h5>
                                        </div>
                                        <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                            <CSVLink {...csvLink} onClick={() => setExportType('fuel')}>
                                                <span className="p-float-label exporttext text-white">Export</span>
                                            </CSVLink>
                                        </div>
                                    </div>
                                }
                                className="p-datatable-responsive"
                                responsiveLayout="scroll"
                                rows={300}
                                selection={selectedProduct}
                                onSelectionChange={(e) => setSelectedProduct(e.value)}
                                rowExpansionTemplate={fuelrowExpansionTemplate}
                                expandedRows={fuelexpandedRows}
                                onRowToggle={(e) => setFuelExpandedRows(e.data)}
                            >
                                <Column expander style={{ width: '3em' }} />
                                <Column field="name" className="text-left" sortable header="Department" footer="Total" />
                                <Column header="Total no. of Drivers" body={actionDriverCount} />
                                <Column field="fuel" className="text-right" header="Total Fuel Amount" footer={'AED ' + totalfuel.toFixed(2)} body={actionfloattemplate} />
                                <Column field="fuel" className="text-right" header="% in Usage" body={usagetemplate} footer={totalfuel === 0 ? '0%' : `${((100 * totalfuel) / totalfuel).toFixed(2)}%`} />
                            </DataTable>
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="card">
                            <DataTable
                                value={formatdata}
                                loading={loading}
                                // header={Commandbar}

                                header={
                                    <div className="p-toolbar p-component">
                                        <div className="p-toolbar-group-start p-toolbar-group-left">
                                            <h5>Salik</h5>
                                        </div>
                                        <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                            <CSVLink {...csvLink} onClick={() => setExportType('salik')}>
                                                <span className="p-float-label exporttext text-white">Export</span>
                                            </CSVLink>
                                        </div>
                                    </div>
                                }
                                className="p-datatable-responsive"
                                responsiveLayout="scroll"
                                rows={300}
                                selection={selectedProduct}
                                onSelectionChange={(e) => setSelectedProduct(e.value)}
                                rowExpansionTemplate={salikrowExpansionTemplate}
                                expandedRows={salikexpandedRows}
                                onRowToggle={(e) => setSalikExpandedRows(e.data)}
                            >
                                <Column expander style={{ width: '3em' }} />
                                <Column field="name" className="text-left" sortable header="Department" footer="Total" />
                                <Column header="Total no. of Drivers" body={actionDriverCount} />
                                <Column field="salik" className="text-right" header="Total Salik Amount" body={actionfloattemplate} footer={'AED ' + totalsaik} />
                                <Column field="salik" className="text-right" header="% in Usage" body={usagetemplate} footer={totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DriverUsage;
