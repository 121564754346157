import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createDxContract, createDxVehicle, createDxVehicleActivity, deleteDxVehicle, deleteDxVehicleActivity, updateDxContract, updateDxVehicle, updateDxVehicleActivity } from '../../graphql/mutations';
import { listDxContracts, listDxVehicles, listDxVehicleActivities } from '../../graphql/queries';
import ListnewForm from '../../Components/ListnewForm';
import AmplifyServices from '../../Service/Service';

import UploadDocuments from '../../Components/UploadDocuments';
import { Button } from 'primereact/button';
import ListForm from '../../Components/ListForm';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ListTable from '../../Components/ListTable';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import './Vehicleform.css';
import moment from 'moment';
const VehicleRenewals = (props: any) => {
    let VehicleIdOption: any = [];
    const navigate = useNavigate();
    const [appointmentNeeded, setAppointmentNeeded] = useState(false);
    const [document, setDocument] = useState([]);
    const [vehicleID, setVehicleID] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [vehicleitem, setvehicleitem] = useState('');
    const [vehicleactivityitem, setvehicleactivityitem] = useState({});
    const [updatedata, setUpdatedata] = useState<any>([]);
    const [enableUpload, setEnableUpload] = useState<any>(false);
    const [documentpath, setdocumentpath] = useState<any>([]);
    const [Viewtable, setViewtable] = useState<any>(false);
    const [ScData, setScData] = useState<any>([]);
    const location1 = useLocation();
    const toast = useRef<any>(null);

    const urlParams1 = new URLSearchParams(location1.search);
    var formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
    var formid = urlParams1.has('id') ? urlParams1.get('id') : null;
    useEffect(() => {
        getDocumentDetails();
        getData();
        getcurrentvehicle();
        getRenewals();
    }, [window.location.href, vehicleID]);

    const getcurrentvehicle = async () => {
        // AmplifyServices.getvehicleitem(formid, 'vehicle').then((resp: any) => {
        //     setvehicleitem(resp);
        // });
        AmplifyServices.getvehicleitem(formid).then((resp: any) => {
            console.log(resp);
            setvehicleactivityitem(resp.items[0]);
        });
    };
    const getRenewals = async (nextToken?: any) => {
        // let variables: any = {};
        let variables: any = {
            filter: {
                vehicleid: {
                    eq: formid // filter priority = 1
                }
            }
        };
        if (nextToken) {
            variables.nextToken = nextToken;
        }
        console.log(variables);

        let GetResponse: any = await API.graphql({
            query: listDxContracts,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: variables
        });

        let res = GetResponse.data.listDxContracts.items;
        console.log(res);
        if (GetResponse.data.listDxContracts.nextToken) {
            console.log(GetResponse.data.listDxContracts);

            // const nextResults = await getRenewals(GetResponse.data.listDxContracts.nextToken);

            const nextResults: any = await getRenewals(GetResponse.data.listDxContracts.nextToken);

            console.log(nextResults);

            res = res.concat(nextResults);
        }
        console.log(res);

        let finalres: any = res
            .filter((item: any) => item !== undefined && item._deleted !== true)
            .sort(function (a: any, b: any) {
                return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
            });
        console.log(finalres);
        setScData(formatDatefield(finalres));
        //   setItems(finalres);
        //   setfilteredData(formatDatefield(finalres));
        //   setVehcileViewfilter(url);
        return finalres;
    };
    const getData = async () => {
        setVehicles([]);
        // setDocument([]);

        await AmplifyServices.getTableDetails(listDxVehicles, 'listDxVehicles').then((res: any) => {
            let Fleetvehicle = res.filter((vehicle: any) => vehicle.fleettype == 'vehicle' && vehicle._deleted !== true);

            Fleetvehicle.map((res: any) => {
                res['label'] = res.id;
                res['value'] = res.id;
                // console.log(res);
                VehicleIdOption.push(res);
            });
            setVehicles(VehicleIdOption);
        });
        // await AmplifyServices.getContractdetails(formid)
        //     .then(async (res: any) => {
        //         console.log(res);
        //         res = res
        //             .sort(function (a: any, b: any) {
        //                 return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
        //             })
        //             .filter((item: any) => item._deleted == null || item._deleted == false);
        //         setScData(formatDatefield(res));
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
    };
    const formatDatefield = (data: any) => {
        return [...(data || [])].map((d) => {
            d.issuedate = new Date(d.issuedate);
            d.expirydate = new Date(d.expirydate);
            return d;
        });
    };
    const getDocumentDetails = async () => {
        await AmplifyServices.getFilteredTableDetails(listDxContracts, 'listDxContracts', VehicleID).then((res: any) => {
            let contracts = res.filter((vehicle: any) => vehicle._deleted !== true);
            setDocument(contracts);
        });
    };

    useEffect(() => {
        vehicleID !== '' && navigate('?id=' + vehicleID);
    }, [vehicleID]);
    const location = window.location.href;
    const urlParams = new URLSearchParams(location.split('?')[1]);

    var VehicleID = urlParams.get('id');

    var expType = urlParams.get('exp');

    const columns = [
        { field: 'vehicleid', textalign: 'left', header: 'Vehicle', fieldtype: 'navigatedocument', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'renewaltype', textalign: 'left', header: 'Renewal Type', fieldtype: 'text', filterOption: true, filterFieldOption: 'renewaltype' },
        { field: 'renewalstatus', textalign: 'left', header: 'Renewal Status', fieldtype: 'text', filterOption: true, filterFieldOption: 'renewalstatus' },
        { field: 'issuedate', textalign: 'left', header: 'Start Date', fieldtype: 'datefilter', dataType: 'date', filterOption: true, filterFieldOption: 'issuedate' },
        { field: 'expirydate', textalign: 'left', header: 'Expire Date', fieldtype: 'datefilter', dataType: 'date', filterOption: true, filterFieldOption: 'expirydate' }
        // { field: 'status', header: 'Status', fieldtype: 'custom', filterOption: true, filterFieldOption: 'status', template: (r: any) => <>{contractStatusBodyTemplate(r)}</> }
    ];
    console.log(ScData);
    const RenewalNavigation = async (e: any, formid: any) => {
        console.log(e);
        switch (e) {
            case 'Insurance':
                navigate(`/general/workspace/renew?id=${formid}&exp=${e}`);
                break;
            case 'Registration':
                navigate(`/general/workspace/renew?id=${formid}&exp=${e}`);
                break;
            case 'Branding':
                navigate(`/general/workspace/renew?id=${formid}&exp=${e}`);
                break;
            case 'Warranty':
                navigate(`/general/workspace/renew?id=${formid}&exp=${e}`);
                break;
            case 'Parking':
                navigate(`/general/workspace/renew?id=${formid}&exp=${e}`);
                break;
            case 'ServiceContract':
                navigate(`/general/workspace/renew?id=${formid}&exp=${e}`);
                break;
        }
    };
    return (
        <>
            {
                <>
                    <div>
                        <div className="grid">
                            <div className="col-12 md:col-12">
                                <div className="card">
                                    <div className="p-fluid formgrid grid">
                                        <div style={{ display: 'flex' }} className="col-12 md:col-12">
                                            <div className="field col-12 md:col-9">
                                                <h5>View Renewals</h5>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <Badge value="S" className="mr-2 batchStyle" size="large" severity="warning" onClick={(e: any) => RenewalNavigation('ServiceContract', formid)}></Badge>
                                                <Badge value="R" className="mr-2 batchStyle" size="large" severity="warning" onClick={(e: any) => RenewalNavigation('Registration', formid)}></Badge>
                                                <Badge value="I" className="mr-2 batchStyle" size="large" severity="warning" onClick={(e: any) => RenewalNavigation('Insurance', formid)}></Badge>
                                                <Badge value="B" className="mr-2 batchStyle" size="large" severity="warning" onClick={(e: any) => RenewalNavigation('Branding', formid)}></Badge>
                                                <Badge value="W" className="mr-2 batchStyle" size="large" severity="warning" onClick={(e: any) => RenewalNavigation('Warranty', formid)}></Badge>
                                                <Badge value="P" className="mr-2 batchStyle" size="large" severity="warning" onClick={(e: any) => RenewalNavigation('Parking', formid)}></Badge>
                                            </div>
                                        </div>

                                        <div className="field col-12  md:col-12">
                                            <ListTable
                                                header="Manage Contracts"
                                                value={ScData}
                                                paginator={true}
                                                rows={10}
                                                dynamicColumns={columns}
                                                emptyMessage="No Renewals found."
                                                responsiveLayout="scroll"
                                                className="p-datatable-responsive"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Renewals"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                rowsPerPageOptions={[5, 10, 25]}
                                                //   selection={selectedProducts}
                                                multiselect={false}
                                            />
                                        </div>
                                        {/* <DataTable
                                            value={ScData}
                                            dataKey="id"
                                            rowsPerPageOptions={[5, 10, 25]}
                                            className="p-datatable-responsive"
                                            // dynamicColumns={columns}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            emptyMessage="No Documents added."
                                            responsiveLayout="scroll"
                                        >
                                            <Column field="vehicleid" header="Vehicle ID" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            <Column field="renewaltype" header="Renewal Type" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            <Column field="renewalstatus" header="Renewal Status" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            <Column field="issuedate" header="Start Date" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            <Column field="expirydate" header="Expire Date" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                        </DataTable> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default VehicleRenewals;
