export const Groups = [
    {
        value: 'Fleet Administrator', //For Display name
        name: 'FleetAdmin' //DB Group Name
    },
    {
        value: 'Fleet Members', //For Display name
        name: 'FleetMembers' //DB Group Name
    }
];
