import { createContext, useReducer } from 'react';

export const AuthContext = createContext();
export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                user: action.payload
            };
        case 'LOGOUT':
            return { user: null };
        case 'PROFILEUPDATE':
            return { ...state, profileStatus: action.payload };
        case 'NAVIGATION':
            return { ...state, navStatus: action.payload };
        default:
            return state;
    }
};
export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, { user: null, status: null });

    return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};
