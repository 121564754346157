import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import FormTemplateComponent from './FormTemplateComponent';
import FormTemplate2 from './FormTemplate2';
import React from 'react';

const keysToComponent = {
    formtemplatecomponent: FormTemplateComponent
}

function Webparts(props: any) {
    const [displayDialog, setdisplayDialog] = useState(false);
    const [componentcode, setcomponentcode] = useState('');
    const overlay = useRef<any>(null);

    const formcolumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'vehicleid',
                title: 'Vehicle',
                fieldtype: 'text',
                required: true,
                value: '',
                hidden: false,
                disabled: false,

                class: 'md:col-4'
            },
        ],
        actions: [
            {
                title: 'Submit',
            },
            {
                title: 'Cancel'
            }
        ]
    }



    useEffect(() => {
    }, []);
    const changedata = (props: any) => {
        console.log(props);
    };

    const addData = (props: any) => {
        console.log(props);
    };

    let configstr = `{"id":"newform","columns":{"columns":[{"title":"Basic Info","name":"basicinfo","fieldtype":"header","class":"md:col-12"},{"name":"model","title":"Model Name","fieldtype":"text","required":true,"value":"","hidden":false,"disabled":false,"class":"md:col-4"},{"name":"make","title":"Make","fieldtype":"dropdown","required":true,"value":"","hidden":false,"disabled":false,"list":"generalconfiguration","fieldname":"name","query":"{\\"filter\\":{\\"fleettype\\":{\\"eq\\":\\"Make\\"}}}","class":"md:col-4"}],"actions":[{"title":"Submit"},{"title":"Cancel"}]},"data":{},"showaction":true,"onChange":"(props)=>{console.log(props)}","submitForm":"{addData}","ChangeBtn":"OpenDocument","layout":"grid2","title":"New Approval"}`;

    let jsonconfig = JSON.parse(configstr);

    let config = {
        id: 'newform',
        columns: {
            columns: [
                {
                    title: 'Basic Info',
                    name: 'basicinfo',
                    fieldtype: 'header',
                    class: 'md:col-12'
                },
                {
                    name: 'vehicleid',
                    title: 'Vehicle',
                    fieldtype: 'text',
                    required: true,
                    value: '',
                    hidden: false,
                    disabled: false,

                    class: 'md:col-4'
                },
            ],
            actions: [
                {
                    title: 'Submit',
                },
                {
                    title: 'Cancel'
                }
            ]
        },
        data: {},
        showaction: true,
        onChange: changedata,
        submitForm: addData,
        ChangeBtn: 'OpenDocument',
        layout: "grid2",
        title: "New Approval"


    }


    return (
        <div>
            {
                

                React.createElement(keysToComponent['formtemplatecomponent'], jsonconfig)
                
            }
        </div>
    );
}
export default Webparts;
