import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dxService from '../Services/dxService';
import { Storage } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

const imageExtensions: any = ['.apng', '.avif', '.gif', '.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.svg', '.webp', '.bmp', '.ico', '.cur'];

const Globaldocview = () => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const [filedocument, setDocument] = useState('');
    const [fileExt, setFileExt] = useState('');
    const id: any = urlParams.has('id') ? urlParams.get('id')?.toString() : '';
    const vid: any = urlParams.has('vid') ? urlParams.get('vid')?.toString() : '';
    const appid: any = urlParams.has('appid') ? urlParams.get('appid')?.toString() : '';
    const type: any = urlParams.has('type') ? urlParams.get('type')?.toString() : '';
    const parent: any = urlParams.has('parent') ? urlParams.get('parent')?.toString() : '';
    useEffect(() => {
        getDocument();
    }, [id]);
    const getDocument = async () => {
        await dxService.getitembyid('document', id).then((res) => {
            setFileExt('.' + res.name.split('?')[0].split('.').pop());

            getFileLocation(res);
        });
    };

    const getFileLocation = async (e: any) => {
        let url = await Storage.get(e.name);

        if (url) {
            setDocument(url);
        }
    };
    let link =
        parent === 'appointment'
            ? '/manage/appointments/item/documents'
            : parent === 'renewals'
            ? '/manage/renewals/item/viewdocuments'
            : parent === 'driver'
            ? '/manage/drivers/item/pictures'
            : parent === 'vehicle'
            ? '/manage/vehicles/item/pictures'
            : '';

    return (
        <div className="container">
            <h2>Document Preview</h2>

            <Link to={`${link}?id=${appid}&mode=view&vid=${vid}&type=${type}`}>
                <Button className="mb-3">Go Back</Button>
            </Link>
            {/* {['.pdf'].includes(document) ? <iframe title="Document Preview" src={document} width="100%" height="600px" /> : <img src={document} alt="Document Preview" />} */}
            {!imageExtensions.includes(fileExt) ? <iframe title="Document Preview" src={`${filedocument}`} width="100%" height="600px" /> : <img src={filedocument} alt="Image Preview" width="100%" title="Preview" />}
        </div>
    );
};

export default Globaldocview;
