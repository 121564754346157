import { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { Amplify, API, Storage } from 'aws-amplify';
import awsconfig from '../aws-exports.js';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { Message } from 'primereact/message';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import FormTemplate from './FormTemplate';

let licenseUrl: any;
const RenewRepeatingTemplate = (props: any) => {
    const [data, setData] = useState<any>({});
    const [forms, setsforms] = useState<any>([]);
    const [messsage, setMesssage] = useState<any>('');

    useEffect(() => {
        props.onload();
        // let a = {
        //     name:'s',
        //     title:'s',
        //     type:'form',
        //     columns:
        // };
        // setsforms([a]);
    }, []);
    useEffect(() => {
        props.onChange(data);
    }, [data]);
    const handlesubChange = async (event: any, type: any, fieldName: any) => {
        // if (event.type === "form" )
        //     setData({
        //         ...data,
        //         [type === 'fileupload' || type == 'form' ? fieldName : type === 'number' ? event.originalEvent.target.name : event.target.name]:
        //             type == 'form' ? event : type === 'fileupload' ? event.files[0].objectURL : type === 'number' ? event.originalEvent.target.value : type === 'multiselect' ? event.target.value : event.target.value
        //     });

        setData({
            ...data,
            [fieldName]: event
        });

        props.onChange(event, type, fieldName);
    };

    const subsubmitdata = async (newprops: any) => {
        console.log(props.updatedata);
        console.log(newprops);
        console.log('test');
        // let currentitem = props.items.filter((p:any)=> p.name === itemname);
        // subvalidateinputs(currentitem);
    };
    const subvalidateinputs = (currentitem: any) => {
        console.log(props);
        console.log(props.columns);
        setMesssage('');
        let collecteddata = data;
        let a = props.columns.filter((p: any) => p.fieldtype != 'header');
        let b = props.columns.filter((p: any) => p.fieldtype != 'header' && p.required);
        let c = Object.keys(collecteddata);
        let d = c.filter((v: any) => {
            return collecteddata[v];
        });

        let e = b.every((v: any) => {
            return d.includes(v.name);
        });

        if (!e) setMesssage('* Please fill all required fields');

        props.submitForm(collecteddata);
    };
    return (
        <div>
            {props.items.map((ele: any, index: any) => {
                function generatecolumns(arg0: any, columns: any) {
                    //    columns.columns[0].name = arg0;
                    //    columns.columns[0].value = arg0;
                    return columns;
                }

                return (
                    <React.Fragment key={ele.name}>
                        <FormTemplate
                            ChangeBtn={'OpenDocument'}
                            layout="grid2"
                            name={ele.name}
                            title={ele.title}
                            columns={ele.columns}
                            showaction={props.showaction}
                            mode={props.mode}
                            data={props.updatedata}
                            onChange={(e: any) => handlesubChange(e, ele.fieldtype, ele.name)}
                            submitForm={subsubmitdata}
                        />
                    </React.Fragment>
                );
            })}
        </div>
    );
};
export default RenewRepeatingTemplate;
