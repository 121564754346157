import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { useContext, useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { RTLContext } from "../../Placeholder/PageCotrol";
import "../Reports.css";
import { useLocation, useNavigate } from "react-router-dom";
import dxService from "../../../Services/dxService";
import { getNextQueryData, localetoisodatetime } from "../../../utils/helper";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Card } from "@aws-amplify/ui-react";
import VehicleConsumption from "./VehicleConsumption";
import VehicleRenewals from "./VehicleRenewals";
import { Storage } from "aws-amplify";
import { OverlayPanel } from "primereact/overlaypanel";
import { getTypeColors } from "../../../utils/color";
const color = getTypeColors("dark");
var today = new Date();
var year = today.getFullYear();
var month = today.getMonth();
let storevalueInc: any;
let storekeyInc: any;
let StoreAccident: any;
let StoreFine: any;
var IncidentFineArrayCount: any = [];
var IncidentAccidentArrayCount: any = [];
// Create a new date object for the first day of the current month
var firstDayOfCurrentMonth = new Date(year, month, 1);

// Subtract 1 day from the first day of the current month
var lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth.getTime() - 1);

var targetDate = new Date(today.getTime());
targetDate.setMonth(targetDate.getMonth() - 4);

let lastenddate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
let lastfirstdate = new Date(today.getFullYear(), today.getMonth() - 4, 1);

const getaccidentLineData = (colorMode: string) => {
  return {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Accidents",
        data: IncidentAccidentArrayCount,
        borderColor: color.Accidents,
        backgroundColor: color.Accidents,
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };
};
const getFineLineData = (colorMode: string) => {
  return {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Fine",
        data: IncidentFineArrayCount,
        borderColor: color.Fine,
        backgroundColor: color.Fine,
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };
};

const getOrdersOptions = () => {
  const textColor =
    getComputedStyle(document.body).getPropertyValue("--text-color") ||
    "rgba(0, 0, 0, 0.87)";
  const gridLinesColor =
    getComputedStyle(document.body).getPropertyValue("--divider-color") ||
    "rgba(160, 167, 181, .3)";
  const fontFamily = getComputedStyle(document.body).getPropertyValue(
    "--font-family"
  );
  return {
    plugins: {
      legend: {
        display: true,
        labels: {
          fontFamily,
          color: textColor,
        },
      },
      datalabels: {
        display: true,
        color: "white",
        // align: 'end', // Adjusted alignment to start
        // anchor: 'end', // Adjusted anchor to end
        // offset: -20,

        font: {
          size: "12",
          weight: "700",
        },
      },
    },
    scales: {
      y: {
        ticks: {
          fontFamily,
          color: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
      x: {
        ticks: {
          fontFamily,
          color: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
    },
  };
};

let ordersOptions = getOrdersOptions();
const VehicleDetailsPage = (props: any) => {
  const bar = useRef<any>(null);
  const isRTL = useContext(RTLContext);
  const location1 = useLocation();
  const [VehicleData, setVehicleData] = useState<any>([]);
  const [FineCount, setFineCount] = useState<any>([]);
  const [AccidentCount, setAccidentCount] = useState<any>([]);
  const [AccidentlineData, setAccidentlineData] = useState<any>(
    getaccidentLineData(props.colorMode)
  );
  const [FinelineData, setFinetlineData] = useState<any>(
    getFineLineData(props.colorMode)
  );
  const urlParams1 = new URLSearchParams(location1.search);
  var formid = urlParams1.has("id") ? urlParams1.get("id") : null;
  const navigate = useNavigate();
  const accidentRef = useRef<any>(null);
  const FineRef = useRef<any>(null);
  useEffect(() => {
    IncidentFineArrayCount = [];
    IncidentAccidentArrayCount = [];

    const fetchData = async () => {
      try {
        const fine = await getFineLogsData();
        const accident = await getAccidentLogsData();

        const res = await getIncidentsitems(fine, accident);
        ordersOptions = getOrdersOptions();
        setAccidentlineData(getaccidentLineData(props.colorMode));
        setFinetlineData(getFineLineData(props.colorMode));
      } catch (error) {
        // Handle any errors that occurred during data fetching
      }
    };

    fetchData();
  }, [props.colorMode]);
  useEffect(() => {
    const fetchVehdetails = async () => {
      try {
        const filter = {
          id: { eq: formid },
        };

        const suc: any = await initFilters();
        const veh: any = await getNextQueryData(
          "",
          filter,
          "vehicleactivity",
          true
        );

        for (const item of veh) {
          if (item.vehicle.ImagePath !== null) {
            const vehicleimage = await Storage.get(item.vehicle.ImagePath);
            item["vehiclelogo"] = vehicleimage;
          } else {
            item["vehiclelogo"] = "";
          }

          const makeimg = suc.filter((make: any) =>
            make.key.toLowerCase().includes(item.vehicle.make.toLowerCase())
          );

          for (const make of makeimg) {
            const S3Img = await fetchImgUrl(make.key);
            item["makelogo"] = S3Img;
            setVehicleData((oldArray: any) => [...oldArray, item]);
          }

          if (makeimg.length === 0) {
            setVehicleData((oldArray: any) => [...oldArray, item]);
          }
        }
      } catch (error) {
        // Handle errors here
        console.error(error);
      }
    };

    fetchVehdetails();
  }, []);

  const fetchImgUrl = async (make: any) => {
    // let url = await Storage.get(`images/logo/${make.toLowerCase()}.jpeg`);
    let url = await Storage.get(`${make}`);
    return url;
  };
  const initFilters = () => {
    let s3ButketImages = Storage.list("images/logo") // for listing ALL files without prefix, pass '' instead
      .then(({ results }) => {
        return results;
      })
      .catch((err) => console.error(err));
    return s3ButketImages;
  };
  //fetch the item which contains fine from db
  const getFineLogsData = async (nextToken?: any) => {
    let variables: any;
    let response: any[] = [];
    const ac = `Fine date _deleted`;
    do {
      variables = {
        and: [
          { vehicleid: { eq: formid } },
          { Fine: { attributeExists: true } },
          { date: { ge: localetoisodatetime(new Date(lastfirstdate)) } },
          {
            date: {
              le:
                moment(localetoisodatetime(lastenddate)).format("YYYY-MM-DD") +
                "T23:59:59.999Z",
            },
          },
        ],
      };

      const Finedata = await dxService.listitems(
        "masterlog",
        ac,
        variables,
        true,
        1000,
        nextToken
      );

      response.push(...Finedata.items);
      nextToken = Finedata.nextToken;
    } while (nextToken);

    response = response.filter((item: any) => item._deleted !== true);
    return response;
  };

  //fetch the item which contains accidents from db
  const getAccidentLogsData = async (nextToken?: any) => {
    const ac = `Accidents date _deleted`;
    let variables: any = {
      and: [
        { vehicleid: { eq: formid } },
        { Accidents: { attributeExists: true } },
        { date: { ge: localetoisodatetime(new Date(lastfirstdate)) } },
        {
          date: {
            le:
              moment(localetoisodatetime(lastenddate)).format("YYYY-MM-DD") +
              "T23:59:59.999Z",
          },
        },
      ],
    };
    let Accidentdata = await dxService.listitems(
      "masterlog",
      ac,
      variables,
      true,
      1000,
      nextToken
    );
    let response = Accidentdata.items;

    let nexttoken = Accidentdata.nextToken;

    if (nexttoken) {
      response = [...response, ...(await getAccidentLogsData(nexttoken))];

      // response = response.concat(nextResults);
    }
    response = response.filter((item: any) => item._deleted !== true);
    return response;
  };
  const getIncidentsitems = async (Finearray: any, Accidentarray: any) => {
    IncidentFineArrayCount = [];
    IncidentAccidentArrayCount = [];
    const last6Months: any = [];
    for (let i = 4; i >= 0; i--) {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      const monthYearString = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
      });

      const [month, year] = monthYearString.split(" ");
      last6Months.push(`${month} ${year}`);
    }

    last6Months.sort((a: any, b: any) => a - b);
    // group AccidentArray
    let months = [
      "January",
      "February",
      "March",
      "April",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let currentMonth: any = new Date().getMonth();
    let last6Month = months
      .slice(currentMonth - 6)
      .concat(months.slice(0, currentMonth));

    var accident = Accidentarray.filter((acc: any) => acc.Accidents !== null);
    accident.map((custom: any) => {
      custom["CustomMonth"] = moment(custom.date).format("MMMM YYYY");
    });
    setAccidentCount(accident);

    var Fines = Finearray.filter(
      (acc: any) => acc !== undefined && acc.Fine !== null && acc.Fine !== ""
    );
    Fines.map((custom: any) => {
      custom["CustomMonth"] = moment(custom.date).format("MMMM YYYY");
    });
    setFineCount(Fines);

    var groupByAccident = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupByAccidentMonthvalue: any = groupByAccident(
      accident,
      "CustomMonth"
    );
    let reformatedata: any = [];
    let finaldata: any = [];

    for (const [key, value] of Object.entries(GroupByAccidentMonthvalue)) {
      storevalueInc = value;
      storekeyInc = key;

      last6Months.map((ite: any) => {
        if (storekeyInc.includes(ite)) {
          reformatedata.push({ value: storevalueInc.length, key: storekeyInc });
        }
      });
    }

    reformatedata.map((mon: any) => {
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });

    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");

    let accidentarr: any = 0;
    for (const [key, value] of Object.entries(groupByMontharray)) {
      StoreAccident = value;
      var sum = 0;
      for (var i = 0; i < StoreAccident.length; i++) {
        sum += StoreAccident[i].value;
      }
      IncidentAccidentArrayCount.push(sum);
    }

    //group Fines Array

    var groupByFine = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByFineMonthvalue: any = groupByFine(Fines, "CustomMonth");

    let reformateFinedata: any = [];
    let finalFinedata: any = [];
    for (const [key, value] of Object.entries(groupByFineMonthvalue)) {
      storevalueInc = value;
      storekeyInc = key;

      last6Months.map((ite: any) => {
        if (storekeyInc.includes(ite)) {
          reformateFinedata.push({
            value: storevalueInc.length,
            key: storekeyInc,
          });
        }
      });
    }

    reformateFinedata.map((mon: any) => {
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finalFinedata.push({ value: mon.value, key: mon.key });
        } else {
          finalFinedata.push({ value: 0, key: lastsixmon });
        }
      });
    });

    var groupByFineMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByFineMontharray: any = groupByFineMonth(finalFinedata, "key");
    for (const [key, value] of Object.entries(groupByFineMontharray)) {
      StoreFine = value;
      var Finesum = 0;
      for (var i = 0; i < StoreFine.length; i++) {
        Finesum += StoreFine[i].value;
      }

      IncidentFineArrayCount.push(Finesum);
    }
  };
  console.log(VehicleData[0]);

  return (
    <>
      <h3 className="container-header">
        Dashboard: {VehicleData.length > 0 && VehicleData[0].id}{" "}
      </h3>
      <div className="">
        <div className="col-12 md:col-12 grid">
          <div
            className="col-12 md:col-6 pb-10 vehicleBox"
            // style={{ width: '625px', marginRight: '13px' }}
          >
            <div
              className="flex align-items-start  card flex-column lg:flex-row lg:justify-content-between"
              style={{ height: "100%" }}
            >
              <div className="flex align-items-start flex-column md:flex-row">
                <img
                  src={VehicleData.length > 0 && VehicleData[0].vehiclelogo}
                  className="mr-5 mb-3 lg:mb-0"
                  style={{ height: " 90px" }}
                />
                <div>
                  <div>
                    <span className="block text-900 font-medium text-3xl mb-1">
                      {VehicleData.length > 0 && VehicleData[0].id}
                    </span>
                    <div className="text-600 font-medium text-xl">
                      {VehicleData.length > 0
                        ? VehicleData[0].vehicle.make +
                          " " +
                          VehicleData[0].vehicle.model
                        : "-"}
                    </div>
                  </div>

                  <div className="flex align-items-center flex-wrap text-sm">
                    <div className="mr-5 mt-3">
                      <span
                        className="font-medium"
                        style={{ color: "#212121" }}
                      >
                        DEPARTMENT
                      </span>
                      <div className="text-700 mt-2">
                        {VehicleData.length > 0 &&
                        VehicleData[0].vehicle.department !== null
                          ? VehicleData[0].vehicle.department.name
                          : "-"}
                      </div>
                    </div>
                    <div className="mr-5 mt-3">
                      <span
                        className="font-medium"
                        style={{ color: "#212121" }}
                      >
                        DRIVER
                      </span>
                      <div className="text-700 mt-2">
                        {VehicleData.length > 0 &&
                        VehicleData[0].driver !== null
                          ? VehicleData[0].driver.name
                          : "-"}
                      </div>
                    </div>
                    <div className="mr-5 mt-3">
                      <span
                        className="font-medium"
                        style={{ color: "#212121" }}
                      >
                        CAPACITY
                      </span>
                      <div className="text-700 mt-2">
                        {VehicleData.length > 0 &&
                        VehicleData[0].vehicle.capacity !== null
                          ? VehicleData[0].vehicle.capacity
                          : "-"}
                      </div>
                    </div>
                    <div className="mr-5 mt-3">
                      <span
                        className="font-medium"
                        style={{ color: "#212121" }}
                      >
                        EXPIRY DATE
                      </span>
                      <div className="text-700 mt-2">
                        {VehicleData.length > 0 &&
                        VehicleData[0].vehicle.enddate !== null
                          ? moment(VehicleData[0].vehicle.enddate).format(
                              "MMM DD YYYY"
                            )
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 lg:mt-0">
                <button
                  className="p-button p-component p-button-rounded mr-2 p-button-icon-only"
                  style={{ background: "#2196f3" }}
                  onClick={() =>
                    navigate(
                      `/manage/vehicles/item/basic?mode=edit&id=${
                        VehicleData.length > 0 && VehicleData[0].id
                      }`
                    )
                  }
                >
                  <span className="p-button-icon p-c pi pi-pencil"></span>
                  <span className="p-button-label p-c">&nbsp;</span>
                  <span
                    role="presentation"
                    className="p-ink"
                    style={{ height: "48px", width: "48px" }}
                  ></span>
                </button>

                <button
                  className="p-button p-component p-button-rounded p-button-success p-button-icon-only"
                  onClick={() =>
                    navigate(
                      `/manage/vehicles/item/basic?mode=view&id=${
                        VehicleData.length > 0 && VehicleData[0].id
                      }`
                    )
                  }
                >
                  <span className="p-button-icon p-c pi pi-eye"></span>
                  <span className="p-button-label p-c">&nbsp;</span>
                  <span
                    role="presentation"
                    className="p-ink"
                    style={{ height: "48px", width: "48px" }}
                  ></span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 overview-box flex flex-column pt-2 vehicleBox">
            <div className="card" style={{ height: "100%" }}>
              <div className="">
                <div className="grid">
                  <div className="col-12 md:col-6">
                    <div
                      className="text-center border-1 surface-border border-round p-4"
                      style={{ height: "114px" }}
                    >
                      <Button
                        className="overview-status p-2  fs-large"
                        title="Total Accidents"
                        style={{
                          display: "flex",
                          width: "100%",
                          placeContent: "center",
                          placeItems: "center",
                          backgroundColor: "#2196f3",
                        }}
                        onClick={(e) => {
                          // handleClick('Accident', e, rowData);
                          accidentRef.current.toggle(e);
                        }}
                      >
                        Accidents
                      </Button>
                      <div className="text-900 text-2xl font-700 my-3 font-bold">
                        {AccidentCount ? AccidentCount.length.toString() : 0}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6 text-center">
                    <div
                      className="text-center border-1 surface-border border-round p-4"
                      style={{ height: "114px" }}
                    >
                      <Button
                        onClick={(e) => {
                          // handleClick('Accident', e, rowData);
                          FineRef.current.toggle(e);
                        }}
                        className="overview-status p-2  fs-large"
                        title="Total Accidents"
                        style={{
                          display: "flex",
                          width: "100%",
                          placeContent: "center",
                          placeItems: "center",
                          backgroundColor: "#2196f3",
                        }}
                      >
                        Fines
                      </Button>
                      <div className="text-900 text-2xl font-700 my-3 font-bold">
                        {FineCount ? FineCount.length.toString() : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OverlayPanel
            ref={accidentRef}
            showCloseIcon
            id="overlay_panel"
            style={{ width: "450px" }}
          >
            <h5>
              Accidents details
              {/* {lastdaysfilterkey ? '('+lastdaysfilterkey +' Days)':''}:  {logVid}  */}
            </h5>
            {/* <div className="col-12 md:col-6"> */}
            {/* <div className="card"> */}
            {/* <div className="card-header">
                        <h5>Order Graph</h5>
                    </div> */}
            <Chart
              className="Ordergraph"
              type="bar"
              data={AccidentlineData}
              plugins={[ChartDataLabels]}
              options={ordersOptions}
            ></Chart>
            {/* </div> */}
            {/* </div> */}
          </OverlayPanel>
          <OverlayPanel
            ref={FineRef}
            showCloseIcon
            id="overlay_panel"
            style={{ width: "450px" }}
          >
            <h5>
              Fines details
              {/* {lastdaysfilterkey ? '('+lastdaysfilterkey +' Days)':''}:  {logVid}  */}
            </h5>

            <Chart
              className="Ordergraph"
              type="bar"
              data={FinelineData}
              plugins={[ChartDataLabels]}
              options={ordersOptions}
            ></Chart>
          </OverlayPanel>
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        <VehicleRenewals formid={formid} />
        <VehicleConsumption props={props} formid={formid} />
      </div>
    </>
  );
};
export default VehicleDetailsPage;
