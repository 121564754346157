import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dxService from '../../../../Services/dxService';
import ListTemplate from '../../../../Components/ListTemplate';

function DriverDashboardExpense(props: any) {
    const [displayDocuments, setDisplayDocuments] = useState<any>([]);

    //#region OnLoad methods
    // Calls method during the component load
    useEffect(() => {
        // sparesID !== '' && getDisplayDetails();
        props.driverid && getDocumentDetails();
    }, [props]);

    const getDocumentDetails = async (nextToken?: any) => {
        let filter = {
            driverid: {
                eq: props.driverid
            }
        };

        let nextTokenValue = nextToken;
        let newItems;
        let AllItems = [];

        do {
            let GetResponse = await dxService.listitems('vehiclespare', '*', filter, true, 100, nextTokenValue);
            newItems = GetResponse.items.filter((item: any) => item._deleted !== true);
            AllItems.push(...newItems);

            // Check for duplicates before appending new items
            const filteredNewItems = newItems.filter((newItem: any) => {
                return !displayDocuments.some((document: any) => document.id === newItem.id);
            });

            setDisplayDocuments((prevState: any) => {
                const updatedDocuments = [...prevState, ...filteredNewItems];
                // Remove duplicates from the updated documents
                const uniqueDocuments = Array.from(new Set(updatedDocuments.map((document: any) => document.id))).map((id: any) => {
                    return updatedDocuments.find((document: any) => document.id === id);
                });
                return uniqueDocuments;
            });

            nextTokenValue = GetResponse.nextToken;
        } while (nextTokenValue);
        setDisplayDocuments(AllItems);
    };

    const AttachmentBodyTemplate = (e: any) => {
        if (typeof e.partname === 'string') {
            return (
                <div>
                    <Link to={`/manage/spares/item/basic?mode=edit&id=${e.id}&parent=appointment`} className="font-bold">
                        {JSON.parse(e.partname)}
                    </Link>
                    <br />
                    Quantity: {e.quantity}
                </div>
            );
        } else if (typeof e.partname === 'object') {
            let partnames = JSON.parse(e.partname);
            const options = partnames.map((partName: any, index: number) => {
                return {
                    label: partName,
                    value: partName
                };
            });
            return <Dropdown options={options} value={partnames[0] || options[0].value} />;
        }
    };
    const taxTemplate = (e: any) => {
        return <>{e.jfield ? JSON.parse(e.jfield).tax : 0}%</>;
    };

    const columns = [
        {
            field: 'partname',
            showfield: 'partname',
            headeralign: 'left',
            bodyalign: 'left',
            header: 'Part Names',
            fieldtype: 'custom',
            filterOption: true,
            filterFieldOption: 'partname',
            sortable: true,
            sortField: 'partname',
            template: (r: any) => <>{AttachmentBodyTemplate(r)}</>
        },
        {
            field: 'amount',
            header: 'Unit Price (AED)',
            headeralign: 'right',
            bodyalign: 'right',
            fieldtype: 'text',
            filterOption: false,
            sortable: true,
            sortField: 'amount'
        },
        //{ field: 'mobileno', header: 'Mobile No', fieldtype: 'text', filterOption: true, filterFieldOption: 'mobileno' },
        { field: 'tax', header: 'Tax', headeralign: 'right', bodyalign: 'right', fieldtype: 'custom', filterOption: true, filterFieldOption: 'tax', template: (r: any) => <>{taxTemplate(r)}</> },
        // // {field: 'imageData', header: 'Image', fieldtype:'image'},
        { field: 'totalamount', headeralign: 'right', bodyalign: 'right', header: 'Amount (AED)', fieldtype: 'text', sortable: true, sortField: 'totalamount' }
    ];
    // const totalAmount = displayDocuments.reduce((total, item: any) => total + parseInt(item.totalamount), 0);
    let totalTax = 0;

    displayDocuments.forEach((item: any) => {
        const taxRate = item.jfield ? JSON.parse(item.jfield).tax : 0; // Extract tax rate from jfield property
        const amount = item.totalamount !== 'NaN' && item.totalamount !== '' ? Number(item.totalamount) : 0; // Extract amount
        const tax: any = amount * (taxRate / 100); // Calculate tax as a percentage of amount
        totalTax += tax; // Add tax to total tax
    });
    const totalAmount = displayDocuments.reduce((acc: any, item: any) => acc + (item.totalamount !== 'NaN' && item.totalamount !== '' ? Number(item.totalamount) : 0), 0); // Sum up the total amount
    const totalAmountWithTax = totalAmount + totalTax;
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <div className="card">
                    <ListTemplate
                        header="Manage Spares"
                        value={displayDocuments}
                        paginator={false}
                        rows={100}
                        dynamicColumns={columns}
                        emptyMessage="No items found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        // rowsPerPageOptions={[5, 10, 25]}
                        //   selection={selectedProducts}
                        multiselect={false}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}Wd
                        //inifinite scroll
                        scrollable={true}
                        scrollHeight="60vh"
                    />

                    <div className="p-dataview p-component p-dataview-list" style={{ textAlign: 'right' }}>
                        <h6 className="p-dataview-content pt-2 pb-1 mb-1">{totalAmount > 0 && 'Sub total:' + ' AED ' + totalAmount.toFixed(2)}</h6>
                        <h6 className="p-dataview-content pt-0 pb-1 mb-1 mt-0">{totalTax > 0 && 'Tax amount:' + ' AED ' + totalTax.toFixed(2)}</h6>
                        <h6 className="p-dataview-content pt-0 m-0">{totalAmountWithTax > 0 && 'Total amount:' + ' AED ' + totalAmountWithTax.toFixed(2)}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DriverDashboardExpense;
