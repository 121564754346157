import { Button } from '@aws-amplify/ui-react';
import React from 'react';
import { Link } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import { Groups } from './GroupNames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

function Roles() {
    // let header = (
    //     <div className="table-header-container">
    //         {Groups.map((group) => {
    //             return (
    //                 <Button className="mr-2">
    //                     <Link to={`/Roles/${group.name}`}>{group.value}</Link>
    //                 </Button>
    //             );
    //         })}
    //     </div>
    // );
    let headerGroup = (
        <ColumnGroup>
            <Row>
                {Groups.map((group) => {
                    return <Column header={group.value} />;
                })}
            </Row>
        </ColumnGroup>
    );
    return (
        <>
            <h1>GROUPS</h1>
            {/* {Groups.map((group) => {
                return (
                    <Button className="mr-2">
                        <Link to={`/Roles/${group.name}`}>{group.value}</Link>
                    </Button>
                );
            })} */}
            <DataTable value={Groups} className="p-datatable-gridlines" showGridlines dataKey="id" responsiveLayout="scroll" headerColumnGroup={headerGroup}></DataTable>
            {/* &nbsp;
            <Button>
                <Link to="/Roles/FleetMembers">Fleet Members</Link>
            </Button> */}
        </>
    );
}

export default Roles;
