import React, { useEffect, useState } from "react";

import moment from "moment";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ListTable from "../../Components/ListTable";
import { getNextQueryData } from "../../utils/helper";

let Shiftitems: any = [];
const NewMileageReport = () => {
  const [data, setdata] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  useEffect(() => {
    getAllMileagedata();
  }, []);
  const getAllMileagedata = async () => {
    let variables: any = {};
    await getNextQueryData("", variables, "logmileage", true).then(
      (md: any) => {
        console.log(md);
        setdata(md);
      }
    );
  };
  const Mileage = (e: any) => {
    return <>{parseFloat(e.totaldistance).toFixed(2)}</>;
  };
  function convertTo12HourFormat(timeString: any) {
    const date = new Date(`1970-01-01T${timeString}`);
    // console.log(date);

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes.toString().padStart(2, "0")} ${amPm}`;
  }

  //   const startTime = "12:00:00.000Z";
  //   const endTime = "21:00:00.000Z";

  //   const convertedStartTime = convertTo12HourFormat(startTime);
  //   const convertedEndTime = convertTo12HourFormat(endTime);

  //   console.log(convertedStartTime); // Output: "12:00 PM"
  //   console.log(convertedEndTime); // Output: "9:00 PM"
  const MileageShift = (e: any) => {
    let actualstart: any;
    let actualend: any;
    if (e.drivershift.shift !== null && e.drivershift.shift.starttime == null) {
      actualstart = e.drivershift.shift.id;
    } else {
      const convertedStartTime = convertTo12HourFormat(
        e.drivershift.shift.starttime
      );
      const convertedEndTime = convertTo12HourFormat(
        e.drivershift.shift.endtime
      );
      //   console.log(e.drivershift.shift.starttime, e.drivershift.shift.endtime);

      actualstart = convertedStartTime;
      actualend = convertedEndTime;
    }
    //
    return (
      <>
        {actualstart && actualend
          ? `${actualstart} to ${actualend}`
          : actualstart}
      </>
    );
  };

  const CustomDate = (e: any) => {
    return <>{moment(e.startdate).format("DD-MMM-YYYY")}</>;
  };
  function convertIntoMinutes(e: any) {
    const duration = e;

    const [hours, minutes, seconds] = duration.split(":").map(Number);

    const totaldurationMinutes = hours * 60 + minutes + seconds / 60;
    return totaldurationMinutes;
  }

  const InShiftKms = (e: any) => {
    // console.log(e);

    const totaldurationMinutes = convertIntoMinutes(e.duration);
    const distance = e.distance;
    const numericValue = parseFloat(distance);
    const Kmstravelledinmins = numericValue / totaldurationMinutes.toFixed(2);
    const totalinshiftMinutes =
      e.inshifttime !== null ? convertIntoMinutes(e.inshifttime) : "";
    const inshiftKmstravelled = Kmstravelledinmins * totalinshiftMinutes;
    return <>{inshiftKmstravelled.toFixed(2)}</>;
  };
  const OutShiftKms = (e: any) => {
    const totaldurationMinutes = convertIntoMinutes(e.duration);
    const distance = e.distance;
    const numericValue = parseFloat(distance);
    const Kmstravelledinmins = numericValue / totaldurationMinutes.toFixed(2);
    const totalOutshiftMinutes =
      e.outshifttime !== null ? convertIntoMinutes(e.outshifttime) : "";
    const OutshiftKmstravelled = Kmstravelledinmins * totalOutshiftMinutes;
    return <>{OutshiftKmstravelled.toFixed(2)}</>;
  };
  const columns = [
    {
      field: "startdate",
      header: "Date",
      textalign: "left",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "date",
      width: "100px",
      template: (r: any) => <>{CustomDate(r)}</>,
    },
    // // {field: 'imageData', header: 'Image', fieldtype:'image'},
    {
      field: "drivername",
      header: "Driver",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "drivername",
      width: "120px",
    },
    {
      field: "startdatetime",
      header: "Start",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "startdatetime",
      width: "100px",
    },
    {
      field: "enddatetime",
      header: "Stop",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "enddatetime",
      width: "100px",
    },
    {
      field: "duration",
      header: "Total Duration",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "duration",
      width: "130px",
    },
    {
      field: "distance",
      header: "Total Mileage",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "distance",
      width: "130px",
    },
    {
      field: "totalshifttime",
      header: "Shift",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "totalshifttime",
      width: "90px",
    },
    {
      field: "MileageafterWH",
      header: "Actual Shift",
      textalign: "left",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "endkm",
      width: "150px",
      template: (r: any) => <>{MileageShift(r)}</>,
    },
    {
      field: "inshifttime",
      header: "In shift",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "endkm",
      width: "100px",
      //   template: (r: any) => <>{Mileage(r)}</>,
    },

    {
      field: "outshifttime",
      header: "Out Shift",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "endkm",
      width: "100px",
      //   template: (r: any) => <>{MileageShift(r)}</>,
    },
    {
      field: "outshifttime",
      header: "InShift Kms",
      textalign: "left",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "endkm",
      width: "130px",
      template: (r: any) => <>{InShiftKms(r)}</>,
    },
    {
      field: "outshifttime",
      header: "OutShift kms",
      textalign: "left",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "endkm",
      width: "130px",
      template: (r: any) => <>{OutShiftKms(r)}</>,
    },
  ];
  return (
    <div>
      {" "}
      <div className="card">
        <ListTable
          header="Mileage Report"
          value={data}
          paginator={true}
          rows={100}
          dynamicColumns={columns}
          emptyMessage="No Vendors found."
          responsiveLayout="scroll"
          className="datatable-responsive"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vendors"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[100, 200, 300]}
          //   selection={selectedProducts}
          //   multiselect={true}
          //   loading={loading}
          selectedItems={setSelectedItems}
        />
        {/* <DataTable value={data} tableStyle={{ minWidth: "50rem" }}>
          <Column field="code" header="Code"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="category" header="Category"></Column>
          <Column field="quantity" header="Quantity"></Column>
        </DataTable> */}
      </div>
    </div>
  );
};

export default NewMileageReport;
