import { useState, useEffect, useRef } from "react";
import axios from "axios";
const MileageAPI = (props: any) => {
  useEffect(() => {
    axios({
      method: "get",
      url: "https://howling-crypt-47129.herokuapp.com/https://tracking.mygps.ae/ax/reports.php?download=0&veh_id=12410%2C12071%2C16642&zones_id=&lines_id=&stopping_points_id=&drivers_id=&groups_id=&holidays=&lang=en&explode=1&start_month=09.2023&pre_start_date=13.10.2023&pre_stop_date=20.10.2023&start_date=01.09.2023&start_time=00%3A00&stop_month=11.2023&stop_date=30.09.2023&stop_time=00%3A00&group=1&tags%5B%5D=&level%5B%5D=&event_group=&event_groups%5B%5D=&notification%5B%5D=&map_type=1&trailer=&last_ibutton_used=0&report_type=4&max_speed=30&vehicle_work_area_width=1&emp_name=&reason_for_opening=&contr_time=120&contr_dist=0&contr_time_max=0&inspections_report_type=0&set_months_range=1&type=1&template=1",
      //   data: bodyFormData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        origin: "x-requested-with",
        "Access-Control-Allow-Origin": "*",
        Cookie: "PILOTID=83d32d94219a114012283fe230163f18; node=3",
      },
    }).then((accessToken: any) => {
      console.log(accessToken);

      return accessToken.data.access_token;
      //this.fetchdb(this.token);
    });
  }, []);
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await axios.get(
  //           "https://tracking.mygps.ae/ax/reports.php",
  //           {
  //             params: {
  //               download: 0,
  //               veh_id: "12410,12071,16642",
  //               zones_id: "",
  //               lines_id: "",
  //               stopping_points_id: "",
  //               drivers_id: "",
  //               groups_id: "",
  //               holidays: "",
  //               lang: "en",
  //               explode: 1,
  //               start_month: "09.2023",
  //               pre_start_date: "13.10.2023",
  //               pre_stop_date: "20.10.2023",
  //               start_date: "01.09.2023",
  //               start_time: "00:00",
  //               stop_month: "11.2023",
  //               stop_date: "30.09.2023",
  //               stop_time: "00:00",
  //               group: 1,
  //               tags: [],
  //               level: [],
  //               event_group: "",
  //               event_groups: [],
  //               notification: [],
  //               map_type: 1,
  //               trailer: "",
  //               last_ibutton_used: 0,
  //               report_type: 4,
  //               max_speed: 30,
  //               vehicle_work_area_width: 1,
  //               emp_name: "",
  //               reason_for_opening: "",
  //               contr_time: 120,
  //               contr_dist: 0,
  //               contr_time_max: 0,
  //               inspections_report_type: 0,
  //               set_months_range: 1,
  //               type: 1,
  //               template: 1,
  //             },
  //           }
  //         );

  //         // Handle the response data here
  //         console.log(response.data);
  //       } catch (error) {
  //         // Handle errors here
  //         console.error("Error fetching data:", error);
  //       }
  //     };

  //     fetchData();
  //   }, []);

  return (
    <div className="grid">
      <div className="col-12 md:col-12">
        <h4 className="container-header">Pilot GPS Integration</h4>
      </div>
    </div>
  );
};
export default MileageAPI;
