import React, { useEffect, useState } from "react";

import moment from "moment";
import ListTemplate from "../../../Components/ListTemplate";
import {
  getNextQueryData,
  convertdate,
  localetoisodatetime,
} from "../../../utils/helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ListTable from "../../../Components/ListTable";
import { Calendar } from "primereact/calendar";
import "../Reports.css";
import { CSVLink } from "react-csv";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Dropdown } from "primereact/dropdown";

import { Chart } from "primereact/chart";
import { API } from "aws-amplify";
import { listDxLogVipfuels, listDxlogMileages } from "../../../graphql/queries";
import { useLocation } from "react-router-dom";
import dxService from "../../../Services/dxService";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
let Shiftitems: any = [];
let driverchartnames: any = [];
let chartoutshiftkms: any = [];
let chartInshiftKms: any = [];
let sortedreformatteddate: any = [];
let storevalue: any;
let barChartOptions: object;
let storekey: any;
const getBarData = async (colorMode: string) => {
  //   const colors = await getTypeColor("dark");
  // console.log(driverchartnames, chartoutshiftkms, chartInshiftKms);

  return {
    labels: driverchartnames,
    datasets: [
      {
        label: "Outshifts",
        backgroundColor: ["#db000029"],
        borderColor: ["#b20000"],
        data: chartoutshiftkms,
        borderWidth: 1,
      },
      {
        label: "InShifts",
        backgroundColor: "#00d70d2b",
        borderColor: "#009d20",
        data: chartInshiftKms,
        borderWidth: 1,
      },
    ],
  };
};
const getBarChartOptions = () => {
  const textColor =
    getComputedStyle(document.body).getPropertyValue("--text-color") ||
    "rgba(0, 0, 0, 0.87)";
  const gridLinesColor =
    getComputedStyle(document.body).getPropertyValue("--divider-color") ||
    "rgba(160, 167, 181, .3)";
  const fontFamily = getComputedStyle(document.body).getPropertyValue(
    "--font-family"
  );

  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          fontFamily,
          color: textColor,
        },
      },
      datalabels: {
        display: true,
        color: "black",
        anchor: "end",
        clamp: true,
        align: "end",
        // offset: -20,

        font: {
          size: "12",
          weight: "700",
        },
      },
    },

    scales: {
      x: {
        ticks: {
          fontFamily,
          color: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
      y: {
        ticks: {
          fontFamily,
          color: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
    },
  };
};
const MileageReports = (props: any) => {
  const [data, setdata] = useState<any>([]);
  const [dprogress, setDownloadProgress] = useState<any>(false);
  const [globalFilter, setGlobalFilter] = useState<any>("");
  const [SelectedDate, setSelectedDate] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [fuelcost, setfuelcost] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [endDateFormat, setEndDateFormat] = useState<string>("");
  const [startDateFormat, setStartDateFormat] = useState<string>("");
  const [selectView, setSelectView] = useState<any>("Report");
  const [selectedVehicle, setSelectedVehicle] = useState<any>("");
  const [barData, setBarData] = useState<any>(getBarData(props.colorMode));
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  var sdate: any = urlParams.has("sdate") ? urlParams.get("sdate") : "";
  var edate: any = urlParams.has("edate") ? urlParams.get("edate") : "";
  var view: any = urlParams.has("view") ? urlParams.get("view") : "";
  var vid: any = urlParams.has("vid") ? urlParams.get("vid") : "";
  const updateCharts = () => {
    barChartOptions = getBarChartOptions();
  };
  useEffect(() => {
    // getAllMileagedata().then(async (rep: any) => {
    //   //   console.log(rep);
    //   // setBarData(await getBarData(props.colorMode));
    // });
    getMileagedata().then(async (data: any) => {
      console.log(data);
      let reformatedata: any = [];
      var groupByDriver = function (xs: any, key: any) {
        return xs.reduce(function (rv: any, x: any) {
          // console.log(rv, x);

          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      let GroupBydriverName: any = groupByDriver(data, "description");
      console.log(GroupBydriverName);
      for (const [key, value] of Object.entries(GroupBydriverName)) {
        storekey = key;
        storevalue = value;
        const totaloutshiftDistance = findKms(value, "outshifttime");
        const totalInshiftDistance = findKms(value, "inshifttime");

        reformatedata.push({
          driver: storekey,
          items: storevalue,
          drivername: storevalue[0].drivername,
          outshifttime: parseFloat(totaloutshiftDistance).toFixed(2),
          inshifttime: parseFloat(totalInshiftDistance).toFixed(2),
        });
      }

      sortedreformatteddate = reformatedata.sort(function (a: any, b: any) {
        return parseFloat(b.outshifttime) - parseFloat(a.outshifttime);
      });
      console.log(reformatedata);
      sortedreformatteddate.map((i: any) => {
        driverchartnames.push(i.items[0].drivername);
        chartoutshiftkms.push(parseFloat(i.outshifttime).toFixed(2));
        chartInshiftKms.push(parseFloat(i.inshifttime).toFixed(2));
      });
      setLoading(false);
      setdata(reformatedata);
      // return sortedreformatteddate;
      setBarData(await getBarData(props.colorMode));
    });
    updateCharts();
    getFuelcostPerUnit();
  }, [SelectedDate]);
  // console.log(SelectedDate);
  useEffect(() => {
    const getParamsDetails = () => {
      if (sdate !== "") {
        setTimeout(() => {
          // Retrieve 'sdate' and 'edate' from URL params, or set them as empty strings
          sdate = urlParams.has("sdate") ? urlParams.get("sdate") : "";
          edate = urlParams.has("edate") ? urlParams.get("edate") : "";

          // Convert the retrieved dates to Date objects
          let startDate = new Date(sdate);
          let endDate = edate !== "" ? new Date(edate) : startDate;

          // Set the selected date range state
          setSelectedDate([startDate, endDate]);
        }, 1000);
      } else {
        // If 'sdate' is not provided, set the default date range
        let todate = new Date();
        let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
        let lastfirstdate = new Date(
          todate.getFullYear(),
          todate.getMonth() - 1,
          1
        );
        setSelectedDate([lastfirstdate, lastenddate]);
      }
    };
    getParamsDetails();
  }, []);
  const getMileagedata = async (nextToken?: any) => {
    driverchartnames = [];
    chartoutshiftkms = [];
    chartInshiftKms = [];
    // let variables: any = {
    //   startdate: {
    //     ge: localetoisodatetime(SelectedDate.length > 0 ? SelectedDate[0] : []),
    //     le: localetoisodatetime(
    //       SelectedDate.length > 0 && SelectedDate[1] !== null
    //         ? SelectedDate[1]
    //         : SelectedDate[0]
    //     ),
    //   },
    // };
    // console.log(variables);

    let variables: any = {
      startdate: {
        ge: localetoisodatetime(SelectedDate.length > 0 ? SelectedDate[0] : []),
        le: localetoisodatetime(
          SelectedDate.length > 0 && SelectedDate[1] !== null
            ? SelectedDate[1]
            : SelectedDate[0]
        ),
      },
      // limit: 1000,
    };
    let filter = {
      between: [
        {
          sortstartdatetime: localetoisodatetime(
            SelectedDate.length > 0 ? SelectedDate[0] : []
          ),
        },
        {
          sortstartdatetime: localetoisodatetime(
            SelectedDate.length > 0 && SelectedDate[1] !== null
              ? SelectedDate[1]
              : SelectedDate[0]
          ),
        },
      ],
    };
    const response: any = await dxService.Sortlistitems(
      "mileagereport",
      "*",
      filter,
      "sortstartdatetimeDriverid",
      true,
      1000,
      nextToken
    );

    let data = response.items;
    const nextTokenResponse = response.nextToken;

    if (nextTokenResponse) {
      const nextResults = await getMileagedata(nextTokenResponse);
      data = data.concat(nextResults);
    }
    // data = data.filter((item: any) => item._deleted !== true);
    console.log(data);
    return data;

    // await getNextQueryData("", variables, "logmileage", true).then(
    //   (md: any) => {
    //     console.log(md);

    //     setLoading(false);
    //     setdata(reformatedata);
    //   }
    // );
    // return sortedreformatteddate;
  };
  const getFuelcostPerUnit = async () => {
    let filter = {
      date: {
        ge: localetoisodatetime(SelectedDate.length > 0 ? SelectedDate[0] : []),
        le: localetoisodatetime(
          SelectedDate.length > 0 && SelectedDate[1] !== null
            ? SelectedDate[1]
            : SelectedDate[0]
        ),
      },
    };
    let GetResponse: any = await API.graphql({
      query: listDxLogVipfuels,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { filter: filter },
    });
    setfuelcost(GetResponse.data.listDxLogVipfuels.items);
    // console.log(GetResponse.data.listDxLogVipfuels.items);
  };
  const findKms = (storevalue: any, shift: any) => {
    const totalshiftDistance = storevalue.reduce((sum: any, e: any) => {
      let totalinshiftMinutes: any;
      const totaldurationMinutes = convertIntoMinutes(e.duration);
      const distance = e.distance;
      const numericValue = parseFloat(distance);
      const Kmstravelledinmins = numericValue / totaldurationMinutes.toFixed(2);
      if (shift == "outshifttime") {
        totalinshiftMinutes =
          e.outshifttime !== null ? convertIntoMinutes(e.outshifttime) : "";
      } else {
        totalinshiftMinutes =
          e.inshifttime !== null ? convertIntoMinutes(e.inshifttime) : "";
      }

      const inshiftKmstravelled: any = Kmstravelledinmins * totalinshiftMinutes;
      //   console.log(inshiftKmstravelled);

      const outshiftdistance = parseFloat(inshiftKmstravelled); // Convert distance to a number
      if (!isNaN(outshiftdistance)) {
        sum += outshiftdistance;
      }
      return sum;
    }, 0);
    return totalshiftDistance;
  };

  const Mileage = (e: any) => {
    return <>{parseFloat(e.totaldistance).toFixed(2)}</>;
  };
  function convertTo12HourFormat(timeString: any) {
    const date = new Date(`1970-01-01T${timeString}`);
    // console.log(date);

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes.toString().padStart(2, "0")} ${amPm}`;
  }

  const MileageShift = (e: any) => {
    let actualstart: any;
    let actualend: any;
    if (e.drivershift.shift !== null && e.drivershift.shift.starttime == null) {
      actualstart = e.drivershift.shift.id;
    } else {
      const convertedStartTime = convertTo12HourFormat(
        e.drivershift.shift.starttime
      );
      const convertedEndTime = convertTo12HourFormat(
        e.drivershift.shift.endtime
      );
      //   console.log(e.drivershift.shift.starttime, e.drivershift.shift.endtime);

      actualstart = convertedStartTime;
      actualend = convertedEndTime;
    }
    //
    return (
      <>
        {actualstart && actualend
          ? `${actualstart} to ${actualend}`
          : actualstart}
      </>
    );
  };

  const CustomDate = (e: any) => {
    return <>{moment(e.startdate).format("DD-MMM-YYYY")}</>;
  };
  function convertIntoMinutes(e: any) {
    const duration = e;

    const [hours, minutes, seconds] = duration.split(":").map(Number);

    const totaldurationMinutes = hours * 60 + minutes + seconds / 60;
    return totaldurationMinutes;
  }

  const InShiftKms = (e: any) => {
    // console.log(e);

    const totaldurationMinutes = convertIntoMinutes(e.duration);
    const distance = e.distance;
    const numericValue = parseFloat(distance);
    const Kmstravelledinmins = numericValue / totaldurationMinutes.toFixed(2);
    const totalinshiftMinutes =
      e.inshifttime !== null ? convertIntoMinutes(e.inshifttime) : "";
    const inshiftKmstravelled = Kmstravelledinmins * totalinshiftMinutes;
    return <>{inshiftKmstravelled.toFixed(2)}</>;
  };
  const OutShiftKms = (e: any) => {
    const totaldurationMinutes = convertIntoMinutes(e.duration);
    const distance = e.distance;
    const numericValue = parseFloat(distance);
    const Kmstravelledinmins = numericValue / totaldurationMinutes.toFixed(2);
    const totalOutshiftMinutes =
      e.outshifttime !== null ? convertIntoMinutes(e.outshifttime) : "";
    const OutshiftKmstravelled = Kmstravelledinmins * totalOutshiftMinutes;
    return (
      <>
        <span className="customer-badge status-unqualified">
          {OutshiftKmstravelled.toFixed(2)}
        </span>
      </>
    );
  };
  function convertTo12HourFormattime(time24: any) {
    const [hours, minutes, seconds] = time24.split(":").map(Number);

    let ampm = "AM";
    let formattedHours = hours;

    if (hours >= 12) {
      ampm = "PM";
      formattedHours = hours === 12 ? 12 : hours - 12;
    }

    if (formattedHours < 10) {
      formattedHours = "0" + formattedHours;
    }

    return `${formattedHours}:${minutes}:${seconds} ${ampm}`;
  }
  const actionStartTime = (e: any) => {
    // console.log(e);

    const convertedStartTime = convertTo12HourFormattime(e.startdatetime);
    return <>{convertedStartTime}</>;
  };
  const actionEndTime = (e: any) => {
    // console.log(e);

    const convertedEndTime = convertTo12HourFormattime(e.enddatetime);
    return (
      <>
        {convertedEndTime}
        {/* {e.enddatetime} */}
      </>
    );
  };
  useEffect(() => {
    updateURL(selectView, SelectedDate[0], SelectedDate[1], selectedVehicle);
  }, [SelectedDate, selectedVehicle]);
  const updateURL = (view: any, sdate: any, edate: any, vid: any) => {
    const baseUrl = window.location.href.split("?")[0];
    const queryParams = new URLSearchParams(window.location.search);

    if (sdate) {
      queryParams.set("sdate", convertdate(sdate));
    } else {
      queryParams.delete("sdate");
    }
    if (edate) {
      queryParams.set("edate", convertdate(edate));
    } else {
      queryParams.delete("edate");
    }
    if (view) {
      queryParams.set("view", view);
    } else {
      queryParams.delete("view");
    }
    if (vid) {
      queryParams.set("vid", vid);
    } else {
      queryParams.delete("vid");
    }

    const newUrl = baseUrl + "?" + queryParams.toString();
    window.history.replaceState({}, "", newUrl);
  };

  const rowExpansionTemplate = (data: any) => {
    // const csvLink = {
    //   filename: `Mileage_${convertdate(SelectedDate[0])}-${convertdate(
    //     SelectedDate[1]
    //   )}`,
    //   header: [],
    //   data: data.items,
    // };
    // console.log(data.items);
    return (
      <div className="orders-subtable">
        <DataTable
          value={data.items.sort(function (a: any, b: any) {
            return moment(a.startdate)
              .format()
              .localeCompare(moment(b.startdate).format());
          })}
          responsiveLayout="scroll"
          className="p-datatable-responsive usagetable"
          header={
            <div className="p-toolbar p-component">
              <div className="p-toolbar-group-start p-toolbar-group-left">
                {/* <h5>Details for {data.driver}</h5> */}
                {/* <CSVLink {...csvLink}>
                  <span
                    className="p-float-label exporttext text-white"
                    style={{ padding: "11px" }}
                  >
                    Export Summary
                  </span>
                </CSVLink> */}
              </div>
            </div>
          }
        >
          <Column
            field="startdate"
            className="text-left p-message p-message-collapse"
            header="Date"
            body={CustomDate}
          />
          <Column
            field="startdatetime"
            className="text-right p-message p-message-collapse"
            header="Start"
            body={actionStartTime}
          />
          <Column
            field="enddatetime"
            className="text-right p-message p-message-collapse"
            header="Stop"
            body={actionEndTime}
          />
          <Column
            field="duration"
            className="text-right p-message p-message-collapse"
            header="Total duration"
            // body={actionsubfloattemplate}
          />
          <Column
            field="distance"
            className="text-right p-message p-message-collapse"
            header="Total Kms"
            // body={subusagetemplate}
          />
          <Column
            field="totalshifttime"
            className="text-right p-message p-message-collapse"
            header="Shift"
            // body={actionsubfloattemplate}
          />
          <Column
            field="parking"
            className="text-right p-message p-message-collapse"
            header="Actual Shift"
            body={MileageShift}
          />
          <Column
            field="inshifttime"
            className="text-right p-message p-message-collapse"
            header="In shift"
            // body={actionsubfloattemplate}
          />
          <Column
            field="outshifttime"
            className="text-right p-message p-message-collapse"
            header="Out Shift"
            //   body={subusagetemplate}
          />
          <Column
            field="InShift Kms"
            className="text-right p-message p-message-collapse"
            header="InShift Kms"
            body={InShiftKms}
          />
          <Column
            field="outshifttime"
            className="text-right p-message p-message-collapse"
            header="OutShift kms"
            body={OutShiftKms}
          />
        </DataTable>
      </div>
    );
  };
  const secondsToHms = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}:${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };
  const actionnoofTrips = (rowData: any) => {
    return <>{rowData.items.length}</>;
  };
  const actiontotalDuration = (rowData: any) => {
    // console.log(rowData);
    const sumDurations = rowData.items.reduce((total: any, entry: any) => {
      const durationParts = entry.duration.split(":").map(Number);
      const durationInSeconds =
        durationParts[0] * 3600 + durationParts[1] * 60 + durationParts[2];
      //   console.log(total, durationInSeconds);
      return total + durationInSeconds;
    }, 0);

    const totalDuration = secondsToHms(sumDurations);

    // console.log(totalDuration);

    return (
      <>
        <div
          className={`p-button`}
          style={{
            width: "-webkit-fill-available",
            justifyContent: "flex-end",
            backgroundColor: "#ffa10024",
            color: "#e28e00",
          }}
        >
          {totalDuration}
        </div>
      </>
    );
  };
  const actiontotalKms = (rowData: any) => {
    const totalDistance = rowData.items.reduce((sum: any, entry: any) => {
      const distance = parseFloat(entry.distance); // Convert distance to a number
      if (!isNaN(distance)) {
        sum += distance;
      }
      return sum;
    }, 0);

    // console.log(totalDistance);
    return (
      <>
        {" "}
        <div
          className={`p-button`}
          style={{
            width: "-webkit-fill-available",
            justifyContent: "flex-end",
            background: "#0db2f024",
            color: "#0070CA",
          }}
        >
          {" "}
          {totalDistance.toFixed(2)}
        </div>
      </>
    );
  };
  //
  const actiontotalInshiftduration = (rowData: any) => {
    const sumInShiftTimeInSeconds = rowData.items.reduce(
      (sum: any, entry: any) => {
        const inShiftTime = entry.inshifttime;
        if (inShiftTime) {
          const [hours, minutes, seconds] = inShiftTime.split(":").map(Number);
          const durationInSeconds = hours * 3600 + minutes * 60 + seconds;
          sum += durationInSeconds;
        }
        return sum;
      },
      0
    );

    const hours = Math.floor(sumInShiftTimeInSeconds / 3600);
    const minutes = Math.floor((sumInShiftTimeInSeconds % 3600) / 60);
    const seconds = sumInShiftTimeInSeconds % 60;

    // console.log(`Sum of inshifttime: ${hours}:${minutes}:${seconds}`);

    return (
      <>
        {" "}
        <div
          className={`p-button`}
          style={{
            width: "-webkit-fill-available",
            justifyContent: "flex-end",
            background: "#00d70d2b",
            color: "#009d20",
          }}
        >
          {" "}
          {`${hours}:${minutes}:${seconds}`}
        </div>
      </>
    );
  };
  //actiontotalInShiftKms
  const actiontotalInShiftKms = (rowData: any) => {
    return (
      <>
        {" "}
        <div
          className={`p-button`}
          style={{
            width: "-webkit-fill-available",
            justifyContent: "flex-end",
            background: "#00d70d2b",
            color: "#009d20",
          }}
        >
          {" "}
          {rowData.inshifttime}
        </div>
      </>
    );
  };
  const actiontotaoutShiftKms = (rowData: any) => {
    return (
      <>
        {" "}
        <div
          className={`p-button`}
          style={{
            width: "-webkit-fill-available",
            justifyContent: "flex-end",
            background: "#db000029",
            color: "#b20000",
          }}
        >
          {" "}
          {rowData.outshifttime}
        </div>
      </>
    );
  };
  const actiontotalInShiftamount = (rowData: any) => {
    // console.log(fuelcost);
    // const insideShiftDistance = 1299.32;
    const outsideShiftDistance = rowData.inshifttime;
    const fuelEfficiency = 10; // km/l
    const fuelCostPerLiter = fuelcost.length > 0 ? fuelcost[0].unitprice : 2.84; // Rs

    const totalFuelConsumptionLiters = outsideShiftDistance / fuelEfficiency;
    const calculatedTotalFuelCost =
      totalFuelConsumptionLiters * fuelCostPerLiter;

    return (
      <>
        <div
          className={`p-button`}
          style={{
            width: "-webkit-fill-available",
            justifyContent: "flex-end",
            background: "#00d70d2b",
            color: "#009d20",
          }}
        >
          {" "}
          {calculatedTotalFuelCost.toFixed(2)}
        </div>
      </>
    );
  };
  const actiontotalOutshiftamount = (rowData: any) => {
    // console.log(rowData);
    // const insideShiftDistance = 1299.32;
    const outsideShiftDistance = rowData.outshifttime;
    const fuelEfficiency = 10; // km/l
    const fuelCostPerLiter = fuelcost.length > 0 ? fuelcost[0].unitprice : 2.84; // Rs

    const totalFuelConsumptionLiters = outsideShiftDistance / fuelEfficiency;
    const calculatedTotalFuelCost =
      totalFuelConsumptionLiters * fuelCostPerLiter;

    return (
      <>
        <div
          className={`p-button`}
          style={{
            width: "-webkit-fill-available",
            justifyContent: "flex-end",
            background: "#db000029",
            color: "#b20000",
          }}
        >
          {" "}
          {calculatedTotalFuelCost.toFixed(2)}
        </div>
      </>
    );
  };
  const actiontotalOutshiftduration = (rowData: any) => {
    // console.log(fuelcost);

    const sumInShiftTimeInSeconds = rowData.items.reduce(
      (sum: any, entry: any) => {
        const inShiftTime = entry.outshifttime;
        if (inShiftTime) {
          const [hours, minutes, seconds] = inShiftTime.split(":").map(Number);
          const durationInSeconds = hours * 3600 + minutes * 60 + seconds;
          sum += durationInSeconds;
        }
        return sum;
      },
      0
    );

    const hours = Math.floor(sumInShiftTimeInSeconds / 3600);
    const minutes = Math.floor((sumInShiftTimeInSeconds % 3600) / 60);
    const seconds = sumInShiftTimeInSeconds % 60;

    // console.log(`Sum of inshifttime: ${hours}:${minutes}:${seconds}`);

    return (
      <>
        <div
          className={`p-button`}
          style={{
            width: "-webkit-fill-available",
            justifyContent: "flex-end",
            background: "#db000029",
            color: "#b20000",
          }}
        >
          {" "}
          {`${hours}:${minutes}:${seconds}`}
        </div>
      </>
    );
  };
  //set the date based on the date selection from the calendar
  useEffect(() => {
    const startDate = SelectedDate[0];
    const endDate = SelectedDate[1];
    const startDateFormat = startDate
      ? moment(startDate).format("DD MMM YY")
      : "";
    const endDateFormat = endDate ? moment(endDate).format("DD MMM YY") : "";

    setStartDateFormat(startDateFormat);
    setEndDateFormat(endDateFormat);
  }, [SelectedDate]);
  const ClearBtn = () => {
    setSelectedDate("");
    // dateformat = [];
    setStartDateFormat("");
    setEndDateFormat("");
    //   dateformat = 0;
    updateURL(selectView, "", "", selectedVehicle);
  };

  if (SelectedDate !== null && SelectedDate.length > 0) {
    var ShowClrBtn: any = (
      <>
        <button
          onClick={ClearBtn}
          className={`clrbtn`}
          // style={{ display: toggle, position: "relative", bottom: "5px" }}
        >
          <i
            className="ms-Icon ms-Icon--Cancel"
            aria-hidden="true"
            style={{ color: "#db0000", fontWeight: 700 }}
          ></i>
          &nbsp;
        </button>
      </>
    );
  }
  const clearbtn = () => {
    setGlobalFilter("");
  };
  const Commandbar = (
    <div className="p-toolbar p-component spd-removebg">
      <div className="p-toolbar-group-start p-toolbar-group-left">
        <Calendar
          id="range"
          className="dateFilter"
          value={SelectedDate}
          dateFormat="dd/m/yy"
          onChange={(e: any) => {
            updateURL(selectView, e.value[0], e.value[1], selectedVehicle);
            setSelectedDate(e.value);
          }}
          selectionMode="range"
          readOnlyInput
          placeholder="Select date"
        />

        {ShowClrBtn}
      </div>
      <div style={{ display: "flex" }}>
        <InputText
          type="text"
          style={{ height: "37px" }}
          value={globalFilter}
          id={globalFilter}
          placeholder="Search ..."
          className="homeNews__search"
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
        {globalFilter.length > 0 ? (
          <button
            type="button"
            className="contentBox__searchClearBtn"
            onClick={clearbtn}
          >
            X
          </button>
        ) : (
          ""
        )}
      </div>
      <div style={{ marginLeft: "10px" }}>
        <Dropdown
          value={selectView}
          style={{ width: "200px" }}
          placeholder="Select an option"
          className="mr-2"
          options={[
            { label: "Report", value: "Report" },
            { label: "Chart", value: "Chart" },
          ]}
          onChange={(e) => {
            setSelectView(e.value);
          }}
          optionLabel="label"
        />
      </div>
    </div>
  );
  console.log(data);

  return (
    <div className="grid ">
      <div className="col-12 md:col-12">
        <h4 className="container-header">Mileage Report</h4>
        <div className="col-12 md:col-12">
          {" "}
          <div className="card container-card">
            {selectView == "Report" ? (
              <DataTable
                value={data
                  .filter((name: any) => {
                    if (
                      name.driver
                        .toLowerCase()
                        .includes(globalFilter.toLowerCase())
                    ) {
                      return name;
                    }
                  })
                  .sort(function (a: any, b: any) {
                    return (
                      parseFloat(b.outshifttime) - parseFloat(a.outshifttime)
                    );
                  })}
                tableStyle={{ minWidth: "50rem" }}
                header={
                  <div className="p-toolbar p-component p-0">
                    <div className="p-toolbar-group-start p-toolbar-group-left">
                      <h5>Summary/Grouped Report</h5>
                    </div>

                    <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                      {Commandbar}
                    </div>
                  </div>
                }
                loading={loading}
                className="p-datatable-responsive"
                responsiveLayout="scroll"
                rows={300}
                selection={selectedProduct}
                onSelectionChange={(e) => setSelectedProduct(e.value)}
                rowExpansionTemplate={rowExpansionTemplate}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                sortField="actiontotalOutshiftduration" // Set the sorting field
                sortOrder={1} // Set the sorting order (descending)
              >
                <Column expander style={{ width: "3em" }} />
                <Column field="driver" header="Driver"></Column>
                <Column
                  field="driver"
                  header="Total no of Trips"
                  body={actionnoofTrips}
                ></Column>

                <Column
                  field="category"
                  header="Total Mileage Kms"
                  body={actiontotalKms}
                  style={{ width: "150px" }}
                ></Column>
                <Column
                  field="quantity"
                  header="Total InShift Kms"
                  body={actiontotalInShiftKms}
                  style={{ width: "150px" }}
                ></Column>
                <Column
                  field="quantity"
                  header="Total OutShift Kms"
                  body={actiontotaoutShiftKms}
                  style={{ width: "150px" }}
                ></Column>
                <Column
                  field="name"
                  header="Total duration (hh:mm:ss)"
                  body={actiontotalDuration}
                  style={{ width: "150px" }}
                ></Column>
                <Column
                  field="quantity"
                  header="Total InShift duration (hh:mm:ss)"
                  body={actiontotalInshiftduration}
                  style={{ width: "150px" }}
                ></Column>
                <Column
                  field="quantity"
                  header="Total OutShift duration (hh:mm:ss)"
                  body={actiontotalOutshiftduration}
                  style={{ width: "150px" }}
                ></Column>
                <Column
                  field="quantity"
                  header="InShift Amount(AED)"
                  body={actiontotalInShiftamount}
                  style={{ width: "150px" }}
                ></Column>
                <Column
                  field="quantity"
                  header="OutShift Amount(AED)"
                  body={actiontotalOutshiftamount}
                  style={{ width: "150px" }}
                ></Column>
              </DataTable>
            ) : (
              <>
                <div>
                  <div className="p-toolbar p-component p-0">
                    <div className="p-toolbar-group-start p-toolbar-group-left">
                      <h5>Chart Report</h5>
                    </div>

                    <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                      {Commandbar}
                    </div>
                  </div>
                  <Chart
                    type="bar"
                    data={barData}
                    plugins={[ChartDataLabels]}
                    options={barChartOptions}
                  ></Chart>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MileageReports;
