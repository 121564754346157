export const VehicleParts = [
    { label: 'Battery', value: 'Battery' },
    { label: 'Nuts/Bolt', value: 'Nuts/Bolt' },
    { label: 'Spark Plugs', value: 'Spark Plugs' },
    { label: 'Engine', value: 'Engine' },
    { label: 'Bushings', value: 'Bushings' },
    { label: 'Clutch', value: 'Clutch' },
    { label: 'Wheels', value: 'Wheels' },
    { label: 'Bearings', value: 'Bearings' },
    { label: 'Fuse', value: 'Fuse' },
    { label: 'Bulb', value: 'Bulb' },
    { label: 'Wiring', value: 'Wiring' },
    { label: 'Wipers', value: 'Wipers' },
    { label: 'A/C', value: 'A/C' },
    { label: 'Seat Cover', value: 'Seat Cover' },
    { label: 'Tyres', value: 'Tyres' },
    { label: 'Others', value: 'Others' }
];
