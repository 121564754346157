/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDxCompany = /* GraphQL */ `
  subscription OnCreateDxCompany(
    $filter: ModelSubscriptionDxCompanyFilterInput
    $owner: String
  ) {
    onCreateDxCompany(filter: $filter, owner: $owner) {
      id
      fleettype
      name
      description
      department
      departments {
        items {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDxCompany = /* GraphQL */ `
  subscription OnUpdateDxCompany(
    $filter: ModelSubscriptionDxCompanyFilterInput
    $owner: String
  ) {
    onUpdateDxCompany(filter: $filter, owner: $owner) {
      id
      fleettype
      name
      description
      department
      departments {
        items {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDxCompany = /* GraphQL */ `
  subscription OnDeleteDxCompany(
    $filter: ModelSubscriptionDxCompanyFilterInput
    $owner: String
  ) {
    onDeleteDxCompany(filter: $filter, owner: $owner) {
      id
      fleettype
      name
      description
      department
      departments {
        items {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDxDepartment = /* GraphQL */ `
  subscription OnCreateDxDepartment(
    $filter: ModelSubscriptionDxDepartmentFilterInput
    $owner: String
  ) {
    onCreateDxDepartment(filter: $filter, owner: $owner) {
      id
      fleettype
      name
      description
      department
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicles {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      member
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDepartmentsId
      __typename
    }
  }
`;
export const onUpdateDxDepartment = /* GraphQL */ `
  subscription OnUpdateDxDepartment(
    $filter: ModelSubscriptionDxDepartmentFilterInput
    $owner: String
  ) {
    onUpdateDxDepartment(filter: $filter, owner: $owner) {
      id
      fleettype
      name
      description
      department
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicles {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      member
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDepartmentsId
      __typename
    }
  }
`;
export const onDeleteDxDepartment = /* GraphQL */ `
  subscription OnDeleteDxDepartment(
    $filter: ModelSubscriptionDxDepartmentFilterInput
    $owner: String
  ) {
    onDeleteDxDepartment(filter: $filter, owner: $owner) {
      id
      fleettype
      name
      description
      department
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicles {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      member
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDepartmentsId
      __typename
    }
  }
`;
export const onCreateDxGeneralConfig = /* GraphQL */ `
  subscription OnCreateDxGeneralConfig(
    $filter: ModelSubscriptionDxGeneralConfigFilterInput
    $owner: String
  ) {
    onCreateDxGeneralConfig(filter: $filter, owner: $owner) {
      id
      name
      parentname {
        id
        name
        parentname {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        fleettype
        documents
        images
        isactive
        date
        author
        editor
        jfield
        userinfo
        childs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxGeneralConfigChildsId
        owner
        __typename
      }
      fleettype
      documents
      images
      isactive
      date
      author
      editor
      jfield
      userinfo
      childs {
        items {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxGeneralConfigChildsId
      owner
      __typename
    }
  }
`;
export const onUpdateDxGeneralConfig = /* GraphQL */ `
  subscription OnUpdateDxGeneralConfig(
    $filter: ModelSubscriptionDxGeneralConfigFilterInput
    $owner: String
  ) {
    onUpdateDxGeneralConfig(filter: $filter, owner: $owner) {
      id
      name
      parentname {
        id
        name
        parentname {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        fleettype
        documents
        images
        isactive
        date
        author
        editor
        jfield
        userinfo
        childs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxGeneralConfigChildsId
        owner
        __typename
      }
      fleettype
      documents
      images
      isactive
      date
      author
      editor
      jfield
      userinfo
      childs {
        items {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxGeneralConfigChildsId
      owner
      __typename
    }
  }
`;
export const onDeleteDxGeneralConfig = /* GraphQL */ `
  subscription OnDeleteDxGeneralConfig(
    $filter: ModelSubscriptionDxGeneralConfigFilterInput
    $owner: String
  ) {
    onDeleteDxGeneralConfig(filter: $filter, owner: $owner) {
      id
      name
      parentname {
        id
        name
        parentname {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        fleettype
        documents
        images
        isactive
        date
        author
        editor
        jfield
        userinfo
        childs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxGeneralConfigChildsId
        owner
        __typename
      }
      fleettype
      documents
      images
      isactive
      date
      author
      editor
      jfield
      userinfo
      childs {
        items {
          id
          name
          fleettype
          documents
          images
          isactive
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxGeneralConfigChildsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxGeneralConfigChildsId
      owner
      __typename
    }
  }
`;
export const onCreateDxDriver = /* GraphQL */ `
  subscription OnCreateDxDriver(
    $filter: ModelSubscriptionDxDriverFilterInput
    $owner: String
  ) {
    onCreateDxDriver(filter: $filter, owner: $owner) {
      id
      fleettype
      name
      mobileno
      contactno
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedriver {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      emailid
      licenseno
      licensetype
      licensepicture
      startdate
      enddate
      jobrole
      firstname
      lastname
      uname
      isnew
      isarchived
      status
      departmentname
      businessunitname
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDriversId
      dxDepartmentDriversId
      owner
      __typename
    }
  }
`;
export const onUpdateDxDriver = /* GraphQL */ `
  subscription OnUpdateDxDriver(
    $filter: ModelSubscriptionDxDriverFilterInput
    $owner: String
  ) {
    onUpdateDxDriver(filter: $filter, owner: $owner) {
      id
      fleettype
      name
      mobileno
      contactno
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedriver {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      emailid
      licenseno
      licensetype
      licensepicture
      startdate
      enddate
      jobrole
      firstname
      lastname
      uname
      isnew
      isarchived
      status
      departmentname
      businessunitname
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDriversId
      dxDepartmentDriversId
      owner
      __typename
    }
  }
`;
export const onDeleteDxDriver = /* GraphQL */ `
  subscription OnDeleteDxDriver(
    $filter: ModelSubscriptionDxDriverFilterInput
    $owner: String
  ) {
    onDeleteDxDriver(filter: $filter, owner: $owner) {
      id
      fleettype
      name
      mobileno
      contactno
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      company {
        id
        fleettype
        name
        description
        department
        departments {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedriver {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      emailid
      licenseno
      licensetype
      licensepicture
      startdate
      enddate
      jobrole
      firstname
      lastname
      uname
      isnew
      isarchived
      status
      departmentname
      businessunitname
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxCompanyDriversId
      dxDepartmentDriversId
      owner
      __typename
    }
  }
`;
export const onCreateDxVehicleActivity = /* GraphQL */ `
  subscription OnCreateDxVehicleActivity(
    $filter: ModelSubscriptionDxVehicleActivityFilterInput
    $owner: String
  ) {
    onCreateDxVehicleActivity(filter: $filter, owner: $owner) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      nextcontracts {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      logs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      recentevents {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      expanses
      nextdates
      events
      VehicleStatus
      ImagePath
      Documents
      expiring
      isarchived
      wfstatus
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverVehicleactivityId
      dxVehicleVehicleactivityId
      dxAppointmentsActivityId
      dxContractActivityId
      owner
      __typename
    }
  }
`;
export const onUpdateDxVehicleActivity = /* GraphQL */ `
  subscription OnUpdateDxVehicleActivity(
    $filter: ModelSubscriptionDxVehicleActivityFilterInput
    $owner: String
  ) {
    onUpdateDxVehicleActivity(filter: $filter, owner: $owner) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      nextcontracts {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      logs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      recentevents {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      expanses
      nextdates
      events
      VehicleStatus
      ImagePath
      Documents
      expiring
      isarchived
      wfstatus
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverVehicleactivityId
      dxVehicleVehicleactivityId
      dxAppointmentsActivityId
      dxContractActivityId
      owner
      __typename
    }
  }
`;
export const onDeleteDxVehicleActivity = /* GraphQL */ `
  subscription OnDeleteDxVehicleActivity(
    $filter: ModelSubscriptionDxVehicleActivityFilterInput
    $owner: String
  ) {
    onDeleteDxVehicleActivity(filter: $filter, owner: $owner) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      nextcontracts {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      logs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      recentevents {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      expanses
      nextdates
      events
      VehicleStatus
      ImagePath
      Documents
      expiring
      isarchived
      wfstatus
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverVehicleactivityId
      dxVehicleVehicleactivityId
      dxAppointmentsActivityId
      dxContractActivityId
      owner
      __typename
    }
  }
`;
export const onCreateDxVehicle = /* GraphQL */ `
  subscription OnCreateDxVehicle(
    $filter: ModelSubscriptionDxVehicleFilterInput
    $owner: String
  ) {
    onCreateDxVehicle(filter: $filter, owner: $owner) {
      id
      platecode
      plateno
      make
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      capacity
      departmentname
      status
      chasisno
      salikno
      salikpin
      fuelno
      vowner
      isarchived
      year
      model
      policyno
      startdate
      enddate
      company
      permittype
      allocationno
      drivername
      VehicleStatus
      ImagePath
      insuranceno
      registrationno
      scno
      scvendor
      cupdate
      jfield
      userinfo
      author
      editor
      fleettype
      allocationdriver
      expanses
      nextdates
      events
      Documents
      expiring
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDepartmentVehiclesId
      dxContractVehicleId
      owner
      __typename
    }
  }
`;
export const onUpdateDxVehicle = /* GraphQL */ `
  subscription OnUpdateDxVehicle(
    $filter: ModelSubscriptionDxVehicleFilterInput
    $owner: String
  ) {
    onUpdateDxVehicle(filter: $filter, owner: $owner) {
      id
      platecode
      plateno
      make
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      capacity
      departmentname
      status
      chasisno
      salikno
      salikpin
      fuelno
      vowner
      isarchived
      year
      model
      policyno
      startdate
      enddate
      company
      permittype
      allocationno
      drivername
      VehicleStatus
      ImagePath
      insuranceno
      registrationno
      scno
      scvendor
      cupdate
      jfield
      userinfo
      author
      editor
      fleettype
      allocationdriver
      expanses
      nextdates
      events
      Documents
      expiring
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDepartmentVehiclesId
      dxContractVehicleId
      owner
      __typename
    }
  }
`;
export const onDeleteDxVehicle = /* GraphQL */ `
  subscription OnDeleteDxVehicle(
    $filter: ModelSubscriptionDxVehicleFilterInput
    $owner: String
  ) {
    onDeleteDxVehicle(filter: $filter, owner: $owner) {
      id
      platecode
      plateno
      make
      departmentid
      businessunitid
      department {
        id
        fleettype
        name
        description
        department
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicles {
          nextToken
          __typename
        }
        drivers {
          nextToken
          __typename
        }
        owner
        member
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDepartmentsId
        __typename
      }
      vehicleactivity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      capacity
      departmentname
      status
      chasisno
      salikno
      salikpin
      fuelno
      vowner
      isarchived
      year
      model
      policyno
      startdate
      enddate
      company
      permittype
      allocationno
      drivername
      VehicleStatus
      ImagePath
      insuranceno
      registrationno
      scno
      scvendor
      cupdate
      jfield
      userinfo
      author
      editor
      fleettype
      allocationdriver
      expanses
      nextdates
      events
      Documents
      expiring
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDepartmentVehiclesId
      dxContractVehicleId
      owner
      __typename
    }
  }
`;
export const onCreateDxAllocations = /* GraphQL */ `
  subscription OnCreateDxAllocations(
    $filter: ModelSubscriptionDxAllocationsFilterInput
    $owner: String
  ) {
    onCreateDxAllocations(filter: $filter, owner: $owner) {
      id
      vehicleid
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      activity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      date
      drivername
      enddate
      dfleettype
      fromdriverID
      fromdrivername
      fromKM
      fromdate
      fromlocation
      driverID
      handoverdrivername
      handoverKM
      handoverdate
      handoverlocation
      distances
      reason
      allocatestatus
      pictures
      documents
      wfstatus
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAllocationsId
      dxVehicleActivityAllocationsId
      dxVehicleAllocationsId
      dxAppointmentsAllocationsId
      owner
      __typename
    }
  }
`;
export const onUpdateDxAllocations = /* GraphQL */ `
  subscription OnUpdateDxAllocations(
    $filter: ModelSubscriptionDxAllocationsFilterInput
    $owner: String
  ) {
    onUpdateDxAllocations(filter: $filter, owner: $owner) {
      id
      vehicleid
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      activity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      date
      drivername
      enddate
      dfleettype
      fromdriverID
      fromdrivername
      fromKM
      fromdate
      fromlocation
      driverID
      handoverdrivername
      handoverKM
      handoverdate
      handoverlocation
      distances
      reason
      allocatestatus
      pictures
      documents
      wfstatus
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAllocationsId
      dxVehicleActivityAllocationsId
      dxVehicleAllocationsId
      dxAppointmentsAllocationsId
      owner
      __typename
    }
  }
`;
export const onDeleteDxAllocations = /* GraphQL */ `
  subscription OnDeleteDxAllocations(
    $filter: ModelSubscriptionDxAllocationsFilterInput
    $owner: String
  ) {
    onDeleteDxAllocations(filter: $filter, owner: $owner) {
      id
      vehicleid
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      activity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      masterlogs {
        items {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      drivershifts {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      date
      drivername
      enddate
      dfleettype
      fromdriverID
      fromdrivername
      fromKM
      fromdate
      fromlocation
      driverID
      handoverdrivername
      handoverKM
      handoverdate
      handoverlocation
      distances
      reason
      allocatestatus
      pictures
      documents
      wfstatus
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAllocationsId
      dxVehicleActivityAllocationsId
      dxVehicleAllocationsId
      dxAppointmentsAllocationsId
      owner
      __typename
    }
  }
`;
export const onCreateDxPermissionsLevels = /* GraphQL */ `
  subscription OnCreateDxPermissionsLevels(
    $filter: ModelSubscriptionDxPermissionsLevelsFilterInput
    $owner: String
  ) {
    onCreateDxPermissionsLevels(filter: $filter, owner: $owner) {
      id
      name
      description
      mask
      groups
      field1
      field2
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxPermissionsLevels = /* GraphQL */ `
  subscription OnUpdateDxPermissionsLevels(
    $filter: ModelSubscriptionDxPermissionsLevelsFilterInput
    $owner: String
  ) {
    onUpdateDxPermissionsLevels(filter: $filter, owner: $owner) {
      id
      name
      description
      mask
      groups
      field1
      field2
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxPermissionsLevels = /* GraphQL */ `
  subscription OnDeleteDxPermissionsLevels(
    $filter: ModelSubscriptionDxPermissionsLevelsFilterInput
    $owner: String
  ) {
    onDeleteDxPermissionsLevels(filter: $filter, owner: $owner) {
      id
      name
      description
      mask
      groups
      field1
      field2
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateDxRoles = /* GraphQL */ `
  subscription OnCreateDxRoles(
    $filter: ModelSubscriptionDxRolesFilterInput
    $owner: String
  ) {
    onCreateDxRoles(filter: $filter, owner: $owner) {
      id
      rolename
      description
      application
      permissionlevel
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      title
      create
      read
      update
      delete
      jfield
      fullcontrol
      moderator
      member
      reader
      choicetype
      fleettable
      fleetdocs
      vehicle
      vehicledocs
      driver
      driverdocs
      appointments
      author
      editor
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxRoles = /* GraphQL */ `
  subscription OnUpdateDxRoles(
    $filter: ModelSubscriptionDxRolesFilterInput
    $owner: String
  ) {
    onUpdateDxRoles(filter: $filter, owner: $owner) {
      id
      rolename
      description
      application
      permissionlevel
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      title
      create
      read
      update
      delete
      jfield
      fullcontrol
      moderator
      member
      reader
      choicetype
      fleettable
      fleetdocs
      vehicle
      vehicledocs
      driver
      driverdocs
      appointments
      author
      editor
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxRoles = /* GraphQL */ `
  subscription OnDeleteDxRoles(
    $filter: ModelSubscriptionDxRolesFilterInput
    $owner: String
  ) {
    onDeleteDxRoles(filter: $filter, owner: $owner) {
      id
      rolename
      description
      application
      permissionlevel
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      title
      create
      read
      update
      delete
      jfield
      fullcontrol
      moderator
      member
      reader
      choicetype
      fleettable
      fleetdocs
      vehicle
      vehicledocs
      driver
      driverdocs
      appointments
      author
      editor
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateDxUserRoles = /* GraphQL */ `
  subscription OnCreateDxUserRoles(
    $filter: ModelSubscriptionDxUserRolesFilterInput
    $owner: String
  ) {
    onCreateDxUserRoles(filter: $filter, owner: $owner) {
      id
      username
      userrole
      usergroups
      userprofile {
        id
        userid
        provideruserid
        email
        display
        picture
        displayname
        sub
        accessgroups
        userroles {
          nextToken
          __typename
        }
        attributes
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      usergroup {
        id
        rolename
        description
        application
        permissionlevel
        userroles {
          nextToken
          __typename
        }
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxRolesUserrolesId
      dxUserProfileUserrolesId
      owner
      __typename
    }
  }
`;
export const onUpdateDxUserRoles = /* GraphQL */ `
  subscription OnUpdateDxUserRoles(
    $filter: ModelSubscriptionDxUserRolesFilterInput
    $owner: String
  ) {
    onUpdateDxUserRoles(filter: $filter, owner: $owner) {
      id
      username
      userrole
      usergroups
      userprofile {
        id
        userid
        provideruserid
        email
        display
        picture
        displayname
        sub
        accessgroups
        userroles {
          nextToken
          __typename
        }
        attributes
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      usergroup {
        id
        rolename
        description
        application
        permissionlevel
        userroles {
          nextToken
          __typename
        }
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxRolesUserrolesId
      dxUserProfileUserrolesId
      owner
      __typename
    }
  }
`;
export const onDeleteDxUserRoles = /* GraphQL */ `
  subscription OnDeleteDxUserRoles(
    $filter: ModelSubscriptionDxUserRolesFilterInput
    $owner: String
  ) {
    onDeleteDxUserRoles(filter: $filter, owner: $owner) {
      id
      username
      userrole
      usergroups
      userprofile {
        id
        userid
        provideruserid
        email
        display
        picture
        displayname
        sub
        accessgroups
        userroles {
          nextToken
          __typename
        }
        attributes
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      usergroup {
        id
        rolename
        description
        application
        permissionlevel
        userroles {
          nextToken
          __typename
        }
        title
        create
        read
        update
        delete
        jfield
        fullcontrol
        moderator
        member
        reader
        choicetype
        fleettable
        fleetdocs
        vehicle
        vehicledocs
        driver
        driverdocs
        appointments
        author
        editor
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxRolesUserrolesId
      dxUserProfileUserrolesId
      owner
      __typename
    }
  }
`;
export const onCreateDxUserProfile = /* GraphQL */ `
  subscription OnCreateDxUserProfile(
    $filter: ModelSubscriptionDxUserProfileFilterInput
    $owner: String
  ) {
    onCreateDxUserProfile(filter: $filter, owner: $owner) {
      id
      userid
      provideruserid
      email
      display
      picture
      displayname
      sub
      accessgroups
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      attributes
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxUserProfile = /* GraphQL */ `
  subscription OnUpdateDxUserProfile(
    $filter: ModelSubscriptionDxUserProfileFilterInput
    $owner: String
  ) {
    onUpdateDxUserProfile(filter: $filter, owner: $owner) {
      id
      userid
      provideruserid
      email
      display
      picture
      displayname
      sub
      accessgroups
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      attributes
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxUserProfile = /* GraphQL */ `
  subscription OnDeleteDxUserProfile(
    $filter: ModelSubscriptionDxUserProfileFilterInput
    $owner: String
  ) {
    onDeleteDxUserProfile(filter: $filter, owner: $owner) {
      id
      userid
      provideruserid
      email
      display
      picture
      displayname
      sub
      accessgroups
      userroles {
        items {
          id
          username
          userrole
          usergroups
          field1
          field2
          field3
          field4
          field5
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxRolesUserrolesId
          dxUserProfileUserrolesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      attributes
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateDxAppointments = /* GraphQL */ `
  subscription OnCreateDxAppointments(
    $filter: ModelSubscriptionDxAppointmentsFilterInput
    $owner: String
  ) {
    onCreateDxAppointments(filter: $filter, owner: $owner) {
      id
      spares {
        items {
          id
          vehicleid
          driverid
          supplier
          invoiceno
          invoicedoc
          partname
          itemtype
          quantity
          description
          amount
          totalamount
          approvedby
          status
          appointmentid
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxAppointmentsSparesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      contract {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      eventtype
      Date
      place
      description
      drivername
      appointmentstatus
      wanttoallocatedriver
      closeallocatedriver
      issccovered
      pictures
      documents
      cost
      invoiceno
      mileage
      auditby
      userprofiles
      accidenthappenedat
      accidenttype
      wfstatus
      author
      editor
      cupdate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAppointmentsId
      dxVehicleAppointmentsId
      dxContractAppointmentsId
      dxMasterLogsAccidentsId
      owner
      __typename
    }
  }
`;
export const onUpdateDxAppointments = /* GraphQL */ `
  subscription OnUpdateDxAppointments(
    $filter: ModelSubscriptionDxAppointmentsFilterInput
    $owner: String
  ) {
    onUpdateDxAppointments(filter: $filter, owner: $owner) {
      id
      spares {
        items {
          id
          vehicleid
          driverid
          supplier
          invoiceno
          invoicedoc
          partname
          itemtype
          quantity
          description
          amount
          totalamount
          approvedby
          status
          appointmentid
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxAppointmentsSparesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      contract {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      eventtype
      Date
      place
      description
      drivername
      appointmentstatus
      wanttoallocatedriver
      closeallocatedriver
      issccovered
      pictures
      documents
      cost
      invoiceno
      mileage
      auditby
      userprofiles
      accidenthappenedat
      accidenttype
      wfstatus
      author
      editor
      cupdate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAppointmentsId
      dxVehicleAppointmentsId
      dxContractAppointmentsId
      dxMasterLogsAccidentsId
      owner
      __typename
    }
  }
`;
export const onDeleteDxAppointments = /* GraphQL */ `
  subscription OnDeleteDxAppointments(
    $filter: ModelSubscriptionDxAppointmentsFilterInput
    $owner: String
  ) {
    onDeleteDxAppointments(filter: $filter, owner: $owner) {
      id
      spares {
        items {
          id
          vehicleid
          driverid
          supplier
          invoiceno
          invoicedoc
          partname
          itemtype
          quantity
          description
          amount
          totalamount
          approvedby
          status
          appointmentid
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxAppointmentsSparesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      allocations {
        items {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      contract {
        id
        renewaltype
        vehicle {
          nextToken
          __typename
        }
        renewalno
        subtype
        issuedate
        expirydate
        vehicleid
        documents
        pictures
        documents2
        renewalstatus
        isappointmentrequire
        appointments {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        wfstatus
        period
        scvendor
        author
        editor
        cupdate
        jfield
        userinfo
        amount
        tax
        totalamount
        policyno
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      eventtype
      Date
      place
      description
      drivername
      appointmentstatus
      wanttoallocatedriver
      closeallocatedriver
      issccovered
      pictures
      documents
      cost
      invoiceno
      mileage
      auditby
      userprofiles
      accidenthappenedat
      accidenttype
      wfstatus
      author
      editor
      cupdate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverAppointmentsId
      dxVehicleAppointmentsId
      dxContractAppointmentsId
      dxMasterLogsAccidentsId
      owner
      __typename
    }
  }
`;
export const onCreateDxVendors = /* GraphQL */ `
  subscription OnCreateDxVendors(
    $filter: ModelSubscriptionDxVendorsFilterInput
    $owner: String
  ) {
    onCreateDxVendors(filter: $filter, owner: $owner) {
      id
      company
      vendorname
      contactno
      email
      vendortype
      officelocation
      garagelocation
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxVendors = /* GraphQL */ `
  subscription OnUpdateDxVendors(
    $filter: ModelSubscriptionDxVendorsFilterInput
    $owner: String
  ) {
    onUpdateDxVendors(filter: $filter, owner: $owner) {
      id
      company
      vendorname
      contactno
      email
      vendortype
      officelocation
      garagelocation
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxVendors = /* GraphQL */ `
  subscription OnDeleteDxVendors(
    $filter: ModelSubscriptionDxVendorsFilterInput
    $owner: String
  ) {
    onDeleteDxVendors(filter: $filter, owner: $owner) {
      id
      company
      vendorname
      contactno
      email
      vendortype
      officelocation
      garagelocation
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateDxContract = /* GraphQL */ `
  subscription OnCreateDxContract(
    $filter: ModelSubscriptionDxContractFilterInput
    $owner: String
  ) {
    onCreateDxContract(filter: $filter, owner: $owner) {
      id
      renewaltype
      vehicle {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      renewalno
      subtype
      issuedate
      expirydate
      vehicleid
      documents
      pictures
      documents2
      renewalstatus
      isappointmentrequire
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      wfstatus
      period
      scvendor
      author
      editor
      cupdate
      jfield
      userinfo
      amount
      tax
      totalamount
      policyno
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxContract = /* GraphQL */ `
  subscription OnUpdateDxContract(
    $filter: ModelSubscriptionDxContractFilterInput
    $owner: String
  ) {
    onUpdateDxContract(filter: $filter, owner: $owner) {
      id
      renewaltype
      vehicle {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      renewalno
      subtype
      issuedate
      expirydate
      vehicleid
      documents
      pictures
      documents2
      renewalstatus
      isappointmentrequire
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      wfstatus
      period
      scvendor
      author
      editor
      cupdate
      jfield
      userinfo
      amount
      tax
      totalamount
      policyno
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxContract = /* GraphQL */ `
  subscription OnDeleteDxContract(
    $filter: ModelSubscriptionDxContractFilterInput
    $owner: String
  ) {
    onDeleteDxContract(filter: $filter, owner: $owner) {
      id
      renewaltype
      vehicle {
        items {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        nextToken
        __typename
      }
      renewalno
      subtype
      issuedate
      expirydate
      vehicleid
      documents
      pictures
      documents2
      renewalstatus
      isappointmentrequire
      appointments {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      activity {
        items {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        nextToken
        __typename
      }
      wfstatus
      period
      scvendor
      author
      editor
      cupdate
      jfield
      userinfo
      amount
      tax
      totalamount
      policyno
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateDxVehicleSpares = /* GraphQL */ `
  subscription OnCreateDxVehicleSpares(
    $filter: ModelSubscriptionDxVehicleSparesFilterInput
    $owner: String
  ) {
    onCreateDxVehicleSpares(filter: $filter, owner: $owner) {
      id
      vehicleid
      driverid
      supplier
      invoiceno
      invoicedoc
      partname
      itemtype
      quantity
      description
      amount
      totalamount
      approvedby
      status
      appointmentid
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxAppointmentsSparesId
      owner
      __typename
    }
  }
`;
export const onUpdateDxVehicleSpares = /* GraphQL */ `
  subscription OnUpdateDxVehicleSpares(
    $filter: ModelSubscriptionDxVehicleSparesFilterInput
    $owner: String
  ) {
    onUpdateDxVehicleSpares(filter: $filter, owner: $owner) {
      id
      vehicleid
      driverid
      supplier
      invoiceno
      invoicedoc
      partname
      itemtype
      quantity
      description
      amount
      totalamount
      approvedby
      status
      appointmentid
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxAppointmentsSparesId
      owner
      __typename
    }
  }
`;
export const onDeleteDxVehicleSpares = /* GraphQL */ `
  subscription OnDeleteDxVehicleSpares(
    $filter: ModelSubscriptionDxVehicleSparesFilterInput
    $owner: String
  ) {
    onDeleteDxVehicleSpares(filter: $filter, owner: $owner) {
      id
      vehicleid
      driverid
      supplier
      invoiceno
      invoicedoc
      partname
      itemtype
      quantity
      description
      amount
      totalamount
      approvedby
      status
      appointmentid
      appointment {
        id
        spares {
          nextToken
          __typename
        }
        activity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        contract {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        log {
          id
          vehicleid
          drivername
          date
          Accidents
          Fine
          Salik
          Mileage
          Parking
          Vipfuel
          author
          reimbursement
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMasterlogsId
          dxVehicleActivityLogsId
          dxVehicleMasterlogsId
          dxAllocationsMasterlogsId
          owner
          __typename
        }
        vehicleid
        eventtype
        Date
        place
        description
        drivername
        appointmentstatus
        wanttoallocatedriver
        closeallocatedriver
        issccovered
        pictures
        documents
        cost
        invoiceno
        mileage
        auditby
        userprofiles
        accidenthappenedat
        accidenttype
        wfstatus
        author
        editor
        cupdate
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAppointmentsId
        dxVehicleAppointmentsId
        dxContractAppointmentsId
        dxMasterLogsAccidentsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxAppointmentsSparesId
      owner
      __typename
    }
  }
`;
export const onCreateDxshifts = /* GraphQL */ `
  subscription OnCreateDxshifts(
    $filter: ModelSubscriptionDxshiftsFilterInput
    $owner: String
  ) {
    onCreateDxshifts(filter: $filter, owner: $owner) {
      id
      shift
      drivershift {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      starttime
      endtime
      startdatetime
      enddatetime
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxshifts = /* GraphQL */ `
  subscription OnUpdateDxshifts(
    $filter: ModelSubscriptionDxshiftsFilterInput
    $owner: String
  ) {
    onUpdateDxshifts(filter: $filter, owner: $owner) {
      id
      shift
      drivershift {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      starttime
      endtime
      startdatetime
      enddatetime
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxshifts = /* GraphQL */ `
  subscription OnDeleteDxshifts(
    $filter: ModelSubscriptionDxshiftsFilterInput
    $owner: String
  ) {
    onDeleteDxshifts(filter: $filter, owner: $owner) {
      id
      shift
      drivershift {
        items {
          id
          date
          day
          startdatetime
          enddatetime
          startKm
          endkm
          totalduration
          durationafterW
          Mileage
          MileageafterWH
          location
          status
          vehicleid
          remarks
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverDrivershiftsId
          dxAllocationsDrivershiftsId
          dxshiftsDrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      starttime
      endtime
      startdatetime
      enddatetime
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateDxdriverShifts = /* GraphQL */ `
  subscription OnCreateDxdriverShifts(
    $filter: ModelSubscriptionDxdriverShiftsFilterInput
    $owner: String
  ) {
    onCreateDxdriverShifts(filter: $filter, owner: $owner) {
      id
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      date
      day
      startdatetime
      enddatetime
      startKm
      endkm
      totalduration
      durationafterW
      Mileage
      MileageafterWH
      location
      shift {
        id
        shift
        drivershift {
          nextToken
          __typename
        }
        starttime
        endtime
        startdatetime
        enddatetime
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      vehicleid
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      drivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedrivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsMileagedrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagereport {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      remarks
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverDrivershiftsId
      dxAllocationsDrivershiftsId
      dxshiftsDrivershiftId
      owner
      __typename
    }
  }
`;
export const onUpdateDxdriverShifts = /* GraphQL */ `
  subscription OnUpdateDxdriverShifts(
    $filter: ModelSubscriptionDxdriverShiftsFilterInput
    $owner: String
  ) {
    onUpdateDxdriverShifts(filter: $filter, owner: $owner) {
      id
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      date
      day
      startdatetime
      enddatetime
      startKm
      endkm
      totalduration
      durationafterW
      Mileage
      MileageafterWH
      location
      shift {
        id
        shift
        drivershift {
          nextToken
          __typename
        }
        starttime
        endtime
        startdatetime
        enddatetime
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      vehicleid
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      drivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedrivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsMileagedrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagereport {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      remarks
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverDrivershiftsId
      dxAllocationsDrivershiftsId
      dxshiftsDrivershiftId
      owner
      __typename
    }
  }
`;
export const onDeleteDxdriverShifts = /* GraphQL */ `
  subscription OnDeleteDxdriverShifts(
    $filter: ModelSubscriptionDxdriverShiftsFilterInput
    $owner: String
  ) {
    onDeleteDxdriverShifts(filter: $filter, owner: $owner) {
      id
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      date
      day
      startdatetime
      enddatetime
      startKm
      endkm
      totalduration
      durationafterW
      Mileage
      MileageafterWH
      location
      shift {
        id
        shift
        drivershift {
          nextToken
          __typename
        }
        starttime
        endtime
        startdatetime
        enddatetime
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      vehicleid
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      drivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagedrivershift {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsMileagedrivershiftId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileagereport {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          sortstartdatetime
          tablename
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverMileagedriverId
          dxdriverShiftsMileagereportId
          owner
          __typename
        }
        nextToken
        __typename
      }
      remarks
      jfield
      userinfo
      author
      editor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverDrivershiftsId
      dxAllocationsDrivershiftsId
      dxshiftsDrivershiftId
      owner
      __typename
    }
  }
`;
export const onCreateDxlogReimbursement = /* GraphQL */ `
  subscription OnCreateDxlogReimbursement(
    $filter: ModelSubscriptionDxlogReimbursementFilterInput
    $owner: String
  ) {
    onCreateDxlogReimbursement(filter: $filter, owner: $owner) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      vehicleid
      driverid
      date
      transactiontype
      invoiceno
      paidto
      Vendorname
      description
      remarks
      split
      amount
      expensetype
      isreimbursed
      approver
      reimbursedamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverLogreimbursementId
      dxVehicleLogreimbursementId
      dxMasterLogsLogreimbursementId
      owner
      __typename
    }
  }
`;
export const onUpdateDxlogReimbursement = /* GraphQL */ `
  subscription OnUpdateDxlogReimbursement(
    $filter: ModelSubscriptionDxlogReimbursementFilterInput
    $owner: String
  ) {
    onUpdateDxlogReimbursement(filter: $filter, owner: $owner) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      vehicleid
      driverid
      date
      transactiontype
      invoiceno
      paidto
      Vendorname
      description
      remarks
      split
      amount
      expensetype
      isreimbursed
      approver
      reimbursedamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverLogreimbursementId
      dxVehicleLogreimbursementId
      dxMasterLogsLogreimbursementId
      owner
      __typename
    }
  }
`;
export const onDeleteDxlogReimbursement = /* GraphQL */ `
  subscription OnDeleteDxlogReimbursement(
    $filter: ModelSubscriptionDxlogReimbursementFilterInput
    $owner: String
  ) {
    onDeleteDxlogReimbursement(filter: $filter, owner: $owner) {
      id
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      vehicleid
      driverid
      date
      transactiontype
      invoiceno
      paidto
      Vendorname
      description
      remarks
      split
      amount
      expensetype
      isreimbursed
      approver
      reimbursedamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverLogreimbursementId
      dxVehicleLogreimbursementId
      dxMasterLogsLogreimbursementId
      owner
      __typename
    }
  }
`;
export const onCreateDxMasterLogs = /* GraphQL */ `
  subscription OnCreateDxMasterLogs(
    $filter: ModelSubscriptionDxMasterLogsFilterInput
    $owner: String
  ) {
    onCreateDxMasterLogs(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      Accidents
      Fine
      Salik
      Mileage
      Parking
      Vipfuel
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileages {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      fines {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          source
          finenumber
          blackpoints
          reason
          location
          type
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsFinesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      salik {
        items {
          id
          transactionid
          date
          transactiondate
          tollgate
          direction
          tagnumber
          vehicleid
          driverid
          drivername
          amount
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsSalikId
          owner
          __typename
        }
        nextToken
        __typename
      }
      parkings {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          author
          editor
          reimbursement
          company
          expirydate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsParkingsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vipfuel {
        items {
          id
          date
          transactiondate
          vehicleid
          rid
          unitprice
          volume
          total
          productname
          receiptno
          odometer
          idunitcode
          stationname
          stationcode
          fleetname
          groupname
          vehiclecode
          citycode
          costcenter
          vatrate
          vatamount
          actualamount
          driverid
          drivername
          cashdescription
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsVipfuelId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accidents {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      vehicleactivity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      author
      reimbursement
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMasterlogsId
      dxVehicleActivityLogsId
      dxVehicleMasterlogsId
      dxAllocationsMasterlogsId
      owner
      __typename
    }
  }
`;
export const onUpdateDxMasterLogs = /* GraphQL */ `
  subscription OnUpdateDxMasterLogs(
    $filter: ModelSubscriptionDxMasterLogsFilterInput
    $owner: String
  ) {
    onUpdateDxMasterLogs(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      Accidents
      Fine
      Salik
      Mileage
      Parking
      Vipfuel
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileages {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      fines {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          source
          finenumber
          blackpoints
          reason
          location
          type
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsFinesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      salik {
        items {
          id
          transactionid
          date
          transactiondate
          tollgate
          direction
          tagnumber
          vehicleid
          driverid
          drivername
          amount
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsSalikId
          owner
          __typename
        }
        nextToken
        __typename
      }
      parkings {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          author
          editor
          reimbursement
          company
          expirydate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsParkingsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vipfuel {
        items {
          id
          date
          transactiondate
          vehicleid
          rid
          unitprice
          volume
          total
          productname
          receiptno
          odometer
          idunitcode
          stationname
          stationcode
          fleetname
          groupname
          vehiclecode
          citycode
          costcenter
          vatrate
          vatamount
          actualamount
          driverid
          drivername
          cashdescription
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsVipfuelId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accidents {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      vehicleactivity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      author
      reimbursement
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMasterlogsId
      dxVehicleActivityLogsId
      dxVehicleMasterlogsId
      dxAllocationsMasterlogsId
      owner
      __typename
    }
  }
`;
export const onDeleteDxMasterLogs = /* GraphQL */ `
  subscription OnDeleteDxMasterLogs(
    $filter: ModelSubscriptionDxMasterLogsFilterInput
    $owner: String
  ) {
    onDeleteDxMasterLogs(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      Accidents
      Fine
      Salik
      Mileage
      Parking
      Vipfuel
      logreimbursement {
        items {
          id
          vehicleid
          driverid
          date
          transactiontype
          invoiceno
          paidto
          Vendorname
          description
          remarks
          split
          amount
          expensetype
          isreimbursed
          approver
          reimbursedamount
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverLogreimbursementId
          dxVehicleLogreimbursementId
          dxMasterLogsLogreimbursementId
          owner
          __typename
        }
        nextToken
        __typename
      }
      mileages {
        items {
          id
          vehicleid
          drivername
          date
          type
          startedtime
          endedtime
          inshifttime
          outshifttime
          totalshifttime
          transactiondate
          startKM
          endKM
          startAddress
          endAddress
          distance
          driverid
          description
          startdatetime
          enddatetime
          startdate
          enddate
          duration
          avgspeed
          maxspeed
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxdriverShiftsDrivershiftId
          dxMasterLogsMileagesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      fines {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          source
          finenumber
          blackpoints
          reason
          location
          type
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsFinesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      salik {
        items {
          id
          transactionid
          date
          transactiondate
          tollgate
          direction
          tagnumber
          vehicleid
          driverid
          drivername
          amount
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsSalikId
          owner
          __typename
        }
        nextToken
        __typename
      }
      parkings {
        items {
          id
          date
          transactiondate
          vehicleid
          driverid
          drivername
          amount
          innovationfee
          totalamount
          author
          editor
          reimbursement
          company
          expirydate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsParkingsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vipfuel {
        items {
          id
          date
          transactiondate
          vehicleid
          rid
          unitprice
          volume
          total
          productname
          receiptno
          odometer
          idunitcode
          stationname
          stationcode
          fleetname
          groupname
          vehiclecode
          citycode
          costcenter
          vatrate
          vatamount
          actualamount
          driverid
          drivername
          cashdescription
          reimbursement
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxMasterLogsVipfuelId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accidents {
        items {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      vehicle {
        id
        platecode
        plateno
        make
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        capacity
        departmentname
        status
        chasisno
        salikno
        salikpin
        fuelno
        vowner
        isarchived
        year
        model
        policyno
        startdate
        enddate
        company
        permittype
        allocationno
        drivername
        VehicleStatus
        ImagePath
        insuranceno
        registrationno
        scno
        scvendor
        cupdate
        jfield
        userinfo
        author
        editor
        fleettype
        allocationdriver
        expanses
        nextdates
        events
        Documents
        expiring
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDepartmentVehiclesId
        dxContractVehicleId
        owner
        __typename
      }
      driver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      allocation {
        id
        vehicleid
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        appointment {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        activity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        date
        drivername
        enddate
        dfleettype
        fromdriverID
        fromdrivername
        fromKM
        fromdate
        fromlocation
        driverID
        handoverdrivername
        handoverKM
        handoverdate
        handoverlocation
        distances
        reason
        allocatestatus
        pictures
        documents
        wfstatus
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverAllocationsId
        dxVehicleActivityAllocationsId
        dxVehicleAllocationsId
        dxAppointmentsAllocationsId
        owner
        __typename
      }
      vehicleactivity {
        id
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        nextcontracts {
          id
          renewaltype
          renewalno
          subtype
          issuedate
          expirydate
          vehicleid
          documents
          pictures
          documents2
          renewalstatus
          isappointmentrequire
          wfstatus
          period
          scvendor
          author
          editor
          cupdate
          jfield
          userinfo
          amount
          tax
          totalamount
          policyno
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        logs {
          nextToken
          __typename
        }
        recentevents {
          id
          vehicleid
          eventtype
          Date
          place
          description
          drivername
          appointmentstatus
          wanttoallocatedriver
          closeallocatedriver
          issccovered
          pictures
          documents
          cost
          invoiceno
          mileage
          auditby
          userprofiles
          accidenthappenedat
          accidenttype
          wfstatus
          author
          editor
          cupdate
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAppointmentsId
          dxVehicleAppointmentsId
          dxContractAppointmentsId
          dxMasterLogsAccidentsId
          owner
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        expanses
        nextdates
        events
        VehicleStatus
        ImagePath
        Documents
        expiring
        isarchived
        wfstatus
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverVehicleactivityId
        dxVehicleVehicleactivityId
        dxAppointmentsActivityId
        dxContractActivityId
        owner
        __typename
      }
      author
      reimbursement
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMasterlogsId
      dxVehicleActivityLogsId
      dxVehicleMasterlogsId
      dxAllocationsMasterlogsId
      owner
      __typename
    }
  }
`;
export const onCreateDxMileageReport = /* GraphQL */ `
  subscription OnCreateDxMileageReport(
    $filter: ModelSubscriptionDxMileageReportFilterInput
    $owner: String
  ) {
    onCreateDxMileageReport(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsMileagedrivershiftId
      owner
      __typename
    }
  }
`;
export const onUpdateDxMileageReport = /* GraphQL */ `
  subscription OnUpdateDxMileageReport(
    $filter: ModelSubscriptionDxMileageReportFilterInput
    $owner: String
  ) {
    onUpdateDxMileageReport(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsMileagedrivershiftId
      owner
      __typename
    }
  }
`;
export const onDeleteDxMileageReport = /* GraphQL */ `
  subscription OnDeleteDxMileageReport(
    $filter: ModelSubscriptionDxMileageReportFilterInput
    $owner: String
  ) {
    onDeleteDxMileageReport(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsMileagedrivershiftId
      owner
      __typename
    }
  }
`;
export const onCreateDxMileage = /* GraphQL */ `
  subscription OnCreateDxMileage(
    $filter: ModelSubscriptionDxMileageFilterInput
    $owner: String
  ) {
    onCreateDxMileage(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      sortstartdatetime
      tablename
      enddate
      duration
      avgspeed
      maxspeed
      mileagedriver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMileagedriverId
      dxdriverShiftsMileagereportId
      owner
      __typename
    }
  }
`;
export const onUpdateDxMileage = /* GraphQL */ `
  subscription OnUpdateDxMileage(
    $filter: ModelSubscriptionDxMileageFilterInput
    $owner: String
  ) {
    onUpdateDxMileage(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      sortstartdatetime
      tablename
      enddate
      duration
      avgspeed
      maxspeed
      mileagedriver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMileagedriverId
      dxdriverShiftsMileagereportId
      owner
      __typename
    }
  }
`;
export const onDeleteDxMileage = /* GraphQL */ `
  subscription OnDeleteDxMileage(
    $filter: ModelSubscriptionDxMileageFilterInput
    $owner: String
  ) {
    onDeleteDxMileage(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      sortstartdatetime
      tablename
      enddate
      duration
      avgspeed
      maxspeed
      mileagedriver {
        id
        fleettype
        name
        mobileno
        contactno
        departmentid
        businessunitid
        department {
          id
          fleettype
          name
          description
          department
          owner
          member
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDepartmentsId
          __typename
        }
        company {
          id
          fleettype
          name
          description
          department
          owner
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        vehicleactivity {
          nextToken
          __typename
        }
        allocations {
          nextToken
          __typename
        }
        masterlogs {
          nextToken
          __typename
        }
        mileagedriver {
          nextToken
          __typename
        }
        appointments {
          nextToken
          __typename
        }
        drivershifts {
          nextToken
          __typename
        }
        logreimbursement {
          nextToken
          __typename
        }
        emailid
        licenseno
        licensetype
        licensepicture
        startdate
        enddate
        jobrole
        firstname
        lastname
        uname
        isnew
        isarchived
        status
        departmentname
        businessunitname
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxCompanyDriversId
        dxDepartmentDriversId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxDriverMileagedriverId
      dxdriverShiftsMileagereportId
      owner
      __typename
    }
  }
`;
export const onCreateDxlogMileage = /* GraphQL */ `
  subscription OnCreateDxlogMileage(
    $filter: ModelSubscriptionDxlogMileageFilterInput
    $owner: String
  ) {
    onCreateDxlogMileage(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsDrivershiftId
      dxMasterLogsMileagesId
      owner
      __typename
    }
  }
`;
export const onUpdateDxlogMileage = /* GraphQL */ `
  subscription OnUpdateDxlogMileage(
    $filter: ModelSubscriptionDxlogMileageFilterInput
    $owner: String
  ) {
    onUpdateDxlogMileage(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsDrivershiftId
      dxMasterLogsMileagesId
      owner
      __typename
    }
  }
`;
export const onDeleteDxlogMileage = /* GraphQL */ `
  subscription OnDeleteDxlogMileage(
    $filter: ModelSubscriptionDxlogMileageFilterInput
    $owner: String
  ) {
    onDeleteDxlogMileage(filter: $filter, owner: $owner) {
      id
      vehicleid
      drivername
      date
      type
      startedtime
      endedtime
      inshifttime
      outshifttime
      totalshifttime
      transactiondate
      startKM
      endKM
      startAddress
      endAddress
      distance
      driverid
      description
      startdatetime
      enddatetime
      startdate
      enddate
      duration
      avgspeed
      maxspeed
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      drivershift {
        id
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        date
        day
        startdatetime
        enddatetime
        startKm
        endkm
        totalduration
        durationafterW
        Mileage
        MileageafterWH
        location
        shift {
          id
          shift
          starttime
          endtime
          startdatetime
          enddatetime
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        vehicleid
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        drivershift {
          nextToken
          __typename
        }
        mileagedrivershift {
          nextToken
          __typename
        }
        mileagereport {
          nextToken
          __typename
        }
        remarks
        jfield
        userinfo
        author
        editor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverDrivershiftsId
        dxAllocationsDrivershiftsId
        dxshiftsDrivershiftId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxdriverShiftsDrivershiftId
      dxMasterLogsMileagesId
      owner
      __typename
    }
  }
`;
export const onCreateDxLogFines = /* GraphQL */ `
  subscription OnCreateDxLogFines(
    $filter: ModelSubscriptionDxLogFinesFilterInput
    $owner: String
  ) {
    onCreateDxLogFines(filter: $filter, owner: $owner) {
      id
      date
      transactiondate
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      source
      finenumber
      blackpoints
      reason
      location
      type
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsFinesId
      owner
      __typename
    }
  }
`;
export const onUpdateDxLogFines = /* GraphQL */ `
  subscription OnUpdateDxLogFines(
    $filter: ModelSubscriptionDxLogFinesFilterInput
    $owner: String
  ) {
    onUpdateDxLogFines(filter: $filter, owner: $owner) {
      id
      date
      transactiondate
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      source
      finenumber
      blackpoints
      reason
      location
      type
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsFinesId
      owner
      __typename
    }
  }
`;
export const onDeleteDxLogFines = /* GraphQL */ `
  subscription OnDeleteDxLogFines(
    $filter: ModelSubscriptionDxLogFinesFilterInput
    $owner: String
  ) {
    onDeleteDxLogFines(filter: $filter, owner: $owner) {
      id
      date
      transactiondate
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      source
      finenumber
      blackpoints
      reason
      location
      type
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsFinesId
      owner
      __typename
    }
  }
`;
export const onCreateDxLogSalik = /* GraphQL */ `
  subscription OnCreateDxLogSalik(
    $filter: ModelSubscriptionDxLogSalikFilterInput
    $owner: String
  ) {
    onCreateDxLogSalik(filter: $filter, owner: $owner) {
      id
      transactionid
      date
      transactiondate
      tollgate
      direction
      tagnumber
      vehicleid
      driverid
      drivername
      amount
      reimbursement
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsSalikId
      owner
      __typename
    }
  }
`;
export const onUpdateDxLogSalik = /* GraphQL */ `
  subscription OnUpdateDxLogSalik(
    $filter: ModelSubscriptionDxLogSalikFilterInput
    $owner: String
  ) {
    onUpdateDxLogSalik(filter: $filter, owner: $owner) {
      id
      transactionid
      date
      transactiondate
      tollgate
      direction
      tagnumber
      vehicleid
      driverid
      drivername
      amount
      reimbursement
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsSalikId
      owner
      __typename
    }
  }
`;
export const onDeleteDxLogSalik = /* GraphQL */ `
  subscription OnDeleteDxLogSalik(
    $filter: ModelSubscriptionDxLogSalikFilterInput
    $owner: String
  ) {
    onDeleteDxLogSalik(filter: $filter, owner: $owner) {
      id
      transactionid
      date
      transactiondate
      tollgate
      direction
      tagnumber
      vehicleid
      driverid
      drivername
      amount
      reimbursement
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsSalikId
      owner
      __typename
    }
  }
`;
export const onCreateDxLogParking = /* GraphQL */ `
  subscription OnCreateDxLogParking(
    $filter: ModelSubscriptionDxLogParkingFilterInput
    $owner: String
  ) {
    onCreateDxLogParking(filter: $filter, owner: $owner) {
      id
      date
      transactiondate
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      author
      editor
      reimbursement
      company
      expirydate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsParkingsId
      owner
      __typename
    }
  }
`;
export const onUpdateDxLogParking = /* GraphQL */ `
  subscription OnUpdateDxLogParking(
    $filter: ModelSubscriptionDxLogParkingFilterInput
    $owner: String
  ) {
    onUpdateDxLogParking(filter: $filter, owner: $owner) {
      id
      date
      transactiondate
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      author
      editor
      reimbursement
      company
      expirydate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsParkingsId
      owner
      __typename
    }
  }
`;
export const onDeleteDxLogParking = /* GraphQL */ `
  subscription OnDeleteDxLogParking(
    $filter: ModelSubscriptionDxLogParkingFilterInput
    $owner: String
  ) {
    onDeleteDxLogParking(filter: $filter, owner: $owner) {
      id
      date
      transactiondate
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      vehicleid
      driverid
      drivername
      amount
      innovationfee
      totalamount
      author
      editor
      reimbursement
      company
      expirydate
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsParkingsId
      owner
      __typename
    }
  }
`;
export const onCreateDxLogVipfuel = /* GraphQL */ `
  subscription OnCreateDxLogVipfuel(
    $filter: ModelSubscriptionDxLogVipfuelFilterInput
    $owner: String
  ) {
    onCreateDxLogVipfuel(filter: $filter, owner: $owner) {
      id
      date
      transactiondate
      vehicleid
      rid
      unitprice
      volume
      total
      productname
      receiptno
      odometer
      idunitcode
      stationname
      stationcode
      fleetname
      groupname
      vehiclecode
      citycode
      costcenter
      vatrate
      vatamount
      actualamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      driverid
      drivername
      cashdescription
      reimbursement
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsVipfuelId
      owner
      __typename
    }
  }
`;
export const onUpdateDxLogVipfuel = /* GraphQL */ `
  subscription OnUpdateDxLogVipfuel(
    $filter: ModelSubscriptionDxLogVipfuelFilterInput
    $owner: String
  ) {
    onUpdateDxLogVipfuel(filter: $filter, owner: $owner) {
      id
      date
      transactiondate
      vehicleid
      rid
      unitprice
      volume
      total
      productname
      receiptno
      odometer
      idunitcode
      stationname
      stationcode
      fleetname
      groupname
      vehiclecode
      citycode
      costcenter
      vatrate
      vatamount
      actualamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      driverid
      drivername
      cashdescription
      reimbursement
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsVipfuelId
      owner
      __typename
    }
  }
`;
export const onDeleteDxLogVipfuel = /* GraphQL */ `
  subscription OnDeleteDxLogVipfuel(
    $filter: ModelSubscriptionDxLogVipfuelFilterInput
    $owner: String
  ) {
    onDeleteDxLogVipfuel(filter: $filter, owner: $owner) {
      id
      date
      transactiondate
      vehicleid
      rid
      unitprice
      volume
      total
      productname
      receiptno
      odometer
      idunitcode
      stationname
      stationcode
      fleetname
      groupname
      vehiclecode
      citycode
      costcenter
      vatrate
      vatamount
      actualamount
      log {
        id
        vehicleid
        drivername
        date
        Accidents
        Fine
        Salik
        Mileage
        Parking
        Vipfuel
        logreimbursement {
          nextToken
          __typename
        }
        mileages {
          nextToken
          __typename
        }
        fines {
          nextToken
          __typename
        }
        salik {
          nextToken
          __typename
        }
        parkings {
          nextToken
          __typename
        }
        vipfuel {
          nextToken
          __typename
        }
        accidents {
          nextToken
          __typename
        }
        vehicle {
          id
          platecode
          plateno
          make
          departmentid
          businessunitid
          capacity
          departmentname
          status
          chasisno
          salikno
          salikpin
          fuelno
          vowner
          isarchived
          year
          model
          policyno
          startdate
          enddate
          company
          permittype
          allocationno
          drivername
          VehicleStatus
          ImagePath
          insuranceno
          registrationno
          scno
          scvendor
          cupdate
          jfield
          userinfo
          author
          editor
          fleettype
          allocationdriver
          expanses
          nextdates
          events
          Documents
          expiring
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDepartmentVehiclesId
          dxContractVehicleId
          owner
          __typename
        }
        driver {
          id
          fleettype
          name
          mobileno
          contactno
          departmentid
          businessunitid
          emailid
          licenseno
          licensetype
          licensepicture
          startdate
          enddate
          jobrole
          firstname
          lastname
          uname
          isnew
          isarchived
          status
          departmentname
          businessunitname
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxCompanyDriversId
          dxDepartmentDriversId
          owner
          __typename
        }
        allocation {
          id
          vehicleid
          date
          drivername
          enddate
          dfleettype
          fromdriverID
          fromdrivername
          fromKM
          fromdate
          fromlocation
          driverID
          handoverdrivername
          handoverKM
          handoverdate
          handoverlocation
          distances
          reason
          allocatestatus
          pictures
          documents
          wfstatus
          jfield
          userinfo
          author
          editor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverAllocationsId
          dxVehicleActivityAllocationsId
          dxVehicleAllocationsId
          dxAppointmentsAllocationsId
          owner
          __typename
        }
        vehicleactivity {
          id
          expanses
          nextdates
          events
          VehicleStatus
          ImagePath
          Documents
          expiring
          isarchived
          wfstatus
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxDriverVehicleactivityId
          dxVehicleVehicleactivityId
          dxAppointmentsActivityId
          dxContractActivityId
          owner
          __typename
        }
        author
        reimbursement
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxDriverMasterlogsId
        dxVehicleActivityLogsId
        dxVehicleMasterlogsId
        dxAllocationsMasterlogsId
        owner
        __typename
      }
      driverid
      drivername
      cashdescription
      reimbursement
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxMasterLogsVipfuelId
      owner
      __typename
    }
  }
`;
export const onCreateDxNavigation = /* GraphQL */ `
  subscription OnCreateDxNavigation(
    $filter: ModelSubscriptionDxNavigationFilterInput
    $owner: String
  ) {
    onCreateDxNavigation(filter: $filter, owner: $owner) {
      id
      type
      item
      order
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxNavigation = /* GraphQL */ `
  subscription OnUpdateDxNavigation(
    $filter: ModelSubscriptionDxNavigationFilterInput
    $owner: String
  ) {
    onUpdateDxNavigation(filter: $filter, owner: $owner) {
      id
      type
      item
      order
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxNavigation = /* GraphQL */ `
  subscription OnDeleteDxNavigation(
    $filter: ModelSubscriptionDxNavigationFilterInput
    $owner: String
  ) {
    onDeleteDxNavigation(filter: $filter, owner: $owner) {
      id
      type
      item
      order
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateDxWorkflow = /* GraphQL */ `
  subscription OnCreateDxWorkflow(
    $filter: ModelSubscriptionDxWorkflowFilterInput
    $owner: String
  ) {
    onCreateDxWorkflow(filter: $filter, owner: $owner) {
      id
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      approvalitems {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      title
      description
      startdate
      duedate
      enddate
      resource
      resourceid
      resourceurl
      currentlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      nextlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      wfstatus
      wfield1
      wfield2
      wfield3
      wfield4
      wfield5
      wjson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsWorkflowId
      dxApprovalConfigWorkflowId
      owner
      __typename
    }
  }
`;
export const onUpdateDxWorkflow = /* GraphQL */ `
  subscription OnUpdateDxWorkflow(
    $filter: ModelSubscriptionDxWorkflowFilterInput
    $owner: String
  ) {
    onUpdateDxWorkflow(filter: $filter, owner: $owner) {
      id
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      approvalitems {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      title
      description
      startdate
      duedate
      enddate
      resource
      resourceid
      resourceurl
      currentlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      nextlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      wfstatus
      wfield1
      wfield2
      wfield3
      wfield4
      wfield5
      wjson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsWorkflowId
      dxApprovalConfigWorkflowId
      owner
      __typename
    }
  }
`;
export const onDeleteDxWorkflow = /* GraphQL */ `
  subscription OnDeleteDxWorkflow(
    $filter: ModelSubscriptionDxWorkflowFilterInput
    $owner: String
  ) {
    onDeleteDxWorkflow(filter: $filter, owner: $owner) {
      id
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      approvalitems {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      title
      description
      startdate
      duedate
      enddate
      resource
      resourceid
      resourceurl
      currentlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      nextlevel {
        id
        name
        title
        action
        approval {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acsjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsId
        owner
        __typename
      }
      wfstatus
      wfield1
      wfield2
      wfield3
      wfield4
      wfield5
      wjson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsWorkflowId
      dxApprovalConfigWorkflowId
      owner
      __typename
    }
  }
`;
export const onCreateDxApprovals = /* GraphQL */ `
  subscription OnCreateDxApprovals(
    $filter: ModelSubscriptionDxApprovalsFilterInput
    $owner: String
  ) {
    onCreateDxApprovals(filter: $filter, owner: $owner) {
      id
      name
      title
      description
      startdate
      duedate
      enddate
      assignedto
      level
      status
      resource
      resourceid
      resourceurl
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      afield1
      afield2
      afield3
      afield4
      afield5
      userprofiles
      ajson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalitemsId
      dxApprovalConfigApprovalId
      owner
      __typename
    }
  }
`;
export const onUpdateDxApprovals = /* GraphQL */ `
  subscription OnUpdateDxApprovals(
    $filter: ModelSubscriptionDxApprovalsFilterInput
    $owner: String
  ) {
    onUpdateDxApprovals(filter: $filter, owner: $owner) {
      id
      name
      title
      description
      startdate
      duedate
      enddate
      assignedto
      level
      status
      resource
      resourceid
      resourceurl
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      afield1
      afield2
      afield3
      afield4
      afield5
      userprofiles
      ajson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalitemsId
      dxApprovalConfigApprovalId
      owner
      __typename
    }
  }
`;
export const onDeleteDxApprovals = /* GraphQL */ `
  subscription OnDeleteDxApprovals(
    $filter: ModelSubscriptionDxApprovalsFilterInput
    $owner: String
  ) {
    onDeleteDxApprovals(filter: $filter, owner: $owner) {
      id
      name
      title
      description
      startdate
      duedate
      enddate
      assignedto
      level
      status
      resource
      resourceid
      resourceurl
      approvalhistory {
        items {
          id
          changes
          status
          activity
          respondedby
          date
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalhistoryId
          dxApprovalsApprovalhistoryId
          owner
          __typename
        }
        nextToken
        __typename
      }
      approvalconfig {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      afield1
      afield2
      afield3
      afield4
      afield5
      userprofiles
      ajson
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalitemsId
      dxApprovalConfigApprovalId
      owner
      __typename
    }
  }
`;
export const onCreateDxApprovalsHistory = /* GraphQL */ `
  subscription OnCreateDxApprovalsHistory(
    $filter: ModelSubscriptionDxApprovalsHistoryFilterInput
    $owner: String
  ) {
    onCreateDxApprovalsHistory(filter: $filter, owner: $owner) {
      id
      changes
      status
      activity
      respondedby
      date
      approval {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        afield1
        afield2
        afield3
        afield4
        afield5
        userprofiles
        ajson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalhistoryId
      dxApprovalsApprovalhistoryId
      owner
      __typename
    }
  }
`;
export const onUpdateDxApprovalsHistory = /* GraphQL */ `
  subscription OnUpdateDxApprovalsHistory(
    $filter: ModelSubscriptionDxApprovalsHistoryFilterInput
    $owner: String
  ) {
    onUpdateDxApprovalsHistory(filter: $filter, owner: $owner) {
      id
      changes
      status
      activity
      respondedby
      date
      approval {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        afield1
        afield2
        afield3
        afield4
        afield5
        userprofiles
        ajson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalhistoryId
      dxApprovalsApprovalhistoryId
      owner
      __typename
    }
  }
`;
export const onDeleteDxApprovalsHistory = /* GraphQL */ `
  subscription OnDeleteDxApprovalsHistory(
    $filter: ModelSubscriptionDxApprovalsHistoryFilterInput
    $owner: String
  ) {
    onDeleteDxApprovalsHistory(filter: $filter, owner: $owner) {
      id
      changes
      status
      activity
      respondedby
      date
      approval {
        id
        name
        title
        description
        startdate
        duedate
        enddate
        assignedto
        level
        status
        resource
        resourceid
        resourceurl
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        workflow {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        afield1
        afield2
        afield3
        afield4
        afield5
        userprofiles
        ajson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxWorkflowApprovalitemsId
        dxApprovalConfigApprovalId
        owner
        __typename
      }
      workflow {
        id
        approvalhistory {
          nextToken
          __typename
        }
        approvalconfig {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        approvalitems {
          nextToken
          __typename
        }
        name
        title
        description
        startdate
        duedate
        enddate
        resource
        resourceid
        resourceurl
        currentlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextlevel {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        wfstatus
        wfield1
        wfield2
        wfield3
        wfield4
        wfield5
        wjson
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dxApprovalConfigStepsWorkflowId
        dxApprovalConfigWorkflowId
        owner
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxWorkflowApprovalhistoryId
      dxApprovalsApprovalhistoryId
      owner
      __typename
    }
  }
`;
export const onCreateDxApprovalConfigSteps = /* GraphQL */ `
  subscription OnCreateDxApprovalConfigSteps(
    $filter: ModelSubscriptionDxApprovalConfigStepsFilterInput
    $owner: String
  ) {
    onCreateDxApprovalConfigSteps(filter: $filter, owner: $owner) {
      id
      name
      title
      action
      approval {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acsjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsId
      owner
      __typename
    }
  }
`;
export const onUpdateDxApprovalConfigSteps = /* GraphQL */ `
  subscription OnUpdateDxApprovalConfigSteps(
    $filter: ModelSubscriptionDxApprovalConfigStepsFilterInput
    $owner: String
  ) {
    onUpdateDxApprovalConfigSteps(filter: $filter, owner: $owner) {
      id
      name
      title
      action
      approval {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acsjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsId
      owner
      __typename
    }
  }
`;
export const onDeleteDxApprovalConfigSteps = /* GraphQL */ `
  subscription OnDeleteDxApprovalConfigSteps(
    $filter: ModelSubscriptionDxApprovalConfigStepsFilterInput
    $owner: String
  ) {
    onDeleteDxApprovalConfigSteps(filter: $filter, owner: $owner) {
      id
      name
      title
      action
      approval {
        id
        name
        title
        action
        approval {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        field1
        field2
        field3
        field4
        field5
        acjson
        workflow {
          nextToken
          __typename
        }
        author
        editor
        jfield
        userinfo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acsjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dxApprovalConfigStepsId
      owner
      __typename
    }
  }
`;
export const onCreateDxApprovalConfig = /* GraphQL */ `
  subscription OnCreateDxApprovalConfig(
    $filter: ModelSubscriptionDxApprovalConfigFilterInput
    $owner: String
  ) {
    onCreateDxApprovalConfig(filter: $filter, owner: $owner) {
      id
      name
      title
      action
      approval {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxApprovalConfig = /* GraphQL */ `
  subscription OnUpdateDxApprovalConfig(
    $filter: ModelSubscriptionDxApprovalConfigFilterInput
    $owner: String
  ) {
    onUpdateDxApprovalConfig(filter: $filter, owner: $owner) {
      id
      name
      title
      action
      approval {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxApprovalConfig = /* GraphQL */ `
  subscription OnDeleteDxApprovalConfig(
    $filter: ModelSubscriptionDxApprovalConfigFilterInput
    $owner: String
  ) {
    onDeleteDxApprovalConfig(filter: $filter, owner: $owner) {
      id
      name
      title
      action
      approval {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          assignedto
          level
          status
          resource
          resourceid
          resourceurl
          afield1
          afield2
          afield3
          afield4
          afield5
          userprofiles
          ajson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxWorkflowApprovalitemsId
          dxApprovalConfigApprovalId
          owner
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          name
          title
          action
          field1
          field2
          field3
          field4
          field5
          acsjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      field1
      field2
      field3
      field4
      field5
      acjson
      workflow {
        items {
          id
          name
          title
          description
          startdate
          duedate
          enddate
          resource
          resourceid
          resourceurl
          wfstatus
          wfield1
          wfield2
          wfield3
          wfield4
          wfield5
          wjson
          author
          editor
          jfield
          userinfo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dxApprovalConfigStepsWorkflowId
          dxApprovalConfigWorkflowId
          owner
          __typename
        }
        nextToken
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateDxDocument = /* GraphQL */ `
  subscription OnCreateDxDocument(
    $filter: ModelSubscriptionDxDocumentFilterInput
    $owner: String
  ) {
    onCreateDxDocument(filter: $filter, owner: $owner) {
      id
      name
      resource
      resourceid
      presourceid
      presource
      field1
      field2
      field3
      s3object {
        bucket
        region
        key
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxDocument = /* GraphQL */ `
  subscription OnUpdateDxDocument(
    $filter: ModelSubscriptionDxDocumentFilterInput
    $owner: String
  ) {
    onUpdateDxDocument(filter: $filter, owner: $owner) {
      id
      name
      resource
      resourceid
      presourceid
      presource
      field1
      field2
      field3
      s3object {
        bucket
        region
        key
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxDocument = /* GraphQL */ `
  subscription OnDeleteDxDocument(
    $filter: ModelSubscriptionDxDocumentFilterInput
    $owner: String
  ) {
    onDeleteDxDocument(filter: $filter, owner: $owner) {
      id
      name
      resource
      resourceid
      presourceid
      presource
      field1
      field2
      field3
      s3object {
        bucket
        region
        key
        __typename
      }
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateDxTableSettings = /* GraphQL */ `
  subscription OnCreateDxTableSettings(
    $filter: ModelSubscriptionDxTableSettingsFilterInput
    $owner: String
  ) {
    onCreateDxTableSettings(filter: $filter, owner: $owner) {
      id
      application
      table
      column
      expression
      isactive
      field1
      field2
      field3
      field4
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDxTableSettings = /* GraphQL */ `
  subscription OnUpdateDxTableSettings(
    $filter: ModelSubscriptionDxTableSettingsFilterInput
    $owner: String
  ) {
    onUpdateDxTableSettings(filter: $filter, owner: $owner) {
      id
      application
      table
      column
      expression
      isactive
      field1
      field2
      field3
      field4
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDxTableSettings = /* GraphQL */ `
  subscription OnDeleteDxTableSettings(
    $filter: ModelSubscriptionDxTableSettingsFilterInput
    $owner: String
  ) {
    onDeleteDxTableSettings(filter: $filter, owner: $owner) {
      id
      application
      table
      column
      expression
      isactive
      field1
      field2
      field3
      field4
      author
      editor
      jfield
      userinfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
