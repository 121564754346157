import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { API } from 'aws-amplify';
import { listDxDepartments, listDxCompanies } from '../graphql/queries';
import { Button as AwsButton } from '@aws-amplify/ui-react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import Documents from '../Pages/Import/Documents';
import dxService from '../Services/dxService';
let driveritems: any = [];
function ImportTempData() {
    const navigate = useNavigate();
    const toast = useRef<any>(null);
    const [driver, setDriver] = useState([]);
    const [jsonData, setJsonData] = useState([]);
    const [savingData, setSavingData] = useState(false);
    const [charges, setCharges] = useState<any>([]);
    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [Department, setDepartment] = useState([]);
    const [Company, setCompany] = useState([]);
    const [Vehicle, setVehicle] = useState([]);
    const [VehicleActivity, setVehicleActivity] = useState([]);
    useEffect(() => {
        setErrorMessage([]);
        getDriverDetails();
        getDepartmentDetails();
        getVehicleDetails();
        getVehicleActivitiesDetails();

        console.log(charges);
        if (charges.name === 'Vehicle') addvehicleData(jsonData);
        if (charges.name === 'Driver') addDriverData(jsonData);
        // setDriver(data);
    }, [charges]);
    const addvehicleData = async (data: any) => {
        console.log(data);

        if (jsonData) {
            jsonData.map(async (data: any) => {
                // console.log(dept, dept.name);
                let filtervehicle: any = Vehicle.filter((item: any) => item.id === data.FullPlate);
                let filtervehicleactivity: any = VehicleActivity.filter((item: any) => item.id === data.FullPlate);
                // console.log(filtervehicleactivity);
                if (filtervehicle.length > 0) {
                    let vehicleactivityObj: any;
                    let vehicleObj: any;
                    // console.log('update', filtervehicle[0]);
                    let filter = {
                        id: {
                            eq: filtervehicle[0].id
                        }
                    };
                    let vehicle = await dxService.listitems('vehicle', '*', filter);
                    console.log(vehicle[0]._version);
                    for (let i = 0; i < Department.length; i++) {
                        let dept: any = Department[i];
                        if (dept.name === data.Department) {
                            vehicleObj = {
                                id: data.FullPlate !== undefined ? data.FullPlate : '',
                                platecode: data.PlateCode !== undefined ? data.PlateCode : '',
                                plateno: data.PlateNumber !== undefined ? data.PlateNumber : '',
                                make: data.Make !== undefined ? data.Make : '',
                                model: data.Model !== undefined ? data.Model : '',
                                capacity: data.PassengerCapacity !== undefined ? data.PassengerCapacity : '',
                                chasisno: data.Chassisno !== undefined ? data.Chassisno : '',
                                salikno: data.Saliknumber !== undefined ? data.Saliknumber : '',
                                salikpin: data.Salikpin !== undefined ? data.Salikpin : '',
                                fuelno: data.Fuelcardnumber !== undefined ? data.Fuelcardnumber : '',
                                vowner: data.Vehicleowner !== undefined ? data.Vehicleowner : '',
                                year: data.VehicleYear !== undefined ? data.VehicleYear : '',
                                departmentname: data.Department !== undefined ? data.Department : '',
                                status: data.Status !== undefined ? data.Status : '',
                                insuranceno: data.Insurancenumber !== undefined ? data.Insurancenumber : '',
                                registrationno: data.Registrationnumber !== undefined ? data.Registrationnumber : '',
                                scno: data.Servicecontractnumber !== undefined ? data.Servicecontractnumber : '',
                                scvendor: data.ServicecontractGarrage !== undefined ? data.ServicecontractGarrage : '',
                                dxDepartmentVehiclesId: data.Department === dept.name ? dept.id : '',
                                _version: vehicle[0]._version
                            };
                            if (filtervehicleactivity.length > 0) {
                                vehicleactivityObj = {
                                    id: data.FullPlate !== undefined ? data.FullPlate : '',
                                    // fleettype: 'vehicle#activity',
                                    VehicleStatus: data.Status !== undefined ? data.Status : '',
                                    dxVehicleVehicleactivityId: data.FullPlate !== undefined ? data.FullPlate : '',
                                    _version: filtervehicleactivity[0]._version
                                };
                            }

                            console.log(vehicleObj);
                            console.log(vehicleactivityObj);

                            // console.log(vehicleactivityObj);
                            let updatevehicle = await dxService
                                .updateitem('vehicle', vehicleObj, false)
                                .then(async (res: any) => {
                                    let createvehicleactivity = await dxService.updateitem('vehicleactivity', vehicleactivityObj, false);
                                })
                                .catch((res: any) => {
                                    console.log(res);
                                });
                        }
                    }
                } else {
                    for (let i = 0; i < Department.length; i++) {
                        let dept: any = Department[i];
                        if (dept.name === data.Department) {
                            let vehicleObj = {
                                id: data.FullPlate !== undefined ? data.FullPlate : '',
                                platecode: data.PlateCode !== undefined ? data.PlateCode : '',
                                plateno: data.PlateNumber !== undefined ? data.PlateNumber : '',
                                make: data.Make !== undefined ? data.Make : '',
                                model: data.Model !== undefined ? data.Model : '',
                                capacity: data.PassengerCapacity !== undefined ? data.PassengerCapacity : '',
                                chasisno: data.Chassisno !== undefined ? data.Chassisno : '',
                                salikno: data.Saliknumber !== undefined ? data.Saliknumber : '',
                                salikpin: data.Salikpin !== undefined ? data.Salikpin : '',
                                fuelno: data.Fuelcardnumber !== undefined ? data.Fuelcardnumber : '',
                                vowner: data.Vehicleowner !== undefined ? data.Vehicleowner : '',
                                year: data.VehicleYear !== undefined ? data.VehicleYear : '',
                                departmentname: data.Department !== undefined ? data.Department : '',
                                status: data.Status !== undefined ? data.Status : '',
                                insuranceno: data.Insurancenumber !== undefined ? data.Insurancenumber : '',
                                registrationno: data.Registrationnumber !== undefined ? data.Registrationnumber : '',
                                scno: data.Servicecontractnumber !== undefined ? data.Servicecontractnumber : '',
                                scvendor: data.ServicecontractGarrage !== undefined ? data.ServicecontractGarrage : '',
                                dxDepartmentVehiclesId: data.Department == dept.name ? dept.id : ''
                            };

                            let vehicleactivityObj = {
                                id: data.FullPlate !== undefined ? data.FullPlate : '',
                                // fleettype: 'vehicle#activity',
                                VehicleStatus: data.Status !== undefined ? data.Status : '',
                                dxVehicleVehicleactivityId: data.FullPlate !== undefined ? data.FullPlate : ''
                            };
                            console.log(vehicleObj);
                            console.log(vehicleactivityObj);
                            let createvehicle = await dxService
                                .createitem('vehicle', vehicleObj, false)
                                .then(async (res: any) => {
                                    let createvehicleactivity = await dxService.createitem('vehicleactivity', vehicleactivityObj, false);
                                })
                                .catch((res: any) => {
                                    console.log(res);
                                });
                        }
                    }
                }
            });
        }
    };
    const addDriverData = async (data: any) => {
        console.log(data);

        //dxCompanyDriversId
        //dxDepartmentDriversId
        if (jsonData) {
            jsonData.map(async (data: any) => {
                let filterdriver: any = driver.filter((item: any) => item.name === data.name);
                console.log(filterdriver);
                if (filterdriver.length > 0) {
                    console.log('update');
                    let filter = {
                        name: {
                            eq: filterdriver[0].name
                        }
                    };
                    let driver = await dxService.listitems('driver', '*', filter);
                    console.log(driver[0]._version);
                    for (let i = 0; i < Company.length; i++) {
                        // console.log('create comp');
                        let comp: any = Company[i];
                        // console.log(comp.name, data.businessunit);
                        if (comp.name === data.businessunit) {
                            for (let i = 0; i < Department.length; i++) {
                                // console.log('create dept');
                                let dept: any = Department[i];

                                console.log(dept.name, data.department);
                                if ((comp.name === data.businessunit && dept.name) === data.department) {
                                    // console.log('create if');
                                    let driverObj = {
                                        id: driver[0].id,
                                        name: data.name,
                                        contactno: data.contactnumber,
                                        emailid: data.emailid,
                                        dxCompanyDriversId: comp.name === data.businessunit ? comp.id : '',
                                        dxDepartmentDriversId: dept.name === data.department ? dept.id : '',
                                        licenseno: data.licensenumber,
                                        licensetype: data.licensetype,
                                        jobrole: data.jobrole,
                                        startdate: new Date(data.startdate),
                                        enddate: new Date(data.enddate),
                                        _version: driver[0]._version
                                    };
                                    console.log(driverObj);
                                    driveritems.push(driverObj);
                                }
                            }
                        }
                    }
                    console.log(driveritems);

                    const uniqueArray = driveritems.filter((obj: any, index: any, self: any) => index === self.findIndex((t: any) => t.name === obj.name));
                    console.log(uniqueArray);
                    uniqueArray.map(async (item: any) => {
                        let createvehicle = await dxService.updateitem('driver', item, false);
                    });
                    driveritems = [];
                } else {
                    console.log('create');
                    for (let i = 0; i < Company.length; i++) {
                        // console.log('create comp');
                        let comp: any = Company[i];
                        // console.log(comp.name, data.businessunit);
                        if (comp.name === data.businessunit) {
                            for (let i = 0; i < Department.length; i++) {
                                // console.log('create dept');
                                let dept: any = Department[i];

                                console.log(dept.name, data.department);
                                if ((comp.name == data.businessunit && dept.name) === data.department) {
                                    // console.log('create if');
                                    let driverObj = {
                                        name: data.name,
                                        contactno: data.contactnumber,
                                        emailid: data.emailid,
                                        dxCompanyDriversId: comp.name === data.businessunit ? comp.id : '',
                                        dxDepartmentDriversId: dept.name === data.department ? dept.id : '',
                                        licenseno: data.licensenumber,
                                        licensetype: data.licensetype,
                                        jobrole: data.jobrole,
                                        startdate: new Date(data.startdate),
                                        enddate: new Date(data.enddate)
                                    };
                                    console.log(driverObj);
                                    driveritems.push(driverObj);
                                }
                            }
                        }
                    }

                    const uniqueArray = driveritems.filter((obj: any, index: any, self: any) => index === self.findIndex((t: any) => t.name === obj.name));
                    console.log(uniqueArray);
                    uniqueArray.map(async (item: any) => {
                        let createvehicle = await dxService.createitem('driver', item, false);
                    });
                    driveritems = [];
                    // items = [];
                    // items = 0;
                }
            });
        }
    };
    const getDepartmentDetails = async () => {
        let GetResponse: any = await API.graphql({ query: listDxDepartments, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        let res = GetResponse.data.listDxDepartments.items;
        console.log(res);
        setDepartment(res);
        let GetCompany: any = await API.graphql({ query: listDxCompanies, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        let rescompany = GetCompany.data.listDxCompanies.items;
        setCompany(rescompany);
    };
    const getVehicleDetails = async () => {
        let vehicle = await dxService.listitems('vehicle', '*');
        console.log(vehicle);
        setVehicle(vehicle);
    };
    const getVehicleActivitiesDetails = async () => {
        let vehicleactivity = await dxService.listitems('vehicleactivity', '*');
        console.log(vehicleactivity);
        setVehicleActivity(vehicleactivity);
    };
    const getDriverDetails = async () => {
        let GetDrivers = await dxService.listitems('driver', '*');
        // let GetResponse: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        // let res = GetResponse.data.listDxAllocations.items;
        console.log(GetDrivers);

        setDriver(GetDrivers);
    };
    // console.log(driver);
    console.log(jsonData);

    const checkNaughtyDriver = () => {
        setErrorMessage([]);
        if (charges.name === 'Vehicle') addvehicleData(jsonData);
        if (charges.name === 'Driver') addDriverData(jsonData);
        setSavingData(false);
        // setDriver([]);
        setJsonData([]);
        setCharges([]);
    };

    return (
        <div className="col-12 md:col-12">
            <Toast ref={toast}></Toast>
            <h4>Import</h4>
            <div className="card p-fluid">
                <div className="field">
                    <div className="col-12 sm:col-8 md:col-7 grid">
                        <div className="col-12 md:col-2 mt-2">
                            <label htmlFor="">Select</label>
                        </div>
                        <div className="col-12 md:col-10">
                            <Dropdown
                                options={[
                                    { label: 'Vehicle', name: 'Vehicle' },
                                    { label: 'Driver', name: 'Driver' }
                                ]}
                                value={charges}
                                onChange={(e) => setCharges(e.target.value)}
                                optionLabel="label"
                                placeholder="Select an option"
                                filter
                            />
                        </div>
                    </div>
                </div>

                {charges.name !== undefined && (
                    <div className="field">
                        <div className="col-12 sm:col-8 md:col-7 grid">
                            <div className="col-12 md:col-2 mt-2">
                                <label htmlFor="">Upload File</label>
                            </div>
                            <div className="col-12 md:col-10">
                                <Documents setJsonData={setJsonData} />
                                {/* <FileUpload accept=".xlsx, .xls*" auto={true} customUpload={true} uploadHandler={handleFileUpload} /> */}
                            </div>
                        </div>
                    </div>
                )}
                <div className="field col-5 grid md:col-3">
                    <AwsButton
                        className=" mr-2"
                        disabled={charges.name === undefined || jsonData.length === 0 ? true : false}
                        onClick={async () => {
                            checkNaughtyDriver();
                        }}
                    >
                        {savingData === false ? 'Submit' : 'Saving'}
                    </AwsButton>
                    <AwsButton
                        style={{
                            background: '#d32f2f',
                            color: '#fff',
                            border: 'none'
                        }}
                        onClick={async () => {
                            navigate('/');
                        }}
                    >
                        Cancel
                    </AwsButton>
                </div>
                <div className="grid col-12 md:col-12">
                    <div className="col-12 md:col-12" style={{ color: '#256029' }}>
                        {/* <pre>{showData == true && JSON.stringify(data, null, 2)}</pre> */}
                        <br />
                        {errorMessage.map((tem: any) => {
                            return <div>{tem}</div>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImportTempData;
