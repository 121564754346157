import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import "./../Reports/Reports.css";
import dxService from "../../Services/dxService";
import { CSVLink } from "react-csv";
import {
  convertdate,
  getVehicleoptions,
  isotolocaledatetime,
  localetoisodatetime,
} from "../../utils/helper";
import { useEffect, useState, memo } from "react";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { useLocation } from "react-router-dom";
import { getTypeColors } from "../../utils/color";
const currentDate = new Date();

// Get the month and year of the previous month
const previousMonth = currentDate.getMonth() - 1;
const previousYear = currentDate.getFullYear();

// Create a new Date object for the previous month's start date
const previousMonthStartDate = new Date(previousYear, previousMonth, 1);

// Create a new Date object for the current month's start date
const currentMonthStartDate = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);

// Set the previous month's end date to the day before the current month's start date
const previousMonthEndDate = new Date(currentMonthStartDate.getTime() - 1);

// Format the start and end dates as desired
const previousMonthStartDateFormatted = new Date(previousMonthStartDate);
const previousMonthEndDateFormatted = new Date(previousMonthEndDate);
previousMonthStartDateFormatted.setUTCHours(0, 0, 0, 0);
previousMonthEndDateFormatted.setUTCHours(0, 0, 0, 0);
// console.log(
//   previousMonthStartDateFormatted.toISOString(),
//   previousMonthEndDateFormatted.toISOString()
// );

const CashReimburse = () => {
  const colors = getTypeColors("light");
  const [Reports, setReports] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [SelectedDate, setSelectedDate] = useState<any>([]);
  const [filtervehicleid, setfiltervehicleid] = useState<any>("");
  const [vehicleid, setvehicleid] = useState<any>("");
  const [startDateFormat, setStartDateFormat] = useState<string>("");
  const [endDateFormat, setEndDateFormat] = useState<string>("");
  const [formatdata, setformatdata] = useState<any>([]);
  const [oldformatdata, setoldformdata] = useState<any>([]);
  const [totalfuel, settotalfuel] = useState<any>(0);
  const [totalsaik, settotalsalik] = useState<any>(0);
  const [totalparking, settotalparking] = useState<any>(0);
  const [totalmaintenance, settotalmaintenance] = useState<any>(0);
  const [totalrenewals, settotalrenewals] = useState<any>(0);
  const [totalothers, settotalothers] = useState<any>(0);
  const [exportType, setExportType] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [salikexpandedRows, setSalikExpandedRows] = useState<any>([]);
  const [transformedData, setTransformedData] = useState<any>([]);
  const [selectView, setSelectView] = useState<any>("Summary");
  const [vehicles, setVehicles] = useState<any>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<any>("");
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  var sdate: any = urlParams.has("sdate") ? urlParams.get("sdate") : "";
  var edate: any = urlParams.has("edate") ? urlParams.get("edate") : "";
  var view: any = urlParams.has("view") ? urlParams.get("view") : "";
  var vid: any = urlParams.has("vid") ? urlParams.get("vid") : "";

  // Get the params value and set it to the state
  useEffect(() => {
    const getParamsDetails = () => {
      // Check if 'view' parameter is provided and set the state accordingly
      if (view !== "") {
        setSelectView(view);
      } else {
        setSelectView("Summary");
      }

      // Check if 'vid' parameter is provided and set the selected vehicle state
      if (vid !== "") {
        setSelectedVehicle(vid);
      }

      // Check if 'sdate' parameter is provided and set the selected date range state
      if (sdate !== "") {
        setTimeout(() => {
          // Retrieve 'sdate' and 'edate' from URL params, or set them as empty strings
          sdate = urlParams.has("sdate") ? urlParams.get("sdate") : "";
          edate = urlParams.has("edate") ? urlParams.get("edate") : "";

          // Convert the retrieved dates to Date objects
          let startDate = new Date(sdate);
          let endDate = edate !== "" ? new Date(edate) : startDate;

          // Set the selected date range state
          setSelectedDate([startDate, endDate]);
        }, 1000);
      } else {
        // If 'sdate' is not provided, set the default date range
        let todate = new Date();
        let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
        let lastfirstdate = new Date(
          todate.getFullYear(),
          todate.getMonth() - 1,
          1
        );
        setSelectedDate([lastfirstdate, lastenddate]);
      }
    };

    // Call the function to retrieve and set the parameter details
    getParamsDetails();
    getVehicle();
  }, []);

  // Fetch vehicle options and set the state
  const getVehicle = () => {
    getVehicleoptions().then((options) => {
      setVehicles(options);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getData();
    };

    // Fetch data when SelectedDate or selectedVehicle changes
    fetchData();

    // Update the URL with the current parameters
    updateURL(selectView, SelectedDate[0], SelectedDate[1], selectedVehicle);
  }, [SelectedDate, selectedVehicle]);

  const getData = async (nextToken?: any) => {
    // let a1 = isotolocaledatetime(SelectedDate);
    // let filter1 ="";
    // if(a1 != null){
    //     let a2 = new Date(a1);
    //     filter1 = moment(a2).format("yyyy-MM-");
    // }

    let tempData: any[] = [];
    let ac = `id vehicleid vehicleid date Accidents Parking Salik Mileage Parking Vipfuel driver vehicle`;
    let variables: any = {};
    // id:{
    //     contains:"${filter1}"
    // },
    let cashreimbursecolumns = `id fleettype name description _deleted   
                            vehicles${
                              selectedVehicle
                                ? `(filter: { 
                                            id: {
                                                eq: "${selectedVehicle}"
                                            }
                                        })`
                                : ""
                            }{
                                items{
                                    id
                                    _deleted
                                     logreimbursement(filter: { 
                                     
                                        date: {
                                            ge: "${localetoisodatetime(
                                              SelectedDate.length > 0
                                                ? SelectedDate[0]
                                                : previousMonthStartDateFormatted.toISOString()
                                            )}",
                                            le: "${localetoisodatetime(
                                              SelectedDate.length > 0 &&
                                                SelectedDate[1] !== null
                                                ? SelectedDate[1]
                                                : previousMonthEndDateFormatted.toISOString()
                                            )}"
                                        }
                                           
                                    }, sortDirection: DESC, limit: 200000) {
                                                items {
                                                  id
                                                  date
                                                  transactiontype
                                                  invoiceno
                                                  paidto
                                                  Vendorname
                                                  description
                                                  remarks
                                                  amount
                                                  expensetype
                                                  isreimbursed
                                                  reimbursedamount
                                                }
                                                nextToken
                                              }
                               
                                }            
                            }`;
    // console.log(cashreimbursecolumns);

    let cr = await dxService.listitems(
      "department",
      cashreimbursecolumns,
      {},
      false
    );
    // console.log(cr);

    let c = cr;

    let a: any = [];
    if (selectedVehicle) {
      a = cr.filter((department: any) => department.vehicles.items.length > 0);
    } else {
      a = c;
    }
    // console.log(cr);
    let totalfuel = 0;
    let totalsalik = 0;
    let totalparking = 0;
    let totalmaintenance = 0;
    let totalrenewals = 0;
    let totalothers = 0;
    let allvalues: {
      department: any;
      vehicles: any;
      salik: number;
      fuel: number;
      parking: number;
      maintenance: number;
      renewals: number;
      others: number;
    }[] = [];
    setformatdata(a);
    // console.log(a);
    a.forEach((dept: any) => {
      let dsalik = 0;
      let dfuel = 0;
      let dparking = 0;
      let dmaintenance = 0;
      let drenewals = 0;
      let dothers = 0;
      let vehicleItems = dept.vehicles.items;
      // console.log(vehicleItems);
      // Filter out deleted vehicles and calculate expense totals for each vehicle
      vehicleItems
        .filter((del: any) => del._deleted !== true)
        .forEach((vehicle: any) => {
          let masterlogs = vehicle.logreimbursement.items;
          // Filter out deleted masterlogs and calculate expense totals for each masterlog
          // console.log(masterlogs);
          if (masterlogs.length > 0) {
            // console.log(masterlogs);
            masterlogs.forEach((b: any) => {
              if (b.expensetype === "Fuel") {
                dfuel += b.amount ? parseFloat(b.amount) : 0;
              } else if (b.expensetype === "Parking") {
                dparking += b.amount ? parseFloat(b.amount) : 0;
              } else if (b.expensetype === "Salik") {
                dsalik += b.amount ? parseFloat(b.amount) : 0;
              } else if (b.expensetype === "Maintenance") {
                dmaintenance += b.amount ? parseFloat(b.amount) : 0;
              } else if (b.expensetype === "Renewals") {
                drenewals += b.amount ? parseFloat(b.amount) : 0;
              } else {
                dothers += b.amount ? parseFloat(b.amount) : 0;
              }
            });
          }
          // masterlogs
          //   .filter((del: any) => del._deleted !== true)
          //   .forEach((masterlog: any) => {
          //     if (masterlog.reimbursement != null) {
          //       masterlog.logreimbursement.items.forEach((b: any) => {
          //         if (b.expensetype === "Fuel") {
          //           dfuel += b.amount ? parseFloat(b.amount) : 0;
          //         } else if (b.expensetype === "Parking") {
          //           dparking += b.amount ? parseFloat(b.amount) : 0;
          //         } else if (b.expensetype === "Salik") {
          //           dsalik += b.amount ? parseFloat(b.amount) : 0;
          //         } else if (b.expensetype === "Maintenance") {
          //           dmaintenance += b.amount ? parseFloat(b.amount) : 0;
          //         } else if (b.expensetype === "Renewals") {
          //           drenewals += b.amount ? parseFloat(b.amount) : 0;
          //         } else {
          //           dothers += b.amount ? parseFloat(b.amount) : 0;
          //         }
          //       });
          //     }
          //   });
        });

      // Accumulate the expense totals for each department
      totalfuel += dfuel;
      totalsalik += dsalik;
      totalparking += dparking;
      totalmaintenance += dmaintenance;
      totalrenewals += drenewals;
      totalothers += dothers;

      // Push the department's expense details to the allvalues array
      allvalues.push({
        department: dept.name,
        vehicles: vehicleItems.length,
        salik: dsalik,
        fuel: dfuel,
        parking: dparking,
        maintenance: dmaintenance,
        renewals: drenewals,
        others: dothers,
      });
      // console.log(allvalues);
    });
    // console.log(allvalues);
    // console.log(allvalues.length);
    // Set the total expense values to their respective states
    settotalfuel(totalfuel);
    settotalsalik(totalsalik);
    settotalparking(totalparking);
    settotalmaintenance(totalmaintenance);
    settotalrenewals(totalrenewals);
    settotalothers(totalothers);

    // Set the allvalues array to the oldformdata state
    setoldformdata(allvalues);

    //getting the data for showing all details
    a.forEach((department: any) => {
      department.vehicles.items.forEach((vehicle: any) => {
        const implog = vehicle.logreimbursement.items.filter(
          (log: any) => Object.keys(log).length > 0
        );

        let totalFuelAmount = 0;
        let totalSalikAmount = 0;
        let totalParkingAmount = 0;
        let totalMaintenanceAmount = 0;
        let totalRenewalsAmount = 0;
        let totalOthersAmount = 0;
        if (implog.length > 0) {
          const fuelLogs = implog.filter(
            (log: any) => log.expensetype === "Fuel"
          );
          const salikLogs = implog.filter(
            (log: any) => log.expensetype === "Salik"
          );
          const parkinglogs = implog.filter(
            (log: any) => log.expensetype === "Parking"
          );
          const maintenancelogs = implog.filter(
            (log: any) => log.expensetype === "Maintenance"
          );
          const renewalslogs = implog.filter(
            (log: any) => log.expensetype === "Renewals"
          );
          const otherslogs = implog.filter(
            (log: any) =>
              !["Fuel", "Salik", "Parking", "Maintenance", "Renewals"].includes(
                log.expensetype
              )
          );
          totalFuelAmount +=
            fuelLogs.length > 0 &&
            fuelLogs.reduce((total: any, log: any) => {
              const fuelValue = parseFloat(log.amount);
              // console.log(fuelValue);

              return isNaN(fuelValue) ? total : total + fuelValue;
            }, 0);
          totalSalikAmount +=
            salikLogs.length > 0 &&
            salikLogs.reduce((total: any, log: any) => {
              const salikValue = parseFloat(log.amount);
              return isNaN(salikValue) ? total : total + salikValue;
            }, 0);
          totalParkingAmount +=
            parkinglogs.length > 0 &&
            parkinglogs.reduce((total: any, log: any) => {
              const parkingValue = parseFloat(log.amount);
              return isNaN(parkingValue) ? total : total + parkingValue;
            }, 0);
          totalMaintenanceAmount =
            maintenancelogs.length > 0 &&
            maintenancelogs.reduce((total: any, log: any) => {
              const Value = parseFloat(log.amount);
              return isNaN(Value) ? total : total + Value;
            }, 0);
          totalRenewalsAmount +=
            renewalslogs.length > 0 &&
            renewalslogs.reduce((total: any, log: any) => {
              const Value = parseFloat(log.amount);
              return isNaN(Value) ? total : total + Value;
            }, 0);
          totalOthersAmount +=
            otherslogs.length > 0 &&
            otherslogs.reduce((total: any, log: any) => {
              const Value = parseFloat(log.amount);
              return isNaN(Value) ? total : total + Value;
            }, 0);
          // console.log(totalFuelAmount, totalSalikAmount);
        }

        const fuelUsageValue = (totalFuelAmount / totalfuel) * 100;
        const salikUsageValue = (totalSalikAmount / totalsaik) * 100;
        const parkingusageValue = (totalParkingAmount / totalparking) * 100;
        const maintenanceUsageValue =
          (totalMaintenanceAmount / totalmaintenance) * 100;
        const renewalsUsageValue = (totalRenewalsAmount / totalrenewals) * 100;
        const othersUsageValue = (totalOthersAmount / totalothers) * 100;
        console.log(totalMaintenanceAmount, totalmaintenance);
        const fuelPercentage =
          totalfuel === 0 || isNaN(fuelUsageValue)
            ? "0%"
            : `${fuelUsageValue.toFixed(2)}%`;
        const salikPercentage =
          totalsaik === 0 || isNaN(salikUsageValue)
            ? "0%"
            : `${salikUsageValue.toFixed(2)}%`;
        const parkingPercentage =
          totalsaik === 0 || isNaN(parkingusageValue)
            ? "0%"
            : `${parkingusageValue.toFixed(2)}%`;
        const maintenancePercentage =
          totalmaintenance === 0 || isNaN(maintenanceUsageValue)
            ? "0%"
            : `${maintenanceUsageValue.toFixed(2)}%`;
        const renewalsPercentage =
          totalrenewals === 0 || isNaN(renewalsUsageValue)
            ? "0%"
            : `${renewalsUsageValue.toFixed(2)}%`;
        const othersPercentage =
          totalothers === 0 || isNaN(othersUsageValue)
            ? "0%"
            : `${othersUsageValue.toFixed(2)}%`;

        tempData.push({
          department: department.name,
          vehicle: vehicle.id,
          totalFuelAmount: "" + totalFuelAmount.toFixed(2),
          fuelPercentage,
          totalSalikAmount: "" + totalSalikAmount.toFixed(2),
          salikPercentage,
          totalParkingAmount: "" + totalParkingAmount.toFixed(2),
          parkingPercentage,
          totalMaintenanceAmount: "" + totalMaintenanceAmount,
          maintenancePercentage,
          totalRenewalsAmount: "" + totalRenewalsAmount.toFixed(2),
          renewalsPercentage,
          totalOthersAmount: "" + totalOthersAmount.toFixed(2),
          othersPercentage,
        });
      });
    });

    setTransformedData(tempData);
    setLoading(false);

    return a;
  };

  const header = [
    { label: "Department", key: "department" },
    { label: "No. of Vehicles", key: "vehicles" },
    { label: "Total Amount", key: "fueld" },
  ];
  useEffect(() => {
    const startDate = SelectedDate[0];
    const endDate = SelectedDate[1];
    const startDateFormat = startDate
      ? moment(startDate).format("DD MMM YY")
      : "";
    const endDateFormat = endDate ? moment(endDate).format("DD MMM YY") : "";

    setStartDateFormat(startDateFormat);
    setEndDateFormat(endDateFormat);
  }, [SelectedDate]);

  const ClearBtn = () => {
    setSelectedDate("");
    // dateformat = [];
    setStartDateFormat("");
    setEndDateFormat("");
    //   dateformat = 0;
    updateURL(selectView, "", "", selectedVehicle);
  };

  if (SelectedDate !== null && SelectedDate.length > 0) {
    var ShowClrBtn: any = (
      <>
        <button
          onClick={ClearBtn}
          className={`clrbtn`}
          // style={{ display: toggle, position: "relative", bottom: "5px" }}
        >
          <i
            className="ms-Icon ms-Icon--Cancel"
            aria-hidden="true"
            style={{ color: "#db0000", fontWeight: 700 }}
          ></i>
          &nbsp;
        </button>
      </>
    );
  }

  const actionfloattemplate = (rowData: any, column: any) => {
    let totalfuel = 0;
    let totalsalik = 0;
    let totalparking = 0;
    let totalmaintenance = 0;
    let totalrenewals = 0;
    let totalothers = 0;

    let dsalik = 0;
    let dfuel = 0;
    let dparking = 0;
    let dmaintenance = 0;
    let drenewals = 0;
    let dothers = 0;

    let vehicleitems = rowData.vehicles.items;

    // Iterate over each vehicle and its masterlogs to calculate expense totals
    vehicleitems &&
      vehicleitems.forEach((vehicle: any) => {
        let masterlogs = vehicle.logreimbursement.items;
        if (masterlogs.length > 0) {
          // masterlogs.forEach((masterlog: any) => {
          masterlogs.forEach((b: any) => {
            if (b.expensetype === "Fuel") {
              dfuel += b.amount ? parseFloat(b.amount) : 0;
            } else if (b.expensetype === "Parking") {
              dparking += b.amount ? parseFloat(b.amount) : 0;
            } else if (b.expensetype === "Salik") {
              dsalik += b.amount ? parseFloat(b.amount) : 0;
            } else if (b.expensetype === "Maintenance") {
              dmaintenance += b.amount ? parseFloat(b.amount) : 0;
            } else if (b.expensetype === "Renewals") {
              drenewals += b.amount ? parseFloat(b.amount) : 0;
            } else {
              dothers += b.amount ? parseFloat(b.amount) : 0;
            }
          });
          // });
        }
      });

    // Accumulate the expense totals
    totalfuel += dfuel;
    totalsalik += dsalik;
    totalparking += dparking;
    totalmaintenance += dmaintenance;
    totalrenewals += drenewals;
    totalothers += dothers;

    let num: any =
      column.field === "salik"
        ? totalsalik
        : column.field === "fuel"
        ? totalfuel
        : column.field === "parking"
        ? totalparking
        : column.field === "maintenance"
        ? totalmaintenance
        : column.field === "renewals"
        ? totalrenewals
        : totalothers;

    // Check if the input is a number and has more than two decimal places
    if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
      // Round off to two decimal places
      num = Number(Number(num).toFixed(2));
    }

    return (
      <div
        className={`p-button ${column.column.props.children}`}
        style={{
          width: "-webkit-fill-available",
          justifyContent: "flex-end",
          background: column.column.props.children,
          color: "#000",
        }}
      >
        {num !== null ? "" + num : "-"}
      </div>
    );
  };

  const actionsubfloattemplate = (rowData: any, column: any) => {
    let dsalik = 0;
    let dfuel = 0;
    let dparking = 0;
    let dmaintenance = 0;
    let drenewals = 0;
    let dothers = 0;

    let masterlogs = rowData.logreimbursement.items;

    // Iterate over each masterlog to calculate the subtotal for expense types
    masterlogs.length > 0 &&
      masterlogs.forEach((b: any) => {
        // if (masterlog.length > 0) {
        // console.log(b);
        // masterlog.forEach((b: any) => {
        if (b.expensetype === "Fuel") {
          dfuel += b.amount ? parseFloat(b.amount) : 0;
        } else if (b.expensetype === "Parking") {
          dparking += b.amount ? parseFloat(b.amount) : 0;
        } else if (b.expensetype === "Salik") {
          dsalik += b.amount ? parseFloat(b.amount) : 0;
        } else if (b.expensetype === "Maintenance") {
          dmaintenance += b.amount ? parseFloat(b.amount) : 0;
        } else if (b.expensetype === "Renewals") {
          drenewals += b.amount ? parseFloat(b.amount) : 0;
        } else {
          dothers += b.amount ? parseFloat(b.amount) : 0;
        }
        // });
        // }
      });

    let num: any =
      column.field === "salik"
        ? dsalik
        : column.field === "fuel"
        ? dfuel
        : column.field === "parking"
        ? dparking
        : column.field === "maintenance"
        ? dmaintenance
        : column.field === "renewals"
        ? drenewals
        : dothers;

    // Check if the input is a number and has more than two decimal places
    if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
      // Round off to two decimal places
      num = Number(Number(num).toFixed(2));
    }

    return <>{num !== null ? "AED " + num : "-"}</>;
  };
  // Function to calculate and display the usage percentage for different expense types
  const usagetemplate = (rowData: any, column: any) => {
    let dsalik = 0;
    let dfuel = 0;
    let dparking = 0;
    let dmaintenance = 0;
    let drenewals = 0;
    let dothers = 0;

    let vehicleitems = rowData.vehicles.items;

    // Iterate over each vehicle item to calculate the usage for expense types
    vehicleitems &&
      vehicleitems.forEach((vehicle: any) => {
        let masterlogs = vehicle.logreimbursement.items;
        // masterlogs.forEach((masterlog: any) => {
        if (masterlogs.length > 0) {
          masterlogs.forEach((b: any) => {
            if (b.expensetype === "Fuel") {
              dfuel += b.amount ? parseFloat(b.amount) : 0;
            } else if (b.expensetype === "Parking") {
              dparking += b.amount ? parseFloat(b.amount) : 0;
            } else if (b.expensetype === "Salik") {
              dsalik += b.amount ? parseFloat(b.amount) : 0;
            } else if (b.expensetype === "Maintenance") {
              dmaintenance += b.amount ? parseFloat(b.amount) : 0;
            } else if (b.expensetype === "Renewals") {
              drenewals += b.amount ? parseFloat(b.amount) : 0;
            } else {
              dothers += b.amount ? parseFloat(b.amount) : 0;
            }
          });
        }
        // });
      });

    let num: any =
      column.field === "salik"
        ? dsalik
        : column.field === "fuel"
        ? dfuel
        : column.field === "parking"
        ? dparking
        : column.field === "maintenance"
        ? dmaintenance
        : column.field === "renewals"
        ? drenewals
        : dothers;

    // Calculate the usage percentage based on the total value for the expense type
    const usagevalue =
      column.field === "fuel"
        ? (num / totalfuel) * 100
        : column.field === "salik"
        ? (num / totalsaik) * 100
        : column.field === "parking"
        ? (num / totalparking) * 100
        : column.field === "maintenance"
        ? (num / totalmaintenance) * 100
        : column.field === "renewals"
        ? (num / totalrenewals) * 100
        : column.field === "others"
        ? (num / totalothers) * 100
        : 0;

    const formattedValue = isNaN(usagevalue)
      ? "0"
      : `${usagevalue.toFixed(2)}%`;

    return (
      <div
        className={`p-button ${column.column.props.children}`}
        style={{
          width: "-webkit-fill-available",
          justifyContent: "flex-end",
          background: column.column.props.children,
          color: "#000",
        }}
      >
        {formattedValue}
      </div>
    );
  };

  const subusagetemplate = (rowData: any, column: any) => {
    // Initialize variables for expense types
    let dsalik = 0;
    let dfuel = 0;
    let dparking = 0;
    let dmaintenance = 0;
    let drenewals = 0;
    let dothers = 0;

    let masterlogs = rowData.logreimbursement.items;

    // Iterate over each masterlog item to calculate the usage for expense types
    masterlogs.forEach((masterlog: any) => {
      if (masterlog.reimbursement != null) {
        masterlog.logreimbursement.items.forEach((b: any) => {
          if (b.expensetype === "Fuel") {
            dfuel += b.amount ? parseFloat(b.amount) : 0;
          } else if (b.expensetype === "Parking") {
            dparking += b.amount ? parseFloat(b.amount) : 0;
          } else if (b.expensetype === "Salik") {
            dsalik += b.amount ? parseFloat(b.amount) : 0;
          } else if (b.expensetype === "Maintenance") {
            dmaintenance += b.amount ? parseFloat(b.amount) : 0;
          } else if (b.expensetype === "Renewals") {
            drenewals += b.amount ? parseFloat(b.amount) : 0;
          } else {
            dothers += b.amount ? parseFloat(b.amount) : 0;
          }
        });
      }
    });

    let num: any =
      column.field === "salik"
        ? dsalik
        : column.field === "fuel"
        ? dfuel
        : column.field === "parking"
        ? dparking
        : column.field === "maintenance"
        ? dmaintenance
        : column.field === "renewals"
        ? drenewals
        : dothers;

    // Calculate the usage percentage based on the total value for the expense type
    const usagevalue =
      column.field === "fuel"
        ? (num / totalfuel) * 100
        : column.field === "salik"
        ? (num / totalsaik) * 100
        : column.field === "parking"
        ? (num / totalparking) * 100
        : column.field === "maintenance"
        ? (num / totalmaintenance) * 100
        : column.field === "renewals"
        ? (num / totalrenewals) * 100
        : column.field === "others"
        ? (num / totalothers) * 100
        : 0;

    const formattedValue = isNaN(usagevalue)
      ? "0"
      : `${usagevalue.toFixed(2)}%`;

    return <>{formattedValue}</>;
  };

  const totalSalikUsage = oldformatdata.reduce(
    (acc: any, cur: any) => acc + cur.salik,
    0
  );
  const totalFuelUsage = oldformatdata.reduce(
    (acc: any, cur: any) => acc + cur.fuel,
    0
  );
  const totalParkingUsage = oldformatdata.reduce(
    (acc: any, cur: any) => acc + cur.parking,
    0
  );
  const totalMaintenanceUsage = oldformatdata.reduce(
    (acc: any, cur: any) => acc + cur.maintenance,
    0
  );
  const totalRenewalsUsage = oldformatdata.reduce(
    (acc: any, cur: any) => acc + cur.renewals,
    0
  );
  const totalOtheresUsage = oldformatdata.reduce(
    (acc: any, cur: any) => acc + cur.others,
    0
  );
  const totalVehicles = oldformatdata.reduce(
    (acc: any, cur: any) => acc + cur.vehicles,
    0
  );

  const exportdata = (data: any, type: any) => {
    let items: any = [];
    data.map((exportitem: any) => {
      let saliknum: any = exportitem.salik;
      let fuelnum: any = exportitem.fuel;
      let parkingnum: any = exportitem.parking;
      let maintenancenum: any = exportitem.maintenance;
      let renewalsnum: any = exportitem.renewals;
      let othersnum: any = exportitem.others;
      // Check if the input is a number and has more than two decimal places
      if (
        saliknum !== null &&
        !isNaN(saliknum) &&
        /\.\d{3,}/.test(saliknum.toString())
      ) {
        // Round off to two decimal places
        saliknum = Number(Number(saliknum).toFixed(2));
      }
      if (
        fuelnum !== null &&
        !isNaN(fuelnum) &&
        /\.\d{3,}/.test(fuelnum.toString())
      ) {
        // Round off to two decimal places
        fuelnum = Number(Number(fuelnum).toFixed(2));
      }
      if (
        parkingnum !== null &&
        !isNaN(parkingnum) &&
        /\.\d{3,}/.test(parkingnum.toString())
      ) {
        // Round off to two decimal places
        parkingnum = Number(Number(parkingnum).toFixed(2));
      }
      if (
        maintenancenum !== null &&
        !isNaN(maintenancenum) &&
        /\.\d{3,}/.test(maintenancenum.toString())
      ) {
        // Round off to two decimal places
        maintenancenum = Number(Number(maintenancenum).toFixed(2));
      }
      if (
        renewalsnum !== null &&
        !isNaN(renewalsnum) &&
        /\.\d{3,}/.test(renewalsnum.toString())
      ) {
        // Round off to two decimal places
        renewalsnum = Number(Number(renewalsnum).toFixed(2));
      }
      if (
        othersnum !== null &&
        !isNaN(othersnum) &&
        /\.\d{3,}/.test(othersnum.toString())
      ) {
        // Round off to two decimal places
        othersnum = Number(Number(othersnum).toFixed(2));
      }
      //% usage
      const salikusagevalue = (saliknum / totalsaik) * 100;
      const salikformattedValue = isNaN(salikusagevalue)
        ? "0"
        : `${salikusagevalue.toFixed(2)}%`;
      const fuelusagevalue = (fuelnum / totalfuel) * 100;
      const fuelformattedValue = isNaN(fuelusagevalue)
        ? "0"
        : `${fuelusagevalue.toFixed(2)}%`;
      const parkingusagevalue = (parkingnum / totalparking) * 100;
      const parkingformattedValue = isNaN(parkingusagevalue)
        ? "0"
        : `${parkingusagevalue.toFixed(2)}%`;
      const maintenanceusagevalue = (maintenancenum / totalmaintenance) * 100;
      const maintenanceformattedValue = isNaN(maintenanceusagevalue)
        ? "0%"
        : `${maintenanceusagevalue.toFixed(2)}%`;
      const renewalsusagevalue = (renewalsnum / totalrenewals) * 100;
      const renewalsformattedValue = isNaN(renewalsusagevalue)
        ? "0%"
        : `${renewalsusagevalue.toFixed(2)}%`;
      const othersusagevalue = (othersnum / totalothers) * 100;
      const othersformattedValue = isNaN(othersusagevalue)
        ? "0%"
        : `${othersusagevalue.toFixed(2)}%`;
      let a = {
        Department: exportitem.department,
        "Total No. of vehicles": exportitem.vehicles,
        "Fuel Amount (AED)": "" + fuelnum,
        "Fuel % in usage": fuelformattedValue,
        "Salik Amount (AED)": "" + saliknum,
        "Salik % in usage": salikformattedValue,
        "Parking Amount (AED)": "" + parkingnum,
        "Parking % in usage": parkingformattedValue,
        "Maintenance Amount (AED)": "" + maintenancenum,
        "Maintenance % in usage": maintenanceformattedValue,
        "Renewals Amount (AED)": "" + renewalsnum,
        "Renewals % in usage": renewalsformattedValue,
        "Others Amount (AED)": "" + othersnum,
        "Others % in usage": othersformattedValue,
      };
      items.push(a);
    });
    // Add footer row
    let footer = {
      Department: "Total",
      "Total No. of vehicles": totalVehicles,
      "Fuel Amount (AED)": totalfuel,
      "Fuel % in usage":
        totalFuelUsage === 0
          ? "0%"
          : `${((100 * totalfuel) / totalFuelUsage).toFixed(2)}%`,
      "Salik Amount (AED)": totalsaik,
      "Salik % in usage":
        totalSalikUsage === 0
          ? "0%"
          : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`,
      "Parking Amount (AED)": totalparking,
      "Parking % in usage":
        totalParkingUsage === 0
          ? "0%"
          : `${((100 * totalparking) / totalParkingUsage).toFixed(2)}%`,
      "Maintenance Amount (AED)": totalmaintenance,
      "Maintenance % in usage":
        totalMaintenanceUsage === 0
          ? "0%"
          : `${((100 * totalmaintenance) / totalMaintenanceUsage).toFixed(2)}%`,
      "Renewals Amount (AED)": totalrenewals,
      "Renewals % in usage":
        totalRenewalsUsage === 0
          ? "0%"
          : `${((100 * totalrenewals) / totalRenewalsUsage).toFixed(2)}%`,
      "Others Amount (AED)": totalothers,
      "Others % in usage":
        totalOtheresUsage === 0
          ? "0%"
          : `${((100 * totalothers) / totalOtheresUsage).toFixed(2)}%`,
    };

    items.push(footer);
    return items;
  };
  const exportdetaileddata = (data: any, type: any) => {
    let items: any = [];

    data.forEach((item: any) => {
      let a = {
        Department: item.department,
        Vehicle: item.vehicle,
        "Fuel Amount (AED)": item.totalFuelAmount.replace("AED ", ""),
        "Fuel % in Usage": item.fuelPercentage,
        "Salik Amount (AED)": item.totalSalikAmount.replace("AED ", ""),
        "Salik % in Usage": item.salikPercentage,
        "Parking Amount (AED)": item.totalParkingAmount.replace("AED ", ""),
        "Parking % in Usage": item.parkingPercentage,
        "Maintenance Amount (AED)": item.totalMaintenanceAmount.replace(
          "AED ",
          ""
        ),
        "Maintenance % in Usage": item.maintenancePercentage,
        "Renewals Amount (AED)": item.totalRenewalsAmount.replace("AED ", ""),
        "Renewals % in Usage": item.renewalsPercentage,
        "Others Amount (AED)": item.totalOthersAmount.replace("AED ", ""),
        "Others % in Usage": item.othersPercentage,
      };

      items.push(a);
    });
    // console.log(totalothers);

    // Add footer row
    let footer = {
      Department: "Total",
      Vehicle: "",
      "Fuel Amount (AED)": totalfuel.toFixed(2),
      "Fuel % in Usage":
        totalFuelUsage === 0
          ? "0%"
          : `${((100 * totalfuel) / totalFuelUsage).toFixed(2)}%`,
      "Salik Amount (AED)": totalsaik.toFixed(2),
      "Salik % in Usage":
        totalSalikUsage === 0
          ? "0%"
          : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`,
      "Parking Amount (AED)": totalparking.toFixed(2),
      "Parking % in Usage":
        totalParkingUsage === 0
          ? "0%"
          : `${((100 * totalparking) / totalParkingUsage).toFixed(2)}%`,
      "Maintenance Amount (AED)": totalmaintenance.toFixed(2),
      "Maintenance % in Usage":
        totalMaintenanceUsage === 0
          ? "0%"
          : `${((100 * totalmaintenance) / totalMaintenanceUsage).toFixed(2)}%`,
      "Renewals Amount (AED)": totalrenewals.toFixed(2),
      "Renewals % in Usage":
        totalRenewalsUsage === 0
          ? "0%"
          : `${((100 * totalrenewals) / totalRenewalsUsage).toFixed(2)}%`,
      "Others Amount (AED)": parseFloat(totalothers).toFixed(2),
      "Others % in Usage":
        totalOtheresUsage === 0
          ? "0%"
          : `${((100 * totalothers) / totalOtheresUsage).toFixed(2)}%`,
    };

    items.push(footer);
    return items;
  };

  const csvLink = {
    filename:
      SelectedDate.length > 0 && startDateFormat && endDateFormat
        ? `Consumption_CashReimbursement_usage_${selectView}_${startDateFormat}-${endDateFormat}` +
          (vehicleid !== "" ? `_${vehicleid}` : "") +
          (filtervehicleid !== "" ? `_${filtervehicleid}` : "")
        : "Comsumption_CashReimbursement_usage" +
          `_${selectView}` +
          (vehicleid !== "" ? `_${vehicleid}` : "") +
          (filtervehicleid !== "" ? `_${filtervehicleid}` : "") +
          ".csv",
    header: header,
    data:
      oldformatdata.length > 0
        ? exportdata(oldformatdata, selectView)
        : Reports,
  };

  const csvDetailedLink = {
    filename:
      SelectedDate.length > 0 && startDateFormat && endDateFormat
        ? `Consumption_CashReimbursement_usage_${selectView}_${startDateFormat}-${endDateFormat}` +
          (vehicleid !== "" ? `_${vehicleid}` : "") +
          (filtervehicleid !== "" ? `_${filtervehicleid}` : "")
        : "Comsumption_CashReimbursement_usage" +
          `_${selectView}` +
          (vehicleid !== "" ? `_${vehicleid}` : "") +
          (filtervehicleid !== "" ? `_${filtervehicleid}` : "") +
          ".csv",
    header: header,
    data:
      formatdata.length > 0
        ? exportdetaileddata(transformedData, selectView)
        : Reports,
  };

  const Commandbar = (
    <div className="p-toolbar p-component spd-removebg">
      <div className="p-toolbar-group-start p-toolbar-group-left">
        <Calendar
          id="range"
          className="dateFilter"
          value={SelectedDate}
          dateFormat="dd/m/yy"
          onChange={(e: any) => {
            updateURL(selectView, e.value[0], e.value[1], selectedVehicle);
            setSelectedDate(e.value);
          }}
          selectionMode="range"
          readOnlyInput
          placeholder="Select date"
        />

        {ShowClrBtn}
      </div>
      <Dropdown
        value={selectView}
        style={{ width: "200px" }}
        placeholder="Select an option"
        className="mr-2"
        options={[
          { label: "Grouped by Department", value: "Summary" },
          { label: "Show All Details", value: "Details" },
        ]}
        onChange={(e) => {
          updateURL(e.value, SelectedDate[0], SelectedDate[1], selectedVehicle);
          setSelectView(e.value);
        }}
        optionLabel="label"
      />
      <Dropdown
        value={selectedVehicle}
        // style={{ width: '200px' }}
        placeholder="Vehicle"
        options={vehicles}
        onChange={(e) => {
          updateURL(selectView, SelectedDate[0], SelectedDate[1], e.value);
          setSelectedVehicle(e.value);
        }}
        optionLabel="label"
        filter
        showClear
      />
    </div>
  );
  const actionVehicleCount = (rowData: any) => {
    return <>{rowData.vehicles.items.length}</>;
  };
  const rowExpansionTemplate = (data: any) => {
    console.log(data);

    return (
      <div className="orders-subtable">
        <DataTable
          value={data.vehicles.items}
          responsiveLayout="scroll"
          className="p-datatable-responsive usagetable"
          header={
            <div className="p-toolbar p-component">
              <div className="p-toolbar-group-start p-toolbar-group-left">
                <h5>Details for {data.name}</h5>
              </div>
              {/* <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                <CSVLink {...csvLink} onClick={() => setExportType(data.name)}>
                                    <span className="p-float-label exporttext text-white">Export {data.name}</span>
                                </CSVLink>
                            </div> */}
            </div>
          }
        >
          <Column
            field="id"
            className="text-left p-message p-message-warn"
            header="Vehicle"
          />
          <Column
            field="fuel"
            className="text-right p-message p-message-warn"
            header="Fuel Amount (AED)"
            body={actionsubfloattemplate}
          />
          <Column
            field="fuel"
            className="text-right p-message p-message-warn"
            header="Fuel % in Usage"
            body={subusagetemplate}
          />
          <Column
            field="salik"
            className="text-right p-message p-message-warn"
            header="Salik Amount (AED)"
            body={actionsubfloattemplate}
          />
          <Column
            field="salik"
            className="text-right p-message p-message-warn"
            header="Salik % in Usage"
            body={subusagetemplate}
          />
          <Column
            field="parking"
            className="text-right p-message p-message-warn"
            header="Parking Amount (AED)"
            body={actionsubfloattemplate}
          />
          <Column
            field="parking"
            className="text-right p-message p-message-warn"
            header="Parking % in Usage"
            body={subusagetemplate}
          />
          <Column
            field="maintenance"
            className="text-right p-message p-message-warn"
            header="Maintenance Amount (AED)"
            body={actionsubfloattemplate}
          />
          <Column
            field="maintenance"
            className="text-right p-message p-message-warn"
            header="Maintenance % in Usage"
            body={subusagetemplate}
          />
        </DataTable>
      </div>
    );
  };

  //set the values to the url
  const updateURL = (view: any, sdate: any, edate: any, vid: any) => {
    const baseUrl = window.location.href.split("?")[0];
    const queryParams = new URLSearchParams(window.location.search);

    if (sdate) {
      queryParams.set("sdate", convertdate(sdate));
    } else {
      queryParams.delete("sdate");
    }
    if (edate) {
      queryParams.set("edate", convertdate(edate));
    } else {
      queryParams.delete("edate");
    }
    if (view) {
      queryParams.set("view", view);
    } else {
      queryParams.delete("view");
    }
    if (vid) {
      queryParams.set("vid", vid);
    } else {
      queryParams.delete("vid");
    }

    const newUrl = baseUrl + "?" + queryParams.toString();
    window.history.replaceState({}, "", newUrl);
  };
  const DataTableMemoized = memo(DataTable);
  console.log(transformedData);

  return (
    <div className="grid ">
      <div className="col-12 md:col-12">
        {/* <h4 className="container-header">Vehicle Usage Report</h4> */}
        <div className="grid">
          <div className="col-12 md:col-12">
            <div className="card container-card">
              {selectView === "Summary" && (
                <DataTable
                  value={formatdata}
                  loading={loading}
                  // header={Commandbar}
                  header={
                    <div className="p-toolbar p-component p-0">
                      <div className="p-toolbar-group-start p-toolbar-group-left">
                        <h5>Summary/Grouped Report</h5>
                      </div>

                      <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                        {Commandbar}
                        <CSVLink
                          {...csvLink}
                          onClick={() => setExportType("Grouped")}
                        >
                          <span className="p-float-label exporttext text-white">
                            Export
                          </span>
                        </CSVLink>
                      </div>
                    </div>
                  }
                  className="p-datatable-responsive"
                  responsiveLayout="scroll"
                  rows={300}
                  selection={selectedProduct}
                  onSelectionChange={(e) => setSelectedProduct(e.value)}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                >
                  <Column expander style={{ width: "3em" }} />
                  <Column
                    field="name"
                    className="text-left"
                    headerStyle={{ minWidth: "100px" }}
                    sortable
                    header="Department"
                    footer="Total"
                  />
                  <Column
                    header="Total no. of Vehicles"
                    className="text-center"
                    headerStyle={{ minWidth: "100px" }}
                    body={actionVehicleCount}
                  />
                  <Column
                    field="fuel"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRFuel}`}
                    header="Fuel Amount (AED)"
                    footer={"AED " + totalfuel.toFixed(2)}
                    body={actionfloattemplate}
                  />
                  <Column
                    field="fuel"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRFuel}`}
                    header="Fuel % in Usage"
                    body={usagetemplate}
                    footer={
                      totalfuel === 0
                        ? "0%"
                        : `${((100 * totalfuel) / totalfuel).toFixed(2)}%`
                    }
                  />
                  <Column
                    field="salik"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRSalik}`}
                    header="Salik Amount (AED)"
                    body={actionfloattemplate}
                    footer={"AED " + totalsaik}
                  />
                  <Column
                    field="salik"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRSalik}`}
                    header="Salik % in Usage"
                    body={usagetemplate}
                    footer={
                      totalSalikUsage === 0
                        ? "0%"
                        : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`
                    }
                  />
                  <Column
                    field="parking"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRParking}`}
                    header="Parking Amount (AED)"
                    body={actionfloattemplate}
                    footer={"AED " + totalparking}
                  />
                  <Column
                    field="parking"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRParking}`}
                    header="Parking % in Usage"
                    body={usagetemplate}
                    footer={
                      totalParkingUsage === 0
                        ? "0%"
                        : `${((100 * totalparking) / totalParkingUsage).toFixed(
                            2
                          )}%`
                    }
                  />
                  <Column
                    field="maintenance"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRMaintenance}`}
                    header="Maintenance Amount (AED)"
                    body={actionfloattemplate}
                    footer={"AED " + totalmaintenance}
                  />
                  <Column
                    field="maintenance"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRMaintenance}`}
                    header="Maintenance % in Usage"
                    body={usagetemplate}
                    footer={
                      totalMaintenanceUsage === 0
                        ? "0%"
                        : `${(
                            (100 * totalmaintenance) /
                            totalMaintenanceUsage
                          ).toFixed(2)}%`
                    }
                  />
                  <Column
                    field="renewals"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRRenewals}`}
                    header="Renewals Amount (AED)"
                    body={actionfloattemplate}
                    footer={"AED " + totalrenewals}
                  />
                  <Column
                    field="renewals"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CRRenewals}`}
                    header="Renewals % in Usage"
                    body={usagetemplate}
                    footer={
                      totalRenewalsUsage === 0
                        ? "0%"
                        : `${(
                            (100 * totalrenewals) /
                            totalRenewalsUsage
                          ).toFixed(2)}%`
                    }
                  />
                  <Column
                    field="others"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CROthers}`}
                    header="Others Amount (AED)"
                    body={actionfloattemplate}
                    footer={"AED " + totalothers.toFixed(2)}
                  />
                  <Column
                    field="others"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    children={`${colors.CROthers}`}
                    header="Others % in Usage"
                    body={usagetemplate}
                    footer={
                      totalOtheresUsage === 0
                        ? "0%"
                        : `${((100 * totalothers) / totalOtheresUsage).toFixed(
                            2
                          )}%`
                    }
                  />
                </DataTable>
              )}

              {selectView === "Details" && (
                <DataTableMemoized
                  value={transformedData}
                  loading={loading}
                  className="p-datatable-responsive"
                  header={
                    <div className="p-toolbar p-component p-0">
                      <div className="p-toolbar-group-start p-toolbar-group-left">
                        <h5>All Details Report</h5>
                      </div>
                      <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                        {/* <CSVLink {...csvLink} onClick={() => setExportType('report')}>
                                                <span className="p-float-label exporttext text-white">Export</span>
                                            </CSVLink> */}
                        {Commandbar}
                        <CSVLink
                          {...csvDetailedLink}
                          onClick={() => setExportType("All_Details")}
                        >
                          <span className="p-float-label exporttext text-white">
                            Export Detailed Data
                          </span>
                        </CSVLink>
                      </div>
                    </div>
                  }
                  // rowGroupFooterTemplate={footerTemplate}
                  responsiveLayout="scroll"
                  scrollable
                  scrollHeight="80vh"
                >
                  <Column
                    field="department"
                    className="text-left"
                    headerStyle={{ minWidth: "100px" }}
                    header="Department"
                    footer="Total"
                    sortable
                    style={{ minWidth: "1.5rem" }}
                  />
                  <Column
                    field="vehicle"
                    className="text-center"
                    headerStyle={{ minWidth: "110px" }}
                    header="Vehicle"
                    sortable
                    style={{ minWidth: "1.5rem" }}
                  />
                  <Column
                    field="totalFuelAmount"
                    sortable
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRFuel }}
                    header="Fuel Amount (AED)"
                    footer={"AED " + totalfuel.toFixed(2)}
                  />
                  <Column
                    field="fuelPercentage"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRFuel }}
                    header="Fuel % in usage"
                    footer={
                      totalfuel === 0
                        ? "0%"
                        : `${((100 * totalfuel) / totalfuel).toFixed(2)}%`
                    }
                  />
                  <Column
                    field="totalSalikAmount"
                    sortable
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRSalik }}
                    header="Salik Amount (AED)"
                    footer={"AED " + totalsaik.toFixed(2)}
                  />
                  <Column
                    field="salikPercentage"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRSalik }}
                    header="Salik % in usage"
                    footer={
                      totalSalikUsage === 0
                        ? "0%"
                        : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`
                    }
                  />
                  <Column
                    field="totalParkingAmount"
                    sortable
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRParking }}
                    header="Parking Amount (AED)"
                    footer={"AED " + totalparking.toFixed(2)}
                  />
                  <Column
                    field="parkingPercentage"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRParking }}
                    header="Parking % in usage"
                    footer={
                      totalParkingUsage === 0
                        ? "0%"
                        : `${((100 * totalparking) / totalParkingUsage).toFixed(
                            2
                          )}%`
                    }
                  />
                  <Column
                    field="totalMaintenanceAmount"
                    sortable
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRMaintenance }}
                    header="Maintenance Amount (AED)"
                    footer={"AED " + totalmaintenance.toFixed(2)}
                  />
                  <Column
                    field="maintenancePercentage"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRMaintenance }}
                    header="Maintenance % in usage"
                    footer={
                      totalMaintenanceUsage === 0
                        ? "0%"
                        : `${(
                            (100 * totalmaintenance) /
                            totalMaintenanceUsage
                          ).toFixed(2)}%`
                    }
                  />
                  <Column
                    field="totalRenewalsAmount"
                    sortable
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRRenewals }}
                    header="Renewals Amount (AED)"
                    footer={"AED " + totalrenewals.toFixed(2)}
                  />
                  <Column
                    field="renewalsPercentage"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button  m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CRRenewals }}
                    header="Renewals % in usage"
                    footer={
                      totalRenewalsUsage === 0
                        ? "0%"
                        : `${(
                            (100 * totalrenewals) /
                            totalRenewalsUsage
                          ).toFixed(2)}%`
                    }
                  />
                  <Column
                    field="totalOthersAmount"
                    sortable
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CROthers }}
                    header="Others Amount (AED)"
                    footer={"AED " + totalothers.toFixed(2)}
                  />
                  <Column
                    field="othersPercentage"
                    className="text-right"
                    headerStyle={{ minWidth: "110px" }}
                    bodyClassName="p-button m-2"
                    headerClassName="mr-2"
                    style={{ justifyContent: "flex-end", color: "#000" }}
                    bodyStyle={{ backgroundColor: colors.CROthers }}
                    header="Others % in usage"
                    footer={
                      totalOtheresUsage === 0
                        ? "0%"
                        : `${((100 * totalothers) / totalOtheresUsage).toFixed(
                            2
                          )}%`
                    }
                  />

                  {/* <Column field="name" className="text-left" header="Department" footer="Total" />
                                <Column field="vehicle" className="text-left" header="Vehicle" footer="" />
                                <Column field="fuel" className="text-right" header="Total Fuel Amount" footer={'AED ' + totalfuel.toFixed(2)} body={actionfloattemplate} />
                                <Column field="fuel" className="text-right" header="Fuel % in Usage" body={usagetemplate} footer={totalfuel === 0 ? '0%' : `${((100 * totalfuel) / totalfuel).toFixed(2)}%`} />
                                <Column field="salik" className="text-right" header="Total Salik Amount" body={actionfloattemplate} footer={'AED ' + totalsaik} />
                                <Column field="salik" className="text-right" header="Salik % in Usage" body={usagetemplate} footer={totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`} /> */}
                </DataTableMemoized>
              )}
            </div>
          </div>
          {/* <div className="col-12 md:col-6">
                        <div className="card">
                            <DataTable
                                value={formatdata}
                                loading={loading}
                                // header={Commandbar}

                                header={
                                    <div className="p-toolbar p-component">
                                        <div className="p-toolbar-group-start p-toolbar-group-left">
                                            <h5>Salik</h5>
                                        </div>
                                        <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                            <CSVLink {...csvLink} onClick={() => setExportType('salik')}>
                                                <span className="p-float-label exporttext text-white">Export</span>
                                            </CSVLink>
                                        </div>
                                    </div>
                                }
                                className="p-datatable-responsive"
                                responsiveLayout="scroll"
                                rows={300}
                                selection={selectedProduct}
                                onSelectionChange={(e) => setSelectedProduct(e.value)}
                                rowExpansionTemplate={salikrowExpansionTemplate}
                                expandedRows={salikexpandedRows}
                                onRowToggle={(e) => setSalikExpandedRows(e.data)}
                            >
                                <Column expander style={{ width: '3em' }} />
                                <Column field="name" className="text-left" sortable header="Department" footer="Total" />
                                <Column header="Total no. of Vehicles" body={actionVehicleCount} />
                                <Column field="salik" className="text-right" header="Total Salik Amount" body={actionfloattemplate} footer={'AED ' + totalsaik} />
                                <Column field="salik" className="text-right" header="% in Usage" body={usagetemplate} footer={totalSalikUsage === 0 ? '0%' : `${((100 * totalsaik) / totalSalikUsage).toFixed(2)}%`} />
                            </DataTable>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default CashReimburse;
