import { useEffect, useState, useRef, useContext } from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { Amplify, API, Storage } from 'aws-amplify';
import awsconfig from '../aws-exports.js';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { Message } from 'primereact/message';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import RepeatingTemplate from './RepeatingTemplate';
import RenewRepeatingTemplate from './RenewRepeatingTemplate';

let licenseUrl: any;
let filterColumns: any[];
const RenewFormTemplate = (props: any) => {
    const UploadDocument = useRef<any>(null);
    const [data, setData] = useState<any>({});
    const [subcolumns, setsubcolumns] = useState<any>([]);
    const [messsage, setMesssage] = useState<any>('');
    const [licenseImages, setLicenseImages] = useState<any>([]);
    const [licenseFieldName, setLicenseFieldName] = useState<any>('');
    const [formitems, setformitems] = useState<any>([]);
    const [rowToDelete, setRowToDelete] = useState<any>({});
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;

    useEffect(() => {
        if (props.columns && props.columns.columns) {
            let ac = props.columns.columns;
            //ac.push(props.childform);
            setsubcolumns(ac);
        }
    }, [props]);

    useEffect(() => {
        updatecolumnvalues();
        // if (formmode != null && formid != null && props.data != null) {
        setData(props.data);
        // }
    }, [props.data]);

    useEffect(() => {
        console.log('change');
        console.log(filterColumns);
        if (filterColumns == undefined) {
            setformitems([]);
        } else {
            setformitems([...formitems]);
        }
        // let a = {
        //     name:'s',
        //     title:'s'
        // };
        // setformitems([a]);
    }, [filterColumns]);

    // useEffect(() => {
    //     console.log('change2');
    //     console.log(formitems);
    //     console.log(filterColumns);

    // }, [formitems]);

    useEffect(() => {
        //console.log(JSON.stringify(data));
        if (data) {
            props.onChange(data);
        }
    }, [data]);

    let layoutclass = 'md:col-12';
    if (props.layout)
        switch (props.layout.toLowerCase()) {
            case 'vertical':
                layoutclass = 'md:col-12';
                break;
            case 'horizontal':
                layoutclass = 'grid md:col-12';
                break;
            case 'grid':
                layoutclass = 'dropdown md:col-6';
                break;
            case 'grid2':
                layoutclass = 'dropdown md:col-4';
                break;
            default:
                layoutclass = 'md:col-12';
        }
    const onTemplateRemove = (file: any, callback: any) => {
        // setTotalSize(totalSize - file.size);
        callback();
    };
    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="flex align-items-center flex-wrap border-none border-bottom-1 border-solid border-gray-200">
                <div className="flex align-items-center">
                    {/* <img alt={file.name} role="presentation" src={file.objectURL} width={100} /> */}
                    <span className="flex flex-column text-left ml-1">
                        {file.name}
                        <small>{props.formatSize}</small>
                    </span>
                </div>
                <Button icon="pi pi-times" onClick={() => onTemplateRemove(file, props.onRemove)} className="p-button-rounded p-button-danger p-button-text ml-auto mb-2" />
            </div>
        );
    };

    const updatecolumnvalues = () => {
        let columndata: any = {};
        if (subcolumns) {
            subcolumns
                .filter((col: any) => col.fieldtype != 'header')
                .map((val: any, key: number) => {
                    columndata[val.name] = val.value;
                });
            setData(columndata);
        }
    };
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <span style={{ color: 'var(--text-color-secondary)' }} className="my-1">
                    Drag and Drop Files Here
                </span>
            </div>
        );
    };

    const dynamicFields = (field: any, type: any, fieldName: any, options: any, disabled: boolean, hidden: boolean, required: boolean, showcurrentdate: boolean) => {
        let ddata: any = data;
        let fieldvalue = field.value;

        var dvalue = ddata && ddata[fieldName] ? ddata[fieldName] : fieldvalue ? fieldvalue : '';
        //console.log(dvalue, 'dvalue');
        if (type === 'text') {
            return (
                <InputText
                    name={fieldName}
                    value={dvalue}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'textarea') {
            return (
                <InputTextarea
                    name={fieldName}
                    value={dvalue}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'number') {
            return (
                <InputNumber
                    name={fieldName}
                    value={dvalue}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    useGrouping={false}
                    maxLength={12}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'datetime') {
            let dateValue: any = dvalue != '' ? new Date(dvalue) : '';

            return (
                <Calendar
                    name={fieldName}
                    value={dateValue}
                    id="buttonbar"
                    showTime
                    showButtonBar
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    required={required}
                    showIcon
                    hourFormat="12"
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'newdate') {
            let dateValue: any = dvalue != '' ? new Date(dvalue) : '';

            return (
                <Calendar
                    name={fieldName}
                    value={dateValue}
                    id="buttonbar"
                    showButtonBar
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    required={required}
                    showIcon
                    hourFormat="12"
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'date') {
            let ShowDate = showcurrentdate == true ? new Date() : new Date().toISOString();

            let dateValue: any = props.getTableName !== undefined && dvalue !== null && dvalue !== undefined ? new Date(dvalue) : props.getTableName !== undefined && dvalue === undefined && showcurrentdate == true ? ShowDate : dvalue;

            return (
                <Calendar
                    name={fieldName}
                    value={dateValue}
                    id="buttonbar"
                    showButtonBar
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    required={required}
                    showIcon
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'dropdown') {
            return (
                <Dropdown
                    name={fieldName}
                    value={dvalue}
                    options={options}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    optionLabel="label"
                    placeholder="Select an option"
                    filter
                    optionDisabled={(option: any) => option.isdisabled}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'fileupload') {
            return (
                <FileUpload
                    customUpload={true}
                    multiple
                    // url="./upload.php"
                    uploadHandler={(e) => uploadHandler(e, fieldName)}
                    emptyTemplate={emptyTemplate}
                    itemTemplate={itemTemplate}
                    onSelect={(e) => handleChange(e, type, fieldName)}
                    auto={true}
                    accept="*"
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'imageupload') {
            return (
                <FileUpload
                    customUpload={true}
                    multiple
                    // url="./upload.php"
                    uploadHandler={(e) => uploadHandler(e, fieldName)}
                    // onSelect={(e) => uploadHandler(e, fieldName)}
                    onSelect={(e) => handleChange(e, type, fieldName)}
                    emptyTemplate={emptyTemplate}
                    itemTemplate={itemTemplate}
                    auto={true}
                    accept="image/*"
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
            //imageupload
        } else if (type === 'switch') {
            return (
                <InputSwitch
                    name={fieldName}
                    checked={data[fieldName]}
                    onChange={(e: any) => {
                        // btnfunction(e, data[fieldName]);
                        handleChange(e, type, fieldName);
                    }}
                    disabled={disabled}
                    hidden={hidden}
                    required={required}
                />
            );
        } else if (type === 'multiselect') {
            return (
                <MultiSelect
                    name={fieldName}
                    value={dvalue}
                    options={options}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    optionLabel="label"
                    placeholder="Select an option"
                    filter
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else {
            return (
                <InputText
                    name={fieldName}
                    value={dvalue}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        }

        //}
    };

    const handlesubChange = async (event: any, type: any, fieldName: any) => {
        // console.log(event);
        // console.log(type);
        // console.log(fieldName);
        //console.log(JSON.stringify(data));

        if (event && !event.type) {
            setData({
                ...data,
                [type === 'fileupload' || type == 'form' ? fieldName : type === 'number' ? event.originalEvent.target.name : event.target.name]:
                    type == 'form' ? event : type === 'fileupload' ? event.files[0].objectURL : type === 'number' ? event.originalEvent.target.value : type === 'multiselect' ? event.target.value : event.target.value
            });
        }
        //console.log(JSON.stringify(data));
    };

    const handleChange = async (event?: any, type?: any, fieldName?: any) => {
        if (type !== 'fileupload') {
            setData({
                ...data,
                [type === 'fileupload' || type == 'form' ? fieldName : type === 'number' ? event.originalEvent.target.name : event.target.name]:
                    type == 'form' ? event.target.value : type === 'fileupload' ? event.files[0].objectURL : type === 'number' ? event.originalEvent.target.value : type === 'multiselect' ? event.target.value : event.target.value
            });

            props.onChange(event, type, fieldName);
        }

        //props.changevehicledata(data);
    };
    //get the license file and the fieldName
    const uploadHandler = async (event: any, fieldName: any) => {
        // setLicenseImages(event.files);
        // setLicenseFieldName(fieldName);

        setData({
            ...data,
            [fieldName]: event.files
        });
        props.onChange(event, 'fileupload', fieldName);
    };
    //call UploadLicense In submit function
    const UploadLicense = async () => {
        let Files: any = [];
        licenseUrl = '';
        return await Promise.all(
            licenseImages.map(async (item: any) => {
                let expDOc = {};
                // props.Fleettype !== undefined ?
                if (props.Fleettype !== undefined) {
                    let url = await Storage.put(`${props.bucketname}/${data.id}/${new Date().getFullYear()}/${data.renewaltype}/` + item.name, item);

                    licenseUrl = url.key;
                } else if (props.Fleettype !== undefined && props.Fleettype == 'vehiclePicture') {
                    await Storage.put(`${props.bucketname}/${data.PlateCode + data.PlateNumber}` + item.name, item)
                        .then((res: any) => {
                            Files.push(res.key + ';');
                            licenseUrl = JSON.stringify({ File: Files });
                        })
                        .catch((err) => {});
                } else {
                    await Storage.put(`${props.bucketname}/${data.name !== undefined ? data.name : data.vehicleid}-${new Date().toDateString()}/` + item.name, item)
                        .then((res: any) => {
                            Files.push(res.key + ';');
                            licenseUrl = JSON.stringify({ File: Files });
                        })
                        .catch((err) => {});
                }
            })
        ).then(() => {
            saveData();
        });
    };

    const subsubmitdata = async () => {
        subvalidateinputs();
    };

    const subvalidateinputs = () => {
        setMesssage('');
        let collecteddata = data;
        let a = subcolumns.filter((p: any) => p.fieldtype != 'header');
        let b = subcolumns.filter((p: any) => p.fieldtype != 'header' && p.required);
        let c = Object.keys(collecteddata);
        let d = c.filter((v: any) => {
            return collecteddata[v];
        });

        let e = b.every((v: any) => {
            return d.includes(v.name);
        });

        if (!e) setMesssage('* Please fill all required fields');
        else props.submitForm(collecteddata);
    };

    const btnfunction = async (event: any, field: any) => {
        field.action.addstep(event);
    };
    const removeFunction = (key: any, value: any) => {
        // console.log(key);
        // console.log(value);
        // console.log(data);
        // console.log(filterColumns);
        // if (value.fieldtype == "clear") {
        //     let b = formitems.filter((ele:any) => ele.name != key);
        //     console.log(formitems);
        //     filterColumns = b;
        //     setformitems(b);
        //     // console.log(filterColumns.length);
        //     // let a = filterColumns.splice(key,1)

        //     // //delete filterColumns[key];
        //     // console.log(a);
        //     // console.log(a.length);
        //     // console.log(filterColumns);
        //     // console.log(filterColumns.length);
        //     // //setformitems(a)

        //     // filterColumns = a;
        //     // setformitems(a);
        // }

        let newData: any[] = [];
        if (filterColumns !== undefined) {
            newData = filterColumns;
            let data = newData.filter((item) => parseInt(item.name) !== key);
            newData = data;
            filterColumns = newData;

            let items = [];
            for (let i = 0; i < filterColumns.length; i++) {
                const element = filterColumns[i];
                items.push({
                    name: `${i}`,
                    title: `${i}`,
                    columns: element.columns
                });
            }
            console.log(items);
            setformitems(items);
            let toDeleteDiv = document.getElementById('column_container' + key);
            toDeleteDiv?.remove();
            let testData = [
                {
                    class: 'md:col-3',
                    data: [],
                    disabled: false,
                    fieldtype: 'text',
                    hidden: true,
                    name: 'dummy',
                    required: false,
                    title: 'dummy',
                    value: ''
                }
            ];
            setsubcolumns([{ ...subcolumns, ...testData }]);
        } else {
            setformitems([]);
        }
        props.onChange(key);
    };

    const runsubfunction = async (event: any, a: any) => {
        console.log(event);
        console.log(a);
        console.log(a.rows);
        console.log(formitems.length);
        if (a.rows > formitems.length) {
            let formitemid = formitems.length.toString();
            let c = JSON.parse(JSON.stringify(a.columns));
            //c.columns[0].name = formitemid;
            c.columns[0].value = formitemid;
            let b = {
                name: formitemid,
                title: formitemid,
                columns: c
            };
            filterColumns = [...formitems, b];
            setformitems([...formitems, b]);
        }
        if (!a.rows) {
            let formitemid = formitems.length.toString();
            let c = JSON.parse(JSON.stringify(a.columns));
            c.columns[0].name = formitemid;
            c.columns[0].value = formitemid;
            let b = {
                name: formitemid,
                title: formitemid,
                columns: c
            };
            filterColumns = [...formitems, b];
            setformitems([...formitems, b]);
        }
    };

    // console.log(subcolumns);

    // const autorunsubfuntion = async () => {
    //     let formitemid = formitems.length.toString();
    //     let c = JSON.parse(JSON.stringify(a.columns));
    //     //c.columns[0].name = formitemid;
    //     c.columns[0].value = formitemid;
    //     let b = {
    //         name: formitemid,
    //         title: formitemid,
    //         columns: c
    //     };
    //     setformitems([...formitems, b]);
    // };

    const submitdata = async () => {
        validateinputs();
    };
    const validateinputs = () => {
        setMesssage('');
        let collecteddata = data;
        let a = subcolumns.filter((p: any) => p.fieldtype != 'header');
        let b = subcolumns.filter((p: any) => p.fieldtype != 'header' && p.fieldtype != 'break' && p.required);
        let c = Object.keys(collecteddata);
        let d = c.filter((v: any) => {
            return collecteddata[v];
        });

        let e = b.every((v: any) => {
            return d.includes(v.name);
        });
        console.log(e);

        if (!e) setMesssage('* Please fill all required fields');
        else props.submitForm(collecteddata);
    };
    const saveData = () => {
        let submitteddata = subcolumns.filter((p: any) => p.fieldtype != 'header');
        let tem: any = [];
        let collectedData = data;
        if (Array.isArray(collectedData.partname)) {
            collectedData.partname = JSON.stringify(collectedData.partname);
        } else {
            collectedData = collectedData;
        }
        collectedData[licenseFieldName] = licenseUrl;
        let EditFormFilter = Object.fromEntries(
            Object.entries(collectedData)
                .filter(([key, value]) => value !== null)
                .filter(([key, value]) => key !== '')
                .filter(([key, value]) => value !== undefined)
        );
        let NewFormFilter = Object.fromEntries(
            Object.entries(collectedData)
                .filter(([key, value]) => value !== null)
                .filter(([key, value]) => key !== '')
                .filter(([key, value]) => value !== '')
                .filter(([key, value]) => value !== undefined)
        );
        let finalData = props.getTableName !== undefined ? EditFormFilter : NewFormFilter;
        // Pushing the column items for validation
        subcolumns.map((val: any, key: number) => {
            if (data[val.name] == '' && val.required == (props.getTableName !== undefined ? true : false)) {
                return false;
            } else if (data[val.name] == null && val.required == false && data[val.name] == undefined) {
                return false;
            } else {
                tem.push(val.name);
            }
        });
        tem = tem.filter((res: any) => res !== 'id');
        //Checking id and fleettype (Edit)
        if (finalData.hasOwnProperty('id') && finalData.hasOwnProperty('fleettype')) {
            if (tem.length + 4 == Object.keys(finalData).length) {
                props.submitForm(finalData);
            } else {
                setMesssage('* Please fill all required fields');
            }
        }
        //Checking id and fleettype (Edit)
        else if (finalData.hasOwnProperty('id') && !finalData.hasOwnProperty('fleettype')) {
            if (tem.length + 3 == Object.keys(finalData).length) {
                props.submitForm(finalData);
            } else {
                setMesssage('* Please fill all required fields');
            }
        }
        // New Form
        else {
            if (tem.length == Object.keys(finalData).length) {
                props.submitForm(finalData);
            } else {
                setMesssage('* Please fill all required fields');
            }
        }
    };

    //updatecolumnvalues();
    //props.onChange(data);
    return (
        // passing id from child subchild component to delete the row
        <div className="grid" id={props.id + props.subIndex}>
            <Toast ref={UploadDocument}></Toast>
            <div className="col-12 md:col-12">
                <div className="card ">
                    {/* <h5> {props.title} </h5>*/}
                    {/* {data['id']} */}

                    <div className="p-fluid formgrid grid">
                        {subcolumns
                            ? subcolumns
                                  .filter((hidden: any) => hidden.hidden === false || hidden.hidden === undefined)
                                  .map((val: any, key: number) => {
                                      return (
                                          <React.Fragment key={key.toString()}>
                                              {(() => {
                                                  if (
                                                      !val.conditionshow ||
                                                      (val.conditionshow && typeof val.conditionshow.value == 'boolean' && val.conditionshow.value == data[val.conditionshow.name]) ||
                                                      (val.conditionshow && typeof val.conditionshow.value === 'string' && val.conditionshow.value.includes(data[val.conditionshow.name]))
                                                  )
                                                      switch (val.fieldtype) {
                                                          case 'header':
                                                              let a = (
                                                                  <div className={`field col-12  ${val.class && val.class != '' ? val.class : 'md:col-12'}`}>
                                                                      <h5 className={key > 1 ? 'spd-padtop' : ''}>{val.title}</h5>
                                                                  </div>
                                                              );
                                                              return a;
                                                          case 'break':
                                                              let b = <div className={`field col-12  ${val.class && val.class != '' ? val.class : 'md:col-12'}`}></div>;
                                                              return b;

                                                          case 'button':
                                                              let c = (
                                                                  <div className={`field col-12  ${val.class && val.class != '' ? val.class : 'md:col-12'}`}>
                                                                      <Button label={`${val.title}`} onClick={(e: any) => btnfunction(e, val)} className="mr-2 mb-2"></Button>
                                                                  </div>
                                                              );
                                                              return c;
                                                          case 'form':
                                                              let d = (
                                                                  <>
                                                                      {props.showtopbutton ? (
                                                                          <>
                                                                              {/* {val.conditionshow ? val.conditionshow.value + ' ' + data[val.conditionshow.name] : '2'} */}
                                                                              <div className={`field col-2  md:col-2`}>
                                                                                  <Button label={`${val.title}`} onClick={(e: any) => runsubfunction(e, val)} className="mr-2 mb-2"></Button>
                                                                              </div>
                                                                          </>
                                                                      ) : (
                                                                          ''
                                                                      )}

                                                                      <div className={`field col-12  ${val.class && val.class != '' ? val.class : 'md:col-12'}`}>
                                                                          {props.Templatename == 'RenewTemplate' ? (
                                                                              <>
                                                                                  <RenewRepeatingTemplate
                                                                                      ChangeBtn={'OpenDocument'}
                                                                                      layout={val.layout ? val.layout : 'grid2'}
                                                                                      name={val.name}
                                                                                      title={val.title}
                                                                                      items={formitems}
                                                                                      showaction={val.showaction}
                                                                                      mode={val.mode}
                                                                                      onload={(e: any) => (val.rows ? runsubfunction(e, val) : console.log('no rows'))}
                                                                                      data={val.updatedata}
                                                                                      onChange={(e: any) => handlesubChange(e, val.fieldtype, val.name)}
                                                                                      submitForm={subsubmitdata}
                                                                                  />
                                                                              </>
                                                                          ) : (
                                                                              <RepeatingTemplate
                                                                                  ChangeBtn={'OpenDocument'}
                                                                                  layout={val.layout ? val.layout : 'grid2'}
                                                                                  name={val.name}
                                                                                  title={val.title}
                                                                                  items={formitems}
                                                                                  showaction={val.showaction}
                                                                                  mode={val.mode}
                                                                                  onload={(e: any) => (val.rows ? runsubfunction(e, val) : console.log('no rows'))}
                                                                                  data={val.updatedata}
                                                                                  onChange={(e: any) => handlesubChange(e, val.fieldtype, val.name)}
                                                                                  submitForm={subsubmitdata}
                                                                              />
                                                                          )}
                                                                      </div>
                                                                  </>
                                                              );
                                                              return d;
                                                          case 'clear':
                                                              let e = (
                                                                  <div
                                                                      className={`field col-12  ${val.class && val.class != '' ? val.class : 'md:col-12'}`}
                                                                      style={{
                                                                          display: 'flex',
                                                                          alignItems: 'flex-end'
                                                                      }}
                                                                  >
                                                                      <Button label={`${val.title}`} onClick={() => removeFunction(props.subIndex, val)} className="p-button-danger"></Button>
                                                                  </div>
                                                              );
                                                              return e;
                                                          default:
                                                              let f = (
                                                                  <div className={`field col-12 ${val.class && val.class != '' ? val.class : layoutclass}`}>
                                                                      <label htmlFor={val.value ? val.value : ''} className={layoutclass.includes('grid') ? 'col-12 mb-2 md:col-2 md:mb-0' : ''}>
                                                                          {val.title}
                                                                          {val.required ? '*' : ''}
                                                                      </label>
                                                                      <div className={layoutclass.includes('grid') ? 'col-12 md:col-10' : ''}>
                                                                          {dynamicFields(val, val.fieldtype, val.name, val.data, val.disabled, val.hidden, val.required, val.showcurrentdate)}
                                                                      </div>
                                                                  </div>
                                                              );
                                                              return f;
                                                      }
                                              })()}
                                              {/* {val.fieldtype == 'header' || val.fieldtype === 'break' || val.fieldtype === 'button' ? (
                                                <div className={`field col-12  ${val.class && val.class != '' ? val.class : 'md:col-12'}`}>
                                                    {val.fieldtype === 'header' ?
                                                        <h5 className={key > 1 ? 'spd-padtop' :
                                                            ''
                                                        }>
                                                            {val.title}
                                                        </h5> :
                                                        val.fieldtype === 'button' ?
                                                            <Button label="Submit" className="mr-2 mb-2"></Button>
                                                            : ''

                                                    }
                                                </div>
                                            ) : (
                                                <>
                                                    <div className={`field col-12 ${val.class && val.class != '' ? val.class : layoutclass}`}>
                                                        <label htmlFor={val.value} className={layoutclass.includes('grid') ? 'col-12 mb-2 md:col-2 md:mb-0' : ''}>
                                                            {val.title}
                                                            {val.required ? '*' : ''}
                                                        </label>
                                                        <div className={layoutclass.includes('grid') ? 'col-12 md:col-10' : ''}>{dynamicFields(val.fieldtype, val.name, val.data, val.disabled, val.hidden, val.required, val.showcurrentdate)}</div>
                                                    </div>
                                                </>
                                            )} */}
                                          </React.Fragment>
                                      );
                                  })
                            : ''}
                    </div>
                    {messsage && messsage != '' ? (
                        <span className="font-italic" style={{ color: '#d32f2f' }}>
                            {messsage}
                        </span>
                    ) : (
                        ''
                    )}
                    {props.showaction ? (
                        <div className="spd-card-footer">
                            <Button onClick={submitdata} className="mr-2" disabled={props.mode == 'view'}>
                                {props.columns.actions ? props.columns.actions[0].title : 'Submit'}
                            </Button>
                            <Button onClick={() => window.history.back()} className="p-button-danger">
                                {props.columns.actions ? props.columns.actions[1].title : 'Reject'}
                            </Button>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};
export default RenewFormTemplate;
