import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import { API } from 'aws-amplify';
import FormTemplate from '../../Components/FormTemplate';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import AmplifyServices from '../../Service/Service';
import { createDxDocument, createDxVehicleActivity, deleteDxVehicleActivity, updateDxVehicleActivity } from '../../graphql/mutations';
import { listDxContracts, listDxVehicleActivities } from '../../graphql/queries';
import { Storage } from 'aws-amplify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import awsExports from '../../aws-exports';
import dxService from '../../Services/dxService';
import AllDocumentsView from './DocumentsView';
import { getGenralconfigs, getNextQueryData, getVehicleactivityoptions, getvendorCompanyoptions, getvendorGarageoptions, getvendoroptions, isotolocaledatetime, localetoisodatetime } from '../../utils/helper';
import Loader from '../../Components/Loader';
var storeVehicleData: any = [];
var UploadDocument: any = [];
var storeVehicleActivityData: any = [];
var vehicleitems: any;
function DocumentnewForm() {
    const navigate = useNavigate();
    const [appointmentNeeded, setAppointmentNeeded] = useState(false);
    const [vehicleID, setVehicleID] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [contract, setcontract] = useState<any>([]);
    const [prevcontract, setprevcontract] = useState<any>([]);
    const [vehicleactivityitem, setvehicleactivityitem] = useState({});
    const [updatedata, setupdatedata] = useState<any>([]);
    const [displayDialog, setdisplayDialog] = useState(false);
    const [Appointmentdata, setAppointmentdata] = useState<any>([]);
    const [Appointdetails, setAppointdetails] = useState<any>('');
    const toast = useRef<any>(null);
    const [GarageVendorOptions, setGarageVendorOptions] = useState<any>([]);
    const [CompanyVendorOptions, setCompanyVendorOptions] = useState<any>([]);
    const [renewaltypeoptions, setRenewaltypeoptions] = useState<any>([]);
    const [listFormData, setlistFormData] = useState<any>({});
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode: any = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid: any = urlParams.has('id') ? urlParams.get('id') : null;
    var type: any = urlParams.has('type') ? urlParams.get('type') : null;
    var vehicleid: any = urlParams.has('vid') ? urlParams.get('vid') : null;

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
        formid = urlParams.has('id') ? urlParams.get('id') : null;
        type = urlParams.has('type') ? urlParams.get('type') : null;
        vehicleid = urlParams.has('vid') ? urlParams.get('vid') : null;
        const paths = location.pathname.split('/');
        const currentPath = paths[paths.length - 1];
        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'allocations':
                setActiveIndex(2);
                break;

            default:
                if (formid) getRenewalData();
                if (vehicleid) getPreviousRenewalData();
                break;
        }
    }, [location]);
    useEffect(() => {
        let scid = vehicleid.includes(',') ? vehicleid.split(',') : vehicleid;
        setlistFormData({ issuedate: '', expirydate: '', renewaltype: type, vehicleid: scid, renewalstatus: 'Completed' });
        getvendordata();
        checkActiveIndex();
    }, [checkActiveIndex]);
    /**
     * getRenewalData() fetch the renewal item from contract table with id and renewal type filter
     * and set it into the listformData.
     */
    const getRenewalData = async (nextToken?: any) => {
        let variables: any = {
            filter: {
                id: {
                    eq: formid
                },
                renewaltype: {
                    eq: type // filter priority = 1
                }
            }
        };
        if (nextToken) {
            variables.nextToken = nextToken;
        }

        let GetResponse: any = await API.graphql({
            query: listDxContracts,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: variables
        });

        let res = GetResponse.data.listDxContracts.items;
        if (GetResponse.data.listDxContracts.nextToken) {
            // const nextResults = await getRenewalData(GetResponse.data.listDxContracts.nextToken);

            const nextResults: any = await getRenewalData(GetResponse.data.listDxContracts.nextToken);

            res = res.concat(nextResults);
        }
        if (res.length > 0) {
            let vid: any;
            if (res[0].vehicleid.includes(',')) {
                vid = vehicleid.includes(',') ? vehicleid.split(',') : vehicleid;
            } else {
                vid = res[0].vehicleid;
            }

            setlistFormData({
                vehicleid: vid,
                renewaltype: res[0].renewaltype,
                renewalno: res[0].renewalno,
                subtype: res[0].subtype,
                renewalstatus: res[0].renewalstatus,
                totalamount: res[0].totalamount,
                scvendor: res[0].scvendor,
                createdAt: isotolocaledatetime(res[0].createdAt),
                updatedAt: isotolocaledatetime(res[0].updatedAt),
                credits: res[0].jfield !== null ? JSON.parse(res[0].jfield).credits : '',
                expirydate: isotolocaledatetime(res[0].expirydate),
                issuedate: isotolocaledatetime(res[0].issuedate),
                id: res[0].id
                // vehicleid: item[0].vehicleid
            });
            // setlistFormData(res[0]);
            setcontract(res);
        }
        return res;
    };
    /**
     * getPreviousRenewalData() is function which filter the previous contract with a Active status in renewal table
    the result is used for updating the Active item status as Completed status.
     */
    const getPreviousRenewalData = async (nextToken?: any) => {
        // let variables: any = {};
        let variables: any = {
            filter: {
                vehicleid: {
                    eq: vehicleid
                },
                renewaltype: {
                    eq: type // filter priority = 1
                },
                renewalstatus: { eq: 'Active' }
                // or: [{ renewalstatus: { eq: 'InProgress' } }, { renewalstatus: { eq: 'Active' } }]
            }
        };
        if (nextToken) {
            variables.nextToken = nextToken;
        }

        let GetResponse: any = await API.graphql({
            query: listDxContracts,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: variables
        });

        let res = GetResponse.data.listDxContracts.items;
        if (GetResponse.data.listDxContracts.nextToken) {
            // const nextResults = await getRenewalData(GetResponse.data.listDxContracts.nextToken);

            const nextResults: any = await getPreviousRenewalData(GetResponse.data.listDxContracts.nextToken);

            res = res.concat(nextResults);
        }
        if (res.length > 0) {
            res = res.filter((item: any) => item._deleted !== true);
            setprevcontract(res);
        }
        return res;
    };
    //getCurrentContractDetails function fetches the Contract details related to the vehicle
    const getCurrentContractDetails = async () => {
        await AmplifyServices.fetchSingleContract(formid)
            .then((a) => {
                // if (a[0].appointments !== undefined) {
                //     setAppointmentdata(a[0].appointments.items);
                // }
                setcontract(a[0]);
            })
            .catch((err) => {
                console.error(err);
            });

        AmplifyServices.filteruniqueContract(formid, type).then((resp: any) => {
            if (resp.items && resp.items.length > 0) setvehicleactivityitem(resp.items[0]);
        });
    };

    const subchangedata = (props: any) => {};

    const addsubData = async (props: any) => {};

    //getvehicledata filters the contract based on the contract id and contract type
    const getvehicledata = async (fid: any, vehicleid: any, type: any) => {
        await AmplifyServices.filterScOnly(fid, type)
            .then((a) => {
                a.map((vid: any) => {
                    if (Object.keys(vid).length > 0) {
                        vid['vehicleid'] = vid.vehicleid.includes(',') ? vid.vehicleid.split(',') : vid.vehicleid;
                        setlistFormData(vid);
                    }
                });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        let cols = formColumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });

        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);
    useEffect(() => {
        // getDocumentDetails();
        getData().then((succ) => {
            if (Appointdetails !== '') {
                Filterappointment().then((item) => {
                    if (item.length > 0) {
                        setAppointmentdata(item);
                    }
                });
            }
        });

        getRenewalType();
    }, [Appointdetails]);
    /**
     * getData() fetch the item with vehicle id from vehicleactivity table
     * If the renewal item has any appointment then it will get the app id from the vehicleactivity table
     */
    const getData = async () => {
        setVehicles([]);
        if (!vehicleid.includes(',')) {
            let filtervehicleactivity = {
                id: {
                    eq: vehicleid
                }
            };
            await getNextQueryData('', filtervehicleactivity, 'vehicleactivity', true).then((res: any) => {
                let vehicleactivitydata = res[0];

                let fetchappdata =
                    vehicleactivitydata !== null &&
                    vehicleactivitydata.expiring !== null &&
                    JSON.parse(vehicleactivitydata.expiring)[type] &&
                    JSON.parse(vehicleactivitydata.expiring)[type].appid &&
                    JSON.parse(vehicleactivitydata.expiring)[type].appid;
                setAppointdetails(fetchappdata);
            });
        }

        //getting the vehicle id details to show on the Vehicle dropdown
        let VActivity: any = await getVehicleactivityoptions();
        setVehicles(VActivity);
    };
    /**
     * getvendordata() get the data from vendor places based on vendor type
     */
    const getvendordata = async () => {
        //getting the company  vendor details  to show on the place dropdown
        let Companyvendordata = await getvendorCompanyoptions();

        setCompanyVendorOptions(Companyvendordata);
        //getting the garage vendor details to show on the place dropdown
        let Garagevendordata = await getvendorGarageoptions();

        setGarageVendorOptions(Garagevendordata);
    };
    /**
     * getRenewalType() get all the renewal type (i.e. Registration,Insurance,Branding) to show it in a 
      dropdown
     */
    const getRenewalType = () => {
        getGenralconfigs('Renewal Type').then((res) => {
            setRenewaltypeoptions(res);
        });
    };
    let sparecolumns = {
        columns: [
            {
                name: 'place',
                title: 'Place',
                value: '',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: GarageVendorOptions,
                class: 'md:col-6'
            },
            {
                name: 'startdate',
                title: 'Date  & Time',
                value: '',
                fieldtype: 'datetime',
                required: false,
                disabled: false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'appointmentstatus', // pass the exact column name of db
                title: 'Appointment Status',
                value: '',
                class: 'md:col-6',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: [
                    { label: 'Scheduled', value: 'Scheduled' },
                    { label: 'Completed', value: 'Completed' }
                ]
            },
            {
                name: 'description', // pass the exact column name of db
                title: 'Description',
                value: '', //for display
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                class: 'md:col-6',
                data: []
            }
        ]
    };
    const formColumns = {
        columns: [
            {
                name: 'vehicleid', // pass the exact column name of db
                value: '',
                title: 'Vehicle',
                fieldtype: vehicleid.includes(',') ? 'multiselect' : 'dropdown',
                required: true,
                disabled: formmode == 'view' ? true : formmode == 'edit' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: vehicles
            },
            {
                name: 'renewaltype', // pass the exact column name of db
                value: '',
                title: 'Renewal Type',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : formmode == 'edit' ? true : false,
                hidden: false,
                data: renewaltypeoptions
            },
            {
                name: 'renewalstatus', // pass the exact column name of db
                value: '',
                title: 'Renewal Status',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : formmode == 'edit' ? true : false,
                hidden: true,
                data: [
                    { label: 'In Progress', value: 'In Progress' },
                    { label: 'Completed', value: 'Completed' }
                ]
            },
            { name: 'break2', title: '', fieldtype: 'break', class: 'md:col-12' },
            {
                name: 'renewalno', // pass the exact column name of db
                value: '', //for display
                title: 'Certificate No',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            {
                name: 'subtype', // pass the exact column name of db
                value: '',
                title: 'Permit Type',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                conditionshow: { name: 'renewaltype', value: 'Insurance,Registration,Branding,', type: String },
                data: []
            },
            {
                name: 'credits', // pass the exact column name of db
                value: '', //for display
                title: 'No of Credits',
                fieldtype: 'number',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                conditionshow: { name: 'renewaltype', value: 'ServiceContract' }
            },
            {
                name: 'scvendor', // pass the exact column name of db
                value: '', //for display
                title: 'Company',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : false,
                // disabled: false,
                hidden: false,
                data: CompanyVendorOptions
                // conditionshow: { name: 'isappointmentrequire', value: false, type: Boolean }
                // conditionshow: { name: 'renewaltype', value: 'ServiceContract' }
            },
            {
                name: 'issuedate', // pass the exact column name of db
                value: '', //for display
                title: type == 'Registration' ? 'Registration date' : 'Issue date',
                fieldtype: 'date',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            {
                name: 'expirydate', // pass the exact column name of db
                value: '',
                title: 'Expiry date',
                fieldtype: 'date',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            {
                name: 'totalamount', // pass the exact column name of db
                value: '',
                title: 'Amount(AED)',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            { name: 'break2', title: '', fieldtype: 'break', class: 'md:col-12' },
            // {
            //     name: 'documents', // pass the exact column name of db
            //     value: '',
            //     title: 'Document',
            //     fieldtype: 'fileupload',
            //     required: false,
            //     disabled: appointmentNeeded ? true : false,
            //     hidden: appointmentNeeded ? true : false,
            //     data: []
            // },

            {
                name: 'documents', // pass the exact column name of db
                value: '',
                title: 'Upload documents',
                fieldtype: 'fileupload',
                required: false,
                disabled: formmode == 'new' ? true : false,
                hidden: formmode == 'new' ? true : formmode == 'view' ? true : false,
                class: 'md:col-4',
                data: []
            },
            {
                name: 'picture', // pass the exact column name of db
                value: '',
                title: 'Upload Pictures',
                fieldtype: 'imageupload',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: formmode == 'new' ? true : formmode == 'view' ? true : false,
                class: 'md:col-4',
                data: []
            },
            {
                name: 'receipts', // pass the exact column name of db
                value: '',
                title: 'Upload recepits',
                fieldtype: 'fileupload',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: formmode == 'new' ? true : formmode == 'view' ? true : false,
                class: 'md:col-4',
                data: []
            },
            // {
            //     name: 'isAppointmentNeeded', // pass the exact column name of db
            //     value: '',
            //     title: 'is Appointment Needed?',
            //     fieldtype: 'switch',
            //     required: false,
            //     disabled: false,
            //     hidden: false,
            //     data: []
            // },
            //hidden fields
            {
                name: 'capacity', // pass the exact column name of db
                value: '',
                title: 'capacity',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'year', // pass the exact column name of db
                value: '',
                title: 'Year',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'departmentname', // pass the exact column name of db
                value: '',
                title: 'Department Name',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'make', // pass the exact column name of db
                value: '',
                title: 'Make',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },

            {
                name: 'model', // pass the exact column name of db
                value: '',
                title: 'Model',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'platecode', // pass the exact column name of db
                value: '',
                title: 'Platecode',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'plateno', // pass the exact column name of db
                value: '',
                title: 'Plate No',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'status', // pass the exact column name of db
                value: '',
                title: 'Status',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'expiring', // pass the exact column name of db
                value: '',
                title: 'Expiring',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'allocationdriver', // pass the exact column name of db
                value: '',
                title: 'allocationdriver',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'events', // pass the exact column name of db
                value: '',
                title: 'events',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'VehicleStatus', // pass the exact column name of db
                value: '',
                title: 'Vehicle Status',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'form1',
                title: 'Schedule Appointment',
                fieldtype: 'form',
                required: false,
                value: '',
                hidden: formmode == 'edit' ? true : formmode == 'view' ? true : false,
                disabled: false,
                class: 'md:col-12',
                columns: sparecolumns,
                updatedata: [],
                mode: 'new',
                onchange: { subchangedata },
                submitdata: { addsubData },
                showaction: false
                // conditionshow: { name: 'eventtype', value: 'replacement' }
            },
            { name: 'break2', title: '', fieldtype: 'break', class: 'md:col-12' },
            {
                name: 'createdAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                // title: 'Created At',
                labeltitle: 'Created At',
                fieldtype: 'label',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode == 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'updatedAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                // title: 'Updated At',
                labeltitle: 'Updated At',
                fieldtype: 'label',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode === 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            }
        ],
        actions: [
            {
                title: formmode != 'edit' ? 'Submit' : 'Save',
                disabled: formmode == 'view' ? true : false
            },
            {
                title: 'Cancel'
            }
        ],
        // fleettype: 'vehicle',
        getTable: listDxVehicleActivities,
        createTable: createDxVehicleActivity, //Pass create table name
        updateTable: updateDxVehicleActivity, //Pass update table name
        deleteTableItem: deleteDxVehicleActivity //Pass update table name
    };

    let qs = '';
    let qsa = '';
    let vid = '';
    let typ = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qsa += vehicleid != null ? '&vid=' + vehicleid : '';
    qsa += type != null ? '&type=' + type : '';
    qs = qsa != '' ? '?' + qsa : '';

    const wizardItems = [
        { label: 'Basic Details', command: () => navigate(`/manage/renewals/item/basic` + qs) },
        { label: 'View Documents', command: () => navigate(`/manage/renewals/item/viewdocuments` + qs), disabled: contract.documents !== null ? false : true },
        { label: 'Appointment', command: () => navigate('/manage/renewals/item/Appointment' + qs), disabled: Appointmentdata.length > 0 ? false : true }
    ];

    const changevehicledata = (props: any) => {
        setupdatedata(props);
    };

    const callchangedata = () => {
        if (vehicleid.includes(',')) {
            editScContract(updatedata, formid);
        } else {
            editnewvehicle(updatedata, formid);
        }
    };
    /**
     * addDocToDB() creates a Image S3 url and image related data in document table
     */
    const addDocToDB = async (files: any) => {
        let newcontract: any = await API.graphql({
            query: createDxDocument,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: files
            }
        });
    };
    /**
     editScContract() functions runs only if the two Service contract is created at a same time 
     and if the vid contains comma (,) 
     */
    const editScContract = async (data: any, fid: string) => {
        UploadDocument = [];
        setdisplayDialog(true);
        let doumentObj: any = {};
        let b = data;
        let splititem = Array.isArray(data.documents);
        let splitpicture = Array.isArray(data.picture);
        let splitrec = Array.isArray(data.receipts);
        if (splititem && data.documents.length > 0) {
            for (let i = 0; i < data.documents.length; i++) {
                doumentObj = new Object({ file: data.documents[i], type: 'documents', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.vehicleid}/documents/` });

                UploadDocument.push(doumentObj);
            }
        }
        if (splitpicture && data.picture.length > 0) {
            for (let i = 0; i < data.picture.length; i++) {
                doumentObj = new Object({ file: data.picture[i], type: 'picture', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.vehicleid}/picture/` });
                UploadDocument.push(doumentObj);
            }
        }

        if (splitrec && data.receipts.length > 0) {
            for (let i = 0; i < data.receipts.length; i++) {
                doumentObj = new Object({ file: data.receipts[i], type: 'receipts', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.vehicleid}/receipts/` });

                UploadDocument.push(doumentObj);
            }
        }

        await Promise.all(
            UploadDocument.map(async (item: any) => {
                await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                    item['fullpath'] = res.key;
                    const files = {
                        name: res.key,
                        // dxAllocationsPicturesId: id,
                        s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                        resource: 'Renewals',
                        resourceid: data.id,
                        presourceid: vehicleid,
                        presource: 'Vehicle',
                        field1: res.key.includes(`${vehicleid}/picture`) ? 'pictures' : res.key.includes(`${vehicleid}/documents`) ? 'documents' : res.key.includes(`${vehicleid}/receipts`) ? 'receipts' : ''
                    };
                    addDocToDB(files);
                });
            })
        ).then(async (res1: any) => {
            let Files: any = [];
            let docs: any = '';
            let picture: any = [];
            let pics: any = '';
            let receipts: any = [];
            let rec: any = '';
            UploadDocument.map((map: any) => {
                docs = map.type == 'documents' ? Files.push(map.fullpath) : '';
                pics = map.type == 'picture' ? picture.push(map.fullpath) : '';
                rec = map.type == 'receipts' ? receipts.push(map.fullpath) : '';
            });

            //Complete the appointment
            if (formmode !== 'edit') {
                if (prevcontract.length > 0) {
                    let updatepreviouscontract = {
                        vehicleid: prevcontract[0].vehicleid,
                        id: prevcontract[0].id,
                        renewalstatus: 'Completed',
                        _version: prevcontract[0]._version
                    };
                    let UpdateprevContractitem: any = await dxService.updateitem('renewal', updatepreviouscontract);
                }
            }

            if (contract.length > 0) {
                let contractdetails: any = contract[0];
                let credits = contractdetails && contractdetails['jfield'] ? JSON.parse(contractdetails['jfield']) : {};
                let cd: any = {};
                if (credits !== undefined) {
                    cd['credits'] = data.credits;
                }
                let updatecontract = {
                    id: contractdetails.id,
                    renewalno: data.renewalno,
                    issuedate: localetoisodatetime(data.issuedate),
                    expirydate: localetoisodatetime(data.expirydate),
                    vehicleid: data.vehicleid.toString(),
                    // documents: Files.toString() + ',' + picture.toString() + ',' + receipts.toString(),
                    renewalstatus: 'Active',
                    period: data.period,
                    scvendor: data.scvendor,
                    subtype: data.subtype,
                    _version: contractdetails._version,
                    jfield: JSON.stringify(cd)
                };

                let UpdateContractitem: any = await dxService.updateitem('renewal', updatecontract);
            }

            const storemultiarray: any = data.vehicleid;
            for (let i = 0; i < storemultiarray.length; i++) {
                let columns = `id,scno,scvendor,_version`;
                let vehicle: any = await dxService.getitembyid('vehicle', storemultiarray[i], columns);
                let vehicledata = vehicle;

                storeVehicleData.push(vehicledata);
            }
            for (let i = 0; i < storeVehicleData.length; i++) {
                let e: any = storeVehicleData[i];
                let updatevehicle = {
                    scno: data.renewalno !== null ? data.renewalno : '',
                    scvendor: data.scvendor !== null ? data.scvendor : '',
                    _version: e._version,
                    id: e.id
                };
                let uv: any = await dxService.updateitem('vehicle', updatevehicle);
            }
            for (let i = 0; i < storemultiarray.length; i++) {
                let filtervehicleactivity = {
                    id: {
                        eq: storemultiarray[i]
                    }
                };
                let vehicleactivity: any = await API.graphql({ query: listDxVehicleActivities, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filtervehicleactivity } });
                let vehicleactivitydata = vehicleactivity.data.listDxVehicleActivities.items[0];
                storeVehicleActivityData.push(vehicleactivitydata);
            }
            for (let i = 0; i < storeVehicleActivityData.length; i++) {
                let e: any = storeVehicleActivityData[i];
                let expiringvalue = e && e['expiring'] ? JSON.parse(e['expiring']) : {};
                let rt: any = {};
                rt[b['renewaltype']] = {
                    issueDate: localetoisodatetime(b['issuedate']),
                    expiryDate: localetoisodatetime(b['expirydate']),
                    // documents: Files.toString() + ',' + picture.toString() + ',' + receipts.toString(),
                    status: data.isappointmentrequire ? data.isappointmentrequire != true : 'Completed',
                    id: b['id']
                };

                e['expiring'] = expiringvalue;

                e['expiring'][b['renewaltype']] = '';
                e['expiring'][b['renewaltype']] = rt[b['renewaltype']];
                e['expiring'] = JSON.stringify(e['expiring']);

                if (Object.keys(e).includes('_lastChangedAt')) delete e['_lastChangedAt'];
                if (Object.keys(e).includes('createdAt')) delete e['createdAt'];
                if (Object.keys(e).includes('updatedAt')) delete e['updatedAt'];

                Object.keys(e).forEach((key) => {
                    if (e[key] == null || e[key] == '') {
                        delete e[key];
                    }
                });

                let updatevehicleObj = {
                    dxContractActivityId: e.dxContractActivityId,
                    id: e.id,
                    expiring: e['expiring'],
                    _version: e._version
                };
                let os1: any = await API.graphql({
                    query: formColumns.updateTable,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {
                        input: updatevehicleObj
                    }
                });
            }
            await AmplifyServices.getvehicleactivitydetails()
                .then(async (res: any) => {
                    setTimeout(() => {
                        window.history.back();
                    }, 2000);
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    };
    /**
     * Filterappointment() filter the appointment and store
     */
    const Filterappointment = async (nextToken?: any) => {
        let variables: any = {
            dxContractAppointmentsId: {
                eq: formid
            }
        };
        let Finedata = await dxService.listitems('appointment', '*', variables, true, 1000, nextToken);
        let response = Finedata.items;

        let nexttoken = Finedata.nextToken;
        if (nexttoken) {
            response = [...response, ...(await Filterappointment(nexttoken))];

            // response = response.concat(nextResults);
        }

        return response;
    };
    /**
     * editnewvehicle() works for edit and also for closing the renewal
     */
    const editnewvehicle = async (data: any, fid: string) => {
        UploadDocument = [];
        setdisplayDialog(true);
        let doumentObj: any = {};
        /** 
         * check if the form has any documents,pictures,receipts, if exist
     then store the necessary details of the documents,pictures,receipts in an array.
         */
        let splititem = Array.isArray(data.documents);
        let splitpicture = Array.isArray(data.picture);
        let splitrec = Array.isArray(data.receipts);
        if (splititem && data.documents.length > 0) {
            for (let i = 0; i < data.documents.length; i++) {
                doumentObj = new Object({ file: data.documents[i], type: 'documents', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.vehicleid}/documents/` });

                UploadDocument.push(doumentObj);
            }
        }
        if (splitpicture && data.picture.length > 0) {
            for (let i = 0; i < data.picture.length; i++) {
                doumentObj = new Object({ file: data.picture[i], type: 'picture', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.vehicleid}/picture/` });

                UploadDocument.push(doumentObj);
            }
        }

        if (splitrec && data.receipts.length > 0) {
            for (let i = 0; i < data.receipts.length; i++) {
                doumentObj = new Object({ file: data.receipts[i], type: 'receipts', path: `${data.renewaltype}/${new Date().getFullYear()}/${data.vehicleid}/receipts/` });

                UploadDocument.push(doumentObj);
            }
        }
        await Promise.all(
            UploadDocument.map(async (item: any) => {
                /** * In the success of the creating new contract upload all the documents in a S3 bucketand 
     also create a details of the documents in a Document table */
                await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                    item['fullpath'] = res.key;
                    const files = {
                        name: res.key,
                        // dxAllocationsPicturesId: id,
                        s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                        resource: 'Renewals',
                        resourceid: data.id,
                        presourceid: vehicleid,
                        presource: 'Vehicle',
                        field1: res.key.includes(`${vehicleid}/picture`) ? 'pictures' : res.key.includes(`${vehicleid}/documents`) ? 'documents' : res.key.includes(`${vehicleid}/receipts`) ? 'receipts' : ''
                    };
                    addDocToDB(files);
                });
            })
        ).then(async (res1: any) => {
            let Files: any = [];
            let docs: any = '';
            let picture: any = [];
            let pics: any = '';
            let receipts: any = [];
            let rec: any = '';
            UploadDocument.map((map: any) => {
                docs = map.type == 'documents' ? Files.push(map.fullpath) : '';
                pics = map.type == 'picture' ? picture.push(map.fullpath) : '';
                rec = map.type == 'receipts' ? receipts.push(map.fullpath) : '';
            });

            //Complete the appointment
            /**
             * if the formmode not equal to edit then update the appointment data and 
             previous contract data
             */
            if (formmode !== 'edit') {
                if (Appointdetails) {
                    Filterappointment().then(async (res: any) => {
                        if (res.length > 0) {
                            let appointmentdetails = res[0];
                            // appointmentdetails['appointmentstatus'] = 'Completed';
                            let updateappointment = {
                                // appointmentstatus: 'Completed',
                                id: appointmentdetails.id,
                                _version: appointmentdetails._version
                            };
                            let appointment: any = await dxService.updateitem('appointment', updateappointment);
                        }
                    });
                }

                if (prevcontract.length > 0) {
                    let updatepreviouscontract = {
                        vehicleid: prevcontract[0].vehicleid,
                        id: prevcontract[0].id,
                        renewalstatus: 'Completed',
                        _version: prevcontract[0]._version
                    };

                    let UpdateprevContractitem: any = await dxService.updateitem('renewal', updatepreviouscontract);
                }
            }
            let b = data;
            if (contract.length > 0) {
                let contractdetails: any = contract[0];
                let credits = contractdetails && contractdetails['jfield'] ? JSON.parse(contractdetails['jfield']) : {};
                let cd: any = {};
                if (credits !== undefined) {
                    cd['credits'] = data.credits;
                }
                let updatecontract = {
                    id: contractdetails.id,
                    renewalno: data.renewalno,
                    issuedate: localetoisodatetime(data.issuedate),
                    expirydate: localetoisodatetime(data.expirydate),
                    vehicleid: data.vehicleid,
                    // documents: Files.toString() + ',' + picture.toString() + ',' + receipts.toString(),
                    renewalstatus: formmode !== 'edit' ? 'Active' : data.renewalstatus,
                    period: data.period,
                    scvendor: data.scvendor,
                    subtype: data.subtype,
                    totalamount: data.totalamount,
                    _version: contractdetails._version,
                    jfield: JSON.stringify(cd)
                };

                let UpdateContractitem: any = await dxService.updateitem('renewal', updatecontract);
            }

            //get and update the vehicle table
            let filterv = {
                id: {
                    eq: data.vehicleid
                }
            };
            await getNextQueryData('', filterv, 'vehicle', true).then(async (res: any) => {
                vehicleitems = res[0];
                if (data.renewaltype !== null && data.renewaltype == 'Registration') {
                    let Updatevehicle = { id: data.vehicleid, registrationno: data.renewalno, startdate: localetoisodatetime(data.issuedate), enddate: localetoisodatetime(data.expirydate), _version: vehicleitems._version };
                    //updateDxVehicle
                    let os: any = await dxService.updateitem('vehicle', Updatevehicle);
                } else if (data.renewaltype !== null && data.renewaltype == 'Insurance') {
                    let Updatevehicle = { id: data.vehicleid, insuranceno: data.renewalno, _version: vehicleitems._version };
                    //updateDxVehicle
                    let os: any = await dxService.updateitem('vehicle', Updatevehicle);
                }
            });

            //get and update the vehicleactivity table
            let filtervehicleactivity = {
                id: {
                    eq: vehicleid
                }
            };

            await getNextQueryData('', filtervehicleactivity, 'vehicleactivity', true).then(async (resp: any) => {
                let e: any = resp[0];
                let expiringvalue = e && e['expiring'] ? JSON.parse(e['expiring']) : {};
                let rt: any = {};
                rt[b['renewaltype']] = {
                    issueDate: localetoisodatetime(b['issuedate']),
                    expiryDate: localetoisodatetime(b['expirydate']),
                    documents: Files.toString() + ',' + picture.toString() + ',' + receipts.toString(),
                    status: 'Completed',
                    id: formid
                };

                e['expiring'] = expiringvalue;
                e['expiring'][b['renewaltype']] = '';
                e['expiring'][b['renewaltype']] = rt[b['renewaltype']];
                e['expiring'] = JSON.stringify(e['expiring']);

                let updatevehicleactivity = { expiring: e.expiring, id: e.id, _version: e._version };

                let Uva: any = await dxService.updateitem('vehicleactivity', updatevehicleactivity);
            });
            await AmplifyServices.getvehicleactivitydetails()
                .then(async (res: any) => {
                    setTimeout(() => {
                        window.history.back();
                    }, 1000);
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    };

    const actionformatDateTemplate = (rowData: any) => {
        return <>{rowData && moment(rowData.Date).format('DD-MMM-YYYY')}</>;
    };

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-12">
                    <h4 className="container-header">
                        {formmode != 'Close' && formmode !== 'view'
                            ? `Edit Renewal: ${listFormData.vehicleid} (${listFormData.renewalstatus})`
                            : formmode === 'view'
                            ? `View Renewal: ${listFormData.vehicleid} (${listFormData.renewalstatus})`
                            : `Close Renewal: ${listFormData.vehicleid} (${listFormData.renewalstatus})`}
                    </h4>

                    <div className="card container-card">
                        <Toast ref={toast} />

                        <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                        <Routes>
                            <Route
                                path={'/basic'}
                                element={
                                    <>
                                        <FormTemplate
                                            columns={formColumns}
                                            // Fleettype={formColumns.fleettype}
                                            layout="grid2"
                                            getTableName="listDxVehicleActivities"
                                            // title={`New Contract ${vehicleID !== '' ? ' - ' : ''}`}
                                            data={listFormData}
                                            // submitForm={submitForm}
                                            onChange={changevehicledata}
                                            isAppointmentNeeded={setAppointmentNeeded}
                                            bucketname="RenewalDocuments"
                                            formName="serviceContract"
                                            getVehicleID={setVehicleID}
                                            showaction={true}
                                            submitForm={callchangedata}
                                        />
                                        <Loader displayDialog={displayDialog} />
                                    </>
                                }
                            />
                            <Route
                                path={'/viewdocuments'}
                                element={
                                    <>
                                        <AllDocumentsView />
                                    </>
                                }
                            />
                            <Route
                                path={'/Appointment'}
                                element={
                                    <>
                                        <DataTable
                                            value={Appointmentdata}
                                            dataKey="id"
                                            rowsPerPageOptions={[5, 10, 25]}
                                            className="datatable-responsive"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} contracts"
                                            emptyMessage="No Documents added."
                                            responsiveLayout="scroll"
                                        >
                                            <Column field="place" header="Place" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                                            <Column header="Date" body={actionformatDateTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            <Column field="appointmentstatus" header="Status" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            <Column field="description" header="Description" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                        </DataTable>
                                    </>
                                }
                            />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DocumentnewForm;
