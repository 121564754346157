import { API } from 'aws-amplify';

import { useLocation, useNavigate } from 'react-router-dom';
import { listDxDocuments } from '../../graphql/queries';
import { Storage } from 'aws-amplify';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { DataView } from 'primereact/dataview';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import excel from '../../assets/excel.jpg';
import Pdf from '../../assets/Pdf.jpg';
import ppt from '../../assets/ppt.jpg';
import word from '../../assets/word.jpg';
import rtf from '../../assets/rtf.jpg';
import '../Vehicles/Vehicleform.css';
import { Dropdown } from 'primereact/dropdown';
import dxService from '../../Services/dxService';
import { Toast } from 'primereact/toast';
import { getNextQueryData } from '../../utils/helper';
import { Link } from 'react-router-dom';

const imageExtensions = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp', 'bmp', 'ico', 'cur'];

const AllDocumentsView = (props: any) => {
    const location = useLocation();
    const ViewOptions = [
        { name: 'Box View', value: 'boxview' },
        { name: 'Table View', value: 'tableview' }
    ];
    const navigate = useNavigate();
    const [displayDocuments, setDisplayDocuments] = useState([]);
    const [documentView, setdocumentView] = useState<any>(null);
    const toast = useRef<any>(null);
    const urlParams = new URLSearchParams(location.search);
    var type = urlParams.has('type') ? urlParams.get('type') : null;
    // var allid = urlParams.has('id') ? urlParams.get('id') : null;
    var vid = urlParams.has('id') ? urlParams.get('id') : null;
    useEffect(() => {
        getDxDocs();
    }, []);
    // console.log(vid, props.type);
    const getDxDocs = async (nextToken?: any) => {
        // console.log(type);

        // let variables: any = {};
        let variables: any = {
            presourceid: {
                contains: vid
            }
            // name: {
            //     contains: type
            // }
            // field1: {
            //     ne: ''
            // }
        };
        if (nextToken) {
            variables.nextToken = nextToken;
        }

        let Documents = await dxService.listitems('document', '*', variables, false, 1000, nextToken);
        //   let response = Documents.items;

        let res = Documents.items;
        let nexttoken = Documents.nextToken;
        if (nexttoken) {
            const nextResults = await getDxDocs(nexttoken);
            res = res.concat(nextResults);
        }
        res = res.filter((item: any) => item._deleted !== true);
        // console.log(res);

        getFileLocation(res);
        return res;
    };
    const Navigation = () => navigate(`/manage/vehicles/item/allocations?id=${vid}&mode=view`);
    const getFileLocation = async (e: any) => {
        // console.log(e);

        let FinalData: any = [];

        for (let i = 0; i < e.length; i++) {
            let url = await Storage.get(e[i].name);
            // console.log(url);
            // console.log(e[i]);
            FinalData.push({
                name: e[i].name.split('/').pop(),
                documents: url,
                path: e[i].name,
                renewaltype: e[i].name.split('/')[0],
                doctype: e[i].field1,
                rid: e[i].resourceid,
                vid: e[i].presourceid,
                id: e[i].id
            });
            // console.log(url);
        }
        let filterfinaaldata: any = [];
        let removeduplicates: any = [];
        filterfinaaldata = FinalData.filter((fil: any, index: any) => fil.name !== '');

        removeduplicates = filterfinaaldata.filter((item: any, index: any) => filterfinaaldata.indexOf(item) === index);

        setDisplayDocuments(removeduplicates);
    };
    const AttachmentBodyTemplate = (e: any) => {
        return (
            <a href={e.documents} target="_self" className="">
                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
            </a>
        );
    };

    const itemTemplate = (product: any) => {
        // console.log(product);
        let extension = product.name.split('.').pop();
        // console.log(extension);
        let imagesrc =
            extension == 'pdf' ? Pdf : extension == 'xlsx' ? excel : extension == 'rtf' ? rtf : extension == 'pptx' ? ppt : extension == 'docx' ? word : `https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/${product.path}`;
        // console.log(imagesrc);
        return (
            <>
                <div className="col-12 sm:col-4 lg:col-4 xl:col-3 p-2">
                    <div className="p-4 border-1 surface-border surface-card border-round">
                        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                            <div className="flex align-items-center gap-2">
                                <i className="pi pi-tag"></i>
                                <span className="font-semibold">
                                    {product.renewaltype} -{product.doctype}
                                </span>
                            </div>
                            {imageExtensions.includes(extension) && (
                                <Link to={`/general/viewdocument?id=${product.id}&appid=${vid}&parent=driver`}>
                                    <i className="pi pi-eye" style={{ marginLeft: '7px', color: 'blue' }}></i>
                                </Link>
                            )}
                            <a href={product.documents} target="_self" className="">
                                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
                            </a>
                            {/* <Tag value={product.inventoryStatus}></Tag> */}
                        </div>
                        <div className="flex flex-column align-items-center gap-3 py-5">
                            {/* <img className="w-9 shadow-2 border-round" src={`https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/${product.path}`} alt={product.name} /> */}
                            <img className="w-9  border-round" src={product.documents} style={{ height: '150px', objectFit: 'contain' }} />
                            <div className="text-l font-bold DocTitle" title={product.name}>
                                {product.name}
                            </div>

                            {/* <Rating value={product.rating}></Rating> */}
                        </div>
                        <Button
                            icon="pi pi-trash"
                            className="p-button-warning"
                            onClick={(e) => {
                                getdeletedfilter(product);
                            }}
                            style={{ marginRight: '.5em' }}
                        />
                    </div>
                </div>
            </>
        );
    };
    // console.log(displayDocuments);
    const nameBodyTemplate = (rowData: any) => {
        let splitname = rowData.name.split('.');
        return <>{rowData.name}</>;
    };
    const downloadBodyTemplate = (rowData: any) => {
        return (
            <>
                {' '}
                <a href={rowData.documents} target="_self" className="">
                    <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
                </a>
            </>
        );
    };
    const getdeletedfilter = async (data?: any, nextToken?: any) => {
        // console.log(data);
        let variables: any = {
            id: {
                eq: data.id
            },
            resourceid: {
                eq: data.rid
            }
        };
        let driverdoc = await getNextQueryData('', variables, 'document').then((item: any) => {
            // console.log(item);
            deletedoc(item);
        });
    };
    const deletedoc = async (data: any) => {
        // console.log(data);
        await Storage.remove(data[0].name).then(async (res) => {
            let deletedoc = await dxService.deleteitem('document', data[0].id, data[0]._version).then((success) => {
                toast.current.show({
                    severity: 'success',
                    summary: 'File deleted Succesfully',
                    detail: '',
                    life: 3000
                });
                getDxDocs();
            });
        });
    };
    const deleteBodyTemplate = (rowData: any) => {
        return (
            <>
                <Button
                    icon="pi pi-trash"
                    className="p-button-warning"
                    onClick={(e) => {
                        getdeletedfilter(rowData);
                    }}
                    style={{ marginRight: '.5em' }}
                />
            </>
        );
    };
    // console.log(documentView);

    return (
        <>
            {' '}
            <div className="grid">
                <div className="col-12 md:col-12">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                        {' '}
                        <div>
                            {' '}
                            <h4 className="container-header">View Documents</h4>
                        </div>
                        <Toast ref={toast} />
                        <div>
                            {' '}
                            <Dropdown value={documentView} onChange={(e) => setdocumentView(e.value)} options={ViewOptions} optionLabel="name" placeholder="Select a View" className="w-full md:w-14rem" />
                        </div>
                    </div>

                    <div className="card">
                        {documentView == 'boxview' ? (
                            <DataView value={displayDocuments} itemTemplate={itemTemplate} paginator rows={8} />
                        ) : (
                            <DataTable value={displayDocuments} paginator rows={10}>
                                <Column field="Document Name" headerStyle={{ textAlign: 'left' }} bodyStyle={{ textAlign: 'left' }} filter header="Document Name" body={nameBodyTemplate}></Column>
                                <Column field="doctype" headerStyle={{ textAlign: 'left' }} bodyStyle={{ textAlign: 'left' }} filter header="Document Type"></Column>
                                {/* <Column field="renewaltype" filter header="Renewal Type"></Column> */}
                                <Column field="category" headerStyle={{ textAlign: 'left' }} bodyStyle={{ textAlign: 'left' }} header="Download Document" body={downloadBodyTemplate}></Column>
                                <Column field="delete" headerStyle={{ textAlign: 'left' }} bodyStyle={{ textAlign: 'left' }} header="Delete" body={deleteBodyTemplate}></Column>
                            </DataTable>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default AllDocumentsView;
