import { API, Auth, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import styles from './UserRoles.module.scss';
import { DataTable } from 'primereact/datatable';
import { Table, TableCell, TableBody, TableHead, TableRow, TextField } from '@aws-amplify/ui-react';
import { createDxRoles, createDxUserRoles } from '../../graphql/mutations'
import { listDxRoles, getDxUserRoles, listDxUserRoles, getDxRoles } from '../../graphql/queries';
import { Column, ColumnProps } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import awsconfig from '../../aws-exports';
import { CognitoIdentityProviderClient, ListUsersCommand } from '@aws-sdk/client-cognito-identity-provider';
import FleetAdmin from '../Roles/GroupDetails';
let deleteGroupsName: any[] = [];
let AddtoGroupsName: any[] = [];
function UserRoles() {
    const [awsUser, setAwsUsers] = useState([]);
    const [showForm, setShowForm] = useState(false);
    //userName is for New input field
    const [userName, setUserName] = useState('');
    //user is for existing user name
    const [user, setUser] = useState('');
    const [selectedDropDown, setSelectedDropDown] = useState('');

    const [data, setData] = useState([]);
    const [groups, setGroups] = useState([]);
    const [roleName, setRoleName] = useState([]);
    useEffect(() => {
        getdata();
        getAllUsers();
    }, []);
    useEffect(() => {
        setUser(userName);
    }, [userName]);
    const getdata = async () => {
        //Who is going to add the users in the table?

        // let o = await API.graphql(
        //     graphqlOperation(createDxRoles, {
        //         input: { rolename: 'Fleet Member', fleettable: 'Fleet Member', fleetdocs: 'Fleet Docs Member', vehicle: 'Vehicles Member', vehicledocs: 'Vehicle Document Member', driver: 'Driver Member', appointments: '' }
        //     })
        // );
        // let o = await API.graphql(
        //     graphqlOperation(createDxUserRoles, {
        //         input: { username: 'ktskumar@gmail.com', userrole: 'Fleet Administrator' }
        //     })
        // );

        //Create a form for adding datas into the tables
        //

        // console.log(o);
        let Roles: any = await API.graphql({ query: listDxRoles, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        let Group = Roles.data.listDxRoles.items;

        setGroups(Group);
        let name = Roles.data.listDxRoles.items.map((role: any) => role.rolename);
        // console.log(name);
        setRoleName(name);
        let UserRoles: any = await API.graphql({ query: listDxUserRoles, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        let userRole = UserRoles.data.listDxUserRoles.items;
        setData(userRole);
        console.log(userRole);
    };
    const getAllUsers = async () => {
        const REGION = awsconfig.aws_appsync_region;
        const usercredential = await Auth.currentUserCredentials();
        const client = new CognitoIdentityProviderClient({ region: REGION, credentials: usercredential });
        const command = new ListUsersCommand({ UserPoolId: 'ap-south-1_RmUExvWI7' });
        const response: any = await client.send(command);
        console.log(response.Users);

        setAwsUsers(response.Users);
    };

    const getSelectedValues = (e: any, newuser: any) => {
        newuser.includes('#new') && setSelectedDropDown(e.target.value);
        let AddtoGroups = groups.filter((role: any) => role.rolename === e.target.value);
        let DeleteGroups = groups.filter((role: any) => role.rolename !== e.target.value);
        deleteGroups(DeleteGroups);
        AddGroups(AddtoGroups);
        newuser.includes('#new') ? setUser(newuser.split('#')[0]) : setUser(newuser);
    };
    const deleteGroups = (groups: any[]) => {
        deleteGroupsName = [];
        let driver = groups.map((role: any) => role.driver).toString();
        let fleetdocs = groups.map((role: any) => role.fleetdocs).toString();
        let fleettable = groups.map((role: any) => role.fleettable).toString();
        let vehicle = groups.map((role: any) => role.vehicle).toString();
        let vehicledocs = groups.map((role: any) => role.vehicledocs).toString();
        deleteGroupsName.push(driver, fleetdocs, fleettable, vehicle, vehicledocs);
    };
    const AddGroups = (groups: any[]) => {
        AddtoGroupsName = [];
        let driver = groups.map((role: any) => role.driver).toString();
        let fleetdocs = groups.map((role: any) => role.fleetdocs).toString();
        let fleettable = groups.map((role: any) => role.fleettable).toString();
        let vehicle = groups.map((role: any) => role.vehicle).toString();
        let vehicledocs = groups.map((role: any) => role.vehicledocs).toString();
        AddtoGroupsName.push(driver, fleetdocs, fleettable, vehicle, vehicledocs);

        //aws amplify react component / ultima react

        // Promise.all(AddtoGroupsName.map((groupName) => Auth.adminAddUserToGroup({ groupName, userPoolId: 'ap-south-1_RmUExvWI7', username: user })))
        //     .then((data) => {
        //         console.log(`Added user ${user} to groups ${AddtoGroupsName.join(', ')}`);
        //     })
        //     .catch((err) => console.error(err));
    };
    const saveData = () => {
        if (user == '' || deleteGroupsName.length == 0 || AddtoGroupsName.length == 0) {
            alert('Denied');
        } else {
            let users = awsUser
                .map((user: any) => user.Attributes)
                .flat()
                .filter((name) => name.Name == 'email' || name.Name == 'preferred_username')
                .filter((val) => val.Value.toLowerCase() == userName.toLowerCase() || val.Value.toLowerCase() == user.toLowerCase());
            let fusers = users;
            // console.log(fusers);
            if (fusers.length > 0) {
                //Filter out the exact name to get the username
                let fil = awsUser.filter((val: any) => {
                    if (val.Username == 'google_106274279633078197130') {
                        console.log(val.Attributes.filter((name: any) => name.Name == 'email' || name.Name == 'preferred_username'));
                        return val;
                    }
                });
                console.log(fil);
            }
            console.log('Delete', user, 'from', deleteGroupsName);
            console.log('Add', user, 'to', AddtoGroupsName);
            console.log('Saved');
            setUser('');
            setUserName('');
            setSelectedDropDown('');
            AddtoGroupsName = [];
            deleteGroupsName = [];
        }
    };

    const userNameColumn = (data: any) => {
        return data.username;
    };
    const roleNameColumn = (data: any) => {
        return (
            <>
                <select id={data.username} onChange={(e) => getSelectedValues(e, data.username)} className={styles.options}>
                    <option value="select" disabled>
                        select
                    </option>
                    {roleName.map((item, key) => {
                        let select = data.userrole == item ? true : false;
                        return (
                            <option value={item} selected={select} key={key}>
                                {item}
                            </option>
                        );
                    })}
                </select>
            </>
        );
    };
    let headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="User Name" />
                <Column header="Role" />
                <Column header="Action" />
            </Row>
        </ColumnGroup>
    );

    return (
        <>
            <Button onClick={() => setShowForm(!showForm)}>{!showForm ? 'Add User' : 'Close'}</Button>
            {showForm && (
                <div className="card">
                    <h5>Add User to Groups</h5>
                    <div className="grid p-fluid">
                        <div className="col-12 md:col-5">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>

                                <InputText placeholder="Username" value={userName} onChange={(e) => setUserName(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-6 md:col-5">
                            <div className="p-inputgroup" style={{ height: '100%' }}>
                                <select value={selectedDropDown} onChange={(e) => getSelectedValues(e, userName + '#new')} style={{ width: '100%' }}>
                                    <option value="select">select</option>
                                    {roleName.map((item, key) => {
                                        let disabled = userName !== '' ? false : true;
                                        return (
                                            <option value={item} key={key} disabled={disabled}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-6 md:col-2">
                            <div className="p-inputgroup">
                                <Button onClick={saveData}>Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <DataTable value={data} filterDisplay="menu" paginator className="p-datatable-customers" showGridlines rows={10} dataKey="id" responsiveLayout="scroll" headerColumnGroup={headerGroup}>
                <Column field="UserName" body={userNameColumn} />
                <Column field="Role" body={roleNameColumn} />
                <Column
                    field="Action"
                    body={
                        <>
                            <Button onClick={saveData}>Save</Button>
                        </>
                    }
                />
            </DataTable>
            {/* <TextField size="large" descriptiveText="Enter user Email" placeholder="info@example.com" label="" errorMessage="There is an error" labelHidden /> */}
            {/* <Table caption="" highlightOnHover={true}>
                <TableHead>
                    <TableRow>
                        <TableCell as="th">User Name</TableCell>
                        <TableCell as="th">Role</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((user: any, key) => {
                        return (
                            <TableRow>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>
                                    <select id={user.username} onChange={(e) => getSelectedValues(e, user.username)}>
                                        <option value="select" disabled>
                                            select
                                        </option>
                                        {roleName.map((item, key) => {
                                            let select = user.userrole == item ? true : false;
                                            return (
                                                <option value={item} selected={select} key={key}>
                                                    {item}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table> */}
        </>
    );
}

export default UserRoles;
