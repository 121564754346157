import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FormTemplate from '../../../Components/FormTemplate';
import dxService from '../../../Services/dxService';
import { Auth, Storage } from 'aws-amplify';
import { updateUserName } from '../../../utils/userhelper';
import { AuthContext } from '../../../AuthContext';
import { getcurrenruserprofile } from '../../../utils/helper';

const UserProfile = () => {
    const { dispatch } = useContext(AuthContext);
    const toast = useRef(null);
    const newtoast = useRef<any>();
    const [listFormData, setlistFormData] = useState<any>({});
    const location1 = useLocation();
    const urlParams = new URLSearchParams(location1.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : '';
    var formID = urlParams.has('id') ? urlParams.get('id') : '';

    useEffect(() => {
        getdata();
    }, []);

    const getdata = async () => {
        if (formID != '') {
            getcurrenruserprofile().then(async (res: any) => {
                await Storage.get(res.profile).then((img) => {
                    res.profile = img;
                    setlistFormData(res);
                });
            });
            // let item = await dxService.getitembyid('userprofile', formID);
            // setlistFormData(item);
        }
    };

    const formcolumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'displayname',
                title: 'Display Name',
                fieldtype: 'text',
                required: true,
                value: listFormData && listFormData['displayname'] ? listFormData.displayname : '',
                hidden: false,
                disabled: formmode == 'close' ? true : false,
                //disabled: false,
                data: '',
                class: 'md:col-12'
            },
            {
                name: 'email',
                title: 'Email',
                fieldtype: 'text',
                required: true,
                value: listFormData && listFormData.email ? listFormData.email : '',
                hidden: false,
                disabled: true,
                //disabled: false,
                data: '',
                class: 'md:col-6'
            },
            {
                name: 'profile',
                title: 'Avatar',
                fieldtype: 'upload',
                required: false,
                value: listFormData && listFormData.profile ? listFormData.profile : '',
                hidden: false,
                disabled: formmode == 'close' ? true : false,
                //disabled: false,
                data: '',
                class: 'md:col-6'
            }
        ],
        actions: [{ title: 'Submit' }, { title: 'Cancel' }]
    };

    const changedata = (props: any) => {};
    const addData = async (props: any) => {
        let pictureurl = props.profile;
        let url;
        if (props.profile && props.profile.change === 1) {
            let picturesfile = props.profile.file[0].file ? props.profile.file[0].file : props.profile.file[0];
            pictureurl = props.profile.url;
            var StoreDocuments = await Storage.put('profiles/Pictures/' + formID + picturesfile.name, picturesfile).then((res: any) => {
                return res;
            });
            // url = await Storage.get(StoreDocuments.key);
            url = StoreDocuments.key;
        }
        // aws table
        let profilevalue = {
            id: formID,
            displayname: props.displayname,
            picture: pictureurl,
            _version: props._version
        };
        //aws main profile
        let userupdateproperties: any = {
            preferred_username: props.displayname
        };

        if (url) {
            userupdateproperties.picture = url.split('?')[0];
            userupdateproperties.profile = url.split('?')[0];
        }

        let profileupdate = await dxService.updateitem('userprofile', profilevalue);
        let updateawsprofile = await updateUserName(userupdateproperties);
        dispatch({ type: 'PROFILEUPDATE', payload: 'Updated' + new Date() });

        if (updateawsprofile != null) {
            newtoast.current.show({
                severity: 'success',
                summary: 'UserProfile is updated successfully!',
                detail: props.displayname + ' updated successfully',
                life: 3000
            });
        }
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Profile</h4>

                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}
                            {/* <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                <div className="surface-ground"> */}
                            <div className="grid">
                                <div className="col-12 md:col-12">
                                    <div className="card container-card">
                                        <FormTemplate
                                            id="newform"
                                            columns={formcolumns}
                                            data={listFormData}
                                            showaction={true}
                                            mode={formmode}
                                            onChange={changedata}
                                            ChangeBtn={'OpenDocument'}
                                            layout="grid2"
                                            title="New Approval"
                                            submitForm={addData}
                                            showtopbutton={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* </div></div> */}
                            <Toast ref={newtoast} />
                            {/* END Inner COMPONENT HTML */}
                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}
            </div>
        </div>
    );
};
export default UserProfile;
