import React from 'react';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import {  createDxGeneralConfig, createDxVehicle } from '../../graphql/mutations';
import awsconfig from '../../aws-exports.js';
import { Button } from 'primereact/button';
import {} from '../../graphql/queries';
import {} from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';

//Amplify.configure(awsconfig);
var id;
const todo = { name: 'My first todo', description: 'Hello world!' };

const TodoPage = () => {
    // const allTodos = await(
    //     API.graphql({ query: queries.getTest2Vehicle }) as Promise<getTest2Vehicle>
    //   );

    const handleClick = async (p: any) => {
        /* create a todo */
        // let a = await API.graphql(graphqlOperation(createTodo, { input: todo }));
        // console.log(a);
        // const newTodo = await API.graphql({
        //     query: createTodo,
        //     variables: {
        //         input: {
        //         "name": "Lorem ipsum dolor sit amet",
        //         "description": "Lorem ipsum dolor sit amet",
        //         "priority":"1"
        //     }
        //     }
        // });
        // console.log(newTodo);
        // switch (p) {
        //     case 'st':
        //         let stodoid = '2';
        //         const stval = await API.graphql(
        //             graphqlOperation(createShanthaTodo, {
        //                 input: {
        //                     todoid: stodoid,
        //                     name: 'First Name Shanthakumar',
        //                     inventoryID: 'yeezyboostss'
        //                 }
        //             })
        //         );
        //         console.log(stval);
        //         break;
        //     case 't':
        //         let todoId = 'b32d1928-d275-4563-8b06-5a8115b68e72';
        //         const a = await API.graphql(
        //             graphqlOperation(updateTodo, {
        //                 input: { id: todoId, name: 'Updated todo info 2' }
        //             })
        //         );
        //         console.log(a);
        //         break;
        //     case 'w':
        //         const b = await API.graphql(graphqlOperation(createWarehouse, { input: { id: '2' } }));
        //         console.log(b);
        //         break;
        //     case 'e':
        //         const c = await API.graphql(
        //             graphqlOperation(createEmployee, {
        //                 input: {
        //                     id: 'amanda',
        //                     name: 'Amanda',
        //                     startDate: '2018-05-22',
        //                     phoneNumber: '6015555555',
        //                     warehouseID: '1',
        //                     jobTitle: 'Manager',
        //                     newHire: 'true'
        //                 }
        //             })
        //         );
        //         console.log(c);
        //         break;
        //     case 'ac':
        //         const ac = await API.graphql(
        //             graphqlOperation(createAccountRepresentative, {
        //                 input: {
        //                     id: 'dabit',
        //                     orderTotal: 400000,
        //                     salesPeriod: 'January 2019'
        //                 }
        //             })
        //         );
        //         console.log(ac);
        //         break;
        //     case 'c':
        //         let cus = await API.graphql(
        //             graphqlOperation(createCustomer, {
        //                 input: {
        //                     id: 'jennifer_thomas',
        //                     accountRepresentativeID: 'dabit',
        //                     name: 'Jennifer Thomas',
        //                     phoneNumber: '+16015555555'
        //                 }
        //             })
        //         );
        //         console.log(cus);
        //         break;
        //     case 'p':
        //         let p = await API.graphql(
        //             graphqlOperation(createProduct, {
        //                 input: {
        //                     id: 'yeezyboost',
        //                     name: 'Yeezy Boost'
        //                 }
        //             })
        //         );
        //         console.log(p);
        //         break;
        //     case 'i':
        //         let i = await API.graphql(
        //             graphqlOperation(createInventory, {
        //                 input: {
        //                     productID: 'yeezyboost',
        //                     warehouseID: '1',
        //                     inventoryAmount: 300
        //                 }
        //             })
        //         );
        //         console.log(i);
        //         break;
        //     case 'o':
        //         let o = await API.graphql(
        //             graphqlOperation(createOrder, {
        //                 input: {
        //                     amount: 300,
        //                     date: '2018-07-12',
        //                     status: 'pending',
        //                     accountRepresentativeID: 'dabit',
        //                     customerID: 'jennifer_thomas',
        //                     productID: 'yeezyboost'
        //                 }
        //             })
        //         );
        //         console.log(o);
        //         break;
        //     case 'Test2Vehicle':
        //         let Test2Vehicle = await API.graphql(
        //             graphqlOperation(createTest2Vehicle, {
        //                 input: {
        //                     id: 1,
        //                     plateno: '1211324',
        //                     make: 'Renault',
        //                     model: 'EK2R',
        //                     year: '2022',
        //                     department: 'Vehicle',
        //                     capacity: '50l',
        //                     chasisno: '2323'
        //                 }
        //             })
        //         );
        //         console.log(Test2Vehicle);
        //         break;
        //     case 'Test2Registration':
        //         let Test2Registration = await API.graphql(
        //             graphqlOperation(createTest2Registrations, {
        //                 input: {
        //                     id: 1,
        //                     type: 'Renewal',
        //                     vehicleId: 'W 68465',
        //                     year: '2022',
        //                     policyno: '20',
        //                     startdate: '2018-07-12',
        //                     enddate: '2018-08-12',
        //                     company: 'Deluxe Homes'
        //                 }
        //             })
        //         );
        //         console.log(Test2Registration);
        //         break;
        // }
    };

    const adddata = async () => {
        let varcompany = {
            id: '1',
            fleettype: 'Company',
            name: 'Deluxe Homes',
            description: 'Deluxe Homes company',
            version: '1',
            isnew: '1'
        };

        let vardept = {
            id: '3',
            fleettype: 'Department',
            name: 'Maintenance',
            description: 'description for maintenance',
            businessunit: '1',
            version: '1',
            isnew: '1'
        };

        let vervehicle = {
            id: 'Y51520',
            fleettype: 'vehicle',
            platecode: 'Y',
            plateno: '51520',
            make: 'Nissan',
            model: 'Sunny',
            year: '2022',
            departmentid: '3',
            businessunitid: '1',
            capacity: '1 + 4 Passenger',
            chasisno: 'MDHBN7AD9NG152877',
            isnew: true,
            version: 1
        };

        let veroldvehicleinsurance = {
            id: 'Y51520',
            fleettype: 'vehicle#insurance#2021',
            policyno: '3000134123',
            startdate: '2021-09-01',
            enddate: '2022-09-01',
            company: 'Gulf Insurance Group',
            isnew: false,
            version: 2
        };

        let vervehicleinsurance = {
            id: 'Y51520',
            fleettype: 'vehicle#insurance#2022',
            policyno: '3000134388',
            startdate: '2022-09-01',
            enddate: '2023-09-01',
            company: 'Gulf Insurance Group',
            isnew: true,
            version: 1
        };

        let vervehicleiregistration = {
            id: 'Y51520',
            fleettype: 'vehicle#registration#2022',
            policyno: '111111',
            startdate: '2023-08-01',
            enddate: '2023-08-01',
            isnew: true,
            version: 1
        };

        let Vendordetails = {
            id: '02',
            company: 'Deluxe Homes',
            vendorname: 'xyz',
            contactno: '9023446578',
            email: 'abc@deluxehomes.com',
            vendortype: 'xyz',
            officelocation: 'xyz',
            garagelocation: 'xyz'
        };
        let VendoAppointmentdetails = {
            id: 'Y51520',
            eventtype: 'vehicle#registration#2022',
            // Date:"111111",
            Date: '2023-01-11T08:45:53.539Z',
            description: '2023-08-01',
            drivername: true,
            // officelocation:1,
            place: '111111'
        };

        let DriverDetails = {
            id: '2',
            fleettype: 'Department',

            name: 'Maintenance',
            description: 'description for maintenance',
            businessunit: '1',
            // officelocation:1,
            isnew: false
        };
        // let o = await API.graphql(graphqlOperation(createTest3Fleet, {
        //     input: vardept

        // }));
        let varvehicleactivity = {
            id: 'A90920',
            fleettype: 'vehicle#activity'
        };
        id = Math.random().toString(36).substring(2, 9);
        let Generalconfig = {
            id: id,
            name: 'Ford Ecosport 2018',
            fleettype: 'Model'
        };
        let o = await API.graphql(
            graphqlOperation(createDxGeneralConfig, {
                input: Generalconfig
            })
        );
        console.log(o);
    };

    const getdata = async () => {
        // let o = await API.graphql({ query: listTest3Vendors });
        // console.log(o);
    };
    const Update = async () => {
        let varvehicleactivity = {
            id: 'X73551',
            fleettype: 'vehicle#activity',
            allocationno: 'Z34220#Driver100#2022-12-25',
            allocationdriver: 'Jai',
            expanses: JSON.stringify({
                fines: 'AED 1250',
                salik: 'AED 1400',
                mileage: '1118Km',
                parking: 'AED 2160',
                accidents: '2',
                repair: ' AED 1260'
            }),
            nextdates: JSON.stringify({
                insurance: '2022-12-27'
            }),
            VehicleStatus: 'Active',
            isnew: 1,
            version: 0
        };
        //   const updatedTodo = await API.graphql({ query:updateTest3Vehicle, variables: {input: todoDetails}});
        // let o = await API.graphql(
        //     graphqlOperation(updateTest3Vehicle, {
        //         input: varvehicleactivity
        //     })
        // );
        // console.log(o);
    };
    console.log('tewst');
    return (
        <div className="grid">
            <div className="col-12">
                <h2>Create Items to table</h2>
                <Button
                    onClick={() => {
                        adddata();
                    }}
                    label="Add data"
                />
                <Button
                    onClick={() => {
                        Update();
                    }}
                    label="Update data"
                />
                <Button
                    onClick={() => {
                        getdata();
                    }}
                    label="Get data"
                />
            </div>
            {/* <div className="col-12">
                <div className="card">
                    <h5>Todo Page</h5>
                    <p>This is your empty page template to start building beautiful applications.</p>
                </div>



                <Button label="Create Item" onClick={() => { handleClick("t") }} />

                <Button label="Create Warehouse" onClick={() => { handleClick("w") }} />

                <Button label="Create Employee" onClick={() => { handleClick("e") }} />

                <Button label="Create Account Representative" onClick={() => { handleClick("ac") }} />

                <Button label="Create Customer" onClick={() => { handleClick("c") }} />

                <Button label="Create Product" onClick={() => { handleClick("p") }} />


                <Button label="Create Inventory" onClick={() => { handleClick("i") }} />

                <Button label="Create Order" onClick={() => { handleClick("o") }} />

<br/>
<div>
    <Button label='Create Shantha Todo' onClick={() =>{ handleClick("st")}} />
</div>
<br/>
<Button label='Create test 2 Vehicle' onClick={() =>{ handleClick("Test2Vehicle")}} />
<Button label='Create test 2 registration' onClick={() =>{ handleClick("Test2Registration")}} />
            </div>

            <div className="col-12">
                <div className="card">

                    <Button label='Create Vehicle' onClick={() => { addvehicle() }} />
                    <Button label='Add Registration' onClick={() => { addRegistration() }} />
                    <Button label='Get data' onClick={() => { getlist() }} />

                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <h1>Single Table</h1>
                    <Button label='Create Vehicle' onClick={() => { addvehicle1() }} />
                    <Button label='Get Vehicles' onClick={() => { getlist1() }} />




                </div>
            </div> */}
        </div>
    );
};

export default TodoPage;
