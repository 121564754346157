import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import DriverUsage from './DriverUsage';
import VehicleUsage from './VehicleUsage';
import { TabMenu } from 'primereact/tabmenu';
import { useCallback, useEffect, useState } from 'react';
import './Reports.css';
import NewDriverUsage from './NewDriverUsage';
import CashReimburse from './CashReimburse';

const Usage = () => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const location1 = useLocation();

    //#region  Callback variables and methods
    const checkActiveIndex = useCallback(() => {
        const paths = location1.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'vehicle':
                setActiveIndex(0);
                break;
            case 'driver':
                setActiveIndex(1);
                break;
            case 'cashreimburse':
                setActiveIndex(2);
                break;
        }
    }, [location1]);
    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);
    //#endregion

    const wizardItems = [
        { label: 'Vehicle Usage', command: () => navigate('/analysereports/usage/vehicle') },
        { label: 'Driver Usage', command: () => navigate('/analysereports/usage/driver') },
        { label: 'Cash Reimbursement', command: () => navigate('/analysereports/usage/cashreimburse') }
    ];
    return (
        <div className="grid ">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Usage Report</h4>
                <div className="card">
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                    <Routes>
                        <Route path="/vehicle" element={<VehicleUsage />} />
                        <Route path="/driver" element={<NewDriverUsage />} />
                        <Route path="/cashreimburse" element={<CashReimburse />} />

                        {/* {activeIndex === 1 && <NewDriverUsage />} */}
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Usage;
