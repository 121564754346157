import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import AmplifyServices from '../../Service/Service';
import ListTable from '../../Components/ListTable';
import { Menu } from 'primereact/menu';
import { useNavigate } from 'react-router-dom';
import { listDxVehicleSpares } from '../../graphql/queries';
import { Dialog } from 'primereact/dialog';
import { deleteDxVehicleSpares } from '../../graphql/mutations';
import moment from 'moment';
import dxService from '../../Services/dxService';
import { API } from 'aws-amplify';
let params: any;
const ManageSpares = () => {
    const navigate = useNavigate();
    const menu = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [items, setItems] = useState<any>([]);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<any>('');
    const [selectedItems, setSelectedItems] = useState<any>([]);

    params = selectedItems.length > 0 && `?mode=edit&id=${selectedItems[0].id}`;
    useEffect(() => {
        getData().then((res) => {
            setItems(res);
        });
    }, []);
    const getData = async (nextToken?: any) => {
        let variables: any = { filter: {}, limit: 1000 };
        if (nextToken) {
            variables.nextToken = nextToken;
        }
        let GetResponse: any = await API.graphql({
            query: listDxVehicleSpares,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: variables
        });
        let res = GetResponse.data.listDxVehicleSpares.items;
        // Remove duplicate items by id
        const uniqueItems = res.reduce((acc: any[], curr: any) => {
            const duplicateItem = acc.find((item) => item.id === curr.id);
            if (!duplicateItem) {
                acc.push(curr);
            }
            return acc;
        }, []);
        setItems((olddata: any) => [...olddata, ...uniqueItems]);
        setLoading(false);
        if (GetResponse.data.listDxVehicleSpares.nextToken) {
            const nextResults = await getData(GetResponse.data.listDxVehicleSpares.nextToken);
            res = res.concat(nextResults);
        }
        return res;
    };

    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };

    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };

    const invoiceDocBodyTemplate = (e: any) => {
        let docs = e.invoicedoc !== null && e.invoicedoc !== '' ? JSON.parse(e.invoicedoc)?.File : [];
        if (docs.length > 0) {
            return (
                <>
                    {docs.map((item: any) => {
                        return (
                            <span>
                                {item.split('/').pop()}
                                <br />
                            </span>
                        );
                    })}
                </>
            );
        } else {
            return <>no</>;
        }
    };
    const TotalAmountBodyTemplate = (e: any) => {
        return (
            <span>
                {`AED ${e.totalamount !== 'NaN' && e.totalamount !== '' ? e.totalamount : 0}`}
                <br />
            </span>
        );
    };
    const columns = [
        // { fieldtype: 'multiselect' },
        { field: 'vehicleid', header: 'Vehicle', textalign: 'left', fieldtype: 'navigateSpares', filterOption: true, filterFieldOption: 'vehicleid', width: '5rem' },
        // { field: 'driverid', header: 'Driver ID', fieldtype: 'text', filterOption: true, filterFieldOption: 'driverid', width: '8rem' },
        // // {field: 'imageData', header: 'Image', fieldtype:'image'},
        { field: 'supplier', header: 'Supplier', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'supplier', width: '9rem' },
        { field: 'invoicedoc', header: 'Invoice Doc', textalign: 'left', fieldtype: 'custom', filterOption: true, width: '9rem', template: (r: any) => <>{invoiceDocBodyTemplate(r)}</> },
        {
            field: 'partname',
            header: 'Part Names',
            textalign: 'left',
            fieldtype: 'partnames',
            sortable: true,
            sortField: 'partname',
            width: '12rem'
        },
        { field: 'quantity', header: 'Quantity', textalign: 'center', fieldtype: 'text', filterOption: true, filterFieldOption: 'quantity', width: '8rem' },
        { field: 'description', header: 'Description', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'description', width: '12rem' },
        { field: 'totalamount', header: 'Total Amount', textalign: 'right', fieldtype: 'custom', filterOption: true, filterFieldOption: 'totalamount', width: '10rem', template: (r: any) => <>{TotalAmountBodyTemplate(r)}</> },
        { field: 'approvedby', header: 'Approved By', textalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'approvedby', width: '12rem' },
        { field: 'status', header: 'Status', textalign: 'left', fieldtype: 'status', filterOption: true, filterFieldOption: 'status', width: '10rem' },
        { field: 'updatedAt', header: 'Updated At', fieldtype: 'date', textalign: 'left', filterOption: false, headeralign: 'left', bodyalign: 'left', filterFieldOption: 'updatedAt', width: '10rem' }
    ];

    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text companyBtn" onClick={() => deleteItem()} />
        </>
    );
    const deleteItem = async () => {
        await Promise.all(
            selectedItems.map(async (item: any) => {
                await dxService.deleteitem('vehiclespare', item.id, item._version).then(() => {
                    getData().then((res) => {
                        setItems(res);
                    });
                    hideDeleteItemsDialog();
                    setSelectedItems([]);
                });
            })
        ).then(() => {
            selectedItems.length = 0;
            setSelectedItems([]);
        });
    };
    const clearbtn = () => {
        setGlobalFilter('');
    };
    let filteredSearch =
        items &&
        items
            .sort(function (a: any, b: any) {
                return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
            })
            .filter((del: any) => del._deleted !== true)
            .filter((name: any) => {
                if (
                    (name.vehicleid !== null && name.vehicleid.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.driverid !== null && name.driverid.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.supplier !== null && name.supplier.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.status !== null && name.status.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.description !== null && name.description.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.partname !== null && name.partname.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.invoiceno !== null && name.invoiceno.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.description !== null && name.description.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.approvedby !== null && name.approvedby.toLowerCase().includes(globalFilter.toLowerCase()))
                ) {
                    return name;
                }
            });
    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New" onClick={() => navigate('/manage/spares/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />

                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />

                <Menu
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedItems.length === 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                navigate('/manage/spares/item/basic' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            {selectedItems && selectedItems.length > 0 && <span>{selectedItems.length + ' of ' + filteredSearch.length + ' selected'}</span>}

            <div>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <form>
                        <div
                            style={{
                                position: 'relative',
                                marginTop: '3px',
                                marginBottom: '1em'
                            }}
                        >
                            <InputText type="text" id={globalFilter} value={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
                            {globalFilter.length > 0 ? (
                                <button type="button" className="contentBox__searchClearBtn" onClick={clearbtn}>
                                    X
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </form>
                </span>
            </div>
        </div>
    );

    return (
        <div className="grid crud">
            <div className="col-12">
                <h4 className="container-header">Manage Spares</h4>
                <div className="card">
                    <ListTable
                        header="Manage Vehicle Spares"
                        value={filteredSearch}
                        paginator={true}
                        rows={100}
                        dynamicColumns={columns}
                        emptyMessage="No Vehicle Spares found."
                        responsiveLayout="scroll"
                        className="p-datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vehicle Spares"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[100, 200, 300]}
                        //   selection={selectedProducts}
                        multiselect={true}
                        loading={loading}
                        Commandbar={Commandbar}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}
                        selectedItems={setSelectedItems}
                    />
                    <Dialog visible={deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedItems && <span>Are you sure you want to delete the selected items?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ManageSpares;
