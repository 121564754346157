import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useEffect, useRef, useState } from 'react';
import AmplifyServices from '../../Service/Service';
import { useLocation, useNavigate } from 'react-router-dom';
import { createDxAllocations, createDxAppointments, createDxDriver, deleteDxDriver, updateDxAllocations, updateDxAppointments, updateDxDriver, updateDxVehicleActivity } from '../../graphql/mutations';
import { listDxAllocations, listDxAppointments, listDxDrivers, listDxVendors } from '../../graphql/queries';
import { FileUpload } from 'primereact/fileupload';
import { ToggleButton } from 'primereact/togglebutton';
import { InputSwitch } from 'primereact/inputswitch';
import { Storage } from 'aws-amplify';
import WorkSpace from '../WorkSpace/WorkSpace';
import Sparesnewform from '../Spares/Newform';

import { Toast } from 'primereact/toast';

import AllocateDriverPopUp from '../WorkSpace/WSAllocation';
// import ListnewForm from '../../Components/ListnewForm';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { _listDxVehicleActivities } from '../../dxgraphql/dxqueries';

var id: any;
var id1: any;
var Appointmenttabledata: any = [];
var Vehicletabledata: any = [];
var ScheduledAllocation: any = [];
var pushscheduletoarray: any = [];

const AppointmentForm = (Data: any, msg: any) => {
    const [DateTime, setDateTime] = useState<any>('');
    const [Place, setPlace] = useState<any>('');
    const [Vehicleid, setvehicleid] = useState<any>('');
    const [Vehicleactivity, setVehicleactivity] = useState<any>({});
    const [Description, setDescription] = useState<any>('');
    const [Driver, setDriver] = useState<any>('');
    const [FormStatus, setFormStatus] = useState<any>('');
    const [DrivernameOptions, setDrivernameOptions] = useState<any>([]);
    const [checked, setChecked] = useState(false);
    const [checkedEdit, setCheckedEdit] = useState(false);
    const [IsScCovered, setIsScCovered] = useState<any>('');
    const [AdditionalCost, setAdditionalCost] = useState<any>('');
    const [AllocateDriverToggle, setAllocateDriverToggle] = useState(false);
    const [ScheduledAllocationItem, setScheduledAllocationItem] = useState<any>([]);
    const [VendorPlaceDetails, setVendorPlaceDetails] = useState<any>([]);
    const [Message, setMessage] = useState<any>('');
    const [showSparesForm, setShowSparesForm] = useState<any>(false);
    const UploadDocument = useRef<any>(null);
    const UploadPicture = useRef<any>(null);
    const navigate = useNavigate();

    const driverformcolumns = {
        columns: [
            {
                name: 'name',
                value: 'Name',
                fieldtype: 'text',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            { name: 'mobileno', value: 'Mobile No', fieldtype: 'number', required: true, disabled: false, hidden: false, data: [] },
            {
                name: 'departmentname',
                value: 'Department',
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: [
                    { label: 'Security', value: 'Security' },
                    { label: 'Operation', value: 'Operation' },
                    { label: 'Management', value: 'Management' },
                    { label: 'Maintenance', value: 'Maintenance' }
                ]
            },
            {
                name: 'businessunitname',
                value: 'Business Unit',
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: [
                    {
                        label: 'Deluxe Homes',
                        value: 'Deluxe Homes'
                    },
                    { label: 'Others', value: 'Others' }
                ]
            },
            { name: 'licenseno', value: 'License No', fieldtype: 'text', required: true, disabled: false, hidden: false, data: [] },
            { name: 'startdate', value: 'Start Date', fieldtype: 'datetime', required: true, disabled: false, hidden: false, data: [] },
            { name: 'enddate', value: 'End Date', fieldtype: 'datetime', required: true, disabled: false, hidden: false, data: [] }
        ],
        fleettype: 'driver',
        getTable: listDxDrivers,
        createTable: createDxDriver,
        updateTable: updateDxDriver,
        deleteTableItem: deleteDxDriver //Pass update table name
    };
    const DriverNameOption: any = [];
    const drivernames = DrivernameOptions.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.id;
        DriverNameOption.push(res);
    });
    const AppointmentStatus: any = [
        {
            label: 'Scheduled',
            value: 'Scheduled'
        },
        { label: 'Completed', value: 'Completed' }
    ];
    const [AppointmentData, setAppointmentData] = useState<any>([]);
    const location = window.location.href;
    const urlParams = new URLSearchParams(location);

    var VehicleID = urlParams.has('vid') ? urlParams.get('vid') : '';
    var EventType = urlParams.has('type') ? urlParams.get('type')?.toString() : '';
    var Fleettype = urlParams.has('ftype') ? urlParams.get('ftype') : '';
    var formtype = urlParams.has('form') ? urlParams.get('form') : '';
    var appid = urlParams.has('id') ? urlParams.get('id') : '';

    // setTimeout(() => {
    // }, 3000);
    const fetchvehicledetails = async () => {
        let filter = {
            // fleettype: {
            //     eq: 'vehicle#activity' // filter priority = 1
            // },
            id: {
                eq: VehicleID
            }
        };

        let GetResponse: any = await API.graphql({ query: _listDxVehicleActivities, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        // setVehicleData(GetResponse.data.listDxVehicles.items);
        // let alloId = JSON.parse(GetResponse.data.listDxVehicleActivities.items[0].allocationdriver).allocationid;

        // fetchAllocationDriverdetails(alloId);
    };
    const fetchAllocationDriverdetails = async (alloId: any) => {
        let filterAllocation = {
            id: {
                eq: alloId
            }
        };
        let GetResponseAllocation: any = await API.graphql({ query: listDxAllocations, variables: { filter: filterAllocation } });
        setScheduledAllocationItem(GetResponseAllocation.data.listDxAllocations.items);
    };
    useEffect(() => {
        setFormStatus('Scheduled');
        if (checked == true) {
            getAllocationtabledetails();
            // ScheduledDriver();
        }
        if (formtype == 'edit') {
            fetchvehicledetails();
        }

        if (formtype == 'edit' && appid != '') {
            getAppointment();
            setTimeout(() => {
                getVehicletabledata();
            }, 1000);
        }

        getDriverdetailsAndVendordetailsData();
    }, [checked]);
    useEffect(() => {
        // if (formtype == 'add') {
        getdata();
        // }
    }, []);
    const onMenuDialogHide = () => {
        setAllocateDriverToggle(false);
    };
    const getAppointment = async () => {
        let filter = {
            id: {
                eq: `${appid}` // filter priority = 1
            }
        };

        let appointmentitems: any = await API.graphql({ query: listDxAppointments, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        Appointmenttabledata = appointmentitems.data.listDxAppointments.items;

        if (Appointmenttabledata.length > 0) {
            let appitem = Appointmenttabledata[0];

            setPlace(appitem.place);
            setDescription(appitem.description);
            setDriver(appitem.drivername);
            setFormStatus(appitem.appointmentstatus);
            setAppointmentData(appitem.vehicleid);
            setChecked(appitem.wanttoallocatedriver);
            setDateTime(new Date(appitem.Date));
        }
        // let appointmentitems = await API.graphql({
        //     query: listDxAppointmentNews,
        //     variables: {
        //         filter:
        //     }
        // });
    };
    const getDriverdetailsAndVendordetailsData = async () => {
        let data = await AmplifyServices.getDriverDetails().then((res: any) => {
            setDrivernameOptions(res);
        });

        let vendorplace = await AmplifyServices.getTableDetails(listDxVendors, 'listDxVendors').then((res: any) => {
            setVendorPlaceDetails(res);
        });
    };
    const vendorGarageLocation: any = [];
    const garagelocation = VendorPlaceDetails.map((res: any) => {
        res['label'] = res.garagelocation;
        res['value'] = res.garagelocation;

        vendorGarageLocation.push(res);
    });
    const getdata = async () => {
        let filter = {
            id: {
                eq: VehicleID
            }
        };

        let o: any = await API.graphql({ query: _listDxVehicleActivities, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        setAppointmentData(o.data.listDxVehicleActivities.items[0].id);
        Vehicletabledata = o.data.listDxVehicleActivities.items[0];
        // Appointmentcehicledata=o.data.listDxVehicleActivities.items
        o.data.listDxVehicleActivities.items[0].driver !== null && setDriver(o.data.listDxVehicleActivities.items[0].driver.id);
        o.data.listDxVehicleActivities.items.length > 0 ? setVehicleactivity(o.data.listDxVehicleActivities.items[0]) : setVehicleactivity(null);
    };
    const getVehicletabledata = async () => {
        let filter = {
            id: {
                eq: Appointmenttabledata[0].vehicleid
            }
        };

        let o: any = await API.graphql({ query: _listDxVehicleActivities, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        // setAppointmentData(o.data.listDxVehicles.items[0].id);
        Vehicletabledata = o.data.listDxVehicleActivities.items[0];
        // Appointmentcehicledata=o.data.listDxVehicles.items
        // setDriver(JSON.parse(o.data.listDxVehicles.items[0].allocationdriver).driver);
    };

    const getAllocationtabledetails = async () => {
        let filter = {
            vehicleid: {
                eq: `${VehicleID}` // filter priority = 1
            },
            allocatestatus: {
                eq: 'Allocated'
            }
        };

        let o: any = await API.graphql({ query: listDxAllocations, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        if (o.data.listDxAllocations.items.length > 0) {
            let sortdata = o.data.listDxAllocations.items.sort(function (a: any, b: any) {
                return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
            })[0];

            if ([sortdata].length > 0) {
                let vehicle = {
                    id: sortdata.id,
                    vehicleid: VehicleID,
                    // drivername:drivername,
                    allocatestatus: 'DeAllocated',
                    // date:allocateddate
                    _version: sortdata._version
                };
                let UpdateAllocationData = await API.graphql({
                    query: updateDxAllocations,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {
                        input: vehicle
                    }
                });
            }
        }
    };

    const createAppointment = async (isallocation: boolean, va: any, DateTime: any, id1: any) => {
        id = Math.random().toString(36).substring(2, 9);
        let varvehicleactivitynew = {
            id: id,
            vehicleid: AppointmentData,
            eventtype: EventType,
            place: Place,
            Date: DateTime,
            description: Description,
            drivername: Driver,
            appointmentstatus: FormStatus,
            wanttoallocatedriver: checked
        };

        let os = await API.graphql({
            query: createDxAppointments,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: varvehicleactivitynew
            }
        });

        var nowdate = moment(new Date()).format('MM-d-yyy');
        var appointmentdate = moment(DateTime).format('MM-d-yyy');
        let vdetails: any = {
            id: AppointmentData,

            VehicleStatus: nowdate == appointmentdate && EventType != 'Repair/Replacement' ? 'Garage' : 'Active',
            _version: Vehicleactivity._version,
            dxAppointmentsActivityId: id
        };
        if (isallocation) {
            vdetails['dxDriverVehicleactivityId'] = Driver;
            vdetails['dxAllocationsActivityId'] = id1;
        }

        if (EventType == 'Service') {
            if (va != null) {
                let vaevents: any = { ...va['events'] };
                if (Vehicleactivity.events != null) {
                    vaevents = JSON.parse(Vehicleactivity.events);
                } else {
                    vaevents['Service'] = {
                        date: '',
                        id: '',
                        allocationid: ''
                    };
                }

                vaevents['Service'] = {};
                vaevents['Service']['date'] = new Date(DateTime);
                vaevents['Service']['id'] = id;
                vaevents['Service']['appointmentstatus'] = 'Scheduled';
                if (isallocation) {
                    vaevents['Service']['allocationid'] = id1;
                }
                vdetails['events'] = JSON.stringify(vaevents);
            }
        } else if (EventType == 'Repair/Replacement') {
            // var repaircost1 = JSON.parse(va.events).Repair.cost !== null ? JSON.parse(va.events).Repair.cost : 0;
            var repaircost = va.events == null ? 0 : JSON.parse(va.events).Repair == null ? 0 : JSON.parse(va.events).Repair.cost == null ? 0 : JSON.parse(va.events).Repair.cost;

            if (va != null) {
                let vaevents: any = { ...va['events'] };
                if (Vehicleactivity.events != null) {
                    vaevents = JSON.parse(Vehicleactivity.events);
                } else {
                    vaevents['Repair'] = {
                        date: '',
                        id: '',
                        allocationid: ''
                    };
                }

                vaevents['Repair'] = {};
                vaevents['Repair']['cost'] = repaircost;
                vaevents['Repair']['date'] = DateTime;
                vaevents['Repair']['id'] = id;
                vaevents['Repair']['appointmentstatus'] = 'Scheduled';
                if (isallocation) {
                    vaevents['Repair']['allocationid'] = id1;
                }
                vdetails['events'] = JSON.stringify(vaevents);
            }
        } else if (EventType == 'Internal Inspection') {
            if (va != null) {
                let vaevents: any = { ...va['events'] };
                if (Vehicleactivity.events != null) {
                    vaevents = JSON.parse(Vehicleactivity.events);
                } else {
                    vaevents['InternalInspection'] = {
                        date: '',
                        id: '',
                        allocationid: ''
                    };
                }

                vaevents['InternalInspection'] = {};
                vaevents['InternalInspection']['date'] = DateTime;
                vaevents['InternalInspection']['id'] = id;
                vaevents['InternalInspection']['appointmentstatus'] = 'Scheduled';
                if (isallocation) {
                    vaevents['InternalInspection']['allocationid'] = id1;
                }
                vdetails['events'] = JSON.stringify(vaevents);
            }
        }

        let o = await API.graphql({
            query: updateDxVehicleActivity,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: vdetails
            }
        });

        setMessage('');
        pushscheduletoarray = [];
        ScheduledAllocation = [];
        setScheduledAllocationItem([]);

        if (EventType == 'Internal Inspection') {
            setShowSparesForm(false);
            navigate('/general/workspace');
        } else {
            setShowSparesForm(true);
        }
    };

    const NavigateToHome = () => navigate('/general/workspace');
    const OnAppointmentSaveCancel = async () => {
        pushscheduletoarray = [];
        ScheduledAllocation = [];
        setScheduledAllocationItem([]);

        NavigateToHome();
    };

    const OnAppointmentEditCancel = async () => {
        // NavigateToHome();
        window.history.back();
    };
    const OnAppointmentSave = async () => {
        // let vaevents = JSON.parse(Vehicleactivity.events);
        if (DateTime == '' || Driver == '' || AppointmentData == '' || EventType == '') {
            setMessage('* Please fill all required fields');
        } else {
            createAppointment(checked, Vehicleactivity, DateTime, id1);
            id1 = Math.random().toString(36).substring(2, 8);
            if (checked == true) {
                createAppointment(checked, Vehicleactivity, DateTime, id1);
                // createAppointment();
                let vehicle = {
                    id: id1,
                    vehicleid: AppointmentData,
                    dxDriverAllocationsId: Driver,
                    fromdrivername: Driver,
                    // drivername: Driver,
                    allocatestatus: 'Allocated',
                    // date: DateTime,

                    fromdate: DateTime
                };
                let AddVehicleDetailsInsurence = await API.graphql({
                    query: createDxAllocations,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {
                        input: vehicle
                    }
                });
            }
        }

        // var dates = new Date(DateTime).toISOString();

        // var isodate = moment.utc('2019-11-03T05:00:00.000Z').format('MM/DD/YYYY');

        // var date = new Date('2023-01-11T08:45:53.539Z');
    };

    const OnAppointmentEditSave = async () => {
        var vaevents: any = {};

        var dt: any;
        if (EventType == 'Service') {
            dt = new Date(JSON.parse(Vehicletabledata.events).Service.date);
        } else if (EventType == 'Repair/Replacement') {
            dt = new Date(JSON.parse(Vehicletabledata.events).Repair.date);
        } else if (EventType == 'Internal Inspection') {
            dt = new Date(JSON.parse(Vehicletabledata.events).InternalInspection.date);
        }

        let no_of_months = 3;
        dt.setMonth(dt.getMonth() + no_of_months);

        let appointment = {
            id: Appointmenttabledata[0].id,
            appointmentstatus: 'Completed',
            closeallocatedriver: checkedEdit,
            issccovered: IsScCovered,
            cost: AdditionalCost,
            _version: Appointmenttabledata[0]._version
        };

        let UpdateActivity = await API.graphql({
            query: updateDxAppointments,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: appointment
            }
        });

        if (EventType == 'Service') {
            let vdetails: any = {
                id: Appointmenttabledata[0].vehicleid,

                VehicleStatus: 'Active',
                _version: Vehicleactivity._version
            };
            let vaevents: any = { ...Vehicletabledata['events'] };
            if (Vehicleactivity.events != null) {
                vaevents = JSON.parse(Vehicleactivity.events);
            }
            vaevents['Service'] = {};
            vaevents['Service']['date'] = dt;
            vaevents['Service']['cost'] = AdditionalCost;
            vaevents['Service']['appointmentstatus'] = 'Active';
            vdetails['events'] = JSON.stringify(vaevents);

            let UpdateActivity = await API.graphql({
                query: updateDxVehicleActivity,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: vdetails
                }
            });
            if (checkedEdit == true) {
                navigate(`/workspace/allocatedriver?type=allocate&id=${Appointmenttabledata[0].vehicleid}&fleettype=vehicle#activity`);
            } else if (checkedEdit == false) {
                NavigateToHome();
            }
        } else if (EventType == 'Repair/Replacement') {
            var Repaircost = JSON.parse(Vehicletabledata.events).Repair.cost;

            let vdetails: any = {
                id: Appointmenttabledata[0].vehicleid,

                // VehicleStatus: 'Active',
                _version: Vehicleactivity._version
            };
            let vaevents: any = { ...Vehicletabledata['events'] };
            if (Vehicleactivity.events != null) {
                vaevents = JSON.parse(Vehicleactivity.events);
            }
            vaevents['Repair'] = {};
            vaevents['Repair']['date'] = dt;
            vaevents['Repair']['cost'] = parseFloat(Repaircost) + parseFloat(AdditionalCost);
            vaevents['Repair']['appointmentstatus'] = 'Active';
            vdetails['events'] = JSON.stringify(vaevents);

            let UpdateActivity = await API.graphql({
                query: updateDxVehicleActivity,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: vdetails
                }
            });

            if (checkedEdit == true) {
                // setAllocateDriverToggle(true);
                navigate(`/workspace/allocatedriver?type=allocate&id=${Appointmenttabledata[0].vehicleid}&fleettype=vehicle#activity`);
            } else if (checkedEdit == false) {
                NavigateToHome();
            }
        } else if (EventType == 'Internal Inspection') {
            let vdetails: any = {
                id: Appointmenttabledata[0].vehicleid,

                VehicleStatus: 'Active',
                _version: Vehicleactivity._version
            };
            let vaevents: any = { ...Vehicletabledata['events'] };
            if (Vehicleactivity.events != null) {
                vaevents = JSON.parse(Vehicleactivity.events);
            }
            vaevents['InternalInspection'] = {};
            vaevents['InternalInspection']['date'] = dt;
            vaevents['InternalInspection']['cost'] = AdditionalCost;
            vaevents['InternalInspection']['appointmentstatus'] = 'Active';
            vdetails['events'] = JSON.stringify(vaevents);

            let UpdateActivity = await API.graphql({
                query: updateDxVehicleActivity,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: vdetails
                }
            });

            if (checkedEdit == true) {
                navigate(`/workspace/allocatedriver?type=allocate&id=${Appointmenttabledata[0].vehicleid}&fleettype=vehicle#activity`);
            } else if (checkedEdit == false) {
                NavigateToHome();
            }
        }
    };

    const handleChangePictureUpload = async (files: any) => {
        var varvehicleactivitynew: any;
        var Uploadedfiles: any = [];
        Uploadedfiles = files.files;
        Uploadedfiles.map(async (item: any) => {
            var files = item;
            var filesname = item.name;
            var StoreDocuments = await Storage.put('appointments/' + 'Pictures/' + `${appid}/` + item.name, item).then((res: any) => {
                UploadPicture.current.show({
                    severity: 'info',
                    summary: 'Success',
                    detail: 'Picture Uploaded',
                    life: 2000
                });
                varvehicleactivitynew = {
                    id: appid,
                    pictures: JSON.stringify({
                        Pictures: res.key + ';'
                    })
                };
            });

            let os = await API.graphql(
                graphqlOperation(updateDxAppointments, {
                    input: varvehicleactivitynew
                })
            );
        });
    };

    const handleChangeDocumentUpload = async (files: any) => {
        var varvehicleactivitynew: any;
        var Uploadedfiles: any = [];
        var ToastMsg: any = [];
        Uploadedfiles = files.files;
        Uploadedfiles.map(async (item: any) => {
            var files = item;
            var filesname = item.name;
            var StoreDocuments = await Storage.put('appointments/' + 'documents/' + `${appid}/` + item.name, item).then((res: any) => {
                UploadDocument.current.show({
                    severity: 'info',
                    summary: 'Success',
                    detail: 'Document Uploaded',
                    life: 2000
                });
                ToastMsg.push(res);
                varvehicleactivitynew = {
                    id: appid,
                    documents: JSON.stringify({
                        Documents: res.key + ';'
                    })
                };
            });

            let os = await API.graphql(
                graphqlOperation(updateDxAppointments, {
                    input: varvehicleactivitynew
                })
            );
        });

        //  var StoreDocuments = await Storage.put('vehicle/' + PlateCode + PlateNumber + '/' + docfile.Filename, docfile.Filepath);
        // const file = e.target.files[0];
    };
    //setMessage

    var NewForm = (
        <>
            <div className="field col-12 md:col-6">
                <label htmlFor="name1">Vehicle ID</label>
                <InputText
                    type="text"
                    value={AppointmentData}
                    disabled={formtype == 'edit' || showSparesForm === true ? true : false}
                    className={AppointmentData == '' || AppointmentData == null ? 'p-invalid' : ''}
                    //  onChange={(e) => setMake(e.target.value)}
                />
            </div>
            <div className="field col-12 md:col-6">
                <label htmlFor="email1">Event Type</label>
                <InputText
                    type="text"
                    value={EventType}
                    disabled={formtype == 'edit' || showSparesForm === true ? true : false}
                    className={EventType == '' || EventType == null ? 'p-invalid' : ''}
                    //   onChange={(e) => setModel(e.target.value)}
                />
            </div>

            <div className="field col-12 md:col-6">
                <label htmlFor="email1">Place</label>
                {/* <InputText type="text" value={Place} onChange={(e) => setPlace(e.target.value)} disabled={formtype == 'edit' ? true : false} /> */}
                <Dropdown
                    value={Place}
                    onChange={(e) => setPlace(e.target.value)}
                    // options={FilteredDriverName}
                    disabled={formtype == 'edit' || showSparesForm === true ? true : false}
                    options={vendorGarageLocation}
                    optionDisabled={(option: any) => option.isdisabled}
                    className={Place == '' || Place == null ? 'p-invalid' : ''} // disable an option
                    optionLabel="label"
                    placeholder="Select an option"
                />
            </div>
            <div className="field col-12 md:col-6">
                <label htmlFor="age1">Date & Time</label>
                <Calendar
                    id="time12"
                    showTime
                    hourFormat="12"
                    className={DateTime == '' || DateTime == null ? 'p-invalid' : ''}
                    showIcon
                    showButtonBar
                    value={DateTime}
                    onChange={(e) => setDateTime(e.value)}
                    disabled={formtype == 'edit' || showSparesForm === true ? true : false}
                ></Calendar>
            </div>
            <div className="field col-12 md:col-6">
                <label htmlFor="age1">Description</label>
                <InputText type="text" value={Description} onChange={(e) => setDescription(e.target.value)} disabled={formtype == 'edit' || showSparesForm === true ? true : false} />
            </div>
            <div className="field col-12 md:col-6">
                <label htmlFor="age1">Driver</label>
                <Dropdown
                    value={Driver}
                    onChange={(e) => {
                        setDriver(e.target.value);
                    }}
                    // options={FilteredDriverName}
                    disabled={formtype == 'edit' || showSparesForm === true ? true : false}
                    options={DriverNameOption}
                    optionDisabled={(option: any) => option.isdisabled} // disable an option
                    optionLabel="label"
                    className={Driver == '' || Driver == null ? 'p-invalid' : ''}
                    placeholder="Select an option"
                />
            </div>
            <div className="field col-12 md:col-6">
                <label htmlFor="age1">Appointment Status</label>
                <Dropdown
                    value={FormStatus}
                    disabled={true}
                    // onChange={(e) => setFormStatus(e.target.value)}
                    // options={FilteredDriverName}
                    options={AppointmentStatus}
                    optionDisabled={(option: any) => option.isdisabled} // disable an option
                    optionLabel="label"
                    placeholder="Select an option"
                />
            </div>

            <div className="field col-12 md:col-6"></div>
            <div className="field col-12 md:col-12">
                <div className="field col-12 md:col-6">
                    <label htmlFor="email1">Do you want to allocate driver for transport?</label>
                </div>
                <div className="field col-12 md:col-6">
                    <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} disabled={formtype == 'edit' || showSparesForm === true ? true : false} />
                </div>
            </div>
            {/* <div className="field col-12 md:col-5"></div> */}

            {showSparesForm == false && (
                <>
                    <div>
                        {' '}
                        <span>{Message}</span>
                    </div>

                    <div className="field col-12 md:col-2">
                        <Button label="Submit" className="mr-2 mb-2" disabled={formtype == 'edit' ? true : false} onClick={OnAppointmentSave} />
                    </div>
                    <div className="field col-12 md:col-2">
                        <Button label="Cancel" className="mr-2 mb-2 p-button p-component p-button-danger" onClick={OnAppointmentSaveCancel} disabled={formtype == 'edit' ? true : false} />
                    </div>
                </>
            )}
        </>
    );

    const dateTemplate = (data: any, col: string) => {
        return data.date == '' ? '-' : moment(data[col]).format('D-MM-YYYY');
    };
    var ScheduledAllocationdata = checked && (
        <>
            <div className="card">
                <h5>Scheduled Allocation</h5>
                <DataTable value={[{ vehicleid: AppointmentData, date: DateTime, drivername: Driver }]} className="datatable-responsive" emptyMessage="No Allocation Schedules." responsiveLayout="scroll">
                    <Column field="vehicleid" header="VehicleId" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column
                        field="date"
                        header="Date"
                        body={(rowData: any) => {
                            return dateTemplate(rowData, 'date');
                        }}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}
                    ></Column>
                    <Column field="drivername" header="drivername" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    {/* <Column field="documentType" header="Document Type" body={DocumentTypeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
     <Column field="File" header="Attachment" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                </DataTable>
            </div>
        </>
    );

    //  useEffect(()=>{
    //     checked&&ScheduledDriver()
    //  },[])
    //  checked&&ScheduledDriver();
    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <div className="card">
                    <h5>Appointment Form</h5>
                    <div className="p-fluid formgrid grid">
                        {formtype == 'add' ? (
                            <> {NewForm}</>
                        ) : formtype == 'edit' ? (
                            <>
                                {NewForm}
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="email1">Do you want to allocate driver for transport?</label>
                                        </div>
                                        <div className="field col-12 md:col-6">
                                            <InputSwitch checked={checkedEdit} onChange={(e) => setCheckedEdit(e.value)} />
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        {/* <div className="field col-12 md:col-6"> */}
                                        <label htmlFor="name1">is Service Contract covered?</label>
                                        {/* </div> */}
                                        <div className="field col-12 md:col-2">
                                            <ToggleButton onLabel="Yes" offLabel="No" checked={IsScCovered} onChange={(e) => setIsScCovered(e.value)} />
                                        </div>
                                    </div>

                                    {/* <InputText type="text" value={IsScCovered} onChange={(e) => setIsScCovered(e.target.value)} /> */}

                                    <Toast ref={UploadPicture}></Toast>
                                    <Toast ref={UploadDocument}></Toast>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="email1">Upload Service Documents and receipts</label>

                                        <FileUpload
                                            name="demo[]"
                                            // url="./upload.php"
                                            // onUpload={onUpload}
                                            uploadHandler={handleChangeDocumentUpload}
                                            multiple
                                            accept="image/*"
                                            // maxFileSize={1000000}
                                            emptyTemplate={<p className="m-0">Drag and drop pictures to here to upload.</p>}
                                            customUpload={true}
                                            // auto={true}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="email1">Upload Vehicle Pictures</label>

                                        <FileUpload name="demo[]" uploadHandler={handleChangePictureUpload} customUpload={true} multiple accept="image/*" emptyTemplate={<p className="m-0">Drag and drop pictures to here to upload.</p>} />
                                    </div>

                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="name1"> Additional Cost [ Cost not covered under Service Contract ]</label>
                                        <InputNumber value={AdditionalCost || null} onValueChange={(e) => setAdditionalCost(e.value)} />
                                        {/* <InputText type="number" value={AdditionalCost} onChange={(e) => setAdditionalCost(e.originalEvent.target.name)} /> */}
                                    </div>
                                    {/* <div className="field col-12 md:col-4"></div> */}

                                    <div className="field col-12 md:col-2">
                                        <Button label="Save" className="mr-2 mb-2" onClick={OnAppointmentEditSave} />
                                    </div>

                                    <div className="field col-12 md:col-2">
                                        <Button label="Cancel" className="mr-2 mb-2 p-button p-component p-button-danger" onClick={OnAppointmentEditCancel} />
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-6">
                {ScheduledAllocationdata}
                {showSparesForm && <Sparesnewform />}
            </div>
        </div>
    );
};

export default AppointmentForm;
