import { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { Amplify, API, Storage } from 'aws-amplify';
import awsconfig from '../aws-exports.js';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { Chip } from 'primereact/chip';
import { MultiSelect } from 'primereact/multiselect';
import { Message } from 'primereact/message';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Tag } from 'primereact/tag';
//import { createDxCompanySettings, createDxDepartmentSettings, createDxDriver } from '../../graphql/mutations';
//Amplify.configure(awsconfig);
let licenseUrl: any;
const ListForm = (props: any) => {
    const UploadDocument = useRef<any>(null);
    const [data, setData] = useState<any>({});
    const [messsage, setMesssage] = useState<any>('');
    const [licenseImages, setLicenseImages] = useState<any>([]);
    const [licenseFieldName, setLicenseFieldName] = useState<any>('');

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;

    useEffect(() => {
        // console.log(props);
        updatecolumnvalues();
        if (formmode != null && formid != null && props.data != null) {
            setData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        props.onChange(data);
    }, [data]);

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-1">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };
    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center">
                    {/* <img alt={file.name} role="presentation" src={file.objectURL} width={100} /> */}
                    <span className="flex flex-column text-left ml-1">
                        {file.name}
                        <small>{props.formatSize}</small>
                    </span>
                </div>
                {/* <Tag value={props.formatSize} severity="warning" className="px-3 py-2" /> */}
                {/* <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" 
          //  onClick={() => onTemplateRemove(file, props.onRemove)} 
            /> */}
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text ml-auto mb-2" />
            </div>
        );
        // return (
        //     <div className="flex align-items-center flex-wrap">
        //      <span className="flex flex-column text-left ml-3">
        //                 {file.name}
        //                 <small>{new Date().toLocaleDateString()}</small>
        //                 <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto"
        //                 //onClick={() => onTemplateRemove(file, props.onRemove)}
        //                 />
        //             </span>
        //     {/* <Chip label={file.name} removable /> */}
        //    </div>
        // );
    };

    //for getting the details of update
    const getDetails = async () => {
        // let filter = {
        //     id: {
        //         eq: `${urlParams.get('id')}` // filter priority = 1
        //     }
        // };
        // let FleetypeFilter = {
        //     id: {
        //         eq: `${urlParams.get('id')}` // filter priority = 1
        //     },
        //     fleettype: {
        //         eq: props.columns.fleettype
        //     }
        // };
        // let appointmentFormFilter = {
        //     vehicleid: {
        //         eq: `${urlParams.get('vid')}` // filter priority = 1
        //     }
        // };
        // // console.log(urlParams.get('vid'));
        // let GetResponse: any = await API.graphql({
        //     query: props.columns.getTable,
        //     variables: { filter: urlParams.get('vid') !== null ? appointmentFormFilter : props.Fleettype !== undefined ? FleetypeFilter : filter }
        // });
        // let response: any = [GetResponse.data[props.getTableName].items[0]].filter((key) => delete key['createdAt'] && delete key['updatedAt'] && delete key['_deleted']);
        // // console.log(response);
        // if (props.setGetmodelDropdown !== undefined) {
        //     props.setGetmodelDropdown(Object.assign({}, ...response));
        // }
        // response = Object.assign({}, ...response);
        // //checking condition for multiselect dropdown
        // if (response.partname) {
        //     //parsing the JSON
        //     response.partname = JSON.parse(response.partname);
        // } else {
        //     response = response;
        // }
        // setData(response);
        // if (urlParams.get('exp') !== null) {
        //     setData({ ...response, ['renewaltype']: urlParams.get('exp') });
        // }
    };
    let layoutclass = 'md:col-12';
    if (props.layout)
        switch (props.layout.toLowerCase()) {
            case 'vertical':
                layoutclass = 'md:col-12';
                break;
            case 'horizontal':
                layoutclass = 'grid md:col-12';
                break;
            case 'grid':
                layoutclass = 'dropdown md:col-6';
                break;
            case 'grid2':
                layoutclass = 'dropdown md:col-4';
                break;
            default:
                layoutclass = 'md:col-12';
        }
    const updatecolumnvalues = () => {
        let columndata: any = {};
        props.columns.columns
            .filter((col: any) => col.fieldtype != 'header')
            .map((val: any, key: number) => {
                columndata[val.name] = val.value;
            });
        // console.log(columndata);
        setData(columndata);
    };
    const dynamicFields = (type: any, fieldName: any, options: any, disabled: boolean, hidden: boolean, required: boolean, showcurrentdate: boolean) => {
        //  console.log(JSON.stringify(props.data));
        // console.log(JSON.stringify(data));
        // console.log(data[fieldName]);
        // console.log(fieldName);
        // console.log(formmode);
        // console.log(formid);
        let ddata: any = data;
        // if(formmode != null && formid != null)
        //     ddata = props.data;

        var dvalue = ddata && ddata[fieldName] ? ddata[fieldName] : '';
        // console.log(dvalue);
        // let multi = ddata && ddata[fieldName] == 'vehicleid' && ddata[fieldName].includes(',') ? true : false;
        // console.log(multi);
        // console.log([`${dvalue}`]);
        //if (Object.keys(data).length > 0) {
        //console.log(type);
        //Appointment form
        if (type === 'text') {
            return (
                <InputText
                    name={fieldName}
                    value={dvalue}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'number') {
            return (
                <InputNumber
                    name={fieldName}
                    value={dvalue || null}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    useGrouping={false}
                    maxLength={12}
                    className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'datetime') {
            // console.log(showcurrentdate);
            let ShowDate = showcurrentdate == true ? new Date() : new Date();

            // console.log(ShowDate);
            let dateValue: any = dvalue != '' ? new Date(dvalue) : '';
            //                     props.getTableName !== undefined && dvalue !== null && dvalue !== undefined
            //                         ? (new Date(dvalue))
            //                         : // : props.getTableName !== undefined && data[fieldName] === undefined
            //                         // ? new Date().toISOString()
            //                         props.getTableName !== undefined && dvalue === undefined && showcurrentdate == true
            //                             ? ShowDate
            //                             : dvalue;

            // console.log(new Date(dateValue));
            return (
                <Calendar
                    name={fieldName}
                    value={dateValue}
                    id="buttonbar"
                    showTime
                    showButtonBar
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    required={required}
                    showIcon
                    hourFormat="12"
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'date') {
            // console.log(showcurrentdate);
            let ShowDate = showcurrentdate == true ? new Date() : new Date().toISOString();
            // console.log(ShowDate);

            let dateValue: any =
                props.getTableName !== undefined && dvalue !== null && dvalue !== undefined
                    ? new Date(dvalue)
                    : // : props.getTableName !== undefined && data[fieldName] === undefined
                    // ? new Date().toISOString()
                    props.getTableName !== undefined && dvalue === undefined && showcurrentdate == true
                    ? ShowDate
                    : dvalue;
            // console.log(dateValue);

            return (
                <Calendar
                    name={fieldName}
                    value={dateValue}
                    id="buttonbar"
                    showButtonBar
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    required={required}
                    showIcon
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'dropdown') {
            return (
                <Dropdown
                    name={fieldName}
                    value={dvalue}
                    options={options}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    optionLabel="label"
                    placeholder="Select an option"
                    filter
                    optionDisabled={(option: any) => option.isdisabled}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'fileupload') {
            return (
                <FileUpload
                    customUpload={true}
                    multiple
                    // url="./upload.php"
                    uploadHandler={(e) => uploadHandler(e, fieldName)}
                    emptyTemplate={emptyTemplate}
                    itemTemplate={itemTemplate}
                    // onSelect={(e) => uploadHandler(e, fieldName)}
                    onSelect={(e) => handleChange(e, type, fieldName)}
                    auto={true}
                    accept="*"
                    className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                />
            );
        } else if (type === 'switch') {
            return <InputSwitch name={fieldName} checked={data[fieldName]} onChange={(e) => handleChange(e, type, fieldName)} disabled={disabled} hidden={hidden} required={required} />;
        } else if (type === 'multiselect') {
            return (
                <MultiSelect
                    name={fieldName}
                    value={dvalue}
                    options={options}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    optionLabel="label"
                    placeholder="Select an option"
                    filter
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                />
            );
        } else {
            return (
                <InputText
                    name={fieldName}
                    value={dvalue}
                    onChange={(e) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                />
            );
        }
        //}
    };
    const handleChange = async (event: any, type: any, fieldName: any) => {
        console.log(fieldName);
        console.log(type);
        console.log(props);

        if (fieldName == 'make') {
            props.setselectedmakeDropdown(event.target.value);
        }
        if (fieldName == 'toggleAllocate') {
            props.setToggleAllocate(event.target.value);
        }
        if (fieldName == 'Appointmentdrivername') {
            props.AllocationDriver(event.target.value);
        }
        if (fieldName == 'AppointmentDate') {
            props.AllocationDate(event.target.value);
        }
        if (fieldName == 'Appointmentvehicleid') {
            props.AllocationId(event.target.value);
        }
        if (props.formName === 'Mileage' && fieldName === 'id') {
            // props.getVehicleID('');
            props.getVehicleID(event.target.value);

            // setTimeout(() => {
            // props.fetchVehicleDetails();
            // }, 2000);
        }
        if (props.isAppointmentNeeded !== undefined && fieldName == 'isAppointmentNeeded') {
            props.isAppointmentNeeded(event.target.value);
        }
        if (props.formName === 'serviceContract' && type == 'multiselect') {
            props.getVehicleID(event.target.value);
        }
        setData({
            ...data,
            [type === 'fileupload' ? fieldName : type === 'number' ? event.originalEvent.target.name : event.target.name]:
                type === 'fileupload' ? event.files[0].objectURL : type === 'number' ? event.originalEvent.target.value : type === 'multiselect' ? event.target.value : event.target.value
        });

        //props.changevehicledata(data);
    };
    //get the license file and the fieldName
    const uploadHandler = async (event: any, fieldName: any) => {
        setLicenseImages(event.files);
        setLicenseFieldName(fieldName);
    };
    //call UploadLicense In submit function
    const UploadLicense = async () => {
        let Files: any = [];
        licenseUrl = '';
        console.log(data);
        return await Promise.all(
            licenseImages.map(async (item: any) => {
                // console.log(item);
                let expDOc = {};
                // props.Fleettype !== undefined ?
                if (props.Fleettype !== undefined) {
                    console.log('vehicle1');
                    let url = await Storage.put(`${props.bucketname}/${data.id}/${new Date().getFullYear()}/${data.renewaltype}/` + item.name, item);

                    licenseUrl = url.key;
                } else if (props.Fleettype !== undefined && props.Fleettype == 'vehiclePicture') {
                    console.log('vehicleimage');
                    await Storage.put(`${props.bucketname}/${data.PlateCode + data.PlateNumber}` + item.name, item)
                        .then((res: any) => {
                            Files.push(res.key + ';');
                            licenseUrl = JSON.stringify({ File: Files });
                            // console.log(JSON.stringify({ File: Files }));
                        })
                        .catch((err) => {});
                } else {
                    console.log('vehicle3');
                    await Storage.put(`${props.bucketname}/${data.name !== undefined ? data.name : data.vehicleid}-${new Date().toDateString()}/` + item.name, item)
                        .then((res: any) => {
                            Files.push(res.key + ';');
                            licenseUrl = JSON.stringify({ File: Files });
                            // console.log(JSON.stringify({ File: Files }));
                        })
                        .catch((err) => {});
                }
                // console.log(licenseUrl);
            })
        ).then(() => {
            saveData();
        });
    };
    const submitdata = async () => {
        // if (licenseImages.length > 0) {
        //     //wait until license upload is completed
        //     await UploadLicense();
        // } else {
        //     saveData();
        // }
        validateinputs();
        //saveData();
    };
    const validateinputs = () => {
        console.log(props.columns);
        setMesssage('');
        let collecteddata = data;
        let a = props.columns.columns.filter((p: any) => p.fieldtype != 'header');
        let b = props.columns.columns.filter((p: any) => p.fieldtype != 'header' && p.required);
        let c = Object.keys(collecteddata);
        let d = c.filter((v: any) => {
            return collecteddata[v];
        });

        let e = b.every((v: any) => {
            return d.includes(v.name);
        });

        if (!e) setMesssage('* Please fill all required fields');

        props.submitForm(collecteddata);
    };
    const saveData = () => {
        console.log(props.columns);
        let submitteddata = props.columns.columns.filter((p: any) => p.fieldtype != 'header');
        console.log(submitteddata);
        let tem: any = [];
        let collectedData = data;
        if (Array.isArray(collectedData.partname)) {
            collectedData.partname = JSON.stringify(collectedData.partname);
        } else {
            collectedData = collectedData;
        }
        collectedData[licenseFieldName] = licenseUrl;
        let EditFormFilter = Object.fromEntries(
            Object.entries(collectedData)
                .filter(([key, value]) => value !== null)
                .filter(([key, value]) => key !== '')
                .filter(([key, value]) => value !== undefined)
        );
        let NewFormFilter = Object.fromEntries(
            Object.entries(collectedData)
                .filter(([key, value]) => value !== null)
                .filter(([key, value]) => key !== '')
                .filter(([key, value]) => value !== '')
                .filter(([key, value]) => value !== undefined)
        );
        let finalData = props.getTableName !== undefined ? EditFormFilter : NewFormFilter;
        // Pushing the column items for validation
        props.columns.columns.map((val: any, key: number) => {
            // console.log(data[val.name]);
            if (data[val.name] == '' && val.required == (props.getTableName !== undefined ? true : false)) {
                return false;
            } else if (data[val.name] == null && val.required == false && data[val.name] == undefined) {
                return false;
            } else {
                tem.push(val.name);
            }
        });
        tem = tem.filter((res: any) => res !== 'id');
        console.log(finalData);
        console.log(tem);
        //Checking id and fleettype (Edit)
        if (finalData.hasOwnProperty('id') && finalData.hasOwnProperty('fleettype')) {
            if (tem.length + 4 == Object.keys(finalData).length) {
                // console.log('1');
                props.submitForm(finalData);
            } else {
                setMesssage('* Please fill all required fields');
            }
        }
        //Checking id and fleettype (Edit)
        else if (finalData.hasOwnProperty('id') && !finalData.hasOwnProperty('fleettype')) {
            if (tem.length + 3 == Object.keys(finalData).length) {
                props.submitForm(finalData);
                // console.log('2');
            } else {
                setMesssage('* Please fill all required fields');
            }
        }
        // New Form
        else {
            if (tem.length == Object.keys(finalData).length) {
                props.submitForm(finalData);
                // console.log('3');
            } else {
                setMesssage('* Please fill all required fields');
            }
        }
    };

    // console.log(props);
    //updatecolumnvalues();
    // console.log(data);
    // console.log(props.columns);
    // console.log("change");
    //props.onChange(data);
    return (
        <div>
            <Toast ref={UploadDocument}></Toast>
            <div className="grid">
                <div className="col-12 md:col-12">
                    <div className="card ">
                        {/* <h5> {props.title} </h5>*/}
                        {/* {data['id']} */}

                        <div className="p-fluid formgrid grid">
                            {props.columns.columns
                                .filter((hidden: any) => hidden.hidden === false || hidden.hidden === undefined)
                                .map((val: any, key: number) => {
                                    return (
                                        <React.Fragment key={key.toString()}>
                                            {val.fieldtype == 'header' ? (
                                                <div className="field col-12  md:col-12">
                                                    <h5 className={key > 1 ? 'spd-padtop' : ''}>{val.title}</h5>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className={`field col-12 ${layoutclass}`}>
                                                        <label htmlFor={val.value} className={layoutclass.includes('grid') ? 'col-12 mb-2 md:col-2 md:mb-0' : ''}>
                                                            {val.title}
                                                            {val.required ? '*' : ''}
                                                        </label>
                                                        <div className={layoutclass.includes('grid') ? 'col-12 md:col-10' : ''}>{dynamicFields(val.fieldtype, val.name, val.data, val.disabled, val.hidden, val.required, val.showcurrentdate)}</div>
                                                    </div>
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                        {messsage && messsage != '' ? (
                            <span className="font-italic" style={{ color: '#d32f2f' }}>
                                {messsage}
                            </span>
                        ) : (
                            ''
                        )}
                        {props.showaction ? (
                            <div className="spd-card-footer">
                                <Button onClick={submitdata} className="mr-2" disabled={props.mode == 'view'}>
                                    Submit
                                </Button>
                                <Button onClick={() => window.history.back()} className="p-button-danger">
                                    Cancel
                                </Button>
                            </div>
                        ) : (
                            ''
                        )}

                        {/* {props.ChangeBtn == 'OpenDocument' ? (
                            <>
                                <Button onClick={submitdata} className="mr-2">
                                    Documents
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={submitdata} className="mr-2">
                                    Submit
                                </Button>
                                <Button onClick={() => window.history.back()} className="p-button-danger">
                                    Cancel
                                </Button>
                            </>
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ListForm;
