import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button as AwsButton } from '@aws-amplify/ui-react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import dxService from '../../Services/dxService';
import Documents from '../TempImport/Document';
import { getdriveroptions, localetoisodatetime } from '../../utils/helper';
import { API } from 'aws-amplify';
import { _listDxVehicleActivities } from '../../dxgraphql/dxqueries';

let errMessages: any = [];
function ImportServices() {
    const navigate = useNavigate();
    const toast = useRef<any>(null);
    const [jsonData, setJsonData] = useState([]);
    const [savingData, setSavingData] = useState(false);
    const [charges, setCharges] = useState<any>([]);
    const [vehicleactivity, setVehicleActivity] = useState<any>([]);
    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [driverNames, setDrivernames] = useState<any>([]);

    console.log(jsonData);
    console.log(vehicleactivity);

    useEffect(() => {
        getdriveroptions().then((resp) => {
            setDrivernames(resp);
        });

        getVactivity().then((res) => {
            setVehicleActivity(res);
        });
    }, []);
    const getVactivity = async (nextToken?: any) => {
        let variables: any = {};

        if (nextToken) {
            variables.nextToken = nextToken;
        }
        let GetResponse: any = await API.graphql({
            query: _listDxVehicleActivities,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: variables
        });
        let res = GetResponse.data.listDxVehicleActivities.items;

        if (GetResponse.data.listDxVehicleActivities.nextToken) {
            const nextResults = await getVactivity(GetResponse.data.listDxVehicleActivities.nextToken);
            res = res.concat(nextResults);
        }
        return res;
    };
    // console.log(driver);

    const checkNaughtyDriver = async () => {
        errMessages = [];
        setErrorMessage([]);

        const items: any = [];
        const additionalData: any = [];

        if (jsonData) {
            jsonData.map((item: any) => {
                const driverID = driverNames.find((driverName: any) => driverName.label.toLowerCase().includes(item.Driver.toLowerCase()));

                if (driverID) {
                    if (charges.label === 'Accident') {
                        const AccidentData = {
                            vehicleid: item.VehicleId.replace(' ', '').replace('-', '').replace('/', ''),
                            dxVehicleAppointmentsId: item.VehicleId.replace(' ', '').replace('-', '').replace('/', ''),
                            eventtype: 'Accident',
                            description: item.Description,
                            Date: localetoisodatetime(new Date(item['Accident Date'])),
                            accidenthappenedat: item.AccidentHappenedAt ? item.AccidentHappenedAt : null,
                            accidenttype: item.AccidentType ? item.AccidentType : null,
                            dxDriverAppointmentsId: driverID !== undefined ? driverID.value : '',
                            appointmentstatus: 'Completed',
                            closeallocatedriver: true
                        };
                        const masterData = {
                            vehicleid: item.VehicleId.replace(' ', '').replace('-', '').replace('/', ''),
                            drivername: item.Driver,
                            date: localetoisodatetime(new Date(item['Accident Date'])),
                            Accidents: item.AccidentType ? item.AccidentType : null,
                            dxDriverMasterlogsId: driverID !== undefined ? driverID.value : '',
                            dxVehicleMasterlogsId: item.VehicleId.replace(' ', '').replace('-', '').replace('/', '')
                        };
                        items.push(AccidentData);
                        additionalData.push(masterData);
                    } else if (charges.label === 'Repair') {
                        const RepairData = {
                            vehicleid: item.VehicleId.replace(' ', '').replace('-', '').replace('/', ''),
                            dxVehicleAppointmentsId: item.VehicleId.replace(' ', '').replace('-', '').replace('/', ''),
                            eventtype: 'Repair/Replacement',
                            description: item.Description,
                            place: item.Garage,
                            invoiceno: item['Invoice No'],
                            mileage: item['Current Mileage'],
                            Date: localetoisodatetime(new Date(item.ScheduleDate)),
                            dxDriverAppointmentsId: driverID !== undefined ? driverID.value : '',
                            cost: item['Total Cost'],
                            //appointmentstatus: etype === 'Repair/Replacement'  ? 'Completed' : 'Scheduled'
                            appointmentstatus: 'Completed'
                        };
                        const sparesData = {
                            vehicleid: item.VehicleId.replace(' ', '').replace('-', '').replace('/', ''),
                            driverid: driverID !== undefined ? driverID.value : '',
                            supplier: item.Garage,
                            invoiceno: item['Invoice No'],
                            description: item.SparesDescription,
                            partname: JSON.stringify([item.Category]),
                            quantity: item.Quantity,
                            amount: item.Amountperunit,
                            totalamount: `${parseFloat(item.Quantity) * parseFloat(item.Amountperunit)}`,
                            appointmentid: '',
                            dxAppointmentsSparesId: null,
                            status: 'Completed',
                            approvedby: '',
                            jfield: JSON.stringify({ tax: item.Tax, unit: item.Unit, warranty: item['Job waranty'] })
                        };
                        additionalData.push(sparesData);
                        items.push(RepairData);
                    } else if (charges.label === 'Service') {
                        const Service = {
                            vehicleid: item.Vehicle.replace(' ', '').replace('-', '').replace('/', ''),
                            dxVehicleAppointmentsId: item.Vehicle.replace(' ', '').replace('-', '').replace('/', ''),
                            eventtype: 'Service',
                            description: item.Description,
                            place: item.Garage,
                            invoiceno: item.Invoice,
                            mileage: item.CurrentMileage,
                            Date: localetoisodatetime(new Date(item.ScheduleDate)),
                            dxDriverAppointmentsId: driverID !== undefined ? driverID.value : '',
                            appointmentstatus: 'Completed'
                        };
                        items.push(Service);
                    }
                } else {
                    errMessages.push('Driver: ' + item.Driver + ' not found');
                }
            });
        }
        let uniqueArr = [...new Set(errMessages)];

        setErrorMessage(uniqueArr);

        await saveData(items, additionalData)
            .then((res) => {
                updateVehicleActivity(res);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    //for repair update in vehicleactivity
    // for accident update in masterlogs

    const saveData = async (data: any, otherdata: any) => {
        setSavingData(true);

        // Using Promise.all to execute all queries concurrently
        let saveData = await Promise.all(
            data.map(async (item: any, key: number) => {
                let createdData: any;

                try {
                    createdData = await dxService.createitem('appointment', item);
                    console.log('Appointment Created', createdData);

                    if (charges.label === 'Accident') {
                        // otherdata[key].accidents = createdData.id;

                        await dxService
                            .createitem('masterlog', otherdata[key])
                            .then(async (res) => {
                                console.log('Master Log Created', res);
                                let uappointment = {
                                    id: createdData.id,
                                    dxMasterLogsAccidentsId: res.id,
                                    _version: createdData._version
                                };
                                //updating the masterlog id
                                createdData = await dxService.updateitem('appointment', uappointment);
                                console.log('Appointment updated', createdData);
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    }
                    if (charges.label === 'Repair') {
                        otherdata[key].dxAppointmentsSparesId = createdData.id;
                        otherdata[key].appointmentid = createdData.id;
                        let spares = await dxService.createitem('vehiclespare', otherdata[key]);
                        console.log('Spares added successfully', spares);
                    }
                    return createdData;
                } catch (error) {
                    console.error(error);
                }
                return createdData;
            })
        ).then((res) => {
            return res;
        });
        return saveData;
    };

    const updateVehicleActivity = async (data: any) => {
        console.log(data);

        try {
            data.map(async (item: any) => {
                if (item) {
                    const res = await getVactivity().then((vehicleactivity) => {
                        return vehicleactivity.find((va: any) => va.id === item.vehicleid);
                    });

                    const eventsData = res?.events ? JSON.parse(res.events) : {};

                    switch (charges.label) {
                        case 'Repair':
                            const repairCost = parseFloat(item.cost);
                            let totalRepairCost = isNaN(repairCost) ? 0 : repairCost;

                            if (eventsData['Repair']?.cost) {
                                const previousCost = parseFloat(eventsData['Repair'].cost);
                                totalRepairCost += isNaN(previousCost) ? 0 : previousCost;
                            }

                            eventsData['Repair'] = {
                                cost: totalRepairCost.toFixed(2).toString(),
                                date: item.Date,
                                appointmentstatus: 'Active'
                            };

                            break;

                        case 'Service':
                            eventsData['Service'] = {
                                // date: item.Date,
                                appointmentstatus: 'Active'
                            };

                            break;

                        case 'Accident':
                            eventsData['Accident'] = {
                                // date: item.Date,
                                appointmentstatus: 'Active'
                            };

                            break;
                    }

                    const vactivity = {
                        id: res.id,
                        dxAppointmentsActivityId: item.id,
                        events: JSON.stringify({ ...eventsData }),
                        _version: res._version
                    };

                    const result = await dxService.updateitem('vehicleactivity', vactivity);
                    console.log('Vehicle Activity updated', result);
                }
            });

            setSavingData(false);
            setJsonData([]);
            setCharges([]);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="col-12 md:col-12">
            <Toast ref={toast}></Toast>
            <h4>Import</h4>
            <div className="card p-fluid">
                <div className="field">
                    <div className="col-12 sm:col-8 md:col-7 grid">
                        <div className="col-12 md:col-2 mt-2">
                            <label htmlFor="">Select</label>
                        </div>
                        <div className="col-12 md:col-10">
                            <Dropdown
                                options={[
                                    { label: 'Accident', name: 'Accident' },
                                    { label: 'Service', name: 'Service' },
                                    { label: 'Repair', name: 'Parking' }
                                ]}
                                value={charges}
                                onChange={(e) => setCharges(e.target.value)}
                                optionLabel="label"
                                placeholder="Select an option"
                                filter
                            />
                        </div>
                    </div>
                </div>

                {charges.name !== undefined && (
                    <div className="field">
                        <div className="col-12 sm:col-8 md:col-7 grid">
                            <div className="col-12 md:col-2 mt-2">
                                <label htmlFor="">Upload File</label>
                            </div>
                            <div className="col-12 md:col-10">
                                <Documents setJsonData={setJsonData} sheetName={charges.label} />
                                {/* <FileUpload accept=".xlsx, .xls*" auto={true} customUpload={true} uploadHandler={handleFileUpload} /> */}
                            </div>
                        </div>
                    </div>
                )}
                <div className="field col-5 grid md:col-3">
                    <AwsButton
                        className=" mr-2"
                        disabled={charges.name === undefined || jsonData.length === 0 ? true : false}
                        onClick={async () => {
                            checkNaughtyDriver();
                        }}
                    >
                        {savingData === false ? 'Submit' : 'Saving'}
                    </AwsButton>
                    <AwsButton
                        style={{
                            background: '#d32f2f',
                            color: '#fff',
                            border: 'none'
                        }}
                        onClick={async () => {
                            navigate('/');
                        }}
                    >
                        Cancel
                    </AwsButton>
                </div>
                <div className="grid col-12 md:col-12">
                    <div className="col-12 md:col-12" style={{ color: '#256029' }}>
                        {/* <pre>{showData == true && JSON.stringify(data, null, 2)}</pre> */}

                        <br />
                        {errorMessage.map((tem: any) => {
                            return <div>{tem}</div>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ImportServices;
