import React, { useEffect, useState } from "react";
import dxService from "../../Services/dxService";
import ListTable from "../../Components/ListTable";
import ListTemplate from "../../Components/ListTemplate";
import { API } from "aws-amplify";
import { listDxMileages } from "../../graphql/queries";

const MileageReport = () => {
  const [date, setdata] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  useEffect(() => {
    // getAlldriverShifts().then((suc: any) => {
    //   console.log(suc);
    //   setdata(suc);
    //   setLoading(false);
    // });
    getSortedMileage();
  }, []);
  //   const getSortedMileage = async () => {
  //     //2023-06-01T00:00:00.000Z#167997949834694#19:21:36#22:02:40
  //     //   listDxMileageReports( enddateDriveridStartdatetimeEnddatetime: {
  //     //   ge: {
  //     //     enddate: "2023-06-01T00:00:00.000Z",
  //     //     driverid: "167997949834694",
  //     //     startdatetime: "19:21:36",
  //     //     enddatetime: "22:02:40"
  //     //   }
  //     // },startdate: "2023-06-01T00:00:00.000Z") {
  //     //listDxMileageReports(startdate: "", enddateDriveridStartdatetimeEnddatetime: {ge: {enddate: ""}, eq: {driverid: ""}})
  //     // listDxMileageReports(startdate: "", enddateDriveridStartdatetimeEnddatetime: {gt: {enddate: ""}, le: {enddate: ""}})
  //     // listDxMileageReports(startdate: "2023-06-01T00:00:00.000Z", enddateDriveridStartdatetimeEnddatetime: {
  //     // ge: {enddate: "2023-06-01T00:00:00.000Z"},eq:{driverid: "167997949834694"}})
  //     //listDxMileageReports(enddateDriveridStartdatetimeEnddatetime: {between: {enddate: "", enddate: ""}})
  //     // "2023-06-01T00:00:00.000Z#your_startdatetime1#your_enddatetime1",
  //     //   "2023-06-07T00:00:00.000Z#your_startdatetime2#your_enddatetime2";
  //     //  listDxMileageReports(enddateDriveridStartdatetimeEnddatetime: {between: [
  //     //   { enddate: "2023-06-01T00:00:00.000Z"},
  //     //   { enddate: "2023-06-07T00:00:00.000Z"}
  //     // ], driverid:{ eq: "167997949835135" } },startdate: "2023-06-01T00:00:00.000Z"){
  //     let column = `query ListDxMileageReports {
  //     listDxMileageReports(enddateDriveridStartdatetimeEnddatetime: {between: [
  //         { enddate: "2023-06-01T00:00:00.000Z"},
  //      { enddate: "2023-06-07T00:00:00.000Z"}
  //       ]},startdate: "2023-06-01T00:00:00.000Z"){
  //       items {
  //         id
  //         vehicleid
  //         drivername
  //         date
  //         type
  //         startedtime
  //         endedtime
  //         inshifttime
  //         outshifttime
  //         totalshifttime
  //         transactiondate
  //         startKM
  //         endKM
  //         startAddress
  //         endAddress
  //         distance
  //         driverid
  //         description
  //         startdatetime
  //         enddatetime
  //         startdate
  //         enddate
  //         duration

  //       }
  //       nextToken
  //       startedAt
  //       __typename
  //     }
  //   }
  // `;
  //     const variables = {
  //       // $filter: {}, // your filter conditions
  //       // $limit: 5,
  //       // $sortDirection: 'ASC' // or 'DESC'
  //     };
  //     let filter = {};
  //     let GetResponse: any = await API.graphql({
  //       query: column,
  //       authMode: "AMAZON_COGNITO_USER_POOLS",
  //       variables: { filter: filter },
  //     });
  //     console.log(GetResponse.data.listDxMileageReports.items);
  //   };
  const getSortedMileage = async () => {
    //2023-06-01T00:00:00.000Z#167997949834694#19:21:36#22:02:40
    //   listDxMileageReports( enddateDriveridStartdatetimeEnddatetime: {
    //   ge: {
    //     enddate: "2023-06-01T00:00:00.000Z",
    //     driverid: "167997949834694",
    //     startdatetime: "19:21:36",
    //     enddatetime: "22:02:40"
    //   }
    // },startdate: "2023-06-01T00:00:00.000Z") {
    //listDxMileageReports(startdate: "", enddateDriveridStartdatetimeEnddatetime: {ge: {enddate: ""}, eq: {driverid: ""}})
    // listDxMileageReports(startdate: "", enddateDriveridStartdatetimeEnddatetime: {gt: {enddate: ""}, le: {enddate: ""}})
    // listDxMileageReports(startdate: "2023-06-01T00:00:00.000Z", enddateDriveridStartdatetimeEnddatetime: {
    // ge: {enddate: "2023-06-01T00:00:00.000Z"},eq:{driverid: "167997949834694"}})
    //listDxMileageReports(enddateDriveridStartdatetimeEnddatetime: {between: {enddate: "", enddate: ""}})
    // "2023-06-01T00:00:00.000Z#your_startdatetime1#your_enddatetime1",
    //   "2023-06-07T00:00:00.000Z#your_startdatetime2#your_enddatetime2";
    //  listDxMileageReports(enddateDriveridStartdatetimeEnddatetime: {between: [
    //   { enddate: "2023-06-01T00:00:00.000Z"},
    //   { enddate: "2023-06-07T00:00:00.000Z"}
    //167997949834694
    // ], driverid:{ eq: "167997949835135" } },startdate: "2023-06-01T00:00:00.000Z"){
    //  listDxMileages(sortstartdatetimeDriverid: {between: [
    //       { sortstartdatetime: "2023-06-01T00:00:00.000Z"},
    //    { sortstartdatetime: "2023-06-05T00:00:00.000Z"}
    //     ],driverid: {
    //     eq: "167997949834694"
    //   }},tablename: "Mileage")
    let column = `query ListDxMileages {
    listDxMileages(
      tablename: "Mileage"
      sortstartdatetimeDriverid: {
               between: [
          { sortstartdatetime: "2023-06-01T00:00:00.000Z" },
          { sortstartdatetime: "2023-06-10T00:00:00.000Z" }
        ]
             }
     
    ){
      items {
        id
        vehicleid
        drivername
        date
        type
        startedtime
        endedtime
        inshifttime
        outshifttime
        totalshifttime
        transactiondate
        startKM
        endKM
        startAddress
        endAddress
        distance
        driverid
        description
        startdatetime
        enddatetime
        startdate
        enddate
        duration
      sortstartdatetime
     
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

    let filter = {
      between: [
        { sortstartdatetime: "2023-06-01T00:00:00.000Z" },
        { sortstartdatetime: "2023-06-10T00:00:00.000Z" },
      ],
    };
    let GetResponse: any = await API.graphql({
      query: column,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { sortstartdatetimeDriverid: filter },
    });
    console.log(GetResponse.data.listDxMileages.items);
  };
  const getAlldriverShifts = async (nextToken?: any) => {
    let variables: any = {
      Mileage: { attributeExists: true },
    };
    let GetResponse: any = await dxService.listitems(
      "drivershifts",
      "*",
      variables,
      true,
      100,
      nextToken
    );
    let response = GetResponse.items;

    let nexttoken = GetResponse.nextToken;
    if (nexttoken) {
      await getAlldriverShifts(nexttoken).then((res: any) => {
        setdata((olditems: any) => [...olditems, ...res]);
      });
    }

    return response;
  };
  const MileageShift = (e: any) => {
    console.log(e);
    if (e.Mileage !== null) {
      const StimeString = e.startKm;
      const [shours, sminutes, sseconds] = StimeString.split(":");

      const Sstarttime = shours + ":" + sminutes + ":" + sseconds + ".000Z";

      //   console.log(Sstarttime);
      const EtimeString = e.endkm;
      const [ehours, eminutes, eseconds] = EtimeString.split(":");

      const Estarttime = ehours + ":" + eminutes + ":" + eseconds + ".000Z";

      console.log(Sstarttime, Estarttime);
      console.log(
        e.shift !== null && e.shift.starttime,
        e.shift !== null && e.shift.endtime
      );
      //   let shiftstart = e.shift !== null && e.shift.starttime;
      //   let shiftend = e.shift !== null && e.shift.endtime;
      //   const moment = require("moment");
      //   //
      //   function calculateExtraTime(t: any, t1: any) {
      //     // Parse the time strings using moment.js to create moment objects
      //     let sdate = moment(t.sdate, moment.ISO_8601);
      //     let edate = moment(t.edate, moment.ISO_8601);
      //     let sdate1 = moment(t1.sdate, moment.ISO_8601);
      //     let edate1 = moment(t1.edate, moment.ISO_8601);

      //     // Calculate the time difference between t1 and t in milliseconds
      //     let timeDifferenceMillis = edate1.diff(sdate1) - edate.diff(sdate);

      //     // If timeDifferenceMillis is negative, it means the second time range is greater than the first one
      //     // We need to adjust it to represent a positive duration
      //     if (timeDifferenceMillis < 0) {
      //       timeDifferenceMillis += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
      //     }

      //     // Convert the time difference from milliseconds to a moment duration
      //     let duration = moment.duration(timeDifferenceMillis);

      //     // Format the time difference
      //     let formattedTimeDifference = moment
      //       .utc(duration.asMilliseconds())
      //       .format("HH:mm:ss.SSS[Z]");

      //     return formattedTimeDifference;
      //   }

      //   // Input time objects
      //   let t = {
      //     sdate: `2023-07-17T${e.shift !== null && e.shift.starttime}`,
      //     edate: `2023-07-17T${e.shift !== null && e.shift.endtime}`,
      //   };
      //   let t1 = {
      //     sdate: `2023-07-17T${Sstarttime}`,
      //     edate: `2023-07-17T${Estarttime}`,
      //   };
      //   console.log(t, t1);
      //   // Calculate and log the extra time traveled
      //   let extraTime = calculateExtraTime(t, t1);
      //   console.log(extraTime); // Output: 05:00:00.000Z
      //------------------------------------------------------------------------
      const moment = require("moment");

      //   function calculateTimeDifference(a: any, b: any) {
      //     // Parse the time strings using moment.js to create moment objects
      //     let timeA = moment(a, moment.ISO_8601);
      //     let timeB = moment(b, moment.ISO_8601);

      //     // Calculate the time difference between timeA and timeB in milliseconds
      //     let timeDifferenceMillis = timeB.diff(timeA);

      //     // Convert the time difference from milliseconds to a moment duration
      //     let duration = moment.duration(timeDifferenceMillis);

      //     // Format the time difference
      //     let formattedTimeDifference = moment
      //       .utc(duration.asMilliseconds())
      //       .format("HH:mm:ss.SSS[Z]");

      //     return formattedTimeDifference;
      //   }

      //   // Input time strings
      //   let a = e.shift !== null && e.shift.endtime;
      //   let b = Estarttime;
      //   console.log(a, b);
      //   // Calculate and log the time difference
      //   let difference = calculateTimeDifference(a, b);
      //   console.log(difference); // Output: 03:44:34.000Z

      function calculateTimeDifference(a: any, b: any) {
        // Parse the time strings using moment.js to create moment objects
        let timeA = moment(a, "HH:mm:ss.SSSZ");
        let timeB = moment(b, "HH:mm:ss.SSSZ");

        // Calculate the time difference between timeA and timeB in milliseconds
        let timeDifferenceMillis = timeA.diff(timeB);

        // If the difference is negative, it means timeB is greater than timeA
        // We need to adjust it to represent a positive duration
        if (timeDifferenceMillis < 0) {
          timeDifferenceMillis += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
        }

        // Convert the time difference from milliseconds to a moment duration
        let duration = moment.duration(timeDifferenceMillis);

        // Format the time difference
        let formattedTimeDifference = moment
          .utc(duration.asMilliseconds())
          .format("HH:mm:ss.SSS[Z]");

        return formattedTimeDifference;
      }

      let a = e.shift !== null && e.shift.endtime;
      let b = Estarttime;

      let timeDifference = calculateTimeDifference(a, b);
      //   console.log(timeDifference); // Output: 02:00:00.000Z
    }

    return (
      <>
        {" "}
        {e.shift !== null && e.shift.starttime == null
          ? e.shift.shift
          : `${e.shift !== null && e.shift.starttime} to ${
              e.shift !== null && e.shift.endtime
            }`}
      </>
    );
  };
  const MileageAfterShift = (e: any) => {
    let Sstarttime = "";
    let Estarttime = "";
    if (e.Mileage !== null) {
      const StimeString = e.startKm;
      const [shours, sminutes, sseconds] = StimeString.split(":");

      Sstarttime = shours + ":" + sminutes + ":" + sseconds + ".000Z";

      //   console.log(Sstarttime);
      const EtimeString = e.endkm;
      const [ehours, eminutes, eseconds] = EtimeString.split(":");

      Estarttime = ehours + ":" + eminutes + ":" + eseconds + ".000Z";
    }
    return (
      <>
        {Sstarttime} to {Estarttime}
      </>
    );
  };
  const Mileage = (e: any) => {
    return <>{parseFloat(e.Mileage).toFixed(2)}</>;
  };
  const columns = [
    {
      field: "date",
      header: "Date",
      textalign: "left",
      fieldtype: "date",
      filterOption: true,
      filterFieldOption: "date",
      width: "150px",
    },
    // // {field: 'imageData', header: 'Image', fieldtype:'image'},
    {
      field: "driver",
      header: "Driver",
      textalign: "left",
      fieldtype: "lookup",
      filterOption: true,
      filterFieldOption: "driver.name",
      width: "130px",
    },
    {
      field: "startKm",
      header: "Start",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "startKm",
      width: "130px",
    },
    {
      field: "endkm",
      header: "Stop",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "endkm",
      width: "130px",
    },
    {
      field: "totalduration",
      header: "Total Duration",
      textalign: "left",
      fieldtype: "text",
      filterOption: true,
      filterFieldOption: "totalduration",
      width: "130px",
    },
    {
      field: "Mileage",
      header: "Total Mileage",
      textalign: "left",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "endkm",
      width: "130px",
      template: (r: any) => <>{Mileage(r)}</>,
    },
    {
      field: "MileageafterWH",
      header: "Actual Shift",
      textalign: "left",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "endkm",
      width: "130px",
      template: (r: any) => <>{MileageShift(r)}</>,
    },
    {
      field: "MileageafterWH",
      header: "Mileage Shift",
      textalign: "left",
      fieldtype: "custom",
      filterOption: true,
      filterFieldOption: "endkm",
      width: "130px",
      template: (r: any) => <>{MileageAfterShift(r)}</>,
    },
    //  template: (r: any) => <>{AttachmentBodyTemplate(r)}</>
  ];

  return (
    <div>
      {" "}
      {/* <div className="card">
        <ListTemplate
          header="Manage Vendors"
          value={date}
          paginator={true}
          rows={100}
          dynamicColumns={columns}
          emptyMessage="No Vendors found."
          responsiveLayout="scroll"
          className="datatable-responsive"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vendors"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[100, 200, 300]}
          //   selection={selectedProducts}
          //   multiselect={true}
          loading={loading}
          selectedItems={setSelectedItems}
        />
      </div> */}
    </div>
  );
};
export default MileageReport;
