import { useEffect, useState } from 'react';
import React from 'react';
import FormTemplate from './FormTemplate';

const RepeatingTemplate = (props: any) => {
    const [data, setData] = useState<any>({});
    const [forms, setsforms] = useState<any>([]);
    const [messsage, setMesssage] = useState<any>('');
    console.log(props.items);

    useEffect(() => {
        // props.onload();
        // let a = {
        //     name:'s',
        //     title:'s',
        //     type:'form',
        //     columns:
        // };
        // setsforms([a]);
    }, []);
    useEffect(() => {
        if (data) props.onChange(data);
    }, [data]);
    const handlesubChange = async (event: any, type: any, fieldName: any) => {
        // if (event.type === "form" )
        //     setData({
        //         ...data,
        //         [type === 'fileupload' || type == 'form' ? fieldName : type === 'number' ? event.originalEvent.target.name : event.target.name]:
        //             type == 'form' ? event : type === 'fileupload' ? event.files[0].objectURL : type === 'number' ? event.originalEvent.target.value : type === 'multiselect' ? event.target.value : event.target.value
        //     });

        if (fieldName != event) {
            setData({
                ...data,
                [fieldName]: event
            });
            if (type) props.onChange(event, type, fieldName);
        } else {
            delete data[event];
            setData({
                ...data
            });
        }
        props.onChange(event, type, fieldName);
    };

    const subsubmitdata = async (newprops: any) => {
        // let currentitem = props.items.filter((p:any)=> p.name === itemname);
        // subvalidateinputs(currentitem);
    };
    const subvalidateinputs = (currentitem: any) => {
        setMesssage('');
        let collecteddata = data;
        let a = props.columns.filter((p: any) => p.fieldtype != 'header');
        let b = props.columns.filter((p: any) => p.fieldtype != 'header' && p.required);
        let c = Object.keys(collecteddata);
        let d = c.filter((v: any) => {
            return collecteddata[v];
        });

        let e = b.every((v: any) => {
            return d.includes(v.name);
        });

        if (!e) setMesssage('* Please fill all required fields');

        props.submitForm(collecteddata);
    };

    return (
        <div>
            {props.items.map((ele: any, index: any) => {
                function generatecolumns(arg0: any, columns: any) {
                    //    columns.columns[0].name = arg0;
                    //    columns.columns[0].value = arg0;
                    return columns;
                }

                return (
                    <React.Fragment key={ele.name}>
                        {props.data.length > 0 ? (
                            <FormTemplate
                                id="column_container"
                                ChangeBtn={'OpenDocument'}
                                layout="grid2"
                                name={ele.name}
                                title={ele.title}
                                columns={ele.columns}
                                showaction={props.showaction}
                                mode={props.mode}
                                data={props.data[index]}
                                onChange={(e: any) => (e ? handlesubChange(e, ele.fieldtype, ele.name) : '')}
                                submitForm={subsubmitdata}
                                subIndex={index}
                                fromchild={true}
                            />
                        ) : (
                            <FormTemplate
                                id="column_container"
                                ChangeBtn={'OpenDocument'}
                                layout="grid2"
                                name={ele.name}
                                title={ele.title}
                                columns={ele.columns}
                                showaction={props.showaction}
                                mode={props.mode}
                                data={props.data}
                                onChange={(e: any) => (e ? handlesubChange(e, ele.fieldtype, ele.name) : '')}
                                submitForm={subsubmitdata}
                                subIndex={index}
                                fromchild={true}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};
export default RepeatingTemplate;
