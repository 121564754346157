import { API } from 'aws-amplify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AmplifyServices from '../../../Service/Service';
import { createDxlogMileage, createDxMasterLogs, updateDxMasterLogs } from '../../../graphql/mutations';
import { listDxAllocations, listDxlogMileages, listDxVehicles } from '../../../graphql/queries';
import { GraphQLSubscription } from '@aws-amplify/api';
import FormTemplate from '../../../Components/FormTemplate';
import { OnCreateDxlogMileageSubscription } from '../../../API';
import { onCreateDxlogMileage } from '../../../graphql/subscriptions';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import moment from 'moment';
import dxService from '../../../Services/dxService';
import { getNextQueryData, localetoisodateendtime, localetoisodatetime } from '../../../utils/helper';

let driversNameOptions: any = [];
const Mileagenewform = () => {
    const toast = useRef<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [vehicleIDOptions, setVehicleIDOptions] = useState<any>([]);
    const [vehicleID, setVehicleID] = useState<any>('');
    const [mileageDate, setFinedDate] = useState<any>(null);
    const [driverName, setDriverName] = useState<any>('');
    const [driverID, setDriverID] = useState<any>('');
    const [listFormData, setlistFormData] = useState<any>({});
    const [allocationID, setallocationID] = useState<any>('');
    const [errorMsg, seterrorMsg] = useState<any>(null);
    const location1 = useLocation();
    const urlParams1 = new URLSearchParams(location1.search);
    var formmode: any = urlParams1.has('mode') ? urlParams1.get('mode') : null;
    var formid: any = urlParams1.has('id') ? urlParams1.get('id') : null;
    var driver: any = urlParams1.has('driver') ? urlParams1.get('driver') : null;
    const navigate = useNavigate();

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
        formid = urlParams1.has('id') ? urlParams1.get('id') : null;
        const paths = location1.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'allocations':
                setActiveIndex(2);
                break;

            default:
                if (formid) getvendordata(formid);
                break;
        }
    }, [location1]);
    useEffect(() => {
        if (formid !== null) {
            fetchUserIdUserName().then((driver: any) => {
                setlistFormData({
                    vehicleid: driver[0].id
                    //  drivername: driver[0].driver ? driver[0].driver.name : ''
                });
            });
        }
    }, []);
    const fetchUserIdUserName = async (nextToken?: any) => {
        let variables: any = {
            id: {
                eq: formid
            }
        };
        let Finedata = await dxService.listitems('vehicleactivity', '*', variables, true, 1000, nextToken);
        let response = Finedata.items;

        let nexttoken = Finedata.nextToken;
        if (nexttoken) {
            response = [...response, ...(await fetchUserIdUserName(nexttoken))];

            // response = response.concat(nextResults);
        }

        return response;
    };
    useEffect(() => {
        checkActiveIndex();
        getVehicleID();
        getdriverID();
    }, [checkActiveIndex]);
    const getvendordata = async (fid: any) => {
        await AmplifyServices.getFilterbyIddetails(listDxlogMileages, 'listDxlogMileages', fid).then((a) => {
            if (a.length > 0) {
                setlistFormData(a[0]);
            }
        });
    };

    const getVehicleID = async () => {
        let GetResponse: any = await API.graphql({ query: listDxVehicles, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        setVehicleIDOptions(GetResponse.data.listDxVehicles.items);
        // getDriverDetailsByDateandVID();
    };
    const getdriverID = async () => {
        driversNameOptions = [];
        let drivers = await dxService.listitems('driver');
        drivers.map((res: any) => {
            res['label'] = res.name;
            res['value'] = res.id;
            driversNameOptions.push(res);
        });
    };
    useEffect(() => {
        mileageDate !== null && vehicleID !== '' && getDriverDetailsByDateandVID();
    }, [mileageDate, vehicleID]);

    //get driver id and driver name based on vehicle and date selection
    const getDriverDetailsByDateandVID = async () => {
        let filter = {
            vehicleid: {
                eq: vehicleID
            },

            fromdate: {
                le: mileageDate ? localetoisodatetime(mileageDate) : null
            },

            or: [
                {
                    handoverdate: {
                        ge: mileageDate ? localetoisodateendtime(mileageDate) : null
                    }
                },

                {
                    handoverdate: {
                        ne: ''
                    }
                }
            ]
        };

        dxService.listitems('allocation', '*', filter, true, 100000).then((res) => {
            res.items = res.items.sort((a: { fromdate: any }, b: { fromdate: string | number | Date }) => {
                const dateA = new Date(a.fromdate);

                const dateB = new Date(b.fromdate);

                return dateB.getTime() - dateA.getTime();
            });

            if (res.items.length > 0) {
                const filteredItems = res.items.filter((item: { handoverdate: string | number | Date; fromdate: string | number | Date }) => {
                    if (item.handoverdate) {
                        const fromDate = new Date(item.fromdate).toDateString();

                        const handoverDate = new Date(item.handoverdate).toDateString();

                        const selectedDateObj = new Date(mileageDate).toDateString();

                        return selectedDateObj === fromDate && selectedDateObj === handoverDate;
                    } else {
                        return false;
                    }
                }); // Filtered items are available

                const selectedItem = filteredItems.length > 0 ? filteredItems[0] : res.items[0];

                setlistFormData({
                    ...listFormData,

                    drivername: selectedItem.driver.id,

                    date: mileageDate
                });
                setallocationID(selectedItem.id);
                setDriverName(selectedItem.fromdrivername !== null ? selectedItem.fromdrivername : selectedItem.handoverdrivername !== null ? selectedItem.handoverdrivername : '');

                setDriverID(selectedItem.dxDriverAllocationsId !== null ? selectedItem.dxDriverAllocationsId : selectedItem.driverID !== null ? selectedItem.driverID : '');
            } else {
            }
        });
    };

    const VehicleOptions: any = [];
    const names = vehicleIDOptions
        .filter((del: any) => del._deleted !== true)
        .map((res: any) => {
            res['label'] = res.id;
            res['value'] = res.id;
            VehicleOptions.push(res);
        });
    useEffect(() => {
        let cols = formColumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });
        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);
    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                title: 'Basic Details',
                name: 'basicdetails',
                fieldtype: 'header',
                class: 'md:col-12'
            },

            {
                name: 'vehicleid', // pass the exact column name of db
                title: 'Vehicle ID',
                fieldtype: 'dropdown',
                value: '',
                required: formid !== null && driver !== null ? false : true,
                // required: false,
                // disabled: formmode == 'view' ? true : false,
                disabled: (formid !== null && driver !== null) || formmode == 'view' ? true : false,
                hidden: false,
                data: VehicleOptions,
                class: 'md:col-6'
            },
            {
                name: 'date', // pass the exact column name of db
                title: 'Date',
                fieldtype: 'date',
                value: '',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },

            {
                name: 'drivername', // pass the exact column name of db
                title: 'Driver Name',
                fieldtype: formid !== null && driver !== null ? 'text' : 'dropdown',
                // value: driverID,
                value: '',
                required: formid !== null && driver !== null ? false : true,
                // required: false,
                disabled: (formid !== null && driver !== null) || formmode == 'view' ? true : false,
                hidden: false,
                data: formid !== null && driver !== null ? [] : driversNameOptions,
                class: 'md:col-4'
            },

            {
                name: 'startKM', // pass the exact column name of db
                title: 'Start KM',
                fieldtype: 'number',
                value: '',
                required: false,
                disabled: false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'endKM', // pass the exact column name of db
                title: 'End KM',
                fieldtype: 'number',
                value: '',
                required: false,
                disabled: false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            }
        ],
        actions: [
            {
                title: 'Submit',
                disabled: errorMsg !== null ? true : false
            },
            {
                title: 'Cancel'
            }
        ]
    };
    const getmasterlogdata = async (props?: any, nextToken?: any) => {
        // let variables: any = {};
        console.log(props);

        let convertdate: any = localetoisodatetime(props.date);
        let splitdate: any = convertdate.split('T')[0];
        let variables: any = {
            // and: [{ date: { ge: localetoisodatetime(lastTwoWeekStartDate) } },

            //      { date: { le: moment(localetoisodatetime(lasttwoWeekEndDate.toISOString())).format('YYYY-MM-DD') + 'T23:59:59.999Z' } }
            //     ]
            vehicleid: { eq: props.vehicleid },
            date: { contains: splitdate },
            dxDriverMasterlogsId: { eq: props.driverid },
            reimbursement: { ne: 'Yes' }
        };
        let get14daysExp = await dxService.listitems('masterlog', '*', variables, true, 6000, nextToken);
        let response = get14daysExp.items;

        let nexttoken = get14daysExp.nextToken;
        if (nexttoken) {
            response = [...response, ...(await getmasterlogdata(props, nexttoken))];
        }
        return response;
    };
    const addData = async (props: any) => {
        let data = props;
        let filter = {
            id: { eq: data.drivername }
        };
        await getNextQueryData('', filter, 'driver', true).then(async (driver: any) => {
            // data['id'] = Math.random().toString(36).substring(2, 9);
            data['driverid'] = data.drivername !== undefined ? data.drivername : driverID;
            data['drivername'] = driver[0].name !== undefined ? driver[0].name : driverName;

            const subs = API.graphql<GraphQLSubscription<OnCreateDxlogMileageSubscription>>({
                query: onCreateDxlogMileage,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            }).subscribe({
                next: async ({ provider, value }) => {},
                error: (error) => console.warn(error)
            });
            await getmasterlogdata(props).then(async (res: any) => {
                let sumMileage = 0;

                if (res.length > 0) {
                    if (allocationID !== '') {
                        res.map((item: any) => {
                            sumMileage += item.Mileage !== '' && item.Mileage !== null && item.Mileage !== 'NaN' ? parseFloat(item.Mileage) : 0;
                            // sumSalik += parseFloat(item.Parking);
                        });
                        let diffMileage = parseFloat(data.endKM) - parseFloat(data.startKM);
                        let masterData = {
                            vehicleid: data.vehicleid,
                            dxVehicleActivityLogsId: data.vehicleid,
                            drivername: data.drivername !== undefined ? data.drivername : driverName,
                            date: localetoisodatetime(data.date),
                            Mileage: sumMileage + diffMileage,
                            // Fine: '',
                            // Salik: '',
                            // Vipfuel: '',
                            dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                            dxVehicleMasterlogsId: data.vehicleid,
                            _version: res[0]._version,
                            dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : '',
                            id: res[0].id
                        };
                        let os = await dxService.updateitem('masterlog', masterData).then((success: any) => {
                            // showSuccess('', 'Added Successfully');
                            if (subs) subs.unsubscribe();
                            // window.history.back();
                        });
                    } else {
                        seterrorMsg('There is no Allocation available for this combination');
                        showError();
                    }
                } else {
                    if (allocationID !== '') {
                        let masterData: any = {
                            vehicleid: data.vehicleid,
                            dxVehicleActivityLogsId: data.vehicleid,
                            drivername: data.drivername !== undefined ? data.drivername : driverName,
                            date: localetoisodatetime(data.date),
                            Mileage: parseFloat(data.endKM) - parseFloat(data.startKM),
                            // Fine: '',
                            // Salik: '',
                            // Vipfuel: '',
                            dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                            dxVehicleMasterlogsId: data.vehicleid,
                            dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : ''
                        };
                        let cuskey = `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}`;
                        masterData['id'] = cuskey;
                        let master = await dxService.createitem('masterlog', masterData, false).then((success) => {
                            // showSuccess('', 'Added Successfully');
                            if (subs) subs.unsubscribe();
                            // window.history.back();
                        });
                    } else {
                        seterrorMsg('There is no Allocation available for this combination');
                        showError();
                    }
                }
            });

            data['distance'] = parseFloat(data.endKM) - parseFloat(data.startKM);
            let mileageData = {
                vehicleid: data.vehicleid,
                date: localetoisodatetime(data.date),
                distance: data.distance,
                dxMasterLogsMileagesId: `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}`,
                jfield: JSON.stringify({
                    allocationid: allocationID
                }),
                endKM: data.endKM,
                startKM: data.startKM,
                id: data.id,
                drivername: data.drivername
            };
            if (allocationID !== '') {
                let parkingitem = await dxService.createitem('logmileage', mileageData, false).then(async (success) => {
                    await showSuccess('', 'Added Successfully');
                    if (subs) subs.unsubscribe();
                    setTimeout(() => {
                        window.history.back();
                    }, 1000);
                });
            }
        });

        // navigate('/manage/logs/all');
    };
    const changedata = (props: any) => {
        props['vehicleid'] !== undefined && setVehicleID(props.vehicleid);
        props['date'] !== undefined && setFinedDate(props.date);
    };
    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';

    const wizardItems = [
        { label: 'New Mileage', command: () => navigate('/manage/mileage/item/basic' + qs) },
        { label: 'New Fines', command: () => navigate('/manage/fines/item/basic' + qs) },
        { label: 'New Salik', command: () => navigate('/manage/salik/item/basic' + qs) },
        { label: 'New VIP Fuel', command: () => navigate('/manage/vipfuel/item/basic' + qs) },
        { label: 'New Parking', command: () => navigate('/manage/parking/item/basic' + qs) }
    ];
    const editnewnavigation = async (formdata: any) => {
        let b: any = formdata;
    };
    const showSuccess = (summary: string, detail: string) => {
        toast.current.show({
            severity: 'success',
            summary: summary,
            detail: detail,
            life: 3000
        });
    };
    const showError = () => {
        toast.current.show({
            severity: 'error',
            summary: 'There is no Allocation available for this combination',
            life: 3000
        });
    };
    const addConfig = (props: any) => {
        if (formmode == 'new') addData(props);
        else if (formmode == 'edit') {
            editnewnavigation(props);
        }
    };
    const logtabItems = [
        { label: 'Mileage', command: () => navigate('/manage/logs/mileage') },

        {
            label: 'Salik',
            command: () => navigate('/manage/logs/salik')
        },

        // { label: 'Documents', disabled: formID != "" ? false : true ,command: () => navigate('/manage/appointments/item/documents' + qs) }
        { label: 'Vip Fuel', command: () => navigate('/manage/logs/vipfuel') },
        { label: 'Fines', command: () => navigate('/manage/logs/fines') },
        { label: 'Parking', command: () => navigate('/manage/logs/parking') },
        {
            label: 'All',
            command: () => navigate('/manage/logs/all')
        }
    ];
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{formmode === 'edit' ? 'Edit Mileage : ' + formid : formmode === 'view' ? 'View Mileage: ' + formid : 'New Mileage'}</h4>
                <TabMenu className="spd-pemptytopbottom" model={logtabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                <div className="card container-card">
                    <Toast ref={toast} />
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                    <Routes>
                        <Route
                            path={'/basic'}
                            element={
                                <>
                                    <FormTemplate columns={formColumns} data={listFormData} showaction={true} onChange={changedata} mode={formmode} ChangeBtn={'OpenDocument'} layout="grid2" title="New Approval" submitForm={addConfig} />
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};
export default Mileagenewform;
