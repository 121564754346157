import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import AmplifyServices from '../../Service/Service';
//import AmplifyServices from '../Service/Service'
import ListTable from '../../Components/ListTable';
import moment from 'moment';
import './approvals.css';
import { useNavigate } from 'react-router';
import { Menu } from 'primereact/menu';
import {  deleteDxAppointments } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { Dialog } from 'primereact/dialog';
import { _listDxApprovalConfigs } from '../../dxgraphql/dxqueries';

// var selectedItems:any
let params: any;
const ManageWFConfig = (props: any) => {
    // selectedItems=props.selectedProducts
    const menu = useRef<any>(null);
    const [dbdata, setdbdata] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);
    // setSelectedItems(props.selectedItems);
    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {

        let GetResponse: any = await API.graphql({ query: _listDxApprovalConfigs, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        setdbdata(GetResponse.data.listDxApprovalConfigs.items);
    };
    const columns = [
        // { fieldtype:'multiselect'},
        { field:'id', showfield: 'title', header: 'Title', fieldtype: 'link', filterOption: true, filterFieldOption: 'place', path:'/#/manage/workflows/item?id=' },
        { field: 'field1', header: 'Description', fieldtype: 'text', filterOption: true, filterFieldOption: 'eventtype' },
        { field: 'createdAt', header: 'Created', fieldtype: 'date', showtime: false, filterOption: true, filterFieldOption: 'Date' },
        { field: 'workflow', header: 'Workflows', fieldtype: 'lookupcollection', showtime: false, filterOption: true, filterFieldOption: 'workflow' },
        { field: 'steps', header: 'Steps', fieldtype: 'lookupcollection', showtime: false, filterOption: true, filterFieldOption: 'steps' }

        //lookup
    ];

    window.onclick = function (event: any) {
        if (!event.target.matches('.dropbtn')) {
            var dropdowns = document.getElementsByClassName('dropdown-content');
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    };
    const navigate = useNavigate();
    const deleteItem = async () => {
        console.log(selectedItems);
        AmplifyServices.DeleteItems(selectedItems, deleteDxAppointments).then((res) => {
            getData();
            hideDeleteItemsDialog();
            setSelectedItems([]);
            selectedItems.length = 0;
        });
       
    };
    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };

    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };
    console.log(selectedItems);
    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New" onClick={() => navigate('/manage/workflows/add')} className="p-button-primary mr-2  newbtnhover" />
                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />

                <Menu
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedItems.length == 1 ? false : true,
                            label: 'Associate Workflow',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                params = selectedItems.length > 0 && `?form=edit&id=${selectedItems[0].id}`;
                                console.log(selectedItems[0], 'selectedItems[0]');
                                navigate('/manage/workflows/associate' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length == 1 ? false : true,
                            label: 'Close Appointment',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                params = selectedItems.length > 0 && `?&form=edit&vid=${selectedItems[0].vehicleid}&type=${selectedItems[0].eventtype}&ftype=vehicle%23activity&id=${selectedItems[0].id}`;
                                console.log(selectedItems[0], 'selectedItems[0]');
                                navigate('/general/appointments/createAppointmentform' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                // deleteItem();
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            <div>
                <input
                    // value={FilterData}
                    type="text"
                    id="search"
                    // onChange={SearchOnchange}
                    placeholder="Search ..."
                    className="p-inputtext p-component homeNews__search"
                />

              
            </div>
        </div>
    );
    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text companyBtn" onClick={() => deleteItem()} />
        </>
    );
    var sorteddata = dbdata.sort(function (a: any, b: any) {
        return moment(b.updatedAt).format().localeCompare(moment(a.updatedAt).format());
    });
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Workflow Configurations</h4>
                <div className={`datatable-editing-demo ${props.className}`}>
                    <ListTable
                        header="Manage Appointments"
                        value={sorteddata}
                        paginator={true}
                        rows={10}
                        dynamicColumns={columns}
                        emptyMessage="No appointments found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vehicles"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 25]}
                        //   selection={selectedProducts}
                        multiselect={true}
                        //   selectedItems={selectedProducts}
                        Commandbar={Commandbar}
                        selectedItems={setSelectedItems}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}
                    />
                    <Dialog visible={deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedItems && <span>Are you sure you want to delete the selected items?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ManageWFConfig;
