import React, { useEffect, useRef, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';

import { API, graphqlOperation } from 'aws-amplify';
import { listDxLogVipfuels, listDxLogSaliks, listDxLogFines, listDxMasterLogs, listDxAllocations, listDxDepartments, listDxContracts, listDxVehicleActivities } from '../../graphql/queries';
import moment from 'moment';
import { createDxLogFines, createDxLogVipfuel, createDxLogSalik, createDxMasterLogs, createDxVehicle } from '../../graphql/mutations';

import { Button as AwsButton } from '@aws-amplify/ui-react';
import Documents from './Document';
import awsconfig from '../../aws-exports';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import dxService from '../../Services/dxService';

let errMessages: any = [];
function TempImport() {
    const navigate = useNavigate();
    const toast = useRef<any>(null);
    const [jsonData, setJsonData] = useState([]);
    const [savingData, setSavingData] = useState(false);
    const [charges, setCharges] = useState<any>([]);
    const [contracts, setContracts] = useState<any>([]);
    const [vehicleActivity, setVehicleActivity] = useState<any>([]);
    const [errorMessage, setErrorMessage] = useState<any>([]);

    useEffect(() => {
        const getData = async (nextToken?: any) => {
            let variables: any = {};

            if (nextToken) {
                variables.nextToken = nextToken;
            }
            let GetResponse: any = await API.graphql({
                query: listDxContracts,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: variables
            });
            let res = GetResponse.data.listDxContracts.items;

            if (GetResponse.data.listDxContracts.nextToken) {
                const nextResults = await getData(GetResponse.data.listDxContracts.nextToken);
                res = res.concat(nextResults);
            }
            return res;
        };
        const getVactivity = async (nextToken?: any) => {
            let variables: any = {};

            if (nextToken) {
                variables.nextToken = nextToken;
            }
            let GetResponse: any = await API.graphql({
                query: listDxVehicleActivities,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: variables
            });
            let res = GetResponse.data.listDxVehicleActivities.items;

            if (GetResponse.data.listDxVehicleActivities.nextToken) {
                const nextResults = await getVactivity(GetResponse.data.listDxVehicleActivities.nextToken);
                res = res.concat(nextResults);
            }
            return res;
        };
        getData().then((res) => {
            console.log(res);
            setContracts(res);
        });
        getVactivity().then((res) => {
            console.log(res);
            setVehicleActivity(res);
        });
        setErrorMessage([]);
    }, []);
    console.log(jsonData);

    // console.log(driver);
    // console.log(jsonData);

    const checkNaughtyDriver = async () => {
        errMessages = [];
        setErrorMessage([]);

        const items: any = [];

        if (jsonData) {
            jsonData.map((item: any) => {
                if (charges.label === 'Insurance') {
                    const InsuranceData = {
                        renewalno: item.Policyno && `${item.Policyno}`,
                        issuedate: item.Issuedate && new Date(item.Issuedate).toISOString(),
                        expirydate: item.expirydate && new Date(item.expirydate).toISOString(),
                        scvendor: item.Company ? item.Company : '',
                        vehicleid: `${item.VehiclePlateno.replace('-', '').replace('/', '')}`,
                        renewalstatus: 'Active',
                        renewaltype: 'Insurance'
                    };
                    items.push(InsuranceData);
                } else if (charges.label === 'Seasonal Parking') {
                    const Parking = {
                        renewalno: item['permit no'] && `${item['permit no']}`,
                        issuedate: item.issuedate && new Date(item.issuedate).toISOString(),
                        expirydate: item.Expirydate && new Date(item.Expirydate).toISOString(),
                        vehicleid: `${item.VehiclePlateno.replace('-', '').replace('/', '')}`,
                        subtype: item['permit type'] && item['permit type'],
                        renewalstatus: 'Active',
                        renewaltype: 'Parking'
                    };
                    items.push(Parking);
                } else if (charges.label === 'Branding') {
                    const Branding = {
                        expirydate: item.Expirydate && new Date(item.Expirydate).toISOString(),
                        renewalno: item.PermitNo && `${item['PermitNo']}`,
                        issuedate: item.Startdate && new Date(item.Startdate).toISOString(),
                        vehicleid: `${item.VehiclePlateno.replace('-', '').replace('/', '')}`,
                        renewalstatus: 'Active',
                        renewaltype: 'Branding'
                    };
                    items.push(Branding);
                } else if (charges.label === 'Registration') {
                    const Registration = {
                        expirydate: item.Expirydate && new Date(item.Expirydate).toISOString(),
                        issuedate: item.Issuedate && new Date(item.Issuedate).toISOString(),
                        vehicleid: item.VehiclePlateno && item.VehiclePlateno.replace('-', '').replace('/', ''),
                        renewalno: item.Registrationno && `${item.Registrationno}`,
                        renewalstatus: 'Active',
                        renewaltype: 'Registration'
                    };
                    items.push(Registration);
                } else if (charges.label === 'Warranty') {
                    const Warranty = {
                        expirydate: item.Expirydate && new Date(item.Expirydate).toISOString(),
                        issuedate: item.issuedate && new Date(item.issuedate).toISOString(),
                        vehicleid: item.VehiclePlateno && `${item.VehiclePlateno.replace('-', '').replace('/', '')}`,
                        renewalno: item.WarrantyNo && `${item.WarrantyNo}`,
                        scvendor: item.Company && `${item.Company}`,
                        renewalstatus: 'Active',
                        renewaltype: 'Warranty'
                    };
                    items.push(Warranty);
                } else if (charges.label === 'Service Contract') {
                    const ServiceContract = {
                        expirydate: item.Expirydate && new Date(item.Expirydate).toISOString(),
                        issuedate: item.Issuedate && new Date(item.Issuedate).toISOString(),
                        vehicleid: item.VehiclePlateno && `${item.VehiclePlateno.replace('-', '').replace('/', '')}`,
                        renewalno: item.Contractno && `${item.Contractno}`,
                        scvendor: item.Company && `${item.Company}`,
                        renewalstatus: 'Active',
                        renewaltype: 'ServiceContract'
                    };
                    items.push(ServiceContract);
                }
            });
        }
        let uniqueArr = [...new Set(errMessages)];

        setErrorMessage(uniqueArr);

        await updteData(items).then((res) => {
            updateVehicleActivity(res);
        });
        setTimeout(() => {
            errMessages = [];
            setErrorMessage([]);
        }, 5000);
    };

    const updteData = async (data: any) => {
        setSavingData(true);

        // Using Promise.all to execute all queries concurrently
        let saveData = await Promise.all(
            data.map(async (item: any) => {
                let createdData;

                try {
                    const res = await contracts.filter((fil: any) => fil.vehicleid === item.vehicleid && fil.renewaltype === item.renewaltype);
                    console.log(res);

                    if (res.length > 0) {
                        console.log('Update');
                        const { id, _version } = res[0];
                        createdData = await dxService.updateitem('renewal', { ...item, id, _version });
                        return createdData;
                    } else {
                        const id = new Date().getTime() + parseInt(('0' + Math.floor(Math.random() * 100)).slice(-2));
                        console.log('Create new');
                        createdData = await dxService.createitem('renewal', { ...item, id });
                        return createdData;
                    }
                } catch (error) {
                    console.error(error);
                }
                return createdData;
            })
        ).then((res) => {
            return res;
        });
        return saveData;
    };

    const updateVehicleActivity = async (data: any) => {
        await Promise.all(
            data.map(async (item: any) => {
                try {
                    // const res = await dxService.getitembyid('vehicleactivity', item.vehicleid);
                    const res = vehicleActivity.filter((va: any) => va.id === item.vehicleid);
                    if (res) {
                        const expiringData = res.expiring ? JSON.parse(res.expiring) : {};
                        const newactivity = { id: item.id, renewalno: item.renewalno, issueDate: item.issuedate, expiryDate: item.expirydate, status: 'Completed' };
                        const vactivity = {
                            id: res.id,
                            dxContractActivityId: item.id,
                            expiring: JSON.stringify({ ...expiringData, [charges.name]: newactivity }),
                            _version: res._version
                        };
                        const result = await dxService.updateitem('vehicleactivity', vactivity);
                        console.log(result);

                        return result;
                    }
                } catch (error) {
                    console.error(error);
                }
            })
        ).then(() => {
            setSavingData(false);
            setJsonData([]);
        });
    };

    return (
        <div className="col-12 md:col-12">
            <Toast ref={toast}></Toast>
            <h4>Import</h4>
            <div className="card p-fluid">
                <div className="field">
                    <div className="col-12 sm:col-8 md:col-7 grid">
                        <div className="col-12 md:col-2 mt-2">
                            <label htmlFor="">Select</label>
                        </div>
                        <div className="col-12 md:col-10">
                            <Dropdown
                                options={[
                                    { label: 'Insurance', name: 'Insurance' },
                                    { label: 'Branding', name: 'Branding' },
                                    { label: 'Registration', name: 'Registration' },
                                    { label: 'Warranty', name: 'Warranty' },
                                    { label: 'Seasonal Parking', name: 'Parking' },
                                    { label: 'Service Contract', name: 'ServiceContract' }
                                ]}
                                value={charges}
                                onChange={(e) => setCharges(e.target.value)}
                                optionLabel="label"
                                placeholder="Select an option"
                                filter
                            />
                        </div>
                    </div>
                </div>

                {charges.name !== undefined && (
                    <div className="field">
                        <div className="col-12 sm:col-8 md:col-7 grid">
                            <div className="col-12 md:col-2 mt-2">
                                <label htmlFor="">Upload File</label>
                            </div>
                            <div className="col-12 md:col-10">
                                <Documents setJsonData={setJsonData} sheetName={charges.label} />
                                {/* <FileUpload accept=".xlsx, .xls*" auto={true} customUpload={true} uploadHandler={handleFileUpload} /> */}
                            </div>
                        </div>
                    </div>
                )}
                <div className="field col-5 grid md:col-3">
                    <AwsButton
                        className=" mr-2"
                        disabled={charges.name === undefined || jsonData.length === 0 ? true : false}
                        onClick={async () => {
                            checkNaughtyDriver();
                        }}
                    >
                        {savingData === false ? 'Submit' : 'Saving'}
                    </AwsButton>
                    <AwsButton
                        style={{
                            background: '#d32f2f',
                            color: '#fff',
                            border: 'none'
                        }}
                        onClick={async () => {
                            navigate('/');
                        }}
                    >
                        Cancel
                    </AwsButton>
                </div>
                <div className="grid col-12 md:col-12">
                    <div className="col-12 md:col-12" style={{ color: '#256029' }}>
                        {/* <pre>{showData == true && JSON.stringify(data, null, 2)}</pre> */}

                        <br />
                        {errorMessage.map((tem: any) => {
                            return <div>{tem}</div>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TempImport;
