import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import awsExports from '../aws-exports';
import dxService from '../Services/dxService';

export const updateUserName = async (newname: any) => {
    try {
        const user = await Auth.currentAuthenticatedUser();

        const result = await Auth.updateUserAttributes(user, newname);

        console.log(result);
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getcurrenruserprofile = async () => {
    return Auth.currentAuthenticatedUser().then(async (currentUser): Promise<any> => {
        let newitemvalue = {
            userid: currentUser.username,

            email: currentUser.attributes.email ? currentUser.attributes.email : '',

            displayname: currentUser.attributes.preferred_username ? currentUser.attributes.preferred_username : currentUser.username
        };

        return newitemvalue;
    });
};
export const checkuserprofile = async (currentUser: any) => {
    console.log(currentUser);
    let userprofile = await dxService.getitembyid('userprofile', currentUser.username);
    if (userprofile === null) {
        let newitemvalue = {
            id: currentUser.username,
            userid: currentUser.username,
            provideruserid: '',
            email: currentUser.attributes.email ? currentUser.attributes.email : '',
            picture: currentUser.attributes.picture ? currentUser.attributes.picture : '',
            displayname: currentUser.attributes.preferred_username ? currentUser.attributes.preferred_username : currentUser.username,
            sub: currentUser.attributes.sub ? currentUser.attributes.sub : ''
        };

        userprofile = await dxService.createitem('userprofile', newitemvalue);
        console.log(userprofile);
        return userprofile;
    } else {
        if (currentUser.attributes.picture && userprofile.picture === '') {
            let updateitemvalue = {
                id: userprofile.id,
                picture: currentUser.attributes.picture,
                _version: userprofile._version
            };
            userprofile = await dxService.updateitem('userprofile', updateitemvalue);
            return userprofile;
        }
        console.log(userprofile);
        return userprofile;
    }
};

// export const changepassword = async (user: any, password: string, newpassword: string) => {
//     try {
//         const { requiredAttributes } = user.challengeParam;

//         const p = await Auth.completeNewPassword(
//             user, // the Cognito User Object
//             newpassword, // the new password
//             // OPTIONAL, the required attributes
//             requiredAttributes
//         );
//         return p;

//     } catch (error: any) {
//         console.log('error signing in', error);
//       throw error;
//     }
// }
