import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import awsconfig from '../../aws-exports';
import { AdminAddUserToGroupCommand, CognitoIdentityProviderClient, ListUsersCommand } from '@aws-sdk/client-cognito-identity-provider';
import { Groups } from './GroupNames';

// Get Group name from parent
const AddUser = (props: any) => {
    const [groupName, setGroupName] = useState([]);
    //all users in aws
    const [awsUser, setAwsUsers] = useState([]);
    //selected name from input search
    const [selectedNames, setSelectedNames] = useState([]);
    //filtering user based on search
    const [filteredUsers, setFilteredUsers] = useState([]);
    //Saving status
    const [saveStatus, setSaveStatus] = useState('Save');

    useEffect(() => {
        let urlGroupName = window.location.hash.split('Roles/')[1];
        //For getting the specific Group Name
        let FilteredGroup: any = Groups.filter((group: any) => group.name === urlGroupName);
        setGroupName(FilteredGroup);
    }, []);

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = async () => {
        const REGION = awsconfig.aws_appsync_region;
        const usercredential = await Auth.currentUserCredentials();
        const client = new CognitoIdentityProviderClient({ region: REGION, credentials: usercredential });
        const command = new ListUsersCommand({ UserPoolId: 'ap-south-1_RmUExvWI7' });
        const response: any = await client.send(command);
        console.log(response.Users);

        setAwsUsers(response.Users);

        // searchValues.push({});
    };
    const searchUser = (event: any) => {
        let _filteredUsers: any;

        let searchValues = [];
        let filterMail;
        let data: any = awsUser;
        for (let i = 0; i < data.length; i++) {
            filterMail = data[i].Attributes.filter((mail: any) => mail.Name === 'email');
            searchValues.push({ name: filterMail[0].Value });
        }
        if (!event.query.trim().length) {
            _filteredUsers = [...searchValues];
        } else {
            _filteredUsers = searchValues.filter((name) => name.name.toLowerCase().startsWith(event.query.toLowerCase()) || name.name.toLowerCase().includes(event.query.toLowerCase()));
        }
        console.log(_filteredUsers);

        setFilteredUsers(_filteredUsers);
    };
    const saveData = () => {
        if (selectedNames.length === 0) {
            alert('Enter User Email');
        } else {
            for (let i = 0; i < selectedNames.length; i++) {
                const element: any = selectedNames[i];
                setSaveStatus('Saving');
                awsUser
                    .filter((name: any) => {
                        return name.Attributes.map((item: any) => item.Value).includes(element.name);
                    })
                    .map(async (item: any) => {
                        const REGION = awsconfig.aws_appsync_region;
                        const usercredential = await Auth.currentUserCredentials();
                        const cpclient = new CognitoIdentityProviderClient({ region: REGION, credentials: usercredential });
                        var params = {
                            UserPoolId: 'ap-south-1_RmUExvWI7',
                            GroupName: 'FleetSupervisors', //Replace Group name here
                            Username: item.Username
                        };

                        try {
                            await cpclient.send(new AdminAddUserToGroupCommand(params));
                            console.log('Success');
                            setSaveStatus('Saved');
                        } catch (err) {
                            console.log(err);
                        }

                        if (selectedNames.length === i + 1) {
                            props.hide(false);
                            setSelectedNames([]);
                        }
                    });
            }

            // setUserName('');
        }
    };
    return (
        <>
            <div className="card">
                <h5>Add User to {groupName.map((group: any) => group.value).toString()}</h5>
                <div className="grid p-fluid">
                    <div className="col-12 md:col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>

                            {/* <InputText placeholder="Username" value={user} onChange={(e) => setNewUser(e.target.value)} /> */}
                            <AutoComplete value={selectedNames} suggestions={filteredUsers} completeMethod={searchUser} field="name" multiple onChange={(e) => setSelectedNames(e.value)} aria-label="Countries" dropdownAriaLabel="Select Country" />
                        </div>
                    </div>

                    <div className="col-12 md:col-4">
                        <div className="p-inputgroup">
                            <Button onClick={saveData} tooltip={selectedNames.length > 0 ? 'Click to Add' : ''}>
                                {saveStatus}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddUser;
