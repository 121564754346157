import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ListTable from '../../Components/ListTable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import AmplifyServices from '../../Service/Service';
import dxService from '../../Services/dxService';
import ListTemplate from '../../Components/ListTemplate';
const Logs = (props: any) => {
    const [LogData, setLogData] = useState<any>([]);
    const [Tablefilter, setTableFilters] = useState<any>('');
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    const navigate = useNavigate();
    const NavigateToHome = () => navigate(`/workspace/Mileagelog?&id=${formid}&fleettype=vehicle#activity`);
    useEffect(() => {
        getcurrentvehicle().then((res) => {
            setLogData(formatDatefield(res));
        });
    }, []);
    const getcurrentvehicle = async (nextToken?: any) => {
        let variables = {
            vehicleid: {
                eq: formid // filter priority = 1
            }
        };
        let GetResponse: any = await dxService.listitems('masterlog', '*', variables, true, 100, nextToken);
        let response = GetResponse.items;

        //push the items to the setitems
        setLogData((prevItems: any) => [...prevItems, ...formatDatefield(response)]);

        let nexttoken = GetResponse.nextToken;

        // window.onscroll = async function (ev) {
        //     if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {
        if (nexttoken) {
            response = [...response, ...(await getcurrentvehicle(nexttoken))];
        }
        //     }
        // };

        return response;
        // AmplifyServices.getMasterLogdetails(formid).then((resp: any) => {
        //     console.log(resp);
        //     resp = resp.filter((item: any) => item._deleted == null || item._deleted == false);
        //     setLogData(formatDatefield(resp));
        // });
    };
    const formatDatefield = (data: any) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };
    const columns = [
        { field: 'vehicleid', headeralign: 'left', bodyalign: 'left', header: 'Vehicle', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'date', headeralign: 'left', bodyalign: 'left', header: 'Date', showtime: false, dataType: 'date', fieldtype: 'datefilter', filterOption: true, filterFieldOption: 'date' },
        { field: 'Mileage', headeralign: 'left', bodyalign: 'left', header: 'Mileage', fieldtype: 'text', filterOption: true, filterFieldOption: 'Mileage' },
        { field: 'Parking', headeralign: 'left', bodyalign: 'left', header: 'Parking', fieldtype: 'text', filterOption: true, filterFieldOption: 'Parking' },
        { field: 'Salik', headeralign: 'left', bodyalign: 'left', header: 'Salik', fieldtype: 'text', filterOption: true, filterFieldOption: 'Salik' },
        { field: 'Accidents', headeralign: 'left', bodyalign: 'left', header: 'Accidents', fieldtype: 'text', filterOption: true, filterFieldOption: 'Accidents' },
        { field: 'driver', headeralign: 'left', bodyalign: 'left', header: 'Driver', fieldtype: 'lookup', filterOption: true, filterFieldOption: 'driver' },
        { field: 'Fine', headeralign: 'left', bodyalign: 'left', header: 'Fine', fieldtype: 'text', filterOption: true, filterFieldOption: 'Fine' }

        // { field: 'status', header: 'Status', fieldtype: 'custom', filterOption: true, filterFieldOption: 'status', template: (r: any) => <>{contractStatusBodyTemplate(r)}</> }
    ];

    return (
        <>
            <div>
                <div className="grid">
                    <div className="col-12 md:col-12">
                        {props.mode != 'view' && (
                            <Button onClick={NavigateToHome} className="mr-2 mt-2 ml-3">
                                Add Logs
                            </Button>
                        )}

                        <div className="card">
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <h5>Logs</h5>
                                </div>
                                <div className="field col-12  md:col-12">
                                    <ListTemplate
                                        header="Manage Contracts"
                                        value={LogData}
                                        paginator={true}
                                        rows={100}
                                        dynamicColumns={columns}
                                        emptyMessage="No Logs found."
                                        responsiveLayout="scroll"
                                        className="p-datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Logs "
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[100, 200, 300]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                        tablefilter={Tablefilter}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Logs;
