import React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import PrimeReact from "primereact/api";
import HomePage from "../Others/HomePage";
import AppMenu from "../Others/Menu";
import AppFooter from "../Others/AppFooter";
import DashBoard from "../DashBoard/DashBoard";
import MaintenanceReport from "../Others/MaintenanceReport";
import Consumption from "../Others/ConsumptionReport";
import PermissionLevel from "../Others/PermissionLevel";
import TodoPage from "../Others/TodoPage";
import UserRoles from "../Others/UserRoles";
import { Route, Routes, useLocation } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import AmplifyServices from "../../Service/Service";
import "./index.scss";
import "./index.css";
import { classNames } from "primereact/utils";
import AppBreadcrumb from "../Others/AppBreadcrumb";
import VehicleForm from "../../Pages/Vehicles/Newform";
import WorkSpace from "../../Pages/WorkSpace/WorkSpace";
import CompanySettingsView from "../../Pages/CompanySettings/CompanySettingsView";
import DepartmentView from "../../Pages/DepartmentSettings/DepartmentView";
import AppointmentForm from "../../Pages/Appointments/AppointmentForm";
import ListTable from "../../Components/ListTable";
import Vendors from "../../Pages/Vendors/ManageVendors";
import Roles from "../../Pages/Roles/Roles";
import GroupDetails from "../../Pages/Roles/GroupDetails";
import Vehicles from "../../Pages/Vehicles/Managevehicles";
import ManageDrivers from "../../Pages/Drivers/ManageDrivers";
import Drivernewform from "../../Pages/Drivers/Newform";
import ManageAppointments from "../../Pages/Appointments/ManageAppointments";
import DepartmentNewform from "../../Pages/DepartmentSettings/DepartmentForm";
import CompanySettingsNewform from "../../Pages/CompanySettings/CompanySettingsForm";
import DepartmentEditform from "../../Pages/DepartmentSettings/DepartmentEditForm";
import CompanySettingsEditform from "../../Pages/CompanySettings/CompanySettingsEditForm";
import Vendornewform from "../../Pages/Vendors/Newform";
import Vendoreditform from "../../Pages/Vendors/Editform";
import Drivereditform from "../../Pages/Drivers/Editform";
import AppointmentNewForm from "../../Pages/Appointments/NewForm";
import VehicleEditForm from "../../Pages/Vehicles/Editform";
import ManageSpares from "../../Pages/Spares/ManageSpares";
import Sparesnewform from "../../Pages/Spares/Newform";
import Spareseditform from "../../Pages/Spares/Editform";
import RenewalForm from "../Renewals/NewRenewalForm";
import AppointmentEditForm from "../../Pages/Appointments/EditForm";
import AllocateDriverPopUp from "../WorkSpace/WSAllocation";
// import DeAllocateDriver from '../../Pages/WorkSpace/DeAllocateDriver';
import SContractnewform from "../../Pages/ServiceContracts/Newform";
import SContracteditform from "../../Pages/ServiceContracts/Editform";
// import MileageNewfrom from '../Logs/Newform';
import MileageForm from "../../Pages/WorkSpace/MileageForm";
import NewVehicle from "../../Pages/Vehicles/NewVehicle";
import { AuthContext } from "../../AuthContext";
import ImportLogs from "../../Pages/Import/Import";
import ManageServiceContract from "../ServiceContracts/ManageSContracts";
import ManageConfigs from "../GeneralConfig/ManageConfigs";
import Configsform from "../GeneralConfig/Form";
import { listDxNavigations } from "../../graphql/queries";
import NavForm from "../NavigationConfig/Form";
import ManageApprovals from "../Approvals/ManageApprovals";
import ApprovalForm from "../Approvals/ApprovalForm";
import ApproverForm from "../Approvals/ApproverForm";
import AttachWorkflow from "../Approvals/Attachworkflow";
import NewAppointment from "../Appointments/NewAppointment";
import ManageNavigation from "../NavigationConfig/ManageNavigation";
import ManageAllocations from "../Allocations/ManageAllocations";
import DocumentnewForm from "../Renewals/EditRenewalForm";
import TestPage from "../Others/TestPage";
import ManageLogs from "../Logs/ManageLogs";
import FinesForm from "../Logs/Fines/Newform";
import SalikForm from "../Logs/Salik/Newform";
import VIPForm from "../Logs/VIPFuel/Newform";
import Mileagenewform from "../Logs/Mileage/Newform";
import NotifyControl from "./NotifyControl";
import ManageWFConfig from "../Approvals/managewfconfig";
import NewWFConfig from "../Approvals/newwfconfig";
import WFAssociate from "../Approvals/wfassociate";
import WFApprove from "../Approvals/wfapprove";
import CloseRenewalform from "../WorkSpace/CloseRenewal";
import SContractviewform from "../ServiceContracts/Viewform";
import SettingsPage from "../Settings/SettingsPage";
import UploadPage from "../Others/UploadPage";
// import RenewalForm from '../Renewals/NewRenewalForm';
import ManageDocuments from "../Renewals/ManageRenewals";
import ViewAllocationAttachment from "../Vehicles/ViewAllocationFiles";
import ManageAWSUsers from "../Administration/Users/ManageAWSUsers";
import ManageAWSGroups from "../Administration/Users/ManageAWSGroups";
import dxService from "../../Services/dxService";
import Comingsoon from "../Others/Comingsoon";
import UserLink from "../Administration/Users/UserLink";
import PermissionLevels from "../Administration/Users/PermissionLevels";
import UserProfile from "../Administration/Users/UserProfile";
import UserRole from "../Administration/Users/UserRole";
import ManageApprovalSteps from "../Approvals/ManageApprovalSteps";
import AddExpense from "../Spares/AddExpense";
import EditAllocation from "../Allocations/EditAllocation";
import ManageGroups from "../Administration/Groups/ManageGroups";
import ManageUsers from "../Administration/Groups/ManageUsers";
import AddUser from "../Roles/AddUser";
import TempImport from "../TempImport/TempImport";
import ImportTempData from "../../TempImportdata/TempImportdata";
import moment from "moment";
import Logsviewform from "../Logs/ViewForm";
import PageForm from "./PageForm";
import VehicleView from "../DashBoard/VehicleView";
import DriverView from "../DashBoard/DriverView/DriverView";
import Usage from "../Reports/Usagre";
import DriverUsage from "../Reports/DriverUsage";
import Globaldocview from "../../utils/globaldocview";
import ImportServices from "../Import/ImportServices";
import AddAllocation from "../Allocations/ImportAllocation";
import ReportBuilders from "../Reports/ReportBuilder/ReportBuilder";
import Parkingform from "../Logs/Parking/Newform";
import MonthlyAllocation from "../WorkSpace/MonthlyAllocation";
import VehicleDetailsPage from "../Reports/VehicleComparison/VehicleComparison";

import { Amplify } from "aws-amplify";
import config from "../../aws-exports";
import ShiftImport from "../Import/ShiftImport";
import MileageReport from "../Reports/MileageReport";
import NewShiftImport from "../Import/NewShiftImport";
import NewMileageReport from "../Reports/NewMileageReport";
import MileageReports from "../Reports/MileageReport/MileageReports";
import MileageAPI from "../MileageAPI";

// import TestDatatable from './Pages/TestDatatable';
export const RTLContext = React.createContext(false);
// Amplify.configure(config);
const PageControl = (props: any) => {
  const { navStatus } = useContext(AuthContext);
  const [menuMode, setMenuMode] = useState("static");
  const [desktopMenuActive, setDesktopMenuActive] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [colorMode, setColorMode] = useState("light");
  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [inputStyle, setInputStyle] = useState("filled");
  const [isRTL, setRTL] = useState<boolean>(false);
  const [ripple, setRipple] = useState(true);
  const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
  const [menuTheme, setMenuTheme] = useState("deluxehomes");
  const [topbarTheme, setTopbarTheme] = useState("DeluxeHomes");
  const [theme, setTheme] = useState("indigo");
  const [newThemeLoaded, setNewThemeLoaded] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [dbMenu, setDBMenu] = useState<any>([]);

  const [user, setUser] = useState(null);

  const copyTooltipRef = useRef<any>();
  const location = useLocation();
  PrimeReact.ripple = true;

  let searchClick: boolean;
  let topbarItemClick: boolean;
  let menuClick: boolean;
  let inlineMenuClick: boolean;

  // useEffect(() => {

  //     // const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
  //     //     switch (event) {
  //     //         case 'signIn':
  //     //             setUser(data);
  //     //             break;
  //     //         case 'signOut':
  //     //             setUser(null);
  //     //             break;
  //     //         case 'customOAuthState':
  //     //             setCustomState(data);
  //     //     }
  //     // });

  //     Auth.currentAuthenticatedUser()
  //         .then(async (currentUser) => {

  //             setUser(currentUser);
  //             dispatch({ type: 'LOGIN', payload: currentUser });
  //         })
  //         .catch(() => {
  //             dispatch({ type: 'LOGOUT', payload: null });
  //         });

  //     Auth.currentUserInfo().then((res) => {
  //         if (res !== null)
  //         setname(res.attributes.preferred_username);
  //     });
  // }, [uname == '']);
  // const checkuser = async () => {
  //     const user = await Auth.currentAuthenticatedUser();
  // };

  const routes = [
    { parent: "", label: "" },
    { parent: "General", label: "Workspace" },
    { parent: "General", label: "Appointments" },
    { parent: "General", label: "Document" },
    // { parent: 'General', label: 'Expense Reimbursement' },
    // { parent: 'General', label: 'Purchase Request' },
    { parent: "Manage", label: "Vehicles" },
    { parent: "Manage", label: "Drivers" },
    { parent: "Manage", label: "Vendors" },
    { parent: "Manage", label: "Inspections" },
    { parent: "Manage", label: "Service Contracts" },
    { parent: "Manage", label: "Vehicle Spares" },
    { parent: "Analyse Reports", label: "Consumptions" },
    { parent: "Analyse Reports", label: "Maintenance" },
    { parent: "Analyse Reports", label: "Import" },
    { parent: "Analyse Reports", label: "Export" },
    { parent: "Mileage", label: "Export" },
    { parent: "Mileage", label: "Export" },

    // { parent: 'UI Kit', label: 'Input' },
    // { parent: 'UI Kit', label: 'Float Label' },
    // { parent: 'UI Kit', label: 'Invalid State' },
    // { parent: 'UI Kit', label: 'Button' },
    // { parent: 'UI Kit', label: 'Table' },
    // { parent: 'UI Kit', label: 'List' },
    // { parent: 'UI Kit', label: 'Panel' },
    // { parent: 'UI Kit', label: 'Tree' },
    // { parent: 'UI Kit', label: 'Overlay' },
    // { parent: 'UI Kit', label: 'Menu' },
    // { parent: 'UI Kit', label: 'Media' },
    // { parent: 'UI Kit', label: 'Message' },
    // { parent: 'UI Kit', label: 'File' },
    // { parent: 'UI Kit', label: 'Chart' },
    // { parent: 'UI Kit', label: 'Misc' },
    // { parent: 'PrimeBlocks', label: 'Blocks' },
    // { parent: 'Utilities', label: 'Icons' },
    // { parent: 'Manage', label: 'Vehicles' },
    // { parent: 'Manage', label: 'Drivers' },
  ];

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);
  useEffect(() => {
    getnavigationItems();
  }, [navStatus]);

  const getnavigationItems = async () => {
    try {
      const allItems: any[] = [];
      const res = await AmplifyServices.getNavDetails(
        listDxNavigations,
        "listDxNavigations",
        "Left"
      );

      const [a, drivers, vehicles] = await Promise.all([
        dxService.listitems("approval", "*", { status: { eq: "Not Started" } }),
        dxService.listitems("driver", "*", {
          enddate: { le: moment().add(30, "days").toISOString() },
        }),
        dxService.listitems("vehicle", "*", {
          enddate: { le: moment().add(30, "days").toISOString() },
        }),
      ]);
      let expireddriver = drivers.filter(
        (del: any) => !del._deleted && del.isarchived !== true
      );
      let expiredvehicle = vehicles.filter(
        (del: any) => !del._deleted && del.isarchived !== true
      );
      console.log(expiredvehicle);

      // console.log(expireddriver);

      await Promise.all(
        res
          .filter((del: any) => !del._deleted)
          .sort((a: any, b: any) => a.order - b.order)
          .map(async (item: any) => {
            const { label, icon, items } = JSON.parse(item.item);
            const { label: itemLabel, icon: itemIcon, to } = items;
            const badge =
              itemLabel === "Dashboard" && a.length !== 0
                ? a.length
                : itemLabel === "Drivers" && expireddriver.length !== 0
                ? expireddriver.length
                : itemLabel === "Vehicles" && expiredvehicle.length !== 0
                ? expiredvehicle.length
                : "";

            const newItem = {
              label,
              icon,
              items: [
                {
                  label: itemLabel,
                  icon: itemIcon,
                  to,
                  badge,
                  badgeClassName: items.badgeClassName,
                },
              ],
            };
            allItems.push(newItem);
          })
      );

      const groupedData = allItems.reduce((result, current) => {
        const existingGroup = result.find(
          (group: any) => group.label === current.label
        );

        if (existingGroup) {
          existingGroup.items.push(current.items[0]);
        } else {
          result.push({
            label: current.label,
            icon: current.icon,
            items: current.items,
          });
        }

        return result;
      }, []);

      setDBMenu(groupedData);
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  useEffect(() => {
    if (menuMode === "overlay") {
      hideOverlayMenu();
    }
    if (menuMode === "static") {
      setDesktopMenuActive(true);
    }
  }, [menuMode]);

  useEffect(() => {
    const appLogoLink = document.getElementById("app-logo") as HTMLInputElement;

    if (appLogoLink)
      if (
        topbarTheme === "white" ||
        topbarTheme === "yellow" ||
        topbarTheme === "amber" ||
        topbarTheme === "orange" ||
        topbarTheme === "lime"
      ) {
        appLogoLink.src = "assets/layout/images/logo-dark.svg";
      } else {
        appLogoLink.src = "assets/layout/images/logo-light.svg";
      }
  }, [topbarTheme]);

  const onMenuClick = (event: any) => {
    menuClick = true;
  };

  const onMenuButtonClick = (event: Event) => {
    menuClick = true;

    if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
    else setMobileMenuActive((prevState) => !prevState);

    event.preventDefault();
  };

  const onTopbarItemClick = (event: any) => {
    topbarItemClick = true;
    if (activeTopbarItem == event.item) setActiveTopbarItem(null);
    else {
      setActiveTopbarItem(event.item);
    }

    event.originalEvent.preventDefault();
  };

  const onSearch = (event: any) => {
    searchClick = true;
    setSearchActive(event);
  };

  const onMenuItemClick = (event: any) => {
    if (!event.item.items && (menuMode === "overlay" || !isDesktop())) {
      hideOverlayMenu();
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = (event: any) => {
    setMenuActive((prevState) => !prevState);
  };

  const onRightMenuButtonClick = () => {
    setRightMenuActive((prevState) => !prevState);
  };

  const onMobileTopbarButtonClick = (event: any) => {
    setMobileTopbarActive((prevState) => !prevState);
    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setMobileMenuActive(false);
    setDesktopMenuActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const layoutContainerClassName = classNames(
    "layout-wrapper ",
    "layout-menu-" + menuTheme + " layout-topbar-" + topbarTheme,
    {
      "layout-menu-static": menuMode === "static",
      "layout-menu-overlay": menuMode === "overlay",
      "layout-menu-slim": menuMode === "slim",
      "layout-menu-horizontal": menuMode === "horizontal",
      "layout-menu-active": desktopMenuActive,
      "layout-menu-mobile-active": mobileMenuActive,
      "layout-topbar-mobile-active": mobileTopbarActive,
      "layout-rightmenu-active": rightMenuActive,
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple,
      "layout-rtl": isRTL,
    }
  );

  return (
    <>
      <div className={layoutContainerClassName}>
        <HomePage
          user={user}
          horizontal={isHorizontal()}
          activeTopbarItem={activeTopbarItem}
          onMenuButtonClick={onMenuButtonClick}
          onTopbarItemClick={onTopbarItemClick}
          onRightMenuButtonClick={onRightMenuButtonClick}
          onMobileTopbarButtonClick={onMobileTopbarButtonClick}
          mobileTopbarActive={mobileTopbarActive}
          searchActive={searchActive}
          onSearch={onSearch}
        />
        <div className="menu-wrapper" onClick={onMenuClick}>
          <div className="layout-menu-container">
            {/* {(inlineMenuPosition === 'top' || inlineMenuPosition === 'both') && <AppInlineMenu menuKey="top" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />}
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} menuMode={menuMode} active={menuActive} />
                        {(inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both') && (
                            <AppInlineMenu menuKey="bottom" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />
                        )} */}
            <AppMenu
              model={dbMenu}
              onMenuItemClick={onMenuItemClick}
              onRootMenuItemClick={onRootMenuItemClick}
              menuMode={menuMode}
              active={menuActive}
            />
          </div>
        </div>
        <div className="layout-main">
          <AppBreadcrumb routes={routes} />

          <div className="layout-content">
            <Routes>
              <Route
                path="/dashboard"
                element={
                  <DashBoard
                    colorMode={colorMode}
                    location={location}
                    isNewThemeLoaded={newThemeLoaded}
                    onNewThemeChange={(e: any) => setNewThemeLoaded(e)}
                  />
                }
              />
              <Route path="/todo" element={<TodoPage />} />
              {/* <Route path="/TestDatatable" element={<TestDatatable/>} /> */}
              {/* <Route path="/fleet" element={<Fleet />} /> */}
              {/* <Route path="/warehousing" element={<Warehousing />} /> */}
              {/* <Route path="/fitout" element={<Fitout />} /> */}
              {/* <Route path="/procurement" element={<Procurement />} /> */}
              {/* <Route path="/finance" element={<Finance />} /> */}
              <Route
                path="/"
                element={
                  <DashBoard
                    colorMode={colorMode}
                    location={location}
                    isNewThemeLoaded={newThemeLoaded}
                    onNewThemeChange={(e: any) => setNewThemeLoaded(e)}
                  />
                }
              />
              <Route path="/general/workspace" element={<WorkSpace />} />
              <Route
                path="/general/workspace/renew"
                element={<RenewalForm />}
              />
              <Route
                path="/manage/reports/item/*"
                element={
                  <VehicleDetailsPage
                    element={
                      <DashBoard
                        colorMode={colorMode}
                        location={location}
                        isNewThemeLoaded={newThemeLoaded}
                        onNewThemeChange={(e: any) => setNewThemeLoaded(e)}
                      />
                    }
                  />
                }
              />
              <Route
                path="/analysereports/maintenance"
                element={<MaintenanceReport />}
              />
              <Route
                path="/general/workspace/createAppointmentform"
                element={<AppointmentForm />}
              />
              <Route
                path="/general/appointments/createAppointmentform"
                element={<AppointmentForm />}
              />
              <Route
                path="/general/appointments/createnewAppointmentform/add"
                element={<AppointmentNewForm />}
              />
              <Route
                path="/general/appointments/createnewAppointmentform/edit"
                element={<AppointmentEditForm />}
              />
              <Route
                path="/manage/appointments/item/*"
                element={<NewAppointment />}
              />
              <Route
                path="/admin/businessunit/all"
                element={<CompanySettingsView />}
              />
              <Route
                path="/admin/businessunit/item/*"
                element={<CompanySettingsNewform />}
              />
              <Route
                path="/admin/businessunit/edit"
                element={<CompanySettingsEditform />}
              />
              <Route
                path="/admin/departments/all"
                element={<DepartmentView />}
              />
              <Route path="/mileageapi" element={<MileageAPI />} />
              <Route
                path="/admin/departments/item/*"
                element={<DepartmentNewform />}
              />
              <Route
                path="/admin/departments/edit"
                element={<DepartmentEditform />}
              />
              <Route path="/Generictable" element={<ListTable />} />
              <Route
                path="/general/appointments/all"
                element={<ManageAppointments />}
              />
              <Route path="/manage/vendors/all" element={<Vendors />} />
              <Route
                path="/manage/vendors/item/*"
                element={<Vendornewform />}
              />
              <Route path="/manage/vendors/edit" element={<Vendoreditform />} />
              <Route path="/importshift" element={<ShiftImport />} />
              <Route path="/importmileage" element={<NewShiftImport />} />
              <Route path="/manage/drivers/all" element={<ManageDrivers />} />
              <Route
                path="/manage/drivers/item/*"
                element={<Drivernewform />}
              />
              <Route
                path="/mileagereport"
                element={<MileageReports colorMode={colorMode} />}
              />
              <Route path="/NewMileageReport" element={<NewMileageReport />} />
              <Route path="/MileageReport" element={<MileageReport />} />
              <Route path="/manage/drivers/edit" element={<Drivereditform />} />
              <Route path="/manage/spares/all" element={<ManageSpares />} />
              <Route path="/manage/spares/item/*" element={<Sparesnewform />} />
              <Route path="/manage/spares/edit" element={<Spareseditform />} />
              <Route path="/manage/vehicles/all" element={<Vehicles />} />
              <Route path="/manage/vehicles/item/*" element={<NewVehicle />} />
              <Route
                path="/manage/vehicles/allocationsAttachment/*"
                element={<ViewAllocationAttachment />}
              />
              <Route
                path="/manage/vehicles/submitVehicleform"
                element={<VehicleForm />}
              />
              <Route
                path="/manage/vehicles/submitVehicleform/edit"
                element={<VehicleEditForm />}
              />
              <Route
                path="/manage/Allocations/all"
                element={<ManageAllocations />}
              />
              <Route
                path="/manage/workspace/monthlyallocation"
                element={<MonthlyAllocation />}
              />
              <Route
                path="/manage/Allocations/items/*"
                element={<EditAllocation />}
              />
              <Route path="/general/document/*" element={<ManageDocuments />} />
              <Route
                path="/manage/servicecontracts"
                element={<ManageServiceContract />}
              />
              <Route
                path="/manage/servicecontracts/item/*"
                element={<SContractnewform />}
              />
              <Route
                path="/manage/servicecontracts/edit"
                element={<SContracteditform />}
              />
              <Route
                path="/workspace/allocatedriver"
                element={<AllocateDriverPopUp />}
              />
              <Route
                path="/workspace/deallocatedriver"
                element={<AllocateDriverPopUp />}
              />
              <Route
                path="/workspace/addallocation"
                element={<AddAllocation />}
              />
              <Route path="/workspace/Mileagelog" element={<MileageForm />} />
              <Route
                path="/manage/mileage/item/*"
                element={<Mileagenewform />}
              />
              <Route path="/manage/fines/item/*" element={<FinesForm />} />
              <Route path="/manage/salik/item/*" element={<SalikForm />} />
              <Route path="/manage/vipfuel/item/*" element={<VIPForm />} />
              <Route path="/manage/parking/item/*" element={<Parkingform />} />
              <Route path="/manage/logs/*" element={<ManageLogs />} />
              <Route path="/manage/logs/item/*" element={<Logsviewform />} />
              <Route path="/analysereports/import" element={<ImportLogs />} />
              <Route path="/UserRoles" element={<UserRoles />} />
              <Route path="/Roles" element={<Roles />} />
              {/* <Route path="/Roles/FleetAdmin" element={<FleetAdmin />} />
                                <Route path="/Roles/FleetMembers" element={<FleetMember />} /> */}
              <Route
                path="/manage/renewals/item/*"
                element={<DocumentnewForm />}
              />
              <Route
                path="/general/workspace/item/basic/*"
                element={<CloseRenewalform />}
              />
              <Route
                path="manage/documents/item/viewdocuments/*"
                element={<SContractviewform />}
              />
              <Route path="/Roles/:id" element={<GroupDetails />} />
              {/* <Route path="/manage/Documents/item/*" element={<DocumentnewForm />} /> */}
              <Route path="/Test" element={<TestPage />} />
              <Route
                path="/manage/Documents/item/*"
                element={<RenewalForm />}
              />
              <Route path="/manage/workflows" element={<ManageWFConfig />} />
              <Route
                path="/manage/workflows/item/*"
                element={<NewWFConfig />}
              />
              <Route path="/manage/workflows/add" element={<NewWFConfig />} />
              <Route
                path="/manage/workflows/associate"
                element={<WFAssociate />}
              />
              <Route path="/manage/approvals/approve" element={<WFApprove />} />
              <Route
                path="/manage/approvals/all"
                element={<ManageApprovals />}
              />
              <Route
                path="/manage/approval/steps"
                element={<ManageApprovalSteps />}
              />
              <Route path="/manage/approvals/form" element={<ApproverForm />} />
              <Route
                path="/manage/approvals/attach"
                element={<AttachWorkflow />}
              />
              <Route
                path="/manage/approvals/item/*"
                element={<ApprovalForm />}
              />
              <Route path="/comingsoon" element={<Comingsoon />} />
              /*** SETTINGS */
              <Route path="/admin/settings" element={<SettingsPage />} />
              <Route path="/admin/upload" element={<UploadPage />} />
              <Route path="/admin/users" element={<ManageAWSUsers />} />
              <Route path="/admin/groups" element={<ManageAWSGroups />} />
              <Route
                path="/admin/permissionlevels"
                element={<PermissionLevels />}
              />
              <Route path="/admin/permissions" element={<PermissionLevel />} />
              <Route path="/admin/userrole" element={<UserRole />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/admin/erpgroups" element={<ManageGroups />} />
              <Route path="/admin/erpusers" element={<ManageUsers />} />
              /*** General Configuration */
              <Route path="/admin/configs/all/*" element={<ManageConfigs />} />
              <Route path="/admin/configs/item/*" element={<Configsform />} />
              /*** Navigations */
              <Route
                path="/admin/navigation/all"
                element={<ManageNavigation />}
              />
              <Route path="/admin/navigation/item/*" element={<NavForm />} />
              <Route path="/admin/userlink" element={<UserLink />} />
              <Route path="/addexpense" element={<AddExpense />} />
              <Route path="/admin/user" element={<AddUser />} />
              <Route path="/tempimport" element={<TempImport />} />
              <Route path="/tempimportData" element={<ImportTempData />} />
              <Route path="/forms/*" element={<PageForm />} />
              <Route path="/dashboard/vehicle" element={<VehicleView />} />
              <Route path="/dashboard/driver" element={<DriverView />} />
              /*** Reports */
              <Route
                path="/analysereports/reportbuilder"
                element={<ReportBuilders />}
              />
              <Route
                path="/analysereports/consumptions"
                element={<Consumption />}
              />
              <Route path="/analysereports/usage/*" element={<Usage />} />
              <Route
                path="/analysereports/driverusage"
                element={<DriverUsage />}
              />
              <Route
                path="/general/viewdocument/*"
                element={<Globaldocview />}
              />
              <Route
                path="/analysereports/importservices"
                element={<ImportServices />}
              />
            </Routes>
          </div>
          <AppFooter colorMode={colorMode} />
          {/* <WorkflowControl /> */}
        </div>
        <NotifyControl />
      </div>
    </>
  );
};

export default PageControl;
