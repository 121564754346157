import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import FormTemplate from "../../../Components/FormTemplate";
import dxService from "../../../Services/dxService";
import { Auth, Storage } from 'aws-amplify';
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import GetUser from "../../../Components/GetUser";
import AWS from "aws-sdk";
import awsExports from "../../../aws-exports";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

const UserRole = () => {
    const toast = useRef(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [roles, setroles] = useState<any>([]);
    const [selectedrole, setselectedrole] = useState<any>({});
    const [allgroups, setallgroups] = useState<any>("");
    const [username, setusername] = useState("");
    const [selecteduser, setselecteduser] = useState<any>({});

    const [filteredItems, setFilteredItems] = useState(null);
    const location1 = useLocation();
    const urlParams = new URLSearchParams(location1.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : '';
    var formID = urlParams.has('id') ? urlParams.get('id') : '';

    useEffect(() => {
        getdata();


    }, []);

    const getdata = async () => {
        let items = await dxService.listitems("roles");
        // let a:any = [];
        // items.forEach((ele:any)=>{
        //     let b = {
        //         'label':ele.rolename,
        //         'value':ele.id
        //     }
        //     a.push(b);

        // })
        // setroles(a);
        setroles(items);
        console.log(items);
        // if (formID != '') {
        //     let item = await dxService.getitembyid("roles", formID);
        //     setlistFormData(item);
        //     console.log(item);
        // }else{

        // }
    }

    const formcolumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'displayname',
                title: 'Display Name',
                fieldtype: 'text',
                required: true,
                value: listFormData.displayname ? listFormData.displayname : '',
                hidden: false,
                disabled: formmode == 'close' ? true : false,
                //disabled: false,
                data: '',
                class: 'md:col-12'
            },
            {
                name: 'email',
                title: 'Email',
                fieldtype: 'text',
                required: true,
                value: listFormData.email ? listFormData.email : '',
                hidden: false,
                disabled: true,
                //disabled: false,
                data: '',
                class: 'md:col-6'
            },
            {
                name: 'picture',
                title: 'Avatar',
                fieldtype: 'upload',
                required: false,
                value: listFormData.picture ? listFormData.picture : '',
                hidden: false,
                disabled: formmode == 'close' ? true : false,
                //disabled: false,
                data: '',
                class: 'md:col-6'
            },
        ]

    };

    const changedata = (props: any) => {
        console.log(props);
    }
    const addData = async (props: any) => {
        console.log(props);
        let pictureurl = props.picture;

        if (props.picture && props.picture.change === 1) {
            let picturesfile = props.picture.file[0].file;
            pictureurl = props.picture.url;
            var StoreDocuments = await Storage.put('profiles/Pictures/' + formID + picturesfile.name, picturesfile).then((res: any) => {
                console.log('File uploaded');
                return res;
            });
            console.log(StoreDocuments);
        }

        let profilevalue = {
            id: formID,
            displayname: props.displayname,
            picture: pictureurl,
            _version: props._version
        }

        let profileupdate = await dxService.updateitem('userprofile', profilevalue);
        console.log(profileupdate);
    }

    const searchItems = (event: any) => {
        console.log(event);
        //in a real application, make a request to a remote url with the query and return filtered results, for demo purposes we filter at client side
        let query = event.query;
        let _filteredItems: any = [];

        for (let i = 0; i < roles.length; i++) {
            let item = roles[i];
            console.log(item);
            if (item.rolename.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredItems(_filteredItems);
    }

    const getpermissionlevels = async () => {
        let str = {
            or: [
                {

                    and: {
                        name:
                        {
                            eq: 'VehicleActivity'
                        },
                        mask: {
                            contains: '1111'
                        }
                    }
                },
                {
                    and: {
                        name:
                        {
                            eq: 'GeneralConfig'
                        },
                        mask: {
                            contains: '0100'
                        }
                    }
                }
            ]
        };
        let a = await dxService.listitems('permissionlevels', '*', str);
        return a;
    }

    const getrolepermissions = async (e: any) => {
        setselectedrole(e.value);
        console.log(e.value);
        if (e.value) {
            let jsonvalue = JSON.parse(e.value.jfield);

            let a = await getpermissionlevels();
            let keys = Object.keys(jsonvalue);
            let orvalue: any[] = [];
            keys.forEach((ele: any) => {
                console.log(ele);
                console.log(jsonvalue[ele]);
                let a = {
                    and: {
                        name:
                        {
                            eq: ele
                        },
                        mask: {
                            contains: jsonvalue[ele]
                        }
                    }
                };
                orvalue.push(a);
            });

            let str = orvalue.length > 0 ? {
                or: orvalue
            } : {};

            let b = await dxService.listitems('permissionlevels', '*', str);
            console.log(b);
            let _allgroups: any = [];
            b.forEach((g: any) => {
                let c = g.groups.split(";");
                c.forEach((_c: string) => {
                    _allgroups.push(_c);
                })

            });
            console.log(_allgroups);
            setallgroups({ groups: _allgroups });
        }
    }

    const getusergroups = async (userPoolId: any, username: string) => {


        let params1: any = {
            UserPoolId: userPoolId, /* required */
            Username: username, /* required */
        };

        Auth
            .currentCredentials()
            .then(user => {
                let AWSconfig = new AWS.Config({
                    apiVersion: '2016-04-18',
                    credentials: user,
                    region: awsExports.aws_cognito_region
                });

                const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);
                cognitoIdp.adminListGroupsForUser(params1, function (err, data) {
                    if (err) console.log(err, err.stack); // an error occurred
                    else console.log(data);           // successful response
                });


                cognitoIdp.adminAddUserToGroup()
            });




        // const params = {
        //     UserPoolId: userPoolId,
        //     Filter: `email = "${email}"`,
        // };

        // const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);

        // cognitoIdp.listUsers(params, function (err, userData) {
        //     if (err) {
        //         console.log('Error in getCognitoUsers: ', err);
        //     } else {
        //     console.log(userData);
        //     }
        //   });
        // return cognitoIdp.listUsers(params).promise();
    }

    const addusertogroups = async (userPoolId: any, username: string, groupname: string) => {

        let params1: any = {
            UserPoolId: userPoolId, /* required */
            Username: username, /* required */
        };

        Auth
            .currentCredentials()
            .then(async user => {
                let AWSconfig = new AWS.Config({
                    apiVersion: '2016-04-18',
                    credentials: user,
                    region: awsExports.aws_cognito_region
                });

                var params2 = {
                    GroupName: groupname, /* required */
                    UserPoolId: userPoolId, /* required */
                    Username: username /* required */
                };
                const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);
                return cognitoIdp.adminAddUserToGroup(params2, function (err, data) {
                    if (err) { console.log(err, err.stack); return err.message; } // an error occurred
                    else { console.log(data); return data; } // successful response
                });


            });

    }

    const addusergroup = async (AWSconfig: any, userPoolId: any, groupname: any, username: any) => {
        var params2 = {
            GroupName: groupname, /* required */
            UserPoolId: userPoolId, /* required */
            Username: username /* required */
        };
        const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);
        return cognitoIdp.adminAddUserToGroup(params2, function (err, data) {
            if (err) { console.log(err, err.stack); return err.message; } // an error occurred
            else { console.log(data); return data; } // successful response
        });
    }

    const removeusergroup = async (AWSconfig: any, userPoolId: any, groupname: any, username: any) => {
        var params2 = {
            GroupName: groupname, /* required */
            UserPoolId: userPoolId, /* required */
            Username: username /* required */
        };
        const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);
        return cognitoIdp.adminRemoveUserFromGroup(params2, function (err, data) {
            if (err) { console.log(err, err.stack); return err.message; } // an error occurred
            else { console.log(data); return data; } // successful response
        });
    }

    const adduserrole = async (userPoolId: any, username: string) => {
        let filter = {
            username: {
                eq: selecteduser.profiles.userid
            }
        };
        let getuserroles = await dxService.listitems('userrole', '*', filter,true);
        console.log(getuserroles);
        if (selectedrole != null) {
            let input = {
                username: selecteduser.profiles.userid,
                userrole: JSON.stringify({ id: selectedrole.id, rolename: selectedrole.rolename, }),
                usergroups: JSON.stringify(allgroups),
                dxUserProfileUserrolesId: selecteduser.profiles.userid,
                dxRolesUserrolesId: selectedrole.id
            }
            let adduser = await dxService.createitem('userrole', input);
            console.log(adduser);

            if (allgroups.groups.length > 0) {
                Auth
                    .currentCredentials()
                    .then(async user => {
                        let AWSconfig = new AWS.Config({
                            apiVersion: '2016-04-18',
                            credentials: user,
                            region: awsExports.aws_cognito_region
                        });
                        allgroups.groups.forEach((gr: any) => {
                            addusergroup(AWSconfig, userPoolId, gr,  selecteduser.profiles.userid).then((resp: any) => {
                                if (resp.response && resp.response.error) {
                                    console.log(resp.response.error);
                                }
                                console.log(resp.response);
                            }).catch((err) => {
                                console.log(err);
                            });
                        });
                    });
            }
        }
    }


    const removeuserrole = async (userPoolId: any, username: string) => {
        let filter = {
            username: {
                eq: username
            }
        }
        let getuserroles = await dxService.listitems('userrole', '*', filter);
        console.log(getuserroles);
        getuserroles.filter((ele: any) => {
            return JSON.parse(ele.userrole).rolename === selectedrole.rolename
        });
        console.log(getuserroles);
        if (getuserroles.length > 0) {
            let a = JSON.parse(getuserroles[0].usergroups);
            console.log(a);
            if (a.groups.length > 0) {

                Auth
                    .currentCredentials()
                    .then(async user => {
                        let AWSconfig = new AWS.Config({
                            apiVersion: '2016-04-18',
                            credentials: user,
                            region: awsExports.aws_cognito_region
                        });
                        allgroups.groups.forEach((gr: any) => {

                            removeusergroup(AWSconfig, userPoolId, gr, username).then((resp: any) => {
                                if (resp.response && resp.response.error) {
                                    console.log(resp.response.error);
                                }
                                console.log(resp.response);
                            }).catch((err) => {
                                console.log(err);
                            })
                        })

                    });
            }
        }

        // if (selectedrole != null) {
        //     let input = {
        //         username: username,
        //         userrole: JSON.stringify({ id: selectedrole.id, rolename: selectedrole.rolename, }),
        //         usergroups: JSON.stringify(allgroups)
        //     }
        //     // let adduser = await dxService.createitem('userrole', input);
        //     // console.log(adduser);

        //     // if (allgroups.groups.length > 0) {



        //     //     Auth
        //     //         .currentCredentials()
        //     //         .then(async user => {
        //     //             let AWSconfig = new AWS.Config({
        //     //                 apiVersion: '2016-04-18',
        //     //                 credentials: user,
        //     //                 region: awsExports.aws_cognito_region
        //     //             });
        //     //             allgroups.groups.forEach((gr: any) => {

        //     //                 addusergroup(AWSconfig, userPoolId, gr, username).then((resp: any) => {
        //     //                     if (resp.response && resp.response.error) {
        //     //                         console.log(resp.response.error);
        //     //                     }
        //     //                     console.log(resp.response);
        //     //                 }).catch((err) => {
        //     //                     console.log(err);
        //     //                 })
        //     //             })





        //     //         });

        //     // }

        // }

    }


    return (

        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Add Members</h4>

                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}
                            {/* <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                <div className="surface-ground"> */}
                            <div className="grid">
                                <div className="col-12 md:col-12">
                                    <div className="card container-card">

                                        <div className={`field col-12 md-col-6`}>
                                            <label htmlFor='' className='col-12 mb-2 md:col-2 md:mb-0'>
                                                Group
                                            </label>
                                            <div className='col-12 md:col-10'>
                                                <Dropdown value={selectedrole} onChange={(e) => getrolepermissions(e)} options={roles} optionLabel="rolename"
                                                    editable placeholder="Select a Group" className="w-full md:w-14rem" />
                                            </div>
                                        </div>

                                        <div className={`field col-12 md-col-6`}>
                                            <label htmlFor='' className='col-12 mb-2 md:col-2 md:mb-0'>
                                                User
                                            </label>
                                            <div className='col-12 md:col-10'>
                                                <GetUser value={selecteduser} onChange={((e: any) => { console.log(e); setselecteduser(e) })} />

                                                {/* <GetUser
                    value={dvalue}
                    onChange={(e: any) => handleChange(e, type, fieldName)}
                    disabled={props.mode != 'view' ? disabled : true}
                    hidden={hidden}
                    required={required}
                    className={required == true && (dvalue == '' || dvalue == null) ? 'p-invalid' : ''}
                /> */}

                                                {/* <InputText id="txtUserName" onChange={(e) => { setusername(e.target.value) }} /> */}
                                            </div>
                                        </div>

                                        <div className={`field col-12 md-col-6`}>
                                            <Button label="Add User" onClick={(e: any) => {
                                                adduserrole(awsExports.aws_user_pools_id, username);

                                                // getusergroups(awsExports.aws_user_pools_id, username)
                                            }} />

                                            <Button label="Remove User" onClick={(e: any) => {
                                                removeuserrole(awsExports.aws_user_pools_id, username);
                                            }} />
                                        </div>






                                    </div>
                                </div>


                            </div>
                            {/* </div></div> */}
                            {/* END Inner COMPONENT HTML */}

                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}




            </div>
        </div>

    );
}
export default UserRole;