import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import FormTemplate from '../../Components/FormTemplate';
import Loader from '../../Components/Loader';
import dxService from '../../Services/dxService';
import awsExports from '../../aws-exports';
import { Storage } from 'aws-amplify';
import { getNextQueryData, isotolocaledatetime, localetoisodatetime } from '../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
const EditAllocation = () => {
    const location = useLocation();
    const toast = useRef<any>(null);
    const navigate = useNavigate();
    const [listFormData, setlistFormData] = useState<any>({});
    const [updatedata, setupdatedata] = useState<any>([]);
    const [getvactivity, setvactivity] = useState<any>([]);
    const [displayDialog, setdisplayDialog] = useState(false);
    const [DrivernameOptions, setDrivernameOptions] = useState<any>([]);
    const urlParams = new URLSearchParams(location.search);
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    var type = urlParams.has('type') ? urlParams.get('type') : null;
    var vid = urlParams.has('vid') ? urlParams.get('vid') : null;
    useEffect(() => {
        getDriverdetailsData();
        getallocationdetails();
        getvehicleactivity();
    }, []);
    const changevehicledata = (props: any) => {
        setupdatedata(props);
        // console.log(props);
    };
    const getallocationdetails = async () => {
        let filter = {
            id: { eq: formid }
        };
        await getNextQueryData('', filter, 'allocation', true).then((alloc: any) => {
            // console.log(alloc);
            setlistFormData({
                allocid: alloc[0].id,
                id: alloc[0].vehicleid,
                dxDriverVehicleactivityId: alloc[0].fromdriverID,
                FromDriverDate: isotolocaledatetime(alloc[0].fromdate),
                FromKmMileage: alloc[0].fromKM,
                FromLocation: alloc[0].fromlocation,
                HandoverDriverDropdown: alloc[0].handoverdrivername,
                HandoverDriverDate: isotolocaledatetime(alloc[0].handoverdate),
                HandoverKmMileage: alloc[0].handoverKM,
                HandoverLocation: alloc[0].handoverlocation,
                reason: alloc[0].reason,
                _version: alloc[0]._version
            });
        });
    };
    const getvehicleactivity = async () => {
        let filter = {
            id: { eq: vid }
        };
        await getNextQueryData('', filter, 'vehicleactivity', true).then((vact: any) => {
            setvactivity(vact[0]);
        });
    };
    const getDriverdetailsData = async () => {
        await getNextQueryData('', {}, 'driver', true).then((driver: any) => {
            // console.log(driver);

            setDrivernameOptions(driver);
        });
    };
    const DriverNameOption: any = [];
    const HandOverOption: any = [];
    const drivernames = DrivernameOptions.filter((del: any) => del._deleted !== true && del.status !== 'InActive' && del.name !== null);

    // console.log(drivernames);

    drivernames.forEach((ele: any) => {
        let a: any = {};
        a['label'] = ele.name;
        a['value'] = ele.id;

        DriverNameOption.push(a);

        let b: any = {};
        b['label'] = ele.name;
        b['value'] = ele.name;
        HandOverOption.push(b);
    });

    const Allocationcolumns = {
        columns: [
            {
                name: 'id',
                value: '',
                title: 'Vehicle Id',
                fieldtype: 'text',
                required: false,
                disabled: true,
                hidden: false,
                data: []
            },
            {
                title: 'Current info',
                name: 'basicinfo',
                fieldtype: 'header',
                hidden: false
            },
            {
                name: 'dxDriverVehicleactivityId',
                value: '',
                title: 'Current Driver',
                fieldtype: 'dropdown',
                required: false,
                // disabled: checkfromdriver.dxDriverVehicleactivityId == null ? false : true,
                disabled: false,
                hidden: false,
                data: DriverNameOption
            },
            {
                name: 'FromDriverDate',
                value: '',
                title: 'Current Date',
                fieldtype: 'datetime',
                required: false,
                disabled: false,
                hidden: false,
                showcurrentdate: true,
                data: []
            },
            {
                name: 'FromKmMileage',
                value: '',
                title: 'Current KM Mileage',
                fieldtype: 'number',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'FromLocation',
                value: '',
                title: 'Current Location',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },

            {
                title: 'Handover info',
                name: 'basicinfo',
                fieldtype: 'header',
                hidden: listFormData.HandoverDriverDropdown == null && listFormData.HandoverDriverDate == null ? true : false
            },
            {
                name: 'HandoverDriverDropdown',

                value: '',
                title: 'Handover Driver',
                fieldtype: 'dropdown',
                // required: checkfromdriver.dxDriverVehicleactivityId == null ? true : false,
                required: false,
                disabled: false,

                hidden: listFormData.HandoverDriverDropdown == null && listFormData.HandoverDriverDate == null ? true : false,
                data: HandOverOption
            },

            {
                name: 'HandoverDriverDate',
                value: '',
                title: 'Handover Date',
                fieldtype: 'datetime',
                // required: checkfromdriver.dxDriverVehicleactivityId == null ? true : false,
                required: false,
                disabled:
                    // checkfromdriver.dxDriverVehicleactivityId == null ? true :
                    false,
                // showcurrentdate: false,
                hidden: listFormData.HandoverDriverDropdown == null && listFormData.HandoverDriverDate == null ? true : false,
                data: []
            },

            {
                name: 'HandoverKmMileage',
                value: '',
                title: 'Handover KM Mileage',
                fieldtype: 'number',
                required: false,
                disabled: false,
                hidden: listFormData.HandoverDriverDropdown == null && listFormData.HandoverDriverDate == null ? true : false,
                data: []
            },

            {
                name: 'HandoverLocation',
                value: '',
                title: 'Handover Location',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: listFormData.HandoverDriverDropdown == null && listFormData.HandoverDriverDate == null ? true : false,
                data: []
            },
            {
                name: 'reason',
                value: '',
                title: 'Reason',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: listFormData.HandoverDriverDropdown == null && listFormData.HandoverDriverDate == null ? true : false,
                data: []
            },
            {
                title: 'Uploads',
                name: 'basicinfo',
                fieldtype: 'header'
            },
            {
                name: 'documents',
                value: '',
                title: 'Upload documents',
                fieldtype: 'fileupload',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'pictures',

                value: '',
                title: 'Upload pictures',
                fieldtype: 'fileupload',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'VehicleStatus',
                value: '',
                title: 'VehicleStatus',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'expanses',
                value: '',
                title: 'expanses',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },

            {
                name: 'allocationdriver',
                value: '',
                title: 'allocationdriver',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'capacity',
                value: '',

                title: 'capacity',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'events',
                value: '',
                title: 'events',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'expiring',
                value: '',
                title: 'expiring',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'make',
                value: '',
                title: 'make',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'model',
                value: '',
                title: 'model',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'platecode',
                value: '',
                title: 'platecode',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'plateno',
                value: 'plateno',
                title: 'plateno',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'policyno',
                value: '',
                title: 'policyno',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'status',
                value: '',
                title: 'status',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'year',
                value: '',
                title: 'year',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'departmentname',
                value: '',
                title: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'dxVehicleVehicleactivityId',
                value: '',
                title: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'dxAllocationsActivityId',
                value: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'dxAppointmentsActivityId',
                value: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'dxContractActivityId',
                value: 'departmentname',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            }
        ],
        actions: [
            {
                title: 'Submit'
            },
            {
                title: 'Cancel'
            }
        ]
    };

    const addDocToDB = async (files: any) => {
        // console.log('adding document', files);
        let createDocument = await dxService.createitem('document', files, false);
    };

    const onSaveAllocateDriver = async (data: any) => {
        console.log(data, getvactivity);
        let updateAllocation: any = {};
        var UploadDocuments: any = [];
        let doumentObj: any = {};
        var Isdocument = Array.isArray(data.documents);
        var Ispicture = Array.isArray(data.pictures);
        setdisplayDialog(true);
        let updatevehicleactivity: any = {};
        let collectfromdriverdetails: any = DrivernameOptions.filter((item: any) => item.id == data.dxDriverVehicleactivityId);
        console.log(collectfromdriverdetails);
        if (data.documents && data.documents.length >= 0) {
            for (let i = 0; i < data.documents.length; i++) {
                //getting the document upload names and creating the own object
                doumentObj = new Object({ file: data.documents[i], type: 'documents', path: `allocations/${new Date().getFullYear()}/${data.id}/documents/` });

                //pushing an object in an UploadDocuments array
                UploadDocuments.push(doumentObj);
            }
        }
        if (data.pictures && data.pictures.length > 0) {
            for (let i = 0; i < data.pictures.length; i++) {
                //getting the document upload names and creating the own object
                doumentObj = new Object({ file: data.pictures[i], type: 'picture', path: `allocations/${new Date().getFullYear()}/${data.id}/picture/` });

                //pushing an object in an UploadDocuments array
                UploadDocuments.push(doumentObj);
            }
        }
        if (data.HandoverDriverDate !== null && data.HandoverDriverDropdown !== null) {
            updateAllocation = {
                vehicleid: data.id,
                fromdriverID: collectfromdriverdetails[0].id,
                fromdrivername: collectfromdriverdetails[0].name,
                fromKM: data.FromKmMileage,
                fromdate: data.FromDriverDate !== null ? localetoisodatetime(new Date(data.FromDriverDate)) : null,
                fromlocation: data.FromLocation,
                dxVehicleActivityAllocationsId: data.id,
                //  dxDriverAllocationsId: collectfromdriverdetails[0].id,
                handoverKM: data.HandoverKmMileage,
                handoverdate: data.HandoverDriverDate !== null ? localetoisodatetime(new Date(data.HandoverDriverDate)) : null,
                handoverdrivername: data.HandoverDriverDropdown,
                handoverlocation: data.HandoverLocation,
                reason: data.reason,
                id: data.allocid,
                _version: data._version
            };
            updatevehicleactivity = {
                id: data.id,
                //dxAllocationsActivityId: id,
                //   dxDriverVehicleactivityId: collectfromdriverdetails[0].id,
                _version: getvactivity._version
            };
        } else {
            updateAllocation = {
                vehicleid: data.id,
                fromdriverID: collectfromdriverdetails[0].id,
                fromdrivername: collectfromdriverdetails[0].name,
                fromKM: data.FromKmMileage,
                fromdate: data.FromDriverDate !== null ? localetoisodatetime(new Date(data.FromDriverDate)) : null,
                fromlocation: data.FromLocation,
                dxVehicleActivityAllocationsId: data.id,
                dxDriverAllocationsId: collectfromdriverdetails[0].id,
                handoverKM: data.HandoverKmMileage,
                handoverdate: data.HandoverDriverDate !== null ? localetoisodatetime(new Date(data.HandoverDriverDate)) : null,
                handoverdrivername: data.HandoverDriverDropdown,
                handoverlocation: data.HandoverLocation,
                reason: data.reason,
                id: data.allocid,
                _version: data._version
            };
            updatevehicleactivity = {
                id: data.id,
                //dxAllocationsActivityId: id,
                dxDriverVehicleactivityId: collectfromdriverdetails[0].id,
                _version: getvactivity._version
            };
        }

        console.log(updateAllocation);
        let allocation = await dxService.updateitem('allocation', updateAllocation, false).then((res: any) => {
            UploadDocuments.map(async (item: any) => {
                await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                    const files = {
                        name: res.key,

                        s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                        resource: 'Allocation',
                        resourceid: data.allocid,
                        presourceid: vid,
                        presource: 'Vehicle',
                        field1: res.key.includes(`${vid}/picture`) ? 'pictures' : res.key.includes(`${vid}/documents`) ? 'documents' : ''
                    };
                    addDocToDB(files);
                });
            });
        });

        console.log(updatevehicleactivity);
        let os = await dxService.updateitem('vehicleactivity', updatevehicleactivity);
        setTimeout(() => {
            window.history.back();
        }, 1000);
    };
    return (
        <>
            {' '}
            <div>
                {' '}
                <>
                    <Toast ref={toast} />
                    <div className="col-12 md:col-12">
                        <h3>Edit Allocation</h3>
                        <FormTemplate
                            id="newallocation"
                            showaction={true}
                            data={listFormData}
                            onChange={changevehicledata}
                            getTableName={'listDxVehicleActivities'}
                            columns={Allocationcolumns}
                            layout="grid2"
                            title="Allocate Driver"
                            submitForm={onSaveAllocateDriver}
                        />
                        <Loader displayDialog={displayDialog} />
                    </div>
                </>
            </div>
        </>
    );
};
export default EditAllocation;
