import { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ListTable from '../../Components/ListTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import dxService from '../../Services/dxService';
import AmplifyServices from '../../Service/Service';
import { listDxGeneralConfigs, listDxTableSettings } from '../../graphql/queries';

import { Dropdown } from 'primereact/dropdown';

const ManageConfigs = () => {
    const navigate = useNavigate();
    const menu = useRef<any>(null);
    const [configItems, setConfigItems] = useState<any>([]);
    const [tableItems, setTableItems] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [globalFilter, setGlobalFilter] = useState<any>('');
    const [selectedView, setSelectedView] = useState<any>('generalconfigs');
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    var view: any = urlParams.has('view') ? urlParams.get('view') : '';
    const Views = [
        { label: 'General Configs', value: 'generalconfigs' },
        { label: 'Table Configs', value: 'tableconfigs' }
    ];
    useEffect(() => {
        getData();
    }, []);
    console.log(selectedView);
    const getData = async () => {
        // if (selectedView == 'General Configs') {
        await AmplifyServices.getTableDetails(listDxGeneralConfigs, 'listDxGeneralConfigs').then(async (res: any) => {
            setConfigItems(res);
        });

        await AmplifyServices.getTableDetails(listDxTableSettings, 'listDxTableSettings').then(async (res: any) => {
            setTableItems(res);
        });
    };
    const columns = [
        { field: 'name', header: 'Name', fieldtype: 'text', textalign: 'left', filterOption: true, filterFieldOption: 'name' },
        { field: 'fleettype', header: 'Type', fieldtype: 'text', textalign: 'left', filterOption: true, filterFieldOption: 'fleettype' },
        { field: 'isactive', header: 'Is Active', fieldtype: 'boolean', textalign: 'left', filterOption: true, filterFieldOption: 'isactive' }
    ];
    const tablecolumns = [
        { field: 'column', header: 'Name', fieldtype: 'text', textalign: 'left', filterOption: true, filterFieldOption: 'name' },
        { field: 'field1', header: 'Value', fieldtype: 'text', textalign: 'left', filterOption: true, filterFieldOption: 'name' },
        { field: 'table', header: 'Type', fieldtype: 'text', textalign: 'left', filterOption: true, filterFieldOption: 'name' }
    ];
    const deleteItems = async () => {
        await dxService.deleteitem('generalconfiguration', selectedItems[0].id, selectedItems[0]._version).then(() => {
            getData();
            setSelectedItems([]);
            selectedItems.length = 0;
        });
    };

    let params = selectedItems.length > 0 && `&id=${selectedItems[0].id}&fleettype=${selectedItems[0].fleettype}`;
    console.log(selectedItems, view);

    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                {' '}
                <Button icon="pi pi-plus" label="New" onClick={() => navigate('/admin/configs/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />
                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />
                <Menu
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedItems.length == 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                //  navigate(`/manage/vehicles/item/basic?mode=edit&id=${SelectedItems != undefined && EditNavigation[0].id}&fleettype=vehicle`);/tablesettings?id=168871245616265&mode=tableedit
                                selectedView === 'generalconfigs' ? navigate('/admin/configs/item/basic?mode=edit' + params) : navigate('/admin/configs/item/tablesettings/?mode=tableedit' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                deleteItems();
                            }
                        }
                    ]}
                ></Menu>
            </div>

            <div className="grid grid-nogutter ">
                {' '}
                <div className="col-12 p-fluid" style={{ textAlign: 'left' }}>
                    {' '}
                    <div className="formgrid grid" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown
                                    id="ddsearchstatus"
                                    value={selectedView}
                                    options={Views}
                                    optionLabel="label"
                                    placeholder="Filter by view"
                                    onChange={(e) => {
                                        updateURL(e.value);
                                        setSelectedView(e.value);
                                    }}
                                />
                                <label htmlFor="ddsearchstatus">Filter Views</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <input value={globalFilter} type="text" id="txtsearchvehicleid" onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search ..." className="p-inputtext p-component homeNews__search pb-0" />
                                <label htmlFor="txtsearchvehicleid">Search</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    let filteredSearch =
        configItems &&
        configItems
            .filter((del: any) => del._deleted !== true)
            .filter((name: any) => {
                if (name.name.toLowerCase().includes(globalFilter.toLowerCase().toLowerCase()) || name.isactive.toString().toLowerCase().includes(globalFilter.toLowerCase()) || name.fleettype.toLowerCase().includes(globalFilter.toLowerCase())) {
                    return name;
                }
            });
    let TableConfigSearch =
        tableItems &&
        tableItems
            .filter((del: any) => del._deleted !== true)
            .filter((name: any) => {
                if (name.column.toLowerCase().includes(globalFilter.toLowerCase().toLowerCase()) || name.field1.toLowerCase().includes(globalFilter.toLowerCase()) || name.table.toLowerCase().includes(globalFilter.toLowerCase())) {
                    return name;
                }
            });
    //tableItems
    //set the values to the url
    const updateURL = (view: any) => {
        console.log(view);

        const baseUrl = window.location.href.split('?')[0];
        const queryParams = new URLSearchParams(window.location.search);

        if (view) {
            queryParams.set('view', view);
        } else {
            queryParams.delete('view');
        }

        const newUrl = baseUrl + '?' + queryParams.toString();
        window.history.replaceState({}, '', newUrl);
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Configs</h4>
                <div className="datatable-editing-demo">
                    {/* <NewForm disableAllField={false} parent="Workspace" columns={AddForm} fleettype="drivers" title={formTitle} onSubmit={onSubmit} /> */}
                    {selectedView == 'generalconfigs' ? (
                        <ListTable
                            header="Manage Configs"
                            value={filteredSearch}
                            paginator={true}
                            rows={100}
                            dynamicColumns={columns}
                            emptyMessage="No items found."
                            responsiveLayout="scroll"
                            className="datatable-responsive"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[100, 200, 300]}
                            //   selection={selectedProducts}
                            multiselect={true}
                            //   selectedItems={selectedProducts}
                            Commandbar={Commandbar}
                            //   onSelectionChange={e => setSelectedProducts(e.value)}Wd
                            selectedItems={setSelectedItems}
                        />
                    ) : (
                        <ListTable
                            header="Manage Configs"
                            value={TableConfigSearch}
                            paginator={true}
                            rows={100}
                            dynamicColumns={tablecolumns}
                            emptyMessage="No items found."
                            responsiveLayout="scroll"
                            className="datatable-responsive"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[100, 200, 300]}
                            //   selection={selectedProducts}
                            multiselect={true}
                            //   selectedItems={selectedProducts}
                            Commandbar={Commandbar}
                            //   onSelectionChange={e => setSelectedProducts(e.value)}Wd
                            selectedItems={setSelectedItems}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ManageConfigs;
