import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { transform } from "typescript";
import FormTemplate from "../../Components/FormTemplate";
import ViewTemplate from "../../Components/ViewTemplate";

const ApproverForm = () => {
    const toast = useRef<any>(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;

    const formcolumns = {
        columns: [
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'name',
                title: 'Name',
                fieldtype: 'text',
                required: true,
                value: 'aaa',
                hidden: false,
                disabled: true,
                data: [],
                class: 'md:col-6'

            },
            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },

            {
                name: 'description',
                title: 'Description',
                fieldtype: 'textarea',
                required: false,
                value: 'test description',
                hidden: false,
                disabled: true,
                data: [],
                class: 'md:col-12'
            }, {
                name: 'comments',
                title: 'Comments',
                fieldtype: 'textarea',
                required: false,
                value: 'test description',
                hidden: false,
                disabled: false,
                data: [],
                class: 'md:col-12'
            }
        ],
        actions: [
            {
                title: 'Approve'
            },
            {
                title: 'Reject'
            }
        ]
    }

    const viewdata = [
        {
            platenumber: 'a'
        },
        {
            platenumber: 'b'
        },
        {
            platenumber: 'c'
        }
    ];

    const viewcolumns = {
        columns: [
            {
                name: 'platenumber',
                title: 'Plate Number',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'model',
                title: 'MOdel Name',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'requestor',
                title: 'Requestor Name',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'priority',
                title: 'Priority',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'startdate',
                title: 'Pending for',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'approvaltype',
                title: 'Approval Type',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'description',
                title: 'Description',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'price',
                title: 'Price',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
        ]
    }

    useEffect(() => {

    }, [])

    const changedata = (props: any) => {

        console.log(props);
    };

    const addData = (props: any) => {
        console.log(props);

    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Approver Form</h4>

                <div className="card ">

                    <div className="surface-section">


                        <ul className="list-none p-0 m-0 flex flex-column md:flex-row">
                            <li className="relative mr-0 md:mr-8 flex-auto">
                                <div className="border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1">
                                <i className="pi pi-check-circle text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                                    <div>
                                        <div className="text-900 font-medium mb-1">Test 1</div>
                                        <span className="text-600 text-sm hidden md:block">Tempus iaculis urna id volutpat</span>
                                    </div>
                                    <div className="w-full absolute top-50 left-100 surface-300 hidden md:block" style={{ transform: 'translateY(-50%)', height: '2px' }} ></div>
                                </div>

                            </li>
                            <li className="relative mr-0 md:mr-8 flex-auto">
                                <div className="border-2 border-blue-500 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1">
                                    <div>
                                        <div className="text-900 font-medium mb-1">Test 2</div>
                                        <span className="text-600 text-sm hidden md:block">Tempus iaculis urna id volutpat</span>
                                    </div>
                                    <div className="w-full absolute top-50 left-100 surface-300 hidden md:block" style={{ transform: 'translateY(-50%)', height: '2px' }} ></div>
                                </div>

                            </li>
                            <li className="relative  flex-auto">
                                <div className="border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1">
                                    <div>
                                        <div className="text-900 font-medium mb-1">Test 3</div>
                                        <span className="text-600 text-sm hidden md:block">Tempus iaculis urna id volutpat</span>
                                    </div>

                                    {/* style="transform: translateY(-50%); height: 2px;"> */}



                                </div>

                            </li>
                        </ul>
                    </div></div>

                {/* <ul className="list-none p-0 m-0 flex flex-column md:flex-row">
                    <li className="relative mr-0 md:mr-8 flex-auto">
                        <div className="border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1">
                            <i className="pi pi-check-circle text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                            <div>
                                <div className="text-900 font-medium mb-1">Applied</div>
                                <span className="text-600 text-sm hidden md:block">Tempus iaculis urna id volutpat</span>
                            </div>
                        </div><div className="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px;"></div></li>
                        <li className="relative mr-0 md:mr-8 flex-auto">
                            <div className="border-2 border-blue-500 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1">
                                <i className="pi pi-users text-blue-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i><div><div className="text-blue-600 font-medium mb-1">Interview</div><span className="text-600 text-sm hidden md:block">Nisi vitae suscipit tellus</span></div></div><div className="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px;"></div></li>
                                <li className="relative flex-auto"><div className="border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1"><i className="pi pi-check-circle text-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i><div><div className="text-900 font-medium mb-1">Confirmation</div><span className="text-600 text-sm hidden md:block">Sit amet est placerat in egestas erat</span></div></div>
                        </li>
                        </ul> */}
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card container-card">
                            <Toast ref={toast} />


                            {/* <FormTemplate
                                            columns={formcolumns}
                                            data={listFormData}
                                            showaction={true}
                                            mode={formmode}
                                            onChange={changedata}
                                            ChangeBtn={'OpenDocument'}
                                            layout="grid2"
                                            title="New Approval"
                                            submitForm={addData}
                                        />
                                   */}
                            <ViewTemplate title="Replacement Approval" description="approval required for the item" columns={viewcolumns} />

                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="card container-card">
                            <Toast ref={toast} />


                            <FormTemplate
                                columns={formcolumns}
                                data={listFormData}
                                showaction={true}
                                mode={formmode}
                                onChange={changedata}
                                ChangeBtn={'OpenDocument'}
                                layout="grid2"
                                title="New Approval"
                                submitForm={addData}
                            />


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApproverForm;