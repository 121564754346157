import { API } from 'aws-amplify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AmplifyServices from '../../Service/Service';
import { createDxVendors, updateDxVendors } from '../../graphql/mutations';
import { listDxGeneralConfigs, listDxVendors } from '../../graphql/queries';
import { GraphQLSubscription } from '@aws-amplify/api';
import FormTemplate from '../../Components/FormTemplate';
import { OnCreateDxAppointmentsSubscription } from '../../API';
import { onCreateDxVendors } from '../../graphql/subscriptions';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import dxService from '../../Services/dxService';
import { isotolocaledatetime } from '../../utils/helper';
const Vendornewform = () => {
    const toast = useRef<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [VendorsOptions, setVendorsOptions] = useState<any>([]);
    const [listFormData, setlistFormData] = useState<any>({});
    const location1 = useLocation();
    const urlParams1 = new URLSearchParams(location1.search);
    var formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
    var formid = urlParams1.has('id') ? urlParams1.get('id') : null;

    const navigate = useNavigate();

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
        formid = urlParams1.has('id') ? urlParams1.get('id') : null;
        const paths = location1.pathname.split('/');
        const currentPath = paths[paths.length - 1];
        console.log(currentPath);

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'allocations':
                setActiveIndex(2);
                break;
            // case 'logs':
            //     setActiveIndex(3);
            //     break;
            // case 'Allocations':
            //     setActiveIndex(4);
            //     break;
            default:
                if (formid) getvendordata(formid);
                break;
        }
    }, [location1]);
    useEffect(() => {
        checkActiveIndex();
        getData();
    }, [checkActiveIndex]);
    const getvendordata = async (fid: any) => {
        console.log('getvendordata');
        await AmplifyServices.getFilterbyIddetails(listDxVendors, 'listDxVendors', fid).then((a) => {
            console.log(a);
            if (a.length > 0) {
                setlistFormData({
                    company: a[0].company,
                    contactno: a[0].contactno,
                    email: a[0].email,
                    garagelocation: a[0].garagelocation,
                    officelocation: a[0].officelocation,
                    vendorname: a[0].vendorname,
                    vendortype: a[0].vendortype,
                    createdAt: isotolocaledatetime(a[0].createdAt),
                    updatedAt: isotolocaledatetime(a[0].updatedAt)
                });
            }
        });
    };

    const getData = async () => {
        let filter = {
            fleettype: {
                eq: 'Vendors'
            }
        };
        let GetResponse: any = await API.graphql({ query: listDxGeneralConfigs, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: filter } });
        console.log(GetResponse.data.listDxGeneralConfigs.items);
        setVendorsOptions(GetResponse.data.listDxGeneralConfigs.items);
    };

    const VendorsNameOption: any = [];
    const names = VendorsOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        // console.log(res);
        VendorsNameOption.push(res);
    });
    useEffect(() => {
        let cols = formColumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });
        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);
    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'company', // pass the exact column name of db
                title: 'Company',
                fieldtype: 'text',
                value: '',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'contactno', // pass the exact column name of db
                title: 'Mobile No.', //for display
                value: '',
                fieldtype: 'number',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'email', // pass the exact column name of db
                title: 'Email', //for display
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'garagelocation', // pass the exact column name of db
                title: 'Garage Location',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'officelocation', // pass the exact column name of db
                title: 'Office Location',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'vendorname', // pass the exact column name of db
                title: 'Vendor Name',
                value: '',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'vendortype', // pass the exact column name of db
                title: 'Vendor Type',
                value: '',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: VendorsNameOption,
                class: 'md:col-4'
            },
            { name: 'break2', title: '', fieldtype: 'break', class: 'md:col-12' },
            {
                name: 'createdAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                labeltitle: 'Created At',
                fieldtype: 'label',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode == 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'updatedAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                labeltitle: 'Updated At',
                fieldtype: 'label',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode === 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            }
        ],
        actions: [
            {
                title: 'Submit'
            },
            {
                title: 'Cancel'
            }
        ]
        // getTable: listDxVendors,
        // createTable: createDxVendors, //Pass create table name
        // updateTable: updateDxVendors, //Pass update table name
        // deleteTableItem: deleteDxVendors //Pass update table name
    };
    const addData = async (props: any) => {
        let data = props;
        // data['id'] = Math.random().toString(36).substring(2, 9);
        const subs = API.graphql<GraphQLSubscription<OnCreateDxAppointmentsSubscription>>({
            query: onCreateDxVendors,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value });
            },
            error: (error) => console.warn(error)
        });
        let vendor = await dxService.createitem('vendor', data, false);
        // let os: any = await API.graphql({
        //     query: createDxVendors,
        //     authMode: 'AMAZON_COGNITO_USER_POOLS',
        //     variables: {
        //         input: data
        //     }
        // });
        showSuccess('', 'Added Successfully');
        if (subs) subs.unsubscribe();
        navigate('/manage/vendors/all');
    };
    const changedata = (props: any) => {
        console.log(props);
    };
    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? 'mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';

    const wizardItems = [{ label: 'Basic Details', command: () => navigate('/manage/vendors/item/basic' + qs) }];
    const editnewnavigation = async (formdata: any, fid: string) => {
        let b: any = formdata;
        console.log(formdata);

        Object.keys(b).forEach((key) => {
            if (b[key] == null) {
                delete b[key];
            }
        });

        if (Object.keys(b).includes('_lastChangedAt')) delete b['_lastChangedAt'];
        if (Object.keys(b).includes('createdAt')) delete b['createdAt'];
        if (Object.keys(b).includes('updatedAt')) delete b['updatedAt'];
        if (Object.keys(b).includes('owner')) delete b['owner'];
        // let dd = new Date(b['startdate']);
        //         b['startdate']= dd.toISOString();

        AmplifyServices.EditConfigDetails(updateDxVendors, 'updateDxVendors', b)
            .then(async (res: any) => {
                console.log(res);

                showSuccess(res.name, 'updated successfully!');
                navigate(`/manage/vendors/all`);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const showSuccess = (summary: string, detail: string) => {
        toast.current.show({
            severity: 'success',
            summary: summary,
            detail: detail,
            life: 3000
        });
    };
    const addConfig = (props: any) => {
        if (formid == null) addData(props);
        else {
            editnewnavigation(props, formid);
        }
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{formmode === 'edit' ? 'Edit vendor : ' + formid : formmode === 'view' ? 'View Vendor: ' + formid : 'New Vendor'}</h4>

                <div className="card container-card">
                    <Toast ref={toast} />
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                    <Routes>
                        <Route
                            path={'/basic'}
                            element={
                                <>
                                    <FormTemplate columns={formColumns} data={listFormData} showaction={true} onChange={changedata} mode={formmode} ChangeBtn={'OpenDocument'} layout="grid2" title="New Approval" submitForm={addConfig} />
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};
export default Vendornewform;
