import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AmplifyServices from '../../Service/Service';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createDxDriver, deleteDxDriver, updateDxDriver } from '../../graphql/mutations';
import { listDxCompanies, listDxDepartments, listDxDrivers } from '../../graphql/queries';
import ListnewForm from '../../Components/ListnewForm';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import ListForm from '../../Components/ListForm';
// import UploadDocuments from '../../Components/UploadDocuments';
import DriverAllocations from './DriverAllocation';
// import Documents from './Documents';
import dxService from '../../Services/dxService';
import FormTemplate from '../../Components/FormTemplate';
import { getNextQueryData, isotolocaledatetime, localetoisodatetime } from '../../utils/helper';
import awsExports from '../../aws-exports';
import { Amplify, Storage } from 'aws-amplify';
import Driverviewform from './DocumentViewform';
import { AuthContext } from '../../AuthContext';
var id: any;
var id1: any;
var UploadDocuments: any = [];
var UploadDriverPicture: any = [];
let uniqueDriverPictures: any = [];
let uniqueDriverDocuments: any = [];
var Appointmenttabledata: any = [];
var Vehicletabledata: any = [];
var wizardItems: any;
const Drivernewform = (Data: any, msg: any) => {
    const { dispatch } = useContext(AuthContext);
    const [DepartmentOptions, setDepartmentOptions] = useState<any>([]);
    const [BusinessOptions, setBusinessOptions] = useState<any>([]);
    const [LicencetypeOptions, setLicencetypeOptions] = useState<any>([]);
    const [documentpath, setdocumentpath] = useState<any>([]);
    const [updatedata, setupdatedata] = useState<any>([]);
    const [listFormData, setlistFormData] = useState<any>({});
    const [oldjField, setoldjField] = useState<any>([]);
    const [checkDriverExist, setcheckDriverExist] = useState<any>([]);

    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<any>(null);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    var driver = urlParams.has('driver') ? urlParams.get('driver') : null;

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
        formid = urlParams.has('id') ? urlParams.get('id') : null;
        const paths = location.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'allocations':
                setActiveIndex(2);
                break;
            // case 'logs':
            //     setActiveIndex(3);
            //     break;
            // case 'Allocations':
            //     setActiveIndex(4);
            //     break;
            default:
                if (formid) getvehicledata(formid);
                break;
        }
    }, [location]);
    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);
    useEffect(() => {
        getData();
    }, []);
    const getvehicledata = async (fid: any, nextToken?: any) => {
        let filter = {
            id: {
                eq: `${fid}` // filter priority = 1
            }
        };

        let drivers = await dxService.listitems('driver', '*', filter, false, 1000, nextToken);
        let response = drivers.items;

        let nexttoken = drivers.nextToken;
        if (nexttoken) {
            const nextResults = await getvehicledata(nexttoken);
            response = response.concat(nextResults);

            // response = response.concat(nextResults);
        }
        response = response.filter((item: any) => item._deleted !== true);
        setoldjField(response);
        let a: any = response;
        let driverphoto = '';
        if (a[0].jfield && JSON.parse(a[0].jfield).driverpicture) {
            driverphoto = await Storage.get(JSON.parse(a[0].jfield).driverpicture);
        }
        if (a.length > 0) {
            setlistFormData({
                id: a[0].id,
                _version: a[0]._version,
                name: a[0].name,
                contactno: a[0].contactno,
                dxCompanyDriversId: a[0].dxCompanyDriversId,
                dxDepartmentDriversId: a[0].dxDepartmentDriversId,
                emailid: a[0].emailid,
                licensetype: a[0].licensetype,
                licenseno: a[0].licenseno,
                jobrole: a[0].jobrole,
                status: a[0].status !== null && a[0].status == 'Active' ? true : false,
                licensepicture: a[0].licensepicture,
                createdAt: isotolocaledatetime(a[0].createdAt),
                updatedAt: isotolocaledatetime(a[0].updatedAt),
                // driverspicture: driverphoto ? driverphoto.split('?')[0] : '',
                driverspicture: driverphoto,
                startdate: isotolocaledatetime(a[0].startdate),
                enddate: isotolocaledatetime(a[0].enddate)
            });
        }
        return response;
    };

    const changevehicledata = (props: any) => {
        setupdatedata(props);
    };
    useEffect(() => {
        let cols = driverformcolumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });
        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);
    const getData = async () => {
        await AmplifyServices.getTableDetails(listDxCompanies, 'listDxCompanies').then(async (res: any) => {
            setBusinessOptions(res);
        });
        await AmplifyServices.getTableDetails(listDxDepartments, 'listDxDepartments').then(async (res: any) => {
            setDepartmentOptions(res);
        });

        // await AmplifyServices.getTableDetails(listDxDepartments, 'listDxDepartments').then(async (res: any) => {
        //    setLicencetypeOptions(res);
        // });
        let filter = {
            fleettype: {
                eq: 'licencetype'
            }
        };
        await dxService.listitems('generalconfiguration', '*', filter).then(async (res: any) => {
            setLicencetypeOptions(res);
        });
        let driverfilter: any = {};
        await getNextQueryData('', driverfilter, 'driver').then(async (res: any) => {
            setcheckDriverExist(res);
        });
    };
    const DepartmentNameOption: any = [];
    const names = DepartmentOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.id;
        DepartmentNameOption.push(res);
    });
    const BusinessNameOption: any = [];
    const Dnames = BusinessOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.id;
        BusinessNameOption.push(res);
    });
    const LicencetypeOption: any = [];
    const Licencetypes = LicencetypeOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        LicencetypeOption.push(res);
    });
    const driverformcolumns = {
        columns: [
            { name: 'driverspicture', value: '', title: "Driver's Photo", fieldtype: 'upload', required: false, disabled: false, hidden: false, data: [] },
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header'
            },
            {
                name: 'name',
                value: '',
                title: 'Name',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },

            // { name: 'mobileno', value: '', title: 'Mobile No', fieldtype: 'number', required: false, disabled: false, hidden: false, data: [] },
            { name: 'contactno', value: '', title: 'Contact No', fieldtype: 'text', required: true, disabled: false, hidden: false, data: [] },
            { name: 'emailid', value: '', title: 'Email ID', fieldtype: 'text', required: true, disabled: false, hidden: false, data: [] },
            {
                name: 'dxCompanyDriversId',
                value: '',
                title: 'Business Unit',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: BusinessNameOption
            },
            {
                name: 'dxDepartmentDriversId',
                value: '',
                title: 'Department',
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: DepartmentNameOption
            },
            { name: 'jobrole', value: '', title: 'Job Role', fieldtype: 'text', required: false, disabled: false, hidden: false, data: [] },
            {
                name: 'status',
                value: '',
                title: 'isActive',
                fieldtype: 'switch',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: []
            },
            {
                title: 'License Information',
                name: 'licenseinfo',
                fieldtype: 'header'
            },

            { name: 'licensetype', value: '', title: 'License Type', fieldtype: 'dropdown', required: true, disabled: false, hidden: false, data: LicencetypeOption },
            // {
            //     title: 'Others',
            //     name: 'others',
            //     fieldtype: 'header'
            // },
            { name: 'licenseno', value: '', title: 'License No', fieldtype: 'text', required: true, disabled: false, hidden: false, data: [] },
            { name: 'startdate', value: '', title: 'Start Date', fieldtype: 'datetime', required: true, disabled: false, hidden: false, data: [] },
            { name: 'enddate', value: '', title: 'End Date', fieldtype: 'datetime', required: true, disabled: false, hidden: false, data: [] },
            // {
            //     name: 'isnew',
            //     value: '',
            //     title: 'New Driver?',
            //     fieldtype: 'dropdown',
            //     required: true,
            //     disabled: false,
            //     hidden: false,
            //     data: [
            //         { label: 'Yes', value: true },
            //         { label: 'No', value: false }
            //     ]
            // }
            {
                title: 'Attachments',
                name: 'attachments',
                fieldtype: 'header'
            },
            { name: 'licensepicture', value: listFormData.licensepicture ? listFormData.licensepicture : '', title: "Driver's Lincese", fieldtype: 'fileupload', required: false, disabled: false, hidden: false, data: [] },

            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },
            {
                name: 'createdAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                // title: 'Created At',
                fieldtype: 'label',
                labeltitle: 'Created At',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode == 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            },
            {
                name: 'updatedAt',
                //title: 'Additional Cost [ Cost not covered under Service Contract ]',
                // title: 'Updated At',
                fieldtype: 'label',
                labeltitle: 'Updated At',
                required: false,
                value: '',
                //hidden: formmode === 'new' && listFormData.eventtype !== 'Repair/Replacement' ? true : false,
                hidden: formmode === 'view' ? false : true,
                disabled: true,
                data: [],
                class: 'md:col-4'
            }
        ],
        actions: [
            {
                title: formmode != 'edit' ? 'Submit' : 'Save',
                disabled: formmode == 'view' ? true : false
            },
            {
                title: 'Cancel'
            }
        ],
        fleettype: 'driver',
        getTable: listDxDrivers,
        createTable: createDxDriver //Pass update table name
    };
    const addDocToDB = async (files: any) => {
        let createDocument = await dxService.createitem('document', files, false);
    };
    const submitForm = async (data: any) => {
        let checkdriver = checkDriverExist.filter((res: any) => (res.name != null && res.name.toLowerCase()) == (data.name !== null && data.name.toLowerCase()));
        if (checkdriver.length > 0) {
            showSuccess('error', 'Driver: ' + checkdriver[0].name, 'Driver with this name already exist');
        } else {
            let id: any = Math.random().toString(36).substring(2, 15);
            let doumentObj: any = {};
            let dPicObj: any = {};
            uniqueDriverPictures = [];
            uniqueDriverDocuments = [];
            if (data.licensepicture && Array.isArray(data.licensepicture)) {
                for (let i = 0; i < data.licensepicture.length; i++) {
                    //getting the document upload names and creating the own object
                    doumentObj = new Object({ file: data.licensepicture[i], type: 'documents', path: `DriverLicense/${data.name}/${new Date().getFullYear()}/${id}/license/` });
                    //pushing an object in an UploadDocuments array
                    UploadDocuments.push(doumentObj);
                }
            }
            if (data.driverspicture && data.driverspicture.file && Array.isArray(data.driverspicture.file)) {
                for (let i = 0; i < data.driverspicture.file.length; i++) {
                    //getting the document upload names and creating the own object
                    dPicObj = new Object({ file: data.driverspicture.file[i], type: 'picture', path: `DriverPictures/${data.name}/${new Date().getFullYear()}/${id}/pictures/` });
                    //pushing an object in an UploadDriverPicture array
                    UploadDriverPicture.push(dPicObj);
                }
            }
            let createdriver: any = {
                // id: id,
                name: data.name,
                contactno: data.contactno,
                dxCompanyDriversId: data.dxCompanyDriversId,
                dxDepartmentDriversId: data.dxDepartmentDriversId,
                emailid: data.emailid,
                jobrole: data.jobrole,
                licenseno: data.licenseno,
                licensepicture: data.licensepicture ? true : false,
                licensetype: data.licensetype,
                startdate: localetoisodatetime(data.startdate),
                enddate: localetoisodatetime(data.enddate),
                status: data.status == true ? 'Active' : 'InActive'
            };
            uniqueDriverDocuments = removeDuplicates(UploadDocuments, 'file');
            if (uniqueDriverDocuments.length > 0) {
                await Promise.all(
                    uniqueDriverDocuments.map(async (item: any) => {
                        await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                            item['fullpath'] = res.key;
                            const files = {
                                name: res.key,
                                // dxAllocationsPicturesId: id,
                                s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                                resource: createdriver.licensetype,
                                resourceid: `LicenseNo/${createdriver.licenseno}`,
                                presourceid: createdriver.id,
                                presource: 'Driver',
                                field1: `License`
                            };
                            addDocToDB(files);
                        });
                    })
                ).then(() => {
                    doumentObj = {};
                    uniqueDriverDocuments = [];
                    UploadDocuments = [];
                });
            }
            uniqueDriverPictures = removeDuplicates(UploadDriverPicture, 'file');
            if (uniqueDriverPictures.length > 0) {
                await Promise.all(
                    uniqueDriverPictures.map(async (item: any) => {
                        await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                            item['fullpath'] = res.key;
                            createdriver.jfield = JSON.stringify({
                                driverpicture: res.key
                            });
                            const files = {
                                name: res.key,
                                // dxAllocationsPicturesId: id,
                                s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                                resource: createdriver.licensetype,
                                resourceid: `LicenseNo/${createdriver.licenseno}`,
                                presourceid: createdriver.id,
                                presource: 'Driver',
                                field1: `photo`
                            };
                            addDocToDB(files);
                        });
                    })
                ).then(() => {
                    dPicObj = {};
                    uniqueDriverPictures = [];
                    UploadDriverPicture = [];
                });
            }
            if (uniqueDriverDocuments.length == 0) {
                let createDriverdata = await dxService.createitem('driver', createdriver).then(async (res) => {
                    uniqueDriverPictures = [];
                    uniqueDriverDocuments = [];
                    UploadDocuments = [];
                    UploadDriverPicture = [];
                    showSuccess('success', 'Driver: ' + res.name, 'Driver Created successfully');
                    dispatch({ type: 'NAVIGATION', payload: 'Updated' + new Date() });
                    setTimeout(() => {
                        let navigateUrl = '/manage/drivers/all';
                        navigate(navigateUrl);
                    }, 1000);
                });
            }
        }

        // setTimeout(() => {
        //     let navigateUrl = '/manage/drivers/all';
        //     navigate(navigateUrl);
        // }, 1000);
    };
    const removeDuplicates = (arr: any, key: any) => {
        return arr.reduce((acc: any, obj: any) => {
            const found = acc.some((item: any) => item[key] === obj[key]);
            if (!found) {
                acc.push(obj);
            }
            return acc;
        }, []);
    };
    let qs = '';
    let qsa = '';
    let qsaedit = '';
    let qsedit = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qsaedit = formid != null ? '?id=' + formid : '';
    qsaedit += formmode != null ? '&mode=' + 'edit' : '';
    qs = qsa != '' ? '?' + qsa : '';
    qsedit = qsaedit != '' ? '?' + qsaedit : '';

    const wizardItemsEdit = [
        { label: 'Basic Details', command: () => navigate('/manage/drivers/item/basic' + qs) },
        { label: 'Licence Documents', command: () => navigate('/manage/drivers/item/pictures' + qs), disabled: listFormData.licensepicture && listFormData.licensepicture == 'true' ? false : true },
        { label: 'Allocations', command: () => navigate('/manage/drivers/item/allocations' + qs) }
    ];
    const wizardItemsView = [
        { label: 'Basic Details', command: () => navigate('/manage/drivers/item/basic' + qs) },
        { label: 'Licence Documents', command: () => navigate('/manage/drivers/item/pictures' + qs), disabled: listFormData.licensepicture && listFormData.licensepicture == 'true' ? false : true },
        { label: 'Allocations', command: () => navigate('/manage/drivers/item/allocations' + qs + '&driver=' + listFormData.name) },
        {
            label: 'Edit',
            icon: 'pi pi-fw pi-pencil',
            command: () => navigate('/manage/drivers/item/basic' + qsaedit)
        }
    ];
    if (formmode == 'view') {
        wizardItems = wizardItemsView;
    } else if (formmode == 'edit' || formmode == 'new') {
        wizardItems = wizardItemsEdit;
    }
    const editnewvehicle = async (formdata: any, fid: string) => {
        uniqueDriverPictures = [];
        uniqueDriverDocuments = [];
        let b: any = formdata;
        let doumentObj: any = {};
        let dPicObj: any = {};
        if (b.licensepicture && Array.isArray(b.licensepicture)) {
            for (let i = 0; i < b.licensepicture.length; i++) {
                //getting the document upload names and creating the own object
                doumentObj = new Object({ file: b.licensepicture[i], type: 'documents', path: `DriverLicense/${b.name}/${new Date().getFullYear()}/${b.id}/license/` });

                //pushing an object in an UploadDocuments array
                UploadDocuments.push(doumentObj);
            }
        }

        if (b.driverspicture && b.driverspicture.file && Array.isArray(b.driverspicture.file)) {
            for (let i = 0; i < b.driverspicture.file.length; i++) {
                //getting the document upload names and creating the own object
                dPicObj = new Object({ file: b.driverspicture.file[i], type: 'picture', path: `DriverPictures/${b.name}/${new Date().getFullYear()}/${b.id}/pictures/` });

                //pushing an object in an UploadDriverPicture array
                UploadDriverPicture.push(dPicObj);
            }
        }
        let updatedriver: any = {
            id: b.id,
            contactno: b.contactno,
            dxCompanyDriversId: b.dxCompanyDriversId,
            dxDepartmentDriversId: b.dxDepartmentDriversId,
            emailid: b.emailid,
            jobrole: b.jobrole,
            licenseno: b.licenseno,
            licensetype: b.licensetype,
            licensepicture: b.licensepicture || b.driverspicture ? true : false,
            name: b.name,
            status: b.status == true ? 'Active' : 'InActive',
            startdate: localetoisodatetime(b.startdate),
            enddate: localetoisodatetime(b.enddate),
            _version: b._version
        };

        uniqueDriverPictures = removeDuplicates(UploadDriverPicture, 'file');

        if (uniqueDriverPictures.length > 0) {
            let jsondatajfield = oldjField[0].jfield ? JSON.parse(oldjField[0].jfield) : {};
            await Promise.all(
                uniqueDriverPictures.map(async (item: any) => {
                    await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                        item['fullpath'] = res.key;
                        jsondatajfield.driverpicture = res.key;
                        updatedriver.jfield = JSON.stringify(jsondatajfield);
                        const files = {
                            name: res.key,
                            // dxAllocationsPicturesId: id,
                            s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                            resource: updatedriver.licensetype,
                            resourceid: `LicenseNo/${updatedriver.licenseno}`,
                            presourceid: updatedriver.id,
                            presource: 'Driver',
                            field1: `photo`
                        };
                        addDocToDB(files);
                    });
                })
            ).then(() => {
                dPicObj = {};
                uniqueDriverPictures = [];
                UploadDriverPicture = [];
            });
        }
        uniqueDriverDocuments = removeDuplicates(UploadDocuments, 'file');

        if (uniqueDriverDocuments.length > 0) {
            await Promise.all(
                uniqueDriverDocuments.map(async (item: any) => {
                    await Storage.put(item.path + item.file.name, item.file).then((res: any) => {
                        item['fullpath'] = res.key;
                        const files = {
                            name: res.key,
                            // dxAllocationsPicturesId: id,
                            s3object: { bucket: awsExports.aws_user_files_s3_bucket, region: awsExports.aws_user_files_s3_bucket_region, key: res.key },
                            resource: updatedriver.licensetype,
                            resourceid: `LicenseNo/${updatedriver.licenseno}`,
                            presourceid: updatedriver.id,
                            presource: 'Driver',
                            field1: `License`
                        };
                        addDocToDB(files);
                    });
                })
            ).then(() => {
                doumentObj = {};
                uniqueDriverDocuments = [];
                UploadDocuments = [];
            });
        }

        let updatedriverdetails = await dxService.updateitem('driver', updatedriver).then(async (res) => {
            uniqueDriverPictures = [];
            uniqueDriverDocuments = [];
            UploadDocuments = [];
            UploadDriverPicture = [];
            showSuccess('success', 'Driver: ' + res.name, 'Driver Updated successfully');
            dispatch({ type: 'NAVIGATION', payload: 'Updated' + new Date() });
            setTimeout(() => {
                let navigateUrl = '/manage/drivers/all';
                navigate(navigateUrl);
            }, 1000);

            // showSuccess('Driver: ' + res.name, 'Driver Updated successfully');
            // setTimeout(() => {
            //     let navigateUrl = '/manage/drivers/all';
            //     navigate(navigateUrl);
            // }, 1000);
        });
        //   let a = ManagevehicleColumns.columns.filter((e: any) => {
        //       return e.fieldtype == 'date' || e.fieldtype == 'datetime';
        //   });
        //   a.map((_a: any) => {
        //       b[_a.name] = b[_a.name] ? new Date(b[_a.name]).toISOString() : null;
        //   });

        //   // a.map((_a: any) => {
        //   //     if (_a.name && formdata[_a.name])
        //   //         b[_a.name] = formdata[_a.name]
        //   // });
        //   // b['fleettype'] = "vehicle";

        // Object.keys(b).forEach((key) => {
        //     if (b[key] == null) {
        //         delete b[key];
        //     }
        // });

        // if (Object.keys(b).includes('_lastChangedAt')) delete b['_lastChangedAt'];
        // if (Object.keys(b).includes('createdAt')) delete b['createdAt'];
        // if (Object.keys(b).includes('updatedAt')) delete b['updatedAt'];
        // if (Object.keys(b).includes('owner')) delete b['owner'];
        // if (Object.keys(b).includes('allocations')) delete b['allocations'];
        // if (Object.keys(b).includes('appointments')) delete b['appointments'];
        // if (Object.keys(b).includes('company')) delete b['company'];
        // if (Object.keys(b).includes('department')) delete b['department'];
        // if (Object.keys(b).includes('masterlogs')) delete b['masterlogs'];
        // if (Object.keys(b).includes('vehicleactivity')) delete b['vehicleactivity'];
        // // let dd = new Date(b['startdate']);
        // //         b['startdate']= dd.toISOString();

        // AmplifyServices.EditDriverDetails(b)
        //     .then(async (res: any) => {
        //         showSuccess('Vehicle: ' + res.id, 'Vehicle updated successfully!');
        //         // let navigateUrl = '/manage/drivers/all';
        //         // navigate(navigateUrl);
        //         setTimeout(() => {
        //             window.history.back();
        //         }, 2000);
        //     })
        //     .catch((err) => {
        //     });
    };
    const showSuccess = (severity: any, summary: string, detail: string) => {
        toast.current.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: 3000
        });
    };
    const addVehicle = (props: any) => {
        //setlistFormData(props);
        if (formid == null) {
            submitForm(props);
        } else {
            editnewvehicle(props, formid);
        }
    };

    return (
        <div className="grid appoinment">
            <div className="col-12 md:col-12">
                <h4 className="container-header">
                    {formmode === 'new' ? `New Driver` : formmode === 'edit' ? `Edit Driver : ${listFormData.name}` : formmode === 'close' ? `Close Driver : ${listFormData.name}` : `View Driver : ${driver ? driver : listFormData.name}`}
                </h4>
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast} />
                            <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                            <Routes>
                                <Route
                                    path={'/basic'}
                                    element={
                                        <>
                                            <FormTemplate
                                                bucketname="vehicle"
                                                Fleettype={'vehiclePicture'}
                                                columns={driverformcolumns}
                                                data={listFormData}
                                                showaction={true}
                                                mode={formmode}
                                                onChange={changevehicledata}
                                                // setselectedmakeDropdown={setselectedmakeDropdown}
                                                // ChangeBtn={'OpenDocument'}
                                                layout="grid2"
                                                title="New Driver"
                                                submitForm={addVehicle}
                                            />
                                        </>
                                    }
                                />
                            </Routes>

                            <Routes>
                                <Route
                                    path={'/pictures'}
                                    element={
                                        <>
                                            {/* <Documents formmode={formmode} formid={formid} /> */}
                                            <Driverviewform />
                                        </>
                                    }
                                />
                            </Routes>
                            <Routes>
                                <Route
                                    path={'/allocations'}
                                    element={
                                        <>
                                            <DriverAllocations />
                                        </>
                                    }
                                />
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Drivernewform;
