import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import AmplifyServices from '../../Service/Service';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import './CompanySettings.css';
import { listDxCompanies } from '../../graphql/queries';
import ListTable from '../../Components/ListTable';
import { deleteDxCompany } from '../../graphql/mutations';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
let params: any;
const CompanySettingsView = () => {
    let emptyItem = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };
    const menu = useRef<any>(null);
    const [items, setItems] = useState<any>(null);
    const [deleteItemDialog, setDeleteItemDialog] = useState<boolean>(false);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);
    const [item, setItem] = useState<any>(emptyItem);
    const [selectedItems, setSelectedItems] = useState<any>([]);

    const [globalFilter, setGlobalFilter] = useState<any>('');
    const toast = useRef<any>(null);
    params = selectedItems.length > 0 && `?mode=edit&id=${selectedItems[0].id}&fleettype=${selectedItems[0].fleettype}`;
    useEffect(() => {
        getData();
    }, [deleteItemDialog, deleteItemsDialog]);
    const getData = async () => {
        await AmplifyServices.getTableDetails(listDxCompanies, 'listDxCompanies').then(async (res: any) => {
            setItems(res);
        });
    };

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
        setSelectedItems([]);
    };

    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };
    const navigate = useNavigate();

    const editProduct = (product: any) => {
        navigate(`/admin/businessunit/item/basic?mode=edit&id=${product.id}&fleettype=${product.fleettype}`);
    };

    const confirmDeleteProduct = (product: any) => {
        setSelectedItems([product]);
        setItem(product);
        setDeleteItemDialog(true);
    };

    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded  mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const columns = [
        // { fieldtype: 'multiselect' },
        {
            field: 'name',
            header: 'Name',
            fieldtype: 'text',
            filterOption: true,
            filterFieldOption: 'name'
        },
        {
            field: 'description',
            header: 'Description',
            fieldtype: 'text',
            filterOption: true,
            filterFieldOption: 'description'
        },
        {
            field: 'owner',
            header: 'Owner',
            fieldtype: 'text',
            filterOption: true,
            filterFieldOption: 'owner'
        },
        {
            field: 'fleettype',
            header: 'Actions',
            fieldtype: 'custom',
            filterOption: false,
            template: (r: any) => <>{actionBodyTemplate(r)}</>
        }
    ];

    const deleteItemDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-danger" onClick={() => deleteItem()} />
        </>
    );
    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text companyBtn" onClick={() => deleteItem()} />
        </>
    );
    const deleteItem = async () => {
        AmplifyServices.DeleteItems(selectedItems, deleteDxCompany).then((res) => {
            getData();
            hideDeleteItemDialog();
            hideDeleteItemsDialog();
            setSelectedItems([]);
            selectedItems.length = 0;
        });
    };

    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New" onClick={() => navigate('/admin/businessunit/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />

                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />

                <Menu
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedItems.length == 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                navigate('/admin/businessunit/item/basic' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            <div>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <form>
                        <div
                            style={{
                                position: 'relative',
                                marginTop: '3px',
                                marginBottom: '1em'
                            }}
                        >
                            <InputText type="text" id={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
                        </div>
                    </form>
                </span>
            </div>
        </div>
    );
    let filteredSearch =
        items &&
        items
            .filter((del: any) => del._deleted !== true)
            .filter((name: any) => {
                if (
                    (name.name !== null && name.name.toLowerCase().includes(globalFilter.toLowerCase().toLowerCase())) ||
                    (name.description !== null && name.description.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase().toLowerCase())) ||
                    (name.owner !== null && name.owner.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase()))
                ) {
                    return name;
                }
            });
    return (
        <div className="grid crud">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <ListTable
                        header="Business Unit"
                        value={filteredSearch}
                        paginator={true}
                        rows={10}
                        dynamicColumns={columns}
                        emptyMessage="No company found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Company"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 25]}
                        //   selection={selectedItems}
                        multiselect={true}
                        //   selectedItems={selectedItems}
                        Commandbar={Commandbar}
                        // Commandbar={leftToolbarTemplate}
                        //   onSelectionChange={e => setSelectedItems(e.value)}
                        selectedItems={setSelectedItems}
                    />

                    <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {item && (
                                <span>
                                    Are you sure you want to delete <b>{item.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedItems && <span>Are you sure you want to delete the selected items?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CompanySettingsView;
