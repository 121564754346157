import { useEffect, useRef, useState } from "react";
import {
  convertdate,
  getNextQueryData,
  isotolocaledatetime,
} from "../../../utils/helper";
import moment from "moment";
import brandingImg from "../../../assets/Branding.svg";
import RepairImg from "../../../assets/Repair.svg";
import UpcomingImg from "../../../assets/Upcoming.svg";
import ExpiredImg from "../../../assets/expired.svg";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const today = new Date(); // get today's date
const next15days = new Date(today.getTime() + 16 * 24 * 60 * 60 * 1000);
const next7days = new Date(today.getTime() + 8 * 24 * 60 * 60 * 1000);
let expiredRType: any = [];
let UpcomingRType: any = [];
const VehicleRenewals = (props: any) => {
  const [upcommingrenewals, setupcommingrenewals] = useState<any>([]);
  const [exprenewals, setexprenewals] = useState<any>([]);
  const [repairCost, setrepairCost] = useState<any>([]);
  const [repairdata, setrepairdata] = useState<any>([]);
  const [brandingdata, setbrandingdata] = useState<any>([]);
  const [brandingCost, setbrandingCost] = useState<any>([]);
  const upcommingRef = useRef<any>(null);
  const expiredtRef = useRef<any>(null);
  const brandingRef = useRef<any>(null);
  const repairRef = useRef<any>(null);
  useEffect(() => {
    UpcomingRType = [];
    const fetchUpcommingRenewals = async () => {
      let filter = {
        vehicleid: { eq: props.formid },
        and: [
          { expirydate: { gt: new Date() } },
          { expirydate: { le: next15days } },
        ],
      };
      await getNextQueryData("", filter, "renewal", true).then(
        async (renew: any) => {
          console.log(renew);
          let collectUpcommingarray: any = [];
          let upcommingrenewals = renew.filter((item: any) => {
            if (item._deleted !== true) {
              if (
                item.renewaltype == "Registration" &&
                moment(item.expirydate).isBetween(new Date(), next15days) &&
                item.renewalstatus !== "Completed"
              ) {
                collectUpcommingarray.push(item);
                return true;
              }
              if (
                item.renewaltype == "Insurance" &&
                moment(item.expirydate).isBetween(new Date(), next15days) &&
                item.renewalstatus !== "Completed"
              ) {
                collectUpcommingarray.push(item);
                return true;
              }
              if (
                item.renewaltype == "ServiceContract" &&
                moment(item.expirydate).isBetween(new Date(), next15days) &&
                item.renewalstatus !== "Completed"
              ) {
                collectUpcommingarray.push(item);
                return true;
              }
              // if (item.renewaltype == 'Warranty' && moment(item.expirydate).isBetween(new Date(), next15days) && item.renewalstatus !== 'Completed') {
              //     collectUpcommingarray.push(item);
              //     return true;
              // }
              if (
                item.renewaltype == "Parking" &&
                moment(item.expirydate).isBetween(new Date(), next7days) &&
                item.renewalstatus !== "Completed"
              ) {
                collectUpcommingarray.push(item);
                return true;
              }
              if (
                item.renewaltype == "Branding" &&
                moment(item.expirydate).isBetween(new Date(), next7days) &&
                item.renewalstatus !== "Completed"
              ) {
                collectUpcommingarray.push(item);
                return true;
              }
            }
          });
          console.log(upcommingrenewals);

          collectUpcommingarray = upcommingrenewals;
          if (collectUpcommingarray.length > 0) {
            setupcommingrenewals(collectUpcommingarray);
            collectUpcommingarray.map((i: any) =>
              UpcomingRType.push(i.renewaltype)
            );
          }
          return upcommingrenewals;
        }
      );
    };
    fetchUpcommingRenewals();
  }, []);
  useEffect(() => {
    expiredRType = [];
    const fetchExpiredrenewals = async () => {
      let filter = {
        vehicleid: { eq: props.formid },
        and: [
          { expirydate: { lt: new Date() } },
          {
            expirydate: { ne: new Date(1970, 0, 1) },
            renewalstatus: { ne: "Completed" },
          },
        ],
      };
      await getNextQueryData("", filter, "renewal", true).then(
        async (renew: any) => {
          let exprenewal = renew.filter(
            (item: any) =>
              item._deleted !== true && item.renewaltype !== "Warranty"
          );
          if (exprenewal.length > 0) {
            setexprenewals(exprenewal);
            exprenewal.map((i: any) => expiredRType.push(i.renewaltype));
            // expiredRType;
          }
          return exprenewal;
        }
      );
    };
    fetchExpiredrenewals();
  }, []);

  useEffect(() => {
    const fetchAppointmentData = async () => {
      let filter = {
        vehicleid: { eq: props.formid },
      };
      await getNextQueryData("", filter, "appointment", true).then(
        async (appointment: any) => {
          const repairCost: any = appointment
            .filter(
              (status: any) =>
                status.eventtype !== "Branding Expense" &&
                status.appointmentstatus === "Completed"
            )
            .reduce(
              (acc: any, serviceItem: { cost: any }) =>
                acc + Number(serviceItem.cost),
              0
            );
          const repairData: any = appointment
            .sort(function (a: any, b: any) {
              return new Date(b.Date).valueOf() - new Date(a.Date).valueOf();
            })
            .filter(
              (status: any) =>
                status.eventtype !== "Branding Expense" &&
                status.appointmentstatus === "Completed"
            );
          const brandingCost: any = appointment
            .filter(
              (status: any) =>
                status.eventtype === "Branding Expense" &&
                status.appointmentstatus === "Completed"
            )
            .reduce(
              (acc: any, serviceItem: { cost: any }) =>
                acc + Number(serviceItem.cost),
              0
            );
          const brandingData: any = appointment
            .sort(function (a: any, b: any) {
              return new Date(b.Date).valueOf() - new Date(a.Date).valueOf();
            })
            .filter(
              (status: any) =>
                status.eventtype === "Branding Expense" &&
                status.appointmentstatus === "Completed"
            );
          setbrandingdata(brandingData);
          setrepairdata(repairData);
          setbrandingCost(brandingCost);
          setrepairCost(repairCost.toFixed(2));
        }
      );
    };
    fetchAppointmentData();
  }, []);
  //   console.log(repairdata, brandingdata);
  const dateTemplate = (data: any, col: string) => {
    let fd = isotolocaledatetime(data.fromdate);
    let td = new Date();

    return <>{data[col] !== null ? convertdate(data[col]) : "-"}</>;
  };
  //dateTemplateNavigate
  const dateTemplateNavigate = (data: any, col: string) => {
    let fd = isotolocaledatetime(data.fromdate);
    let td = new Date();
    if ((fd != null && fd >= td) || data.handoverdate == null) {
      return (
        <a
          href={`#/manage/renewals/item/basic?mode=view&id=${data.id}&vid=${data.vehicleid}&type=${data.renewaltype}`}
        >
          <b>{data[col] !== null ? convertdate(data[col]) : "-"}</b>
        </a>
      );
    } else {
      return <>{data[col] !== null ? convertdate(data[col]) : "-"}</>;
    }
  };
  const AppointmentdateTemplate = (data: any, col: string) => {
    let fd = isotolocaledatetime(data.fromdate);
    let td = new Date();
    if ((fd != null && fd >= td) || data.handoverdate == null) {
      return (
        <a
          href={`#/manage/appointments/item/basic?mode=view&id=${data.id}&vid=${data.vehicleid}`}
        >
          <b>{data[col] !== null ? convertdate(data[col]) : "-"}</b>
        </a>
      );
    } else {
      return <>{data[col] !== null ? convertdate(data[col]) : "-"}</>;
    }
  };
  const AmountTemplate = (data: any, col: string) => {
    return <>{data[col] !== null ? parseFloat(data[col]).toFixed(2) : "-"}</>;
  };
  //AppointmentdateTemplate
  return (
    <div className=" col-12 md:col-12 p-5 pl-2">
      <div className="grid">
        <div className="col-12 lg:col-6 xl:col-3 vehicleBox">
          <div className="surface-card shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span
                  className="block  font-medium mb-3"
                  style={{ fontSize: "1.2rem", color: "#212121" }}
                >
                  Upcomming renewals
                </span>
                <div className="text-900 font-medium text-xl">
                  {" "}
                  {upcommingrenewals
                    ? upcommingrenewals.length.toString()
                    : "0"}
                </div>
              </div>
              <Button
                onClick={(e) => {
                  // handleClick('Accident', e, rowData);
                  upcommingRef.current.toggle(e);
                }}
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                {/*upcommingRef <i className="pi pi-shopping-cart text-blue-500 text-xl"></i> */}
                <img src={UpcomingImg} height="20px" />
              </Button>
            </div>
            {/* // console.log(expiredRType, UpcomingRType); */}
            <span className="text-green-500 font-medium">
              {UpcomingRType.toString()}{" "}
            </span>
            <span className="text-700 mt-2">
              {UpcomingRType.length > 0
                ? "goint to expire soon"
                : "there is no upcomming renewal"}{" "}
            </span>
          </div>
        </div>
        <div className="col-12 lg:col-6 xl:col-3 vehicleBox">
          <div className="surface-card shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span
                  className="block  font-medium mb-3"
                  style={{ fontSize: "1.2rem", color: "#212121" }}
                >
                  Expired Renewals
                </span>
                <div className="text-900 font-medium text-xl">
                  {" "}
                  {exprenewals ? exprenewals.length.toString() : "0"}
                </div>
              </div>
              <Button
                onClick={(e) => {
                  // handleClick('Accident', e, rowData);
                  expiredtRef.current.toggle(e);
                }}
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                {/* <i className="pi pi-shopping-cart text-blue-500 text-xl"></i> */}
                <img src={ExpiredImg} height="20px" />
              </Button>
            </div>
            <span className="text-green-500 font-medium">
              {expiredRType.toString()}{" "}
            </span>
            <span className="text-700 mt-2">
              {expiredRType.length > 0
                ? "already expired"
                : "there is no expired renewal"}
            </span>
          </div>
        </div>
        <div className="col-12 lg:col-6 xl:col-3 vehicleBox">
          <div className="surface-card shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span
                  className="block  font-medium mb-3"
                  style={{ fontSize: "1.2rem", color: "#212121" }}
                >
                  Branding Cost
                </span>
                <div className="text-900 font-medium text-xl">
                  {brandingCost ? brandingCost.toString() : "0"}
                </div>
              </div>

              <Button
                onClick={(e) => {
                  // handleClick('Accident', e, rowData);
                  brandingRef.current.toggle(e);
                }}
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                {/* <i className="pi pi-shopping-cart text-blue-500 text-xl"></i> */}
                <img src={brandingImg} height="20px" />
              </Button>
            </div>
            <span className="text-green-500 font-medium">
              {brandingdata.length > 0
                ? moment(brandingdata[0].Date).format("DD MMM YYYY")
                : ""}{" "}
            </span>
            <span className="text-500">
              {brandingdata.length > 0
                ? "last branding date"
                : "there is no branding cost"}
            </span>
          </div>
        </div>
        <div className="col-12 lg:col-6 xl:col-3 vehicleBox">
          <div className="surface-card shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span
                  className="block  font-medium mb-3"
                  style={{ fontSize: "1.2rem", color: "#212121" }}
                >
                  Repair Cost
                </span>
                <div className="text-900 font-medium text-xl">
                  {repairCost ? repairCost.toString() : "0"}
                </div>
              </div>
              <Button
                onClick={(e) => {
                  // handleClick('Accident', e, rowData);
                  repairRef.current.toggle(e);
                }}
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                {/* <i className="pi pi-shopping-cart text-blue-500 text-xl"></i> */}
                <img src={RepairImg} height="20px" />
              </Button>
            </div>
            <span className="text-green-500 font-medium">
              {repairdata.length > 0
                ? moment(repairdata[0].Date).format("DD MMM YYYY")
                : ""}{" "}
            </span>
            <span className="text-500">
              {repairdata.length > 0
                ? "last repair date"
                : "there is no repair cost"}
            </span>
          </div>
        </div>
      </div>
      <OverlayPanel
        ref={upcommingRef}
        showCloseIcon
        id="overlay_panel"
        style={{ width: "450px" }}
      >
        <h5>
          Upcomming Renewals
          {/* {lastdaysfilterkey ? '('+lastdaysfilterkey +' Days)':''}:  {logVid}  */}
        </h5>
        <DataTable value={upcommingrenewals} rows={5} paginator={true}>
          <Column field="renewaltype" header="Renewal Type"></Column>
          <Column
            field="issuedate"
            header="IssueDate"
            body={(rowData: any) => {
              return dateTemplate(rowData, "issuedate");
            }}
          ></Column>

          <Column
            field="expirydate"
            header="ExpiryDate"
            body={(rowData: any) => {
              return dateTemplateNavigate(rowData, "expirydate");
            }}
          ></Column>
        </DataTable>
      </OverlayPanel>
      <OverlayPanel
        ref={expiredtRef}
        showCloseIcon
        id="overlay_panel"
        style={{ width: "450px" }}
      >
        <h5>
          Expired Renewals
          {/* {lastdaysfilterkey ? '('+lastdaysfilterkey +' Days)':''}:  {logVid}  */}
        </h5>
        <DataTable value={exprenewals} rows={5} paginator={true}>
          <Column field="renewaltype" header="Renewal Type"></Column>
          <Column
            field="issuedate"
            header="IssueDate"
            body={(rowData: any) => {
              return dateTemplate(rowData, "issuedate");
            }}
          ></Column>

          <Column
            field="expirydate"
            header="ExpiryDate"
            body={(rowData: any) => {
              return dateTemplateNavigate(rowData, "expirydate");
            }}
          ></Column>
        </DataTable>
      </OverlayPanel>
      <OverlayPanel
        ref={brandingRef}
        showCloseIcon
        id="overlay_panel"
        style={{ width: "450px" }}
      >
        <h5>
          Brandings
          {/* {lastdaysfilterkey ? '('+lastdaysfilterkey +' Days)':''}:  {logVid}  */}
        </h5>
        <DataTable value={brandingdata} rows={5} paginator={true}>
          <Column
            field="cost"
            body={(rowData: any) => {
              return AmountTemplate(rowData, "cost");
            }}
            header="Amount"
          ></Column>
          <Column
            field="place"
            header="Place"
            // body={(rowData: any) => {
            //     return dateTemplate(rowData, 'issuedate');
            // }}
          ></Column>

          <Column
            field="Date"
            header="Date"
            body={(rowData: any) => {
              return AppointmentdateTemplate(rowData, "Date");
            }}
          ></Column>
        </DataTable>
      </OverlayPanel>
      <OverlayPanel
        ref={repairRef}
        showCloseIcon
        id="overlay_panel"
        style={{ width: "450px" }}
      >
        <h5>
          Repair
          {/* {lastdaysfilterkey ? '('+lastdaysfilterkey +' Days)':''}:  {logVid}  */}
        </h5>
        <DataTable value={repairdata} rows={5} paginator={true}>
          <Column
            field="cost"
            body={(rowData: any) => {
              return AmountTemplate(rowData, "cost");
            }}
            header="Amount"
          ></Column>
          <Column
            field="place"
            header="Place"
            // body={(rowData: any) => {
            //     return dateTemplate(rowData, 'issuedate');
            // }}
          ></Column>

          <Column
            field="Date"
            header="Date"
            body={(rowData: any) => {
              return AppointmentdateTemplate(rowData, "Date");
            }}
          ></Column>
        </DataTable>
      </OverlayPanel>
    </div>
  );
};
export default VehicleRenewals;
