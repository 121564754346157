import { API } from 'aws-amplify';
import { AutoComplete } from 'primereact/autocomplete';
import { useEffect, useState } from 'react';
import { listDxUserProfiles } from '../graphql/queries';
import dxService from '../Services/dxService';

function GetUser(props: any) {
    const [users, setUsers] = useState([]);
    //selected name from input search
    const [selectedNames, setSelectedNames] = useState<any>([]);
    const [closeselectedNames, setCloseSelectedNames] = useState<any>([]);
    //filtering user based on search
    const [filteredUsers, setFilteredUsers] = useState([]);

    //#region OnLoad methods
    // Calls method during the component load
    useEffect(() => {
        const getUsers = async (nextToken?: any) => {
            let variables: any = {};
            if (nextToken) {
                variables.nextToken = nextToken;
            }
            let GetResponse: any = await API.graphql({
                query: listDxUserProfiles,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: variables
            });
            let res = GetResponse.data.listDxUserProfiles.items;

            if (GetResponse.data.listDxUserProfiles.nextToken) {
                const nextResults = await getUsers(GetResponse.data.listDxUserProfiles.nextToken);
                res = res.concat(nextResults);
            }

            return res;
        };

        getUsers().then((res) => {
            setUsers(res);
        });
    }, []);
    //#endregion

    //#region OnLoad methods
    // Calls method during the props/state changes

    useEffect(() => {
        props.onChange({ profiles: selectedNames.userprofiles }, 'user', 'auditby');
    }, [selectedNames]);
    //#endregion

    //search all the available users from the db or refer Adduser.tsx inside Roles folder to get directly from the aws users
    const searchUser = (event: any) => {
        let _filteredUsers: any = [];
        let searchValues = [];
        let data: any = users;

        for (let i = 0; i < data.length; i++) {
            const { email, displayname, userid } = data[i];
            searchValues.push({ email, displayname, userid });
            // searchValues.push(data[i]);
        }

        if (!event.query.trim().length) {
            _filteredUsers = [...searchValues];
        } else {
            const query = event.query.toLowerCase();
            _filteredUsers = searchValues.filter((user) => user.email.toLowerCase().includes(query) || user.displayname.toLowerCase().includes(query));
        }

        const formattedUsers = _filteredUsers.map((user: any) => ({ name: user.email, userprofiles: user }));
        setFilteredUsers(formattedUsers);
    };

    return (
        <div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-user"></i>
                </span>
                <AutoComplete
                    value={selectedNames.length > 0 ? selectedNames : typeof props.value === 'string' ? props.value : typeof props.value.profiles === 'object' ? props.value.profiles.email : ''}
                    suggestions={filteredUsers}
                    completeMethod={searchUser}
                    field="name"
                    onChange={(e) => {
                        setSelectedNames(e.value);
                    }}
                    aria-label="email"
                    dropdownAriaLabel="Select User"
                    disabled={props.disabled}
                    required={props.required}
                    className={props.className}
                    placeholder="search"
                />
            </div>
        </div>
    );
}

export default GetUser;
