import { API, graphqlOperation, Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createDxContract, createDxVehicle, deleteDxVehicle, updateDxVehicle } from '../../graphql/mutations';
import { listDxVehicles } from '../../graphql/queries';
import ListnewForm from '../../Components/ListnewForm';
import AmplifyServices from '../../Service/Service';
import VehicleDocumentEditForm from '../Vehicles/DocumentEditform';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SContractviewform from './Viewform';

function SContracteditform() {
    const location = window.location.href;
    const urlParams = new URLSearchParams(location.split('?')[1]);
    var VehicleID: any;
    var expType: any;
    const AllVehicleId: any = [];
    const navigate = useNavigate();
    const [appointmentNeeded, setAppointmentNeeded] = useState(false);
    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        VehicleID = urlParams.get('id');
        expType = urlParams.get('exp');
        getData();
    }, []);

    const getData = async () => {
        await AmplifyServices.getTableDetails(listDxVehicles, 'listDxVehicles').then((res: any) => {
            let Fleetvehicle = res.filter((vehicle: any) => vehicle.fleettype == 'vehicle' && vehicle._deleted !== true);
            // let filterDocument = Fleetvehicle.filter((res: any) => res.id === VehicleID)[0].expiring;
            // let parseValue = JSON.parse(filterDocument);
            Fleetvehicle.map((res: any) => {
                res['label'] = res.id;
                res['value'] = res.id;
                // console.log(res);
                AllVehicleId.push(res);
            });
            setVehicles(AllVehicleId);
        });
    };

    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                name: 'isAppointmentNeeded', // pass the exact column name of db
                value: 'is Appointment Needed?',
                fieldtype: 'switch',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'id', // pass the exact column name of db
                value: 'Vehicle',
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: appointmentNeeded ? true : false,
                data: vehicles
            },
            {
                name: 'policyno', // pass the exact column name of db
                value: 'Policy No', //for display
                fieldtype: 'text',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            {
                name: 'issuedate', // pass the exact column name of db
                value: 'Issue date', //for display
                fieldtype: 'datetime',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            {
                name: 'expirydate', // pass the exact column name of db
                value: 'Expiry date',
                fieldtype: 'datetime',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            {
                name: 'renewaltype', // pass the exact column name of db
                value: 'Renewal Type',
                fieldtype: 'dropdown',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: [
                    { label: 'Insurance', value: 'Insurance' },
                    { label: 'Registration', value: 'Registration' },
                    { label: 'Service Contract', value: 'ServiceContract' },
                    { label: 'RTADocuments', value: 'RTADocuments' },
                    { label: 'Warranty', value: 'Warranty' }
                ]
            },
            {
                name: 'documents', // pass the exact column name of db
                value: 'Document',
                fieldtype: 'fileupload',
                required: false,
                disabled: appointmentNeeded ? true : false,
                hidden: appointmentNeeded ? true : false,
                data: []
            },
            {
                name: 'renewalstatus', // pass the exact column name of db
                value: 'Renewal Status',
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: [
                    { label: 'In Progress', value: 'In Progress' },
                    { label: 'Completed', value: 'Completed' }
                ]
            },
            //hidden fields
            {
                name: 'capacity', // pass the exact column name of db
                value: 'capacity',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'year', // pass the exact column name of db
                value: 'Year',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'departmentname', // pass the exact column name of db
                value: 'Department Name',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'make', // pass the exact column name of db
                value: 'Make',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'permittype', // pass the exact column name of db
                value: 'Permit Type',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'model', // pass the exact column name of db
                value: 'Model',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'platecode', // pass the exact column name of db
                value: 'Platecode',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'plateno', // pass the exact column name of db
                value: 'Plate No',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'status', // pass the exact column name of db
                value: 'Status',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'expiring', // pass the exact column name of db
                value: 'Expiring',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'allocationdriver', // pass the exact column name of db
                value: 'allocationdriver',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'events', // pass the exact column name of db
                value: 'events',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'VehicleStatus', // pass the exact column name of db
                value: 'Vehicle Status',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            }
        ],
        fleettype: 'vehicle',
        getTable: listDxVehicles,
        createTable: createDxVehicle, //Pass create table name
        updateTable: updateDxVehicle, //Pass update table name
        deleteTableItem: deleteDxVehicle //Pass update table name
    };

    const submitForm = async (data: any) => {
        let vehicleData = data;
        let contractData = {
            id: Math.random().toString(36).substring(2, 9),
            policyno: data.policyno,
            issuedate: data.issuedate,
            expirydate: data.expirydate,
            vehicleid: data.id,
            renewaltype: data.renewaltype,
            documents: data.documents,
            renewalstatus: data.renewalstatus,
            _version: data._version
        };
        let appointmentNeeded = vehicleData['isAppointmentNeeded'];
        if (vehicleData['expiring'] !== undefined) {
            let ExpiringValue = JSON.parse(vehicleData['expiring']);
            ExpiringValue[vehicleData['renewaltype']] = {
                issueDate: vehicleData['issuedate'],
                expiryDate: vehicleData['expirydate'],
                documents: vehicleData['documents'],
                status: vehicleData['renewalstatus']
            };
            vehicleData['expiring'] = JSON.stringify(ExpiringValue);
        } else {
            vehicleData['expiring'] = JSON.stringify({
                [vehicleData['renewaltype']]: {
                    issueDate: vehicleData['issuedate'],
                    expiryDate: vehicleData['expirydate'],
                    documents: vehicleData['documents'],
                    status: vehicleData['renewalstatus']
                }
            });
        }

        delete vehicleData['renewaltype'];
        delete vehicleData['isAppointmentNeeded'];
        delete vehicleData['renewalstatus'];
        delete vehicleData['issuedate'];
        delete vehicleData['expirydate'];
        delete vehicleData['documents'];

        if (Object.keys(vehicleData).includes('_lastChangedAt')) delete vehicleData['_lastChangedAt'];
        vehicleData['fleettype'] = 'vehicle';
        let os: any = await API.graphql(
            graphqlOperation(formColumns.updateTable, {
                input: vehicleData
            })
        );
        console.log('Updated in Vehicle');

        //update to vehicle#activity
        let filter = {
            id: {
                eq: data.id
            },
            fleettype: {
                eq: `vehicle#activity` // filter priority = 1
            }
        };
        let vehicleActivity: any = await API.graphql({ query: listDxVehicles, variables: { filter: filter } });
        if (vehicleActivity.data.listDxVehicles.items.length > 0) {
            const objData = Object.assign({}, ...vehicleActivity.data.listDxVehicles.items);
            vehicleData['fleettype'] = objData.fleettype;
            vehicleData['_version'] = objData._version;
            let os: any = await API.graphql(
                graphqlOperation(formColumns.updateTable, {
                    input: vehicleData
                })
            );
            console.log('Updated in Vehicle#activity');
        }
        let contract: any = await API.graphql(
            graphqlOperation(createDxContract, {
                input: contractData
            })
        );
        console.log('Created new file in Contract');
        // &form=add&vid=A12908&type=Service&ftype=vehicle%23activity
        let navigateUrl = appointmentNeeded === true ? '/general/appointments/createnewAppointmentform/edit?vid=' + VehicleID + '&type=' + expType + '&ftype=vehicle%23activity' : '/manage/servicecontracts';
        navigate(navigateUrl);
    };

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <ListnewForm
                        columns={formColumns}
                        Fleettype={formColumns.fleettype}
                        layout="grid"
                        getTableName="listDxVehicles"
                        title="Edit Contract - "
                        submitForm={submitForm}
                        isAppointmentNeeded={setAppointmentNeeded}
                        bucketname="RenewalDocuments"
                    />
                </div>
                <div className="col-12 md:col-6">
                    <SContractviewform />
                </div>
            </div>
        </>
    );
}

export default SContracteditform;
