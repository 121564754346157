import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createDxVehicleSpares, deleteDxVehicleSpares, updateDxVehicleSpares } from '../../graphql/mutations';
import { listDxDrivers, listDxVehicles, listDxVehicleSpares, listDxVendors } from '../../graphql/queries';
import ListnewForm from '../../Components/ListnewForm';
import { VehicleParts } from './VehicleParts';
import AmplifyServices from '../../Service/Service';
import Sparesviewform from './Viewform';

const Spareseditform = () => {
    const navigate = useNavigate();
    const [vehicleNoOptions, setVehicleNoOptions] = useState<any>([]);
    const [driverIdOptions, setDriverIdOptions] = useState<any>([]);
    const [supplierOptions, setSupplierOptions] = useState<any>([]);

    useEffect(() => {
        getData();
    }, []);

    //get the items from the tables for dropdown option
    const getData = async () => {
        let tables = [listDxVehicles, listDxDrivers, listDxVendors];
        let fetchResName = ['listDxVehicles', 'listDxDrivers', 'listDxVendors'];
        let VehicleNo: any = [];
        let DriverID: any = [];
        let Supplier: any = [];
        for (let i = 0; i < tables.length; i++) {
            await AmplifyServices.getTableDetails(tables[i], fetchResName[i]).then(async (res: any) => {
                if (fetchResName[i] === 'listDxVehicles') {
                    res.filter((del: any) => del._deleted !== true).map((res: any) => {
                        res['label'] = res.id;
                        res['value'] = res.id;
                        return VehicleNo.push(res);
                    });
                } else if (fetchResName[i] === 'listDxDrivers') {
                    res.filter((del: any) => del._deleted !== true).map((res: any) => {
                        res['label'] = res.name;
                        res['value'] = res.id;
                        return DriverID.push(res);
                    });
                } else if (fetchResName[i] === 'listDxVendors') {
                    res.filter((del: any) => del._deleted !== true).map((res: any) => {
                        res['label'] = res.vendorname;
                        res['value'] = res.vendorname;
                        return Supplier.push(res);
                    });
                }
            });
        }
        setVehicleNoOptions(VehicleNo);
        setDriverIdOptions(DriverID);
        setSupplierOptions(Supplier);
    };

    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                name: 'vehicleid', // pass the exact column name of db
                value: 'Vehicle No',
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: vehicleNoOptions
            },
            {
                name: 'driverid', // pass the exact column name of db
                value: 'Driver Name', //for display
                fieldtype: 'dropdown',
                required: true,
                disabled: false,
                hidden: false,
                data: driverIdOptions
            },
            {
                name: 'supplier', // pass the exact column name of db
                value: 'Supplier', //for display
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: supplierOptions //Fetch from other table
            },

            {
                name: 'partname', // pass the exact column name of db
                value: 'Part Name',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: VehicleParts
            },
            {
                name: 'quantity', // pass the exact column name of db
                value: 'Quantity',
                fieldtype: 'number',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'description', // pass the exact column name of db
                value: 'Description',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },

            {
                name: 'amount', // pass the exact column name of db
                value: 'Amount',
                fieldtype: 'number',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'totalamount', // pass the exact column name of db
                value: 'Total Amount',
                fieldtype: 'number',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'invoiceno', // pass the exact column name of db
                value: 'Invoice No',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'invoicedoc', // pass the exact column name of db
                value: 'Invoice Doc',
                fieldtype: 'fileupload',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'status', // pass the exact column name of db
                value: 'Status',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: [
                    { label: 'In Progress', value: 'In Progress' },
                    { label: 'Completed', value: 'Completed' }
                ]
            }
        ],
        getTable: listDxVehicleSpares,
        createTable: createDxVehicleSpares, //Pass create table name
        updateTable: updateDxVehicleSpares, //Pass update table name
        deleteTableItem: deleteDxVehicleSpares //Pass update table name
    };

    const submitForm = async (data: any) => {
        // console.log(props.columns);
        if (Object.keys(data).includes('_lastChangedAt')) delete data['_lastChangedAt'];

        let os = await API.graphql(
            graphqlOperation(formColumns.updateTable, {
                input: data
            })
        );
        let navigateUrl = '/manage/spares';
        navigate(navigateUrl);
    };

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <ListnewForm columns={formColumns} layout="horizontal" getTableName="listDxVehicleSpares" title="Edit Spares - " submitForm={submitForm} bucketname="SparesDocuments" />
                </div>
                <div className="col-12 md:col-6">
                    <Sparesviewform />
                </div>
            </div>
        </>
    );
};
export default Spareseditform;
