import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import './App.css';
import PrimeReact from 'primereact/api';
import { AuthModeStrategyType } from '@aws-amplify/datastore/lib-esm/types';

import { useLocation, useNavigate } from 'react-router-dom';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './App.scss';
import '../src/App.css';
import awsconfig from './aws-exports';
import { Amplify, Auth, DataStore } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { AuthContext } from './AuthContext';
import PageControl from './Pages/Placeholder/PageCotrol';
import AuthorizedControl from './Pages/Placeholder/AuthorizedControl';
import { useAuthContext } from './useAuthContext';
import dxService from './Services/dxService';
import { checkuserprofile } from './utils/userhelper';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// import TestDatatable from './Pages/TestDatatable';
export const RTLContext = React.createContext(false);

const [localRedirectSignIn, productionRedirectSignIn] = awsconfig.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, productionRedirectSignOut] = awsconfig.oauth.redirectSignOut.split(',');
console.log(isLocalhost);
console.log(localRedirectSignIn);
console.log(productionRedirectSignIn);
const updatedAwsConfig = {
    ...awsconfig,
    oauth: {
        ...awsconfig.oauth,
        redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
        redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut
    },
    DataStore: {
        authModeStrategyType: AuthModeStrategyType.DEFAULT
    }
};
console.log(updatedAwsConfig);

Amplify.configure(updatedAwsConfig);

function App() {
    const [menuMode, setMenuMode] = useState('static');

    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    const [isRTL, setRTL] = useState<boolean>(false);

    const [topbarTheme, setTopbarTheme] = useState('DeluxeHomes');

    const { dispatch } = useContext(AuthContext);
    const [user, setUser] = useState<any>(null);
    const [checkuserlogin, setCheckUserLogin] = useState<any>(true);
    const [userprofile, setuserprofile] = useState<any>(null);
    const [uname, setUname] = useState('');
    const [errorinfo, seterrorinfo] = useState('');
    const [name, setname] = useState('');
    const [token, settoken] = useState({});
    const navigate = useNavigate();
    const copyTooltipRef = useRef<any>();
    const location = useLocation();
    const userAuth = useAuthContext();
    PrimeReact.ripple = true;

    const clear = async () => {
        await DataStore.clear().then(async (resp) => {});
        console.info('Clear - DataStore');
    };
    const start = async () => {
        console.info('Start - DataStore');
    };

    useEffect(() => {
        setCheckUserLogin(true);
        Auth.currentAuthenticatedUser()
            .then(async (currentUser) => {
                let cuser = await checkuserprofile(currentUser);
                console.log(cuser);
                setuserprofile(cuser);
                //checkuserprofile(currentUser);
                console.log(cuser);
                console.log('Groups:', currentUser.signInUserSession.accessToken.payload['cognito:groups']);
                const token = currentUser.signInUserSession.accessToken.jwtToken;
                settoken(token);
                start();

                setUser(currentUser);
                dispatch({ type: 'LOGIN', payload: currentUser });
                setCheckUserLogin(false);
                checkuser();
            })
            .catch(() => {
                dispatch({ type: 'LOGOUT', payload: null });
                setCheckUserLogin(false);
                console.log('Not signed in');
            });

        Auth.currentUserInfo().then((res) => {
            if (res !== null) setname(res.attributes.preferred_username);
        });
    }, [uname == '']);

    // const checkuserprofile = async (currentUser: any) => {
    //     console.log(currentUser);
    //     let userprofile = await dxService.getitembyid('userprofile', currentUser.username);
    //     if (userprofile === null) {

    //         let newitemvalue = {
    //             id: currentUser.username,
    //             userid: currentUser.username,
    //             provideruserid: '',
    //             email: currentUser.attributes.email ? currentUser.attributes.email : '',
    //             picture: currentUser.attributes.picture ? currentUser.attributes.picture : '',
    //             displayname: currentUser.attributes.preferred_username ? currentUser.attributes.preferred_username : currentUser.username,
    //             sub: currentUser.attributes.sub ? currentUser.attributes.sub : ''
    //         };

    //         userprofile = await dxService.createitem('userprofile', newitemvalue);
    //         setuserprofile(userprofile);
    //     }else{
    //         setuserprofile(userprofile);
    //     }
    //     console.log(userprofile);
    // };

    const checkuser = async () => {
        const user = await Auth.currentAuthenticatedUser();
        console.log(user);
    };

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (menuMode === 'overlay') {
            hideOverlayMenu();
        }
        if (menuMode === 'static') {
            setDesktopMenuActive(true);
        }
    }, [menuMode]);

    useEffect(() => {
        const appLogoLink = document.getElementById('app-logo') as HTMLInputElement;

        if (appLogoLink)
            if (topbarTheme === 'white' || topbarTheme === 'yellow' || topbarTheme === 'amber' || topbarTheme === 'orange' || topbarTheme === 'lime') {
                appLogoLink.src = 'assets/layout/images/logo-dark.svg';
            } else {
                appLogoLink.src = 'assets/layout/images/logo-light.svg';
            }
    }, [topbarTheme]);

    const loginform = () => {
        seterrorinfo('');
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    };

    const userloginform = async (username: string, password: string) => {
        seterrorinfo('');

        try {
            const user = await Auth.signIn(username, password);
            console.log(user);
            setUser(user);
            setUname(user.username);
        } catch (error: any) {
            console.log('error signing in', error);
            console.log(error.message);
            seterrorinfo(error.message);
            setUname('');
        }
        return null;
        //Auth.signIn(username,password).then(user => console.log(user)).catch(err => console.log(err))
    };

    const changepassword = async (user: any, password: string, newpassword: string) => {
        seterrorinfo('');
        try {
            const { requiredAttributes } = user.challengeParam;

            const p = await Auth.completeNewPassword(
                user, // the Cognito User Object
                newpassword, // the new password
                // OPTIONAL, the required attributes
                requiredAttributes
            );
            console.log(p);
            setUser(p);
        } catch (error: any) {
            console.log('error signing in', error);
            seterrorinfo(error.message);
        }
        return null;
    };

    const hideOverlayMenu = () => {
        setMobileMenuActive(false);
        setDesktopMenuActive(false);
    };

    return (
        <div className="App">
            {checkuserlogin === false ? (
                <RTLContext.Provider value={isRTL}>{userAuth.user ? <PageControl user={user} /> : <AuthorizedControl login={loginform} userlogin={userloginform} user={user} message={errorinfo} changepassword={changepassword} />}</RTLContext.Provider>
            ) : (
                ''
            )}
        </div>
    );
}

export default App;
