import React, { useState, useEffect, useRef, Children, useContext } from 'react';
import { Amplify, API, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Toast } from 'primereact/toast';
import { AuthContext } from '../../../AuthContext';
import { CognitoIdentityProviderClient, AdminAddUserToGroupCommand, AdminRemoveUserFromGroupCommand, ListGroupsCommand, AdminListGroupsForUserCommand, AdminUpdateUserAttributesCommand } from '@aws-sdk/client-cognito-identity-provider';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../../dxgraphql/dxqueries';
import AWS from 'aws-sdk';
import awsExports from '../../../aws-exports';
import ListTemplate from '../../../Components/ListTemplate';
import dxService from '../../../Services/dxService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useNavigate } from 'react-router-dom';



const ManageGroups = () => {
    const { dispatch } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [appitem, setappitem] = useState<any>({});
    const [newdata, setnewdata] = useState<any>({});
    const navigate = useNavigate();

    const toast = useRef(null);
    const [uname, setUname] = useState('');
    const [name, setname] = useState('');
    const [groupitems, setgroupitems] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [selectedgroup, setselectedgroup] = useState<any>({});
    const [tableFilters, setTableFilters] = useState<any>('');
    const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);

    const actionmenu = useRef<any>(null);
    useEffect(() => {
        getdata();



    }, []);

    useEffect(() => {


    }, [appitem]);

    const columns = [
        { field: 'id', showfield: 'rolename', header: 'Group Name', fieldtype: 'href', path: '#/admin/erpusers?id=', filterOption: true, filterFieldOption: 'name' },
        { field: 'description', header: 'Description', fieldtype: 'text', filterOption: true, filterFieldOption: 'mobileno' },
        { field: '_lastChangedAt', header: 'Modified', fieldtype: 'date', filterOption: true, filterFieldOption: 'mobileno' }
    ];



    const changedata = (props: any) => {
        console.log(props);
    };

    const addData = (props: any) => {
        console.log(props);
    };

    const getdata = async () => {
        let groups = await dxService.listitems('roles');
        console.log(groups);
        setgroupitems(groups);

    }

    const deleteItem = async () => {
        //removeuserrole(awsExports.aws_user_pools_id);
        setDeleteItemsDialog(false);
    };

    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };


    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };


    const deleteItemsDialogFooter = (
        <>

            <Button label="Yes" icon="pi pi-check" className="p-button-text " onClick={() => deleteItem()} />
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemsDialog} />
        </>
    );

    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">

                <Dialog visible={deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {selectedgroup && <span>Are you sure you want to remove the selected grpup?</span>}
                    </div>
                </Dialog>

                <Button icon="pi pi-plus" label="Add Group" onClick={() => { navigate('/admin/permissions') }} className="p-button-primary mr-2  newbtnhover" />
                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => actionmenu.current.toggle(e)} />

                <Menu
                    ref={actionmenu}
                    popup
                    model={[
                        {
                            //disabled: selecteduser.length > 0 ? false : true,
                            label: 'Edit Group',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                if(selectedgroup.length > 0){
                                    navigate('/admin/permissions?id='+selectedgroup[0].id)
                                }
                             
                              
                            }
                        },
                        {
                            //disabled: selecteduser.length > 0 ? false : true,
                            label: 'Remove Group',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                // deleteItem();
                                console.log(selectedgroup);
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                />
            </div>

        </div>
    );



    return (

        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Groups</h4>

                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}

                            <ListTemplate
                                header="Manage Users"
                                value={groupitems}
                                paginator={true}
                                rows={10}
                                filters={tableFilters}
                                dynamicColumns={columns}
                                emptyMessage="No drivers found."
                                responsiveLayout="scroll"
                                className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Drivers"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[5, 10, 25]}
                                selection={selectedgroup}
                                multiselect={true}
                                //   selectedItems={selectedProducts}
                                Commandbar={Commandbar}
                                onSelectionChange={(e: any) => { console.log(e); setselectedgroup(e.value) }}
                                selectedItems={setselectedgroup}
                            />

                            {/* <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                <div className="surface-ground">
                                    <div className="grid">
                                        <div className="col-12 lg:col-4 p-3">

                                        </div>

                                    </div></div></div> */}


                            {/* END Inner COMPONENT HTML */}

                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}




            </div>
        </div>

    );
};
export default ManageGroups;

