import { API, Storage } from 'aws-amplify';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { DataView } from 'primereact/dataview';
import { DataViewLayoutOptions } from 'primereact/dataview';
import { Tag } from 'primereact/tag';
import { useEffect, useState } from 'react';
import { listDxAppointments, listDxDocuments } from '../../graphql/queries';
import excel from '../../assets/excel.jpg';
import Pdf from '../../assets/Pdf.jpg';
import ppt from '../../assets/ppt.jpg';
import word from '../../assets/word.jpg';
import rtf from '../../assets/rtf.jpg';
import File from '../../assets/File.png';
import dxService from '../../Services/dxService';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
const imageExtensions = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp', 'bmp', 'ico', 'cur'];
let sparesID: any;
let VID: any;
const Appointmentviewform = () => {
    const location = window.location.href;
    const urlParams = new URLSearchParams(location.split('?')[1]);
    const [displayDocuments, setDisplayDocuments] = useState<any>([]);
    const [documentView, setdocumentView] = useState<any>(location.includes('basic') ? 'List' : 'Grid');
    const [icon, setIcon] = useState('pi pi-times');
    sparesID = urlParams.has('id') ? urlParams.get('id') : urlParams.has('sid') ? urlParams.get('sid') : '';
    VID = urlParams.has('vid') ? urlParams.get('vid') : '';
    const formmode = urlParams.has('mode') ? urlParams.get('mode') : '';
    //#region OnLoad methods
    // Calls method during the component load
    useEffect(() => {
        getDocumentDetails();
    }, []);

    const getDocumentDetails = async (nextToken?: any) => {
        const filterItem: any = {
            resourceid: {
                eq: sparesID
            },
            presource: {
                eq: 'Appointment'
            }
        };

        const fitlerbyVehicle: any = {
            presourceid: {
                eq: VID
            },
            resourceid: {
                eq: sparesID
            },
            presource: {
                eq: 'Appointment'
            }
        };

        const variables = VID !== null ? fitlerbyVehicle : filterItem;

        try {
            const response: any = await dxService.listitems('document', '*', variables, false, 1000, nextToken);

            let data = response.items;
            const nextTokenResponse = response.nextToken;

            if (nextTokenResponse) {
                const nextResults = await getDocumentDetails(nextTokenResponse);
                data = data.concat(nextResults);
            }
            data = data.filter((item: any) => item._deleted !== true);

            getFileLocation(data);
        } catch (error) {
            console.error(error);
        }
    };

    const getFileLocation = (e: any) => {
        let FinalData: any = [];
        e.map(async (item: any) => {
            let url = await Storage.get(item.name);

            if (url) {
                FinalData.push({
                    id: item.id,
                    resourceid: item.resourceid,
                    docurl: item.name,
                    name: item.name.split('/').pop().split(';')[0],
                    documentsdoc: url,
                    invoiceno: item.field2 ? item.field2 : '-',
                    type: item.field1,
                    _version: item._version
                });
            }

            setDisplayDocuments((olddata: any) => [...olddata, ...FinalData]);
        });
    };
    const AttachmentBodyTemplate = (e: any) => {
        return (
            <a href={e.documentsdoc} target="_blank" className="">
                {e.name}
                {/* <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i> */}
            </a>
        );
    };
    function downloadFile(url: any) {
        const link = document.createElement('a');
        link.href = url.documentsdoc;
        link.setAttribute('download', url.name);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    //getting the appointment item to update while clicking remove icon
    const deletefromAppointment = async (e: any, nextToken?: any) => {
        let appointmentdocuments = {
            id: {
                eq: e.resourceid
            }
        };
        //remove the item from dynamo db - appointment
        await dxService.listitems('appointment', '*', appointmentdocuments, true, 300, nextToken).then(async (res) => {
            let response = res.items;

            if (response.length > 0) {
                let obj: any = {
                    id: e.resourceid
                };
                //update the document
                if (e.type === 'documents') {
                    const documentsArr = response[0].documents.split(';');
                    const nameToRemove = e.docurl; // name of document to remove
                    const updatedDocuments = documentsArr.filter((doc: any) => !doc.includes(nameToRemove)).join(';');
                    obj.documents = JSON.stringify({ Documents: updatedDocuments });
                    obj._version = response[0]._version;
                }
                if (e.type === 'pictures') {
                    const documentsArr = response[0].pictures.split(';');
                    const nameToRemove = e.docurl; // name of document to remove
                    const updatedPictures = documentsArr.filter((doc: any) => !doc.includes(nameToRemove)).join(';');
                    obj.pictures = JSON.stringify({ Pictures: updatedPictures });
                    obj._version = response[0]._version;
                }
                await dxService.updateitem('appointment', obj).then((res) => {
                    //calling getDocuments after deleting everything
                    // setIcon('pi pi-times');
                    const newArray = displayDocuments.filter((item: any) => item.id !== e.id);
                    setDisplayDocuments(newArray);
                    // getDocumentDetails();
                });
                return '';
            }

            let nexttoken = res.nextToken;
            await deletefromAppointment(e, nexttoken);
        });
    };
    const removeFile = async (e: any) => {
        // setIcon('pi pi-spin pi-spinner');
        //remove file from s3 bucket
        await Storage.remove(e.docurl)
            .then(async (result) => {
                //remove the items from dynamo db - documents table
                await dxService.deleteitem('document', e.id, e._version).then(async (res) => {
                    //get the appointment item and update the respective fields
                    deletefromAppointment(e);
                });
            })
            .catch((err) => {
                console.error('Error deleting file: ', err);
            });
    };
    const invoiceNoBodyTemplate = (e: any) => {
        //return <span className="">{e.invoiceno}</span>;
        return (
            <>
                <Link to={`/general/viewdocument?id=${e.id}&vid=${VID}&appid=${sparesID}&parent=appointment`}>
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-text"></Button>
                </Link>
                <Button icon="pi pi-download" className="p-button-rounded p-button-text" onClick={() => downloadFile(e)}></Button>
                {formmode === 'close' && <Button id="btnremovefn" icon={icon} className="p-button-rounded p-button-text" onClick={() => removeFile(e)}></Button>}
            </>
        );
    };
    const typeTemplate = (e: any) => {
        let typevalue = 'pi ';
        typevalue += e.type === 'pictures' ? 'pi-image' : 'pi-file';
        return <i className={typevalue} style={{ fontSize: '1.5rem' }}></i>;
    };

    const dvitemTemplate = (item: any) => {
        let extension = item.docurl.split('.').pop();
        let imagesrc = imageExtensions.includes(extension)
            ? item.documentsdoc
            : ['pdf'].includes(extension)
            ? Pdf
            : ['xlsx', 'xls', 'csv'].includes(extension)
            ? excel
            : ['rtf'].includes(extension)
            ? rtf
            : ['pptx'].includes(extension)
            ? ppt
            : ['docx'].includes(extension)
            ? word
            : File;

        return (
            <>
                <div className="col-12 sm:col-4 lg:col-4 xl:col-3 p-2">
                    <div className="p-4 border-1 surface-border surface-card border-round">
                        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                            {imageExtensions.includes(extension) && (
                                <Link to={`/general/viewdocument?id=${item.id}&vid=${VID}&appid=${sparesID}&parent=appointment`}>
                                    <i className="pi pi-eye" style={{ marginLeft: '7px', color: 'blue' }}></i>
                                </Link>
                            )}
                            <a href={item.documentsdoc} target="_blank" className="text-right">
                                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
                            </a>
                        </div>
                        <div className="flex flex-column align-items-center gap-3 py-5">
                            <img className="w-9  border-round" src={imagesrc} style={{ height: '150px', objectFit: 'cover' }} />
                            <div className="text-l font-bold DocTitle" title={item.name}>
                                {item.name}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const uniqueItems = displayDocuments.reduce((acc: { [x: string]: any }, cur: { id: string | number }) => ((acc[cur.id] = acc[cur.id] || cur), acc), {});
    const result = Object.values(uniqueItems);
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <div className="card flex flex-wrap align-items-center justify-content-between gap-2">
                    <h5 className="container-header">View Documents</h5>
                    {!location.includes('basic') && (
                        <div>
                            <Dropdown
                                value={documentView}
                                onChange={(e) => setdocumentView(e.value)}
                                options={[
                                    { label: 'List', value: 'List' },
                                    { label: 'Grid', value: 'Grid' }
                                ]}
                                optionLabel="label"
                                placeholder="Select a View"
                            />
                        </div>
                    )}
                </div>
                {documentView === 'Grid' ? (
                    <DataView value={result} itemTemplate={dvitemTemplate} />
                ) : (
                    <DataTable
                        value={result}
                        dataKey="id"
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} contracts"
                        emptyMessage="No Documents added."
                        responsiveLayout="scroll"
                    >
                        <Column field="type" header="Type" body={typeTemplate} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="documentsdoc" header="Document" body={AttachmentBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }}></Column>
                        <Column field="" header="Action" body={invoiceNoBodyTemplate} headerStyle={{ width: '20%', minWidth: '5rem' }}></Column>
                    </DataTable>
                )}
            </div>
        </div>
    );
};
export default Appointmentviewform;
