
import { API } from "aws-amplify";
import moment from "moment";
import { Button } from "primereact/button";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { transform } from "typescript";
import FormTemplate from "../../Components/FormTemplate";
import ViewTemplate from "../../Components/ViewTemplate";
import { _listDxApprovals } from "../../dxgraphql/dxqueries";
import { createDxApprovals, updateDxAppointments, updateDxApprovals } from "../../graphql/mutations";
import { listDxAppointments, listDxApprovals } from "../../graphql/queries";
import dxService from "../../Services/dxService";
import { useAuthContext } from "../../useAuthContext";
import { convertdate, getcurrenruserprofile, getuserinfo, getuserinfoprofile } from "../../utils/helper";
import AppointmentExpense from "../Appointments/AppointmentExpense";

const WFApprove = () => {
    const toast = useRef<any>(null);
    const userAuth = useAuthContext();
    const [listFormData, setlistFormData] = useState<any>({});
    const [newdata, setnewdata] = useState<any>({});
    const [currentapprovalitem, setcurrentapprovalitem] = useState<any>({});
    const [currentvaitem, setcurrentvaitem] = useState<any>({});
    const [currentlevelaction, setcurrentlevelaction] = useState<any>({});
    const navigate = useNavigate();
    const [currentresourceitem, setcurrentresourceitem] = useState<any>({});
    const [currentstep, setcurrentstep] = useState<any>({});
    const [currentaction, setcurrentaction] = useState<any>({});
    const [allsteps, setallsteps] = useState<any>({});
    const [resvalues, setresvalues] = useState<any>({});
    const [restitle, setrestitle] = useState<any>('');


    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;

    const viewcolumns1: any = {
        id: {field:'Id',type:'text'},
        vehicleid: {field:'Vehicle Id',type:'text'},
        eventtype: {field:'Appointmet Type',type:'text'},
        description: {field:'Description',type:'text'},
        Date: {field:'Requested Date',type:'date'},
        place: {field:'Place',type:'text'},
        appointmentstatus: {field:'Status',type:'text'},
        cost: {field:'Expense Cost',type:'currency'},
        invoiceno: {field:'Invoice No',type:'text'},
        mileage: {field:'Mileage',type:'text'},
        owner: {field:'Requested by',type:'text'}
    }
    const formcolumns = {
        columns: [
            {
                title: 'Pending Approval',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },
            {
                name: 'title',
                title: 'Title',
                fieldtype: 'text',
                required: true,
                value: 'aaa',
                hidden: false,
                disabled: true,
                data: [],
                class: 'md:col-6'

            },
            {
                name: 'break2',
                title: '',
                fieldtype: 'break',
                class: 'md:col-12'
            },

            // {
            //     name: 'description',
            //     title: 'Description',
            //     fieldtype: 'textarea',
            //     required: false,
            //     value: '',
            //     hidden: false,
            //     disabled: true,
            //     data: [],
            //     class: 'md:col-12'
            // }, 
            {
                name: 'afield5',
                title: 'Comments',
                fieldtype: 'textarea',
                required: false,
                value: '',
                hidden: false,
                disabled: currentapprovalitem.afield4 === "Completed" ? true : false,
                data: [],
                class: 'md:col-12'
            }
        ],
        actions: [
            {
                title: 'Approve'
            },
            {
                title: 'Reject'
            }
        ]
    }

    const viewdata = [
        {
            platenumber: 'a'
        },
        {
            platenumber: 'b'
        },
        {
            platenumber: 'c'
        }
    ];

    const viewcolumns = {
        columns: [
            {
                name: 'platenumber',
                title: 'Plate Number',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'model',
                title: 'MOdel Name',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'requestor',
                title: 'Requestor Name',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'priority',
                title: 'Priority',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'startdate',
                title: 'Pending for',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'approvaltype',
                title: 'Approval Type',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'description',
                title: 'Description',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
            {
                name: 'price',
                title: 'Price',
                fieldtype: 'text',
                value: 'aaa',
                hidden: false,
                disabled: true,
                class: 'md:col-6'
            },
        ]
    }

    const changedata = (props: any) => {
        console.log(props);
        setnewdata(props);
    };

    const addData = (props: any) => {
        console.log(props);
        console.log(newdata);
        console.log(currentaction);

    };
    useEffect(() => {
        getitems();
    }, []);

    const getprofilevalue = async (data: any, indexitem: any, id: any) => {

        let a = await dxService.getitembyid('userprofile', id);
        let dvalue = a.displayname
        data[indexitem] = dvalue;
        console.log(data);
        setcurrentresourceitem(data);
        console.log(dvalue);
        // return dvalue;




    }

    const getitems = async () => {
        if (formid != null) {
            //Get Current Approval Item
            let _currentitem = await getcurrentapprovalitem(formid);
            console.log(_currentitem);
            setcurrentapprovalitem(_currentitem);
            let _a = {
                title: _currentitem.title,
                description: _currentitem.description,
                afield5: _currentitem.afield5
            }
            setlistFormData(_a);


            //Get Resource Item
            let _resurl = _currentitem.resourceurl;
            let _resid = _currentitem.resourceid;

            console.log(_resurl);
            console.log(_resid);
            let cresitem = await getcurrentresourceitem(_resurl, _resid);
            setrestitle(cresitem.eventtype);
            console.log(cresitem);
            setcurrentresourceitem(cresitem);

            let vaitem = await dxService.getitembyid('vehicleactivity', cresitem.vehicleid);
            setcurrentvaitem(vaitem);

            //Setting up Resource item value to View Template
            let reskeys = cresitem && Object.keys(cresitem);
            console.log(reskeys);
            let rescolumns: any = [];
            if (reskeys && reskeys.length > 0)
                reskeys.map((ele: any) => {
                    if (viewcolumns1[ele] && cresitem[ele]) {
                        let a: any = {
                            name: viewcolumns1[ele].field,
                            title: viewcolumns1[ele].field,
                            fieldtype: viewcolumns1[ele].type,
                            //value: ele === 'owner' ? await getprofilevalue(cresitem[ele]) : cresitem[ele],
                            value: cresitem && cresitem[ele],
                            hidden: false,
                            disabled: true,
                            class: 'md:col-6'
                        };
                        rescolumns.push(a);
                    }
                });
            console.log(rescolumns);
            setresvalues({ columns: rescolumns });

            //Get All Steps
            let _wf = _currentitem.workflow;
            console.log(_currentitem);
            let steps = getallsteps(_wf);


            //Get Current Step
            let _approvallevel = _currentitem.level;
            let _currentstep = getwfstep(_approvallevel, steps);
            let _actions = JSON.parse(JSON.parse(_currentstep.acsjson));
            console.log(_actions);
            setcurrentlevelaction(_actions);


        }
    }

    //Get Approval Item
    ///**
    //  * 
    //  * @param fid 
    //  * @returns 
    //  */
    const getcurrentapprovalitem = async (fid: string) => {
        let approvalitem: any = await dxService.getitembyid('approval', fid, '*', true);
        console.log(approvalitem);
        if (approvalitem != null) {
            return approvalitem
        }
        return null;

        // const variables: any = {
        //     filter: {
        //         id: {
        //             eq: fid
        //         }
        //     }
        // };
        // let approvalitems: any = await API.graphql({
        //     query: _listDxApprovals, authMode: 'AMAZON_COGNITO_USER_POOLS',
        //     variables: variables
        // });
        // if (approvalitems.data.listDxApprovals.items.length > 0) {
        //     let currentitem = approvalitems.data.listDxApprovals.items[0];
        //     return currentitem;
        // }

    }

    //Get Resource Item
    ///**
    //  * 
    //  * @param source 
    //  * @param id 
    //  * @returns 
    //  */
    const getcurrentresourceitem = async (source: string, id: string) => {

        if (source === "dxAppointments") {

            let columns = `id
            vehicleid
            eventtype
            Date
            place
            description
            drivername
            appointmentstatus
            wanttoallocatedriver
            closeallocatedriver
            issccovered
            pictures
            documents
            cost
            invoiceno
            mileage
            auditby
            accidenthappenedat
            accidenttype
            wfstatus
            author
            editor
            createdAt
            updatedAt
            owner
            _version`;
            let item = await dxService.getitembyid('appointment', id, columns);
            let a = item;
            let b = await dxService.getitembyid('userprofile', a['owner']);
            a['owner'] = b.displayname;
            return a;



            // const variables: any = {
            //     filter: {
            //         id: {
            //             eq: id
            //         }
            //     }
            // };

            // let resourceitem: any = await API.graphql({
            //     query: listDxAppointments, authMode: 'AMAZON_COGNITO_USER_POOLS',
            //     variables: variables
            // });

            // if (resourceitem.data.listDxAppointments.items)
            //     return resourceitem.data.listDxAppointments.items[0];

            // return null;
        }

    }

    //Get All Steps by Workflow
    const getallsteps = (wf: any) => {

        console.log(wf);
        //IDENTIFY STEPS
        let steps = wf.approvalconfig.steps.items;
        return steps;
    }

    //Get current step for the approval item
    const getwfstep = (wflevel: any, steps: any) => {

        let _wfcurrentlevel = wflevel;

        //Get First Step
        let d = steps.filter((ele: any) => {
            return ele.name == _wfcurrentlevel
        });
        console.log(d);

        if (d.length > 0)
            return d[0];

        return null;


    }

    //Get Action properties based om Button event
    const getselectedbuttonaction = (p: any) => {
        let a = currentlevelaction.actions.filter((e: any) => {
            let condition = e.condition;
            switch (condition) {
                case "eq":
                    return e.value === p
                case "contains":
                    return e.value.contains(p)
            }

        });
        console.log(a);
        if (a.length > 0)
            return a[0]

        return null;
    }

    //Update Resource Item
    const updateResoueceItem = async (table: any, data: any) => {
        let queryname = null;
        if (table == "appointments") {
            queryname = updateDxAppointments;
        } else if (table == "approvals") {
            queryname = updateDxApprovals
        }

        if (queryname) {
            // Update Resouece Item
            let _updatedvalue = await API.graphql({
                query: queryname,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: data
                }
            });
            console.log(_updatedvalue);
            return _updatedvalue;
        } else
            return null;

    }

    //On Button Submission
    const addnewData = async (props: any) => {
        console.log(props);
        console.log(newdata);
        console.log(currentapprovalitem);
        console.log(currentresourceitem);

        // Identify Selected Button Action from Actions
        let _b = getselectedbuttonaction(props)

        //Get Next Step
        console.log(currentlevelaction);
        let nextstep = _b.nextstep;
        console.log(nextstep);

        //Get Resource Update Values
        let _resupdatevales = _b.resourceupdates;
        console.log(_resupdatevales);


        //Update Resouece Item
        //Generate Object for Resource Item Update
        let rdata: any = {
            id: currentresourceitem.id,
            _version: currentresourceitem._version
            //duedate: ''
        };
        _resupdatevales.forEach((ele: any) => {
            rdata[ele.name] = ele.value;
        });
        console.log(rdata);

        let _updateresitem = await updateResoueceItem("appointments", rdata);
        console.log(_updateresitem);

        let vehicleactivityid = currentresourceitem.vehicleid;

        if (currentvaitem !== null && props === "Approved") {


            let repaircost = currentvaitem.events == null ? 0 : JSON.parse(currentvaitem.events).Repair == null ? 0 : JSON.parse(currentvaitem.events).Repair.cost == null ? 0 : JSON.parse(currentvaitem.events).Repair.cost;


            repaircost = repaircost + parseInt(currentresourceitem.cost);


            let vaevents: any = { ...currentvaitem['events'] };
            if (currentvaitem.events != null) {
                vaevents = JSON.parse(currentvaitem.events);
                vaevents['Repair']['cost'] = repaircost;
            }
            let a = {
                id: currentvaitem.id,
                events: JSON.stringify(vaevents),
                _version: currentvaitem._version
            }
            let _updatevehiceactivity = await dxService.updateitem('vehicleactivity', a);
            console.log(_updatevehiceactivity);
        }



        console.log(userAuth.user);
        // let currentuser = await getcurrenruserprofile();
        // await getuserinfoprofile(userAuth.user.username);
        let currentuser = await getuserinfoprofile(userAuth.user.username);



        // Update Current Approval Item
        let _adata: any = {
            id: currentapprovalitem.id,
            afield5: newdata.afield5,
            afield4: "Completed",
            afield3: currentuser.userid,
            status: props,
            enddate: new Date(),
            _version: currentapprovalitem._version
            //duedate: ''
        }
        let uservalues = currentapprovalitem.userinfo ? JSON.parse(currentapprovalitem.userinfo) : {};
        uservalues['afield3'] = currentuser;

        _adata['userinfo'] = JSON.stringify(uservalues);
        // _adata['userinfo'] = JSON.stringify({
        //     afield3: currentuser
        // });

        let _updateapprovalitem = await updateResoueceItem("approvals", _adata);
        console.log(_updateapprovalitem);


        if (nextstep) {
            if (nextstep != "" && nextstep != "STOP") {
                //Get Next Step
            } else {
                window.history.back();
            }
        } else {
            console.log("Workflow Completed");
            //navigate('/dashboard');
            window.history.back();
        }


    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                {/* <h4 className="container-header">Approval Form</h4> */}

                <div className="card ">

                    <div className="surface-section">


                        <ul className="list-none p-0 m-0 flex flex-column md:flex-row">
                            <li className="relative mr-0 md:mr-8 flex-auto">
                                <div className={currentresourceitem && currentresourceitem.appointmentstatus === 'Scheduled' ? 'border-2 border-blue-500  surface-border surface-card  border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1  surface-border surface-card  border-round p-3 flex flex-column md:flex-row align-items-center z-1'}>
                                    {currentresourceitem && currentresourceitem.appointmentstatus === 'Pending' || currentresourceitem.appointmentstatus === 'Completed' ?
                                        <i className="pi pi-check-circle text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                                        : ''
                                    }

                                    <div>
                                        <div className="text-900 font-medium mb-1">Scheduled</div>
                                        <span className="text-600 text-sm hidden md:block">{
                                            'Requested on ' + convertdate(currentapprovalitem.createdAt)


                                        }</span>
                                    </div>
                                    <div className="w-full absolute top-50 left-100 surface-300 hidden md:block" style={{ transform: 'translateY(-50%)', height: '2px' }} ></div>
                                </div>

                            </li>
                            <li className="relative mr-0 md:mr-8 flex-auto">
                                <div className={currentresourceitem && currentresourceitem.appointmentstatus === 'Pending' ? 'border-2 border-blue-500  surface-border surface-card  border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1  surface-border surface-card  border-round p-3 flex flex-column md:flex-row align-items-center z-1'}>
                                    {currentresourceitem && currentresourceitem.appointmentstatus === 'Completed' ?
                                        <i className="pi pi-check-circle text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                                        : ''
                                    }
                                    <div>
                                        <div className="text-900 font-medium mb-1">
                                            Pending
                                        </div>
                                        <span className="text-600 text-sm hidden md:block">{'Assigned To: ' +
                                            getuserinfo(currentapprovalitem, 'userinfo', 'assignedto', 'displayname')
                                        }</span>
                                    </div>
                                    <div className="w-full absolute top-50 left-100 surface-300 hidden md:block" style={{ transform: 'translateY(-50%)', height: '2px' }} ></div>
                                </div>

                            </li>
                            <li className="relative  flex-auto">

                                <div className={currentresourceitem && currentresourceitem.appointmentstatus === 'Completed' ? 'border-2 border-blue-500  surface-border surface-card  border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1  surface-border surface-card  border-round p-3 flex flex-column md:flex-row align-items-center z-1'}>
                                    {currentresourceitem && currentresourceitem.appointmentstatus === 'Completed' ?
                                        <i className="pi pi-check-circle text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                                        : ''
                                    }
                                    <div>
                                        <div className="text-900 font-medium mb-1">Completed</div>
                                        <span className="text-600 text-sm hidden md:block">
                                            {currentapprovalitem.status + ' by ' +
                                                getuserinfo(currentapprovalitem, 'userinfo', 'afield3', 'displayname')

                                                // currentapprovalitem.userinfo != null && currentapprovalitem.userinfo != undefined ? JSON.parse(currentapprovalitem.userinfo)['afield3'].displayname : ''
                                            }
                                        </span>
                                    </div>

                                    {/* style="transform: translateY(-50%); height: 2px;"> */}



                                </div>

                            </li>
                        </ul>
                    </div></div>


                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card container-card">
                            <Toast ref={toast} />
                            <ViewTemplate
                                title={restitle + " Approval Form"}
                                description="Approval required for the item"
                                columns={resvalues} />

                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="card container-card">
                            <Toast ref={toast} />

                         {currentresourceitem.id ? 
                            <AppointmentExpense appid={currentresourceitem.id} />
                            : ''}

                            <FormTemplate
                                id="newform"
                                columns={formcolumns}
                                data={listFormData}
                                showaction={false}
                                mode={formmode}
                                onChange={changedata}
                                ChangeBtn={'OpenDocument'}
                                layout="grid2"
                                title="New Approval"
                                submitForm={addData}
                            />

                            <div className="spd-card-footer">
                                <Button disabled={currentapprovalitem.afield4 !== 'Completed' ? false : true} onClick={() => { addnewData('Approved'); }} className="mr-2">Approve

                                </Button>
                                <Button disabled={currentapprovalitem.afield4 !== 'Completed' ? false : true} onClick={() => { addnewData('Rejected'); }} className="p-button-danger">
                                    Reject
                                </Button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WFApprove;