import { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useNavigate } from 'react-router-dom';
import './approvals.css';
import { Amplify, API } from 'aws-amplify';
import awsconfig from '../../aws-exports.js';
import AmplifyServices from '../../Service/Service';
import moment from 'moment';
import ListTable from '../../Components/ListTable';
import { Menu } from 'primereact/menu';
import dxService from '../../Services/dxService';


var EditNavigation: any = '';

const ManageApprovalSteps = (props: any) => {
    const toast = useRef<any>(null);
    const actionmenu = useRef<any>(null);
    const [approvaldata, setapprovaldata] = useState<any>([]);
    const [selecteditem, setselecteditem] = useState<any>(null);
    const [Tablefilter, setTableFilters] = useState<any>('');
    const [selectedItems, setselectedItems] = useState<any>([]);
    const [approvalitems, setapprovalitems] = useState<any>([]);
    const [FilterData, setFilterData] = useState<any>('');
    const [deleteItemDialog, setdeleteItemDialog] = useState<boolean>(false);
    const navigate = useNavigate();

    const newNavigateClick = () => navigate('/manage/approvals/item/basic?mode=new');
    EditNavigation = selectedItems != undefined && selectedItems.slice(-1);

    const stepcolumns = [
        // { field: 'id', header: 'ID', fieldtype: 'text', filterOption: false, filterFieldOption: 'id' },
        { field: 'approval', header: 'Approval', fieldtype: 'lookup', filterOption: false, filterFieldOption: 'id' },
        { field: 'name', header: 'Name', fieldtype: 'text', filterOption: false, filterFieldOption: 'title' },
        { field: 'title', header: 'Title', fieldtype: 'text', filterOption: false, filterFieldOption: 'title' },
        { field: 'field1', header: 'Action type', fieldtype: 'text', filterOption: false, filterFieldOption: 'title' },
        { field: 'field2', header: 'Next Step', fieldtype: 'text', filterOption: false, filterFieldOption: 'title' },
        { field: 'field3', header: 'Assigned To', fieldtype: 'text', filterOption: false, filterFieldOption: 'title' },
        { field: 'field4', header: 'Description', fieldtype: 'text', filterOption: false, filterFieldOption: 'title' },
        { field: 'acsjson', header: 'acsjson', fieldtype: 'text', filterOption: false, filterFieldOption: 'title' },
        { field: 'id', header: 'Link', fieldtype: 'href', path: '#/manage/wfconfig/approve?id=', filterOption: false, filterFieldOption: 'id' },
        //{ field: 'status', header: 'Status', fieldtype: 'link', filterOption: false, filterFieldOption: 'status' }
        // { field: 'status', header: 'Status', fieldtype: 'custom', filterOption: true, filterFieldOption: 'status', template: (r: any) => <>{contractStatusBodyTemplate(r)}</> }
    ];


    useEffect(() => {
        console.log(selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        getdata();

    }, []);


    const getdata = async () => {

        let steps = await dxService.listitems('approvalconfigsteps', '*', {}, true);
        console.log(steps);
        steps.sort((a:any,b:any) =>{
            if(a.approval != null && b.approval != null){
            console.log(a.approval);
            let _a= a.approval.name.toLowerCase();
            let _b = b.approval.name.toLowerCase();
            if(_a < _b) return -1;
            if(_a > _b) return 1;
            return 0;
            }else
            return -1;

        })
       
        setapprovalitems(steps);
        // await AmplifyServices.getapprovals()
        //     .then(async (res: any) => {
        //         // setapprovaldata(res);
        //         setapprovalitems(res);
        //     })
        //     .catch((err: any) => console.log(err));
    };
    const confirmDeleteSelected = () => {
        // console.log(selectedProducts);

        setdeleteItemDialog(true);
    };
    const SearchOnchange = (e: any) => {
        setFilterData(e.target.value);
    };
    const clearbtn = () => {
        setFilterData('');
    };
    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New" onClick={newNavigateClick} className="p-button-primary mr-2 mb-2 newbtnhover" />

                <Button type="button" label="Actions" icon="pi pi-angle-down" className={'mb-2'} onClick={(e) => actionmenu.current.toggle(e)} />
                <Menu
                    ref={actionmenu}
                    popup
                    model={[
                        {
                            disabled: selectedItems != undefined && selectedItems.length == 1 ? false : true,
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                navigate(`/manage/vehicles/item/basic?mode=edit&id=${selectedItems != undefined && EditNavigation[0].id}&fleettype=vehicle`);
                            }
                        },
                        {
                            disabled: selectedItems != undefined && selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                ></Menu>
            </div>
            <div>

                <input value={FilterData} type="text" id="search" onChange={SearchOnchange} placeholder="Search ..." className="p-inputtext p-component homeNews__search" />
                {FilterData.length > 0 ? (
                    <button type="button" className="contentBox__searchClearBtn" onClick={clearbtn}>
                        X
                    </button>
                ) : (
                    ''
                )}
            </div>
        </div>
    );


    return (
        <div className='grid'>
            <div className='col-12 md:col-12'>
                <h4 className='container-header'>Manage Approvals</h4>

                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast} />

                            <ListTable
                                header="Manage Approval Steps"
                                value={approvalitems}
                                paginator={false}
                                rows={10}
                                dynamicColumns={stepcolumns}
                                emptyMessage="No approvals found."
                                responsiveLayout="scroll"
                                className="p-datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Service Contracts"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[5, 10, 25]}
                                //   selection={selectedProducts}
                                multiselect={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ManageApprovalSteps;
