import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { useContext, useEffect, useRef, useState } from "react";
import dxService from "../../../Services/dxService";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { localetoisodatetime } from "../../../utils/helper";
import { classNames } from "primereact/utils";
import moment from "moment";
import { RTLContext } from "../../Placeholder/PageCotrol";
import DriverDashboardDocs from "../../DashBoard/DriverView/DriverDocument/DriverDashboardDocuments";
import VehicleDocuments from "./VehicleDocuments";
import { getTypeColor, getTypeColors } from "../../../utils/color";
// const color = getTypeColors('dark');
let storevalue: any;
let storekey: any;

let StoreSalikValue: any;
var SalikArray: any = [];
var VipFuelArray: any = [];
var ParkingArray: any = [];

let StoreParkingArray: any;
let StoreVipFuelValue: any;
let StoreRepairValue: any;
var ReapirArray: any = [];
var FineArray: any = [];
let StoreFineValue: any;
var today = new Date();

var targetDate = new Date(today.getTime());
targetDate.setMonth(targetDate.getMonth() - 4);
// console.log(targetDate);
let lastenddate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
let lastfirstdate = new Date(today.getFullYear(), today.getMonth() - 4, 1);
// console.log(lastfirstdate, lastenddate);

const getChartData = async (colorMode?: string) => {
  const color = await getTypeColor("dark");
  const currentMonth = new Date().getMonth();
  const pastMonthNames = [];

  // Adjust the loop to correctly fetch the previous five months
  for (let i = 0; i < 5; i++) {
    // Loop 5 times to get the last 5 months including the current month
    const monthIndex = (currentMonth - i + 12) % 12;
    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(new Date(0, monthIndex));
    pastMonthNames.push(monthName);
  }

  return {
    labels: pastMonthNames.reverse(),
    datasets: [
      {
        label: "Salik",
        data: SalikArray,
        // borderColor: red,
        // backgroundColor: red,
        borderColor: color.Salik,
        backgroundColor: color.Salik,
        borderWidth: 2,
        fill: true,
      },
      {
        label: "Vip Fuel",
        data: VipFuelArray,
        // borderColor: yellowColor,
        // backgroundColor: yellowColor,
        borderColor: color.Vipfuel,
        backgroundColor: color.Vipfuel,
        borderWidth: 2,
        fill: true,
      },
      {
        label: "Fine",
        data: FineArray,
        // borderColor: cyanColor,
        // backgroundColor: cyanColor,
        borderColor: color.Fine,
        backgroundColor: color.Fine,
        borderWidth: 2,
        fill: true,
      },

      {
        label: "Repair",
        data: ReapirArray,
        // borderColor: tealColor,
        // backgroundColor: tealColor,
        borderColor: color.Repair,
        backgroundColor: color.Repair,
        borderWidth: 2,
        fill: true,
      },
      {
        label: "Parking",
        data: ParkingArray,
        borderColor: color.Parking,
        backgroundColor: color.Parking,
        borderWidth: 2,
        fill: true,
      },
    ],
  };
};
const getChartOptions = () => {
  const textColor =
    getComputedStyle(document.body).getPropertyValue("--text-color") ||
    "rgba(0, 0, 0, 0.87)";
  const gridLinesColor =
    getComputedStyle(document.body).getPropertyValue("--divider-color") ||
    "rgba(160, 167, 181, .3)";
  const fontFamily = getComputedStyle(document.body).getPropertyValue(
    "--font-family"
  );
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: true,
        labels: {
          fontFamily,
          color: textColor,
        },
      },
      datalabels: {
        display: true,
        color: "black",
        anchor: "end",
        clamp: true,
        // align: 'end',
        // offset: -20,

        font: {
          size: "12",
          weight: "700",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          fontFamily,
          color: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
      y: {
        ticks: {
          fontFamily,
          color: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
    },
    // animation: {
    //     animateScale: true,
    //     animateRotate: true
    // }
  };
};
let chartMonthlyData: any;
let chartMonthlyOptions: any;
const VehicleConsumption = (props: any) => {
  const bar = useRef<any>(null);
  const isRTL = useContext(RTLContext);
  const [barData, setBarData] = useState<any>();
  useEffect(() => {
    // FetchRepairData().then((spare: any) => {
    //     console.log(spare);
    // });
    const fetchData = async () => {
      const [MasterlogData, Appointmentdata] = await Promise.all([
        FetchMasterlogData(),
        FetchRepairData(),
      ]);
      //   console.log(MasterlogData);

      await MonthlyReports(MasterlogData, Appointmentdata);
      setBarData(await getChartData(props.colorMode));
    };

    fetchData();

    // chartMonthlyData = getChartData(props.colorMode);
    // chartMonthlyOptions = getChartOptions();
  }, []);
  const FetchMasterlogData = async (nextToken?: any) => {
    // console.log(lastfirstdate, lastenddate);

    const ac = `Vipfuel Salik Fine Parking date _deleted`;
    let variables: any = {
      //   Fine: { attributeExists: true }
      and: [
        { vehicleid: { eq: props.formid } },
        { date: { ge: localetoisodatetime(new Date(lastfirstdate)) } },
        {
          date: {
            le:
              moment(localetoisodatetime(lastenddate)).format("YYYY-MM-DD") +
              "T23:59:59.999Z",
          },
        },
      ],
    };
    // console.log(variables);

    let masterlogdata = await dxService.listitems(
      "masterlog",
      ac,
      variables,
      true,
      1000,
      nextToken
    );
    let response = masterlogdata.items;

    let nexttoken = masterlogdata.nextToken;
    // console.log(response);
    if (nexttoken) {
      response = [...response, ...(await FetchMasterlogData(nexttoken))];

      // response = response.concat(nextResults);
    }

    return response;
  };

  const FetchRepairData = async (nextToken?: any) => {
    let variables: any = {
      //   Fine: { attributeExists: true }
      and: [
        { vehicleid: { eq: props.formid } },
        { Date: { ge: localetoisodatetime(new Date(lastfirstdate)) } },
        {
          Date: {
            le:
              moment(localetoisodatetime(lastenddate)).format("YYYY-MM-DD") +
              "T23:59:59.999Z",
          },
        },
        {
          eventtype: { eq: "Repair/Replacement" },
        },
        {
          appointmentstatus: { eq: "Completed" },
        },
      ],
    };

    let appointment = await dxService.listitems(
      "appointment",
      "*",
      variables,
      true,
      1000,
      nextToken
    );
    let response = appointment.items;

    let nexttoken = appointment.nextToken;
    // console.log(response);
    if (nexttoken) {
      response = [...response, ...(await FetchRepairData(nexttoken))];

      // response = response.concat(nextResults);
    }

    return response;
  };
  const getSalikData = async (salikarr: any, last6Months: any) => {
    SalikArray = [];
    var groupBySalik = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupBySalikMonthvalue: any = groupBySalik(salikarr, "CustomMonth");
    let reformatedata: any = [];
    let finaldata: any = [];
    // console.log(salikarr, last6Months);

    for (const [key, value] of Object.entries(GroupBySalikMonthvalue)) {
      storevalue = value;
      storekey = key;
      StoreSalikValue = value;
      // console.log(StoreSalikValue);
      var sum = 0;
      for (var i = 0; i < StoreSalikValue.length; i++) {
        sum += parseFloat(StoreSalikValue[i].Salik);
      }

      // console.log(sum);

      last6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: sum, key: storekey });
        }
      });
    }
    // console.log(reformatedata);
    reformatedata.map((mon: any) => {
      // console.log(mon);
      // console.log(storevalue, storekey);
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    // console.log(groupByMontharray);
    for (const [key, value] of Object.entries(groupByMontharray)) {
      // console.log(key, value);
      StoreSalikValue = value;
      var sum = 0;
      for (var i = 0; i < StoreSalikValue.length; i++) {
        sum += StoreSalikValue[i].value;
      }
      SalikArray.push(sum);
    }
    // console.log(SalikArray);
  };
  const getFuelData = async (fuelarr: any, last6Months: any) => {
    VipFuelArray = [];
    var groupBySalik = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupBySalikMonthvalue: any = groupBySalik(fuelarr, "CustomMonth");
    let reformatedata: any = [];
    let finaldata: any = [];
    // console.log(last6Months);

    for (const [key, value] of Object.entries(GroupBySalikMonthvalue)) {
      storevalue = value;
      storekey = key;
      StoreVipFuelValue = value;
      // console.log(StoreVipFuelValue);
      var sum = 0;
      for (var i = 0; i < StoreVipFuelValue.length; i++) {
        sum += parseFloat(StoreVipFuelValue[i].Vipfuel);
      }

      // console.log(sum);

      last6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: sum.toFixed(2), key: storekey });
        }
      });
    }
    // console.log(reformatedata);
    reformatedata.map((mon: any) => {
      // console.log(mon);
      // console.log(storevalue, storekey);
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    // console.log(groupByMontharray);
    for (const [key, value] of Object.entries(groupByMontharray)) {
      // console.log(key, value);
      StoreVipFuelValue = value;
      var sum: number = 0;
      for (var i = 0; i < StoreVipFuelValue.length; i++) {
        sum += StoreVipFuelValue[i].value;
      }
      VipFuelArray.push(Number(sum));
    }
    // console.log(VipFuelArray);
  };
  const getFineData = async (Fines: any, last6Months: any) => {
    FineArray = [];
    var groupBySalik = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupBySalikMonthvalue: any = groupBySalik(Fines, "CustomMonth");
    let reformatedata: any = [];
    let finaldata: any = [];
    // console.log(last6Months);

    for (const [key, value] of Object.entries(GroupBySalikMonthvalue)) {
      storevalue = value;
      storekey = key;
      StoreFineValue = value;
      // console.log(StoreFineValue);
      var sum = 0;
      for (var i = 0; i < StoreFineValue.length; i++) {
        sum += parseFloat(StoreFineValue[i].Fine);
      }

      // console.log(sum);

      last6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: sum.toFixed(2), key: storekey });
        }
      });
    }
    // console.log(reformatedata);
    reformatedata.map((mon: any) => {
      // console.log(mon);
      // console.log(storevalue, storekey);
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    // console.log(groupByMontharray);
    for (const [key, value] of Object.entries(groupByMontharray)) {
      // console.log(key, value);
      StoreFineValue = value;
      var sum: number = 0;
      for (var i = 0; i < StoreFineValue.length; i++) {
        sum += StoreFineValue[i].value;
      }
      FineArray.push(Number(sum));
    }
    // console.log(FineArray);
  };

  const getParkingData = async (Parking: any, last6Months: any) => {
    ParkingArray = [];
    var groupBySalik = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupByParkingMonthvalue: any = groupBySalik(Parking, "CustomMonth");
    let reformatedata: any = [];
    let finaldata: any = [];
    // console.log(last6Months);

    for (const [key, value] of Object.entries(GroupByParkingMonthvalue)) {
      storevalue = value;
      storekey = key;
      StoreParkingArray = value;
      // console.log(StoreFineValue);
      var sum = 0;
      for (var i = 0; i < StoreParkingArray.length; i++) {
        sum += parseFloat(StoreParkingArray[i].Parking);
      }

      // console.log(sum);

      last6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: sum.toFixed(2), key: storekey });
        }
      });
    }
    // console.log(reformatedata);
    reformatedata.map((mon: any) => {
      // console.log(mon);
      // console.log(storevalue, storekey);
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    // console.log(groupByMontharray);
    for (const [key, value] of Object.entries(groupByMontharray)) {
      // console.log(key, value);
      StoreParkingArray = value;
      var sum: number = 0;
      for (var i = 0; i < StoreParkingArray.length; i++) {
        sum += StoreParkingArray[i].value;
      }
      ParkingArray.push(Number(sum));
    }
    // console.log(FineArray);
  };
  //getRepairCostData
  const getRepairCostData = async (Parking: any, last6Months: any) => {
    //let StoreRepairValue: any;
    //var ReapirArray: any = [];
    ReapirArray = [];
    var groupBySalik = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let GroupByParkingMonthvalue: any = groupBySalik(Parking, "CustomMonth");
    let reformatedata: any = [];
    let finaldata: any = [];
    // console.log(last6Months);

    for (const [key, value] of Object.entries(GroupByParkingMonthvalue)) {
      storevalue = value;
      storekey = key;
      StoreRepairValue = value;
      // console.log(StoreFineValue);
      var sum = 0;
      for (var i = 0; i < StoreRepairValue.length; i++) {
        sum += parseFloat(StoreRepairValue[i].cost);
      }

      // console.log(sum);

      last6Months.map((ite: any) => {
        if (storekey.includes(ite)) {
          reformatedata.push({ value: sum.toFixed(2), key: storekey });
        }
      });
    }
    // console.log(reformatedata);
    reformatedata.map((mon: any) => {
      // console.log(mon);
      // console.log(storevalue, storekey);
      last6Months.map((lastsixmon: any) => {
        if (mon.key == lastsixmon) {
          finaldata.push({ value: mon.value, key: mon.key });
        } else {
          finaldata.push({ value: 0, key: lastsixmon });
        }
      });
    });
    var groupByMonth = function (xs: any, key: any) {
      return xs.reduce(function (rv: any, x: any) {
        // console.log(rv, x);

        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupByMontharray: any = groupByMonth(finaldata, "key");
    // console.log(groupByMontharray);
    for (const [key, value] of Object.entries(groupByMontharray)) {
      // console.log(key, value);
      StoreRepairValue = value;
      var sum: number = 0;
      for (var i = 0; i < StoreRepairValue.length; i++) {
        sum += StoreRepairValue[i].value;
      }
      ReapirArray.push(Number(sum));
    }
    // console.log(FineArray);
  };
  const MonthlyReports = async (Report: any, appointment: any) => {
    // console.log(appointment);

    SalikArray = [];
    const last6Months = [];

    for (let i = 4; i >= 0; i--) {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      const monthYearString = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
      });

      const [month, year] = monthYearString.split(" ");
      last6Months.push(`${month} ${year}`);
    }
    console.log(last6Months);

    // last6Months.sort();
    //common filter for the array
    const filterAndMapData = (data: any) => {
      return data
        .filter((acc: any) => {
          return acc !== undefined && acc !== null && acc !== "" && acc !== "0";
        })
        .map((custom: any) => {
          custom["CustomMonth"] = moment(custom.date).format("MMMM YYYY");
          return custom;
        });
    };

    const salikarr = filterAndMapData(Report.filter((acc: any) => acc.Salik));
    const fuelarr = filterAndMapData(
      Report.filter(
        (acc: any) => acc !== undefined && acc.Vipfuel && acc.Vipfuel
      )
    );
    const fines = filterAndMapData(
      Report.filter((acc: any) => acc !== undefined && acc.Fine)
    );
    const parking = filterAndMapData(
      Report.filter((acc: any) => acc !== undefined && acc.Parking)
    );
    const Repair = filterAndMapData(
      appointment.filter((acc: any) => acc !== undefined && acc.cost)
    );
    // console.log(salikarr);

    getSalikData(salikarr, last6Months);
    getFuelData(fuelarr, last6Months);
    getFineData(fines, last6Months);
    getParkingData(parking, last6Months);
    getRepairCostData(Repair, last6Months);
  };

  return (
    <div className="col-12 md:col-12 grid dashboard">
      <div className="col-12 md:col-6 vehicleBox">
        <div className="card height-100">
          {/* <div className="card-header">
                        <h5>Monthly Comparison Report</h5>
                        <Button type="button" label="Change View" style={{ background: '#2196f3' }} className={classNames(' reportview', { 'ml-auto': !isRTL, 'mr-auto': isRTL })} onClick={changeMonthlyDataView}></Button>
                    </div> */}

          <Chart
            ref={bar}
            type="bar"
            data={barData}
            plugins={[ChartDataLabels]}
            options={chartMonthlyOptions}
            //  height="400px"
          ></Chart>
        </div>
      </div>
      <VehicleDocuments props={props} />
    </div>
  );
};
export default VehicleConsumption;
