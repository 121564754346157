import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AmplifyServices from '../../Service/Service';
import { createDxCompany, deleteDxCompany, updateDxCompany } from '../../graphql/mutations';
import { listDxCompanies } from '../../graphql/queries';

import ListnewForm from '../../Components/ListnewForm';
import FormTemplate from '../../Components/FormTemplate';
import { TabMenu } from 'primereact/tabmenu';
import { Toast } from 'primereact/toast';
const CompanySettingsNewform = (Data: any, msg: any) => {
    const toast = useRef<any>(null);

    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [listFormData, setlistFormData] = useState<any>({});
    const location1 = useLocation();
    const urlParams1 = new URLSearchParams(location1.search);
    var formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
    var formid = urlParams1.has('id') ? urlParams1.get('id') : null;

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
        formid = urlParams1.has('id') ? urlParams1.get('id') : null;
        const paths = location1.pathname.split('/');
        const currentPath = paths[paths.length - 1];
        console.log(currentPath);

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'allocations':
                setActiveIndex(2);
                break;
            // case 'logs':
            //     setActiveIndex(3);
            //     break;
            // case 'Allocations':
            //     setActiveIndex(4);
            //     break;
            default:
                if (formid) getCompanyDetails(formid);
                break;
        }
    }, [location1]);
    const getCompanyDetails = async (fid: any) => {
        await AmplifyServices.getFilterbyIddetails(listDxCompanies, 'listDxCompanies', fid).then((a) => {
            console.log(a);
            if (a.length > 0) {
                setlistFormData(a[0]);
            }
        });
    };
    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);

    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                name: 'name', // pass the exact column name of db
                title: 'Name',
                value: '',
                fieldtype: 'text',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'description', // pass the exact column name of db
                title: 'Description', //for display
                value: '', //for display
                fieldtype: 'text',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'owner', // pass the exact column name of db
                title: 'Owner',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            }
        ],
        actions: [
            {
                title: 'Submit'
            },
            {
                title: 'Cancel'
            }
        ]
        // fleettype: "Company",
        // getTable: listDxCompanies,
        // createTable: createDxCompany, //Pass create table name
        // updateTable: updateDxCompany, //Pass update table name
        // deleteTableItem: deleteDxCompany, //Pass update table name
    };
    const addData = async (props: any) => {
        let data = props;
        data['id'] = parseInt(('0' + Math.floor(Math.random() * 100)).slice(-2));
        console.log(data);

        let os: any = await API.graphql({
            query: createDxCompany,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });
        showSuccess('', 'Added Successfully');

        navigate('/admin/businessunit/all');
    };
    const changedata = (props: any) => {
        console.log(props);
    };
    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';
    const wizardItems = [
        {
            label: 'Basic Details',
            command: () => navigate('/admin/businessunit/item/basic' + qs)
        }
    ];
    const editnewnavigation = async (formdata: any, fid: string) => {
        let b: any = formdata;

        Object.keys(b).forEach((key) => {
            if (b[key] == null) {
                delete b[key];
            }
        });
        if (Object.keys(b).includes('_lastChangedAt')) delete b['_lastChangedAt'];
        if (Object.keys(b).includes('createdAt')) delete b['createdAt'];
        if (Object.keys(b).includes('updatedAt')) delete b['updatedAt'];
        if (Object.keys(b).includes('owner')) delete b['owner'];
        if (Object.keys(b).includes('drivers')) delete b['drivers'];
        if (Object.keys(b).includes('departments')) delete b['departments'];
        // let dd = new Date(b['startdate']);
        //         b['startdate']= dd.toISOString();
        console.log(b);
        AmplifyServices.EditConfigDetails(updateDxCompany, 'updateDxCompany', b)
            .then(async (res: any) => {
                console.log(res);

                showSuccess(res.name, 'updated successfully!');
                navigate(`/admin/businessunit/all`);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const showSuccess = (summary: string, detail: string) => {
        toast.current.show({
            severity: 'success',
            summary: summary,
            detail: detail,
            life: 3000
        });
    };
    const addConfig = (props: any) => {
        if (formid == null) addData(props);
        else {
            editnewnavigation(props, formid);
        }
    };
    //   const submitForm = async (data: any) => {
    //     // console.log(props.columns);
    //     data["id"] = Math.random().toString(36).substring(2, 9);
    //     data["fleettype"] = formColumns.fleettype;

    //     let os = await API.graphql({
    //       query: formColumns.createTable,
    //       authMode: "AMAZON_COGNITO_USER_POOLS",
    //       variables: {
    //         input: data,
    //       },
    //     });

    //     let navigateUrl = "/admin/businessunit";
    //     navigate(navigateUrl);
    //   };

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{formmode === 'edit' ? 'Edit Business Unit : ' + listFormData.name : formmode === 'view' ? 'View Business Unit: ' + listFormData.name : 'New Business Unit'}</h4>

                <div className="card container-card">
                    <Toast ref={toast} />
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                    <Routes>
                        <Route
                            path={'/basic'}
                            element={
                                <>
                                    <FormTemplate columns={formColumns} data={listFormData} showaction={true} onChange={changedata} mode={formmode} ChangeBtn={'OpenDocument'} layout="grid2" title="New Approval" submitForm={addConfig} />
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default CompanySettingsNewform;
