import { API } from 'aws-amplify';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { listDxUserRoles } from '../../graphql/queries';
import { Groups } from './GroupNames';
import Navigation from './Navigation/Navigation';

function GroupDetails() {
    const [groupName, setGroupName] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [data, setData] = useState([]);
    //set the groupName when the url changes
    useEffect(() => {
        getData();
        let urlGroupName = window.location.hash.split('Roles/')[1];
        //For getting the specific Group Name
        let FilteredGroup: any = Groups.filter((group: any) => group.name == urlGroupName);
        setGroupName(FilteredGroup);
    }, [window.location.href, selectedUsers]);

    const getData = async () => {
        let UserRoles: any = await API.graphql({ query: listDxUserRoles });
        let userRole = UserRoles.data.listDxUserRoles.items;
        // userRole = userRole.filter((role: any) => role.userrole == 'Fleet Administrator');

        setData(userRole);
    };

    const userEmailColumn = (data: any) => {
        return data.username;
    };
    const userRoleColumn = (data: any) => {
        return data.userrole;
    };
    const userIDColumn = (data: any) => {
        return data.id;
    };
    const onUserClicked = (e: any, name: any) => {
        let Users: any = [...selectedUsers];
        // console.log(selectedUsers);
        if (e.target.checked) Users.push(name);
        else Users.splice(Users.indexOf(name), 1);

        setSelectedUsers(Users);

        //how to uncheck all checkbox after submit?
    };
    const checkBoxColumn = (name: any) => {
        return (
            <>
                <input type="checkbox" name={name.username} id={name.id} onChange={(e) => onUserClicked(e, name.username)} />
            </>
        );
    };

    //Passing the group name from url
    let header = (
        <div className="table-header-container">
            <Navigation group={groupName.map((group: any) => group.name).toString()} removeUsers={selectedUsers} selectedCheckBox={setSelectedUsers} />
        </div>
    );
    return (
        <div className="col-12">
            <div className="card">
                <h5>{groupName.map((group: any) => group.value)}</h5>
                <DataTable value={data} paginator rows={10} dataKey="id" responsiveLayout="scroll" header={header}>
                    <Column field="#" header="#" body={checkBoxColumn} />
                    <Column field="email" header="Email" sortable={true} body={userEmailColumn} />
                    <Column field="role" header="Role" sortable={true} body={userRoleColumn} />
                    <Column field="id" header="ID" sortable={true} body={userIDColumn} />
                </DataTable>
            </div>
        </div>
    );
}

export default GroupDetails;
