import { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
// import { Button } from '@aws-amplify/ui-react';
import dxService from '../../../Services/dxService';
import { convertdate, getVehicleoptions, isotolocaledatetime, localetoisodateendtime, localetoisodatetime } from '../../../utils/helper';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import html2canvas from 'html2canvas';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './ReportBuilder.css';
import { CSVLink } from 'react-csv';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Message } from 'primereact/message';
import { Link } from 'react-router-dom';
import { getTypeColors } from '../../../utils/color';
//Vehicle, Repar,Salik, Vip Fuel,Mileage, Fine
//Bar Chart Data

function ReportBuilders() {
    const TypeOptions = [
        { value: 'Summary', label: 'Summary' },
        { value: 'OChart', label: 'Overall Chart' },
        { value: 'Comparison', label: 'Comparison Chart' }
    ];
    const ExportOptions = [
        //appointment
        { name: 'Repair/Replacement', label: 'Repair and Expenses' },
        { name: 'Service', label: 'Service Schedule' },
        { name: 'Branding Expense', label: 'Branding' },
        { name: 'Accident', label: 'Accident' },
        //masterlogs
        { name: 'Cash Reimbursement', label: 'Cash Reimbursement' },
        { name: 'Reimbursement Categories', label: 'Reimbursement Categories' },
        { name: 'Salik', label: 'Salik' },
        { name: 'Vipfuel', label: 'VIP fuel' },
        { name: 'Mileage', label: 'Mileage' },
        { name: 'Fine', label: 'Fine' },
        //servicecontract
        { name: 'Parking', label: 'Parking' },
        { name: 'Advertisement', label: 'Advertisement' },
        { name: 'Insurance', label: 'Insurance' }
    ];
    const colors: any = getTypeColors('dark');
    const [selectedMonth, setSelectedMonth] = useState<any>('');
    const [selectedType, setSelectedType] = useState<any>('');
    const [selectedExports, setSelectedExports] = useState(ExportOptions);
    const [customDate, setCustomDate] = useState<any>([]);
    const [chartData, setChartData] = useState<any>([]);
    const [chartOptions, setChartOptions] = useState<any>([]);

    const [masterAppointmentReport, setMasterAppointmentReport] = useState<any>([]);
    const [masterlogsReport, setMasterLogsReport] = useState<any>([]);
    const [masterReimburseReport, setMasterReimburseReport] = useState<any>([]);
    const [mastercontractReport, setMasterContractReport] = useState<any>([]);
    const [appointmentReport, setAppointmentReport] = useState<any>([]);
    const [logsReport, setLogsReport] = useState<any>([]);
    const [reimburseReport, setReimburseReport] = useState<any>([]);
    const [contractReport, setContractReport] = useState<any>([]);
    const [RenewalReport, setRenewalReport] = useState<any>([]);
    const [allReports, setAllReports] = useState<any>([]);
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [expandedSparesRows, setExpandedSparesRows] = useState<any>([]);
    const [exportName, setExportName] = useState<any>('');
    const [exportData, setExportData] = useState<any>([]);
    const [vehicleOptions, setVehicleOptions] = useState<any>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<any>('');
    const [dprogress, setDownloadProgress] = useState<any>(false);

    const [appointmentLoading, setAppointmentLoading] = useState<any>(true);
    const [logsLoading, setLogsLoading] = useState<any>(true);
    const [reimburseLoading, setReimburseLoading] = useState<any>(true);
    const [contractLoading, setContractLoading] = useState<any>(true);
    const [RenewaltLoading, setRenewaltLoading] = useState<any>(true);
    const chartref = useRef<any>(null);
    const actionMenu = useRef<any>(null);
    const location = useLocation();
    const urlParams1 = new URLSearchParams(location.search);
    var month = urlParams1.has('m') ? urlParams1.get('m') : '1month';
    var viewType = urlParams1.has('v') ? urlParams1.get('v') : '';
    var vid = urlParams1.has('vid') ? urlParams1.get('vid') : '';
    var sdate: any = urlParams1.has('sdate') ? urlParams1.get('sdate') : '';
    var edate: any = urlParams1.has('edate') ? urlParams1.get('edate') : '';

    //FYI selectedType is also called as viewType
    const filteredOptions =
        selectedType && (selectedType === 'OChart' || selectedType === 'Comparison')
            ? ExportOptions.filter((option) => !['Service', 'Accident', 'Advertisement'].includes(option.name))
            : ExportOptions.filter((option) => !['Reimbursement Categories'].includes(option.name));
    //set the params value in the state
    useEffect(() => {
        month = urlParams1.has('m') ? urlParams1.get('m') : '1month';
        viewType = urlParams1.has('v') ? urlParams1.get('v') : '';
        vid = urlParams1.has('vid') ? urlParams1.get('vid') : '';
        sdate = urlParams1.has('sdate') ? urlParams1.get('sdate') : '';
        edate = urlParams1.has('edate') ? urlParams1.get('edate') : '';
        setSelectedMonth(month ? month : '1month');
        setSelectedType(viewType === 'OChart' ? 'OChart' : viewType === 'Summary' ? 'Summary' : viewType === 'Comparison' ? 'Comparison' : '');
        setSelectedVehicle(vid);
        let startDate = new Date(sdate);
        let endDate = new Date(edate);
        setCustomDate([startDate, endDate]);
    }, [month, viewType, vid, sdate, edate]);
    //get vehicle dropdown options
    useEffect(() => {
        const getVehicleOptions = () => {
            getVehicleoptions().then((options) => {
                setVehicleOptions(options);
            });
        };
        getVehicleOptions();
    }, []);

    //set date based on month dropdown selection
    useEffect(() => {
        if (selectedMonth === 'custom' && sdate === '' && edate === '') {
            setCustomDate([]);
        } else if (selectedMonth === '1month') {
            let todate = new Date();
            let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
            let lastfirstdate = new Date(todate.getFullYear(), todate.getMonth() - 1, 1);
            setCustomDate([lastfirstdate, lastenddate]);
        } else if (selectedMonth === '3months') {
            let todate = new Date();
            let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
            let lastfirstdate = new Date(todate.getFullYear(), todate.getMonth() - 3, 1);
            setCustomDate([lastfirstdate, lastenddate]);
        } else if (selectedMonth === '6months') {
            let todate = new Date();
            let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
            let lastfirstdate = new Date(todate.getFullYear(), todate.getMonth() - 6, 1);
            setCustomDate([lastfirstdate, lastenddate]);
        }
    }, [selectedMonth]);
    const loadingastrue = () => {
        setAppointmentLoading(true);
        setLogsLoading(true);
        setContractLoading(true);
        setRenewaltLoading(true);
    };
    //if the View type is overall chart and comparison filter out unnecessary options
    useEffect(() => {
        const updatedExports =
            selectedType === 'OChart' || selectedType === 'Comparison'
                ? selectedExports.filter((exportItem: any) => !['Service', 'Accident', 'Advertisement'].includes(exportItem.name))
                : selectedExports.filter((option) => !['Reimbursement Categories'].includes(option.name));
        setSelectedExports(updatedExports);
    }, [selectedType]);

    //fetch data according needs
    useEffect(() => {
        const getRequiredData = async () => {
            let appointmentItems = ['Service', 'Repair/Replacement', 'Accident', 'Branding Expense'];

            let logsItems = ['Salik', 'Vipfuel', 'Mileage', 'Fine', 'Parking'];
            let reimburseItems = ['Cash Reimbursement', 'Reimbursement Categories'];
            let serviceContractItems = ['Advertisement'];
            let RenewalItems = ['Insurance'];
            let appointmentReports = selectedExports.filter((exp: any) => appointmentItems.includes(exp.name));
            if (appointmentReports.length > 0) {
                await getAppointmentReport(appointmentReports);
            } else {
                setAppointmentLoading(false);
            }
            let logsReports = selectedExports.filter((exp: any) => logsItems.includes(exp.name));
            if (logsReports.length > 0) {
                await getLogsReport(logsReports);
            } else {
                setLogsLoading(false);
            }
            let reimburseReports = selectedExports.filter((exp: any) => reimburseItems.includes(exp.name));
            if (reimburseReports.length > 0) {
                await getReimburseReport();
            } else {
                setReimburseLoading(false);
            }
            let serviceContractReports = selectedExports.filter((exp: any) => serviceContractItems.includes(exp.name));
            if (serviceContractReports.length > 0) {
                await getContractReport(serviceContractReports);
            } else {
                setContractLoading(false);
            }
            let RenewalReports = selectedExports.filter((exp: any) => RenewalItems.includes(exp.name));
            if (RenewalReports.length > 0) {
                await getRenewalReport(RenewalReports);
            } else {
                setRenewaltLoading(false);
            }
        };
        getRequiredData();
    }, [selectedExports, customDate]);

    // filter the items based on the selected vehicle name
    useEffect(() => {
        if (selectedVehicle) {
            setAppointmentReport(masterAppointmentReport.filter((vehicle: any) => vehicle.vehicleid === selectedVehicle));
            setLogsReport(masterlogsReport.filter((vehicle: any) => vehicle.vehicleid === selectedVehicle));
            setContractReport(mastercontractReport.filter((vehicle: any) => vehicle.vehicleid === selectedVehicle));
            setRenewalReport(mastercontractReport.filter((vehicle: any) => vehicle.vehicleid === selectedVehicle));
            //setRenewalReport
            setReimburseReport(masterReimburseReport.filter((vehicle: any) => vehicle.vehicleid === selectedVehicle));
        } else {
            setAppointmentReport(masterAppointmentReport);
            setLogsReport(masterlogsReport);
            setContractReport(mastercontractReport);
            setRenewalReport(mastercontractReport);
            setMasterReimburseReport(masterReimburseReport);
        }
    }, [selectedVehicle, masterAppointmentReport, masterlogsReport, mastercontractReport, masterReimburseReport]);

    //chart data
    useEffect(() => {
        if (selectedExports.length === 0) {
            setAllReports([]);
        }
        const generateDatasets = () => {
            const datasets = selectedExports.flatMap((exportItem: any) => {
                const monthlyData = getChartData(exportItem.name); // Get monthly data array
                // console.log(monthlyData);

                let label = exportItem.label;

                // Check if the export contains Reimbursement Categories
                if (label === 'Reimbursement Categories') {
                    const customLabels = ['CR Fuel', 'CR Salik', 'CR Parking', 'CR Maintenance', 'CR Renewals', 'CR Others'];

                    const customDatasets = customLabels.map((customLabel) => {
                        const customMonthlyData = getChartData(label, customLabel); // Get monthly data array for custom label

                        return {
                            label: customLabel,
                            data: customMonthlyData,
                            backgroundColor: getRandomColor(customLabel.replaceAll(' ', '')),
                            borderColor: getRandomColor(customLabel.replaceAll(' ', '')),
                            borderWidth: 1
                        };
                    });

                    // Return the custom datasets separately using flatMap
                    return customDatasets;
                }

                return {
                    label: label,
                    data: monthlyData,
                    backgroundColor: getRandomColor(label),
                    borderColor: getRandomColor(label),
                    borderWidth: 1
                };
            });

            return datasets;
        };

        const getChartData = (exportName: any, reimbursenames?: any) => {
            let updatedSelectedReport = allReports.filter((report: any) => selectedExports.some((selected: any) => Object.keys(report.name)[0].includes(selected.label)));
            setAllReports(updatedSelectedReport);
            //   console.log(exportName, appointmentReport);
            let data = [];
            let shouldReturnCount = false;
            //if shouldReturnCount is true then it will return the length of the report
            //else it will return the data for calculating the total number of reports
            switch (exportName) {
                case 'Service':
                    data = appointmentReport.filter((item: any) => item.eventtype === 'Service');
                    shouldReturnCount = true;
                    break;

                case 'Repair/Replacement':
                    data = appointmentReport.filter((item: any) => item.eventtype === 'Repair/Replacement');
                    break;
                case 'Branding Expense':
                    data = appointmentReport.filter((item: any) => item.eventtype === 'Branding Expense');
                    break;

                case 'Accident':
                    data = appointmentReport.filter((item: any) => item.eventtype === 'Accident');
                    shouldReturnCount = true;
                    break;

                case 'Salik':
                    data = logsReport.filter((item: any) => item.Salik !== null);
                    break;

                case 'Vipfuel':
                    data = logsReport.filter((item: any) => item.Vipfuel !== null);
                    break;

                case 'Mileage':
                    data = logsReport.filter((item: any) => item.Mileage !== null);
                    break;

                case 'Fine':
                    data = logsReport.filter((item: any) => item.Fine !== null);
                    break;
                case 'Cash Reimbursement':
                    data = reimburseReport.filter((item: any) => item.amount !== null);
                    break;
                case 'Reimbursement Categories':
                    switch (reimbursenames) {
                        case 'CR Fuel':
                            data = reimburseReport.filter((item: any) => item.expensetype === 'Fuel' && item.amount !== null);
                            break;
                        case 'CR Salik':
                            data = reimburseReport.filter((item: any) => item.expensetype === 'Salik' && item.amount !== null);
                            break;
                        case 'CR Parking':
                            data = reimburseReport.filter((item: any) => item.expensetype === 'Parking' && item.amount !== null);
                            break;
                        case 'CR Maintenance':
                            data = reimburseReport.filter((item: any) => item.expensetype === 'Maintenance' && item.amount !== null);
                            break;
                        case 'CR Renewals':
                            data = reimburseReport.filter((item: any) => item.expensetype === 'Renewals' && item.amount !== null);
                            break;
                        case 'CR Others':
                            data = reimburseReport.filter((item: any) => item.expensetype === 'Others' && item.amount !== null);
                            break;
                        default:
                            data = [];
                            break;
                    }
                    break;

                case 'Parking':
                    data = logsReport.filter((item: any) => item.Parking !== null && item.Parking !== '');
                    // shouldReturnCount = true;
                    break;

                case 'Advertisement':
                    data = contractReport.filter((item: any) => item.subtype && item.subtype === 'Advertisement');
                    shouldReturnCount = true;
                    break;
                case 'Insurance':
                    data = RenewalReport.filter((item: any) => item.renewaltype === 'Insurance' && item.totalamount !== null);
                    //   shouldReturnCount = true;
                    break;
                //RenewalReport
                default:
                    break;
            }

            if (shouldReturnCount) {
                return data.length;
            } else {
                const totalAmount = data.reduce(
                    (total: any, item: any) =>
                        total +
                        (Number(
                            item[
                                exportName === 'Repair/Replacement'
                                    ? 'cost'
                                    : exportName === 'Branding Expense'
                                    ? 'cost'
                                    : exportName === 'Insurance'
                                    ? 'totalamount'
                                    : exportName === 'Cash Reimbursement' || exportName === 'Reimbursement Categories'
                                    ? 'amount'
                                    : exportName
                            ]
                        ) || 0),
                    0
                );
                if (selectedType === 'OChart') {
                    return [totalAmount.toFixed(2)];
                } else {
                    return getMonthlyData(data, exportName);
                }
            }
        };

        //get the month names
        const getComparisonLabels = (numMonths: number) => {
            const currentMonth = new Date().getMonth();
            const pastMonthNames = [];

            for (let i = 1; i <= numMonths; i++) {
                const monthIndex = (currentMonth - i + 12) % 12;
                // console.log(monthIndex);
                const monthName = new Intl.DateTimeFormat('en-US', {
                    month: 'long'
                }).format(new Date(0, monthIndex));
                // console.log(monthName);

                pastMonthNames.push(monthName);
            }
            return pastMonthNames.reverse();
        };
        //get the month names if the selected month is custom
        const getCustomMonthNames = () => {
            const customStartDate = new Date(customDate[0]);
            const customEndDate = new Date(customDate[1]);

            const monthNames = [];
            for (let date = customStartDate; date <= customEndDate; date.setMonth(date.getMonth() + 1)) {
                monthNames.push(date.toLocaleDateString('en-US', { month: 'long' }));
            }
            return monthNames;
        };

        const comparisonLabels = selectedMonth === 'custom' ? getCustomMonthNames() : getComparisonLabels(selectedMonth === '1month' ? 1 : selectedMonth === '3months' ? 3 : selectedMonth === '6months' ? 6 : 1);

        //get the monthly data to show in the chart
        const getMonthlyData = (data: any[], fieldName: string) => {
            //   console.log(data, fieldName);

            const monthlyData: number[] = [];

            const monthFormat = new Intl.DateTimeFormat('en-US', { month: 'long' });
            const groupedData: Record<string, any[]> = {};

            for (const item of data) {
                const date = item.Date ? new Date(item.Date) : item.issuedate ? new Date(item.issuedate) : new Date(item.date);
                const monthName = monthFormat.format(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
                if (!groupedData[monthName]) {
                    groupedData[monthName] = [];
                }
                groupedData[monthName].push(item);
            }

            for (const month of comparisonLabels) {
                const monthData = groupedData[month];
                if (monthData) {
                    const totalAmount: any = monthData.reduce((total: number, item: any) => {
                        const fieldValue =
                            fieldName === 'Repair/Replacement' || fieldName === 'Branding Expense'
                                ? item.cost
                                    ? Number(item.cost)
                                    : 0
                                : fieldName === 'Cash Reimbursement' || fieldName === 'Reimbursement Categories'
                                ? item.amount
                                    ? Number(item.amount)
                                    : 0
                                : item[fieldName]
                                ? Number(item[fieldName])
                                : 0;

                        return total + (isNaN(fieldValue) ? 0 : fieldValue);
                    }, 0);
                    monthlyData.push(totalAmount !== 0 ? totalAmount.toFixed(2) : totalAmount);
                } else {
                    monthlyData.push(0);
                }
            }

            return monthlyData;
        };

        const getRandomColor = (type: any) => {
            // Generate a specific color for each dataset
            const color =
                type === 'VIP fuel'
                    ? colors.Vipfuel
                    : type === 'Mileage'
                    ? colors.Mileage
                    : type === 'Fine'
                    ? colors.Fine
                    : type === 'Parking'
                    ? colors.Parking
                    : type === 'Repair and Expenses'
                    ? colors.Repair
                    : type === 'Branding'
                    ? colors.Branding
                    : type === 'Salik'
                    ? colors.Salik
                    : type === 'Cash Reimbursement'
                    ? colors.CashReimbursement
                    : colors[type];

            return color;
        };

        const data = {
            labels: selectedType === 'OChart' ? [`${customDate[0] && convertdate(customDate[0])}${customDate[1] ? ' to ' + convertdate(customDate[1]) : ''}`] : selectedType === 'Comparison' ? comparisonLabels : [],
            datasets: generateDatasets()
        };

        const options = {
            responsive: true,
            cutoutPercentage: 50,
            legend: {
                position: 'bottom'
            },
            plugins: {
                legend: {
                    display: true
                },
                datalabels: {
                    display: true,
                    color: '#000',
                    align: 'end', // Adjusted alignment to start
                    anchor: 'end', // Adjusted anchor to end
                    clamp: true,
                    font: {
                        weight: '700'
                    }
                },
                tooltip: {
                    callbacks: {
                        label: (context: any) => {
                            return `${context.dataset.label}: AED ${context.raw}`;
                        }
                    }
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    }
                },
                y: {
                    beginAtZero: true
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [selectedExports, appointmentReport, logsReport, RenewalReport, contractReport, customDate]);

    const getAppointmentReport = async (name: any) => {
        if (customDate.length > 0) {
            const filter = {
                and: [
                    {
                        Date: {
                            ge: localetoisodatetime(customDate[0])
                        }
                    },
                    {
                        Date: {
                            le: customDate[1] ? localetoisodateendtime(customDate[1]) : localetoisodateendtime(customDate[0])
                        }
                    }
                ],
                or: [
                    ...name
                        .filter((item: any) => ['Repair/Replacement', 'Accident', 'Branding Expense'].includes(item.name))
                        .map((item: any) => ({
                            eventtype: {
                                eq: item.name
                            }
                        })),
                    ...(name.some((item: any) => item.name === 'Service')
                        ? [
                              {
                                  eventtype: {
                                      eq: 'Service'
                                  },
                                  appointmentstatus: {
                                      ne: 'Completed'
                                  }
                              }
                          ]
                        : [])
                ]
            };

            let response: any[] = [];
            let nextToken = null;
            const ac = `id vehicleid Date place cost appointmentstatus eventtype  _deleted 
            spares{
                items{
                    id
                    vehicleid
                    description
                    invoiceno
                    partname
                    supplier
                    quantity
                    amount
                    totalamount
                    jfield
                    status
                }
            }`;
            do {
                const { items, nextToken: newNextToken }: any = await dxService.listitems('appointment', ac, filter, true, 1000, nextToken);
                response.push(...items);
                nextToken = newNextToken;
            } while (nextToken);

            response = response.filter((del: any) => del._deleted !== true).sort((a: any, b: any) => moment(b.Date).format().localeCompare(moment(a.Date).format()));

            setMasterAppointmentReport(response);
            setAppointmentReport(response);
            setAppointmentLoading(false);

            return response;
        }
    };

    const getLogsReport = async (name: any) => {
        if (customDate.length > 0) {
            const filter = {
                and: [
                    {
                        date: {
                            ge: localetoisodatetime(customDate[0])
                        }
                    },
                    {
                        date: {
                            le: customDate[1] ? localetoisodateendtime(customDate[1]) : localetoisodateendtime(customDate[0])
                        }
                    }
                ],
                or: [
                    ...name
                        .filter((item: any) => ['Salik', 'Vipfuel', 'Mileage', 'Fine', 'Parking'].includes(item.name))
                        .map((item: any) => ({
                            [item.name]: {
                                ne: null
                            }
                        }))
                ]
            };

            let response: any[] = [];
            let nextToken = null;
            const ac = `id vehicleid date driver{id name} Vipfuel Salik Fine Mileage Parking`;
            do {
                const { items, nextToken: newNextToken }: any = await dxService.listitems('masterlog', ac, filter, true, 1000, nextToken);
                // console.log(items);

                response.push(...items);
                nextToken = newNextToken;
            } while (nextToken);

            response = response.filter((del: any) => del._deleted !== true).sort((a: any, b: any) => moment(b.date).format().localeCompare(moment(a.date).format()));

            setMasterLogsReport(response);
            setLogsReport(response);
            setLogsLoading(false);

            return response;
        }
    };
    const getReimburseReport = async () => {
        if (customDate.length > 0) {
            const filter = {
                and: [
                    {
                        date: {
                            ge: localetoisodatetime(customDate[0])
                        }
                    },
                    {
                        date: {
                            le: customDate[1] ? localetoisodateendtime(customDate[1]) : localetoisodateendtime(customDate[0])
                        }
                    }
                ]
            };

            let response: any[] = [];
            let nextToken = null;
            const ac = `id vehicleid date driver{id name} description expensetype amount paidto`;
            do {
                const { items, nextToken: newNextToken }: any = await dxService.listitems('logreimbursement', ac, filter, true, 1000, nextToken);
                response.push(...items);
                nextToken = newNextToken;
            } while (nextToken);

            response = response.filter((del: any) => del._deleted !== true).sort((a: any, b: any) => moment(b.date).format().localeCompare(moment(a.date).format()));

            setMasterReimburseReport(response);
            setReimburseReport(response);
            setReimburseLoading(false);

            return response;
        }
    };
    const getRenewalReport = async (name: any) => {
        if (customDate.length > 0) {
            let filter = {
                or: name
                    .filter((item: any) => ['Insurance'].includes(item.name))
                    .map((item: any) => ({
                        renewaltype: {
                            eq: item.name
                        }
                    })),
                and: [
                    {
                        issuedate: {
                            le: customDate[1] ? localetoisodateendtime(customDate[1]) : localetoisodateendtime(customDate[0])
                        }
                    },
                    {
                        issuedate: {
                            ge: localetoisodatetime(customDate[0])
                        }
                    }
                ]
            };
            let response: any[] = [];
            let nextToken = null;
            const ac = `id vehicleid issuedate renewaltype totalamount expirydate renewalno renewalstatus`;
            do {
                const { items, nextToken: newNextToken }: any = await dxService.listitems('renewal', ac, filter, true, 1000, nextToken);
                // console.log(items);

                response.push(...items);
                nextToken = newNextToken;
            } while (nextToken);

            response = response.filter((del: any) => del._deleted !== true).sort((a: any, b: any) => moment(b.expirydate).format().localeCompare(moment(a.expirydate).format()));

            setMasterContractReport(response);
            setRenewalReport(response);
            setRenewaltLoading(false);
            return response;
        }
        // let b = a.filter((del: any) => del._deleted !== true);

        //Parking Advertisement
    };
    const getContractReport = async (name: any) => {
        if (customDate.length > 0) {
            let filter = {
                or: name
                    .filter((item: any) => ['Advertisement'].includes(item.name))
                    .map((item: any) => ({
                        subtype: {
                            eq: item.name
                        }
                    })),
                and: [
                    {
                        expirydate: {
                            le: customDate[1] ? localetoisodateendtime(customDate[1]) : localetoisodateendtime(customDate[0])
                        }
                    },
                    {
                        expirydate: {
                            ge: localetoisodatetime(customDate[0])
                        }
                    }
                ]
            };
            let response: any[] = [];
            let nextToken = null;
            const ac = `id vehicleid issuedate expirydate policyno renewalstatus`;
            do {
                const { items, nextToken: newNextToken }: any = await dxService.listitems('renewal', ac, filter, true, 1000, nextToken);
                response.push(...items);
                nextToken = newNextToken;
            } while (nextToken);

            response = response.filter((del: any) => del._deleted !== true).sort((a: any, b: any) => moment(b.expirydate).format().localeCompare(moment(a.expirydate).format()));

            setMasterContractReport(response);
            setContractReport(response);
            setContractLoading(false);
            return response;
        }
        // let b = a.filter((del: any) => del._deleted !== true);

        //Parking Advertisement
    };

    const downloadChart = () => {
        setDownloadProgress(true);
        const chartElement: any = document.getElementById('chart-container');
        const scale = 2; // Increase the scale value for higher resolution

        html2canvas(chartElement, { scale }).then((canvas) => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = `Report_${selectedType}_${convertdate(customDate[0])}${customDate[1] ? '_to_' + convertdate(customDate[1]) : ''}`;
            link.click();
            setDownloadProgress(false);
        });
    };
    const actionGlobalTemplate = (rowData: any, column: any) => {
        return <>{rowData[column.field] ? rowData[column.field] : '-'}</>;
    };
    const rowExpansionTemplate = (rowData: any) => {
        return (
            <div className="col-12 md:col-12">
                <DataTable
                    value={rowData && rowData.spares ? rowData.spares.items : []}
                    responsiveLayout="scroll"
                    className="p-datatable-responsive usagetable"
                    header={
                        <div className="p-toolbar p-component">
                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                <h5>Expenses for {rowData.vehicleid}</h5>
                            </div>
                        </div>
                    }
                    // rowClassName={actionRowClassName}
                >
                    <Column field={'vehicleid'} className="text-left p-message p-message-warn" header={'Vehicle'} />
                    <Column field={'description'} className="text-left p-message p-message-warn" header={'Description'} body={actionGlobalTemplate} />
                    <Column field={'invoiceno'} className="text-left p-message p-message-warn" header={'Invoice No'} body={actionGlobalTemplate} />
                    <Column
                        field={'partname'}
                        className="text-left p-message p-message-warn"
                        header={'Part Name'}
                        body={(data) => {
                            const value = data.partname ? JSON.parse(data.partname) : '-';
                            return <>{value}</>;
                        }}
                    />
                    <Column field={'supplier'} className="text-left p-message p-message-warn" header={'Supplier'} body={actionGlobalTemplate} />
                    <Column field={'quantity'} className="text-left p-message p-message-warn" header={'Quantity'} body={actionGlobalTemplate} />
                    <Column field={'amount'} className="text-left p-message p-message-warn" header={'Amount'} body={actionGlobalTemplate} />
                    <Column
                        field={'totalamount'}
                        className="text-left p-message p-message-warn"
                        header={'Total Amount'}
                        body={(data) => {
                            const tax = data.jfield && JSON.parse(data.jfield).tax ? JSON.parse(data.jfield).tax : 0;
                            const totalAmountWithTax = Number(data.totalamount) * (1 + Number(tax) / 100);
                            return <>{totalAmountWithTax.toFixed(2)}</>;
                        }}
                    />
                    <Column
                        field={'tax'}
                        className="text-left p-message p-message-warn"
                        header={'Tax'}
                        body={(data) => {
                            const tax = data.jfield && JSON.parse(data.jfield).tax ? JSON.parse(data.jfield).tax : 0;
                            return <>{tax}%</>;
                        }}
                    />
                    <Column field={'status'} className="text-left p-message p-message-warn" header={'Status'} />
                </DataTable>
            </div>
        );
    };
    //short and simple function to export data dynamically
    // const exportdata = (data: any[], name: any, fieldNames: any[], headerNames: any[]) => {
    //     let items: {}[] = [];
    //     data.forEach((item: { [x: string]: any; hasOwnProperty: (arg0: any) => any }) => {
    //         let newItem: any = {};
    //         fieldNames.forEach((fieldName: string, index: number) => {
    //             if (item.hasOwnProperty(fieldName)) {
    //                 newItem[headerNames[index]] = item[fieldName];
    //             } else if (item.hasOwnProperty(fieldName.toLowerCase())) {
    //                 newItem[headerNames[index]] = item[fieldName.toLowerCase()];
    //             }
    //         });
    //         items.push(newItem);
    //     });
    //     return items;
    // };
    const exportdata = (data: any[], name: any, fieldNames: any, headerNames: any) => {
        let items: {}[] = [];
        data.forEach((item) => {
            if (name === 'Service' || name === 'Repair/Replacement') {
                items.push({
                    Vehicle: item.vehicleid,
                    Date: convertdate(item.Date),
                    Place: item.place,
                    Cost: item.cost,
                    Status: item.appointmentstatus
                });
            } else if (name === 'Accident') {
                items.push({
                    Vehicle: item.vehicleid,
                    Date: convertdate(item.Date),
                    Location: item.accidenthappenedat,
                    'Accident Type': item.accidenttype,
                    Status: item.appointmentstatus
                });
            } else if (name === 'Salik' || name === 'Vipfuel' || name === 'Fine' || name === 'Mileage' || name === 'Parking') {
                items.push({
                    Vehicle: item.vehicleid,
                    Date: convertdate(item.date),
                    'Driver Name': item.driver ? item.driver.name : '',
                    [name]: item[name]
                });
            } else if (name === 'Cash Reimbursement') {
                //'vehicleid', 'date', 'driver.name', 'description', 'expensetype', 'amount', 'paidto'
                items.push({
                    Vehicle: item.vehicleid,
                    Date: convertdate(item.date),
                    'Driver Name': item.driver ? item.driver.name : '',
                    Description: item.description,
                    Type: item.expensetype,
                    Amount: item.amount,
                    'Paid To': item.paidto
                });
            } else if (name === 'Advertisement') {
                items.push({
                    Vehicle: item.vehicleid,
                    'Issue Date': convertdate(item.issuedate),
                    'Expiry Date': convertdate(item.expirydate),
                    'Policy No': item.policyno,
                    Status: item.renewalstatus
                });
            } else if (name === 'Vendor Grouped') {
                items.push({
                    Vehicle: item.vehicleid,
                    Description: item.description,
                    'Invoice No': item.invoiceno,
                    Partname: item.partname ? JSON.parse(item.partname) : '',
                    Supplier: item.supplier,
                    Quantity: item.quantity,
                    Amount: item.amount,
                    'Total Amount': item.totalamount,
                    Tax: item.jfield && JSON.parse(item.jfield).tax ? JSON.parse(item.jfield).tax + '%' : 0 + '%',
                    Status: item.status
                });
            }
        });

        return items;
    };
    const headerTemplate = (e: any) => {
        return <>{e.supplier}</>;
    };
    //set the values to the url
    const updateURL = (month: any, view: any, vehicle: any, sdate: any, edate: any) => {
        const baseUrl = window.location.href.split('?')[0];
        const queryParams = new URLSearchParams(window.location.search);

        if (month) {
            queryParams.set('m', month);
            if (month === 'custom') {
                queryParams.set('sdate', convertdate(sdate));
                queryParams.set('edate', convertdate(edate));
            } else {
                queryParams.delete('sdate');
                queryParams.delete('edate');
            }
        } else {
            queryParams.delete('m');
        }

        if (view) {
            queryParams.set('v', view);
        } else {
            queryParams.delete('v');
        }

        if (vehicle) {
            queryParams.set('vid', vehicle);
        } else {
            queryParams.delete('vid');
        }

        const newUrl = baseUrl + '?' + queryParams.toString();
        window.history.replaceState({}, '', newUrl);
    };

    return (
        <>
            <h4>Report Builder</h4>
            <div className="card">
                <div className="col-12 md:col-12">
                    <div className="grid">
                        <div>
                            <Dropdown
                                value={selectedMonth}
                                onChange={(e) => {
                                    setSelectedMonth(e.value);
                                    setAppointmentReport([]);
                                    setLogsReport([]);
                                    setContractReport([]);
                                    setRenewalReport([]);
                                    setChartData([]);
                                    loadingastrue();
                                    updateURL(e.value, selectedType, selectedVehicle, customDate[0], customDate[1]);
                                }}
                                placeholder="Select an option"
                                className="ml-2 mr-2 mb-3"
                                options={[
                                    { value: '1month', label: 'Last month' },
                                    { value: '3months', label: 'Last 3 months' },
                                    { value: '6months', label: 'Last 6 months' },
                                    { value: 'custom', label: 'Custom' }
                                ]}
                            />
                        </div>
                        {selectedMonth === 'custom' && (
                            <div>
                                <Calendar
                                    id="range"
                                    className="dateFilter mr-2"
                                    value={customDate}
                                    dateFormat="dd/m/yy"
                                    onChange={(e: any) => {
                                        setCustomDate(e.value);
                                        loadingastrue();
                                        updateURL('custom', selectedType, selectedVehicle, e.value[0], e.value[1]);
                                    }}
                                    selectionMode="range"
                                    readOnlyInput
                                    placeholder="Select date"
                                />
                            </div>
                        )}
                        <div>
                            {customDate[0] && (
                                <Dropdown
                                    options={TypeOptions}
                                    value={selectedType}
                                    onChange={(e) => {
                                        setSelectedType(e.value);
                                        updateURL(selectedMonth, e.value, selectedVehicle, customDate[0], customDate[1]);
                                    }}
                                    placeholder="Select View Type"
                                    className="mr-2"
                                />
                            )}
                        </div>
                        <div>
                            <Dropdown
                                options={vehicleOptions}
                                value={selectedVehicle}
                                onChange={(e) => {
                                    setSelectedVehicle(e.value);
                                    updateURL(selectedMonth, selectedType, e.value, customDate[0], customDate[1]);
                                }}
                                placeholder="Vehicle"
                                className="mr-2"
                                filter
                                showClear
                            />
                        </div>
                        <div>
                            {selectedType && (
                                <MultiSelect
                                    options={filteredOptions}
                                    value={selectedExports}
                                    onChange={(e) => {
                                        loadingastrue();
                                        setSelectedExports(e.value);
                                    }}
                                    placeholder="Select items to export"
                                    filter
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={`col-12 ${selectedType === 'Summary' ? 'md:col-12 grid justify-content-between' : selectedType === 'Comparison' ? 'md:col-9' : 'md:col-8'}`} style={{ margin: 'auto' }}>
                    {(selectedType === 'OChart' || selectedType === 'Comparison') && selectedExports.length > 0 && (
                        <>
                            {appointmentLoading === true || logsLoading === true ? (
                                <div className="md:col-12" style={{ textAlign: 'center' }}>
                                    <ProgressSpinner />
                                </div>
                            ) : (
                                <>
                                    <Button disabled={dprogress === true ? true : false} onClick={downloadChart}>
                                        {dprogress === true ? 'Please wait...' : 'Download'}
                                    </Button>
                                    <br />
                                    {selectedExports.some((item) => item.name === 'Reimbursement Categories') && (
                                        <Message severity="info" text="CR: Cash Reimbursement" className="p-inline-message p-inline-message-info" style={{ background: 'none' }}>
                                            <span className="p-inline-message-icon pi pi-info-circle"></span>
                                        </Message>
                                    )}
                                    <Chart
                                        id="chart-container"
                                        ref={chartref}
                                        type="bar"
                                        data={chartData}
                                        options={chartOptions}
                                        plugins={[ChartDataLabels]}
                                        //  height="400px"
                                    />
                                </>
                            )}
                        </>
                    )}
                    {selectedType === 'Summary' &&
                        selectedExports.length > 0 &&
                        selectedExports
                            .filter((item: any) => ['Repair/Replacement', 'Cash Reimbursement'].includes(item.name))
                            .concat(selectedExports.filter((item) => !['Repair/Replacement', 'Cash Reimbursement'].includes(item.name)))
                            .map((item: any, key: number) => {
                                let data =
                                    item.name === 'Service'
                                        ? appointmentReport.filter((item: any) => item.eventtype === 'Service')
                                        : item.name === 'Repair/Replacement'
                                        ? appointmentReport.filter((item: any) => item.eventtype === 'Repair/Replacement')
                                        : item.name === 'Branding Expense'
                                        ? appointmentReport.filter((item: any) => item.eventtype === 'Branding Expense')
                                        : item.name === 'Accident'
                                        ? appointmentReport.filter((item: any) => item.eventtype === 'Accident')
                                        : item.name === 'Salik'
                                        ? logsReport.filter((item: any) => item.Salik !== null && item.Salik !== '')
                                        : item.name === 'Vipfuel'
                                        ? logsReport.filter((item: any) => item.Vipfuel !== null && item.Vipfuel !== '')
                                        : item.name === 'Mileage'
                                        ? logsReport.filter((item: any) => item.Mileage !== null && item.Mileage !== '')
                                        : item.name === 'Fine'
                                        ? logsReport.filter((item: any) => item.Fine !== null && item.Fine !== '')
                                        : item.name === 'Parking'
                                        ? logsReport.filter((item: any) => item.Parking !== null && item.Parking !== '')
                                        : item.name === 'Cash Reimbursement'
                                        ? reimburseReport
                                        : item.name === 'Insurance'
                                        ? RenewalReport.filter((item: any) => item.renewaltype === 'Insurance' && item.totalamount !== null)
                                        : item.name === 'Advertisement'
                                        ? contractReport.filter((item: any) => item.subtype && item.subtype === 'Advertisement')
                                        : allReports;
                                //RenewalReport
                                let cost =
                                    item.name === 'Service'
                                        ? appointmentReport.filter((item: any) => item.eventtype === 'Service').reduce((acc: any, serviceItem: { cost: any }) => acc + Number(serviceItem.cost), 0)
                                        : item.name === 'Repair/Replacement'
                                        ? appointmentReport.filter((item: any) => item.eventtype === 'Repair/Replacement').reduce((acc: any, repairItem: { cost: any }) => acc + Number(repairItem.cost), 0)
                                        : item.name === 'Branding Expense'
                                        ? appointmentReport.filter((item: any) => item.eventtype === 'Branding Expense').reduce((acc: any, repairItem: { cost: any }) => acc + Number(repairItem.cost), 0)
                                        : item.name === 'Accident'
                                        ? appointmentReport.filter((item: any) => item.eventtype === 'Accident').reduce((acc: any, accidentItem: { cost: any }) => acc + Number(accidentItem.cost), 0)
                                        : item.name === 'Salik'
                                        ? logsReport.filter((item: any) => item.Salik !== null && item.Salik !== '').reduce((acc: any, salikItem: { Salik: any }) => acc + Number(salikItem.Salik), 0)
                                        : item.name === 'Vipfuel'
                                        ? logsReport.filter((item: any) => item.Vipfuel !== null && item.Vipfuel !== '').reduce((acc: any, salikItem: { Vipfuel: any }) => acc + Number(salikItem.Vipfuel), 0)
                                        : item.name === 'Mileage'
                                        ? logsReport.filter((item: any) => item.Mileage !== null && item.Mileage !== '').reduce((acc: any, salikItem: { Mileage: any }) => acc + Number(salikItem.Mileage), 0)
                                        : item.name === 'Fine'
                                        ? logsReport.filter((item: any) => item.Fine !== null && item.Fine !== '').reduce((acc: any, salikItem: { Fine: any }) => acc + Number(salikItem.Fine), 0)
                                        : item.name === 'Parking'
                                        ? logsReport.filter((item: any) => item.Parking !== null && item.Parking !== '').reduce((acc: any, salikItem: { Parking: any }) => acc + Number(salikItem.Parking), 0)
                                        : item.name === 'Cash Reimbursement'
                                        ? reimburseReport.filter((item: any) => item.amount !== null && item.amount !== '').reduce((acc: any, salikItem: { amount: any }) => acc + Number(salikItem.amount), 0)
                                        : item.name === 'Insurance'
                                        ? RenewalReport.filter((item: any) => item.totalamount !== null && item.totalamount !== '').reduce((acc: any, salikItem: { totalamount: any }) => acc + Number(salikItem.totalamount), 0)
                                        : item.name === 'Advertisement'
                                        ? contractReport.filter((item: any) => item.subtype && item.subtype === 'Advertisement')
                                        : allReports;
                                // console.log(RenewalReport);

                                //RenewalReport
                                //Field names to include in the column
                                const selectedFields =
                                    item.name === 'Service' || item.name === 'Repair/Replacement' || item.name === 'Branding Expense'
                                        ? ['vehicleid', 'Date', 'place', 'cost', 'appointmentstatus']
                                        : item.name === 'Accident'
                                        ? ['vehicleid', 'Date', 'accidenthappenedat', 'accidenttype', 'appointmentstatus']
                                        : item.name === 'Salik' || item.name === 'Vipfuel' || item.name === 'Fine' || item.name === 'Mileage' || item.name === 'Parking'
                                        ? ['vehicleid', 'date', 'driver.name', item.name === 'Vipfuel' ? 'Vipfuel' : item.name]
                                        : item.name === 'Cash Reimbursement'
                                        ? ['vehicleid', 'date', 'driver.name', 'description', 'expensetype', 'amount', 'paidto']
                                        : item.name === 'Insurance'
                                        ? ['vehicleid', 'issuedate', 'expirydate', 'renewalno', 'totalamount']
                                        : item.name === 'Advertisement'
                                        ? ['vehicleid', 'issuedate', 'expirydate', 'policyno', 'renewalstatus']
                                        : ['Vehicle'];

                                // Header names for the field
                                const selectedHeader =
                                    item.name === 'Service' || item.name === 'Repair/Replacement' || item.name === 'Branding Expense'
                                        ? ['Vehicle', 'Date', 'Place', 'Cost', 'Status']
                                        : item.name === 'Accident'
                                        ? ['Vehicle', 'Date', 'Location', 'Accident Type', 'Status']
                                        : item.name === 'Salik' || item.name === 'Vipfuel' || item.name === 'Fine' || item.name === 'Mileage' || item.name === 'Parking'
                                        ? ['Vehicle', 'Date', 'Driver Name', item.name === 'Vipfuel' ? 'VIP Fuel' : item.name]
                                        : item.name === 'Cash Reimbursement'
                                        ? ['Vehicle', 'Date', 'Driver Name', 'Description', 'Type', 'Amount', 'Paid To']
                                        : item.name === 'Insurance'
                                        ? ['Vehicle', 'Issue Date', 'Expiry Date', 'Policy No', 'Amount']
                                        : item.name === 'Advertisement'
                                        ? ['Vehicle', 'Issue Date', 'Expiry Date', 'Policy No', 'Status']
                                        : ['Vehicle'];

                                return (
                                    <div
                                        className={`col-12 md:col-6 mb-4 ${item.name === 'Repair/Replacement' || item.name === 'Cash Reimbursement' ? 'col-12 md:col-12' : 'responsive_table_card'}`}
                                        style={{
                                            boxShadow: '0px 0px 10px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12)'
                                        }}
                                    >
                                        {/* remove the below condition code to hide the spares grouped report */}
                                        {/* the below table is only for Vendor Group spares Report */}
                                        {item.name === 'Repair/Replacement' && (
                                            <>
                                                <DataTable
                                                    key={key}
                                                    value={data.flatMap((obj: any) => obj.spares && obj.spares.items)}
                                                    loading={item.name === 'Repair/Replacement' && appointmentLoading === true ? true : false}
                                                    // header={Commandbar}
                                                    header={
                                                        <div className="p-toolbar p-component p-0">
                                                            <div className="p-toolbar-group-start p-toolbar-group-left">
                                                                <h5>
                                                                    <b>Vendor Grouped Spares Report</b>
                                                                    <span> (Total: {data.flatMap((obj: any) => obj.spares && obj.spares.items).length})</span>
                                                                </h5>
                                                            </div>
                                                            <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                                                <div
                                                                    title="Action"
                                                                    className="pi pi-ellipsis-v p-3"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={(e) => {
                                                                        actionMenu.current.toggle(e);
                                                                        setExportName('Vendor Grouped');
                                                                        setExportData(data.flatMap((obj: any) => obj.spares && obj.spares.items));
                                                                    }}
                                                                />
                                                                <Menu
                                                                    ref={actionMenu}
                                                                    popup
                                                                    model={[
                                                                        {
                                                                            label: 'Export',
                                                                            icon: 'pi pi-file-export',
                                                                            template: (
                                                                                <CSVLink
                                                                                    className="p-menuitem-link"
                                                                                    data={exportData.length > 0 ? exportdata(exportData, exportName, selectedFields, selectedHeader) : allReports}
                                                                                    filename={
                                                                                        customDate.length > 0
                                                                                            ? `Report_Builder_${exportName}_${selectedType}_${convertdate(customDate[0])}${customDate[1] ? '_to_' + convertdate(customDate[1]) : ''}`
                                                                                            : `Report_Builder_${exportName}_${selectedType}_${convertdate(customDate[0])}${customDate[1] ? '_to_' + convertdate(customDate[1]) : ''}` + '.csv'
                                                                                    }
                                                                                >
                                                                                    <span className="p-menuitem-icon pi pi-file-export"></span>
                                                                                    Export
                                                                                </CSVLink>
                                                                            )
                                                                        },
                                                                        {
                                                                            label: 'Remove',
                                                                            icon: 'pi pi-times',
                                                                            command: () => {
                                                                                const updatedExports = selectedExports.filter((exportItem: any) => exportItem.name !== exportName);
                                                                                setSelectedExports(updatedExports);
                                                                            }
                                                                        }
                                                                    ]}
                                                                ></Menu>
                                                            </div>
                                                        </div>
                                                    }
                                                    className="p-datatable-responsive mb-4"
                                                    responsiveLayout="scroll"
                                                    rows={300}
                                                    scrollable
                                                    scrollHeight="60vh"
                                                    expandedRows={item.name === 'Repair/Replacement' ? expandedSparesRows : undefined}
                                                    onRowToggle={(e) => {
                                                        setExpandedSparesRows(e.data);
                                                    }}
                                                    rowGroupMode={item.name === 'Repair/Replacement' ? 'subheader' : ''}
                                                    groupRowsBy={item.name === 'Repair/Replacement' ? 'supplier' : ''}
                                                    sortField={item.name === 'Repair/Replacement' ? 'supplier' : ''}
                                                    expandableRowGroups={item.name === 'Repair/Replacement' ? true : false}
                                                    rowGroupHeaderTemplate={headerTemplate}
                                                >
                                                    <Column field={'vehicleid'} className="text-left p-message p-message-warn" header={'Vehicle'} body={actionGlobalTemplate} />
                                                    <Column field={'description'} className="text-left p-message p-message-warn" header={'Description'} body={actionGlobalTemplate} />
                                                    <Column field={'invoiceno'} className="text-left p-message p-message-warn" header={'Invoice No'} body={actionGlobalTemplate} />
                                                    <Column
                                                        field={'partname'}
                                                        className="text-left p-message p-message-warn"
                                                        header={'Part Name'}
                                                        body={(data) => {
                                                            const value = data.partname ? JSON.parse(data.partname) : '-';
                                                            return <>{value}</>;
                                                        }}
                                                    />
                                                    <Column field={'supplier'} className="text-left p-message p-message-warn" header={'Supplier'} body={actionGlobalTemplate} />
                                                    <Column field={'quantity'} className="text-left p-message p-message-warn" header={'Quantity'} body={actionGlobalTemplate} />
                                                    <Column field={'amount'} className="text-left p-message p-message-warn" header={'Amount'} body={actionGlobalTemplate} />
                                                    <Column
                                                        field={'totalamount'}
                                                        className="text-left p-message p-message-warn"
                                                        header={'Total Amount'}
                                                        body={(data) => {
                                                            const tax = data.jfield && JSON.parse(data.jfield).tax ? JSON.parse(data.jfield).tax : 0;
                                                            const totalAmountWithTax = Number(data.totalamount) * (1 + Number(tax) / 100);
                                                            return <>{totalAmountWithTax.toFixed(2)}</>;
                                                        }}
                                                    />
                                                    <Column
                                                        field={'tax'}
                                                        className="text-left p-message p-message-warn"
                                                        header={'Tax'}
                                                        body={(data) => {
                                                            const tax = data.jfield && JSON.parse(data.jfield).tax ? JSON.parse(data.jfield).tax : 0;
                                                            return <>{tax}%</>;
                                                        }}
                                                    />
                                                    <Column field={'status'} className="text-left p-message p-message-warn" header={'Status'} body={actionGlobalTemplate} />
                                                </DataTable>
                                                <hr />
                                            </>
                                        )}

                                        <DataTable
                                            key={key}
                                            value={data}
                                            loading={
                                                ((item.name === 'Repair/Replacement' || item.name === 'Accident' || item.name === 'Branding Expense') && appointmentLoading) ||
                                                (item.name === 'Advertisement' && contractLoading) ||
                                                (item.name === 'Insurance' && RenewaltLoading) ||
                                                (item.name === 'Cash Reimbursement' && reimburseLoading) ||
                                                (['Salik', 'Vipfuel', 'Mileage', 'Fine', 'Parking'].includes(item.name) && logsLoading) ||
                                                false
                                            }
                                            // header={Commandbar}
                                            header={
                                                <div className="p-toolbar p-component p-0">
                                                    <div className="p-toolbar-group-start p-toolbar-group-left">
                                                        <h5>
                                                            <b>{item.name}</b>
                                                            <span> (Total: {data.length})</span>
                                                        </h5>
                                                    </div>
                                                    <div className="p-toolbar-group-end p-toolbar-group-right spd-report">
                                                        <div
                                                            title="Action"
                                                            className="pi pi-ellipsis-v p-3"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={(e) => {
                                                                actionMenu.current.toggle(e);
                                                                setExportName(item.name);
                                                                setExportData(data);
                                                            }}
                                                        />
                                                        <Menu
                                                            ref={actionMenu}
                                                            popup
                                                            model={[
                                                                {
                                                                    label: 'Export',
                                                                    icon: 'pi pi-file-export',
                                                                    template: (
                                                                        <CSVLink
                                                                            className="p-menuitem-link"
                                                                            data={exportData.length > 0 ? exportdata(exportData, exportName, selectedFields, selectedHeader) : allReports}
                                                                            filename={
                                                                                customDate.length > 0
                                                                                    ? `Report_Builder_${exportName}_${selectedType}_${convertdate(customDate[0])}${customDate[1] ? '_to_' + convertdate(customDate[1]) : ''}`
                                                                                    : `Report_Builder_${exportName}_${selectedType}_${convertdate(customDate[0])}${customDate[1] ? '_to_' + convertdate(customDate[1]) : ''}` + '.csv'
                                                                            }
                                                                        >
                                                                            <span className="p-menuitem-icon pi pi-file-export"></span>
                                                                            Export
                                                                        </CSVLink>
                                                                    )
                                                                },
                                                                {
                                                                    label: 'Remove',
                                                                    icon: 'pi pi-times',
                                                                    command: () => {
                                                                        const updatedExports = selectedExports.filter((exportItem: any) => exportItem.name !== exportName);
                                                                        setSelectedExports(updatedExports);
                                                                    }
                                                                }
                                                            ]}
                                                        ></Menu>
                                                    </div>
                                                </div>
                                            }
                                            className="p-datatable-responsive"
                                            responsiveLayout="scroll"
                                            rows={300}
                                            scrollable
                                            scrollHeight="60vh"
                                            rowExpansionTemplate={item.name === 'Repair/Replacement' ? rowExpansionTemplate : undefined}
                                            expandedRows={item.name === 'Repair/Replacement' ? expandedRows : undefined}
                                            onRowToggle={(e) => setExpandedRows(e.data)}
                                        >
                                            {item.name === 'Repair/Replacement' && <Column expander />}
                                            {selectedFields.map((field, index) => {
                                                return (
                                                    <Column
                                                        key={index}
                                                        field={field}
                                                        header={selectedHeader[index]}
                                                        className="text-left"
                                                        sortable
                                                        body={(data) => {
                                                            if (field === 'driver.name') {
                                                                return <Link to={`/dashboard/driver?id=${data.driver && data.driver.id}`}>{data.driver && data.driver.name}</Link>;
                                                            } else {
                                                                const value = ['Date', 'date', 'issuedate', 'expirydate'].includes(selectedFields[index])
                                                                    ? data[field]
                                                                        ? convertdate(isotolocaledatetime(data[field]))
                                                                        : '-'
                                                                    : field.includes('.')
                                                                    ? data[field.split('.')[0]]
                                                                        ? data[field.split('.')[0]].name
                                                                        : '-'
                                                                    : data[field] !== null && !isNaN(data[field])
                                                                    ? Number(data[field]).toFixed(2)
                                                                    : data[field]
                                                                    ? data[field]
                                                                    : '-';
                                                                return <>{value}</>;
                                                            }
                                                        }}
                                                        // footer={
                                                        //     selectedHeader[index] === 'Vehicle'
                                                        //         ? 'Total'
                                                        //         : selectedHeader[index] === 'Cost' || selectedHeader[index] === 'Mileage' || selectedHeader[index] === 'Salik' || selectedHeader[index] === 'VIP Fuel' || selectedHeader[index] === 'Fine'
                                                        //         ? selectedHeader[index] === 'Mileage'
                                                        //             ? cost
                                                        //             : 'AED ' + cost
                                                        //         : ''
                                                        // }
                                                    />
                                                );
                                            })}
                                        </DataTable>
                                    </div>
                                );
                            })}
                </div>
            </div>
        </>
    );
}

export default ReportBuilders;
