import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import dxService from '../../Services/dxService';
import { DataView } from 'primereact/dataview';
import { Link } from 'react-router-dom';

let sparesID: any;
let VID: any;
function AppointmentExpense(props: any) {
    const location = window.location.href;
    const urlParams = new URLSearchParams(location.split('?')[1]);
    const [displayDocuments, setDisplayDocuments] = useState<any>([]);
    sparesID = urlParams.has('id') ? urlParams.get('id') : urlParams.has('sid') ? urlParams.get('sid') : '';
    VID = urlParams.has('vid') ? urlParams.get('vid') : '';
    //#region OnLoad methods
    // Calls method during the component load
    useEffect(() => {
        sparesID = props.appid ? props.appid : urlParams.get('id');
        VID = urlParams.get('vid');
        // sparesID !== '' && getDisplayDetails();
        getDocumentDetails();
    }, []);

    const getDocumentDetails = async (nextToken?: any) => {
        let filter = {
            appointmentid: {
                eq: sparesID
            }
        };

        let GetResponse = await dxService.listitems('vehiclespare', '*', filter, true, 100, nextToken);
        let newItems = GetResponse.items.filter((item: any) => item._deleted !== true);
        setDisplayDocuments((prevState: any) => [...prevState, ...newItems]);

        let nexttoken = GetResponse.nextToken;
        if (nexttoken) {
            getDocumentDetails(nexttoken);
        }
    };

    const AttachmentBodyTemplate = (e: any) => {
        if (typeof e.partname === 'string') {
            return <Link to={`/manage/spares/item/basic?mode=edit&id=${e.id}&parent=appointment`}>{JSON.parse(e.partname)}</Link>;
        } else if (typeof e.partname === 'object') {
            let partnames = JSON.parse(e.partname);
            const options = partnames.map((partName: any, index: number) => {
                return {
                    label: partName,
                    value: partName
                };
            });
            return <Dropdown options={options} value={partnames[0] || options[0].value} />;
        }
    };
    const invoiceNoBodyTemplate = (e: any) => {
        return <span className="">{e.invoiceno}</span>;
    };
    const statusBodyTemplate = (e: any) => {
        if (e.status !== null) {
            let status = e.status;
            return <>{status && <span className={`customer-badge status-${status.replaceAll(' ', '')}`}>{status}</span>}</>;
        }
    };
    const dvitemTemplate = (item: any) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-2 gap-4">
                    {/* <i className="pi pi-image p-overlay-badge" style={{ fontSize: '3rem' }}></i> */}
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-end flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <h6 className="p-0 m-0">{AttachmentBodyTemplate(item)}</h6>
                            Quantity: {item.quantity}
                        </div>
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">{'AED ' + item.amount}</div>
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">{item.jfield ? JSON.parse(item.jfield).tax : 0}%</div>
                        <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                            AED {item.totalamount !== 'NaN' && item.totalamount !== '' ? item.totalamount : 0}
                            {/* <Button icon="pi pi-download" className="p-button-rounded "></Button> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const expenseHeader = (
        <div className="col-12 p-0">
            <div className="flex flex-column xl:flex-row xl:align-items-start gap-4">
                <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-end flex-1 gap-4">
                    <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                        <div className="font-bold">Part Names</div>
                    </div>
                    <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                        <div className="font-bold">Unit Price</div>
                    </div>
                    <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                        <div className="font-bold">Tax Rate</div>
                    </div>
                    <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2 font-bold">
                        Amount
                        {/* <Button icon="pi pi-download" className="p-button-rounded "></Button> */}
                    </div>
                </div>
            </div>
        </div>
    );
    // const totalAmount = displayDocuments.reduce((total, item: any) => total + parseInt(item.totalamount), 0);
    let totalTax = 0;

    displayDocuments.forEach((item: any) => {
        const taxRate = item.jfield ? JSON.parse(item.jfield).tax : 0; // Extract tax rate from jfield property
        const amount = item.totalamount !== 'NaN' && item.totalamount !== '' ? Number(item.totalamount) : 0; // Extract amount
        const tax: any = amount * (taxRate / 100); // Calculate tax as a percentage of amount
        totalTax += tax; // Add tax to total tax
    });
    const totalAmount = displayDocuments.reduce((acc: any, item: any) => acc + (item.totalamount !== 'NaN' && item.totalamount !== '' ? Number(item.totalamount) : 0), 0); // Sum up the total amount
    const totalAmountWithTax = totalAmount + totalTax;
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <div className="card">
                    <h5>Expenses</h5>

                    <DataView value={displayDocuments} itemTemplate={dvitemTemplate} header={expenseHeader} emptyMessage="No expense added" />
                    <div className="p-dataview p-component p-dataview-list" style={{ textAlign: 'right' }}>
                        <h6 className="p-dataview-content pt-2 pb-1 mb-1">{totalAmount > 0 && 'Sub total:' + ' AED ' + totalAmount.toFixed(2)}</h6>
                        <h6 className="p-dataview-content pt-0 pb-1 mb-1 mt-0">{totalTax > 0 && 'Tax amount:' + ' AED ' + totalTax.toFixed(2)}</h6>
                        <h6 className="p-dataview-content pt-0 m-0">{totalAmountWithTax > 0 && 'Total amount:' + ' AED ' + totalAmountWithTax.toFixed(2)}</h6>
                    </div>
                    {/* <DataTable
                        value={displayDocuments}
                        dataKey="id"
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} contracts"
                        emptyMessage="No Documents added."
                        responsiveLayout="scroll"
                    >
                        <Column field="invoiceno" header="Invoice No" body={invoiceNoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="partname" header="Part names" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="supplier" header="Vendor" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="totalamount" header="Amount" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable> */}
                </div>
            </div>
        </div>
    );
}
export default AppointmentExpense;
