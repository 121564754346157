import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AmplifyServices from '../../Service/Service';
import { createDxDepartment, deleteDxDepartment, updateDxDepartment } from '../../graphql/mutations';
import { listDxCompanies, listDxDepartments } from '../../graphql/queries';
import ListnewForm from '../../Components/ListnewForm';

const DepartmentEditform = () => {
    const [DepartmentOptions, setDepartmentOptions] = useState<any>([]);

    const navigate = useNavigate();
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await AmplifyServices.getTableDetails(listDxCompanies, 'listDxCompanies').then(async (res: any) => {
            setDepartmentOptions(res);
        });
    };

    const BusinessUnitOption: any = [];
    const names = DepartmentOptions.filter((del: any) => del._deleted !== true).map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        // console.log(res);
        BusinessUnitOption.push(res);
    });

    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                name: 'name', // pass the exact column name of db
                value: 'Name',
                fieldtype: 'text',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'description', // pass the exact column name of db
                value: 'Description', //for display
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'name', // pass the exact column name of db
                value: 'Business Unit', //for display
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: BusinessUnitOption
            },
            {
                name: 'owner', // pass the exact column name of db
                value: 'Owner',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'member', // pass the exact column name of db
                value: 'Member',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            }
        ],
        fleettype: 'Department',
        getTable: listDxDepartments,
        createTable: createDxDepartment, //Pass create table name
        updateTable: updateDxDepartment, //Pass update table name
        deleteTableItem: deleteDxDepartment //Pass update table name
    };

    const submitForm = async (data: any) => {
        // console.log(props.columns);
        if (Object.keys(data).includes('_lastChangedAt')) delete data['_lastChangedAt'];

        let os = await API.graphql({
            query: formColumns.updateTable,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });

        let navigateUrl = '/admin/departments';
        navigate(navigateUrl);
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <ListnewForm columns={formColumns} layout="horizontal" getTableName="listDxDepartments" title="Edit Department - " submitForm={submitForm} />
            </div>
        </div>
    );
};

export default DepartmentEditform;
