import { API, graphqlOperation } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { createDxCompany,  deleteDxCompany,  updateDxCompany } from '../../graphql/mutations';
import { listDxCompanies } from '../../graphql/queries';
import ListnewForm from '../../Components/ListnewForm';

const CompanySettingsEditform = () => {
    const navigate = useNavigate();

    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                name: 'name', // pass the exact column name of db
                value: 'Name',
                fieldtype: 'text',
                required: true,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'description', // pass the exact column name of db
                value: 'Description', //for display
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'owner', // pass the exact column name of db
                value: 'Owner',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            }
        ],
        fleettype: 'Company',
        getTable: listDxCompanies,
        createTable: createDxCompany, //Pass create table name
        updateTable: updateDxCompany, //Pass update table name
        deleteTableItem: deleteDxCompany //Pass update table name
    };

    const submitForm = async (data: any) => {
        if (Object.keys(data).includes('_lastChangedAt')) delete data['_lastChangedAt'];
        let os = await API.graphql({
            query: formColumns.updateTable,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                input: data
            }
        });

        let navigateUrl = '/admin/businessunit';
        navigate(navigateUrl);
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <ListnewForm columns={formColumns} layout="horizontal" getTableName="listDxCompanies" title="Edit Business Form" submitForm={submitForm} />
            </div>
        </div>
    );
};

export default CompanySettingsEditform;
