import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import awsconfig from '../../aws-exports';
import { AdminRemoveUserFromGroupCommand, CognitoIdentityProviderClient, ListUsersCommand } from '@aws-sdk/client-cognito-identity-provider';
import { InputText } from 'primereact/inputtext';

// Get Group name from parent
const RemoveUser = (props: any) => {
    //set Delete status
    const [deleteStatus, setDeleteStatus] = useState('Remove');
    //all users in aws
    const [awsUser, setAwsUsers] = useState([]);

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = async () => {
        const REGION = awsconfig.aws_appsync_region;
        const usercredential = await Auth.currentUserCredentials();
        const client = new CognitoIdentityProviderClient({ region: REGION, credentials: usercredential });
        const command = new ListUsersCommand({ UserPoolId: 'ap-south-1_RmUExvWI7' });
        const response: any = await client.send(command);

        setAwsUsers(response.Users);

        // searchValues.push({});
    };

    const RemoveUser = () => {
        if (props.removeUser.length == 0) {
            alert('Please select User');
            props.hide(false);
        } else {
            for (let i = 0; i < props.removeUser.length; i++) {
                awsUser
                    .filter((name: any) => {
                        return name.Attributes.map((item: any) => item.Value).includes(props.removeUser[i]);
                    })
                    .map(async (item: any) => {
                        setDeleteStatus('Removing');
                        const REGION = awsconfig.aws_appsync_region;
                        const usercredential = await Auth.currentUserCredentials();
                        const cpclient = new CognitoIdentityProviderClient({ region: REGION, credentials: usercredential });
                        var params = {
                            UserPoolId: 'ap-south-1_RmUExvWI7',
                            GroupName: 'FleetSupervisors', //Replace Group nasme here
                            Username: item.Username
                        };

                        try {
                            const data = await cpclient.send(new AdminRemoveUserFromGroupCommand(params));
                            console.log('Success');
                            setDeleteStatus('Removed');
                            window.location.reload();
                        } catch (err) {
                            console.log(err);
                        }

                        if (props.removeUser.length == i + 1) {
                            props.hide(false);
                            props.selectedCheckBox([]);
                            // window.location.reload();
                        }
                    });
            }

            // setUserName('');
        }
    };
    return (
        <>
            <div className="card">
                <h5>Remove user from {props.group}</h5>
                <div className="grid p-fluid">
                    <div className="col-12 md:col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>

                            <InputText disabled placeholder="Username" value={props.removeUser} />
                        </div>
                    </div>

                    <div className="col-12 md:col-4">
                        <div className="p-inputgroup">
                            <Button onClick={RemoveUser} tooltip={'Click to Remove'}>
                                {deleteStatus}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RemoveUser;
