import React, { useState, useEffect, useRef, Children, useContext } from 'react';
import { Amplify, API, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Toast } from 'primereact/toast';
import { AuthContext } from '../../../AuthContext';
import { CognitoIdentityProviderClient, AdminAddUserToGroupCommand, AdminRemoveUserFromGroupCommand, ListGroupsCommand, AdminListGroupsForUserCommand, AdminUpdateUserAttributesCommand } from '@aws-sdk/client-cognito-identity-provider';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../../dxgraphql/dxqueries';
import AWS from 'aws-sdk';
import awsExports from '../../../aws-exports';
import ListTemplate from '../../../Components/ListTemplate';
import dxService from '../../../Services/dxService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import GetUser from '../../../Components/GetUser';
import { Menu } from 'primereact/menu';



const ManageUsers = () => {
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [group, setgroup] = useState<any>({});
    const [selectedrole, setselectedrole] = useState<any>({});
    const [selecteduser, setselecteduser] = useState<any>({});
    const [Displaydduser, setDisplaydduser] = useState(false);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);
    const [allgroups, setallgroups] = useState<any>("");
    const [appitem, setappitem] = useState<any>({});
    const [newdata, setnewdata] = useState<any>({});
    const toast = useRef(null);
    const [uname, setUname] = useState('');
    const [name, setname] = useState('');
    const [groupitems, setgroupitems] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [tableFilters, setTableFilters] = useState<any>('');
    const actionmenu = useRef<any>(null);
    const location1 = useLocation();
    const urlParams = new URLSearchParams(location1.search);
    var formID = urlParams.has('id') ? urlParams.get('id') : '';

    useEffect(() => {
        getdata();



    }, []);

    useEffect(() => {


    }, [appitem]);

    const columns = [
        // { field: 'id', showfield: 'username', header: 'User Name', fieldtype: 'href', path: '#/admin/erpusers?id=', filterOption: true, filterFieldOption: 'name' },
        { field: 'userprofile', header: 'User Name', showfield: 'displayname', fieldtype: 'user' },
        { field: 'description', header: 'Description', fieldtype: 'text', filterOption: true, filterFieldOption: 'mobileno' },
        { field: '_lastChangedAt', header: 'Modified', fieldtype: 'date', filterOption: true, filterFieldOption: 'mobileno' }
    ];

    const AddUserDialogFooter = (
        <>


            <Button label="Add User" onClick={(e: any) => {
                adduserrole(awsExports.aws_user_pools_id); setDisplaydduser(false);
            }}
                icon="pi pi-check" className="p-button-text" />

            <Button label="Cancel" onClick={(e: any) => {
                //removeuserrole(awsExports.aws_user_pools_id); 
                setDisplaydduser(false)
            }}
                icon="pi pi-times" className="p-button-text" />
        </>
    );

    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };

    const deleteItemsDialogFooter = (
        <>

            <Button label="Yes" icon="pi pi-check" className="p-button-text " onClick={() => deleteItem()} />
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemsDialog} />
        </>
    );

    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">

                <Dialog header="Add Member" visible={Displaydduser} style={{ width: '30vw' }} modal footer={AddUserDialogFooter} onHide={() => setDisplaydduser(false)}>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <div className="card container-card">
                                <div className={`field col-12 md-col-6`}>
                                    <label htmlFor='' className='col-12 mb-2 md:col-2 md:mb-0'>
                                        User
                                    </label>
                                    <div className='col-12 md:col-10'>
                                        <GetUser value={selecteduser} onChange={((e: any) => { console.log(e); setselecteduser(e) })} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {selecteduser && <span>Are you sure you want to remove the selected users?</span>}
                    </div>
                </Dialog>

                <Button icon="pi pi-plus" label="Add Member" onClick={() => setDisplaydduser(true)} className="p-button-primary mr-2  newbtnhover" />
                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => actionmenu.current.toggle(e)} />

                <Menu
                    ref={actionmenu}
                    popup
                    model={[
                        {
                            //disabled: selecteduser.length > 0 ? false : true,
                            label: 'Remove User',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                // deleteItem();
                                console.log(selecteduser);
                                confirmDeleteSelected();
                            }
                        }
                    ]}
                />
            </div>

        </div>
    );



    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };




    const changedata = (props: any) => {
        console.log(props);
    };

    const addData = (props: any) => {
        console.log(props);
    };

    const getdata = async () => {
        let group = await dxService.getitembyid('roles', formID);
        setgroup(group);
        setselectedrole(group);
        getrolepermissions(group);
        console.log(group);
        let filter = {
            // _deleted : {
            //     eq: true
            // }
            dxRolesUserrolesId:{
                eq: formID
            }
        }
        let users = await dxService.listitems('userrole', '*', filter, true);
        console.log(users);
        setgroupitems(users);


    }

    const deleteItem = async () => {
        removeuserrole(awsExports.aws_user_pools_id);
        setDeleteItemsDialog(false);
    };

    const getrolepermissions = async (e: any) => {
        console.log(e);
        if (e) {
            let jsonvalue = JSON.parse(e.jfield);
            let keys = Object.keys(jsonvalue);
            let orvalue: any[] = [];
            keys.forEach((ele: any) => {
                console.log(ele);
                console.log(jsonvalue[ele]);
                let a = {
                    and: {
                        name:
                        {
                            eq: ele
                        },
                        mask: {
                            contains: jsonvalue[ele]
                        }
                    }
                };
                orvalue.push(a);
            });

            let str = orvalue.length > 0 ? {
                or: orvalue
            } : {};

            let b = await dxService.listitems('permissionlevels', '*', str);
            console.log(b);
            let _allgroups: any = [];
            b.forEach((g: any) => {
                let c = g.groups.split(";");
                c.forEach((_c: string) => {
                    _allgroups.push(_c);
                })

            });
            console.log(_allgroups);
            setallgroups({ groups: _allgroups });
        }
    }

    const addusergroup = async (AWSconfig: any, userPoolId: any, groupname: any, username: any) => {
        var params2 = {
            GroupName: groupname, /* required */
            UserPoolId: userPoolId, /* required */
            Username: username /* required */
        };
        const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);
        return cognitoIdp.adminAddUserToGroup(params2, function (err, data) {
            if (err) { console.log(err, err.stack); return err.message; } // an error occurred
            else { console.log(data); return data; } // successful response
        });
    }

    const removeusergroup = async (AWSconfig: any, userPoolId: any, groupname: any, username: any) => {
        var params2 = {
            GroupName: groupname, /* required */
            UserPoolId: userPoolId, /* required */
            Username: username /* required */
        };
        const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);
        return cognitoIdp.adminRemoveUserFromGroup(params2, function (err, data) {
            if (err) { console.log(err, err.stack); return err.message; } // an error occurred
            else { console.log(data); return data; } // successful response
        });
    }

    const adduserrole = async (userPoolId: any) => {

        let filter = {
            username: {
                eq: selecteduser.profiles.userid
            }
        };
        let getuserroles = await dxService.listitems('userrole', '*', filter, true);
        console.log(getuserroles);
        console.log(group);
        console.log(allgroups);
        if (group != null) {
            let input = {
                username: selecteduser.profiles.userid,
                userrole: JSON.stringify({ id: group.id, rolename: group.rolename, }),
                usergroups: JSON.stringify(allgroups),
                dxUserProfileUserrolesId: selecteduser.profiles.userid,
                dxRolesUserrolesId: group.id
            }
            console.log(input);
            let adduser = await dxService.createitem('userrole', input);
            console.log(adduser);

            if (allgroups.groups.length > 0) {
                Auth
                    .currentCredentials()
                    .then(async user => {
                        let AWSconfig = new AWS.Config({
                            apiVersion: '2016-04-18',
                            credentials: user,
                            region: awsExports.aws_cognito_region
                        });
                        allgroups.groups.forEach((gr: any) => {
                            addusergroup(AWSconfig, userPoolId, gr, selecteduser.profiles.userid).then((resp: any) => {
                                if (resp.response && resp.response.error) {
                                    console.log(resp.response.error);
                                }
                                getdata();
                                console.log(resp.response);
                            }).catch((err) => {
                                console.log(err);
                            });
                        });
                    });
            }
        }
    }


    const removeuserrole = async (userPoolId: any) => {
        console.log(selecteduser);
        console.log(selectedrole);

        if (selecteduser.length > 0) {
            let cuser: any = selecteduser[0];
            let usergroups = cuser.usergroups;
            let jusergroups = JSON.parse(usergroups)
            console.log(jusergroups);
            console.log(jusergroups.groups);

            if (selecteduser.length > 0) {
                let userrole = selecteduser[0];
                let deleteuserrole = await dxService.deleteitem('userrole', userrole.id, userrole._version);
                console.log(deleteuserrole);
            }

            if (jusergroups.groups.length > 0) {

                Auth
                    .currentCredentials()
                    .then(async user => {
                        let AWSconfig = new AWS.Config({
                            apiVersion: '2016-04-18',
                            credentials: user,
                            region: awsExports.aws_cognito_region
                        });
                        jusergroups.groups.forEach((gr: any) => {

                            removeusergroup(AWSconfig, userPoolId, gr, cuser.username).then((resp: any) => {
                                if (resp.response && resp.response.error) {
                                    console.log(resp.response.error);
                                } else {
                                    console.log(resp.response);
                                    getdata();

                                }

                            }).catch((err: any) => {
                                console.log(err);
                            })
                        })

                    });
            }
        }
    }

    // if(selecteduser.length > 0){
    //     let userrole = selecteduser[0];
    //     let deleteuserrole = await dxService.deleteitem('userrole',userrole.id, userrole._version);
    //     console.log(deleteuserrole);
    // }

    // if (selecteduser.length > 0) {
    //     let user = selecteduser[0];

    //     console.log(selecteduser);
    //     console.log(selectedrole);
    //     console.log(selectedItems);
    //     let userid = user.userprofile.userid;
    //     let filter = {
    //         username: {
    //             eq: userid
    //         }
    //     }
    //     let getuserroles = await dxService.listitems('userrole', '*', filter);
    //     console.log(getuserroles);
    //     getuserroles.filter((ele: any) => {
    //         return JSON.parse(ele.userrole).rolename === selectedrole.rolename
    //     });
    //     console.log(getuserroles);
    //     if (getuserroles.length > 0) {
    //         let a = JSON.parse(getuserroles[0].usergroups);
    //         console.log(a);
    //         if (a.groups.length > 0) {

    //             Auth
    //                 .currentCredentials()
    //                 .then(async user => {
    //                     let AWSconfig = new AWS.Config({
    //                         apiVersion: '2016-04-18',
    //                         credentials: user,
    //                         region: awsExports.aws_cognito_region
    //                     });
    //                     allgroups.groups.forEach((gr: any) => {

    //                         removeusergroup(AWSconfig, userPoolId, gr, userid).then((resp: any) => {
    //                             if (resp.response && resp.response.error) {
    //                                 console.log(resp.response.error);
    //                             }else{
    //                                 console.log(resp.response);

    //                             }

    //                         }).catch((err: any) => {
    //                             console.log(err);
    //                         })
    //                     })

    //                 });
    //         }
    //     }
    // }


    return (

        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{group ? group['rolename'] : ''} Members</h4>

                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}

                            <ListTemplate
                                header="Manage Users"
                                value={groupitems}
                                paginator={true}
                                rows={10}
                                filters={tableFilters}
                                dynamicColumns={columns}
                                emptyMessage="No drivers found."
                                responsiveLayout="scroll"
                                className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Drivers"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[5, 10, 25]}
                                selection={selecteduser}
                                multiselect={true}
                                //   selectedItems={selectedProducts}
                                Commandbar={Commandbar}
                                onSelectionChange={(e: any) => { console.log(e); setselecteduser(e.value) }}
                                selectedItems={setselecteduser}
                            />

                            {/* <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                <div className="surface-ground">
                                    <div className="grid">
                                        <div className="col-12 lg:col-4 p-3">

                                        </div>

                                    </div></div></div> */}


                            {/* END Inner COMPONENT HTML */}

                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}




            </div>
        </div>

    );
};
export default ManageUsers;

