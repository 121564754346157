import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AmplifyServices from '../../Service/Service';
import { createDxContract, createDxDriver, deleteDxDriver, deleteDxVehicle, updateDxDriver, updateDxVehicle } from '../../graphql/mutations';
import { listDxDrivers, listDxGeneralConfigs, listDxVehicles } from '../../graphql/queries';
import ListnewForm from '../../Components/ListnewForm';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Storage } from 'aws-amplify';
import VehicleDocumentEditForm from './DocumentEditform';
import moment from 'moment';
var data: any = [];
var Documents: any = [];
var DocumentsObject: any = {};
const VehicleEditForm = () => {
    const [VehicletableData, setVehicletableData] = useState<any>([]);
    const [AddDocuments, setAddDocuments] = useState<any>([]);
    const [dropdownItem, setDropdownItem] = useState<any>('');
    const [startDate, setstartDate] = useState<any>('');
    const [EndDate, setEndDate] = useState<any>(null);
    const [Data, setData] = useState<any>([]);
    const [selectedmakeDropdown, setselectedmakeDropdown] = useState<any>(null);
    const [GetmodelDropdown, setGetmodelDropdown] = useState<any>(null);
    const [VehicleMakename, setVehicleMakename] = useState<any>([]);
    const [VehicleModelname, setVehicleModelname] = useState<any>([]);
    const [File, setFile] = useState<any>('');
    const location = window.location.href;
    const toast = useRef<any>(null);
    // const navigate = useNavigate();
    const urlParams = new URLSearchParams(location);
    var editId = urlParams.has('id') ? urlParams.get('id') : '';
    var formType = urlParams.has('form') ? urlParams.get('form') : '';
    const navigate = useNavigate();
    useEffect(() => {
        getData();
        getMakeModel();
    }, []);

    const getMakeModel = async () => {
        await AmplifyServices.getTableDetails(listDxGeneralConfigs, 'listDxGeneralConfigs').then(async (res: any) => {
            let Make = res.filter((dept: any) => dept.fleettype.includes('Make'));
            let Model = res.filter((dept: any) => dept.fleettype.includes('Model'));
            setVehicleMakename(Make);
            setVehicleModelname(Model);
        });
    };
    const getData = async () => {
        Storage.list(`vehicle/${editId}/`) // for listing ALL files without prefix, pass '' instead
            .then((result: any) => {
                let EditDocuments: any = [];
                result.results.map(async (item: any) => {
                    // item.push(EditDocuments)
                    const signedURL = await Storage.get(item.key);

                    item.downLoadUrl = signedURL;

                    if (item.key.includes('Registration#')) {
                        item.documentType = 'Registration / Milkia';
                        item.name = item.key.split('#')[2];
                        item.Year = item.key.split('#')[1];
                        EditDocuments.push(item);
                    } else if (item.key.includes('ServiceContract#')) {
                        item.documentType = 'Service Contracts';
                        item.name = item.key.split('#')[2];
                        item.Year = item.key.split('#')[1];
                        EditDocuments.push(item);
                    } else if (item.key.includes('Insurance#')) {
                        // console.log('contains');
                        item.documentType = 'Milkia Insurance Returns';
                        item.name = item.key.split('#')[2];
                        item.Year = item.key.split('#')[1];
                        EditDocuments.push(item);
                    } else if (item.key.includes('RTADocuments#')) {
                        // console.log('contains');
                        item.documentType = 'RTA Test documents';
                        item.name = item.key.split('#')[2];
                        item.Year = item.key.split('#')[1];
                        EditDocuments.push(item);
                    } else if (item.key.includes('Waranty#')) {
                        // console.log('contains');
                        item.documentType = 'Waranty documents';
                        item.name = item.key.split('#')[2];
                        item.Year = item.key.split('#')[1];
                        EditDocuments.push(item);
                    }
                    // console.log(EditDocuments);
                    setAddDocuments(EditDocuments);
                });

                // console.log(result.results);
            })

            .catch((err) => console.log(err));
        // console.log(EditData);
    };
    // const DepartmentNameOption: any = [];
    // const names = DepartmentOptions.map((res: any) => {
    //     res['label'] = res.name;
    //     res['value'] = res.name;
    //     // console.log(res);
    //     DepartmentNameOption.push(res);
    // });
    const BusinessNameOption: any = [];
    const vehicleMake: any = [];
    const Makename = VehicleMakename.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        // console.log(res);
        vehicleMake.push(res);
    });
    const dropdownItems = [
        { name: 'Registration / Milkia', code: 'Registration' },
        { name: 'Service Contracts', code: 'ServiceContract' },
        { name: 'Milkia Insurance Returns', code: 'Insurance' },
        { name: 'RTA Test documents', code: 'RTADocuments' },
        { name: 'Waranty documents', code: 'Waranty' }
    ];
    const vehicleModel: any = [];
    const Modelname = VehicleModelname.map((res: any) => {
        res['label'] = res.name;
        res['value'] = res.name;
        // console.log(res);
        vehicleModel.push(res);
    });
    // console.log(GetmodelDropdown);

    const ManagevehicleColumns = {
        columns: [
            {
                name: 'make',
                value: 'Make',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data: vehicleMake
            },
            {
                name: 'model',
                value: 'Model',
                fieldtype: 'dropdown',
                required: false,
                disabled: false,
                hidden: false,
                data:
                    selectedmakeDropdown !== null
                        ? vehicleModel.filter((res: any) => res.label.toLowerCase().includes(selectedmakeDropdown.toLowerCase()))
                        : GetmodelDropdown !== null
                        ? vehicleModel.filter((res: any) => res.label.toLowerCase().includes(GetmodelDropdown.make.toLowerCase()))
                        : []
            },
            {
                name: 'platecode',
                value: 'PlateCode',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'plateno',
                value: 'PlateNumber',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },

            {
                name: 'year',
                value: 'year',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'departmentname',
                value: 'Department',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'status',
                value: 'Status',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'capacity',
                value: 'Capacity',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'ChassisNo',
                value: 'Chassis No',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'LicensePlate',
                value: 'License Plate',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'RegistrationDate',
                value: 'Registration Date',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'ExpiryDate',
                value: 'Expiry Date',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'SalikNumber',
                value: 'Salik Number',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'SalikPIN',
                value: 'Salik PIN',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'FuelCardNumber',
                value: 'Fuel Card Number',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'Owner',
                value: 'Owner',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'SCExpiryDate',
                value: 'Service Contract Expiry Date',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'Warantyexpirydate',
                value: 'Waranty expiry date',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: false,
                data: []
            },
            {
                name: 'Documents',
                value: 'Documents',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'ImagePath',
                value: 'ImagePath',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            },
            {
                name: 'policyno',
                value: 'policyno',
                fieldtype: 'text',
                required: false,
                disabled: false,
                hidden: true,
                data: []
            }
        ],
        fleettype: 'vehicle',
        getTable: listDxVehicles,

        updateTable: updateDxVehicle,
        deleteTableItem: deleteDxVehicle
    };

    const save = () => {
        // setState(prevState => ({
        //     ...prevState,
        //     fName: 'your updated value here'
        //  }));
        var DocumentType = dropdownItem.name;
        var DocumentTypeName = dropdownItem.code;
        var FileType = File;

        var startdate = startDate;
        var enddate = EndDate;

        // console.log(DocumentType,FileType);

        if (DocumentType !== undefined && FileType !== '') {
            var uniqueIds: any = [];
            DocumentsObject.filename = File.name;
            DocumentsObject.filepath = File;
            // console.log(DocumentType);
            let PrefixImage = '';
            // console.log(startDate);

            let FormatStartDate = startDate != '' ? moment(startDate).format('yyyy') : '-';
            // moment(startDate).format('yyyy')
            if (DocumentType == 'Registration / Milkia') {
                PrefixImage = `Registration#${FormatStartDate}#`;
            } else if (DocumentType == 'Service Contracts') {
                PrefixImage = `ServiceContract#${FormatStartDate}#`;
            } else if (DocumentType == 'Milkia Insurance Returns') {
                PrefixImage = `Insurance#${FormatStartDate}#`;
            } else if (DocumentType == 'RTA Test documents') {
                PrefixImage = `RTADocuments#${FormatStartDate}#`;
            } else if (DocumentType == 'Waranty documents') {
                PrefixImage = `Waranty#${FormatStartDate}#`;
            }
            Documents.push({ Filename: PrefixImage + File.name, Filepath: File, documentType: DocumentTypeName, issueDate: startDate, expiryDate: enddate });
            // console.log(Documents);

            var filterdocumenttype = data.filter((data: any) => data.documentType.includes(DocumentType));
            // console.log(filterdocumenttype);

            if (filterdocumenttype.length > 0) {
                // console.log('greater');
                // console.log(filterdocumenttype);
                filterdocumenttype[0].File = FileType;
                let allobj = Object.assign({}, ...filterdocumenttype);
                data.push(allobj);
                // console.log(data);
                const obj = { documentType: DocumentType, File: FileType, year: FormatStartDate, startdate: startdate, enddate: enddate };
                // console.log(obj);
                var alldata: any = data.push(obj);
                setData(data);
            } else {
                const obj = { documentType: DocumentType, File: FileType, year: FormatStartDate, startdate: startdate, enddate: enddate };
                // console.log(obj);
                var alldata: any = data.push(obj);
                // console.log('not selected');
                setData(data);
            }
            // console.log(data);
            setDropdownItem('');

            const file: any = document.querySelector('.file');
            file.value = '';
            setFile('');
            // setYear('');
            setstartDate('');
            setEndDate('');
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Please fill the Document Type and Attachment',
                life: 3000
            });
        }
    };
    const DocumentTypeBodyTemplate = (rowData: any) => {
        // console.log(rowData.documentType);
        // console.log(rowData);

        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.documentType}
            </>
        );
    };
    const AttachmentBodyTemplate = (rowData: any) => {
        // console.log(rowData);
        // var attachment=Param=='add'?(<>
        // <span className="p-column-title">Code</span>
        // {rowData.File.name !== undefined ? rowData.File.name : ''}
        // </>):Param=='edit'?(<>
        //     <span className="p-column-title">Code</span>
        //     <a href={rowData.downLoadUrl}>{rowData.name}</a>

        // </>):''
        var attachment =
            rowData.name !== undefined ? (
                <>
                    <span className="p-column-title">Code</span>
                    <a href={rowData.downLoadUrl}>{rowData.name}</a>
                </>
            ) : (
                <>
                    <span className="p-column-title">Code</span>
                    {rowData.File.name}
                </>
            );
        return <>{attachment}</>;
    };
    const EditVehicleData = async () => {
        // console.log(Documents);
        var UploadedFiles: any = [];
        Documents.length > 0
            ? Documents.map(async (docfile: any, key: any) => {
                  //   console.log(docfile);

                  var StoreDocuments = await Storage.put('vehicle/' + GetmodelDropdown.id + '/' + docfile.Filename, docfile.Filepath);

                  UploadedFiles.push(StoreDocuments);
                  //Adding it to the ContractTable

                  let FleettypeFilter = {
                      id: {
                          eq: `${urlParams.get('id')}` // filter priority = 1
                      },
                      fleettype: {
                          eq: 'vehicle#activity'
                      }
                  };
                  let GetResponse: any = await API.graphql({
                      query: listDxVehicles,
                      variables: { filter: FleettypeFilter }
                  });
                  let response: any = [GetResponse.data.listDxVehicles.items[0]].filter((key) => delete key['createdAt'] && delete key['updatedAt'] && delete key['_deleted']);
                  if (response.length > 0) {
                      response = Object.assign({}, ...response);
                      console.log(response);
                      let contractData: any = {};
                      contractData = {
                          id: Math.random().toString(36).substring(2, 9),
                          policyno: response.policyno,
                          issuedate: docfile.issueDate,
                          expirydate: docfile.expiryDate,
                          vehicleid: response.id,
                          renewaltype: docfile.documentType,
                          documents: StoreDocuments.key,
                          renewalstatus: 'Completed'
                          //   _version: 1
                      };
                      console.log(contractData);

                      let contract: any = await API.graphql(
                          graphqlOperation(createDxContract, {
                              input: contractData
                          })
                      );
                      console.log('New Contract created');

                      let data: any = Object.fromEntries(Object.entries(response).filter(([key, value]) => value !== null));

                      //   console.log(data);
                      if (data['expiring'] !== undefined) {
                          let ExpiringValue = JSON.parse(data['expiring']);
                          ExpiringValue[docfile.documentType] = {
                              issueDate: docfile.issueDate,
                              expiryDate: docfile.expiryDate,
                              documents: StoreDocuments.key,
                              status: 'Completed'
                          };
                          data['expiring'] = JSON.stringify(ExpiringValue);
                      } else {
                          data['expiring'] = JSON.stringify({
                              [docfile.documentType]: {
                                  issueDate: docfile.issueDate,
                                  expiryDate: docfile.expiryDate,
                                  documents: StoreDocuments.key,
                                  status: 'Completed'
                              }
                          });
                      }

                      if (Documents.length == key + 1) {
                          //   console.log(data);
                          if (Object.keys(data).includes('_lastChangedAt')) delete data['_lastChangedAt'];
                          let o = await API.graphql(
                              graphqlOperation(updateDxVehicle, {
                                  input: data
                              })
                          );
                          console.log('Vehicle activity Updated');
                          //Vehicle activity
                          let FleettypeFilter = {
                              id: {
                                  eq: `${urlParams.get('id')}` // filter priority = 1
                              },
                              fleettype: {
                                  eq: 'vehicle'
                              }
                          };
                          //vehicle
                          let GetVresponse: any = await API.graphql({
                              query: listDxVehicles,
                              variables: { filter: FleettypeFilter }
                          });
                          let vResponse: any = [GetVresponse.data.listDxVehicles.items[0]].filter((key) => delete key['createdAt'] && delete key['updatedAt'] && delete key['_deleted']);
                          if (vResponse.length > 0) {
                              vResponse = Object.assign({}, ...vResponse);
                              data['_version'] = vResponse._version;
                              data['fleettype'] = vResponse.fleettype;
                              let os = await API.graphql(
                                  graphqlOperation(updateDxVehicle, {
                                      input: data
                                  })
                              );
                              console.log('Vehicle Updated');
                          }
                      }
                  }
                  //   console.log('allfiles');
                  navigate('/manage/vehicles');
              })
            : navigate('/manage/vehicles');
    };
    const submitForm = async (data: any) => {
        if (Object.keys(data).includes('_lastChangedAt')) delete data['_lastChangedAt'];
        AmplifyServices.EditVehicleDetails(data)
            .then(async (res: any) => {})
            .catch((err) => {
                console.log(err);
            });
        // let navigateUrl = '/manage/drivers';
        // navigate(navigateUrl);
    };
    return (
        <div className="grid">
            {/* <div className='col-12 md:col-12'> */}
            <div className="col-12 md:col-6">
                <ListnewForm
                    setGetmodelDropdown={setGetmodelDropdown}
                    setselectedmakeDropdown={setselectedmakeDropdown}
                    ChangeBtn={'SaveForm'}
                    columns={ManagevehicleColumns}
                    layout="grid"
                    getTableName="listDxVehicles"
                    title="Edit Vehicle - "
                    submitForm={submitForm}
                />
            </div>
            <div className="col-12 md:col-6">
                {<VehicleDocumentEditForm data={AddDocuments} />}
                <div className="card">
                    <h5>Documents</h5>

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12">
                            <label htmlFor="name1">Document Type </label>
                            <Dropdown id="state" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} options={dropdownItems} optionLabel="name" placeholder="Select One"></Dropdown>
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="name1">Start Date</label>
                            <Calendar showIcon showButtonBar value={startDate} onChange={(e) => setstartDate(e.value)}></Calendar>
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="name1">End Date</label>
                            <Calendar showIcon showButtonBar value={EndDate} onChange={(e) => setEndDate(e.value)}></Calendar>
                        </div>
                        <div className="field col-9 md:col-10">
                            <label htmlFor="email1">Attachment</label>
                            {/* <FileUpload mode="basic"   name="demo[]" url="./upload.php" accept="image/*" maxFileSize={1000000} onUpload={(e)=>onUpload(e)} onSelect={(e)=>fileType(e)} /> */}
                            {/*<div className="container">
      <div className="button-wrap">
        <label className="button"  htmlFor="upload" >Upload File</label>
        <input type='file' className="file"  id="fileUpload"   onChange={(e:any)=>setFile(e.target.files[0].name)} />
      </div>
    </div> */}
                            {/* <form action="/form/sumbit" method="get">
      <label className="label">
      <input type='file' className="file"  id="upload"   onChange={(e:any)=>setFile(e.target.files[0].name)} />
        <span>Select a file</span>
      </label>
    </form> */}
                            <form>
                                {/* <label htmlFor="fileUpload">Upload file</label> */}
                                <input type="file" className="file" id="fileUpload" onChange={(e: any) => setFile(e.target.files[0])} />
                            </form>
                        </div>
                        <div className="field col-2 md:col-2" style={{ width: 'auto' }}>
                            <label htmlFor="email1"></label>
                            <label htmlFor="email1"></label>
                            <Button className="mr-2 mb-2" onClick={save}>
                                <i className="pi pi-plus" style={{ position: 'relative', left: '13px' }}></i>
                            </Button>
                        </div>
                        <div className="field col-12">
                            <div className="card">
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <DataTable
                                            value={Data}
                                            dataKey="id"
                                            rowsPerPageOptions={[5, 10, 25]}
                                            className="datatable-responsive"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            emptyMessage="No Documents added."
                                            responsiveLayout="scroll"
                                        >
                                            <Column field="year" header="Year" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            <Column field="documentType" header="Document Type" body={DocumentTypeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                            <Column field="File" header="Attachment" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field col-12 md:col-5"></div>
                        <div className="field col-12 md:col-4"></div>
                        <div className="field col-12 md:col-3">
                            {/* {FormBtn} */}
                            <Button label="Save Documents" className="mr-2 mb-2" onClick={EditVehicleData} />
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default VehicleEditForm;
