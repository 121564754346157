import { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import './Consumption.css';
import moment from 'moment';
import dxService from '../../Services/dxService';
import { CSVLink } from 'react-csv';
import { convertdate, getdriveroptionsName, getVehicleoptions, isotolocaledatetime, localetoisodateendtime, localetoisodatetime } from '../../utils/helper';
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
// import * as FileSaver from 'file-saver/src/FileSaver';
// const ExcelJS: any = require('excel.js');
var formatreport: any = [];
var dateformat: any = [];
const Consumption = () => {
    const [Reports, setReports] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [SelectedDate, setSelectedDate] = useState<any>([]);
    const [filtervehicleid, setfiltervehicleid] = useState<any>('');
    const [drivername, setDrivername] = useState<any>('');
    const [filteredData, setfilteredData] = useState<any>([]);
    const [NewfilteredData, setNewfilteredData] = useState<any>([]);
    const [startDateFormat, setStartDateFormat] = useState<string>('');
    const [endDateFormat, setEndDateFormat] = useState<string>('');
    const [formatdata, setformatdata] = useState<any>([]);
    const [driverdropdownOptions, setdriverdropdownOptions] = useState<any>([]);
    const [VehicleOptions, setVehicleOptions] = useState<any>([]);
    const [driverdropdown, setdriverdropdown] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var driver = urlParams.has('driver') ? urlParams.get('driver') : null;
    var vid = urlParams.has('vid') ? urlParams.get('vid') : null;
    var sdate: any = urlParams.has('sdate') ? urlParams.get('sdate') : '';
    var edate: any = urlParams.has('edate') ? urlParams.get('edate') : '';

    // The purpose of this useEffect hook is to retrieve the parameter values from the URL and set them to the component's state variables. It is executed when the component mounts.
    useEffect(() => {
        // Function to retrieve parameter values and update state variables
        const getParamsDetails = () => {
            if (driver !== null) {
                // if (drivername.length == 0) {
                setDrivername(driver);
            }
            if (vid !== null) {
                setfiltervehicleid(vid);
            }
            if (sdate !== '') {
                sdate = urlParams.has('sdate') ? urlParams.get('sdate') : '';
                edate = urlParams.has('edate') ? urlParams.get('edate') : '';
                let startDate = new Date(sdate);
                let endDate = edate ? new Date(edate) : startDate;
                setSelectedDate([startDate, endDate]);
            } else {
                let todate = new Date();
                let lastenddate = new Date(todate.getFullYear(), todate.getMonth(), 0);
                let lastfirstdate = new Date(todate.getFullYear(), todate.getMonth() - 1, 1);
                setSelectedDate([lastfirstdate, lastenddate]);
            }
        };

        // Call the function to retrieve parameter values
        getParamsDetails();
    }, []);

    // The purpose of this useEffect hook is to fetch data and update the driver and vehicle dropdown options based on the selected date. It is executed when the SelectedDate state variable changes.

    useEffect(() => {
        // Function to fetch data and update dropdown options
        getData().then(async (res) => {
            let VActivity: any = await getdriveroptionsName();
            let VaVehicle: any = await getVehicleoptions();
            setdriverdropdownOptions(VActivity);
            setVehicleOptions(VaVehicle);
            setLoading(false);
        });
    }, [SelectedDate]);

    const getData = async (nextToken?: any) => {
        const variables: any = {
            and: [
                {
                    date: {
                        ge: localetoisodatetime(SelectedDate[0])
                    }
                },
                {
                    date: {
                        le: SelectedDate[1] ? localetoisodateendtime(SelectedDate[1]) : localetoisodateendtime(SelectedDate[0])
                    }
                }
            ]
        };
        let response = [];
        let logs;
        do {
            logs = await dxService.listitems('masterlog', '*', variables, true, 1000, nextToken);
            const logsItems = logs.items || [];
            response.push(...logsItems);

            setReports((prevItems: any) => [...prevItems, ...logsItems]);
            setNewfilteredData((prevItems: any) => [...prevItems, ...logsItems]);

            nextToken = logs.nextToken;
        } while (nextToken);
        const uniqueLogsItems = response.reduce((unique: any[], obj: { id: any }) => (unique.some((item: { id: any }) => item.id === obj.id) ? unique : [...unique, obj]), []);
        setReports(uniqueLogsItems);
        setNewfilteredData(uniqueLogsItems);
        return response;
    };

    const header = [
        { label: 'Date', key: 'date' },
        { label: 'Vehicle', key: 'vehicle' },
        { label: 'Driver', key: 'driver' },
        { label: 'Km', key: 'km' },
        { label: 'Parking', key: 'parking' },
        { label: 'Salik', key: 'salik' },
        { label: 'Accidents', key: 'accidents' },
        { label: 'Fine', key: 'fine' },
        { label: 'Cash Reimbursement', key: 'Cash Reimbursement' },
        { label: 'Salik', key: 'salik' },
        { label: 'Vipfuel', key: 'vipfuel' }
    ];
    // The purpose of this useEffect hook is to filter the Reports data based on the selected filters (drivername, filtervehicleid, and SelectedDate). It is executed when either the Reports, drivername, or filtervehicleid state variables change.
    useEffect(() => {
        if (drivername !== undefined && drivername.length > 0 && SelectedDate.length == 0 && (filtervehicleid == undefined || filtervehicleid.length == 0)) {
            let fr = Reports.filter((driver: any) => {
                return driver.driver && driver.driver.name.includes(drivername);
            });
            setNewfilteredData(fr);
        } else if ((drivername !== undefined && drivername.length) > 0 && SelectedDate.length !== 0 && filtervehicleid !== undefined && filtervehicleid.length > 0) {
            let fr = Reports.filter((item: any) => {
                const startDate = moment(SelectedDate[0]).subtract(0, 'day').format('D-MMM-YY');
                const endDate = moment(SelectedDate[1]).add(1, 'day').format('D-MMM-YY');
                return moment(item.date).isBetween(startDate, endDate) && item.driver && item.driver.name.includes(drivername) && item.vehicleid.toLowerCase().includes(filtervehicleid.toLowerCase().toString());
            });
            exportFilenameDateFormat(SelectedDate);
            setNewfilteredData(fr);
        } else if ((drivername == undefined || drivername.length == 0) && SelectedDate.length !== 0 && filtervehicleid !== undefined && filtervehicleid.length > 0) {
            let fr = Reports.filter((item: any) => {
                const startDate = moment(SelectedDate[0]).subtract(0, 'day').format('D-MMM-YY');
                const endDate = moment(SelectedDate[1]).add(1, 'day').format('D-MMM-YY');
                return moment(item.date).isBetween(startDate, endDate) && item.vehicleid.toLowerCase().includes(filtervehicleid.toLowerCase().toString());
            });
            exportFilenameDateFormat(SelectedDate);
            setNewfilteredData(fr);
        } else if ((drivername == undefined || drivername.length == 0) && filtervehicleid !== undefined && filtervehicleid.length > 0 && SelectedDate.length == 0) {
            let fr = Reports.filter((driver: any) => {
                return driver.vehicleid.toLowerCase().includes(filtervehicleid.toLowerCase().toString());
            });
            setNewfilteredData(fr);
        } else if (drivername !== undefined && drivername.length > 0 && filtervehicleid !== undefined && filtervehicleid.length > 0) {
            let fr = Reports.filter((driver: any) => {
                return driver.vehicleid.toLowerCase().includes(filtervehicleid.toLowerCase().toString()) && driver.driver && driver.driver.name.includes(drivername);
            });
            setNewfilteredData(fr);
        } else if (SelectedDate.length == 2 && SelectedDate[0] !== null && SelectedDate[1] !== null && drivername !== undefined && drivername.length == 0) {
            const filteredReports = Reports.filter((item: any) => {
                if (SelectedDate.length === 0) {
                    return true;
                } else {
                    const startDate = moment(SelectedDate[0]).subtract(0, 'day').format('D-MMM-YY');
                    const endDate = moment(SelectedDate[1]).add(1, 'day').format('D-MMM-YY');
                    return moment(item.date).isBetween(startDate, endDate);
                }
            });
            exportFilenameDateFormat(SelectedDate);
            setNewfilteredData(filteredReports);
        } else if (SelectedDate.length > 0 && SelectedDate[0] !== null && SelectedDate[1] !== null && drivername !== undefined && drivername.length > 0) {
            let fr = Reports.filter((item: any) => {
                const startDate = moment(SelectedDate[0]).subtract(0, 'day').format('D-MMM-YY');
                const endDate = moment(SelectedDate[1]).add(1, 'day').format('D-MMM-YY');
                return moment(item.date).isBetween(startDate, endDate) && item.driver && item.driver.name.includes(drivername);
            });
            exportFilenameDateFormat(SelectedDate);
            setNewfilteredData(fr);
        } else if (drivername == undefined && SelectedDate.length == 2 && SelectedDate[0] !== null && SelectedDate[1] !== null) {
            const filteredReports = Reports.filter((item: any) => {
                if (SelectedDate.length === 0) {
                    return true;
                } else {
                    const startDate = moment(SelectedDate[0]).subtract(0, 'day').format('D-MMM-YY');
                    const endDate = moment(SelectedDate[1]).add(1, 'day').format('D-MMM-YY');
                    return moment(item.date).isBetween(startDate, endDate);
                }
            });
            setNewfilteredData(filteredReports);
        } else if ((SelectedDate.length == 0 || SelectedDate == '') && (drivername == undefined || drivername.length == 0) && (filtervehicleid == undefined || filtervehicleid.length == 0)) {
            setNewfilteredData(Reports);
        }
    }, [Reports, drivername, filtervehicleid]);
    const exportFilenameDateFormat = (SelectedDate: any) => {
        const startDate = SelectedDate[0];
        const endDate = SelectedDate[1];
        const startDateFormat = startDate ? moment(startDate).format('DD MMM YY') : '';
        const endDateFormat = endDate ? moment(endDate).format('DD MMM YY') : '';

        setStartDateFormat(startDateFormat);
        setEndDateFormat(endDateFormat);
    };

    const formatDatefield = (data: any) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };

    const ClearBtn = () => {
        setSelectedDate('');
        dateformat = [];
        setStartDateFormat('');
        setEndDateFormat('');
        dateformat = 0;
    };

    if (SelectedDate !== null && SelectedDate.length > 0) {
        var ShowClrBtn: any = (
            <>
                <button
                    onClick={ClearBtn}
                    className={`clrbtn`}
                    // style={{ display: toggle, position: "relative", bottom: "5px" }}
                >
                    <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" style={{ color: '#db0000', fontWeight: 700 }}></i>
                    &nbsp;
                </button>
            </>
        );
    }

    const actionTemplateParking = (rowData: any, column: any) => {
        let Classname = rowData.Parking >= 250 ? 'TotalColorcondition' : 'TotalColor';
        let num: any = rowData.Parking; // This is a string, not a number

        // Check if the input is a number and has more than two decimal places

        if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
            // Round off to two decimal places
            num = Number(Number(num).toFixed(2));
        }

        return (
            <>
                <a className={Classname} target="black">
                    {num !== null ? num : '-'}
                </a>
            </>
        );
    };
    const actionTemplateAccidents = (rowData: any, column: any) => {
        let Classname = rowData.CarWash > 250 ? 'TotalColorcondition' : 'TotalColor';

        return (
            <>
                <a className={Classname} target="black">
                    {rowData.Accidents ? rowData.Accidents : '-'}
                </a>
            </>
        );
    };
    const actionTemplateFuel = (rowData: any, column: any) => {
        let Classname = rowData.Vipfuel > 250 ? 'TotalColorcondition' : 'TotalColor';
        // let background=rowData.Total>'AED 300'?'#FFCDD2':''
        let num: any = rowData.Vipfuel; // This is a string, not a number

        // Check if the input is a number and has more than two decimal places

        if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
            // Round off to two decimal places
            num = Number(Number(num).toFixed(2));
        }

        return (
            <>
                <a className={Classname} target="black">
                    {num !== null ? num : '-'}
                </a>
            </>
        );
    };

    const actionTemplateFine = (rowData: any, column: any) => {
        let Classname = rowData.Fine > 250 ? 'TotalColorcondition' : 'TotalColor';
        // let background=rowData.Total>'AED 300'?'#FFCDD2':''
        return (
            <>
                <a className={Classname} target="black">
                    {rowData.Fine ? rowData.Fine : '-'}
                </a>
            </>
        );
    };

    //actionTemplateDate
    const actionTemplateSalik = (rowData: any, column: any) => {
        let Classname = rowData.Salik > 250 ? 'TotalColorcondition' : 'TotalColor';
        // let background=rowData.Total>'AED 300'?'#FFCDD2':''
        let num: any = rowData.Salik; // This is a string, not a number

        // Check if the input is a number and has more than two decimal places

        if (num !== null && !isNaN(num) && /\.\d{3,}/.test(num.toString())) {
            // Round off to two decimal places
            num = Number(Number(num).toFixed(2));
        }

        return (
            <>
                <a className={Classname} target="black">
                    {num !== null ? num : '-'}
                </a>
            </>
        );
    };
    const activerDriverTemplate = (rowData: any) => {
        return <Link to={`/dashboard/driver?id=${rowData.driver && rowData.driver.id}`}>{rowData.driver ? rowData.driver.name : ''}</Link>;
    };
    const SearchOnvehiclechange = (e: any) => {
        updateURL(drivername, e.value, SelectedDate[0], SelectedDate[1]);
        setfiltervehicleid(e.target.value);
    };
    const SearchOnDriverchange = async (e: any) => {
        updateURL(e.value, filtervehicleid, SelectedDate[0], SelectedDate[1]);
        setDrivername(e.target.value);
    };

    const formatDate = (value: any) => {
        let date = new Date(value);
        return date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
    const dateBodyTemplate = (rowData: any) => {
        //return formatDate(rowData.date);
        return <>{convertdate(isotolocaledatetime(rowData.date))}</>;
    };

    const dateFilterTemplate = (options: any) => {
        return <Calendar value={options.value} onChange={(e: any) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const exportdata = (data: any) => {
        let items: any = [];
        data.map((exportitem: any) => {
            let a = {
                Date: convertdate(isotolocaledatetime(exportitem.date)),
                Vehicle: exportitem.vehicleid,
                Driver: exportitem.driver ? exportitem.driver.name : exportitem.drivername,
                Mileage: exportitem.Mileage,
                Parking: exportitem.Parking,
                Accidents: exportitem.Accidents,
                Fine: exportitem.Fine,
                REIMBURSEMENT: exportitem.reimbursement,
                Salik: exportitem.Salik,
                Vipfuel: exportitem.Vipfuel
            };
            items.push(a);
        });
        return items;
    };

    const csvLink = {
        filename:
            SelectedDate.length > 0 && startDateFormat && endDateFormat
                ? `Consumption_${startDateFormat}-${endDateFormat}` + (drivername !== '' && drivername !== undefined ? `_${drivername}` : '') + (filtervehicleid !== '' ? `_${filtervehicleid}` : '')
                : 'Comsumption' + (drivername !== '' && drivername !== undefined ? `_${drivername}` : '') + (filtervehicleid !== '' ? `_${filtervehicleid}` : '') + '.csv',
        header: header,
        data:
            NewfilteredData.length > 0
                ? exportdata(
                      NewfilteredData.sort((a: any, b: any) => {
                          const dateA = Number(new Date(a.date));
                          const dateB = Number(new Date(b.date));
                          return dateB - dateA;
                      })
                  )
                : Reports
    };
    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="table-header-container">
                {/* <div className="field col-12 md:col-4"> */}
                <label htmlFor="range"></label>
                <Calendar
                    id="range"
                    className="dateFilter"
                    value={SelectedDate}
                    dateFormat="dd/m/yy"
                    onChange={(e: any) => {
                        updateURL(drivername, filtervehicleid, e.value[0], e.value[1]);
                        setSelectedDate(e.value);
                    }}
                    selectionMode="range"
                    readOnlyInput
                    placeholder="Select date"
                />

                {ShowClrBtn}
                {/* </div> */}
            </div>
            <div className="field col">
                <div className="field col">
                    {/* <Button></Button> */}
                    <CSVLink {...csvLink}>
                        <span className="p-float-label exporttext text-white">Export</span>
                    </CSVLink>
                </div>
            </div>
            <div>
                {/* <CsvDownloader
                    filename="myfile"
                    extension=".csv"
                    separator=";"
                    wrapColumnChar=""
                    // columns={columns}
                    datas={filteredData}
                    text="DOWNLOAD"
                /> */}

                {/* <button onClick={ExportExcel}>Export</button> */}
            </div>

            <div className="grid grid-nogutter ">
                <div className="col-12 p-fluid" style={{ textAlign: 'left' }}>
                    <div className="formgrid grid" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <div className="field col">
                            <div className="field col">
                                <span className="p-float-label">
                                    {/* <input value={drivername} type="text" id="txtsearchdriver" onChange={SearchOnDriverchange} placeholder="Search Driver..." className="p-inputtext p-component homeNews__search" />
                                    <label htmlFor="txtsearchdriver">Driver</label> */}
                                    <Dropdown showClear id="ddsearchstatus" filter value={drivername} options={driverdropdownOptions} optionLabel="label" placeholder="Filter by driver" onChange={SearchOnDriverchange} />
                                    <label htmlFor="ddsearchstatus">Driver</label>
                                </span>
                            </div>
                        </div>

                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown showClear id="ddsearchstatus" filter value={filtervehicleid} options={VehicleOptions} optionLabel="label" placeholder="Search Vehicle..." onChange={SearchOnvehiclechange} />
                                {/* <input value={filtervehicleid} type="text" id="txtsearchvehicleid" onChange={SearchOnvehiclechange} placeholder="Search Vehicle..." className="p-inputtext p-component homeNews__search" /> */}

                                <label htmlFor="txtsearchvehicleid">Vehicle</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}
        </div>
    );
    //set the values to the url
    const updateURL = (driver: any, vehicle: any, sdate: any, edate: any) => {
        const baseUrl = window.location.href.split('?')[0];
        const queryParams = new URLSearchParams(window.location.search);

        if (sdate) {
            queryParams.set('sdate', convertdate(sdate));
        } else {
            queryParams.delete('sdate');
        }
        if (edate) {
            queryParams.set('edate', convertdate(edate));
        } else {
            queryParams.delete('edate');
        }
        if (driver) {
            queryParams.set('driver', driver);
        } else {
            queryParams.delete('driver');
        }

        if (vehicle) {
            queryParams.set('vid', vehicle);
        } else {
            queryParams.delete('vid');
        }

        const newUrl = baseUrl + '?' + queryParams.toString();
        window.history.replaceState({}, '', newUrl);
    };
    return (
        <div className="grid">
            <div className="col-12">
                <h4>Consumption</h4>
                <div className="card">
                    <div className="MaintenanceReportTable">
                        <DataTable
                            value={
                                NewfilteredData.length > 0 &&
                                NewfilteredData.sort((a: any, b: any) => {
                                    const dateA = Number(new Date(a.date));
                                    const dateB = Number(new Date(b.date));
                                    return dateB - dateA;
                                })
                            }
                            loading={loading}
                            header={Commandbar}
                            className="p-datatable-responsive"
                            responsiveLayout="scroll"
                            paginator
                            rows={300}
                            selection={selectedProduct}
                            onSelectionChange={(e) => setSelectedProduct(e.value)}
                        >
                            <Column
                                field="date"
                                sortable
                                //  body={actionTemplateDate}
                                header="DATE"
                                dataType="date"
                                style={{ minWidth: '10rem' }}
                                body={dateBodyTemplate}
                                filter
                                filterElement={dateFilterTemplate}
                            />
                            <Column field="vehicleid" sortable header="VEHICLE" />
                            <Column field="driver.name" sortable body={activerDriverTemplate} header="DRIVER" />
                            <Column
                                field="Mileage"
                                header="KM TRAVELLED"
                                body={(rowData) => {
                                    return rowData.Mileage ? rowData.Mileage : '-';
                                }}
                            />
                            <Column field="Parking" body={actionTemplateParking} header="PARKING" />

                            <Column field="Accidents" body={actionTemplateAccidents} header="ACCIDENTS" />
                            <Column
                                field="reimbursement"
                                sortable
                                body={(data) => {
                                    return <>{data.reimbursement ? data.reimbursement : '-'}</>;
                                }}
                                header="REIMBURSEMENT (AED)"
                            />
                            <Column field="Fine" sortable body={actionTemplateFine} header="FINE (AED)" />

                            <Column field="Salik" sortable body={actionTemplateSalik} header="SALIK (AED)" />
                            <Column field="Vipfuel" sortable body={actionTemplateFuel} header="VIP FUEL (AED)" />
                            {/* <Column field="Salik" body={actionTemplateSalik} header="SALIK" /> */}
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Consumption;
