import { API } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { updateDxVehicle, updateDxAllocations, updateDxVehicleActivity, createDxDocument } from '../../graphql/mutations';
import { listDxVehicles, listDxAllocations, listDxVehicleActivities } from '../../graphql/queries';

import { _listDxVehicleActivities } from '../../dxgraphql/dxqueries';
import { Toast } from 'primereact/toast';
import { Storage } from 'aws-amplify';
import FormTemplate from '../../Components/FormTemplate';
import awsExports from '../../aws-exports';
import dxService from '../../Services/dxService';
import Loader from '../../Components/Loader';
import { Button as AwsButton } from '@aws-amplify/ui-react';
import { getallocateditemfromvehicleactivity, getcurrentallocationfromvehicleactivity, getdriveroptions, isotolocaledatetime, localetoisodatetime } from '../../utils/helper';
import Documents from '../Import/Documents';
import moment from 'moment';
var id: any;
var checkfromdriver: any = [];

const AddAllocation = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [updatedata, setupdatedata] = useState<any>([]);
    const [VehicleData, setVehicleData] = useState<any>([]);
    const [vactivityitem, setvactivityitem] = useState<any>(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [DrivernameOptions, setDrivernameOptions] = useState<any>([]);
    const [allocateditem, setVallocateditem] = useState<any>([]);
    const [currentallocationitem, setVcurrentallocationitem] = useState<any>([]);
    const [displayDialog, setdisplayDialog] = useState(false);
    const [jsonData, setJsonData] = useState<any>([]);
    const [allalocation, setallalocation] = useState<any>([]);
    const urlParams = new URLSearchParams(location.search);
    const toast = useRef<any>(null);
    var formid = urlParams.has('id') ? urlParams.get('id') : null;
    var type = urlParams.has('type') ? urlParams.get('type') : null;
    var aid = urlParams.has('aid') ? urlParams.get('aid') : null;
    //#region Onload methods
    //calls method during the component load
    useEffect(() => {
        getdata();
    }, [props.displayModal]);
    let allocationarr: any = [{ id: 'S12386' }];
    useEffect(() => {
        // console.log(vactivityitem);
        setlistFormData(vactivityitem);
        if (vactivityitem != null && vactivityitem.allocations) {
            let _allocations = vactivityitem.allocations;

            setVcurrentallocationitem(getcurrentallocationfromvehicleactivity(_allocations));
            setVallocateditem(getallocateditemfromvehicleactivity(_allocations));
            // console.log(vactivityitem);

            //checkfromdriver=> checking whether the driver is allocated to the vehicle or not
            checkfromdriver = vactivityitem;
            // console.log(checkfromdriver);
        }
    }, [vactivityitem]);

    useEffect(() => {
        if (allocateditem != null && allocateditem.length > 0) {
            if (allocateditem && allocateditem.length > 0) {
                setlistFormData({
                    FromDriverDate: isotolocaledatetime(allocateditem[0].fromdate),
                    dxDriverVehicleactivityId: allocateditem[0].fromdriverID,
                    FromKmMileage: allocateditem[0].fromKM,
                    FromLocation: allocateditem[0].fromlocation,
                    id: vactivityitem.id
                    //dxAllocationsActivityId: GetResponse[0].dxAllocationsActivityId
                });
            }
        }
    }, [allocateditem]);
    //#endregion

    //getting the vehicle details from the vehicle table based on vehicle id
    const getdata = async () => {
        let allocationsresponse = await dxService.listitems('allocation', '*', {}, false);
        console.log(allocationsresponse);
        setallalocation(allocationsresponse);
        let filter = {
            id: {
                eq: formid
            }
        };
        let GetResponse: any = formid && (await dxService.listitems('vehicleactivity', '*', filter, true));
        setvactivityitem(GetResponse[0]);
        setVehicleData(GetResponse);
    };

    const addexceldata = async (data: any) => {
        console.log(jsonData);
        jsonData.map(async (data: any) => {
            let vid = data.vid;
            let did = data.did;
            let fromkm = data.fromkm;
            let tokm = data.tokm;
            let fromdriverdate = data.fromdate;
            let todriverdate = data.todate;

            let fromdateformattedDateString = fromdriverdate
                .replace(/\.(\d\d)/g, ':$1')
                .replace(/\./g, '')
                .replace(/ (AM|PM)/, ' $1');
            let convertfromdate = new Date(fromdateformattedDateString);
            let handoverdate = todriverdate;
            let handoverdateformattedDateString = handoverdate
                .replace(/\.(\d\d)/g, ':$1')
                .replace(/\./g, '')
                .replace(/ (AM|PM)/, ' $1');
            let converthandoverdate = new Date(handoverdateformattedDateString);

            let createallocation = {
                vehicleid: vid,
                fromdriverID: did.includes(',') ? did.replace(/,/g, '') : did,
                fromdrivername: data.fromdrivername,

                fromdate: type === 'old' ? localetoisodatetime(convertfromdate) : localetoisodatetime(convertfromdate),
                // fromlocation: type === 'old' ? data.FromLocation : data.HandoverLocation,
                dxVehicleActivityAllocationsId: vid,
                dxDriverAllocationsId: did.includes(',') ? did.replace(/,/g, '') : did,
                // handoverdrivername: data.HandoverDrivername,
                // handoverKM: data.HandoverKmMileage,
                handoverdate: localetoisodatetime(converthandoverdate),
                // handoverlocation: data.HandoverLocation,
                allocatestatus: 'DeAllocated',
                fromKM: fromkm,
                handoverKM: tokm,
                distances: data.MileageDifference
            };

            console.log(createallocation);
            let master = await dxService.createitem('allocation', createallocation, false);
            console.log(master);
        });
    };
    //this function calls while clicking on the save button
    const onSaveAllocateDriver = async (data: any) => {
        console.log(data);
        if (type == 'old') {
            addexceldata(data);
        }
    };

    return (
        <div>
            {' '}
            <>
                <Toast ref={toast} />
                <div className="col-12 md:col-12">
                    <Documents setJsonData={setJsonData} />
                    <AwsButton
                        className=" mr-2"
                        onClick={
                            onSaveAllocateDriver
                            // validatedriver();
                        }
                    >
                        Saving
                    </AwsButton>
                    <AwsButton className=" mr-2">Cancel</AwsButton>
                </div>
            </>
        </div>
    );
};

export default AddAllocation;
