import { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { Amplify, API, Storage } from 'aws-amplify';
import awsconfig from '../aws-exports.js';
import { InputSwitch } from 'primereact/inputswitch';
import { SwitchField } from '@aws-amplify/ui-react';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { setTimeout } from 'timers';
//import { createDxCompanySettings, createDxDepartmentSettings, createDxDriver } from '../../graphql/mutations';
//Amplify.configure(awsconfig);
let urlParams: any;
let licenseUrl: any;
const ListnewForm = (props: any) => {
    const UploadDocument = useRef<any>(null);
    const [data, setData] = useState<any>({});
    const [messsage, setMesssage] = useState<any>('');
    const [licenseImages, setLicenseImages] = useState<any>([]);
    const [licenseFieldName, setLicenseFieldName] = useState<any>('');
    useEffect(() => {
        let params = window.location.href.split('?')[1];
        urlParams = new URLSearchParams(params);
        updatecolumnvalues();
        props.getTableName !== undefined && getDetails();
    }, [window.location.href]);
    console.log(props);
    //for getting the details of update
    const getDetails = async () => {
        let filter = {
            id: {
                eq: `${urlParams.get('id')}` // filter priority = 1
            }
        };
        let FleetypeFilter = {
            id: {
                eq: `${urlParams.get('id')}` // filter priority = 1
            },
            fleettype: {
                eq: props.columns.fleettype
            }
        };
        let appointmentFormFilter = {
            vehicleid: {
                eq: `${urlParams.get('vid')}` // filter priority = 1
            }
        };

        let GetResponse: any = await API.graphql({
            query: props.columns.getTable,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: { filter: urlParams.get('vid') !== null ? appointmentFormFilter : props.Fleettype !== undefined ? FleetypeFilter : filter }
        });
        let response: any = [GetResponse.data[props.getTableName].items[0]].filter((key) => delete key['createdAt'] && delete key['updatedAt'] && delete key['_deleted']);

        if (props.setGetmodelDropdown !== undefined) {
            props.setGetmodelDropdown(Object.assign({}, ...response));
        }
        response = Object.assign({}, ...response);
        //checking condition for multiselect dropdown
        if (response.partname) {
            //parsing the JSON
            response.partname = JSON.parse(response.partname);
        } else {
            response = response;
        }
        setData(response);
        if (urlParams.get('exp') !== null) {
            setData({ ...response, ['renewaltype']: urlParams.get('exp') });
        }
    };
    let layoutclass = 'md:col-12';
    if (props.layout)
        switch (props.layout.toLowerCase()) {
            case 'vertical':
                layoutclass = 'md:col-12';
                break;
            case 'horizontal':
                layoutclass = 'grid md:col-12';
                break;
            case 'grid':
                layoutclass = 'dropdown md:col-6';
                break;
            case 'grid2':
                layoutclass = 'dropdown md:col-4';
                break;
            default:
                layoutclass = 'md:col-12';
        }
    const updatecolumnvalues = () => {
        let columndata: any = {};
        props.columns.columns
            .filter((col: any) => col.required === true)
            .map((val: any, key: number) => {
                columndata[val.name] = val.data;
            });
        setData(columndata);
        // setData({ ...columndata, ['id']: Math.random().toString(36).substring(2, 9) });
    };
    console.log(data);

    const dynamicFields = (type: any, fieldName: any, options: any, disabled: boolean, hidden: boolean, required: boolean, showcurrentdate: boolean) => {
        if (Object.keys(data).length > 0) {
            //Appointment form
            if (type === 'text') {
                return (
                    <InputText
                        name={fieldName}
                        value={data[fieldName] ? data[fieldName] : ''}
                        onChange={(e) => handleChange(e, type, fieldName)}
                        disabled={disabled}
                        hidden={hidden}
                        required={required}
                        className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                    />
                );
            } else if (type === 'number') {
                return (
                    <InputNumber
                        name={fieldName}
                        value={data[fieldName] || null}
                        onChange={(e) => handleChange(e, type, fieldName)}
                        disabled={disabled}
                        hidden={hidden}
                        required={required}
                        useGrouping={false}
                        maxLength={12}
                        className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                    />
                );
            } else if (type === 'datetime') {
                let ShowDate = showcurrentdate == true ? new Date() : new Date().toISOString();

                let dateValue: any =
                    props.getTableName !== undefined && data[fieldName] !== null && data[fieldName] !== undefined
                        ? new Date(data[fieldName])
                        : // : props.getTableName !== undefined && data[fieldName] === undefined
                        // ? new Date().toISOString()
                        props.getTableName !== undefined && data[fieldName] === undefined && showcurrentdate == true
                        ? ShowDate
                        : data[fieldName];

                return (
                    <Calendar
                        name={fieldName}
                        value={dateValue}
                        id="buttonbar"
                        showTime
                        showButtonBar
                        onChange={(e) => handleChange(e, type, fieldName)}
                        disabled={disabled}
                        required={required}
                        showIcon
                        hourFormat="12"
                        className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                    />
                );
            } else if (type === 'date') {
                let ShowDate = showcurrentdate == true ? new Date() : new Date().toISOString();

                let dateValue: any =
                    props.getTableName !== undefined && data[fieldName] !== null && data[fieldName] !== undefined
                        ? new Date(data[fieldName])
                        : // : props.getTableName !== undefined && data[fieldName] === undefined
                        // ? new Date().toISOString()
                        props.getTableName !== undefined && data[fieldName] === undefined && showcurrentdate == true
                        ? ShowDate
                        : data[fieldName];

                return (
                    <Calendar
                        name={fieldName}
                        value={dateValue}
                        id="buttonbar"
                        showButtonBar
                        onChange={(e) => handleChange(e, type, fieldName)}
                        disabled={disabled}
                        required={required}
                        showIcon
                        className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                    />
                );
            } else if (type === 'dropdown') {
                return (
                    <Dropdown
                        name={fieldName}
                        value={data[fieldName]}
                        options={options}
                        onChange={(e) => handleChange(e, type, fieldName)}
                        optionLabel="label"
                        placeholder="Select an option"
                        filter
                        optionDisabled={(option: any) => option.isdisabled}
                        disabled={disabled}
                        hidden={hidden}
                        required={required}
                        className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                    />
                );
            } else if (type === 'fileupload') {
                return (
                    <FileUpload
                        customUpload={true}
                        multiple
                        // url="./upload.php"
                        uploadHandler={(e) => uploadHandler(e, fieldName)}
                        // onSelect={(e) => uploadHandler(e, fieldName)}
                        onSelect={(e) => handleChange(e, type, fieldName)}
                        auto={true}
                        accept="*"
                        className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                    />
                );
            } else if (type === 'switch') {
                return <InputSwitch name={fieldName} checked={data[fieldName]} onChange={(e) => handleChange(e, type, fieldName)} disabled={disabled} hidden={hidden} required={required} />;
            } else if (type === 'multiselect') {
                return (
                    <MultiSelect
                        name={fieldName}
                        value={data[fieldName]}
                        options={options}
                        onChange={(e) => handleChange(e, type, fieldName)}
                        optionLabel="label"
                        placeholder="Select an option"
                        filter
                        disabled={disabled}
                        hidden={hidden}
                        required={required}
                        className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                    />
                );
            } else {
                return (
                    <InputText
                        name={fieldName}
                        value={data[fieldName]}
                        onChange={(e) => handleChange(e, type, fieldName)}
                        disabled={disabled}
                        hidden={hidden}
                        required={required}
                        className={required == true && (data[fieldName] == '' || data[fieldName] == null) ? 'p-invalid' : ''}
                    />
                );
            }
        }
    };
    const handleChange = async (event: any, type: any, fieldName: any) => {
        if (fieldName == 'make') {
            props.setselectedmakeDropdown(event.target.value);
        }
        if (fieldName == 'toggleAllocate') {
            props.setToggleAllocate(event.target.value);
        }
        if (fieldName == 'Appointmentdrivername') {
            props.AllocationDriver(event.target.value);
        }
        if (fieldName == 'AppointmentDate') {
            props.AllocationDate(event.target.value);
        }
        if (fieldName == 'Appointmentvehicleid') {
            props.AllocationId(event.target.value);
        }
        if (props.formName === 'Mileage' && fieldName === 'id') {
            // props.getVehicleID('');
            props.getVehicleID(event.target.value);

            // setTimeout(() => {
            // props.fetchVehicleDetails();
            // }, 2000);
        }
        if (props.isAppointmentNeeded !== undefined && fieldName == 'isAppointmentNeeded') {
            props.isAppointmentNeeded(event.target.value);
        }
        if (props.formName === 'serviceContract' && fieldName === 'id') {
            props.getVehicleID(event.target.value);
        }
        setData({
            ...data,
            [type === 'fileupload' ? fieldName : type === 'number' ? event.originalEvent.target.name : event.target.name]:
                type === 'fileupload' ? event.files[0].objectURL : type === 'number' ? event.originalEvent.target.value : type === 'multiselect' ? event.target.value : event.target.value
        });
    };
    //get the license file and the fieldName
    const uploadHandler = async (event: any, fieldName: any) => {
        setLicenseImages(event.files);
        setLicenseFieldName(fieldName);
    };

    //call UploadLicense In submit function
    const UploadLicense = async () => {
        console.log(data);

        let Files: any = [];
        licenseUrl = '';
        return await Promise.all(
            licenseImages.map(async (item: any) => {
                let expDOc = {};
                // props.Fleettype !== undefined ?
                if (props.Fleettype !== undefined) {
                    let url = await Storage.put(`${props.bucketname}/${data.id}/${new Date().getFullYear()}/${data.renewaltype}/` + item.name, item);

                    licenseUrl = url.key;
                } else {
                    await Storage.put(`${props.bucketname}/${data.vehicleid && data.id ? data.vehicleid : !data.id && data.name ? data.name : data.id}/${new Date().getFullYear()}/` + item.name, item)
                        .then((res: any) => {
                            Files.push(res.key);
                            licenseUrl = JSON.stringify({ File: Files });
                        })
                        .catch((err) => {});
                }
            })
        ).then(() => {
            saveData();
        });
    };
    const submitdata = async () => {
        if (licenseImages.length > 0) {
            //wait until license upload is completed
            await UploadLicense();
        } else {
            saveData();
        }
    };
    const saveData = () => {
        let tem: any = [];
        let collectedData = data;
        if (Array.isArray(collectedData.partname)) {
            collectedData.partname = JSON.stringify(collectedData.partname);
        } else {
            collectedData = collectedData;
        }

        collectedData[licenseFieldName] = licenseUrl;
        let EditFormFilter = Object.fromEntries(
            Object.entries(collectedData)
                .filter(([key, value]) => value !== null)
                .filter(([key, value]) => key !== '')
                .filter(([key, value]) => value !== undefined)
        );
        let NewFormFilter = Object.fromEntries(
            Object.entries(collectedData)
                .filter(([key, value]) => value !== null)
                .filter(([key, value]) => key !== '')
                .filter(([key, value]) => value !== '')
                .filter(([key, value]) => value !== undefined)
        );
        let finalData = props.getTableName !== undefined ? EditFormFilter : NewFormFilter;
        // Pushing the column items for validation
        props.columns.columns.map((val: any, key: number) => {
            if (data[val.name] == '' && val.required == (props.getTableName !== undefined ? true : false)) {
                return false;
            } else if (data[val.name] == null && val.required == false && data[val.name] == undefined) {
                return false;
            } else {
                tem.push(val.name);
            }
        });
        tem = tem.filter((res: any) => res !== 'id');
        console.log(finalData, tem);
        //Checking id and fleettype (Edit)
        if (finalData.hasOwnProperty('id') && finalData.hasOwnProperty('fleettype')) {
            if (tem.length + 4 == Object.keys(finalData).length) {
                props.submitForm(finalData);
            } else {
                console.log('1');
                setMesssage('* Please fill all required fields');
            }
        }
        //Checking id and fleettype (Edit)
        else if (finalData.hasOwnProperty('id') && !finalData.hasOwnProperty('fleettype')) {
            if (tem.length + 3 == Object.keys(finalData).length) {
                props.submitForm(finalData);
            } else {
                console.log('2');
                setMesssage('* Please fill all required fields');
            }
        }
        // New Form
        else {
            if (tem.length == Object.keys(finalData).length) {
                props.submitForm(finalData);
            } else {
                console.log('3');
                setMesssage('* Please fill all required fields');
            }
        }
    };

    return (
        <div>
            <Toast ref={UploadDocument}></Toast>
            <div className="grid">
                <div className="col-12 md:col-12">
                    <div className="card">
                        <h5>
                            {/* {props.title}
                            {data['vehicleid'] ? data['vehicleid'] : data['name'] ? data['name'] : data['id']} */}
                        </h5>
                        <div className="p-fluid formgrid grid">
                            {props.columns.columns
                                .filter((hidden: any) => hidden.hidden === false)
                                .map((val: any, key: number) => {
                                    return (
                                        <div className={`field col-12 ${layoutclass}`} key={key}>
                                            <label htmlFor={val.value} className={layoutclass.includes('grid') ? 'col-12 mb-2 md:col-2 md:mb-0' : ''}>
                                                {val.value}
                                            </label>
                                            <div className={layoutclass.includes('grid') ? 'col-12 md:col-10' : ''}>{dynamicFields(val.fieldtype, val.name, val.data, val.disabled, val.hidden, val.required, val.showcurrentdate)}</div>
                                        </div>
                                    );
                                })}
                        </div>
                        <span className="font-italic" style={{ color: '#d32f2f' }}>
                            {messsage}
                        </span>
                        <br />
                        {props.ChangeBtn == 'OpenDocument' ? (
                            <>
                                <Button onClick={submitdata} className="mr-2">
                                    Documents
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={submitdata} className="mr-2">
                                    Submit
                                </Button>
                                <Button onClick={() => window.history.back()} className="p-button-danger">
                                    Cancel
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ListnewForm;
