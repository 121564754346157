import React, { useContext, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';

const AuthorizedControl = (props: any) => {
    const [username, setusername] = useState<any>('');
    const [password, setpassword] = useState<any>('');
    const [newpassword, setnewpassword] = useState<any>('');

    return (
        <>
            <div className="layout-main pages-body  flex flex-column">
                <div className="grid spd-grid">
                    <div className="md:col-6 spd-imagecard"></div>
                    <div className="col-12 md:col-6 " style={{ padding: '0px' }}>
                        <div className="pages-body  flex flex-column">
                            <div className="align-self-center mt-auto mb-auto">
                                <div className="pages-panel card flex flex-column px-6">
                                    <div className="pages-header px-3 py-1" style={{ backgroundColor: '#fff' }}>
                                        <img id="app-logo" src="assets/layout/images/logo-light.svg" alt="ultima-layout" style={{ height: '5rem' }} />
                                    </div>
                                    <h4>Login</h4>
                                    {props.user != null && props.user.challengeName && props.user.challengeName === 'NEW_PASSWORD_REQUIRED' ? (
                                        <>
                                            <div className="pages-detail mb-6 px-6">Welcome to Deluxe Homes ERP Portal</div>
                                            <div className="input-panel flex flex-column px-3">
                                                <div className="p-inputgroup mb-4">
                                                    <span className="p-inputgroup-addon">
                                                        <i className="pi pi-envelope"></i>
                                                    </span>
                                                    <span className="p-float-label">
                                                        <InputText type="text" id="inputgroup1" onChange={(e) => setusername(e.target.value)} />
                                                        <label htmlFor="inputgroup1">Username</label>
                                                    </span>
                                                </div>

                                                <div className="p-inputgroup mb-4">
                                                    <span className="p-inputgroup-addon">
                                                        <i className="pi pi-envelope"></i>
                                                    </span>
                                                    <span className="p-float-label">
                                                        <InputText type="password" id="inputgroup2" onChange={(e) => setpassword(e.target.value)} />
                                                        <label htmlFor="inputgroup2">Old Password</label>
                                                    </span>
                                                </div>

                                                <div className="p-inputgroup mt-3 mb-4">
                                                    <span className="p-inputgroup-addon">
                                                        <i className="pi pi-lock"></i>
                                                    </span>
                                                    <span className="p-float-label">
                                                        <InputText type="password" id="inputgroup3" onChange={(e) => setnewpassword(e.target.value)} />
                                                        <label htmlFor="inputgroup3">New Password</label>
                                                    </span>
                                                </div>
                                            </div>

                                            <Button label="Change Password" className="p-button-outlined mr-2 mb-2" onClick={(e) =>  props.changepassword(props.user, password, newpassword)} />
                                        </>
                                    ) : (
                                        <>
                                            <div className="pages-detail mb-4 px-6">Welcome to Deluxe Homes ERP Portal</div>
                                            <div className="input-panel flex flex-column px-3">
                                                <div className="p-inputgroup mt-3 mb-4">
                                                    <span className="p-inputgroup-addon">
                                                        <i className="pi pi-envelope"></i>
                                                    </span>
                                                    <span className="p-float-label">
                                                        <InputText type="text" id="inputgroup1" onChange={(e) => setusername(e.target.value)} />
                                                        <label htmlFor="inputgroup1">Username</label>
                                                    </span>
                                                </div>

                                                <div className="p-inputgroup mt-3 mb-4">
                                                    <span className="p-inputgroup-addon">
                                                        <i className="pi pi-lock"></i>
                                                    </span>
                                                    <span className="p-float-label">
                                                        <InputText type="password" id="inputgroup2" onChange={(e) => setpassword(e.target.value)} />
                                                        <label htmlFor="inputgroup2">Password</label>
                                                    </span>
                                                </div>
                                            </div>

                                            <Button label="Sign In" icon="pi pi-user" className="w-full" onClick={() => props.userlogin(username, password)} />
                                            <div className="pages-detail mb-2 px-6">{props.message}</div>
                                            <h5>or</h5>
                                            <Button label="Login with Deluxe Homes Email" icon="pi pi-google" className="w-full p-button p-button-success  mb-4" onClick={() => props.login()} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthorizedControl;
