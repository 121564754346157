import React, { useState, useEffect, useRef, Children, useContext } from 'react';
import { Amplify, API, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Toast } from 'primereact/toast';
import { AuthContext } from '../../../AuthContext';
import { CognitoIdentityProviderClient, AdminAddUserToGroupCommand, AdminRemoveUserFromGroupCommand, ListGroupsCommand, AdminListGroupsForUserCommand, AdminUpdateUserAttributesCommand } from '@aws-sdk/client-cognito-identity-provider';
import AmplifyServices from '../../../Service/Service';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../../dxgraphql/dxqueries';
import { getDxApprovalConfig, getDxWorkflow, listDxApprovals } from '../../../graphql/queries';
import { updateDxApprovalConfig, updateDxApprovals, updateDxWorkflow } from '../../../graphql/mutations';
import FormTemplate from '../../../Components/FormTemplate';
import { Button } from 'primereact/button';
import awsExports from '../../../aws-exports';
import AWS from 'aws-sdk';


const UserLink = () => {
    const { dispatch } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [listFormData, setlistFormData] = useState<any>({});
    const [workflowitem, setworkflowitem] = useState<any>({});
    const [stepitem, setstepitem] = useState<any>({});
    const [appitem, setappitem] = useState<any>({});
    const [newdata, setnewdata] = useState<any>({});
    const toast = useRef(null);
    const [uname, setUname] = useState('');
    const [name, setname] = useState('');


    useEffect(() => {
        getdata();



    }, []);

    useEffect(() => {

    }, [appitem]);



    const changedata = (props: any) => {
        console.log(props);


    };

    const addData = (props: any) => {
        console.log(props);

    };





    const getdata = async () => {

        Auth.currentAuthenticatedUser()
        .then(async (currentUser) => {
            console.log(currentUser);

            let AWSconfig = new AWS.Config({
                apiVersion: '2016-04-18',
                credentials: currentUser,    //  The whole user object goes in the config.credentials field!  Key issue.
                region: awsExports.aws_cognito_region
            });

            const params = {
                UserPoolId: awsExports.aws_user_pools_id,
            };

            let existingUsername = 'a7c49a4a-92bf-4133-bf6a-21a39bb87288';
            let userId = '0b75713c-50f9-4782-aae9-940ca4de8297';
            let providerName = 'Google';
            const parameters = {
                // Existing user in the user pool to be linked to the external identity provider user account.
                DestinationUser: {
                    ProviderAttributeValue: existingUsername,
                    ProviderName: 'Cognito'
                },
                // An external identity provider account for a user who does not currently exist yet in the user pool.
                SourceUser: {
                    ProviderAttributeName: 'Cognito_Subject',
                    //ProviderAttributeValue: '110939693768695707353',
                    ProviderName: providerName // Facebook, Google, Login with Amazon, Sign in with Apple
                },
                UserPoolId: awsExports.aws_user_pools_id
            };
            

            const cognitoIdp = new AWS.CognitoIdentityServiceProvider(AWSconfig);
            await cognitoIdp.adminLinkProviderForUser(parameters);
            

    console.log('Successfully linked external identity to user.');

        
        });

    


    }


    return (

        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Link User</h4>

                {/* Inner Container Component HTML */}
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card container-card">
                            <Toast ref={toast}></Toast>

                            {/* Inner COMPONENT HTML */}
                            <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                <div className="surface-ground">
                                    <div className="grid">
                                        <div className="col-12 lg:col-4 p-3">
                                            <div className="p-3 border-round shadow-2 flex align-items-center surface-card">
                                                <div className="bg-teal-100 inline-flex align-items-center justify-content-center mr-3"
                                                // style="width: 48px; height: 48px; border-radius: 10px;"
                                                >
                                                    <i className="pi pi-check text-teal-600 text-3xl"></i>
                                                </div>
                                                <div>
                                                    <span className="text-900 text-xl font-medium mb-2">Approvals</span>
                                                    <p className="mt-1 mb-0 text-600 font-medium text-sm">Click here to manage approval configurations</p>
                                                </div>
                                                <div className="ml-auto">
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div></div></div>
                                     {/* END Inner COMPONENT HTML */}

                        </div>
                    </div>
                </div>
                {/* END of Inner Container Component HTML */}




            </div>
        </div>

    );
};
export default UserLink;

