import React, { useState, useEffect, useRef, useCallback } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import AmplifyServices from '../../Service/Service';
import { deleteDxMasterLogs } from '../../graphql/mutations';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import dxService from '../../Services/dxService';
import ListTemplate from '../../Components/ListTemplate';
import { TabMenu } from 'primereact/tabmenu';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';
const ManageLogs = () => {
    const navigate = useNavigate();
    const menu = useRef<any>(null);
    const [items, setItems] = useState<any>([]);
    const [salikitems, setsalikitems] = useState<any>([]);
    const [vipfuelitems, setvipfuelitems] = useState<any>([]);
    const [fineitems, setfineitems] = useState<any>([]);
    const [mileageitems, setmileageitems] = useState<any>([]);
    const [parkingItems, setParkingItems] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [globalFilter, setGlobalFilter] = useState<any>('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const location1 = useLocation();
    const urlParams = new URLSearchParams(location1.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : '';

    var vid = urlParams.has('vid') ? urlParams.get('vid') : null;
    const checkActiveIndex = useCallback(async () => {
        formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
        const paths = location1.pathname.split('/');

        const currentPath = paths[paths.length - 1];
        if (currentPath == 'all')
            getData().then((res) => {
                setItems(formatDatefield(res));
            });
        switch (currentPath) {
            case 'salik':
                setActiveIndex(1);
                if (currentPath == 'salik')
                    getSalikdata(vid).then((res) => {
                        setsalikitems(formatDatefield(res));
                    });
                break;
            case 'vipfuel':
                setActiveIndex(2);
                if (currentPath == 'vipfuel')
                    getVipfuelData(vid).then((res) => {
                        setvipfuelitems(formatDatefield(res));
                    });
                break;
            case 'fines':
                setActiveIndex(3);
                if (currentPath == 'fines')
                    getFinesData(vid).then((res) => {
                        setfineitems(formatDatefield(res));
                    });
                break;

            case 'mileage':
                setActiveIndex(4);
                if (currentPath == 'mileage')
                    getMileageData(vid).then((res) => {
                        setmileageitems(formatDatefield(res));
                    });
                break;
            case 'parking':
                setActiveIndex(5);
                if (currentPath == 'parking')
                    getParkingeData(vid).then((res) => {
                        setParkingItems(formatDatefield(res));
                    });
                break;
        }
    }, [location1]);
    useEffect(() => {}, []);

    useEffect(() => {
        checkActiveIndex();
    }, [checkActiveIndex]);

    const getSalikdata = async (vehicleid: any, nextToken?: any) => {
        let variables: any = {};

        let filter = {};
        if (vehicleid != null) {
            filter = {
                or: [{ vehicleid: { eq: vehicleid } }]
            };
        }
        variables = filter;

        let GetResponse: any = await dxService.listitems('logsalik', '*', variables, true, 100, nextToken);
        let response = GetResponse.items;
        let nexttoken = GetResponse.nextToken;
        window.onscroll = async function (ev) {
            if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {
                setLoading(true);
                await getSalikdata(vehicleid, nexttoken).then((res) => {
                    const formattedData = formatDatefield(res);
                    setsalikitems((olditems: any) => [...olditems, ...formattedData]);
                });
            } else {
                setLoading(false);
            }
        };
        setLoading(false);
        return response;
    };
    const getVipfuelData = async (vehicleid: any, nextToken?: any) => {
        let variables: any = {};
        let filter = {};
        if (vehicleid != null) {
            filter = {
                or: [{ vehicleid: { eq: vehicleid } }]
            };
        }
        variables = filter;
        let GetResponse: any = await dxService.listitems('logvipfuels', '*', variables, true, 100, nextToken);
        let response = GetResponse.items;
        let nexttoken = GetResponse.nextToken;
        window.onscroll = async function (ev) {
            if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {
                setLoading(true);
                await getVipfuelData(vehicleid, nexttoken).then((res) => {
                    const formattedData = formatDatefield(res);
                    setvipfuelitems((olditems: any) => [...olditems, ...formattedData]);
                });
            } else {
                setLoading(false);
            }
        };
        setLoading(false);
        return response;
    };
    const getMileageData = async (vehicleid: any, nextToken?: any) => {
        let variables: any = {};
        let filter = {};
        if (vehicleid != null) {
            filter = {
                or: [{ vehicleid: { eq: vehicleid } }]
            };
        }
        variables = filter;
        let GetResponse: any = await dxService.listitems('logmileage', '*', variables, true, 100, nextToken);
        let response = GetResponse.items;
        let nexttoken = GetResponse.nextToken;
        window.onscroll = async function (ev) {
            if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {
                setLoading(true);
                await getMileageData(vehicleid, nexttoken).then((res) => {
                    const formattedData = formatDatefield(res);
                    setmileageitems((olditems: any) => [...olditems, ...formattedData]);
                });
            } else {
                setLoading(false);
            }
        };
        setLoading(false);
        return response;
    };
    const getParkingeData = async (vehicleid: any, nextToken?: any) => {
        let variables: any = {};
        let filter = {};
        if (vehicleid != null) {
            filter = {
                or: [{ vehicleid: { eq: vehicleid } }]
            };
        }
        variables = filter;
        let GetResponse: any = await dxService.listitems('logparking', '*', variables, true, 100, nextToken);
        let response = GetResponse.items;

        let nexttoken = GetResponse.nextToken;
        window.onscroll = async function (ev) {
            if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {
                setLoading(true);
                await getParkingeData(vehicleid, nexttoken).then((res) => {
                    const formattedData = formatDatefield(res);
                    setParkingItems((olditems: any) => [...olditems, ...formattedData]);
                });
            } else {
                setLoading(false);
            }
        };
        setLoading(false);
        return response;
    };
    const getFinesData = async (vehicleid: any, nextToken?: any) => {
        let variables: any = {};
        let filter = {};
        if (vehicleid != null) {
            filter = {
                or: [{ vehicleid: { eq: vehicleid } }]
            };
        }
        variables = filter;
        let GetResponse: any = await dxService.listitems('logfines', '*', variables, true, 100, nextToken);
        let response = GetResponse.items;
        let nexttoken = GetResponse.nextToken;
        window.onscroll = async function (ev) {
            if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {
                setLoading(true);
                await getFinesData(vehicleid, nexttoken).then((res) => {
                    const formattedData = formatDatefield(res);
                    setfineitems((olditems: any) => [...olditems, ...formattedData]);
                });
            } else {
                setLoading(false);
            }
        };
        setLoading(false);
        return response;
    };
    // const getSalikdata = async (nextToken?: any) => {
    //     let variables: any = {};
    //     let GetResponse: any = await dxService.listitems('logsalik', '*', variables, true, 100, nextToken);
    //     let response = GetResponse.items;
    //     let nexttoken = GetResponse.nextToken;
    //     window.onscroll = async function (ev) {
    //         if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {
    //             await getSalikdata(nexttoken).then((res) => {
    //                 const formattedData = formatDatefield(res);
    //                 setsalikitems((olditems: any) => [...olditems, ...formattedData]);
    //             });
    //         }
    //     };
    //     return response;
    // };
    const logtabItems = [
        {
            label: 'All',
            command: () => navigate('/manage/logs/all')
        },
        {
            label: 'Salik',
            command: () => navigate('/manage/logs/salik')
        },
        // { label: 'Documents', disabled: formID != "" ? false : true ,command: () => navigate('/manage/appointments/item/documents' + qs) }
        { label: 'Vip Fuel', command: () => navigate('/manage/logs/vipfuel') },
        { label: 'Fines', command: () => navigate('/manage/logs/fines') },
        { label: 'Mileage', command: () => navigate('/manage/logs/mileage') },
        { label: 'Parking', command: () => navigate('/manage/logs/parking') }
    ];

    const getData = async (nextToken?: any) => {
        // let ac = `id vehicleid drivername date Accidents Fine Salik Mileage Parking Vipfuel driver vehicle`;
        // let logs = await dxService.listitems('masterlog', '', {}, true, 100, nextToken);
        // setItems(formatDatefield(logs));
        let variables: any = {};
        let GetResponse: any = await dxService.listitems('masterlog', '*', variables, true, 100, nextToken);
        let response = GetResponse.items;
        let nexttoken = GetResponse.nextToken;
        window.onscroll = async function (ev) {
            if (window.innerHeight + window.scrollY + 10 >= document.body.scrollHeight && nexttoken) {
                setLoading(true);
                await getData(nexttoken).then((res) => {
                    const formattedData = formatDatefield(res);
                    setItems((olditems: any) => [...olditems, ...formattedData]);
                });
            } else {
                setLoading(false);
            }
        };
        setLoading(false);
        return response;

        // await AmplifyServices.getTableDetails(listDxMasterLogs, 'listDxMasterLogs').then(async (res: any) => {
        //     setItems(res);
        // });
    };
    const formatDatefield = (data: any) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };
    const driverBodyTemplate = (rowData: any) => {
        return (
            <Link to={`/dashboard/driver?${rowData.driverid ? 'id=' + rowData.driverid : rowData.driver ? 'id=' + rowData.driver.id : 'name=' + rowData.drivername}`} style={{ textTransform: 'uppercase', fontWeight: '600' }}>
                {rowData.driver ? rowData.driver.name : rowData.drivername}
            </Link>
        );
    };
    const fineBodyTemplate = (rowData: any) => {
        return <>{rowData.Fine !== null ? parseFloat(rowData.Fine).toFixed(2) : '-'}</>;
    };
    const mileageBodyTemplate = (rowData: any) => {
        return <>{rowData.Mileage !== null ? parseFloat(rowData.Mileage).toFixed(2) : '-'}</>;
    };
    const salikBodyTemplate = (rowData: any) => {
        return <>{rowData.Salik !== null ? parseFloat(rowData.Salik).toFixed(2) : '-'}</>;
    };
    const vipfuelBodyTemplate = (rowData: any) => {
        return <>{rowData.Vipfuel !== null ? parseFloat(rowData.Vipfuel).toFixed(2) : '-'}</>;
    };
    const clearbtn = () => {
        setGlobalFilter('');
    };
    const salikcolumns = [
        { field: 'vehicleid', header: 'Vehicle', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid', headeralign: 'left', bodyalign: 'left' },
        { field: 'drivername', header: 'Driver', fieldtype: 'custom', filterOption: true, filterFieldOption: 'drivername', headeralign: 'left', bodyalign: 'left', template: (r: any) => <>{driverBodyTemplate(r)}</> },
        { field: 'direction', header: 'Direction', fieldtype: 'text', filterOption: true, filterFieldOption: 'direction', headeralign: 'left', bodyalign: 'left' },
        { field: 'date', header: 'Date', dataType: 'date', fieldtype: 'datefilter', filterOption: true, filterFieldOption: 'date', headeralign: 'left', bodyalign: 'left' },
        { field: 'tollgate', header: 'Tollgate', fieldtype: 'text', filterOption: true, filterFieldOption: 'tollgate', headeralign: 'left', bodyalign: 'left' },
        { field: 'tagnumber', header: 'Tag Number', fieldtype: 'text', filterOption: true, filterFieldOption: 'tagnumber', headeralign: 'left', bodyalign: 'left' },
        { field: 'amount', header: 'Amount', fieldtype: 'text', filterOption: true, filterFieldOption: 'amount', headeralign: 'left', bodyalign: 'center' },
        { field: 'updatedAt', header: 'Updated At', fieldtype: 'date', textalign: 'left', filterOption: false, headeralign: 'left', bodyalign: 'left', filterFieldOption: 'updatedAt' }
    ];
    const vipfuelcolumns = [
        { field: 'rid', header: 'RID', fieldtype: 'text', filterOption: false, filterFieldOption: 'rid' },
        { field: 'vehicleid', header: 'Vehicle', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'date', header: 'Date', dataType: 'date', fieldtype: 'date', filterOption: true, filterFieldOption: 'date' },
        { field: 'drivername', header: 'Driver', fieldtype: 'custom', filterOption: true, filterFieldOption: 'drivername', template: (r: any) => <>{driverBodyTemplate(r)}</> },
        { field: 'unitprice', header: 'Unit Price', fieldtype: 'text', filterOption: false, filterFieldOption: 'unitprice' },

        { field: 'volume', header: 'Volume', fieldtype: 'text', filterOption: false, filterFieldOption: 'volume' },
        { field: 'total', header: 'Total', fieldtype: 'text', filterOption: false, filterFieldOption: 'total' },
        { field: 'productname', header: 'Product', fieldtype: 'text', filterOption: false, filterFieldOption: 'productname' },
        { field: 'receiptno', header: 'ReceiptNr', fieldtype: 'text', filterOption: false, filterFieldOption: 'receiptno' },
        { field: 'idunitcode', header: 'UnitCode', fieldtype: 'text', filterOption: false, filterFieldOption: 'idunitcode' },
        { field: 'stationname', header: 'Station', fieldtype: 'text', filterOption: false, filterFieldOption: 'stationname' },
        { field: 'stationcode', header: 'Station Code', fieldtype: 'text', filterOption: false, filterFieldOption: 'stationcode' },
        { field: 'fleetname', header: 'Fleet Name', fieldtype: 'text', filterOption: false, filterFieldOption: 'fleetname' },
        { field: 'groupname', header: 'Group Name', fieldtype: 'text', filterOption: false, filterFieldOption: 'groupname ' },
        { field: 'vehiclecode', header: 'V Code', fieldtype: 'text', filterOption: false, filterFieldOption: 'vehiclecode ' },
        { field: 'citycode', header: 'City Code', fieldtype: 'text', filterOption: false, filterFieldOption: 'citycode' },
        { field: 'costcenter', header: 'Cost Center', fieldtype: 'text', filterOption: false, filterFieldOption: 'costcenter' },
        { field: 'actualamount', header: 'Actual Amount', fieldtype: 'text', filterOption: false, filterFieldOption: 'actualamount' },
        { field: 'vatrate', header: 'VAT Rate', fieldtype: 'text', filterOption: false, filterFieldOption: 'vatrate' },
        { field: 'vatamount', header: 'VAT Amount', fieldtype: 'text', filterOption: false, filterFieldOption: 'vatamount' },
        // { field: 'odometer', header: 'Odometer', fieldtype: 'text', filterOption: false, filterFieldOption: 'odometer' },
        { field: 'total', header: 'Total', fieldtype: 'text', filterOption: false, filterFieldOption: 'total' },
        // { field: 'volume', header: 'Volume', fieldtype: 'text', filterOption: false, filterFieldOption: 'volume' }
        { field: 'updatedAt', header: 'Updated At', fieldtype: 'date', textalign: 'left', filterOption: false, headeralign: 'left', bodyalign: 'left', filterFieldOption: 'updatedAt' }
    ];
    const finescolumns = [
        { field: 'vehicleid', header: 'Vehicle', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid', width: '8rem' },
        { field: 'drivername', header: 'Driver', fieldtype: 'custom', filterOption: true, filterFieldOption: 'drivername', width: '10rem', template: (r: any) => <>{driverBodyTemplate(r)}</> },
        { field: 'date', header: 'Date', fieldtype: 'date', filterOption: true, filterFieldOption: 'date', width: '10rem' },
        { field: 'finenumber', header: 'Fine No', fieldtype: 'text', filterOption: true, filterFieldOption: 'finenumber', width: '10rem' },
        { field: 'reason', header: 'Reason', fieldtype: 'text', filterOption: true, filterFieldOption: 'reason', width: '15rem' },
        { field: 'blackpoints', header: 'Black Points', fieldtype: 'text', filterOption: true, filterFieldOption: 'blackpoints', width: '10rem' },
        { field: 'innovationfee', header: 'Innovation Fee', fieldtype: 'text', filterOption: true, filterFieldOption: 'innovationfee', width: '15rem' },
        { field: 'amount', header: 'Amount', fieldtype: 'text', filterOption: true, filterFieldOption: 'amount', width: '10rem' },
        { field: 'totalamount', header: 'Total', fieldtype: 'text', filterOption: true, filterFieldOption: 'totalamount', width: '10rem' }
    ];
    const mileagecolumns = [
        { field: 'vehicleid', header: 'Vehicle', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'drivername', header: 'Driver', fieldtype: 'custom', filterOption: true, filterFieldOption: 'drivername', width: '10rem', template: (r: any) => <>{driverBodyTemplate(r)}</> },
        { field: 'date', header: 'Date', fieldtype: 'date', filterOption: true, filterFieldOption: 'date', width: '10rem' },
        { field: 'startKM', header: 'Start KM', fieldtype: 'text', filterOption: true, filterFieldOption: 'startKM', width: '10rem' },
        { field: 'endKM', header: 'End KM', fieldtype: 'text', filterOption: true, filterFieldOption: 'endKM', width: '10rem' },
        { field: 'distance', header: 'Distance', fieldtype: 'text', filterOption: true, filterFieldOption: 'distance', width: '10rem' }
    ];
    const parkingcolumns = [
        { field: 'vehicleid', header: 'Vehicle', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'drivername', header: 'Driver', fieldtype: 'custom', filterOption: true, filterFieldOption: 'drivername', width: '10rem', template: (r: any) => <>{driverBodyTemplate(r)}</> },
        { field: 'date', header: 'Date', fieldtype: 'date', filterOption: true, filterFieldOption: 'date', width: '10rem' },
        { field: 'amount', header: 'Amount', fieldtype: 'custom', filterOption: true, filterFieldOption: 'amount', width: '10rem', template: (r: any) => <>AED {r.amount ? r.amount : 0}</> }
    ];

    const columns = [
        // { fieldtype: 'multiselect' },
        { field: 'driver.name', header: 'Driver Name', headeralign: 'left', bodyalign: 'left', fieldtype: 'custom', filterOption: true, filterFieldOption: 'driver.name', template: (r: any) => <>{driverBodyTemplate(r)}</> },
        { field: 'vehicleid', header: 'Vehicle', headeralign: 'left', bodyalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'Accidents', header: 'Accidents', headeralign: 'left', bodyalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'Accidents' },
        { field: 'Fine', header: 'Fine', fieldtype: 'custom', headeralign: 'left', bodyalign: 'center', filterOption: true, filterFieldOption: 'Fine', template: (r: any) => <>{fineBodyTemplate(r)}</> },
        { field: 'Mileage', header: 'Mileage', fieldtype: 'custom', headeralign: 'left', bodyalign: 'center', filterOption: true, filterFieldOption: 'Mileage', template: (r: any) => <>{mileageBodyTemplate(r)}</> },
        // { field: 'Parking', header: 'Parking', fieldtype: 'text', filterOption: true, filterFieldOption: 'Parking' },
        { field: 'Salik', header: 'Salik', fieldtype: 'custom', headeralign: 'left', bodyalign: 'center', filterOption: true, filterFieldOption: 'Salik', template: (r: any) => <>{salikBodyTemplate(r)}</> },
        { field: 'Vipfuel', header: 'Vip Fuel', fieldtype: 'custom', headeralign: 'left', bodyalign: 'center', filterOption: true, filterFieldOption: 'Vipfuel', template: (r: any) => <>{vipfuelBodyTemplate(r)}</> },
        { field: 'date', header: 'Date', showtime: false, fieldtype: 'datefilter', headeralign: 'left', bodyalign: 'left', filterOption: true, filterFieldOption: 'date', dataType: 'date' },
        { field: 'updatedAt', header: 'Updated At', fieldtype: 'date', textalign: 'left', filterOption: false, headeralign: 'left', bodyalign: 'left', filterFieldOption: 'updatedAt' }
    ];

    const deleteItems = async () => {
        await AmplifyServices.DeleteItems(selectedItems, deleteDxMasterLogs).then((res) => {
            getData();
            setSelectedItems([]);
            selectedItems.length = 0;
        });
    };

    let params = selectedItems.length > 0 && `?mode=edit&id=${selectedItems[0].id}`;
    let filteredSearch =
        items &&
        items
            .filter((del: any) => del._deleted !== true)
            .filter((name: any) => {
                if (
                    (name.driver !== null && name.driver !== undefined && name.driver.name.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.vehicleid !== null && name.vehicleid.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.Accidents !== null && name.Accidents.toLowerCase().includes(globalFilter.toLowerCase())) ||
                    (name.Fine !== null && name.Fine.includes(globalFilter)) ||
                    (name.Mileage !== null && name.Mileage.includes(globalFilter)) ||
                    (name.Vipfuel !== null && name.Vipfuel.includes(globalFilter)) ||
                    (name.Mileage !== null && name.Mileage.includes(globalFilter)) ||
                    (name.drivername !== null && name.drivername.toLowerCase().includes(globalFilter.toLowerCase()))
                ) {
                    return name;
                }
            })
            .sort((a: any, b: any) => a.order - b.order);
    const Commandbar = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="New Mileage" onClick={() => navigate('/manage/mileage/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />
                <Button icon="pi pi-plus" label="New Fines" onClick={() => navigate('/manage/fines/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />
                <Button icon="pi pi-plus" label="New Salik" onClick={() => navigate('/manage/salik/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />
                <Button icon="pi pi-plus" label="New VIP Fuel" onClick={() => navigate('/manage/vipfuel/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />
                <Button icon="pi pi-plus" label="New Parking" onClick={() => navigate('/manage/parking/item/basic?mode=new')} className="p-button-primary mr-2  newbtnhover" />
                {selectedItems.length > 0 ? (
                    <Button
                        icon="pi pi-trash"
                        label="Delete"
                        onClick={() => {
                            deleteItems();
                        }}
                        className="p-button-danger mr-2  newbtnhover"
                    />
                ) : (
                    ''
                )}
            </div>
            {selectedItems && selectedItems.length > 0 && <span>{selectedItems.length + ' of ' + filteredSearch.length + ' selected'}</span>}

            <div>
                <InputText type="text" value={globalFilter} id={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
                {globalFilter.length > 0 ? (
                    <button type="button" className="contentBox__searchClearBtn" onClick={clearbtn}>
                        X
                    </button>
                ) : (
                    ''
                )}
            </div>
        </div>
    );

    // .filter((name: any) => {
    //     if (name.name.toLowerCase().includes(globalFilter.toLowerCase().toLowerCase()) || name.to.toLowerCase().toLowerCase().includes(globalFilter.toLowerCase()) || name.items.toLowerCase().includes(globalFilter.toLowerCase())) {
    //         return name;
    //     }
    // });
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Logs</h4>
                <div className="datatable-editing-demo">
                    {/* <NewForm disableAllField={false} parent="Workspace" columns={AddForm} fleettype="drivers" title={formTitle} onSubmit={onSubmit} /> */}

                    <TabMenu className="spd-pemptytopbottom" model={logtabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                    <Routes>
                        <Route
                            path={'/all'}
                            element={
                                <>
                                    <ListTemplate
                                        header=""
                                        value={filteredSearch}
                                        paginator={false}
                                        rows={100}
                                        dynamicColumns={columns}
                                        emptyMessage="No items found."
                                        responsiveLayout="scroll"
                                        className="datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[100, 200, 300]}
                                        //   selection={selectedProducts}
                                        multiselect={true}
                                        //   selectedItems={selectedProducts}
                                        Commandbar={Commandbar}
                                        //   onSelectionChange={e => setSelectedProducts(e.value)}Wd
                                        selectedItems={setSelectedItems}
                                    />
                                    {loading === true && (
                                        <div className="md:col-12" style={{ textAlign: 'center' }}>
                                            <ProgressSpinner />
                                        </div>
                                    )}
                                </>
                            }
                        />
                        <Route
                            path={'/salik'}
                            element={
                                <>
                                    <ListTemplate
                                        header=""
                                        value={salikitems.filter((del: any) => del._deleted !== true)}
                                        paginator={false}
                                        rows={100}
                                        dynamicColumns={salikcolumns}
                                        emptyMessage="No items found."
                                        responsiveLayout="scroll"
                                        className="datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[100, 200, 300]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                        //   selectedItems={selectedProducts}
                                        Commandbar={Commandbar}
                                    />
                                    {loading === true && (
                                        <div className="md:col-12" style={{ textAlign: 'center' }}>
                                            <ProgressSpinner />
                                        </div>
                                    )}
                                </>
                            }
                        />
                        <Route
                            path={'/vipfuel'}
                            element={
                                <>
                                    {' '}
                                    <ListTemplate
                                        header=""
                                        value={vipfuelitems.filter((del: any) => del._deleted !== true)}
                                        paginator={false}
                                        rows={100}
                                        dynamicColumns={vipfuelcolumns}
                                        emptyMessage="No items found."
                                        responsiveLayout="scroll"
                                        className="datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[100, 200, 300]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                        //   selectedItems={selectedProducts}
                                        Commandbar={Commandbar}
                                    />
                                    {loading === true && (
                                        <div className="md:col-12" style={{ textAlign: 'center' }}>
                                            <ProgressSpinner />
                                        </div>
                                    )}
                                </>
                            }
                        />
                        <Route
                            path={'/fines'}
                            element={
                                <>
                                    <ListTemplate
                                        header=""
                                        value={fineitems.filter((del: any) => del._deleted !== true)}
                                        paginator={false}
                                        rows={100}
                                        dynamicColumns={finescolumns}
                                        emptyMessage="No items found."
                                        responsiveLayout="scroll"
                                        className="datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[100, 200, 300]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                        //   selectedItems={selectedProducts}
                                        Commandbar={Commandbar}
                                    />
                                    {loading === true && (
                                        <div className="md:col-12" style={{ textAlign: 'center' }}>
                                            <ProgressSpinner />
                                        </div>
                                    )}
                                </>
                            }
                        />
                        <Route
                            path={'/mileage'}
                            element={
                                <>
                                    <ListTemplate
                                        header=""
                                        value={mileageitems.filter((del: any) => del._deleted !== true)}
                                        paginator={false}
                                        rows={100}
                                        dynamicColumns={mileagecolumns}
                                        emptyMessage="No items found."
                                        responsiveLayout="scroll"
                                        className="datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[100, 200, 300]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                        //   selectedItems={selectedProducts}
                                        Commandbar={Commandbar}
                                    />
                                    {loading === true && (
                                        <div className="md:col-12" style={{ textAlign: 'center' }}>
                                            <ProgressSpinner />
                                        </div>
                                    )}
                                </>
                            }
                        />
                        <Route
                            path={'/parking'}
                            element={
                                <>
                                    <ListTemplate
                                        header=""
                                        value={parkingItems.filter((del: any) => del._deleted !== true)}
                                        paginator={false}
                                        rows={100}
                                        dynamicColumns={parkingcolumns}
                                        emptyMessage="No items found."
                                        responsiveLayout="scroll"
                                        className="datatable-responsive"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        rowsPerPageOptions={[100, 200, 300]}
                                        //   selection={selectedProducts}
                                        multiselect={false}
                                        //   selectedItems={selectedProducts}
                                        Commandbar={Commandbar}
                                    />
                                    {loading === true && (
                                        <div className="md:col-12" style={{ textAlign: 'center' }}>
                                            <ProgressSpinner />
                                        </div>
                                    )}
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default ManageLogs;
