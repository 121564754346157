import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import AmplifyServices from '../../Service/Service';
import moment from 'moment';
import './Appointments.css';
import { useNavigate } from 'react-router';
import { Menu } from 'primereact/menu';
import { deleteDxAppointments } from '../../graphql/mutations';
import { Dialog } from 'primereact/dialog';
import dxService from '../../Services/dxService';
import ListTemplate from '../../Components/ListTemplate';
import { Appointment } from '../../DxModels';
import { InputText } from 'primereact/inputtext';
import { listDxAppointments } from '../../graphql/queries';
import { API } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { getNextQueryData } from '../../utils/helper';
import { Dropdown } from 'primereact/dropdown';

// var selectedItems:any
let params: any;
const ManageAppointments = (props: any) => {
    const location = useLocation();
    const menu = useRef<any>(null);
    const [AppointmentType, setAppointmentType] = useState<any>(null);
    const [AppointmentData, setAppointmentData] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [items, setItems] = useState<any>([]);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState<boolean>(false);
    const [cancelItemsDialog, seCancelItemsDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [Eventtype, setEventtype] = useState<any>(null);
    const navigate = useNavigate();
    const [globalFilter, setGlobalFilter] = useState<any>('');
    const urlParams = new URLSearchParams(location.search);
    var vid = urlParams.has('vid') ? urlParams.get('vid') : null;
    var type = urlParams.has('type') ? urlParams.get('type') : null;
    const sortViewOptions = [
        { label: 'All Appointments', value: 'All Appointments' },
        { label: 'Renewal Appointments', value: 'Renewal Appointments' }
    ];
    const EventType = [
        { label: 'Show All', value: '-' },
        { label: 'Branding Expense', value: 'Branding Expense' },
        { label: 'Repair/Replacement', value: 'Repair/Replacement' },
        { label: 'Service', value: 'Service' },
        { label: 'Accident Repair', value: 'Accident Repair' },
        { label: 'Accident', value: 'Accident' }
    ];

    //#region OnLoad methods
    // Calls method during the component load
    useEffect(() => {
        if (vid !== null) {
            getfilteredData();
        } else {
            if (AppointmentType !== null && AppointmentType == 'Renewal Appointments') {
                getRenewalData();
            } else {
                getData();
            }
        }
    }, [AppointmentType]);
    const getRenewalData = async () => {
        let filter = {
            eventtype: {
                eq: 'Renewals'
            }
        };
        await getNextQueryData('', filter, 'appointment', true).then((item: any) => {
            console.log(item);
            setAppointmentData(formatDatefield(item));
            setItems(formatDatefield(item));
            setLoading(false);
        });
    };
    const getfilteredData = async () => {
        let filter = {
            vehicleid: { eq: vid },
            eventtype: { eq: type }
        };
        await getNextQueryData('', filter, 'appointment', true).then((item: any) => {
            console.log(item);
            setAppointmentData(formatDatefield(item));
            setItems(formatDatefield(item));
            setLoading(false);
        });
    };
    const getData = async (nextToken?: any) => {
        let filter = {
            eventtype: {
                ne: 'Renewals'
            }
        };
        const ac = `Date appointmentstatus cost description  eventtype id invoiceno place vehicleid _version _deleted createdAt`;
        await getNextQueryData('', filter, 'appointment', true, ac).then((item: any) => {
            console.log(item);
            console.log(item);
            setAppointmentData(formatDatefield(item));
            setItems(formatDatefield(item));
            setLoading(false);
        });
    };

    //#endregion
    //formating the date
    const formatDatefield = (data: any) => {
        return [...(data || [])].map((d) => {
            d.Date = new Date(d.Date);
            d.createdAt = new Date(d.createdAt);

            return d;
        });
    };

    //appointment status column for table
    const statusBodyTemplate = (e: any) => {
        if (e.appointmentstatus !== null) {
            let status = e.appointmentstatus;
            return <>{status && <span className={`customer-badge status-${status.replaceAll(' ', '')}`}>{status}</span>}</>;
        }
    };
    useEffect(() => {
        //setAppointmentData
        // console.log(items);

        if (items !== null && items.length > 0) {
            console.log(Eventtype);

            let et = Eventtype;
            let searchkeys: any = {};

            if (et !== '' && et !== undefined) searchkeys['eventtype'] = { value: et, condition: 'eq' };

            let fr = items
                .sort(function (a: any, b: any) {
                    return moment(b.createdAt).format().localeCompare(moment(a.createdAt).format());
                })
                .filter((item: any) => {
                    return Object.keys(searchkeys).every((a: any) => {
                        if (searchkeys[a].condition === 'eq') {
                            if (searchkeys[a].value !== '-') {
                                return item[a] !== null && searchkeys[a].value !== null && item[a].toLowerCase() === searchkeys[a].value.toLowerCase();
                            } else {
                                return item;
                            }
                        }
                    });
                });
            setAppointmentData(fr);
        }
    }, [Eventtype]);
    const columns = [
        {
            field: 'vehicleid',
            showfield: 'vehicleid',
            header: 'Vehicle',
            path: '#/manage/appointments/item/basic?mode=view&id=',
            fieldtype: 'href',
            filterOption: true,
            filterFieldOption: 'vehicleid',
            sortable: true,
            sortField: 'vehicleid',
            width: '10rem',
            headeralign: 'left',
            bodyalign: 'left'
        },
        {
            field: 'eventtype',
            header: 'Event Type',
            fieldtype: 'text',
            filterOption: true,
            filterFieldOption: 'eventtype',
            sortable: true,
            sortField: 'eventtype',
            width: '10rem',
            headeralign: 'left',
            bodyalign: 'left'
        },
        {
            field: 'Date',
            header: 'Date',
            fieldtype: 'datefilter',
            showtime: false,
            dataType: 'date',
            filterOption: true,
            filterFieldOption: 'Date',
            sortable: true,
            sortField: 'Date',
            width: '10rem',
            headeralign: 'left',
            bodyalign: 'left'
        },
        {
            field: 'createdAt',
            header: 'Created',
            fieldtype: 'datefilter',
            showtime: false,
            dataType: 'date',
            filterOption: true,
            filterFieldOption: 'createdAt',
            sortable: true,
            sortField: 'createdAt',
            width: '10rem',
            headeralign: 'left',
            bodyalign: 'left'
        },
        {
            field: 'place',
            header: 'Place',
            fieldtype: 'text',
            filterOption: true,
            filterFieldOption: 'place',
            width: '10rem',
            headeralign: 'left',
            bodyalign: 'left'
        },
        {
            field: 'description',
            header: 'Description',
            fieldtype: 'description',
            filterOption: true,
            filterFieldOption: 'description',
            width: '20rem',
            headeralign: 'left',
            bodyalign: 'left'
        },
        {
            field: 'appointmentstatus',
            header: 'Status',
            fieldtype: 'custom',
            filterOption: true,
            filterFieldOption: 'appointmentstatus',
            headeralign: 'left',
            bodyalign: 'left',
            template: (r: any) => <>{statusBodyTemplate(r)}</>
        },
        {
            field: 'updatedAt',
            header: 'Updated At',
            fieldtype: 'date',
            filterOption: false,
            filterFieldOption: 'updatedAt',
            headeralign: 'left',
            bodyalign: 'left'
        }
    ];

    //calls this function to delete the selected item from appointment
    const deleteItem = async () => {
        try {
            await Promise.all(
                selectedItems.map(async (item: any) => {
                    const res = await dxService.deleteitem('appointment', item.id, item._version);
                    let variables = { appointmentid: { eq: res.id } };
                    const spare = await dxService.listitems('vehiclespare', '*', variables, true, 100000);
                    let deleteitems = spare.items;
                    if (deleteitems.length > 0) {
                        await Promise.all(
                            deleteitems.map(async (del: any) => {
                                await dxService.deleteitem('vehiclespare', del.id, del._version);
                            })
                        );
                    }
                    if (item.eventtype == 'Service' || item.eventtype == 'Repair/Replacement' || item.eventtype == 'Repair') {
                        let getvehicleactivity = item.vehicleid;
                        let filter = { id: { eq: getvehicleactivity } };
                        let va = await dxService.listitems('vehicleactivity', '*', filter, true, 100000);
                        if (va.items[0].events !== null) {
                            let vdetails: any = {
                                id: va.items[0].id,
                                _version: va.items[0]._version
                            };
                            let events = JSON.parse(va.items[0].events);
                            if (events.Repair) {
                                let vaevents: any = JSON.parse(va.items[0].events);
                                vaevents['Repair']['cost'] = Math.abs(events.Repair.cost - item.cost) === 0 ? Math.abs(events.Repair.cost - item.cost) : Math.abs(events.Repair.cost - item.cost).toFixed(2);
                                vdetails['events'] = JSON.stringify(vaevents);
                                await dxService.updateitem('vehicleactivity', vdetails);
                            }
                        }
                    } else if (item.eventtype === 'Accident') {
                        const res = await dxService.getitembyid('masterlog', item.dxMasterLogsAccidentsId);
                        await dxService.deleteitem('masterlog', res.id, res._version);
                    }
                })
            );
            reset();
        } catch (err) {
            console.error(err);
        }
    };

    //Calls this function to cancel the appointment

    const cancelItem = async () => {
        selectedItems.map(async (item: any) => {
            const itemtocancel = {
                id: item.id,
                appointmentstatus: 'Cancelled',
                _version: item._version
            };

            await dxService
                .updateitem('appointment', itemtocancel)
                .then(async (res) => {
                    let variables = {
                        appointmentid: { eq: res.id }
                    };
                    await dxService.listitems('vehiclespare', '*', variables, true, 100000).then(async (spare) => {
                        let cancelspares = spare.items;
                        // cancelspares.length > 0 &&
                        const cancelPromises = cancelspares.map((del: any) => {
                            const itemToCancel = {
                                id: del.id,
                                status: 'Cancelled',
                                _version: del._version
                            };
                            return dxService.updateitem('vehiclespare', itemToCancel);
                        });

                        try {
                            await Promise.all(cancelPromises);
                            console.log('All spares cancelled successfully.');
                        } catch (error) {
                            console.error('Error cancelling spares:', error);
                        }
                    });
                    let getvehicleactivity = item.vehicleid;
                    let filter = {
                        id: {
                            eq: getvehicleactivity
                        }
                    };
                    let va = await dxService.listitems('vehicleactivity', '*', filter);
                    if (va[0].events !== null) {
                        const vdetails: any = {
                            id: va[0].id,
                            _version: va[0]._version
                        };

                        const events = JSON.parse(va[0].events);

                        if (item.eventtype === 'Service') {
                            if (events && events.Service) {
                                const vaevents: any = JSON.parse(va[0].events);
                                if (events.Repair) {
                                    vaevents['Repair']['cost'] = Math.abs(events.Repair.cost - item.cost);
                                }

                                vaevents['Service']['appointmentstatus'] = 'Cancelled';
                                delete vaevents['Service']['id'];

                                vdetails['events'] = JSON.stringify(vaevents);

                                dxService.updateitem('vehicleactivity', vdetails).then((res: any) => {
                                    reset();
                                });
                            } else {
                                reset();
                            }
                        } else if (item.eventtype == 'Repair/Replacement' && events.Repair) {
                            const vaevents: any = JSON.parse(va[0].events);
                            vaevents['InternalInspection']['appointmentstatus'] = 'Cancelled';
                            delete vaevents['InternalInspection']['id'];
                            vdetails['events'] = JSON.stringify(vaevents);

                            dxService.updateitem('vehicleactivity', vdetails).then((res: any) => {
                                reset();
                            });
                        } else if (item.eventtype == 'Internal Inspection' && events.InternalInspection) {
                            const vaevents: any = JSON.parse(va[0].events);
                            vaevents['InternalInspection']['appointmentstatus'] = 'Cancelled';
                            delete vaevents['InternalInspection']['id'];
                            vdetails['events'] = JSON.stringify(vaevents);

                            dxService.updateitem('vehicleactivity', vdetails).then((res: any) => {
                                reset();
                            });
                        } else if (events['Accident Repair']) {
                            const vaevents: any = JSON.parse(va[0].events);
                            vaevents['Accident Repair']['appointmentstatus'] = 'Cancelled';
                            delete vaevents['Accident Repair']['id'];
                            vdetails['events'] = JSON.stringify(vaevents);

                            dxService.updateitem('vehicleactivity', vdetails).then((res: any) => {
                                reset();
                            });
                        }
                    } else {
                        reset();
                    }
                })

                .catch((err) => {
                    console.error(err);
                });
        });
        // Promise.all(
        //     selectedItems.map(async (item: any) => {
        //         let deleteItem = {
        //             id: item.id
        //         };
        //         let DeleteDetails = await API.graphql(
        //             graphqlOperation(deleteDxAppointmentNew, {
        //                 input: deleteItem
        //             })
        //         );
        //         return DeleteDetails;
        //     })
        // ).then(() => {
        //     getData();
        // });
        // selectedItems.length = 0;
    };
    const reset = () => {
        getData();
        hideDeleteItemsDialog();
        hideCancelItemsDialog();
        setSelectedItems([]);
        selectedItems.length = 0;
    };
    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };
    const hideCancelItemsDialog = () => {
        seCancelItemsDialog(false);
    };

    const confirmDeleteSelected = (type: any) => {
        if (type === 'delete') {
            setDeleteItemsDialog(true);
        }
        if (type === 'cancel') {
            seCancelItemsDialog(true);
        }
    };
    const clearbtn = () => {
        setGlobalFilter('');
    };

    //filter and sort the datas
    var sorteddata = AppointmentData.filter((del: any) => del._deleted !== true)
        .filter((name: any) => {
            if (
                (name.vehicleid !== null && name.vehicleid.toLowerCase().includes(globalFilter.toLowerCase())) ||
                (name.place !== null && name.place.toLowerCase().includes(globalFilter.toLowerCase())) ||
                (name.eventtype !== null && name.eventtype.toLowerCase().includes(globalFilter.toLowerCase())) ||
                (name.description !== null && name.description.toLowerCase().includes(globalFilter.toLowerCase())) ||
                (name.appointmentstatus !== null && name.appointmentstatus.toLowerCase().includes(globalFilter.toLowerCase()))
            ) {
                return name;
            }
        })
        .sort((a: any, b: any) => {
            const dateA = Number(new Date(a.updatedAt));
            const dateB = Number(new Date(b.updatedAt));
            return dateB - dateA;
        });
    const Commandbar = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <div className="table-header-container">
                <Button icon="pi pi-plus" label="Schedule Appointment" onClick={() => navigate('/manage/appointments/item/basic?mode=new&parent=appointments')} className="p-button-primary mr-2  newbtnhover" />
                <Button type="button" label="Actions" icon="pi pi-angle-down" onClick={(e) => menu.current.toggle(e)} />

                <Menu
                    style={{ width: '160px' }}
                    ref={menu}
                    popup
                    model={[
                        {
                            disabled: selectedItems.length == 1 ? false : true,
                            label: 'Edit Appointment',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                params = selectedItems.length > 0 && `?mode=edit&vid=${selectedItems[0].vehicleid}&type=${selectedItems[0].eventtype}&id=${selectedItems[0].id}&parent=appointments`;
                                navigate('/manage/appointments/item/basic' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length == 1 && selectedItems[0].appointmentstatus !== 'Completed' && selectedItems[0].appointmentstatus !== 'Cancelled' ? false : true,
                            label: 'Close Appointment',
                            icon: 'pi pi-fw pi-pencil',
                            command: () => {
                                params = selectedItems.length > 0 && `?mode=close&vid=${selectedItems[0].vehicleid}&type=${selectedItems[0].eventtype}&id=${selectedItems[0].id}&parent=appointments`;
                                navigate('/manage/appointments/item/basic' + params);
                            }
                        },
                        {
                            disabled: selectedItems.length == 1 && selectedItems[0].appointmentstatus !== 'Completed' && selectedItems[0].appointmentstatus !== 'Cancelled' ? false : true,
                            label: 'Cancel Appointment',
                            icon: 'pi pi-fw pi-times',
                            command: () => {
                                confirmDeleteSelected('cancel');
                            }
                        },
                        {
                            disabled: selectedItems.length > 0 ? false : true,
                            label: 'Delete',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                // deleteItem();
                                confirmDeleteSelected('delete');
                            }
                        }
                    ]}
                ></Menu>
            </div>
            {selectedItems && selectedItems.length > 0 && <span>{selectedItems.length + ' of ' + sorteddata.length + ' selected'}</span>}
            <div className="grid grid-nogutter ">
                <div className="col-12 p-fluid" style={{ textAlign: 'left' }}>
                    <div className="formgrid grid" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <div style={{ display: 'flex' }}>
                            <InputText type="text" style={{ height: '37px' }} value={globalFilter} id={globalFilter} placeholder="Search ..." className="homeNews__search" onChange={(e) => setGlobalFilter(e.target.value)} />
                            {globalFilter.length > 0 ? (
                                <button type="button" className="contentBox__searchClearBtn" onClick={clearbtn}>
                                    X
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="ddsearchstatus" value={Eventtype} options={EventType} optionLabel="label" placeholder="Filter by event type" onChange={(e) => setEventtype(e.value)} />
                                <label htmlFor="ddsearchstatus">Event Type</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="ddsearchstatus" value={AppointmentType} options={sortViewOptions} optionLabel="label" placeholder="Filter by appointment type" onChange={(e) => setAppointmentType(e.value)} />
                                <label htmlFor="ddsearchstatus">Appointment Type</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text companyBtn" onClick={() => deleteItem()} />
        </>
    );
    const cancelItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text companyBtn" onClick={hideCancelItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text companyBtn" onClick={() => cancelItem()} />
        </>
    );

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">Manage Appointments</h4>
                <div className={`datatable-editing-demo ${props.className}`}>
                    <ListTemplate
                        header="Manage Appointments"
                        value={sorteddata}
                        paginator={true}
                        rows={100}
                        dynamicColumns={columns}
                        emptyMessage="No appointments found."
                        responsiveLayout="scroll"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vehicles"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[100, 200, 300]}
                        //   selection={selectedProducts}
                        multiselect={true}
                        loading={loading}
                        //   selectedItems={selectedProducts}
                        Commandbar={Commandbar}
                        selectedItems={setSelectedItems}
                        //   onSelectionChange={e => setSelectedProducts(e.value)}
                    />
                    <Dialog visible={deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedItems && <span>Are you sure you want to delete the selected items?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={cancelItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={cancelItemsDialogFooter} onHide={hideCancelItemsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedItems && <span>Are you sure you want to delete the selected items?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ManageAppointments;
