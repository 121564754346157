import { useEffect, useState, useRef } from 'react';
import { Amplify, API, Storage } from 'aws-amplify';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { Message } from 'primereact/message';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import dxService from '../Services/dxService';
import { convertdate } from '../utils/helper';


let licenseUrl: any;
const ViewTemplate = (props: any) => {
    const UploadDocument = useRef<any>(null);
    const [data, setData] = useState<any>({});
    const [subcolumns, setsubcolumns] = useState<any>([]);
    const [messsage, setMesssage] = useState<any>('');
    const [licenseImages, setLicenseImages] = useState<any>([]);
    const [licenseFieldName, setLicenseFieldName] = useState<any>('');
    const [formitems, setformitems] = useState<any>([]);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    var formmode = urlParams.has('mode') ? urlParams.get('mode') : null;
    var formid = urlParams.has('id') ? urlParams.get('id') : null;

    useEffect(() => {
        if (props.columns && props.columns.columns) {
            let ac = props.columns.columns.filter((p: any) => p.fieldtype != 'header' && p.fieldtype != 'break' && p.fieldtype != 'hidden');
            //ac.push(props.childform);
            setsubcolumns(ac);
        }

    }, [props])


    let layoutclass = 'md:col-12';
    if (props.layout)
        switch (props.layout.toLowerCase()) {
            case 'vertical':
                layoutclass = 'md:col-12';
                break;
            case 'horizontal':
                layoutclass = 'grid md:col-12';
                break;
            case 'grid':
                layoutclass = 'dropdown md:col-6';
                break;
            case 'grid2':
                layoutclass = 'dropdown md:col-4';
                break;
            default:
                layoutclass = 'md:col-12';
        }
    const updatecolumnvalues = () => {
        let columndata: any = {};
        if (subcolumns) {
            subcolumns
                .filter((col: any) => col.fieldtype != 'header')
                .map((val: any, key: number) => {
                    columndata[val.name] = val.value;
                });
            // console.log(columndata);
            setData(columndata);
        }
    };

    
    const dynamicFields = (field: any, showcurrentdate?: boolean) => {
        let ddata: any = data;
        let fieldvalue = field.value;
        var dvalue = ddata && ddata[field.name] ? ddata[field.name] : fieldvalue;
        return (
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                <div className="text-500 w-6 md:w-4 font-medium">{field.title}</div>
                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{field.fieldtype === 'text' ? dvalue : 
                field.fieldtype === 'date' ? convertdate(dvalue) : 
                field.fieldtype === 'currency' ? "AED "+dvalue :
                dvalue}</div>
            </li>
        );
        //}
    };






    const handlesubChange = async (event: any, type: any, fieldName: any) => {
        // console.log(fieldName);
        // console.log(type);
        // console.log(props);
        console.log(event);
        console.log(data);

        if (!event.type) {


            setData({
                ...data,
                [type === 'fileupload' || type == 'form' ? fieldName : type === 'number' ? event.originalEvent.target.name : event.target.name]:
                    type == 'form' ? event : type === 'fileupload' ? event.files[0].objectURL : type === 'number' ? event.originalEvent.target.value : type === 'multiselect' ? event.target.value : event.target.value
            });
        }

    };
    const handleChange = async (event?: any, type?: any, fieldName?: any) => {
        // console.log(fieldName);
        // console.log(type);
        // console.log(props);
        // console.log(event);

        setData({
            ...data,
            [type === 'fileupload' || type == 'form' ? fieldName : type === 'number' ? event.originalEvent.target.name : event.target.name]:
                type == 'form' ? event.target.value : type === 'fileupload' ? event.files[0].objectURL : type === 'number' ? event.originalEvent.target.value : type === 'multiselect' ? event.target.value : event.target.value
        });

        props.onChange(event, type, fieldName);


        //props.changevehicledata(data);
    };
    //get the license file and the fieldName
    const uploadHandler = async (event: any, fieldName: any) => {
        setLicenseImages(event.files);
        setLicenseFieldName(fieldName);
    };
    //call UploadLicense In submit function
    const UploadLicense = async () => {
        let Files: any = [];
        licenseUrl = '';
        console.log(data);
        return await Promise.all(
            licenseImages.map(async (item: any) => {
                // console.log(item);
                let expDOc = {};
                // props.Fleettype !== undefined ?
                if (props.Fleettype !== undefined) {
                    console.log('vehicle1');
                    let url = await Storage.put(`${props.bucketname}/${data.id}/${new Date().getFullYear()}/${data.renewaltype}/` + item.name, item);

                    licenseUrl = url.key;
                } else if (props.Fleettype !== undefined && props.Fleettype == 'vehiclePicture') {
                    console.log('vehicleimage');
                    await Storage.put(`${props.bucketname}/${data.PlateCode + data.PlateNumber}` + item.name, item)
                        .then((res: any) => {
                            Files.push(res.key + ';');
                            licenseUrl = JSON.stringify({ File: Files });
                            // console.log(JSON.stringify({ File: Files }));
                        })
                        .catch((err) => { });
                } else {
                    console.log('vehicle3');
                    await Storage.put(`${props.bucketname}/${data.name !== undefined ? data.name : data.vehicleid}-${new Date().toDateString()}/` + item.name, item)
                        .then((res: any) => {
                            Files.push(res.key + ';');
                            licenseUrl = JSON.stringify({ File: Files });
                            // console.log(JSON.stringify({ File: Files }));
                        })
                        .catch((err) => { });
                }
                // console.log(licenseUrl);
            })
        ).then(() => {
            saveData();
        });
    };

    const subsubmitdata = async () => {
        console.log('s');
        subvalidateinputs();

    }
    const subvalidateinputs = () => {
        console.log(subcolumns);
        setMesssage('');
        let collecteddata = data;
        let a = subcolumns.filter((p: any) => p.fieldtype != 'header');
        let b = subcolumns.filter((p: any) => p.fieldtype != 'header' && p.required);
        let c = Object.keys(collecteddata);
        let d = c.filter((v: any) => {
            return collecteddata[v];
        });

        let e = b.every((v: any) => {
            return d.includes(v.name);
        });

        if (!e) setMesssage('* Please fill all required fields');

        console.log(collecteddata);
        props.submitForm(collecteddata);

    };

    const btnfunction = async (event: any, field: any) => {
        console.log(props);
        field.action.addstep(event);
    }
    const runsubfunction = async (event: any, a: any) => {
        console.log(event);
        console.log(a);

        let formitemid = formitems.length.toString();
        let c = JSON.parse(JSON.stringify(a.columns));
        //c.columns[0].name = formitemid;
        c.columns[0].value = formitemid;
        let b = {
            name: formitemid,
            title: formitemid,
            columns: c
        };
        setformitems([...formitems, b]);
    }
    const submitdata = async () => {
        validateinputs();
    };
    const validateinputs = () => {
        console.log(subcolumns);
        setMesssage('');
        let collecteddata = data;
        let a = subcolumns.filter((p: any) => p.fieldtype != 'header');
        let b = subcolumns.filter((p: any) => p.fieldtype != 'header' && p.fieldtype != 'break' && p.required);
        let c = Object.keys(collecteddata);
        let d = c.filter((v: any) => {
            return collecteddata[v];
        });

        let e = b.every((v: any) => {
            return d.includes(v.name);
        });

        if (!e) setMesssage('* Please fill all required fields');

        console.log(collecteddata);
        console.log(props);
        props.submitForm(collecteddata);
    };
    const saveData = () => {
        console.log(subcolumns);
        let submitteddata = subcolumns.filter((p: any) => p.fieldtype != 'header');
        console.log(submitteddata);
        let tem: any = [];
        let collectedData = data;
        if (Array.isArray(collectedData.partname)) {
            collectedData.partname = JSON.stringify(collectedData.partname);
        } else {
            collectedData = collectedData;
        }
        collectedData[licenseFieldName] = licenseUrl;
        let EditFormFilter = Object.fromEntries(
            Object.entries(collectedData)
                .filter(([key, value]) => value !== null)
                .filter(([key, value]) => key !== '')
                .filter(([key, value]) => value !== undefined)
        );
        let NewFormFilter = Object.fromEntries(
            Object.entries(collectedData)
                .filter(([key, value]) => value !== null)
                .filter(([key, value]) => key !== '')
                .filter(([key, value]) => value !== '')
                .filter(([key, value]) => value !== undefined)
        );
        let finalData = props.getTableName !== undefined ? EditFormFilter : NewFormFilter;
        // Pushing the column items for validation
        subcolumns.map((val: any, key: number) => {
            // console.log(data[val.name]);
            if (data[val.name] == '' && val.required == (props.getTableName !== undefined ? true : false)) {
                return false;
            } else if (data[val.name] == null && val.required == false && data[val.name] == undefined) {
                return false;
            } else {
                tem.push(val.name);
            }
        });
        tem = tem.filter((res: any) => res !== 'id');
        console.log(finalData);
        console.log(tem);
        //Checking id and fleettype (Edit)
        if (finalData.hasOwnProperty('id') && finalData.hasOwnProperty('fleettype')) {
            if (tem.length + 4 == Object.keys(finalData).length) {
                // console.log('1');
                props.submitForm(finalData);
            } else {
                setMesssage('* Please fill all required fields');
            }
        }
        //Checking id and fleettype (Edit)
        else if (finalData.hasOwnProperty('id') && !finalData.hasOwnProperty('fleettype')) {
            if (tem.length + 3 == Object.keys(finalData).length) {
                props.submitForm(finalData);
                // console.log('2');
            } else {
                setMesssage('* Please fill all required fields');
            }
        }
        // New Form
        else {
            if (tem.length == Object.keys(finalData).length) {
                props.submitForm(finalData);
                // console.log('3');
            } else {
                setMesssage('* Please fill all required fields');
            }
        }
    };

    console.log(props);
    //updatecolumnvalues();
    // console.log(data);
    //console.log(subcolumns);
    // console.log("change");
    //props.onChange(data);
    return (
        <div>
            <Toast ref={UploadDocument}></Toast>
            <div className="grid">
                <div className="col-12 md:col-12">
                    <div className="card ">

                        <div className="surface-section">
                            <div className="font-medium text-3xl text-900 mb-3">{props.title}</div>
                            <div className="text-500 mb-5">{props.description}</div>
                            <ul className="list-none p-0 m-0">

                                {
                                    subcolumns ?
                                        subcolumns
                                            .filter((hidden: any) => hidden.hidden === false || hidden.hidden === undefined)
                                            .map((val: any, key: number) => {
                                                return (
                                                    <React.Fragment key={key.toString()}>
                                                        {dynamicFields(val, val.showcurrentdate ? val.showcurrentdate : null)}
                                                    </React.Fragment>
                                                );
                                            }) : ''
                                }
                            </ul>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    );
};
export default ViewTemplate;
