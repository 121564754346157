import { API } from 'aws-amplify';

import { useLocation, useNavigate } from 'react-router-dom';
import { listDxDocuments } from '../../graphql/queries';
import { Storage } from 'aws-amplify';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { DataView } from 'primereact/dataview';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import excel from '../../assets/excel.jpg';
import Pdf from '../../assets/Pdf.jpg';
import ppt from '../../assets/ppt.jpg';
import word from '../../assets/word.jpg';
import rtf from '../../assets/rtf.jpg';
import './Vehicleform.css';
import dxService from '../../Services/dxService';
import { getNextQueryData } from '../../utils/helper';
import { Link } from 'react-router-dom';

const imageExtensions = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp', 'bmp', 'ico', 'cur'];

const AllPictures = (props: any) => {
    const location = useLocation();

    const navigate = useNavigate();
    const [displayDocuments, setDisplayDocuments] = useState<any>([]);
    const urlParams = new URLSearchParams(location.search);
    // var type = urlParams.has('type') ? urlParams.get('type') : null;
    // var allid = urlParams.has('id') ? urlParams.get('id') : null;
    var vid = urlParams.has('id') ? urlParams.get('id') : null;
    useEffect(() => {
        getDxDocs();
    }, []);
    console.log(props.type);
    const getDxDocs = async (nextToken?: any) => {
        // let variables: any = {};
        let variables: any = {
            filter: {
                presourceid: {
                    contains: vid
                },
                field1: {
                    eq: props.type ? props.type : 'pictures'
                }
            }
        };
        if (nextToken) {
            variables.nextToken = nextToken;
        }

        let GetResponse: any = await API.graphql({
            query: listDxDocuments,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: variables
        });

        let res = GetResponse.data.listDxDocuments.items;
        console.log(GetResponse.data.listDxDocuments);
        getFileLocation(res);
        if (GetResponse.data.listDxDocuments.nextToken) {
            const nextResults = await getDxDocs(GetResponse.data.listDxDocuments.nextToken);
            res = res.concat(nextResults);
        }
        res = res.filter((item: any) => item._deleted !== true);
        getFileLocation(res);
        return res;
    };
    // const getDxDocs = async () => {
    //     let docsfilter = {
    //         presourceid: {
    //             contains: vid
    //         },
    //         field1: {
    //             eq: props.type
    //         }
    //     };
    //     let GetResponse: any = await API.graphql({ query: listDxDocuments, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: { filter: docsfilter } });
    //     console.log(GetResponse.data.listDxDocuments.items);
    //     getFileLocation(GetResponse.data.listDxDocuments.items);
    // };
    const Navigation = () => navigate(`/manage/vehicles/item/allocations?id=${vid}&mode=view`);
    const getFileLocation = async (e: any) => {
        console.log(e);

        let FinalData: any = [];

        for (let i = 0; i < e.length; i++) {
            let url = await Storage.get(e[i].name);
            console.log(url);
            console.log(e[i]);
            FinalData.push({
                name: e[i].name.split('/').pop(),
                documents: url,
                path: e[i].name,
                type: e[i].name.split('/')[0],
                doctype: e[i].field1,
                rid: e[i].resourceid,
                vid: e[i].presourceid,
                id: e[i].id
            });
            console.log(FinalData);
        }
        let filterfinaaldata: any = [];
        let removeduplicates: any = [];
        filterfinaaldata = FinalData.filter((fil: any, index: any) => fil.name !== '');

        removeduplicates = filterfinaaldata.filter((item: any, index: any) => filterfinaaldata.indexOf(item) === index);

        // setDisplayDocuments((oldArray: any) => [...oldArray, removeduplicates]);
        setDisplayDocuments(removeduplicates);
    };
    const AttachmentBodyTemplate = (e: any) => {
        return (
            <a href={e.documents} target="_self" className="">
                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
            </a>
        );
    };
    const deletedoc = async (data: any) => {
        console.log(data);

        let filter = {
            // id: {
            //     eq: data.id
            // },
            // resourceid: {
            //     eq: data.rid
            // }
            and: [{ id: { eq: data.id } }, { resourceid: { eq: data.rid } }]
        };
        await getNextQueryData('', filter, 'document').then(async (item: any) => {
            console.log(item);
            let document = item;
            let findvidlength = data.vid.split(',');
            console.log(document);
            if (findvidlength.length > 1) {
                let fullid = data.vid;
                const newString = fullid.replace(vid, '');
                const parseid = newString.replace(',', '');
                console.log(newString.replace(',', ''));
                console.log(document);
                let updatedoc = {
                    id: document[0].id,
                    presourceid: parseid,
                    _version: document[0]._version
                };
                await Storage.remove(data.path)
                    .then(async (res: any) => {
                        // let updatedoc={

                        // }
                        let os = await dxService.updateitem('document', updatedoc).then((success) => {
                            getDxDocs();
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else if (findvidlength.length == 1) {
                await Storage.remove(data.path).then(async (res) => {
                    let deletedoc = await dxService.deleteitem('document', document[0].id, document[0]._version).then((success) => {});
                });
            }
        });
        getDxDocs();
        // dxService.listitems('document', '*', filter);
    };
    const itemTemplate = (product: any) => {
        console.log(product);
        let extension = product.name.split('.').pop();
        console.log(extension);
        let imagesrc =
            extension == 'pdf' ? Pdf : extension == 'xlsx' ? excel : extension == 'rtf' ? rtf : extension == 'pptx' ? ppt : extension == 'docx' ? word : `https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/${product.path}`;
        console.log(imagesrc);

        return (
            <>
                <div className="col-12 sm:col-4 lg:col-4 xl:col-3 p-2">
                    <div className="p-4 border-1 surface-border surface-card border-round">
                        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                            <div className="flex align-items-center gap-2">
                                <i className="pi pi-tag"></i>
                                <span className="font-semibold">{product.type}</span>
                            </div>
                            {imageExtensions.includes(extension) && (
                                <Link to={`/general/viewdocument?id=${product.id}&appid=${vid}&parent=vehicle`}>
                                    <i className="pi pi-eye" style={{ marginLeft: '7px', color: 'blue' }}></i>
                                </Link>
                            )}
                            <a href={product.documents} target="_self" className="">
                                <i className="pi pi-download" style={{ marginLeft: '7px', color: 'blue' }}></i>
                            </a>
                            {/* <Tag value={product.inventoryStatus}></Tag> */}
                        </div>
                        <div className="flex flex-column align-items-center gap-3 py-5">
                            {/* <img className="w-9 shadow-2 border-round" src={`https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/${product.path}`} alt={product.name} /> */}
                            <img className="w-9  border-round" src={product.documents} style={{ height: '150px', objectFit: 'contain' }} />
                            <div className="text-l font-bold DocTitle" title={product.name}>
                                {product.name}
                            </div>
                            {/* <Rating value={product.rating}></Rating> */}
                        </div>
                        <Button
                            icon="pi pi-trash"
                            className="p-button-warning"
                            onClick={(e) => {
                                deletedoc(product);
                            }}
                            style={{ marginRight: '.5em' }}
                        />
                    </div>
                </div>
            </>
        );
    };
    console.log(displayDocuments);

    return (
        <>
            {' '}
            <div className="grid">
                <div className="col-12 md:col-12">
                    <h4 className="container-header">View Pictures</h4>
                    <div className="card">
                        {/* <h5>{type?.toLocaleUpperCase()}</h5> */}
                        {/* <Button className="mr-2 mb-2 p-button-warn" onClick={Navigation}>
                            <i className="pi pi-arrow-left" style={{ fontSize: '1rem' }}></i>
                        </Button> */}
                        {/* <DataTable
                            value={displayDocuments}
                            dataKey="id"
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} contracts"
                            emptyMessage="No Documents added."
                            responsiveLayout="scroll"
                        >
                           
                            <Column field="documents" header="Attachment" body={AttachmentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        </DataTable> */}
                        <DataView value={displayDocuments} itemTemplate={itemTemplate} paginator rows={8} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default AllPictures;
