import React, { useState, useEffect } from 'react';
import { _getDxApprovalConfig, _getDxWorkflow, _listDxApprovalConfigs } from '../../../dxgraphql/dxqueries';
import { useLocation } from 'react-router-dom';
import dxService from '../../../Services/dxService';
import './DriverView.css';
import { DriverDashboardConsumptions } from './DriverConsumption/DriverConsumptions';
import DriverDashboardDocs from './DriverDocument/DriverDashboardDocuments';
import DriverDashboardExpense from './DriverExpense/DriverExpense';
import ListTable from '../../../Components/ListTable';
import AdditionalConsumptions from './DriverConsumption/AdditionalConsumptions';
import { Storage } from 'aws-amplify';
import { Link } from 'react-router-dom';

const DriverView = () => {
    const [driver, setDriver] = useState<any>({ name: '' });
    const [driverAllocations, setDriverAllocations] = useState<any>([]);
    const [consumptionsData, setConsumptionsData] = useState<any>([]);
    const location = useLocation();
    const urlParams1 = new URLSearchParams(location.search);
    var driverid = urlParams1.has('id') ? urlParams1.get('id') : '';
    var drivername = urlParams1.has('name') ? urlParams1.get('name') : '';

    useEffect(() => {
        getDriverData();
    }, []);

    const getDriverData = async () => {
        let filterbyname = {
            name: {
                eq: drivername
            }
        };
        let response = [];
        let nexttoken = null;
        if (drivername !== '') {
            do {
                const { items, nextToken: newNextToken }: any = await dxService.listitems('driver', '*', filterbyname, true, 1000, nexttoken);
                response.push(...items);
                nexttoken = newNextToken;
            } while (nexttoken);
            response = response.filter((del: any) => del._deleted !== true);
            response = response[0];
        } else {
            response = await dxService.getitembyid('driver', driverid);
        }

        if (response && response.jfield !== null && JSON.parse(response.jfield).driverpicture) {
            // console.log(response);
            let driverpic = JSON.parse(response.jfield).driverpicture;
            let driverimage = await Storage.get(driverpic);
            // console.log(driverimage);
            response['driverimage'] = driverimage;
        } else {
            response['driverimage'] = null;
        }
        setDriver(response);
        let allocationFilter = {
            fromdriverID: {
                eq: response.id
            }
        };
        let allocationResponse: any = [];
        let nexttotken = null;
        do {
            const { items, nextToken: newNextToken }: any = await dxService.listitems('allocation', '*', allocationFilter, true, 1000, nexttotken);
            allocationResponse.push(...items);
            nexttotken = newNextToken;
        } while (nexttotken);
        allocationResponse = allocationResponse.filter((item: any) => item._deleted !== true);
        setDriverAllocations(allocationResponse);
    };
    const columns = [
        { field: 'vehicleid', header: 'Vehicle', headeralign: 'left', bodyalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'vehicleid' },
        { field: 'fromKM', header: 'Start KM', headeralign: 'center', bodyalign: 'center', fieldtype: 'text', sortable: true, sortField: 'fromKM' },
        { field: 'handoverKM', header: 'End KM', headeralign: 'center', bodyalign: 'center', fieldtype: 'text', sortable: true, sortField: 'handoverKM' },
        { field: 'allocatestatus', header: 'Status', headeralign: 'left', bodyalign: 'left', fieldtype: 'text', filterOption: true, filterFieldOption: 'allocatestatus' }
        // { field: 'drivername', header: 'drivername', fieldtype: 'text', filterOption: true, filterFieldOption: 'drivername' },
        // { field: 'date', header: 'date', fieldtype: 'date', showtime: false, filterOption: true, filterFieldOption: 'date' }
        // { field: 'status', header: 'Status', fieldtype: 'custom', filterOption: true, filterFieldOption: 'status', template: (r: any) => <>{contractStatusBodyTemplate(r)}</> }
    ];
    // let url = await Storage.get(e[i].name);
    return (
        <>
            <h4 className="container-header">Dashboard : {driver.name}</h4>
            <div className="grid dashboard card">
                <div className="col-12 md:col-6 lg:col-4">
                    <div className="card overview-box flex flex-column driver-container-card">
                        <h5>
                            Driver Details{' '}
                            <Link to={`/manage/drivers/item/basic?id=${driver.id}&mode=edit`} title="Edit Driver">
                                <span className="ml-3 fs-large pi pi-user-edit"></span>
                            </Link>
                        </h5>
                        <div className="driver-info">
                            <div className="driver">
                                {driver.driverimage ? <img src={driver.driverimage} alt={driver.name} title={driver.name} className="mr-3" /> : <div className="pi pi-user image"></div>}
                                <div>
                                    <h4 className="driver_name m-0" title={driver.name}>
                                        {driver.name} {driver.status && '(' + driver.status + ')'}
                                    </h4>
                                    <div title={driver.jobrole}>{driver.jobrole}</div>
                                    <div className="driver-contact" title={driver.contactno}>
                                        {driver.contactno}
                                    </div>
                                </div>
                            </div>
                            <div className="driver-department" title={driver.department && driver.department.name}>
                                Department: {driver.department && driver.department.name}
                            </div>
                            <div className="driver-licenseno" title={driver.licenseno}>
                                License No: {driver.licenseno}
                            </div>
                            <div className="driver-licensetype" title={driver.licensetype}>
                                License Type: {driver.licensetype}
                            </div>
                        </div>
                    </div>
                    <div className="card overview-box flex flex-column driver-container-card">
                        <h5>Allocation Details</h5>
                        <div className="driver-info">
                            <ListTable
                                header="Manage Contracts"
                                value={driverAllocations}
                                paginator={false}
                                rows={100}
                                dynamicColumns={columns}
                                emptyMessage="No Allocation found."
                                responsiveLayout="scroll"
                                className="p-datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Service Contracts"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[100, 200, 300]}
                                //   selection={selectedProducts}
                                // multiselect={false}
                                // tablefilter={Tablefilter}
                                scrollable={true}
                                scrollHeight="60vh"
                            />
                        </div>
                    </div>
                    <div className="card overview-box flex flex-column driver-container-card">
                        <h5>Repairs</h5>
                        <div className="driver-info">
                            <DriverDashboardExpense driverid={driver.id} />
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-8">
                    <div className="card overview-box flex flex-column driver-container-card">
                        <div className="driver-info">
                            <AdditionalConsumptions data={consumptionsData} />
                        </div>
                    </div>

                    <div className="card overview-box flex flex-column driver-container-card">
                        <div className="driver-info">
                            <DriverDashboardConsumptions name={driver.name} getdata={setConsumptionsData} />
                        </div>
                    </div>
                    <div className="card overview-box flex flex-column driver-container-card">
                        <div className="driver-info">
                            <DriverDashboardDocs />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DriverView;
