import { API } from "aws-amplify";
import { getcurrenruserprofile } from "../utils/helper";
import { getitem } from "./dxserviceconfig";
class dxService {
  /**
   * Creates the item and returns the object value
   * @param application 'Application Name'
   * @param value 'Input value for storing the data in to db'
   * @param moredata 'Check whether to use custom data model declaration'
   * @returns Object value after creating the item in db
   */
  async createitem(
    application: string,
    value: any,
    moredata: boolean = false
  ): Promise<any> {
    let _app = moredata ? "_" + application : application;

    if (_app != null) {
      let _temp = "create" + getitem[application].name;
      let createquery = getitem[_app].create;
      let currentime = new Date();
      let randomno: number = parseInt(
        ("0" + Math.floor(Math.random() * 100)).slice(-2)
      );
      if (!value.id) {
        value["id"] = currentime.getTime() + "" + randomno;
      } /** START: Get the display information for owner field */
      let uservalues: any = {};

      if (value.userinfo != undefined && value.userinfo != null) {
        uservalues = JSON.parse(value["userinfo"]);
        uservalues["owner"] = {};
      } else {
        uservalues["owner"] = {};
      }
      let ownervalue = await getcurrenruserprofile();
      uservalues["owner"] = ownervalue;
      value["userinfo"] =
        JSON.stringify(
          uservalues
        ); /** END: Get the display information for owner field */
      let _response: any = await API.graphql({
        query: createquery,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: value,
        },
      });

      let _output = _response.data[_temp];

      return _output;
    } else {
      console.warn(`Check the application name!`);

      return null;
    }
  }

  /**
   * Updates the item and returns the object value
   * @param application 'Application Name'
   * @param value 'Input value for storing the data in to db'
   * @param moredata 'Check whether to use custom data model declaration'
   * @returns Object value after updating the item in db
   */
  async updateitem(
    application: string,
    value: any,
    moredata: boolean = false
  ): Promise<any> {
    let _app = moredata ? "_" + application : application;
    if (_app != null) {
      let _temp = "update" + getitem[application].name;
      let updatequery = getitem[_app].update;
      let _response: any = await API.graphql({
        query: updatequery,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: value,
        },
      });
      let _output = _response.data[_temp];
      return _output;
    } else {
      console.warn(`Check the application name!`);
      return null;
    }
  }

  async deleteitem(
    application: string,
    itemid: string | null,
    version: string
  ): Promise<any> {
    if (itemid) {
      let _filter = {
        input: {
          id: itemid,
          _version: version,
        },
      };
      return await this.deleteresponse(application, _filter);
    }
    return null;
  }

  async deleteresponse(application: string, variable: any): Promise<any> {
    let _app = application;
    let _temp = "delete" + getitem[application].name;
    if (_app != null) {
      let _response: any = await API.graphql({
        query: getitem[application]["delete"],
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: variable,
      });
      let _output = _response.data[_temp];
      return _output;
    } else {
      return null;
    }
  }

  //#region Get Item Function
  /**
   * Returns the object from the table based on unique 'id'
   * @param application 'Application Name'
   * @param itemid
   * @param columns optional column
   * @returns data object from db
   */
  async getitembyid(
    application: string,
    itemid: string | null,
    columns: string = "*",
    moredata: boolean = false
  ): Promise<any> {
    if (itemid) {
      let _filter = {
        id: itemid,
      };
      return await this.getresponse(application, _filter, columns, moredata);
    }
    return null;
  }

  /**
   * Returns the object from the table based on property and its value
   * @param application 'Application Name'
   * @param property 'Property for filtering data'
   * @param itemid 'Value against the property for filtering data'
   * @param columns optional column
   * @param moredata 'Check whether to use custom data model declaration'
   * @returns data object from db
   */
  async getitembyvalue(
    application: string,
    property: string,
    itemid: string | null,
    columns: string = "*",
    moredata: boolean = false
  ): Promise<any> {
    if (itemid) {
      let str = `{
                "${property}": "${itemid}"
            }`;
      let _filter = JSON.parse(str);
      return await this.getresponse(application, _filter, columns, moredata);
    }
    return null;
  }
  //#endregion

  //#region List Items fucntion
  /**
   * Returns the array of items from db based on specified application
   * @param application 'Application Name'
   * @param columns optional column
   * @param moredata 'Check whether to use custom data model declaration'
   * @returns An array of object
   */
  async Sortlistitems(
    application: string,
    columns: string = "*",
    filter: any = {},
    sortkey: any,
    moredata: boolean = false,
    limit?: number,
    nexttoken?: any
  ): Promise<any> {
    // console.log(application, columns, filter, sortkey, moredata);

    let _filter: any = {};
    let sortfield: any = sortkey;

    if (filter) {
      console.log(sortfield);
      _filter = {
        sortstartdatetimeDriverid: filter,
        tablename: "Mileage",
        // limit: limit ? limit : 100,
        // nextToken: nexttoken? nexttoken : null
      };
      console.log(_filter);

      if (limit) {
        //_filter['sortDirection']="DESC";
        _filter["limit"] = limit;
        _filter["nextToken"] = nexttoken ? nexttoken : null;
      }
    }
    return await this.listSortresponse(
      application,
      _filter,
      columns,
      moredata,
      limit
    );
  }
  async listitems(
    application: string,
    columns: string = "*",
    filter: any = {},
    moredata: boolean = false,
    limit?: number,
    nexttoken?: any
  ): Promise<any> {
    let _filter: any = {};
    if (filter) {
      _filter = {
        filter: filter,
        // limit: limit ? limit : 100,
        // nextToken: nexttoken? nexttoken : null
      };

      if (limit) {
        //_filter['sortDirection']="DESC";
        _filter["limit"] = limit;
        _filter["nextToken"] = nexttoken ? nexttoken : null;
      }
    }
    return await this.listresponse(
      application,
      _filter,
      columns,
      moredata,
      limit
    );
  }

  //#endregion

  //#region Utilities - Return responses

  /**
   * Returns the response from db based on the parameters
   * @param application 'Application Name'
   * @param variable 'Filters' and 'query' for the quering database
   * @param columns optional column
   * @param moredata 'Check whether to use custom data model declaration'
   * @returns Output of the response
   */
  async getresponse(
    application: string,
    variable: any,
    columns: string,
    moredata: boolean
  ): Promise<any> {
    let _app = moredata ? "_" + application : application;
    let _op = moredata ? "_" : "";
    let _temp = "get" + getitem[application].name;
    if (_app != null) {
      //let viewcolumns = getitem[_app].get;
      let viewcolumns = getitem[application][_op + "get"];
      if (columns && columns !== "*") {
        let a = `query myquery($id: ID!) {
                    ${_temp}(id: $id) {
                        ${columns}
                    }
                }`;
        viewcolumns = a;
      }
      let _response: any = await API.graphql({
        query: viewcolumns,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: variable,
      });
      let _output = _response.data[_temp];
      return _output;
    } else {
      return null;
    }
  }

  /**
   * Returns the response from db based on the parameters
   * @param application 'Application Name'
   * @param variable 'Filters' and 'query' for the quering database
   * @param columns optional column
   * @param moredata 'Check whether to use custom data model declaration'
   * @returns Output of the response
   */
  async listresponse(
    application: string,
    variable: any,
    columns: string,
    moredata: boolean,
    limit?: number
  ): Promise<any> {
    let _app = moredata ? "_" + application : application;
    let _op = moredata ? "_" : "";

    let _temp = "list" + getitem[application].listname;
    let _filtertemp = "Model" + getitem[application].name + "FilterInput";
    if (_app !== null && getitem[application]) {
      let viewcolumns = getitem[application][_op + "list"];
      if (columns && columns !== "*") {
        let a = `query myquery(
                    $id: ID
                    $filter: ${_filtertemp}
                    $limit: Int
                    $nextToken: String
                    $sortDirection: ModelSortDirection
                  ) {
                    ${_temp}(
                      id: $id
                      filter: $filter
                      limit: $limit
                      nextToken: $nextToken
                      sortDirection: $sortDirection
                    ) {
                      items {
                        ${columns}
                    }
                    nextToken
                        startedAt
                }
            }`;
        viewcolumns = a;
      }

      let _response: any = await API.graphql({
        query: viewcolumns,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: variable,
      });

      let _output = _response.data[_temp].items;
      if (limit) {
        _output = _response.data[_temp];
      }
      return _output;
    } else {
      console.warn(`Check the application name! 
            Values passed:{application: ${application},variables: ${
        variable ? JSON.stringify(variable) : variable
      }, moredata: ${moredata}`);
      return null;
    }
  }
  async listSortresponse(
    application: string,
    variable: any,
    columns: string,
    moredata: boolean,
    limit?: number
  ): Promise<any> {
    console.log(application, variable, columns, moredata, limit);

    let _app = moredata ? "_" + application : application;
    let _op = moredata ? "_" : "";

    let _temp = "list" + getitem[application].listname;
    let _filtertemp = "Model" + getitem[application].name + "FilterInput";
    if (_app !== null && getitem[application]) {
      let viewcolumns = getitem[application][_op + "list"];
      if (columns && columns !== "*") {
        let a = `query myquery(
                    $id: ID
                    $filter: ${_filtertemp}
                    $limit: Int
                    $nextToken: String
                    $sortDirection: ModelSortDirection
                  ) {
                    ${_temp}(
                      id: $id
                      filter: $filter
                      limit: $limit
                      nextToken: $nextToken
                      sortDirection: $sortDirection
                    ) {
                      items {
                        ${columns}
                    }
                    nextToken
                        startedAt
                }
            }`;
        viewcolumns = a;
      }

      let _response: any = await API.graphql({
        query: viewcolumns,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: variable,
      });
      //   console.log(_temp);

      console.log(_response.data[_temp].items);

      let _output = _response.data[_temp].items;
      if (limit) {
        _output = _response.data[_temp];
      }
      return _output;
    } else {
      console.warn(`Check the application name! 
            Values passed:{application: ${application},variables: ${
        variable ? JSON.stringify(variable) : variable
      }, moredata: ${moredata}`);
      return null;
    }
  }
  async queryitems(query: string, qfilter: any = {}): Promise<any> {
    let a: any = await API.graphql({
      query: query,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: qfilter,
    });
    let keys = Object.keys(a.data);
    if (keys.length > 0) {
      let listtype = keys[0];
      return a.data[listtype];
    }
    return null;
  }

  //#endregion
}
export default new dxService();
