import { API } from 'aws-amplify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AmplifyServices from '../../../Service/Service';
import { createDxLogParking, createDxLogSalik, createDxMasterLogs } from '../../../graphql/mutations';
import { listDxAllocations, listDxLogParkings, listDxLogSaliks, listDxVehicles } from '../../../graphql/queries';
import { GraphQLSubscription } from '@aws-amplify/api';
import FormTemplate from '../../../Components/FormTemplate';
import { OnCreateDxLogParkingSubscription, OnCreateDxLogSalikSubscription } from '../../../API';
import { onCreateDxLogParking, onCreateDxLogSalik } from '../../../graphql/subscriptions';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import moment from 'moment';
import dxService from '../../../Services/dxService';
import { getNextQueryData, getvendorCompanyoptions, isotolocaledatetime, localetoisodateendtime, localetoisodatetime } from '../../../utils/helper';
let driversNameOptions: any = [];
const Parkingform = () => {
    const toast = useRef<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [vehicleIDOptions, setVehicleIDOptions] = useState<any>([]);
    const [vehicleID, setVehicleID] = useState<any>('');
    const [parkingDate, setparkingDate] = useState<any>(null);
    const [driverName, setDriverName] = useState<any>('');
    const [driverID, setDriverID] = useState<any>('');
    const [listFormData, setlistFormData] = useState<any>({});
    const [updatedate, setupdatedate] = useState<any>({});
    const [errorMsg, seterrorMsg] = useState<any>(null);
    const [AddParking, setAddParking] = useState<any>({});
    const [allocationID, setallocationID] = useState<any>('');
    const [CompanyVendorOptions, setGarageCompanyOptions] = useState<any>([]);
    const location1 = useLocation();
    const urlParams1 = new URLSearchParams(location1.search);
    var formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
    var formid = urlParams1.has('id') ? urlParams1.get('id') : null;
    var driver: any = urlParams1.has('driver') ? urlParams1.get('driver') : null;
    var type: any = urlParams1.has('type') ? urlParams1.get('type') : null;
    const navigate = useNavigate();

    const checkActiveIndex = useCallback(() => {
        formmode = urlParams1.has('mode') ? urlParams1.get('mode') : null;
        formid = urlParams1.has('id') ? urlParams1.get('id') : null;
        const paths = location1.pathname.split('/');
        const currentPath = paths[paths.length - 1];

        switch (currentPath) {
            case 'documents':
                setActiveIndex(1);
                break;
            case 'allocations':
                setActiveIndex(2);
                break;

            default:
                if (formid) getvendordata(formid);
                break;
        }
    }, [location1]);
    useEffect(() => {
        checkActiveIndex();
        getVehicleID();
        getdriverID();
    }, [checkActiveIndex]);
    useEffect(() => {
        if (formid !== null) {
            // fetchUserIdUserName().then((driver: any) => {
            setlistFormData({ vehicleid: formid });
            // });
        }
    }, []);
    const fetchUserIdUserName = async (nextToken?: any) => {
        let variables: any = {
            id: {
                eq: formid
            }
        };
        let Finedata = await dxService.listitems('vehicleactivity', '*', variables, true, 1000, nextToken);
        let response = Finedata.items;

        let nexttoken = Finedata.nextToken;
        if (nexttoken) {
            response = [...response, ...(await fetchUserIdUserName(nexttoken))];

            // response = response.concat(nextResults);
        }

        return response;
    };
    const getvendordata = async (fid: any) => {
        let Companyvendordata = await getvendorCompanyoptions();

        setGarageCompanyOptions(Companyvendordata);
        await AmplifyServices.getFilterbyIddetails(listDxLogParkings, 'listDxLogParkings', fid).then((a) => {
            if (a.length > 0) {
                setlistFormData(a[0]);
            }
        });
    };

    const getVehicleID = async () => {
        let GetResponse: any = await API.graphql({ query: listDxVehicles, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        setVehicleIDOptions(GetResponse.data.listDxVehicles.items);
        // getDriverDetailsByDateandVID();
    };
    const getdriverID = async () => {
        driversNameOptions = [];
        let drivers = await dxService.listitems('driver');
        drivers.map((res: any) => {
            res['label'] = res.name;
            res['value'] = res.id;
            driversNameOptions.push(res);
        });
    };
    useEffect(() => {
        parkingDate !== null && vehicleID !== '' && getDriverDetailsByDateandVID();
    }, [parkingDate, vehicleID]);

    //get driver id and driver name based on vehicle and date selection
    const getDriverDetailsByDateandVID = async () => {
        let response: any = [];

        let filter = {
            vehicleid: {
                eq: vehicleID
            },

            fromdate: {
                le: parkingDate ? localetoisodatetime(parkingDate) : null
            },

            or: [
                {
                    handoverdate: {
                        ge: parkingDate ? localetoisodateendtime(parkingDate) : null
                    }
                },

                {
                    handoverdate: {
                        ne: ''
                    }
                }
            ] // allocatestatus: { //     eq: 'Allocated' // }
        };

        dxService.listitems('allocation', '*', filter, true, 100000).then((res) => {
            res.items = res.items.sort((a: { fromdate: any }, b: { fromdate: string | number | Date }) => {
                const dateA = new Date(a.fromdate);

                const dateB = new Date(b.fromdate);

                return dateB.getTime() - dateA.getTime();
            });

            if (res.items.length > 0) {
                const filteredItems = res.items.filter((item: { handoverdate: string | number | Date; fromdate: string | number | Date }) => {
                    if (item.handoverdate) {
                        const fromDate = new Date(item.fromdate).toDateString();

                        const handoverDate = new Date(item.handoverdate).toDateString();

                        const selectedDateObj = new Date(parkingDate).toDateString();

                        return selectedDateObj === fromDate && selectedDateObj === handoverDate;
                    } else {
                        return false;
                    }
                }); // Filtered items are available

                const selectedItem = filteredItems.length > 0 ? filteredItems[0] : res.items[0];

                setlistFormData({
                    ...listFormData,

                    drivername: selectedItem.driver.id,

                    date: parkingDate
                });
                setallocationID(selectedItem.id);
                setDriverName(selectedItem.fromdrivername !== null ? selectedItem.fromdrivername : selectedItem.handoverdrivername !== null ? selectedItem.handoverdrivername : '');

                setDriverID(selectedItem.dxDriverAllocationsId !== null ? selectedItem.dxDriverAllocationsId : selectedItem.driverID !== null ? selectedItem.driverID : '');
            } else {
            }
        });
    };

    const VehicleOptions: any = [];
    const names = vehicleIDOptions
        .filter((del: any) => del._deleted !== true)
        .map((res: any) => {
            res['label'] = res.id;
            res['value'] = res.id;
            VehicleOptions.push(res);
        });
    useEffect(() => {
        let cols = formColumns.columns.filter((a: any) => {
            return a.fieldtype != 'header';
        });
        cols.map((c) => {
            c.value = listFormData[c.name] ? listFormData[c.name] : c.value;
        });
    }, [listFormData]);
    const formColumns = {
        columns: [
            //Edit Form Columns
            {
                title: 'Basic Info',
                name: 'basicinfo',
                fieldtype: 'header',
                class: 'md:col-12'
            },

            {
                name: 'vehicleid', // pass the exact column name of db
                title: 'Vehicle ID',
                fieldtype: 'dropdown',
                value: '',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: VehicleOptions,
                class: 'md:col-6'
            },
            {
                name: 'date', // pass the exact column name of db
                title: 'Trip Date',
                fieldtype: 'date',
                value: '',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'driverid', // pass the exact column name of db
                title: 'Driver ID',
                fieldtype: 'text',
                value: driverID,
                required: false,
                disabled: true,
                hidden: true,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'drivername', // pass the exact column name of db
                title: 'Driver Name',
                fieldtype: 'dropdown',
                value: driverName,
                required: true,
                disabled: false,
                hidden: false,
                data: driversNameOptions,
                class: 'md:col-6'
            },
            {
                name: 'transactiondate', // pass the exact column name of db
                title: 'Transaction Date',
                fieldtype: 'date',
                value: '',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'transactionid', // pass the exact column name of db
                title: 'Transaction ID', //for display
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },

            {
                name: 'amount', // pass the exact column name of db
                title: 'Amount', //for display
                value: '',
                fieldtype: 'text',
                required: true,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },

            {
                name: 'description', // pass the exact column name of db
                title: 'Description',
                value: '',
                fieldtype: 'text',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'expirydate', // pass the exact column name of db
                title: 'Expiry Date',
                value: '',
                fieldtype: 'date',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: type == 'cash' ? true : false,
                data: [],
                class: 'md:col-6'
            },
            {
                name: 'company', // pass the exact column name of db
                title: 'Company',
                value: '',
                fieldtype: 'dropdown',
                required: false,
                disabled: formmode == 'view' ? true : false,
                hidden: type == 'cash' ? true : false,
                data: CompanyVendorOptions,
                class: 'md:col-6'
            }
        ],
        actions: [
            {
                title: 'Submit',
                disabled: errorMsg !== null ? true : false
            },
            {
                title: 'Cancel'
            }
        ]
    };
    const getReimbursemasterlogdata = async (props?: any, nextToken?: any) => {
        // let variables: any = {};
        let convertdate: any = localetoisodatetime(props.date);
        let splitdate: any = convertdate.split('T')[0];
        let variables: any = {
            // and: [{ date: { ge: localetoisodatetime(lastTwoWeekStartDate) } },

            //      { date: { le: moment(localetoisodatetime(lasttwoWeekEndDate.toISOString())).format('YYYY-MM-DD') + 'T23:59:59.999Z' } }
            //     ]
            vehicleid: { eq: props.vehicleid },
            date: { contains: splitdate },
            dxDriverMasterlogsId: { eq: props.driverid },
            reimbursement: { eq: 'Yes' }
        };
        let get14daysExp = await dxService.listitems('masterlog', '*', variables, true, 6000, nextToken);
        let response = get14daysExp.items;

        let nexttoken = get14daysExp.nextToken;
        if (nexttoken) {
            response = [...response, ...(await getReimbursemasterlogdata(props, nexttoken))];
        }
        return response;
    };
    const getmasterlogdata = async (props?: any, nextToken?: any) => {
        // let variables: any = {};
        let convertdate: any = localetoisodatetime(props.date);
        let splitdate: any = convertdate.split('T')[0];
        let variables: any = {
            // and: [{ date: { ge: localetoisodatetime(lastTwoWeekStartDate) } },

            //      { date: { le: moment(localetoisodatetime(lasttwoWeekEndDate.toISOString())).format('YYYY-MM-DD') + 'T23:59:59.999Z' } }
            //     ]
            vehicleid: { eq: props.vehicleid },
            date: { contains: splitdate },
            dxDriverMasterlogsId: { eq: props.driverid },
            reimbursement: { ne: 'Yes' }
        };
        let get14daysExp = await dxService.listitems('masterlog', '*', variables, true, 6000, nextToken);
        let response = get14daysExp.items;

        let nexttoken = get14daysExp.nextToken;
        if (nexttoken) {
            response = [...response, ...(await getmasterlogdata(props, nexttoken))];
        }
        return response;
    };
    const addData = async (props: any) => {
        setAddParking(props);
        let parkingdata: any = {};
        let data = props;
        let filter = {
            id: { eq: data.drivername }
        };
        let driversName = await getNextQueryData('', filter, 'driver', true).then(async (driver: any) => {
            // data['id'] = Math.random().toString(36).substring(2, 9);
            data['driverid'] = data.drivername !== undefined ? data.drivername : driverID;
            data['drivername'] = driver[0].name !== undefined ? driver[0].name : driverName;
            const subs = API.graphql<GraphQLSubscription<OnCreateDxLogParkingSubscription>>({
                query: onCreateDxLogParking,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            }).subscribe({
                next: async ({ provider, value }) => {},
                error: (error) => console.warn(error)
            });

            console.log(data);
            if (type == 'cash') {
                await getReimbursemasterlogdata(props).then(async (res: any) => {
                    let sumParking = 0;
                    if (res.length > 0) {
                        if (allocationID !== '') {
                            res.map((item: any) => {
                                sumParking += item.Parking !== '' && item.Parking !== null && item.Parking !== 'NaN' ? parseFloat(item.Parking) : 0;
                                // sumParking += parseFloat(item.Parking);
                            });
                            console.log(sumParking);

                            let updatemasterData = {
                                vehicleid: data.vehicleid,
                                drivername: data.drivername !== undefined ? data.drivername : driverName,
                                date: localetoisodatetime(data.date),
                                // Fine: '',
                                // Salik: '',
                                Parking: sumParking + parseFloat(data.amount),
                                // Vipfuel: '',
                                dxVehicleActivityLogsId: data.vehicleid,
                                dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : '',
                                // dxMasterLogsParkingsId: res[0].id,
                                dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                                dxVehicleMasterlogsId: data.vehicleid,
                                _version: res[0]._version,
                                id: res[0].id,
                                reimbursement: 'Yes'
                            };
                            console.log(updatemasterData);
                            let os = await dxService.updateitem('masterlog', updatemasterData).then((success: any) => {
                                // showSuccess('', 'Added Successfully');
                                if (subs) subs.unsubscribe();
                                // navigate('/manage/logs/all');
                                // window.history.back();
                            });
                        } else {
                            seterrorMsg('There is no Allocation available for this combination');
                            showError();
                        }
                    } else {
                        if (allocationID !== '') {
                            let masterData: any = {
                                vehicleid: data.vehicleid,
                                drivername: data.drivername !== undefined ? data.drivername : driverName,
                                date: localetoisodatetime(data.date),
                                // Fine: '',
                                // Salik: '',
                                dxVehicleActivityLogsId: data.vehicleid,
                                dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : '',
                                Parking: data.amount,
                                // Vipfuel: '',
                                dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                                dxVehicleMasterlogsId: data.vehicleid,
                                reimbursement: 'Yes'
                            };
                            let cuskey = `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}#reimbursement`;
                            masterData['id'] = cuskey;
                            console.log(masterData);
                            // parkingdata['dxMasterLogsParkingsId'] = cuskey;
                            let master = await dxService.createitem('masterlog', masterData, false).then((success) => {
                                // showSuccess('', 'Added Successfully');
                                if (subs) subs.unsubscribe();
                                // navigate('/manage/logs/all');
                                // window.history.back();
                            });
                        } else {
                            seterrorMsg('There is no Allocation available for this combination');
                            showError();
                        }
                    }
                });
            } else {
                await getmasterlogdata(props).then(async (res: any) => {
                    let sumParking = 0;
                    if (res.length > 0) {
                        if (allocationID !== '') {
                            res.map((item: any) => {
                                sumParking += item.Parking !== '' && item.Parking !== null && item.Parking !== 'NaN' ? parseFloat(item.Parking) : 0;
                                // sumParking += parseFloat(item.Parking);
                            });
                            console.log(sumParking);

                            let updatemasterData = {
                                vehicleid: data.vehicleid,
                                drivername: data.drivername !== undefined ? data.drivername : driverName,
                                date: localetoisodatetime(data.date),
                                // Fine: '',
                                // Salik: '',
                                Parking: sumParking + parseFloat(data.amount),
                                // Vipfuel: '',
                                dxVehicleActivityLogsId: data.vehicleid,
                                dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : '',
                                // dxMasterLogsParkingsId: res[0].id,
                                dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                                dxVehicleMasterlogsId: data.vehicleid,
                                _version: res[0]._version,
                                id: res[0].id
                            };
                            console.log(updatemasterData);
                            let os = await dxService.updateitem('masterlog', updatemasterData).then((success: any) => {
                                // showSuccess('', 'Added Successfully');
                                if (subs) subs.unsubscribe();
                                // navigate('/manage/logs/all');
                                // window.history.back();
                            });
                        } else {
                            seterrorMsg('There is no Allocation available for this combination');
                            showError();
                        }
                    } else {
                        if (allocationID !== '') {
                            let masterData: any = {
                                vehicleid: data.vehicleid,
                                drivername: data.drivername !== undefined ? data.drivername : driverName,
                                date: localetoisodatetime(data.date),
                                // Fine: '',
                                // Salik: '',
                                dxVehicleActivityLogsId: data.vehicleid,
                                dxAllocationsMasterlogsId: allocationID !== undefined ? allocationID : '',
                                Parking: data.amount,
                                // Vipfuel: '',
                                dxDriverMasterlogsId: data.driverid !== undefined ? data.driverid : driverID,
                                dxVehicleMasterlogsId: data.vehicleid
                            };
                            let cuskey = `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}`;
                            masterData['id'] = cuskey;
                            console.log(masterData);
                            // parkingdata['dxMasterLogsParkingsId'] = cuskey;
                            let master = await dxService.createitem('masterlog', masterData, false).then((success) => {
                                // showSuccess('', 'Added Successfully');
                                if (subs) subs.unsubscribe();
                                // navigate('/manage/logs/all');
                                // window.history.back();
                            });
                        } else {
                            seterrorMsg('There is no Allocation available for this combination');
                            showError();
                        }
                    }
                });
            }
            parkingdata = {
                id: data.id,
                amount: data.amount,
                date: localetoisodatetime(data.date),
                driverid: data.driverid,
                drivername: data.drivername,
                // dxMasterLogsParkingsId: `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}`,
                transactiondate: localetoisodatetime(data.transactiondate),
                innovationfee: data.transactionid,
                vehicleid: data.vehicleid,
                totalamount: data.description,
                expirydate: localetoisodatetime(data.expirydate),
                company: data.company,
                jfield: JSON.stringify({
                    allocationid: allocationID
                })
            };
            if (allocationID !== '') {
                if (type == 'cash') {
                    parkingdata['reimbursement'] = 'Yes';
                    parkingdata['dxMasterLogsParkingsId'] = `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}#reimbursement`;
                } else {
                    parkingdata['dxMasterLogsParkingsId'] = `${data.vehicleid}#${localetoisodatetime(data.date)}#${data.drivername !== undefined ? data.drivername : driverName}`;
                }
                let parkingitem = await dxService.createitem('logparking', parkingdata, false).then(async (success) => {
                    await showSuccess('', 'Added Successfully');
                    if (subs) subs.unsubscribe();
                    setTimeout(() => {
                        window.history.back();
                    }, 1000);
                });
            }
        });
    };
    const changedata = (props: any) => {
        setupdatedate(props);
        props['vehicleid'] !== undefined && setVehicleID(props.vehicleid);
        props['date'] !== undefined && setparkingDate(props.date);
    };
    let qs = '';
    let qsa = '';
    qsa = formid != null ? 'id=' + formid : '';
    qsa += formmode != null ? '&mode=' + formmode : '';
    qs = qsa != '' ? '?' + qsa : '';

    const wizardItems = [
        { label: 'New Parking', command: () => navigate('/manage/parking/item/basic' + qs) },
        { label: 'New Salik', command: () => navigate('/manage/salik/item/basic' + qs) },
        { label: 'New Mileage', command: () => navigate('/manage/mileage/item/basic' + qs) },
        { label: 'New Fines', command: () => navigate('/manage/fines/item/basic' + qs) },
        { label: 'New VIP Fuel', command: () => navigate('/manage/vipfuel/item/basic' + qs) }
    ];
    const editnewnavigation = async (formdata: any, fid: string) => {
        //
    };
    const showSuccess = (summary: string, detail: string) => {
        toast.current.show({
            severity: 'success',
            summary: summary,
            detail: detail,
            life: 3000
        });
    };
    const showError = () => {
        toast.current.show({
            severity: 'error',
            summary: 'There is no Allocation available for this combination',
            life: 3000
        });
    };
    const addConfig = (props: any) => {
        // if (formid == null)
        addData(props);
        // else {
        //     editnewnavigation(props, formid);
        // }
    };
    const logtabItems = [
        { label: 'Parking', command: () => navigate('/manage/logs/parking') },
        {
            label: 'Salik',
            command: () => navigate('/manage/logs/salik')
        },

        // { label: 'Documents', disabled: formID != "" ? false : true ,command: () => navigate('/manage/appointments/item/documents' + qs) }
        { label: 'Vip Fuel', command: () => navigate('/manage/logs/vipfuel') },
        { label: 'Fines', command: () => navigate('/manage/logs/fines') },
        { label: 'Mileage', command: () => navigate('/manage/logs/mileage') },

        {
            label: 'All',
            command: () => navigate('/manage/logs/all')
        }
    ];
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <h4 className="container-header">{formmode === 'edit' ? 'Edit Salik : ' + formid : formmode === 'view' ? 'View Salik: ' + formid : type == 'cash' ? 'Parking Reimbursement Form' : 'New Parking'}</h4>
                <TabMenu className="spd-pemptytopbottom" model={logtabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                <div className="card container-card">
                    <Toast ref={toast} />
                    <TabMenu className="spd-pemptytopbottom" model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                    <Routes>
                        <Route
                            path={'/basic'}
                            element={
                                <>
                                    <FormTemplate columns={formColumns} data={listFormData} showaction={true} onChange={changedata} mode={formmode} ChangeBtn={'OpenDocument'} layout="grid2" title="New Approval" submitForm={addConfig} />
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};
export default Parkingform;
