import { GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { OnCreateDxAllocationsSubscription, OnCreateDxAppointmentsSubscription, OnCreateDxContractSubscription, OnUpdateDxAllocationsSubscription, OnUpdateDxAppointmentsSubscription, OnUpdateDxContractSubscription } from '../../API';
import { onCreateDxAllocations, onCreateDxAppointments, onCreateDxContract, onUpdateDxAllocations, onUpdateDxAppointments, onUpdateDxContract } from '../../graphql/subscriptions';
import AmplifyServices from '../../Service/Service';
const NotifyControl = (props: any) => {
    const newtoast = useRef<any>();

    useEffect(() => {
        subscribecontracts();

        subscribeappointments();

        subscribeallocation();
    }, []);
    const getVehicleActivityData = async () => {
        await AmplifyServices.getvehicleactivitydetails()
            .then(async (res: any) => {
                // navigate('/general/workspace');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const subscribeallocation =async () =>{
        const subsallocate = API.graphql<GraphQLSubscription<OnCreateDxAllocationsSubscription>>({
            query: onCreateDxAllocations,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value });
                let createditem: any = value;
                getVehicleActivityData();
                console.log(createditem);
                let vehicleid = " - " +createditem.data.onCreateDxAllocations.vehicleid ? "Vehicle ID: "+ createditem.data.onCreateDxAllocations.vehicleid:"";
                newtoast.current.show({
                    severity: 'success',
                    summary: 'Driver Allocated successfully!',
                    detail: 'Allocated ID: ' + createditem.data.onCreateDxAllocations.id + vehicleid ,
                    life: 3000
                });
            },
            error: (error) => console.warn(error)
        });

        const subsdeallocate = API.graphql<GraphQLSubscription<OnUpdateDxAllocationsSubscription>>({
            query: onUpdateDxAllocations,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value });
                let createditem: any = value;
                getVehicleActivityData();
                console.log(createditem);
                let vehicleid = " - " +createditem.data.onUpdateDxAllocations.vehicleid ? "Vehicle ID: "+ createditem.data.onUpdateDxAllocations.vehicleid:"";
                newtoast.current.show({
                    severity: 'success',
                    summary: 'Driver Allocation updated successfully!',
                    detail: 'Allocated ID: ' + createditem.data.onUpdateDxAllocations.id + vehicleid ,
                    life: 3000
                });
            },
            error: (error) => console.warn(error)
        });
    }
    const subscribecontracts = async () => {
        const subs = API.graphql<GraphQLSubscription<OnCreateDxContractSubscription>>({
            query: onCreateDxContract,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value });
                let createditem: any = value;
                getVehicleActivityData();
                console.log(createditem);
                let vehicleid = createditem.data.onCreateDxContract.vehicleid ? "Vehicle ID: "+ createditem.data.onCreateDxContract.vehicleid+" - " :'';

                newtoast.current.show({
                    severity: 'success',
                    summary: 'New Renewal is created successfully!',
                    detail: vehicleid + 'Renewal ID: ' + createditem.data.onCreateDxContract.id,
                    life: 3000
                });
            },
            error: (error) => console.warn(error)
        });

        const subs2 = API.graphql<GraphQLSubscription<OnUpdateDxContractSubscription>>({
            query: onUpdateDxContract,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value });
                let updateditem: any = value;
                getVehicleActivityData();
                console.log(updateditem);
                let vehicleid = updateditem.data.onUpdateDxContract.vehicleid ? "Vehicle ID: "+ updateditem.data.onUpdateDxContract.vehicleid+" - " :'';
                newtoast.current.show({
                    severity: 'success',
                    summary: 'Renewal is updated successfully!',
                    detail: vehicleid + 'Renewal ID: ' + updateditem.data.onUpdateDxContract.id,
                    life: 3000
                });
            },
            error: (error) => console.warn(error)
        });
    };

    const subscribeappointments = async () => {

      


        const subs = API.graphql<GraphQLSubscription<OnCreateDxAppointmentsSubscription>>({
            query: onCreateDxAppointments,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value });
                let createditem: any = value;
                getVehicleActivityData();
                console.log(createditem);
                let vehicleid = createditem.data.onCreateDxAppointments.vehicleid ? "Vehicle ID: "+ createditem.data.onCreateDxAppointments.vehicleid+" - " :'';
                newtoast.current.show({
                    severity: 'success',
                    summary: 'New Appointment is created successfully!',
                    detail: vehicleid +'Appointment ID: ' + createditem.data.onCreateDxAppointments.id,
                    life: 3000
                });
            },
            error: (error) => console.warn(error)
        });

        const subs2 = API.graphql<GraphQLSubscription<OnUpdateDxAppointmentsSubscription>>({
            query: onUpdateDxAppointments,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
            next: async ({ provider, value }) => {
                console.log({ provider, value });
                let updateditem: any = value;
                getVehicleActivityData();
                let vehicleid = updateditem.data.onUpdateDxAppointments.vehicleid ? "Vehicle ID: "+ updateditem.data.onUpdateDxAppointments.vehicleid+" - " :'';
                console.log(updateditem);
                newtoast.current.show({
                    severity: 'success',
                    summary: 'Appointment is updated successfully!',
                    detail: vehicleid + 'Appointment ID: ' + updateditem.data.onUpdateDxAppointments.id,
                    life: 3000
                });
            },
            error: (error) => console.warn(error)
        });
    };

    return (
        <>
            <Toast ref={newtoast} />
        </>
    );
};

export default NotifyControl;


