import { API } from "aws-amplify";

import { useLocation, useNavigate } from "react-router-dom";
import { listDxDocuments } from "../../graphql/queries";
import { Storage } from "aws-amplify";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { DataView } from "primereact/dataview";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import excel from "../../assets/excel.jpg";
import Pdf from "../../assets/Pdf.jpg";
import ppt from "../../assets/ppt.jpg";
import word from "../../assets/word.jpg";
import rtf from "../../assets/rtf.jpg";
import "../Vehicles/Vehicleform.css";
import { Dropdown } from "primereact/dropdown";
import dxService from "../../Services/dxService";
import { Link } from "react-router-dom";

const imageExtensions = [
  "apng",
  "avif",
  "gif",
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
  "webp",
  "bmp",
  "ico",
  "cur",
];

const AllDocumentsView = (props: any) => {
  const location = useLocation();
  const ViewOptions = [
    { name: "Box View", value: "boxview" },
    { name: "Table View", value: "tableview" },
  ];
  const navigate = useNavigate();
  const [displayDocuments, setDisplayDocuments] = useState([]);
  const [documentView, setdocumentView] = useState<any>(null);
  const urlParams = new URLSearchParams(location.search);
  var type = urlParams.has("type") ? urlParams.get("type") : null;
  // var allid = urlParams.has('id') ? urlParams.get('id') : null;
  var vid = urlParams.has("vid") ? urlParams.get("vid") : null;
  var id = urlParams.has("id") ? urlParams.get("id") : null;
  useEffect(() => {
    getDxDocs();
  }, []);
  const getDxDocs = async (nextToken?: any) => {
    // let variables: any = {};
    let variables: any = {
      resourceid: {
        contains: id,
      },
      name: {
        contains: type,
      },
      field1: {
        ne: "",
      },
    };
    if (nextToken) {
      variables.nextToken = nextToken;
    }

    delete variables.nextToken;

    let Documents = await dxService.listitems(
      "document",
      "*",
      variables,
      false,
      1000,
      nextToken
    );
    //   let response = Documents.items;

    let res = Documents.items;
    let nexttoken = Documents.nextToken;
    if (nexttoken) {
      const nextResults = await getDxDocs(nexttoken);
      res = res.concat(nextResults);
    }
    res = res.filter((item: any) => item._deleted !== true);
    console.log(res);

    getFileLocation(res);
    return res;
  };
  const Navigation = () =>
    navigate(`/manage/vehicles/item/allocations?id=${id}&mode=view`);
  const getFileLocation = async (e: any) => {
    let FinalData: any = [];

    for (let i = 0; i < e.length; i++) {
      let url = await Storage.get(e[i].name);
      FinalData.push({
        id: e[i].id,
        name: e[i].name.split("/").pop(),
        documents: url,
        path: e[i].name,
        renewaltype: e[i].name.split("/")[0],
        doctype: e[i].field1,
      });
    }
    let filterfinaaldata: any = [];
    let removeduplicates: any = [];
    filterfinaaldata = FinalData.filter(
      (fil: any, index: any) => fil.name !== ""
    );

    removeduplicates = filterfinaaldata.filter(
      (item: any, index: any) => filterfinaaldata.indexOf(item) === index
    );

    setDisplayDocuments(removeduplicates);
  };
  const AttachmentBodyTemplate = (e: any) => {
    return (
      <a href={e.documents} target="_self" className="">
        <i
          className="pi pi-download"
          style={{ marginLeft: "7px", color: "blue" }}
        ></i>
      </a>
    );
  };
  const itemTemplate = (product: any) => {
    console.log(product);

    let extension = product.name.split(".").pop();
    let imagesrc =
      extension == "pdf"
        ? Pdf
        : extension == "xlsx"
        ? excel
        : extension == "rtf"
        ? rtf
        : extension == "pptx"
        ? ppt
        : extension == "docx"
        ? word
        : product.documents;
    return (
      <>
        <div className="col-12 sm:col-4 lg:col-4 xl:col-3 p-2">
          <div className="p-4 border-1 surface-border surface-card border-round">
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
              <div className="flex align-items-center gap-2">
                <i className="pi pi-tag"></i>
                <span className="font-semibold">
                  {product.renewaltype} -{product.doctype}
                </span>
              </div>
              {imageExtensions.includes(extension) && (
                <Link
                  to={`/general/viewdocument?id=${product.id}&vid=${vid}&appid=${id}&type=${type}&parent=renewals`}
                >
                  <i
                    className="pi pi-eye"
                    style={{ marginLeft: "7px", color: "blue" }}
                  ></i>
                </Link>
              )}
              <a href={product.documents} target="_self" className="">
                <i
                  className="pi pi-download"
                  style={{ marginLeft: "7px", color: "blue" }}
                ></i>
              </a>
              {/* <Tag value={product.inventoryStatus}></Tag> */}
            </div>
            <div className="flex flex-column align-items-center gap-3 py-5">
              {/* <img className="w-9 shadow-2 border-round" src={`https://managevehicles3bucket124522-dev.s3.ap-south-1.amazonaws.com/public/${product.path}`} alt={product.name} /> */}
              <img
                className="w-9  border-round"
                src={imagesrc}
                style={{ height: "150px", objectFit: "contain" }}
              />
              <div className="text-l font-bold DocTitle" title={product.name}>
                {product.name}
              </div>
              {/* <Rating value={product.rating}></Rating> */}
            </div>
          </div>
        </div>
      </>
    );
  };
  const nameBodyTemplate = (rowData: any) => {
    let splitname = rowData.name.split(".");
    return <>{rowData.name}</>;
  };
  const downloadBodyTemplate = (rowData: any) => {
    return (
      <>
        {" "}
        <a href={rowData.documents} target="_self" className="">
          <i
            className="pi pi-download"
            style={{ marginLeft: "7px", color: "blue" }}
          ></i>
        </a>
      </>
    );
  };

  return (
    <>
      {" "}
      <div className="grid">
        <div className="col-12 md:col-12">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            {" "}
            <div>
              {" "}
              <h4 className="container-header">View Documents</h4>
            </div>
            <div>
              {" "}
              <Dropdown
                value={documentView}
                onChange={(e) => setdocumentView(e.value)}
                options={ViewOptions}
                optionLabel="name"
                placeholder="Select a View"
                className="w-full md:w-14rem"
              />
            </div>
          </div>

          <div className="card">
            {documentView == "boxview" ? (
              <DataView
                value={displayDocuments}
                itemTemplate={itemTemplate}
                paginator
                rows={8}
              />
            ) : (
              <DataTable
                value={displayDocuments}
                tableStyle={{ minWidth: "50rem" }}
                paginator
                rows={10}
              >
                <Column
                  field="Document Name"
                  filter
                  header="Document Name"
                  body={nameBodyTemplate}
                ></Column>
                <Column field="doctype" filter header="Document Type"></Column>
                <Column
                  field="renewaltype"
                  filter
                  header="Renewal Type"
                ></Column>
                <Column
                  field="category"
                  header="Download Document"
                  body={downloadBodyTemplate}
                ></Column>
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AllDocumentsView;
