import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { FileUpload } from 'primereact/fileupload';
var XLSX = require('xlsx');

function Documents(props: any) {
    console.log(props);

    const fileUploadRef = useRef<any>(null);
    const [totalSize, setTotalSize] = useState(0);
    const onTemplateRemove = (file: any, callback: any) => {
        props.setJsonData([]);
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateSelect = (e: any) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };
    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                {/* <span>{formid}</span> */}
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };
    const onTemplateClear = () => {
        setTotalSize(0);
    };
    const headerTemplate = (options: any) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop File Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };

    // convert excel file to JSON data
    const handleFileUpload = (event: any) => {
        const file = event.files[0];
        const reader = new FileReader();

        reader.onload = (event: any) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheet = workbook.Sheets[props.sheetName];
            const result: any = XLSX.utils.sheet_to_json(sheet, {
                dateNF: 'dd-mm-yyyy',
                raw: false
            });

            // Create an array to hold unique values
            var unique_data: any = [];
            // Loop through each row of data
            for (var i = 0; i < result.length; i++) {
                var row = result[i];
                // Check if the row already exists in the unique array
                var exists = false;
                for (var j = 0; j < unique_data.length; j++) {
                    if (JSON.stringify(unique_data[j]) === JSON.stringify(row)) {
                        exists = true;
                        break;
                    }
                }
                // If the row does not exist in the unique array, add it
                if (!exists) {
                    unique_data.push(row);
                }
            }

            props.setJsonData(unique_data);
        };
        reader.readAsBinaryString(file);
    };
    return (
        <div>
            <FileUpload
                ref={fileUploadRef}
                name="demo[]"
                multiple
                accept=".xlsx, .xls, .csv"
                uploadHandler={handleFileUpload}
                maxFileSize={1000000}
                customUpload={true}
                auto={true}
                onError={onTemplateClear}
                onClear={onTemplateClear}
                onSelect={onTemplateSelect}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions}
            />
        </div>
    );
}

export default Documents;
